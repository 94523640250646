import React from 'react';
import TableRow from "@material-ui/core/TableRow";
import Skeleton from "@material-ui/lab/Skeleton";
import TableCell from "@material-ui/core/TableCell";
import EditIcon from "@material-ui/icons/Edit";
import {useTranslation} from "react-i18next";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import HasRight from "../../../../../Route/HasRight";
import Button from "../../../../inputs/Button";

const Row = ({question, onSelected, loading}) => {
    const {t} = useTranslation();
    const displayQuestion = (!loading && question);

    return (
        <TableRow>
            <TableCell>
                {!displayQuestion && <Skeleton variant="circle" width={40} height={40}/>}
                {(displayQuestion && question.Visible === 'Y') && <VisibilityIcon/>}
                {(displayQuestion && question.Visible === 'N') && <VisibilityOffIcon/>}
            </TableCell>
            <TableCell>
                {!displayQuestion && <Skeleton/>}
                {(displayQuestion) && <span>{question.Code}</span>}
            </TableCell>
            <TableCell>
                {!displayQuestion && <Skeleton/>}
                {(displayQuestion) && <span>{question.Index}</span>}
            </TableCell>
            <TableCell>
                {!displayQuestion && <Skeleton/>}
                {(displayQuestion) && <span>{question.Question}</span>}
            </TableCell>
            <TableCell>
                {!displayQuestion && <Skeleton/>}
                {(displayQuestion) && <span>{t('question_type_' + question.Type)}</span>}
            </TableCell>
            <TableCell>
                {!displayQuestion && <Skeleton/>}
                {(displayQuestion && question.Options.length > 0 && question.Type !== 'string') &&
                <ul>{question.Options.map((o,idx) => <li key={'q-'+idx}>{o}</li>)}</ul>}
            </TableCell>
            <HasRight manager>
                <TableCell align="right">
                    {!displayQuestion && <Skeleton/>}
                    {(displayQuestion) && <Button
                        variant="contained"
                        color="primary"
                        onClick={() => onSelected(question)}
                        startIcon={<EditIcon/>}
                    >
                        {t('edit_btn')}
                    </Button>}
                </TableCell>

            </HasRight>
        </TableRow>
    );
};

export default Row;