import styledComponents from 'styled-components'
import {styled} from "@material-ui/core/styles";
import ReactAvatarEditor from 'react-avatar-editor';


const Div = styledComponents.div``;
const Label = styledComponents.label``;
const Figure = styledComponents.figure``;
const Input = styledComponents.input``;

export const AvatarEditorRange = styled(Input)(style => {
    return {
        width: '170px',
        margin: '0 auto',
    }
});

export const AvatarEditorImages = styled(Div)(style => {
    return {
        textAlign: 'center',
    }
});

export const AvatarEditorImagesInner = styled(Div)(style => {
    return {
        textAlign: 'center',
        width: '100%',
        maxWidth: '25rem',
        margin: '0 auto',
    }
});

export const AvatarEditor = styled(ReactAvatarEditor)(style => {
    return {
        width: '170px !important',
        height: '170px !important',
        margin: '0 auto',
        background: '#484848',
    }
});

export const AvatarImages = styled(Figure)(style => {
    return {
        width: '100%',
        maxWidth: '25rem',
        margin: '0 auto',
        '& > img': {
            width: '170px !important',
            height: '170px !important',
            margin: '0 auto',
            padding: '1rem;',
            boxSizing: 'border-box',
            background: '#484848',
        }
    }
});

export const AvatarWrapper = styled(Div)(style => {
    return {
        margin: "0 0 20px",
        padding: "0",
        textAlign: "center"
    }
});


export const AvatarBtnsWrapper = styled(Div)(style => {
    return {
        margin: '10px 0 0',
        display: 'flex'
    }
});


export const AvatarBtn = styled(Label)(style => {
    return {
        width: "100%",
        padding: '9px 13px',
        boxSizing: 'border-box',
        display: 'block',
        color: '#fff',
        background: '#555',
        border: '1px solid #484848',
        borderRadius: '5px',
        cursor: 'pointer',
        '&:disabled': {
            opacity: .4,
            cursor: 'not-allowed'
        },
        '&:hover': {
            opacity: .4,
            transition: 'all .2s ease'
        },
        '&.upload': {
            background: '#fff',
            color: '#333',
            '& > input': {
                display: 'none'
            }
        },
        '&.save': {
            backgroundColor: '#48c774',
            color: '#fff',
            border: '1px solid #48c774'
        }

    }
});

export const AvatarBtnWrapper = styled(Div)(style => {
    return {
        width: '48%',
        marginRight: '2%',
        marginBottom: '5px',
        '&:last-child': {
            marginRight: 0,
        }
    }
});