import {rest} from '@karpeleslab/klbfw'
import {
    TAG_CATEGORY_CREATE,
    TAG_CATEGORY_CREATE_DONE,
    TAG_CREATE_LABEL,
    TAG_CREATE_LABEL_DONE,
    TAG_DELETE,
    TAG_DELETE_DONE
} from "../reducers/TagReducer";
import {success} from "./system/ToastAction";
import {handleError} from "../../components/utils/apiErrorHandler";

export const createLabel = (tag, name, language, tagData = {}) => {
    return (dispatch, getState) => {
        dispatch({type: TAG_CREATE_LABEL});
        const p = {
            Name: name,
            Language__: language
        };

        const updateParams = {
            tag:tag.Classify_Tag__,
            ...tagData
        }

        return rest('Classify/Tag/' + tag.Classify_Tag__ + '/Label', 'POST', p)
            .then(() =>  rest('Classify/' + tag.Classify__ + '/Tag:setAttributes', 'POST', updateParams))
            .then((d) => {
                success('tag_edited_success');
                dispatch({type: TAG_CREATE_LABEL_DONE});
                return d.data;
            }).catch((err) => handleError(getState, dispatch, err));
    }
};

export const createCategory = (classifyId, tagName) => {
    return (dispatch, getState) => {
        dispatch({type: TAG_CATEGORY_CREATE});
        return rest('Classify/' + classifyId + '/Tag:setAttributes', 'POST', {tag: tagName, Flags: ['category']})
            .then((d) => {
                success('category_create_success');
                dispatch({type: TAG_CATEGORY_CREATE_DONE});
                return d.data;
            }).catch((err) => handleError(getState, dispatch, err));
    }
};

export const deleteTag = (tagId) => {
    return (dispatch, getState) => {
        dispatch({type: TAG_DELETE});
        return rest('Classify/Tag/' + tagId, 'DELETE')
            .then(s => {
                dispatch({type: TAG_DELETE_DONE, tagId : tagId});
                success('category_delete_success');
            })
    }
};