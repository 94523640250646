import styled from '@material-ui/core/styles/styled';
import Drawer from '@material-ui/core/Drawer';

export const drawerWidth = 120;

export const StyledDrawer = styled(Drawer)(style => {
	let s = {
		borderRight: '1px solid #1E1E1E',
		height: '100%',
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		paddingTop: style.theme.spacing(8),
		overflow: 'hidden',
		background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), #121212',
	};


	return {'& > .MuiDrawer-paper': s};
});