import React                 from 'react';
import {useTranslation}      from 'react-i18next';
import Empty                 from '../feeback/State/Empty';
import {connect}             from 'react-redux';
import {getStoreWizardRoute} from '../../../router/routes/store/factory/store';

const NoStore = ({isOwner}) => {
	const {t} = useTranslation();

	return (
		<Empty
			title={t('dashboard_no_store_stats')}
			textI18Key="store_wizard_intro_desc"
			graphic={null}
			action={isOwner ? {
				title: t('create_btn'),
				to: getStoreWizardRoute(),
			} : null}
		/>
	);
};
const mapStateToProps = (state) => {
	return {
		isOwner: state.access.owner
	};
};

export default connect(mapStateToProps)(NoStore);