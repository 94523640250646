import React, { Fragment, useEffect } from 'react';
import TableHead                      from '@material-ui/core/TableHead';
import MaterialTable                  from '@material-ui/core/Table';
import TableRow                       from '@material-ui/core/TableRow';
import TableCell                      from '@material-ui/core/TableCell';
import TableBody                      from '@material-ui/core/TableBody';
import TableContainer                 from '@material-ui/core/TableContainer';
import Row                            from './Row';
import { connect }                    from 'react-redux';
import {
	fetchMembers,
	setMembersFilters,
	setMembersPaging
}                                     from '../../../../../store/actions/fanclub/MembershipAction';
import TableFooter                    from '@material-ui/core/TableFooter';
import SortableCell                   from '../../../data/table/SortableCell';
import { useTranslation }             from 'react-i18next';
import Empty                          from './Empty';
import Pagination                     from '../../../data/table/Pagination';

const Table = ({ selectedMembership, members, fetch, loading, filters, paging, count, setMembersPaging, setMembersFilters }) => {
	const loadingData = [...Array(paging.results_per_page).keys()];
	const { t } = useTranslation();
	const displayLoading = () => loadingData.map((k) => <Row key={k} loading={true} member={null}/>);
	const displayMembers = () => members.map((u, idx) => <Row key={u.Membership_User__} loading={false} idx={idx}/>);

	useEffect(() => {
		fetch();
	}, [selectedMembership, fetch, filters, paging]);

	const { sort } = filters;

	const sortHandler = (property, dir) => {
		var p = { ...filters };
		// only 1 sort
		p.sort = {};
		p.sort[property] = dir;
		setMembersFilters(p);
	};

	return (
		<Fragment>
			{(loading || members.length > 0) &&
			<TableContainer>
				<MaterialTable aria-label='simple table'>
					<TableHead>
						<TableRow>
							<Pagination
								setPaging={setMembersPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
						<TableRow>
							<TableCell style={{ width: '40px' }}> {t('tablecell_picture')}</TableCell>
							<TableCell style={{ width: '17px' }}>
								<SortableCell sort={sort} onClick={sortHandler} field='Index' label={t('tablecell_n')}/>
							</TableCell>
							<TableCell> {t('tablecell_username')}</TableCell>
							<TableCell> {t('tablecell_email')}</TableCell>
							<TableCell>
								<SortableCell sort={sort} onClick={sortHandler} field='Status'
								              label={t('tablecell_status')}/>
							</TableCell>
							<TableCell>
								<SortableCell sort={sort} onClick={sortHandler} field='Price'
								              label={t('tablecell_price')}/>
							</TableCell>
							<TableCell align='right'>
								<SortableCell sort={sort} onClick={sortHandler} field='Membership_Plan__'
								              label={t('tablecell_plan')}/>
							</TableCell>
							<TableCell align='right'>
								<SortableCell sort={sort} onClick={sortHandler} field='Start'
								              label={t('tablecell_membership_start')}/>
							</TableCell>
							<TableCell align='right'/>
						</TableRow>
					</TableHead>
					<TableBody>
						{loading && displayLoading()}
						{!loading && displayMembers()}
					</TableBody>
					<TableFooter>
						<TableRow>
							<Pagination
								setPaging={setMembersPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
					</TableFooter>

				</MaterialTable>
			</TableContainer>
			}
			{(!loading && members.length < 1) && <Empty/>}
		</Fragment>
	);

};


const mapStateToProps = (state) => {
	return {
		filters: state.membership.membersFilters,
		paging: state.membership.membersPaging,
		count: state.membership.membersPagingCount,
		loading: state.membership.membersLoading,
		members: state.membership.members
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: () => dispatch(fetchMembers()),
		setMembersPaging: (newPaging) => dispatch(setMembersPaging(newPaging)),
		setMembersFilters: (newPaging) => dispatch(setMembersFilters(newPaging))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);

