import React from 'react';
import Select from "./Select";
import {useTranslation} from "react-i18next";

const ShippingStatusSelector = ({value, setValue, disabled, required}) => {
    const {t} = useTranslation();

    return <Select
        label={t('shipping_status')}
        value={value}
        onChange={e => setValue(e.target.value)}
        optionKey='Key'
        optionValue='Value'
        options={[
            {Key: 'pending', Value: t('shipping_filter_tab_pending')},
            {Key: 'processing', Value: t('shipping_filter_tab_processing')},
            {Key: 'sent', Value: t('shipping_filter_tab_sent')},
            {Key: 'return', Value: t('shipping_filter_tab_return')}
        ]}
        inputProps={{
            disabled: disabled,
            variant: 'outlined',
            fullWidth: true,
            required: required
        }}
    />;
};

export default ShippingStatusSelector;