import React from 'react';
import {useTranslation} from "react-i18next";
import Graphic from "../../../../../resources/images/subscriptionPlan.svg";
import EmptyState from "../../../feeback/State/Empty";
import AddIcon from "@material-ui/icons/Add";

const Empty = ({createHandler}) => {

    const {t} = useTranslation();
    return (
        <EmptyState
            textI18Key='fanclub_wizard_plans_desc'
            action={{
                endIcon: <AddIcon/>,
                title: t('create_btn'),
                onClick: createHandler
            }}
            graphic={Graphic}
        />
    );

};

export default Empty;