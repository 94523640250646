import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => {
    const variants = [
        {variant: '1x1', ratio: 1},
        {variant: '4x3', ratio: 1.3333},
    ];

    const sizes = [
        {size: 'small', height: 16},
        {size: 'medium', height: 32},
        {size: 'large', height: 48},
        {size: 'x-large', height: 64}
    ];

    let c = {
        'wrapper': {
            position: 'relative',
        }
    };
    variants.forEach((v) => {
        sizes.forEach((s) => {
            c['flag-' + v.variant + '-' + s.size] = {
                width: (s.height * v.ratio) + 'px',
                height: s.height + 'px',
                border: '1px solid #cacaca;',
                '&.disabled': {
                    filter: 'grayscale(100%)'
                },
                '&.rounded': {
                    borderRadius: '50%',
                }
            };
            c['wrapper-' + v.variant + '-' + s.size] = {
                width: (s.height * v.ratio) + 'px',
                height: s.height + 'px',
                '&.rounded': {
                    overflow: 'hidden'
                }
            };
        })
    });

    return c;

});


const Flag = React.forwardRef((props, ref) => {
    const {code, ratio = '4x3', size = 'medium', disabled = false, rounded = false} = props;
    const classes = useStyles();
    return <div {...props} ref={ref} className={classes['wrapper-' + ratio + '-' + size] + (rounded ? ' rounded' : '')}>
        <img alt={code} className={classes['flag-' + ratio + '-' + size] + (disabled ? ' disabled' : '') + (rounded ? ' rounded' : '')}
             src={process.env.PUBLIC_URL + '/imgs/flags/' + ratio + '/' + code + '.svg'}/></div>
});

export default Flag;