import React, {Fragment, useEffect, useState} from 'react';
import TableHead                              from '@material-ui/core/TableHead';
import MaterialTable                from '@material-ui/core/Table';
import TableRow                     from '@material-ui/core/TableRow';
import TableCell                    from '@material-ui/core/TableCell';
import TableContainer               from '@material-ui/core/TableContainer';
import Row                          from './Row';
import {useTranslation} from 'react-i18next';
import Empty          from './Empty';
import TableBody      from '@material-ui/core/TableBody';
import TableFooter    from '@material-ui/core/TableFooter';
import {connect}      from 'react-redux';
import Pagination     from '../../../../../data/table/Pagination';
import {
	fetchShippingFeeScales,
	setShippingFeeScaleFilters,
	setShippingFeeScalePaging
}                     from '../../../../../../../store/actions/store/ShippingFeeAction';
import {fetchVAT}     from '../../../../../../../store/actions/OrderAction';
import Grid           from '@material-ui/core/Grid';
import TextField      from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

const Table = ({
	               feeId,
	               zoneId,
	               scales,
	               fetch,
	               loading,
	               filters,
	               paging,
	               count,
	               setShippingFeeScalePaging,
	               setShippingFeeScaleFilters,
	               fetchVat
               }) => {
	const loadingData = [...Array(paging.results_per_page).keys()];
	const {t} = useTranslation();
	const displayLoading = () => loadingData.map((k) => <Row
		key={k}
		loading={true}
		scale={null}
		vat={vat}
	/>);

	const [vat, setVat] = useState('');
	useEffect(() => {
		fetchVat().then(d => setVat(d.Rate))
	}, [fetchVat]);

	const displayScales = () => scales.map((u, idx) => <Row key={u.Catalog_Deliverable_Fee_Scale__}
	                                                        loading={false}
	                                                        scale={u} vat={vat}/>);

	useEffect(() => {
		// Reset filters the first time
		setShippingFeeScaleFilters({...filters, Catalog_Deliverable_Fee_Zone__: zoneId});
		// eslint-disable-next-line
	}, [zoneId, setShippingFeeScaleFilters]);


	useEffect(() => {
		fetch(feeId);
	}, [fetch, feeId, filters, paging]);

	/*const {sort} = filters;

	const sortHandler = (property, dir) => {
		let p = {...filters};
		// only 1 sort
		p.sort = {};
		p.sort[property] = dir;
		setRecordsFilters(p);
	};*/

	return (
		<Fragment>
			{(loading || scales.length > 0) &&
			<TableContainer>
				<MaterialTable aria-label="simple table">
					<TableHead>
						<TableRow>
							<Pagination
								setPaging={setShippingFeeScalePaging}
								paging={paging}
								loading={loading}
								count={count}
								prefix='2'
							/>
						</TableRow>
						<TableRow>
							<TableCell> {t('tablecell_shipping_fee_scale_code')}</TableCell>
							<TableCell> {t('tablecell_shipping_fee_scale_price')}</TableCell>
							<TableCell> {t('tablecell_shipping_fee_scale_weight')}</TableCell>
							<TableCell> {t('tablecell_shipping_fee_scale_tax_profile')}</TableCell>
							<TableCell align="right"/>
						</TableRow>
					</TableHead>
					<TableBody>
						{loading && displayLoading()}
						{!loading && displayScales()}
					</TableBody>
					<TableFooter>
						<TableRow>
							<Pagination
								setPaging={setShippingFeeScalePaging}
								paging={paging}
								loading={loading}
								count={count}
								prefix='2'
							/>
						</TableRow>
					</TableFooter>

				</MaterialTable>
				<Grid container alignItems='flex-end' direction='column'>
					<Grid item>
						<TextField
							variant='standard'

							value={vat}
							onChange={e => setVat(e.target.value)}
							type='number'
							InputProps={
								{
									min: 0,
									endAdornment:  <InputAdornment position="end">%</InputAdornment>,
									startAdornment: <InputAdornment position="start">{t('vat')}:</InputAdornment>,
								}
							}
						/>
					</Grid>
				</Grid>
			</TableContainer>
			}
			{(!loading && scales.length < 1) && <Empty/>}
		</Fragment>
	);

};


const mapStateToProps = (state) => {
	return {
		filters: state.shippingFee.scalesFilters,
		paging: state.shippingFee.scalesPaging,
		count: state.shippingFee.scalesPagingCount,
		loading: state.shippingFee.scalesLoading,
		scales: state.shippingFee.scales,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: (feeId) => dispatch(fetchShippingFeeScales(feeId)),
		setShippingFeeScalePaging: (newPaging) => dispatch(setShippingFeeScalePaging(newPaging)),
		setShippingFeeScaleFilters: (newFilters) => dispatch(setShippingFeeScaleFilters(newFilters)),
		fetchVat: () => dispatch(fetchVAT())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);

