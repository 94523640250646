import React, { useEffect, useState }          from 'react';
import { Grid }                                from '@material-ui/core';
import { setProductsFilters }                  from '../../../../store/actions/store/ProductAction';
import { connect }                             from 'react-redux';
import { useTranslation }                      from 'react-i18next';
import Loading                                 from '../../feeback/loading/Loading';
import Table                                   from './table/Table';
import Toolbar                                 from '../../typography/Toolbar';
import AddIcon                                 from '@material-ui/icons/Add';
import Button                                  from '../../inputs/Button';
import HasRight                                from '../../../Route/HasRight';
import { Link }                                from 'react-router-dom';
import { getStoreProductCreateVariationRoute } from '../../../../router/routes/store/factory/store';

const Variations = ({ productId, productsFilters, setProductsFilters, catalog }) => {
	const [showTable, setShowTable] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		setProductsFilters({ Parent_Catalog_Product__: productId });
		setShowTable(true);
		// eslint-disable-next-line
	}, [setProductsFilters, setShowTable]);

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Toolbar title={t('product_variations_title')}>
					<HasRight manager>
						<Button
							endIcon={<AddIcon/>}
							color='primary'
							variant='contained'
							component={Link}
							to={getStoreProductCreateVariationRoute(productId)}
						>
							{t('create_btn')}
						</Button>
					</HasRight>
				</Toolbar>
			</Grid>

			<Grid item xs={12}>
				{showTable && <Table catalog={catalog} isVariation={true}/>}
				{!showTable && <Loading/>}
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		productsFilters: state.product.productsFilters,
		catalog: state.store.catalog,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setProductsFilters: (newFilters) => dispatch(setProductsFilters(newFilters)),
	};
};


export default connect(mapStateToProps, mapDispatchToProps)(Variations);
