import React, {Fragment, useEffect} from 'react';
import MaterialTable from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer                                      from "@material-ui/core/TableContainer";
import Row                                                 from "./Row";
import {connect}                                           from 'react-redux'
import TableFooter                                         from "@material-ui/core/TableFooter";
import TablePagination                                     from "@material-ui/core/TablePagination";
import TablePaginationActions                              from "@material-ui/core/TablePagination/TablePaginationActions";
import {useTranslation}                                    from 'react-i18next';
import {fetchTickets, setTicketsFilters, setTicketsPaging} from "../../../../../store/actions/SupportAction";
import queryString                                         from "query-string";
import {useHistory, useLocation}                           from "react-router-dom";
import Empty                                               from "./Empty";
import TableCell                                           from '@material-ui/core/TableCell';
import SortableCell                                        from '../../../data/table/SortableCell';
import TableHead                                           from '@material-ui/core/TableHead';
import Grid                                                from '@material-ui/core/Grid';

const Table = ({tickets, fetch, loading, filters, paging, count, setTicketsPaging, setTicketsFilters, disabled, box}) => {
    const refreshTimeout = setTimeout(() => {
        if (disabled) return;
        fetch(true);
    }, 30000)

    useEffect(() => {
        return () => {
            clearTimeout((refreshTimeout))
        }
    }, [refreshTimeout])

    const loadingData = [...Array(paging.results_per_page).keys()];
    const {t} = useTranslation();
    const displayLoading = () => loadingData.map((k) => <Row key={k} loading={true} ticket={null}/>);
    const displayTickets = () => tickets.map((u) => <Row key={u.Support_Ticket__} loading={false} ticket={u} box={box}/>);

    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const params = queryString.parse(location.search);
        const pNo =  'page_no' in params ? parseInt(params.page_no) : 1;
        if(paging.page_no !== pNo )
            setTicketsPaging({page_no:pNo})
        // eslint-disable-next-line
    }, [location]);

    useEffect(() => {
        if (disabled) return;
        fetch();
    }, [fetch, filters, paging, disabled]);

    const changePageHandler = (e, newPage) => {
        // The pagination component start at 0;...
        if (loading) return;
        let params = queryString.parse(location.search);
        params.page_no = newPage + 1;
        history.push(location.pathname + '?' + queryString.stringify(params));
    };

    const changeResultPerPageHandler = e => {
        if (loading) return;
        setTicketsPaging({results_per_page: e.target.value})
    };

    const { sort } = filters;

    const sortHandler = (property, dir) => {
        var p = { ...filters };
        // only 1 sort
        p.sort = {};
        p.sort[property] = dir;
        setTicketsFilters(p);
    };

    return (
        <Fragment>
            {(loading || tickets.length > 0) &&
            <TableContainer>
                <MaterialTable aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Grid container>
                                    <Grid item xs={8}/>
                                    <Grid item xs={4}>
                                        <SortableCell sort={sort} onClick={sortHandler} field='Last_Update' label={t('tablecell_mail_date')}/>
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(loading || disabled) && displayLoading()}
                        {(!loading && !disabled) && displayTickets()}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                colSpan={8}
                                count={loading ? loadingData.length : parseInt(count)}
                                rowsPerPage={parseInt(paging.results_per_page)}
                                page={loading ? 0 : parseInt(paging.page_no) - 1}
                                labelRowsPerPage={t('rows_per_page')}
                                SelectProps={{
                                    inputProps: {'aria-label': t('rows_per_page')},
                                    native: true,
                                }}
                                labelDisplayedRows={
                                    ({ from, to, count }) => t(count !== -1 ? 'table_paging' : 'table_paging_more', {from:from, to:to, count:count})
                                }
                                onChangePage={changePageHandler}
                                onChangeRowsPerPage={changeResultPerPageHandler}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </MaterialTable>
            </TableContainer>
            }
            {(!loading && tickets.length < 1) && <Empty box={box}/>}
        </Fragment>
    )

};


const mapStateToProps = (state) => {
    return {
        filters: state.support.ticketsFilters,
        paging: state.support.ticketsPaging,
        count: state.support.ticketsPagingCount,
        loading: state.support.ticketsLoading,
        tickets: state.support.tickets
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetch: (silent = false) => dispatch(fetchTickets(silent)),
        setTicketsPaging: (newPaging) => dispatch(setTicketsPaging(newPaging)),
        setTicketsFilters: (newPaging) => dispatch(setTicketsFilters(newPaging))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);

