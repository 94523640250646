import React, {Fragment, useEffect} from 'react';
import TableHead from '@material-ui/core/TableHead';
import MaterialTable from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Row from './Row';
import {connect} from 'react-redux';
import TableFooter from '@material-ui/core/TableFooter';
import {useTranslation} from 'react-i18next';
import Empty from './Empty';
import Pagination from '../../data/table/Pagination';
import {fetchMassMails, setMassMailsFilters, setMassMailsPaging} from '../../../../store/actions/MassMailAction';


const Table = ({
	               realmId,
	               massMails,
	               fetch,
	               setMassMailsFilters,
	               setMassMailsPaging,
	               loading,
	               filters,
	               paging,
	               count,
	               disabled = false
               }) => {
	const loadingData = [...Array(paging.results_per_page).keys()];

	const displayLoading = () => loadingData.map((k) => <Row key={k} loading={true} massMail={null}/>);
	const displayMassMails = () => massMails.map((o) => <Row key={o.Network_MassMail__} loading={false} massMail={o}/>);
	const {t} = useTranslation();

	useEffect(() => {
		if (!disabled) fetch(realmId);
	}, [realmId, disabled, fetch, filters, paging]);


	return (
		<Fragment>
			{(loading || massMails.length > 0) &&
			<TableContainer>
				<MaterialTable aria-label="simple table">
					<TableHead>
						<TableRow>
							<Pagination
								setPaging={setMassMailsPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
						<TableRow>
							<TableCell>{t('massmail_label_label')}</TableCell>
							<TableCell>{t('massmail_schedule_lbl')}</TableCell>
							<TableCell>{t('massmail_status')}</TableCell>
							<TableCell align='right'/>
						</TableRow>
					</TableHead>
					<TableBody>
						{(loading || disabled) && displayLoading()}
						{(!loading && !disabled) && displayMassMails()}
					</TableBody>
					<TableFooter>
						<TableRow>
							<Pagination
								setPaging={setMassMailsPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
					</TableFooter>

				</MaterialTable>
			</TableContainer>
			}
			{(!loading && massMails.length < 1) && <Empty/>}
		</Fragment>
	);
};


const mapStateToProps = (state) => {
	return {
		filters: state.massMail.massMailsFilters,
		paging: state.massMail.massMailsPaging,
		count: state.massMail.massMailsPagingCount,
		loading: state.massMail.massMailsLoading,
		massMails: state.massMail.massMails
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: (realmId) => dispatch(fetchMassMails(realmId)),
		setMassMailsFilters: (filters) => dispatch(setMassMailsFilters(filters)),
		setMassMailsPaging: (paging) => dispatch(setMassMailsPaging(paging))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);

