import {pad, trimLength} from './misc';

export const getLineId = (idx) => {
	return `lyric-line-${idx}`;
};

export const getLineTimeCodeId = (idx) => {
	return `lyric-line-time-code-${idx}`;
};

export const getLineElement = (idx) => {
	return document.getElementById(getLineId(idx));
};

export const getLineTimeCodeElement = (idx) => {
	return document.getElementById(getLineTimeCodeId(idx));
};

export const getTimeCode = (idx) => {
	const el = getLineTimeCodeElement(idx);
	if (!el) return null;
	const timeCode = el.innerText;
	if (!timeCode) return null;
	if (timeCode[0] === '-') return null;
	return timeCode;
};

/*
Presuppose the timeCode var will be in the following format '--:--:--.---' or '--:--.---'
 */
export const getTimeCodeFormat = (timeCode, full) => {
	if (!timeCode) {
		if (full) return '--:--:--.---';
		return '--:--.---';
	}
	return full ? timeCode : (timeCode.length > 9 ? timeCode.substr(3) : timeCode);
};

export const format = (str, size) => {
	return trimLength(pad(str, size), size)
}

export const formatDuration = (duration) => {
	return `${format(duration.hours(), 2)}:${format(duration.minutes(), 2)}:${format(duration.seconds(), 2)}.${format(duration.milliseconds(), 3)}`;
};
