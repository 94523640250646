import React, { useCallback } from 'react';
import { useTranslation }     from 'react-i18next';

// component
import Row            from './Row';
import MaterialTable  from '@material-ui/core/Table';
import TableBody      from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { Typography } from '@material-ui/core';
import Graphic        from '../../../../../../../resources/images/chat.svg';

const Table = ({ chatLog }) => {
	const { t } = useTranslation();
	const displayQuestions = useCallback(() => {
		return chatLog.map((u, idx) => <Row key={idx} chatLog={u}/>);
	}, [chatLog]);

	return (
		<>
			{(chatLog.length > 0) &&
			<TableContainer>
				<MaterialTable aria-label='simple table'>
					<TableBody>
						{displayQuestions()}
					</TableBody>
				</MaterialTable>
			</TableContainer>
			}
			{(chatLog.length < 1) && <div style={{ textAlign: 'center' }}>
				<Typography variant='h5' gutterBottom>{t('chat_empty_title')}</Typography>
				<img src={Graphic} alt='artist' style={{ marginTop: '10px' }} width='50%'/>

			</div>}

		</>
	);


};


export default Table;
