import React, { useEffect, useState } from 'react';
import Dialog                         from '@material-ui/core/Dialog';
import DialogTitle                    from '@material-ui/core/DialogTitle';
import DialogContent                  from '@material-ui/core/DialogContent';
import DialogContentText              from '@material-ui/core/DialogContentText';
import DialogActions                  from '@material-ui/core/DialogActions';
import Grid                           from '@material-ui/core/Grid';
import { getLocale }             from '@karpeleslab/klbfw';
import TextField                 from '@material-ui/core/TextField';
import { useHistory }            from 'react-router-dom';
import { useTranslation }        from 'react-i18next';
import Button                    from '../../inputs/Button';
import Alert                     from '@material-ui/lab/Alert';
import { getSiteEventEditRoute } from '../../../../router/routes/site/factory/planner';
import ReserveTypeSelector       from '../../inputs/ReserveTypeSelector';
import EventDates                from './EventDates';
import { createEvent }           from '../../../../store/actions/planner/EventAction';
import { connect }               from 'react-redux';
import FormRequiredField         from '../../feeback/FormRequiredField';

const CreateDialog = ({ open, setOpen, creating, cmsId, createEntryData, createEvent }) => {
	const { t } = useTranslation();
	const [lang] = useState(getLocale());
	const [title, setTitle] = useState('');
	const [location, setLocation] = useState('');

	const [date, setDate] = useState(null);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [reserveType, setReserveType] = useState();
	const [freeCancelDate, setFreeCancelDate] = useState(null);

	const [saveEnabled, setSaveEnabled] = useState(false);


	let history = useHistory();

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		if (!startDate || !date || !endDate || !reserveType || !lang || title.trim() === '' || location.trim() === '') {
			setSaveEnabled(false);
			return;
		}

		/* const now = moment();

		 if (startDate < now || date < now || endDate < now) {
			 setSaveEnabled(false);
			 return;
		 }

		 if (startDate > date || startDate > endDate || endDate > date) {
			 setSaveEnabled(false);
			 return;
		 }*/

		setSaveEnabled(true);

	}, [lang, title, date, startDate, endDate, reserveType, location]);

	const handleSubmit = (e) => {
		e.stopPropagation();
		e.preventDefault();

		createEvent(
			cmsId,
			title,
			lang,
			'@' + date.unix(),
			'@' + startDate.unix(),
			'@' + endDate.unix(),
			reserveType,
			location,
			!freeCancelDate ? null : '@' + freeCancelDate.unix()
		)
			.then((event) => {
				history.push(getSiteEventEditRoute(event.Planner_Event__));
			}).then(handleClose);
	};

	return (
		<Dialog
			open={open}
			aria-labelledby={t('create_event')}
			aria-describedby={t('create_event')}
			fullWidth
			maxWidth='lg'
		>
			<form onSubmit={handleSubmit}>
				<DialogTitle id='create-event-title'>{t('create_event')}</DialogTitle>
				<DialogContent>
					<DialogContentText id='create-event-description'>
					</DialogContentText>

					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Alert severity='info'>{t('create_event_alert')}</Alert>
						</Grid>

						<FormRequiredField/>

						<Grid item xs={12} md={6}>
							<TextField label={t('event_title')} fullWidth variant='outlined' value={title}
							           required
							           disabled={creating}
							           onChange={e => setTitle(e.target.value)}
							           helperText={t('title_event_selected_lang')}/>
						</Grid>

						<Grid item xs={12} md={6}>
							<TextField label={t('event_location')} fullWidth variant='outlined' value={location}
							           required
							           disabled={creating}
							           onChange={e => setLocation(e.target.value)}
							           helperText={t('event_location_helperText')}
							           inputProps={{
								           maxLength: 128
							           }}/>
						</Grid>

						<Grid item xs={12}>
							<EventDates disabled={creating}
							            date={date}
							            setDate={setDate}
							            endDate={endDate}
							            setEndDate={setEndDate}
							            startDate={startDate}
							            setStartDate={setStartDate}
							            freeCancelDate={freeCancelDate}
							            setFreeCancelDate={setFreeCancelDate}
							/>
						</Grid>

						<Grid item xs={12}>
							<Alert severity='warning'>
								{t('event_reserve_type_alert')}
							</Alert>
						</Grid>
						<Grid item xs={12}>
							<ReserveTypeSelector
								required
								value={reserveType}
								setValue={setReserveType}
								disabled={creating}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button color='default' onClick={handleClose} disabled={creating}>
						{t('close_btn')}
					</Button>
					<Button color='primary' variant='contained' loading={creating} disabled={creating || !saveEnabled}
					        type='submit'>
						{t('create_btn')}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

const mapStateToProps = (state) => {
	return {
		creating: state.event.creating || state.cms.creatingEntryData
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		createEvent: (cmsId, title, language, date, reserveStartDate, reserveEndDate, reserveType, location, freeCancelDate) => dispatch(createEvent(cmsId, title, language, date, reserveStartDate, reserveEndDate, reserveType, location, freeCancelDate)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDialog);
