import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import AppBarTitle from "../typography/AppBarTitle";
import {useTranslation} from "react-i18next";
import Table from "./table/Table/Table";
import {connect} from 'react-redux'
import {fetchSupport, fetchTickets, selectTicket} from "../../../store/actions/SupportAction";
import {matchPath, Route, useHistory, useLocation} from "react-router-dom";
import Ticket from "./Ticket";
import Hidden from "@material-ui/core/Hidden";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Loading from "../feeback/loading/Loading";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Filters from "./Filters";
import Collapse from "@material-ui/core/Collapse";
import SelectRealm from "../../../pages/SelectRealm";
import Toolbar from "../typography/Toolbar";
import {getSupportBoxRoute, getSupportBoxTicketRoute} from "../../../router/routes/support/factory/boxes";

const useStyles = makeStyles(theme => ({
    root: {
        borderRight: 'solid 1px rgba(224, 224, 224, 1);',
    },
}));

const MailBox = ({selectedRealm, box, disabled, resetSelectedTicket, tags, tagsLoading, refreshSupport, refreshTickets}) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const location = useLocation();
    const history = useHistory();
    const hasTicket = matchPath(location.pathname,  getSupportBoxTicketRoute(box)) !== null;
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [filtersShown, setFiltersShown] = useState(false);

    if (!selectedRealm && !hasTicket) return <SelectRealm/>;

    if (!hasTicket) {
        resetSelectedTicket();
    }

    const createFilterToggle = v => e => {
        setFiltersShown(v);
    };

    const handleClose = (d) => {
        refreshTickets();
        refreshSupport(d.Support__);
        resetSelectedTicket();
        history.push(getSupportBoxRoute(box))
    };

    const handleReplied = (d) => {
        refreshTickets();
        refreshSupport(d.Support__);
        resetSelectedTicket();
        history.push(getSupportBoxRoute(box))
    };

    return (
        <Grid container spacing={3}>
            <AppBarTitle title={t('support_' + box + '_title')}/>

            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Toolbar title={t('support_' + box + '_title')}>
                            {tagsLoading && <Loading/>}
                            {(!tagsLoading && tags.length > 0) &&
                            <Tooltip title={t('filters')}>
                                <IconButton aria-label={t('filters')} onClick={createFilterToggle(!filtersShown)}>
                                    {!filtersShown && <FilterListIcon/>}
                                    {filtersShown && <ExpandLessIcon/>}
                                </IconButton>
                            </Tooltip>}
                        </Toolbar>
                        <Collapse in={filtersShown}>
                            <Toolbar>
                                <Filters/>
                            </Toolbar>
                        </Collapse>
                    </Grid>
                    <Hidden smDown={hasTicket}>
                        <Grid item xs={hasTicket ? 5 : 12} className={hasTicket ? classes.root : ''}>
                            <Table disabled={disabled} box={box}/>
                        </Grid>
                    </Hidden>

                    <Route path={getSupportBoxTicketRoute(box)}>
                        <Grid item xs={mobile ? 12 : 7}>
                            <Ticket onReplied={handleReplied} onClosed={handleClose}/>
                        </Grid>
                    </Route>
                </Grid>
            </Grid>


        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        tags: state.support.supportClassifyTags,
        tagsLoading: state.support.supportClassifyTagsLoading,
        selectedRealm: state.realm.selected,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        refreshTickets: () => dispatch(fetchTickets(true)),
        refreshSupport: (id) => dispatch(fetchSupport(id, true)),
        resetSelectedTicket: () => dispatch(selectTicket(null))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MailBox);