import {COMMON_RESET}                                from './CommonReducer';
import {USER_LOGOUT}                                                             from './system/UserReducer';

const initState = () => {
	return {
		massMailSegment: null,
		massMailSegmentLoading: true,
		massMailSegmentUpdating: false,

		massMail: null,
		massMailLoading: true,

		creating: false,
		updating: false,
		ready: false,
		buildingQueue: false,
		cancelling: false,
		testing: false,

		massMailsLoading: true,
		massMailsFilters: {
			sort: {'Scheduled': 'DESC'}
		},
		massMails: [],
		massMailsPagingCount: 0,
		massMailsPaging: {
			page_no: 1,
			results_per_page: 10
		},

		segmentsLoading: true,
		segmentsFilters: {},
		segments: [],
		segmentsPagingCount: 0,
		segmentsPaging: {
			page_no: 1,
			results_per_page: 10
		},
	};
};

export const MASSMAIL_FETCH = 'MASSMAIL_FETCH';
export const MASSMAIL_FETCH_DONE = 'MASSMAIL_FETCH_DONE';

export const MASSMAIL_SEGMENT_FETCH = 'MASSMAIL_SEGMENT_FETCH';
export const MASSMAIL_SEGMENT_FETCH_DONE = 'MASSMAIL_SEGMENT_FETCH_DONE';

export const MASSMAIL_SEGMENT_UPDATE = 'MASSMAIL_SEGMENT_UPDATE';
export const MASSMAIL_SEGMENT_UPDATE_DONE = 'MASSMAIL_SEGMENT_UPDATE_DONE';

export const MASSMAIL_CREATE = 'MASSMAIL_CREATE';
export const MASSMAIL_CREATE_DONE = 'MASSMAIL_CREATE_DONE';

export const MASSMAIL_UPDATE = 'MASSMAIL_UPDATE';
export const MASSMAIL_UPDATE_DONE = 'MASSMAIL_UPDATE_DONE';

export const MASSMAIL_READY = 'MASSMAIL_READY';
export const MASSMAIL_READY_DONE = 'MASSMAIL_READY_DONE';

export const MASSMAIL_BUILDQUEUE = 'MASSMAIL_BUILDQUEUE';
export const MASSMAIL_BUILDQUEUE_DONE = 'MASSMAIL_BUILDQUEUE_DONE';

export const MASSMAIL_CANCEL = 'MASSMAIL_CANCEL';
export const MASSMAIL_CANCEL_DONE = 'MASSMAIL_CANCEL_DONE';

export const MASSMAIL_TEST = 'MASSMAIL_TEST';
export const MASSMAIL_TEST_DONE = 'MASSMAIL_TEST_DONE';

export const MASSMAIL_LIST_FETCH = "MASSMAIL_LIST_FETCH"
export const MASSMAIL_LIST_FETCH_DONE = "MASSMAIL_LIST_FETCH_DONE"
export const MASSMAIL_LIST_SET_PAGING = "MASSMAIL_LIST_SET_PAGING"
export const MASSMAIL_LIST_SET_FILTERS = "MASSMAIL_LIST_SET_FILTERS"

export const MASSMAIL_SEGMENT_LIST_FETCH = "MASSMAIL_SEGMENT_LIST_FETCH"
export const MASSMAIL_SEGMENT_LIST_FETCH_DONE = "MASSMAIL_SEGMENT_LIST_FETCH_DONE"
export const MASSMAIL_SEGMENT_LIST_SET_PAGING = "MASSMAIL_SEGMENT_LIST_SET_PAGING"
export const MASSMAIL_SEGMENT_LIST_SET_FILTERS = "MASSMAIL_SEGMENT_LIST_SET_FILTERS"

const massMailReducer = (state = initState(), action) => {
	switch (action.type) {
		case USER_LOGOUT:
			return {...initState()};
		case COMMON_RESET:
			return {...initState()};
		case MASSMAIL_SEGMENT_FETCH:
			return {...state, massMailSegmentLoading: true};
		case MASSMAIL_SEGMENT_FETCH_DONE:
			return {...state, massMailSegmentLoading: false, massMailSegment: action.segment};
		case MASSMAIL_FETCH:
			return {...state, massMailLoading: true};
		case MASSMAIL_FETCH_DONE:
			return {...state, massMailLoading: false, massMail: action.massMail};
		case MASSMAIL_SEGMENT_UPDATE:
			return {...state, massMailSegmentUpdating: true};
		case MASSMAIL_SEGMENT_UPDATE_DONE:
			if (!action.segment) return {...state, massMailSegmentUpdating: false};
			return {...state, massMailSegmentUpdating: false, massMailSegment: action.segment};
		case MASSMAIL_CREATE:
			return {...state, creating: true};
		case MASSMAIL_CREATE_DONE:
			return {...state, creating: false};
		case MASSMAIL_UPDATE:
			return {...state, updating: true};
		case MASSMAIL_UPDATE_DONE:
			if(!action.massMail) return {...state, updating: false};
			return {...state, updating: false, massMail: action.massMail};
		case MASSMAIL_READY:
			return {...state, ready: true};
		case MASSMAIL_READY_DONE:
			if(!action.massMail) return {...state, ready: false};
			return {...state, ready: false, massMail: action.massMail};
		case MASSMAIL_BUILDQUEUE:
			return {...state, buildingQueue: true};
		case MASSMAIL_BUILDQUEUE_DONE:
			if(!action.massMail) return {...state, buildingQueue: false};
			return {...state, buildingQueue: false, massMail: action.massMail};
		case MASSMAIL_CANCEL:
			return {...state, cancelling: true};
		case MASSMAIL_CANCEL_DONE:
			if(!action.massMail) return {...state, cancelling: false};
			return {...state, cancelling: false, massMail: action.massMail};
		case MASSMAIL_TEST:
			return {...state, testing: true};
		case MASSMAIL_TEST_DONE:
			return {...state, testing: false};
		case MASSMAIL_LIST_FETCH:
			return {...state, massMailsLoading: true};
		case MASSMAIL_LIST_FETCH_DONE:
			return {
				...state,
				massMailsLoading: false,
				massMails: action.massMails,
				massMailsPagingCount: action.paging.count
			};
		case MASSMAIL_LIST_SET_PAGING:
			return {...state, massMailsPaging: action.paging};
		case MASSMAIL_LIST_SET_FILTERS:
			return {...state, massMailsFilters: action.filters};

		case MASSMAIL_SEGMENT_LIST_FETCH:
			return {...state, segmentsLoading: true};
		case MASSMAIL_SEGMENT_LIST_FETCH_DONE:
			return {
				...state,
				segmentsLoading: false,
				segments: action.segments,
				segmentsPagingCount: action.paging.count
			};
		case MASSMAIL_SEGMENT_LIST_SET_PAGING:
			return {...state, segmentsPaging: action.paging};
		case MASSMAIL_SEGMENT_LIST_SET_FILTERS:
			return {...state, segmentsFilters: action.filters};
		default:
			return state;
	}
};

export default massMailReducer;