import React from 'react';
import TableRow from "@material-ui/core/TableRow";
import Skeleton from "@material-ui/lab/Skeleton";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import moment from "moment";
import {getSiteOrderEditRoute} from "../../../../../router/routes/site/factory/order";
import {getSiteUserEditRoute} from "../../../../../router/routes/site/factory/user";
import GoodsTableWrapper from "./GoodsTableWrapper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Link from "../../../typography/Link";

// Sorry about all this crap, doing css pisses me off
const useStyle = makeStyles(theme => {
    return {
        root: {
            '& > *': {
                borderBottom: 'unset',
            },
        },
        selected: {
            backgroundColor: 'rgba(245, 0, 87, 0.08);'
        },
        dark: {
            backgroundColor: theme.palette.action.hover
        }
    }
});


const Row = ({shipping, loading, selected, onSelectionChange, showTracking = true, highlight}) => {
    const displayShipping = (!loading && shipping);
    const classes = useStyle();

    return (
        <>
            <TableRow selected={highlight}
                      className={classes.root + ' print-main-row ' + (highlight ? classes.selected : (!loading ? classes.dark : ''))}>
                <TableCell className='no-print' padding='checkbox'>
                    {!displayShipping && <Skeleton/>}
                    {displayShipping &&
                    <Checkbox
                        color='primary'
                        checked={selected}
                        onChange={e => onSelectionChange(!selected, shipping)}
                        inputProps={{'aria-labelledby': shipping.Item.Catalog_Product['Basic.Name']}}
                    />}
                </TableCell>
                <TableCell>
                    {!displayShipping && <Skeleton/>}
                    {displayShipping && <span><Link
                        to={getSiteOrderEditRoute(shipping.Order.Order__)}>{shipping.Order.Invoice_Number}</Link></span>}
                </TableCell>
                <TableCell>
                    {!displayShipping && <Skeleton/>}
                    {displayShipping &&
                    <span>{shipping.Items.reduce((a, b) => a + (parseInt(b.Item.Quantity) || 0), 0)}</span>}
                </TableCell>
                <TableCell>
                    {!displayShipping && <Skeleton/>}
                    {displayShipping && (shipping.Order.Shipping_User_Location.Display.map((t, i) => <div
                        key={i}>{t}</div>))}
                </TableCell>
                {showTracking && <TableCell>
                    {!displayShipping && <Skeleton/>}
                    {displayShipping && <span>{shipping.Shipping_Code ?? '-'}</span>}
                </TableCell>}

                <TableCell className='no-print'>
                    {!displayShipping && <Skeleton/>}
                    {displayShipping && <span>{moment(parseInt(shipping.Date.unixms)).format('LLL')}</span>}
                </TableCell>
                <TableCell className='no-print'>
                    {!displayShipping && <Skeleton/>}
                    {displayShipping &&
                    <Link
                        to={getSiteUserEditRoute(shipping.Order.User__)}>{shipping.Order.User.Profile.Display_Name}</Link>}
                </TableCell>

            </TableRow>
            {!loading && <GoodsTableWrapper showUngroup={!showTracking} shipping={shipping}/>}
        </>
    );
};

export default Row;