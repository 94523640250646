import React, { Fragment, useEffect }                              from 'react';
import TableHead                                                   from '@material-ui/core/TableHead';
import MaterialTable                                               from '@material-ui/core/Table';
import TableRow                                                    from '@material-ui/core/TableRow';
import TableCell                                                   from '@material-ui/core/TableCell';
import TableBody                                                   from '@material-ui/core/TableBody';
import TableContainer                                              from '@material-ui/core/TableContainer';
import Row                                                         from './Row';
import { connect }                                                 from 'react-redux';
import TableFooter                                                 from '@material-ui/core/TableFooter';
import { useTranslation }                                          from 'react-i18next';
import { fetchTemplates, setTemplatesFilters, setTemplatesPaging } from '../../../../../store/actions/CmsAction';
import Empty                                                       from './Empty';
import Pagination                                                  from '../../../data/table/Pagination';

const Table = ({ cmsId, templates, fetch, mode, setTemplatesFilters, setTemplatesPaging, loading, filters, paging, count, disabled = false, value, setValue }) => {

	const loadingData = [...Array(paging.results_per_page).keys()];

	const displayLoading = () => loadingData.map((k) => <Row key={k} template={null}/>);
	const displayTemplates = () => templates.map((e, idx) => <Row mode={mode} key={e.Content_Cms_Template__}
	                                                              template={e}
	                                                              value={value} setValue={setValue}/>);
	const { t } = useTranslation();

	useEffect(() => {
		if (disabled) return;
		fetch(cmsId);
	}, [disabled, cmsId, fetch, filters, paging]);

	return (
		<Fragment>
			{(loading || templates.length > 0) &&
			<TableContainer>
				<MaterialTable aria-label='simple table'>
					<TableHead>
						<TableRow>
							<Pagination
								setPaging={setTemplatesPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
						<TableRow>
							<TableCell>{t('tablecell_name')}</TableCell>
							<TableCell align='right'></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(loading || disabled) && displayLoading()}
						{(!loading && !disabled) && displayTemplates()}
					</TableBody>
					<TableFooter>
						<TableRow>
							<Pagination
								setPaging={setTemplatesPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
					</TableFooter>

				</MaterialTable>
			</TableContainer>
			}
			{(!loading && templates.length < 1) && <Empty/>}
		</Fragment>
	);
};


const mapStateToProps = (state) => {
	return {
		filters: state.cms.templatesFilters,
		paging: state.cms.templatesPaging,
		count: state.cms.templatesPagingCount,
		loading: state.cms.templatesLoading,
		templates: state.cms.templates
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: (cmsId) => dispatch(fetchTemplates(cmsId)),
		setTemplatesFilters: (filters) => dispatch(setTemplatesFilters(filters)),
		setTemplatesPaging: (paging) => dispatch(setTemplatesPaging(paging))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);

