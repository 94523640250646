import React                  from 'react';
import {useTranslation}       from 'react-i18next';
import {Divider}              from '@material-ui/core';
import SelectRealm            from '../SelectRealm';
import Grid                   from '@material-ui/core/Grid';
import AppBarTitle            from '../../components/common/typography/AppBarTitle';
import HasRight               from '../../components/Route/HasRight';
import Redirect               from '../../components/Route/Redirect';
import {getPXLDashboardRoute} from '../../router/routes/pxl/factory/general';
import FinancialReport        from '../../components/common/report/FinancialReport';
import SalesReport            from '../../components/common/report/SalesReport';
import {connect}              from 'react-redux';

const Report = ({selectedRealm, ready}) => {
	const {t} = useTranslation();

	if (!selectedRealm || !ready) return <SelectRealm/>;

	return (
		<Grid container spacing={3}>
			<HasRight admin deniedComponent={<Redirect target={getPXLDashboardRoute()}/>}>
				<AppBarTitle title={t('menu_site_financial_report')}/>
				<Grid item xs={12}>
					<FinancialReport/>
				</Grid>
				<Grid item xs={12}>
					<Divider/>
				</Grid>
				<Grid item xs={12}>
					<SalesReport/>
				</Grid>
			</HasRight>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		ready: state.common.ready
	};
};

export default connect(mapStateToProps)(Report);
