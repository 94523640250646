import React                          from 'react';
import TableRow                       from '@material-ui/core/TableRow';
import Skeleton                       from '@material-ui/lab/Skeleton';
import TableCell                      from '@material-ui/core/TableCell';
import {connect}                      from 'react-redux';
import moment                         from 'moment';
import {useTranslation}               from 'react-i18next';
import Button                         from '../../inputs/Button';
import Link                           from '../../typography/Link';
import {Edit}                         from '@material-ui/icons';
import {getSiteNotificationEditRoute} from '../../../../router/routes/site/factory/content';

const Row = ({massMail, loading}) => {
	const displayOrder = (!loading && massMail);
	const {t} = useTranslation();

	return (
		<TableRow>
			<TableCell>
				{!displayOrder && <Skeleton width={17}/>}
				{displayOrder && <span>{massMail.Label}</span>}
			</TableCell>
			<TableCell>
				{!displayOrder && <Skeleton/>}
				{displayOrder &&
				<span>{massMail.Scheduled ? moment(parseInt(massMail.Scheduled.unixms)).format('LLL') : '-'}</span>}
			</TableCell>
			<TableCell>
				{!displayOrder && <Skeleton/>}
				{displayOrder && <span>{t('massmail_status_' + massMail.Status)}</span>}
			</TableCell>
			<TableCell align='right'>
				{!displayOrder && <Skeleton/>}
				{
					displayOrder &&
					<Button variant="contained"
					        color="primary"
					        startIcon={<Edit/>}
					        component={Link}
					        to={getSiteNotificationEditRoute(massMail.Network_MassMail__)}
					>
						{t('view_btn')}
					</Button>
				}
			</TableCell>
		</TableRow>
	);
};

const mapStateToProps = (state, {loading, idx}) => {
	if (loading) return {};
	return {
		order: state.order.orders[idx],
	};
};

export default connect(mapStateToProps, null)(Row);