import React, { Fragment } from 'react';
import { getDecimals }     from '../../../../store/actions/system/CurrencyAction';
import { connect }         from 'react-redux';
import { useTranslation }  from 'react-i18next';

function round(value, decimals) {
	return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

const Price = ({ value, currency, inclVat = true, vat = null, priceIncludeVat = false, getDecimals }) => {
	const { t } = useTranslation();
	let inclvat = 0;
	if (vat) {
		inclvat = value + ((value * vat) / 100);
	}

	if(priceIncludeVat) {
	    return <span><strong>{value}</strong> ({t('incl_vat')})</span>
    }

	return <Fragment>
		<span><strong>{value}</strong> ({t('excl_vat')})</span><br/>
		{(vat && inclVat) && <span>
                <strong>
                {round(inclvat, getDecimals(currency))}
                </strong> ({t('incl_vat')})
            </span>}
	</Fragment>;
};

const mapDispatchToProps = (dispatch) => {
	return {
		getDecimals: (currency) => dispatch(getDecimals(currency))
	};
};

export default connect(null, mapDispatchToProps)(Price);
