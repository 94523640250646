import React, { useEffect, useState } from 'react';
import { Grid }                       from '@material-ui/core';
import { useTranslation }             from 'react-i18next';
import Button                         from '../inputs/Button';
import { connect }                    from 'react-redux';
import {deepCopy}                     from '../../utils/misc';
import FormData                       from '../user/location/FormData';
import { setSiteLocation }            from '../../../store/actions/TemplateAction';
import FormRequiredField              from '../feeback/FormRequiredField';

const AddressForm = ({ settingId, address, locationUpdate, locationCreate, updating, setSiteLocation }) => {
	const [copiedAddress, setCopiedAddress] = useState(deepCopy(address ?? {})); // we copy the address to detect changes and enabled the save btn in edit mode

	const [isValid, setIsValid] = useState(false);
	const [saveEnabled, setSaveEnabled] = useState(false);
	const { t } = useTranslation();
	const [loading, setLoading] = useState(locationUpdate || locationCreate || updating);

	useEffect(() => {
		setLoading(locationCreate || locationUpdate || updating);
	}, [locationUpdate, locationCreate, updating, setLoading]);

	useEffect(() => {
		setCopiedAddress(deepCopy(address ?? {}));
	}, [setCopiedAddress, address]);

	useEffect(() => {
		if (!isValid) {
			setSaveEnabled(false);
			return;
		}

		if (!(copiedAddress['User_Location__'] ?? false)) { // create mode
			// we are valid (above check, so we can enable the btn
			setSaveEnabled(true);
			return;
		}

		// detect changes
		const hasChanged = k => {
			if (k in copiedAddress && !(k in (address ?? {}))) return true;
			if (!(k in copiedAddress) && k in (address ?? {})) return true;

			return (copiedAddress[k] ?? '').trim() !== (address[k] ?? '').trim();
		};

		setSaveEnabled(
			hasChanged('First_Name') ||
			hasChanged('Last_Name') ||
			hasChanged('Zip') ||
			hasChanged('Province') ||
			hasChanged('City') ||
			address['Country'].Country__ !== (('Country' in copiedAddress && copiedAddress['Country']) ? copiedAddress['Country'].Country__ : copiedAddress.Country__) ||
			hasChanged('Contact_Phone') ||
			hasChanged('Address') ||
			hasChanged('Address2') ||
			hasChanged('Company_Name') ||
			hasChanged('Company_European_Vat_Number') ||
			hasChanged('Company_Department'));

	}, [setSaveEnabled, isValid, copiedAddress, address]);

	const handleSave = () => {
		const tmp = { ...copiedAddress };
		tmp['Country__'] = copiedAddress.Country.Country__;
		if('Company_European_Vat_Number' in tmp && !(tmp.Company_European_Vat_Number ?? '').trim())
			delete  tmp.Company_European_Vat_Number;

		setSiteLocation(settingId, tmp, copiedAddress['User_Location__'] ?? null);
	};

	return (
		<Grid container spacing={3}>
			<FormRequiredField/>
			<Grid item xs={12}>
				<FormData
					disabled={loading}
					address={copiedAddress}
					setAddress={setCopiedAddress}
					setIsValid={setIsValid}
				/>
			</Grid>
			<Grid item xs={12}>
				<Grid container justify='flex-end'>

					<Button
						color='primary'
						variant='contained'
						loading={loading}
						onClick={handleSave}
						disabled={!saveEnabled}>
						{t('save_btn')}
					</Button>

				</Grid>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		locationUpdate: state.userLocation.updating,
		locationCreate: state.userLocation.creating,
		updating: state.template.updatingTemplate,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setSiteLocation: (settingId, data, locationId) => dispatch(setSiteLocation(settingId, data, locationId))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressForm);
