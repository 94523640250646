import React, {useEffect, useState} from 'react';
import {Grid} from "@material-ui/core";
import AvatarEditor from "../../avatar-editor/AvatarEditor";
import {useTranslation} from "react-i18next";
import {connect} from 'react-redux'
import {updateAvatar} from "../../../store/actions/system/UserActions";
import Button from "../inputs/Button";


const Picture = ({user, changingAvatar, updateAvatar}) => {
    const {t} = useTranslation();
    const [avatarSaveBtnEnabled, setAvatarSaveBtnEnabled] = useState(false);

    const initialAvatar = user.Profile.Media_Image ? user.Profile.Media_Image.Url : null;
    const [avatarImage, setAvatarImage] = useState(initialAvatar);

    useEffect(() => {

        if (initialAvatar !== avatarImage) {
            setAvatarSaveBtnEnabled(true);
            return
        }
        setAvatarSaveBtnEnabled(false)

    }, [avatarImage, initialAvatar]);

    const handleSave = (e) => {
        if (!avatarImage || avatarImage === initialAvatar) return;
        updateAvatar(avatarImage);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <AvatarEditor defaultAvatar={initialAvatar} field='profile'
                              setAvatarImage={(n, v) => setAvatarImage(v)} fieldId='superId'/>
                <Button fullWidth color='primary' variant='outlined' loading={changingAvatar} disabled={!avatarSaveBtnEnabled || changingAvatar}
                        onClick={handleSave}>
                    {t('save_btn')}
                </Button>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        changingAvatar: state.user.changingAvatar
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        // Meh refactor this stuff
        updateAvatar: (file) => dispatch(updateAvatar([{
            file: file,
            target: "User/@/Profile:addImage",
            param: {purpose: "main"}
        }]))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Picture);
