import React, {useEffect, useState} from 'react';
import { Grid, TextField} from "@material-ui/core";
import {connect} from "react-redux";
import RequestPasswordDialog from "./RequestPasswordDialog";
import {useTranslation} from "react-i18next";
import {setEmail} from "../../../store/actions/system/UserActions";
import Button from "../inputs/Button";

const ChangeEmail = ({user, changingInfo, setEmailApi}) => {
    const {t} = useTranslation();

    const [requestPwdOpen, setRequestPwdOpen] = useState(false);
    const [email, setEmail] = useState(user.Email);
    const [saveEnabled, setSaveEnabled] = useState(false);

    useEffect(() => {
        if (email !== user.Email) {
            setSaveEnabled(true);
            return
        }
        setSaveEnabled(false)
    }, [email, user]);

    const handleChangeEmail = (pwd) => {
        setEmailApi(email, pwd);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    disabled={changingInfo}
                    fullWidth label={t('profile_email')}
                    value={email}
                    type='email'
                    onChange={(e) => setEmail(e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container justify='flex-end'>

                    <Button color='primary' variant='contained' loading={changingInfo}
                            disabled={!saveEnabled || changingInfo} onClick={() => {
                        if (user.Has_Password)
                            setRequestPwdOpen(true);
                        else
                            handleChangeEmail(null)
                    }}>
                        {t('save_btn')}
                    </Button>

                </Grid>
            </Grid>
            {user.Has_Password && <RequestPasswordDialog open={requestPwdOpen} setOpen={setRequestPwdOpen}
                                                         closeCallback={handleChangeEmail}/>}
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        changingInfo: state.user.changingInfo,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setEmailApi: (email, password) => dispatch(setEmail(email, password))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeEmail);
