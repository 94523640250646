import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {connect} from 'react-redux'
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from 'react-i18next';
import Alert from "@material-ui/lab/Alert";
import Button from "../../inputs/Button";
import {productSetSale, unlinkGood} from "../../../../store/actions/store/ProductAction";


const DeleteGoodDialog = ({productId, good, open, setOpen, deleting, unlinkGood, removeFromSale}) => {
    const {t} = useTranslation();

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        unlinkGood(productId, good.Catalog_Deliverable__)
            .then(d => {
                if (d.deliverables.length < 1)
                    return removeFromSale(productId).then(handleClose()); // We deleted the last deliverable force the product out of sales
                else
                    handleClose()
            })
    };

    return (
        <Dialog
            open={open}
            aria-labelledby={t('confirm_delete_product_good_title')}
            aria-describedby={t('confirm_delete_product_good_title')}
            fullWidth
        >
            <DialogTitle id="product-deletegood-title">{t('confirm_delete_product_good_title')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="product-deletegood-desc">
                    <Alert severity='warning'>
                        {t('confirm_delete_product_good_desc')}
                    </Alert>
                    <br/>
                    <Typography variant="body1" color="textPrimary" component="span">{t('are_you_sure')}</Typography>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button variant='contained' color="primary" autoFocus onClick={handleClose} disabled={deleting}>
                    {t('back_btn')}
                </Button>
                <Button color="secondary" loading={deleting} disabled={deleting} endIcon={<DeleteIcon/>}
                        onClick={handleDelete}>
                    {t('unlink_btn')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {
        deleting: state.product.deleteGood
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        unlinkGood: (id, gid) => dispatch(unlinkGood(id, gid)),
        removeFromSale: (id) => dispatch(productSetSale(id, false, true)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteGoodDialog);