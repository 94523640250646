import {COMMON_RESET} from './CommonReducer';

const initState = () => {
	return {
		commentsStats: 0,

		commentsLoading: true,
		commentsFilters: {
			Patrol: 'pending',
			sort: {Report: 'DESC'}
		},
		comments: [],
		commentsPagingCount: 0,
		commentsPaging: {
			page_no: 1,
			results_per_page: 10
		},

		commentDeleting: false,
		commentPatrolling: false,
	};
};

export const SOCIAL_COMMENTS_FETCH = 'SOCIAL_COMMENTS_FETCH';
export const SOCIAL_COMMENTS_FETCH_DONE = 'SOCIAL_COMMENTS_FETCH_DONE';
export const SOCIAL_COMMENTS_SET_FILTERS = 'SOCIAL_COMMENTS_SET_FILTERS';
export const SOCIAL_COMMENTS_SET_PAGING = 'SOCIAL_COMMENTS_SET_PAGING';
export const SOCIAL_COMMENTS_STATS_FETCH_DONE = 'SOCIAL_COMMENTS_STATS_FETCH_DONE';

export const SOCIAL_COMMENT_DELETE = 'SOCIAL_COMMENT_DELETE';
export const SOCIAL_COMMENT_DELETE_DONE = 'SOCIAL_COMMENT_DELETE_DONE';

export const SOCIAL_COMMENT_PATROL = 'SOCIAL_COMMENT_PATROL';
export const SOCIAL_COMMENT_PATROL_DONE = 'SOCIAL_COMMENT_PATROL_DONE';

const socialReducer = (state = initState(), action) => {
	switch (action.type) {
		case COMMON_RESET:
			return {...initState()};
		case SOCIAL_COMMENTS_STATS_FETCH_DONE:
			return {...state, commentsStats: action.count};
		case SOCIAL_COMMENT_DELETE:
			return {...state, commentDeleting: true};
		case SOCIAL_COMMENT_DELETE_DONE:
			return {...state, commentDeleting: false};
		case SOCIAL_COMMENT_PATROL:
			return {...state, commentPatrolling: true};
		case SOCIAL_COMMENT_PATROL_DONE:
			return {...state, commentPatrolling: false};
		case SOCIAL_COMMENTS_SET_PAGING:
			return {...state, commentsPaging: action.paging};
		case SOCIAL_COMMENTS_SET_FILTERS:
			return {...state, commentsFilters: action.filters};
		case SOCIAL_COMMENTS_FETCH:
			return {...state, commentsLoading: true};
		case SOCIAL_COMMENTS_FETCH_DONE:
			return {
				...state,
				commentsLoading: false,
				comments: action.comments,
				commentsPagingCount: action.paging.count
			};
		default:
			return state;
	}
};

export default socialReducer;