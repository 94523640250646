import { USER_LOGOUT }  from './system/UserReducer';
import { COMMON_RESET } from './CommonReducer';

const initState = () => {
	return {
		loadingArtist: true,
		artist: null,
		updatingArtist: false,
		uploadingArtistImage: false,
		uploadingMemberImage: false,

		loadingImages: false,
		deletingImage: false,
		settingMainImage: false,
		images: [],
		uploadImgCount: 0,
		uploadImgDoneCount: 0,
		uploadImgRunningCount: 0,
		uploadImgRunningPercent: 0,

		members: [],
		loadingMembers: false,

		member: null,
		memberLoading: false,
		memberUpdating: false,
		memberCreating: false,

		memberDeleting: false,

		artists: [],
		artistsLoading: false,

		artistMinimalCreating: false,
	};
};


export const ARTIST_GALLERY_IMG_VARIATION = 'strip&scale_crop=240x180&format=jpeg';

export const ARTIST_FETCH = 'ARTIST_FETCH';
export const ARTIST_FETCH_DONE = 'ARTIST_FETCH_DONE';

export const ARTIST_SEARCH_FETCH = 'ARTIST_SEARCH_FETCH';
export const ARTIST_SEARCH_FETCH_DONE = 'ARTIST_SEARCH_FETCH_DONE';

export const ARTIST_CREATE_MINIMAL = 'ARTIST_CREATE_MINIMAL';
export const ARTIST_CREATE_MINIMAL_DONE = 'ARTIST_CREATE_MINIMAL_DONE';

export const ARTIST_UPDATE = 'ARTIST_UPDATE';
export const ARTIST_UPDATE_DONE = 'ARTIST_UPDATE_DONE';

export const ARTIST_UPLOAD_IMAGE = 'ARTIST_UPLOAD_IMAGE';
export const ARTIST_UPLOAD_IMAGE_DONE = 'ARTIST_UPLOAD_IMAGE_DONE';

export const ARTIST_MEMBER_UPLOAD_IMAGE = 'ARTIST_MEMBER_UPLOAD_IMAGE';
export const ARTIST_MEMBER_UPLOAD_IMAGE_DONE = 'ARTIST_MEMBER_UPLOAD_IMAGE_DONE';

export const ARTIST_MEMBERS_FETCH = 'ARTIST_MEMBERS_FETCH';
export const ARTIST_MEMBERS_FETCH_DONE = 'ARTIST_MEMBERS_FETCH_DONE';

export const ARTIST_MEMBER_FETCH = 'ARTIST_MEMBER_FETCH';
export const ARTIST_MEMBER_FETCH_DONE = 'ARTIST_MEMBER_FETCH_DONE';

export const ARTIST_MEMBER_DELETE = 'ARTIST_MEMBER_DELETE';
export const ARTIST_MEMBER_DELETE_DONE = 'ARTIST_MEMBER_DELETE_DONE';

export const ARTIST_MEMBER_UPDATE = 'ARTIST_MEMBER_UPDATE';
export const ARTIST_MEMBER_UPDATE_DONE = 'ARTIST_MEMBER_UPDATE_DONE';

export const ARTIST_MEMBER_CREATE = 'ARTIST_MEMBER_CREATE';
export const ARTIST_MEMBER_CREATE_DONE = 'ARTIST_MEMBER_CREATE_DONE';

export const ARTIST_GALLERY_FETCH = 'ARTIST_GALLERY_FETCH';
export const ARTIST_GALLERY_FETCH_DONE = 'ARTIST_GALLERY_FETCH_DONE';

export const ARTIST_GALLERY_UPLOAD_IMG = 'ARTIST_GALLERY_UPLOAD_IMG';
export const ARTIST_GALLERY_UPLOAD_IMG_DONE = 'ARTIST_GALLERY_UPLOAD_IMG_DONE';
export const ARTIST_GALLERY_UPLOAD_IMG_RUNNING = 'ARTIST_GALLERY_UPLOAD_IMG_RUNNING';

export const ARTIST_GALLERY_DELETE_IMG = 'ARTIST_GALLERY_DELETE_IMG';
export const ARTIST_GALLERY_DELETE_IMG_DONE = 'ARTIST_GALLERY_DELETE_IMG_DONE';

export const ARTIST_SET_MAIN_IMAGE = 'ARTIST_SET_MAIN_IMAGE';
export const ARTIST_SET_MAIN_IMAGE_DONE = 'ARTIST_SET_MAIN_IMAGE_DONE';

const artistReducer = (state = initState(), action) => {
	switch (action.type) {
		case USER_LOGOUT:
		case COMMON_RESET:
			return initState();
		case ARTIST_CREATE_MINIMAL:
			return { ...state, artistMinimalCreating: true };
		case ARTIST_CREATE_MINIMAL_DONE:
			return { ...state, artistMinimalCreating: false };
		case ARTIST_SEARCH_FETCH:
			return { ...state, artistsLoading: true };
		case ARTIST_SEARCH_FETCH_DONE:
			return { ...state, artistsLoading: false, artists: action.artists };
		case ARTIST_MEMBER_CREATE:
			return { ...state, memberCreating: true };
		case ARTIST_MEMBER_CREATE_DONE:
			return { ...state, memberCreating: false };
		case ARTIST_MEMBER_UPDATE:
			return { ...state, memberUpdating: true };
		case ARTIST_MEMBER_UPDATE_DONE:
			return { ...state, memberUpdating: false, member: action.member };
		case ARTIST_MEMBER_DELETE:
			return { ...state, memberDeleting: true };
		case ARTIST_MEMBER_DELETE_DONE:
			return { ...state, memberDeleting: false };
		case ARTIST_MEMBER_FETCH:
			return { ...state, memberLoading: true };
		case ARTIST_MEMBER_FETCH_DONE:
			return { ...state, memberLoading: false, member: action.member };
		case ARTIST_MEMBERS_FETCH:
			return { ...state, loadingMembers: true };
		case ARTIST_MEMBERS_FETCH_DONE:
			return { ...state, loadingMembers: false, members: action.members };
		case ARTIST_MEMBER_UPLOAD_IMAGE:
			return { ...state, uploadingMemberImage: true };
		case ARTIST_MEMBER_UPLOAD_IMAGE_DONE:
			return { ...state, uploadingMemberImage: false };
		case ARTIST_UPLOAD_IMAGE:
			return { ...state, uploadingArtistImage: true };
		case ARTIST_UPLOAD_IMAGE_DONE:
			return { ...state, uploadingArtistImage: false };
		case ARTIST_UPDATE:
			return { ...state, updatingArtist: true };
		case ARTIST_UPDATE_DONE:
			return { ...state, updatingArtist: false, artist: action.artist };
		case ARTIST_FETCH:
			return { ...state, loadingArtist: true };
		case ARTIST_FETCH_DONE:
			return { ...state, loadingArtist: false, artist: action.artist };
		case ARTIST_GALLERY_UPLOAD_IMG:
			return { ...state, uploadImgCount: state.uploadImgCount + action.count };
		case ARTIST_GALLERY_UPLOAD_IMG_RUNNING:
			return { ...state, uploadImgRunningCount: action.count, uploadImgRunningPercent: action.ratio };
		case ARTIST_GALLERY_UPLOAD_IMG_DONE:
			return {
				...state,
				uploadImgDoneCount: state.uploadImgDoneCount + 1,
				images: [...state.images, action.img]
			};
		case ARTIST_GALLERY_FETCH:
			return { ...state, loadingImages: true };
		case ARTIST_GALLERY_FETCH_DONE:
			return { ...state, loadingImages: false, images: action.images };
		case ARTIST_GALLERY_DELETE_IMG:
			return { ...state, deletingImage: true };
		case ARTIST_GALLERY_DELETE_IMG_DONE:
			if (action.artist) {
				const newState = { ...state, deletingImage: false };
				newState.images = state.images.filter(i => i.Drive_Item__ !== action.driveItemId);
				if (action.artist.Image_Drive_Item__ !== state.artist.Image_Drive_Item__) {
					newState.artist = action.artist;
				}

				return newState;
			}
			return { ...state, deletingImage: false };
		case ARTIST_SET_MAIN_IMAGE:
			return { ...state, settingMainImage: true };
		case ARTIST_SET_MAIN_IMAGE_DONE:
			if (!action.artist) return { ...state, settingMainImage: false };
			return { ...state, settingMainImage: false, artist: action.artist };
		default:
			return state;
	}

};

export default artistReducer;
