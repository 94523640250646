import {rest}        from '@karpeleslab/klbfw';
import {handleError} from '../../components/utils/apiErrorHandler';
import {
	MASSMAIL_BUILDQUEUE,
	MASSMAIL_BUILDQUEUE_DONE, MASSMAIL_CANCEL, MASSMAIL_CANCEL_DONE,
	MASSMAIL_CREATE,
	MASSMAIL_CREATE_DONE,
	MASSMAIL_FETCH,
	MASSMAIL_FETCH_DONE,
	MASSMAIL_LIST_FETCH,
	MASSMAIL_LIST_FETCH_DONE,
	MASSMAIL_LIST_SET_FILTERS,
	MASSMAIL_LIST_SET_PAGING,
	MASSMAIL_READY,
	MASSMAIL_READY_DONE,
	MASSMAIL_SEGMENT_FETCH,
	MASSMAIL_SEGMENT_FETCH_DONE,
	MASSMAIL_SEGMENT_LIST_FETCH,
	MASSMAIL_SEGMENT_LIST_FETCH_DONE,
	MASSMAIL_SEGMENT_LIST_SET_FILTERS, MASSMAIL_SEGMENT_LIST_SET_PAGING,
	MASSMAIL_SEGMENT_UPDATE,
	MASSMAIL_SEGMENT_UPDATE_DONE, MASSMAIL_TEST, MASSMAIL_TEST_DONE,
	MASSMAIL_UPDATE,
	MASSMAIL_UPDATE_DONE
} from '../reducers/MassMailReducer';
import {success}     from './system/ToastAction';

export const fetchMassMailSegment = (id) => {
	return (dispatch, getState) => {
		dispatch({type: MASSMAIL_SEGMENT_FETCH});
		return rest('Network/MassMail/Segment/' + id)
			.then((d) => {
				dispatch({type: MASSMAIL_SEGMENT_FETCH_DONE, segment: d.data});

				return d.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const fetchMassMail = (id) => {
	return (dispatch, getState) => {
		dispatch({type: MASSMAIL_FETCH});
		return rest('Network/MassMail/' + id)
			.then((d) => {
				dispatch({type: MASSMAIL_FETCH_DONE, massMail: d.data});

				return d.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const updateMassMailSegment = (id, data) => {
	return (dispatch, getState) => {
		dispatch({type: MASSMAIL_SEGMENT_UPDATE});
		return rest('Network/MassMail/Segment/' + id, 'PATCH', data)
			.then(d => {
				dispatch({type: MASSMAIL_SEGMENT_UPDATE_DONE, segment: d.data});
				success('massmail_segment_updated');
				return d.data;
			}).catch((err) => {
				dispatch({type: MASSMAIL_SEGMENT_UPDATE_DONE});
				handleError(getState, dispatch, err);
			});
	};
};

export const createMassMail = (realmId, segmentId, label, schedule, subject, body) => {
	return (dispatch, getState) => {
		dispatch({type: MASSMAIL_CREATE});
		const data = {
			'Network_MassMail_Segment__': segmentId,
			'Scheduled': schedule,
			'Label': label,
			'Subject': subject,
			'Body': body,
			'Realm__': realmId,
		};

		return rest('Network/MassMail', 'POST', data)
			.then(d => {
				dispatch({type: MASSMAIL_CREATE_DONE, massMail: d.data});
				success('massmail_created');
				return d.data;
			}).catch((err) => {
				dispatch({type: MASSMAIL_CREATE_DONE});
				handleError(getState, dispatch, err);
			});
	};
};

export const massMailSetReady = (massMailId) => {
	return (dispatch, getState) => {
		dispatch({type: MASSMAIL_READY});

		return rest('Network/MassMail/' + massMailId + ':ready', 'POST')
			.then(d => {
				dispatch({type: MASSMAIL_READY_DONE, massMail: d.data});
				success('massmail_ready');
				return d.data;
			}).catch((err) => {
				dispatch({type: MASSMAIL_READY_DONE});
				handleError(getState, dispatch, err);
			});
	};
};

export const massMailBuildQueue = (massMailId) => {
	return (dispatch, getState) => {
		dispatch({type: MASSMAIL_BUILDQUEUE});

		return rest('Network/MassMail/' + massMailId + ':buildQueue', 'POST')
			.then(d => {
				dispatch({type: MASSMAIL_BUILDQUEUE_DONE, massMail: d.data});
				return d.data;
			}).catch((err) => {
				dispatch({type: MASSMAIL_BUILDQUEUE_DONE});
				handleError(getState, dispatch, err);
			});
	};
};

export const massMailCancel = (massMailId) => {
	return (dispatch, getState) => {
		dispatch({type: MASSMAIL_CANCEL});

		return rest('Network/MassMail/' + massMailId + ':cancel', 'POST')
			.then(d => {
				dispatch({type: MASSMAIL_CANCEL_DONE, massMail: d.data});
				success('massmail_cancelled');
				return d.data;
			}).catch((err) => {
				dispatch({type: MASSMAIL_CANCEL_DONE});
				handleError(getState, dispatch, err);
			});
	};
};

export const massMailTest = (massMailId) => {
	return (dispatch, getState) => {
		dispatch({type: MASSMAIL_TEST});

		return rest('Network/MassMail/' + massMailId + ':test', 'POST')
			.then(d => {
				dispatch({type: MASSMAIL_TEST_DONE, massMail: d.data});
				success('massmail_tested');
				return d.data;
			}).catch((err) => {
				dispatch({type: MASSMAIL_TEST_DONE});
				handleError(getState, dispatch, err);
			});
	};
};



export const updatedMassMail = (massMailId, label, schedule, subject, body) => {
	return (dispatch, getState) => {
		dispatch({type: MASSMAIL_UPDATE});
		const data = {
			'Scheduled': schedule,
			'Label': label,
			'Subject': subject,
			'Body': body,
		};

		return rest('Network/MassMail/' + massMailId, 'PATCH', data)
			.then(d => {
				dispatch({type: MASSMAIL_UPDATE_DONE, massMail: d.data});
				success('massmail_updated');
				return d.data;
			}).catch((err) => {
				dispatch({type: MASSMAIL_UPDATE_DONE});
				handleError(getState, dispatch, err);
			});
	};
};


export const fetchMassMails = (realmId) => {
	return (dispatch, getState) => {
		dispatch({type: MASSMAIL_LIST_FETCH});

		const params = {
			Realm__: realmId,
			...getState().massMail.massMailsFilters,
			...getState().massMail.massMailsPaging
		};

		return rest('Network/MassMail', 'GET', params)
			.then(data => {
				dispatch({type: MASSMAIL_LIST_FETCH_DONE, massMails: data.data, paging: data.paging});
				return data.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const setMassMailsFilters = (filters) => {
	return (dispatch, getState) => {
		dispatch({type: MASSMAIL_LIST_SET_FILTERS, filters: filters});
	};
};

export const setMassMailsPaging = (paging) => {
	return (dispatch, getState) => {
		dispatch({type: MASSMAIL_LIST_SET_PAGING, paging: {...getState().massMail.massMailsPaging, ...paging}});
	};
};





export const fetchSegments = (realmId) => {
	return (dispatch, getState) => {
		dispatch({type: MASSMAIL_SEGMENT_LIST_FETCH});

		const params = {
			Realm__: realmId,
			...getState().massMail.segmentsFilters,
			...getState().massMail.segmentsPaging
		};

		return rest('Network/MassMail/Segment', 'GET', params)
			.then(data => {
				dispatch({type: MASSMAIL_SEGMENT_LIST_FETCH_DONE, segments: data.data, paging: data.paging});
				return data.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const setSegmentsFilters = (filters) => {
	return (dispatch, getState) => {
		dispatch({type: MASSMAIL_SEGMENT_LIST_SET_FILTERS, filters: filters});
	};
};

export const setSegmentsPaging = (paging) => {
	return (dispatch, getState) => {
		dispatch({type: MASSMAIL_SEGMENT_LIST_SET_PAGING, paging: {...getState().massMail.segmentsPaging, ...paging}});
	};
};