import React, {useState} from 'react';
import Grid              from '@material-ui/core/Grid';
import AppBarTitle       from '../../../components/common/typography/AppBarTitle';
import {useTranslation}  from 'react-i18next';
import {connect}         from 'react-redux';
import SelectRealm       from '../../SelectRealm';
import AddIcon           from '@material-ui/icons/Add';
import Table             from '../../../components/common/music/discography/table/Table';
import CreateDialog      from '../../../components/common/music/discography/CreateDialog';
import HasRight          from '../../../components/Route/HasRight';
import Toolbar           from '../../../components/common/typography/Toolbar';
import Button            from '../../../components/common/inputs/Button';
import ReleasesActivator from '../../../components/common/music/discography/ReleasesActivator';
import Filters           from '../../../components/common/music/discography/table/Filters';

const List = ({artist, selectedRealm}) => {
	const {t} = useTranslation();
	const [createDialogOpened, setCreateDialogOpened] = useState(false);

	if (!selectedRealm || !artist) return <SelectRealm/>;

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('discography_title')}/>
			<Grid item xs={12}>
				<Toolbar title={t('discography_title')}>
					<HasRight manager>
						<Button endIcon={<AddIcon/>} onClick={e => setCreateDialogOpened(true)} variant="contained"
						        color="primary">
							{t('create_btn')}
						</Button>
					</HasRight>
				</Toolbar>
			</Grid>

			<ReleasesActivator/>
			<Grid item xs={12}>
				<Filters/>
			</Grid>
			<Grid item xs={12}>
				<Table/>
			</Grid>
			<CreateDialog open={createDialogOpened} setOpen={setCreateDialogOpened}/>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		artist: state.artist.artist,
	};
};
export default connect(mapStateToProps)(List);
