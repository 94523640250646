import React, {Fragment} from 'react';
import moment from "moment";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux'
import {getDecimals} from "../../../../../../store/actions/system/CurrencyAction";

const useStyles = makeStyles(theme => ({
    past: {
        color: theme.palette.text.disabled
    },
    current: {
        color: theme.palette.primary.main
    },
    future: {
        color: theme.palette.text.secondary
    },
}));


const Row = ({price, showOldPrices, vat, currencies, getDecimals}) => {
    const classes = useStyles();
    const now = moment().unix();
    const oldPrice = price.End_Date && (now > price.End_Date.unix);
    const {t} = useTranslation();


    if (oldPrice && !showOldPrices)
        return null;

    const futurePrice = price.Start_Date.unix > now;
    const current = !futurePrice && !oldPrice;

    let cname = '';
    if (current) cname = classes.current;
    if (oldPrice) cname = classes.past;
    if (futurePrice) cname = classes.future;

    function round(value, decimals) {
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
    }

    const Price = ({value, currency, inclVat = true, priceIsVatIncluded = false}) => {
        let inclvat = 0;
        if (vat) {
            inclvat = value + ((value * vat) / 100);
        }

        if (priceIsVatIncluded)
            return <span><strong>{value}</strong> ({t('incl_vat')})</span>

        return <Fragment>
            <span><strong>{value}</strong> ({t('excl_vat')})</span><br/>
            {(vat && inclVat) && <span>
                <strong>
                {round(inclvat, getDecimals(currency))}
                </strong> ({t('incl_vat')})
            </span>}
        </Fragment>
    };

    const DiscountPriceDisplay = () =>
        <Fragment>
            <s><Price value={parseFloat(price.Public_Price)} currency={price.Currency__} inclVat={false} priceIsVatIncluded={!!(price.Flags ?? {}).vat_included}/></s> <br/>
            Discount: <br/>
            <Price value={parseFloat(price.Price)} currency={price.Currency__} priceIsVatIncluded={!!(price.Flags ?? {}).vat_included}/>
        </Fragment>;

    return <TableRow>
        <TableCell className={cname}>
            {price.Discount === 'Y' && <DiscountPriceDisplay/>}
            {price.Discount === 'N' && <Price value={parseFloat(price.Price)} currency={price.Currency__} priceIsVatIncluded={!!(price.Flags ?? {}).vat_included}/>}

        </TableCell>
        <TableCell className={cname}>
            {price.Currency__}
        </TableCell>
        <TableCell colSpan={2} className={cname}>
            {futurePrice && <span>{t('will_start_at')}: </span>}
            {!futurePrice && <span>{t('started_at')}: </span>}

            {moment(parseInt(price.Start_Date.unixms)).format('LLL')}

            {price.End_Date &&
            <Fragment>
                <br/><br/>
                {oldPrice && <span>{t('ended_at')}: </span>}
                {!oldPrice && <span>{t('will_end_at')}: </span>}
                {moment(parseInt(price.End_Date.unixms)).format('LLL')}
            </Fragment>
            }
        </TableCell>
    </TableRow>
};

const mapStateToProps = (state) => {
    return {
        currencies: state.currency.currencies, // we use this to watch the chances on the currencies, so a refresh will be triggers when number of decimals will be available
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDecimals: (currency) => dispatch(getDecimals(currency))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Row);
