import React                      from 'react';
import {useTranslation}           from 'react-i18next';
import Grid                       from '@material-ui/core/Grid';
import AppBarTitle                from '../../components/common/typography/AppBarTitle';
import Toolbar                    from '../../components/common/typography/Toolbar';
import Loading                    from '../../components/common/feeback/loading/Loading';
import {useUserBilling}           from '../../hooks/api/user/useUserBilling';
import UpdateBillingWrapper       from './UpdateBillingWrapper';
import Redirect                   from '../../components/Route/Redirect';
import {getPXLBillingCreateRoute} from '../../router/routes/pxl/factory/order';
import {useParams}                from 'react-router-dom';

const Billing = () => {
	const {t} = useTranslation();
	const {billingId} = useParams();
	const [billing, refresh] = useUserBilling(billingId);


	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('billings_title')}/>

			<Grid item xs={12}>
				<Toolbar title={t('billings_title')}/>
			</Grid>

			<Grid item xs={12}>
				{!billing && <Loading/>}
				{(billing && !billing.data) && <Redirect target={getPXLBillingCreateRoute()}/>}
				{(billing && billing.data) && <UpdateBillingWrapper setBilling={refresh} billing={billing.data}/>}
			</Grid>

		</Grid>
	);
};


export default Billing;