import React, {useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import {useTranslation} from "react-i18next";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import {connect} from 'react-redux'
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import {useHistory} from "react-router-dom";
import {getSiteEventEditRoute} from "../../../../../router/routes/site/factory/planner";
import Button from "../../../inputs/Button";
import {markAsUsed} from "../../../../../store/actions/planner/ReserveAction";

const TerminateLiveDialog = ({event, open, setOpen, marking, markAsUsed}) => {
    const {t} = useTranslation();
    const history = useHistory();

    const [checked, setChecked] = useState({});

    useEffect(() => {
        setChecked(JSON.parse(localStorage.getItem('live-' + event.Planner_Event__) ?? '{}'));
    }, [open, event]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleTerminate = (e) => {
        e.preventDefault();
        markAsUsed(event.Planner_Event__, Object.keys(checked))
            .then(d => {
                localStorage.removeItem('live-' + event.Planner_Event__);
                history.push(getSiteEventEditRoute(event.Planner_Event__))
            })

    };

    return (
        <Dialog
            open={open}
            aria-labelledby={t('terminate_live')}
            aria-describedby={t('terminate_live')}
        >

            <DialogTitle id="terminate-live-title">{t('terminate_live')}</DialogTitle>
            <DialogContent>
                <Alert severity='info'>
                    {t('reserve_validate_pending', {count: Object.keys(checked).length})}
                </Alert>
                <br/>
                <DialogContentText id="terminate-live-desc">
                    {t('terminate_live_desc')}
                    <br/>
                    <Typography variant="body1" color="textPrimary" component="span">{t('are_you_sure')}</Typography>
                </DialogContentText>


            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={handleClose} disabled={marking}>
                    {t('close_btn')}
                </Button>
                <Button color="primary" type='submit' variant='contained' onClick={handleTerminate}
                        disabled={marking}
                        loading={marking}>
                    {t('live_terminate_btn')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {
        marking: state.reserve.markingAsUsed,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        markAsUsed: (eventId, reserveIds) => dispatch(markAsUsed(eventId, reserveIds))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TerminateLiveDialog);