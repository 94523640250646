import RecordsList   from '../../../pages/music/record/List';
import RecordsCreate from '../../../pages/music/record/Create';
import RecordsEdit   from '../../../pages/music/record/Edit';
import {
	getMusicRecordCreateRoute,
	getMusicRecordEditRoute,
	getMusicRecordLyricsRoute,
	getMusicRecordsRoute
}                    from './factory/records';
import Lyrics        from '../../../pages/music/record/Lyrics';

export default [
	{
		path: getMusicRecordsRoute(),
		component: RecordsList,
		exact: true,
		type: 'route',
		loginRequired: true,
	},
	{
		path: getMusicRecordCreateRoute(),
		component: RecordsCreate,
		exact: true,
		type: 'route',
		loginRequired: true,
	},
	{
		path: getMusicRecordLyricsRoute(),
		component: Lyrics,
		exact: false,
		type: 'route',
		loginRequired: true,
	},
	{
		path: getMusicRecordEditRoute(),
		component: RecordsEdit,
		exact: false,
		type: 'route',
		loginRequired: true,
	},
];
