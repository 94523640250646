import ListItemIcon                 from '@material-ui/core/ListItemIcon';
import MenuItem                     from '@material-ui/core/MenuItem';
import Popover                      from '@material-ui/core/Popover';
import Typography                   from '@material-ui/core/Typography';
import React, {useEffect, useState} from 'react';
import {getLocale}                  from '@karpeleslab/klbfw';
import {useLocation}                from 'react-router-dom';
import {connect}                    from 'react-redux';
import {compose}                    from 'redux';
import {fetchLang}                  from '../../../store/actions/system/LangAction';
import Flag                         from '../data/flag/Flag';
import Grid                         from '@material-ui/core/Grid';
import makeStyles                   from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
	},
	langBox: {
		marginRight: theme.spacing(2),
	},
}));

const LanguageSwitcher = ({fetch, lang}) => {
	const [menu, setMenu] = useState(null);
	const location = useLocation();
	useEffect(() => {
		fetch();
	}, [fetch]);
	const classes = useStyles();


	const userMenuClick = event => {
		setMenu(event.currentTarget);
	};

	const userMenuClose = () => {
		setMenu(null);
	};


	const handleLanguageChange = (lng) => {

		/*
		Change Language
		 */

		userMenuClose();
	};

	return (
		<Grid container spacing={2}>
			<Grid item onClick={userMenuClick} justify="center" alignItems="center"
			      style={{marginRight: 'auto', marginLeft: 'auto'}}>
				<Flag
					ratio="4x3"
					size="small"
					code={getLocale().split('-')[1].toLowerCase()}
					alt={getLocale()}
				/>
			</Grid>
			<Grid item xs={12} onClick={userMenuClick} style={{textAlign: 'center'}}>
				{lang.data.map(lng => (
					getLocale() === lng.Language__ &&
					<Typography variant="body2" color="textSecondary" component="p" key={lng.Language__}>
						{lng.Local_Name}
					</Typography>
				))}
			</Grid>

			<Popover
				open={Boolean(menu)}
				anchorEl={menu}
				onClose={userMenuClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				classes={{
					paper: 'py-8'
				}}
			>
				<Grid item xs={12}>
					{lang.data.map(lng => (
						<a href={`/l/${lng.Language__}${location.pathname}`} style={{textDecoration: 'none'}}
						   key={lng.Language__}>
							<MenuItem key={lng.id} onClick={() => handleLanguageChange(lng)}>
								<ListItemIcon>
									<Grid className={classes.langBox} style={{marginTop: '3px'}}>
										<Flag
											ratio="4x3"
											size="small"
											code={lng.Language__.split('-')[1].toLowerCase()}
											alt={lng.Local_Name}
										/>
									</Grid>
									<Grid>
										{lng.Local_Name}
									</Grid>

								</ListItemIcon>

							</MenuItem>
						</a>
					))}
				</Grid>


			</Popover>
		</Grid>
	);
};


const mapStateToProps = (state) => {
	return {
		lang: state.lang,
		state: state,
		open: state.common.drawerOpened,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: () => dispatch(fetchLang()),
	};
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(LanguageSwitcher);