const initState = {
    loading: true,
    currencies: [],
    decimalMap: {},
    nameMap: {},
    formatMap: {},

};

export const CURRENCY_FETCH = 'CURRENCY_FETCH';
export const CURRENCY_FETCH_DONE = 'CURRENCY_FETCH_DONE';

export const CURRENCY_DECIMAL_MAP = 'CURRENCY_DECIMAL_MAP';

export const CURRENCY_NAME_MAP = 'CURRENCY_NAME_MAP';
export const CURRENCY_FORMAT_MAP = 'CURRENCY_FORMAT_MAP';

const currencyReducer = (state = initState, action) => {
    switch (action.type) {
        case CURRENCY_FETCH:
            return {...state, loading: true};
        case CURRENCY_NAME_MAP:
            let nm = {...state.nameMap};
            nm[action.currency] = action.name;
            return {...state, nameMap: nm};
        case CURRENCY_DECIMAL_MAP:
            let newMap = {...state.decimalMap};
            newMap[action.currency] = action.decimal;
            return {...state, decimalMap: newMap};
        case CURRENCY_FORMAT_MAP:
            let formatMap = {...state.formatMap};
            formatMap[action.currency] = action.format;
            return {...state, formatMap: formatMap};
        case CURRENCY_FETCH_DONE:
            return {...state, loading: false, currencies: action.currencies};
        default:
            return state;
    }

};

export default currencyReducer