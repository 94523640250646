import React            from 'react';
import {Autocomplete}   from '@material-ui/lab';
import {TextField}      from '@material-ui/core';
import {useTranslation} from 'react-i18next';

const ShippingHandlingSelector = ({value, setValue, disabled}) => {
	const {t} = useTranslation();

	return (
		<Autocomplete
			disabled={disabled}
			multiple
			options={[
				'electronics',
				'fragile',
				'do_not_stack',
				'keep_side_up',
				'raw_food',
				'contains_batteries',
				'keep_dry'
			]}
			getOptionLabel={(option) => t('shipping_handling_'+option)}
			filterSelectedOptions
			value={value ?? []}
			onChange={(event, newValue) => {
				setValue(newValue);
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					variant="outlined"
					label={t('shipping_handling_label')}
					placeholder={t('shipping_handling_label')}
					helperText={t('shipping_handling_helperText')}
				/>
			)}
		/>
	);
};

export default ShippingHandlingSelector;