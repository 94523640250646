export const getSiteNewsRoute = () => '/news';
export const getSitePagesRoute = () => '/pages';
export const getSiteCommentsRoute = () => '/comments';
export const getSiteContentTranslateRoute = (entryDataId = null, language = null) => '/article/' + (entryDataId ?? ':entryDataId') + '/translate/' +  (language ?? ':language')
export const getSiteContentEditRoute = (entryDataId = null) => '/article/' + (entryDataId ?? ':entryData');
export const getSiteNotificationRoute = () => '/notify'
export const getSiteNotificationListRoute = () => '/notify/list'
export const getSiteNotificationCreateRoute = () => '/notify/create'
export const getSiteNotificationEditRoute= (massMailId = null) => '/notify/' + (massMailId ?? ':massMailId');
export const getSiteNotificationCreateListRoute = () => '/notify/list/create'
export const getSiteNotificationListEditRoute = (listId = null) => '/notify/list/' + (listId ?? ':segmentId');
