import React, {useState}       from 'react';
import {useTranslation}        from 'react-i18next';
import {Grid}                  from '@material-ui/core';
import Button                  from '../../../inputs/Button';
import AddIcon                 from '@material-ui/icons/Add';
import Toolbar                 from '../../../typography/Toolbar';
import ShippingFeeZoneSelector from '../../../inputs/ShippingFeeZoneSelector';
import RichAlert               from '../../../feeback/RichAlert';
import CreateDialog            from './zone/CreateDialog';
import CreateValueDialog            from './zone/value/CreateDialog';
import CreateScaleDialog            from './scale/CreateDialog';
import uuid                    from '../../../../utils/uuid';
import Table                   from './scale/table/Table';
import ValueTable                   from './zone/value/table/Table';

const ShippingFeeConfiguration = ({fee}) => {
	const {t} = useTranslation();
	const [selectedZone, setSelectedZone] = useState(null);
	const [showCreate, setShowCreate] = useState(false);
	const [showCreateZoneValue, setShowCreateZoneValue] = useState(false);
	const [showCreateScale, setShowCreateScale] = useState(false);

	const [zoneKey, setZoneKey] = useState(uuid()); // used to rebuild the selector on zone creation

	const onZoneCreated = () => {
		setZoneKey(uuid());
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Toolbar title={t('shipping_fees_zones')}>
					<Button
						endIcon={<AddIcon/>}
						onClick={e => setShowCreate(true)}
						variant="contained"
						color="primary"
					>
						{t('create_btn')}
					</Button>
				</Toolbar>
			</Grid>

			<Grid item xs={12}>
				<ShippingFeeZoneSelector
					key={zoneKey}
					feeId={fee.Catalog_Deliverable_Fee__}
					value={selectedZone}
					onChange={setSelectedZone}
				/>
			</Grid>

			{!selectedZone &&
			<Grid item xs={12}><RichAlert severity="info">{t('select_shipping_zone_info')}</RichAlert></Grid>
			}

			{selectedZone && <>
				<Grid item xs={12}>
					<Toolbar title={t('shipping_fees_zone_values')}>
						<Button
							endIcon={<AddIcon/>}
							onClick={e => setShowCreateZoneValue(true)}
							variant="contained"
							color="primary"
						>
							{t('create_btn')}
						</Button>
					</Toolbar>
				</Grid>

				<Grid item xs={12}>
					<ValueTable zoneId={selectedZone.Catalog_Deliverable_Fee_Zone__}/>
				</Grid>

				<Grid item xs={12}>
					<Toolbar title={t('shipping_fees_scales')}>
						<Button
							endIcon={<AddIcon/>}
							onClick={e => setShowCreateScale(true)}
							variant="contained"
							color="primary"
						>
							{t('create_btn')}
						</Button>
					</Toolbar>
				</Grid>

				<Grid item xs={12}>
					<Table feeId={fee.Catalog_Deliverable_Fee__} zoneId={selectedZone.Catalog_Deliverable_Fee_Zone__}/>
				</Grid>


				<CreateValueDialog
					zoneId={selectedZone.Catalog_Deliverable_Fee_Zone__}
					open={showCreateZoneValue}
					setOpen={setShowCreateZoneValue}
				/>
			</>

			}

			<CreateDialog
				feeId={fee.Catalog_Deliverable_Fee__}
				open={showCreate}
				setOpen={setShowCreate}
				onCreated={onZoneCreated}
			/>

			{selectedZone && <CreateScaleDialog
				feeId={fee.Catalog_Deliverable_Fee__}
				zoneId={selectedZone.Catalog_Deliverable_Fee_Zone__}
				open={showCreateScale}
				setOpen={setShowCreateScale}
			/>}
		</Grid>
	);
};

export default ShippingFeeConfiguration;