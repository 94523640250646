import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {connect} from 'react-redux'
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from 'react-i18next';
import {useHistory} from "react-router-dom";
import {deleteCanned} from "../../../../store/actions/SupportAction";
import {getSupportCannedsRoute} from "../../../../router/routes/support/factory/canned";
import Button from "../../inputs/Button";

const DeleteDialog = ({open, setOpen, deleting, canned, deleteCanned}) => {
    const {t} = useTranslation();
    const history = useHistory();

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        deleteCanned(canned.Support_Canned__)
            .then(() => {
                handleClose();
                history.push(getSupportCannedsRoute())
            })
    };

    return (
        <Dialog
            open={open}
            aria-labelledby="Delete canned response"
            aria-describedby="Delete canned response"
            fullWidth
        >
            <DialogTitle id="canned-delete-title">{t('confirm_delete_canned_title')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="canned-delete-description">
                    {t('confirm_delete_canned_desc')}
                    <br/>
                    <Typography variant="body1" color="textPrimary" component="span">{t('are_you_sure')}</Typography>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button variant='contained' color="primary" autoFocus onClick={handleClose} disabled={deleting}>
                    {t('back_btn')}
                </Button>
                <Button color="secondary" loading={deleting} variant='outlined' disabled={deleting} endIcon={<DeleteForeverIcon/>} onClick={handleDelete}>
                    {t('delete_btn')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {
        deleting: state.support.deletingCanned
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        deleteCanned: (cannedId) => dispatch(deleteCanned(cannedId))
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(DeleteDialog);
