import React from 'react';
import {useTranslation} from "react-i18next";
import Graphic from "../../../../../resources/images/oauthTokens.svg";
import EmptyState from "../../../feeback/State/Empty";

const Empty = () => {
    const {t} = useTranslation();
    return (
        <EmptyState
            title={t('profile_tokens_empty_title')}
            textI18Key='profile_tokens_empty_desc'
            graphic={Graphic}
        />
    );

};

export default Empty;