import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {connect} from 'react-redux'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Typography from "@material-ui/core/Typography";
import {useTranslation} from 'react-i18next';
import {closeTicket} from "../../../store/actions/SupportAction";
import Button from "../inputs/Button";

const DeleteDialog = ({open, setOpen, closing, ticket, closeTicket, onTicketClosed}) => {
    const {t} = useTranslation();

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        closeTicket(ticket.Support_Ticket__)
            .then(onTicketClosed)
    };

    return (
        <Dialog
            open={open}
            aria-labelledby="Close ticket"
            aria-describedby="Close ticket"
            fullWidth
        >
            <DialogTitle id="ticket-close-title">{t('confirm_close_ticket_title')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="ticket-close-description">
                    {t('confirm_close_ticket_desc')}
                    <br/>
                    <Typography variant="body1" color="textPrimary" component="span">{t('are_you_sure')}</Typography>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button variant='contained' color="primary" autoFocus onClick={handleClose} disabled={closing}>
                    {t('back_btn')}
                </Button>
                <Button color="secondary" variant='outlined' loading={closing} disabled={closing} endIcon={<HighlightOffIcon/>} onClick={handleDelete}>
                    {t('close_btn')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {
        closing: state.support.ticketClosing
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        closeTicket: (ticketId) => dispatch(closeTicket(ticketId))
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(DeleteDialog);
