import {COMMON_RESET} from "../CommonReducer";
import {USER_LOGOUT} from "../system/UserReducer";

const initState = () => {
    return {
        questionsLoading: false,
        questions: [],
        questionsFilters: {},
        questionsPagingCount: 0,
        questionsPaging: {
            page_no: 1,
            results_per_page: 10
        },

        creating: false,
        updating: false,
        deleting: false,

        statsLoading: false,
        stats: [],
    }
};

export const QUESTION_STATS_FETCH = 'QUESTION_STATS_FETCH';
export const QUESTION_STATS_FETCH_DONE = 'QUESTION_STATS_FETCH_DONE';

export const QUESTION_LIST_FETCH = 'QUESTION_LIST_FETCH';
export const QUESTION_LIST_FETCH_DONE = 'QUESTION_LIST_FETCH_DONE';
export const QUESTION_SET_PAGING = 'QUESTION_SET_PAGING';
export const QUESTION_SET_FILTERS = 'QUESTION_SET_FILTERS';

export const QUESTION_CREATE = 'QUESTION_CREATE';
export const QUESTION_CREATE_DONE = 'QUESTION_CREATE_DONE';

export const QUESTION_UPDATE = 'QUESTION_UPDATE';
export const QUESTION_UPDATE_DONE = 'QUESTION_UPDATE_DONE';

export const QUESTION_DELETE = 'QUESTION_DELETE';
export const QUESTION_DELETE_DONE = 'QUESTION_DELETE_DONE';


const questionReducer = (state = initState(), action) => {
    switch (action.type) {
        case COMMON_RESET:
        case USER_LOGOUT:
            return initState();
        case QUESTION_STATS_FETCH:
            return {...state, statsLoading: true}
        case QUESTION_STATS_FETCH_DONE:
            return {...state, statsLoading: false, stats: action.stats}
        case QUESTION_CREATE:
            return {...state, creating: true};
        case QUESTION_CREATE_DONE:
            return {...state, creating: false};
        case QUESTION_LIST_FETCH:
            return {...state, questionsLoading: true};
        case QUESTION_LIST_FETCH_DONE:
            return {
                ...state,
                questionsLoading: false,
                questions: action.questions,
                questionsPagingCount: action.paging.count
            };
        case QUESTION_SET_FILTERS :
            return {...state, questionsFilters: action.filters};
        case QUESTION_SET_PAGING :
            return {...state, questionsPaging: action.paging};
        case QUESTION_DELETE:
            return {...state, deleting: true};
        case QUESTION_DELETE_DONE:
            return {...state, deleting: false};
        case QUESTION_UPDATE:
            return {...state, updating: true};
        case QUESTION_UPDATE_DONE:
            return {...state, updating: false};
        default:
            return state;
    }

};

export default questionReducer