import Articles from "../../../pages/fanclub/article/Articles";
import {getFanclubArticlesRoute} from "./factory/content";

export default  [
    {
        path: getFanclubArticlesRoute(),
        component: Articles,
        exact: false,
        type: 'route',
        loginRequired: true,
    },
]