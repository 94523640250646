import React, {useEffect}       from 'react';
import {useTranslation}         from 'react-i18next';
import {
	CircularProgress,
	List,
	ListItem,
	ListItemIcon
}                               from '@material-ui/core';
import ListItemNavLink          from '../ListItemNavLink';
import {
	getSupportClosedRoute,
	getSupportInboxRoute,
	getSupportNewTicketRoute,
	getSupportOutboxRoute
}                               from '../../../../router/routes/support/factory/boxes';
import Badge                    from '@material-ui/core/Badge';
import MailIcon                 from '@material-ui/icons/Mail';
import SendIcon                 from '@material-ui/icons/Send';
import CancelScheduleSendIcon   from '@material-ui/icons/CancelScheduleSend';
import {getSupportCannedsRoute} from '../../../../router/routes/support/factory/canned';
import StorageIcon              from '@material-ui/icons/Storage';
import HasRight                 from '../../../Route/HasRight';
import EmailIcon                from '@material-ui/icons/Email';
import {fetchSupport}           from '../../../../store/actions/SupportAction';
import {connect}                from 'react-redux';

const MenuSupport = ({support, loading, refreshSupport, ready, creatingArtist}) => {
	const disabled = loading || support === null || !ready;
	const {t} = useTranslation();


	useEffect(() => {
		const refreshInterval = setInterval(() => {
			if (!support || creatingArtist) return;
			refreshSupport(support.Support__).catch(() => {
			});
		}, 30000);

		return () => {
			clearInterval(refreshInterval);
		};
		// eslint-disable-next-line
	}, [support, creatingArtist]);

	return (
		<List>
			<div>
				{loading && <ListItem>
					<ListItemIcon>
						<CircularProgress size={15}/>
					</ListItemIcon>
				</ListItem>
				}


				<ListItemNavLink
					tooltip={t('menu_support_inbox')}
					disabled={disabled}
					path={getSupportInboxRoute()}
					icon={<Badge color="primary"
					             badgeContent={support ? parseInt(support.New_Ticket_Count) : 0}><MailIcon
						size={24}/></Badge>}
					primary={t('menu_support_inbox')}
				/>

				<ListItemNavLink
					tooltip={t('menu_support_outbox')}
					disabled={disabled}
					path={getSupportOutboxRoute()}
					icon={<SendIcon size={24}/>}
					primary={t('menu_support_outbox')}
				/>

				<ListItemNavLink
					tooltip={t('menu_support_closebox')}
					disabled={disabled}
					path={getSupportClosedRoute()}
					icon={<CancelScheduleSendIcon size={24}/>}
					primary={t('menu_support_closebox')}
				/>

				<ListItemNavLink
					tooltip={t('menu_support_canned_response')}
					disabled={disabled}
					path={getSupportCannedsRoute()}
					icon={<StorageIcon size={24}/>}
					primary={t('menu_support_canned_response')}
				/>

				<HasRight support hideLoading>
					<ListItemNavLink
						tooltip={t('menu_support_contact_user')}
						disabled={disabled}
						path={getSupportNewTicketRoute()}
						icon={<EmailIcon size={24}/>}
						primary={t('menu_support_contact_user')}
					/>
				</HasRight>
			</div>
		</List>
	);
};

const mapStateToProps = (state) => {
	return {
		support: state.support.support,
		loading: state.support.loading,
		ready: state.common.ready,
		creatingArtist: state.music.creating, // Use to not to fetch support info when creating a new artist (cause it can lead to access denied error)
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		refreshSupport: (id) => dispatch(fetchSupport(id, true, true))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuSupport);
