import {rest}        from '@karpeleslab/klbfw';
import {handleError} from '../../components/utils/apiErrorHandler';
import {
	AUTHOR_CREATE,
	AUTHOR_CREATE_DONE,
	AUTHOR_UPDATE,
	AUTHOR_UPDATE_DONE,
	AUTHORS_FETCH,
	AUTHORS_FETCH_DONE,
	AUTHORS_SET_FILTERS,
	AUTHORS_SET_PAGING
}                    from '../reducers/AuthorReducer';
import {success}     from './system/ToastAction';

export const setAuthorsPaging = (newPaging) => {
	return (dispatch, getState) => {
		dispatch({type: AUTHORS_SET_PAGING, paging: {...getState().author.authorsPaging, ...newPaging}});
	};
};

export const setAuthorsFilters = (newFilters) => {
	return (dispatch, getState) => {
		dispatch({type: AUTHORS_SET_FILTERS, filters: newFilters});
	};
};

export const fetchAuthors = (cmsId) => {
	return (dispatch, getState) => {
		dispatch({type: AUTHORS_FETCH});

		const params = {
			...getState().author.authorsFilters,
			...getState().author.authorsPaging
		};

		return rest('Content/Cms/' + cmsId + '/Author', 'GET', params)
			.then(data => {
				dispatch({type: AUTHORS_FETCH_DONE, authors: data.data, paging: data.paging});
				return data.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const updateAuthor = (authorId, data) => {
	return (dispatch, getState) => {
		dispatch({type: AUTHOR_UPDATE});
		return rest('Content/Cms/Author/' + authorId, 'PATCH', data)
			.then((d) => {
				success('author_update_success');
				dispatch({type: AUTHOR_UPDATE_DONE, author: d.data});
				return d.data;
			})
			.catch((err) => {
				dispatch({type: AUTHOR_UPDATE_DONE});
				handleError(getState, dispatch, err);
			});
	};
};

export const createAuthor = (cmsId, name) => {
	return (dispatch, getState) => {
		dispatch({type: AUTHOR_CREATE});
		return rest('Content/Cms/' + cmsId + '/Author', 'POST', {Name: name})
			.then((d) => {
				success('author_create_success');
				dispatch({type: AUTHOR_CREATE_DONE, author: d.data});
				return d.data;
			})
			.catch((err) => {
				dispatch({type: AUTHOR_CREATE_DONE});
				handleError(getState, dispatch, err);
			});
	};
};

