import React                  from 'react';
import { Grid, IconButton }   from '@material-ui/core';
import PlayCircleOutlineIcon  from '@material-ui/icons/PlayCircleOutline';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';

const PlayControls = ({ mediaRef, isPaused, setIsPaused, disabled = false }) => {
	const handlePlay = () => {
		mediaRef.current.play();
		setIsPaused(false);
	};

	const handlePause = () => {
		mediaRef.current.pause();
		setIsPaused(true);
	};

	return (
		<Grid container spacing={1}>
			<Grid item>
				<IconButton
					size='medium'
					disabled={!isPaused || disabled}
					onClick={handlePlay}
				>
					<PlayCircleOutlineIcon/>
				</IconButton>

				<IconButton
					size='medium'
					disabled={isPaused || disabled}
					onClick={handlePause}
				>
					<PauseCircleOutlineIcon/>
				</IconButton>
			</Grid>
		</Grid>
	);
};

export default PlayControls;
