import React, {Fragment, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Message from "./Message";
import Divider from "@material-ui/core/Divider";
import {useTranslation} from "react-i18next";
import Button from "../inputs/Button";

const PER_PAGE = 3;

const Messages = ({messages}) => {
    const [displayed, setDisplayed] = useState(messages.slice(0, PER_PAGE));
    const {t} = useTranslation();

    const handleSeeMoreClick = () => {
        setDisplayed([...displayed, ...messages.slice(displayed.length, displayed.length + PER_PAGE)])
    };

    return (
        <Fragment>
            {
                displayed.map((message, idx)=> {
                    return <Grid key={message.Support_Ticket_Message__} item xs={12}>
                        <Message message={message}/>
                        {idx !== (displayed.length-1) && <Divider variant='middle'/>}
                    </Grid>
                })
            }

            {displayed.length < messages.length &&
            <Grid container justify='center' alignItems="center">
                <Grid item xs={5} md={4} lg={5}>
                    <Divider variant='middle'/>
                </Grid>
                <Grid item  xs={2} md={4} lg={2}>
                    <Button fullWidth onClick={handleSeeMoreClick}>{t('show_more_btn')}</Button>
                </Grid>
                <Grid item xs={5} md={4} lg={5}>
                    <Divider variant='middle'/>
                </Grid>
            </Grid>
            }

        </Fragment>);
};


export default Messages;