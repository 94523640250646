import React from 'react';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {useTranslation} from "react-i18next";
import {getStoreProductEditRoute} from "../../../../router/routes/store/factory/store";
import {getSiteEventEditRoute} from "../../../../router/routes/site/factory/planner";
import {getFanclubPlanEditRoute} from "../../../../router/routes/fanclub/factory/plan";
import Link from "../../typography/Link";

const Item = ({item}) => {
    const {t} = useTranslation();
    let link = getStoreProductEditRoute(item.Catalog_Product__);
    if ("Meta.event" in item.Catalog_Product) {
        link = getSiteEventEditRoute(item.Catalog_Product["Meta.event"])
    } else if ("Meta.Membership_Plan__" in item.Catalog_Product) {
        link = getFanclubPlanEditRoute(item.Catalog_Product["Meta.Membership_Plan__"])
    }

    return (
        <TableRow>
            <TableCell>
                <Link to={link}>
                    {item.Catalog_Product['Basic.Name']}
                </Link>
            </TableCell>
            <TableCell>{item.Status}</TableCell>
            <TableCell align="right">
                <span>{item.Price.display} ({t('excl_vat')})</span><br/>
                <span>{item.Price_Vat.display} ({t('incl_vat')})</span>
            </TableCell>
            <TableCell align="right">{item.Quantity}</TableCell>
            <TableCell align="right">
                <span>{item.Full_Price.display} ({t('excl_vat')})</span><br/>
                <span>{item.Full_Price_Vat.display} ({t('incl_vat')})</span>
            </TableCell>
        </TableRow>
    );
};

export default Item;