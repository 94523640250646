import { USER_LOGOUT }  from '../system/UserReducer';
import { COMMON_RESET } from '../CommonReducer';

const initState = () => {
	return {
		deliverablesLoading: false,
		deliverables: [],
		deliverablesFilters: {},
		deliverablesPagingCount: 0,
		deliverablesPaging: {
			page_no: 1,
			results_per_page: 10
		},

		creating: false,

		deliverable: null,
		loading: false,
		updating: false,

		uploading: false,
		uploadImgRunningPercent: 0,

		deleting: false,
		deletingFile: false,

		deliverableProductsLoading: false,
		deliverableProducts: [],
		deliverableProductsFilters: {},
		deliverableProductsPagingCount: 0,
		deliverableProductsPaging: {
			page_no: 1,
			results_per_page: 10
		},

		outOfStock: 0,
	};
};


export const DELIVERABLE_FETCH = 'DELIVERABLE_FETCH';
export const DELIVERABLE_FETCH_DONE = 'DELIVERABLE_FETCH_DONE';
export const DELIVERABLE_DELETE = 'DELIVERABLE_DELETE';
export const DELIVERABLE_DELETE_DONE = 'DELIVERABLE_DELETE_DONE';
export const DELIVERABLE_UPDATE = 'DELIVERABLE_UPDATE';
export const DELIVERABLE_UPDATE_DONE = 'DELIVERABLE_UPDATE_DONE';
export const DELIVERABLE_UPLOAD = 'DELIVERABLE_UPLOAD';
export const DELIVERABLE_UPLOAD_RUNNING = 'DELIVERABLE_UPLOAD_RUNNING';
export const DELIVERABLE_UPLOAD_DONE = 'DELIVERABLE_UPLOAD_DONE';
export const DELIVERABLE_FILE_DELETE = 'DELIVERABLE_FILE_DELETE';
export const DELIVERABLE_FILE_DELETE_DONE = 'DELIVERABLE_FILE_DELETE_DONE';
export const DELIVERABLE_PRODUCTS_LIST_FETCH = 'DELIVERABLE_PRODUCTS_LIST_FETCH';
export const DELIVERABLE_PRODUCTS_LIST_FETCH_DONE = 'DELIVERABLE_PRODUCTS_LIST_FETCH_DONE';
export const DELIVERABLE_PRODUCTS_SET_PAGING = 'DELIVERABLE_PRODUCTS_SET_PAGING';
export const DELIVERABLE_PRODUCTS_SET_FILTERS = 'DELIVERABLE_PRODUCTS_SET_FILTERS';

export const DELIVERABLE_LIST_FETCH = 'DELIVERABLE_LIST_FETCH';
export const DELIVERABLE_LIST_FETCH_DONE = 'DELIVERABLE_LIST_FETCH_DONE';
export const DELIVERABLE_SET_PAGING = 'DELIVERABLE_SET_PAGING';
export const DELIVERABLE_SET_FILTERS = 'DELIVERABLE_SET_FILTERS';

export const DELIVERABLE_CREATE = 'DELIVERABLE_CREATE';
export const DELIVERABLE_CREATE_DONE = 'DELIVERABLE_CREATE_DONE';

export const DELIVERABLE_STATS_DONE = 'DELIVERABLE_STATS_DONE';

const deliverableReducer = (state = initState(), action) => {
	switch (action.type) {
		case USER_LOGOUT:
		case COMMON_RESET:
			return initState();
		case DELIVERABLE_CREATE:
			return { ...state, creating: true };
		case DELIVERABLE_CREATE_DONE:
			return { ...state, creating: false };
		case DELIVERABLE_LIST_FETCH:
			return { ...state, deliverablesLoading: true };
		case DELIVERABLE_LIST_FETCH_DONE:
			return {
				...state,
				deliverablesLoading: false,
				deliverables: action.deliverables,
				deliverablesPagingCount: action.paging.count
			};
		case DELIVERABLE_SET_FILTERS :
			return { ...state, deliverablesFilters: action.filters };
		case DELIVERABLE_SET_PAGING :
			return { ...state, deliverablesPaging: action.paging };
		case DELIVERABLE_FETCH:
			return { ...state, loading: true };
		case DELIVERABLE_FETCH_DONE:
			return { ...state, loading: false, deliverable: action.deliverable };
		case DELIVERABLE_DELETE:
			return { ...state, deleting: true };
		case DELIVERABLE_DELETE_DONE:
			return { ...state, deleting: false };
		case DELIVERABLE_UPDATE:
			return { ...state, updating: true };
		case DELIVERABLE_UPDATE_DONE:
			return { ...state, updating: false, deliverable: action.deliverable };
		case DELIVERABLE_UPLOAD:
			return { ...state, uploading: true, uploadImgRunningPercent: 0 };
		case DELIVERABLE_UPLOAD_RUNNING:
			return { ...state, uploadImgRunningPercent: action.ratio };
		case DELIVERABLE_UPLOAD_DONE:
			return { ...state, uploading: false, deliverable: action.deliverable, uploadImgRunningPercent: 0 };
		case DELIVERABLE_FILE_DELETE:
			return { ...state, deletingFile: true };
		case DELIVERABLE_FILE_DELETE_DONE:
			return { ...state, deletingFile: false, deliverable: action.deliverable };
		case DELIVERABLE_PRODUCTS_LIST_FETCH:
			return { ...state, deliverableProductsLoading: true };
		case DELIVERABLE_PRODUCTS_LIST_FETCH_DONE:
			return {
				...state,
				deliverableProductsLoading: false,
				deliverableProducts: action.products,
				deliverableProductsPagingCount: action.paging.count
			};
		case DELIVERABLE_PRODUCTS_SET_FILTERS :
			return { ...state, deliverableProductsFilters: action.filters };
		case DELIVERABLE_PRODUCTS_SET_PAGING :
			return { ...state, deliverableProductsPaging: action.paging };
		case DELIVERABLE_STATS_DONE:
			return { ...state, outOfStock: parseInt(action.outOfStock) };
		default:
			return state;
	}
};


export default deliverableReducer;
