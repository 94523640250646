import {COMMON_RESET}    from './CommonReducer';
import {TAG_DELETE_DONE} from './TagReducer';

const initState = () => {
	return {
		selected: null,

		selectedTags: [],
		selectedTagsLoading: true,

		isSelectedFanClub: false, // will tell if the current selected cms is the fanclub cms
	                              // It's useful when displaying the cms edition, we can have variation for the fanclub cms
		news_cms: null,
		news_loading: true,

		fanclub_cms: null,
		fanclub_cms_loading: true,

		pages_cms: null,
		pages_cms_loading: true,

		entriesLoading: true,
		entriesFilters: {},
		entries: [],
		entriesPagingCount: 0,
		entriesPaging: {
			page_no: 1,
			results_per_page: 10
		},

		entry: null,
		loadingEntry: true,

		duplicating: false,

		entryData: null,
		loadingEntryData: true,

		entryManagingTags: false,

		updatingEntryData: false,

		uploadImgCount: 0,
		uploadImgDoneCount: 0,
		uploadImgRunningCount: 0,
		uploadImgRunningPercent: 0,

		creatingEntryData: false,

		deletingEntryData: false,

		deletingImage: false,

		templatesLoading: true,
		templatesFilters: {},
		templates: [],
		templatesPagingCount: 0,
		templatesPaging: {
			page_no: 1,
			results_per_page: 10
		},

		selectedTemplate: null,
		selectedTemplateLoading: false,
		templateCreating: false,
		templateUpdating: false,
		templateDeleting: false,
	};
};

export const CMS_ENTRY_MANAGE_TAGS = 'CMS_ENTRY_MANAGE_TAGS';
export const CMS_ENTRY_MANAGE_TAGS_DONE = 'CMS_ENTRY_MANAGE_TAGS_DONE';

export const CMS_TAGS_FETCH = 'CMS_TAGS_FETCH';
export const CMS_TAGS_FETCH_DONE = 'CMS_TAGS_FETCH_DONE';

export const CMS_ENTRY_DATA_FETCH = 'CMS_ENTRY_DATA_FETCH';
export const CMS_ENTRY_DATA_FETCH_DONE = 'CMS_ENTRY_DATA_FETCH_DONE';

export const CMS_ENTRY_FETCH = 'CMS_ENTRY_FETCH';
export const CMS_ENTRY_FETCH_DONE = 'CMS_ENTRY_FETCH_DONE';

export const CMS_LOAD_FANCLUB = 'CMS_LOAD_FANCLUB';
export const CMS_LOAD_FANCLUB_DONE = 'CMS_LOAD_FANCLUB_DONE';

export const CMS_LOAD_NEWS = 'CMS_LOAD_NEWS';
export const CMS_LOAD_NEWS_DONE = 'CMS_LOAD_NEWS_DONE';

export const CMS_LOAD_PAGES = 'CMS_LOAD_PAGES';
export const CMS_LOAD_PAGES_DONE = 'CMS_LOAD_PAGES_DONE';

export const CMS_SELECT = 'CMS_SELECT';

export const CMS_ENTRIES_FETCH = 'CMS_ENTRIES_FETCH';
export const CMS_ENTRIES_FETCH_DONE = 'CMS_ENTRIES_FETCH_DONE';
export const CMS_ENTRIES_SET_FILTERS = 'CMS_ENTRIES_SET_FILTERS';
export const CMS_ENTRIES_SET_PAGING = 'CMS_ENTRIES_SET_PAGING';
export const CMS_ENTRIES_RESET_PAGING = 'CMS_ENTRIES_RESET_PAGING';

export const CMS_ENTRY_DATA_UPDATE = 'CMS_ENTRY_DATA_UPDATE';
export const CMS_ENTRY_DATA_UPDATE_DONE = 'CMS_ENTRY_DATA_UPDATE_DONE';

export const CMS_ENTRY_UPLOAD_IMG = 'CMS_ENTRY_UPLOAD_IMG';
export const CMS_ENTRY_UPLOAD_IMG_DONE = 'CMS_ENTRY_UPLOAD_IMG_DONE';
export const CMS_ENTRY_UPLOAD_IMG_RUNNING = 'CMS_ENTRY_UPLOAD_IMG_RUNNING';

export const CMS_ENTRY_DATA_CREATE = 'CMS_ENTRY_DATA_CREATE';
export const CMS_ENTRY_DATA_CREATE_DONE = 'CMS_ENTRY_DATA_CREATE_DONE';


export const CMS_ENTRY_DATA_DELETE = 'CMS_ENTRY_DATA_DELETE';
export const CMS_ENTRY_DATA_DELETE_DONE = 'CMS_ENTRY_DATA_DELETE_DONE';

export const CMS_ENTRY_DELETE_IMAGE = 'CMS_ENTRY_DELETE_IMAGE';
export const CMS_ENTRY_DELETE_IMAGE_DONE = 'CMS_ENTRY_DELETE_IMAGE_DONE';
export const CMS_ENTRY_DELETE_IMAGE_ERROR = 'CMS_ENTRY_DELETE_IMAGE_ERROR';

export const CMS_TEMPLATES_FETCH = 'CMS_TEMPLATES_FETCH';
export const CMS_TEMPLATES_FETCH_DONE = 'CMS_TEMPLATES_FETCH_DONE';
export const CMS_TEMPLATES_SET_FILTERS = 'CMS_TEMPLATES_SET_FILTERS';
export const CMS_TEMPLATES_SET_PAGING = 'CMS_TEMPLATES_SET_PAGING';

export const CMS_TEMPLATE_UPDATE = 'CMS_TEMPLATE_UPDATE';
export const CMS_TEMPLATE_UPDATE_DONE = 'CMS_TEMPLATE_UPDATE_DONE';

export const CMS_TEMPLATE_CREATE = 'CMS_TEMPLATE_CREATE';
export const CMS_TEMPLATE_CREATE_DONE = 'CMS_TEMPLATE_CREATE_DONE';

export const CMS_TEMPLATE_DELETE = 'CMS_TEMPLATE_DELETE';
export const CMS_TEMPLATE_DELETE_DONE = 'CMS_TEMPLATE_DELETE_DONE';

export const CMS_TEMPLATE_FETCH = 'CMS_TEMPLATE_FETCH';
export const CMS_TEMPLATE_FETCH_DONE = 'CMS_TEMPLATE_FETCH_DONE';

export const CMS_ENTRY_DUPLICATE = 'CMS_ENTRY_DUPLICATE';
export const CMS_ENTRY_DUPLICATE_DONE = 'CMS_ENTRY_DUPLICATE_DONE';

const cmsReducer = (state = initState(), action) => {
	switch (action.type) {
		case COMMON_RESET:
			return {...initState()};
		case CMS_ENTRIES_RESET_PAGING:
			return {...state, entriesPaging: initState().entriesPaging};
		case CMS_ENTRY_DELETE_IMAGE:
			return {...state, deletingImage: true};
		case CMS_ENTRY_DELETE_IMAGE_DONE:
			return {...state, deletingImage: false, entry: action.entry};
		case CMS_ENTRY_DELETE_IMAGE_ERROR:
			return {...state, deletingImage: false};
		case CMS_ENTRY_DATA_DELETE:
			return {...state, deletingEntryData: true};
		case CMS_ENTRY_DATA_DELETE_DONE:
			return {...state, deletingEntryData: false};
		case CMS_ENTRY_DATA_CREATE:
			return {...state, creatingEntryData: true};
		case CMS_ENTRY_DATA_CREATE_DONE:
			return {...state, creatingEntryData: false};
		case CMS_ENTRY_UPLOAD_IMG:
			return {...state, uploadImgCount: state.uploadImgCount + action.count};
		case CMS_ENTRY_UPLOAD_IMG_RUNNING:
			return {...state, uploadImgRunningCount: action.count, uploadImgRunningPercent: action.ratio};
		case CMS_ENTRY_UPLOAD_IMG_DONE:
			const newEntry = {...state.entry};
			newEntry.Image = [...newEntry.Image, action.img];
			return {...state, uploadImgDoneCount: state.uploadImgDoneCount + 1, entry: newEntry};
		case CMS_ENTRY_DATA_UPDATE:
			return {...state, updatingEntryData: true};
		case CMS_ENTRY_DATA_UPDATE_DONE:
			return {...state, updatingEntryData: false, entryData: action.entryData};
		case TAG_DELETE_DONE:
			if (!state.entryData) return {...state};
			const tmp = {...state.entryData};
			tmp.Content_Cms_Entry.Tags = tmp.Content_Cms_Entry.Tags.filter(t => t.Classify_Tag__ !== action.tagId);
			return {...state, entryData: tmp};
		case CMS_ENTRY_MANAGE_TAGS:
			return {...state, entryManagingTags: true};
		case CMS_ENTRY_MANAGE_TAGS_DONE:
			if (!state.entryData) return {...state};
			let newEntryData = {...state.entryData};
			newEntryData.Content_Cms_Entry = action.entry;
			return {...state, entryManagingTags: false, entryData: newEntryData};
		case CMS_TAGS_FETCH:
			return {...state, selectedTagsLoading: true};
		case CMS_TAGS_FETCH_DONE:
			return {...state, selectedTagsLoading: false, selectedTags: action.tags};
		case CMS_ENTRY_FETCH:
			return {...state, loadingEntry: true, entry: null};
		case CMS_ENTRY_FETCH_DONE:
			return {...state, loadingEntry: false, entry: action.entry};
		case CMS_ENTRY_DATA_FETCH:
			return {...state, loadingEntryData: true, entryData: action.silent ? state.entryData : null};
		case CMS_ENTRY_DATA_FETCH_DONE:
			return {...state, loadingEntryData: false, entryData: action.entryData};
		case CMS_SELECT:
			let isFanclub = false;
			if ((state.fanclub_cms && action.cms) && state.fanclub_cms.Content_Cms__ === action.cms.Content_Cms__)
				isFanclub = true;
			return {...state, selected: action.cms, isSelectedFanClub: isFanclub};
		case CMS_LOAD_FANCLUB:
			return {...state, fanclub_cms_loading: true};
		case CMS_LOAD_FANCLUB_DONE:
			let isFc = false;
			if ((state.selected && action.cms) && state.selected.Content_Cms__ === action.cms.Content_Cms__)
				isFc = true;
			return {...state, fanclub_cms_loading: false, fanclub_cms: action.cms, isSelectedFanClub: isFc};
		case CMS_LOAD_PAGES:
			return {...state, pages_cms_loading: true};
		case CMS_LOAD_PAGES_DONE:
			return {...state, pages_cms_loading: false, pages_cms: action.cms};

		case CMS_LOAD_NEWS:
			return {...state, news_cms_loading: true};
		case CMS_LOAD_NEWS_DONE:
			return {...state, news_cms_loading: false, news_cms: action.cms};
		case CMS_ENTRIES_SET_PAGING:
			return {...state, entriesPaging: action.paging};
		case CMS_ENTRIES_SET_FILTERS:
			return {...state, entriesFilters: action.filters};
		case CMS_ENTRIES_FETCH:
			return {...state, entriesLoading: true};
		case CMS_ENTRIES_FETCH_DONE:
			return {
				...state,
				entriesLoading: false,
				entries: action.entries,
				entriesPagingCount: action.paging.count
			};
		case CMS_TEMPLATES_SET_PAGING:
			return {...state, templatesPaging: action.paging};
		case CMS_TEMPLATES_SET_FILTERS:
			return {...state, templatesFilters: action.filters};
		case CMS_TEMPLATES_FETCH:
			return {...state, templatesLoading: true};
		case CMS_TEMPLATES_FETCH_DONE:
			return {
				...state,
				templatesLoading: false,
				templates: action.templates,
				templatesPagingCount: action.paging.count
			};
		case CMS_TEMPLATE_CREATE:
			return {...state, templateCreating: true};
		case CMS_TEMPLATE_CREATE_DONE:
			return {...state, templateCreating: false};
		case CMS_TEMPLATE_UPDATE:
			return {...state, templateUpdating: true};
		case CMS_TEMPLATE_UPDATE_DONE:
			return {...state, templateUpdating: false, selectedTemplate: action.template};
		case CMS_TEMPLATE_DELETE:
			return {...state, templateDeleting: true};
		case CMS_TEMPLATE_DELETE_DONE:
			return {...state, templateDeleting: false};
		case  CMS_TEMPLATE_FETCH:
			return {...state, selectedTemplateLoading: true};
		case  CMS_TEMPLATE_FETCH_DONE:
			return {...state, selectedTemplateLoading: false, selectedTemplate: action.template};
		case  CMS_ENTRY_DUPLICATE:
			return {...state, duplicating: true};
		case  CMS_ENTRY_DUPLICATE_DONE:
			return {...state, duplicating: false};
		default:
			return state;
	}

};

export default cmsReducer;