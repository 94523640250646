import React, {useState}              from 'react';
import TableRow                       from '@material-ui/core/TableRow';
import Skeleton                       from '@material-ui/lab/Skeleton';
import TableCell                      from '@material-ui/core/TableCell';
import {useTranslation}               from 'react-i18next';
import Button                         from '../../../../inputs/Button';
import DeleteIcon                     from '@material-ui/icons/Delete';
import moment                         from 'moment';
import DeleteDialog                   from './DeleteDialog';
import HasRight                       from '../../../../../Route/HasRight';
import ParentComment                  from './ParentComment';
import {Grid}                         from '@material-ui/core';
import ResponseComment                from './ResponseComment';
import MessageIcon                    from '@material-ui/icons/Message';
import CheckIcon                      from '@material-ui/icons/Check';
import {fetchComments, patrolComment} from '../../../../../../store/actions/SocialAction';
import {connect}                      from 'react-redux';
import Link                           from '../../../../typography/Link';
import {getSiteUserEditRoute}         from '../../../../../../router/routes/site/factory/user';

const Row = ({comment, loading, showResponse, isResponse = false, patrolComment, refresh}) => {
	const displayComment = (!loading && comment);
	const {t} = useTranslation();
	const [showDelete, setShowDelete] = useState(false);
	const [patrolling, setPatrolling] = useState(false);
	const [showResp, setShowResponse] = useState(false);

	const handleValidate = () => {
		setPatrolling(true);
		patrolComment(comment.Social_Comment__)
			.then(refresh);
	};

	return (
		<>
			<TableRow style={isResponse ? {backgroundColor: '#525252'} : {}}>
				<TableCell>
					{!displayComment && <Skeleton/>}
					{displayComment &&
					<Link to={getSiteUserEditRoute(comment.Profile.User__)}>
						{comment.Profile.Display_Name}
					</Link>}
				</TableCell>
				<TableCell>
					{!displayComment && <Skeleton/>}
					{displayComment && <Grid container spacing={3}>
						<Grid item xs={12}>{comment.Message}</Grid>
						<Grid item xs={12}>
							{!showResponse && <ParentComment comment={comment}/>}
						</Grid>
					</Grid>}
				</TableCell>
				<TableCell>
					{!displayComment && <Skeleton/>}
					{displayComment && <span>{comment.Likes.Like_Count}</span>}
				</TableCell>
				<TableCell>
					{!displayComment && <Skeleton/>}
					{displayComment && <span>{moment(parseInt(comment.Date.unixms)).format('LLL')}</span>}
				</TableCell>

				<TableCell align="right">
					<Grid container spacing={3} justify="space-around">
						{!displayComment && <Skeleton/>}
						{((displayComment && showResponse && comment.Comments) && comment.Comments.Comment_Count > 0) &&
						<Grid item><Button
							variant="outlined"
							color="primary"
							onClick={() => setShowResponse(!showResp)}
							startIcon={<MessageIcon/>}
						>
							{!showResp && t('show_x_response', {count: comment.Comments.Comment_Count})}
							{showResp && t('hide_x_response', {count: comment.Comments.Comment_Count})}
						</Button></Grid>}
						<HasRight admin hideLoading>
							{displayComment && <Grid item><Button
								variant="outlined"
								color="secondary"
								onClick={() => setShowDelete(true)}
								startIcon={<DeleteIcon/>}
							>
								{t('delete_btn')}
							</Button></Grid>}
						</HasRight>

						{(displayComment && comment.Patrol === 'pending') && <Grid item><Button
							variant="outlined"
							color="primary"
							onClick={() => handleValidate()}
							loading={patrolling}
							startIcon={<CheckIcon/>}
						>
							{t('validate_btn')}
						</Button></Grid>}

					</Grid>
				</TableCell>
				{showDelete && <DeleteDialog comment={comment} open={showDelete} setOpen={setShowDelete}/>}
			</TableRow>
			{(showResponse && showResp) && <ResponseComment comment={comment}/>}
		</>
	);
};


const mapDispatchToProps = (dispatch, props) => {
	return {
		patrolComment: (commentId) => dispatch(patrolComment(commentId)),
		refresh: () => dispatch(fetchComments()),
	};
};
export default connect(null, mapDispatchToProps)(Row);
