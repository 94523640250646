import React, {useState}       from 'react';
import {connect}               from 'react-redux';
import SubscribersTable        from '../../../components/common/membership/members/table/Table';
import Grid                    from '@material-ui/core/Grid';
import Filters                 from '../../../components/common/membership/members/filters/Filters';
import Tooltip                 from '@material-ui/core/Tooltip';
import IconButton              from '@material-ui/core/IconButton';
import FilterListIcon          from '@material-ui/icons/FilterList';
import ExpandLessIcon          from '@material-ui/icons/ExpandLess';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import Collapse                from '@material-ui/core/Collapse';
import Loading                 from '../../../components/common/feeback/loading/Loading';
import {Overlay}               from '../../../components/common/feeback/Overlay';
import Typography              from '@material-ui/core/Typography';
import {exportMembers}         from '../../../store/actions/fanclub/MembershipAction';
import {downloadUrl}           from '../../../components/utils/dowloadUrl';
import SelectRealm             from '../../SelectRealm';
import AppBarTitle             from '../../../components/common/typography/AppBarTitle';
import {useTranslation}        from 'react-i18next';
import Toolbar                 from '../../../components/common/typography/Toolbar';
import HasCapability           from '../../../components/Route/HasCapability';
import {getPXLDashboardRoute}  from '../../../router/routes/pxl/factory/general';
import Redirect                from '../../../components/Route/Redirect';
import HasRight                from '../../../components/Route/HasRight';
import FanclubActivator        from '../../../components/common/fanclub/FanclubActivator';

const Subscribers = ({selectedRealm, selectedMembership, membersExporting, exportMembers, filters}) => {
	let hasFilters = false;
	const {t} = useTranslation();

	if (filters.Status && '$in' in filters.Status) hasFilters = true;
	if (filters.Membership_Plan__ && '$in' in filters.Membership_Plan__) hasFilters = true;


	const [filtersShown, setFiltersShown] = useState(hasFilters);

	if (!selectedRealm) return <SelectRealm/>;
	if (!selectedMembership) return <Loading/>;

	const createFilterToggle = v => e => {
		setFiltersShown(v);
	};

	const exportClickHandler = () => {
		exportMembers().then(data => {
			downloadUrl(data.url, 'export.csv');
		});
	};

	return (
		<Grid container spacing={3}>
			<HasCapability fanclub deniedComponent={<Redirect target={getPXLDashboardRoute()}/>}>
				<AppBarTitle title={t('fanclub_members')}/>

				<FanclubActivator/>

				<Grid item xs={12}>
					<Toolbar title={t('members_subscriptions_title')}>
						<Tooltip title={t('filters')}>
							<IconButton aria-label={t('filters')} onClick={createFilterToggle(!filtersShown)}>
								{!filtersShown && <FilterListIcon/>}
								{filtersShown && <ExpandLessIcon/>}
							</IconButton>
						</Tooltip>

						<HasRight support hideLoading>
							<Tooltip title={t('export_with_current_filters')}>
								<IconButton aria-label={t('export_with_current_filters')} onClick={exportClickHandler}>
									<DescriptionOutlinedIcon/>
								</IconButton>
							</Tooltip>
						</HasRight>
					</Toolbar>
					<Collapse in={filtersShown}>
						<Filters setShowFilters={setFiltersShown}/>
					</Collapse>
				</Grid>
				<Grid item xs={12}>
					<SubscribersTable memberhsip={selectedMembership}/>
				</Grid>
				<Overlay open={membersExporting}>
					<Loading color="inherit" size={60} direction="column">
						<Grid item>
							<Typography variant="h2" component="h2" color="inherit">
								{t('generating_file_please_wait')}
							</Typography>
						</Grid>
					</Loading>
				</Overlay>
			</HasCapability>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedMembership: state.membership.selectedFanclub,
		filters: state.membership.membersFilters,
		selectedRealm: state.realm.selected,
		membersExporting: state.membership.membersExporting,
		loadingFanclub: state.membership.loadingFanclub
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		exportMembers: () => dispatch(exportMembers())
	};
};


export default connect(mapStateToProps, mapDispatchToProps)(Subscribers);
