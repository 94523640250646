import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import {useTranslation} from "react-i18next";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import {connect} from 'react-redux'
import Typography from "@material-ui/core/Typography";
import Button from "../../../inputs/Button";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import {deleteTag} from "../../../../../store/actions/TagAction";

const DeleteDialog = ({tag, open, setOpen, deleting, deleteTag, onDeleted}) => {
    const {t} = useTranslation();

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        deleteTag(tag.Classify_Tag__)
            .then(d => {
                onDeleted(tag);
                setOpen(false)
            })
    };


    return (
        <Dialog
            open={open}
            aria-labelledby="Delete tag"
            aria-describedby="Delete tag"
            fullWidth
        >
            <DialogTitle id="tag-delete-title">{t('delete_category_title')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="tag-delete-description">
                    {t('delete_category_desc')}
                    <br/>
                    <Typography variant="body1" color="textPrimary" component="span">{t('are_you_sure')}</Typography>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button variant='contained' color="primary" autoFocus onClick={handleClose} disabled={deleting}>
                    {t('back_btn')}
                </Button>
                <Button color="secondary" disabled={deleting} loading={deleting} endIcon={<DeleteForeverIcon/>}
                        onClick={handleDelete}>
                    {t('delete_btn')}
                </Button>
            </DialogActions>
        </Dialog>

    );
};

const mapStateToProps = (state) => {
    return {
        deleting: state.tag.deleting,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        deleteTag: (tagId) => dispatch(deleteTag(tagId)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDialog);