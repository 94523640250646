import React, {useState} from 'react';
import TableCell from "@material-ui/core/TableCell";
import Box from "@material-ui/core/Box";
import TableRow from "@material-ui/core/TableRow";
import UngroupDialog from "../UngroupDialog";
import GoodsTable from "./GoodsTable";

const GoodsTableWrapper = ({shipping, showUngroup = true}) => {
    const [showUngroupDialog, setShowUngroupDialog] = useState(false);

    return (
        <>
            <TableRow>
                <TableCell/>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={showUngroup ? 6 : 7}>
                    <Box margin={1}>
                        <GoodsTable
                            showUngroupBtn={showUngroup}
                            ungroupOnClick={() => setShowUngroupDialog(true)}
                            shipping={shipping}
                            selectable={false}
                        />
                    </Box>
                </TableCell>
            </TableRow>
            {showUngroup && <UngroupDialog
                open={showUngroupDialog}
                setOpen={setShowUngroupDialog}
                shipping={shipping}
            />}
        </>
    );
};

export default GoodsTableWrapper;