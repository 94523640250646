import React, {useState} from 'react';
import {connect} from 'react-redux'
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import {useTranslation} from "react-i18next";
import {setTicketsFilters} from "../../../store/actions/SupportAction";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Button from "../inputs/Button";

const Filters = ({tags, filters, setTicketsFilters, ticketsLoading}) => {
    const {t} = useTranslation();

    const [tagsValues, setTagsValues] = useState(filters.Tags ?? []);

    const clearFiltersHandler = () => {
        let params = {
            ...filters
        };

        delete params.Tag;

        setTagsValues([]);
        setTicketsFilters(params);
    };

    const filterHandler = () => {
        let params = {
            ...filters
        };

        delete params.Tag;

        if (tagsValues.length > 0) params.Tag = tagsValues;

        setTicketsFilters(params);
    };

    const createTagToggleHandler = tag => e => {
        if (e.target.checked) {
            setTagsValues([...tagsValues, tag])
        } else {
            setTagsValues(tagsValues.filter(tv => tv !== tag));
        }
    };

    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={3}
        >
            <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary" component="p">
                    {t('support_tags_filter_description')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {tags.map(tag =>
                    <FormControlLabel
                        key={tag.Classify_Tag__}
                        control={<Checkbox
                            value={tag.Classify_Tag__}
                            color="primary"
                            checked={tagsValues.includes(tag.Classify_Tag__)}
                            name='Tag'
                            onChange={createTagToggleHandler(tag.Classify_Tag__)}
                        />}
                        label={tag.Name}
                    />
                )}


            </Grid>

            <Grid item  xs={12}>
                <Grid container justify="flex-end" spacing={1}>
                    <Grid item  lg={2}>
                        <Button disabled={ticketsLoading} variant="contained" color='secondary' startIcon={<DeleteIcon/>}
                                onClick={clearFiltersHandler}>{t('clear_filters_btn')}</Button>
                    </Grid>
                    <Grid item lg={1}>
                        <Button disabled={ticketsLoading} loading={ticketsLoading} variant="contained" color='primary' endIcon={<SearchIcon/>}
                                onClick={filterHandler}>{t('filter_btn')}</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};


const mapStateToProps = (state) => {
    return {
        tags: state.support.supportClassifyTags,
        filters: state.support.ticketsFilters,
        ticketsLoading: state.support.ticketsLoading,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setTicketsFilters: (filters) => dispatch(setTicketsFilters(filters))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
