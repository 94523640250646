import React                  from 'react';
import { useTranslation }     from 'react-i18next';
import Card                   from '@material-ui/core/Card';
import CardMedia              from '@material-ui/core/CardMedia';
import defaultPicture         from '../../../resources/images/landscape-image.svg';
import CardContent            from '@material-ui/core/CardContent';
import Grid                   from '@material-ui/core/Grid';
import Typography             from '@material-ui/core/Typography';
import SnsLinks               from '../data/SnsLink/SnsLinks';
import CardActions            from '@material-ui/core/CardActions';
import { Link }               from 'react-router-dom';
import Skeleton               from '@material-ui/lab/Skeleton';
import makeStyles             from '@material-ui/core/styles/makeStyles';
import { getMemberEditRoute } from '../../../router/routes/artist/factory/members';
import Button from "../inputs/Button";

const useStyles = makeStyles({
	media: {
		height: 140,
	},
	fillHeight: {
		height: '100%'
	},
	editBtn: {
		marginLeft: 'auto'
	}
});


const Member = ({ member, editable }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<Card className={classes.fillHeight} elevation={0} variant='outlined'>
			{member && <CardMedia
				className={classes.media}
				image={member.Image_Drive_Item ? member.Image_Drive_Item.Icon : defaultPicture}
				title={t('member_avatar')}
			/>}
			{!member && <Skeleton height={200}/>}
			<CardContent>
				{member && <Grid container spacing={3}>
					<Grid item>
						<Typography gutterBottom variant='h5' component='h2'>
							{member.Name}
						</Typography>
					</Grid>
				</Grid>}

				{(member && member.Birthdate) &&
				<Typography variant='body2' color='textSecondary' component='p'>
					{t('member_birthdate', { date: member.Birthdate })}
				</Typography>
				}
				{(member && member.Blood_Type) &&
				<Typography variant='body2' color='textSecondary' component='p'>
					{t('member_bloodtype', { type: member.Blood_Type })}
				</Typography>
				}

				{member && <SnsLinks values={member.Links} viewMode={true}/>}
				{!member && <Skeleton height={250}/>}

			</CardContent>
			<CardActions>
				{(member && editable) &&
				<Button size='small' color='primary' className={classes.editBtn} component={Link}
				        to={getMemberEditRoute(member.Music_Label_Artist_Member__)}>
					{t('edit_lnk')}
				</Button>}
			</CardActions>

		</Card>
	);
};

export default Member;
