import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import { Grid, TextField} from "@material-ui/core";
import {Title} from "../typography/Title";
import {connect} from 'react-redux'
import {updateProfile} from "../../../store/actions/system/UserActions";
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Date from "../inputs/Date";
import Button from "../inputs/Button";

const Information = ({user, changingInfo, updateProfile}) => {
    const {t} = useTranslation();
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [username, setUsername] = useState(user.Profile.Display_Name);
    const originalBirthdate = (user.Profile.Birthdate && user.Profile.Birthdate !== '0000-00-00') ? moment(user.Profile.Birthdate) : null;
    const [birthdate, setBirthdate] = useState(originalBirthdate);

    const originalGender = user.Profile.Gender ?? 'N/A';
    const [gender, setGender] = useState(originalGender);

    const genderLabel = React.useRef(null);
    const [genderLabelWidth, setGenderLabelWidth] = useState(0);

    useEffect(() => {
        setGenderLabelWidth(genderLabel.current.offsetWidth);
    }, []);

    useEffect(() => {
        if (username !== user.Profile.Display_Name) {
            setSaveEnabled(true);
            return
        }

        if (!birthdate && originalBirthdate) {
            setSaveEnabled(true);
            return
        }

        if (birthdate && !originalBirthdate) {
            setSaveEnabled(true);
            return
        }

        if ((birthdate && originalBirthdate) && birthdate.format('YYYY-MM-DD') !== originalBirthdate.format('YYYY-MM-DD')) {
            setSaveEnabled(true);
            return
        }

        if (gender !== originalGender) {
            setSaveEnabled(true);
            return
        }

        setSaveEnabled(false);
    }, [username, birthdate, user, gender, originalBirthdate, originalGender]);

    const handleSave = () => {
        let p = {
            Gender: gender === 'N/A' ? null : gender,
            Display_Name: username
        };

        if (birthdate) {
            p.Birthdate = birthdate.format('YYYY-MM-DD');
        }

        updateProfile(p);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Title>{t('profile_information')}</Title>
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    variant="outlined"
                    fullWidth label={t('profile_username')}
                    value={username}
                    disabled={changingInfo}
                    onChange={(e) => setUsername(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Date
                    label={t('profile_birthdate')}
                    inputVariant="outlined"
                    value={birthdate}
                    disabled={changingInfo}
                    onChange={setBirthdate}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={5}>
                <FormControl variant='outlined' fullWidth disabled={changingInfo}>
                    <InputLabel ref={genderLabel} htmlFor='gender-select'>{t('profile_gender')}</InputLabel>
                    <Select
                        native
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                        labelWidth={genderLabelWidth}
                        inputProps={{
                            id: 'gender-select',
                        }}
                    >
                        <option value='N/A'>{t('not_available')}</option>
                        <option value='M'>{t('gender_male')}</option>
                        <option value='F'>{t('gender_female')}</option>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Grid container justify='flex-end'>

                    <Button color='primary' variant='contained' loading={changingInfo}
                            disabled={!saveEnabled || changingInfo} onClick={handleSave}>
                        {t('save_btn')}
                    </Button>

                </Grid>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        changingInfo: state.user.changingInfo,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateProfile: (data) => dispatch(updateProfile(data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Information);
