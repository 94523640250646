import React, {useEffect}     from 'react';
import {fetchRecord}          from '../../../store/actions/discography/RecordAction';
import {connect}              from 'react-redux';
import {useTranslation}       from 'react-i18next';
import {useParams}            from 'react-router-dom';
import SelectRealm            from '../../SelectRealm';
import Loading                from '../../../components/common/feeback/loading/Loading';
import HasRight               from '../../../components/Route/HasRight';
import Redirect               from '../../../components/Route/Redirect';
import {getMusicRecordsRoute} from '../../../router/routes/music/factory/records';
import Grid                   from '@material-ui/core/Grid';
import AppBarTitle            from '../../../components/common/typography/AppBarTitle';
import Toolbar                from '../../../components/common/typography/Toolbar';
import Edit                   from '../../../components/common/music/record/lyrics/Edit';
import moment                 from 'moment';

const Lyrics = ({selectedRealm, artist, loading, fetchRecord, record}) => {
	const {t} = useTranslation();
	const {recordId} = useParams();

	useEffect(() => {
		fetchRecord(recordId);
	}, [fetchRecord, recordId]);

	if (!selectedRealm) return <SelectRealm/>;
	if (!artist || loading) return <Loading/>;

	return (
		<HasRight manager deniedComponent={<Redirect target={getMusicRecordsRoute()}/>}>
			<Grid container spacing={3}>
				<AppBarTitle title={t('record_lyrics_title')}/>

				<Grid item xs={12}>
					<Toolbar title={t('record_lyrics_title')}/>
				</Grid>

				<Grid item xs={12}>
					<Edit
						fullHour={moment.duration(parseInt(record.Duration), 'seconds').hours() > 0}
						record={record}
					/>
				</Grid>
			</Grid>
		</HasRight>
	);
};

const mapStateToProps = (state) => {
	return {
		artist: state.artist.artist,
		selectedRealm: state.realm.selected,
		record: state.record.record,
		loading: state.record.recordLoading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchRecord: (id) => dispatch(fetchRecord(id))
	};
};


export default connect(mapStateToProps, mapDispatchToProps)(Lyrics);
