import React, { useEffect } from 'react';
import Grid               from '@material-ui/core/Grid';
import Select             from './Select';
import PictureChooser     from './PictureChooser';
import { useTranslation } from 'react-i18next';
import FormControl        from '@material-ui/core/FormControl';
import FormLabel          from '@material-ui/core/FormLabel';
import { FormHelperText } from '@material-ui/core';
import FormGroup          from '@material-ui/core/FormGroup';
import FormControlLabel   from '@material-ui/core/FormControlLabel';
import Checkbox           from '@material-ui/core/Checkbox';
import TextField          from '@material-ui/core/TextField';
import FormRequiredField  from '../feeback/FormRequiredField';

const TemplateSettings = ({ template, settings = {}, setSettings, files = {}, originalFiles = {}, setFiles, setIsValid, disabled = false, showFanclub = false, showStore = false, showReleases = false }) => {
	const { t } = useTranslation();

	// fill the default values
	useEffect(() => {
		let d = {};
		template.Settings.forEach(s => {
			if (!([s.Key] in settings) && s.Default) {
				d[s.Key] = s.Default;
			}
		});

		setSettings({ ...settings, ...d });
		// eslint-disable-next-line
	}, [setSettings, template]);

	useEffect(() => {
		for (let i = 0; i < template.Settings.length; i++) {
			const s = template.Settings[i];
			let settingFilled = ([s.Key] in settings);
			if (s.Type === 'multiple') {
				if (settingFilled && settings[s.Key].length < 1) {
					settingFilled = false;
				}
			}
			if (s.Type !== 'file' && s.Required === 'Y' && !s.Default && !settingFilled) { // files are never required
				// Required, with no default value and we didn't set it
				setIsValid(false);
				return;
			}

			if (!settingFilled) continue; // No need to check the value we don't have one

			switch (s.Type) {
				case 'select':
					const possibleValues = s.Options.split('|');
					const exists = possibleValues.filter(v => v === settings[s.Key]);
					if (exists.length < 1) {
						setIsValid(false);
						return;
					}
					break;
				default:
					break;

			}
		}

		setIsValid(true);
	}, [setIsValid, settings, template]);

	const createChangeHandler = key => e => {
		setSettings({ ...settings, [key]: e.target.value });
	};

	const createFileChangeHandler = key => file => {
		if (file === undefined && key in originalFiles) {
			file = originalFiles[key];
		} else if (file === undefined) {
			let cpy = { ...file };
			delete cpy[key];
			setFiles(cpy);
			return;
		}

		setFiles({ ...files, [key]: file });
	};

	const createMultipleChangeHandler = key => e => {
		const value = e.target.name;
		const checked = e.target.checked;
		let cpy = [...(settings[key] ?? [])];
		if (!checked) {
			cpy = cpy.filter(e => e !== value);
		} else {
			cpy.push(value);
		}

		setSettings({ ...settings, [key]: cpy });
	};

	let prevInput = null;
	let spaceTaken = 0;
	const buildSetting = (s) => {
		let returnValue = <></>;
		if (s.Key === 'fanclub_visible' && !showFanclub) return returnValue;
		if (s.Key === 'shop_visible' && !showStore) return returnValue;
		if (s.Key === 'releases_visible' && !showReleases) return returnValue;

		if(spaceTaken >= 2) spaceTaken = 0;

		switch (s.Type) {
			case 'multiple' :
				returnValue =
					<>
						<Grid key={s.Key} item xs={12}>
							<FormControl
								component='fieldset'
								required={s.Required === 'Y'}
								disabled={disabled}
								fullWidth
							>
								<FormLabel component='legend'>{s.Name}</FormLabel>
								{s.Description && <FormHelperText>{s.Description}</FormHelperText>}
								<FormGroup row>
									{s.Options.split('|').map(o =>
										<FormControlLabel
											control={
												<Checkbox
													checked={(settings[s.Key] ?? []).includes(o)}
													onChange={createMultipleChangeHandler(s.Key)}
													color='primary'
													name={o}
												/>}
											label={t('template_setting_' + s.Key + '_' + o.toLowerCase())}
										/>
									)}
								</FormGroup>
							</FormControl>
						</Grid>
					</>;
				break;
			case 'select' :
				const options = [];
				s.Options.split('|').forEach(o =>
					options.push({ Key: o, Value: t('template_setting_' + s.Key + '_' + o.toLowerCase()) })
				);

				returnValue =
					<>
						{(prevInput === 'file') && <Grid item xs={6}/>}
						<Grid key={s.Key} item xs={6}>
							<Select
								label={s.Name}
								value={settings[s.Key]}
								onChange={createChangeHandler(s.Key)}
								optionValue='Value'
								optionKey='Key'
								options={options}
								helperText={s.Description}
								inputProps={{
									disabled: disabled,
									variant: 'outlined',
									fullWidth: true,
									required: s.Required === 'Y'
								}}
							/>
						</Grid>
					</>;
					if(prevInput === 'file') spaceTaken+=1;
				break;
			case 'file':
				if (s.File_Type === 'Image') {
					returnValue =
						<>
							{(prevInput === 'select' && (spaceTaken === 1)) && <Grid item xs={6}/>}
							<Grid key={s.Key} item xs={6}>
								<PictureChooser
									label={s.Name}
									helperText={s.Description}
									value={files[s.Key]}
									defaultValue={originalFiles[s.Key]}
									setValue={createFileChangeHandler(s.Key)}
									disabled={disabled}
									minHeight={s.File_Options && 'height' in s.File_Options ? s.File_Options['height'] : 0}
									minWidth={s.File_Options && 'width' in s.File_Options ? s.File_Options['width'] : 0}
									required={false} // Files are never required
								/>
							</Grid>
						</>;
					if(prevInput === 'select' && (spaceTaken === 1)) spaceTaken+=1;
				}
				break;
			case 'text':
				returnValue =
					<>
						<Grid key={s.Key} item xs={12}>
							<TextField
								value={settings[s.Key]}
								onChange={createChangeHandler(s.Key)}
								variant='outlined'
								multiline rows={5}
								rowsMax={Infinity}
								required={s.Required === 'Y'}
								fullWidth
								disabled={disabled}
								label={s.Name}
								helperText={s.Description}
							/>
						</Grid>
					</>;
				break;
			default:
				break;
		}

		switch (s.Type) {
			case 'text':
			case 'multiple':
				spaceTaken += 2;
				break;
			case 'file':
			case 'select':
				spaceTaken +=1
				break;
			default:
				break;
		}

		prevInput = s.Type;
		return returnValue;
	};

	return (
		<Grid container spacing={3} direction='row'>
			<FormRequiredField/>
			{template.Settings.map(s => buildSetting(s))}
		</Grid>
	);
};

export default TemplateSettings;
