import React, {useState} from 'react';
import AppBarTitle from '../../components/common/typography/AppBarTitle';
import Grid from '@material-ui/core/Grid';
import Toolbar from '../../components/common/typography/Toolbar';
import {useTranslation} from 'react-i18next';
import {Title} from '../../components/common/typography/Title';
import UserLocationSelector from '../../components/common/user/location/selector/UserLocationSelector';
import Stripe from '../../components/common/inputs/Stripe/Stripe';
import {useUserBillingCreateWithMethod} from '../../hooks/api/user/useUserBilling';
import Button from '../../components/common/inputs/Button';
import Alert from '@material-ui/lab/Alert';
import {useHistory} from 'react-router-dom';
import {getPXLBillingRoute} from '../../router/routes/pxl/factory/order';
import {connect} from "react-redux";

const Create = ({user}) => {
	const {t} = useTranslation();
	const [location, setLocation] = useState();
	const [create] = useUserBillingCreateWithMethod();
	const [stripe, setStripe] = useState(null);
	const [creating, setCreating] = useState(false);
	const [stripeOk, setStripeOk] = useState(false);
	const [stripeElements, setStripeElements] = useState(null);
	const [stripeIntent, setStripeIntent] = useState(null)
	const [error, setError] = useState(null);
	const history = useHistory();

	const onStripeChange = e => {
		setStripeOk(e.complete === true);
	};

	const handleCreate = async () => {
		if (!stripe || !stripeElements)
			return; // stripe not loaded yet

		setCreating(true);

		const result = await stripe.confirmSetup({
			elements: stripeElements,
			redirect: 'if_required',
			confirmParams: {
				payment_method_data: {
					billing_details: {
						name: `${location.First_Name} ${location.Last_Name}`,
						email: user.Email,
						address: {
							country: location.Country__,
							postal_code: location.Zip,
							state: location.Province ?? '',
							city: location.City ?? '',
							line1: location.Address ?? '',
							line2: location.Address2 ?? '',
						}
					},
				}, // Make sure to change this to your payment completion page
				//return_url: `https://${window.location.host}${getPXLOrderRoute(order.Order__)}`,
			}
		});

		if (result.error) {
			if (result.error.type === "card_error" || result.error.type === "validation_error") {
				setError(result.error.message);
			} else {
				setError(t('unexpected_error'));
			}

			setCreating(false);
			return;
		}


		create('PXL billing', location, 'Stripe', {stripe_intent: stripeIntent.stripe_intent})
			.then((b) => {
				history.push(getPXLBillingRoute(b.User_Billing__));
			})
			.finally(() => setCreating(false));
	};

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('create_billing_title')}/>

			<Grid item xs={12}>
				<Toolbar title={t('create_billing_title')}/>
			</Grid>

			<Grid item xs={12}>
				<Title>{t('select_user_location')}</Title>
			</Grid>

			<Grid item xs={12}>
				<UserLocationSelector setValue={setLocation} value={location} disabled={creating}/>
			</Grid>

			<Grid item xs={12}>
				<Title>{t('cc_information')}</Title>
			</Grid>

			{error && <Grid item xs={12}>
				<Alert severity="error">
					{error}
				</Alert>
			</Grid>
			}

			<Grid item xs={12}>
				<Stripe
					setStripeElements={setStripeElements}
					setStripe={setStripe}
					onChange={onStripeChange}
					disabled={creating}
					setIntent={setStripeIntent}
				/>
			</Grid>

			<Grid item xs={12}>
				<Grid justify="flex-end" container>
					<Button variant="contained" loading={creating} disabled={!stripeOk || !location || creating}
							color="primary"
							onClick={handleCreate}>
						{t('save_btn')}
					</Button>
				</Grid>
			</Grid>

		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.user.user,
	}
};

const mapDispatchToProps = (dispatch) => {
	return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);