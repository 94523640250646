import React, { useState } from 'react';
import { Grid }    		   from '@material-ui/core';
import Audio               from '../../../../data/player/Audio';
import { useTranslation }  from 'react-i18next';
import ManualAdjustment    from './ManualAdjustment';
import Interval            from './Interval';
import PlayControls        from './PlayControls';
import Button from "../../../../inputs/Button";

const Controls = React.forwardRef((props, ref) => {
	const { record, onTimeUpdate, onTimingSet, onTimeUnset, cache, selected, fullHour, onTimeEdited, disabled, timingDisabled } = props;
	const { t } = useTranslation();
	const [isPaused, setIsPaused] = useState(true);

	const handleSecondsAdjust = amount => {
		if (amount === 'start') ref.current.currentTime = 0;
		else ref.current.currentTime += amount;
	};

	return (
		<Grid container spacing={3} justify='space-between' alignItems='center'>
			<Grid item xs={12}>
				<Grid container spacing={3} alignItems='center' justify='space-between'>
					<Grid item>
						<PlayControls
							disabled={disabled}
							mediaRef={ref}
							isPaused={isPaused}
							setIsPaused={setIsPaused}
						/>
					</Grid>

					<Grid item>
						<Button
							disabled={disabled || timingDisabled}
							variant='contained'
							color='primary'
							onClick={() => onTimingSet(ref.current.currentTime)}
						>
							{t('record_lyrics_timing_set')}
						</Button>
					</Grid>

					<Grid item>
						<Button
							disabled={disabled}
							variant='outlined'
							color='primary'
							onClick={() => handleSecondsAdjust('start')}
						>
							{t('record_lyrics_timing_start')}
						</Button>
					</Grid>

					<Grid item>
						<Interval handleSecondsAdjust={handleSecondsAdjust} disabled={disabled}/>
					</Grid>


					<Grid item>
						<Audio
							disabled={disabled}
							onPause={() => setIsPaused(true)}
							onPlay={() => setIsPaused(false)}
							ref={ref}
							mp3={record.Complete_Preview.mp3}
							onTimeUpdate={onTimeUpdate}
						/>
					</Grid>
				</Grid>

			</Grid>

			<Grid item xs={12}>
				<ManualAdjustment
					disabled={disabled || timingDisabled}
					onTimeEdited={onTimeEdited}
					fullHour={fullHour}
					record={record}
					selected={selected}
					cache={cache}
					onTimeUnset={onTimeUnset}
				/>
			</Grid>

		</Grid>
	);
});

export default Controls;
