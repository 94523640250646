import React, {Fragment, useEffect, useState} from 'react';
import {connect}                              from 'react-redux';
import {fetchMembershipUser}                  from '../../../store/actions/fanclub/MembershipAction';
import {withRouter}                           from 'react-router';
import Grid                                   from '@material-ui/core/Grid';
import {Title}                                from '../../../components/common/typography/Title';
import Skeleton                               from '@material-ui/lab/Skeleton';

import OrdersTable            from '../../../components/common/order/table/Table';
import Profile                from '../../../components/common/membership/members/edit/Profile';
import Plan                   from '../../../components/common/membership/members/edit/Plan';
import Detail                 from '../../../components/common/membership/members/edit/Detail';
import MembershipStatus       from '../../../components/common/data/chip/MembershipStatus';
import {setOrdersFilters}     from '../../../store/actions/OrderAction';
import AppBarTitle            from '../../../components/common/typography/AppBarTitle';
import {useTranslation}       from 'react-i18next';
import HasRight               from '../../../components/Route/HasRight';
import Toolbar                from '../../../components/common/typography/Toolbar';
import {getPXLDashboardRoute} from '../../../router/routes/pxl/factory/general';
import Redirect               from '../../../components/Route/Redirect';
import HasCapability          from '../../../components/Route/HasCapability';
import FanclubActivator       from '../../../components/common/fanclub/FanclubActivator';

const IMG_VARIATION = 'strip&scale_crop=400x400&format=jpeg';

const SubscriberEdit = ({membershipUser, loading, fetch, match, setOrdersFilters, selectedMembership}) => {
	const [enableOrdersDisplay, setEnableOrdersDisplay] = useState(false);
	const {t} = useTranslation();

	useEffect(() => {
		fetch(match.params.membershipUser)
			.then(mu => {
				setOrdersFilters({
					User_Billing__: mu.User_Billing__,
					Realm__: mu.User.Realm__,
					value:mu.User.Profile.Display_Name
				});
				setEnableOrdersDisplay(true);
			});
	}, [fetch, setOrdersFilters, match]);


	return (

		<HasCapability fanclub deniedComponent={<Redirect target={getPXLDashboardRoute()}/>}>

			{membershipUser ?
				<AppBarTitle
					title={t('edition_membership_loaded', {membershipUser: membershipUser.User.Profile.Display_Name})}/>
				:
				<AppBarTitle title={t('edition_membership')}/>
			}

			<Grid container spacing={3} justify="space-evenly">
				{/* Title */}
				<Grid item xs={12} md={10}>
					{membershipUser &&
					<Title>{membershipUser.User.Profile.Display_Name} {t('members_membership')}</Title>}
					{!membershipUser && <Skeleton width={100}/>}
				</Grid>
				<Grid item xs={12} md={2}>
					{membershipUser &&
					<Fragment>{t('status')}: <MembershipStatus membership={membershipUser}/></Fragment>
					}
					{!membershipUser && <Skeleton/>}
				</Grid>

				<FanclubActivator/>

				{/* Profile Picture */}
				<Grid item lg={3} md={6} xs={12}>
					<Profile membershipUser={membershipUser}/>

				</Grid>
				{/* Product description */}
				<Grid item lg={4} md={6} xs={12}>
					<Plan membershipUser={membershipUser}/>
				</Grid>
				{/* Membership Actions */}
				<Grid item lg={4} md={12} xs={12}>
					<Detail membershipUser={membershipUser}/>
				</Grid>


			</Grid>
			<HasRight support>
				<Grid item xs={12}>
					<Toolbar title={t('payment_history_title')}/>
				</Grid>

				<Grid item xs={12}>
					<OrdersTable disabled={!enableOrdersDisplay}/>
				</Grid>
			</HasRight>
		</HasCapability>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedMembership: state.membership.selectedFanclub,
		membershipUser: state.membership.membershipUser,
		loading: state.membership.membershipUserLoading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: (id) => dispatch(fetchMembershipUser(id, {image_variation: IMG_VARIATION})),
		setOrdersFilters: (filters) => dispatch(setOrdersFilters(filters)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SubscriberEdit));
