import React, {useEffect, useState} from 'react';
import Alert from "@material-ui/lab/Alert";
import {useTranslation} from "react-i18next";
import {connect} from 'react-redux'

const PriceFeeNotice = ({data, low = false, shipping = false}) => {
    const {t} = useTranslation();
    const baseRate = data ? (data.Rate ? data.Rate.Rate : null) : null;
    const [rate, setRate] = useState(baseRate);
    const [rateLow, setRateLow] = useState(data ? (data.Rate ? data.Rate.Rate_Low : baseRate) : baseRate);
    const [rateShipping, setRateShipping] = useState(data ? (data.Rate ? data.Rate.Rate_Shipping : baseRate) : baseRate);
    
    useEffect(() => {
        const baseRate = data ? (data.Rate ? data.Rate.Rate : null) : null;
        setRate(baseRate)
        // If no rate_low ou shipping take base rate
        setRateLow(data ? (data.Rate ? data.Rate.Rate_Low : baseRate) : baseRate)
        setRateShipping(data ? (data.Rate ? data.Rate.Rate_Shipping : baseRate) : baseRate)
    }, [data])

    if (!rate) return <></>
    if (!rateLow && low) return <></>
    if (!rateShipping && shipping) return <></>

    return (
        <Alert severity="info">
            {
                t('price_fee_notice', {
                        rate: low ? rateLow : (shipping ? rateShipping : rate)
                    }
                )
            }
        </Alert>
    );
};

const mapStateToProps = (state) => {
    return {
        data: state.realm.paymentController
    }
}

export default connect(mapStateToProps)(PriceFeeNotice);