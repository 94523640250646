import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Grid} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import StripeForm from '../../../../inputs/Stripe/Stripe';
import Button from '../../../../inputs/Button';
import {success} from '../../../../../../store/actions/system/ToastAction';
import {useOrderProcess} from '../../../../../../hooks/api/useOrder';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {getPXLOrderRoute} from "../../../../../../router/routes/pxl/factory/order";

const Stripe = ({order, settings, setPaying, refreshOrder, setDialogActions = null}) => {
	const {t} = useTranslation();

	const [processingOrder, setProcessingOrder] = useState(false);
	const [processOrder] = useOrderProcess(order.Order__);

	const [stripeError, setStripeError] = useState(null);
	const [saveEnabled, setSaveEnabled] = useState(false);

	const [stripe, setStripe] = useState(null);
	const [stripeElements, setStripeElements] = useState(null);

	const [ccRemember, setCcRemember] = useState(true);

	useEffect(() => {
		setPaying(processingOrder);
	}, [processingOrder, setPaying]);

	useEffect(() => {
		if (!stripe) {
			return;
		}

		const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");

		if (!clientSecret) {
			return;
		}

		stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
			switch (paymentIntent.status) {
				case "succeeded":
					processOrder(settings.session, settings.method, {stripe_intent: 1})
						.then(d => {
							setProcessingOrder(false);
							refreshOrder(d);
						})
						.then(() => success('order_paid'));
					break;
				case "processing":
					setStripeError(t('payment_processing'));
					break;
				case "requires_payment_method":
					setStripeError(t('requires_payment_method'));
					break;
				default:
					setStripeError(t('unexpected_error'));
					break;
			}
		});
		// eslint-disable-next-line
	}, [stripe, setStripeError, processOrder, setProcessingOrder]);

	const onStripeChange = e => {
		setSaveEnabled(e.complete === true);
	};

	const handleProcess = async (e) => {
		e.preventDefault();

		if (!stripe || !stripeElements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		setProcessingOrder(true);

		const result = await stripe.confirmPayment({
			elements: stripeElements,
			redirect: 'if_required',
			confirmParams: {
				//setup_future_usage: ccRemember ? 'off_session' : undefined,
				payment_method_data: {
					billing_details: {
						name: `${order.Billing_User_Location.First_Name} ${order.Billing_User_Location.Last_Name}`,
						email: order.User.Email,
						address: {
							country: order.Billing_User_Location.Country__,
							postal_code: order.Billing_User_Location.Zip,
							state: order.Billing_User_Location.Province ?? '',
							city: order.Billing_User_Location.City ?? '',
							line1: order.Billing_User_Location.Address ?? '',
							line2: order.Billing_User_Location.Address2 ?? '',
						}
					}
				}, // Make sure to change this to your payment completion page
				return_url: `https://${window.location.host}${getPXLOrderRoute(order.Order__)}`,
			},
		});

		if (result.error) {
			if (result.error.type === "card_error" || result.error.type === "validation_error") {
				setStripeError(result.error.message);
			} else {
				setStripeError(t('unexpected_error'));
			}

			setProcessingOrder(false);
			return;
		}

		processOrder(settings.session, settings.method, {stripe_intent: 1})
			.then(d => {
				setProcessingOrder(false);
				refreshOrder(d);
			})
			.then(() => success('order_paid'));
	};

	useEffect(() => {
		if (!setDialogActions) return;
		setDialogActions((<Button
			color="primary"
			variant="contained"
			loading={processingOrder}
			disabled={!stripe || !stripeElements || processingOrder || !saveEnabled}
			onClick={handleProcess}
		>
			{t('purchase_btn')}
		</Button>));

		// eslint-disable-next-line
	}, [setDialogActions, processingOrder, stripe, stripeElements, saveEnabled]);


	useEffect(() => {
		setProcessingOrder(true)
		processOrder(settings.session, settings.method, {cc_remember: ccRemember ? 1 : 0, stripe_intent: 1})
			.then(d => {
				setProcessingOrder(false)
				refreshOrder(d);
			})
		// eslint-disable-next-line
	}, [ccRemember])

	return (<Grid container spacing={3}>

		{stripeError && <Grid item xs={12}>
			<Alert severity="error">
				{stripeError}
			</Alert>
		</Grid>}

		<Grid item xs={12}>
			<StripeForm
				settings={settings.fields}
				setStripeElements={setStripeElements}
				setStripe={setStripe}
				onChange={onStripeChange}
				disabled={processingOrder}
			/>
		</Grid>

		<Grid item xs={12}>
			<FormControlLabel
				control={<Checkbox
					checked={ccRemember}
					onChange={e => setCcRemember(e.target.checked)}
					color='primary'
				/>}
				label={t('stripe_cc_remember')}
			/>
		</Grid>


		{!setDialogActions && <Grid item xs={12}>
			<Grid container spacing={3} justify="center">
				<Grid item>
					<Button
						color="primary"
						variant="contained"
						loading={processingOrder}
						disabled={!stripe || !stripeElements || processingOrder || !saveEnabled}
						onClick={handleProcess}
					>
						{t('purchase_btn')}
					</Button>
				</Grid>
			</Grid>
		</Grid>}
	</Grid>);
};

export default Stripe;

Stripe.propTypes = {
	refreshOrder: PropTypes.func.isRequired,
	setPaying: PropTypes.func.isRequired,
	settings: PropTypes.object.isRequired,
	order: PropTypes.object.isRequired,
	setDialogActions: PropTypes.func // Used when the component is used in a dialog in order to integrate the action into the dialog actions
};
