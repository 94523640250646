import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import Select from "./Select";
import {connect} from 'react-redux'
import {fetchShippingProviders} from "../../../store/actions/store/ShippingAction";
import CircularProgress from "@material-ui/core/CircularProgress";

const ShippingProviderSelector = ({value, setValue, disabled, required, fetchShippingProviders, loading, list}) => {
    const {t} = useTranslation();

    useEffect(() => {
        fetchShippingProviders();
    }, [fetchShippingProviders])

    return <Select
        label={t('shipping_provider')}
        helperText={t('shipping_provider_helperText')}
        value={value}
        onChange={e => setValue(e.target.value)}
        optionKey='Key'
        optionValue='Value'
        options={list.map(p => {
            return {Key: p.code, Value: p.name}
        })}
        inputProps={{
            native: true,
            disabled: disabled || loading,
            variant: 'outlined',
            fullWidth: true,
            required: required
        }}
        selectProps={
            {
                endAdornment: loading ? <CircularProgress color="inherit" size={20}/> : undefined,
            }
        }
    />;
};

const mapStateToProps = (state) => {
    return {
        loading: state.shipping.loadingProviders,
        list: state.shipping.providers,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchShippingProviders: () => dispatch(fetchShippingProviders())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShippingProviderSelector);