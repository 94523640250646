import React, {useState} from 'react';
import {connect} from "react-redux";
import Button from "../../inputs/Button";
import uuid from "../../../utils/uuid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Overlay} from "../../feeback/Overlay";
import Loading from "../../feeback/loading/Loading";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {shippingsToImage} from "../../../../store/actions/store/ShippingAction";
import {useTranslation} from "react-i18next";
import PrintSizeDialog from "./PrintSizeDialog";

const useStyles = makeStyles((theme) => {
    return {
        iframe: {border: 'none', height: '0px', width: '0px', position: 'absolute'},
        pageBreak: {
            '@media print ': {
                pageBreakBefore: 'always',
            }
        },

    }
});

const PrintBtn = ({selected, children, generating, shippingsToImage, ...btnProps}) => {
    const [key] = useState(uuid());
    const classes = useStyles();
    const {t} = useTranslation();
    const [width, setWidth] = useState(991);
    const [height, setHeight] = useState(306);
    const [showDialog, setShowDialog] = useState(false);

    const onPrint = e => {
        const shippingIds = Object.keys(selected).map(l => selected[l].Catalog_Shipping__);

        const pageBreak = '<div style="page-break-before: always;"/>';

        shippingsToImage(shippingIds, width, height).then(imgs => {
            if(imgs.length < 1) return ;
            if('print_result' in imgs[0]) return // A printer has been linked to the platform, we don't need to open the printing dialog

            let iframeContent = '';

            imgs.forEach((img, idx) => {
                iframeContent += '<img width="' + width + '" height="' + height + '" alt="" src="' + img.Url + '"/>';
                if (idx !== imgs.length - 1) iframeContent += pageBreak
            });

            let pri = document.getElementById('to-print-' + key).contentWindow;

            pri.src = 'about:blank';
            pri.document.open();
            pri.document.write(
                '<body onload="window.print()" style="width: ' + width + 'px">'
                + iframeContent +
                '</body>');
            pri.document.close();
            pri.focus();
            //pri.print();
        })

    };

    return (
        <>
            <Button onClick={() => setShowDialog(true)} {...btnProps}>
                {children}
            </Button>
            <iframe title='print' id={'to-print-' + key} className={classes.iframe}/>
            <Overlay open={generating}>
                <Loading color='inherit' size={60} direction='column'>
                    <Grid item>
                        <Typography variant="h2" component="h2" color="inherit">
                            {t('generating_addresses_print')}
                        </Typography>
                    </Grid>
                </Loading>
            </Overlay>
            <PrintSizeDialog
                open={showDialog}
                setOpen={setShowDialog}
                onPrint={onPrint}
                width={width}
                setWidth={setWidth}
                height={height}
                setHeight={setHeight}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        selected: state.shipping.selectedShippings,
        generating: state.shipping.locationToImages,
        filters: state.shipping.shippingsFilters,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        shippingsToImage: (locationIds, width, height) => dispatch(shippingsToImage(locationIds, width, height))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PrintBtn);