import {rest} from "@karpeleslab/klbfw";
import {handleError} from "../../../components/utils/apiErrorHandler";
import {
    EVENT_CREATE,
    EVENT_CREATE_DONE,
    EVENT_DELETE,
    EVENT_DELETE_DONE,
    EVENT_FETCH,
    EVENT_FETCH_DONE,
    EVENT_FETCH_NEXT,
    EVENT_FETCH_NEXT_DONE,
    EVENT_FETCH_NEXT_LIST,
    EVENT_FETCH_NEXT_LIST_DONE,
    EVENT_LIST_FETCH,
    EVENT_LIST_FETCH_DONE,
    EVENT_SET_FILTERS,
    EVENT_SET_PAGING,
    EVENT_UPDATE,
    EVENT_UPDATE_DONE
}                from "../../reducers/planner/EventReducer";
import {success} from "../system/ToastAction";
import moment    from 'moment';

export const setEventsPaging = (newPaging) => {
    return (dispatch, getState) => {
        dispatch({type: EVENT_SET_PAGING, paging: {...getState().event.eventsPaging, ...newPaging}});
    }
};

export const setEventsFilters = (newFilters) => {
    return (dispatch, getState) => {
        dispatch({type: EVENT_SET_FILTERS, filters: newFilters});
    }
};

export const fetchEvents = () => {
    return (dispatch, getState) => {
        dispatch({type: EVENT_LIST_FETCH});

        const params = {
            ...getState().event.eventsFilters,
            ...getState().event.eventsPaging,
        };

        const plannerId = getState().schedule.planner.Planner__;

        return rest('Planner/' + plannerId + '/Event', 'GET', params)
            .then(data => {
                dispatch({type: EVENT_LIST_FETCH_DONE, events: data.data, paging: data.paging});
                return data.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const fetchEvent = (id) => {
    return (dispatch, getState) => {
        dispatch({type: EVENT_FETCH});
        return rest('Planner/Event/' + id)
            .then(data => {
                dispatch({type: EVENT_FETCH_DONE, event: data.data});
                return data.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const createEvent = (cmsId, title, language, date, reserveStartDate, reserveEndDate, reserveType, location, freeCancelDate = null, shortContents = '', contents = '') => {
    return (dispatch, getState) => {
        dispatch({type: EVENT_CREATE});
        const plannerId = getState().schedule.planner.Planner__;

        return new Promise((resolve, reject) => {
            const entryParams = {
                Language__: language,
                Title: title,
                Short_Contents: shortContents,
                Contents: contents
            };

            const eventParams = {
	            Reserve_Type: reserveType,
                Event_Date: date,
                Visible: 'N',
                Name: title,
                Reservation_Start: reserveStartDate,
                Reservation_End: reserveEndDate,
                Free_Cancel_End: freeCancelDate,
                Event_Type: 'pxl',
                Location: location,
	            Published:  '@' + moment().unix(),
            };

            rest('Content/Cms/' + cmsId + '/Entry', 'POST', entryParams)
                .then(d => {
                    eventParams.Content_Cms_Entry__ = d.data.Content_Cms_Entry__;
                    return rest('Planner/' + plannerId + '/Event', 'POST', eventParams)
                })
                .then(d => {
                    success('event_create_success');
                    dispatch({type: EVENT_CREATE_DONE});
                    resolve(d.data);
                })
                .catch(reject)

        }).catch((err) => {
            dispatch({type: EVENT_CREATE_DONE});
            handleError(getState, dispatch, err)
        });

    }
};

export const deleteEvent = (id) => {
    return (dispatch, getState) => {
        dispatch({type: EVENT_DELETE});

        return rest('Planner/Event/' + id, 'DELETE')
            .then(d => {
                dispatch({type: EVENT_DELETE_DONE});
                success('event_delete_success');
                return d;
            }).catch((err) => {
                dispatch({type: EVENT_DELETE_DONE});
                handleError(getState, dispatch, err)
            });
    }
};

export const updateEvent = (id, data) => {
    return (dispatch, getState) => {
        dispatch({type: EVENT_UPDATE});
        return rest('Planner/Event/' + id, 'PATCH', data)
            .then((d) => {
                success('event_update_success');
                dispatch({type: EVENT_UPDATE_DONE, event: d.data});
                return d.data;
            }).catch((err) => {
                dispatch({type: EVENT_UPDATE_DONE});
                handleError(getState, dispatch, err)
            });
    }
};

export const fetchNextEvent = (plannerId) => {
    return (dispatch, getState) => {
        dispatch({type: EVENT_FETCH_NEXT, silent: true});
        return rest('Planner/' + plannerId + '/Event:next')
            .then((d) => {
                dispatch({type: EVENT_FETCH_NEXT_DONE, event: d.data});
                return d.data;
            }).catch((err) => {
                dispatch({type: EVENT_FETCH_NEXT_DONE, event: null});
                handleError(getState, dispatch, err)
            });
    }
};

export const fetchNextEventList = (plannerId, silent = false) => {
    return (dispatch, getState) => {
        dispatch({type: EVENT_FETCH_NEXT_LIST, silent: silent});
        return rest('Planner/' + plannerId + '/Event:next_list')
            .then((d) => {
                dispatch({type: EVENT_FETCH_NEXT_LIST_DONE, events: d.data});
                return d.data;
            }).catch((err) => {
                dispatch({type: EVENT_FETCH_NEXT_LIST_DONE, events: []});
                handleError(getState, dispatch, err)
            });
    }
};
