import React, {useState} from 'react';
import {useTranslation}  from 'react-i18next';
import Dialog            from '@material-ui/core/Dialog';
import DialogTitle       from '@material-ui/core/DialogTitle';
import DialogContent     from '@material-ui/core/DialogContent';
import Grid              from '@material-ui/core/Grid';
import TextField         from '@material-ui/core/TextField';
import DialogActions     from '@material-ui/core/DialogActions';
import Button            from '../../../../../inputs/Button';
import {connect}         from 'react-redux';
import {Box}             from '@material-ui/core';
import CountrySelector   from '../../../../../inputs/CountrySelector';
import {
	createShippingFeeZoneValue,
	fetchShippingFeeZoneValues
}                        from '../../../../../../../store/actions/store/ShippingFeeAction';

const CreateDialog = ({zoneId, open, setOpen, creating, create, refresh}) => {
	const {t} = useTranslation();
	const [country, setCountry] = useState(null);
	const [province, setProvince] = useState('');

	const [saveDisabled] = useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	const handleCreate = (e) => {
		e.preventDefault();
		create(zoneId, {Country__: country ?? null, Province: province.trim() ?? null})
			.then((s) => refresh(zoneId))
			.then(handleClose);
	};

	return (
		<Dialog
			disableEnforceFocus
			open={open}
			aria-labelledby={t('create_shipping_fee_zone_value')}
			aria-describedby={t('create_shipping_fee_zone_value')}
			onEnter={() => {
				setCountry(null);
				setProvince('');
			}}
		>
			<form onSubmit={handleCreate}>
				<DialogTitle>{t('create_shipping_fee_zone_value')}</DialogTitle>
				<DialogContent>
					<Box py={2}>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<CountrySelector
									label={t('shipping_zone_country')}
									helperText={t('shipping_zone_country_helperText')}
									disabled={creating}
									fullWidth
									value={country}
									setValue={setCountry}
								/>
							</Grid>
							{country && <Grid item xs={12}>
								<TextField
									label={t('shipping_fee_zone_province')}
									helperText={t('shipping_fee_zone_province_helperText')}
									fullWidth variant="outlined" value={province}
									disabled={creating}
									onChange={e => setProvince(e.target.value)}
								/>
							</Grid>}
						</Grid>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button color="default" onClick={handleClose} disabled={creating}>
						{t('close_btn')}
					</Button>

					<Button color="primary" type="submit" variant="contained"
					        disabled={creating || saveDisabled}
					        loading={creating}>
						{t('create_btn')}
					</Button>
				</DialogActions>
			</form>
		</Dialog>

	);
};
const mapStateToProps = (state) => {
	return {
		creating: state.shippingFee.zoneValueCreating,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		create: (zoneId, data) => dispatch(createShippingFeeZoneValue(zoneId, data)),
		refresh: (zoneId) => dispatch(fetchShippingFeeZoneValues(zoneId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDialog);