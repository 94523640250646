import React from 'react';
import {connect} from 'react-redux'
import moment from "moment";
import Skeleton from "@material-ui/lab/Skeleton";
import {getSiteUserEditRoute} from "../../../../../../../router/routes/site/factory/user";
import {useTranslation}       from "react-i18next";


// action
import { chatLogDelete } from "../../../../../../../store/actions/planner/ChatAction";

// component
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { Avatar } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';

// icon
import DeleteIcon from '@material-ui/icons/Delete';
import Link from "../../../../../typography/Link";

const Row = ({chatLog, chatLogDelete}) => {
	const {t} = useTranslation();

	return (
			<TableRow>
				<TableCell>
					{!chatLog && <Skeleton/>}
					{(chatLog) && 
						<Tooltip title={t('chat_log_delete')} aria-label={t('chat_log_delete')} >
							<DeleteIcon color="primary" onClick={() => {chatLogDelete()}} />
						</Tooltip>
					}
				</TableCell>
				<TableCell>
					{!chatLog && <Skeleton/>}
					{(chatLog) && moment(parseInt(chatLog.Date.unixms)).format('LL LTS')}
				</TableCell>
				<TableCell>
					{!chatLog && <Skeleton/>}
					{(chatLog) && t(`chat_type_${chatLog.Type}`)}
				</TableCell>
				<TableCell
					align={'center'}
				>
					{!chatLog && <Skeleton/>}
					{(chatLog && chatLog.Profile) && <Link to={getSiteUserEditRoute(chatLog.Profile.User__)} target='_blank'>
						<Avatar style={{margin: "0 auto"}} alt={chatLog.Profile.Display_Name} src={chatLog.Profile.Media_Image ? chatLog.Profile.Media_Image.Url: undefined }/>
						{chatLog.Profile.Display_Name}
					</Link>}
				</TableCell>
				<TableCell
					style={{
						width: "50%"
					}}
				>
					{!chatLog && <Skeleton/>}
					{(chatLog) && chatLog.Message}
				</TableCell>
			</TableRow>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		chatLogDelete: () => dispatch(chatLogDelete()),
	}
};

export default connect(null, mapDispatchToProps)(Row);
