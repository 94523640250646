import Users                                     from "../../../pages/site/Users";
import User                                      from "../../../pages/site/User";
import {getSiteUserEditRoute, getSiteUsersRoute} from "./factory/user";

export default [
    {
        path: getSiteUsersRoute(),
        component: Users,
        exact: true,
        type: 'route',
        loginRequired: true,
    },
    {
        path: getSiteUserEditRoute(),
        component: User,
        type: 'route',
        loginRequired: true,
    },
]
