import React from 'react';
import TableRow from "@material-ui/core/TableRow";
import Skeleton from "@material-ui/lab/Skeleton";
import TableCell from "@material-ui/core/TableCell";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import moment from "moment";
import {connect} from 'react-redux'
import {useHistory} from "react-router-dom";
import {getSupportBoxTicketRoute} from "../../../../../router/routes/support/factory/boxes";

const useStyles = makeStyles(theme => ({
    email: {
        marginLeft: theme.spacing(1),
    },
    ticketRow: {
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#00000052',
        }
    },
    selected: {
        backgroundColor: '#00000052',
    },
    timeOk :{
        color: theme.palette.text.secondary,
    },
    time2Days :{
        color: theme.palette.text.primary,
    },
    time5Days :{
        color: theme.palette.warning.main,
    },
    timeUrgent :{
        color: theme.palette.error.main,
    }
}));

const Row = ({ticket, loading, selected, box}) => {
    const displayTicket = (!loading && ticket);
    const {t} = useTranslation();
    const classes = useStyles();
    const isSelected = (selected  && !loading)? selected.Support_Ticket__ === ticket.Support_Ticket__ : false;
    const history = useHistory();

    const handleSelect = () => {
        if (isSelected) return;
        history.push(getSupportBoxTicketRoute(box, ticket.Support_Ticket__))
    };


    const getLastUpdateColor = () => {
        const now = moment().unix();
        const diff = now - ticket.Last_Update.unix


        if(diff < 86400) // 24h
            return classes.timeOk
        if(diff < 86400 * 2) // 2 days
            return classes.time2Days
        if(diff < 86400 * 5) // 5 days
            return classes.time5Days

        return classes.timeUrgent
    }

    return (
        <TableRow className={classes.ticketRow + ' ' + (isSelected ? classes.selected : '')} onClick={handleSelect}>
            <TableCell>
                {!displayTicket && <Skeleton/>}
                {displayTicket &&

                <Grid container>
                    <Grid item xs={8}>


                        {!ticket.User.Email &&   <Typography variant="body1" component="p" color="textSecondary">{t('support_ticket_anonymous')}</Typography>}
                        {ticket.User.Profile.Display_Name &&   <Typography variant="body1" component="p" color="textSecondary">{ticket.User.Profile.Display_Name}</Typography>}
                        {ticket.User.Email &&   <Typography variant="body1" component="p" color="textSecondary">{ticket.User.Email}</Typography>}

                    </Grid>

                    <Grid item xs={4}>
                        <Typography gutterBottom variant="body2" component="span" className={getLastUpdateColor()}>
                            {moment(parseInt(ticket.Last_Update.unixms)).fromNow()}
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography gutterBottom variant="subtitle2">
                            {ticket.Subject}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography gutterBottom variant="body2" component="span" color="textSecondary">
                            {t('support_ticket_message_count', {messageCount: ticket.Message_Count})}
                        </Typography>
                    </Grid>

                </Grid>

                }
            </TableCell>
        </TableRow>
    );
};

const mapStateToProps = (state) => {
    return {
        selected: state.support.ticket
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Row);