import React, {useState} from 'react';
import { Grid} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import uuid from "../../../../utils/uuid";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteDialog from "./DeleteDialog";
import EditCategoryDialog from "./EditCategoryDialog";
import Button from "../../../inputs/Button";

const Tags = ({values, onChange, onAdd, onRemove, list, onDelete, onEdit, managable = false, disabled = false}) => {
    const [deleteDialogOpened, setDeleteDialogOpened] = useState(false);
    const [editDialogOpened, setEditDialogOpened] = useState(false);
    const [selectedTag, setSelectedTag] = useState(null);

    const openDeleteDialog = (tag) => {
        setSelectedTag(tag);
        setDeleteDialogOpened(true)
    };

    const openEditDialog = (tag) => {
        setSelectedTag(tag);
        setEditDialogOpened(true)
    };


    const add = (id) => () => {
        const newV = [...values];
        newV.push(id);
        onAdd(id);
        onChange(newV);
    };

    const remove = (id) => () => {
        const newV = values.filter(v => v !== id);
        onRemove(id);
        onChange(newV);
    };

    return (
        <Grid container spacing={2}>
            {list.map((t) => {
                    const checked = values.includes(t.Classify_Tag__);

                    return <Grid item xs={6} md={2} key={uuid()}>
                        <Grid container direction='column'>
                            <Button
                                color={checked ? 'primary' : 'default'}
                                variant='outlined'
                                startIcon={checked ? <CheckIcon/> : <CheckBoxOutlineBlankIcon/>}
                                onClick={checked ? remove(t.Classify_Tag__) : add(t.Classify_Tag__)}
                                disabled={disabled}
                            >
                                {t.Name}
                            </Button>
                            {managable && <Grid item xs={12}>
                                <Grid container direction='row' justify="space-between">
	                                {onDelete &&
	                                <IconButton onClick={e => openDeleteDialog(t)} color='secondary' size='small'>
		                                <DeleteIcon fontSize='small'/>
	                                </IconButton>
	                                }
                                    <IconButton onClick={e => openEditDialog(t)} color='primary' size='small'>
	                                    <EditIcon fontSize='small'/>
                                    </IconButton>
                                </Grid>
                            </Grid>}
                        </Grid>

                    </Grid>
                }
            )}
            {(managable && selectedTag && onDelete) &&
            <DeleteDialog onDeleted={onDelete} tag={selectedTag} setOpen={setDeleteDialogOpened}
                          open={deleteDialogOpened}/>}
            {(managable && selectedTag) &&
            <EditCategoryDialog onUpdated={onEdit} tag={selectedTag} setOpen={setEditDialogOpened}
                                open={editDialogOpened}/>}
        </Grid>
    );
};

export default Tags;
