import { REALM_FETCH_DONE, REALM_SELECT, REALM_SELECTING } from '../RealmReducer';
import { setContext }                                      from '@karpeleslab/klbfw';
import { USER_LOGOUT }                                     from './UserReducer';

const initState = () => {
	return {
		accesses: {},
		access: null,

		owner: false,
		admin: false,
		manager: false,
		viewer: false,
		support: false,

		groupLoading: true,
		group: null,

		revokingAccess: false,
		leavingGroup: false,
		grantingAccess: false,

		decodingLink: false,
		acceptingLink: false,

		groupuser: null,

	};
};

export const ACCESS_GROUP_FETCH = 'ACCESS_GROUP_FETCH';
export const ACCESS_GROUP_FETCH_DONE = 'ACCESS_GROUP_FETCH_DONE';

export const ACCESS_ACCEPT_LINK = 'ACCESS_ACCEPT_LINK';
export const ACCESS_ACCEPT_LINK_DONE = 'ACCESS_ACCEPT_LINK_DONE';

export const ACCESS_REVOKE = 'ACCESS_REVOKE';
export const ACCESS_REVOKE_DONE = 'ACCESS_REVOKE_DONE';

export const ACCESS_LEAVE = 'ACCESS_LEAVE';
export const ACCESS_LEAVE_DONE = 'ACCESS_LEAVE_DONE';

export const ACCESS_GRANT = 'ACCESS_GRANT';
export const ACCESS_GRANT_DONE = 'ACCESS_GRANT_DONE';

export const ACCESS_DECODE_LINK = 'ACCESS_DECODE_LINK';
export const ACCESS_DECODE_LINK_DONE = 'ACCESS_DECODE_LINK_DONE';

export const GET_GROUP_USER = 'GET_GROUP_USER';
export const GET_GROUP_USER_DONE = 'GET_GROUP_USER_DONE';


const AccessReducer = (state = initState(), action) => {
	switch (action.type) {
		case USER_LOGOUT:
			return initState();
		case ACCESS_ACCEPT_LINK:
			return { ...state, decodingLink: true };
		case ACCESS_ACCEPT_LINK_DONE:
			return { ...state, decodingLink: false };
		case ACCESS_DECODE_LINK:
			return { ...state, decodingLink: true };
		case ACCESS_DECODE_LINK_DONE:
			return { ...state, decodingLink: false };
		case ACCESS_LEAVE:
			return { ...state, leavingGroup: true };
		case ACCESS_LEAVE_DONE:
			return { ...state, leavingGroup: false };
		case ACCESS_GRANT:
			return { ...state, grantingAccess: true };
		case ACCESS_GRANT_DONE:
			return { ...state, grantingAccess: false };
		case ACCESS_REVOKE:
			return { ...state, revokingAccess: true };
		case ACCESS_REVOKE_DONE:
			return { ...state, revokingAccess: false };
		case ACCESS_GROUP_FETCH:
			return { ...state, groupLoading: true };
		case GET_GROUP_USER:
			return { ...state };
		case GET_GROUP_USER_DONE:
			return { ...state };
		case ACCESS_GROUP_FETCH_DONE:
			return { ...state, groupLoading: false, group: action.group };
		case REALM_FETCH_DONE:
			return { ...state, accesses: action.accesses };
		case REALM_SELECTING:
			setContext('g', state.accesses[action.realmId].user_group);
			return { ...state };
		case REALM_SELECT:
			if(!action.access) return { ...state };
			const access = action.access[action.realm.Realm__];
			setContext('g', access.user_group);

			const newRight = {
				owner: false,
				admin: false,
				manager: false,
				viewer: false,
				support: false,
			};

			switch (access.available) {
				case 'O':
					newRight.owner = true;
				// eslint-disable-next-line
				case 'A':
					newRight.admin = true;
				// eslint-disable-next-line
				case 'W':
					newRight.manager = true;
				// eslint-disable-next-line
				case 'C':
					newRight.support = true;
				// eslint-disable-next-line
				case 'R':
					newRight.viewer = true;
				// eslint-disable-next-line
				default:
					break;
			}

			return { ...state, access: access, ...newRight };
		default:
			return state;
	}

};

export default AccessReducer;
