import React, {useEffect, useState} from 'react';
import {Trans, useTranslation} from "react-i18next";
import Typography       from "@material-ui/core/Typography";
import Grid             from "@material-ui/core/Grid";
import Table            from "./Table";
import uuid             from "../../../../utils/uuid";
import {formatDuration} from "../../../../utils/time";
import * as moment      from "moment";
import Alert            from "@material-ui/lab/Alert";
import makeStyles       from "@material-ui/core/styles/makeStyles";
import Toolbar          from '../../../typography/Toolbar';
import AddIcon          from '@material-ui/icons/Add';
import Button           from '../../../inputs/Button';
import SelectorDialog   from './SelectorDialog';

// dirty
const useStyles = makeStyles(() => {
    return {
        fixAlert: {
            '& > .MuiAlert-message': {
                display: 'block',
            }
        }
    }
});

const Musics = ({value, setValue, disabled, isReview = false}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    //const durationInt = value.reduce((a, b) => +a + +b.Music_Label_Record.Duration, 0);
    const [intDuration, setIntDuration] = useState(0);
    const [duration, setDuration] = useState('');

    const [showAdd, setShowAdd] = useState(false);

    useEffect(() => {
        setDuration(formatDuration(moment.duration(intDuration, 'seconds')))
    }, [intDuration, setDuration]);

    useEffect(() => {
        const durationInt = value.reduce((a, b) => +a + +b.Music_Label_Record.Duration, 0);
        setIntDuration(parseInt(durationInt))
    }, [value]);

    const onAdd = (values) => {
        const filtered = [];
        let newDuration = intDuration;
        let pos = value.length;
        values.forEach(v => {
            const existing = value.filter(v => v.Music_Label_Record__ === value.Music_Label_Record__);
            if (existing.length > 0) return; // Cannot have the same record twice
            filtered.push({
                Music_Label_Album_Track__: uuid(),
                Music_Label_Record__: v.Music_Label_Record__,
                Music_Label_Record: v,
                Track: pos
            });
            pos++;
            newDuration +=  parseInt(v.Duration)
        })


        setIntDuration(newDuration);

        setValue([
            ...value,
            ...filtered
        ])
    };

    const onDelete = (music) => {
        const newValue = [];
        let newDuration = 0;
        value.forEach(v => {
            const vCopy = {...v};
            if (vCopy.Music_Label_Record__ === music.Music_Label_Record__) return;
            if (vCopy.Track > music.Track) vCopy.Track--;
            newValue.push(vCopy);
            newDuration += parseInt(vCopy.Music_Label_Record.Duration);
        });

        setIntDuration(newDuration);
        setValue(newValue);
    };

    const changeOrder = (music, offset) => {
        if (value.length < 2) return;
        const vCopy = [...value];

        for (let i = 0; i < vCopy.length; i++) {
            if (vCopy[i].Music_Label_Record__ !== music.Music_Label_Record__) continue;

            const targetIndex = offset > 0 ? i + 1 : i - 1;
            const targetOffset = offset > 0 ? -1 : +1;
            if (targetIndex < 0 || targetIndex >= vCopy.length) {
                break;
            } // something wrong

            const tmp = vCopy[i];
            tmp.Track += offset;

            vCopy[i] = vCopy[targetIndex];
            vCopy[targetIndex] = tmp;
            vCopy[i].Track += targetOffset;

            break;
        }

        setValue(vCopy);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Toolbar title={t('album_musics_title')}>
                    {!isReview &&
                    <Button variant='contained' color='primary'
                            onClick={()=>setShowAdd(true)}
                            disabled={disabled}
                            endIcon={<AddIcon/>}>
                        {t('add_track_btn')}
                    </Button>
                    }
                </Toolbar>
            </Grid>
            {!isReview && <Grid item xs={12}>
                <Grid container spacing={3} justify="space-between">
                    <Grid item>
                        <Typography variant="body2" color="textSecondary" component='p'>
                            <Trans i18nKey='album_musics_text'>
                                sample <br/>
                                <strong>sample</strong>
                            </Trans>
                        </Typography>

                    </Grid>
                </Grid>
            </Grid>
            }
            <Grid item xs={12} md={4}>
                <Trans i18nKey='album_musics_duration'>
                    sample <br/>
                    <strong>sample</strong>
                    {{duration}}
                </Trans>
            </Grid>
            <Grid item xs={12}>
                {/*Album limit is 74 minutes an 42 second*/}
                <Alert severity={(intDuration > ((74 * 60) + 42) || value.length < 1) ? 'warning' : "info"}
                       className={classes.fixAlert}>
                    <Trans i18nKey='album_musics_alert'>
                        sample <br/>
                        <strong>sample</strong>
                    </Trans>
                </Alert>
            </Grid>
            <Grid item xs={12}>
                <Table musics={value} onDelete={onDelete} changeOrder={changeOrder} disabled={disabled || isReview}/>
            </Grid>
            <SelectorDialog open={showAdd} setOpen={setShowAdd} existing={value} onAdd={onAdd}/>
        </Grid>
    );
};

export default Musics;