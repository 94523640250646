import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText                    from "@material-ui/core/DialogContentText";
import { GALLERY_VARIATION, IMG_VARIATION } from "./Gallery";
import DialogActions                        from "@material-ui/core/DialogActions";
import Grid                                 from "@material-ui/core/Grid";
import makeStyles                           from "@material-ui/core/styles/makeStyles";
import {connect}                            from 'react-redux'
import DeleteIcon                           from "@material-ui/icons/Delete";
import Typography                           from "@material-ui/core/Typography";
import {deleteEntryPicture}                 from "../../../../store/actions/CmsAction";
import { useTranslation }                   from 'react-i18next';
import Button                               from "../../inputs/Button";
import { mimeToType }                       from '../../../utils/misc';

const useStyles = makeStyles(theme => ({
    imgToInsert: {
        textAlign: 'center'
    },
	audio: {
		width: '100%'
	},
	video: {
		width: '100%'
	}
}));

const DeleteDialog = ({img, open, setOpen, deleting, entry, deleteEntryPicture}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        deleteEntryPicture(entry.Content_Cms_Entry__, img.Drive_Item__)
            .then(handleClose)
    };

	const getDisplay = (img) => {
		const type = mimeToType(img.Mime);
		switch (type) {
			case 'audio':
				return (
					<audio controls className={classes.audio}>
						<source src={img.View_Url} type={img.Mime}/>
					</audio>
				)
			case 'video':
				return (
					<video controls className={classes.video}>
						<source src={img.View_Url} type={img.Mime}/>
					</video>
				)
			default:
				return  (<img src={img.Media_Image[IMG_VARIATION]} alt={img.Filename}/>)

		}
	}

    return (
        <Dialog
            open={open}
            aria-labelledby="Delete gallery picture"
            aria-describedby="Delete gallery picture"
            fullWidth
        >
            <DialogTitle id="gallery-delete-title">{t('confirm_delete_picture_title')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="gallery-delete-description">
                    {t('confirm_delete_picture_desc')}
                    <br/>
                    <Typography variant="body1" color="textPrimary" component="span">{t('are_you_sure')}</Typography>
                </DialogContentText>
                <Grid container justify='center' alignItems='center' spacing={2}>
                    <Grid item xs={12} className={classes.imgToInsert}>
	                    {getDisplay(img)}
                    </Grid>
                    <Grid item xs={12}>

                    </Grid>

                </Grid>
            </DialogContent>

            <DialogActions>
                <Button variant='contained' color="primary" autoFocus onClick={handleClose} disabled={deleting}>
                    {t('back_btn')}
                </Button>
                <Button color="secondary" loading={deleting} disabled={deleting} endIcon={<DeleteIcon/>} onClick={handleDelete}>
                    {t('delete_btn')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {
        deleting: state.cms.deletingImage
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        deleteEntryPicture: (entryId, imgId) => dispatch(deleteEntryPicture(entryId, imgId, {...GALLERY_VARIATION})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDialog);
