import React from 'react';
import {useTranslation} from 'react-i18next';
import Select from "./Select";

const PlanFreeSelector = ({value, setValue, disabled, required}) => {
    const {t} = useTranslation();

    return <Select
        label={t('plan_free_selector')}
        helperText={t('plan_free_selector_helperText')}
        value={value}
        onChange={e => setValue(e.target.value)}
        optionKey='Key'
        optionValue='Value'
        options={[
            {Key: 'Y', Value: t('plan_free_selector_y')},
            {Key: 'N', Value: t('plan_free_selector_n')},
        ]}
        inputProps={{
            native:true,
            disabled: disabled,
            variant: 'outlined',
            fullWidth: true,
            required: required
        }}
    />;
};

export default PlanFreeSelector;