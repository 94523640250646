import MembersList from "../../../pages/artist/members/List";
import MemberCreate from "../../../pages/artist/members/Create";
import MemberEdit from "../../../pages/artist/members/Edit";
import {getMemberCreateRoute, getMemberEditRoute, getMembersRoute} from "./factory/members";

export default [
    {
        path: getMembersRoute(),
        component: MembersList,
        exact: true,
        type: 'route',
        loginRequired: true,
    },
    {
        path: getMemberCreateRoute(),
        component: MemberCreate,
        exact: false,
        type: 'route',
        loginRequired: true,
    },
    {
        path: getMemberEditRoute(),
        component: MemberEdit,
        exact: false,
        type: 'route',
        loginRequired: true,
    },
]