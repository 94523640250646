import React, {useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogContentText         from "@material-ui/core/DialogContentText";
import DialogContent             from "@material-ui/core/DialogContent";
import Grid                      from "@material-ui/core/Grid";
import TextField                                  from "@material-ui/core/TextField";
import {useTranslation}                           from "react-i18next";
import DialogTitle                                from "@material-ui/core/DialogTitle";
import DialogActions                              from "@material-ui/core/DialogActions";
import {connect}                                  from 'react-redux'
import { fetchGroup, getInviteLink, grantAccess, getGroupUser } from "../../../store/actions/system/AccessAction";
import Button                                     from "../inputs/Button";
import AccessSelector                             from "../inputs/AccessSelector";
import HasRight                                   from '../../Route/HasRight';
import { Divider }                                from '@material-ui/core';
import ClipboardCopyTextField                     from '../inputs/ClipboardCopyTextField';
import { getPXLJoinRoute }                        from '../../../router/routes/pxl/factory/general';
import FormRequiredField                          from '../feeback/FormRequiredField';

const CreateCategoryDialog = ({open, setOpen, currentRight, creating, loading, grantAccess, getInviteLink,artist,group, refresh, getGroupUser}) => {
    const {t} = useTranslation();
    const [email, setEmail] = useState('');
    const [access, setAccess] = useState('R');

    const [groupLink, setGroupLink] = useState('');

    const [saveDisabled, setSaveDisabled] = useState(true);
    const [emailIndexOf, setEmailIndexOf] = useState(null);

    useEffect(() => {
        setSaveDisabled((email.trim() === '' || access.trim() === ''))
    }, [email, access, setSaveDisabled]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleCreate = (e) => {
        e.preventDefault();
        grantAccess(email, access)
            .then(refresh)
            .then(handleClose)

    };

    const handleCreateLink = (e) => {
	    getInviteLink(group, access)
		    .then((data) => {
			    setGroupLink(data.Link)
		    })
    }

    const checkEmail = () => {
        getGroupUser(email)
        .then((data) => {
            setEmailIndexOf(data);
        }).catch((err) => {
            setEmailIndexOf(err);
        });
    }

    return (
        <Dialog
	        disableEnforceFocus
            open={open}
            aria-labelledby={t('grant_access')}
            aria-describedby={t('grant_access')}
	        onEnter={() => {
            	setEmail('');
	            setGroupLink('');
	            setAccess('R');
            }}
        >
            <form onSubmit={handleCreate}>
                <DialogTitle id="grant-access-title">{t('grant_access')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="grant-access-description">
                        {t('grant_access_description')}
                    </DialogContentText>
                    <Grid container spacing={2}>
	                    <FormRequiredField/>
                        <Grid item xs={12}>
                            <TextField label={t('grant_access_email_label')} fullWidth variant="outlined" value={email}
                                       required
                                       type='email'
                                       disabled={creating}
                                       onChange={e => setEmail(e.target.value)}
                                       onBlur={() => {checkEmail()}}
                                       />
                        </Grid>
                        <Grid item xs={12}>
                            <AccessSelector
                                currentRight={currentRight}
                                onChange={ e => setAccess(e.target.value)}
                                value={access}
                                inputProps={{
                                    variant: 'outlined',
                                    fullWidth: true,
                                    required: true,
                                    disabled: creating || loading
                                }}
                            />
                        </Grid>
	                    <HasRight admin hideLoading>
		                    <Grid item xs={12}>
			                    <Grid container spacing={2} justify='center'>
				                    <Grid item xs={12}>
					                    <Divider/>
				                    </Grid>
				                    {emailIndexOf !== null && emailIndexOf.result === "success"&&<Grid item xs={12}>
					                    <DialogContentText style={{ color:"red", fontWeight: "bold" }}>
						                    {t("group_user_already_registered")}
					                    </DialogContentText>
				                    </Grid>}
				                    <Grid item xs={12}>
					                    <DialogContentText>
						                    {t('create_invite_link_desc')}
					                    </DialogContentText>
				                    </Grid>
				                    {(groupLink) &&
				                    <ClipboardCopyTextField
					                    label={t('invite_link')}
					                    value={`${process.env.REACT_APP_ADMIN_URL}${getPXLJoinRoute(groupLink, artist.Music_Label_Artist__)}`}
					                    fullWidth
					                    variant='outlined'
					                    />
				                    }
			                    </Grid>
		                    </Grid>
	                    </HasRight>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="default" onClick={handleClose}  disabled={creating || loading}>
                        {t('close_btn')}
                    </Button>
	                <HasRight admin hideLoading>
		                <Button
			                color="primary"
			                variant='outlined'
			                onClick={handleCreateLink}
			                disabled={creating || loading || !access || groupLink}
			                loading={creating || loading}>
			                {t('create_link_btn')}
		                </Button>
	                </HasRight>
                    <Button color="primary" type='submit' variant='contained'
                            disabled={creating || loading || saveDisabled}
                            loading={creating || loading}>
                        {t('grant_btn')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>

    );
};

const mapStateToProps = (state) => {
    return {
        creating: state.access.grantingAccess,
        artist: state.artist.artist,
        loading: state.access.groupLoading,
	    group: state.access.access.user_group,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        grantAccess: (userId, access) => dispatch(grantAccess(userId, access)),
	    getInviteLink: (id, access) => dispatch(getInviteLink(id, access)),
        getGroupUser: (user) => dispatch(getGroupUser(user)),
        refresh: () => dispatch(fetchGroup())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCategoryDialog);
