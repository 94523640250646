import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {List} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import {Title} from "../typography/Title";
import {useTranslation} from "react-i18next";

const QuestionOptions = ({value, setValue, required, disabled}) => {
    const [newQuestion, setNewQuestion] = useState('');
    const [addEnabled, setAddEnabled] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        setAddEnabled(!!newQuestion.trim())
    }, [newQuestion, setAddEnabled]);

    const createEditHandler = idx => e => {
        let cpy = [...value];
        cpy[idx] = e.target.value;
        setValue(cpy);
    };

    const createDeleteHandler = idx => () => {
        let cpy = [...value];
        cpy.splice(idx, 1);
        setValue(cpy);
    };

    const onAdd = () => {
        let cpy = [...value];
        cpy.push(newQuestion.trim());
        setValue(cpy);
        setNewQuestion('');
    };

    return (
        <Grid container spacing={2} direction='column'>
            <Grid item xs={12}>
                <Title>{t('question_options')}</Title>
            </Grid>
            <Grid item xs={12}>
                <List>
                    {value.map((v, idx) =>
                        <ListItem key={'question-answer-' + idx}>
                            <TextField
                                label={t('question_options_index', {index: idx + 1})}
                                fullWidth variant="outlined"
                                value={v}
                                disabled={disabled}
                                onChange={createEditHandler(idx)}
                                InputProps={
                                    {
                                        endAdornment: (
                                            <IconButton color='secondary' size='small'
                                                        onClick={createDeleteHandler(idx)}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        )
                                    }
                                }
                            />

                        </ListItem>
                    )}

                    <ListItem>
                        <TextField
                            label={t('question_option_add')}
                            helperText={t('question_option_add_helperText')}
                            fullWidth variant="outlined"
                            value={newQuestion}
                            required={required && value.length < 1}
                            disabled={disabled}
                            onKeyPress={e => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    onAdd()
                                }
                            }}
                            onChange={e => {
                                setNewQuestion(e.target.value)
                            }}

                            InputProps={
                                {
                                    endAdornment: (
                                        <IconButton color='primary' disabled={!addEnabled} size='small'
                                                    onClick={() => onAdd()}>
                                            <AddIcon/>
                                        </IconButton>
                                    )
                                }
                            }
                        />
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    );
};

export default QuestionOptions;