import React     from 'react';
import RichAlert from '../../../feeback/RichAlert';
import { Trans } from 'react-i18next';

const StreamStatus = ({ error, ready }) => {
	if (error) {
		return (

			<RichAlert severity='warning'>
				<Trans i18nKey='stream_info_error'>
					sample<br/>
					<strong>sample</strong>
				</Trans>
			</RichAlert>
		);
	}

	if (!ready) {
		return (

			<RichAlert severity='info'>
				<Trans i18nKey='stream_info_loading'>
					sample<br/>
					<strong>sample</strong>
				</Trans>
			</RichAlert>
		);
	}

	return (

		<RichAlert severity='success'>
			<Trans i18nKey='stream_info_loaded'>
				sample<br/>
				<strong>sample</strong>
			</Trans>
		</RichAlert>
	);
};

export default StreamStatus;
