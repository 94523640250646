import React, { useEffect, useState } from 'react';
import {  Grid, TextField }           from '@material-ui/core';
import { connect }                    from 'react-redux';
import { updateEntryData }            from '../../../../store/actions/CmsAction';
import RichText                       from '../../inputs/RichText';
import ArticlePictures                from './ArticlePictures';
import { useTranslation }             from 'react-i18next';
import Button                         from "../../inputs/Button";
import HasRight                       from '../../../Route/HasRight';
import {Skeleton}                     from '@material-ui/lab';
import CmsAuthorAutocomplete          from '../../inputs/author/CmsAuthorAutocomplete';
import FormRequiredField              from '../../feeback/FormRequiredField';

const Content = ({ entryData, updateEntryData, updatingEntryData, isManager, disabled = false }) => {
	const [title, setTitle] = useState('');
	const [contents, setContents] = useState('');
	const [shortContents, setShortContents] = useState('');
	const [author, setAuthor] = useState('');
	const { t } = useTranslation();

	const [saveEnabled, setSaveEnabled] = useState(false);


	// This use to be sure the initial state is loaded only 1 time, this means if the entryData is changed by another component (update of its content)
	// this component will not change it's internal state. This means for example if we change the article title without safe but we safe some new tags, the articles title changes will no be lost
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		if (!entryData || loaded) return;
		setTitle(entryData.Title);
		setShortContents(entryData.Short_Contents);
		setContents(entryData.Contents);
		setAuthor(entryData?.Content_Cms_Author ?? null);
		setLoaded(true);

	}, [entryData, loaded]);

	useEffect(() => {
		if (!entryData || !loaded) return;

		if ((author?.Content_Cms_Author__ ?? null) !== (entryData?.Content_Cms_Author__ ?? null)) {
			setSaveEnabled(true);
			return;
		}

		if (title !== entryData.Title) {
			setSaveEnabled(true);
			return;
		}

		if (contents !== entryData.Contents) {
			setSaveEnabled(true);
			return;
		}

		if (shortContents !== entryData.Short_Contents) {
			setSaveEnabled(true);
			return;
		}

		setSaveEnabled(false);
	}, [entryData, title, contents, shortContents, author, loaded]);

	const handleSubmit = (e) => {
		e.stopPropagation();
		e.preventDefault();

		const data = {
			Title: title,
			Contents: contents,
			Short_Contents: shortContents,
			Content_Cms_Author__:author?.Content_Cms_Author__ ?? null
		};

		updateEntryData(entryData.Content_Cms_Entry_Data__, data)
			.then(d => {
				setContents(d.Contents);
			});

	};

	return (
		<form onSubmit={handleSubmit}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<ArticlePictures entryData={entryData} disabled={disabled}/>
				</Grid>

				<FormRequiredField/>

				<Grid item xs={12}>
					<TextField disabled={updatingEntryData || !isManager || disabled} variant='outlined' fullWidth
					           label={t('title_lbl')} value={title}
					           onChange={(e) => setTitle(e.target.value)}/>
				</Grid>
				<Grid item xs={12}>
					<TextField disabled={updatingEntryData || !isManager || disabled} variant='outlined' fullWidth
					           label={t('short_contents_lbl')}
					           value={shortContents}
					           onChange={(e) => setShortContents(e.target.value)}/>
				</Grid>

				<Grid item xs={12}>
					<HasRight admin loadingComponent={<Skeleton/>} >
						<CmsAuthorAutocomplete
							cmsId={entryData.Content_Cms__}
							disabled={updatingEntryData || !isManager || disabled}
							required
							value={author}
							setValue={setAuthor}
						/>
					</HasRight>
				</Grid>

				<Grid item xs={12}>
					<RichText
						cmsId={entryData.Content_Cms__}
						entryId={entryData.Content_Cms_Entry__}
						disabled={updatingEntryData || !isManager || disabled}
						value={contents}
						onChange={(content, editor) => setContents(content)}
					/>
				</Grid>

				<Grid item xs={12}>
					<Grid container justify='space-between'>
						{entryData.Preview_Url && entryData.Preview_Url.full &&
						<Button
							color='primary'
							type='submit'
							variant='outlined'
							component='a'
							disabled={disabled}
							href={entryData.Preview_Url.full}
							target='_blank'
							rel='noopener noopener'
						>
							{t('preview_btn')}
						</Button>
						}

						{isManager &&
						<Button color='primary' type='submit' loading={updatingEntryData} variant='contained'
						        disabled={!saveEnabled || updatingEntryData || disabled}>
							{t('save_btn')}
						</Button>
						}

					</Grid>
				</Grid>
			</Grid>
		</form>
	);
};

const mapStateToProps = (state) => {
	return {
		updatingEntryData: state.cms.updatingEntryData,
		isManager: state.access.manager,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateEntryData: (entryDataId, data) => dispatch(updateEntryData(entryDataId, data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Content);
