import React, {useEffect, useState} from 'react';
import MultiLangTextField from "./MultiLangTextField";
import IconButton from "@material-ui/core/IconButton";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Tooltip from "@material-ui/core/Tooltip";
import {useTranslation} from "react-i18next";
import {deepCopy} from "../../utils/misc";

const getParent = (productId, value) => {
    let parent = null;

    for (let i = 0; i < value.length; i++) {
        if (!value[i].Catalog_Product__) continue;
        if (value[i].Catalog_Product__ === productId) return null;
        parent = value[i].Catalog_Product__;
    }

    return parent;
};

const ProductInput = ({value, productId, setValue, disabled = false, ...rest}) => {
    const [override, setOverride] = useState(false);
    const [parent, setParent] = useState(getParent(productId, value));
    const [original, setOriginal] = useState(value); // used when we revert to the value used defined by the parent.
    const {t} = useTranslation();

    useEffect(() => {
        setParent(getParent(productId, value))
    }, [value, setParent, productId]);

    const overrideHandler = (e) => {
        e.preventDefault()
        setOverride(!override);
        if (override) setValue(original);
        else setOriginal(deepCopy(value))
    };

    return (
        <MultiLangTextField
            disabled={disabled || (parent && !override)}
            value={value}
            setValue={setValue}
            {...rest}

            endAdornment={
                parent &&
                <Tooltip title={t(!override ? 'product_field_override_unlock' : 'product_field_override_lock')}
                         placement='top-end'>
                    <IconButton
                        onClick={overrideHandler}
                    >
                        {override && <LockOpenIcon/>}
                        {!override && <LockIcon/>}

                    </IconButton>
                </Tooltip>
            }

        />
    );
};

export default ProductInput;