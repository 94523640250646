import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'
import { withRouter } from "react-router";
import {getPXLLoginRoute} from "../../router/routes/pxl/factory/general";

class LoginRequired extends Component {
    render() {
        const {location, user, children, to} = this.props
        if(user)
            return <Fragment> {children}</Fragment>

        const returnTo =  encodeURIComponent(location.pathname+location.search+location.hash);

        return <Redirect from={location.pathname} to={to ?? (getPXLLoginRoute()+'?return_to='+returnTo)}/>
    }
}

const mapStateToProps = (state) => {
    return {
        user : state.user.user
    }
}

export default connect(mapStateToProps, null)(withRouter(LoginRequired));