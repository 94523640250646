import React, {useEffect, useState} from 'react';
import Dialog                       from '@material-ui/core/Dialog';
import DialogTitle                  from '@material-ui/core/DialogTitle';
import DialogContent                from '@material-ui/core/DialogContent';
import Grid                         from '@material-ui/core/Grid';
import TextField                    from '@material-ui/core/TextField';
import DialogActions                from '@material-ui/core/DialogActions';
import Button                       from '../Button';
import {useTranslation}             from 'react-i18next';
import {Box}                        from '@material-ui/core';
import {connect}                    from 'react-redux';
import {updateAuthor}               from '../../../../store/actions/AuthorAction';

const EditDialog = ({open, setOpen, author, editing, edit, onUpdated}) => {
	const {t} = useTranslation();
	const [name, setName] = useState(author.Name);
	const [saveEnabled, setSaveEnable] = useState(false);

	useEffect(() => {
		if (name.trim() && author.Name !== name.trim()) {
			setSaveEnable(true);
			return;
		}

		setSaveEnable(false);
	}, [author, name, setSaveEnable]);

	const handleClose = () => {
		setOpen(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		e.stopPropagation();
		edit(author.Content_Cms_Author__, {Name: name}).then((d) => {
			onUpdated(d)
			handleClose();
		});
	};

	return (
		<Dialog
			open={open}
			aria-labelledby={t('edit_author')}
			aria-describedby={t('edit_author')}
			fullWidth
			maxWidth='sm'
			onEnter={() => {
				setName(author.Name);
			}}
		>
			<form onSubmit={handleSubmit}>
				<DialogTitle id="edit-author-title">{t('edit_author')}</DialogTitle>
				<DialogContent>
					<Box py={2}>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<TextField
									disabled={editing}
									variant="outlined"
									fullWidth
									label={t('name_lbl')}
									required
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</Grid>
						</Grid>
					</Box>

				</DialogContent>
				<DialogActions>
					<Button color="default" onClick={handleClose} disabled={editing}>
						{t('close_btn')}
					</Button>
					<Button color="primary" variant="contained" loading={editing} disabled={editing || !saveEnabled}
					        type="submit">
						{t('edit_btn')}
					</Button>
				</DialogActions>
			</form>
		</Dialog>

	);
};

const mapStateToProps = (state) => {
	return {
		editing: state.author.editing,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		edit: (authorId, data) => dispatch(updateAuthor(authorId, data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDialog);
