import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {connect} from 'react-redux'
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from 'react-i18next';
import {fetchGroup, revokeAccess} from "../../../store/actions/system/AccessAction";
import Button from "../inputs/Button";

const DeleteDialog = ({open, setOpen, deleting, loading, member, revokeAccess, refresh}) => {
    const {t} = useTranslation();

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        revokeAccess(member.User.User__)
            .then(() => refresh())
            .then(() => handleClose())
    };

    return (
        <Dialog
            open={open}
            aria-labelledby={t('revoke_access')}
            aria-describedby={t('revoke_access')}
            fullWidth
        >
            <DialogTitle id="access-leave-title">{t('confirm_revoke_access_title')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="access-leave-desc">
                    {t('confirm_revoke_access_desc')}
                    <br/>
                    <Typography variant="body1" color="textPrimary" component="span">{t('are_you_sure')}</Typography>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button variant='contained' color="primary" autoFocus onClick={handleClose}
                        disabled={deleting || loading}>
                    {t('back_btn')}
                </Button>
                <Button color="secondary" disabled={deleting || loading} loading={deleting || loading}
                        endIcon={<DeleteForeverIcon/>} onClick={handleDelete}>
                    {t('delete_btn')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {
        deleting: state.access.revokingAccess,
        loading: state.access.groupLoading
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        revokeAccess: (id) => dispatch(revokeAccess(id)),
        refresh: () => dispatch(fetchGroup())
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(DeleteDialog);
