import React from 'react';
import {Route} from "react-router-dom";

const SetStatus = ({statusCode, children}) => {
    return (
        <Route
        render={({staticContext}) => {
            if (staticContext) staticContext.statusCode = statusCode;
            return children;
        }}
        />
    );
};

export default SetStatus;