import React from 'react';
import Grid from "@material-ui/core/Grid";
import {TextField}                                                                 from "@material-ui/core";
import {Facebook, Instagram, Reddit, Soundcloud, Tiktok, Twitch, Twitter, Youtube} from "./styles";
import Typography                                                                  from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import {useTranslation} from "react-i18next";
import MaterialLink from "@material-ui/core/Link";

const SnsLinks = ({values = [], onChange, viewMode = false, disabled=false, available = ['twitter', 'facebook']}) => {
    const {t} = useTranslation();

    const links = {
        twitter: {
            label: t('artist_sns_twitter_label'),
            placeholder: t('artist_sns_twitter_placeholder'),
            Adornment: Twitter,
        },
        facebook:
            {
                label: t('artist_sns_facebook_label'),
                placeholder: t('artist_sns_facebook_placeholder'),
                Adornment: Facebook,
            },
        youtube:
            {
                label: t('artist_sns_youtube_label'),
                placeholder: t('artist_sns_youtube_placeholder'),
                Adornment: Youtube,
            },
        instagram:
            {
                label: t('artist_sns_instagram_label'),
                placeholder: t('artist_sns_instagram_placeholder'),
                Adornment: Instagram,
            },
        soundcloud:
            {
                label: t('artist_sns_soundcloud_label'),
                placeholder: t('artist_sns_soundcloud_placeholder'),
                Adornment: Soundcloud,
            },
        twitch:
            {
                label: t('artist_sns_twitch_label'),
                placeholder: t('artist_sns_twitch_placeholder'),
                Adornment: Twitch,
            },
        tiktok:
            {
                label: t('artist_sns_tiktok_label'),
                placeholder: t('artist_sns_tiktok_placeholder'),
                Adornment: Tiktok,
            },
        reddit:
            {
                label: t('artist_sns_reddit_label'),
                placeholder: t('artist_sns_reddit_placeholder'),
                Adornment: Reddit,
            }
    };


    const isAvailable = (sns) => {
        return available.includes(sns)
    }

    const getValue = (sns) => {
        for (let i = 0; i < values.length; i++) {
            if (values[i].Type === sns) {
                return values[i].Link
            }
        }

        return '';
    };

    if (viewMode) {
        return (
            <Grid container spacing={3} justify="flex-start">
                {values.map(link => {
                    const Icon = links[link.Type].Adornment;
                    return (
                        <Grid item xs={2} key={'sns-view-link' + link.Type}>
                            <MaterialLink href={link.Link} target='_blank' rel="noopener noreferrer">
                                <Icon className={link.Type}/>
                            </MaterialLink>
                        </Grid>
                    )
                })}
            </Grid>)
    }

    const createOnChangeHandler = sns => e => {
        const link = e.target.value.trim();
        let newValues = [...values];
        for (let i = 0; i < newValues.length; i++) {
            if (newValues[i].Type === sns) {
                if (!link) {
                    // remove
                    newValues.splice(i, 1);
                } else {
                    newValues[i].Link = link;
                }
                onChange(newValues);
                return;
            }
        }


        if (!link) return;
        newValues.push({Type: sns, Link: link});
        onChange(newValues);
    };


    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h5" color="textPrimary" component="h5">
                    {t('artist_wizard_step_2_title_sns')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" color="textSecondary" component="p">
                    {t('artist_wizard_step_2_desc_sns')}
                </Typography>
            </Grid>
            {Object.keys(links).map((sns => {
                    if(!isAvailable(sns)) return null;
                    const Adornment = links[sns].Adornment;
                    return (
                        <Grid item xs={12} md={6} key={'sns-' + sns}>
                            <TextField
                                disabled={disabled}
                                type="url"
                                variant='outlined'
                                placeholder={links[sns].placeholder}
                                fullWidth
                                value={getValue(sns)}
                                label={links[sns].label}
                                onChange={createOnChangeHandler(sns)}
                                InputProps={
                                    {
                                        endAdornment:
                                            (<InputAdornment position="end">
                                                <Adornment className={sns}/>
                                            </InputAdornment>)
                                    }
                                }
                            />
                        </Grid>
                    )
                }
            ))}
        </Grid>
    );
};

export default SnsLinks;