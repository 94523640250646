import React from 'react';
import {useTranslation} from 'react-i18next';
import Select from "./Select";

const QuestionTypeSelector = ({value, setValue, disabled, required}) => {
    const {t} = useTranslation();

    return <Select
        label={t('question_type')}
        helperText={t('question_type_helperText')}
        value={value}
        onChange={e => setValue(e.target.value)}
        optionKey='Key'
        optionValue='Value'
        options={[
            {Key: 'string', Value: t('question_type_string')},
            {Key: 'select', Value: t('question_type_select')},
        ]}
        inputProps={{
            disabled: disabled,
            variant: 'outlined',
            fullWidth: true,
            required: required
        }}
    />;
};

export default QuestionTypeSelector;