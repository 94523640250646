import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {connect} from 'react-redux'
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from 'react-i18next';
import {deleteTemplate} from "../../../../store/actions/CmsAction";
import Button from "../../inputs/Button";

const DeleteDialog = ({open, setOpen, deleting, template, deleteTemplate, onDeleted}) => {
    const {t} = useTranslation();

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        deleteTemplate(template.Content_Cms_Template__)
            .then(() => {
                handleClose();
                onDeleted();
            })
    };

    return (
        <Dialog
            open={open}
            aria-labelledby="Delete article template"
            aria-describedby="Delete article template"
            fullWidth
        >
            <DialogTitle id="template-delete-title">{t('confirm_delete_template_title')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="template-delete-description">
                    {t('confirm_delete_template_desc')}
                    <br/>
                    <Typography variant="body1" color="textPrimary" component="span">{t('are_you_sure')}</Typography>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button variant='contained' color="primary" autoFocus onClick={handleClose} disabled={deleting}>
                    {t('back_btn')}
                </Button>
                <Button color="secondary" loading={deleting} disabled={deleting} endIcon={<DeleteForeverIcon/>} onClick={handleDelete}>
                    {t('delete_btn')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {
        deleting: state.cms.templateDeleting
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        deleteTemplate: (templateId) => dispatch(deleteTemplate(templateId))
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(DeleteDialog);
