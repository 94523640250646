import React, {useEffect, useState} from 'react';
import {withRouter}                 from 'react-router';
import {Grid}                       from '@material-ui/core';
import {connect}                    from 'react-redux';
import {fetchEntryData}             from '../../../store/actions/CmsAction';
import {useTranslation}             from 'react-i18next';
import LoadingAppBarTitle           from '../../../components/common/typography/LoadingAppBarTitle';
import Toolbar                      from '../../../components/common/typography/Toolbar';
import {useParams}                  from 'react-router-dom';
import Loading                      from '../../../components/common/feeback/loading/Loading';
import DefaultLocationSetter        from '../../../components/common/user/location/default/DefaultLocationSetter';
import OrderTranslation             from '../../../components/common/article/OrderTranslation';
import Flag                         from '../../../components/common/data/flag/Flag';
import Panel                        from '../../../components/common/data/tab/Panel';
import TranslationOptions           from '../../../components/common/article/TranslationOptions';
import Step                         from '@material-ui/core/Step';
import StepLabel                    from '@material-ui/core/StepLabel';
import Stepper                      from '@material-ui/core/Stepper';


const ArticleTranslate = ({entryData, fetchEntryData, user, loadingEntryData}) => {
	const {t} = useTranslation();
	const {entryDataId, language} = useParams();
	const [step, setStep] = useState(0);
	const [options, setOptions] = useState({});

	useEffect(() => {
		fetchEntryData(entryDataId);
	}, [entryDataId, fetchEntryData]);

	const handleNext = () => {
		setStep(step + 1);
	};

	const handleBack = () => {
		setStep(step - 1);
	};

	return (
		<Grid container spacing={3}>
			<LoadingAppBarTitle
				value={entryData}
				fallback={t('article_translate_title')}
				title={(v) => t('article_translate_title_title', {article: v})}
			/>
			<Grid item xs={12}>
				<Toolbar title={
					entryData ?
						t('article_translate_title_title', {article: entryData}) :
						t('article_translate_title')
				}/>
			</Grid>

			{!entryData && <Grid item xs={12}>
				<Loading/>
			</Grid>}
			{entryData && <Grid item xs={12}>
				<Grid justify="center" alignItems="center" container spacing={3}>
					<Grid item>
						<Flag code={entryData.Language__.split('-')[1].toLowerCase()}/>
					</Grid>
					<Grid item>
						{t('translating_to')}
					</Grid>
					<Grid item>
						<Flag code={language.split('-')[1].toLowerCase()}/>
					</Grid>
				</Grid>
			</Grid>}
			{(entryData && !user.Default_Billing_User_Location__) && <DefaultLocationSetter user={user}/>}
			{(entryData && user.Default_Billing_User_Location__) &&

			<Grid item xs={12}>
				<Stepper activeStep={step} alternativeLabel>
					<Step><StepLabel>{t('translate_tab_option')}</StepLabel></Step>
					<Step><StepLabel>{t('translate_tab_order')}</StepLabel></Step>
				</Stepper>

				<Panel value={step} index={0}>
					<TranslationOptions
						options={options}
						setOptions={setOptions}
						next={handleNext}
						sourceEntryData={entryData}
						sourceLang={entryData.Language__}
						targetLang={language}
						entryId={entryData.Content_Cms_Entry__}
					/>
				</Panel>
				<Panel value={step} index={1}>
					<OrderTranslation
						tier={options.tier ?? undefined}
						entryId={entryData.Content_Cms_Entry__}
						from={entryData.Language__}
						to={language}
						handleBack={handleBack}
					/>
				</Panel>

			</Grid>

			}

		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		entryData: state.cms.entryData,
		loadingEntryData: state.cms.loadingEntryData,
		user: state.user.user,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchEntryData: (id) => dispatch(fetchEntryData(id))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ArticleTranslate));
