import EditArtist from "../../../pages/artist/Edit";
import {getArtistRoute} from "./factory/artist";

export default [
    {
        path: getArtistRoute(),
        component: EditArtist,
        exact: true,
        type: 'route',
        loginRequired: true,
    },
]