import React, {useEffect} from 'react';
import Grid               from '@material-ui/core/Grid';
import AppBarTitle        from '../../../components/common/typography/AppBarTitle';
import Form               from '../../../components/common/music/record/Form';
import {useTranslation}   from 'react-i18next';
import {connect}          from 'react-redux';
import Loading            from '../../../components/common/feeback/loading/Loading';
import SelectRealm        from '../../SelectRealm';
import {fetchRecord}      from '../../../store/actions/discography/RecordAction';
import {useParams}        from 'react-router-dom';
import Toolbar            from '../../../components/common/typography/Toolbar';

const Edit = ({artist, selectedRealm, record, loading, fetchRecord}) => {
	const {t} = useTranslation();
	const {recordId} = useParams();

	useEffect(() => {
		fetchRecord(recordId);
	}, [fetchRecord, recordId]);

	if (!selectedRealm) return <SelectRealm/>;
	if (!artist || loading) return <Loading/>;

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('record_edit_title')}/>

			<Grid item xs={12}>
				<Toolbar title={t('record_edit_title')}/>
			</Grid>

			<Grid item xs={12}>
				<Form artist={artist} record={record}/>
			</Grid>
		</Grid>
	);
};


const mapStateToProps = (state) => {
	return {
		artist: state.artist.artist,
		selectedRealm: state.realm.selected,
		record: state.record.record,
		loading: state.record.recordLoading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchRecord: (id) => dispatch(fetchRecord(id))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
