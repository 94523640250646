import React, { useEffect, useState } from 'react';
import Free                           from './Methods/Free';
import OnFile                         from './Methods/OnFile';
import Stripe                         from './Methods/Stripe';
import Panel                          from '../../../data/tab/Panel';

const PaymentMethodContent = ({ order, selectedPaymentMethod, method, methodData, setPaying, refreshOrder, setDialogActions = null }) => {
	const [component, setComponent] = useState(null);

	useEffect(() => {
		switch (method) {
			case 'Free':
				setComponent(
					<Free
						order={order}
						setPaying={setPaying}
						refreshOrder={refreshOrder}
						settings={methodData}
						setDialogActions={setDialogActions}
					/>
				);
				break;
			case 'OnFile':
				setComponent(
					<OnFile
						order={order}
						setPaying={setPaying}
						refreshOrder={refreshOrder}
						settings={methodData}
						setDialogActions={setDialogActions}
					/>
				);
				break;
			case 'Stripe':
				setComponent(
					<Stripe
						order={order}
						setPaying={setPaying}
						refreshOrder={refreshOrder}
						settings={methodData}
						setDialogActions={setDialogActions}
					/>
				);
				break;
			default:
				return <></>;
		}

		// eslint-disable-next-line
	}, [setComponent, methodData]);

	if (!component) return <></>;

	return (
		<Panel index={method} value={selectedPaymentMethod} boxProps={{ p: 0 }}>
			{component}
		</Panel>
	);
};

export default PaymentMethodContent;