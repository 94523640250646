function hasKanji(str) {
// if the string is not provided, and if it's called directly on the string, we can access the text via 'this'
	if (!str) {
		str = this;
	}

	return str.match(/[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/);
}


function replaceSpecialChars(str) {
	// if the string is not provided, and if it's called directly on the string, we can access the text via 'this'
	if (!str) {
		str = this;
	}

	return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '') // Remove accents
		.replace(/([^\w]+|\s+)/g, '-') // Replace space and other characters by hyphen
		.replace(/--+/g, '-');    // Replaces multiple hyphens by one hyphen
}


// eslint-disable-next-line no-extend-native
String.prototype.hasKanji = hasKanji;
// eslint-disable-next-line no-extend-native
String.prototype.replaceSpecialChars = replaceSpecialChars;
