import {rest} from '@karpeleslab/klbfw'
import {handleError} from "../../components/utils/apiErrorHandler";
import {
	SUPPORT_CANNED_CREATE,
	SUPPORT_CANNED_CREATE_DONE,
	SUPPORT_CANNED_DELETE,
	SUPPORT_CANNED_DELETE_DONE,
	SUPPORT_CANNED_FETCH,
	SUPPORT_CANNED_FETCH_DONE,
	SUPPORT_CANNED_SET_FILTERS,
	SUPPORT_CANNED_SET_PAGING,
	SUPPORT_FETCH,
	SUPPORT_FETCH_DONE,
	SUPPORT_FETCH_TAGS,
	SUPPORT_FETCH_TAGS_DONE,
	SUPPORT_SELECTED_CANNED_FETCH,
	SUPPORT_SELECTED_CANNED_FETCH_DONE,
	SUPPORT_SELECTED_CANNED_UPDATE,
	SUPPORT_SELECTED_CANNED_UPDATE_DONE,
	SUPPORT_TICKET_CLOSE,
	SUPPORT_TICKET_CLOSE_DONE,
	SUPPORT_TICKET_CREATE,
	SUPPORT_TICKET_CREATE_DONE,
	SUPPORT_TICKET_FETCH,
	SUPPORT_TICKET_FETCH_DONE,
	SUPPORT_TICKET_MESSAGES_FETCH,
	SUPPORT_TICKET_MESSAGES_FETCH_DONE,
	SUPPORT_TICKET_REPLY,
	SUPPORT_TICKET_REPLY_DONE,
	SUPPORT_TICKET_SELECT,
	SUPPORT_TICKETS_FETCH,
	SUPPORT_TICKETS_FETCH_DONE,
	SUPPORT_TICKETS_SET_FILTERS,
	SUPPORT_TICKETS_SET_PAGING, SUPPORT_UPDATE, SUPPORT_UPDATE_DONE
} from '../reducers/SupportReducer';
import {success} from "./system/ToastAction";

export const fetchSupport = (id, refreshNewCount = false, silent = false) => {
    return (dispatch, getState) => {
        if (!refreshNewCount)
            dispatch({type: SUPPORT_FETCH});
        return rest('Support/' + id)
            .then((d) => {
                if (refreshNewCount && d.data.New_Ticket_Count === getState().support.support.New_Ticket_Count)
                    return d.data;
                dispatch({type: SUPPORT_FETCH_DONE, support: d.data});
                return d.data;
            }).catch((e) => {
		        dispatch({type: SUPPORT_FETCH_DONE});
                handleError(getState, dispatch, e, silent);
            })
    }
};

export const fetchTickets = (silent = false) => {
    return (dispatch, getState) => {
        dispatch({type: SUPPORT_TICKETS_FETCH, silent: silent});

        const params = {
            ...getState().support.ticketsFilters,
            ...getState().support.ticketsPaging
        };

        return rest('Support/' + getState().support.support.Support__ + '/Ticket', 'GET', params)
            .then(data => {
                dispatch({type: SUPPORT_TICKETS_FETCH_DONE, tickets: data.data, paging: data.paging});
                return data.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const setTicketsPaging = (newPaging) => {
    return (dispatch, getState) => {
        dispatch({type: SUPPORT_TICKETS_SET_PAGING, paging: {...getState().support.ticketsPaging, ...newPaging}});
    }
};

export const setTicketsFilters = (newFilters) => {
    return (dispatch, getState) => {
        dispatch({type: SUPPORT_TICKETS_SET_FILTERS, filters: newFilters});
    }
};

export const selectTicket = (ticket) => {
    return (dispatch, getState) => {
        dispatch({type: SUPPORT_TICKET_SELECT, ticket: ticket});
    }
};

export const fetchTicket = (ticketId) => {
    return (dispatch, getState) => {
        dispatch({type: SUPPORT_TICKET_FETCH});
        return rest('Support/Ticket/' + ticketId)
            .then((d) => {
                dispatch({type: SUPPORT_TICKET_FETCH_DONE, ticket: d.data});
                return d.data;
            }).catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const fetchMessage = (ticketId) => {
    return (dispatch, getState) => {
        dispatch({type: SUPPORT_TICKET_MESSAGES_FETCH});
        return rest('Support/Ticket/' + ticketId + '/Message')
            .then((d) => {
                dispatch({type: SUPPORT_TICKET_MESSAGES_FETCH_DONE, messages: d.data});
                return d.data;
            }).catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const fetchSupportTags = (classifyId) => {
    return (dispatch, getState) => {
        dispatch({type: SUPPORT_FETCH_TAGS});
        return rest('Classify/' + classifyId + '/Tag', 'GET', {Flags: ['category']})
            .then((d) => {
                dispatch({type: SUPPORT_FETCH_TAGS_DONE, tags: d.data});
                return d.data;
            }).catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};


export const replyToTicket = (ticketId, message) => {
    return (dispatch, getState) => {
        dispatch({type: SUPPORT_TICKET_REPLY});
        return rest('Support/Ticket/' + ticketId + ':postReply', 'POST', {message: message})
            .then(d => {
                success('support_ticket_reply_success');
                dispatch({type: SUPPORT_TICKET_REPLY_DONE});
                return d.data;
            }).catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const closeTicket = (ticketId) => {
    return (dispatch, getState) => {
        dispatch({type: SUPPORT_TICKET_CLOSE});
        return rest('Support/Ticket/' + ticketId, 'PATCH', {Status: 'closed'})
            .then(d => {
                success('support_ticket_close_success');
                dispatch({type: SUPPORT_TICKET_CLOSE_DONE});
                return d.data;
            }).catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};


export const fetchCanned = () => {
    return (dispatch, getState) => {
        dispatch({type: SUPPORT_CANNED_FETCH});

        const params = {
            ...getState().support.cannedFilters,
            ...getState().support.cannedPaging
        };

        return rest('Support/' + getState().support.support.Support__ + '/Canned', 'GET', params)
            .then(data => {
                dispatch({type: SUPPORT_CANNED_FETCH_DONE, canned: data.data, paging: data.paging});
                return data.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const setCannedPaging = (newPaging) => {
    return (dispatch, getState) => {
        dispatch({type: SUPPORT_CANNED_SET_PAGING, paging: {...getState().support.cannedPaging, ...newPaging}});
    }
};

export const setCannedFilters = (newFilters) => {
    return (dispatch, getState) => {
        dispatch({type: SUPPORT_CANNED_SET_FILTERS, filters: newFilters});
    }
};

export const createCanned = (title, lang, message) => {
    return (dispatch, getState) => {
        const params = {
            Language__: lang,
            Title: title,
            Message: message
        };

        dispatch({type: SUPPORT_CANNED_CREATE});
        return rest('Support/' + getState().support.support.Support__ + '/Canned', 'POST', params)
            .then((d) => {
                success('support_canned_create_success');
                dispatch({type: SUPPORT_CANNED_CREATE_DONE});
                return d.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const fetchSelectedCanned = (cannedId) => {
    return (dispatch, getState) => {
        dispatch({type: SUPPORT_SELECTED_CANNED_FETCH});
        return rest('Support/Canned/' + cannedId)
            .then(d => {
                dispatch({type: SUPPORT_SELECTED_CANNED_FETCH_DONE, canned: d.data});
                return d.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const updateCanned = (cannedId, title, message) => {
    return (dispatch, getState) => {
        const params = {
            Title: title,
            Message: message
        };

        dispatch({type: SUPPORT_SELECTED_CANNED_UPDATE});
        return rest('Support/Canned/' + cannedId, 'PATCH', params)
            .then(d => {
                dispatch({type: SUPPORT_SELECTED_CANNED_UPDATE_DONE, canned: d.data});
                success('support_canned_update_success');
                return d.data;
            }).catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const deleteCanned = (cannedId) => {
    return (dispatch, getState) => {
        dispatch({type: SUPPORT_CANNED_DELETE});
        return rest('Support/Canned/' + cannedId, 'DELETE')
            .then(d => {
                dispatch({type: SUPPORT_CANNED_DELETE_DONE});
                success('support_canned_delete_success');
                return d.data;
            }).catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const supportSelect = (support) => {
    return (dispatch, getState) => {
        dispatch({type: SUPPORT_FETCH_DONE, support: null});
    }
}

export const createTicket = (supportId, user, subject, message) => {
    return (dispatch, getState) => {
        dispatch({type: SUPPORT_TICKET_CREATE});
        const params = {
            subject: subject,
            message: message,
            userId: user,
        }
        return rest('Support/' + supportId + '/Ticket:adminCreate', 'POST', params)
            .then(d => {
                success('support_ticket_create_success')
                dispatch({type: SUPPORT_TICKET_CREATE_DONE});
                return d.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
}

export const updateSupport = (params) => {
	return (dispatch, getState) => {
		dispatch({type: SUPPORT_UPDATE});

		return rest('Support/' + getState().support.support.Support__, 'PATCH', params)
			.then(data => {
				success('support_update_success')
				dispatch({type: SUPPORT_UPDATE_DONE, support: data.data});
				return data.data;
			})
			.catch((err) => {
				dispatch({type: SUPPORT_UPDATE_DONE});
				handleError(getState, dispatch, err)
			})
	}
};
