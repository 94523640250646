import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Trans, useTranslation} from 'react-i18next';
import uuid from '../../utils/uuid';
import {Grid, Typography} from '@material-ui/core';
import Toolbar from '../../common/typography/Toolbar';
import Button from '../../common/inputs/Button';
import {Add} from '@material-ui/icons';
import ClassifyTagAutocomplete from '../../common/inputs/ClassifyTagAutocomplete';
import EmptyTags from '../../common/store/shop/EmptyTags';
import CreateCategoryDialog from '../../common/cms/entry/CreateCategoryDialog';

const Categories = ({
	                       handleNext,
	                       handleBack,
	                       categories,
	                       setCategories,
	                       realm,
	                       isAdmin,
	                       isManager,
	                       productManagingTags
                       }) => {
	const {t} = useTranslation();

	const [autocompleteKey, setAutoCompleteKey] = useState(uuid());
	const [createDialogOpened, setCreateDialogOpened] = useState(false);


	const refreshAutocompleteList = () => {
		setAutoCompleteKey(uuid());
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Toolbar disableGutters title={t('categories')}>
					{isManager &&
					<Button variant="contained" color="primary" startIcon={<Add/>}
					        onClick={() => setCreateDialogOpened(true)}>
						{t('add_btn')}
					</Button>
					}
				</Toolbar>
			</Grid>

			{categories.length > 0 && <Grid item xs={12}>
				<Typography variant="body2" color="textSecondary" component="p">
					<Trans i18nKey="product_categories_text">
						sample <br/>
						sample
					</Trans>
				</Typography>
			</Grid>}

			<Grid item xs={12}>
				<ClassifyTagAutocomplete
					isAdmin={isAdmin}
					isManager={isManager}
					disabled={!isManager || productManagingTags}
					key={autocompleteKey}
					classifyId={realm.Classify.Classify__}
					value={categories}
					setValue={setCategories}
				/>
			</Grid>

			{categories.length < 1 && <Grid item xs={12}><EmptyTags/></Grid>}

			<Grid item xs={12}>
				<Grid container justify="space-between" spacing={3}>

					<Grid item>
						<Button
							onClick={handleBack}
							variant={'outlined'}
							color="primary"
						>
							{t('back_btn')}
						</Button>
					</Grid>

					<Grid item>
						<Button onClick={handleNext} variant="contained" color="primary">
							{t('next_btn')}
						</Button>
					</Grid>
				</Grid>
			</Grid>

			{isManager && <CreateCategoryDialog
				open={createDialogOpened}
				setOpen={setCreateDialogOpened}
				classifyId={realm.Classify.Classify__}
				onCreated={refreshAutocompleteList}
			/>}

		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		realm: state.realm.selected,
		isAdmin: state.access.admin,
		isManager: state.access.manager,
		tags: state.product.selectedTags,
		productManagingTags: state.product.productManagingTags,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
