import React, { useEffect, useState } from 'react';
import Grid                           from '@material-ui/core/Grid';
import { Title }                      from '../../../../typography/Title';
import { Trans, useTranslation }      from 'react-i18next';
import { Typography }                 from '@material-ui/core';
import Button                         from '../../../../inputs/Button';
import DeleteOutlineIcon              from '@material-ui/icons/DeleteOutline';
import moment                         from 'moment';
import TextField                      from '@material-ui/core/TextField';
import { pad, trimLeft }              from '../../../../../utils/misc';
import { formatDuration }             from '../../../../../utils/lyrics';

const ManualAdjustment = ({ record, cache, selected, onTimeUnset, onTimeEdited, fullHour, disabled = false }) => {
	const { t } = useTranslation();
	const [minTiming, setMinTiming] = useState(moment.duration());
	const [maxTiming, setMaxTiming] = useState(moment.duration(parseInt(record.Duration), 'seconds'));

	const [hour, setHours] = useState(null);
	const [minutes, setMinutes] = useState(null);
	const [seconds, setSeconds] = useState(null);
	const [milliseconds, setMilliseconds] = useState(null);

	const [isEdited, setIsEdited] = useState(false);

	const format = (val, size) => {
		return pad(trimLeft(val, '0'), size);
	};

	const handleHourChange = e => {
		let val = e.target.value;
		if (!(/^\d+$/.test(val))) return;
		if (parseInt(val) > 60 || parseInt(val) < 0) return;

		val = format(val, 2);
		const check = moment.duration()
			.add(hour, 'hour')
			.add(parseInt(minutes), 'minutes')
			.add(parseInt(seconds), 'seconds')
			.add(parseInt(milliseconds), 'milliseconds');
		if (check >= maxTiming || check <= minTiming) return;

		setHours(val);
	};

	const handleMinuteChange = e => {
		let val = e.target.value;
		if (!(/^\d+$/.test(val))) return;
		if (parseInt(val) > 60 || parseInt(val) < 0) return;

		val = format(val, 2);
		const check = moment.duration()
			.add(parseInt(hour), 'hour')
			.add(val, 'minutes')
			.add(parseInt(seconds), 'seconds')
			.add(parseInt(milliseconds), 'milliseconds');
		if (check >= maxTiming || check <= minTiming) return;

		setMinutes(val);
	};

	const handleSecondChange = e => {
		let val = e.target.value;
		if (!(/^\d+$/.test(val))) return;
		if (parseInt(val) > 60 || parseInt(val) < 0) return;
		val = format(val, 2);
		const check = moment.duration()
			.add(parseInt(hour), 'hour')
			.add(parseInt(minutes), 'minutes')
			.add(val, 'seconds')
			.add(parseInt(milliseconds), 'milliseconds');
		if (check >= maxTiming || check <= minTiming) return;
		setSeconds(val);
	};

	const handleMillisecondChange = e => {
		let val = e.target.value;
		if (!(/^\d+$/.test(val))) return;
		if (parseInt(val) > 999 || parseInt(val) < 0) return;

		val = format(val, 3);
		const check = moment.duration()
			.add(parseInt(hour), 'hour')
			.add(parseInt(minutes), 'minutes')
			.add(parseInt(seconds), 'seconds')
			.add(val, 'milliseconds');
		if (check >= maxTiming || check <= minTiming) return;

		setMilliseconds(val);
	};

	useEffect(() => {
		const currentlyReadingIdx = selected - 1;
		const hasReadingLyrics = (currentlyReadingIdx >= 0 && cache[currentlyReadingIdx].info.timeCode !== null);
		if (hasReadingLyrics) {
			const time = moment.duration(cache[currentlyReadingIdx].info.timeCode, 'seconds');
			setHours(format(time.hours(), 2));
			setMinutes(format(time.minutes(), 2));
			setSeconds(format(time.seconds(), 2));
			setMilliseconds(format(time.milliseconds(), 3));
		}

		let prevLyrics = currentlyReadingIdx - 1;
		let found = null;
		while (prevLyrics >= 0 && found === null) {
			found = cache[prevLyrics].info.timeCode;
			prevLyrics--;
		}

		setMinTiming(found === null ? moment.duration() : moment.duration(found, 'seconds'));


		let nextLyrics = currentlyReadingIdx + 1;
		found = null;
		while (nextLyrics >= 0 && nextLyrics < cache.length && found === null) {
			found = cache[nextLyrics].info.timeCode;
			nextLyrics++;
		}

		const max = found == null ? moment.duration(parseInt(record.Duration), 'seconds') : moment.duration(found, 'seconds');
		setMaxTiming(max);

		// eslint-disable-next-line
	}, [selected, cache, setMinTiming, setMaxTiming]);

	const handleReset = () => {
		const currentlyReadingIdx = selected - 1;
		const hasReadingLyrics = (currentlyReadingIdx >= 0 && cache[currentlyReadingIdx].info.timeCode !== null);
		if (!hasReadingLyrics) return;
		const time = moment.duration(cache[currentlyReadingIdx].info.timeCode, 'seconds');
		setHours(format(time.hours(), 2));
		setMinutes(format(time.minutes(), 2));
		setSeconds(format(time.seconds(), 2));
		setMilliseconds(format(time.milliseconds(), 3));
	};

	useEffect(() => {
		const currentlyReadingIdx = selected - 1;
		const hasReadingLyrics = (currentlyReadingIdx >= 0 && cache[currentlyReadingIdx].info.timeCode !== null);
		if (!hasReadingLyrics) {
			setIsEdited(false);
			return;
		}

		const time = moment.duration(cache[currentlyReadingIdx].info.timeCode, 'seconds');
		if (time.milliseconds() !== parseInt(milliseconds) ||
			time.seconds() !== parseInt(seconds) ||
			time.minutes() !== parseInt(minutes) ||
			time.hours() !== parseInt(hour)
		)
			setIsEdited(true);
		else setIsEdited(false);

	}, [hour, minutes, seconds, milliseconds, setIsEdited, cache, selected]);

	const handleEdit = () => {
		const currentlyReadingIdx = selected - 1;
		const hasReadingLyrics = (currentlyReadingIdx >= 0 && cache[currentlyReadingIdx].info.timeCode !== null);
		if (!hasReadingLyrics) return;

		const value = moment.duration()
			.add(parseInt(hour), 'hour')
			.add(parseInt(minutes), 'minutes')
			.add(parseInt(seconds), 'seconds')
			.add(parseInt(milliseconds), 'milliseconds');

		onTimeEdited(currentlyReadingIdx, value.asSeconds());
	};

	const hasReadingLyrics = ((selected - 1) >= 0 && cache[selected - 1].info.timeCode !== null);
	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Title>{t('record_lyrics_manual_adjustment')}</Title>
			</Grid>

			<Grid item xs={6}>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<Typography variant='subtitle2'>{t('record_lyrics_currently_reading')}</Typography>
					</Grid>
					<Grid item xs={12}>
						{!hasReadingLyrics && t('record_lyrics_currently_reading_lyric_na')}
						{hasReadingLyrics && <Typography variant='subtitle1'>
							<Trans
								i18nKey='record_lyrics_currently_reading_lyric'
								values={{ number: selected, text: cache[selected - 1].content.text }}
							>
								sample<br/>
								<strong>sample</strong>
							</Trans>
						</Typography>
						}
					</Grid>
				</Grid>

			</Grid>
			<Grid item xs={6}>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						{hasReadingLyrics && <Grid container spacing={1} justify='space-between'>
							<Grid item>
								{t('record_lyric_adjustment_min', { value: formatDuration(minTiming) })}
							</Grid>
							<Grid item>
								{t('record_lyric_adjustment_max', { value: formatDuration(maxTiming) })}
							</Grid>
						</Grid>
						}
					</Grid>

					<Grid item xs={12}>
						<Grid container spacing={1}>
							{fullHour && <Grid item xs={3}>
								<TextField
									variant='outlined'
									disabled={!hasReadingLyrics || disabled}
									InputLabelProps={{ shrink: !!hour }}
									label={t('record_lyrics_timecode_hour')}
									type='number'
									onChange={handleHourChange}
									value={hour}
									InputProps={
										{
											step: 1,
											min: 0,
										}
									}
								/>
							</Grid>
							}
							<Grid item xs={fullHour ? 3 : 4}>
								<TextField
									variant='outlined'
									disabled={!hasReadingLyrics || disabled}
									InputLabelProps={{ shrink: !!minutes }}
									label={t('record_lyrics_timecode_minute')}
									type='number'
									onChange={handleMinuteChange}
									value={minutes}
									InputProps={
										{
											step: 1,
											min: 0,
											max: 60,
										}
									}
								/>
							</Grid>
							<Grid item xs={fullHour ? 3 : 4}>
								<TextField
									variant='outlined'
									disabled={!hasReadingLyrics || disabled}
									InputLabelProps={{ shrink: !!seconds }}
									label={t('record_lyrics_timecode_second')}
									type='number'
									onChange={handleSecondChange}
									value={seconds}
									InputProps={
										{
											step: 1,
											min: 0,
											max: 60,
										}
									}
								/>
							</Grid>
							<Grid item xs={fullHour ? 3 : 4}>
								<TextField
									variant='outlined'
									disabled={!hasReadingLyrics || disabled}
									InputLabelProps={{ shrink: !!milliseconds }}
									label={t('record_lyrics_timecode_millisecond')}
									type='number'
									onChange={handleMillisecondChange}
									value={milliseconds}
									InputProps={
										{
											step: 1,
											min: 0,
											max: 999,
										}
									}
								/>
							</Grid>
						</Grid>

					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={1} justify='space-between'>
							<Grid item>
								<Grid container spacing={1}>
									<Grid item>
										<Button
											size='small'
											disabled={!hasReadingLyrics || !isEdited || disabled}
											variant='contained'
											color='primary'
											onClick={handleEdit}
										>
											{t('record_lyrics_edit_timecode')}

										</Button>
									</Grid>
									<Grid item>
										<Button
											size='small'
											disabled={!hasReadingLyrics || !isEdited || disabled}
											variant='contained'
											color='secondary'
											onClick={handleReset}
										>
											{t('record_lyrics_cancel_timecode')}
										</Button>
									</Grid>
								</Grid>
							</Grid>


							<Grid item>
								<Button
									size='small'
									disabled={!hasReadingLyrics || disabled}
									variant='outlined'
									color='secondary'
									onClick={() => onTimeUnset(selected - 1)}
									startIcon={<DeleteOutlineIcon/>}
								>
									{t('record_lyrics_unset_timecode')}

								</Button>
							</Grid>
						</Grid>

					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default ManualAdjustment;
