import React, {useState} from 'react';
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardHeader from "@material-ui/core/CardHeader";
import Loading from "../feeback/loading/Loading";
import Card from "@material-ui/core/Card";
import {useTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import {green} from '@material-ui/core/colors';
import {fetchConsumerLink} from "../../../store/actions/system/OAuth2Action";
import {connect} from 'react-redux'

const BankOAuth = ({consumerInfo, oauth = null, fetchConsumerLink}) => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false)
    const handleLink = () => {
        setLoading(true)
        fetchConsumerLink(consumerInfo.OAuth2_Consumer__, window.location.href)
            .then((d) => {
                window.location = d.redirect_url;
                setLoading(false)
            })
    }
    return (
        <Card variant='outlined'>
            <CardContent>
                <CardActionArea onClick={handleLink} disabled={oauth !== null}>
                    <CardHeader
                        action={
                            !loading ?
                                <Grid container spacing={3}>

                                    <Grid item>
                                        <img alt={consumerInfo.Name}
                                             src={consumerInfo.Logo}
                                             {...consumerInfo.LogoDisplay}
                                        />
                                    </Grid>

                                </Grid>
                                :
                                <Loading thickness={1}/>
                        }
                        title={
                            <Grid container direction="row" alignItems="center">
                                {oauth !== null && <Grid item>
                                    <CheckIcon style={{color: green[500]}}/>
                                </Grid>}
                                <Grid item>
                                    {t('store_bank_' + consumerInfo.Name)}
                                </Grid>
                            </Grid>

                        }
                        subheader={t(oauth === null ? 'store_bank_link_info' : 'store_bank_link_enabled')}
                    />
                </CardActionArea>
            </CardContent>
        </Card>
    );
};


const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchConsumerLink: (consumerID, returnTo) => dispatch(fetchConsumerLink(consumerID, returnTo))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BankOAuth);
