import React, { Fragment, useEffect }                     from 'react';
import MaterialTable                                      from '@material-ui/core/Table';
import TableRow                                           from '@material-ui/core/TableRow';
import TableBody                                          from '@material-ui/core/TableBody';
import TableContainer                                     from '@material-ui/core/TableContainer';
import Row                                                from './Row';
import { connect }                                        from 'react-redux';
import TableFooter                                        from '@material-ui/core/TableFooter';
import { useTranslation }                                 from 'react-i18next';
import { fetchCanned, setCannedFilters, setCannedPaging } from '../../../../../store/actions/SupportAction';
import TableHead                                          from '@material-ui/core/TableHead';
import TableCell                                          from '@material-ui/core/TableCell';
import Empty                                              from './Empty';
import Pagination                                         from '../../../data/table/Pagination';

const Table = ({
	               canned, fetch, loading, filters, paging, count, setCannedPaging, disabled, mode = 'edit', value, setValue = () => {
	}
               }) => {
	const loadingData = [...Array(paging.results_per_page).keys()];
	const { t } = useTranslation();
	const displayLoading = () => loadingData.map((k) => <Row key={k} loading={true} canned={null}/>);
	const displayCanned = () => canned.map((u) => <Row key={u.Support_Canned__} loading={false} canned={u} mode={mode}
	                                                   value={value} setValue={setValue}/>);
	useEffect(() => {
		if (disabled) return;
		fetch();
	}, [fetch, filters, paging, disabled]);

	return (
		<Fragment>
			{(loading || canned.length > 0) &&
			<TableContainer>
				<MaterialTable aria-label='simple table'>
					<TableHead>
						<TableRow>
							<Pagination
								setPaging={setCannedPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
						<TableRow>
							<TableCell style={{ width: '64px' }}>{t('language_lbl')}</TableCell>
							<TableCell>{t('title_lbl')}</TableCell>
							<TableCell align='right'/>
						</TableRow>
					</TableHead>
					<TableBody>
						{(loading || disabled) && displayLoading()}
						{(!loading && !disabled) && displayCanned()}
					</TableBody>
					<TableFooter>
						<TableRow>
							<Pagination
								setPaging={setCannedPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
					</TableFooter>
				</MaterialTable>
			</TableContainer>
			}
			{(!loading && canned.length < 1) && <Empty/>}
		</Fragment>
	);

};


const mapStateToProps = (state) => {
	return {
		filters: state.support.cannedFilters,
		paging: state.support.cannedPaging,
		count: state.support.cannedPagingCount,
		loading: state.support.cannedLoading,
		canned: state.support.canned
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: (silent = false) => dispatch(fetchCanned(silent)),
		setCannedPaging: (newPaging) => dispatch(setCannedPaging(newPaging)),
		setCannedFilters: (newPaging) => dispatch(setCannedFilters(newPaging))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);

