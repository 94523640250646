import React from 'react';
import {useTranslation} from "react-i18next";
import Graphic from "../../../../../resources/images/albums.svg";
import EmptyState from "../../../feeback/State/Empty";

const Empty = () => {

    const {t} = useTranslation();
    return (
        <EmptyState
            title={t('site_albums_empty_title')}
            textI18Key='site_albums_empty_text'
            graphic={Graphic}
        />
    );

};

export default Empty;