import React                   from 'react';
import Grid                    from '@material-ui/core/Grid';
import AppBarTitle             from '../../../components/common/typography/AppBarTitle';
import {Trans, useTranslation} from 'react-i18next';
import {connect}               from 'react-redux';
import SelectRealm             from '../../SelectRealm';
import Table                   from '../../../components/common/store/shipping/table/Table';
import Filters                 from '../../../components/common/store/shipping/Filters';
import Alert                   from '@material-ui/lab/Alert';
import makeStyles              from '@material-ui/core/styles/makeStyles';
import BulkActions             from '../../../components/common/store/shipping/BulkActions';
import Toolbar                 from '../../../components/common/typography/Toolbar';
import HasCapability           from '../../../components/Route/HasCapability';
import {getPXLDashboardRoute}  from '../../../router/routes/pxl/factory/general';
import Redirect                from '../../../components/Route/Redirect';
import HasRight                from '../../../components/Route/HasRight';
import StoreActivator          from '../../../components/common/store/StoreActivator';

// dirty
const useStyles = makeStyles(() => {
	return {
		fixAlert: {
			'& > .MuiAlert-message': {
				display: 'block',
			}
		}
	};
});


const List = ({catalog, selectedRealm}) => {
	const {t} = useTranslation();
	const classes = useStyles();

	if (!selectedRealm || !catalog) return <SelectRealm/>;

	return (
		<Grid container spacing={3}>
			<HasCapability store deniedComponent={<Redirect target={getPXLDashboardRoute()}/>}>
				<AppBarTitle title={t('shipping_title')}/>
				<StoreActivator/>
				<Grid item xs={12}>
					<Toolbar title={t('shipping_title')}/>
				</Grid>
				<Grid item xs={12}>
					<Alert severity="info" className={classes.fixAlert}>
						<Trans i18nKey="shippings_page_info">
							sample<br/>
							<strong>sample</strong>
						</Trans>
					</Alert>
				</Grid>
				<Grid item xs={12}>
					<Filters/>
				</Grid>
				<HasRight manager hideLoading>
					<Grid item xs={12}>
						<BulkActions/>
					</Grid>
				</HasRight>
				<Grid item xs={12}>
					<Table/>
				</Grid>
			</HasCapability>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		catalog: state.store.catalog,
	};
};
export default connect(mapStateToProps)(List);
