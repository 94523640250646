import React            from 'react';
import Box              from "@material-ui/core/Box";
import Grid             from "@material-ui/core/Grid";
import Alert            from "@material-ui/lab/Alert";
import Typography              from "@material-ui/core/Typography";
import {Trans, useTranslation} from "react-i18next";
import RichAlert               from '../../../feeback/RichAlert';

const TicketValidateDisplay = ({reserve, event, success = true}) => {
    const {t} = useTranslation();

    const getPrice = seat => {
        const s = event.Seats.filter(c => c.Planner_Event_Seat__ === seat);
        if(s.length < 1) return "";
        return  s[0].Price.display;
    }
    
    return (
        <Box borderRadius={6} borderColor="grey.500" border={1} bgcolor={'background.paper'} p={2}>
            <Grid container direction='row' spacing={3} justify='center' alignItems='center'>
                <Grid item xs={12}>
                    {success && <Alert
                        severity='success'>{t('event_live_reserve_validate_success', {serial: reserve.Serial})}</Alert>
                    }
                    {!success && <Alert
                        severity='warning'>{t('event_live_reserve_validate_used', {serial: reserve.Serial})}</Alert>
                    }
                </Grid>
                <Grid item xs={6}>
                    <Grid container direction='column'>
                        <Typography variant='h6'>
                            {t('event_live_reserve_name')}
                        </Typography>
                        <Typography variant='subtitle2'>
                            {reserve.Name}
                        </Typography>
                        <Typography variant='subtitle1'>
                            {reserve.Name_Kana}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container direction='column'>
                        <Typography variant='h6'>
                            {t('event_live_reserve_tel')}
                        </Typography>
                        <Typography variant='subtitle2'>
                            {reserve.Tel}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container direction='column' justify='center'>
                        <Typography variant='h6'>
                            {t('event_live_reserve_serial')}
                        </Typography>
                        <Typography variant='subtitle2'>
                            {reserve.Serial}
                        </Typography>
                    </Grid>
                </Grid>
                {reserve.Allocated_Seat && <Grid item xs={6}>
                    <Grid container direction='column' justify='center'>
                        <Typography variant='h6'>
                            {t('event_live_reserve_allocated_seat')}
                        </Typography>
                        <Typography variant='subtitle2'>
                            {reserve.Allocated_Seat}
                        </Typography>
                    </Grid>
                </Grid>
                }
                <Grid item xs={6}>
                    <Grid container direction='column' justify='center'>
                        <Typography variant='h6'>
                            {t('event_live_reserve_seat')}
                        </Typography>
                        <Typography variant='subtitle2'>
                            {reserve.Planner_Event_Seat.Seat_Name}
                        </Typography>
                        <Typography variant='subtitle1'>
                            {reserve.Planner_Event_Seat.Description}
                        </Typography>
                    </Grid>
                </Grid>
                {reserve.Status !== 'reserve_paid' &&
                <Grid item xs={12}>
                    <RichAlert severity='warning'>
                        <Trans i18nKey='event_live_reserve_validate_unpaid' values={{price:getPrice(reserve.Planner_Event_Seat__)}}>
                            sample<br/>
                            <strong>sample</strong>
                        </Trans>
                    </RichAlert>
                </Grid>
                }
            </Grid>
        </Box>
    );
};

export default TicketValidateDisplay;