import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux'
import {fetch} from "../../../store/actions/system/CurrencyAction";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {Select} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import {useTranslation} from 'react-i18next';

const Currencies = ({loading, list, fetch, onChange, defaultValue = '', inputProps = {}, allowEmpty = false, value = undefined}) => {
    const currencyLabel = React.useRef(null);
    const [currencyLabelWidth, setCurrencyLabelWidth] = useState(0);
    const {t} = useTranslation();

    useEffect(() => {
        if (!currencyLabel || !currencyLabel.current) return;
        setCurrencyLabelWidth(currencyLabel.current.offsetWidth);
    }, [currencyLabel]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    if (loading) {
        return (
            <Skeleton/>
        )
    }

    return (
        <FormControl {...inputProps}>
            <InputLabel ref={currencyLabel} htmlFor='currency-select'>{t('currency_lbl')}</InputLabel>
            {!loading && <Select
                native
                value={value}
                onChange={onChange}
                labelWidth={currencyLabelWidth}
                inputProps={{
                    id: 'currency-select',
                }}
            >
                {allowEmpty && <option value='all'>{t('currency_all')}</option>}
                {list.map((v) =>
                    <option key={v.Currency__} value={v.Currency__}>{v.Name}</option>
                )}
            </Select>
            }
        </FormControl>
    );
};

const mapStateToProps = (state) => {
    return {
        loading: state.currency.loading,
        list: state.currency.currencies,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetch: (data) => dispatch(fetch())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Currencies);