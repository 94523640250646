import React, {Fragment, useEffect, useState} from 'react';
import {useMusicRecordAlbum}                  from '../../../../../hooks/api/label/music/record/useMusicRecord';
import TableContainer                         from '@material-ui/core/TableContainer';
import MaterialTable                          from '@material-ui/core/Table';
import TableHead                              from '@material-ui/core/TableHead';
import TableRow                               from '@material-ui/core/TableRow';
import TableCell                              from '@material-ui/core/TableCell';
import TableBody                              from '@material-ui/core/TableBody';
import Empty                                  from '../../../order/table/Empty';
import {useTranslation}                       from 'react-i18next';
import Row                                    from './Row';

const RecordAlbumTable = ({recordId}) => {
	const {t} = useTranslation();
	const [fetch] = useMusicRecordAlbum(recordId);
	const [albums, setAlbums] = useState(null);

	useEffect(() => {
		fetch().then(setAlbums);
	}, [fetch]);

	const loading = !albums;

	const loadingData = [...Array(5).keys()];

	const displayLoading = () => loadingData.map((k) => <Row key={k} album={null}/>);
	const displayOrders = () => albums.map((o, idx) => <Row key={o.Music_Label_Album__} album={o}/>);

	return (
		<Fragment>
			{(loading || albums.length > 0) &&
			<TableContainer>
				<MaterialTable aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>{t('tablecell_record_album_name')}</TableCell>
							<TableCell>{t('tablecell_record_album_position')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(loading) && displayLoading()}
						{(!loading) && displayOrders()}
					</TableBody>


				</MaterialTable>
			</TableContainer>
			}
			{(!loading && albums.length < 1) && <Empty/>}
		</Fragment>
	);
};

export default RecordAlbumTable;