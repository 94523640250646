import React from 'react';
import TableRow from "@material-ui/core/TableRow";
import Skeleton from "@material-ui/lab/Skeleton";
import TableCell from "@material-ui/core/TableCell";
import {useTranslation} from "react-i18next";
import {connect} from 'react-redux'
import {useHistory} from "react-router-dom";
import Flag from "../../../data/flag/Flag";
import SearchIcon from "@material-ui/icons/Search";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {getSupportCannedEditRoute} from "../../../../../router/routes/support/factory/canned";
import Button from "../../../inputs/Button";


const useStyles = makeStyles(theme => ({
    selected: {
        backgroundColor: '#00000052',
    },
    selectable: {
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#00000052',
        }
    },
}));

const Row = ({canned, loading, mode = 'edit', value, setValue}) => {
    const displayCanned = (!loading && canned);
    const {t} = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const isSelected = canned ? (value ? value.Support_Canned__ === canned.Support_Canned__ : false) : false;

    const handleActionClick = () => {
        if (mode === 'edit')
            history.push(getSupportCannedEditRoute(canned.Support_Canned__));
        else
            setValue(canned)
    };

    return (
        <TableRow
            className={(isSelected ? classes.selected : '') + ' ' + (mode === 'select' ? classes.selectable : '')}
            onClick={() => mode === 'select' ? handleActionClick() : ''}
        >
            <TableCell style={{width: '64px'}}>
                {!displayCanned && <Skeleton/>}
                {displayCanned && <Flag code={canned.Language__.split('-')[1].toLowerCase()}/>}
            </TableCell>
            <TableCell>
                {!displayCanned && <Skeleton/>}
                {displayCanned && canned.Title}
            </TableCell>
            <TableCell align='right'>
                {!displayCanned && <Skeleton/>}
                {displayCanned && <Button
                    variant={mode === 'edit' ? 'contained' : 'text'}
                    color="primary"
                    endIcon={mode === 'select' ? <ArrowForwardIcon/> : ''}
                    startIcon={mode === 'edit' ? <SearchIcon/> : ''}
                    onClick={handleActionClick}
                >
                    {mode === 'edit' ? t('view_btn') : t('select_btn')}
                </Button>}
            </TableCell>
        </TableRow>
    );
};

const mapStateToProps = (state) => {
    return {
        selected: state.support.ticket
    }
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(Row);