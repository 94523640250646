import React            from 'react';
import AppBarTitle      from '../components/common/typography/AppBarTitle';
import {useTranslation} from 'react-i18next';
import Grid             from '@material-ui/core/Grid';
import {connect}        from 'react-redux';
import Account          from '../components/common/profile/Account';
import Information      from '../components/common/profile/Information';
import Security         from '../components/common/profile/Security';
import OAuth            from '../components/common/profile/OAuth/OAuth';

const Profile = ({user}) => {
	const {t} = useTranslation();

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('profile_title', {username: user.Profile.Display_Name})}/>

			<Grid item xs={12}>
				<Account/>
			</Grid>

			<Grid item xs={12}>
				<Information/>
			</Grid>

			<Grid item xs={12}>
				<Security/>
			</Grid>

			<Grid item xs={12}>
				<OAuth/>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.user.user,
	};
};

export default connect(mapStateToProps)(Profile);
