import React              from 'react';
import { useTranslation } from 'react-i18next';
import Select             from './Select';

const PlanPeriodSelector = ({ value, setValue, disabled, required }) => {
	const { t } = useTranslation();

	return <Select
		label={t('plan_period_label')}
		helperText={t('plan_period_helperText')}
		value={value}
		onChange={e => setValue(e.target.value)}
		optionKey='Key'
		optionValue='Value'
		options={[
			{ Key: '1m', Value: t('plan_period_1m') },
			{ Key: '3m', Value: t('plan_period_3m') },
			{ Key: '6m', Value: t('plan_period_6m') },
			{ Key: '1y', Value: t('plan_period_1y') },
		]}
		inputProps={{
			native: true,
			disabled: disabled,
			variant: 'outlined',
			fullWidth: true,
			required: required
		}}
	/>;
};

export default PlanPeriodSelector;
