export const printElementById = (elem, css = '') =>
{
	let mywindow = window.open('', 'PRINT');

	mywindow.document.write('<html><head><title>' + document.title  + '</title>');
	mywindow.document.write('</head><body >');
	mywindow.document.write('<style>' +
		'@media print' +
		'{    ' +
		'    .no-print, .no-print *' +
		'    {' +
		'        display: none !important;' +
		'    }' +
		'}' + css +

		'</style>');
	mywindow.document.write(document.getElementById(elem).innerHTML);
	mywindow.document.write('</body></html>');
	mywindow.document.close(); // necessary for IE >= 10
	mywindow.focus(); // necessary for IE >= 10*/

	mywindow.print();
	mywindow.close();

	return true;
}