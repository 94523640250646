import React    from 'react';
import CartItem from './CartItem';

const CartItems = ({ items, refreshCart = null }) => {
	return (
		<>
			{items.map(product => <CartItem key={product.key} cartItem={product} refreshCart={refreshCart}/>)}
		</>
	);
};

export default CartItems;