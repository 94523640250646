import uuid from "../../../components/utils/uuid";
import {USER_LOGOUT} from "./UserReducer";

export class Toast {
    id = null;
    message = ''; // i18n translation key
    i18n = true;
    duration = 4000;
    type = 'info';

    constructor(message, i18n = true, duration = 2000, type = 'info') {
        this.message = message;
        this.i18n = i18n;
        this.id = uuid();
        this.duration = duration;
        this.type = type;
    }
}

const initState = () => {
    return {
        queue: [],
        current: null,
    };
};

export const TOAST_MSG = 'TOAST_MSG';
export const TOAST_RESET = 'TOAST_RESET';

const toastReducer = (state = initState(), action) => {
    switch (action.type) {
        case USER_LOGOUT:
            return initState();
        case TOAST_MSG:
            if (state.current === null) {
                return {...state, current: action.toast};
            }
            return {...state, queue: [...state.queue, action.toast]};

        case TOAST_RESET:
            let newMsg = state.queue.filter((t) => t.id !== action.toast.id);

            if (state.current && action.toast.id === state.current.id) { // the current is the one we want to reset
                let current = null; // let's reset the current
                if (newMsg.length > 0) current = newMsg.shift(); // if there is more in the queue set the first one as current and remove it from the queue

                return {...state, current: current, queue: newMsg};
            }

            // the message was in the queue and it's not the current so dont care about it.
            return {...state, queue: newMsg};
        default:
            return state;
    }

};

export default toastReducer