import React                      from 'react';
import TableRow                   from '@material-ui/core/TableRow';
import Skeleton                   from '@material-ui/lab/Skeleton';
import TableCell                  from '@material-ui/core/TableCell';
import EditIcon                   from '@material-ui/icons/Edit';
import { Link }                   from 'react-router-dom';
import Chip                       from '@material-ui/core/Chip';
import { useTranslation }         from 'react-i18next';
import CheckIcon                  from '@material-ui/icons/Check';
import { formatDuration }         from '../../../../utils/time';
import * as moment                from 'moment';
import defaultPicture             from '../../../../../resources/images/landscape-image.svg';
import Avatar                     from '@material-ui/core/Avatar';
import { getMusicAlbumEditRoute } from '../../../../../router/routes/music/factory/album';
import Button from "../../../inputs/Button";


const Row = ({ album, loading }) => {
	const { t } = useTranslation();
	const displayAlbum = (!loading && album);
	return (
		<TableRow>
			<TableCell>
				{!displayAlbum && <Skeleton width={40} height={40}/>}
				{(displayAlbum) &&
				<Avatar variant='square' src={album.Drive_Item__ ? album.Drive_Item.Icon : defaultPicture}/>}
			</TableCell>

			<TableCell>
				{!displayAlbum && <Skeleton/>}
				{displayAlbum && <span>{album.Name}</span>}
			</TableCell>

			<TableCell>
				{!displayAlbum && <Skeleton/>}
				{displayAlbum && <span>{album.Tracks}</span>}
			</TableCell>
			<TableCell>
				{!displayAlbum && <Skeleton/>}
				{displayAlbum && <span>{formatDuration(moment.duration(parseInt(album.Duration), 'seconds'))}</span>}
			</TableCell>

			<TableCell align='right'>
				{!displayAlbum && <Skeleton/>}
				{(displayAlbum && album.Status !== 'published') &&
				<Button
					variant='contained'
					color='primary'
					startIcon={<EditIcon/>}
					component={Link} to={getMusicAlbumEditRoute(album.Music_Label_Album__)}
				>
					{t('edit_btn')}
				</Button>
				}

				{(displayAlbum && album.Status === 'published') && <Chip
					component={Link}
					to={getMusicAlbumEditRoute(album.Music_Label_Album__)}
					clickable
					variant='outlined'
					size='small'
					color='primary'
					label={t('album_status_published')}
					icon={<CheckIcon/>}
				/>}
			</TableCell>
		</TableRow>
	);
};

export default Row;
