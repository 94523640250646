import Login                                                                           from "../../../pages/login/Login";
import Contact from "../../../pages/Contact";
import Home    from "../../../pages/Home";
import {
	getPXLContactRoute,
	getPXLDashboardRoute,
	getPXLJoinRoute,
	getPXLLoginRoute,
	getPXLPwdForget
}              from './factory/general';
import PasswordForgotten
               from '../../../pages/PasswordForgotten';
import Join    from '../../../pages/join/Join';

export default [
    {
        path: getPXLDashboardRoute(),
        component: Home,
        exact: true,
        type: 'route',
        loginRequired: true,
    },
    {
        path: getPXLLoginRoute(),
        component: Login,
        exact: true,
        type: 'route',
    },
	{
		path: getPXLJoinRoute(),
		component: Join,
		exact: true,
		type: 'route',
		loginRequired: true,
	},
	{
		path: getPXLPwdForget(),
		component: PasswordForgotten,
		exact: true,
		type: 'route',
	},
    {
        path: getPXLContactRoute(),
        component: Contact,
        exact: true,
        type: 'route',
        loginRequired: true,
    },
]
