import styledComponents from 'styled-components'
import styled from "@material-ui/core/styles/styled";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";

const Main = styledComponents.main``;
const Div = styledComponents.div``;

export const MainContent = styled(Main)(style => {
    return {
        flexGrow: 1,
        height: '100%',
        overflow: 'auto',
    }
});

export const MainContainer = styled(Container)(style => {
    return {
        paddingTop: style.theme.spacing(5),
        paddingBottom: style.theme.spacing(5),
    }
});


export const Root = styled(Div)(style => {
    return {
        display: 'flex',
    }
});


export const StyledAppBar = styled(AppBar)(style => {
    return {
        minHeight: '64px',
        zIndex: style.theme.zIndex.drawer + 1,
        color:'white',
        background: "linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%), #121212;",
    };
});

export const AppBarSpacer = styled(Div)(style => {
    return style.theme.mixins.toolbar
});