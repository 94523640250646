import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Trans, useTranslation} from 'react-i18next';
import {createSeat, fetchSeats, updateSeat} from '../../../../../store/actions/planner/SeatAction';
import {fetchEvent} from '../../../../../store/actions/planner/EventAction';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '../../../inputs/Button';
import SeatVisibleSelector from '../../../inputs/SeatVisibleSelector';
import Price from '../../../inputs/Price';
import HasRight                      from '../../../../Route/HasRight';
import TextField                     from '@material-ui/core/TextField';
import DeleteForeverIcon             from '@material-ui/icons/DeleteForever';
import DeleteDialog                  from './DeleteDialog';
import PriceFeeNotice                from '../../../feeback/PriceFeeNotice';
import SeatTypeSelector              from '../../../inputs/SeatTypeSelector';
import SeatMembershipSelector        from '../../../inputs/SeatMembershipSelector';
import RichAlert                     from '../../../feeback/RichAlert';
import TicketRequiresPaymentSelector from '../../../inputs/TicketRequiresPaymentSelector';
import {Box}                         from '@material-ui/core';
import Loading                       from '../../../feeback/loading/Loading';
import {getText}                     from '../../../../../store/actions/TextAction';
import {deepCopy}                    from '../../../../utils/misc';
import MultiLangTextField            from '../../../inputs/MultiLangTextField';
import {hasChanged, isI18NFilled}    from '../../../../utils/i18nInput';
import FormRequiredField             from '../../../feeback/FormRequiredField';

const ManageDialog = ({
	                      open,
	                      setOpen,
	                      event,
	                      seat = null,
	                      isAdmin,
	                      creating,
	                      updating,
	                      updateSeat,
	                      createSeat,
	                      refreshingEvent,
	                      refreshEvent,
	                      isManager,
	                      getText
                      }) => {
	const {t} = useTranslation();
	const [saveEnabled, setSaveEnabled] = useState(false);

	const [name, setName] = useState(seat ? seat.Seat_Name : '');

	const [displayName, setDisplayName] = useState({});
	const [displayNameText, setDisplayNameText] = useState({});


	const [description, setDescription] = useState({});
	const [descriptionText, setDescriptionText] = useState({});

	const [capacity, setCapacity] = useState(seat ? seat.Capacity : 0);
	const [visible, setVisible] = useState(seat ? seat.Visible : '');
	const [type, setType] = useState(seat ? seat.Type : 'standing');
	const [price, setPrice] = useState(seat ? parseFloat(seat.Price.value) : undefined);
	const [currency, setCurrency] = useState(seat ? seat.Currency__ : 'JPY');
	const [vatIncluded, setVatIncluded] = useState(seat ? !!seat.Price_Flags.vat_included : false);

	const [requiresPayment, setRequiresPayment] = useState(seat ? seat.Reservation_Requires_Payment : 'Y');

	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [canDelete, setCanDelete] = useState(seat && seat.Count < 1 && event.Visible === 'N' && isAdmin);
	const [membershipRequired, setMembershipRequired] = useState(seat ? seat.Membership_Required : 'N');

	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		if (!seat) setLoaded(true);
		else setLoaded(false);

		let promises = [];

		if (seat) {
			promises.push(getText(seat.Seat_Name_Display_Text__)
				.then(text => {
					setDisplayName(text.Values ?? {});
					setDisplayNameText(deepCopy(text));
				}));
		}else {
			setDisplayName( {});
			setDisplayNameText({Values:{}});
		}

		if (seat) {
			promises.push(getText(seat.Description_Text__)
				.then(text => {
					setDescription(text.Values ?? {});
					setDescriptionText(deepCopy(text));
				}));
		}else {
			setDescription( {});
			setDescriptionText({Values:{}});
		}

		setName(seat ? seat.Seat_Name : '');

		setCapacity(seat ? seat.Capacity : 0);
		setVisible(seat ? seat.Visible : '');
		setType(seat ? seat.Type : 'standing');
		setPrice(seat ? parseFloat(seat.Price.value) : undefined);
		setCurrency(seat ? seat.Currency__ : 'JPY');
		setCanDelete(seat && seat.Count < 1 && event.Visible === 'N' && isAdmin);
		setVatIncluded(seat ? !!seat.Price_Flags.vat_included : false);
		setMembershipRequired(seat ? seat.Membership_Required : 'N');
		setRequiresPayment(seat ? seat.Reservation_Requires_Payment : 'Y');

		if (seat)
			Promise.all(promises).then(r => setLoaded(true));
	}, [seat, setName, setCapacity, setVisible, setPrice, setCurrency, setType, setCanDelete, setVatIncluded, isAdmin, event, setMembershipRequired, setRequiresPayment, setLoaded, setDisplayName, setDisplayNameText, setDescription, setDescriptionText, getText]);

	// create case
	useEffect(() => {
		if (seat) return;
		setSaveEnabled(name.trim()  && price != null && currency && visible && type && isI18NFilled(displayName) && isI18NFilled(description));
	}, [name, description, capacity, visible, price, currency, setSaveEnabled, seat, type, displayName]);

	// update case
	useEffect(() => {
		if (!seat) return;

		if (!name.trim() || price == null || !currency || !visible || !type || !isI18NFilled(displayName) || !isI18NFilled(description)) {
			setSaveEnabled(false);
			return;
		}

		const includedVat = !!seat.Price_Flags.vat_included;

		if (name !== seat.Seat_Name ||
			visible !== seat.Visible ||
			type !== seat.Type ||
			capacity !== seat.Capacity ||
			membershipRequired !== seat.Membership_Required ||
			price !== parseFloat(seat.Price.raw.value) ||
			includedVat !== vatIncluded ||
			currency !== seat.Currency__ ||
			hasChanged(displayNameText.Values, displayName) ||
			hasChanged(descriptionText.Values, description) ||
			!!requiresPayment) {
			setSaveEnabled(true);
			return;
		}

		setSaveEnabled(false);
	}, [name, description, capacity, visible, price, currency, setSaveEnabled, seat, vatIncluded, type, membershipRequired, requiresPayment, displayName, displayNameText, descriptionText]);

	const handleClose = () => {
		setOpen(false);
	};

	const handleCreate = () => {
		const priceFlags = {};
		if (vatIncluded) priceFlags['vat_included'] = true;
		const rPayments = type === 'stream' ? 'Y' : requiresPayment;

		createSeat(event.Planner_Event__, name, displayName, description, capacity, visible, price, priceFlags, currency, type, membershipRequired, rPayments)
			.then(e => refreshEvent(event.Planner_Event__))
			.then(handleClose);
	};

	const handleDeleted = () => {
		refreshEvent(event.Planner_Event__)
			.then(handleClose);
	};

	const handleUpdate = () => {
		const priceFlags = {};
		if (vatIncluded) priceFlags['vat_included'] = true;

		const rPayments = type === 'stream' ? 'Y' : requiresPayment;

		updateSeat(seat.Planner_Event_Seat__, name, displayName, description, capacity, visible, price, priceFlags, currency, type, membershipRequired, rPayments)
			.then(e => refreshEvent(event.Planner_Event__))
			.then(handleClose);
	};

	const handleSave = (e) => {
		e.preventDefault();
		if (seat) handleUpdate();
		else handleCreate();
	};


	return (
		<Dialog
			open={open}
			aria-labelledby={seat ? t('update_seat') : t('create_seat')}
			aria-describedby={seat ? t('update_seat') : t('create_seat')}
			fullWidth
			maxWidth="lg"
		>
			<form onSubmit={handleSave}>
				<DialogTitle id="manage-seat-title">{seat ? t('update_seat') : t('create_seat')}</DialogTitle>
				<DialogContent>
					<Box py={2}>
						{loaded && <Grid container spacing={3}>
							{seat &&
							<Grid item xs={12}>
								<Alert severity="info">{t('seat_delete_alert')}</Alert>
							</Grid>
							}

							<FormRequiredField/>

							<Grid item xs={12}>
								<TextField
									label={t('seat_name')}
									fullWidth
									variant="outlined"
									value={name}
									required
									disabled={creating || updating || refreshingEvent || !!seat || !isManager}
									onChange={e => setName(e.target.value)}
									helperText={t('seat_name_helperText')}
									inputProps={{maxLength: 16}}
								/>
							</Grid>

							<Grid item xs={12}>
								<MultiLangTextField
									value={displayName}
									setValue={setDisplayName}
									variant="outlined"
									placeholder={t('seat_name_display')}
									helperText={t('seat_name_display_helperText')}
									fullWidth
									required
									label={t('seat_name_display')}
									disabled={creating || updating || refreshingEvent || !isManager}
								/>
							</Grid>


							<Grid item xs={12}>
								<MultiLangTextField
									value={description}
									setValue={setDescription}
									variant="outlined"
									placeholder={t('seat_description')}
									helperText={t('seat_description_helperText')}
									fullWidth
									required
									multiline rows={5}
									rowsMax={Infinity}
									label={t('seat_description')}
									disabled={creating || updating || refreshingEvent || !isManager}
								/>
							</Grid>

							<Grid item xs={12} md={6}>
								<TextField
									label={t('seat_capacity')}
									fullWidth
									variant="outlined"
									value={capacity}
									required
									disabled={creating || updating || refreshingEvent || !isManager}
									onChange={e => setCapacity(e.target.value)}
									helperText={t('seat_capacity_helperText')}
									type="number"
									InputProps={
										{
											min: 0
										}
									}
								/>
							</Grid>

							<Grid item xs={12} md={6}>
								<SeatVisibleSelector
									value={visible}
									setValue={setVisible}
									required
									disabled={creating || updating || refreshingEvent || !isManager}
								/>
							</Grid>

							{type === 'slot' && <Grid item xs={12}>
								<RichAlert severity="warning">
									<Trans i18nKey="seat_type_slot_warn">
										sample<br/>
										<strong>sample</strong>
									</Trans>
								</RichAlert>
							</Grid>}

							<Grid item xs={12} md={6}>
								<SeatTypeSelector
									value={type}
									setValue={setType}
									required
									disabled={creating || updating || refreshingEvent || !isManager}
								/>
							</Grid>

							<Grid item xs={12} md={6}>
								<SeatMembershipSelector
									value={membershipRequired}
									setValue={setMembershipRequired}
									required
									disabled={creating || updating || refreshingEvent || !isManager}
								/>
							</Grid>

							<Grid item xs={12} md={12}>
								<TicketRequiresPaymentSelector
									disabled={creating || updating || refreshingEvent || !isManager || type === 'stream'}
									value={type === 'stream' ? 'Y' : requiresPayment}
									setValue={setRequiresPayment}
									required
								/>
							</Grid>

							<Grid item xs={12}>
								<PriceFeeNotice low={type !== 'stream'}/>
							</Grid>

							<Grid item xs={12}>
								<Price
									variant="outlined"
									vatIncluded={vatIncluded}
									setVatIncluded={setVatIncluded}
									fullWidth
									required
									value={price}
									onValueChange={setPrice}
									label={t('seat_price_lbl')}
									helperText={vatIncluded ? t('seat_price_vat_incl_helperText') : t('seat_price_helperText')}
									currency={currency}
									disabled={creating || updating || refreshingEvent || !isManager}
								/>
							</Grid>

						</Grid>
						}
						{!loaded && <Grid container spacing={3} justify="center" alignItems='center'>
							<Grid item>
								<Loading/>
							</Grid>
						</Grid>}
					</Box>
				</DialogContent>
				<DialogActions>
					<Grid container spacing={2}
					      justify={canDelete ? 'space-between' : 'flex-end'}>
						{canDelete &&
						<Grid item>
							<Button
								size="small"
								color="secondary"
								startIcon={<DeleteForeverIcon/>}
								disabled={creating || updating || refreshingEvent || !loaded}
								onClick={e => setShowDeleteDialog(true)}
							>
								{t('delete_btn')}
							</Button>
						</Grid>
						}
						<Grid item>
							<Button
								color="default"
								onClick={handleClose}
								disabled={creating || updating || refreshingEvent || !loaded}
							>
								{t('close_btn')}
							</Button>
							<HasRight manager>
								<Button
									color="primary"
									variant="contained"
									loading={creating || updating || refreshingEvent}
									disabled={creating || updating || refreshingEvent || !saveEnabled || !loaded}
									type="submit"
								>
									{seat ? t('save_btn') : t('create_btn')}
								</Button>
							</HasRight>
						</Grid>
					</Grid>
				</DialogActions>
			</form>
			{canDelete &&
			<DeleteDialog seat={seat} open={showDeleteDialog} setOpen={setShowDeleteDialog} onDeleted={handleDeleted}/>}
		</Dialog>
	);
};

const mapStateToProps = (state) => {
	return {
		creating: state.seat.creating,
		updating: state.seat.updating,
		refreshingEvent: state.seat.eventLoading,
		isAdmin: state.access.admin,
		isManager: state.access.manager,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getText: (id) => dispatch(getText(id)),

		refreshEvent: (eventId) => dispatch(fetchEvent(eventId)),
		refreshSeats: (eventId) => dispatch(fetchSeats(eventId)),
		createSeat: (eventId, name, displayName, description, capacity, visible, price, priceFlags, currency, type, membershipRequired, requiresPayment) => dispatch(createSeat(eventId, name, displayName, description, capacity, visible, price, priceFlags, currency, type, membershipRequired, requiresPayment)),
		updateSeat: (seatId, name, displayName, description, capacity, visible, price, priceFlags, currency, type, membershipRequired, requiresPayment) => dispatch(updateSeat(seatId, name, displayName, description, capacity, visible, price, priceFlags, currency, type, membershipRequired, requiresPayment))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageDialog);
