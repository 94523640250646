import React              from 'react';
import { useTranslation } from 'react-i18next';
import TableRow           from '@material-ui/core/TableRow';
import TableCell          from '@material-ui/core/TableCell';
import TableHead          from '@material-ui/core/TableHead';

const CartHeader = ({hasAction = true}) => {
	const { t } = useTranslation();

	return (
		<TableHead>
			<TableRow>
				<TableCell><strong>{t('cart_item_name')}</strong></TableCell>
				<TableCell><strong>{t('cart_item_quantity')}</strong></TableCell>
				<TableCell><strong>{t('cart_item_price_raw')}</strong></TableCell>
				<TableCell><strong>{t('cart_item_vat_rate')}</strong></TableCell>
				<TableCell><strong>{t('cart_item_total')}</strong></TableCell>
				{hasAction && <TableCell><strong>{t('cart_item_action')}</strong></TableCell>}
			</TableRow>
		</TableHead>
	);
};

export default CartHeader;
