import React, {Fragment, useEffect, useState} from 'react';
import {Title} from "../../common/typography/Title";
import Grid from "@material-ui/core/Grid";
import Loading from "../../common/feeback/loading/Loading";
import {Trans, useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import creationDoneImg from "../../../resources/images/artist-wizard/creationDone.svg";
import {useHistory} from "react-router-dom";
import {connect} from 'react-redux'
import {createArtist} from "../../../store/actions/MusicAction";
import {fetch as refreshRealmList, fetchAndSelect} from "../../../store/actions/RealmAction";
import '../../utils/strings'
import {getLocale} from "@karpeleslab/klbfw";
import {reset} from "../../../store/actions/CommonAction";
import {getFanclubWizardRoute} from "../../../router/routes/fanclub/factory/wizard";
import HasCapability from "../../Route/HasCapability";
import Button from "../../common/inputs/Button";
import MaterialLink from "@material-ui/core/Link";


const CreationStep = ({handleBack, handleNext, artist, members, template, settings, settingFiles, createArtist, refreshRealmList, fetchAndSelectRealm, reset}) => {
    const {t} = useTranslation();
    const history = useHistory();
    const [creating, setCreating] = useState(true);
    const [url, setUrl] = useState('');


    const handleFanclubWizard = () => {
        history.push(getFanclubWizardRoute())
    };

    useEffect(() => {
        let pictures = {
            artist: artist.picture,
            members: []
        };
        let formatedArtist = {
            name: artist.name,
            country: artist.country.Country__,
            Links: artist.links,
            genre: artist.genre ? artist.genre.Music_Genre__ : null,
            biography: {[getLocale()]: artist.biography},
            catchphrase: {[getLocale()]: artist.catchphrase}
        };

        let formatedMembers = [];

        members.forEach((member) => {
            let formatedMember =
                {
                    name: member.name,
                    Links: member.links,
                    biography: {[getLocale()]: member.biography},
                    bloodType: member.bloodType,
                    birthdate: member.birthdate ? member.birthdate.format('YYYY-MM-DD') : null

                };

            pictures.members.push(member.picture);
            formatedMembers.push(formatedMember)
        });

        createArtist({
            info: formatedArtist,
            members: formatedMembers,
            template: template.Site_Template__,
            settings: settings,
        }, settingFiles, pictures)
            .then(data => {
                return refreshRealmList().then(
                    e => {
                        reset();
                        fetchAndSelectRealm(data.Realm.Realm__)
                            .then(e => {
                                setUrl('https://' + data.Registry_Url.Domain);
                                setCreating(false);
                            })
                    }
                );
            })
            .catch((err) => {
                handleBack();
            })
        // eslint-disable-next-line
    }, [createArtist]);

    return (
        <Grid container spacing={3} direction="row">
            <Grid item xs={4} md={4}>
                <img src={creationDoneImg} alt='artist' width="100%"/>
            </Grid>

            <Grid item xs={12} md={8}>
                <Grid container spacing={3} justify="center">

                    <Grid item>
                        {!creating && <Title>{t('artist_wizard_step_creation_done_title')}</Title>}
                        {creating && <Title>{t('artist_wizard_step_creation_title')}</Title>}
                    </Grid>

                    {creating &&
                    <Grid item xs={12}>
                        <Loading size={100} thickness={1}/>
                    </Grid>
                    }

                    {!creating &&
                    <Fragment>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" color="textPrimary" align="center">
                                <Trans i18nKey='artist_wizard_step_creation_done_desc'>
                                    sample <br/>
                                    <strong>sample</strong>
                                </Trans>
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="subtitle2" color="textSecondary" align="center">
                                <Trans i18nKey='artist_wizard_step_creation_website'>
                                    sample <br/>

                                    <MaterialLink target='_blank' rel='noopener noreferrer' href={url}>{{url}}</MaterialLink>
                                </Trans>
                            </Typography>

                        </Grid>

                        <HasCapability fanclub>
                            <Grid item>
                                <Title>{t('artist_wizard_step_creation_done_fanclub_title')}</Title>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="subtitle1" color="textPrimary" align="center">
                                    <Trans i18nKey='artist_wizard_step_creation_done_fanclub_desc'>
                                        sample <br/>
                                        <strong>sample</strong>
                                    </Trans>
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container justify='center'>
                                    <Button onClick={handleFanclubWizard} variant="contained" color="primary">
                                        {t('fan_club_wizard_btn')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </HasCapability>

                    </Fragment>
                    }

                </Grid>
            </Grid>
        </Grid>
    )
};

const mapStateToProps = (state) => {
    return {
        creating: state.music.creatingArtist
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        reset: () => dispatch(reset()),
        createArtist: (data, settingFiles, pictures) => dispatch(createArtist(data, settingFiles, pictures)),
        refreshRealmList: () => dispatch(refreshRealmList()),
        fetchAndSelectRealm: (id) => dispatch(fetchAndSelect(id)),

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreationStep);