export const getAttribute = (attributes, key) => {
    const keys = key.split('.');
    let node = attributes.values ?? {};
    for (let i = 0; i < keys.length; i++) {
        if (!(keys[i] in node)) return null;
        node = node[keys[i]];
    }
    if (!node) return null;

    return node;
};

export const isAttributeUpdated = (attributes, key, value) => {
    const attr = getAttribute(attributes, key) ?? [];

    if(attr.length !== value.length) return true;
    const map = {};
    value.forEach(v => map[v.Language__ ?? 'any'] = v);

    for(let i = 0; i<attr.length; i++) {
        const lang = attr[i].Language__ ?? 'any';
        if(!([lang] in map)) return true;
        if(map[lang].Value !== attr[i].Value) return true;
    }

    return false;
}
