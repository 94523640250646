import React, {useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Grid                                             from "@material-ui/core/Grid";
import TextField                                        from "@material-ui/core/TextField";
import {connect}                                        from 'react-redux'
import {useTranslation}                                 from 'react-i18next';
import {createTemplate, fetchTemplates, updateTemplate} from "../../../../store/actions/CmsAction";
import RichText                                         from "../../inputs/RichText";
import {useTheme}                                       from "@material-ui/core/styles";
import useMediaQuery                                    from "@material-ui/core/useMediaQuery";
import DeleteForeverIcon                                from "@material-ui/icons/DeleteForever";
import DeleteDialog                                     from "./DeleteDialog";
import Button                                           from "../../inputs/Button";
import FormRequiredField                                from '../../feeback/FormRequiredField';


const ManageDialog = ({cmsId, open, setOpen, creating, updating, updateTemplate, createTemplate, refreshTemplatesList, template = null, isManager, isAdmin}) => {
    const {t} = useTranslation();
    const [name, setName] = useState("");
    const [html, setHtml] = useState("");
    const [saveEnabled, setSaveEnabled] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const onDeleted = () => {
        refreshTemplatesList(cmsId);
        handleClose();
    };

    useEffect(() => {
        if (template) {
            setName(template.Name);
            setHtml(template.Html);
        } else {
            setName("");
            setHtml("");
        }

    }, [template, setHtml, setName]);

    useEffect(() => {
        if (template) {
            if (name.trim() !== template.Name) {
                setSaveEnabled(true);
                return;
            }

            if (html.trim() !== template.Html) {
                setSaveEnabled(true);
                return;
            }

            setSaveEnabled(false);
            return;
        }

        if (!name.trim() || !html.trim()) {
            setSaveEnabled(false);
            return;
        }

        setSaveEnabled(true)
    }, [template, name, html, setSaveEnabled]);


    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (template) {
            updateTemplate(template.Content_Cms_Template__, name, html).then((d) => {
                refreshTemplatesList(cmsId);
                handleClose();
            });
            return
        }

        createTemplate(cmsId, name, html)
            .then((d) => {
                refreshTemplatesList(cmsId);
                handleClose();
            })
    };


    //disableEnforceFocus because tinymce will create model, input will not be focusable
    return (
        <Dialog
            open={open}
            aria-labelledby={template ? t('update_template') : t('create_template')}
            aria-describedby={template ? t('update_template') : t('create_template')}
            fullWidth
            fullScreen={fullScreen}
            disableEnforceFocus
        >
            <form onSubmit={handleSubmit}>
                <DialogTitle
                    id="manage-template-title">{template ? t('update_template_title') : t('create_template_title')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="manage-template-description">
                        {template ? t('update_template_desc') : t('create_template_desc')}
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <FormRequiredField/>
                        <Grid item xs={12}>
                            <TextField label={t('name_lbl')} fullWidth variant="outlined" value={name}
                                       required
                                       disabled={creating || updating || !isManager}
                                       onChange={e => setName(e.target.value)}
                                       helperText={t('template_name_helper')}/>
                        </Grid>

                        <Grid item xs={12}>
                            <RichText value={html} onChange={setHtml} disabled={updating || !isManager}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {(template && isAdmin) &&
                    <Button size='small' color='secondary'
                            startIcon={<DeleteForeverIcon/>}
                            disabled={creating || updating}
                            onClick={e => setShowDeleteDialog(true)}
                    >
                        {t('delete_btn')}
                    </Button>
                    }
                    <Button color="default" onClick={handleClose} disabled={creating || updating}>
                        {t('close_btn')}
                    </Button>

                    {isManager && <Button color="primary" variant='contained'
                                          loading={updating}
                                          disabled={creating || updating || !saveEnabled}
                                          type='submit'>
                        {template ? t('save_btn') : t('create_btn')}
                    </Button>
                    }
                </DialogActions>
            </form>
            {(showDeleteDialog && template !== null) &&
            <DeleteDialog open={showDeleteDialog} setOpen={setShowDeleteDialog} template={template}
                          onDeleted={onDeleted}/>}
        </Dialog>

    );
};

const mapStateToProps = (state) => {
    return {
        creating: state.cms.templateCreating,
        updating: state.cms.templateUpdating,
        isManager: state.access.manager,
        isAdmin: state.access.admin,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        createTemplate: (cmsId, name, html) => dispatch(createTemplate(cmsId, name, html)),
        updateTemplate: (id, name, html) => dispatch(updateTemplate(id, name, html)),
        refreshTemplatesList: (cmsId) => dispatch(fetchTemplates(cmsId)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageDialog);
