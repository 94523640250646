import React                  from 'react';
import Grid                   from '@material-ui/core/Grid';
import AppBarTitle            from '../../../components/common/typography/AppBarTitle';
import Form                   from '../../../components/common/music/record/Form';
import {useTranslation}       from 'react-i18next';
import {connect}              from 'react-redux';
import Loading                from '../../../components/common/feeback/loading/Loading';
import SelectRealm            from '../../SelectRealm';
import HasRight               from '../../../components/Route/HasRight';
import Redirect               from '../../../components/Route/Redirect';
import Toolbar                from '../../../components/common/typography/Toolbar';
import {getMusicRecordsRoute} from '../../../router/routes/music/factory/records';

const Create = ({artist, selectedRealm}) => {
	const {t} = useTranslation();

	if (!selectedRealm) return <SelectRealm/>;
	if (!artist) return <Loading/>;

	return (
		<HasRight manager deniedComponent={<Redirect target={getMusicRecordsRoute()}/>}>
			<Grid container spacing={3}>
				<AppBarTitle title={t('record_create_title')}/>

				<Grid item xs={12}>
					<Toolbar title={t('record_create_title')}/>
				</Grid>

				<Grid item xs={12}>
					<Form artist={artist}/>
				</Grid>

			</Grid>
		</HasRight>
	);
};


const mapStateToProps = (state) => {
	return {
		artist: state.artist.artist,
		selectedRealm: state.realm.selected,
	};
};

export default connect(mapStateToProps)(Create);