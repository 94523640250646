import {
	ARTIST_CREATE_MINIMAL,
	ARTIST_CREATE_MINIMAL_DONE,
	ARTIST_FETCH,
	ARTIST_FETCH_DONE,
	ARTIST_GALLERY_DELETE_IMG,
	ARTIST_GALLERY_DELETE_IMG_DONE,
	ARTIST_GALLERY_FETCH,
	ARTIST_GALLERY_FETCH_DONE,
	ARTIST_GALLERY_IMG_VARIATION,
	ARTIST_GALLERY_UPLOAD_IMG,
	ARTIST_GALLERY_UPLOAD_IMG_DONE,
	ARTIST_GALLERY_UPLOAD_IMG_RUNNING,
	ARTIST_MEMBER_CREATE,
	ARTIST_MEMBER_CREATE_DONE,
	ARTIST_MEMBER_DELETE,
	ARTIST_MEMBER_DELETE_DONE,
	ARTIST_MEMBER_FETCH,
	ARTIST_MEMBER_FETCH_DONE,
	ARTIST_MEMBER_UPDATE,
	ARTIST_MEMBER_UPDATE_DONE,
	ARTIST_MEMBER_UPLOAD_IMAGE,
	ARTIST_MEMBER_UPLOAD_IMAGE_DONE,
	ARTIST_MEMBERS_FETCH,
	ARTIST_MEMBERS_FETCH_DONE,
	ARTIST_SEARCH_FETCH,
	ARTIST_SEARCH_FETCH_DONE,
	ARTIST_SET_MAIN_IMAGE,
	ARTIST_SET_MAIN_IMAGE_DONE,
	ARTIST_UPDATE,
	ARTIST_UPDATE_DONE,
	ARTIST_UPLOAD_IMAGE,
	ARTIST_UPLOAD_IMAGE_DONE
}                       from '../reducers/ArtistReducer';
import { handleError }  from '../../components/utils/apiErrorHandler';
import { rest, upload } from '@karpeleslab/klbfw';
import { success }      from './system/ToastAction';

export const fetchArtist = (id) => {
	return (dispatch, getState) => {
		dispatch({ type: ARTIST_FETCH });
		return rest('Music/Label/Artist/' + id)
			.then(d => {
				dispatch({ type: ARTIST_FETCH_DONE, artist: d.data });
				return d.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const updateText = (id, values) => {
	return (dispatch, getState) => {
		return rest('Text/' + id + ':setAll', 'POST', { values: values })
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const updateArtistImage = (id, file, purpose = null) => {
	return (dispatch, getState) => {
		dispatch({ type: ARTIST_UPLOAD_IMAGE });
		const params = {};
		if (purpose) {
			params.purpose = purpose;
		}
		const p = upload.append('Music/Label/Artist/' + id + ':addImage', file, params);

		upload.run();

		return p.then(
			s => dispatch({ type: ARTIST_UPLOAD_IMAGE_DONE })
		).catch((err) => {
			handleError(getState, dispatch, err);
		});
	};
};


export const updateMemberImage = (id, file, purpose = null) => {
	return (dispatch, getState) => {
		dispatch({ type: ARTIST_MEMBER_UPLOAD_IMAGE });
		const params = {};
		if (purpose) {
			params.purpose = purpose;
		}
		const p = upload.append('Music/Label/Artist/Member/' + id + ':addImage', file, params);


		upload.run();

		return p.then(
			s => dispatch({ type: ARTIST_MEMBER_UPLOAD_IMAGE_DONE })
		).catch((err) => {
			handleError(getState, dispatch, err);
		});
	};
};

export const updateArtist = (id, data) => {
	return (dispatch, getState) => {
		dispatch({ type: ARTIST_UPDATE });
		return rest('Music/Label/Artist/' + id, 'PATCH', data)
			.then((d) => {
				dispatch({ type: ARTIST_UPDATE_DONE, artist: d.data });
				success('artist_update_success');
				return d.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};


export const updateMember = (id, data) => {
	return (dispatch, getState) => {
		dispatch({ type: ARTIST_MEMBER_UPDATE });
		return new Promise((resolve, reject) => {
			const promises = [];
			promises.push(rest('Music/Label/Artist/Member/' + id, 'PATCH', data.base));

			data.texts.forEach(text => {
				promises.push(dispatch(updateText(text.Text__, text.Values)));
			});

			Promise.all(promises)
				.then(promisesResults => {
					const updateMemberResponse = promisesResults[0];
					dispatch({ type: ARTIST_MEMBER_UPDATE_DONE, member: updateMemberResponse.data });
					success('member_update_success');
					resolve(promisesResults);
				})

				.catch(reject);
		}).catch((err) => {
			handleError(getState, dispatch, err);
		});
	};
};

export const fetchMembers = (artistId) => {
	return (dispatch, getState) => {
		dispatch({ type: ARTIST_MEMBERS_FETCH });
		return rest('Music/Label/Artist/' + artistId + '/Member')
			.then(d => {
				dispatch({ type: ARTIST_MEMBERS_FETCH_DONE, members: d.data });
				return d.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const fetchMember = (memberId) => {
	return (dispatch, getState) => {
		dispatch({ type: ARTIST_MEMBER_FETCH });
		return rest('Music/Label/Artist/Member/' + memberId)
			.then(d => {
				dispatch({ type: ARTIST_MEMBER_FETCH_DONE, member: d.data });
				return d.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const deleteMember = (id) => {
	return (dispatch, getState) => {
		dispatch({ type: ARTIST_MEMBER_DELETE });
		return rest('Music/Label/Artist/Member/' + id, 'DELETE')
			.then(d => {
				dispatch({ type: ARTIST_MEMBER_DELETE_DONE });
				success('member_delete_success');
				return d.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const createMember = (artistId, data, picture) => {
	return (dispatch, getState) => {
		dispatch({ type: ARTIST_MEMBER_CREATE });

		return new Promise((resolve, reject) => {
			rest('Music/Label/Artist/' + artistId + '/Member:create', 'POST', data)
				.then((d) => {
					if (picture) {
						dispatch(updateMemberImage(d.data.Music_Label_Artist_Member__, picture, 'main'))
							.then(r => resolve(d.data));
					} else {
						resolve(d.data);
					}
				}).catch(reject);
		}).then(data => {
			success('member_create_success');
			dispatch({ type: ARTIST_MEMBER_CREATE_DONE });
			return data;
		})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const searchArtist = (namePrefix) => {
	return (dispatch, getState) => {
		dispatch({ type: ARTIST_SEARCH_FETCH });

		const params = {
			results_per_page: 100
		};

		if (namePrefix) {
			params.Name = { '$like': namePrefix + '%' };
		}

		const labelId = getState().artist.artist.Music_Label__;

		return rest('Music/Label/' + labelId + '/Artist', 'GET', params)
			.then(d => {
				dispatch({ type: ARTIST_SEARCH_FETCH_DONE, artists: d.data });
				return d.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const createMinimalArtist = (name) => {
	return (dispatch, getState) => {
		dispatch({ type: ARTIST_CREATE_MINIMAL });

		const labelId = getState().artist.artist.Music_Label__;

		const params = {
			Name: name
		};

		return rest('Music/Label/' + labelId + '/Artist', 'POST', params)
			.then(d => {
				dispatch({ type: ARTIST_CREATE_MINIMAL_DONE });
				success('artist_create_success');
				return d.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const fetchArtistGallery = (artistId) => {
	return (dispatch, getState) => {
		dispatch({ type: ARTIST_GALLERY_FETCH });
		const params = { image_variation: ARTIST_GALLERY_IMG_VARIATION };
		return rest('Music/Label/Artist/' + artistId + ':getImages', 'POST', params)
			.then(d => {
				dispatch({ type: ARTIST_GALLERY_FETCH_DONE, images: d.data });
				return d.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const uploadFileToArtistGallery = (artistId, files = [], params = {}) => {
	return (dispatch, getState) => {
		dispatch({ type: ARTIST_GALLERY_UPLOAD_IMG, count: files.length });

		upload.onprogress = d => {
			let runningCount = 0;
			let blockTotal = 0;
			let progressTotal = 0;
			d.running.forEach((running) => {
				if (running.status !== 'pending' && running.status !== 'complete') {
					runningCount++;
					progressTotal += running.done;
					blockTotal += running.blocks;
				}
			});

			const ratio = blockTotal > 0 ? progressTotal / blockTotal : 0;

			dispatch({ type: ARTIST_GALLERY_UPLOAD_IMG_RUNNING, count: runningCount, ratio });
		};

		for (let i = 0; i < files.length; i++) {
			upload.append('Music/Label/Artist/' + artistId + ':addImage', files[i], params)
				.then((img) => {
					dispatch({ type: ARTIST_GALLERY_UPLOAD_IMG_DONE, img: img.final.image });
				});
		}

		upload.run();
	};
};

export const deleteGalleryImage = (artistId, driveItemId) => {
	return (dispatch, getState) => {
		dispatch({ type: ARTIST_GALLERY_DELETE_IMG });
		const params = { image: driveItemId };
		return rest('Music/Label/Artist/' + artistId + ':deleteImage', 'POST', params)
			.then(d => {
				success('artist_gallery_img_delete_success');
				dispatch({ type: ARTIST_GALLERY_DELETE_IMG_DONE, artist: d.data, driveItemId: driveItemId });
				return d.data;
			}).catch((err) => {
				dispatch({ type: ARTIST_GALLERY_DELETE_IMG_DONE });
				handleError(getState, dispatch, err);
			});
	};
};

export const setArtistMainImage = (artistId, driveItemId) => {
	return (dispatch, getState) => {
		dispatch({ type: ARTIST_SET_MAIN_IMAGE });
		const params = { image: driveItemId };
		return rest('Music/Label/Artist/' + artistId + ':setMainImage', 'POST', params)
			.then(d => {
				success('artist_main_image_set_success');
				dispatch({ type: ARTIST_SET_MAIN_IMAGE_DONE, artist:d.data });
				return d.data;
			}).catch((err) => {
				dispatch({ type: ARTIST_SET_MAIN_IMAGE_DONE });
				handleError(getState, dispatch, err);
			});
	};
};
