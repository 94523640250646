import React, {useEffect, useState} from 'react';
import Grid                         from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { Title }          from '../../typography/Title';
import Prices             from './Prices';
import Goods              from './Goods';
import HasRight           from '../../../Route/HasRight';
import PutOnSale          from './PutOnSale';
import SellingAttributes  from './SellingAttributes';
import Visibility         from './Visibility';
import { connect }        from 'react-redux';

const Selling = ({ productId, attributes, isAdmin }) => {
	const { t } = useTranslation();
	const [isLowRate, setIsLowRate] = useState(true);

	useEffect(() => {
		const deliverables = attributes.deliverables ?? [];
		for (let i = 0; i < deliverables.length; i++) {
			if(['files'].includes(deliverables[i].Style)) {
				setIsLowRate(false)
				return;
			}
		}
		setIsLowRate(true)
	}, [attributes, setIsLowRate])

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Title>{t('product_selling_attributes')}</Title>
			</Grid>
			<Grid item xs={12}>
				<SellingAttributes attributes={attributes} productId={productId}/>
			</Grid>

			<Grid item xs={12}>
				<Title>{t('product_prices')}</Title>
			</Grid>
			<Grid item xs={12}>
				<Prices productId={productId} disabled={!isAdmin} showCreation={isAdmin} isLowRate={isLowRate}/>
			</Grid>

			<Grid item xs={12}>
				<Goods productId={productId} attributes={attributes}/>
			</Grid>

			<Grid item xs={12}>
				<Visibility productId={productId} attributes={attributes}/>
			</Grid>

			<HasRight manager>
				<Grid item xs={12}>
					<PutOnSale productId={productId} attributes={attributes}/>
				</Grid>
			</HasRight>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		isAdmin: state.access.admin,
	};
};

export default connect(mapStateToProps)(Selling);
