import { COMMON_RESET }  from '../CommonReducer';
import { USER_LOGOUT }   from '../system/UserReducer';
import {TAG_DELETE_DONE} from '../TagReducer';

const initState = () => {
	return {
		release: null,
		releaseLoading: false,
		updatingRelease: false,

		updatingTags: false,
		selectedTagsLoading: false,
		selectedTags: []
	};
};

export const RELEASE_UPDATE_TAGS = 'RELEASE_UPDATE_TAGS';
export const RELEASE_UPDATE_TAGS_DONE = 'RELEASE_UPDATE_TAGS_DONE';

export const RELEASE_TAGS_FETCH = 'RELEASE_TAGS_FETCH';
export const RELEASE_TAGS_FETCH_DONE = 'RELEASE_TAGS_FETCH_DONE';

export const RELEASE_FETCH = 'RELEASE_FETCH';
export const RELEASE_FETCH_DONE = 'RELEASE_FETCH_DONE';

export const RELEASE_UPDATE = 'RELEASE_UPDATE';
export const RELEASE_UPDATE_DONE = 'RELEASE_UPDATE_DONE';

const releaseReducer = (state = initState(), action) => {
	switch (action.type) {
		case COMMON_RESET:
		case USER_LOGOUT:
			return initState();
		case RELEASE_UPDATE:
			return { ...state, updatingRelease: true };
		case RELEASE_UPDATE_DONE:
			if (!action.release)
				return { ...state, updatingRelease: false };
			return { ...state, updatingRelease: false, release: action.release };
		case RELEASE_FETCH:
			return { ...state, releaseLoading: true };
		case RELEASE_FETCH_DONE:
			return { ...state, releaseLoading: false, release: action.release };
		case RELEASE_UPDATE_TAGS:
			return { ...state, updatingTags: true };
		case RELEASE_UPDATE_TAGS_DONE:
			return { ...state, updatingTags: false, release: action.release };
		case TAG_DELETE_DONE:
			if(!state.release) return {...state};
			const tmp = {...state.release}
			tmp.Tags =  tmp.Tags.filter(t => t.Classify_Tag__ !== action.tagId)
			return {...state, release: tmp};
		case RELEASE_TAGS_FETCH:
			return { ...state, selectedTagsLoading: true };
		case RELEASE_TAGS_FETCH_DONE:
			return { ...state, selectedTagsLoading: false, selectedTags: action.tags };
		default:
			return state;
	}

};

export default releaseReducer;
