import React, {useEffect, useState} from 'react';
import Grid                         from '@material-ui/core/Grid';
import AppBarTitle                  from '../../../components/common/typography/AppBarTitle';
import {connect}                    from 'react-redux';
import Loading                      from '../../../components/common/feeback/loading/Loading';
import SelectRealm                  from '../../SelectRealm';
import {useTranslation}             from 'react-i18next';
import {useParams}                  from 'react-router-dom';
import AccessCheck                  from '../../../components/Route/AccessCheck';
import Redirect                     from '../../../components/Route/Redirect';
import {getStoreWarehouseRoute}     from '../../../router/routes/store/factory/store';
import Toolbar                      from '../../../components/common/typography/Toolbar';
import EditForm                     from '../../../components/common/store/warehouse/EditForm';
import {fetchDeliverable}           from '../../../store/actions/store/DeliverableAction';
import Files                        from '../../../components/common/store/warehouse/Files';
import Product                      from '../../../components/common/store/warehouse/Product';
import StoreActivator               from '../../../components/common/store/StoreActivator';


const Edit = ({selectedRealm, fetchDeliverable, deliverable}) => {
	const {t} = useTranslation();
	const {deliverableId} = useParams();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (!deliverableId) return;
		fetchDeliverable(deliverableId).then(() => setLoading(false));
	}, [deliverableId, fetchDeliverable]);

	if (!selectedRealm) return <SelectRealm/>;
	if (loading) return <Loading/>;

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('deliverable_edit_title')}/>
			<StoreActivator/>
			<AccessCheck capabilities={{store: true}} rights={{manager: true}}
			             deniedComponent={<Redirect target={getStoreWarehouseRoute()}/>}>
				<Grid item xs={12}>
					<Toolbar title={t('deliverable_edit_title')}/>
				</Grid>

				<Grid item xs={12}>
					<EditForm deliverable={deliverable}/>
				</Grid>

				<Grid item xs={12}>
					<Files deliverable={deliverable}/>
				</Grid>

				<Grid item xs={12}>
					<Product deliverable={deliverable}/>
				</Grid>

			</AccessCheck>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		deliverable: state.deliverable.deliverable,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchDeliverable: (deliverableId) => dispatch(fetchDeliverable(deliverableId))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
