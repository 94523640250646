import React, {useEffect} from 'react';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import Skeleton from "@material-ui/lab/Skeleton";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {connect} from 'react-redux'
import {fetchOne} from "../../../../../store/actions/BillingAction";
import makeStyles from "@material-ui/core/styles/makeStyles";
import moment from "moment";
import { useTranslation } from 'react-i18next';

const IMG_VARIATION = "strip&scale_crop=400x400&format=jpeg";

const useStyles = makeStyles(theme => ({
    fillHeight: {
        height: '100%'
    },
    wordBreak: {
        wordBreak: "break-all"
    }
}));

const Profile = ({membershipUser, billingLoading, billing, fetchBilling}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    useEffect(() => {
        if (membershipUser) fetchBilling(membershipUser.User_Billing__, membershipUser.User__)

    }, [membershipUser, fetchBilling]);

    let birthDate = 'N/A';
    if (membershipUser && membershipUser.User.Profile.Birthdate && membershipUser.User.Profile.Birthdate !== '0000-00-00')
        birthDate = moment(membershipUser.User.Profile.Birthdate).format('LLL');

    return (
        <Card className={classes.fillHeight} variant='outlined'>
            <CardHeader
                avatar={
                    membershipUser ?
                        <Avatar aria-label="profile"
                                src={membershipUser.User.Profile.Media_Image ? membershipUser.User.Profile.Media_Image[IMG_VARIATION] : ''}/>
                        : <Skeleton variant='circle'/>
                }
                titleTypographyProps={{className: classes.wordBreak}}
                title={
                    membershipUser ? membershipUser.User.Email
                        : <Skeleton/>
                }
            />
            <CardContent>
                {membershipUser && <Typography variant="body1" color="textPrimary" component="p">{t('profile_address')}:</Typography>}

                {billingLoading && <Skeleton height={50}/>}
                {(!billingLoading && billing) &&
                <Typography variant="body2" color="textSecondary" component="p">
                    {billing?.User_Location?.Display ?? 'N/A'}
                </Typography>}

                {(!billingLoading && !membershipUser?.User_Billing__) &&
                    <Typography variant="body2" color="secondary" component="p">
                        {t('membership_billing_disabled')}
                    </Typography>}

                {membershipUser &&
                <Typography variant="body1" color="textPrimary" component="p">{t('profile_birthdate')}:</Typography>}

                {!membershipUser && <Skeleton/>}
                {membershipUser && <Typography variant="body2" color="textSecondary" component="p">
                    {birthDate}
                </Typography>}

                {membershipUser &&
                <Typography variant="body1" color="textPrimary" component="p">{t('profile_gender')}:</Typography>}

                {!membershipUser && <Skeleton/>}
                {membershipUser && <Typography variant="body2" color="textSecondary" component="p">
                    {membershipUser.User.Profile.Gender ?? 'N/A'}
                </Typography>}

            </CardContent>
        </Card>
    );
};

const mapStateToProps = (state) => {
    return {
        billingLoading: state.billing.billingLoading,
        billing: state.billing.billing,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchBilling: (id, _admin_as) => dispatch(fetchOne(id, _admin_as, false))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
