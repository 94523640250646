import {rest, upload} from "@karpeleslab/klbfw";
import {handleError} from "../../../components/utils/apiErrorHandler";
import {
    SHIPPING_EXPORT,
    SHIPPING_EXPORT_DONE,
    SHIPPING_GROUP,
    SHIPPING_GROUP_DONE,
    SHIPPING_IMPORT,
    SHIPPING_IMPORT_DONE,
    SHIPPING_LIST_FETCH,
    SHIPPING_LIST_FETCH_DONE,
    SHIPPING_LOCATION_TO_IMAGE,
    SHIPPING_LOCATION_TO_IMAGE_DONE,
    SHIPPING_PROVIDERS_FETCH,
    SHIPPING_PROVIDERS_FETCH_DONE,
    SHIPPING_REFRESH_PENDING_DONE, SHIPPING_REFRESH_PROCESSING_DONE,
    SHIPPING_SEND,
    SHIPPING_SEND_DONE,
    SHIPPING_SET_FILTERS,
    SHIPPING_SET_PAGING,
    SHIPPING_SET_SELECTED_SHIPPINGS,
    SHIPPING_SET_STATUS,
    SHIPPING_SET_STATUS_DONE,
    SHIPPING_UNGROUP,
    SHIPPING_UNGROUP_DONE,
} from '../../reducers/store/ShippingReducer';
import {success} from "../system/ToastAction";

export const setShippingsPaging = (newPaging) => {
    return (dispatch, getState) => {
        dispatch({type: SHIPPING_SET_PAGING, paging: {...getState().shipping.shippingsPaging, ...newPaging}});
    }
};

export const setShippingsFilters = (newFilters) => {
    return (dispatch, getState) => {
        dispatch({type: SHIPPING_SET_FILTERS, filters: newFilters});
    }
};

export const setSelectedShippings = (selected) => {
    return (dispatch, getState) => {
        dispatch({type: SHIPPING_SET_SELECTED_SHIPPINGS, selected: selected});
    }
};

export const setShippingStatusBulk = (ids, status) => {
    return (dispatch, getState) => {
        dispatch({type: SHIPPING_SET_STATUS});
        return rest('Catalog/Shipping:updateStatus', 'POST', {shipping: ids, status: status})
            .then(data => {
                dispatch(refreshShippingsPendingCount());
                dispatch({type: SHIPPING_SET_STATUS_DONE});
                success('shipping_status_change_success');
                return data.data;
            })
            .catch((err) => {
                dispatch({type: SHIPPING_SET_STATUS_DONE});
                handleError(getState, dispatch, err)
            })
    }
};

export const sendShipping = (ids, tracking) => {
    return (dispatch, getState) => {
        dispatch({type: SHIPPING_SEND});
        return rest('Catalog/Shipping:sent', 'POST', {shipping: ids, tracking: tracking})
            .then(data => {
                dispatch(refreshShippingsPendingCount());
                dispatch({type: SHIPPING_SEND_DONE});
                success('shipping_sent_success');
                return data.data;
            })
            .catch((err) => {
                dispatch({type: SHIPPING_SEND_DONE});
                handleError(getState, dispatch, err)
            })
    }
};

export const refreshShippingsPendingCount = (silent = false) => {
    return (dispatch, getState) => {
        const params = {
            Status: 'pending',
        };

        const id = getState().store.catalog.Catalog__;

        return rest('Catalog/' + id + '/Shipping', 'GET', params)
            .then(data => {
                dispatch({type: SHIPPING_REFRESH_PENDING_DONE, count: data.paging.count});
                return data.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err, silent)
            })
    }
};

export const refreshShippingsProcessingCount = (silent = false) => {
    return (dispatch, getState) => {
        const params = {
            Status: 'processing',
        };

        const id = getState().store.catalog.Catalog__;

        return rest('Catalog/' + id + '/Shipping', 'GET', params)
            .then(data => {
                dispatch({type: SHIPPING_REFRESH_PROCESSING_DONE, count: data.paging.count});
                return data.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err, silent)
            })
    }
};

export const fetchShippings = () => {
    return (dispatch, getState) => {
        dispatch({type: SHIPPING_LIST_FETCH});

        const params = {
            ...getState().shipping.shippingsFilters,
            ...getState().shipping.shippingsPaging,
        };

        const id = getState().store.catalog.Catalog__;

        return rest('Catalog/' + id + '/Shipping', 'GET', params)
            .then(data => {
                dispatch({type: SHIPPING_LIST_FETCH_DONE, shippings: data.data, paging: data.paging});
                return data.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const shippingsToImage = (shippingIds, width = 991, height = 306, format = 'png') => {
    return (dispatch, getState) => {
        dispatch({type: SHIPPING_LOCATION_TO_IMAGE});
        const result = [];
        const batch = 5;

        const params = {
            format: format,
            width: width,
            height: height
        };

        const batchPromises = (i, reject) => {
            const lastIndex = Math.min(i + batch, shippingIds.length);
            const promises = [];
            for (let j = i; j < lastIndex; j++) {
                promises.push(
                    rest('Catalog/Shipping/' + shippingIds[j] + ':print', 'POST', params)
                        .then(d => result.push(d.data))
                        .catch(reject))
            }

            return Promise.all(promises).catch(reject)
        };

        return new Promise((resolve, reject) => {
            let promises = null;
            for (let i = 0; i < shippingIds.length; i += batch) {
                if (promises)
                    promises = promises.then(() => batchPromises(i, reject));
                else promises = batchPromises(i, reject);
            }

            if (promises) promises.then(() => resolve(result));
            else {
                resolve(result)
            }

        })
            .then(r => {
                dispatch({type: SHIPPING_LOCATION_TO_IMAGE_DONE});
                return r;
            }).catch((err) => {
                handleError(getState, dispatch, err)
            })

    }
};


export const shippingExport = (shippingIds, format) => {
    return (dispatch, getState) => {
        dispatch({type: SHIPPING_EXPORT});

        const params = {
            method: format,
            shipping: shippingIds
        }

        return rest('Catalog/Shipping/Transfer:export', 'POST', params)
            .then(d => {
                dispatch({type: SHIPPING_EXPORT_DONE});
                return d.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
}

export const shippingImport = (format, file) => {
    return (dispatch, getState) => {
        dispatch({type: SHIPPING_IMPORT});
        const params = {
            method: format,
        }

        return new Promise((resolve, reject) => {
            upload.onprogress = (item) => {
                item.failed.forEach(i => {
                    upload.deleteItem(i.up_id)
                    reject(i.failure);
                })
            };

            upload.append('Catalog/Shipping/Transfer:import', file, params)
                .then((data) => {
                    resolve(data.final);
                }).catch(reject);

            upload.run();

        }).then(d => {
            dispatch({type: SHIPPING_IMPORT_DONE});
            success('shipping_import_success');
            return d;
        }).catch((err) => {
            dispatch({type: SHIPPING_IMPORT_DONE});
            handleError(getState, dispatch, err)
        });
    }
}

export const fetchShippingProviders = () => {
    return (dispatch, getState) => {
        if (getState().shipping.loadingProviders || getState().shipping.providers.length > 0) return; // consider the providers list won't change often, allow to cache them
        dispatch({type: SHIPPING_PROVIDERS_FETCH})
        return rest('Catalog/Shipping/Transfer:getMethods')
            .then(d => {
                dispatch({type: SHIPPING_PROVIDERS_FETCH_DONE, providers: d.data})
                return d.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
}

export const shippingGroup = (id, toGroupIds) => {
    return (dispatch, getState) => {
        dispatch({type: SHIPPING_GROUP});
        return rest('Catalog/Shipping/' + id + ':append', 'POST', {shipping: toGroupIds})
            .then(d => {
                success('shipping_group_success')
                dispatch({type: SHIPPING_GROUP_DONE})
                return d.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
}

export const shippingUngroup = (id, toUngroupIds) => {
    return (dispatch, getState) => {
        dispatch({type: SHIPPING_UNGROUP});
        return rest('Catalog/Shipping/' + id + ':split', 'POST', {items: toUngroupIds})
            .then(d => {
                success('shipping_ungroup_success')
                dispatch({type: SHIPPING_UNGROUP_DONE})
                return d.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
}
