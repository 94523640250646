import React from 'react';
import TableRow from "@material-ui/core/TableRow";
import Skeleton from "@material-ui/lab/Skeleton";
import TableCell from "@material-ui/core/TableCell";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import EditIcon from '@material-ui/icons/Edit';
import moment from "moment";
import {Link} from "react-router-dom";
import HasRight from "../../../../Route/HasRight";
import {getFanclubPlanEditRoute} from "../../../../../router/routes/fanclub/factory/plan";
import Button from "../../../inputs/Button";

const Row = ({plan}) => {

    const displayPlan = (plan);

    return (
        <TableRow>
            <TableCell component="th" scope="row">
                {!displayPlan && <Skeleton variant="circle" width={40} height={40}/>}
                {(displayPlan && plan.Visible === 'Y') && <VisibilityIcon/>}
                {(displayPlan && plan.Visible === 'N') && <VisibilityOffIcon/>}

            </TableCell>
            <TableCell>
                {!displayPlan && <Skeleton/>}
                {displayPlan &&
                <span>{plan.Priority}</span>
                }
            </TableCell>
            <TableCell>
                {!displayPlan && <Skeleton/>}
                {displayPlan && <span>{plan.Catalog_Product['Basic.Name']}</span>}
            </TableCell>
            <TableCell align="right">
                {!displayPlan && <Skeleton/>}
                {displayPlan && <span>{moment(parseInt(plan.Created.unixms)).format('LLL')}</span>}
            </TableCell>
            <TableCell align="right">
                {!displayPlan && <Skeleton/>}
                {displayPlan &&
                <HasRight admin hideLoading>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<EditIcon/>}
                        component={Link} to={getFanclubPlanEditRoute(plan.Membership_Plan__)}
                    >
                        Edit
                    </Button>
                </HasRight>
                }
            </TableCell>
        </TableRow>
    );
};


export default Row;