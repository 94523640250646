import React                            from 'react';
import TableRow                         from '@material-ui/core/TableRow';
import Skeleton                         from '@material-ui/lab/Skeleton';
import TableCell                        from '@material-ui/core/TableCell';
import SearchIcon                       from '@material-ui/icons/Search';
import { Link }                         from 'react-router-dom';
import { useTranslation }               from 'react-i18next';
import { formatDuration }               from '../../../../utils/time';
import * as moment                      from 'moment';
import defaultPicture                   from '../../../../../resources/images/landscape-image.svg';
import Avatar                           from '@material-ui/core/Avatar';
import Button                           from '../../../inputs/Button';
import { getMusicDiscographyEditRoute } from '../../../../../router/routes/music/factory/discography';
import { Chip }                         from '@material-ui/core';

const Row = ({ release, loading }) => {
	const { t } = useTranslation();
	const album = release ? release.Music_Label_Album : null;
	const displayAlbum = (!loading && album);

	return (
		<TableRow>
			<TableCell>
				{!displayAlbum && <Skeleton width={40} height={40}/>}
				{(displayAlbum) &&
				<Avatar variant='square' src={album.Drive_Item__ ? album.Drive_Item.Icon : defaultPicture}/>}
			</TableCell>

			<TableCell>
				{!displayAlbum && <Skeleton/>}
				{displayAlbum && <span>{album.Name}</span>}
			</TableCell>

			<TableCell>
				{!displayAlbum && <Skeleton/>}
				{displayAlbum && <span>{(release.Tags ?? []).map((tag) =>
					<Chip
						label={tag.Name}
						variant='outlined'
						style={{marginLeft:'5px'}}/>)}
		        </span>}
			</TableCell>

			<TableCell>
				{!displayAlbum && <Skeleton/>}
				{displayAlbum && <span>{album.Tracks}</span>}
			</TableCell>
			<TableCell>
				{!displayAlbum && <Skeleton/>}
				{displayAlbum && <span>{formatDuration(moment.duration(parseInt(album.Duration), 'seconds'))}</span>}
			</TableCell>

			<TableCell align='right'>
				{!displayAlbum && <Skeleton/>}
				{displayAlbum && <Button
					variant='contained'
					color='primary'
					startIcon={<SearchIcon/>}
					component={Link}
					to={getMusicDiscographyEditRoute(release.Music_Label_Release__)}
				>
					{t('view_btn')}
				</Button>}
			</TableCell>
		</TableRow>
	);
};

export default Row;
