import {USER_LOGOUT}  from '../system/UserReducer';
import {COMMON_RESET} from '../CommonReducer';

const initState = () => {
	return {
		feesLoading: false,
		fees: [],
		feesFilters: {},
		feesPagingCount: 0,
		feesPaging: {
			page_no: 1,
			results_per_page: 10
		},


		zonesLoading: false,
		zones: [],
		zonesFilters: {},
		zonesPagingCount: 0,
		zonesPaging: {
			page_no: 1,
			results_per_page: 10
		},

		scalesLoading: false,
		scales: [],
		scalesFilters: {},
		scalesPagingCount: 0,
		scalesPaging: {
			page_no: 1,
			results_per_page: 10
		},

		fee: null,
		feeLoading: true,

		creating: false,
		updating: false,
		deleting: false,

		zoneCreating:false,
		zoneUpdating:false,
		zoneDeleting:false,

		zoneValuesLoading: false,
		zoneValues: [],
		zoneValuesFilters: {},
		zoneValuesPagingCount: 0,
		zoneValuesPaging: {
			page_no: 1,
			results_per_page: 10
		},
		zoneValueCreating:false,
		zoneValueUpdating:false,
		zoneValueDeleting:false,

		scaleCreating:false,
		scaleUpdating:false,
		scaleDeleting:false,

	};
};



export const SHIPPING_FEE_SCALE_DELETE = 'SHIPPING_FEE_SCALE_DELETE';
export const SHIPPING_FEE_SCALE_DELETE_DONE = 'SHIPPING_FEE_SCALE_DELETE_DONE';

export const SHIPPING_FEE_SCALE_UPDATE = 'SHIPPING_FEE_SCALE_UPDATE';
export const SHIPPING_FEE_SCALE_UPDATE_DONE = 'SHIPPING_FEE_SCALE_UPDATE_DONE';

export const SHIPPING_FEE_SCALE_CREATE = 'SHIPPING_FEE_SCALE_CREATE';
export const SHIPPING_FEE_SCALE_CREATE_DONE = 'SHIPPING_FEE_SCALE_CREATE_DONE';

export const SHIPPING_FEE_SCALE_LIST_FETCH = 'SHIPPING_FEE_SCALE_LIST_FETCH';
export const SHIPPING_FEE_SCALE_LIST_FETCH_DONE = 'SHIPPING_FEE_SCALE_LIST_FETCH_DONE';

export const SHIPPING_FEE_SCALE_SET_PAGING = 'SHIPPING_FEE_SCALE_SET_PAGING';
export const SHIPPING_FEE_SCALE_SET_FILTERS = 'SHIPPING_FEE_SCALE_SET_FILTERS';

export const SHIPPING_FEE_ZONE_DELETE = 'SHIPPING_FEE_ZONE_DELETE';
export const SHIPPING_FEE_ZONE_DELETE_DONE = 'SHIPPING_FEE_ZONE_DELETE_DONE';

export const SHIPPING_FEE_ZONE_UPDATE = 'SHIPPING_FEE_ZONE_UPDATE';
export const SHIPPING_FEE_ZONE_UPDATE_DONE = 'SHIPPING_FEE_ZONE_UPDATE_DONE';

export const SHIPPING_FEE_ZONE_CREATE = 'SHIPPING_FEE_ZONE_CREATE';
export const SHIPPING_FEE_ZONE_CREATE_DONE = 'SHIPPING_FEE_ZONE_CREATE_DONE';

export const SHIPPING_FEE_ZONE_LIST_FETCH = 'SHIPPING_FEE_ZONE_LIST_FETCH';
export const SHIPPING_FEE_ZONE_LIST_FETCH_DONE = 'SHIPPING_FEE_ZONE_LIST_FETCH_DONE';

export const SHIPPING_FEE_ZONE_SET_PAGING = 'SHIPPING_FEE_ZONE_SET_PAGING';
export const SHIPPING_FEE_ZONE_SET_FILTERS = 'SHIPPING_FEE_ZONE_SET_FILTERS';

export const SHIPPING_FEE_LIST_FETCH = 'SHIPPING_FEE_LIST_FETCH';
export const SHIPPING_FEE_LIST_FETCH_DONE = 'SHIPPING_FEE_LIST_FETCH_DONE';

export const SHIPPING_FEE_SET_PAGING = 'SHIPPING_FEE_SET_PAGING';
export const SHIPPING_FEE_SET_FILTERS = 'SHIPPING_FEE_SET_FILTERS';

export const SHIPPING_FEE_CREATE = 'SHIPPING_FEE_CREATE';
export const SHIPPING_FEE_CREATE_DONE = 'SHIPPING_FEE_CREATE_DONE';

export const SHIPPING_FEE_UPDATE = 'SHIPPING_FEE_UPDATE';
export const SHIPPING_FEE_UPDATE_DONE = 'SHIPPING_FEE_UPDATE_DONE';

export const SHIPPING_FEE_FETCH = 'SHIPPING_FEE_FETCH';
export const SHIPPING_FEE_FETCH_DONE = 'SHIPPING_FEE_FETCH_DONE';

export const SHIPPING_FEE_DELETE = 'SHIPPING_FEE_DELETE';
export const SHIPPING_FEE_DELETE_DONE = 'SHIPPING_FEE_DELETE_DONE';

export const SHIPPING_FEE_ZONE_VALUE_DELETE = 'SHIPPING_FEE_ZONE_VALUE_DELETE';
export const SHIPPING_FEE_ZONE_VALUE_DELETE_DONE = 'SHIPPING_FEE_ZONE_VALUE_DELETE_DONE';

export const SHIPPING_FEE_ZONE_VALUE_UPDATE = 'SHIPPING_FEE_ZONE_VALUE_UPDATE';
export const SHIPPING_FEE_ZONE_VALUE_UPDATE_DONE = 'SHIPPING_FEE_ZONE_VALUE_UPDATE_DONE';

export const SHIPPING_FEE_ZONE_VALUE_CREATE = 'SHIPPING_FEE_ZONE_VALUE_CREATE';
export const SHIPPING_FEE_ZONE_VALUE_CREATE_DONE = 'SHIPPING_FEE_ZONE_VALUE_CREATE_DONE';

export const SHIPPING_FEE_ZONE_VALUE_LIST_FETCH = 'SHIPPING_FEE_ZONE_VALUE_LIST_FETCH';
export const SHIPPING_FEE_ZONE_VALUE_LIST_FETCH_DONE = 'SHIPPING_FEE_ZONE_VALUE_LIST_FETCH_DONE';

export const SHIPPING_FEE_ZONE_VALUE_SET_PAGING = 'SHIPPING_FEE_ZONE_VALUE_SET_PAGING';
export const SHIPPING_FEE_ZONE_VALUE_SET_FILTERS = 'SHIPPING_FEE_ZONE_VALUE_SET_FILTERS';

const feeReducer = (state = initState(), action) => {
	switch (action.type) {
		case USER_LOGOUT:
		case COMMON_RESET:
			return initState();
		case SHIPPING_FEE_CREATE:
			return {...state, creating: true};
		case SHIPPING_FEE_CREATE_DONE:
			return {...state, creating: false};
		case SHIPPING_FEE_FETCH:
			return {...state, feeLoading: true, fee: null};
		case SHIPPING_FEE_FETCH_DONE:
			return {...state, feeLoading: false, fee: action.fee};
		case SHIPPING_FEE_UPDATE:
			return {...state, updating: true};
		case SHIPPING_FEE_UPDATE_DONE:
			return {...state, updating: false, fee: action.fee};
		case SHIPPING_FEE_DELETE:
			return {...state, deleting: true};
		case SHIPPING_FEE_DELETE_DONE:
			return {...state, deleting: false};
		case SHIPPING_FEE_LIST_FETCH:
			return {...state, feesLoading: true};
		case SHIPPING_FEE_LIST_FETCH_DONE:
			return {
				...state,
				feesLoading: false,
				fees: action.fees,
				feesPagingCount: action.paging.count
			};
		case SHIPPING_FEE_SET_FILTERS :
			return {...state, feesFilters: action.filters};
		case SHIPPING_FEE_SET_PAGING :
			return {...state, feesPaging: action.paging};

		case SHIPPING_FEE_ZONE_LIST_FETCH:
			return {...state, zonesLoading: true};
		case SHIPPING_FEE_ZONE_LIST_FETCH_DONE:
			return {
				...state,
				zonesLoading: false,
				zones: action.zones,
				zonesPagingCount: action.paging.count
			};

		case SHIPPING_FEE_ZONE_SET_FILTERS :
			return {...state, zonesFilters: action.filters};
		case SHIPPING_FEE_ZONE_SET_PAGING :
			return {...state, zonesPaging: action.paging};
		case SHIPPING_FEE_ZONE_CREATE:
			return {...state, zoneCreating: true};
		case SHIPPING_FEE_ZONE_CREATE_DONE:
			return {...state, zoneCreating: false};
		case SHIPPING_FEE_ZONE_UPDATE:
			return {...state, zoneUpdating: true};
		case SHIPPING_FEE_ZONE_UPDATE_DONE:
			const list = [...state.zones]
			for(let i = 0; i<list.length; i++) {
				if(list[i].Catalog_Deliverable_Fee_Zone__ === action.zone.Catalog_Deliverable_Fee_Zone__) {
					list[i] = action.zone;
					break;
				}
			}
			return {...state, zoneUpdating: false, zones: list};
		case SHIPPING_FEE_ZONE_DELETE:
			return {...state, zoneDeleting: true};
		case SHIPPING_FEE_ZONE_DELETE_DONE:
			const listTmp = state.zones.filter(z => z.Catalog_Deliverable_Fee_Zone__ !== action.zoneId)
			return {...state, zoneDeleting: false, zones: listTmp};


		case SHIPPING_FEE_SCALE_LIST_FETCH:
			return {...state, scalesLoading: true};
		case SHIPPING_FEE_SCALE_LIST_FETCH_DONE:
			return {
				...state,
				scalesLoading: false,
				scales: action.scales,
				scalesPagingCount: action.paging.count
			};
		case SHIPPING_FEE_SCALE_SET_FILTERS :
			return {...state, scalesFilters: action.filters};
		case SHIPPING_FEE_SCALE_SET_PAGING :
			return {...state, scalesPaging: action.paging};
		case SHIPPING_FEE_SCALE_CREATE:
			return {...state, scaleCreating: true};
		case SHIPPING_FEE_SCALE_CREATE_DONE:
			return {...state, scaleCreating: false};
		case SHIPPING_FEE_SCALE_UPDATE:
			return {...state, scaleUpdating: true};
		case SHIPPING_FEE_SCALE_UPDATE_DONE:
			return {...state, scaleUpdating: false};
		case SHIPPING_FEE_SCALE_DELETE:
			return {...state, scaleDeleting: true};
		case SHIPPING_FEE_SCALE_DELETE_DONE:
			return {...state, scaleDeleting: false};

		case SHIPPING_FEE_ZONE_VALUE_CREATE:
			return {...state, zoneValueCreating: true};
		case SHIPPING_FEE_ZONE_VALUE_CREATE_DONE: {
			const tmp = [...state.zones];
			for(let i = 0; i < tmp.length; i++) {
				if(action.value.Catalog_Deliverable_Fee_Zone__ !== tmp[i].Catalog_Deliverable_Fee_Zone__) continue;
				tmp[i].Values.push(action.value)
				break;
			}
			return {...state, zoneValueCreating: true, zones:tmp};
		}
		case SHIPPING_FEE_ZONE_VALUE_UPDATE:
			return {...state, zoneValueUpdating: true};
		case SHIPPING_FEE_ZONE_VALUE_UPDATE_DONE: {
			const tmp = [...state.zones];
			for(let i = 0; i < tmp.length; i++) {
				if(action.value.Catalog_Deliverable_Fee_Zone__ !== tmp[i].Catalog_Deliverable_Fee_Zone__) continue;
				for(let j = 0; j < tmp[i].Values.length; j++) {
					if(tmp[i].Values[j].Catalog_Deliverable_Fee_Zone_Value__ !== action.value.Catalog_Deliverable_Fee_Zone_Value__) continue;
					tmp[i].Values[j] = action.value;
					break;
				}

				break;
			}

			return {...state, zoneValueUpdating: false, zones:tmp};
		}

		case SHIPPING_FEE_ZONE_VALUE_DELETE:
			return {...state, zoneValueDeleting: true};
		case SHIPPING_FEE_ZONE_VALUE_DELETE_DONE: {
			const tmp = [...state.zones];
			for(let i = 0; i < tmp.length; i++) {
				if(action.valueId !== tmp[i].Catalog_Deliverable_Fee_Zone__) continue;
				for(let j = 0; j < tmp[i].Values.length; j++) {
					if(tmp[i].Values[j].Catalog_Deliverable_Fee_Zone_Value__ !== action.valueId) continue;
					tmp[i].Values.splice(j, 1);
					break;
				}

				break;
			}

			return {...state, zoneValueDeleting: false, zones:tmp};
		}

		case SHIPPING_FEE_ZONE_VALUE_LIST_FETCH:
			return {...state, zoneValuesLoading: true};
		case SHIPPING_FEE_ZONE_VALUE_LIST_FETCH_DONE:
			return {
				...state,
				zoneValuesLoading: false,
				zoneValues: action.values,
				zoneValuesPagingCount: action.paging.count
			};

		case SHIPPING_FEE_ZONE_VALUE_SET_FILTERS :
			return {...state, zoneValuesFilters: action.filters};
		case SHIPPING_FEE_ZONE_VALUE_SET_PAGING :
			return {...state, zoneValuesPaging: action.paging};


		default:
			return state;
	}

};

export default feeReducer;