import React, { Fragment, useEffect } from 'react';
import TableHead                      from '@material-ui/core/TableHead';
import MaterialTable                  from '@material-ui/core/Table';
import TableRow                       from '@material-ui/core/TableRow';
import TableCell                      from '@material-ui/core/TableCell';
import TableBody                      from '@material-ui/core/TableBody';
import TableContainer                 from '@material-ui/core/TableContainer';
import Row                            from './Row';
import { connect }                    from 'react-redux';
import TableFooter                    from '@material-ui/core/TableFooter';
import { useTranslation }             from 'react-i18next';
import Empty                          from './Empty';

import { fetchEvents, setEventsFilters, setEventsPaging } from '../../../../../store/actions/planner/EventAction';
import Pagination                                         from '../../../data/table/Pagination';

const Table = ({ events, fetch, loading, filters, paging, count, setEventsPaging, setEventsFilters }) => {
	const loadingData = [...Array(paging.results_per_page).keys()];
	const { t } = useTranslation();
	const displayLoading = () => loadingData.map((k) => <Row key={k} loading={true} event={null}/>);
	const displayEvents = () => events.map((u, idx) => <Row key={u.Planner_Event__} loading={false} event={u}/>);

	useEffect(() => {
		// Reset filters the first time
		setEventsFilters({});
	}, [setEventsFilters]);

	useEffect(() => {
		fetch();
	}, [fetch, filters, paging]);

	/*const {sort} = filters;

	const sortHandler = (property, dir) => {
		let p = {...filters};
		// only 1 sort
		p.sort = {};
		p.sort[property] = dir;
		setSchedulesFilters(p);
	};*/

	return (
		<Fragment>
			{(loading || events.length > 0) &&
			<TableContainer>
				<MaterialTable aria-label='simple table'>
					<TableHead>
						<TableRow>
							<Pagination
								setPaging={setEventsPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
						<TableRow>
							<TableCell>{t('tablecell_visible')}</TableCell>
							<TableCell> {t('tablecell_event_date')}</TableCell>
							<TableCell> {t('tablecell_event_name')}</TableCell>
							<TableCell> {t('tablecell_event_reservation')}</TableCell>
							<TableCell> {t('tablecell_event_categories')}</TableCell>
							<TableCell align='right'/>
						</TableRow>
					</TableHead>
					<TableBody>
						{loading && displayLoading()}
						{!loading && displayEvents()}
					</TableBody>
					<TableFooter>
						<TableRow>
							<Pagination
								setPaging={setEventsFilters}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
					</TableFooter>

				</MaterialTable>
			</TableContainer>
			}
			{(!loading && events.length < 1) && <Empty/>}
		</Fragment>
	);

};


const mapStateToProps = (state) => {
	return {
		filters: state.event.eventsFilters,
		paging: state.event.eventsPaging,
		count: state.event.eventsPagingCount,
		loading: state.event.eventsLoading,
		events: state.event.events
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: () => dispatch(fetchEvents()),
		setEventsPaging: (newPaging) => dispatch(setEventsPaging(newPaging)),
		setEventsFilters: (newFilters) => dispatch(setEventsFilters(newFilters))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);

