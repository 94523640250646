import React from 'react';
import {useTranslation} from "react-i18next";
import Graphic from "../../../../resources/images/deliverableFiles.svg";
import EmptyState from "../../feeback/State/Empty";

const EmptyFiles = () => {
    const {t} = useTranslation();

    return (
        <EmptyState
            title={t('store_warehouse_file_empty_title')}
            textI18Key={'store_warehouse_file_empty_text'}
            graphic={Graphic}
        />
    );

};


export default EmptyFiles;