import React, { useEffect, useState } from 'react';
import Grid                           from '@material-ui/core/Grid';
import Typography                from '@material-ui/core/Typography';
import { Trans, useTranslation } from 'react-i18next';
import MaterialLink              from '@material-ui/core/Link';
import HasRight              from '../../Route/HasRight';
import Alert                 from '@material-ui/lab/Alert';
import StripeAccountSelector from './StipeAccountSelector';
import Button                from '../inputs/Button';

const StepStripe = ({handleNext, handleBack, stripe, realm, setStripe}) => {
	const {t} = useTranslation();
	const [saveEnabled, setSaveEnabled] = useState(false);

	useEffect(() => {
		setSaveEnabled(stripe && realm);
	}, [stripe, realm]);

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Typography variant='h5' color='textPrimary' component='h5'>
					{t('stripe_required_title')}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography variant='body1' color='textSecondary' component='p'>
					<Trans i18nKey='stripe_required_desc'>
						sample <br/>
						<strong>sample</strong>
						<MaterialLink href='https://stripe.com' target='_blank' rel='noopener noreferrer'>Stripe
							link</MaterialLink>
					</Trans>
				</Typography>
			</Grid>

			<HasRight owner deniedComponent={
				<Grid container justify='center'>
					<Grid item>
						<Alert severity='warning'>
							{t('stripe_required_owner_required')}
						</Alert>
					</Grid>
				</Grid>

			}>
				<Grid item xs={12}>
					<StripeAccountSelector value={stripe} onChange={setStripe}/>
				</Grid>

				<Grid item xs={12}>
					<Grid container justify='flex-end' spacing={3}>
						<Grid item>
							<Button onClick={handleNext} variant='contained' color='primary'
							        disabled={!saveEnabled}>
								{t('use_stripe_btn')}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</HasRight>
		</Grid>
	);
};

export default StepStripe;
