import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next';
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import {createMinimalArtist, searchArtist} from "../../../../../store/actions/ArtistAction";
import {createFilterOptions} from "@material-ui/lab";
import CollaboratorRoles from "../../../inputs/CollaboratorRoles";
import AddIcon from "@material-ui/icons/Add";
import CreateArtistDialog from "./CreateArtistDialog";
import Button from "../../../inputs/Button";

const filter = createFilterOptions();

const Create = ({searchArtist, artistsList, artistsLoading, artist, createArtist, artistCreating, onAdd, existing, disabled}) => {
    const {t} = useTranslation();
    const [selectedArtist, setSelectedArtist] = useState(null);
    const [selectedRole, setSelectedRole] = useState();

    const controlDisabled = artistCreating || artistsLoading || disabled;

    const [saveEnabled, setSaveEnabled] = useState(false);
    const [createArtistName, setCreateArtistName] = useState('');
    const [createArtistDialogOpen, setCreateArtistDialogOpen] = useState(false);

    const [disabledRoles, setDisabledRoles] = useState([]);

    useEffect(() => {
        const list = [];
        if (!selectedArtist) {
            setDisabledRoles(list);
            return;
        }

        existing.forEach(a => {
            if (a.Music_Label_Artist__ === selectedArtist.Music_Label_Artist__)
                list.push(a.Role)
        });

        setDisabledRoles(list);

    }, [existing, selectedArtist]);

    useEffect(() => {
        if (!artist) return;
        searchArtist()
    }, [searchArtist, artist]);


    useEffect(() => {
        setSaveEnabled(selectedArtist && selectedRole);
    }, [selectedArtist, selectedRole, setSaveEnabled]);

    const handleCreateCollaborator = () => {
        onAdd(selectedArtist, selectedRole);
        setSelectedArtist(null);
        setSelectedRole('')
    };

    const handleCreateArtist = (name) => {
        createArtist(name)
            .then(a => {
                searchArtist()
                    .then(s => setSelectedArtist(a))
                    .then(s => setCreateArtistDialogOpen(false))
            })
    };

    return (
        <Grid container spacing={3} justify="flex-start">
            <Grid item xs={12} md={4}>
                <Autocomplete
                    id="artist-select"
                    value={selectedArtist}
                    getOptionSelected={(option, value) => option.Music_Label_Artist__ === value.Music_Label_Artist__}
                    getOptionLabel={(option) => option === '' ? '' : option.Name}
                    renderOption={(option) => option.Name}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        if (params.inputValue !== '') {
                            filtered.push({
                                inputValue: params.inputValue,
                                Name: artistCreating ? t('creating_artist', {name: params.inputValue}) : t('create_artist', {name: params.inputValue}),
                            });
                        }

                        return filtered;
                    }}
                    onChange={(event, newValue) => {
                        if (artistCreating) return;
                        if (typeof newValue === 'string') {
                            // timeout to avoid instant validation of the dialog's form.
                            if (!newValue.hasKanji()) {
                                handleCreateArtist({'en-US': newValue});
                                return;
                            }
                            setTimeout(() => {
                                setCreateArtistName(newValue);
                                setCreateArtistDialogOpen(true)
                            });
                            return;
                        }

                        if (newValue && newValue.inputValue) {
                            if (!newValue.inputValue.hasKanji()) {
                                handleCreateArtist({'en-US': newValue.inputValue});
                                return;
                            }

                            setCreateArtistName(newValue.inputValue);
                            setCreateArtistDialogOpen(true);

                            return;
                        }

                        setSelectedArtist(newValue);
                    }}

                    options={artistsList}
                    disabled={controlDisabled}
                    loading={artistCreating || artistsLoading}
                    renderInput={(params) => (
                        <TextField
                            fullWidth
                            {...params}
                            label={t('artist_select_lbl')}
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {(artistCreating || artistsLoading) ?
                                            <CircularProgress color="inherit" size={20}/> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <CollaboratorRoles value={selectedRole} onChange={e => setSelectedRole(e.target.value)}
                                   disabledOptions={disabledRoles}
                                   inputProps={{
                                       variant: 'outlined',
                                       fullWidth: true,
                                       disabled: controlDisabled
                                   }}/>
            </Grid>

            <Grid item>
                <Grid container>
                    <Grid item>
                        <Button variant='contained' color='primary' onClick={handleCreateCollaborator}
                                disabled={!saveEnabled || controlDisabled}
                                endIcon={<AddIcon/>}>
                            {t('add_btn')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <CreateArtistDialog open={createArtistDialogOpen} setOpen={setCreateArtistDialogOpen}
                                nameValue={createArtistName} handleCreate={handleCreateArtist}/>
        </Grid>

    );
};

const mapStateToProps = (state) => {
    return {
        artistsList: state.artist.artists,
        artist: state.artist.artist,
        artistsLoading: state.artist.artistsLoading,
        artistCreating: state.artist.artistMinimalCreating,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        searchArtist: (namePrefix) => dispatch(searchArtist(namePrefix)),
        createArtist: (name) => dispatch(createMinimalArtist(name))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);