import React, {useCallback, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import {TextField} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import uuid from "../../utils/uuid";
import defaultPicture                       from "../../../resources/images/landscape-image.svg";
import SnsLinks                             from "../../common/data/SnsLink/SnsLinks";
import InputI18N                            from "../../common/inputs/InputI18n";
import {requiredCheck as nameRequiredCheck} from '../../utils/i18nInput'
import Date                                 from "../../common/inputs/Date";
import DialogContent                        from "@material-ui/core/DialogContent";
import Dialog                               from "@material-ui/core/Dialog";
import DialogTitle                          from "@material-ui/core/DialogTitle";
import DialogActions                        from "@material-ui/core/DialogActions";
import BloodTypeSelector                    from "../../common/inputs/BloodTypeSelector";
import Button                               from "../../common/inputs/Button";
import FormRequiredField                    from '../../common/feeback/FormRequiredField';

const AddMemberDialog = ({member, memberIdx, onEdit, open, setOpen, onAdd}) => {
    const {t} = useTranslation();

    // Use to trick react to rerender the input without a value
    const [profilePictUuid, setProfilePictUuid] = useState(uuid);

    const [needDetailedName, setNeedDetailedName] = useState(false);

    const [name, setName] = useState(member ? member.name : {});
    const [bloodType, setBloodType] = useState(member ? member.bloodType ?? '' : '');
    const [birthdate, setBirthdate] = useState(member ? member.birthdate : null);
    const [biography, setBiography] = useState(member ? member.biography : '');
    const [links, setLinks] = useState(member ? member.links : []);
    const [profilePicture, setProfilePicture] = useState(member ? member.picture : undefined);
    const pictureRef = React.useRef(null);

    const [addEnabled, setAddEnabled] = useState(false);

    const handleClose = () => {
        setOpen(false)
    };

    useEffect(() => {
        if (!member) return;
        setName(member.name ?? {});
        setBloodType(member.bloodType ?? '');
        setBirthdate(member.birthdate ?? null);
        setBiography(member.biography ?? '');
        setLinks(member.links ?? []);
        setProfilePicture(member.picture ?? undefined);
    }, [member, setBloodType, setBirthdate, setBiography, setLinks, setProfilePicture, setName]);

    const onPictureChange = (e) => {
        if (e.target.files.length < 1) {
            setProfilePicture(undefined)
        } else {
            setProfilePicture(e.target.files[0])
        }
    };


    useEffect(() => {
        setAddEnabled(nameRequiredCheck(name, needDetailedName))
    }, [name, needDetailedName, setAddEnabled]);

    const setProfilePictSrc = useCallback(() => {
        if (profilePicture === undefined || profilePicture === null) {
            pictureRef.current.src = defaultPicture;
            return;
        }

        const fr = new FileReader();
        fr.onload = function () {
            pictureRef.current.src = fr.result;
        };
        fr.readAsDataURL(profilePicture);
    }, [profilePicture, pictureRef]);

    useEffect(() => {
        if (!pictureRef || !pictureRef.current) return;

        setProfilePictSrc()

    }, [profilePicture, setProfilePictSrc]);

    const reset = () => {
        setName({});
        setProfilePicture(null);
        setBloodType('');
        setLinks([]);
        setBiography('');
        setBirthdate(null);
        setProfilePictUuid(uuid());
    };

    const addMemberHandler = () => {
        onAdd({
            name: name,
            picture: profilePicture,
            bloodType: bloodType ? bloodType : null,
            links: links,
            biography: biography,
            birthdate: birthdate ?? null
        });


        reset();
        handleClose();
    };

    const editMemberHandler = () => {
        onEdit(memberIdx,
            {
                name: name,
                picture: profilePicture,
                bloodType: bloodType ? bloodType : null,
                links: links,
                biography: biography,
                birthdate: birthdate ?? null
            });
        reset();
        handleClose();
    };

    return (
        <Dialog
            open={open}
            aria-labelledby={t('artist_wizard_step_3_title')}
            aria-describedby={t('artist_wizard_step_3_title')}
            onRendered={() => setProfilePictSrc()}
            fullWidth
            maxWidth='lg'
        >
            <DialogTitle
                id="create-member-title">{member ? t('member_edit_title') : t('artist_wizard_step_3_title')}</DialogTitle>
            <DialogContent>

                <Grid container spacing={2}>
                    <FormRequiredField/>
                    <Grid item xs={12}>
                        <InputI18N value={name}
                                   onChange={setName}
                                   onDetailedNameChange={setNeedDetailedName}
                                   labelKey='artist_member_name'
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <BloodTypeSelector value={bloodType} setValue={setBloodType}/>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Date
                            disableFuture
                            openTo="year"
                            format="LL"
                            views={["year", "month", "date"]}
                            label={t('profile_birthdate')}
                            inputVariant="outlined"
                            fullWidth
                            value={birthdate ?? null}
                            onChange={setBirthdate}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <SnsLinks values={links} onChange={setLinks}/>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <img height={150} ref={pictureRef} alt={t('member_avatar')}/>
                            </Grid>
                            <Grid item>
                                <FormControl fullWidth>
                                    <InputLabel shrink={true} htmlFor='member-avatar'>{t('member_avatar')}</InputLabel>
                                    <Input
                                        key={profilePictUuid}
                                        onChange={onPictureChange} inputProps={
                                        {
                                            accept: "image/png, image/jpeg, image/jpg, image/svg"
                                        }
                                    }
                                        id="member-avatar" type='file'/>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            variant='outlined'
                            multiline rows={5}
                            rowsMax={Infinity}
                            placeholder={t('member_bio_placeholder')}
                            fullWidth
                            value={biography}
                            onChange={e => setBiography(e.target.value)}
                            label={t('member_bio_label')}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={handleClose}>
                    {t('close_btn')}
                </Button>
                <Button variant='contained' color="primary" disabled={!addEnabled} onClick={() => {
                    member ? editMemberHandler() : addMemberHandler()
                }}>
                    {member ? t('save_btn') : t('add_btn')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddMemberDialog;
