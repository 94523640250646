import React, {useState} from 'react';
import {useTranslation}  from 'react-i18next';
import {connect}         from 'react-redux';
import {fetchEntry}      from '../../../../../../store/actions/CmsAction';
import {fetchComment}    from '../../../../../../store/actions/SocialAction';
import Button            from '../../../../inputs/Button';
import {Grid}            from '@material-ui/core';

const ParentComment = ({comment, fetchEntry, fetchComment}) => {
	const {t} = useTranslation();
	const [isParentEntry] = useState(comment.MetaObject__.startsWith('ccme'));
	const [loading, setLoading] = useState(false);
	const [parent, setParent] = useState(null);

	const handleLoadParent = () => {
		const method = isParentEntry ? fetchEntry : fetchComment;
		setLoading(true);
		method(comment.MetaObject__)
			.then(d => {
				setLoading(false);
				setParent(d);
			});
	};

	return (
		<Grid container spacing={3}>
			{parent === null &&
			<Grid item xs={12}>
				<Button size="small" variant="text" loading={loading} onClick={handleLoadParent}>
					{t('load_parent')}
				</Button>
			</Grid>
			}
			{parent !== null &&
			<>
				<Grid item xs={12}>
					<strong>{t('comment_answer_to')}</strong>
				</Grid>
				<Grid item xs={12}>
					{!isParentEntry && parent.Message}
					{isParentEntry && parent.Title}
				</Grid>
			</>
			}
		</Grid>
	);
};


const mapDispatchToProps = (dispatch) => {
	return {
		fetchEntry: (entryId) => dispatch(fetchEntry(entryId)),
		fetchComment: (commentId) => dispatch(fetchComment(commentId)),
	};
};

export default connect(null, mapDispatchToProps)(ParentComment);