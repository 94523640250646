import {combineReducers} from 'redux';

import FlowReducer         from './system/FlowReducer';
import UserReducer         from './system/UserReducer';
import CommonReducer       from './CommonReducer';
import MembershipReducer   from './fanclub/MembershipReducer';
import PlanReducer         from './fanclub/PlanReducer';
import OrderReducer        from './OrderReducer';
import BillingReducer      from './BillingReducer';
import CurrencyReducer     from './system/CurrencyReducer';
import ToastReducer        from './system/ToastReducer';
import RealmReducer        from './RealmReducer';
import ProductReducer      from './store/ProductReducer';
import TagReducer          from './TagReducer';
import CmsReducer          from './CmsReducer';
import LanguageReducer     from './system/LanguageReducer';
import SupportReducer      from './SupportReducer';
import LangReducer         from './system/LangReducer';
import StatsReducer        from './StatsReducer';
import OAuth2Reducer       from './system/OAuth2Reducer';
import CountryReducer      from './system/CountryReducer';
import MusicReducer        from './MusicReducer';
import ArtistReducer       from './ArtistReducer';
import RegistryReducer     from './RegistryReducer';
import RecordReducer       from './discography/RecordReducer';
import AlbumReducer        from './discography/AlbumReducer';
import ContactReducer      from './ContactReducer';
import ScheduleReducer     from './planner/ScheduleReducer';
import EventReducer        from './planner/EventReducer';
import DiscographyReducer  from './discography/DiscographyReducer';
import AccessReducer       from './system/AccessReducer';
import TemplateReducer     from './TemplateReducer';
import StoreReducer        from './store/StoreReducer';
import ShippingReducer     from './store/ShippingReducer';
import SeatReducer         from './planner/SeatReducer';
import QuestionReducer     from './planner/QuestionReducer';
import ReserveReducer      from './planner/ReserveReducer';
import DeliverableReducer  from './store/DeliverableReducer';
import MailReducer         from './mail/MailReducer';
import UserLocationReducer from './system/UserLocationReducer';
import LyricsReducer       from './discography/LyricsReducer';
import ChatReducer         from './planner/ChatReducer';
import StreamReducer       from './planner/StreamReducer';
import CouponReducer       from './store/CouponReducer';
import ReleaseReducer      from './discography/ReleaseReducer';
import SocialReducer       from './SocialReducer';
import MassMailReducer     from './MassMailReducer';
import AuthorReducer       from './AuthorReducer';
import ShippingFeeReducer  from './store/ShippingFeeReducer';

const rootReducer = combineReducers({
	common: CommonReducer,
	realm: RealmReducer,
	user: UserReducer,
	flow: FlowReducer,
	membership: MembershipReducer,
	plan: PlanReducer,
	order: OrderReducer,
	billing: BillingReducer,
	currency: CurrencyReducer,
	music: MusicReducer,
	toast: ToastReducer,
	product: ProductReducer,
	tag: TagReducer,
	cms: CmsReducer,
	language: LanguageReducer,
	country: CountryReducer,
	support: SupportReducer,
	lang: LangReducer,
	stats: StatsReducer,
	oauth2: OAuth2Reducer,
	artist: ArtistReducer,
	registry: RegistryReducer,
	record: RecordReducer,
	album: AlbumReducer,
	contact: ContactReducer,
	schedule: ScheduleReducer,
	event: EventReducer,
	discography: DiscographyReducer,
	access: AccessReducer,
	template: TemplateReducer,
	store: StoreReducer,
	shipping: ShippingReducer,
	seat: SeatReducer,
	question: QuestionReducer,
	reserve: ReserveReducer,
	deliverable: DeliverableReducer,
	mail: MailReducer,
	userLocation: UserLocationReducer,
	lyrics: LyricsReducer,
	chat: ChatReducer,
	stream: StreamReducer,
	coupon: CouponReducer,
	release: ReleaseReducer,
	social: SocialReducer,
	massMail: MassMailReducer,
	author: AuthorReducer,
	shippingFee: ShippingFeeReducer,
});

export default rootReducer;
