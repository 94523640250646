import React                   from 'react';
import DialogContent           from '@material-ui/core/DialogContent';
import DialogActions           from '@material-ui/core/DialogActions';
import Button                  from '../inputs/Button';
import Dialog                  from '@material-ui/core/Dialog';
import {Trans, useTranslation} from 'react-i18next';
import RichAlert               from '../feeback/RichAlert';
import DialogTitle             from '@material-ui/core/DialogTitle';
import {massMailSetReady}      from '../../../store/actions/MassMailAction';
import {connect}               from 'react-redux';

const MassMailReadyDialog = ({massMailId, open, setOpen, settingReady, setReady}) => {
	const {t} = useTranslation();

	const handleClose = () => {
		setOpen(false);
	};

	const handleReady = () => {
		setReady(massMailId).then(handleClose);
	};

	return (
		<Dialog
			open={open}
			fullWidth
		>
			<DialogTitle>{t('massmail_ready_title')}</DialogTitle>

			<DialogContent>
				<RichAlert severity="warning">
					<Trans i18nKey={'massmail_ready_warn'}>
						sample<br/>
						<strong>sample</strong>
					</Trans>
				</RichAlert>
			</DialogContent>

			<DialogActions>
				<Button color="secondary" autoFocus onClick={handleClose} disabled={settingReady}>
					{t('close_btn')}
				</Button>
				<Button loading={settingReady} color="primary" variant="contained" onClick={handleReady}
				        disabled={settingReady}
				>
					{t('set_mass_mail_ready')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};


const mapStateToProps = (state) => {
	return {
		settingReady: state.massMail.ready,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setReady: (massMailId) => dispatch(massMailSetReady(massMailId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MassMailReadyDialog);

