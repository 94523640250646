import React, {Fragment, useEffect} from 'react';
import TableHead from '@material-ui/core/TableHead';
import MaterialTable from '@material-ui/core/Table';
import TableRow                                               from '@material-ui/core/TableRow';
import TableCell                                              from '@material-ui/core/TableCell';
import TableBody                                              from '@material-ui/core/TableBody';
import TableContainer                                         from '@material-ui/core/TableContainer';
import Row                                                    from './Row';
import {connect}                                              from 'react-redux';
import TableFooter                                            from '@material-ui/core/TableFooter';
import {useTranslation}                                       from 'react-i18next';
import Empty                                                  from './Empty';
import Pagination                                             from '../../../../data/table/Pagination';
import {fetchComments, setCommentsFilters, setCommentsPaging} from '../../../../../../store/actions/SocialAction';

const Table = ({
	               realm,
	               comments,
	               fetch,
	               loading,
	               filters,
	               paging,
	               count,
	               setCommentsPaging,
	               setCommentsFilters,
	               disabled = false,
					showResponse = false,
               }) => {

	const loadingData = [...Array(paging.results_per_page).keys()];
	const {t} = useTranslation();
	const displayLoading = () => loadingData.map((k) => <Row key={k} loading={true} comment={null} showResponse={showResponse}/>);
	const displayComments = () => comments.map((u, idx) => <Row key={u.Social_Comment__} loading={false} comment={u} showResponse={showResponse}/>);

	useEffect(() => {
		if (realm && !disabled) {
			fetch();
		}

	}, [disabled, realm, fetch, setCommentsFilters, filters, paging]);


	/*
	const {sort} = filters;

	const sortHandler = (property, dir) => {
		var p = {...filters};
		// only 1 sort
		p.sort = {};
		p.sort[property] = dir;
		setCommentsFilters(p);
	};*/

	return (
		<Fragment>
			{(loading || comments.length > 0) && <TableContainer>
				<MaterialTable aria-label="simple table">
					<TableHead>
						<TableRow>
							<Pagination
								setPaging={setCommentsPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
						<TableRow>
							<TableCell>{t('tablecell_comment_from')}</TableCell>
							<TableCell>{t('tablecell_comment')}</TableCell>
							<TableCell>{t('likes_title')}</TableCell>
							<TableCell>{t('tablecell_created')}</TableCell>
							<TableCell align="right">
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(loading || disabled) && displayLoading()}
						{(!loading && !disabled) && displayComments()}
					</TableBody>
					<TableFooter>
						<TableRow>
							<Pagination
								setPaging={setCommentsPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
					</TableFooter>

				</MaterialTable>
			</TableContainer>}
			{(!loading && comments.length < 1) && <Empty/>}
		</Fragment>
	);

};


const mapStateToProps = (state) => {
	return {
		filters: state.social.commentsFilters,
		paging: state.social.commentsPaging,
		count: state.social.commentsPagingCount,
		loading: state.social.commentsLoading,
		comments: state.social.comments
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: () => dispatch(fetchComments()),
		setCommentsPaging: (newPaging) => dispatch(setCommentsPaging(newPaging)),
		setCommentsFilters: (newPaging) => dispatch(setCommentsFilters(newPaging))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);

