import React, {useEffect, useState}    from 'react';
import {connect}                       from 'react-redux';
import {resetEntriesPaging, selectCms} from '../../../store/actions/CmsAction';
import AppBarTitle                     from '../../../components/common/typography/AppBarTitle';
import Grid                            from '@material-ui/core/Grid';
import EntriesTable                    from '../../../components/common/cms/table/Table';
import AddIcon                         from '@material-ui/icons/Add';
import CreateDialog                    from '../../../components/common/cms/entry/CreateDialog';
import {useTranslation}                from 'react-i18next';
import SelectRealm                     from '../../SelectRealm';
import Empty                           from '../../../components/common/article/table/Empty';
import HasRight                        from '../../../components/Route/HasRight';
import Toolbar                         from '../../../components/common/typography/Toolbar';
import {getPXLDashboardRoute}          from '../../../router/routes/pxl/factory/general';
import HasCapability                   from '../../../components/Route/HasCapability';
import Redirect                        from '../../../components/Route/Redirect';
import FanclubActivator                from '../../../components/common/fanclub/FanclubActivator';
import Button                          from '../../../components/common/inputs/Button';
import Filters                         from '../../../components/common/cms/table/Filters';


const Articles = ({selectedRealm, fanclubCms, selectCms, resetEntriesPaging}) => {
	const [showTable, setShowTable] = useState(false);
	const [showCreate, setShowCreate] = useState(false);
	const [cms, setCms] = useState(null);
	const {t} = useTranslation();


	useEffect(() => {
		resetEntriesPaging();
	}, [resetEntriesPaging]);

	useEffect(() => {
		if (!fanclubCms) return;

		setCms(fanclubCms);
		setShowTable(true);

	}, [setShowTable, fanclubCms, setCms]);


	if (!selectedRealm) return <SelectRealm/>;

	return (
		<Grid container spacing={3}>
			<HasCapability fanclub deniedComponent={<Redirect target={getPXLDashboardRoute()}/>}>
				<AppBarTitle title={t('fanclub_articles_title')}/>

				<FanclubActivator/>

				<Grid item xs={12}>
					<Toolbar title={t('fanclub_articles_title')}>
						<HasRight manager hideLoading>
							<Button endIcon={<AddIcon/>} variant="contained" color="primary"
							        onClick={e => setShowCreate(true)}>
								{t('create_btn')}
							</Button>
						</HasRight>
					</Toolbar>
				</Grid>

				<Grid item xs={12}>
					<Filters disabled={!showTable} cms={cms}/>
				</Grid>

				<Grid item xs={12}>
					<EntriesTable disabled={!showTable} cmsId={cms && cms.Content_Cms__} noEntriesComponent={<Empty/>}/>
				</Grid>

				{fanclubCms &&
				<CreateDialog setOpen={setShowCreate} open={showCreate} cmsId={fanclubCms.Content_Cms__}/>}
			</HasCapability>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		fanclubCms: state.cms.fanclub_cms,
		selectedRealm: state.realm.selected,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		selectCms: (cms) => dispatch(selectCms(cms)),
		resetEntriesPaging: () => dispatch(resetEntriesPaging()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Articles);
