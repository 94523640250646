import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";
import {Title} from "../typography/Title";
import TextField from "@material-ui/core/TextField";
import {changePassword} from "../../../store/actions/system/UserActions";
import {connect} from "react-redux";
import RequestPasswordDialog from "./RequestPasswordDialog";
import Button from "../inputs/Button";

const Security = ({user, changePassword, changingInfo}) => {
    const {t} = useTranslation();
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordRepeat, setNewPasswordRepeat] = useState('');
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [requestPwdOpen, setRequestPwdOpen] = useState(false);

    const resetFields = () => {
        setNewPassword('');
        setNewPasswordRepeat('');
    };

    useEffect(() => {
        if (newPassword === '') {
            setSaveEnabled(false);
            return;
        }

        if (newPassword !== newPasswordRepeat) {
            setSaveEnabled(false);
            return;
        }

        setSaveEnabled(true);

    }, [newPassword, newPasswordRepeat]);

    const handleSave = (old) => {
        changePassword(old, newPassword)
            .then(resetFields)
            .catch(resetFields)
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Title>{t('profile_security')}</Title>
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    label={t('label_new_password')}
                    fullWidth
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    variant="outlined"
                    type='password'
                    disabled={changingInfo}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    label={t('label_new_password_repeat')}
                    fullWidth
                    value={newPasswordRepeat}
                    onChange={(e) => setNewPasswordRepeat(e.target.value)}
                    variant="outlined"
                    type='password'
                    error={newPasswordRepeat !== newPassword}
                    helperText={newPasswordRepeat !== newPassword ? t('confirm_password_not_match') :''}
                    disabled={changingInfo}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container justify='flex-end'>
                    <Button color='primary' variant='contained' loading={changingInfo}
                            disabled={!saveEnabled || changingInfo} onClick={() => {
                        if (user.Has_Password)
                            setRequestPwdOpen(true);
                        else
                            handleSave(null);
                    }}>
                        {t('save_btn')}
                    </Button>

                </Grid>
            </Grid>
            {user.Has_Password && <RequestPasswordDialog open={requestPwdOpen} setOpen={setRequestPwdOpen}
                                                         closeCallback={handleSave}/>}
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        changingInfo: state.user.changingPassword,
        user: state.user.user,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        changePassword: (oldPwd, newPwd) => dispatch(changePassword(oldPwd, newPwd))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Security);
