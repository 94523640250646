import {USER_LOGOUT} from "../system/UserReducer";
import {COMMON_RESET} from "../CommonReducer";

const initState = () => {
    return {
        catalog: null,
        loading: false,

        creating: false,

        salesReport: [], // The income histogram per day in the period
        productReport: [], // The quantity of each product sale in th eperdio
        itemReport: [], // The list of ordered items in the period
        reportPagingCount: 0,
        reportPaging: {
            page_no: 1,
            results_per_page: 10
        },
        reportLoading: false,

        updating: false,
    }
};

export const CATALOG_FETCH = 'CATALOG_FETCH';
export const CATALOG_FETCH_DONE = 'CATALOG_FETCH_DONE';

export const STORE_CATALOG_LOCATION_UPDATE = 'STORE_CATALOG_LOCATION_UPDATE';
export const STORE_CATALOG_LOCATION_UPDATE_DONE = 'STORE_CATALOG_LOCATION_UPDATE_DONE';

export const STORE_CREATE = 'STORE_CREATE';
export const STORE_CREATE_DONE = 'STORE_CREATE_DONE';

export const STORE_FETCH_REPORT = 'STORE_FETCH_REPORT';
export const STORE_FETCH_REPORT_DONE = 'STORE_FETCH_REPORT_DONE';
export const STORE_FETCH_REPORT_SET_PAGING = 'STORE_FETCH_REPORT_SET_PAGING';

const storeReducer = (state = initState(), action) => {
    switch (action.type) {
        case USER_LOGOUT:
        case COMMON_RESET:
            return initState();
        case STORE_CATALOG_LOCATION_UPDATE:
            return {...state, updating: true}
        case STORE_CATALOG_LOCATION_UPDATE_DONE:
            return {...state, updating: false}
        case  STORE_FETCH_REPORT:
            return {...state, reportLoading: true};
        case  STORE_FETCH_REPORT_SET_PAGING:
            return {...state, reportPaging: action.paging};
        case  STORE_FETCH_REPORT_DONE:
            return {
                ...state,
                reportLoading: false,
                salesReport: action.sales,
                productReport: action.product,
                itemReport: action.items,
                reportPagingCount: action.paging.count
            };
        case STORE_CREATE:
            return {...state, creating: true};
        case STORE_CREATE_DONE:
            return {...state, creating: false, catalog: action.catalog};
        case CATALOG_FETCH:
            return {...state, loading: true};
        case CATALOG_FETCH_DONE:
            return {...state, loading: false, catalog: action.catalog};
        default:
            return state;
    }

};

export default storeReducer
