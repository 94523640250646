import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "../../inputs/Button";
import {useTranslation} from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import {connect} from 'react-redux'
import {productSetSale} from "../../../../store/actions/store/ProductAction";
import RemoveFromSaleDialog from "./RemoveFromSaleDialog";
import moment from "moment";
import {getAttribute} from "../../../utils/product";

const PutOnSale = ({productId, attributes, setting, setSale, prices}) => {
    const {t} = useTranslation();
    const [onSale, setOnSale] = useState(false);
    const [missingPriceOrGood, setMissingPriceOrGood] = useState(false);
    const [showRemoveSaleDialog, setShowRemoveSaleDialog] = useState(false);

    useEffect(() => {

        let missingPriceOrGood = false;
        if (attributes.deliverables.length < 1) {
            missingPriceOrGood = true;
        }

        const now = moment().unix();
        let hasCurrent = false;
        for (let i = 0; i < prices.length; i++) {
            const price = prices[i];
            const oldPrice = price.End_Date && now > price.End_Date.unix;
            const futurePrice = price.Start_Date.unix > now;
            const current = !futurePrice && !oldPrice;
            if (current) {
                hasCurrent = true;
                break;
            }
        }

        if (!hasCurrent) {
            missingPriceOrGood = true;
        }

        const tmp = getAttribute(attributes, 'Basic.TaxProfile') ?? [];
        if (!(tmp.length > 0 ? tmp[0].Value : ''))
            missingPriceOrGood = true;

        setMissingPriceOrGood(missingPriceOrGood);

        const sale = attributes.sale.filter(s => s.Catalog_Product__ === productId);

        setOnSale(sale.length > 0 ? sale[0].Visible === 'Y' : false)

    }, [attributes, setOnSale, prices, setMissingPriceOrGood, productId]);

    const setOnSaleHandle = () => {
        setSale(productId, true)
    };

    return (
        <Grid container spacing={3} direction='column' justify='center' alignItems='center'>
            <Grid item xs={12}>
                {missingPriceOrGood &&
                <Alert severity='warning'>
                    {t('product_missing_price_goods')}
                </Alert>
                }
            </Grid>
            <Grid item>

                {!onSale &&
                <Button
                    disabled={missingPriceOrGood || setting}
                    variant='contained'
                    color='primary'
                    loading={setting}
                    endIcon={<AttachMoneyIcon/>}
                    onClick={setOnSaleHandle}
                >
                    {t('put_on_sale_btn')}
                </Button>
                }
                {onSale &&
                <Button
                    onClick={() => setShowRemoveSaleDialog(true)}
                    disabled={missingPriceOrGood}
                    variant='outlined'
                    color='secondary'
                    loading={setting}
                    endIcon={<MoneyOffIcon/>}
                >
                    {t('remove_from_sale_btn')}
                </Button>
                }
            </Grid>
            <RemoveFromSaleDialog productId={productId} open={showRemoveSaleDialog} setOpen={setShowRemoveSaleDialog}/>
        </Grid>
    );
};


const mapStateToProps = (state) => {
    return {
        setting: state.product.changingSaleState,
        prices: state.product.prices,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSale: (id, isOnSale) => dispatch(productSetSale(id, isOnSale)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PutOnSale);
