import React, {Fragment}            from 'react';
import {connect}                    from 'react-redux';
import DrawerItem                   from './DrawerItem';
import {
	CalendarToday,
	Dashboard,
	HeadsetMic,
	LibraryMusic,
	People,
	Storefront
}                                   from '@material-ui/icons';
import {useTranslation}             from 'react-i18next';
import {getPXLDashboardRoute}       from '../../../router/routes/pxl/factory/general';
import {
	MENU_ARTIST,
	MENU_FANCLUB,
	MENU_MUSIC,
	MENU_PLANNER,
	MENU_STORE,
	MENU_SUPPORT,
	MENU_WEBSITE
}                                   from '../../../store/reducers/CommonReducer';
import {
	getSiteAddressRoute,
	getSiteFinancialReportRoute,
	getSiteSettingsRoute
}                                   from '../../../router/routes/site/factory/setting';
import {getSiteOrdersRoute}         from '../../../router/routes/site/factory/order';
import {
	getSiteCommentsRoute,
	getSiteNewsRoute,
	getSiteNotificationListRoute,
	getSiteNotificationRoute
}                                   from '../../../router/routes/site/factory/content';
import {getSiteUsersRoute}          from '../../../router/routes/site/factory/user';
import WebIcon                      from '@material-ui/icons/Web';
import {getArtistRoute}             from '../../../router/routes/artist/factory/artist';
import {getArtistWizardRoute}       from '../../../router/routes/artist/factory/wizard';
import {getMembersRoute}            from '../../../router/routes/artist/factory/members';
import RecentActorsIcon             from '@material-ui/icons/RecentActors';
import {
	getSiteEventsRoute,
	getSiteSchedulesRoute
}                                   from '../../../router/routes/site/factory/planner';
import {getMusicDiscographyRoute}   from '../../../router/routes/music/factory/discography';
import {getMusicAlbumsRoute}        from '../../../router/routes/music/factory/album';
import {getMusicRecordsRoute}       from '../../../router/routes/music/factory/records';
import HasCapability                from '../../Route/HasCapability';
import {getFanclubArticlesRoute}    from '../../../router/routes/fanclub/factory/content';
import {getFanclubWizardRoute}      from '../../../router/routes/fanclub/factory/wizard';
import {getFanclubPlansRoute}       from '../../../router/routes/fanclub/factory/plan';
import {getFanclubSubscribersRoute} from '../../../router/routes/fanclub/factory/subscriber';
import {
	getStoreCouponsRoute,
	getStoreShipmentsRoute,
	getStoreShippingFeesRoute,
	getStoreShopRoute,
	getStoreWarehouseRoute,
	getStoreWizardRoute
}                               from '../../../router/routes/store/factory/store';
import {
	getSupportClosedRoute,
	getSupportInboxRoute,
	getSupportNewTicketRoute,
	getSupportOutboxRoute
}                               from '../../../router/routes/support/factory/boxes';
import {getSupportCannedsRoute} from '../../../router/routes/support/factory/canned';
import LanguageSwitcher         from '../../common/languageSwitcher/LanguageSwitcher';

const DrawerContent = ({user}) => {
	const {t} = useTranslation();
	if (!user) return <Fragment/>;

	return (
		<Fragment>
			<DrawerItem
				Icon={Dashboard}
				text={t('menu_dashboard')}
				to={getPXLDashboardRoute()}
				selectedRoutes={[
					{path: getPXLDashboardRoute(), exact: true},
				]}
			/>
			<DrawerItem
				Icon={WebIcon}
				text={t('menu_website')}
				menu={MENU_WEBSITE}
				selectedRoutes={[
					{path: getSiteSettingsRoute(), exact: true},
					{path: getSiteUsersRoute(), exact: false},
					{path: getSiteNewsRoute(), exact: true},
					{path: getSiteOrdersRoute(), exact: true},
					{path: getSiteCommentsRoute(), exact: false},
					{path: getSiteNotificationRoute(), exact: false},
					{path: getSiteNotificationListRoute(), exact: false},
					{path: getSiteFinancialReportRoute(), exact: false},
					{path: getSiteAddressRoute(), exact: false},
				]}
			/>

			<DrawerItem
				Icon={RecentActorsIcon}
				text={t('menu_artist')}
				menu={MENU_ARTIST}
				selectedRoutes={[
					{path: getArtistRoute(), exact: false},
					{path: getMembersRoute(), exact: false},
					{path: getArtistWizardRoute(), exact: false},
				]}
			/>

			<DrawerItem
				Icon={LibraryMusic}
				text={t('menu_music')}
				menu={MENU_MUSIC}
				selectedRoutes={[
					{path: getMusicDiscographyRoute(), exact: false},
					{path: getMusicAlbumsRoute(), exact: false},
					{path: getMusicRecordsRoute(), exact: false},
				]}
			/>

			<DrawerItem
				Icon={CalendarToday}
				text={t('menu_planner')}
				menu={MENU_PLANNER}
				selectedRoutes={[
					{path: getSiteSchedulesRoute(), exact: false},
					{path: getSiteEventsRoute(), exact: false},
				]}
			/>

			<HasCapability fanclub hideLoading>
				<DrawerItem
					Icon={People}
					text={t('menu_fanclub')}
					menu={MENU_FANCLUB}
					selectedRoutes={[
						{path: getFanclubArticlesRoute(), exact: false},
						{path: getFanclubWizardRoute(), exact: false},
						{path: getFanclubPlansRoute(), exact: false},
						{path: getFanclubSubscribersRoute(), exact: false},
					]}
				/>
			</HasCapability>

			<HasCapability store hideLoading>
				<DrawerItem
					Icon={Storefront}
					text={t('menu_store')}
					menu={MENU_STORE}
					selectedRoutes={[
						{path: getStoreWizardRoute(), exact: false},
						{path: getStoreShopRoute(), exact: false},
						{path: getStoreCouponsRoute(), exact: false},
						{path: getStoreWarehouseRoute(), exact: false},
						{path: getStoreShippingFeesRoute(), exact: false},
						{path: getStoreShipmentsRoute(), exact: false},
					]}
				/>
			</HasCapability>

			<DrawerItem
				Icon={HeadsetMic}
				text={t('menu_support')}
				menu={MENU_SUPPORT}
				selectedRoutes={[
					{path: getSupportInboxRoute(), exact: false},
					{path: getSupportOutboxRoute(), exact: false},
					{path: getSupportClosedRoute(), exact: false},
					{path: getSupportCannedsRoute(), exact: false},
					{path: getSupportNewTicketRoute(), exact: false},

				]}
			/>

			<LanguageSwitcher/>

		</Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		open: state.common.drawerOpened,
		user: state.user.user,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DrawerContent);
