import {useCallback, useEffect, useState} from 'react';
import {rest}                             from '@karpeleslab/klbfw';
import {error, success, warning}          from '../../store/actions/system/ToastAction';
import {getStore}                         from '../../store/store';
import {handleError}                      from '../../components/utils/apiErrorHandler';

const deepCopy = (object) => {
	return JSON.parse(JSON.stringify(object));
};

const snack = (token, severity) => {
	switch (severity) {
		case 'success':
			success(token);
			break;
		case 'error':
			error(token);
			break;
		case 'warning':
			warning(token);
			break;
		default:
			break;
	}
};

const onError = (e) => {
	const store = getStore();
	handleError(store.getState, store.dispatch, e);
};

const catchRedirect = result => {
	if (!result || !result.result || result.result !== 'redirect')
		return result;
	throw result;
};


export const useResource = (endpoint, params = null, restSettings = null) => {
	let settings = {...defaultSettings, ...(restSettings ? restSettings : {})};

	const [resource, setResource] = useState(null);
	const [loading, setLoading] = useState(false);

	const refresh = useCallback(
		(data, settingsOverride = null) => {
			const s = {...settings, ...(settingsOverride ? settingsOverride : {})};

			if (data) {
				setResource(prev => ({...(prev ? prev : {}), data: data}));
				return;
			}

			if (!s.silent) setLoading(true);
			return rest(endpoint, 'GET', params ? params : {})
				.then(d => s.catchRedirect ? catchRedirect(d) : d)
				.then(d => s.innerThen ? s.innerThen(d) : d)
				.then(r => {
					setResource(r);
					return r;
				})
				.then(res => {
					if (s.snackMessageToken)
						snack(s.snackMessageToken, s.snackMessageSeverity);
					return res;
				})
				.catch(e => {
					setResource({error: e});
					if (s.handleError) onError(e);
					else {
						throw e;
					}
				})
				.finally(() => {
					if (!s.silent)
						setLoading(false);
				});
		}
		, [setResource, endpoint, params]); //eslint-disable-line

	useEffect(() => {
		refresh();
	}, [endpoint, params]); //eslint-disable-line

	return [resource, refresh, loading];
};

export const useResourceList = (endpoint, restSettings = null) => {
	let settings = {...defaultSettings, ...(restSettings ? restSettings : {})};

	const [list, setList] = useState(null);
	const [loading, setLoading] = useState(false);
	const [lastFilter, setLastFilter] = useState({});
	const [lastPaging, setLastPaging] = useState({});

	const fetch = useCallback((filters = null, paging = null, settingsOverride = null) => {
		const s = {...settings, ...(settingsOverride ? settingsOverride : {})};

		if (!s.silent) setLoading(true);
		if (filters) setLastFilter(filters);
		if (paging) setLastPaging(paging);

		return rest(endpoint, 'GET', {
			...(filters ? filters : lastFilter),
			...(paging ? paging : lastPaging),
		})
			.then(d => s.catchRedirect ? catchRedirect(d) : d)
			.then(d => s.innerThen ? s.innerThen(d) : d)
			.then(res => {
				if (s.snackMessageToken)
					snack(s.snackMessageToken, s.snackMessageSeverity);
				return res;
			})
			.then(list => {
				setList(list);
				return list;
			})
			.catch(d => {
				if (s.handleError) onError(d);
				else {
					throw d;
				}
			})
			.finally(() => {
				if (!s.silent) setLoading(false);
			});
	}, [lastPaging, lastFilter, endpoint]); //eslint-disable-line

	const setItem = (idx, item) => {
		const cpy = deepCopy(list);
		cpy.data[idx] = item;
		setList(cpy);
	};

	return [list, fetch, loading, setItem];
};

const defaultSettings = {
	snackMessageToken: null,
	snackMessageSeverity: 'success',
	catchRedirect: true,
	handleError: true,
	rawResult: false,
	silent: false,
	innerThen: null,
};

export const useAction = (endpoint, method = 'POST', restSettings = null) => {
	let settings = {...defaultSettings, ...(restSettings ? restSettings : {})};

	const [loading, setLoading] = useState(false);

	const doAction = useCallback((params = {}, settingsOverride = null) => {
		const s = {...settings, ...(settingsOverride ? settingsOverride : {})};
		if (!s.silent) setLoading(true);
		return rest(endpoint, method, params)
			.then(d => s.catchRedirect ? catchRedirect(d) : d)
			.then(d => s.rawResult ? d : d.data)
			.then(d => s.innerThen ? s.innerThen(d) : d)
			.then(res => {
				if (s.snackMessageToken)
					snack(s.snackMessageToken, s.snackMessageSeverity);
				return res;
			})
			.catch(d => {
				if (s.handleError) onError(d);
				else {
					throw d;
				}
			})
			.finally(() => {
				if (!s.silent) setLoading(false);
			});
	}, [endpoint, method]);//eslint-disable-line

	return [doAction, loading];
};
