import {rest} from "@karpeleslab/klbfw";
import {handleError} from "../../../components/utils/apiErrorHandler";
import {
    MAIL_HISTORY_LIST_FETCH,
    MAIL_HISTORY_LIST_FETCH_DONE,
    MAIL_HISTORY_SET_FILTERS,
    MAIL_HISTORY_SET_PAGING
} from "../../reducers/mail/MailReducer";

export const setMailHistoryPaging = (newPaging) => {
    return (dispatch, getState) => {
        dispatch({type: MAIL_HISTORY_SET_PAGING, paging: {...getState().mail.historyPaging, ...newPaging}});
    }
};

export const setMailHistoryFilters = (newFilters) => {
    return (dispatch, getState) => {
        dispatch({type: MAIL_HISTORY_SET_FILTERS, filters: newFilters});
    }
};

export const fetchMailHistory = (realmId) => {
    return (dispatch, getState) => {
        dispatch({type: MAIL_HISTORY_LIST_FETCH});

        const params = {
            Realm__: realmId,
            ...getState().mail.historyFilters,
            ...getState().mail.historyPaging,
        };

        return rest('Mail/History', 'GET', params)
            .then(data => {
                dispatch({type: MAIL_HISTORY_LIST_FETCH_DONE, history: data.data, paging: data.paging});
                return data.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};