import React, {useCallback, useEffect, useState} from 'react';
import Grid                                      from '@material-ui/core/Grid';
import TextField                                 from '@material-ui/core/TextField';
import Country                                   from '../../inputs/Country';
import {useTranslation}                          from 'react-i18next';
import {phoneValidator}                          from '../../../utils/validator';
import {europeanVatNumberValidate}               from '../../../utils/misc';
import {connect}                                 from 'react-redux';
import {provincesLookup, zipLookup}              from '../../../../store/actions/system/CountryAction';

const FormData = ({address, setAddress, setIsValid, disabled = false, zipLookup, provincesLookup}) => {
	const {t} = useTranslation();
	const [provinces, setProvinces] = useState([]);

	const createInputChangeHandler = key => e => {
		let newForm = {...address, [key]: e.target.value};
		// Special case
		if (key === 'Company_European_Vat_Number' && !(newForm.Company_European_Vat_Number ?? '').trim())
			delete newForm.Company_European_Vat_Number;

		setAddress(newForm);

		if (key === 'Zip' && ('Country' in address && !!address['Country']))
			setTimeout(() => handleZipLookup(address['Country'].Country__, e.target.value), 1000);

	};

	const onZipLookedUp = useCallback((d) => {
		if (!d) return;
		const tmp = {...address};
		if (!tmp['Address'] && d.Address) {
			tmp['Address'] = d.Address;
		}

		if (!tmp['Address2'] && d.Address2) {
			tmp['Address2'] = d.Address2;
		}

		if (!tmp['Province'] && d.Province_Name) {
			if (provinces)
				tmp['Province'] = d.Province;
			else
				tmp['Province'] = d.Province_Name;
		}

		if (!tmp['City'] && d.City) {
			tmp['City'] = d.City;
		}


		setAddress(tmp);
		// eslint-disable-next-line
	}, [address, provinces]);

	const [zipLookupResult, setZipLookupResult] = useState({});
	const handleZipLookup = (country, zip) => {
		zipLookup(country, zip).then(setZipLookupResult);
	};

	useEffect(() => {
		if (!zipLookupResult) return;
		onZipLookedUp(zipLookupResult);
		// eslint-disable-next-line
	}, [zipLookupResult]);


	const isFilled = useCallback((k, trimmed = true) => !!(k in address && (trimmed ? (address[k] ?? '').trim() : (address[k] ?? ''))), [address]);

	useEffect(() => {
		const phoneValid = isFilled('Contact_Phone', false) && phoneValidator(address['Contact_Phone']);
		if (isFilled('Company_European_Vat_Number', false) && !europeanVatNumberValidate(address['Company_European_Vat_Number'] ?? '')) {
			setIsValid(false);
			return;
		}

		setIsValid(
			isFilled('First_Name') &&
			isFilled('Last_Name') &&
			isFilled('Zip') &&
			isFilled('Province') &&
			isFilled('City') &&
			'Country' in address && !!address['Country'] &&
			isFilled('Contact_Phone') &&
			phoneValid &&
			isFilled('Address'));
	}, [address, setIsValid, isFilled]);

	const [displayFormat, setDisplayFormat] = useState('Country' in address && !!address['Country'] ? address['Country'].Display_Format : null);


	useEffect(() => {
		setDisplayFormat('Country' in address && !!address['Country'] ? address['Country'].Display_Format : null);
	}, [setDisplayFormat, address]);

	const [once, setOnce] = useState(false);
	useEffect(() => {
		if (!('Country' in address && !!address['Country'])) return;
		if (once) return;
		setOnce(true);
		provincesLookup(address['Country'].Country__).then(p => setProvinces(p));
	}, [once, setOnce, provincesLookup, address]);


	const buildForm = () => {
		const knownFields = {
			First_Name: {helperText: undefined, required: true},
			Last_Name: {helperText: undefined, required: true},
			Company_Name: {helperText: t('address_company_info_helperText'), required: false},
			Company_Department: {helperText: t('address_company_info_helperText'), required: false},
			Address: {helperText: undefined, required: true, inputProps: {maxLength: 30}},
			Address2: {helperText: undefined, required: false},
			City: {helperText: undefined, required: true},
			Province: {helperText: undefined, required: true},
		};

		const form = [];
		displayFormat.forEach(row => {
			let rowSize = 0;
			const cleaned = [];
			
			row.forEach(el => {
				if (el in knownFields) {
					cleaned.push(el);
					rowSize++;
				}
			});

			cleaned.forEach(field => {
				let error = undefined;

				let label = field.toLowerCase();
				if (field === 'Address') label = 'address1';

				form.push(
					<Grid item xs={12} md={12 / rowSize} key={`address_form_${label}`}>
						<TextField
							label={t('address_' + label)}
							helperText={knownFields[field].helperText}
							variant="outlined"
							fullWidth
							inputProps={knownFields[field].inputProps ?? undefined}
							required={knownFields[field].required}
							error={error}
							disabled={disabled}
							value={address[field] ?? ''}
							select={field === 'Province' && provinces.length > 0}
							SelectProps={{
								native: true,
							}}
							onChange={createInputChangeHandler(field)}
						>
							{(field === 'Province' && provinces.length > 0) && <option/>}
							{field === 'Province' && provinces.map(p => <option key={'province_' + p.key}
							                                                    value={p.key}>{p.name}</option>)}
						</TextField>
					</Grid>
				);
			});
		});

		return form;
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={6}>
				<Country
					value={address['Country'] ?? null}
					required={true}
					disabled={disabled}
					onChange={e => {
						setAddress({...address, Country: e});
						if (e)
							provincesLookup(e.Country__).then(p => setProvinces(p));
					}}
					inputProps={{
						autoComplete: 'new-password',

					}}/>
			</Grid>

			<Grid item xs={12} md={6}>
				<TextField
					label={t('address_zip')}
					variant="outlined"
					fullWidth
					required
					disabled={disabled}
					value={address['Zip'] ?? ''}
					onChange={createInputChangeHandler('Zip')}
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<TextField
					label={t('address_contact_phone')}
					variant="outlined"
					fullWidth
					disabled={disabled}
					required
					error={isFilled('Contact_Phone', false) && !phoneValidator(address['Contact_Phone'])}
					value={address['Contact_Phone'] ?? ''}
					onChange={createInputChangeHandler('Contact_Phone')}
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<TextField
					label={t('address_company_european_vat_number')}
					helperText={t('address_company_european_vat_number_helperText')}
					variant="outlined"
					fullWidth
					disabled={disabled}
					error={isFilled('Company_European_Vat_Number', false) && !europeanVatNumberValidate(address['Company_European_Vat_Number'] ?? '')}
					value={address['Company_European_Vat_Number'] ?? ''}
					onChange={createInputChangeHandler('Company_European_Vat_Number')}
				/>
			</Grid>

			{displayFormat && <>
				{buildForm()}
			</>}

		</Grid>
	);
};


const mapDispatchToProps = (dispatch) => {
	return {
		zipLookup: (country, zip) => dispatch(zipLookup(country, zip)),
		provincesLookup: (country) => dispatch(provincesLookup(country))
	};
};

export default connect(null, mapDispatchToProps)(FormData);
