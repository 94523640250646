import React                            from 'react';
import Skeleton                         from '@material-ui/lab/Skeleton';
import GridListTile                     from '@material-ui/core/GridListTile';
import GridListTileBar                  from '@material-ui/core/GridListTileBar';
import moment                           from 'moment';
import IconButton                       from '@material-ui/core/IconButton';
import DeleteIcon                       from '@material-ui/icons/Delete';
import StarBorderIcon                   from '@material-ui/icons/StarBorder';
import StarIcon                         from '@material-ui/icons/Star';
import makeStyles                       from '@material-ui/core/styles/makeStyles';
import { ARTIST_GALLERY_IMG_VARIATION } from '../../../store/reducers/ArtistReducer';
import { connect }                      from 'react-redux';
import { useTranslation }               from 'react-i18next';
import { setArtistMainImage }           from '../../../store/actions/ArtistAction';

const useStyles = makeStyles(theme => ({
	icon: {
		color: 'rgba(255, 255, 255, 0.54)',
		'&:hover': {
			color: 'rgba(255, 255, 255, 0.8)',
		}
	},
	main: {
		color: 'yellow',
	}
}));

const GalleryImage = ({ artist, isManager, loading, img, onDelete, setArtistMainImage, ...rest }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const handleDelete = () => {
		onDelete(img);
	};

	const handleSetAsMainImage = () => {
		if (artist.Image_Drive_Item__ === img.Drive_Item__) return;
		setArtistMainImage(artist.Music_Label_Artist__, img.Drive_Item__);
	};

	if (loading || !img) {
		return (
			<GridListTile {...rest}>
				<Skeleton width={200} height={180}/>
			</GridListTile>
		);
	}
	return (
		<GridListTile  {...rest}>
			<img src={img.Media_Image[ARTIST_GALLERY_IMG_VARIATION]} alt={img.Filename}/>
			<GridListTileBar
				title={img.Filename}
				subtitle={moment(parseInt(img.Created.unixms)).format('LLL')}
				actionIcon={
					isManager ? <IconButton className={classes.icon} onClick={handleDelete}>
						<DeleteIcon/>
					</IconButton> : null
				}
			/>
			<GridListTileBar
				titlePosition='top'
				actionIcon={
					isManager ? <IconButton
						className={classes.icon}
						title={t('artist_set_logo')}
						onClick={handleSetAsMainImage}>
						{artist.Image_Drive_Item__ !== img.Drive_Item__ && <StarBorderIcon/>}
						{artist.Image_Drive_Item__ === img.Drive_Item__ && <StarIcon className={classes.main}/>}
					</IconButton> : null
				}
				actionPosition='right'
			/>
		</GridListTile>
	);
};

const mapStateToProps = (state) => {
	return {
		isManager: state.access.manager,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setArtistMainImage: (artistId, imageId) => dispatch(setArtistMainImage(artistId, imageId))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GalleryImage);
