import {rest}        from '@karpeleslab/klbfw';
import {handleError} from '../../../components/utils/apiErrorHandler';
import {
	SHIPPING_FEE_CREATE,
	SHIPPING_FEE_CREATE_DONE,
	SHIPPING_FEE_FETCH,
	SHIPPING_FEE_FETCH_DONE,
	SHIPPING_FEE_LIST_FETCH,
	SHIPPING_FEE_LIST_FETCH_DONE,
	SHIPPING_FEE_SCALE_CREATE,
	SHIPPING_FEE_SCALE_CREATE_DONE,
	SHIPPING_FEE_SCALE_DELETE,
	SHIPPING_FEE_SCALE_DELETE_DONE,
	SHIPPING_FEE_SCALE_LIST_FETCH,
	SHIPPING_FEE_SCALE_LIST_FETCH_DONE,
	SHIPPING_FEE_SCALE_SET_FILTERS,
	SHIPPING_FEE_SCALE_SET_PAGING,
	SHIPPING_FEE_SCALE_UPDATE,
	SHIPPING_FEE_SCALE_UPDATE_DONE,
	SHIPPING_FEE_SET_FILTERS,
	SHIPPING_FEE_SET_PAGING,
	SHIPPING_FEE_UPDATE,
	SHIPPING_FEE_UPDATE_DONE,
	SHIPPING_FEE_ZONE_CREATE,
	SHIPPING_FEE_ZONE_CREATE_DONE,
	SHIPPING_FEE_ZONE_DELETE,
	SHIPPING_FEE_ZONE_DELETE_DONE,
	SHIPPING_FEE_ZONE_LIST_FETCH,
	SHIPPING_FEE_ZONE_LIST_FETCH_DONE,
	SHIPPING_FEE_ZONE_SET_FILTERS,
	SHIPPING_FEE_ZONE_SET_PAGING,
	SHIPPING_FEE_ZONE_UPDATE,
	SHIPPING_FEE_ZONE_UPDATE_DONE,
	SHIPPING_FEE_ZONE_VALUE_CREATE,
	SHIPPING_FEE_ZONE_VALUE_CREATE_DONE,
	SHIPPING_FEE_ZONE_VALUE_DELETE,
	SHIPPING_FEE_ZONE_VALUE_DELETE_DONE,
	SHIPPING_FEE_ZONE_VALUE_LIST_FETCH,
	SHIPPING_FEE_ZONE_VALUE_LIST_FETCH_DONE,
	SHIPPING_FEE_ZONE_VALUE_SET_FILTERS,
	SHIPPING_FEE_ZONE_VALUE_SET_PAGING,
	SHIPPING_FEE_ZONE_VALUE_UPDATE,
	SHIPPING_FEE_ZONE_VALUE_UPDATE_DONE,
} from '../../reducers/store/ShippingFeeReducer';
import {success}     from '../system/ToastAction';

export const setShippingFeePaging = (newPaging) => {
	return (dispatch, getState) => {
		dispatch({type: SHIPPING_FEE_SET_PAGING, paging: {...getState().shippingFee.feesPaging, ...newPaging}});
	};
};

export const setShippingFeeFilters = (newFilters) => {
	return (dispatch, getState) => {
		dispatch({type: SHIPPING_FEE_SET_FILTERS, filters: newFilters});
	};
};

export const fetchShippingFees = () => {
	return (dispatch, getState) => {
		dispatch({type: SHIPPING_FEE_LIST_FETCH});

		const params = {
			...getState().shippingFee.feesFilters,
			...getState().shippingFee.feesPaging,
		};

		const id = getState().store.catalog.Catalog__;

		return rest('Catalog/' + id + '/Deliverable/Fee', 'GET', params)
			.then(data => {
				dispatch({type: SHIPPING_FEE_LIST_FETCH_DONE, fees: data.data, paging: data.paging});
				return data.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const createShippingFee = (params) => {
	return (dispatch, getState) => {
		const id = getState().store.catalog.Catalog__;

		dispatch({type: SHIPPING_FEE_CREATE});
		return rest('Catalog/' + id + '/Deliverable/Fee', 'POST', params)
			.then(data => {
				success('shipping_fee_create_success');
				dispatch({type: SHIPPING_FEE_CREATE_DONE});
				return data.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const updateShippingFee = (feeId, params) => {
	return (dispatch, getState) => {

		dispatch({type: SHIPPING_FEE_UPDATE});
		return rest('Catalog/Deliverable/Fee/' + feeId, 'PATCH', params)
			.then(data => {
				success('shipping_fee_update_success');
				dispatch({type: SHIPPING_FEE_UPDATE_DONE, fee: data.data});
				return data.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const fetchShippingFee = (feeId) => {
	return (dispatch, getState) => {

		dispatch({type: SHIPPING_FEE_FETCH});
		return rest('Catalog/Deliverable/Fee/' + feeId, 'GET')
			.then(data => {
				dispatch({type: SHIPPING_FEE_FETCH_DONE, fee: data.data});
				return data.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};


export const setShippingFeeZonePaging = (newPaging) => {
	return (dispatch, getState) => {
		dispatch({type: SHIPPING_FEE_ZONE_SET_PAGING, paging: {...getState().shippingFee.zonesPaging, ...newPaging}});
	};
};

export const setShippingFeeZoneFilters = (newFilters) => {
	return (dispatch, getState) => {
		dispatch({type: SHIPPING_FEE_ZONE_SET_FILTERS, filters: newFilters});
	};
};

export const fetchShippingFeeZones = (feeId) => {
	return (dispatch, getState) => {
		dispatch({type: SHIPPING_FEE_ZONE_LIST_FETCH});

		const params = {
			...getState().shippingFee.zonesFilters,
			...getState().shippingFee.zonesPaging,
		};

		return rest(`Catalog/Deliverable/Fee/${feeId}/Zone`, 'GET', params)
			.then(data => {
				dispatch({type: SHIPPING_FEE_ZONE_LIST_FETCH_DONE, zones: data.data, paging: data.paging});
				return data.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const createShippingFeeZone = (feeId, params) => {
	return (dispatch, getState) => {

		dispatch({type: SHIPPING_FEE_ZONE_CREATE});
		return rest('Catalog/Deliverable/Fee/' + feeId + '/Zone', 'POST', params)
			.then(data => {
				success('shipping_fee_zone_create_success');
				dispatch({type: SHIPPING_FEE_ZONE_CREATE_DONE});
				return data.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const updateShippingFeeZone = (zoneId, params) => {
	return (dispatch, getState) => {

		dispatch({type: SHIPPING_FEE_ZONE_UPDATE});
		return rest('Catalog/Deliverable/Fee/Zone/' + zoneId, 'PATCH', params)
			.then(data => {
				success('shipping_fee_zone_update_success');
				dispatch({type: SHIPPING_FEE_ZONE_UPDATE_DONE, zone: data.data});
				return data.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const deleteShippingFeeZone = (zoneId) => {
	return (dispatch, getState) => {

		dispatch({type: SHIPPING_FEE_ZONE_DELETE});
		return rest('Catalog/Deliverable/Fee/Zone/' + zoneId, 'DELETE')
			.then(data => {
				success('shipping_fee_zone_delete_success');
				dispatch({type: SHIPPING_FEE_ZONE_DELETE_DONE, zoneId: zoneId});
				return data.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};



export const setShippingFeeScalePaging = (newPaging) => {
	return (dispatch, getState) => {
		dispatch({type: SHIPPING_FEE_SCALE_SET_PAGING, paging: {...getState().shippingFee.scalesPaging, ...newPaging}});
	};
};

export const setShippingFeeScaleFilters = (newFilters) => {
	return (dispatch, getState) => {
		dispatch({type: SHIPPING_FEE_SCALE_SET_FILTERS, filters: newFilters});
	};
};

export const fetchShippingFeeScales = (feeId) => {
	return (dispatch, getState) => {
		dispatch({type: SHIPPING_FEE_SCALE_LIST_FETCH});

		const params = {
			...getState().shippingFee.scalesFilters,
			...getState().shippingFee.scalesPaging,
		};

		return rest(`Catalog/Deliverable/Fee/${feeId}/Scale`, 'GET', params)
			.then(data => {
				dispatch({type: SHIPPING_FEE_SCALE_LIST_FETCH_DONE, scales: data.data, paging: data.paging});
				return data.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const createShippingFeeScale = (feeId, zoneId, params) => {
	return (dispatch, getState) => {

		params = (params ?? {})
		params['Catalog_Deliverable_Fee_Zone__'] = zoneId;
		dispatch({type: SHIPPING_FEE_SCALE_CREATE});
		return rest('Catalog/Deliverable/Fee/' + feeId + '/Scale', 'POST', params)
			.then(data => {
				success('shipping_fee_scale_create_success');
				dispatch({type: SHIPPING_FEE_SCALE_CREATE_DONE});
				return data.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const updateShippingFeeScale = (scaleId, params) => {
	return (dispatch, getState) => {

		dispatch({type: SHIPPING_FEE_SCALE_UPDATE});
		return rest('Catalog/Deliverable/Fee/Scale/' + scaleId, 'PATCH', params)
			.then(data => {
				success('shipping_fee_scale_update_success');
				dispatch({type: SHIPPING_FEE_SCALE_UPDATE_DONE, scale: data.data});
				return data.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const deleteShippingFeeScale = (scaleId) => {
	return (dispatch, getState) => {

		dispatch({type: SHIPPING_FEE_SCALE_DELETE});
		return rest('Catalog/Deliverable/Fee/Scale/' + scaleId, 'DELETE')
			.then(data => {
				success('shipping_fee_scale_delete_success');
				dispatch({type: SHIPPING_FEE_SCALE_DELETE_DONE, scaleId: scaleId});
				return data.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};


export const createShippingFeeZoneValue = (zoneId, params) => {
	return (dispatch, getState) => {

		dispatch({type: SHIPPING_FEE_ZONE_VALUE_CREATE});
		return rest('Catalog/Deliverable/Fee/Zone/'+ zoneId+ '/Value', 'POST', params)
			.then(data => {
				success('shipping_fee_zone_value_create_success');
				dispatch({type: SHIPPING_FEE_ZONE_VALUE_CREATE_DONE, value:data.data});
				return data.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const updateShippingFeeZoneValue = (valueId, params) => {
	return (dispatch, getState) => {

		dispatch({type: SHIPPING_FEE_ZONE_VALUE_UPDATE});
		return rest('Catalog/Deliverable/Fee/Zone/Value/' + valueId, 'PATCH', params)
			.then(data => {
				success('shipping_fee_zone_update_success');
				dispatch({type: SHIPPING_FEE_ZONE_VALUE_UPDATE_DONE, value: data.data});
				return data.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const deleteShippingFeeZoneValue = (valueId) => {
	return (dispatch, getState) => {

		dispatch({type: SHIPPING_FEE_ZONE_VALUE_DELETE});
		return rest('Catalog/Deliverable/Fee/Zone/Value/' + valueId, 'DELETE')
			.then(data => {
				success('shipping_fee_zone_value_delete_success');
				dispatch({type: SHIPPING_FEE_ZONE_VALUE_DELETE_DONE, valueId: valueId});
				return data.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const setShippingFeeZoneValuePaging = (newPaging) => {
	return (dispatch, getState) => {
		dispatch({type: SHIPPING_FEE_ZONE_VALUE_SET_PAGING, paging: {...getState().shippingFee.zoneValuesPaging, ...newPaging}});
	};
};

export const setShippingFeeZoneValueFilters = (newFilters) => {
	return (dispatch, getState) => {
		dispatch({type: SHIPPING_FEE_ZONE_VALUE_SET_FILTERS, filters: newFilters});
	};
};

export const fetchShippingFeeZoneValues = (zoneId) => {
	return (dispatch, getState) => {
		dispatch({type: SHIPPING_FEE_ZONE_VALUE_LIST_FETCH});

		const params = {
			...getState().shippingFee.zoneValuesFilters,
			...getState().shippingFee.zoneValuesPaging,
		};

		return rest(`Catalog/Deliverable/Fee/Zone/${zoneId}/Value`, 'GET', params)
			.then(data => {
				dispatch({type: SHIPPING_FEE_ZONE_VALUE_LIST_FETCH_DONE, values: data.data, paging: data.paging});
				return data.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};