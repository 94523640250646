import React, {useEffect}                                     from 'react';
import {useTranslation}                                       from 'react-i18next';
import {useHistory}                                           from 'react-router-dom';
import HasCapability                                          from '../../../Route/HasCapability';
import {CircularProgress, Grid, List, ListItem, ListItemIcon} from '@material-ui/core';
import {connect}                                              from 'react-redux';
import ListItemNavLink                                        from '../ListItemNavLink';
import {
	getStoreCouponsRoute,
	getStoreShipmentsRoute,
	getStoreShippingFeesRoute,
	getStoreShopRoute,
	getStoreWarehouseRoute,
	getStoreWizardRoute
}                                     from '../../../../router/routes/store/factory/store';
import StoreIcon                      from '@material-ui/icons/Store';
import HasRight                       from '../../../Route/HasRight';
import LocalOfferIcon                 from '@material-ui/icons/LocalOffer';
import Badge                          from '@material-ui/core/Badge';
import {FaWarehouse}                  from 'react-icons/fa';
import LocalShippingIcon              from '@material-ui/icons/LocalShipping';
import MonetizationOnIcon             from '@material-ui/icons/MonetizationOn';
import Button                         from '../../../common/inputs/Button';
import {refreshShippingsPendingCount} from '../../../../store/actions/store/ShippingAction';
import {deliverableStats}             from '../../../../store/actions/store/DeliverableAction';
import {hideMenu}                     from '../../../../store/actions/CommonAction';
import graphic                        from '../../../../resources/images/store-wizard/intro.svg';

const MenuStore = ({
	                   catalog,
	                   loading,
	                   ready,
	                   realm,
	                   openDrawer,
	                   isOwner,
	                   refreshShippingsPendingCount,
	                   shippingCount,
	                   creatingArtist,
	                   deliverableStats,
	                   outOfStock,
	                   isSupport,
	                   hideMenu
                   }) => {
	const disabled = loading || catalog === null || !ready;
	const {t} = useTranslation();
	const history = useHistory();
	const displayCreate = ready && !catalog && realm;

	// For the first time
	useEffect(() => {
		if (!catalog || creatingArtist || !isSupport || !ready) return;
		deliverableStats(catalog.Catalog__).catch(() => {
		});
		refreshShippingsPendingCount().catch(() => {
		});
		// eslint-disable-next-line
	}, [refreshShippingsPendingCount, deliverableStats, ready]);

	useEffect(() => {
		if (!isSupport) return;
		const refreshInterval = setInterval(() => {
			if (!catalog || creatingArtist) return;
			deliverableStats(catalog.Catalog__).catch(() => {
			});
			refreshShippingsPendingCount().catch(() => {
			});
		}, 30000);

		return () => {
			clearInterval(refreshInterval);
		};
	}, [catalog, refreshShippingsPendingCount, creatingArtist, isSupport, deliverableStats]);

	if (displayCreate && !isOwner) {
		return <p>{t('store_ask_owner')}</p>;
	}

	return (
		<HasCapability store hideLoading>
			<List>
				<div>

					{loading && <ListItem>
						<ListItemIcon>
							<CircularProgress size={15}/>
						</ListItemIcon>
					</ListItem>
					}


					{!displayCreate && <>

						<ListItemNavLink
							tooltip={t('menu_store_shop')}
							disabled={disabled}
							path={getStoreShopRoute()}
							exact={true}
							icon={<StoreIcon/>}
							primary={t('menu_store_shop')}
						/>

						<HasRight admin>
							<ListItemNavLink
								tooltip={t('menu_store_coupon')}
								disabled={disabled}
								path={getStoreCouponsRoute()}
								icon={<LocalOfferIcon size={24}/>}
								primary={t('menu_store_coupon')}
							/>
						</HasRight>

						<ListItemNavLink
							tooltip={t('menu_store_warehouse')}
							disabled={disabled}
							path={getStoreWarehouseRoute()}
							icon={
								<Badge color="secondary" badgeContent={parseInt(outOfStock)}>
									<FaWarehouse size={24}/>
								</Badge>}
							primary={t('menu_store_warehouse')}
						/>

						<ListItemNavLink
							tooltip={t('menu_store_shipping')}
							disabled={disabled}
							path={getStoreShipmentsRoute()}
							icon={
								<Badge color="primary" badgeContent={parseInt(shippingCount)}>
									<LocalShippingIcon size={24}/>
								</Badge>}
							primary={t('menu_store_shipping')}
						/>

						<HasRight admin>
							<ListItemNavLink
								tooltip={t('menu_store_shipping_fees')}
								disabled={disabled}
								path={getStoreShippingFeesRoute()}
								icon={<MonetizationOnIcon/>}
								primary={t('menu_store_shipping_fees')}
							/>
						</HasRight>

						{/*<HasRight owner>
							 <ListItemNavLink
                                tooltip={t('menu_store_bank')}
                                disabled={disabled}
                                path={getStoreBankRoute()}
                                icon={<AccountBalanceIcon/>}
                                primary={t('menu_store_bank')}
                            />
						</HasRight>*/}

					</>
					}
					{displayCreate &&
					<ListItem onClick={() => {
						hideMenu();
						history.push(getStoreWizardRoute());
					}}>
						<Grid container spacing={2} justify={'center'}>
							<Grid item style={{textAlign: 'center'}}>
								<img src={graphic} alt='store' width="100px"/>
							</Grid>
							<Grid item xs={12}/>
							<Grid item>
								<Button color="primary" variant="outlined">
									{t('menu_create_store')}
								</Button>
							</Grid>
						</Grid>
					</ListItem>
					}
				</div>
			</List>
		</HasCapability>
	);
};

const mapStateToProps = (state) => {
	return {
		ready: state.common.ready,
		catalog: state.store.catalog,
		loading: state.store.loading,

		shippingCount: state.shipping.shippingsPendingCount,
		outOfStock: state.deliverable.outOfStock,

		realm: state.realm.selected,

		isOwner: state.access.owner,
		isSupport: state.access.support,
		creatingArtist: state.music.creating, // Use to not to fetch shipments info when creating a new artist (cause it can lead to access denied error)
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		refreshShippingsPendingCount: () => dispatch(refreshShippingsPendingCount(true)),
		deliverableStats: (catalogId) => dispatch(deliverableStats(catalogId, true)),
		hideMenu: () => dispatch(hideMenu()),
	};
};


export default connect(mapStateToProps, mapDispatchToProps)(MenuStore);

