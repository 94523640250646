import React, { useState }         from 'react';
import { connect }                 from 'react-redux';
import Grid                        from '@material-ui/core/Grid';
import Form                        from './Form';
import { createPlan }              from '../../../../../store/actions/fanclub/PlanAction';
import { useHistory }              from 'react-router-dom';
import { getFanclubPlanEditRoute } from '../../../../../router/routes/fanclub/factory/plan';
import { getCurrency }             from '@karpeleslab/klbfw';
import { useTranslation }          from 'react-i18next';
import Button                      from '../../../inputs/Button';

const Create = ({ creating, createPlan, hasFreePlan}) => {
	const { t } = useTranslation();

	const history = useHistory();
	const [isValid, setIsValid] = useState(false);
	const [plan, setPlan] = useState({
		period: '1m',
		free: 'N',
	});
	const onAdd = () => {
		const formatedData = {
			'Basic.Name': plan.name,
			'Description.Short': plan.description,
			Visible: plan.visible,
			Tag: plan.tag,
			Price: parseFloat(plan.price),
			Priority: plan.priority ?? 0,
			Currency__: getCurrency(),
			vatIncluded: plan.vatIncluded ?? false,
			Period:plan.period ?? '1m',
			Free: hasFreePlan ? 'N' : (plan.free ?? 'N'),
		};

		if(plan.color) formatedData.Color = plan.color;

		createPlan(formatedData)
			.then(p => {
				history.push(getFanclubPlanEditRoute(p.Membership_Plan__));
			});
	};
	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Form disabled={creating} plan={plan} setPlan={setPlan} setIsValid={setIsValid} allowFree={!hasFreePlan}/>
			</Grid>
			<Grid item xs={12}>
				<Grid container justify='flex-end'>
					<Button color='primary' variant='contained' loading={creating} onClick={onAdd}
					        disabled={!isValid || creating}>
						{t('create_btn')}
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		creating: state.plan.creatingPlan,
		hasFreePlan: state.plan.hasFreePlan,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		createPlan: (data) => dispatch(createPlan(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
