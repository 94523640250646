import React from 'react';
import {Title} from "./Title";
import {Toolbar as MUToolbar} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";


const Toolbar = ({title, children, disableGutters = false, startAdornment=null}) => {
    return (
        <MUToolbar disableGutters={disableGutters}>
            <Grid container justify='space-between'>
                <Grid item>
                    <Grid container >
                        {startAdornment && <Grid item>{startAdornment}</Grid>}
                        <Grid item>
                            <Title>{title}</Title>
                        </Grid>
                    </Grid>
                </Grid>
                {children && <Grid item>{children}</Grid>}
            </Grid>
        </MUToolbar>
    );
};

export default Toolbar;