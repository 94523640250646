import React, { useEffect, useState }                    from 'react';
import { Grid }                                          from '@material-ui/core';
import { useTranslation }                                from 'react-i18next';
import TextField                                         from '@material-ui/core/TextField';
import ProductInput                                      from '../../common/inputs/ProductInput';
import ExpandMoreIcon                                    from '@material-ui/icons/ExpandMore';
import Typography                                        from '@material-ui/core/Typography';
import { Accordion, AccordionDetails, AccordionSummary } from './Accordion';
import Button                                            from '../../common/inputs/Button';
import { getAttribute }                                  from '../../utils/product';
import { isObject }                                      from '../../utils/misc';
import FormRequiredField                                 from '../../common/feeback/FormRequiredField';

const Basic = ({ handleNext, attributes, setAttributes }) => {
	const { t } = useTranslation();

	const set = key => e => {
		const keys = key.split('.');
		const family = attributes.values[keys[0]];
		if (Array.isArray(e)) {
			family[keys[1]] = e;
		} else {
			if (family[keys[1]].length < 1) family[keys[1]].push({
				Language__: null,
				Realm__: null,
				Value: '',
			});
			family[keys[1]][0].Value = isObject(e) ? e.target.value : e;
		}

		setAttributes({ ...attributes });
	};

	const [nextEnabled, setNextEnabled] = useState(!!(getAttribute(attributes, 'Native.Internal_Name')[0].Value.trim()));


	useEffect(() => {
		setNextEnabled(!!(getAttribute(attributes, 'Native.Internal_Name')[0].Value.trim()));
		// eslint-disable-next-line
	}, [setNextEnabled, attributes]);

	return (
		<Grid container spacing={3}>
			<FormRequiredField/>
			<Grid item xs={12} md={6}>
				<TextField
					label={t('product_internal_name')}
					fullWidth variant='outlined'
					value={getAttribute(attributes, 'Native.Internal_Name')[0].Value}
					error={!(getAttribute(attributes, 'Native.Internal_Name')[0].Value.trim())}
					required
					helperText={t('product_internal_name_helperText')}
					onChange={set('Native.Internal_Name')}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<ProductInput
					allowDelete={false}
					fullWidth
					value={getAttribute(attributes, 'Basic.Name')}
					setValue={set('Basic.Name')}
					label={t('product_basic_name')}
					helperText={t('product_basic_name_helperText')}
					variant='outlined'
				/>
			</Grid>

			<Grid item xs={12} md={12}>
				<ProductInput
					allowDelete={false}
					fullWidth
					value={getAttribute(attributes, 'Description.CatchPhrase')}
					setValue={set('Description.CatchPhrase')}
					label={t('product_description_catch_phrase')}
					helperText={t('product_description_catch_phrase_helperText')}
					variant='outlined'
				/>
			</Grid>

			<Grid item xs={12} md={12}>
				<ProductInput
					allowDelete={false}
					fullWidth
					value={getAttribute(attributes, 'Description.Short')}
					setValue={set('Description.Short')}
					multiline rows={5}
					rowsMax={Infinity}
					label={t('product_description_short')}
					helperText={t('product_description_short_helperText')}
					variant='outlined'
				/>
			</Grid>

			<Grid item xs={12} md={12}>
				<ProductInput
					allowDelete={false}
					fullWidth
					value={getAttribute(attributes, 'Basic.Keywords')}
					setValue={set('Basic.Keywords')}
					label={t('product_basic_keywords')}
					helperText={t('product_basic_keywords_helperText')}
					variant='outlined'
				/>
			</Grid>
			<Grid item xs={12}>
				<Accordion disableGutters elevation={0} style={{ padding: 0 }}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon/>}
					>
						<Typography>{t('wizard_product_advanced')}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Grid container spacing={3}>
							<Grid item xs={12} md={6}>
								<ProductInput
									allowDelete={false}
									fullWidth
									value={getAttribute(attributes, 'Description.Author')}
									setValue={set('Description.Author')}
									label={t('product_description_author')}
									helperText={t('product_description_author_helperText')}
									variant='outlined'/>
							</Grid>

							<Grid item xs={12} md={6}>
								<ProductInput
									allowDelete={false}
									fullWidth
									type='date'
									value={getAttribute(attributes, 'Basic.Date_Release')}
									setValue={set('Basic.Date_Release')}
									label={t('product_basic_date_release')}
									helperText={t('product_basic_date_release_helperText')}
									variant='outlined'
								/>
							</Grid>

							<Grid item xs={12} md={6}>
								<ProductInput
									allowDelete={false}
									fullWidth
									type='number'
									inputProps={{
										min: 0
									}}
									value={getAttribute(attributes, 'Description.Pieces')}
									setValue={set('Description.Pieces')}
									label={t('product_description_pieces')}
									helperText={t('product_description_pieces_helperText')}
									variant='outlined'
								/>
							</Grid>

							<Grid item xs={12} md={6}>
								<ProductInput
									allowDelete={false}
									fullWidth
									type='number'
									inputProps={{
										min: 0
									}}
									value={getAttribute(attributes, 'Basic.Priority')}
									setValue={set('Basic.Priority')}
									label={t('product_basic_priority')}
									helperText={t('product_basic_priority_helperText')}
									variant='outlined'
								/>
							</Grid>

							<Grid item xs={12}>
								<TextField
									label={t('product_author_code')}
									fullWidth variant='outlined'

									value={getAttribute(attributes, 'Description.AuthorCode')[0].Value}
									helperText={getAttribute(attributes, 'Description.AuthorCode')[0].Value.hasKanji() ? t('product_author_code_error') : t('product_author_code_helperText')}
									onChange={e => set('Description.AuthorCode')(e.target.value.toLowerCase().replaceSpecialChars())}
									error={getAttribute(attributes, 'Description.AuthorCode')[0].Value.hasKanji()}
								/>
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>
			</Grid>

			<Grid item xs={12}>
				<Grid container justify='flex-end'>
					<Grid item>
						<Button
							onClick={handleNext}
							color='primary'
							variant='contained'
							disabled={!nextEnabled}
						>
							{t('next_btn')}
						</Button>
					</Grid>
				</Grid>
			</Grid>

		</Grid>
	);
};

export default Basic;
