import React, {useEffect, useState} from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from "@stripe/react-stripe-js";
import Loading from '../../feeback/loading/Loading';
import {rest} from '@karpeleslab/klbfw';
import StripeIntent from "./StripeIntent";

let stripePromise = null;
const initStripe = (token, account_id = undefined) => {

	if (!stripePromise) {
		const options = account_id ? {stripeAccount: account_id} : undefined;
		stripePromise = loadStripe(token, options);
	}
	return stripePromise;
};

const Stripe = ({setStripe, setStripeElements, onChange, disabled = false, settings = null, setIntent = null}) => {
	const [stripePromise, setStripePromise] = useState(null);
	const [paymentInfo, setPaymentInfo] = useState(settings);
	const [clientSecret, setClientSecret] = useState("");

	useEffect(() => {
		if (settings) return;

		Promise.all([
		rest('Realm/PaymentMethod:setup', 'POST', {method: 'stripe'}),
		rest('Order/Payment:methodInfo', 'GET', {method: 'stripe'})
		]).then(d => {
			const intentResult = d[0].data;
			const infoResult = d[1].data;
			if(setIntent) {
				setIntent(intentResult.Setup)
			}
			setPaymentInfo({
				stripe_intent:{attributes:{client_secret:intentResult.Setup.client_secret}},
				cc_token:infoResult.Fields.cc_token
			})
		})
	}, [setPaymentInfo, settings, setIntent]);

	useEffect(() => {
		if (!paymentInfo) return;
		setClientSecret(paymentInfo.stripe_intent.attributes.client_secret)
		setStripePromise(initStripe(paymentInfo.cc_token.attributes.key, paymentInfo.cc_token.attributes.options.stripe_account));
	}, [paymentInfo, setStripePromise]);

	if (!stripePromise || !clientSecret) return <Loading/>;

	const appearance = {
		theme: 'stripe',
	};

	const options = {
		clientSecret,
		appearance,
	};

	return (
		<Elements stripe={stripePromise} options={options}>
			<StripeIntent setStripe={setStripe} setStripeElements={setStripeElements} onChange={onChange}/>
		</Elements>
	);
};

export default Stripe;