import React, {useState} from 'react';
import {connect}            from 'react-redux'
import ChipMultipleSelect   from "../../../inputs/ChipMultipleSelect";
import Grid                 from "@material-ui/core/Grid";
import DeleteIcon           from '@material-ui/icons/Delete';
import SearchIcon           from '@material-ui/icons/Search';
import {useTranslation}     from 'react-i18next';
import TextField            from "@material-ui/core/TextField";
import {setReservesFilters} from "../../../../../store/actions/planner/ReserveAction";
import Chip                 from "@material-ui/core/Chip";
import makeStyles           from "@material-ui/core/styles/makeStyles";
import Button               from "../../../inputs/Button";
import ReserveUsedSelector  from '../../../inputs/ReserveUsedSelector';

const useStyles = makeStyles(theme => ({
    big: {
        height: '100%',
        '& > .MuiInputBase-root': {
            height: '100%',
        }
    }
}));

const Filters = ({setReservesFilters, reservesLoading, reservesFilters}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    let initialStatusFilters = [];
    let initialSearchFilters = '';
    let initialUsedFilters = '';

    if (reservesFilters.Status && '$in' in reservesFilters.Status)
        initialStatusFilters = reservesFilters.Status['$in'];

    if (reservesFilters.Name && '$prefix' in reservesFilters.Name)
        initialSearchFilters = reservesFilters.Name['$prefix'];

    if (reservesFilters.Used)
        initialUsedFilters = reservesFilters.Used;

    const [statusValues, setStatusValues] = useState(initialStatusFilters);
    const [search, setSearch] = useState(initialSearchFilters);
    const [used, setUsed] = useState(initialUsedFilters ?? 'all');


    const statusFilters = {
        reserve_free: <Chip variant='outlined' label={t('reserve_status_reserve_free')}/>,
        reserve_paid: <Chip variant='outlined' label={t('reserve_status_reserve_paid')}/>,
        cancel: <Chip variant='outlined' label={t('reserve_status_cancel')}/>,
    };

    const filterHandler = () => {
        let params = {
            ...reservesFilters
        };

        delete params.Status;
        delete params.Name;
        delete params.Used;

        if (statusValues.length > 0) params.Status = {'$in': statusValues};
        if (search) params.Name = {'$prefix': search};
        if (used !== 'all') params.Used = used;

        setReservesFilters(params);
    };

    const clearFiltersHandler = () => {
        let params = {
            ...reservesFilters
        };

        delete params.Name;
        delete params.Status;
        delete params.Used;

        setStatusValues([]);
        setSearch("");
        setUsed('all');

        setReservesFilters(params);
    };

    const handleSearchChange = e => {
        if (e.key !== 'Enter')
            return;

        filterHandler()
    };

    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={3}
        >
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField
                        className={classes.big}  // Hell this is dirty
                        fullWidth
                        variant="outlined"
                        value={search}
                        disabled={reservesLoading}
                        onKeyPress={handleSearchChange}
                        onChange={e => setSearch(e.target.value)}
                        placeholder={t('placeholder_search_users')}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ChipMultipleSelect disabled={reservesLoading} label={t('status_lbl')} setValue={setStatusValues}
                                        value={statusValues} data={statusFilters} id='status-filter'/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ReserveUsedSelector
                        setValue={setUsed}
                        value={used}
                        disabled={reservesLoading}
                    />
                </Grid>

            </Grid>

            <Grid container item xs={12} justify="flex-end" spacing={1}>
                <Grid item xs={2}>
                    <Button disabled={reservesLoading} variant="contained" color='secondary' startIcon={<DeleteIcon/>}
                            onClick={clearFiltersHandler}>{t('clear_filters_btn')}</Button>
                </Grid>
                <Grid item xs={1}>
                    <Button disabled={reservesLoading} variant="contained" color='primary' endIcon={<SearchIcon/>}
                            onClick={filterHandler} loading={reservesLoading}>{t('filter_btn')}</Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        reserveLoading: state.reserve.reserveLoading,
        reservesFilters: state.reserve.reservesFilters
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setReservesFilters: (params) => dispatch(setReservesFilters(params))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
