import React, { useEffect, useState } from 'react';
import TextField                      from '@material-ui/core/TextField';
import Autocomplete                   from '@material-ui/lab/Autocomplete';
import { connect }                    from 'react-redux';
import { fetchTags }                  from '../../../store/actions/discography/ReleaseAction';
import { useTranslation }             from 'react-i18next';

const ReleasesTagsAutocomplete = ({ value, setValue, label, fetchTags, loading, tags, ...rest }) => {
	const { t } = useTranslation();
	const [options, setOptions] = useState([]);

	useEffect(() => {
		if (!label) return;
		fetchTags(label.Classify.Classify__);
	}, [fetchTags, label]);

	useEffect(() => {
		if (!tags) return;
		setOptions(tags.map(t => t.Name));
	}, [tags, setOptions]);

	return (
		<Autocomplete
			multiple
			value={value ?? []}
			onChange={(event, newValue) => {
				setValue(newValue);
			}}
			options={options ?? []}
			renderInput={(params) => <TextField {...params} label={t('release_search_tags')} variant='outlined'/>}
			{...rest}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		tags: state.release.selectedTags,
		loading: state.release.selectedTagsLoading,
		label: state.music.label,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchTags: (classifyId) => dispatch(fetchTags(classifyId))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReleasesTagsAutocomplete);

