import React, { useEffect }      from 'react';
import { connect }               from 'react-redux';
import { fetchStreamArchive }    from '../../../../../store/actions/planner/StreamAction';
import { Trans, useTranslation } from 'react-i18next';
import Loading                   from '../../../feeback/loading/Loading';
import { Grid }                  from '@material-ui/core';
import RichAlert                 from '../../../feeback/RichAlert';
import Typography                from '@material-ui/core/Typography';
import ReactPlayer               from 'react-player';

const StreamArchive = ({ event, loading, archive, fetchStreamArchive }) => {
	const { t } = useTranslation();

	useEffect(() => {
		fetchStreamArchive(event.Planner_Event__);
	}, [event.Planner_Event__, fetchStreamArchive]);

	if (loading) return <Loading containerProps={{ style: { height: '100%' } }}/>;

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<RichAlert severity='info'>
					<Trans i18nKey='stream_archive_info'>
						sample<br/>
						<strong>sample</strong>
					</Trans>
				</RichAlert>
			</Grid>
			{!archive && <Grid item xs={12}>
				<Typography variant='h6'>{t('stream_archive_empty')}</Typography>
			</Grid>}

			{archive && <Grid item xs={12}>
				<ReactPlayer url={archive.Hls_Source} controls={true} width='100%'/>
			</Grid>}

		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.stream.loadingArchive,
		archive: state.stream.archive
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchStreamArchive: (eventId) => dispatch(fetchStreamArchive(eventId))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(StreamArchive);
