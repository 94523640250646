import {Chip} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

export const StyledNavChip = withStyles(theme => {
    return {
        root: {
            '&:hover' : {
                cursor: 'pointer',
                opacity: 0.4
            }
        },
    }
})(Chip);


export const InactiveChip = withStyles(theme => {
    return {
        root: {
            color: theme.palette.error.main,
        },
        icon: {
            color: theme.palette.error.main,
        }
    }
})(Chip);

export const ExpiredChip = withStyles(theme => {
    return {
        root: {
            color: theme.palette.warning.main,
        },
        icon: {
            color: theme.palette.warning.main,
        }
    }
})(Chip);

export const PaidChip = withStyles(theme => {
    return {
        root: {
            color: theme.palette.success.main,
        },
        icon: {
            color: theme.palette.success.main,
        }
    }
})(Chip);