import React, {useEffect, useState} from 'react';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import uuid from "../../utils/uuid";
import {useTranslation} from "react-i18next";
import {Select as MaterialSelect} from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import {isFunction} from '../../utils/misc'

const Select = ({label, options, optionKey, optionValue, value = undefined, helperText = undefined, onChange, inputProps = {}, selectProps = {}, allowEmpty = true, translatePrefix = undefined}) => {
    const selectLabel = React.useRef(null);
    const [selectLabelWidth, setLabelWidth] = useState(0);
    const [forUuid,] = useState(uuid());
    const {t} = useTranslation();

    useEffect(() => {
        setLabelWidth(selectLabel.current.offsetWidth);
    }, []);

    return (
        <FormControl {...inputProps}>
            <InputLabel ref={selectLabel} htmlFor={forUuid}>{label}</InputLabel>
            <MaterialSelect
                native
                {...selectProps}
                value={value}
                onChange={onChange}
                labelWidth={selectLabelWidth}
                inputProps={{
                    id: forUuid,
                }}
            >
                {allowEmpty && <option/>}
                {options.map(o =>
                    <option key={forUuid + '_' + (optionKey ? o[optionKey] : o)} value={optionKey ? o[optionKey] : o}>
                        {optionValue ? (isFunction(optionValue) ? optionValue(o) : o[optionValue]) : (translatePrefix ? t(translatePrefix + '_' + o) : o)}
                    </option>)
                }
            </MaterialSelect>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};

export default Select;