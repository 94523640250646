import React                      from 'react';
import {useTranslation}           from 'react-i18next';
import Grid                       from '@material-ui/core/Grid';
import AppBarTitle                from '../../components/common/typography/AppBarTitle';
import Toolbar                    from '../../components/common/typography/Toolbar';
import Table                      from '../../components/common/billing/table/Table';
import AddIcon                    from '@material-ui/icons/Add';
import Button                     from '../../components/common/inputs/Button';
import {Link}                     from 'react-router-dom';
import {getPXLBillingCreateRoute} from '../../router/routes/pxl/factory/order';

const Billings = () => {
	const {t} = useTranslation();

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('billings_title')}/>

			<Grid item xs={12}>
				<Toolbar title={t('billings_title')}>
					<Button variant="contained" color="primary" startIcon={<AddIcon/>} component={Link}
					        to={getPXLBillingCreateRoute()}>
						{t('add_btn')}
					</Button>
				</Toolbar>
			</Grid>

			<Grid item xs={12}>
				<Table/>
			</Grid>

		</Grid>
	);
};


export default Billings;