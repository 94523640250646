import React, {useEffect, useState} from 'react';
import {fetchAll} from "../../../../../store/actions/planner/ReserveAction";
import Loading from "../../../feeback/loading/Loading";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import CheckIcon from '@material-ui/icons/Check';
import {connect} from 'react-redux'
import {useTranslation} from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import TicketValidateDisplay from "./TicketValidateDisplay";
import Button from "../../../inputs/Button";

const Validate = ({event, reserves, loading, fetchAll, usedReserves}) => {
    const {t} = useTranslation();
    const [serial, setSerial] = useState('');
    const [previousSerial, setPreviousSerial] = useState('');
    const [reserve, setReserve] = useState(null);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [used, setUsed] = useState(false);

    const serialInput = React.useRef(null);
    const validateBtnInput = React.useRef(null);
    const validateBtnAction = React.useRef(null);

    const [checked, setChecked] = useState({});

    useEffect(() => {
        if (!event) return;
        setChecked(JSON.parse(localStorage.getItem('live-' + event.Planner_Event__) ?? '{}'));
        fetchAll(event.Planner_Event__)
    }, [event, fetchAll]);

    const isChecked = (id) => {
        return [id] in checked || usedReserves.filter(r => r.Planner_Event_Seat_Reserve__ === id).length > 0;
    };

    const check = (id) => {
        const newChecked = {...checked, [id]: true};
        setChecked(newChecked);
        localStorage.setItem('live-' + event.Planner_Event__, JSON.stringify(newChecked))
    };

    const handleSearchChange = e => {
        if (e.key !== 'Enter')
            return;

        fetchHandler()
    };


    const fetchHandler = () => {
        const searchValue = serial.trim();
        if (searchValue === '') return;
        setReserve(null);
        setSuccess(false);
        setError(false);
        setUsed(false);
        setPreviousSerial(searchValue);

        const filtered = reserves.filter(r => {
            if (searchValue[0] === '0') return r.Tel === searchValue || r.Serial === searchValue;
            return r.Serial === searchValue;
        });

        if (filtered.length > 0) {
            const r = filtered[0];
            setReserve(r);
            if (isChecked(r.Planner_Event_Seat_Reserve__)) {
                setUsed(true);
            } else {
                setSuccess(true);
                serialInput.current.blur();

                validateBtnAction.current.focusVisible();
                setTimeout( // Meh
                    () => {
                        validateBtnInput.current.focus();
                    },
                    100)

            }
        } else {
            setError(true);
        }

        setSerial('');
    };

    const validateHandler = () => {
        check(reserve.Planner_Event_Seat_Reserve__);
        setReserve(null);
        setSuccess(false);
        setError(false);
        setUsed(false);
        serialInput.current.focus();
    };

    if (loading) return <Loading/>;

    return (
        <Grid container spacing={3} direction='row' justify='center' alignItems='center'>
            <Grid item xs={12}>
                <TextField
                    inputRef={serialInput}
                    fullWidth
                    variant="outlined"
                    value={serial}
                    onKeyPress={handleSearchChange}
                    onChange={e => setSerial(e.target.value)}
                    placeholder={t('event_live_reserve_validate')}
                    InputProps={
                        {
                            min: 0,
                            endAdornment:
                                (<InputAdornment position="end">
                                    <IconButton
                                        color='primary'
                                        disabled={serial.trim() === ''}
                                        aria-label={t('event_live_reserve_validate')}
                                        onClick={fetchHandler}
                                    >
                                        <CheckIcon/>

                                    </IconButton>
                                </InputAdornment>)
                        }
                    }
                />
            </Grid>
            <Grid item xs={2}/>

            <Grid item xs={8}>
                {error &&
                <Alert severity='error'>{t('event_live_reserve_validate_error', {serial: previousSerial})}</Alert>}

                {(success || used) && <TicketValidateDisplay reserve={reserve} success={success} event={event}/>}
            </Grid>

            <Grid item xs={2}/>

            <Grid item>
                <Button style={{display: (!success || !reserve) ? 'none' : 'block'}} color='primary' variant='contained'
                        disabled={(!success || !reserve)} onClick={validateHandler}
                        action={validateBtnAction}
                        ref={validateBtnInput}
                >
                    {t('event_live_reserve_validate_btn')}
                </Button>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        reserves: state.reserve.allReserve,
        usedReserves: state.reserve.allUsedReserve,
        loading: state.reserve.loadingAll,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAll: (eventId) => dispatch(fetchAll(eventId)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Validate);