import React from 'react';
import {useTranslation} from 'react-i18next';
import Select from "./Select";

const QuestionVisibleSelector = ({value, setValue, disabled, required}) => {
    const {t} = useTranslation();

    return <Select
        label={t('question_visible')}
        helperText={t('question_visible_helperText')}
        value={value}
        onChange={e => setValue(e.target.value)}
        optionKey='Key'
        optionValue='Value'
        options={[
            {Key: 'Y', Value: t('question_visible_Y')},
            {Key: 'N', Value: t('question_visible_N')},
        ]}
        inputProps={{
            disabled: disabled,
            variant: 'outlined',
            fullWidth: true,
            required: required
        }}
    />;
};

export default QuestionVisibleSelector;