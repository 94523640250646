import React, {useState} from 'react';
import {Grid} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import Alert from '@material-ui/lab/Alert';
import {useUserBillingMethodUpdate} from '../../../hooks/api/user/useUserBillingMethod';
import {Title} from '../typography/Title';
import Button from '../inputs/Button';
import Stripe from '../inputs/Stripe/Stripe';
import PaymentMethod from './PaymentMethod';
import {connect} from "react-redux";


// We only support Stripe for now
const UpdatePaymentMethodForm = ({method, setMethod, location, user}) => {
	const {t} = useTranslation();
	const [update] = useUserBillingMethodUpdate(method.User_Billing_Method__);
	const [updating, setUpdating] = useState(false);


	const [showForm, setShowForm] = useState(false);
	const [stripe, setStripe] = useState(null);
	const [stripeElements, setStripeElements] = useState(null);
	const [stripeIntent, setStripeIntent] = useState(null)

	const [saveEnabled, setSaveEnabled] = useState(false);
	const [error, setError] = useState(null);

	const onStripeChange = e => {
		setSaveEnabled(e.complete === true);
	};

	const handleSave = async () => {
		if (!stripe || !stripeElements)
			return; // stripe not loaded yet

		setUpdating(true);

		const result = await stripe.confirmSetup({
			elements: stripeElements,
			redirect: 'if_required',
			confirmParams: {
				payment_method_data: {
					billing_details: {
						name: `${location.First_Name} ${location.Last_Name}`,
						email: user.Email,
						address: {
							country: location.Country__,
							postal_code: location.Zip,
							state: location.Province ?? '',
							city: location.City ?? '',
							line1: location.Address ?? '',
							line2: location.Address2 ?? '',
						}
					},
				}, // Make sure to change this to your payment completion page
				//return_url: `https://${window.location.host}${getPXLOrderRoute(order.Order__)}`,
			}
		});

		if (result.error) {
			if (result.error.type === "card_error" || result.error.type === "validation_error") {
				setError(result.error.message);
			} else {
				setError(t('unexpected_error'));
			}

			setUpdating(false);
			return;
		}

		update({stripe_intent: stripeIntent.stripe_intent, method: 'Stripe'})
			.then(setMethod)
			.then(() => setShowForm(false))
			.finally(() => setUpdating(false));
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Title>{t('billing_payment_method_update_title')}</Title>
			</Grid>
			{!showForm &&
				<>
					<Grid item xs={12}>
						<Alert severity='info'>
							<PaymentMethod method={method}/>
						</Alert>
					</Grid>

					<Grid item xs={12}>
						<Grid container justify='flex-end'>
							<Button
								color='primary'
								variant='contained'
								onClick={() => setShowForm(true)}
							>
								{t('edit_btn')}
							</Button>
						</Grid>
					</Grid>
				</>
			}

			{showForm &&
				<>

					{method.Expired && <Grid item xs={12}>
						<Alert severity='error'>
							{t('billing_method_expired')}
						</Alert>
					</Grid>
					}

					{method.Fail_Count_Reached && <Grid item xs={12}>
						<Alert severity='error'>
							{t('billing_method_failed')}
						</Alert>
					</Grid>
					}

					{error && <Grid item xs={12}>
						<Alert severity='error'>
							{error}
						</Alert>
					</Grid>
					}

					<Grid item xs={12}>
						<Stripe
							setStripeElements={setStripeElements}
							setStripe={setStripe}
							setIntent={setStripeIntent}
							onChange={onStripeChange}
							disabled={updating}
						/>
					</Grid>

					<Grid item xs={12}>
						<Grid container justify='flex-end'>
							<Button
								color='primary'
								variant='contained'
								loading={updating}
								disabled={!stripe || !stripeElements || updating || !saveEnabled}
								onClick={handleSave}
							>
								{t('save_btn')}
							</Button>
						</Grid>
					</Grid>
				</>
			}
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.user.user,
	}
};

const mapDispatchToProps = (dispatch) => {
	return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePaymentMethodForm);
