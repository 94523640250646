import React, {useEffect, useState} from 'react';
import Grid                         from '@material-ui/core/Grid';
import AppBarTitle                  from '../../components/common/typography/AppBarTitle';
import AddIcon                      from '@material-ui/icons/Add';
import {useTranslation}             from 'react-i18next';
import Table                        from '../../components/common/support/canned/Table/Table';
import {connect}                    from 'react-redux';
import CreateDialog                 from '../../components/common/support/canned/CreateDialog';
import SelectRealm                  from '../SelectRealm';
import HasRight                     from '../../components/Route/HasRight';
import Toolbar                      from '../../components/common/typography/Toolbar';
import Button                       from '../../components/common/inputs/Button';

const Canned = ({selectedRealm, support}) => {
	const {t} = useTranslation();
	const [showCreate, setShowCreate] = useState(false);
	const [enableTableDisplay, setEnableTableDisplay] = useState(false);

	useEffect(() => {
		if (!support) return;
		setEnableTableDisplay(true);
	}, [support]);


	if (!selectedRealm) return <SelectRealm/>;

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('support_canned_title')}/>

			<Grid item xs={12}>
				<Toolbar title={t('support_canned_title')}>
					<HasRight manager hideLoading>
						<Button endIcon={<AddIcon/>} variant="contained" color="primary"
						        onClick={e => setShowCreate(true)}>
							{t('create_btn')}
						</Button>
					</HasRight>
				</Toolbar>
			</Grid>
			<Grid item xs={12}>
				<Table disabled={!enableTableDisplay}/>
			</Grid>
			{support && <CreateDialog open={showCreate} setOpen={setShowCreate}/>}
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		support: state.support.support,
		selectedRealm: state.realm.selected,
	};
};

export default connect(mapStateToProps, null)(Canned);