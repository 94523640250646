import React                    from 'react';
import FlowLogin                from '../../components/login/Login';
import Grid                     from '@material-ui/core/Grid';
import GraphicText              from '../../resources/images/PXL_logo_nouhin_white_text.png';
import { connect }              from 'react-redux';
import Redirect                 from '../../components/Route/Redirect';
import { getPXLDashboardRoute } from '../../router/routes/pxl/factory/general';
import { useTranslation }       from 'react-i18next';
import LayoutLess               from '../../components/layout/LayoutLess';


const Login = ({ user }) => {
	const { t } = useTranslation();
	if (user) {
		return <Redirect target={getPXLDashboardRoute()}/>;
	}

	return (
		<LayoutLess title={t('login_title')}>
			<Grid container
			      direction='row'
			      justify='center'
			      alignItems='center'
			      spacing={5}
			>
				<Grid item xs={12} md={8}>
					<img src={GraphicText} width='100%' alt='PXL'/>
				</Grid>
				<Grid item xs={12} md={8}>
					<FlowLogin/>
				</Grid>
			</Grid>
		</LayoutLess>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.user.user
	};
};

export default connect(mapStateToProps)(Login);
