import React            from 'react';
import Card             from "@material-ui/core/Card";
import CardContent      from "@material-ui/core/CardContent";
import Typography       from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";
import Price            from "../../common/data/Price/Price";
import makeStyles       from "@material-ui/core/styles/makeStyles";
import CardHeader       from "@material-ui/core/CardHeader";
import IconButton       from "@material-ui/core/IconButton";
import DeleteIcon       from "@material-ui/icons/Delete";
import Grid             from '@material-ui/core/Grid';
import EditIcon         from '@material-ui/icons/Edit';

const useStyles = makeStyles({
    description: {
        whiteSpace: 'pre-wrap'
    },
    fillHeight: {height: '100%'}
});

const Plan = ({plan, onDelete, onEdit, vat, viewOnly = false}) => {
    const {t} = useTranslation();
    const classes = useStyles();
    return (
        <Card className={classes.fillHeight} variant='outlined'>
            <CardHeader
                action={
                    (!viewOnly) ? (
                    	<Grid container spacing={3} justify='space-between'>
		                    {onEdit && <Grid item>
			                    <IconButton color='primary' onClick={onEdit}>
				                    <EditIcon/>
			                    </IconButton>
		                    </Grid>}
		                    {onDelete && <Grid item>
			                    <IconButton color='secondary' onClick={onDelete}>
				                    <DeleteIcon/>
			                    </IconButton>
		                    </Grid>
		                    }
	                    </Grid>
	                    )
	                    : undefined
                }
                title={plan.name}

            />
            <CardContent>
                <Typography variant={"h6"}>
                    {t('price_lbl')}
                </Typography>
                {plan.free === 'N' && <Typography color="textSecondary" variant='body2' gutterBottom>
                    <Price
                        priceIncludeVat={plan.vatIncluded ?? false}
                        value={parseFloat(plan.price)}
                        currency={plan.currency}
                        vat={parseFloat(vat)}
                    />
                </Typography>}
                {plan.free === 'Y' && <Typography color="textSecondary" variant='body2' gutterBottom>
                    {t('plan_free_notice')}
                </Typography>}
                <Typography variant="body2" color="textSecondary" gutterBottom component="p"
                            className={classes.description}>
                    {plan.description}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default Plan;
