import React, {useEffect, useState} from 'react';
import makeStyles                   from '@material-ui/core/styles/makeStyles';
import {drawerWidth}                                         from '../style';
import {connect}                                             from 'react-redux';
import {hideMenu}                                            from '../../../../store/actions/CommonAction';
import {ClickAwayListener, Typography}                       from '@material-ui/core';
import {useTranslation}                                      from 'react-i18next';
import {
	MENU_ARTIST,
	MENU_FANCLUB, MENU_MOBILE,
	MENU_MUSIC,
	MENU_PLANNER, MENU_STORE, MENU_SUPPORT,
	MENU_WEBSITE
} from '../../../../store/reducers/CommonReducer';
import MenuWebsite   from './MenuWebsite';
import MenuArtist    from './MenuArtist';
import MenuPlanner   from './MenuPlanner';
import MenuMusic     from './MenuMusic';
import MenuFanclub   from './MenuFanclub';
import MenuStore     from './MenuStore';
import MenuSupport   from './MenuSupport';
import useTheme      from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => {
	return {
		container: {
			height: '100%',
			position: 'fixed',
			top: '0px',
			left: drawerWidth,
			zIndex: theme.zIndex.drawer,
			paddingTop: props => (64 - props.scrollTop) < 0 ? 0 : 64 - props.scrollTop,
			background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%), #121212;',
		},
		title: {
			margin: theme.spacing(3),
		}
	};
});


const Menu = ({menu, hideMenu}) => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down('sm'));
	const [scrollY, setScrollY] = useState(0);

	function logit() {
		setScrollY(window.pageYOffset);
	}

	useEffect(() => {
		function watchScroll() {
			window.addEventListener("scroll", logit);
		}
		watchScroll();
		return () => {
			window.removeEventListener("scroll", logit);
		};
	});

	const classes = useStyles({scrollTop: scrollY});
	const {t} = useTranslation();

	if (!menu || menu === MENU_MOBILE || mobile) return <></>;

	const getMenuContent = () => {
		switch (menu) {
			case MENU_WEBSITE:
				return <MenuWebsite/>;
			case MENU_ARTIST:
				return <MenuArtist/>;
			case MENU_MUSIC:
				return <MenuMusic/>;
			case MENU_PLANNER:
				return <MenuPlanner/>;
			case MENU_FANCLUB:
				return <MenuFanclub/>
			case MENU_STORE:
				return <MenuStore/>
			case MENU_SUPPORT:
				return <MenuSupport/>
			default:
				return <></>;
		}
	};

	return (
		<ClickAwayListener onClickAway={() => hideMenu()}>
			<div className={classes.container}>
				<Typography variant="h6" align="center" className={classes.title}>
					{t('menu_choose_feature')}
				</Typography>
				{getMenuContent()}
			</div>
		</ClickAwayListener>
	);
};

const mapStateToProps = (state) => {
	return {
		menu: state.common.menu,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		hideMenu: () => dispatch(hideMenu()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);