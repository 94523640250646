import { USER_LOGOUT }        from '../system/UserReducer';
import { COMMON_RESET }       from '../CommonReducer';
import { LYRICS_UPDATE_DONE } from './LyricsReducer';

const initState = () => {
	return {
		recordsLoading: false,
		records: [],
		recordsFilters: {},
		recordsPagingCount: 0,
		recordsPaging: {
			page_no: 1,
			results_per_page: 10
		},

		creating: false,
		updating: false,
		deleting: false,
		record: null,
		recordLoading: false,
	};
};

export const RECORD_LIST_FETCH = 'RECORD_LIST_FETCH';
export const RECORD_LIST_FETCH_DONE = 'RECORD_LIST_FETCH_DONE';

export const RECORD_FETCH = 'RECORD_FETCH';
export const RECORD_FETCH_DONE = 'RECORD_FETCH_DONE';

export const RECORD_SET_PAGING = 'RECORD_SET_PAGING';
export const RECORD_SET_FILTERS = 'RECORD_SET_FILTERS';

export const RECORD_CREATE = 'RECORD_CREATE';
export const RECORD_CREATE_DONE = 'RECORD_CREATE_DONE';

export const RECORD_UPDATE = 'RECORD_UPDATE';
export const RECORD_UPDATE_DONE = 'RECORD_UPDATE_DONE';

export const RECORD_DELETE = 'RECORD_DELETE';
export const RECORD_DELETE_DONE = 'RECORD_DELETE_DONE';

const recordReducer = (state = initState(), action) => {
	switch (action.type) {
		case USER_LOGOUT:
		case COMMON_RESET:
			return initState();
		case RECORD_DELETE:
			return { ...state, deleting: true };
		case RECORD_DELETE_DONE:
			return { ...state, deleting: false };
		case RECORD_UPDATE:
			return { ...state, updating: true };
		case RECORD_UPDATE_DONE:
			if (!action.record) return { ...state, updating: false };
			return { ...state, updating: false, record: action.record };
		case RECORD_CREATE:
			return { ...state, creating: true };
		case RECORD_CREATE_DONE:
			return { ...state, creating: false };
		case RECORD_FETCH:
			return { ...state, recordLoading: true };
		case LYRICS_UPDATE_DONE:
		case RECORD_FETCH_DONE:
			if (!action.record) return { ...state, recordLoading: false };
			return { ...state, recordLoading: false, record: action.record };
		case RECORD_LIST_FETCH:
			return { ...state, recordsLoading: true };
		case RECORD_LIST_FETCH_DONE:
			return {
				...state,
				recordsLoading: false,
				records: action.records,
				recordsPagingCount: action.paging.count
			};

		case RECORD_SET_FILTERS :
			return { ...state, recordsFilters: action.filters };
		case RECORD_SET_PAGING :
			return { ...state, recordsPaging: action.paging };
		default:
			return state;
	}

};

export default recordReducer;
