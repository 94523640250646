import PropTypes          from 'prop-types';
import React              from 'react';
import { useTranslation } from 'react-i18next';
import TableContainer     from '@material-ui/core/TableContainer';
import Table              from '@material-ui/core/Table';
import TableHead          from '@material-ui/core/TableHead';
import TableRow           from '@material-ui/core/TableRow';
import TableCell          from '@material-ui/core/TableCell';
import { TableBody }      from '@material-ui/core';
import {Title}            from '../../../typography/Title';

const CartTotals = ({ cart, detailed = false }) => {
	const { t } = useTranslation();

	return (
		<TableContainer>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell align='center' colSpan={2}>
							<Title>
								{t('cart_totals')}
							</Title>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>

					{detailed && <TableRow>
						<TableCell>{t('cart_total_before_discount')}</TableCell>
						<TableCell align='right'>{cart.total_no_coupon_no_vat.display}</TableCell>
					</TableRow>}

					<TableRow>
						<TableCell>{t('cart_total_vat_ht')}</TableCell>
						<TableCell align='right'>{cart.total.display}</TableCell>
					</TableRow>

					<TableRow>
						<TableCell>{t('cart_total_vat_only')}</TableCell>
						<TableCell align='right'>{cart.total_vat_only.display}</TableCell>
					</TableRow>

					<TableRow>
						<TableCell><strong>{t('cart_total_vat')}</strong></TableCell>
						<TableCell align='right'><strong>{cart.total_vat.display}</strong></TableCell>
					</TableRow>

				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default CartTotals;

CartTotals.propTypes = {
	cart: PropTypes.object.isRequired
};
