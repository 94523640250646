import React from 'react';
import {useTranslation} from "react-i18next";
import Graphic          from "../../../../../resources/images/stream.svg";
import EmptyState       from "../../../feeback/State/Empty";

const Empty = () => {
    const {t} = useTranslation();
    return (
        <EmptyState
            title={t('stream_empty_title')}
            textI18Key='stream_empty_text'
            graphic={Graphic}
        />
    );

};

export default Empty;
