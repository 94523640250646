import React, {useEffect, useState} from 'react';
import GridListTile from "@material-ui/core/GridListTile";
import Skeleton from "@material-ui/lab/Skeleton";
import GridList from "@material-ui/core/GridList";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from '@material-ui/core/styles';
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import makeStyles from "@material-ui/core/styles/makeStyles";
import InsertDialog from "./InsertDialog";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {Grid} from "@material-ui/core";
import AddIcon                   from "@material-ui/icons/Add";
import DeleteIcon                    from '@material-ui/icons/Delete';
import LinearProgress                from "@material-ui/core/LinearProgress";
import Typography                    from "@material-ui/core/Typography";
import {connect}                     from 'react-redux'
import {fetchEntry, uploadFiles}     from "../../../../store/actions/CmsAction";
import DeleteDialog                  from "./DeleteDialog";
import {useTranslation}              from 'react-i18next';
import Empty                         from "./Empty";
import Toolbar                       from "../../typography/Toolbar";
import Button                        from "../../inputs/Button";
import { mimeToType }                from '../../../utils/misc';
import {useContentCmsEntryImageHtml} from '../../../../hooks/api/content/cms/useContentCmsEntry';

export const IMG_VARIATION = "strip&scale_crop=240x180&format=jpeg";
export const VIDEO_VARIATION = "format=mp4";
export const AUDIO_VARIATION = "format=mp3";
export const GALLERY_VARIATION = {image_variation: [IMG_VARIATION,VIDEO_VARIATION], audio_variation:AUDIO_VARIATION}
const useStyles = makeStyles(theme => ({
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
        '&:hover': {
            color: 'rgba(255, 255, 255, 0.8)',
        }
    },
	audio: {
		marginTop: '15%',
		width: '100%'
	},
	video: {
		width: '100%'
	}
}));

const IMG_MIME  = 'image/png, image/jpeg, image/jpg, image/svg, image/gif'
const VIDEO_MIME = 'video/mp4, video/x-m4v, video/*';
const AUDIO_MIME = 'audio/*';

const ACCEPT_MIME = `${IMG_MIME}, ${VIDEO_MIME}, ${AUDIO_MIME}`;

export const GALLERY_DISPLAY_AUDIO = ['audio'];
export const GALLERY_DISPLAY_VIDEO = ['video'];
export const GALLERY_DISPLAY_IMAGE = ['image'];
export const GALLERY_DISPLAY_ALL = [...GALLERY_DISPLAY_AUDIO,...GALLERY_DISPLAY_VIDEO,...GALLERY_DISPLAY_IMAGE]


const Gallery = ({
                     entryId,
                     entry,
                     loading,
                     galleryMode = 'insert',
                     onInsert = () => {
                     },
                     onSelect = () => {
                     },
                     uploadFiles,
                     uploadImgCount,
                     uploadImgDoneCount,
                     uploadImgRunningCount,
                     uploadImgRunningPercent,
                     fetchEntry,
                     isManager,
					 display = GALLERY_DISPLAY_ALL,
	                 disabled = false
                 }) => {

    useEffect(() => {
        if (!entryId) return;
        fetchEntry(entryId);
    }, [entryId, fetchEntry]);


    const inputFileRef = React.useRef(null);
    const {t} = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [insertDialogShown, setInsertDialogShown] = useState(false);
    const [deleteDialogShown, setDeleteDialogShown] = useState(false);
    const [selectedImg, setSelectedImg] = useState(null);

    const [toDisplay, setToDisplay] = useState([]);
	const [getImageHtml] = useContentCmsEntryImageHtml(entryId);

	useEffect(() => {
		if(!entry) {
			setToDisplay([]);
		}else{
			setToDisplay(entry.Image.filter(img => {
				const type = mimeToType(img.Mime);
				return display.includes(type);
			}));
		}

	}, [entry, setToDisplay, display])


    const loadingData = [...Array(5).keys()];
    const displayLoading = () => loadingData.map((k) =>
        <GridListTile key={k}>
            <Skeleton width={200} height={180}/>
        </GridListTile>);

    const handleInsert = (img) => {
    	const type = mimeToType(img.Mime);
    	switch (type){
		    case 'image':
			    setInsertDialogShown(true);
			    break;
		    default:
			    getImageHtml(img.Drive_Item__).then(r => {
				    onInsert(r.HTML);
			    })

			    break;
	    }

    }

	const getDisplay = (img) => {
    	const type = mimeToType(img.Mime);
    	switch (type) {
		    case 'audio':
	            return (
				    <audio controls className={classes.audio}>
					    <source src={img.View_Url} type={img.Mime}/>
				    </audio>
			    )
		    case 'video':
		    	return (
				    <video controls className={classes.video}>
					    <source src={img.View_Url} type={img.Mime}/>
				    </video>
			    )
		    default:
		    	return  (<img src={img.Media_Image[IMG_VARIATION]} alt={img.Filename}/>)

	    }
	}


    const displayImages = () => toDisplay.map((img) =>
       <GridListTile key={img.Blob__}>
	        {getDisplay(img)}

            <GridListTileBar
                title={img.Filename}
                subtitle={moment(parseInt(img.Created.unixms)).format('LLL')}
                actionIcon={
                    <IconButton disabled={disabled} className={classes.icon} onClick={() => {
                        setSelectedImg(img);
                        if (galleryMode === 'select')
                            onSelect(img);
                        else if (galleryMode === 'insert')
	                        handleInsert(img)
                        else if (galleryMode === 'manage')
                            setDeleteDialogShown(true);
                    }
                    }>
                        {(galleryMode === 'insert' && isManager) && <AddPhotoAlternateIcon/>}
                        {(galleryMode === 'select' && isManager) && <ArrowForwardIcon/>}
                        {(galleryMode === 'manage' && isManager) && <DeleteIcon/>}
                    </IconButton>
                }
            />
        </GridListTile>
    );

    const displayUpload = uploadImgCount !== uploadImgDoneCount;

    let donePercent = 0;
    let bufferPercent = 0;

    if (displayUpload) {
        donePercent = (100 * uploadImgDoneCount) / uploadImgCount;
        bufferPercent = (100 * (uploadImgDoneCount + uploadImgRunningCount)) / uploadImgCount;
        // calculate the % of the currently uploading
        donePercent += ((bufferPercent - donePercent) * uploadImgRunningPercent)
    }

    const handleFileInputChange = (e) => {
        if (e.target.files.length < 1) {
            return;
        }

        uploadFiles(entry.Content_Cms_Entry__, e.target.files)
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Toolbar title={t('gallery_title')}>
                    {(entry && isManager) &&
                    <input accept={ACCEPT_MIME} multiple={true} type='file'
                           style={{display: 'none'}} ref={inputFileRef}
                           onChange={handleFileInputChange}/>}
                    {(entry && isManager) &&
                    <Button variant='contained' color='primary' disabled={displayUpload || disabled} endIcon={<AddIcon/>}
                            className={classes.addBtn}
                            onClick={() => {
                                inputFileRef.current.click()
                            }}
                    >
                        {t('add_btn')}
                    </Button>}
                </Toolbar>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" color="textSecondary" component="p">
                    {t('gallery_desc')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {displayUpload && <LinearProgress variant="buffer" value={donePercent} valueBuffer={bufferPercent}/>}
            </Grid>
            <Grid item xs={12}>
                <GridList cellHeight={180} cols={fullScreen ? 3 : 5}>
                    {!entry && displayLoading()}
                    {entry && displayImages()}

                    {(selectedImg && galleryMode === 'insert') &&
                    <InsertDialog entry={entry} open={insertDialogShown} setOpen={setInsertDialogShown}
                                  img={selectedImg} onInsert={onInsert}/>}

                    {(selectedImg && galleryMode === 'manage') &&
                    <DeleteDialog entry={entry} open={deleteDialogShown} setOpen={setDeleteDialogShown}
                                  img={selectedImg}/>}
                </GridList>
                {entry && toDisplay.length < 1 && <Empty/>}
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        uploadImgCount: state.cms.uploadImgCount,
        uploadImgDoneCount: state.cms.uploadImgDoneCount,
        uploadImgRunningCount: state.cms.uploadImgRunningCount,
        uploadImgRunningPercent: state.cms.uploadImgRunningPercent,
        entry: state.cms.entry,
        loading: state.cms.loadingEntry,
        isManager: state.access.manager
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        uploadFiles: (entryId, files) => dispatch(uploadFiles(entryId, files, {...GALLERY_VARIATION})),
        fetchEntry: (id) => dispatch(fetchEntry(id, {...GALLERY_VARIATION})),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
