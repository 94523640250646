import React, { useEffect, useState } from 'react';
import Grid                           from '@material-ui/core/Grid';
import { useTranslation }             from 'react-i18next';
import { TextField }                  from '@material-ui/core';
import { connect }                    from 'react-redux';
import { forgetPassword }             from '../store/actions/system/UserActions';
import Button                         from '../components/common/inputs/Button';
import Box                            from '@material-ui/core/Box';
import GraphicText                    from '../resources/images/PXL_logo_nouhin_white_text.png';
import LayoutLess                     from '../components/layout/LayoutLess';

const PasswordForgotten = ({ forgetPassword, sending }) => {
	const { t } = useTranslation();
	const [email, setEmail] = useState('');
	const [sendEnabled, setSendEnabled] = useState(false);

	useEffect(() => {
		setSendEnabled(email.trim() !== '');
	}, [setSendEnabled, email]);

	const onSubmit = (e) => {
		e.preventDefault();
		forgetPassword(email).then(() => setEmail(''));
	};

	return (
		<LayoutLess title={t('password_forgotten_title')}>
			<Grid container
			      direction='row'
			      justify='center'
			      alignItems='center'
			      spacing={5}
			>
				<Grid item xs={12} md={8}>
					<img src={GraphicText} width='100%' alt='PXL'/>
				</Grid>
				<Grid item xs={12} md={8}>
					<form onSubmit={onSubmit}>
						<p>{t('password_forgotten_desc')}</p>
						<TextField
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							variant='outlined'
							fullWidth
							label={t('email_lbl')}
							type='email'
							required
							disabled={sending}
						/>
						<Box m={3}/>
						<Button
							type='submit'
							fullWidth
							variant='contained'
							color='primary'
							loading={sending}
							disabled={!sendEnabled || sending}
						>
							{t('send_btn')}
						</Button>
					</form>
				</Grid>
			</Grid>
		</LayoutLess>
	);
};

const mapStateToProps = (state) => {
	return {
		sending: state.user.forgetPasswordSent,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		forgetPassword: (email) => dispatch(forgetPassword(email))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordForgotten);
