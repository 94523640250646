import React, {useState} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import AttachmentIcon from '@material-ui/icons/Attachment';
import {useTranslation} from "react-i18next";
import AttachmentDialog from "./AttachmentDialog";
import Grid from "@material-ui/core/Grid";
import Button from "../inputs/Button";

const useStyles = makeStyles(theme => ({
    message: {
        whiteSpace: 'pre-wrap',
    },
}));


const Message = ({message}) => {
    const classes = useStyles();
    const {t} = useTranslation();

    // That"s the case if there is a form in the contact form.
    const initialFileAttachments = message.File.filter((file) => file.Mime !== "text/plain" && file.Mime !== "text/html");

    let initialPartsAttachments = [];
    //If the attachments are sent with the email client, the attachment will be found as a Part of the files
    message.File.forEach((file) => {
        Object.keys(file.Part ?? []).forEach(partKey => {
            const part = file.Part[partKey];
            if (part.Mime !== "text/plain" && part.Mime !== "text/html")
                initialPartsAttachments.push(part);
        })
    });

    const [fileAttachments] = useState(initialFileAttachments);
    const [partAttachments] = useState(initialPartsAttachments);
    const [showAttachments, setShowAttachments] = useState(false);

    const hasAttachments = fileAttachments.length + partAttachments.length > 0;

    return (
        <Box p={2} color={message.Type === "question" ? "text.primary" : "background.paper"}
             bgcolor={message.Type === "question" ? "" : "text.secondary"}>

            <Typography variant='caption' component='p' className={classes.message}>
                {message.Message}
            </Typography>
            {
                hasAttachments &&
                <Grid container justify="flex-end">
                    <Grid item>
                        <Button startIcon={<AttachmentIcon/>} onClick={() => setShowAttachments(true)}>
                            {t('message_attachments_btn', {count: fileAttachments.length + partAttachments.length})}
                        </Button>
                    </Grid>
                </Grid>

            }
            {hasAttachments &&
            <AttachmentDialog open={showAttachments} setOpen={setShowAttachments} fileAttachments={fileAttachments}
                              partAttachments={partAttachments}/>
            }

        </Box>
    );
};

export default Message;