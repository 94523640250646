import React from 'react';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {useTranslation} from "react-i18next";
import Button from "../../../inputs/Button";

const Row = ({collaborator, onDelete, deletable, isReview=  false}) => {
    const {t} = useTranslation();

    return (
        <TableRow>
            <TableCell>
                {collaborator.Music_Label_Artist.Name}
            </TableCell>
            <TableCell>
                {t('role_' + collaborator.Role)}
            </TableCell>
            {!isReview && <TableCell align="right">
                {deletable &&
                <Button variant='text' color='secondary' onClick={e => onDelete(collaborator)}>
                    {t('delete_btn')}
                </Button>}
            </TableCell>}
        </TableRow>
    );
};

export default Row;