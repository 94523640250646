import React, { useState } from 'react';
import DialogTitle                    from '@material-ui/core/DialogTitle';
import DialogContent       from '@material-ui/core/DialogContent';
import DialogActions       from '@material-ui/core/DialogActions';
import Button              from '../../common/inputs/Button';
import Dialog              from '@material-ui/core/Dialog';
import { useTranslation }  from 'react-i18next';
import Form                from '../../common/membership/plans/create/Form';
import { getCurrency }     from '@karpeleslab/klbfw';
import Box                 from '@material-ui/core/Box';

const CreatePlanDialog = ({ open, setOpen, addPlan, planEdit = null, allowFree = true }) => {
	const { t } = useTranslation();
	const [plan, setPlan] = useState(planEdit ? {...planEdit} : { period: '1m', free: 'N' });
	const [isValid, setIsValid] = useState(false);

	const handleClose = () => setOpen(false);
	const handleAdd = () => {
		addPlan(planEdit ? plan : {
			...plan,
			free: allowFree ? plan.free : 'N',
			priority: plan.priority ?? 0,
			currency: getCurrency(),
		});
		handleClose();
	};
	return (
		<Dialog
			open={open}
			aria-labelledby={t('fanclub_plans_create_title')}
			aria-describedby={t('fanclub_plans_create_title')}
			fullWidth
			onEnter={() => {
				setPlan(planEdit ? {...planEdit} : { period: '1m', free: 'N' });
				setIsValid(false);
			}}
			maxWidth='lg'
		>
			<DialogTitle id='create-plan-title'>{t('fanclub_plans_create_title')}</DialogTitle>
			<DialogContent>
				<Box p={2}>
					<Form setIsValid={setIsValid} plan={plan} setPlan={setPlan} allowFree={allowFree}/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button color='default' onClick={handleClose}>
					{t('close_btn')}
				</Button>
				<Button color='primary' variant='contained' onClick={handleAdd} disabled={!isValid}
				        type='submit'>
					{t('create_btn')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default CreatePlanDialog;
