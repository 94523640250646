import React, {useEffect, useState} from 'react';
import {Grid}                       from '@material-ui/core';
import {useTranslation}             from 'react-i18next';
import {deepCopy}                   from '../../utils/misc';
import {useUserLocationUpdate}      from '../../../hooks/api/user/useUserLocation';
import {useUserBillingUpdate}       from '../../../hooks/api/user/useUserBilling';
import {Title}                      from '../typography/Title';
import Button                       from '../inputs/Button';
import FormData                     from '../user/location/FormData';
import FormRequiredField            from '../feeback/FormRequiredField';


const UpdateLocationForm = ({billing, setBilling, location, setLocation}) => {
	const {t} = useTranslation();
	const [formValid, setFormValid] = useState(false);
	const [saveEnabled, setSaveEnabled] = useState(false);
	const [data, setData] = useState(deepCopy(location));
	const [updateLocation, updatingLocation] = useUserLocationUpdate(location.User_Location__);
	const [updateBilling, updatingBilling] = useUserBillingUpdate(billing.User_Billing__);

	useEffect(() => {
		setData(deepCopy(location));
	}, [location, setData]);

	// detect changes
	const hasChanged = k => {
		if (k in data && !(k in (location ?? {}))) return true;
		if (!(k in data) && k in (location ?? {})) return true;

		return (data[k] ?? '').trim() !== (location[k] ?? '').trim();
	};

	useEffect(() => {
		if (formValid && (
			hasChanged('First_Name') ||
			hasChanged('Last_Name') ||
			hasChanged('Zip') ||
			hasChanged('Province') ||
			hasChanged('City') ||
			location['Country'].Country__ !== (('Country' in data && data['Country']) ? data['Country'].Country__ : data.Country__) ||
			hasChanged('Contact_Phone') ||
			hasChanged('Address') ||
			hasChanged('Address2') ||
			hasChanged('Company_Name') ||
			hasChanged('Company_European_Vat_Number') ||
			hasChanged('Company_Department'))
		)
			setSaveEnabled(true);
		else

			setSaveEnabled(false);

		// eslint-disable-next-line
	}, [location, data, formValid, setSaveEnabled]);

	const saveHandler = () => {
		const toSave = {
			First_Name: data.First_Name.trim(),
			Last_Name: data.Last_Name.trim(),
			Zip: data.Zip.trim(),
			Province: data.Province,
			City: data.City,
			Contact_Phone: data.Contact_Phone,
			Address: data.Address,
			Address2: data.Address2,
			Company_Name: data.Company_Name,
			Company_European_Vat_Number: data.Company_European_Vat_Number,
			Company_Department: data.Company_Department,
			Country__: (('Country' in data && data['Country']) ? data['Country'].Country__ : data.Country__),
		};
		updateLocation(toSave)
			.then(location => {
				setLocation(location);
				return location;
			})
			.then(location => {
				return updateBilling({User_Location__: location.User_Location__});
			})
			.then(billing => {
				setBilling(billing);
			});
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Title>{t('billing_user_location_update_title')}</Title>
			</Grid>

			<FormRequiredField/>

			<Grid item xs={12}>
				<FormData
					setAddress={setData}
					setIsValid={setFormValid}
					address={data}
					disabled={updatingLocation || updatingBilling}
				/>
			</Grid>

			<Grid item xs={12}>
				<Grid container justify="flex-end">
					<Button
						color="primary"
						variant="contained"
						onClick={saveHandler}
						loading={updatingLocation || updatingBilling}
						disabled={!saveEnabled || updatingLocation || updatingBilling}
					>
						{t('save_btn')}
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default UpdateLocationForm;