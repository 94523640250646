import React              from 'react';
import Grid               from '@material-ui/core/Grid';
import GridList           from '@material-ui/core/GridList';
import Empty              from '../../common/store/shop/gallery/Empty';
import GalleryImg         from './GalleryImg';
import Toolbar            from '../../common/typography/Toolbar';
import { useTranslation } from 'react-i18next';
import Button             from '../../common/inputs/Button';
import AddIcon            from '@material-ui/icons/Add';

const Gallery = ({ handleBack, handleNext, files, setFiles }) => {
	const { t } = useTranslation();
	const inputFileRef = React.useRef(null);

	const handleFileInputChange = (e) => {
		if (e.target.files.length < 1) {
			return;
		}
		setFiles([...files, ...e.target.files]);
	};

	const onRemove = (idx) => {
		const array = [...files];
		array.splice(idx, 1);
		setFiles(array);

	};
	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Toolbar title={t('gallery_title')}>
					<input accept='image/png, image/jpeg, image/jpg, image/svg, image/gif' multiple={true} type='file'
					       style={{ display: 'none' }} ref={inputFileRef}
					       onChange={handleFileInputChange}/>
					<Button variant='contained' color='primary'
					        endIcon={<AddIcon/>}
					        onClick={() => {
						        inputFileRef.current.click();
					        }}
					>
						{t('add_btn')}
					</Button>
				</Toolbar>
			</Grid>

			<Grid item xs={12}>
				<GridList cellHeight={180} cols={5}>
					{files.map((file, idx) => <GalleryImg key={idx} idx={idx} file={file} onRemove={onRemove}/>)};
				</GridList>

				{files.length < 1 && <Empty/>}
			</Grid>

			<Grid item xs={12}>
				<Grid container justify='space-between' spacing={3}>

					<Grid item>
						<Button
							onClick={handleBack}
							variant={'outlined'}
							color='primary'
						>
							{t('back_btn')}
						</Button>
					</Grid>

					<Grid item>
						<Button onClick={handleNext} variant='contained' color='primary'>
							{t('next_btn')}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Gallery;
