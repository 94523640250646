import React, {useEffect, useState} from 'react';
import Grid                         from '@material-ui/core/Grid';
import AppBarTitle                  from '../../../components/common/typography/AppBarTitle';
import {useTranslation}             from 'react-i18next';
import {connect}                    from 'react-redux';
import Loading                      from '../../../components/common/feeback/loading/Loading';
import SelectRealm                  from '../../SelectRealm';
import {useParams}                  from 'react-router-dom';
import {fetchAlbum}                 from '../../../store/actions/discography/AlbumAction';
import Form                         from '../../../components/common/music/album/Form';
import Toolbar                      from '../../../components/common/typography/Toolbar';

const Edit = ({artist, selectedRealm, album, fetchAlbum}) => {
	const {t} = useTranslation();
	const {albumId} = useParams();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		fetchAlbum(albumId)
			.then(s => setLoading(false));
	}, [fetchAlbum, albumId]);

	if (!selectedRealm) return <SelectRealm/>;
	if (!artist || loading) return <Loading/>;

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('album_edit_title')}/>

			<Grid item xs={12}>
				<Toolbar title={t('album_edit_title')}/>
			</Grid>

			<Grid item xs={12}>
				<Form artist={artist} album={album}/>
			</Grid>

		</Grid>
	);
};


const mapStateToProps = (state) => {
	return {
		artist: state.artist.artist,
		selectedRealm: state.realm.selected,
		album: state.album.album,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchAlbum: (id) => dispatch(fetchAlbum(id))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);