import React, {useCallback, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Button from "./Button";
import {useTranslation} from "react-i18next";
import defaultPicture from "../../../resources/images/landscape-image.svg";
import uuid from "../../utils/uuid";
import FormHelperText from "@material-ui/core/FormHelperText";
import {error} from "../../../store/actions/system/ToastAction";

const PictureChooser = ({value, setValue, defaultValue, label, disabled, pictureEditedChanged, required = false, helperText, minWidth = 0, minHeight = 0}) => {
    const {t} = useTranslation();

    const [pictureSrc, setPictureSrc] = useState(defaultPicture);
    const [pictureEdited, _setPictureEdited] = useState(false);
    const [pictureKey, setPictureKey] = useState(uuid());

    const setPictureEdited = useCallback((v) => {
        _setPictureEdited(v);
        if (pictureEditedChanged)
            pictureEditedChanged(v)
    }, [_setPictureEdited, pictureEditedChanged]);

    // Update img src tag
    useEffect(() => {
        const isSame = value === defaultValue || (value && 'Drive_Item__' in value && defaultValue && value.Drive_Item__ === defaultValue.Drive_Item__);

        if (value === undefined || 'Drive_Item__' in value) {
            if (defaultValue && 'Icon' in defaultValue)
                setPictureSrc(defaultValue.Icon);
            else {
                if (!defaultValue && value && 'Icon' in value) {
                    setPictureSrc(value.Icon);
                } else
                    setPictureSrc(defaultPicture);
            }

            if (isSame) {
                setPictureEdited(false);
                setPictureKey(uuid());
            }

            return;
        }

        const fr = new FileReader();
        fr.onload = function () {
            const img = new Image();

            img.onload = function () {
                if (img.width < minWidth || img.height < minHeight) {
                    error(t('picture_chooser_invalid_size', {minHeight: minHeight, minWidth: minWidth}), false,3000);
                    onRevertPicture();
                    return;
                }

                setPictureSrc(fr.result);
            };

            img.src = fr.result; // is the data URL because called with readAsDataURL

        };
        fr.readAsDataURL(value);

        // eslint-disable-next-line
    }, [value, defaultValue, setPictureEdited, minWidth, minHeight]);

    const onPictureChange = (e) => {
        if (e.target.files.length < 1) {
            setValue(defaultValue)
        } else {
            setValue(e.target.files[0])
        }

        setPictureEdited(true);
    };

    const onRevertPicture = () => {
        setValue(defaultValue);
        setPictureEdited(false);
        setPictureKey(uuid())
    };

    return (
        <Grid container spacing={3} alignItems="center">
            <Grid item>
                <img height={150} src={pictureSrc} alt={label}/>
            </Grid>
            <Grid item>
                <FormControl fullWidth disabled={disabled}>
                    <InputLabel shrink={true} htmlFor='picture-chooser'>{label}</InputLabel>
                    <Input
                        required={required}
                        disabled={disabled}
                        key={pictureKey}
                        onChange={onPictureChange} inputProps={
                        {
                            accept: 'image/png, image/jpeg, image/jpg, image/svg'
                        }
                    }
                        id="picture-chooser" type='file'/>
                    {helperText && <FormHelperText>{helperText}</FormHelperText>}
                </FormControl>
                {pictureEdited &&
                <Button color='secondary' variant='text' onClick={onRevertPicture} disabled={disabled}>
                    {t('cancel_edit_picture')}
                </Button>}
            </Grid>
        </Grid>
    );
};

export default PictureChooser;