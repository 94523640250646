import React                      from 'react';
import {Grid}                     from '@material-ui/core';
import {useFirstPaymentMethod}    from '../../hooks/api/user/useUserBilling';
import Loading                    from '../../components/common/feeback/loading/Loading';
import {useUserLocation}          from '../../hooks/api/user/useUserLocation';
import UpdateLocationForm         from '../../components/common/billing/UpdateLocationForm';
import UpdatePaymentMethodForm    from '../../components/common/billing/UpdatePaymentMethodForm';
import {getPXLBillingCreateRoute} from '../../router/routes/pxl/factory/order';
import Redirect                   from '../../components/Route/Redirect';

const UpdateBillingWrapper = ({billing, setBilling}) => {
	const [location, refreshLocation] = useUserLocation(billing.User_Location__);
	const [method, refreshMethod] = useFirstPaymentMethod(billing.User_Billing__);

	if (!location || !method)
		return <Loading/>;

	if (!method.data || !location.data)
		return <Redirect target={getPXLBillingCreateRoute()}/>;

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<UpdateLocationForm
					setBilling={setBilling}
					billing={billing}
					location={location.data}
					setLocation={refreshLocation}
				/>
			</Grid>

			<Grid item xs={12}>
				<UpdatePaymentMethodForm
					location={location.data}
					method={method.data}
					setMethod={refreshMethod}
				/>
			</Grid>
		</Grid>
	);
};

export default UpdateBillingWrapper;