import React, {Fragment, useState} from 'react';
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import ReactToolbar from "@material-ui/core/Toolbar";
import moment from "moment";
import {useHistory} from 'react-router'
import {useTranslation} from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteDialog from "./DeleteDialog";
import {connect} from 'react-redux'
import {getSupportInboxRoute} from "../../../router/routes/support/factory/boxes";
import SearchIcon from '@material-ui/icons/Search';
import {Link} from "react-router-dom";
import {getSiteUserEditRoute} from "../../../router/routes/site/factory/user";

const useStyles = makeStyles(theme => ({
    avatar: {
        marginRight: theme.spacing(3)
    },
}));

const Toolbar = ({ticket, onClosed, hasRight}) => {
    const history = useHistory();
    const {t} = useTranslation();
    const classes = useStyles();

    const [anchorMenuEl, setAnchorMenuEl] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const handleClickMenu = event => {
        setAnchorMenuEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorMenuEl(null);
    };

    const handleBackClick = () => {
        if (ticket.Status === 'new' || ticket.Status === 'assigned')
            history.push(getSupportInboxRoute())
    };

    const onTicketClosed = d => {
        setShowDeleteDialog(false);
        onClosed(d);
    };

    return (
        <Fragment>
            <Typography variant="h5" component="h5" gutterBottom>
                {ticket.Subject}
            </Typography>
            <ReactToolbar disableGutters>
                <Hidden mdUp={true}>
                    <Grid item>
                        <IconButton edge="start" color="inherit" aria-label="back" onClick={handleBackClick}>
                            <ArrowBackIosIcon fontSize="small"/>
                        </IconButton>
                    </Grid>

                </Hidden>

                <Avatar src={ticket.User.Profile.Media_Image ? ticket.User.Profile.Media_Image.Url : ''}
                        className={classes.avatar}/>

                <Grid container direction='column' spacing={0}>
                    <Grid item>
                        <Typography variant="body2" component="span" color="textSecondary">
                            {moment(parseInt(ticket.Last_Update.unixms)).format('LLL')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2">
                            {ticket.User.Profile.Display_Name &&
                            <span>{ticket.User.Profile.Display_Name}</span>}
                            {!ticket.User.Profile.Display_Name && <span>{t('support_ticket_anonymous')}</span>}
                        </Typography>
                    </Grid>
                    {ticket.User.Email &&
                    <Grid item>
                        <Typography variant="body2" component="span" color="textSecondary">
                            {ticket.User.Email}
                        </Typography>
                    </Grid>
                    }

                </Grid>

                <IconButton
                    aria-label="more"
                    aria-haspopup="true"
                    component={Link}
                    target="_blank"
                    to={getSiteUserEditRoute(ticket.User.User__)}

                >
                    <SearchIcon/>
                </IconButton>

                {(hasRight && ticket.Status !== 'closed') &&
                <Fragment>
                    <IconButton
                        aria-label="more"
                        aria-haspopup="true"
                        onClick={handleClickMenu}
                    >
                        <MoreVertIcon/>
                    </IconButton>

                    <Menu
                        id="simple-menu"
                        anchorEl={anchorMenuEl}
                        keepMounted
                        open={Boolean(anchorMenuEl)}
                        onClose={handleCloseMenu}
                    >
                        <MenuItem onClick={() => {
                            handleCloseMenu();
                            setShowDeleteDialog(true)
                        }}>
                            <ListItemIcon>
                                <HighlightOffIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText primary={t('close_ticket_btn')}/>
                        </MenuItem>
                    </Menu>
                </Fragment>
                }

            </ReactToolbar>
            <DeleteDialog open={showDeleteDialog} setOpen={setShowDeleteDialog} onTicketClosed={onTicketClosed}
                          ticket={ticket}/>
        </Fragment>
    );
};


const mapStateToProps = (state) => {
    return {
        hasRight: state.access.manager
    }
};

export default connect(mapStateToProps)(Toolbar);
