import React              from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles         from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
	warning: {
		color: 'red'
	},
}));

const DeliverableStyle = ({deliverable}) => {
	const {t} = useTranslation();
	const classes = useStyles();

	if(deliverable.Style !== 'shipping')
		return <span>{t('deliverable_style_' + deliverable.Style)}</span>

	return (
		<span className={deliverable.Stock < 1 ? classes.warning : ''}>
			{t('deliverable_style_' + deliverable.Style)} - {t('deliverable_shipping_left', {stock: deliverable.Stock})}
		</span>
	);
};

export default DeliverableStyle;
