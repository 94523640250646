import { rest }        from '@karpeleslab/klbfw';
import { handleError } from '../../../components/utils/apiErrorHandler';
import {
	ANNOUNCE_POST,
	ANNOUNCE_POST_DONE,
	CHAT_LIST_FETCH,
	CHAT_LIST_FETCH_DONE,
	CHAT_LOG_DELETE,
	CHAT_POOLING_ENABLED,
	CHAT_STATUS_CHANGE
}                      from '../../reducers/planner/ChatReducer';
import { success }     from '../system/ToastAction';

export const chatFetch = (event) => {

	return (dispatch, getState) => {

		const chatLog = getState().chat.chatLog;

		let query = {
			index: 0
		};

		if (chatLog.length !== 0) {
			query.index = chatLog.slice(-1)[0].Index;
		}

		dispatch({
			type: CHAT_LIST_FETCH
		});

		return rest(`Planner/Event/${event.Planner_Event__}/Chat:poll`, 'POST', query)
			.then(res => {
				dispatch({
					type: CHAT_LIST_FETCH_DONE,
					chatLog: res.data
				});
				return res;
			})
			.then((res) => {
				if (!getState().chat.poolingEnabled) return res;
				dispatch(chatFetch(event));
				return res;
			})
			.catch(err => {
				dispatch({
					type: CHAT_LIST_FETCH_DONE,
				});
				handleError(getState, dispatch, err, true);
			});
	};
};

export const announcePost = (event, query) => {
	return (dispatch, getState) => {

		dispatch({ type: ANNOUNCE_POST });

		return rest(`Planner/Event/${event.Planner_Event__}/Chat:post`, 'POST', query)
			.then(data => {
				dispatch({
					type: ANNOUNCE_POST_DONE,
					chatLimit: data.data.Time_Limit
				});
				success('chat_send_comp');
			})
			.catch(err => {
				dispatch({ type: ANNOUNCE_POST_DONE });
				handleError(getState, dispatch, err);
			});
	};
};

export const chatStatusChange = (event, query) => {
	return (dispatch, getState) => {
		dispatch({
			type: CHAT_STATUS_CHANGE,
			status: query
		});
	};
};

export const chatLogDelete = () => {
	return (dispatch, getState) => {
		dispatch({
			type: CHAT_LOG_DELETE
		});
	};
};

export const enablePooling = () => {
	return (dispatch, getState) => {
		dispatch({ type: CHAT_POOLING_ENABLED, enabled: true });
	};
};


export const disablePooling = () => {
	return (dispatch, getState) => {
		dispatch({ type: CHAT_POOLING_ENABLED, enabled: false });
	};
};
