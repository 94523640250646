import React from 'react';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "../../../inputs/Button";
import TableBody from "@material-ui/core/TableBody";
import {getStoreWarehouseEditRoute} from "../../../../../router/routes/store/factory/store";
import Table from "@material-ui/core/Table";
import Checkbox from "@material-ui/core/Checkbox";
import {useTranslation} from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Link from "../../../typography/Link";

const useStyle = makeStyles({
    root: {
        '&:last-child td': {
            borderBottom: 'unset',
        },
    },
});

const GoodsTable = ({showUngroupBtn, ungroupOnClick, selectable = false, shipping, onSelectionChange, selected = {}}) => {
    const {t} = useTranslation();
    const classes = useStyle();

    return (
        <Table size='small'>
            <TableHead>
                <TableRow>
                    {selectable && <TableCell padding='checkbox' className='no-print'/>}
                    <TableCell> {t('tablecell_shipping_item')}</TableCell>
                    <TableCell> {t('tablecell_shipping_good')}</TableCell>
                    <TableCell> {t('tablecell_shipping_quantity')}</TableCell>
                    <TableCell className='no-print'> {(showUngroupBtn && shipping.Items.length > 1) &&
                    <Button
                        variant='outlined'
                        color='secondary'
                        size='small'
                        onClick={ungroupOnClick}
                    >
                        {t('shipment_ungroup')}
                    </Button>
                    }</TableCell>

                </TableRow>
            </TableHead>
            <TableBody className={classes.root}>
                {shipping.Items.map(({Item, Deliverable, Catalog_Shipping_Item__}) => {
                    const isSelected = [Catalog_Shipping_Item__] in selected;
                    return (
                        <TableRow>
                            {selectable && <TableCell className='no-print'>
                                {selectable && <Checkbox
                                    size='small'
                                    color='primary'
                                    checked={isSelected}
                                    onChange={e => onSelectionChange(!isSelected, Catalog_Shipping_Item__, Item)}
                                />
                                }
                            </TableCell>}
                            <TableCell>
                                <span>{Item.Catalog_Product['Basic.Name']}</span>
                            </TableCell>
                            <TableCell>
                                            <span>
                                                <Link
                                                    to={getStoreWarehouseEditRoute(Deliverable.Catalog_Deliverable__)}>
                                                    {Deliverable.Name}
                                                </Link>
                                            </span>
                            </TableCell>
                            <TableCell colSpan={2}>
                                <span>{Item.Quantity}</span>
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    );
};

export default GoodsTable;