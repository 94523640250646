import React, { useState }            from 'react';
import Grid                           from '@material-ui/core/Grid';
import Toolbar                        from '../../common/typography/Toolbar';
import HasRight                       from '../../Route/HasRight';
import Button                         from '../../common/inputs/Button';
import LinkIcon                       from '@material-ui/icons/Link';
import TableContainer                 from '@material-ui/core/TableContainer';
import Table                          from '@material-ui/core/Table';
import TableHead                      from '@material-ui/core/TableHead';
import TableRow                       from '@material-ui/core/TableRow';
import TableCell                      from '@material-ui/core/TableCell';
import TableBody                      from '@material-ui/core/TableBody';
import MaterialLink                   from '@material-ui/core/Link';
import { Link }                       from 'react-router-dom';
import { getStoreWarehouseEditRoute } from '../../../router/routes/store/factory/store';
import IconButton                     from '@material-ui/core/IconButton';
import DeleteForeverIcon              from '@material-ui/icons/DeleteForever';
import EmptyGoods                     from '../../common/store/shop/EmptyGoods';
import LinkGoodDialog                 from '../../common/store/shop/LinkGoodDialog';
import { useTranslation }             from 'react-i18next';

const Deliverables = ({ deliverables, setDeliverables, creating = false }) => {
	const { t } = useTranslation();
	const [showLinkDialog, setShowLinkDialog] = useState(false);

	const handleDelete = d => {
		setDeliverables(deliverables.filter(del => del.Catalog_Deliverable__ !== d.Catalog_Deliverable__));
	};

	const handleAdd = (d) => {
		setDeliverables([...deliverables, d]);
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Toolbar title={t('product_goods')}>
					<HasRight manager>
						<Button
							endIcon={<LinkIcon/>}
							onClick={() => setShowLinkDialog(true)}
							variant='contained'
							color='primary'
							disabled={creating}
						>
							{t('link_btn')}
						</Button>
					</HasRight>
				</Toolbar>
			</Grid>

			<Grid item xs={12}>
				{deliverables.length > 0 &&
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell> {t('tablecell_deliverable_name')}</TableCell>
								<TableCell> {t('tablecell_deliverable_style')}</TableCell>
								<TableCell align='right'/>
							</TableRow>
						</TableHead>
						<TableBody>
							{deliverables.map(g =>
								<TableRow key={g.Catalog_Deliverable__}>
									<TableCell>
										<MaterialLink
											component={Link}
											to={getStoreWarehouseEditRoute(g.Catalog_Deliverable__)}>
											{g.Name}
										</MaterialLink>
									</TableCell>
									<TableCell> {t('deliverable_style_' + g.Style)}</TableCell>
									<TableCell align='right'>
										<HasRight admin>
											<IconButton
												disabled={creating}
												color='secondary'
												onClick={() => handleDelete(g)}>
												<DeleteForeverIcon/>
											</IconButton>
										</HasRight>
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				}
				{deliverables.length < 1 && <EmptyGoods/>}
				<LinkGoodDialog
					existing={deliverables}
					linkCallback={handleAdd}
					open={showLinkDialog}
					setOpen={setShowLinkDialog}
				/>
			</Grid>

		</Grid>
	);
};

export default Deliverables;
