import React from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import {connect} from 'react-redux'
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import {refundOrder} from "../../../../store/actions/OrderAction";
import { useTranslation } from 'react-i18next';
import Button from "../../inputs/Button";

const RefundOrderDialog = ({order, open, setOpen, refunding, refundOrder}) => {
    const handleClose = () => {
        setOpen(false);
    };

    const handleRefund = () => {
        refundOrder().then(handleClose)
    };
    const { t } = useTranslation();
    return (
        <Dialog
            open={open}
            aria-labelledby={t('refund_order')}
            aria-describedby={t('refund_order')}
        >
            <DialogTitle id="refund-order-title">{t('confirm_refund_order_title')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="refund-order-description">
                    {t('confirm_refund_order_desc')}
                    <br/>
                    <Typography variant="body1" color="textPrimary" component="span"> {t('are_you_sure')}</Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' color="primary" autoFocus onClick={handleClose} disabled={refunding}>
                    {t('back_btn')}
                </Button>
                <Button color="secondary" disabled={refunding} endIcon={<MoneyOffIcon/>} onClick={handleRefund}>
                    {t('refund_btn')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {
        refunding: state.order.orderRefunding
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        refundOrder: () => dispatch(refundOrder())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RefundOrderDialog);