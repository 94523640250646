import React, { useEffect, useState } from 'react';
import Dialog                         from '@material-ui/core/Dialog';
import DialogContent                  from '@material-ui/core/DialogContent';
import Grid                           from '@material-ui/core/Grid';
import TextField                      from '@material-ui/core/TextField';
import { useTranslation }             from 'react-i18next';
import DialogTitle                    from '@material-ui/core/DialogTitle';
import DialogActions                  from '@material-ui/core/DialogActions';
import { connect }                    from 'react-redux';
import { useHistory }                 from 'react-router-dom';
import { getStoreWarehouseEditRoute } from '../../../../router/routes/store/factory/store';
import Button                         from '../../inputs/Button';
import { createDeliverable }          from '../../../../store/actions/store/DeliverableAction';
import DeliverableStyleSelector       from '../../inputs/DeliverableStyleSelector';
import uuid                           from '../../../utils/uuid';
import FormControl                    from '@material-ui/core/FormControl';
import InputLabel                     from '@material-ui/core/InputLabel';
import Input                          from '@material-ui/core/Input';
import FormHelperText                 from '@material-ui/core/FormHelperText';
import Alert                          from '@material-ui/lab/Alert';
import ShippingFeeSelector            from '../../inputs/ShippingFeeSelector';
import FormRequiredField              from '../../feeback/FormRequiredField';

const CreateDialog = ({ realm, open, setOpen, redirect = true, catalog, creating, createDeliverable, onCreated = null }) => {
	const { t } = useTranslation();
	const [name, setName] = useState('');
	const [style, setStyle] = useState('');
	const [file, setFile] = useState(null);
	const [fileKey, setFileKey] = useState(uuid()); // used to reset the file input
	const [stock, setStock] = useState(0);
	const [fee, setFee] = useState(null)

	const history = useHistory();

	const [saveDisabled, setSaveDisabled] = useState(true);

	useEffect(() => {
		if (name.trim() === '' || !style) {
			setSaveDisabled(true);
			return;
		}

		if (style === 'files' && !file) {
			setSaveDisabled(true);
			return;
		}

		setSaveDisabled(false);
	}, [name, style, file, setSaveDisabled]);


	useEffect(() => {
		if (style === 'files') return;
		// If the style is not a file we reset the file input
		setFile(null); // Reset the file input
		setFileKey(uuid()); // Force the input to be redrawn by react (because for security reason file input content cannot be set)
	}, [style, setFile, setFileKey]);

	const handleClose = () => {
		setOpen(false);
	};

	const handleCreate = (e) => {
		e.preventDefault();
		createDeliverable(catalog.Catalog__, name, style, file, stock, fee ? fee.Catalog_Deliverable_Fee__ : null)
			.then((p) => {
				if (redirect) history.push(getStoreWarehouseEditRoute(p.Catalog_Deliverable__));
				else handleClose();
				if (onCreated) onCreated();
			});

	};

	const fileChangeHandler = (e) => {
		if (e.target.files.length < 1) {
			setFile(null);
		} else {
			setFile(e.target.files[0]);
		}
	};

	return (
		<Dialog
			open={open}
			aria-labelledby={t('create_deliverable')}
			aria-describedby={t('create_deliverable')}
		>
			<form onSubmit={handleCreate}>
				<DialogTitle
					id='create-product-title'>{t('create_deliverable')}</DialogTitle>
				<DialogContent>
					<Grid container spacing={2}>
						<FormRequiredField/>
						<Grid item xs={12}>
							<TextField label={t('deliverable_name')} fullWidth variant='outlined' value={name}
							           required
							           helperText={t('deliverable_name_helperText')}
							           disabled={creating}
							           onChange={e => setName(e.target.value)}/>
						</Grid>
						<Grid item xs={12}>
							<DeliverableStyleSelector
								value={style}
								setValue={setStyle}
								disabled={creating}
								required
							/>
						</Grid>

						<Grid item xs={12}>
							<ShippingFeeSelector
								value={fee}
								onChange={setFee}
								disabled={creating}
							/>
						</Grid>

						{style === 'files' &&
						<Grid item xs={12}><Alert severity='info'>{t('deliverable_create_file_info')}</Alert></Grid>}
						{style === 'shipping' && <Grid item xs={12}><Alert
							severity='info'>{t('deliverable_create_shipping_info')}</Alert></Grid>}

						{style === 'files' &&
						<Grid item xs={12}>
							<FormControl fullWidth required>
								<InputLabel shrink={true}
								            htmlFor='deliverable-file'>{t('deliverable_file')}</InputLabel>
								<Input key={fileKey}
								       disabled={creating}
								       onChange={fileChangeHandler}
								       id='deliverable-file'
								       type='file'/>
								<FormHelperText>{t('deliverable_file_helperText')}</FormHelperText>
							</FormControl>
						</Grid>}
						{style === 'shipping' &&
						<Grid item xs={12}>
							<TextField label={t('deliverable_stock')}
							           fullWidth
							           variant='outlined'
							           value={stock}
							           required
							           type='number'
							           inputProps={{ min: 0 }}
							           onChange={e => setStock(e.target.value)}/>
						</Grid>
						}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button color='default' onClick={handleClose} disabled={creating}>
						{t('close_btn')}
					</Button>
					<Button color='primary' type='submit' variant='contained'
					        disabled={creating || saveDisabled}
					        loading={creating}>
						{t('create_btn')}
					</Button>
				</DialogActions>
			</form>
		</Dialog>

	);
};

const mapStateToProps = (state) => {
	return {
		creating: state.deliverable.creating,
		catalog: state.store.catalog,
		realm: state.realm.selected,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		createDeliverable: (catalogId, name, style, file, stock, feeId = null) => dispatch(createDeliverable(catalogId, name, style, file, stock, feeId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDialog);
