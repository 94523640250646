import React, {useEffect, useState} from 'react';
import {connect}                    from 'react-redux';
import {useTranslation}             from 'react-i18next';
import Autocomplete                 from '@material-ui/lab/Autocomplete';
import TextField                    from '@material-ui/core/TextField';
import CircularProgress             from '@material-ui/core/CircularProgress';
import {
	fetchShippingFeeZones,
	setShippingFeeZoneFilters,
	setShippingFeeZonePaging
}                                   from '../../../store/actions/store/ShippingFeeAction';
import {DeleteForever, Edit}        from '@material-ui/icons';
import {IconButton}                 from '@material-ui/core';
import EditDialog                   from '../store/shipping/fee/zone/EditDialog';
import DeleteDialog                 from '../store/shipping/fee/zone/DeleteDialog';

const ShippingFeeZoneSelector = ({
	                                 feeId,
	                                 loading,
	                                 list,
	                                 fetch,
	                                 filters,
	                                 value,
	                                 onChange,
	                                 required = false,
	                                 disabled = false,
	                                 setFilters,
	                                 setPaging
                                 }) => {
	const {t} = useTranslation();

	const [showEdit, setShowEdit] = useState(false);
	const [showDelete, setShowDelete] = useState(false);

	useEffect(() => {
		setFilters({});
		setPaging({page_no: 1, results_per_page: 100});
	}, [setFilters, setPaging]);

	const onChangeHandler = (e) => {
		setFilters({Name: {'$prefix': e.target.value},});
	};

	useEffect(() => {
		fetch(feeId);
	}, [fetch, filters, feeId]);

	const onEdited = (edited) => {
		if (!value) return;
		if (value.Catalog_Deliverable_Fee_Zone__ !== edited.Catalog_Deliverable_Fee_Zone__) return;
		onChange(edited);
	};

	const onDeleted = (deleted) => {
		if (!value) return;
		if (value.Catalog_Deliverable_Fee_Zone__ !== deleted.Catalog_Deliverable_Fee_Zone__) return;
		onChange(null);
	};

	return (
		<>
			<Autocomplete
				id="shipping-fee-zone-select"
				disabled={disabled}
				value={value}
				onBlur={() => {
					setFilters({});
					fetch(feeId);
				}}
				onChange={(e, value) => onChange(value)}
				getOptionSelected={(option, value) => option.Catalog_Deliverable_Fee_Zone__ === value.Catalog_Deliverable_Fee_Zone__}
				getOptionLabel={(option) => option === '' ? '' : option.Name}
				options={list}
				loading={loading}
				renderInput={(params) => (
					<TextField
						{...params}
						required={required}
						label={t('deliverable_fee_zone_selector')}
						helperText={t('deliverable_fee_zone_selector_helperText')}
						variant="outlined"
						onChange={onChangeHandler}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loading ? <CircularProgress color="inherit" size={20}/> : null}
									{value && <IconButton
										onClick={() => setShowEdit(true)}
										size="small"
									>
										<Edit/>
									</IconButton>}
									{value && <IconButton
										onClick={() => setShowDelete(true)}
										size="small"
									>
										<DeleteForever/>
									</IconButton>}
									{params.InputProps.endAdornment}
								</React.Fragment>
							),
						}}
					/>
				)}
			/>
			{value && <EditDialog zone={value} open={showEdit} setOpen={setShowEdit} onEdited={onEdited}/>}
			{value && <DeleteDialog zone={value} open={showDelete} setOpen={setShowDelete} onDeleted={onDeleted}/>}
		</>
	);

};

const mapStateToProps = (state) => {
	return {
		loading: state.shippingFee.zonesLoading,
		list: state.shippingFee.zones,
		filters: state.shippingFee.zonesFilters,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: (feeId) => dispatch(fetchShippingFeeZones(feeId)),
		setFilters: (filters) => dispatch(setShippingFeeZoneFilters(filters)),
		setPaging: (paging) => dispatch(setShippingFeeZonePaging(paging))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ShippingFeeZoneSelector);
