import React, {useEffect, useState} from 'react';
import Grid                         from '@material-ui/core/Grid';
import AppBarTitle                  from '../../../components/common/typography/AppBarTitle';
import {useTranslation}             from 'react-i18next';
import {connect}                    from 'react-redux';
import SelectRealm                  from '../../SelectRealm';
import Table                        from '../../../components/common/store/warehouse/table/Table';
import Toolbar                      from '../../../components/common/typography/Toolbar';
import Redirect                     from '../../../components/Route/Redirect';
import {getPXLDashboardRoute}       from '../../../router/routes/pxl/factory/general';
import HasCapability                from '../../../components/Route/HasCapability';
import HasRight                     from '../../../components/Route/HasRight';
import Button                       from '../../../components/common/inputs/Button';
import AddIcon                      from '@material-ui/icons/Add';
import CreateDialog                 from '../../../components/common/store/warehouse/CreateDialog';
import {useLocation}                from 'react-router';
import {setDeliverablesFilters}     from '../../../store/actions/store/DeliverableAction';
import RichAlert                    from '../../../components/common/feeback/RichAlert';
import {Link}                       from 'react-router-dom';
import {getStoreWarehouseRoute}     from '../../../router/routes/store/factory/store';
import Warehouse                    from '../../../components/common/dashboard/Warehouse';
import Loading                      from '../../../components/common/feeback/loading/Loading';
import StoreActivator               from '../../../components/common/store/StoreActivator';


const List = ({catalog, selectedRealm, setDeliverablesFilters, filters}) => {
	const {t} = useTranslation();
	const [showCreate, setShowCreate] = useState(false);
	const location = useLocation();
	const oos = location.search.includes('oos');
	const [showTable, setShoTable] = useState(false);

	useEffect(() => {
		if (!selectedRealm || !catalog) return;
		let cpy = {...filters};
		if (!location.search.includes('oos')) {
			delete cpy.Stock;
			delete cpy.Style;
		} else {
			cpy.Stock = 0;
			cpy.Style = 'shipping';
		}
		setShoTable(true);
		setDeliverablesFilters(cpy);

		// eslint-disable-next-line
	}, [catalog, selectedRealm, location, setDeliverablesFilters]);

	if (!selectedRealm || !catalog) return <SelectRealm/>;


	return (
		<Grid container spacing={3}>
			<HasCapability store hideLoading deniedComponent={<Redirect target={getPXLDashboardRoute()}/>}>
				<AppBarTitle title={t('warehouse_title')}/>
				<StoreActivator/>
				<Grid item xs={12}>
					<Toolbar title={t('warehouse_title')}>
						<HasRight manager>
							<Button endIcon={<AddIcon/>} color="primary" variant="contained"
							        onClick={() => setShowCreate(true)}>
								{t('create_btn')}
							</Button>
						</HasRight>
					</Toolbar>
				</Grid>

				{!oos && <Grid item xs={12}><Warehouse/></Grid>}

				{oos && <Grid item xs={12}><RichAlert
					severity="info"
					action={
						<Button color="primary" size="small" variant="contained" component={Link}
						        to={`${getStoreWarehouseRoute()}`}>
							{t('view_all_btn')}
						</Button>
					}
				>
					{t('warehouse_oos_only_info')}
				</RichAlert></Grid>}

				<Grid item xs={12}>
					{showTable && <Table catalog={catalog}/>}
					{!showTable && <Loading/>}
				</Grid>
			</HasCapability>
			<CreateDialog open={showCreate} setOpen={setShowCreate}/>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		catalog: state.store.catalog,
		filters: state.deliverable.deliverablesFilters,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setDeliverablesFilters: (newFilters) => dispatch(setDeliverablesFilters(newFilters))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
