import React, {useState} from 'react';
import {
	hideMenu,
	setMenu
}                        from '../../../../store/actions/CommonAction';
import {connect}        from 'react-redux';
import makeStyles       from '@material-ui/core/styles/makeStyles';
import {
	Box,
	Grid
}                       from '@material-ui/core';
import RealmSelector    from '../../../common/realm/RealmSelector';
import {
	MENU_ARTIST,
	MENU_FANCLUB,
	MENU_MOBILE,
	MENU_MUSIC,
	MENU_PLANNER,
	MENU_STORE,
	MENU_SUPPORT,
	MENU_WEBSITE
}                  from '../../../../store/reducers/CommonReducer';
import RootMenu    from './RootMenu';
import MenuWebsite from '../Menu/MenuWebsite';
import MenuArtist        from '../Menu/MenuArtist';
import MenuMusic         from '../Menu/MenuMusic';
import MenuPlanner       from '../Menu/MenuPlanner';
import MenuFanclub       from '../Menu/MenuFanclub';
import MenuStore         from '../Menu/MenuStore';
import MenuSupport       from '../Menu/MenuSupport';
import {useTranslation}  from 'react-i18next';
import Button            from '../../../common/inputs/Button';
import {ArrowBack, Send} from '@material-ui/icons';
import ShareDialog       from '../../appBar/ShareDialog';

const useStyles = makeStyles(theme => ({
	container: {
		height: '100%',
		width: '100%',
		top: '0px',
		position: 'fixed',
		left: '0px',
		background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%), #121212;',
		overflow:'auto',
	},
}));

const MobileMenu = ({menu, hideMenu, setMenu, registryUrl}) => {
	const classes = useStyles();
	const {t} = useTranslation();
	const [showShare, setShowShare] = useState(false);

	if (!menu) {
		document.body.style.overflowY = 'auto';
		return <></>;
	}

	document.body.style.overflowY = 'hidden';

	const getMenuContent = () => {
		switch (menu) {
			case MENU_MOBILE:
				return <RootMenu/>;
			case MENU_WEBSITE:
				return <MenuWebsite/>;
			case MENU_ARTIST:
				return <MenuArtist/>;
			case MENU_MUSIC:
				return <MenuMusic/>;
			case MENU_PLANNER:
				return <MenuPlanner/>;
			case MENU_FANCLUB:
				return <MenuFanclub/>;
			case MENU_STORE:
				return <MenuStore/>;
			case MENU_SUPPORT:
				return <MenuSupport/>;
			default:
				return <></>;

		}
	};

	const handleBack = () => {
		if (menu === MENU_MOBILE) {
			hideMenu();
		} else {
			setMenu(MENU_MOBILE);
		}
	};

	return (
		<Box p={3} className={classes.container}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Grid container spacing={3} justify={'space-between'}>
						<Grid item>
							<Button variant="text" startIcon={<ArrowBack/>} onClick={handleBack}>
								{t('back_btn')}
							</Button>
						</Grid>
						<Grid item>
							{registryUrl && <Button color='primary' onClick={() => setShowShare(true)} startIcon={<Send/>} variant='contained'>
								{t('share_title')}
							</Button>}
						</Grid>
					</Grid>

				</Grid>
				<Grid item xs={12}>
					<RealmSelector fullwidth={true}/>
				</Grid>
				<Grid item xs={12}>
					{getMenuContent()}
				</Grid>
			</Grid>
			<ShareDialog setOpen={(value) => {
				setShowShare(value)
				setTimeout(() => { // dirty hack
					document.body.style.overflowY = 'hidden';
				}, 300);
			}} open={showShare}/>
		</Box>
	);
};

const mapStateToProps = (state) => {
	return {
		menu: state.common.menu,
		registryUrl: state.registry.url,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setMenu: (menu) => dispatch(setMenu(menu)),
		hideMenu: () => dispatch(hideMenu())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);