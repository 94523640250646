import React from 'react';
import TableRow from "@material-ui/core/TableRow";
import TableCell                from "@material-ui/core/TableCell";
import {useTranslation}         from "react-i18next";
import Audio                       from "../../../data/player/Audio";
import {formatDuration}            from "../../../../utils/time";
import * as moment                 from "moment";
import Grid                        from "@material-ui/core/Grid";
import ExpandLessIcon              from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon              from '@material-ui/icons/ExpandMore';
import IconButton                  from "@material-ui/core/IconButton";
import Video                       from "../../../data/player/Video";
import { getMusicRecordEditRoute } from '../../../../../router/routes/music/factory/records';
import Link from "../../../typography/Link";
import Button from "../../../inputs/Button";

const Row = ({music, count, first, last, onDelete, changeOrder, disabled}) => {
    const {t} = useTranslation();

    return (
        <TableRow>
            <TableCell>
	            <Link to={getMusicRecordEditRoute(music.Music_Label_Record.Music_Label_Record__)}>
                    {music.Music_Label_Record.Name}
	            </Link>
            </TableCell>
            <TableCell>
                {music.Music_Label_Record.Type === 'video' &&  <Video width={300} source={music.Music_Label_Record.Preview.mp4}/>}
                {music.Music_Label_Record.Type === 'audio' &&   <Audio mp3={music.Music_Label_Record.Preview.mp3}/>}
            </TableCell>
            <TableCell>
                {formatDuration(moment.duration(parseInt(music.Music_Label_Record.Duration), 'seconds'))}
            </TableCell>
            {!disabled && <TableCell align="right">
                <Grid container alignItems='center' justify='space-between'>
                    <Grid item>
                        <Button variant='text' color='secondary' onClick={e => onDelete(music)}>
                            {t('delete_btn')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Grid container direction='column'>
                            {!first && <Grid item>
                                <IconButton onClick={e => changeOrder(music, -1)}>
                                    <ExpandLessIcon/>
                                </IconButton>
                            </Grid>}
                            {(count > 0 && !last) && <Grid item>
                                <IconButton onClick={e => changeOrder(music, 1)}>
                                    <ExpandMoreIcon/>
                                </IconButton>
                            </Grid>}
                        </Grid>
                    </Grid>
                </Grid>
            </TableCell>}
        </TableRow>
    );
};

export default Row;
