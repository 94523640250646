import React                     from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Dialog                    from '@material-ui/core/Dialog';
import DialogTitle               from '@material-ui/core/DialogTitle';
import DialogContent             from '@material-ui/core/DialogContent';
import DialogContentText         from '@material-ui/core/DialogContentText';
import Typography                from '@material-ui/core/Typography';
import DialogActions             from '@material-ui/core/DialogActions';
import PlayCircleOutlineIcon     from '@material-ui/icons/PlayCircleOutline';
import { connect }               from 'react-redux';
import { startStream }           from '../../../../../store/actions/planner/StreamAction';
import Button from "../../../inputs/Button";

const OpenStreamDialog = ({ event, open, setOpen, startStream, starting }) => {
	const { t } = useTranslation();

	const handleClose = () => {
		setOpen(false);
	};

	const handleStart = () => {
		startStream(event.Planner_Event__)
			.finally(() => handleClose());
	};

	return (
		<Dialog
			open={open}
			aria-labelledby={t('stream_open_title')}
			aria-describedby={t('stream_open_title')}
			fullWidth
		>
			<DialogTitle id='stream-open-title'>{t('stream_open_title')}</DialogTitle>
			<DialogContent>
				<DialogContentText id='stream-open-description'>
					<Trans i18nKey='stream_open_desc'>
						sample<br/>
						<strong>sample</strong>
					</Trans>
					<br/>
					<Typography variant='body1' color='textPrimary' component='span'>{t('are_you_sure')}</Typography>
				</DialogContentText>
			</DialogContent>

			<DialogActions>
				<Button color='primary' autoFocus onClick={handleClose} disabled={starting}>
					{t('back_btn')}
				</Button>
				<Button
					variant={event.Stream_Status === 'started' ? 'outlined' : 'contained'}
					color='primary'
					disabled={starting}
					loading={starting}
					endIcon={<PlayCircleOutlineIcon/>}
					onClick={handleStart}
				>
					{event.Stream_Status === 'started' ? t('reopen_stream_btn') : t('open_stream_btn')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const mapStateToProps = (state) => {
	return {
		starting: state.stream.starting
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		startStream: (eventId) => dispatch(startStream(eventId))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenStreamDialog);
