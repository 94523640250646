import React, {useEffect, useState} from 'react';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {Select} from "@material-ui/core";
import {useTranslation} from 'react-i18next';
import FormHelperText from "@material-ui/core/FormHelperText";

const CollaboratorRoles = ({onChange, inputProps = {}, value = undefined, disabledOptions = []}) => {
    const selectLabel = React.useRef(null);
    const [selectLabelWidth, setLabelWidth] = useState(0);
    const {t} = useTranslation();

    const isOptionDisabled = (option) => {
        return disabledOptions.includes(option)
    };

    useEffect(() => {
        setLabelWidth(selectLabel.current.offsetWidth);
    }, []);

    return (
        <FormControl {...inputProps}>
            <InputLabel ref={selectLabel} htmlFor='role-select'>{t('role_label')}</InputLabel>
            <Select
                native
                value={value}
                onChange={onChange}
                labelWidth={selectLabelWidth}
                inputProps={{
                    id: 'role-select',
                }}
            >
                <option/>
                <option disabled={isOptionDisabled('artist')} value='artist'>{t('role_artist')}</option>
                <option disabled={isOptionDisabled('featuring')} value='featuring'>{t('role_featuring')}</option>
                <option disabled={isOptionDisabled('lyricist')} value='lyricist'>{t('role_lyricist')}</option>
                <option disabled={isOptionDisabled('composer')} value='composer'>{t('role_composer')}</option>
                <option disabled={isOptionDisabled('producer')} value='producer'>{t('role_producer')}</option>
                <option disabled={isOptionDisabled('remixer')} value='remixer'>{t('role_remixer')}</option>
            </Select>
            {disabledOptions.length>0 && <FormHelperText>
                {t('role_disabled_helperText')}
            </FormHelperText>}
        </FormControl>
    );
};

export default CollaboratorRoles;
