import React from 'react';
import Select from "./Select";
import {useTranslation} from "react-i18next";

const CouponTargetSelector = ({value, setValue, disabled, required}) => {
    const {t} = useTranslation();

    return <Select
        label={t('coupon_target')}
        value={value}
        onChange={e => setValue(e.target.value)}
        optionKey='Key'
        optionValue='Value'
        allowEmpty={false}
        options={[
            {Key: 'product', Value: t('coupon_target_product')},
            {Key: 'global', Value: t('coupon_target_global')},
        ]}
        inputProps={{
            disabled: disabled,
            variant: 'outlined',
            fullWidth: true,
            required: required
        }}
    />;
};

export default CouponTargetSelector;