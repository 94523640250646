import React, { useEffect } from 'react';
import Grid                 from '@material-ui/core/Grid';
import { useTranslation }   from 'react-i18next';
import Loading              from '../../common/feeback/loading/Loading';
import Typography           from '@material-ui/core/Typography';
import { connect }          from 'react-redux';
import graphic              from '../../../resources/images/store-wizard/intro.svg';
import { createStore }      from '../../../store/actions/store/StoreAction';

const StepCreation = ({ stripeAccount, createStore, realm, address, hasLocation }) => {
	const { t } = useTranslation();

	useEffect(() => {
		const addressData = !hasLocation ? { ...address } : null;
		if (!hasLocation) addressData['Country__'] = address.Country.Country__;
		createStore(realm.Realm__, addressData, stripeAccount ? stripeAccount.OAuth2_Consumer_Token__ : null);
		// eslint-disable-next-line
	}, [stripeAccount, createStore, realm, address]);

	return (
		<Grid container spacing={3} direction='row'
		      justify='center'>


			<Grid item xs={4} md={4}>
				<img src={graphic} alt='store' width='100%'/>
			</Grid>

			<Grid item xs={12} md={8}>
				<Grid container spacing={3} direction='column' justify='center'>

					<Grid item xs={12}>
						<Typography variant='h5' color='textPrimary' component='h5'>
							{t('store_wizard_creating_title')}
						</Typography>
					</Grid>

					<Grid item xs={12}>
						<Loading/>
					</Grid>

				</Grid>
			</Grid>

		</Grid>);
};

const mapStateToProps = (state) => {
	return {
		realm: state.realm.selected
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		createStore: (realmId, address, stripeToken) => dispatch(createStore(realmId, address, stripeToken)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(StepCreation);
