import DiscographyList   from '../../../pages/music/discography/List';
import DiscographyEdit   from '../../../pages/music/discography/Edit';
import DiscographyReview from '../../../pages/music/discography/Review';
import {
	getMusicDiscographyEditRoute,
	getMusicDiscographyReviewRoute,
	getMusicDiscographyRoute
}                        from './factory/discography';

export default [
	{
		path: getMusicDiscographyReviewRoute(),
		component: DiscographyReview,
		exact: true,
		type: 'route',
		loginRequired: true,
	},
	{
		path: getMusicDiscographyEditRoute(),
		component: DiscographyEdit,
		exact: true,
		type: 'route',
		loginRequired: true,
	},
	{
		path: getMusicDiscographyRoute(),
		component: DiscographyList,
		exact: true,
		type: 'route',
		loginRequired: true,
	},
];
