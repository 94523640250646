import React, { Fragment, useEffect }                        from 'react';
import TableHead                                             from '@material-ui/core/TableHead';
import MaterialTable                                         from '@material-ui/core/Table';
import TableRow                                              from '@material-ui/core/TableRow';
import TableCell                                             from '@material-ui/core/TableCell';
import TableBody                                             from '@material-ui/core/TableBody';
import TableContainer                                        from '@material-ui/core/TableContainer';
import Row                                                   from './Row';
import { connect }                                           from 'react-redux';
import TableFooter                                           from '@material-ui/core/TableFooter';
import { fetchEntries, setEntriesFilters, setEntriesPaging } from '../../../../store/actions/CmsAction';
import { useTranslation }                                    from 'react-i18next';
import Pagination                                            from '../../data/table/Pagination';

const Table = ({ noEntriesComponent, entries, fetch, cmsId, setEntriesFilters, setEntriesPaging, loading, filters, paging, count, disabled = false }) => {

	const loadingData = [...Array(paging.results_per_page).keys()];

	const displayLoading = () => loadingData.map((k) => <Row key={k} entry={null}/>);
	const displayEntries = () => entries.map((e, idx) => <Row key={e.Content_Cms_Entry__} entry={e}/>);
	const { t }          = useTranslation();

	useEffect(() => {
		if (!disabled && cmsId) fetch(cmsId);
	}, [disabled, fetch, filters, paging, cmsId]);

	/*const {sort} = filters;

	 const sortHandler = (property, dir) => {
		 let p = {...filters};
		 // only 1 sort
		 p.sort = {};
		 p.sort[property] = dir;
		 setEntriesFilters(p);
	 };*/

	return (
		<Fragment>
			{(loading || entries.length > 0 || !noEntriesComponent) && <TableContainer>
				<MaterialTable aria-label='simple table'>
					<TableHead>
						<TableRow>
							<Pagination
								setPaging={setEntriesPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
						<TableRow>
							<TableCell>{t('title')}</TableCell>
							<TableCell>{t('categories')}</TableCell>
							<TableCell align='right'>{t('language')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(loading || disabled) && displayLoading()}
						{(!loading && !disabled) && displayEntries()}
					</TableBody>
					<TableFooter>
						<TableRow>
							<Pagination
								setPaging={setEntriesPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
					</TableFooter>

				</MaterialTable>
			</TableContainer>
			}
			{(noEntriesComponent && !loading && entries.length < 1) && noEntriesComponent}
		</Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		filters: state.cms.entriesFilters,
		paging:  state.cms.entriesPaging,
		count:   state.cms.entriesPagingCount,
		loading: state.cms.entriesLoading,
		entries: state.cms.entries,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch:             (cmsId) => dispatch(fetchEntries(cmsId)),
		setEntriesFilters: (filters) => dispatch(setEntriesFilters(filters)),
		setEntriesPaging:  (paging) => dispatch(setEntriesPaging(paging))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);

