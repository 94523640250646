import React from 'react';
import {useTranslation} from "react-i18next";
import GraphicInbox from "../../../../../resources/images/inbox.svg";
import GraphicOutbox from "../../../../../resources/images/outbox.svg";
import GraphicClosedbox from "../../../../../resources/images/closedBox.svg";
import EmptyState from "../../../feeback/State/Empty";

const Empty = ({box}) => {

    const {t} = useTranslation();

    // Not proud of this, don"t know how do dynamically import the right image based on the string in the var  box
    const getGraphic = () => {
        switch (box) {
            case 'inbox' :
                return GraphicInbox;
            case 'outbox' :
                return GraphicOutbox;
            case 'closed' :
                return GraphicClosedbox;
            default:
                return null
        }
    };

    return (
        <EmptyState
            title={t('site_support_' + box + '_empty_title')}
            textI18Key={'site_support_' + box + '_empty_text'}
            graphic={getGraphic()}
        />
    );

};

export default Empty;