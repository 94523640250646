import React      from 'react';
import { Helmet } from 'react-helmet';
import Hidden     from '@material-ui/core/Hidden';
import Grid       from '@material-ui/core/Grid';
import Box        from '@material-ui/core/Box';
import Graphic    from '../../resources/images/PXL_logo_nouhin_white.png';
import makeStyles from '@material-ui/core/styles/makeStyles';

// Dirty stuff because i don't know anything in css and it pisses me off
const useStyles = makeStyles((theme) => {
	return {
		fillHeight: {
			height: '100vh',
		},
		bg: {
			backgroundColor: theme.palette.primary.main, /* For browsers that do not support gradients */
			backgroundImage: theme.palette.primary.gradient,
		},
		container: {
			width: '100%',
			[theme.breakpoints.down('sm')]: {
				paddingRight: '12px',
				paddingLeft: '12px',
				margin: '0px'
			}
		},

	};
});

const LayoutLess = ({ title, children }) => {
	const classes = useStyles();

	return (
		<Grid container justify='center' alignItems='center'
		      className={classes.fillHeight + ' ' + classes.container}>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			<Hidden smDown>
				<Grid item md={7} className={classes.fillHeight}>
					<Box className={`${classes.fillHeight} ${classes.bg}`}>
						<Grid container
						      direction='row'
						      justify='center'
						      alignItems='center' className={classes.fillHeight}>
							<Grid item xs={6}>
								<img src={Graphic} width='100%' alt='PXL'/>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Hidden>
			<Grid item xs={12} md={5}>
				{children}
			</Grid>
		</Grid>
	);
};

export default LayoutLess;
