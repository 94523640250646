import React, {useEffect, useState} from 'react';
import {withRouter}                 from 'react-router';
import {connect}                    from 'react-redux';
import {fetchOrder}                 from '../../store/actions/OrderAction';
import Grid                         from '@material-ui/core/Grid';
import {Title}                      from '../../components/common/typography/Title';
import Skeleton                     from '@material-ui/lab/Skeleton';
import DisplayOrderTable            from '../../components/common/order/order/Order';
import Detail                       from '../../components/common/order/order/Details';
import Address                      from '../../components/common/order/order/Address';
import MoneyOffIcon                 from '@material-ui/icons/MoneyOff';
import RefundOrderDialog            from '../../components/common/order/order/RefundOrderDialog';
import AppBarTitle                  from '../../components/common/typography/AppBarTitle';
import {useTranslation}             from 'react-i18next';
import HasRight                     from '../../components/Route/HasRight';
import Redirect                     from '../../components/Route/Redirect';
import {getPXLDashboardRoute}       from '../../router/routes/pxl/factory/general';
import SearchIcon                   from '@material-ui/icons/Search';
import {Link}                       from 'react-router-dom';
import {getSiteUserEditRoute}       from '../../router/routes/site/factory/user';
import Button                       from '../../components/common/inputs/Button';


const Order = ({fetch, match, loading, order, isAdmin}) => {
	const [showRefundDialog, setShowRefundDialog] = useState(false);
	const {t} = useTranslation();

	useEffect(() => {
		fetch(match.params.order);
	}, [fetch, match]);

	return (
		<HasRight support deniedComponent={<Redirect target={getPXLDashboardRoute()}/>}>
			<AppBarTitle title={'Order ' + (order ? '#' + order.Invoice_Number : '')}/>
			<Grid container spacing={3} justify="space-evenly">
				{/* Title */}
				<Grid item xs={12}>
					{!loading && <Title>{t('order_number', {orderID: order.Invoice_Number})} </Title>}
					{loading && <Skeleton width={100}/>}
				</Grid>

				<Grid item lg={3} md={6} xs={12}>
					<Detail order={order}/>
				</Grid>

				<Grid item lg={3} md={6} xs={12}>
					<Address address={loading ? null : order.Billing_User_Location}/>
				</Grid>

				<Grid item lg={3} md={6} xs={12}>
					<Address address={loading ? null : order.Shipping_User_Location}/>
				</Grid>

				<Grid item xs={12}>
					<DisplayOrderTable order={order}/>
				</Grid>
				<Grid item xs={12}>
					<Grid container justify="flex-end" spacing={3}>
						{
							(!loading && order.Refund_Available && isAdmin) &&
							<Grid item>
								<Button variant="outlined" color="secondary" endIcon={<MoneyOffIcon/>}
								        onClick={() => setShowRefundDialog(true)}>
									{t('refund_btn')}
								</Button>
							</Grid>
						}
						{!loading &&
						<Grid item>
							<Button
								component={Link}
								variant="contained"
								color="primary"
								endIcon={<SearchIcon/>}
								to={getSiteUserEditRoute(order.User__)}
							>
								{t('view_user_btn')}
							</Button>
						</Grid>}
					</Grid>
				</Grid>
			</Grid>
			<RefundOrderDialog open={showRefundDialog} setOpen={setShowRefundDialog} order={order}/>
		</HasRight>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.order.orderLoading,
		order: state.order.order,
		isAdmin: state.access.admin
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: (id) => dispatch(fetchOrder(id))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Order));
