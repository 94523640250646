import Subscribers from "../../../pages/fanclub/subscriber/Subscribers";
import SubscriberEdit from "../../../pages/fanclub/subscriber/SubscriberEdit";
import {getFanclubSubscriberEditRoute, getFanclubSubscribersRoute} from "./factory/subscriber";

export default [
    {
        path: getFanclubSubscribersRoute(),
        component: Subscribers,
        exact: true,
        type: 'route',
        loginRequired: true,
    },
    {
        path: getFanclubSubscriberEditRoute(),
        component: SubscriberEdit,
        exact: false,
        type: 'route',
        loginRequired: true,
    },
]