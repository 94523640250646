import React, { Fragment, useEffect, useState } from 'react';
import Grid                                     from '@material-ui/core/Grid';
import Alert                                    from '@material-ui/lab/Alert';
import { Trans, useTranslation }                from 'react-i18next';
import Link                                     from '../typography/Link';
import { getPXLContactRoute }                   from '../../../router/routes/pxl/factory/general';
import { Toolbar }                   from '@material-ui/core';
import { Title }                     from '../typography/Title';
import TemplateSelector              from '../inputs/TemplateSelector';
import Button                        from '../inputs/Button';
import DeleteForeverIcon             from '@material-ui/icons/DeleteForever';
import DeleteDialog                  from '../realm/DeleteDialog';
import { rebuildCache }              from '../../../store/actions/store/StoreAction';
import { updateTemplateAndSettings } from '../../../store/actions/TemplateAction';
import { connect }                   from 'react-redux';
import { isObject }                  from '../../utils/misc';
import makeStyles                    from '@material-ui/core/styles/makeStyles';
import TemplateSettings              from '../inputs/TemplateSettings';
import TemplateGallery               from '../feeback/TemplateGallery';

// dirty
const useStyles = makeStyles(() => {
	return {
		fixAlert: {
			'& > .MuiAlert-message': {
				display: 'block',
			}
		}
	};
});

const TemplateSetup = ({
	                          selectedRealm,
	                          url,
	                          originalTemplate,
	                          originalSettings,
	                          updatingTemplate,
	                          updateTemplateAndSettings,
	                          isCustomTemplate,
	                          store,
	                          fanclub,
	                          rebuildStoreCache,
	                          isOwner
                          }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const [template, setTemplate] = useState(originalTemplate);
	const [settings, setSettings] = useState({});
	const [settingFiles, setSettingFiles] = useState({});
	const [isSettingsValid, setIsSettingsValid] = useState(true);
	const [saveEnabled, setSaveEnabled] = useState(false);

	const [showDelete, setShowDelete] = useState(false);

	useEffect(() => {
		if (!selectedRealm) return; // Because we have the delete artist function that will reset everything on this page
		setTemplate(originalTemplate);
		let data = {};
		let dataFiles = {};

		setIsSettingsValid(true);

		if (!originalSettings.Data) return;

		Object.keys(originalSettings.Data).forEach(key => {
			if (originalSettings.Data[key] instanceof Object && 'Drive_Item__' in originalSettings.Data[key]) {
				dataFiles[key] = originalSettings.Data[key];
			} else {
				data[key] = originalSettings.Data[key];
			}
		});

		setSettings(data);
		setSettingFiles(dataFiles);

		// We assume the backend send us valid settings
		// eslint-disable-next-line
	}, [originalTemplate, originalSettings]);

	// Detect changes
	useEffect(() => {
		if (!selectedRealm || !template || !originalTemplate) return; // Because we have the delete artist function that will reset everything on this page

		if (!template) {  // Because we have the delete artist function that will reset everything on this page
			setSaveEnabled(false);
			return;
		}

		if (template.Site_Template__ !== originalTemplate.Site_Template__) {
			setSaveEnabled(true);
			return;
		}

		const settingKeys = Object.keys(settings ?? {});
		const settingFilesKeys = Object.keys(settingFiles ?? {});

		// Check if the settings are the same length, if not a modification as occurred
		if ((settingKeys.length + settingFilesKeys.length) !== Object.keys(originalSettings.Data ?? {}).length) {
			setSaveEnabled(isSettingsValid);
			return;
		}

		// Check for the settings (not the files)
		// We know there is the same number of key in each settings
		for (let i = 0; i < settingKeys.length; i++) {
			// We the setting key is not present in the original it means a modification as occurred
			if (!([settingKeys[i]] in (originalSettings.Data ?? {}))) {
				setSaveEnabled(isSettingsValid);
				return;
			}

			// if the setting value is not the same as in the original it means a modification as occurred
			if (settings[settingKeys[i]] !== originalSettings.Data[settingKeys[i]]) {
				setSaveEnabled(isSettingsValid);
				return;
			}
		}

		// Check for the settings files
		for (let i = 0; i < settingFilesKeys.length; i++) {

			if (!([settingFilesKeys[i]] in (originalSettings.Data ?? {}))) {
				setSaveEnabled(isSettingsValid);
				return;
			}

			// If a modification occurred, the file will be a js File object and the var Drive_Item__ will no be present anymore
			if (!('Drive_Item__' in settingFiles[settingFilesKeys[i]])) {
				setSaveEnabled(isSettingsValid);
				return;
			}
		}

		setSaveEnabled(false);
		// eslint-disable-next-line
	}, [originalTemplate, originalSettings, template, settings, settingFiles, setSaveEnabled, isSettingsValid]);

	const saveHandler = () => {
		const cpy = settings;
		if (!fanclub) cpy['fanclub_visible'] = 'N';
		if (!store) cpy['shop_visible'] = 'N';
		// Special cases
		if ('location' in cpy && isObject(cpy.location))
			cpy.location = cpy.location.User_Location__;
		else if('location' in (originalSettings.Data) && isObject(cpy.originalSettings)) {
			if(originalSettings.Data.location.User_Location__)
				cpy.location = originalSettings.Data.location.User_Location__;
		}
		
		updateTemplateAndSettings(template.Site_Template__, cpy, settingFiles, url.Registry_Url__)
			.then(() => {
				// If the language as changed we need to regen the product list cache
				if (!store) return;
				let changed = false;
				const newLang = (cpy?.Languages ?? []);//
				const curLang = (originalSettings?.Data?.Languages ?? []);
				if (curLang.length !== newLang.length) changed = true;
				else {
					const indexed = newLang.reduce((a, v) => ({ ...a, [v]: v }), {});
					curLang.forEach(l => {
						if (!(l in indexed)) changed = true;
					});
				}

				if (!changed) return;
				// Let's update the store cache, we do that silently we do not need any progress bar etc
				rebuildStoreCache();
			});
	};

	return (
		<>
			{isCustomTemplate &&
			<Grid item xs={12}>
				<Alert severity='info' className={classes.fixAlert}>
					<Trans i18nKey='settings_template_custom_alert'>
						sample<br/>
						<strong>sample</strong>
						<Link to={getPXLContactRoute()}>sample</Link>
					</Trans>
				</Alert>
			</Grid>}

			{(template) &&
			<Fragment>
				{!isCustomTemplate && <Grid item xs={12}>
					<Toolbar>
						<Title>{t('settings_template')}</Title>
					</Toolbar>
				</Grid>}

				{!isCustomTemplate && <Grid item xs={12}>
					<Alert severity='info' className={classes.fixAlert}>
						<Trans i18nKey='settings_template_alert'>
							sample<br/>
							<strong>sample</strong>
							<Link to={getPXLContactRoute()}>sample</Link>
						</Trans>
					</Alert>
				</Grid>}

				{!isCustomTemplate && <Grid item xs={6}>
					<TemplateSelector
						matchCapabilities={true}
						allowEmpty={false}
						value={template}
						onChange={setTemplate}
						inputProps={{
							variant: 'outlined',
							fullWidth: true,
							required: true,
							disabled: updatingTemplate
						}}
					/>
				</Grid>}

				{(!isCustomTemplate && template) && <Grid item xs={6}>
					<TemplateGallery template={template}/>
				</Grid>
					}

				{template.Settings.length > 0 &&
				<Fragment>
					<Grid item xs={12}>
						<Toolbar>
							<Title>{t('settings_template_settings')}</Title>
						</Toolbar>
					</Grid>

					<Grid item xs={12}>
						<TemplateSettings
							showStore={!!store}
							showFanclub={!!fanclub}
							showReleases={true}
							template={template}
							setSettings={setSettings}
							settings={settings}
							files={settingFiles}
							originalFiles={originalSettings.Data ?? {}}
							setFiles={setSettingFiles}
							setIsValid={setIsSettingsValid}
							disabled={updatingTemplate}
						/>
					</Grid>
					<Grid item xs={12}>
						<Grid container justify={isOwner ? 'space-between' : 'flex-end'}>
							{isOwner && <Button
								variant='contained'
								color='secondary'
								disabled={updatingTemplate}
								onClick={e => setShowDelete(true)}
								endIcon={<DeleteForeverIcon/>}
							>
								{t('delete_artist_btn')}
							</Button>}

							<Button variant='contained' color='primary' onClick={saveHandler}
							        disabled={!saveEnabled || updatingTemplate}
							        loading={updatingTemplate}>
								{t('save_btn')}
							</Button>
						</Grid>
					</Grid>
				</Fragment>
				}
				{(template.Settings.length < 1 && isOwner) &&
				<Grid item xs={12}>
					<Grid container justify='flex-start'>
						<Button
							variant='contained'
							color='secondary'
							disabled={updatingTemplate}
							onClick={e => setShowDelete(true)}
							endIcon={<DeleteForeverIcon/>}
						>
							{t('delete_artist_btn')}
						</Button>
					</Grid>
				</Grid>
				}
			</Fragment>
			}
			{isOwner && <DeleteDialog open={showDelete} setOpen={setShowDelete}/>}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		url: state.registry.url,
		originalTemplate: state.template.selectedTemplate,
		originalSettings: state.template.templateSettings,
		isCustomTemplate: state.template.isCustomTemplate,
		updatingTemplate: state.template.updatingTemplate,

		store: state.store.catalog,
		fanclub: state.membership.selectedFanclub,

		isOwner: state.access.owner
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		rebuildStoreCache: () => dispatch(rebuildCache()),
		updateTemplateAndSettings: (templateId, settings, files, registryIrlId) => dispatch(updateTemplateAndSettings(templateId, settings, files, registryIrlId))
	};
};


export default connect(mapStateToProps, mapDispatchToProps)(TemplateSetup);
