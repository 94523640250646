import React, {useEffect, useState} from 'react';
import Grid                         from '@material-ui/core/Grid';
import {Title}                      from '../../../typography/Title';
import InputLabel                   from '@material-ui/core/InputLabel';
import FormHelperText               from '@material-ui/core/FormHelperText';
import Select                       from '@material-ui/core/Select';
import FormControl                  from '@material-ui/core/FormControl';
import TextField                    from '@material-ui/core/TextField';
import {connect}                    from 'react-redux';
import {updatePlan}                 from '../../../../../store/actions/fanclub/PlanAction';
import {useTranslation}             from 'react-i18next';
import Button                       from '../../../inputs/Button';
import ColorPicker                  from '../../../inputs/ColorPicker/ColorPicker';

const Settings = ({plan, updatePlan, updating}) => {
	const visibleLabel = React.useRef(null);
	const [visibleLabelWidth, setVisibleLabelWidth] = useState(0);
	const {t} = useTranslation();
	useEffect(() => {
		setVisibleLabelWidth(visibleLabel.current.offsetWidth);
	}, []);

	const [visible, setVisible] = useState('');
	const [priority, setPriority] = useState(0);
	const [color, setColor] = useState('');

	const [saveEnabled, setSaveEnabled] = useState(false);

	useEffect(() => {
		if (!plan) return;

		setVisible(plan.Visible);
		setPriority(plan.Priority);
		setColor(plan.Color ? '#' + plan.Color : '');
	}, [plan]);

	useEffect(() => {
		if (!plan) return;
		if (priority !== plan.Priority) {
			setSaveEnabled(true);
			return;
		}

		if (color !== '#' + plan.Color) {
			setSaveEnabled(true);
			return;
		}

		if (visible !== plan.Visible) {
			setSaveEnabled(true);
			return;
		}

		setSaveEnabled(false);
	}, [visible, priority, plan, color]);

	const visibilityChangeHandler = e => {
		setVisible(e.target.value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		updatePlan(plan, {
			Visible: visible,
			Priority: priority,
			Color: color
		});
	};

	return (
		<form onSubmit={handleSubmit}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Title>{t('plan_settings_title')}</Title>
				</Grid>


				<Grid item lg={6} xs={12}>

					<FormControl variant="outlined" fullWidth disabled={updating}>
						<InputLabel ref={visibleLabel} htmlFor="visibility-select">{t('visibility_title')}</InputLabel>
						<Select
							native
							value={visible}
							onChange={visibilityChangeHandler}
							labelWidth={visibleLabelWidth}
							inputProps={{
								id: 'visibility-select',
							}}
						>
							<option value="Y">{t('yes_opt')}</option>
							<option value="N">{t('no_opt')}</option>
						</Select>
						<FormHelperText>{t('changing_visibility_helper')}</FormHelperText>
					</FormControl>

				</Grid>
				<Grid item lg={6} xs={12}>
					<TextField label={t('priority_lbl')} fullWidth variant="outlined" type="number" value={priority}
					           disabled={updating}
					           onChange={e => setPriority(e.target.value)}
					           helperText={t('priority_helper')}/>

				</Grid>
				<Grid item lg={6} xs={12}>
					<ColorPicker
                        label={t('plan_color_lbl')}
                        helperText={t('plan_color_helperText')}
						value={color}
						setValue={setColor}
                        fullWidth
                        variant='outlined'
                        disabled={updating}
					/>
				</Grid>
				<Grid item xs={12}>
					<Grid justify="flex-end" container>
						<Button variant="contained" loading={updating} disabled={!saveEnabled || updating}
						        color="primary" type="submit">
							{t('save_btn')}
						</Button>
					</Grid>
				</Grid>
			</Grid>

		</form>
	);
};

const mapStateToProps = (state) => {
	return {
		updating: state.plan.planUpdating
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updatePlan: (plan, data) => dispatch(updatePlan(plan, data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
