import {COMMON_RESET} from "../CommonReducer";
import {USER_LOGOUT} from "../system/UserReducer";

export const MEMBERSHIP_MEMBERS_IMAGE_VARIATION = "strip&scale_crop=150x150&format=jpeg";

const initState = () => {
    return {
        loadingFanclub : false,
        selectedFanclub: null,

        membersLoading: true,
        membersFilters: {
            sort: {'Index': 'ASC'},
            image_variation: MEMBERSHIP_MEMBERS_IMAGE_VARIATION
        },
        members: [],
        membersPagingCount: 0,
        membersPaging: {
            page_no: 1,
            results_per_page: 10
        },

        membershipUser: null,
        membershipUserLoading: true,

        membersExporting: false,
        membersExportUrl: null,

        membershipUnsubscribe: false,

        membershipUpdating : false,
    }
};

export const MEMBERSHIP_FAN_CLUB_FETCH = 'MEMBERSHIP_FAN_CLUB_FETCH';
export const MEMBERSHIP_FAN_CLUB_FETCH_DONE = 'MEMBERSHIP_FAN_CLUB_FETCH_DONE';

export const MEMBERSHIP_MEMBERS_FETCH = 'MEMBERSHIP_MEMBERS_FETCH';
export const MEMBERSHIP_MEMBERS_FETCH_DONE = 'MEMBERSHIP_MEMBERS_FETCH_DONE';
export const MEMBERSHIP_MEMBERS_SET_PAGING = 'MEMBERSHIP_MEMBERS_SET_PAGING';
export const MEMBERSHIP_MEMBERS_SET_FILTERS = 'MEMBERSHIP_MEMBERS_SET_FILTERS';

export const MEMBERSHIP_USER_FETCH = 'MEMBERSHIP_USER_FETCH';
export const MEMBERSHIP_USER_FETCH_DONE = 'MEMBERSHIP_USER_FETCH_DONE';

export const MEMBERSHIP_EXPORT = 'MEMBERSHIP_EXPORT';
export const MEMBERSHIP_EXPORT_DONE = 'MEMBERSHIP_EXPORT_DONE';

export const MEMBERSHIP_UNSUBSCRIBE = 'MEMBERSHIP_UNSUBSCRIBE';
export const MEMBERSHIP_UNSUBSCRIBE_DONE = 'MEMBERSHIP_UNSUBSCRIBE_DONE';

export const MEMBERSHIP_UPDATE = 'MEMBERSHIP_UPDATE';
export const MEMBERSHIP_UPDATE_DONE = 'MEMBERSHIP_UPDATE_DONE';

const membershipReducer = (state = initState(), action) => {
    switch (action.type) {
        case USER_LOGOUT:
        case COMMON_RESET:
            return {...initState()};
        case MEMBERSHIP_UPDATE:
            return {...state, membershipUpdating: true};
        case MEMBERSHIP_UPDATE_DONE:
            return {...state, membershipUpdating: false, membershipUser:action.membershipUser};
        case MEMBERSHIP_UNSUBSCRIBE:
            return {...state, membershipUnsubscribe: true};
        case MEMBERSHIP_UNSUBSCRIBE_DONE:
            return {...state, membershipUnsubscribe: false, membershipUser:action.membershipUser};
        case MEMBERSHIP_EXPORT :
            return {...state, membersExportUrl: null, membersExporting: true};
        case MEMBERSHIP_EXPORT_DONE :
            return {...state, membersExportUrl: action.url, membersExporting: false};

        case MEMBERSHIP_FAN_CLUB_FETCH:
            return {...state, selectedFanclub: null, loadingFanclub:true};
        case MEMBERSHIP_FAN_CLUB_FETCH_DONE:
            return {...state, selectedFanclub: action.fanclub, loadingFanclub:false};

        case MEMBERSHIP_MEMBERS_FETCH:
            return {...state, membersLoading: true};
        case MEMBERSHIP_MEMBERS_FETCH_DONE:
            return {
                ...state,
                membersLoading: false,
                members: action.members,
                membersPagingCount: action.paging.count
            };

        case MEMBERSHIP_MEMBERS_SET_FILTERS :
            return {...state, membersFilters: action.filters};
        case MEMBERSHIP_MEMBERS_SET_PAGING :
            return {...state, membersPaging: action.paging};



        case MEMBERSHIP_USER_FETCH:
            return {...state, membershipUserLoading: true, membershipUser: null};
        case MEMBERSHIP_USER_FETCH_DONE:
            return {...state, membershipUserLoading: false, membershipUser: action.membershipUser};
        default:
            return state
    }
};

export default membershipReducer