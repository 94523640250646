import React from 'react';
import TableRow from "@material-ui/core/TableRow";
import Skeleton from "@material-ui/lab/Skeleton";
import TableCell from "@material-ui/core/TableCell";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {formatPrice} from "../../../../../store/actions/system/CurrencyAction";
import {connect} from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "../../../inputs/Button";


const Row = ({coupon, loading, formatPrice, onSelect}) => {
    const displayCoupon = (!loading && coupon);
    const {t} = useTranslation();
    return (
        <TableRow>
            <TableCell>
                {!displayCoupon && <Skeleton/>}
                {displayCoupon && <span>{coupon.Code}</span>}
            </TableCell>

            <TableCell>
                {!displayCoupon && <Skeleton/>}
                {displayCoupon && <span>{t('coupon_type_' + coupon.Type)}</span>}
            </TableCell>

            <TableCell>
                {!displayCoupon && <Skeleton/>}
                {displayCoupon && <span>{moment(parseInt(coupon.Expiration.unixms)).format('LLL')}</span>}
            </TableCell>

            {/*<TableCell>
                {!displayCoupon && <Skeleton/>}
                {displayCoupon && <span>{t('coupon_status_' + coupon.Status)}</span>}
            </TableCell>*/}

            <TableCell>
                {!displayCoupon && <Skeleton/>}
                {displayCoupon && <span>
                    {coupon.Reduction_Type === 'fixed' ?
                        formatPrice(coupon.Reduction_Value, coupon.Reduction_Currency)
                        :
                        <>
                            {coupon.Reduction_Value}
                            {coupon.Reduction_Type === 'rate' ? '%' : coupon.Reduction_Currency}
                            {
                                coupon.Reduction_Max_Amount &&
                                <>
                                    <br/>
                                    {t('coupon_max_reduction', {value: formatPrice(coupon.Reduction_Max_Amount, coupon.Reduction_Currency)})}
                                </>
                            }
                        </>
                    }

                </span>}
            </TableCell>

            <TableCell align="right">
                {!displayCoupon && <Skeleton/>}
                {(displayCoupon) &&  <Button color='secondary' endIcon={<DeleteIcon/>} onClick={() => onSelect(coupon)}>
                    {t('delete_btn')}
                </Button>}
            </TableCell>

        </TableRow>
    );
};


const mapDispatchToProps = (dispatch) => {
    return {
        formatPrice: (price, currency) => dispatch(formatPrice(price, currency))
    }
};

export default connect(null, mapDispatchToProps)(Row);