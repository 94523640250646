import {USER_LOGOUT} from "../system/UserReducer";
import {COMMON_RESET} from "../CommonReducer";

const initState = () => {
    return {
        releasesLoading: false,
        releases: [],
        releasesFilters: {},
        releasesPagingCount: 0,
        releasesPaging: {
            page_no: 1,
            results_per_page: 10
        },

        creating: false,
    }
};

export const DISCOGRAPHY_RELEASES_FETCH = 'DISCOGRAPHY_RELEASES_FETCH';
export const DISCOGRAPHY_RELEASES_FETCH_DONE = 'DISCOGRAPHY_RELEASES_FETCH_DONE';
export const DISCOGRAPHY_RELEASES_SET_PAGING = 'DISCOGRAPHY_RELEASES_SET_PAGING';
export const DISCOGRAPHY_RELEASES_SET_FILTERS = 'DISCOGRAPHY_RELEASES_SET_FILTERS';

export const DISCOGRAPHY_RELEASE_CREATE = 'DISCOGRAPHY_RELEASE_CREATE';
export const DISCOGRAPHY_RELEASE_CREATE_DONE = 'DISCOGRAPHY_RELEASE_CREATE_DONE';

const DiscographyReducer = (state = initState(), action) => {
    switch (action.type) {
        case USER_LOGOUT:
        case COMMON_RESET:
            return initState();
        case DISCOGRAPHY_RELEASE_CREATE:
            return {...state, creating: true};
        case DISCOGRAPHY_RELEASE_CREATE_DONE:
            return {...state, creating: false};
        case DISCOGRAPHY_RELEASES_FETCH:
            return {...state, releasesLoading: true};
        case DISCOGRAPHY_RELEASES_FETCH_DONE:
            return {
                ...state,
                releasesLoading: false,
                releases: action.releases,
                releasesPagingCount: action.paging.count
            };
        case DISCOGRAPHY_RELEASES_SET_FILTERS :
            return {...state, releasesFilters: action.filters};
        case DISCOGRAPHY_RELEASES_SET_PAGING :
            return {...state, releasesPaging: action.paging};
        default:
            return state;
    }

};

export default DiscographyReducer