import React from 'react';
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import PhoneIcon from "@material-ui/icons/Phone";
import Card from "@material-ui/core/Card";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    card: {
        whiteSpace: 'pre-wrap',
        height: '100%'
    }
}));

const Address = ({address}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Card className={classes.card} variant='outlined'>
            <CardContent>
                <Typography gutterBottom variant="h5"
                            component="h2">{t('shipping_address')}</Typography>

                {!address && <Skeleton height={100}/>}
                {
                    address &&
                    <Typography variant="body2" color="textSecondary" component="p">
                        {address.Display.join('\n')}
                    </Typography>
                }
                {
                    (address && address.Contact_Phone) &&
                    <Typography variant="body2" color="textSecondary" component="p">
                        <PhoneIcon fontSize="small"/>
                        {address.Contact_Phone}
                    </Typography>
                }
            </CardContent>
        </Card>
    );
};

export default Address;