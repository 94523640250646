import React, { useEffect }        from 'react';
import TablePaginationActions      from './TablePaginationActions';
import TablePagination             from '@material-ui/core/TablePagination';
import { useTranslation }          from 'react-i18next';
import queryString                 from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

const Pagination = ({ loading, count, paging, setPaging, prefix = '' }) => {
	const { t } = useTranslation();
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		const params = queryString.parse(location.search);
		const pNo = `${prefix}p` in params ? parseInt(params[`${prefix}p`]) : 1;
		const perPage = `${prefix}pp` in params ? parseInt(params[`${prefix}pp`]) : paging.results_per_page;

		if (paging.page_no !== pNo || paging.results_per_page !== perPage)
			setPaging({ page_no: pNo, results_per_page: perPage });
		// eslint-disable-next-line
	}, [location]);

	const changePageHandler = (e, newPage) => {
		// The pagination component start at 0;...
		if (loading) return;
		let params = queryString.parse(location.search);
		params[`${prefix}p`] = newPage + 1;
		history.push(location.pathname + '?' + queryString.stringify(params));
	};

	const changeResultPerPageHandler = e => {
		if (loading) return;
		let params = queryString.parse(location.search);
		params[`${prefix}p`] = 1;
		params[`${prefix}pp`] = e.target.value;
		history.push(location.pathname + '?' + queryString.stringify(params));
	};

	return (
		<TablePagination
			rowsPerPageOptions={[5, 10, 25, 50, 100]}
			count={loading ? (parseInt(count) ?? parseInt(paging.results_per_page)) : parseInt(count)}
			rowsPerPage={parseInt(paging.results_per_page)}
			page={loading ? ((parseInt(paging.page_no) - 1) ?? 0) : parseInt(paging.page_no) - 1}
			labelRowsPerPage={t('rows_per_page')}
			SelectProps={{
				inputProps: { 'aria-label': t('rows_per_page') },
				native: true,
			}}
			labelDisplayedRows={
				({ from, to, count }) => t(count !== -1 ? 'table_paging' : 'table_paging_more', {
					from: from,
					to: to,
					count: count
				})
			}
			onChangePage={changePageHandler}
			onChangeRowsPerPage={changeResultPerPageHandler}
			ActionsComponent={TablePaginationActions}
		/>

	);
};

export default Pagination;
