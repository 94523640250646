import withStyles    from '@material-ui/core/styles/withStyles';
import MaterialPaper from '@material-ui/core/Paper';

export const Paper = withStyles(theme => {
	return {
		root: {
			padding: theme.spacing(2),
			display: 'flex',
			overflow: 'auto',
			flexDirection: 'column',
			[theme.breakpoints.down('sm')]: {
				//paddingTop: style.theme.spacing(0),
				//padding: theme.spacing(0),
				//margin: theme.spacing(0),
				//width: '100%',

				//paddingBottom: style.theme.spacing(0),
				//paddingLeft: style.theme.spacing(0),
				//paddingRight: style.theme.spacing(0),

			}
		},
	};
})(MaterialPaper);

export const BlackPaper = withStyles(theme => {
	return {
		root: {
			background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.08) 100%, rgba(255, 255, 255, 0.08) 100%), #121212',
		},
	};
})(MaterialPaper);