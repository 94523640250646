import React, { Fragment, useEffect } from 'react';
import TableHead                      from '@material-ui/core/TableHead';
import MaterialTable                  from '@material-ui/core/Table';
import TableRow                       from '@material-ui/core/TableRow';
import TableCell                      from '@material-ui/core/TableCell';
import TableBody                      from '@material-ui/core/TableBody';
import TableContainer                 from '@material-ui/core/TableContainer';
import Row                            from './Row';
import { connect }                    from 'react-redux';
import TableFooter                    from '@material-ui/core/TableFooter';
import { useTranslation }             from 'react-i18next';
import Empty                          from './Empty';
import {
	fetchReserves,
	setReservesFilters,
	setReservesPaging
}                                     from '../../../../../../store/actions/planner/ReserveAction';
import SortableCell                   from '../../../../data/table/SortableCell';
import Pagination                     from '../../../../data/table/Pagination';


const Table = ({ event, onSelected, reserves, fetch, loading, filters, paging, count, setReservesPaging, setReservesFilters }) => {
	const loadingData = [...Array(paging.results_per_page).keys()];
	const { t } = useTranslation();
	const displayLoading = () => loadingData.map((k) => <Row key={k} loading={true} reserve={null}/>);
	const displayQuestions = () => reserves.map((u, idx) => <Row onSelected={onSelected}
	                                                             key={u.Planner_Event_Seat_Reserve__} loading={false}
	                                                             reserve={u}/>);
	useEffect(() => {
		// Reset filters the first time
		setReservesFilters({});
	}, [setReservesFilters]);

	useEffect(() => {
		if (!event) return;
		fetch(event.Planner_Event__);
	}, [fetch, filters, paging, event]);

	const { sort } = filters;

	const sortHandler = (property, dir) => {
		let p = { ...filters };
		// only 1 sort
		p.sort = {};
		p.sort[property] = dir;
		setReservesFilters(p);
	};

	return (
		<Fragment>
			{(loading || reserves.length > 0) &&
			<TableContainer>
				<MaterialTable aria-label='simple table'>
					<TableHead>
						<TableRow>
							<Pagination
								setPaging={setReservesPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
						<TableRow>
							<TableCell>
								<SortableCell sort={sort} onClick={sortHandler} field='Serial'
								              label={t('tablecell_reserve_serial')}/>
							</TableCell>
							<TableCell>{t('tablecell_reserve_name')}</TableCell>
							<TableCell>{t('tablecell_reserve_phone')}</TableCell>
							<TableCell> {t('tablecell_reserve_seat')}</TableCell>
							<TableCell> {t('tablecell_reserve_status')}</TableCell>
							<TableCell> {t('tablecell_reserve_used')}</TableCell>
							<TableCell> {t('tablecell_allocated_seat')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{loading && displayLoading()}
						{!loading && displayQuestions()}
					</TableBody>
					<TableFooter>
						<TableRow>
							<Pagination
								setPaging={setReservesPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
					</TableFooter>

				</MaterialTable>
			</TableContainer>
			}
			{(!loading && reserves.length < 1) && <Empty/>}
		</Fragment>
	);

};


const mapStateToProps = (state) => {
	return {
		filters: state.reserve.reservesFilters,
		paging: state.reserve.reservesPaging,
		count: state.reserve.reservesPagingCount,
		loading: state.reserve.reservesLoading,
		reserves: state.reserve.reserves
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: (eventId) => dispatch(fetchReserves(eventId)),
		setReservesPaging: (newPaging) => dispatch(setReservesPaging(newPaging)),
		setReservesFilters: (newFilters) => dispatch(setReservesFilters(newFilters))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);

