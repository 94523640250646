import {COMMON_RESET} from './CommonReducer';
import {USER_LOGOUT}  from './system/UserReducer';

export const AUTHORS_IMAGE_VARIATION = 'strip&scale_crop=150x150&format=jpeg';
export const AUTHOR_IMAGE_VARIATION = 'strip&scale_crop=400x400&format=jpeg';

const initState = () => {
	return {
		authorsLoading: false,
		authorsFilters: {},
		authors: [],
		authorsPagingCount: 0,
		authorsPaging: {
			page_no: 1,
			results_per_page: 10
		},

		editing:false,
		creating:false,
	};
};

export const AUTHORS_FETCH = 'AUTHORS_FETCH';
export const AUTHORS_FETCH_DONE = 'AUTHORS_FETCH_DONE';
export const AUTHORS_SET_PAGING = 'AUTHORS_SET_PAGING';
export const AUTHORS_SET_FILTERS = 'AUTHORS_SET_FILTERS';

export const AUTHOR_UPDATE = 'AUTHOR_UPDATE';
export const AUTHOR_UPDATE_DONE = 'AUTHOR_UPDATE_DONE';

export const AUTHOR_CREATE = 'AUTHOR_CREATE';
export const AUTHOR_CREATE_DONE = 'AUTHOR_CREATE_DONE';

const authorReducer = (state = initState(), action) => {
	switch (action.type) {
		case USER_LOGOUT:
		case COMMON_RESET:
			return initState();
		case AUTHOR_UPDATE:
			return {...state, editing: true};
		case AUTHOR_UPDATE_DONE:
			if(!action.author) return {...state, editing: false};

			const tmp = [...state.authors]
			tmp.forEach((a) => {
				if(a.Content_Cms_Author__ !== action.author.Content_Cms_Author__) return;
				a.Name = action.author.Name;
			})
			return {...state, editing: false, authors: tmp};
		case AUTHOR_CREATE:
			return {...state, creating: true};
		case AUTHOR_CREATE_DONE: {
			if (!action.author) return {...state, creating: false};

			const tmp = [...state.authors];
			tmp.forEach((a) => {
				if (a.Content_Cms_Author__ !== action.author.Content_Cms_Author__) return;
				a.Name = action.author.Name;
			});
			return {...state, creating: false, authors: tmp};
		}
		case AUTHORS_FETCH:
			return {...state, authorsLoading: true};
		case AUTHORS_FETCH_DONE:
			return {
				...state,
				authorsLoading: false,
				authors: action.authors,
				authorsPagingCount: action.paging.count
			};
		case AUTHORS_SET_FILTERS :
			return {...state, authorsFilters: action.filters};
		case AUTHORS_SET_PAGING :
			return {...state, authorsPaging: action.paging};
		default:
			return state;
	}
};

export default authorReducer;