import React, {useEffect}                        from 'react';
import {CircularProgress, IconButton, TextField} from '@material-ui/core';
import {useTranslation}                          from 'react-i18next';
import {useNetworkMassMailSegment}               from '../../../hooks/api/network/massmail/useMassMailSegment';
import {Autocomplete}                            from '@material-ui/lab';
import {connect}                                 from 'react-redux';
import {Edit}                                    from '@material-ui/icons';
import {useHistory}                              from 'react-router-dom';
import {getSiteNotificationListEditRoute}        from '../../../router/routes/site/factory/content';

const MassMailSegmentSelector = ({
	                                 realmId,
	                                 value,
	                                 setValue,
	                                 label = null,
	                                 helperText = null,
	                                 disabled = false,
	                                 required = false,
	                                 isManager,
	                                 ...rest
                                 }) => {
	const {t} = useTranslation();
	const [list, fetch, loading] = useNetworkMassMailSegment();
	const history = useHistory();

	useEffect(() => {
		fetch({Realm__: realmId}, {results_per_page: 100}); // having more than 100 segments looks overkill
	}, [realmId]);  //eslint-disable-line

	const handleEdit = () => {
		history.push(getSiteNotificationListEditRoute(value.Network_MassMail_Segment__))
	}

	return (
		<Autocomplete
			disabled={Boolean(disabled)}
			value={value}
			onChange={(e, value) => {
				setValue(value);
			}}
			getOptionSelected={(option, value) => option.Network_MassMail_Segment__ === value.Network_MassMail_Segment__}
			getOptionLabel={option => !option || !option.Name ? '' : option.Name}
			options={list?.data ?? []}
			loading={loading}
			renderInput={(params) => (
				<TextField
					{...params}
					required={required}
					label={t('mass_mail_segment_selector')}
					variant="outlined"
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<React.Fragment>
								{loading ? <CircularProgress color="inherit" size={20}/> : null}
								{(isManager && value)  && <IconButton
									size='small'
									onClick={handleEdit}
								>
									<Edit/>
								</IconButton>}
								{params.InputProps.endAdornment}
							</React.Fragment>
						)
					}}
				/>
			)}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		isManager: state.access.manager
	};
};

export default connect(mapStateToProps)(MassMailSegmentSelector);
