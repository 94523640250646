import React            from 'react';
import AppBarTitle      from '../components/common/typography/AppBarTitle';
import {useTranslation} from 'react-i18next';
import Grid             from '@material-ui/core/Grid';
import {connect}        from 'react-redux';
import SelectRealm      from './SelectRealm';
import Sales            from '../components/common/dashboard/Sales';
import Membership       from '../components/common/dashboard/Membership';
import Support          from '../components/common/dashboard/Support';
import HasRight         from '../components/Route/HasRight';
import Shippings        from '../components/common/dashboard/Shippings';
import HasCapability    from '../components/Route/HasCapability';
import NextEvent        from '../components/common/dashboard/NextEvent';
import TaskCenter       from '../components/common/dashboard/TaskCenter';
import {Skeleton}       from '@material-ui/lab';

const Home = ({selectedRealm, ready}) => {
	const {t} = useTranslation();

	if (!selectedRealm) return <SelectRealm/>;
	if (!ready) {
		return (<Grid container spacing={3}>
			<AppBarTitle title={t('dashboard_title')}/>
			<Grid item xs={12}>
				<Skeleton width="100%" height={400}/>
			</Grid>
		</Grid>);
	}

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('dashboard_title')}/>

			<HasRight support>
				<HasCapability fanclub hideLoading>
					<Grid item xs={12} md={4}>
						<Membership/>
					</Grid>
				</HasCapability>
			</HasRight>

			<Grid item xs={12} md={4}>
				<Support/>
			</Grid>

			<HasCapability store hideLoading>
				<Grid item xs={12} md={4}>
					<Shippings/>
				</Grid>
			</HasCapability>

			<Grid item xs={12} md={6}>
				<TaskCenter/>
			</Grid>

			<HasCapability event hideLoading>
				<Grid item xs={12} md={6}>
					<NextEvent/>
				</Grid>
			</HasCapability>

			<HasRight support>
				<Grid item xs={12}>
					<Sales/>
				</Grid>
			</HasRight>
		</Grid>
	);
};


const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		ready: state.common.ready
	};
};


export default connect(mapStateToProps)(Home);
