import React, { useEffect, useState } from 'react';
import Grid                           from '@material-ui/core/Grid';
import { COLOR_LIST }                 from '../../../utils/colors';
import { useTranslation }             from 'react-i18next';
import { formatPrice }                from '../../../../store/actions/system/CurrencyAction';
import { connect }                    from 'react-redux';
import { getStoreProductEditRoute }   from '../../../../router/routes/store/factory/store';
import ActivePieChart                 from '../../data/charts/pie/ActivePieChart';
import Link                           from '../../typography/Link';

const formatReport = (report, currency, t) => {
	const result = [];

	report.forEach((r, idx) => {
			let name = r.Catalog_Product['Basic.Name'] ?? r.Catalog_Product['Internal_Name'] ?? 'N/A';

			if ('Basic.Decorator' in r.Catalog_Product) {
				if (r.Catalog_Product['Basic.Decorator'] === 'Planner')
					name = t('sale_stats_planner', { name: name });
				else if (r.Catalog_Product['Basic.Decorator'] === 'Membership')
					name = t('sale_stats_membership', { name: name });
				else if (r.Catalog_Product['Basic.Decorator'] === 'Superchat')
					name = t('sale_stats_superchat');
			}else {
				name = t('sale_stats_store', { name: name });
			}

			result.push({
				name: name,
				Count: (currency && currency !== 'all') ? parseInt(r.Total_Vat.value_float) : parseInt(r.Quantity.value),
				fill: COLOR_LIST[idx % (COLOR_LIST.length - 1)]
			});
		}
	);

	return result;
};

const ProductReport = ({ report, loading, currency, formatPrice }) => {
	const { t } = useTranslation();
	const [tableHeaders, setTableHeaders] = useState([
		t('tablecell_product_name'),
		t('tablecell_product_quantity'),
	]);

	useEffect(() => {
		const headers = [];
		headers.push(t('tablecell_product_name'));
		headers.push(t('tablecell_product_quantity'));
		if (currency && currency !== 'all') {
			headers.push(t('tablecell_product_income'));
		}
		setTableHeaders(headers);
		// eslint-disable-next-line
	}, [setTableHeaders, currency]);

	const getCellContent = (rowIdx, cellIdx, data) => {
		const name = data.name ?? report[rowIdx].Catalog_Product.Internal_Name ?? 'N/A';
		switch (cellIdx) {
			case 0:
				if (!('Basic.Decorator' in report[rowIdx].Catalog_Product))
					return <Link to={getStoreProductEditRoute(report[rowIdx].Catalog_Product.Catalog_Product__)}>
						{name}
					</Link>;
				return name;
			case 1:
				return report[rowIdx].Quantity.value;
			case 2:
				return formatPrice(report[rowIdx].Total_Vat.value_float, currency);
			default:
				return <></>;

		}
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<ActivePieChart
					title={t('store_report_product')}
					noDataText={t('store_report_product_no_stats')}
					loading={loading}
					stats={formatReport(report, currency, t)}
					getCount={count => (currency && currency !== 'all') ?
						t('store_report_product_pie_currency', { count: formatPrice(count, currency) }) :
						t('store_report_product_pie', { count: count })}
					tableHeaders={tableHeaders}
					getCellContent={getCellContent}
				/>
			</Grid>
		</Grid>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		formatPrice: (price, currency) => dispatch(formatPrice(price, currency))
	};
};

export default connect(null, mapDispatchToProps)(ProductReport);
