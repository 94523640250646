import React, {Fragment, useEffect} from 'react';
import TableHead                    from '@material-ui/core/TableHead';
import MaterialTable                from '@material-ui/core/Table';
import TableRow                     from '@material-ui/core/TableRow';
import TableCell                    from '@material-ui/core/TableCell';
import TableContainer               from '@material-ui/core/TableContainer';
import Row                          from './Row';
import {useTranslation}             from 'react-i18next';
import Empty                        from './Empty';
import TableBody                    from '@material-ui/core/TableBody';
import TableFooter                  from '@material-ui/core/TableFooter';
import {connect}                    from 'react-redux';
import Pagination                   from '../../../../../../data/table/Pagination';
import {
	fetchShippingFeeZoneValues,
	setShippingFeeZoneValueFilters,
	setShippingFeeZoneValuePaging
}                                   from '../../../../../../../../store/actions/store/ShippingFeeAction';

const Table = ({
	               zoneId,
	               values,
	               fetch,
	               loading,
	               filters,
	               paging,
	               count,
	               setShippingFeeZoneValuePaging,
	               setShippingFeeZoneValueFilters
               }) => {
	const loadingData = [...Array(paging.results_per_page).keys()];
	const {t} = useTranslation();
	const displayLoading = () => loadingData.map((k) => <Row
		key={k}
		loading={true}
		value={null}
	/>);

	const displayValues = () => values.map((u, idx) => <Row key={u.Catalog_Deliverable_Fee_Zone_Value__}
	                                                        loading={false}
	                                                        value={u}/>);

	useEffect(() => {
		// Reset filters the first time
		setShippingFeeZoneValueFilters({...filters});
		// eslint-disable-next-line
	}, [setShippingFeeZoneValueFilters]);


	useEffect(() => {
		fetch(zoneId);
	}, [fetch, filters, paging, zoneId]);

	/*const {sort} = filters;

	const sortHandler = (property, dir) => {
		let p = {...filters};
		// only 1 sort
		p.sort = {};
		p.sort[property] = dir;
		setRecordsFilters(p);
	};*/

	return (
		<Fragment>
			{(loading || values.length > 0) &&
			<TableContainer>
				<MaterialTable aria-label="simple table">
					<TableHead>
						<TableRow>
							<Pagination
								setPaging={setShippingFeeZoneValuePaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
						<TableRow>
							<TableCell> {t('tablecell_shipping_zone_value_country')}</TableCell>
							<TableCell> {t('tablecell_shipping_zone_value_province')}</TableCell>
							<TableCell align="right"/>
						</TableRow>
					</TableHead>
					<TableBody>
						{loading && displayLoading()}
						{!loading && displayValues()}
					</TableBody>
					<TableFooter>
						<TableRow>
							<Pagination
								setPaging={setShippingFeeZoneValuePaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
					</TableFooter>

				</MaterialTable>
			</TableContainer>
			}
			{(!loading && values.length < 1) && <Empty/>}
		</Fragment>
	);

};


const mapStateToProps = (state) => {
	return {
		filters: state.shippingFee.zoneValuesFilters,
		paging: state.shippingFee.zoneValuesPaging,
		count: state.shippingFee.zoneValuesPagingCount,
		loading: state.shippingFee.zoneValuesLoading,
		values: state.shippingFee.zoneValues,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: (zoneId) => dispatch(fetchShippingFeeZoneValues(zoneId)),
		setShippingFeeZoneValuePaging: (newPaging) => dispatch(setShippingFeeZoneValuePaging(newPaging)),
		setShippingFeeZoneValueFilters: (newFilters) => dispatch(setShippingFeeZoneValueFilters(newFilters)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);

