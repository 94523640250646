import {useAction}                         from '../useBaseHooks';
import {useCallback}                       from 'react';
import {getUserSetDefaultLocationEndpoint} from '../endpoints/user/userEndpoints';

export const useUserSetDefaultLocation = userId => {
	const [_doAction, loading] = useAction(getUserSetDefaultLocationEndpoint(userId), 'POST', { snackMessageToken: 'user_default_location_set_success' });

	const doAction = useCallback((userLocationId, type, settingsOverride = {}) => _doAction({
		User_Location__: userLocationId,
		type: type
	}, settingsOverride), []); //eslint-disable-line

	return [doAction, loading];
};
