import React            from 'react';
import ReactButton      from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles       from '@material-ui/core/styles/withStyles';

export const StyledButton = withStyles(theme => {
	return {
		containedPrimary: {
			background: theme.palette.primary.gradientInverted,
			color: theme.palette.text.primary,
		},
		disabled: {
			color: 'rgba(255, 255, 255, 0.3)',
			boxShadow: 'none',
			background: 'rgba(255, 255, 255, 0.12)',
		}
	};
})(ReactButton);

const Button = React.forwardRef(({ loading, children, ...rest }, ref) => {
	if (loading) {
		rest.disabled = true;
		rest.endIcon = <CircularProgress thickness={2} size={16}/>;
	}

	return (
		<StyledButton {...rest} ref={ref}>
			{children}
		</StyledButton>
	);
});

export default Button;
