import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {connect} from 'react-redux'
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from 'react-i18next';
import {useHistory} from "react-router-dom";
import Button from "../../inputs/Button";
import {deleteAlbum} from "../../../../store/actions/discography/AlbumAction";
import {getMusicAlbumsRoute} from "../../../../router/routes/music/factory/album";

const DeleteDialog = ({open, setOpen, deleting, album, deleteAlbum}) => {
    const {t} = useTranslation();
    const history = useHistory();

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        deleteAlbum(album.Music_Label_Album__)
            .then(() => {
                handleClose();
                history.push(getMusicAlbumsRoute())
            })
    };

    return (
        <Dialog
            open={open}
            aria-labelledby={t('delete_album')}
            aria-describedby={t('delete_album')}
            fullWidth
        >
            <DialogTitle id="album-delete-title">{t('confirm_delete_album_title')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="album-delete-description">
                    {t('confirm_delete_album_desc')}
                    <br/>
                    <Typography variant="body1" color="textPrimary" component="span">{t('are_you_sure')}</Typography>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button variant='contained' color="primary" autoFocus onClick={handleClose} disabled={deleting}>
                    {t('back_btn')}
                </Button>
                <Button color="secondary" disabled={deleting} loading={deleting} endIcon={<DeleteForeverIcon/>}
                        onClick={handleDelete}>
                    {t('delete_btn')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {
        deleting: state.album.deleting
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        deleteAlbum: (albumId) => dispatch(deleteAlbum(albumId))
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(DeleteDialog);