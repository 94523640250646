import React, {useEffect, useState} from 'react';
import {getLocale}                  from '@karpeleslab/klbfw';
import {isObject}                   from '../../utils/misc';
import {Grid}                       from '@material-ui/core';
import GridList                     from '@material-ui/core/GridList';
import GridListTile                 from '@material-ui/core/GridListTile';
import FullscreenIcon               from '@material-ui/icons/Fullscreen';
import IconButton                   from '@material-ui/core/IconButton';
import GridListTileBar              from '@material-ui/core/GridListTileBar';
import {TEMPLATE_IMAGE_VARIATION}   from '../../../store/reducers/TemplateReducer';

const TemplateGallery = ({template}) => {
	const [gallery, setGallery] = useState([]);

	useEffect(() => {
		if (!isObject(template.Images)) {
			setGallery([]);
			return;
		}
		const locale = getLocale();
		if (locale in template.Images) {
			setGallery(template.Images[locale]);
		} else {
			const available = Object.keys(template.Images);
			if (available.length < 1) setGallery([]);
			else setGallery(template.Images[available[0]]);
		}
	}, [template, setGallery]);

	if (gallery.length < 1) return <></>;
	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<GridList cellHeight={180} cols={3}>
					{gallery.map(img => {
							return <GridListTile key={img.Image_Drive_Item__}>
								<img src={img.Image_Drive_Item.Media_Image[TEMPLATE_IMAGE_VARIATION]}
								     alt={img.Description}/>
								<GridListTileBar
									title={img.Description}
									actionIcon={
										<IconButton onClick={() => {
											window.open(img.Image_Drive_Item.View_Url, '_blank').focus();
										}}>
											<FullscreenIcon/>
										</IconButton>
									}
								/>
							</GridListTile>;
						}
					)}
				</GridList>
			</Grid>
		</Grid>
	);
};

export default TemplateGallery;