import {rest} from "@karpeleslab/klbfw";
import {handleError} from "../../components/utils/apiErrorHandler";

export const getText = (id) => {
    return (dispatch, getState) => {
        return rest('Text/' + id)
            .then(d => {
                return d.data
            }).catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};