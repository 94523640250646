import React from 'react';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from "@material-ui/core/IconButton";
import {getStoreFromUrl} from "../../../../utils/store";
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MaterialLink from "@material-ui/core/Link";
import Button from "../../../inputs/Button";


const Row = ({link, count, first, last, onDelete, changeOrder, disabled, onVisibilityChange}) => {
    const {t} = useTranslation();
    const {Icon, Key} = getStoreFromUrl(link.Link);

    return (
        <TableRow>
            <TableCell style={{width: '40px'}}>
                <img width={40} src={Icon} alt={t('store_icon')}/>
            </TableCell>
            <TableCell>
                {t('store_' + Key)}
            </TableCell>
            <TableCell>
                <MaterialLink href={link.Link} rel="noopener noreferrer">{link.Link}</MaterialLink>
            </TableCell>
            <TableCell>
                <IconButton disabled={disabled} onClick={e => onVisibilityChange(link, link.Visible === 'Y' ? 'N' : 'Y')}>
                    {(link.Visible === 'Y') && <VisibilityIcon/>}
                    {(link.Visible === 'N') && <VisibilityOffIcon/>}
                </IconButton>
            </TableCell>
            {!disabled && <TableCell align="right">
                <Grid container alignItems='center' justify='space-between'>
                    <Grid item>
                        <Button variant='text' color='secondary' onClick={e => onDelete(link)}>
                            {t('delete_btn')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Grid container direction='column'>
                            {!first && <Grid item>
                                <IconButton onClick={e => changeOrder(link, -1)}>
                                    <ExpandLessIcon/>
                                </IconButton>
                            </Grid>}
                            {(count > 0 && !last) && <Grid item>
                                <IconButton onClick={e => changeOrder(link, 1)}>
                                    <ExpandMoreIcon/>
                                </IconButton>
                            </Grid>}
                        </Grid>
                    </Grid>
                </Grid>
            </TableCell>}
        </TableRow>
    );
};

export default Row;