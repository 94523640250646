import React, {useState}      from 'react';
import {useTranslation}       from 'react-i18next';
import Grid                   from '@material-ui/core/Grid';
import {connect}              from 'react-redux';
import HasRight               from '../../components/Route/HasRight';
import Redirect               from '../../components/Route/Redirect';
import {getPXLDashboardRoute} from '../../router/routes/pxl/factory/general';
import AppBarTitle            from '../../components/common/typography/AppBarTitle';
import Toolbar                from '../../components/common/typography/Toolbar';
import UserAutocomplete       from '../../components/common/inputs/UserAutocomplete';
import NewTicket              from '../../components/common/support/NewTicket';
import SelectRealm            from '../SelectRealm';
import FormRequiredField      from '../../components/common/feeback/FormRequiredField';

const Contact = ({selectedRealm, support, creating}) => {
	const {t} = useTranslation();
	const [user, setUser] = useState(null);

	if (!selectedRealm) return <SelectRealm/>;

	const sendHandler = () => {
		setUser(null);
	};

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('support_contact_title')}/>
			<Grid item xs={12}>
				<Toolbar title={t('support_contact_title')}/>
			</Grid>

			<HasRight support deniedComponent={<Redirect target={getPXLDashboardRoute()}/>}>
				<FormRequiredField/>
				<Grid item xs={12}>
					<UserAutocomplete realm={selectedRealm} value={user} setValue={setUser} disabled={creating}/>
				</Grid>
				<Grid item xs={12}>
					<NewTicket support={support} user={user} onSent={sendHandler}/>
				</Grid>
			</HasRight>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		support: state.support.support,
		creating: state.support.creating,
	};
};

export default connect(mapStateToProps)(Contact);
