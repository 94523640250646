import React, {Fragment} from 'react';
import {connect} from 'react-redux'
import Loading from "./HasRight";

const HasCapability = ({
                           store = false,
                           fanclub = false,
                           event = false,
                           stream = false,
                           hideLoading = false,
                           deniedComponent = null,
                           loadingComponent = null,

                           children,

                           //Reducer state
                           _fanclub,
                           _event,
                           _store,
                           _stream,
                           ready
                       }) => {


    const l = hideLoading ? <Fragment/> : (loadingComponent ? loadingComponent : <Loading/>);
    if (!ready) return l;

    let denied = (store && !_store) ||
        (event && !_event) ||
        (stream && !_stream) ||
        (fanclub && !_fanclub);

    if (!denied) return children ?? <Fragment/> ;

    return deniedComponent ? deniedComponent : <Fragment/>
};


const mapStateToProps = (state) => {
    return {
        ready: state.common.ready,
        _fanclub: state.template.isFanclubSupported,
        _store: state.template.isStoreSupported,
        _event: state.template.isEventSupported,
        _stream: state.template.isStreamSupported,
    }
};

export default connect(mapStateToProps)(HasCapability);