import { handleError }  from '../../components/utils/apiErrorHandler';
import { rest, upload } from '@karpeleslab/klbfw';
import {
	CMS_ENTRIES_FETCH,
	CMS_ENTRIES_FETCH_DONE,
	CMS_ENTRIES_RESET_PAGING,
	CMS_ENTRIES_SET_FILTERS,
	CMS_ENTRIES_SET_PAGING,
	CMS_ENTRY_DATA_CREATE,
	CMS_ENTRY_DATA_CREATE_DONE,
	CMS_ENTRY_DATA_DELETE,
	CMS_ENTRY_DATA_DELETE_DONE,
	CMS_ENTRY_DATA_FETCH,
	CMS_ENTRY_DATA_FETCH_DONE,
	CMS_ENTRY_DATA_UPDATE,
	CMS_ENTRY_DATA_UPDATE_DONE,
	CMS_ENTRY_DELETE_IMAGE,
	CMS_ENTRY_DELETE_IMAGE_DONE,
	CMS_ENTRY_DELETE_IMAGE_ERROR, CMS_ENTRY_DUPLICATE, CMS_ENTRY_DUPLICATE_DONE,
	CMS_ENTRY_FETCH,
	CMS_ENTRY_FETCH_DONE,
	CMS_ENTRY_MANAGE_TAGS,
	CMS_ENTRY_MANAGE_TAGS_DONE,
	CMS_ENTRY_UPLOAD_IMG,
	CMS_ENTRY_UPLOAD_IMG_DONE,
	CMS_ENTRY_UPLOAD_IMG_RUNNING,
	CMS_LOAD_FANCLUB,
	CMS_LOAD_FANCLUB_DONE,
	CMS_LOAD_NEWS,
	CMS_LOAD_NEWS_DONE,
	CMS_LOAD_PAGES,
	CMS_LOAD_PAGES_DONE,
	CMS_SELECT,
	CMS_TAGS_FETCH,
	CMS_TAGS_FETCH_DONE,
	CMS_TEMPLATE_CREATE,
	CMS_TEMPLATE_CREATE_DONE,
	CMS_TEMPLATE_DELETE,
	CMS_TEMPLATE_DELETE_DONE,
	CMS_TEMPLATE_FETCH,
	CMS_TEMPLATE_FETCH_DONE,
	CMS_TEMPLATE_UPDATE,
	CMS_TEMPLATE_UPDATE_DONE,
	CMS_TEMPLATES_FETCH,
	CMS_TEMPLATES_FETCH_DONE,
	CMS_TEMPLATES_SET_FILTERS,
	CMS_TEMPLATES_SET_PAGING
} from '../reducers/CmsReducer';
import { success }      from './system/ToastAction';

export const loadNewsCms = (id) => {
	return (dispatch, getState) => {
		dispatch({ type: CMS_LOAD_NEWS });
		return rest('Content/Cms/' + id)
			.then((d) => {
				dispatch({ type: CMS_LOAD_NEWS_DONE, cms: d.data });

				return d.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const loadPagesCms = (id) => {
	return (dispatch, getState) => {
		dispatch({ type: CMS_LOAD_PAGES });
		return rest('Content/Cms/' + id)
			.then((d) => {
				dispatch({ type: CMS_LOAD_PAGES_DONE, cms: d.data });

				return d.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const loadFanClubCms = (id) => {
	return (dispatch, getState) => {
		dispatch({ type: CMS_LOAD_FANCLUB });
		return rest('Content/Cms/' + id)
			.then((d) => {
				dispatch({ type: CMS_LOAD_FANCLUB_DONE, cms: d.data });

				return d.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};


export const fetchAndSelectCms = (id) => {
	return (dispatch, getState) => {
		return rest('Content/Cms/' + id)
			.then((d) => {
				dispatch(selectCms(d.data));
				return d.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};


export const selectCms = (cms) => {
	return (dispatch, getState) => {
		dispatch({ type: CMS_SELECT, cms: cms });
	};
};


export const fetchEntries = (cmsId) => {
	return (dispatch, getState) => {
		dispatch({ type: CMS_ENTRIES_FETCH });

		const filters = getState().cms.entriesFilters;
		const params = {
			...filters,
			...getState().cms.entriesPaging
		};

		let searchMode = 'query' in filters;
		let endpoint = 'Content/Cms/' + cmsId + '/Entry';
		if (searchMode) endpoint = 'Content/Cms/' + cmsId+ ':adminSearch';

		return rest(endpoint, 'GET', params)
			.then(data => {
				let result = data.data;
				if (searchMode) {
					// The search API return CMS entry data, the list API return CMS entries,
					// we need to make CMs entry data looks like CMS entry
					result = result.data.map(d => {
						return {
							Content_Cms_Entry__: d.Content_Cms_Entry__,
							Title: d.Title,
							Tags: d.Tags,
							Language__:d.Language__,
							Data: {
								[d.Language__]: d
							}
						};
					});
				}
				dispatch({ type: CMS_ENTRIES_FETCH_DONE, entries: result, paging: data.paging });
				return result;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const setEntriesFilters = (filters) => {
	return (dispatch, getState) => {
		dispatch({ type: CMS_ENTRIES_SET_FILTERS, filters: filters });
	};
};

export const setEntriesPaging = (paging) => {
	return (dispatch, getState) => {
		dispatch({ type: CMS_ENTRIES_SET_PAGING, paging: { ...getState().cms.entriesPaging, ...paging } });
	};
};

export const fetchEntryData = (id, silent = false) => {
	return (dispatch, getState) => {
		dispatch({ type: CMS_ENTRY_DATA_FETCH, silent: silent });
		return rest('Content/Cms/Entry/Data/' + id)
			.then((d) => {
				dispatch({ type: CMS_ENTRY_DATA_FETCH_DONE, entryData: d.data });
				return d.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const selectEntryData = (entryData) => {
	return (dispatch, getState) => {
		dispatch({ type: CMS_ENTRY_DATA_FETCH_DONE, entryData: entryData });
	}
}

export const fetchEntry = (id, params = {}) => {
	return (dispatch, getState) => {
		dispatch({ type: CMS_ENTRY_FETCH });
		return rest('Content/Cms/Entry/' + id, 'GET', params)
			.then((d) => {
				dispatch({ type: CMS_ENTRY_FETCH_DONE, entry: d.data });
				return d.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const fetchTags = (classifyId) => {
	return (dispatch, getState) => {
		dispatch({ type: CMS_TAGS_FETCH });
		return rest('Classify/' + classifyId + ':describeAllTags')
			.then((d) => {
				dispatch({ type: CMS_TAGS_FETCH_DONE, tags: d.data });
				return d.data;
			});
	};
};

export const addTag = (entryId, tagId) => {
	return (dispatch, getState) => {
		return rest('Content/Cms/Entry/' + entryId + ':addTag', { tag: tagId })
			.then((d) => {
				return d.data;
			});
	};
};

export const removeTag = (entryId, tagId) => {
	return (dispatch, getState) => {
		return rest('Content/Cms/Entry/' + entryId + ':delTag', { tag: tagId })
			.then((d) => {
				return d.data;
			});
	};
};

export const editEntryTags = (entryId, toAdd = [], toDel = []) => {
	return (dispatch, getState) => {
		dispatch({ type: CMS_ENTRY_MANAGE_TAGS });
		return new Promise((resolve, reject) => {
			let p = [];
			if (toAdd.length > 0)
				p.push(rest('Content/Cms/Entry/' + entryId + ':addTag', 'POST', { tag: toAdd }));
			if (toDel.length > 0)
				p.push(rest('Content/Cms/Entry/' + entryId + ':delTag', 'POST', { tag: toDel }));

			Promise.all(p)
				.then((promisesResults) => {
					//promisesResults will contain an array of the result of all promise, we will take last one to have the more update entry data
					resolve(promisesResults[promisesResults.length - 1]);
				})
				.catch(reject);
		}).then((d) => {
			success('article_tags_updated_success');
			dispatch({ type: CMS_ENTRY_MANAGE_TAGS_DONE, entry: d.data });
			return d.data;
		})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const updateEntry = (entryId, data) => {
	return (dispatch, getState) => {
		dispatch({ type: CMS_ENTRY_DATA_UPDATE });
		return rest('Content/Cms/Entry/' + entryId, 'PATCH', data)
			.then((d) => {
				return d.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
}

export const updateEntryData = (entryDataId, data) => {
	return (dispatch, getState) => {
		dispatch({ type: CMS_ENTRY_DATA_UPDATE });
		return rest('Content/Cms/Entry/Data/' + entryDataId, 'PATCH', data)
			.then((d) => {
				success('article_edit_success');
				dispatch({ type: CMS_ENTRY_DATA_UPDATE_DONE, entryData: d.data });
				return d.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};


export const setImages = (entryDataId, mainId = undefined, coverId = undefined) => {
	return (dispatch, getState) => {
		dispatch({ type: CMS_ENTRY_DATA_UPDATE });
		return new Promise((resolve, reject) => {
			let p = [];
			if (mainId !== undefined)
				p.push(rest('Content/Cms/Entry/Data/' + entryDataId + ':setImage', 'POST', {
					image: mainId,
					type: 'main'
				}));
			if (coverId !== undefined)
				p.push(rest('Content/Cms/Entry/Data/' + entryDataId + ':setImage', 'POST', {
					image: coverId,
					type: 'top'
				}));

			Promise.all(p)
				.then((promisesResults) => {
					//promisesResults will contain an array of the result of all promise, we will take last one to have the more update entry data
					resolve(promisesResults[promisesResults.length - 1]);
				})
				.catch(reject);
		}).then((d) => {
			success('article_edit_success');
			dispatch({ type: CMS_ENTRY_DATA_UPDATE_DONE, entryData: d.data });
			return d.data;
		})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const uploadFiles = (entryId, files = [], params = {}) => {
	return (dispatch, getState) => {
		dispatch({ type: CMS_ENTRY_UPLOAD_IMG, count: files.length });

		upload.onprogress = d => {
			let runningCount = 0;
			let blockTotal = 0;
			let progressTotal = 0;
			d.running.forEach((running) => {
				if (running.status !== 'pending' && running.status !== 'complete') {
					runningCount++;
					progressTotal += running.done;
					blockTotal += running.blocks;
				}
			});

			const ratio = blockTotal > 0 ? progressTotal / blockTotal : 0;

			dispatch({ type: CMS_ENTRY_UPLOAD_IMG_RUNNING, count: runningCount, ratio });
		};

		for (let i = 0; i < files.length; i++) {
			upload.append('Content/Cms/Entry/' + entryId + ':addImage', files[i], params)
				.then((img) => {
					dispatch({ type: CMS_ENTRY_UPLOAD_IMG_DONE, img: img.final });
				});
		}

		upload.run();
	};
};

export const createEntry = (cmsId, title, language, shortContents = '', contents = '') => {
	return (dispatch, getState) => {
		dispatch({ type: CMS_ENTRY_DATA_CREATE });
		const params = {
			Language__: language,
			Title: title,
			Short_Contents: shortContents,
			Contents: contents
		};

		return rest('Content/Cms/' + cmsId + '/Entry', 'POST', params)
			.then(d => {
				success('article_create_success');
				dispatch({ type: CMS_ENTRY_DATA_CREATE_DONE });
				return d.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const createEntryData = (entryId, language, title = '', shortContents = '', contents = '') => {
	return (dispatch, getState) => {
		dispatch({ type: CMS_ENTRY_DATA_CREATE });

		const params = { Language__: language };

		if (shortContents) params['Short_Contents'] = shortContents;
		if (contents) params['Contents'] = contents;
		if (title) params['Title'] = title;

		return rest('Content/Cms/Entry/' + entryId + '/Data', 'POST', params)
			.then(d => {
				success('article_create_success');
				dispatch({ type: CMS_ENTRY_DATA_CREATE_DONE });
				return d.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const duplicateEntry = (entryId, suffix) => {
	return (dispatch, getState) => {
		dispatch({ type: CMS_ENTRY_DUPLICATE });
		return rest('Content/Cms/Entry/' + entryId + ':duplicate', 'POST', {suffix:suffix})
			.then(d => {
				success('article_duplicate_success');
				dispatch({ type: CMS_ENTRY_DUPLICATE_DONE });
				return d.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const deleteEntryData = (entryDataId) => {
	return (dispatch, getState) => {
		dispatch({ type: CMS_ENTRY_DATA_DELETE });
		return rest('Content/Cms/Entry/Data/' + entryDataId, 'DELETE')
			.then(d => {
				success('article_delete_success');
				dispatch({ type: CMS_ENTRY_DATA_DELETE_DONE });
				return d.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const deleteEntryPicture = (entryId, imgId, extraParams = {}) => {
	return (dispatch, getState) => {
		dispatch({ type: CMS_ENTRY_DELETE_IMAGE });
		return rest('Content/Cms/Entry/' + entryId + ':deleteImage', 'POST', { image: imgId, ...extraParams })
			.then(d => {
				success('article_delete_picture_success');
				dispatch({ type: CMS_ENTRY_DELETE_IMAGE_DONE, entry: d.data });
				return d.data;
			}).catch((err) => {
				dispatch({ type: CMS_ENTRY_DELETE_IMAGE_ERROR });
				handleError(getState, dispatch, err);
			});
	};
};

export const resetEntriesPaging = () => {
	return (dispatch, getState) => {
		dispatch({ type: CMS_ENTRIES_RESET_PAGING });
	};
};


export const fetchTemplates = (cmsId) => {
	return (dispatch, getState) => {
		dispatch({ type: CMS_TEMPLATES_FETCH });

		const params = {
			...getState().cms.templatesFilters,
			...getState().cms.templatesPaging
		};

		return rest('Content/Cms/' + cmsId + '/Template', 'GET', params)
			.then(data => {
				dispatch({ type: CMS_TEMPLATES_FETCH_DONE, templates: data.data, paging: data.paging });
				return data.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const setTemplatesFilters = (filters) => {
	return (dispatch, getState) => {
		dispatch({ type: CMS_TEMPLATES_SET_FILTERS, filters: filters });
	};
};

export const setTemplatesPaging = (paging) => {
	return (dispatch, getState) => {
		dispatch({ type: CMS_TEMPLATES_SET_PAGING, paging: { ...getState().cms.templatesPaging, ...paging } });
	};
};

export const createTemplate = (cmsId, name, html) => {
	return (dispatch, getState) => {
		dispatch({ type: CMS_TEMPLATE_CREATE });

		const params = {
			Name: name,
			Html: html
		};

		return rest('Content/Cms/' + cmsId + '/Template', 'POST', params)
			.then(d => {
				success('cms_template_create_success');
				dispatch({ type: CMS_TEMPLATE_CREATE_DONE });
				return d.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const updateTemplate = (templateId, name, html) => {
	return (dispatch, getState) => {
		dispatch({ type: CMS_TEMPLATE_UPDATE });

		const params = {
			Name: name,
			Html: html
		};

		return rest('Content/Cms/Template/' + templateId, 'PATCH', params)
			.then(d => {
				success('cms_template_update_success');
				dispatch({ type: CMS_TEMPLATE_UPDATE_DONE, template: d.data });
				return d.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const deleteTemplate = (templateId) => {
	return (dispatch, getState) => {
		dispatch({ type: CMS_TEMPLATE_DELETE });

		return rest('Content/Cms/Template/' + templateId, 'DELETE')
			.then(d => {
				success('cms_template_delete_success');
				dispatch({ type: CMS_TEMPLATE_DELETE_DONE });
				return d.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const fetchTemplate = (templateId) => {
	return (dispatch, getState) => {
		dispatch({ type: CMS_TEMPLATE_FETCH });
		return rest('Content/Cms/Template/' + templateId)
			.then(d => {
				dispatch({ type: CMS_TEMPLATE_FETCH_DONE, template: d.data });
				return d.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

