import {
    FETCH_LANG_BEGIN,
    FETCH_LANG_SUCCESS,
    FETCH_LANG_FAILURE
} from "../../reducers/system/LangReducer";

import {rest} from "@karpeleslab/klbfw";

export const fetchLang = () => {

    return (dispatch, getState) => {
        dispatch({type: FETCH_LANG_BEGIN});

        return rest('Language:local', "GET")
            .then(
                (d) => {
                    dispatch({type: FETCH_LANG_SUCCESS, lang: d.data})
                }
            )
            .catch((err) => {
                dispatch({type: FETCH_LANG_FAILURE, schedule: null});
                throw err;
            })
    }
};  
