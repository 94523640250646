import React, { useEffect, useState } from 'react';
import {useTranslation}               from 'react-i18next';

import TextField        from '@material-ui/core/TextField';
import Autocomplete     from '@material-ui/lab/Autocomplete';
import CircularProgress     from '@material-ui/core/CircularProgress';
import {useCatalogProducts} from "../../../hooks/api/products";


const ProductsAutocomplete = ({ catalogId, label, onProductSelected }) => {
    const {t}                        = useTranslation();
    const [open, setOpen]            = useState(false);
    const [search, setSearch]        = useState('');
    const [products, fetch, loading] = useCatalogProducts(catalogId);

    useEffect(() => {
        if (open)
            fetch();
    }, [open]); //eslint-disable-line

    useEffect(() => {
        if (!products)
            return;

        fetch({ value: search }, { results_per_page: 50 });
    }, [search]); //eslint-disable-line

    return (
        <Autocomplete
            id="products-autocomplete"
            style={{ width: '100%', minWidth: 300 }}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            getOptionSelected={(option, value) => option.Internal_Name === value.Internal_Name}
            getOptionLabel={(option) => option.Internal_Name || ''}
            options={products ? products.data : []}
            onChange={(e, p) => onProductSelected(p)}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    helperText={t('coupon_targeted_product_helperText')}
                    label={label || t('coupon_targeted_product')}
                    variant="outlined"
                    value={search}
                    required
                    onChange={e => setSearch(e.target.value)}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </>
                        )
                    }}
                />
            )}
        />
    );
};

export default ProductsAutocomplete;
