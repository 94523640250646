import React, { Fragment, useEffect }   from 'react';
import TableHead                        from '@material-ui/core/TableHead';
import MaterialTable                    from '@material-ui/core/Table';
import TableRow                         from '@material-ui/core/TableRow';
import TableCell                        from '@material-ui/core/TableCell';
import TableBody                        from '@material-ui/core/TableBody';
import TableContainer                   from '@material-ui/core/TableContainer';
import Row                              from './Row';
import { connect }                      from 'react-redux';
import TableFooter                      from '@material-ui/core/TableFooter';
import { useTranslation }               from 'react-i18next';
import Empty                            from './Empty';
import { fetchTokens, setTokensPaging } from '../../../../../store/actions/system/OAuth2Action';
import Pagination                       from '../../../data/table/Pagination';

const Table = ({ tokens, fetch, loading, paging, count, setTokensPaging }) => {
	const loadingData = [...Array(paging.results_per_page).keys()];
	const { t } = useTranslation();
	const displayLoading = () => loadingData.map((k) => <Row key={k} loading={true} token={null}/>);
	const displayTokens = () => tokens.map((u) => <Row key={u.OAuth2_Consumer_Token__} loading={false}
	                                                   token={u}/>);
	useEffect(() => {
		fetch();
	}, [fetch, paging]);

	return (
		<Fragment>
			{(loading || tokens.length > 0) &&
			<TableContainer>
				<MaterialTable aria-label='simple table'>
					<TableHead>
						<TableRow>
							<Pagination
								setPaging={setTokensPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
						<TableRow>
							<TableCell padding='checkbox'/>
							<TableCell> {t('tablecell_tokens_service')}</TableCell>
							<TableCell> {t('tablecell_tokens_name')}</TableCell>
							<TableCell align='right'/>
						</TableRow>
					</TableHead>
					<TableBody>
						{loading && displayLoading()}
						{!loading && displayTokens()}
					</TableBody>
					<TableFooter>
						<TableRow>
							<Pagination
								setPaging={setTokensPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
					</TableFooter>

				</MaterialTable>
			</TableContainer>
			}
			{(!loading && tokens.length < 1) && <Empty/>}
		</Fragment>
	);

};


const mapStateToProps = (state) => {
	return {
		paging: state.oauth2.tokensPaging,
		count: state.oauth2.tokensPagingCount,
		loading: state.oauth2.tokensLoading,
		tokens: state.oauth2.tokens
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: () => dispatch(fetchTokens()),
		setTokensPaging: (newPaging) => dispatch(setTokensPaging(newPaging)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);

