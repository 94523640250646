import React, { useEffect, useState } from 'react';
import Grid                           from '@material-ui/core/Grid';
import Button                         from '../../common/inputs/Button';
import AttachMoneyIcon                from '@material-ui/icons/AttachMoney';
import MoneyOffIcon                   from '@material-ui/icons/MoneyOff';
import { Trans, useTranslation }      from 'react-i18next';
import RichAlert                      from '../../common/feeback/RichAlert';
import { getAttribute }               from '../../utils/product';

const PutOnSale = ({ attributes, deliverables, price, onSale, setOnSale, disabled }) => {
	const [missingPriceOrGood, setMissingPriceOrGood] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {

		let missingPriceOrGood = false;
		if (deliverables.length < 1) {
			missingPriceOrGood = true;
		}

		if (!price) {
			missingPriceOrGood = true;
		}

		missingPriceOrGood |= getAttribute(attributes, 'Basic.TaxProfile') == null;

		if (!missingPriceOrGood) {
			const split = getAttribute(attributes, 'Basic.TaxProfile')[0].Value.split(',');

			if (split.length < 1 || !(split[0].trim())) {
				missingPriceOrGood = true;
			}
		}

		setMissingPriceOrGood(missingPriceOrGood);

	}, [attributes, setOnSale, price, setMissingPriceOrGood, deliverables]);
	return (
		<Grid container spacing={3} direction='column' justify='center' alignItems='center'>

			{!!missingPriceOrGood && <Grid item xs={12}>
				<RichAlert severity='warning'>
					<Trans i18nKey='product_missing_price_goods'>
						sample<br/>
						<strong>sample</strong>
					</Trans>
				</RichAlert>
			</Grid>
			}

			<Grid item>
				{!onSale &&
				<Button
					disabled={missingPriceOrGood || disabled}
					variant='contained'
					color='primary'
					endIcon={<AttachMoneyIcon/>}
					onClick={() => setOnSale(true)}
				>
					{t('put_on_sale_btn')}
				</Button>
				}

				{onSale &&
				<Button
					onClick={() => setOnSale(false)}
					disabled={missingPriceOrGood || disabled}
					variant='outlined'
					color='secondary'
					endIcon={<MoneyOffIcon/>}
				>
					{t('remove_from_sale_btn')}
				</Button>
				}

			</Grid>
		</Grid>
	);
};

export default PutOnSale;
