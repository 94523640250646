import React                          from 'react';
import TableRow                       from '@material-ui/core/TableRow';
import Skeleton                       from '@material-ui/lab/Skeleton';
import TableCell                      from '@material-ui/core/TableCell';
import {useTranslation}               from 'react-i18next';
import Button                         from '../../../../inputs/Button';
import EditIcon                       from '@material-ui/icons/Edit';
import {Link}                         from 'react-router-dom';
import {getStoreShippingFeeEditRoute} from '../../../../../../router/routes/store/factory/store';


const Row = ({fee, loading}) => {
	const displayFee = (!loading && fee);
	const {t} = useTranslation();
	return (
		<TableRow>
			<TableCell>
				{!displayFee && <Skeleton/>}
				{displayFee && <span>{fee.Name}</span>}
			</TableCell>

			<TableCell>
				{!displayFee && <Skeleton/>}
				{displayFee && <span>{t('shipping_fee_type_' + fee.Type)}</span>}
			</TableCell>


			<TableCell align="right">
				{!displayFee && <Skeleton/>}
				{(displayFee) && <Button
					variant="contained"
					color="primary"
					startIcon={<EditIcon/>}
					component={Link} to={getStoreShippingFeeEditRoute(fee.Catalog_Deliverable_Fee__)}
				>
					{t('edit_btn')}
				</Button>}
			</TableCell>

		</TableRow>
	);
};


export default Row;