import React, {Fragment, useEffect, useState} from 'react';
import Grid                                   from "@material-ui/core/Grid";
import {Trans, useTranslation}                from "react-i18next";
import TemplateSelector                       from "../../common/inputs/TemplateSelector";
import makeStyles                             from "@material-ui/core/styles/makeStyles";
import Typography                             from "@material-ui/core/Typography";
import Alert                                  from "@material-ui/lab/Alert";
import TemplateSettings                       from "../../common/inputs/TemplateSettings";
import Button                                 from "../../common/inputs/Button";
import TemplateGallery                        from '../../common/feeback/TemplateGallery';
import FormRequiredField                      from '../../common/feeback/FormRequiredField';

// dirty
const useStyles = makeStyles(() => {
    return {
        fixAlert: {
            '& > .MuiAlert-message': {
                display: 'block',
            }
        }
    }
});

const StepCustomize = ({handleBack, handleNext, template, setTemplate, settings, setSettings, settingFiles, setSettingFiles}) => {
    const {t} = useTranslation();
    const classes = useStyles();

    const [nextEnabled, setNextEnabled] = useState(!!template);
    const [isSettingsValid, setIsSettingsValid] = useState(false);

    useEffect(() => {
        if (!template) {
            setNextEnabled(false);
            return
        }

        if (template.Settings.length > 0 && !isSettingsValid) {
            setNextEnabled(false);
            return
        }

        setNextEnabled(true)
    }, [setNextEnabled, template, isSettingsValid]);

    const handleTemplateChange = t => {
        setTemplate(t);
        setSettings({
	        fanclub_visible:'N',
	        shop_visible:'N',
        }); // reset settings too
        setSettingFiles({}); // reset settings too
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h5" color="textPrimary" component="h5">
                    {t('artist_wizard_step_customize_title')}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Alert severity="info" className={classes.fixAlert}>
                    <Trans i18nKey='artist_wizard_step_customize_desc'>
                        sample <br/>
                        <strong>sample</strong>
                    </Trans>
                </Alert>
            </Grid>

            <FormRequiredField/>

            <Grid item xs={6}>
                <TemplateSelector
                    value={template}
                    onChange={handleTemplateChange}
                    inputProps={{
                        variant: 'outlined',
                        fullWidth: true,
                        required: true
                    }}
                />
            </Grid>

            {(template) && <Grid item xs={12}>
                <TemplateGallery template={template}/>
            </Grid>
            }

            {(template && template.Settings.length > 0) &&

            <Fragment>
                <Grid item xs={12}>
                    <Typography variant="h5" color="textPrimary" component="h5">
                        {t('artist_wizard_step_customize_settings_title')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <TemplateSettings
                        template={template}
                        setSettings={setSettings}
                        settings={settings}
                        setFiles={setSettingFiles}
                        files={settingFiles}
                        setIsValid={setIsSettingsValid}
                    />
                </Grid>
            </Fragment>

            }

            <Grid item xs={12}>
                <Grid container justify='space-between' spacing={3}>

                    <Grid item xs={1}>
                        <Button onClick={handleBack} variant={'outlined'}
                                color="primary">
                            {t('back_btn')}
                        </Button>
                    </Grid>

                    <Grid item xs={1}>
                        <Button onClick={handleNext} disabled={!nextEnabled} variant='contained'
                                color="primary">
                            {t('next_btn')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default StepCustomize;
