import React, {useCallback, useEffect} from 'react';
import {useTranslation}                from 'react-i18next';
import Grid                            from '@material-ui/core/Grid';
import TextField                       from '@material-ui/core/TextField';
import {emailValidator}                from '../../../utils/validator';
import SegmentVisibleSelector          from '../../inputs/SegmentVisibleSelector';
import {isObject}                      from '../../../utils/misc';

const Form = ({data, setData, setIsValid, disabled = false}) => {
	const {t} = useTranslation();

	const createInputChangeHandler = key => e => {
		const v = isObject(e) ? e.target.value : e;
		let newForm = {...data, [key]: v};

		setData(newForm);
	};

	const getValue = useCallback((name, defaultValue) => {
		return (data ?? {})[name] ?? defaultValue;
	}, [data]);

	useEffect(() => {
		if (
			!getValue('Name', '').trim() ||
			!emailValidator(getValue('Email', '').trim())
		) {
			setIsValid(false);
			return;
		}

		setIsValid(true);
	}, [setIsValid, getValue]);

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<TextField
					label={t('massmail_segment_name')}
					variant="outlined"
					fullWidth
					required
					disabled={disabled}
					value={getValue('Name', '')}
					onChange={createInputChangeHandler('Name')}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					label={t('massmail_segment_email')}
					variant="outlined"
					type="email"
					fullWidth
					required
					disabled={disabled}
					value={getValue('Email', '')}
					onChange={createInputChangeHandler('Email')}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					label={t('massmail_segment_subject_prefix')}
					helperText={t('massmail_segment_subject_prefix_helperText')}
					variant="outlined"
					fullWidth
					disabled={disabled}
					value={getValue('Subject_Prefix', '')}
					onChange={createInputChangeHandler('Subject_Prefix')}
				/>
			</Grid>
			<Grid item xs={12}>
				<SegmentVisibleSelector
					disabled={disabled}
					value={getValue('Visible', 'Y')}
					setValue={createInputChangeHandler('Visible')}
				/>
			</Grid>
		</Grid>
	);
};

export default Form;