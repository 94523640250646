import React, {useState}                    from 'react';
import Dialog                               from '@material-ui/core/Dialog';
import DialogTitle                          from '@material-ui/core/DialogTitle';
import DialogContent                        from '@material-ui/core/DialogContent';
import Grid                                 from '@material-ui/core/Grid';
import DialogActions                        from '@material-ui/core/DialogActions';
import {Trans, useTranslation}              from 'react-i18next';
import {Box}                                from '@material-ui/core';
import {connect}                            from 'react-redux';
import uuid                                 from '../../../../utils/uuid';
import {fetchReserves, importAllocatedSeat} from '../../../../../store/actions/planner/ReserveAction';
import RichAlert                            from '../../../feeback/RichAlert';
import Button                               from '../../../inputs/Button';

const ImportAllocatedSeatDialog = ({open, setOpen, eventId, importing, importAllocatedSeat, refresh}) => {
	const {t} = useTranslation();
	const [file, setFile] = useState(null);
	const [fileKey, setFileKey] = useState(uuid());

	const handleClose = () => {
		setOpen(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		e.stopPropagation();
		importAllocatedSeat(eventId, file).then(() => {
			refresh(eventId);
			handleClose();
		});
	};

	const handleFileInputChange = (e) => {
		if (e.target.files.length < 1) {
			return;
		}

		setFile(e.target.files[0]);
	};

	return (
		<Dialog
			open={open}
			aria-labelledby={t('import_allocated_seat_btn')}
			aria-describedby={t('import_allocated_seat_btn')}
			fullWidth
			maxWidth="lg"
			onEnter={() => {
				setFile(null);
				setFileKey(uuid());
			}}
		>
			<form onSubmit={handleSubmit}>
				<DialogTitle>{t('import_allocated_seat_btn')}</DialogTitle>
				<DialogContent>
					<Box py={2}>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<RichAlert severity="info">
									<Trans i18nKey="import_allocated_seat_info">
										sample<br/>
										<strong>sample</strong>
									</Trans>
								</RichAlert>
							</Grid>
							<Grid item xs={12}>
								<input accept=".csv" type="file" key={fileKey} onChange={handleFileInputChange}/>
							</Grid>
						</Grid>
					</Box>

				</DialogContent>
				<DialogActions>
					<Button color="default" onClick={handleClose} disabled={importing}>
						{t('close_btn')}
					</Button>
					<Button color="primary" variant="contained" loading={importing} disabled={importing || !file}
					        type="submit">
						{t('import_allocated_seat_btn')}
					</Button>
				</DialogActions>
			</form>
		</Dialog>

	);
};

const mapStateToProps = (state) => {
	return {
		importing: state.reserve.importing,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		importAllocatedSeat: (eventId, file) => dispatch(importAllocatedSeat(eventId, file)),
		refresh: (eventId) => dispatch(fetchReserves(eventId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportAllocatedSeatDialog);
