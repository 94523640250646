import React        from 'react';
import Grid         from '@material-ui/core/Grid';
import {Trans}      from 'react-i18next';
import {Typography} from '@material-ui/core';

const FormRequiredField = () => {
	return (
		<Grid item xs={12}>
			<Trans i18nKey='form_required_field'>
				sample<br/>
				<Typography component={'span'} color='secondary'>sample</Typography>
			</Trans>
		</Grid>
	);
};

export default FormRequiredField;