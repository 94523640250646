import React, { Fragment, useEffect }       from 'react';
import TableHead                            from '@material-ui/core/TableHead';
import MaterialTable                        from '@material-ui/core/Table';
import TableRow                             from '@material-ui/core/TableRow';
import TableCell                            from '@material-ui/core/TableCell';
import TableBody                            from '@material-ui/core/TableBody';
import TableContainer                       from '@material-ui/core/TableContainer';
import Row                                  from './Row';
import { connect }                          from 'react-redux';
import TableFooter                          from '@material-ui/core/TableFooter';
import { useTranslation }                   from 'react-i18next';
import Empty                                from './Empty';
import { fetchReleases, setReleasesPaging } from '../../../../../store/actions/discography/DiscographyAction';
import { setRecordsFilters }                from '../../../../../store/actions/discography/RecordAction';
import Pagination                           from '../../../data/table/Pagination';

const Table = ({ releases, fetch, loading, filters, paging, count, setReleasesPaging, setReleasesFilters }) => {
	const loadingData = [...Array(paging.results_per_page).keys()];
	const { t } = useTranslation();
	const displayLoading = () => loadingData.map((k) => <Row key={k} loading={true} release={null}/>);
	const displayReleases = () => releases.map((u) => <Row key={u.Music_Label_Release__} loading={false}
	                                                       release={u}/>);

	useEffect(() => {
		fetch();
	}, [fetch, filters, paging]);

	/*const {sort} = filters;

	const sortHandler = (property, dir) => {
		let p = {...filters};
		// only 1 sort
		p.sort = {};
		p.sort[property] = dir;
		setReleasesFilters(p);
	};*/

	return (
		<Fragment>
			{(loading || releases.length > 0) &&
			<TableContainer>
				<MaterialTable aria-label='simple table'>
					<TableRow>
						<Pagination
							setPaging={setReleasesPaging}
							paging={paging}
							loading={loading}
							count={count}
						/>
					</TableRow>
					<TableHead>
						<TableRow>
							<TableCell> {t('tablecell_album_picture')}</TableCell>
							<TableCell> {t('tablecell_album_name')}</TableCell>
							<TableCell> {t('tablecell_release_classification')}</TableCell>
							<TableCell> {t('tablecell_album_track_count')}</TableCell>
							<TableCell> {t('tablecell_album_duration')}</TableCell>
							<TableCell align='right'/>
						</TableRow>
					</TableHead>
					<TableBody>
						{loading && displayLoading()}
						{!loading && displayReleases()}
					</TableBody>
					<TableFooter>
						<TableRow>
							<Pagination
								setPaging={setReleasesPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
					</TableFooter>
				</MaterialTable>
			</TableContainer>
			}
			{(!loading && releases.length < 1) && <Empty/>}
		</Fragment>
	);

};


const mapStateToProps = (state) => {
	return {
		filters: state.discography.releasesFilters,
		paging: state.discography.releasesPaging,
		count: state.discography.releasesPagingCount,
		loading: state.discography.releasesLoading,
		releases: state.discography.releases
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: () => dispatch(fetchReleases()),
		setReleasesPaging: (newPaging) => dispatch(setReleasesPaging(newPaging)),
		setRecordsFilters: (newFilters) => dispatch(setRecordsFilters(newFilters))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);

