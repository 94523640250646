import React                  from 'react';
import {connect}              from 'react-redux';
import Dialog                 from '@material-ui/core/Dialog';
import DialogTitle            from '@material-ui/core/DialogTitle';
import DialogContent          from '@material-ui/core/DialogContent';
import DialogActions          from '@material-ui/core/DialogActions';
import Button                 from '../../common/inputs/Button';
import {useTranslation}       from 'react-i18next';
import makeStyles             from '@material-ui/core/styles/makeStyles';
import {Box, Grid}            from '@material-ui/core';
import {
	EmailShareButton,
	FacebookIcon,
	FacebookShareButton,
	LineIcon,
	LineShareButton,
	RedditIcon,
	RedditShareButton,
	TwitterIcon,
	TwitterShareButton,
	WhatsappIcon,
	WhatsappShareButton
}                             from 'react-share';
import EmailIcon              from '@material-ui/icons/Email';
import ClipboardCopyTextField from '../../common/inputs/ClipboardCopyTextField';

const useStyles = makeStyles(theme => ({
	title: {
		color: theme.palette.primary.main,
		textAlign: 'center'
	},
}));


const ShareDialog = ({open, setOpen, registryUrl}) => {
	const {t} = useTranslation();
	const classes = useStyles();
	const handleClose = () => {
		setOpen(false);
	};

	const url = registryUrl && ('https://' + registryUrl.Domain);

	return (
		<Dialog
			disableEnforceFocus
			open={open}
			aria-labelledby={t('share_title')}
			aria-describedby={t('share_title')}
		>
			<DialogTitle className={classes.title}>
				{t('share_title')}
			</DialogTitle>
			<DialogContent>
				<Box py={2}>
					<Grid container spacing={3} justify="center" alignContent="center">
						<Grid xs={12} style={{textAlign: 'center'}}>{t('share_description')}</Grid>
						<Grid item>
							<FacebookShareButton url={url}>
								<FacebookIcon size={48} round={true}/>
							</FacebookShareButton>
						</Grid>
						<Grid item>
							<TwitterShareButton url={url}>
								<TwitterIcon size={48} round={true}/>
							</TwitterShareButton>
						</Grid>
						<Grid item>
							<RedditShareButton url={url}>
								<RedditIcon size={48} round={true}/>
							</RedditShareButton>
						</Grid>
						<Grid item>
							<WhatsappShareButton url={url}>
								<WhatsappIcon size={48} round={true}/>
							</WhatsappShareButton>
						</Grid>
						<Grid item>
							<LineShareButton url={url}>
								<LineIcon size={48} round={true}/>
							</LineShareButton>
						</Grid>
						<Grid item>
							<EmailShareButton url={url}>
								<EmailIcon size={48} round={true}/>
							</EmailShareButton>
						</Grid>

						<Grid item xs={12}>
							<ClipboardCopyTextField value={url} variant="outlined" fullWidth/>
						</Grid>

					</Grid>

				</Box>

			</DialogContent>
			<DialogActions>
				<Button variant="text" color="primary" onClick={handleClose}>
					{t('done_btn')}
				</Button>
			</DialogActions>

		</Dialog>
	);
};

const mapStateToProps = (state) => {
	return {
		registryUrl: state.registry.url,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareDialog);
