import React, {useEffect, useState} from 'react';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {Select} from "@material-ui/core";
import {useTranslation} from 'react-i18next';
import moment from "moment";

const Year = ({onChange, defaultValue = '', inputProps = {}, value = undefined, interval = [-3, 0]}) => {
    const selectLabel = React.useRef(null);
    const [selectLabelWidth, setCurrencyLabelWidth] = useState(0);
    const {t} = useTranslation();

    useEffect(() => {
        setCurrencyLabelWidth(selectLabel.current.offsetWidth);
    }, []);

    const now = parseInt(moment().format('YYYY'));
    let options = [];

    for (let i = now + interval[1]; i >= now + interval[0]; i--) {
        options.push(<option key={'year-' + i} value={i}>{i}</option>)
    }

    return (
        <FormControl {...inputProps}>
            <InputLabel ref={selectLabel} htmlFor='year-select'>{t('year_lbl')}</InputLabel>
            <Select
                native
                value={value}
                onChange={onChange}
                labelWidth={selectLabelWidth}
                inputProps={{
                    id: 'year-select',
                }}
            >
                {options}
            </Select>

        </FormControl>
    );
};

export default Year;