import React              from 'react';
import { useTranslation } from 'react-i18next';
import Select             from './Select';
import { connect }        from 'react-redux';

const SeatTypeSelector = ({ value, setValue, disabled, required, _stream }) => {
	const { t } = useTranslation();

	const options = [
		{ Key: 'seat', Value: t('seat_type_seat') },
		{ Key: 'slot', Value: t('seat_type_slot') },
		{ Key: 'standing', Value: t('seat_type_standing') },
	];

	if (_stream) options.push({ Key: 'stream', Value: t('seat_type_stream') });

	return <Select
		label={t('seat_type')}
		helperText={t('seat_type_helperText')}
		value={value}
		onChange={e => setValue(e.target.value)}
		optionKey='Key'
		optionValue='Value'
		options={options}
		inputProps={{
			disabled: disabled,
			variant: 'outlined',
			fullWidth: true,
			required: required
		}}
	/>;
};

const mapStateToProps = (state) => {
	return {
		_stream: state.template.isStreamSupported,
	};
};

export default connect(mapStateToProps)(SeatTypeSelector);
