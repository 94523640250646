import React, {useEffect, useState} from 'react';
import {Trans, useTranslation}      from 'react-i18next';
import EmptyTags                    from './tags/EmptyTags';
import uuid                         from '../../../utils/uuid';
import {editEntryTags}              from '../../../../store/actions/CmsAction';
import {connect}                            from 'react-redux';
import {CircularProgress, Grid, Typography} from '@material-ui/core';
import Toolbar                              from '../../typography/Toolbar';
import Button                       from '../../inputs/Button';
import {Add}                        from '@material-ui/icons';
import ClassifyTagAutocomplete      from '../../inputs/ClassifyTagAutocomplete';
import CreateCategoryDialog         from './CreateCategoryDialog';

const ArticleTags = ({entryData, disabled = false, editEntryTags, entryManagingTags, isManager, isAdmin, plans, plansLoading, isSelectedFanClub}) => {
	const {t} = useTranslation();
	const [articleTags, setArticleTags] = useState(entryData.Content_Cms_Entry.Tags ?? []);

	const [originalMapTags, setOriginalMapTags] = useState({});
	const [originalLength, setOriginalLength] = useState();

	const [autocompleteKey, setAutoCompleteKey] = useState(uuid());
	const [createDialogOpened, setCreateDialogOpened] = useState(false);

	const [except, setExcept] = useState({});

	const [saveEnabled, setSaveEnabled] = useState(false);


	const refreshAutocompleteList = () => {
		setAutoCompleteKey(uuid());
	};

	useEffect(() => {
		let tags =  entryData.Content_Cms_Entry.Tags ?? [];
		let planTags = {};
		if(isSelectedFanClub && plans) {
			plans.forEach(plan => {
				planTags[plan.Classify_Tag__] = plan.Classify_Tag__;
			})

			tags = tags.filter(t => !(t.Classify_Tag__ in planTags))
			setExcept(planTags);
		}

		let original = [...tags];
		original = original.reduce((obj, cur) => ({...obj, [cur.Classify_Tag__]: cur}), {});

		setArticleTags(tags);
		setOriginalLength(Object.keys(original).length);
		setOriginalMapTags(original);
	}, [entryData, setOriginalMapTags, setArticleTags, setOriginalLength, plans, isSelectedFanClub]);

	useEffect(() => {
		if (originalLength !== articleTags.length) {
			setSaveEnabled(true);
			return;
		}

		for (let i = 0; i < articleTags.length; i++) {
			if (!(articleTags[i].Classify_Tag__ in originalMapTags)) {
				setSaveEnabled(true);
				return;
			}
		}

		setSaveEnabled(false);


	}, [articleTags, setSaveEnabled, originalMapTags, originalLength]);

	const handleSave = (e) => {
		e.preventDefault();
		let toRemove = {...originalMapTags};
		let toAdd = [];

		for (let i = 0; i < articleTags.length; i++) {
			if (articleTags[i].Classify_Tag__ in toRemove) {
				delete toRemove[articleTags[i].Classify_Tag__];
			} else {
				toAdd.push(articleTags[i].Classify_Tag__);
			}
		}

		toRemove = Object.keys(toRemove);

		e.preventDefault();

		editEntryTags(entryData.Content_Cms_Entry__, toAdd, toRemove);
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Toolbar disableGutters title={t('categories')}>
					{isManager &&
					<Button variant="contained" color="primary" disabled={disabled} startIcon={<Add/>}
					        onClick={() => setCreateDialogOpened(true)}>
						{t('add_btn')}
					</Button>
					}
				</Toolbar>
			</Grid>

			{articleTags.length > 0 && <Grid item xs={12}>
				<Typography variant="body2" color="textSecondary" component="p">
					<Trans i18nKey="entry_categories_text">
						sample <br/>
						sample
					</Trans>
				</Typography>
			</Grid>}

			<Grid item xs={12}>
				{(!isSelectedFanClub || (isSelectedFanClub && plans && !plansLoading)) && <ClassifyTagAutocomplete
					except={except}
					isAdmin={isAdmin}
					isManager={isManager}
					disabled={!isManager || entryManagingTags}
					key={autocompleteKey}
					classifyId={entryData.Content_Cms_Entry.Content_Cms.Classify.Classify__}
					value={articleTags}
					setValue={setArticleTags}
				/>}
				{(isSelectedFanClub && (!plans || plansLoading)) && <CircularProgress/>}
			</Grid>

			{articleTags.length < 1 && <Grid item xs={12}><EmptyTags/></Grid>}

			{isManager &&
			<Grid item xs={12}>
				<Grid container justifyContent="flex-end">
					<Button color="primary" variant="contained" loading={entryManagingTags} onClick={handleSave}
					        disabled={!saveEnabled || entryManagingTags || disabled}>
						{t('save_btn')}
					</Button>
				</Grid>
			</Grid>
			}

			{isManager && <CreateCategoryDialog
				open={createDialogOpened}
				setOpen={setCreateDialogOpened}
				classifyId={entryData ? entryData.Content_Cms_Entry.Content_Cms.Classify.Classify__ : ''}
				onCreated={refreshAutocompleteList}
			/>}

		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		entryManagingTags: state.cms.entryManagingTags,
		isManager: state.access.manager,
		isAdmin: state.access.admin,
		plans: state.plan.plans,
		plansLoading: state.plan.plansLoading,
		isSelectedFanClub: state.cms.isSelectedFanClub,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		editEntryTags: (entryId, toAdd, toDelete) => dispatch(editEntryTags(entryId, toAdd, toDelete)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleTags);
