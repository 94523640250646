import React from 'react';
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {getLocale} from "@karpeleslab/klbfw";
import moment from "moment";

const locale = getLocale().split('-')[0];
if (locale !== 'en')
    require('moment/locale/' + locale);

moment.locale(locale);

const DateTime = ({variant, ...props}) => {
    if(variant) props.inputVariant = variant;

    return (
        <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={locale}>
            <DateTimePicker {...props} />
        </MuiPickersUtilsProvider>
    );
};

export default DateTime;