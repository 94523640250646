import {rest} from "@karpeleslab/klbfw";
import {handleError} from "../../../components/utils/apiErrorHandler";
import {LANGUAGE_FETCH, LANGUAGE_FETCH_DONE} from "../../reducers/system/LanguageReducer";

export const fetch = (fetch) => {
    return (dispatch, getState) => {
        if (getState().language.loading) return; // language are loading don't start another request
        if (getState().language.languages.length > 0) return; // language won't changes do not reload them
        dispatch({type: LANGUAGE_FETCH});
        return rest('Language')
            .then(data => {
                dispatch({type: LANGUAGE_FETCH_DONE, languages: data.data});
                return data.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })

    }
};