import React from 'react';
import TableRow from "@material-ui/core/TableRow";
import Skeleton from "@material-ui/lab/Skeleton";
import TableCell from "@material-ui/core/TableCell";
import {connect}                            from 'react-redux'
import Avatar                               from "@material-ui/core/Avatar";
import CardMembershipIcon                   from "@material-ui/icons/CardMembership";
import NavChip                              from "../../../data/chip/NavChip";
import EditIcon                             from "@material-ui/icons/Edit";
import {Link}                               from "react-router-dom";
import MembershipStatus                     from "../../../data/chip/MembershipStatus";
import {MEMBERSHIP_MEMBERS_IMAGE_VARIATION} from "../../../../../store/reducers/fanclub/MembershipReducer";
import {getFanclubSubscriberEditRoute}      from "../../../../../router/routes/fanclub/factory/subscriber";
import {getFanclubPlanEditRoute}            from "../../../../../router/routes/fanclub/factory/plan";
import Button                               from "../../../inputs/Button";
import moment                               from 'moment';

const Row = ({member, loading}) => {
    const displayMember = (!loading && member);
    return (
        <TableRow>
            <TableCell>
                {!displayMember && <Skeleton variant="circle" width={40} height={40}/>}
                {(displayMember && member.User.Profile.Media_Image) &&
                <Avatar src={member.User.Profile.Media_Image[MEMBERSHIP_MEMBERS_IMAGE_VARIATION]}/>}
                {(displayMember && !member.User.Profile.Media_Image) && <Avatar/>}
            </TableCell>
            <TableCell>
                {!displayMember && <Skeleton width={17}/>}
                {displayMember && <span>{'#' + member.Index}</span>}
            </TableCell>
            <TableCell>
                {!displayMember && <Skeleton/>}
                {displayMember && <span>{member.User.Profile.Display_Name}</span>}
            </TableCell>
            <TableCell>
                {!displayMember && <Skeleton/>}
                {displayMember && <span>{member.User.Email ?? '-'}</span>}
            </TableCell>
            <TableCell>
                {!displayMember && <Skeleton/>}
                {displayMember && <MembershipStatus membership={member}/>}
            </TableCell>
            <TableCell>
                {!displayMember && <Skeleton/>}
                {displayMember && <span>{member.Price.tax.display}</span>}
            </TableCell>
            <TableCell align="right">
                {!displayMember && <Skeleton/>}
                {displayMember &&
                <NavChip to={getFanclubPlanEditRoute(member.Membership_Plan__)} variant="outlined" color="primary"
                         icon={<CardMembershipIcon/>}
                         label={member.Membership_Plan.Catalog_Product['Basic.Name']}/>
                }
            </TableCell>
            <TableCell>
                {!displayMember && <Skeleton/>}
                {(displayMember && member.Start) && <span>{moment(parseInt(member.Start.unixms)).format('LL')}</span>}
            </TableCell>
            <TableCell align="right">
                {!displayMember && <Skeleton/>}
                {displayMember && <Button
                    variant="contained"
                    color="primary"
                    startIcon={<EditIcon/>}
                    component={Link} to={getFanclubSubscriberEditRoute(member.Membership_User__)}
                >
                    Edit
                </Button>}
            </TableCell>
        </TableRow>
    );
};

const mapStateToProps = (state, {loading, idx}) => {
    if (loading) return {};
    return {
        member: state.membership.members[idx],
    }
};

export default connect(mapStateToProps, null)(Row);