import { rest }        from '@karpeleslab/klbfw';
import { handleError } from '../../../components/utils/apiErrorHandler';
import {
	STREAM_ARCHIVE_FETCH,
	STREAM_ARCHIVE_FETCH_DONE,
	STREAM_BROADCAST_FETCH,
	STREAM_BROADCAST_FETCH_DONE,
	STREAM_START,
	STREAM_START_DONE
}                      from '../../reducers/planner/StreamReducer';
import { success }     from '../system/ToastAction';

export const fetchStreamBroadcastInfo = (eventId) => {
	return (dispatch, getState) => {
		dispatch({ type: STREAM_BROADCAST_FETCH });
		return rest('Planner/Event/' + eventId + ':getBroadcastInfo', 'POST')
			.then(d => {

				dispatch({
					type: STREAM_BROADCAST_FETCH_DONE,
					info:d.data?.info ?? null,
					status: d.data.status,
				});
				return d.data;
			}).catch((err) => {
				dispatch({ type: STREAM_BROADCAST_FETCH_DONE });
				handleError(getState, dispatch, err);
			});
	};
};

export const startStream = (eventId) => {
	return (dispatch, getState) => {
		dispatch({ type: STREAM_START });
		return rest('Planner/Event/' + eventId + ':startStream', 'POST')
			.then(d => {
				success('stream_started_success');
				dispatch({ type: STREAM_START_DONE, event: d.data });
				return d.data;
			}).catch((err) => {
				dispatch({ type: STREAM_START_DONE });
				handleError(getState, dispatch, err);
			});
	};
};

export const fetchStreamArchive = (eventId) => {
	return (dispatch, getState) => {
		dispatch({ type: STREAM_ARCHIVE_FETCH });
		return rest('Planner/Event/' + eventId + '/Archive', 'GET')
			.then(d => {
				dispatch({ type: STREAM_ARCHIVE_FETCH_DONE, archive: d.data.length > 0 ? d.data[0] : null });
				return d.data;
			}).catch((err) => {
				dispatch({ type: STREAM_ARCHIVE_FETCH_DONE });
				handleError(getState, dispatch, err);
			});
	};
};

