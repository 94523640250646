import React, { useEffect }            from 'react';
import { connect }                     from 'react-redux';
import { useTranslation }              from 'react-i18next';
import Autocomplete                    from '@material-ui/lab/Autocomplete';
import TextField                       from '@material-ui/core/TextField';
import CircularProgress                from '@material-ui/core/CircularProgress';
import { fetchUsers, setUsersFilters } from '../../../store/actions/RealmAction';

const UserAutocomplete = ({ realm, loading, list, fetch, filters, value, setValue, required = false, disabled = false, setFilters }) => {
	const { t } = useTranslation();

	useEffect(() => {
		if (!realm) return;
		setFilters({
			Realm__: realm.Realm__
		});
	}, [setFilters, realm]);

	const onChangeHandler = (e) => {
		if (!realm) return;
		setFilters({ value: e.target.value, Realm__: realm.Realm__ });
	};

	useEffect(() => {
		if (!realm) return;
		fetch();
		// eslint-disable-next-line
	}, [fetch, fetch, filters, realm]);

	return (
		<Autocomplete
			id='user-autocomplete-select'
			onBlur={() => {
				if (!realm) return;
				setFilters({ Realm__: realm.Realm__ });
				fetch();
			}}
			disabled={disabled}
			value={value}
			onChange={(e, value) => setValue(value)}
			getOptionSelected={(option, value) => option.User__ === value.User__}
			getOptionLabel={(option) => option === '' ? '' : option.Email}
			options={list}
			loading={loading}
			renderInput={(params) => (
				<TextField
					{...params}
					required={required}
					label={t('user_autocomplete_lbl')}
					helperText={t('user_autocomplete_helperText')}
					variant='outlined'
					onChange={onChangeHandler}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<React.Fragment>
								{loading ? <CircularProgress color='inherit' size={20}/> : null}
								{params.InputProps.endAdornment}
							</React.Fragment>
						),
					}}
				/>
			)}
		/>
	);

};

const mapStateToProps = (state) => {
	return {
		loading: state.realm.usersLoading,
		list: state.realm.users,
		filters: state.realm.usersFilters,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: () => dispatch(fetchUsers()),
		setFilters: (filters) => dispatch(setUsersFilters(filters))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAutocomplete);
