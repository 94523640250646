import React, {useEffect, useState} from 'react';
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Skeleton from "@material-ui/lab/Skeleton";
import {useTranslation} from 'react-i18next';
import Item from "./Item";


const Order = ({order}) => {
    const [rowSpan, setRowSpan] = useState(3);
    const {t} = useTranslation();
    useEffect(() => {
        if (!order) return;
        let c = 3;
        if (parseInt(order.Subtotals.shipping.value_int)) c += 1;
        if (parseInt(order.Subtotals.discount.value_int)) c += 1;

        setRowSpan(c)

    }, [order]);

    return (
        <TableContainer>
            <Table aria-label="spanning table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center" colSpan={4}>
                            {t('details')}
                        </TableCell>
                        <TableCell align="right">{t('tablecell_price')}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{t('tablecell_desc')}</TableCell>
                        <TableCell>{t('tablecell_status')}</TableCell>
                        <TableCell align="right">{t('tablecell_unit')}</TableCell>
                        <TableCell align="right">{t('tablecell_quantity')}</TableCell>
                        <TableCell align="right">{t('tablecell_sum')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {order && order.Items.map(row => (<Item key={row.Order_Item__} item={row}/>))}
                    {
                        !order &&
                        <TableRow>
                            <TableCell><Skeleton/></TableCell>
                            <TableCell><Skeleton/></TableCell>
                            <TableCell align="right"><Skeleton/></TableCell>
                            <TableCell align="right"><Skeleton/></TableCell>
                            <TableCell align="right"><Skeleton/></TableCell>
                        </TableRow>
                    }

                    <TableRow>
                        <TableCell rowSpan={rowSpan} colspan="2"/>
                        <TableCell colSpan={2}>{t('tablecell_subtotal')}</TableCell>
                        <TableCell align="right">
                            {order && order.Total.display}
                            {!order && <Skeleton/>}
                        </TableCell>
                    </TableRow>

                    {
                        (order && parseInt(order.Subtotals.shipping.value_int) !== 0) &&

                        <TableRow>
                            <TableCell colSpan={2}>{t('tablecell_shipping')}</TableCell>
                            <TableCell align="right">{order.Subtotals.shipping.display}</TableCell>
                        </TableRow>
                    }

                    <TableRow>
                        <TableCell>{t('tablecell_tax')}</TableCell>
                        <TableCell align="right">
                            {order && order.Vat_Rate + '%'}
                            {!order && <Skeleton/>}
                        </TableCell>
                        <TableCell align="right">
                            {order && order.Vat_Amount.display}
                            {!order && <Skeleton/>}
                        </TableCell>
                    </TableRow>

                    {
                        (order && parseInt(order.Subtotals.discount.value_int) !== 0) &&

                        <TableRow>
                            <TableCell colSpan={2}>{t('tablecell_discount')}</TableCell>
                            <TableCell align="right">{order.Subtotals.discount.display}</TableCell>
                        </TableRow>
                    }

                    <TableRow>
                        <TableCell colSpan={2}>{t('tablecell_total')}</TableCell>
                        <TableCell align="right">
                            {order && order.Total_Vat.display}
                            {!order && <Skeleton/>}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default Order;