import React, { useEffect, useState }       from 'react';
import { Grid }                             from '@material-ui/core';
import { Title }                            from '../../typography/Title';
import { useTranslation }                   from 'react-i18next';
import ProductInput                         from '../../inputs/ProductInput';
import { getAttribute, isAttributeUpdated } from '../../../utils/product';
import TextField                            from '@material-ui/core/TextField';
import { connect }                          from 'react-redux';
import Button                               from '../../inputs/Button';
import { updateProduct }                    from '../../../../store/actions/store/ProductAction';
import { deepCopy }                         from '../../../utils/misc';
import FormRequiredField                    from '../../feeback/FormRequiredField';


const Details = ({ productId, attributes, updating, isManager, updateProduct }) => {
	const { t } = useTranslation();

	const [basicName, setBasicName] = useState(deepCopy(getAttribute(attributes, 'Basic.Name') ?? []));
	const [basicKeywords, setBasicKeywords] = useState(deepCopy(getAttribute(attributes, 'Basic.Keywords') ?? []));
	const [basicPriority, setBasicPriority] = useState(deepCopy(getAttribute(attributes, 'Basic.Priority') ?? []));
	const [basicDateRelease, setBasicDateRelease] = useState(deepCopy(getAttribute(attributes, 'Basic.Date_Release') ?? []));
	const [descriptionCatchPhrase, setDescriptionCatchPhrase] = useState(deepCopy(getAttribute(attributes, 'Description.CatchPhrase') ?? []));
	const [descriptionShort, setDescriptionShort] = useState(deepCopy(getAttribute(attributes, 'Description.Short') ?? []));
	const [descriptionAuthor, setDescriptionsAuthor] = useState(deepCopy(getAttribute(attributes, 'Description.Author') ?? []));
	const [descriptionPieces, setDescriptionsPieces] = useState(deepCopy(getAttribute(attributes, 'Description.Pieces') ?? []));

	const nativeName = getAttribute(attributes, 'Native.Internal_Name') ?? [];
	const [nativeInternalName, setNativeInternalName] = useState(nativeName.length > 0 ? nativeName[0].Value : '');

	const tmpAuthorCode = getAttribute(attributes, 'Description.AuthorCode') ?? [];
	const [authorCode, setAuthorCode] = useState(tmpAuthorCode.length > 0 ? tmpAuthorCode[0].Value : '');


	const [saveEnabled, setSaveEnabled] = useState(false);

	useEffect(() => {
		setBasicName(deepCopy(getAttribute(attributes, 'Basic.Name') ?? []));
		setBasicPriority(deepCopy(getAttribute(attributes, 'Basic.Priority') ?? []));
		setBasicKeywords(deepCopy(getAttribute(attributes, 'Basic.Keywords') ?? []));
		setBasicDateRelease(deepCopy(getAttribute(attributes, 'Basic.Date_Release') ?? []));
		setDescriptionCatchPhrase(deepCopy(getAttribute(attributes, 'Description.CatchPhrase') ?? []));
		setDescriptionShort(deepCopy(getAttribute(attributes, 'Description.Short') ?? []));
		setDescriptionsAuthor(deepCopy(getAttribute(attributes, 'Description.Author') ?? []));
		setDescriptionsPieces(deepCopy(getAttribute(attributes, 'Description.Pieces') ?? []));

		const nativeName = getAttribute(attributes, 'Native.Internal_Name') ?? [];
		setNativeInternalName(nativeName.length > 0 ? nativeName[0].Value : '');

		const tmpAuthorCode = getAttribute(attributes, 'Description.AuthorCode') ?? [];
		setAuthorCode(tmpAuthorCode.length > 0 ? tmpAuthorCode[0].Value : '');

	}, [attributes, setBasicName, setAuthorCode, setBasicPriority, setNativeInternalName, setDescriptionsPieces, setBasicKeywords, setBasicDateRelease, setDescriptionCatchPhrase, setDescriptionsAuthor, setDescriptionShort]);

	useEffect(() => {
		if (!nativeInternalName.trim() || authorCode.hasKanji()) {
			setSaveEnabled(false);
			return;
		}
		const nativeName = getAttribute(attributes, 'Native.Internal_Name') ?? [];
		if (nativeInternalName.trim() !== (nativeName.length > 0 ? nativeName[0].Value : '')) {
			setSaveEnabled(true);
			return;
		}

		const tmpAuthorCode = getAttribute(attributes, 'Description.AuthorCode') ?? [];
		if (authorCode.trim() !== (tmpAuthorCode.length > 0 ? tmpAuthorCode[0].Value : '')) {
			setSaveEnabled(true);
			return;
		}

		if (isAttributeUpdated(attributes, 'Basic.Name', basicName) ||
			isAttributeUpdated(attributes, 'Basic.Priority', basicPriority) ||
			isAttributeUpdated(attributes, 'Basic.Keywords', basicKeywords) ||
			isAttributeUpdated(attributes, 'Basic.Date_Release', basicDateRelease) ||
			isAttributeUpdated(attributes, 'Description.CatchPhrase', descriptionCatchPhrase) ||
			isAttributeUpdated(attributes, 'Description.Short', descriptionShort) ||
			isAttributeUpdated(attributes, 'Description.Author', descriptionAuthor) ||
			isAttributeUpdated(attributes, 'Description.Pieces', descriptionPieces)) {

			setSaveEnabled(true);
			return;
		}

		setSaveEnabled(false);
	}, [attributes, basicName, nativeInternalName, authorCode, basicKeywords, basicPriority, basicDateRelease, descriptionCatchPhrase, descriptionPieces, descriptionAuthor, descriptionShort, setSaveEnabled]);


	const saveHandler = () => {
		const data = [];

		const buildData = (value, key) => {
			value.forEach(v => {
				data.push({
					Variable: key,
					Language__: v.Language__,
					Realm__: null,
					Value: v.Value,
				});
			});
		};
		// Because updating a property is quite heavy (updating children etc), we need to send only the props that have changed
		const nativeName = getAttribute(attributes, 'Native.Internal_Name') ?? [];
		if (nativeInternalName.trim() !== (nativeName.length > 0 ? nativeName[0].Value : '')) {
			data.push({
				Variable: 'Native.Internal_Name',
				Language__: null,
				Realm__: null,
				Value: nativeInternalName.trim(),
			});
		}

		const tmpAuthorCode = getAttribute(attributes, 'Description.AuthorCode') ?? [];
		if (authorCode.trim() !== (tmpAuthorCode.length > 0 ? tmpAuthorCode[0].Value : '')) {
			data.push({
				Variable: 'Description.AuthorCode',
				Language__: null,
				Realm__: null,
				Value: authorCode.trim(),
			});
		}

		if (isAttributeUpdated(attributes, 'Basic.Name', basicName))
			buildData(basicName, 'Basic.Name');

		if (isAttributeUpdated(attributes, 'Basic.Keywords', basicKeywords))
			buildData(basicKeywords, 'Basic.Keywords');

		if (isAttributeUpdated(attributes, 'Basic.Priority', basicPriority))
			buildData(basicPriority, 'Basic.Priority');

		if (isAttributeUpdated(attributes, 'Basic.Date_Release', basicDateRelease))
			buildData(basicDateRelease, 'Basic.Date_Release');

		if (isAttributeUpdated(attributes, 'Description.CatchPhrase', descriptionCatchPhrase))
			buildData(descriptionCatchPhrase, 'Description.CatchPhrase');

		if (isAttributeUpdated(attributes, 'Description.Short', descriptionShort))
			buildData(descriptionShort, 'Description.Short');

		if (isAttributeUpdated(attributes, 'Description.Author', descriptionAuthor))
			buildData(descriptionAuthor, 'Description.Author');

		if (isAttributeUpdated(attributes, 'Description.Pieces', descriptionPieces))
			buildData(descriptionPieces, 'Description.Pieces');

		updateProduct(productId, data);
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Title>{t('product_details_title')}</Title>
			</Grid>

			<FormRequiredField/>

			<Grid item xs={12} md={6}>
				<ProductInput
					allowDelete={false}
					productId={productId}
					fullWidth
					value={basicName}
					setValue={setBasicName}
					disabled={!isManager || updating}
					label={t('product_basic_name')}
					helperText={t('product_basic_name_helperText')}
					variant='outlined'/>
			</Grid>

			<Grid item xs={12} md={6}>
				<TextField label={t('product_internal_name')}
				           fullWidth variant='outlined'
				           value={nativeInternalName}
				           disabled={!isManager || updating}
				           required
				           helperText={t('product_internal_name_helperText')}
				           onChange={e => setNativeInternalName(e.target.value)}/>
			</Grid>

			<Grid item xs={12} md={12}>
				<ProductInput
					allowDelete={false}
					productId={productId}
					fullWidth
					value={descriptionCatchPhrase}
					setValue={setDescriptionCatchPhrase}
					disabled={!isManager || updating}
					label={t('product_description_catch_phrase')}
					helperText={t('product_description_catch_phrase_helperText')}
					variant='outlined'/>
			</Grid>

			<Grid item xs={12} md={12}>
				<ProductInput
					allowDelete={false}
					productId={productId}
					fullWidth
					value={descriptionShort}
					setValue={setDescriptionShort}
					multiline rows={5}
					rowsMax={Infinity}
					disabled={!isManager || updating}
					label={t('product_description_short')}
					helperText={t('product_description_short_helperText')}
					variant='outlined'/>
			</Grid>

			<Grid item xs={12} md={12}>
				<ProductInput
					allowDelete={false}
					productId={productId}
					fullWidth
					value={basicKeywords}
					setValue={setBasicKeywords}
					disabled={!isManager || updating}
					label={t('product_basic_keywords')}
					helperText={t('product_basic_keywords_helperText')}
					variant='outlined'/>
			</Grid>

			<Grid item xs={12} md={6}>
				<ProductInput
					allowDelete={false}
					productId={productId}
					fullWidth
					value={descriptionAuthor}
					setValue={setDescriptionsAuthor}
					disabled={!isManager || updating}
					label={t('product_description_author')}
					helperText={t('product_description_author_helperText')}
					variant='outlined'/>
			</Grid>

			<Grid item xs={12} md={6}>
				<ProductInput
					allowDelete={false}
					productId={productId}
					fullWidth
					type='date'
					value={basicDateRelease}
					setValue={setBasicDateRelease}
					disabled={!isManager || updating}
					label={t('product_basic_date_release')}
					helperText={t('product_basic_date_release_helperText')}
					variant='outlined'/>
			</Grid>

			<Grid item xs={12} md={6}>
				<ProductInput
					allowDelete={false}
					productId={productId}
					fullWidth
					type='number'
					inputProps={{
						min: 0
					}}
					value={descriptionPieces}
					setValue={setDescriptionsPieces}
					disabled={!isManager || updating}
					label={t('product_description_pieces')}
					helperText={t('product_description_pieces_helperText')}
					variant='outlined'/>
			</Grid>

			<Grid item xs={12} md={6}>
				<ProductInput
					allowDelete={false}
					productId={productId}
					fullWidth
					type='number'
					inputProps={{
						min: 0
					}}
					value={basicPriority}
					setValue={setBasicPriority}
					disabled={!isManager || updating}
					label={t('product_basic_priority')}
					helperText={t('product_basic_priority_helperText')}
					variant='outlined'/>
			</Grid>

			<Grid item xs={12}>
				<TextField
					label={t('product_author_code')}
					fullWidth variant='outlined'
					value={authorCode}
					disabled={!isManager || updating}
					helperText={authorCode.hasKanji() ? t('product_author_code_error') : t('product_author_code_helperText')}
					onChange={e => setAuthorCode(e.target.value.toLowerCase().replaceSpecialChars())}
					error={authorCode.hasKanji()}
				/>
			</Grid>

			{isManager &&
			<Grid item xs={12}>
				<Grid container justify='flex-end'>

					<Button onClick={saveHandler} loading={updating} color='primary' variant='contained'
					        disabled={!saveEnabled || updating}>
						{t('save_btn')}
					</Button>

				</Grid>
			</Grid>
			}
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		isManager: state.access.manager,
		updating: false,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateProduct: (id, data) => dispatch(updateProduct(id, data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);
