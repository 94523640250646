import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import MuiTableContainer from "@material-ui/core/TableContainer/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {useTranslation} from "react-i18next";
import {getSiteOrderEditRoute} from "../../../../router/routes/site/factory/order";
import {getSiteUserEditRoute} from "../../../../router/routes/site/factory/user";
import RichAlert from "../../feeback/RichAlert";
import Link from "../../typography/Link";

export const TableContainer = withStyles(theme => {
    return {
        root: {
            overflow: 'auto',
            maxHeight: 300,
        },
    }
})(MuiTableContainer);

const ImportExportErrorsTable = ({errors, isExport = true}) => {
    const {t} = useTranslation();

    return (
        <>
            <RichAlert severity='error'>
                {t(isExport ? 'shipping_export_errors' : 'shipping_import_errors')}
            </RichAlert>

            <TableContainer>
                <Table size='small' stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell> {t('tablecell_shipping_item')}</TableCell>
                            <TableCell> {t('tablecell_shipping_order_number')}</TableCell>
                            <TableCell> {t('tablecell_shipping_user')}</TableCell>
                            <TableCell> {t('tablecell_shipping_export_error')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {errors.map(({Catalog_Shipping, Message}) =>
                        <TableRow>
                            <TableCell> {Catalog_Shipping.Item.Catalog_Product['Basic.Name']}</TableCell>
                            <TableCell> {<Link
                                to={getSiteOrderEditRoute(Catalog_Shipping.Order.Order__)}>{Catalog_Shipping.Order.Invoice_Number}</Link>}</TableCell>
                            <TableCell> {<Link
                                to={getSiteUserEditRoute(Catalog_Shipping.Order.User__)}>{Catalog_Shipping.Order.User.Profile.Display_Name}</Link>}</TableCell>
                            <TableCell> {Message}</TableCell>
                        </TableRow>
                    )}
                </Table>
            </TableContainer>
        </>
    );
};

export default ImportExportErrorsTable;