import React, {useEffect} from 'react';
import TableHead from "@material-ui/core/TableHead";
import MaterialTable from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Row from "./Row";
import {fetchPlans} from "../../../../../store/actions/fanclub/PlanAction";
import {connect} from "react-redux";
import {useTranslation} from 'react-i18next';

const Table = ({selectedMembership, fetch, plans, loading}) => {
    useEffect(() => {
        fetch();
    }, [selectedMembership, fetch]);
    const {t} = useTranslation();
    const displayLoading = () => [...Array(5).keys()].map((k) => <Row key={k} plan={null}/>);
    const displayPlans = () => plans.map((p, idx) => <Row key={p.Membership_Plan__} plan={p}/>);

    return (
        <TableContainer>
            <MaterialTable aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>{t('tablecell_visible')}</TableCell>
                        <TableCell>{t('tablecell_display_order')}</TableCell>
                        <TableCell>{t('tablecell_name')}</TableCell>
                        <TableCell align="right">{t('tablecell_created')}</TableCell>
                        <TableCell align="right"/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading && displayLoading()}
                    {!loading && displayPlans()}
                </TableBody>
            </MaterialTable>
        </TableContainer>
    );
};

const mapStateToProps = (state) => {
    return {
        loading: state.plan.plansLoading,
        plans: state.plan.plans
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetch: () => dispatch(fetchPlans())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);