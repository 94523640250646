import { rest }        from '@karpeleslab/klbfw';
import { success }     from '../system/ToastAction';
import { handleError } from '../../../components/utils/apiErrorHandler';
import {
	RELEASE_FETCH,
	RELEASE_FETCH_DONE,
	RELEASE_TAGS_FETCH,
	RELEASE_TAGS_FETCH_DONE,
	RELEASE_UPDATE,
	RELEASE_UPDATE_DONE,
	RELEASE_UPDATE_TAGS,
	RELEASE_UPDATE_TAGS_DONE
}                      from '../../reducers/discography/ReleaseReducer';

export const fetchRelease = (id) => {
	return (dispatch, getState) => {
		dispatch({ type: RELEASE_FETCH });
		return rest('Music/Label/Release/' + id)
			.then(data => {
				dispatch({ type: RELEASE_FETCH_DONE, release: data.data });
				return data.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};


export const editReleaseTags = (releaseId, toAdd = [], toDel = []) => {
	return (dispatch, getState) => {
		dispatch({ type: RELEASE_UPDATE_TAGS });
		return new Promise((resolve, reject) => {
			let p = [];
			if (toAdd.length > 0)
				p.push(rest('Music/Label/Release/' + releaseId + ':addTag', 'POST', { tag: toAdd }));
			if (toDel.length > 0)
				p.push(rest('Music/Label/Release/' + releaseId + ':delTag', 'POST', { tag: toDel }));

			Promise.all(p)
				.then((promisesResults) => {
					//promisesResults will contain an array of the result of all promise, we will take last one to have the more update entry data
					resolve(promisesResults[promisesResults.length - 1]);
				})
				.catch(reject);
		}).then((d) => {
			success('release_tags_updated_success');
			dispatch({ type: RELEASE_UPDATE_TAGS_DONE, release: d.data });
			return d.data;
		})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const fetchTags = (classifyId) => {
	return (dispatch, getState) => {
		dispatch({ type: RELEASE_TAGS_FETCH });
		return rest('Classify/' + classifyId + ':describeAllTags')
			.then((d) => {
				dispatch({ type: RELEASE_TAGS_FETCH_DONE, tags: d.data });
				return d.data;
			});
	};
};

export const updateRelease = (releaseId, data) => {
	return (dispatch, getState) => {
		dispatch({ type: RELEASE_UPDATE });
		return rest('Music/Label/Release/' + releaseId, 'PATCH', data)
			.then(data => {
				success('release_update_success')
				dispatch({ type: RELEASE_UPDATE_DONE, release: data.data });
				return data.data;
			})
			.catch((err) => {
				dispatch({ type: RELEASE_UPDATE_DONE });
				handleError(getState, dispatch, err);
			});
	};
};
