import React, {useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions                from "@material-ui/core/DialogActions";
import {connect}                    from 'react-redux'
import Button                       from "../../inputs/Button";
import {createCoupon, fetchCoupons} from "../../../../store/actions/store/CouponAction";
import DateTime                     from "../../inputs/DateTime";
import CouponTargetSelector         from "../../inputs/CouponTargetSelector";
import ProductAutocomplete          from "../../../../pages/store/coupon/ProductAutocomplete";
import CouponTypeSelector           from "../../inputs/CouponTypeSelector";
import Currencies                   from "../../inputs/Currencies";
import {TextField}                  from "@material-ui/core";
import {getCurrency}                from "@karpeleslab/klbfw";
import FormRequiredField            from '../../feeback/FormRequiredField';

const CreateDialog = ({catalog, open, setOpen, creating, createCoupon, refreshCoupons, setNewCode}) => {
    const {t} = useTranslation();
    const [captionText, setCaptionText] = useState('');
    const [couponTarget, setCouponTarget] = useState('global');
    const [couponType, setCouponType] = useState('once');
    const now = new Date();
    const [expiry, setExpiry] = useState(new Date(now.setMonth(now.getMonth() + 1)).toUTCString());
    const [reducCurrency, setReducCurrency] = useState(getCurrency());
    const [reducMaxValue, setReducMaxValue] = useState(null);
    const [reductionType, setReductionType] = useState('rate');
    const [reducValue, setReducValue] = useState();
    const [product, setProduct] = useState(null);

    const [saveDisabled, setSaveDisabled] = useState(true);

    useEffect(() => {
        if ((couponTarget === "product" && !product) ||
            !reducValue ||
            !expiry ||
            !reductionType ||
            (!!reducMaxValue && !reducCurrency) ||
            (reductionType === 'fix' && !!reducValue && !reducCurrency)
        ) {
            setSaveDisabled(true);
            return;
        }

        setSaveDisabled(false);

    }, [couponTarget, couponType, expiry, reductionType, reducValue, product, setSaveDisabled, reducCurrency, reducMaxValue]);

    const reset = () => {
        setCaptionText('')
        setCouponTarget('global');
        setCouponType('once');
        const now = new Date();
        setExpiry(new Date(now.setMonth(now.getMonth() + 1)).toUTCString());
        setReducCurrency(getCurrency());
        setReducMaxValue('');
        setReductionType('rate');
        setReducValue('');
        setProduct(null);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleCreate = (e) => {
        e.preventDefault();

        const params = {
            Type: couponType,
            Expiration: expiry,
            Target_Type: couponTarget,
            Reduction_Type: reductionType,
            Reduction_Value: reducValue,
            Caption_Type: captionText.trim() ? 'static' : 'none'
        };

        if (couponTarget !== 'global' && product)
            params.Target_Value = product.Catalog_Product__;

        if (reducMaxValue)
            params.Reduction_Max_Amount = reducMaxValue;

        if (reducCurrency)
            params.Reduction_Currency = reducCurrency;

        if (params.Caption_Type !== 'none')
            params.Caption_Value = captionText;


        createCoupon(params)
            .then(d => setNewCode(d.Code))
            .then(() => refreshCoupons())
            .then(() => handleClose())
    };

    return (
        <Dialog
            open={open}
            aria-labelledby={t('create_coupon')}
            aria-describedby={t('create_coupon')}
            fullWidth
            maxWidth='lg'
            onEnter={() => reset()}
        >
            <form onSubmit={handleCreate}>
                <DialogTitle
                    id="create-coupon-title">{t('create_coupon')}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <FormRequiredField/>
                        <Grid item md={6}>
                            <DateTime
                                fullWidth
                                variant='outlined'
                                label={t('coupon_expires')}
                                required
                                value={expiry}
                                setValue={setExpiry}
                                disabled={creating}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CouponTypeSelector
                                value={reductionType}
                                setValue={setReductionType}
                                required
                                disabled={creating}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required={true}
                                name="reduction-value"
                                label={t('coupon_reduction_value')}
                                type="number"
                                id="coupon-reduction-value"
                                value={reducValue}
                                onChange={(e) => setReducValue(e.target.value)}
                                InputProps={{
                                    endAdornment: reductionType === 'rate' && '%',
                                    inputProps: {
                                        min: 0,
                                        max: reductionType === 'rate' ? 100 : undefined,
                                    }
                                }}
                                disabled={creating}
                            />

                        </Grid>
                        <Grid item xs={6}>
                            {reductionType === "fix" && <Currencies
                                onChange={(e) => setReducCurrency(e.target.value)}
                                value={reducCurrency}
                                inputProps={{
                                    variant: "outlined",
                                    disabled: creating,
                                    fullWidth: true,
                                    required: true,
                                }}
                            />
                            }
                        </Grid>
                        {reductionType === "rate" && <>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    name="reduction-value"
                                    label={t('coupon_reduction_max_value')}
                                    helperText={t('coupon_reduction_max_value_helperText')}
                                    type="number"
                                    id="coupon-reduction-max-value"
                                    value={reducMaxValue}
                                    onChange={(e) => setReducMaxValue(e.target.value)}
                                    required={false}
                                    disabled={creating}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Currencies
                                    onChange={(e) => setReducCurrency(e.target.value)}
                                    value={reducCurrency}
                                    inputProps={{
                                        variant: "outlined",
                                        disabled: creating,
                                        fullWidth: true,
                                        required: true,
                                    }}
                                />
                            </Grid>
                        </>
                        }
                        <Grid item xs={6}>
                            <CouponTargetSelector
                                value={couponTarget}
                                setValue={setCouponTarget}
                                required
                                disabled={creating}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            {couponTarget === 'product' &&
                            <ProductAutocomplete
                                catalogId={catalog.Catalog__}
                                onProductSelected={setProduct}
                            />}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label={t('coupon_caption')}
                                helperText={t('coupon_caption_helperText')}
                                type="text"
                                value={captionText}
                                onChange={(e) => setCaptionText(e.target.value)}
                                required={false}
                                disabled={creating}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="default" onClick={handleClose} disabled={creating}>
                        {t('close_btn')}
                    </Button>
                    <Button color="primary" type='submit' variant='contained'
                            disabled={creating || saveDisabled}
                            loading={creating}>
                        {t('create_btn')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>

    );
};

const mapStateToProps = (state) => {
    return {
        creating: state.product.creating,
        catalog: state.store.catalog,
        realm: state.realm.selected,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        createCoupon: (params) => dispatch(createCoupon(params)),
        refreshCoupons: () => dispatch(fetchCoupons()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDialog);