import React, {Fragment}              from 'react';
import Toolbar                        from '@material-ui/core/Toolbar';
import {StyledAppBar}                 from './style';
import {connect}                      from 'react-redux';
import {logout}                       from '../../store/actions/system/UserActions';
import Typography                     from '@material-ui/core/Typography';
import makeStyles                     from '@material-ui/core/styles/makeStyles';
import ProfileMenu                    from './appBar/ProfileMenu';
import Logo                           from '../../resources/images/logo-only-color.png';
import {Link}                         from 'react-router-dom';
import {getPXLDashboardRoute}         from '../../router/routes/pxl/factory/general';
import RealmSection                   from './appBar/RealmSection';
import useTheme                       from '@material-ui/core/styles/useTheme';
import useMediaQuery                  from '@material-ui/core/useMediaQuery';
import {CircularProgress, IconButton} from '@material-ui/core';
import {Menu}                         from '@material-ui/icons';
import {hideMenu, setMenu}            from '../../store/actions/CommonAction';
import {MENU_MOBILE}                  from '../../store/reducers/CommonReducer';
import MobileMenu                     from './drawer/MobileMenu/MobileMenu';

const useStyles = makeStyles(theme => ({
	title: {
		flexGrow: 1,
		overflow: 'hidden'
	},
	logo: {
		marginLeft: '32px',
		marginRight: '32px',
		width: '63px'
	}
}));

const AppBar = ({user, title, setMenu, menu, hideMenu, ready}) => {
	const classes = useStyles();
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down('sm'));
	if (!user) return <Fragment/>;
	return (
		<StyledAppBar position="absolute" elevation={0}>
			<Toolbar disableGutters>
				<Link to={getPXLDashboardRoute()}><img className={classes.logo} src={Logo} alt="logo"/></Link>
				<div className={classes.title}>
					<Typography
						component="span"
						variant="h6"
						color="inherit"
						noWrap
					>
						{title}
					</Typography>
				</div>

				<ProfileMenu/>
				{!mobile && <RealmSection/>}
				{mobile && <>
					{ready && <IconButton onClick={() => menu ? hideMenu() : setMenu(MENU_MOBILE)}>
						<Menu/>
					</IconButton>
					}
					{!ready && <CircularProgress size={18} style={{marginRight:'6px'}}/>}
					<MobileMenu/>
				</>}
			</Toolbar>
		</StyledAppBar>
	);
};

const mapStateToProps = (state) => {
	return {
		title: state.common.title,
		user: state.user.user,
		menu: state.common.menu,
		ready: state.common.ready,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		logout: () => dispatch(logout()),
		setMenu: (menu) => dispatch(setMenu(menu)),
		hideMenu: () => dispatch(hideMenu())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AppBar);
