import React, {useEffect, useState}  from 'react';
import {connect}                     from 'react-redux';
import UsersTable                    from '../../components/common/user/table/Table';
import Grid                          from '@material-ui/core/Grid';
import SelectRealm                   from '../SelectRealm';
import {setUsersFilters}             from '../../store/actions/RealmAction';
import {REALM_USERS_IMAGE_VARIATION} from '../../store/reducers/RealmReducer';
import AppBarTitle                   from '../../components/common/typography/AppBarTitle';
import {useTranslation}              from 'react-i18next';
import Filters                       from '../../components/common/user/table/Filters';
import Toolbar                       from '../../components/common/typography/Toolbar';
import {useLocation}                 from 'react-router-dom';
import queryString                   from 'query-string';

const Users = ({selectedRealm, setUsersFilters}) => {
	// This prevent from loading the table without the filter set
	const [enableTableDisplay, setEnableTableDisplay] = useState(false);
	const {t} = useTranslation();

	// Dirty shit
	const location = useLocation();

	useEffect(() => {
		if (selectedRealm) {
			let params = queryString.parse(location.search);
			const f = {
				Realm__: selectedRealm.Realm__,
				image_variation: REALM_USERS_IMAGE_VARIATION,
				Status: 'valid'
			};
			if (params.s) f.value = params.s;
			setUsersFilters(f);
			setEnableTableDisplay(true);
		} else {
			setEnableTableDisplay(false);
		}
		// eslint-disable-next-line
	}, [selectedRealm, setUsersFilters]);

	if (!selectedRealm) return <SelectRealm/>;

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('users_title')}/>

			<Grid item xs={12}>
				<Toolbar title={t('users_title')}/>
			</Grid>
			<Grid item xs={12}>
				<Filters/>
			</Grid>
			<Grid item xs={12}>
				<UsersTable disabled={!enableTableDisplay} realm={selectedRealm}/>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setUsersFilters: (newPaging) => dispatch(setUsersFilters(newPaging))
	};
};


export default connect(mapStateToProps, mapDispatchToProps)(Users);
