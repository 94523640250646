import React, {useState} from 'react';
import TableRow          from '@material-ui/core/TableRow';
import Skeleton          from '@material-ui/lab/Skeleton';
import TableCell         from '@material-ui/core/TableCell';
import {useTranslation}  from 'react-i18next';
import Button            from '../../../../../../inputs/Button';
import EditIcon          from '@material-ui/icons/Edit';
import Grid              from '@material-ui/core/Grid';
import {DeleteForever}   from '@material-ui/icons';
import DeleteDialog      from '../DeleteDialog';
import EditDialog        from '../EditDialog';


const Row = ({value, loading}) => {
	const displayFee = (!loading && value);
	const {t} = useTranslation();
	const [showDelete, setShowDelete] = useState(false);
	const [showEdit, setShowEdit] = useState(false);


	return (
		<TableRow>
			<TableCell>
				{!displayFee && <Skeleton/>}
				{displayFee && <span>{value?.Country?.Name ?? t('all')}</span>}
			</TableCell>

			<TableCell>
				{!displayFee && <Skeleton/>}
				{displayFee && <span>{(!value.Province || !value.Province.trim()) ? t('all') : value.Province}</span>}
			</TableCell>


			<TableCell align="right">
				{!displayFee && <Skeleton/>}
				{(displayFee) && <Grid container spacing={3} justify="space-between">
					<Grid item>
						<Button
							variant="outlined"
							color="secondary"
							onClick={() => setShowDelete(true)}
							startIcon={<DeleteForever/>}
						>
							{t('delete_btn')}
						</Button>
					</Grid>

					<Grid item>
						<Button
							variant="contained"
							color="primary"
							onClick={() => setShowEdit(true)}
							startIcon={<EditIcon/>}
						>
							{t('edit_btn')}
						</Button>
					</Grid>
				</Grid>}
			</TableCell>

			{showDelete && <DeleteDialog setOpen={setShowDelete} open={showDelete} value={value}/>}
			{showEdit && <EditDialog setOpen={setShowEdit} open={showEdit} value={value}/>}

		</TableRow>
	);
};


export default Row;