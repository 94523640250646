import React from 'react';
import Grid from "@material-ui/core/Grid";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import {useTranslation} from "react-i18next";
import Introduction from "./StepIntroduction";
import StepArtist from "./StepArtist";
import StepMembers from "./StepMembers";
import StepValidate from "./StepValidate";
import CreationStep from "./CreationStep";
import StepCustomize from "./StepCustomize";

const Wizard = () => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [members, setMembers] = React.useState([]);
    const [artist, setArtist] = React.useState({});
    const [template, setTemplate] = React.useState(null);
    const [settings, setSettings] = React.useState({});
    const [settingFiles, setSettingFiles] = React.useState({});

    const {t} = useTranslation();

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const getStepContent = () => {
        switch (activeStep) {
            case 0:
                return <Introduction handleNext={handleNext} handleBack={handleBack}/>;
            case 1:
                return <StepArtist handleNext={handleNext} handleBack={handleBack} artist={artist}
                                   setArtist={setArtist}/>;
            case 2:
                return <StepMembers handleNext={handleNext} handleBack={handleBack} members={members}
                                    setMembers={setMembers}/>;
            case 3:
                return <StepCustomize handleNext={handleNext} handleBack={handleBack} template={template}
                                      setTemplate={setTemplate} settings={settings} setSettings={setSettings}
                                      settingFiles={settingFiles} setSettingFiles={setSettingFiles}
                />;
            case 4:
                return <StepValidate handleNext={handleNext} handleBack={handleBack} artist={artist}
                                     members={members}/>;
            case 5:
                return <CreationStep handleNext={handleNext} handleBack={handleBack} artist={artist}
                                     members={members} template={template} settings={settings} settingFiles={settingFiles}/>;
            default:

                return <div/>
        }
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    <Step>
                        <StepLabel>{t('create_artist_step_1_title')}</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>{t('create_artist_step_2_title')}</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>{t('create_artist_step_3_title')}</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>{t('create_artist_step_4_title')}</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>{t('create_artist_step_5_title')}</StepLabel>
                    </Step>
                </Stepper>
            </Grid>
            <Grid item xs={12}>
                {getStepContent()}
            </Grid>
        </Grid>
    );
};

export default Wizard;
