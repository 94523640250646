import Profile from "../../../pages/Profile";
import {getPXLProfileRoute} from "./factory/profile";

export default [
    {
        path: getPXLProfileRoute(),
        component: Profile,
        exact: false,
        type: 'route',
        loginRequired: true,
    },
]