import React, { useEffect, useState }          from 'react';
import { connect }                             from 'react-redux';
import Grid                                    from '@material-ui/core/Grid';
import { useTranslation }                      from 'react-i18next';
import IconButton                              from '@material-ui/core/IconButton';
import SearchIcon                              from '@material-ui/icons/Search';
import TextField                               from '@material-ui/core/TextField';
import ClearIcon                               from '@material-ui/icons/Clear';
import { setEntriesFilters, setEntriesPaging } from '../../../../store/actions/CmsAction';
import { useHistory, useLocation }             from 'react-router-dom';
import queryString                             from 'query-string';
import CmsTagsAutocomplete                     from '../../inputs/CmsTagsAutocomplete';
import { arrayEquals }                         from '../../../utils/misc';

const Filters = ({ cms, disabled = false, setEntriesFilters, setEntriesPaging, filters, loading }) => {
	const { t } = useTranslation();

	const location = useLocation();
	let params = queryString.parse(location.search);
	const [search, setSearch] = useState(filters?.query?.all || params.s);
	const [tags, setTags] = useState(filters?.query?.tag || (Array.isArray(params.t) ? params.t : (!!params.t ? [params.t] : [])));


	const history = useHistory();

	const handleSearchChange = e => {
		if (e.key !== 'Enter')
			return;

		filterHandler();
	};

	const filterHandler = (clear = false) => {
		let params = queryString.parse(location.search);
		delete params.s;
		delete params.t;
		params.p = 1;
		if (!clear && search && search.trim()) params.s = search;
		if (!clear && tags?.length) {
			params.t = tags;
		}
		history.push(location.pathname + '?' + queryString.stringify(params));
	};

	useEffect(() => {
		let params = queryString.parse(location.search);
		let newFilters = null;

		if ((!!params.s && filters?.query?.all !== params.s)) {
			newFilters = { all: params.s };
		}

		if (!!params.t && !arrayEquals((filters?.query?.tag ?? []), params.t)) {
			newFilters = { ...(newFilters ?? {}), tag: params.t };
		}


		if (newFilters !== null) {
			setEntriesFilters({ ...filters, query: { ...newFilters } });
			setEntriesPaging({ page_no: 1 });
			return;
		}

		if (!params.s || !params.t) {
			let tmp = { ...filters };
			if (!params.s && ('query' in tmp)) delete tmp.query.all;
			if (!params.t && ('query' in tmp)) delete tmp.query.tag;
			if (('query' in tmp) && Object.keys(tmp.query).length < 1) {
				delete tmp.query;
			}

			setEntriesFilters(tmp);
			setEntriesPaging({ page_no: 1 });
		}
		// eslint-disable-next-line
	}, [location]);

	const clearFiltersHandler = () => {
		setSearch('');
		setTags([]);
		filterHandler(true);
	};

	useEffect(() => {
		return () => {
			setEntriesFilters({});
		};
		// eslint-disable-next-line
	}, []);

	return (

		<Grid container item xs={12} spacing={3}>
			<Grid item xs={12} md={5}>
				<TextField
					fullWidth
					variant='outlined'
					value={search}
					onKeyPress={handleSearchChange}
					onChange={e => setSearch(e.target.value)}
					placeholder={t('placeholder_search_releases')}
				/>
			</Grid>
			<Grid item xs={12} md={5}>
				<CmsTagsAutocomplete
					value={tags}
					setValue={setTags}
					cms={cms}
				/>
			</Grid>

			<Grid item xs={12} md={2}>
				<IconButton
					aria-label={t('placeholder_search_releases')}
					onClick={() => filterHandler()}
					disabled={!(search || tags?.length > 0)}
				>
					<SearchIcon/>

				</IconButton>
				<IconButton
					aria-label={t('clear_filter_label')}
					disabled={!(search || tags?.length > 0)}
					onClick={clearFiltersHandler}
				>
					<ClearIcon/>

				</IconButton>
			</Grid>


		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		filters: state.cms.entriesFilters,
		loading: state.cms.entriesLoading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setEntriesFilters: (filters) => dispatch(setEntriesFilters(filters)),
		setEntriesPaging: (paging) => dispatch(setEntriesPaging(paging)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
