import React from 'react';
import TableRow from "@material-ui/core/TableRow";
import Skeleton from "@material-ui/lab/Skeleton";
import TableCell from "@material-ui/core/TableCell";
import {connect} from 'react-redux'
import moment from "moment";
import SearchIcon from '@material-ui/icons/Search';
import {useTranslation} from 'react-i18next';
import {getSiteOrderEditRoute} from "../../../../router/routes/site/factory/order";
import {getSiteUserEditRoute} from "../../../../router/routes/site/factory/user";
import Button from "../../inputs/Button";
import Link from "../../typography/Link";

const Row = ({order, loading}) => {
    const displayOrder = (!loading && order);
    const {t} = useTranslation();

    return (
        <TableRow>
            <TableCell>
                {!displayOrder && <Skeleton width={17}/>}
                {displayOrder && <span>{order.Invoice_Number}</span>}
            </TableCell>
            <TableCell>
                {!displayOrder && <Skeleton/>}
                {displayOrder && <span>
                    <Link to={getSiteUserEditRoute(order.User__)}>
                        {order.User.Profile.Display_Name}
                    </Link>
                </span>}
            </TableCell>
            <TableCell>
                {!displayOrder && <Skeleton/>}
                {displayOrder &&
                <span>{order.Invoice_Date ? moment(parseInt(order.Invoice_Date.unixms)).format('LLL') : '-'}</span>}
            </TableCell>
            <TableCell>
                {!displayOrder && <Skeleton/>}
                {displayOrder && <span>{t('order_status_' + order.Status)}</span>}
            </TableCell>
            <TableCell>
                {!displayOrder && <Skeleton/>}
                {displayOrder && <span>
                    {(order.Payment_Method && order.Payment_Class) && order.Payment_Method + ' - ' + order.Payment_Class}
                    {(!order.Payment_Method && order.Payment_Class) && order.Payment_Class}
                    {(order.Payment_Method && !order.Payment_Class) && order.Payment_Method}
                    </span>}
            </TableCell>
            <TableCell>
                {!displayOrder && <Skeleton/>}
                {displayOrder && <span>{t('order_invoice_status_' + order.Invoice_Status)}</span>}
            </TableCell>
            <TableCell>
                {!displayOrder && <Skeleton/>}
                {displayOrder && <span>{order.Total_Vat.display}</span>}
            </TableCell>
            <TableCell>
                {!displayOrder && <Skeleton/>}
                {displayOrder && <span>{order.Vat_Amount.display}</span>}
            </TableCell>
            <TableCell>
                {!displayOrder && <Skeleton/>}
                {
                    displayOrder &&
                    <Button variant='contained'
                            color='primary'
                            startIcon={<SearchIcon/>}
                            component={Link}
                            to={getSiteOrderEditRoute(order.Order__)}
                    >
                        {t('view_btn')}
                    </Button>
                }
            </TableCell>
        </TableRow>
    );
};

const mapStateToProps = (state, {loading, idx}) => {
    if (loading) return {};
    return {
        order: state.order.orders[idx],
    }
};

export default connect(mapStateToProps, null)(Row);