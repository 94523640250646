import React, {useEffect}     from 'react';
import {withRouter}           from 'react-router';
import {connect}              from 'react-redux';
import {fetchPlan}            from '../../../store/actions/fanclub/PlanAction';
import Grid                   from '@material-ui/core/Grid';
import Details                from '../../../components/common/membership/plans/edit/Details';
import Settings               from '../../../components/common/membership/plans/edit/Settings';
import Prices                 from '../../../components/common/membership/plans/edit/Prices';
import Tag                    from '../../../components/common/membership/plans/edit/Tag';
import {useTranslation}       from 'react-i18next';
import LoadingAppBarTitle     from '../../../components/common/typography/LoadingAppBarTitle';
import Redirect               from '../../../components/Route/Redirect';
import {getFanclubPlansRoute} from '../../../router/routes/fanclub/factory/plan';
import AccessCheck            from '../../../components/Route/AccessCheck';
import FanclubActivator       from '../../../components/common/fanclub/FanclubActivator';

const PlanEdit = ({match, loading, plan, fetch}) => {
	useEffect(() => {
		fetch(match.params.plan);
	}, [match, fetch]);
	const {t} = useTranslation();

	return (
		<AccessCheck rights={{admin: true}} capabilities={{fanclub: true}}
		             deniedComponent={<Redirect target={getFanclubPlansRoute()}/>}>
			<Grid container spacing={3} justify="space-evenly">

				<LoadingAppBarTitle
					value={plan}
					title={(plan) => t('edition_plan_loaded', {plan: plan.Catalog_Product['Basic.Name']})}
					fallback={t('edition_plan')}
				/>

				<FanclubActivator/>

				<Grid item xs={12}>
					<Details plan={plan}/>
				</Grid>

				<Grid item xs={12}>
					<Settings plan={plan}/>
				</Grid>

				<Grid item xs={12}>
					<Tag plan={plan}/>
				</Grid>

				{(plan && plan.Free === 'N') &&
				<Grid item xs={12}>
					<Prices productId={plan ? plan.Catalog_Product__ : null}/>
				</Grid>
				}
			</Grid>
		</AccessCheck>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.plan.planLoading,
		plan: state.plan.selectedPlan,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: (id) => dispatch(fetchPlan(id))
	};
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PlanEdit));
