import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {connect} from 'react-redux'
import {useHistory} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {useTranslation} from 'react-i18next';
import {deleteSchedule} from "../../../../store/actions/planner/ScheduleAction";
import Button from "../../inputs/Button";
import {getSiteSchedulesRoute} from "../../../../router/routes/site/factory/planner";

const DeleteDialog = ({open, setOpen, deleting, schedule, deleteSchedule}) => {
    let history = useHistory();
    const {t} = useTranslation();
    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = (e) => {
        e.stopPropagation();
        e.preventDefault();

        deleteSchedule(schedule.Planner_Schedule__)
            .then(d => {
                handleClose();
                history.push(getSiteSchedulesRoute());
            })
    };

    return (

        <Dialog
            open={open}
            aria-labelledby={t('delete_schedule')}
            aria-describedby={t('delete_schedule')}
        >
            <DialogTitle id="delete-schedule-title">{t('confirm_delete_schedule')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="delete-schedule-description">
                    {t('delete_schedule_desc')}
                    <br/>
                    <Typography variant="body1" color="textPrimary" component="span">{t('are_you_sure')}</Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' color="primary" autoFocus onClick={handleClose} disabled={deleting}>
                    {t('back_btn')}
                </Button>
                <Button color="secondary" disabled={deleting} loading={deleting} endIcon={<DeleteForeverIcon/>}
                        onClick={handleDelete}>
                    {t('delete_forever_btn')}
                </Button>
            </DialogActions>
        </Dialog>

    );
};

const mapStateToProps = (state) => {
    return {
        deleting: state.schedule.deleting,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        deleteSchedule: (id) => dispatch(deleteSchedule(id)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDialog);