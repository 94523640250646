import React, { Fragment, useEffect, useState } from 'react';
import TableRow                                 from '@material-ui/core/TableRow';
import TableCell                                from '@material-ui/core/TableCell';
import Skeleton                                 from '@material-ui/lab/Skeleton';
import { useTranslation }                       from 'react-i18next';
import { connect }                              from 'react-redux';
import AccessSelector                           from '../inputs/AccessSelector';
import Button                                   from '../inputs/Button';
import DeleteIcon                               from '@material-ui/icons/Delete';
import DeleteDialog                             from './DeleteDialog';
import Grid                                     from '@material-ui/core/Grid';
import { fetchGroup, grantAccess }              from '../../../store/actions/system/AccessAction';
import LeaveDialog                              from './LeaveDialog';
import ExitToAppIcon                            from '@material-ui/icons/ExitToApp';

const Member = ({ member, group, loading, isOwner, isAdmin, updating, user, grantAccess, refresh, refreshing }) => {
	const display                       = member && !loading;
	const { t }                         = useTranslation();
	const [access, setAccess]           = useState('');
	const [canEdit, setCanEdit]         = useState(false);
	const [isMe, setIsMe]               = useState(false);
	const [saveEnabled, setSaveEnabled] = useState(false);

	const [deleteDialogOpened, setDeleteDialogOpened] = useState(false);
	const [leaveDialogOpened, setLeaveDialogOpened]   = useState(false);

	useEffect(() => {
		if (!member) return;
		const isMe = user.User__ === member.User.User__;
		setAccess(member.Access);
		setIsMe(isMe);

		if (isOwner && !isMe) {
			setCanEdit(member.Access !== 'O');
		} else if (isAdmin && !isMe) {
			setCanEdit(member.Access !== 'O' && member.Access !== 'A');
		} else {
			setCanEdit(false);
		}

	}, [setAccess, setIsMe, setCanEdit, member, isOwner, isAdmin, user]);

	useEffect(() => {
		if (!member) return;
		setSaveEnabled(access !== member.Access);
	}, [member, access, setSaveEnabled]);

	const handleSave = () => {
		grantAccess(member.User.User__, access)
			.then(refresh);
	};

	return (
		<Fragment>
			<TableRow>
				<TableCell>
					{!display && <Skeleton/>}
					{display && <span>{member.User.Profile.Display_Name}</span>}
				</TableCell>

				<TableCell>
					{!display && <Skeleton/>}
					{(display && !canEdit) && <span>{t('access_right_' + access)}</span>}
					{(display && canEdit) &&
					<AccessSelector
						currentRight={isOwner ? 'O' : 'A'}
						onChange={e => setAccess(e.target.value)}
						value={access}
						inputProps={{
							variant:   'outlined',
							fullWidth: true,
							size:      'small',
							disabled:  updating
						}}
					/>
					}
				</TableCell>
				<TableCell>
					{!display && <Skeleton/>}
					{display && <span>{member.User.Email}</span>}
				</TableCell>
				<TableCell align='right'>
					{!display && <Skeleton/>}
					{(display && !isOwner && isMe) &&
					<Button
						variant='contained'
						color='secondary'
						size='small'
						disabled={updating || refreshing}
						onClick={() => setLeaveDialogOpened(true)}
						startIcon={<ExitToAppIcon/>}
					>
						{t('leave_btn')}
					</Button>
					}
					{(display && canEdit && !isMe) &&

					<Grid container spacing={3} justify='flex-end'>
						<Grid item>
							<Button
								variant='contained'
								color='primary'
								size='small'
								loading={updating || refreshing}
								disabled={updating || refreshing || !saveEnabled}
								onClick={() => handleSave()}
							>
								{t('save_btn')}
							</Button>
						</Grid>
						<Grid item>
							<Button
								variant='contained'
								color='secondary'
								size='small'
								disabled={updating || refreshing}
								onClick={() => setDeleteDialogOpened(true)}
								startIcon={<DeleteIcon/>}
							>
								{t('delete_btn')}
							</Button>
						</Grid>
					</Grid>

					}
				</TableCell>
			</TableRow>
			{(canEdit && !isMe) &&
			<DeleteDialog open={deleteDialogOpened} setOpen={setDeleteDialogOpened} member={member}/>}
			{(!isOwner && isMe) &&
			<LeaveDialog open={leaveDialogOpened} setOpen={setLeaveDialogOpened}/>}
		</Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		user:       state.user.user,
		isOwner:    state.access.owner,
		isAdmin:    state.access.admin,
		updating:   state.access.grantingAccess,
		refreshing: state.access.groupLoading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		grantAccess: (userId, access) => dispatch(grantAccess(userId, access)),
		refresh:     () => dispatch(fetchGroup())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Member);
