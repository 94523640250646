import React, {Fragment, useEffect, useState} from 'react';
import {connect}                              from 'react-redux';
import {withRouter}                           from 'react-router';
import Grid                                   from '@material-ui/core/Grid';
import {fetchUser}                            from '../../store/actions/RealmAction';
import Profile                                from '../../components/common/user/Profile';
import MembershipProfile                      from '../../components/common/membership/members/edit/Profile';
import Tabs                                   from '@material-ui/core/Tabs';
import Tab                                    from '@material-ui/core/Tab';
import Panel                                  from '../../components/common/data/tab/Panel';
import OrdersTable                            from '../../components/common/order/table/Table';
import MailHistoryTable                       from '../../components/common/mail/history/table/Table';
import {setOrdersFilters}                     from '../../store/actions/OrderAction';
import {fetchMembershipUserByUser}            from '../../store/actions/fanclub/MembershipAction';
import Plan                                   from '../../components/common/membership/members/edit/Plan';
import Detail                                 from '../../components/common/membership/members/edit/Detail';
import makeStyles                             from '@material-ui/core/styles/makeStyles';
import VisibilityIcon                         from '@material-ui/icons/Visibility';
import {Link}                                 from 'react-router-dom';
import MembershipStatus                       from '../../components/common/data/chip/MembershipStatus';
import AppBarTitle                            from '../../components/common/typography/AppBarTitle';

import {useTranslation}                from 'react-i18next';
import HasRight                        from '../../components/Route/HasRight';
import {getFanclubSubscriberEditRoute} from '../../router/routes/fanclub/factory/subscriber';
import {setMailHistoryFilters}         from '../../store/actions/mail/MailAction';
import SelectRealm                     from '../SelectRealm';
import MailDialog                      from '../../components/common/mail/MailDialog';
import NewTicket                       from '../../components/common/support/NewTicket';
import Alert                           from '@material-ui/lab/Alert';
import useTheme                        from '@material-ui/core/styles/useTheme';
import useMediaQuery                   from '@material-ui/core/useMediaQuery';
import Button                          from '../../components/common/inputs/Button';
import FormRequiredField               from '../../components/common/feeback/FormRequiredField';

const useStyles = makeStyles(theme => ({
	alignRight: {
		textAlign: 'right',
		[theme.breakpoints.down('xs')]: {
			textAlign: 'center'
		}

	},
	membershipViewBtn: {
		marginLeft: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(3)
		}
	}
}));

export const ORDER_TAB = 'ORDER_TAB';
export const MAIL_HISTORY_TAB = 'MAIL_HISTORY_TAB';
export const FANCLUB_TAB = 'FANCLUB_TAB';
export const CONTACT_TAB = 'CONTACT_TAB';

const User = ({
	              user,
	              loadingUser,
	              support,
	              match,
	              selectedRealm,
	              fetchUser,
	              selectedFanclub,
	              setOrdersFilters,
	              setMailHistoryFilters,
	              selectedMembership,
	              fetchMembership,
	              membershipUserLoading,
	              isSupport
              }) => {
	const [enableTableDisplay, setEnableTableDisplay] = useState(false);
	const [tabIndex, setTabIndex] = React.useState(ORDER_TAB);
	const [selectedMail, setSelectedMail] = React.useState(null);
	const [mailDialogOpen, setMailDialogOpen] = React.useState(false);
	const classes = useStyles();
	const {t} = useTranslation();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {noSsr: true});


	const handleChange = (event, newValue) => {
		setTabIndex(newValue);
	};

	useEffect(() => {
		fetchUser(match.params.user)
			.then(usr => {

				setOrdersFilters({/*Realm__: '*',*/User__: usr.User__,});
				setMailHistoryFilters({User__: usr.User__,});
				setEnableTableDisplay(true);
			});
	}, [fetchUser, match, setOrdersFilters, setEnableTableDisplay, setMailHistoryFilters]);

	useEffect(() => {
		if (!selectedFanclub || !user) return;
		fetchMembership(user.User__);
	}, [selectedFanclub, user, fetchMembership]);

	if (!selectedRealm) return <SelectRealm/>;

	return (
		<Grid container spacing={3}>


			{user ?
				<AppBarTitle title={t('user_display_name', {user: user.Profile.Display_Name})}/>
				:
				<AppBarTitle title={t('user_display_name_load')}/>
			}


			<Grid item xs={12}>
				<Profile user={user}/>
			</Grid>
			<Grid item xs={12}>
				<Tabs
					variant="scrollable"
					scrollButtons={isMobile ? 'on' : 'auto'}
					value={tabIndex}
					indicatorColor="primary"
					textColor="primary"
					onChange={handleChange}
				>
					{isSupport && <Tab label={t('orders_lbl')} value={ORDER_TAB}/>}
					{isSupport && <Tab label={t('mail_history_tab_lbl')} value={MAIL_HISTORY_TAB}/>}
					{(selectedFanclub && isSupport) &&
					<Tab label={t('fanclub_membership')} disabled={!selectedFanclub} value={FANCLUB_TAB}/>}
					{(support && isSupport) && <Tab label={t('contact_user')} disabled={!support} value={CONTACT_TAB}/>}
				</Tabs>

				<HasRight support>
					<Panel value={tabIndex} index={ORDER_TAB}>
						<OrdersTable disabled={!enableTableDisplay}/>
					</Panel>


					<Panel value={tabIndex} index={MAIL_HISTORY_TAB}>
						<MailHistoryTable onSelected={m => {
							setSelectedMail(m);
							setMailDialogOpen(true);
						}} disabled={!enableTableDisplay} realm={selectedRealm}/>
					</Panel>

					<Panel value={tabIndex} index={FANCLUB_TAB}>
						<Grid container spacing={3} justify="space-evenly">
							{(!membershipUserLoading && !selectedMembership) &&
							<p>{t('user_dont_have_fanclub_sub')}</p>}

							{/* Profile Picture */}
							{(selectedMembership || membershipUserLoading) &&
							<Fragment>
								{selectedMembership && <Grid xs={12} item className={classes.alignRight}>
									<Fragment>{t('status')} : <MembershipStatus
										membership={selectedMembership}/></Fragment>
									<Button
										className={classes.membershipViewBtn}
										variant="contained"
										color="primary"
										startIcon={<VisibilityIcon/>}
										component={Link}
										to={getFanclubSubscriberEditRoute(selectedMembership.Membership_User__)}
									>
										{t('view_btn')}
									</Button>
								</Grid>}
								<Grid item lg={3} md={6} xs={12}>
									<MembershipProfile membershipUser={selectedMembership}/>
								</Grid>

								<Grid item lg={4} md={6} xs={12}>
									<Plan enableActions={false} membershipUser={selectedMembership}/>
								</Grid>

								<Grid item lg={4} md={12} xs={12}>
									<Detail enableActions={false} membershipUser={selectedMembership}/>
								</Grid>
							</Fragment>
							}

						</Grid>
					</Panel>

					<Panel value={tabIndex} index={CONTACT_TAB}>
						<HasRight support deniedComponent={t('lack_of_privilege_text')}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<Alert severity="info">
										{t('contact_user_info')}
									</Alert>
								</Grid>
								<FormRequiredField/>
							</Grid>
							<NewTicket support={support} user={user}/>
						</HasRight>
					</Panel>
				</HasRight>
			</Grid>
			<MailDialog mail={selectedMail} open={mailDialogOpen} setOpen={setMailDialogOpen}/>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		user: state.realm.selectedUser,
		support: state.support.support,
		loadingUser: state.realm.loadingUser,
		selectedFanclub: state.membership.selectedFanclub,
		selectedMembership: state.membership.membershipUser,
		membershipUserLoading: state.membership.membershipUserLoading,
		isSupport: state.access.support,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchUser: (id) => dispatch(fetchUser(id)),
		setOrdersFilters: (filters) => dispatch(setOrdersFilters(filters)),
		setMailHistoryFilters: (filters) => dispatch(setMailHistoryFilters(filters)),
		fetchMembership: (id) => dispatch(fetchMembershipUserByUser(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(User));
