import React              from 'react';
import { connect }        from 'react-redux';
import { Grid }           from '@material-ui/core';
import { useTranslation } from 'react-i18next';

// action
import { AntSwitch }   from './style';
import { updateEvent } from '../../../../store/actions/planner/EventAction';

const ChatSwitch = ({ event, updateEvent }) => {
	const { t } = useTranslation();


	const createHandleSwitch = key => () => {
		let flags = (Array.isArray(event.Stream_Flags) ? {} : event.Stream_Flags) ?? {};
		if (key in flags)
			delete flags[key];
		else
			flags[key] = true;

		updateEvent(event.Planner_Event__, { Stream_Flags: Object.keys(flags) });
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Grid container alignItems='center' spacing={1}>
					<Grid item>{t('stream_chat_disabled')}</Grid>
					<Grid item>
						<AntSwitch
							checked={event.Stream_Flags.chat_enabled}
							onChange={createHandleSwitch('chat_enabled')}
						/>
					</Grid>
					<Grid item>{t('stream_chat_enabled')}</Grid>
				</Grid>
			</Grid>

			<Grid item xs={12}>
				<Grid container alignItems='center' spacing={1}>
					<Grid item>{t('stream_super_chat_disabled')}</Grid>
					<Grid item>
						<AntSwitch
							disabled={!event.Stream_Flags.chat_enabled}
							checked={event.Stream_Flags.chat_enabled && event.Stream_Flags.super_chat_enabled}
							onChange={createHandleSwitch('super_chat_enabled')}
						/>
					</Grid>
					<Grid item>{t('stream_super_chat_enabled')}</Grid>
				</Grid>
			</Grid>

		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		event: state.event.event
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateEvent: (id, data) => dispatch(updateEvent(id, data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatSwitch);
