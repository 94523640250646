import {rest}        from '@karpeleslab/klbfw';
import {handleError} from '../../components/utils/apiErrorHandler';
import {
	SOCIAL_COMMENT_DELETE,
	SOCIAL_COMMENT_DELETE_DONE, SOCIAL_COMMENT_PATROL, SOCIAL_COMMENT_PATROL_DONE,
	SOCIAL_COMMENTS_FETCH,
	SOCIAL_COMMENTS_FETCH_DONE,
	SOCIAL_COMMENTS_SET_FILTERS,
	SOCIAL_COMMENTS_SET_PAGING,
	SOCIAL_COMMENTS_STATS_FETCH_DONE
} from '../reducers/SocialReducer';
import {success}     from './system/ToastAction';

export const fetchCommentsStats = (realmId) => {
	return (dispatch, getState) => {
		return rest('Social/Comment', 'GET', {Realm__: realmId, Visible:'Y', Patrol : 'pending'})
			.then(data => {
				dispatch({type: SOCIAL_COMMENTS_STATS_FETCH_DONE, count: data.paging.count});
				return data.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const fetchComments = () => {
	return (dispatch, getState) => {
		dispatch({type: SOCIAL_COMMENTS_FETCH});

		const params = {
			...getState().social.commentsFilters,
			...getState().social.commentsPaging
		};

		return rest('Social/Comment', 'GET', params)
			.then(data => {
				dispatch({type: SOCIAL_COMMENTS_FETCH_DONE, comments: data.data, paging: data.paging});
				return data.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const fetchComment = (id) => {
	return (dispatch, getState) => {
		return rest('Social/Comment/'+id, 'GET')
			.then(data => {
				return data.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const fetchResponses = (parent) => {
	return (dispatch, getState) => {
		return rest('Social/Comment', 'GET', {
			key: parent,
			results_per_page: 100,
		})
			.then(data => {
				return data.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const deleteComment = (id) => {
	return (dispatch, getState) => {
		dispatch({type: SOCIAL_COMMENT_DELETE});

		return rest('Social/Comment/' + id, 'DELETE')
			.then(data => {
				success('comment_delete_success');
				dispatch({type: SOCIAL_COMMENT_DELETE_DONE});
				dispatch(fetchCommentsStats(data.data.Realm__));
				return data.data;
			}).catch((err) => {
				dispatch({type: SOCIAL_COMMENT_DELETE_DONE});
				handleError(getState, dispatch, err);
			});
	};
};

export const patrolComment = (id) => {
	return (dispatch, getState) => {
		dispatch({type: SOCIAL_COMMENT_PATROL});

		return rest('Social/Comment/' + id + ':patrolled', 'POST')
			.then(data => {
				success('comment_verify_success');
				dispatch({type: SOCIAL_COMMENT_PATROL_DONE});
				dispatch(fetchCommentsStats(data.data.Realm__));
				return data.data;
			}).catch((err) => {
				dispatch({type: SOCIAL_COMMENT_PATROL_DONE});
				handleError(getState, dispatch, err);
			});
	};
};

export const setCommentsFilters = (filters) => {
	return (dispatch, getState) => {
		dispatch({type: SOCIAL_COMMENTS_SET_FILTERS, filters: filters});
	};
};

export const setCommentsPaging = (paging) => {
	return (dispatch, getState) => {
		dispatch({type: SOCIAL_COMMENTS_SET_PAGING, paging: {...getState().social.commentsPaging, ...paging}});
	};
};