import React, {useEffect, useState} from 'react';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {Select} from "@material-ui/core";
import {useTranslation} from 'react-i18next';

const AccessSelector = ({onChange, inputProps = {}, value = undefined, currentRight}) => {
    const selectLabel = React.useRef(null);
    const [selectLabelWidth, setLabelWidth] = useState(0);
    const {t} = useTranslation();

    useEffect(() => {
        setLabelWidth(selectLabel.current.offsetWidth);
    }, []);

    return (
        <FormControl {...inputProps}>
            <InputLabel ref={selectLabel} htmlFor='access-select'>{t('access_role')}</InputLabel>
            <Select
                native
                value={value}
                onChange={onChange}
                labelWidth={selectLabelWidth}
                inputProps={{
                    id: 'access-select',
                }}
            >
                <option value='A' disabled={currentRight !== 'O'}>{t('access_right_A')}</option>
                <option value='W'>{t('access_right_W')}</option>
                <option value='C'>{t('access_right_C')}</option>
                <option value='R'>{t('access_right_R')}</option>
            </Select>

        </FormControl>
    );
};

export default AccessSelector;