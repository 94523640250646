import React, {useState}           from 'react';
import {Grid}                      from '@material-ui/core';
import UserLocationSelector        from '../selector/UserLocationSelector';
import {Title}                     from '../../../typography/Title';
import {useTranslation}            from 'react-i18next';
import {useUserSetDefaultLocation} from '../../../../../hooks/api/user/useUser';
import Button                      from '../../../inputs/Button';
import {connect}                   from 'react-redux';
import {changeUser}                from '../../../../../store/actions/system/UserActions';

const DefaultLocationSetter = ({user, type = 'Billing', changeUser}) => {
	const {t} = useTranslation();
	const [location, setLocation] = useState();
	const [set, setting] = useUserSetDefaultLocation('@');

	const handleSave = () => {
		set(location, type).then(u => {
			changeUser(u);
		});
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Title>{t(`set_default_${type.toLowerCase()}_location_title`)}</Title>
			</Grid>

			<Grid item xs={12}>
				<UserLocationSelector
					disabled={setting}
					value={location}
					setValue={setLocation}
				/>
			</Grid>

			<Grid item xs={12}>
				<Grid container justify="flex-end">
					<Button
						loading={setting}
						color="primary"
						variant="contained"
						onClick={handleSave}
						disabled={!location || setting}
					>
						{t('save_btn')}
					</Button>
				</Grid>
			</Grid>

		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		changeUser: (user) => dispatch(changeUser(user))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLocationSetter);