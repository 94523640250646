import {COMMON_RESET} from "./CommonReducer";
import {USER_LOGOUT} from "./system/UserReducer";

const initState = () => {
    return {
        loadingUrl: true,
        url: null,
    }
};

export const REGISTRY_URL_FETCH = 'REGISTRY_URL_FETCH';
export const REGISTRY_URL_FETCH_DONE = 'REGISTRY_URL_FETCH_DONE';

export const REGISTRY_UPDATE_DONE = 'REGISTRY_UPDATE_DONE';

const registryReducer = (state = initState(), action) => {
    switch (action.type) {
        case USER_LOGOUT:
        case COMMON_RESET:
            return initState();
        case REGISTRY_UPDATE_DONE:
            return {...state, url: action.url};
        case REGISTRY_URL_FETCH:
            return {...state, loadingUrl: true};
        case REGISTRY_URL_FETCH_DONE:
            return {...state, loadingUrl: false, url: action.url};
        default:
            return state;
    }

};

export default registryReducer