import React, {useEffect, useState} from 'react';

import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {TextField} from "@material-ui/core";
import InputLabel                           from "@material-ui/core/InputLabel";
import FormControl                          from "@material-ui/core/FormControl";
import Input                                from "@material-ui/core/Input";
import defaultPicture                       from '../../../resources/images/landscape-image.svg'
import '../../utils/strings'
import Country                              from "../../common/inputs/Country";
import MusicGenre                           from "../../common/inputs/MusicGenre";
import SnsLinks                             from "../../common/data/SnsLink/SnsLinks";
import {requiredCheck as nameRequiredCheck} from '../../utils/i18nInput'
import InputI18N                            from "../../common/inputs/InputI18n";
import Button                               from "../../common/inputs/Button";
import FormRequiredField                    from '../../common/feeback/FormRequiredField';


const StepArtist = ({handleBack, handleNext, artist, setArtist}) => {
    const {t} = useTranslation();

    const [nextEnabled, setNextEnabled] = useState(false);
    const [name, setName] = useState(artist.name ?? {});
    const [needDetailedName, setNeedDetailedName] = useState(false);

    const [country, setCountry] = useState('country' in artist ? artist.country : '');

    const [links, setLinks] = useState(artist.links ?? []);

    const [genre, setGenre] = useState(artist.genre ?? '');
    const [biography, setBiography] = useState(artist.biography ?? '');
    const [catchphrase, setCatchphrase] = useState(artist.catchphrase ?? '');
    const [picture, setPicture] = useState(artist.picture);
    const pictureRef = React.useRef(null);


    useEffect(() => {
        if(!nameRequiredCheck(name, needDetailedName)) {
            setNextEnabled(false);
            return;
        }

        if ((!country || !country.Country__) ||
            !picture) {
            setNextEnabled(false);
            return;
        }


        setNextEnabled(true)

    }, [setNextEnabled, needDetailedName, country, genre, picture, name]);


    const handleNextStep = () => {
        setArtist({
            name: name,
            country: country,
            links: links,
            genre: genre,
            picture: picture,
            biography: biography,
            catchphrase: catchphrase
        });

        handleNext();
    };

    const onPictureChange = (e) => {
        if (e.target.files.length < 1) {
            setPicture(undefined)
        } else {
            setPicture(e.target.files[0])
        }
    };

    useEffect(() => {
        if (picture === undefined) {
            pictureRef.current.src = defaultPicture;
            return;
        }

        const fr = new FileReader();
        fr.onload = function () {
            pictureRef.current.src = fr.result;
        };
        fr.readAsDataURL(picture);

    }, [picture]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h5" color="textPrimary" component="h5">
                    {t('artist_wizard_step_2_title')}
                </Typography>
            </Grid>

            <FormRequiredField/>

            <Grid item xs={12}>
                <Typography variant="body1" color="textSecondary" component="p">
                    {t('artist_wizard_step_2_desc')}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <InputI18N value={name}
                           onChange={setName}
                           onDetailedNameChange={setNeedDetailedName}
                           labelKey='artist_name'
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <Country value={country} required={true} onChange={e => setCountry(e)} inputProps={{
                    variant: 'outlined',
                    fullWidth: true
                }}/>
            </Grid>
            <Grid item xs={12} md={6}>
                <MusicGenre value={genre} onChange={e => setGenre(e)} inputProps={{
                    variant: 'outlined',
                    fullWidth: true
                }}/>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={3} alignItems="center">
                    <Grid item>
                        <img height={150} ref={pictureRef} alt={t('artist_avatar')}/>
                    </Grid>
                    <Grid item>
                        <FormControl fullWidth required>
                            <InputLabel shrink={true} htmlFor='artist-avatar'>{t('artist_avatar')}</InputLabel>
                            <Input
                                onChange={onPictureChange} inputProps={
                                {
                                    accept: "image/png, image/jpeg, image/jpg, image/svg"
                                }
                            }
                                id="artist-avatar" type='file'/>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <TextField
                    variant='outlined'
                    multiline rows={2}
                    rowsMax={Infinity}
                    helperText={t('artist_catchphrase_helperText')}
                    fullWidth
                    value={catchphrase}
                    onChange={e => setCatchphrase(e.target.value)}
                    label={t('artist_catchphrase_label')}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    variant='outlined'
                    multiline rows={5}
                    rowsMax={Infinity}
                    placeholder={t('artist_bio_placeholder')}
                    fullWidth
                    value={biography}
                    onChange={e => setBiography(e.target.value)}
                    label={t('artist_bio_label')}
                />
            </Grid>

            <Grid item xs={12}>
                <SnsLinks values={links} onChange={setLinks} available={
                    [
                        'youtube',
                        'facebook',
                        'twitter',
                        'instagram',
                        'soundcloud',
                        'twitch',
                        'tiktok',
                        'reddit',
                    ]

                }/>
            </Grid>

            <Grid item xs={12}>
                <Grid container justify='flex-end'>

                    <Button onClick={handleNextStep} variant="contained" color="primary" disabled={!nextEnabled}>
                        {t('next_btn')}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default StepArtist;