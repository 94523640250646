import React, {useState}      from 'react';
import Grid                   from '@material-ui/core/Grid';
import AppBarTitle            from '../../../components/common/typography/AppBarTitle';
import {useTranslation}       from 'react-i18next';
import {connect}              from 'react-redux';
import SelectRealm            from '../../SelectRealm';
import Toolbar                from '../../../components/common/typography/Toolbar';
import Redirect               from '../../../components/Route/Redirect';
import {getPXLDashboardRoute} from '../../../router/routes/pxl/factory/general';
import HasRight               from '../../../components/Route/HasRight';
import Button                 from '../../../components/common/inputs/Button';
import AddIcon                from '@material-ui/icons/Add';
import CreateDialog           from '../../../components/common/store/coupon/CreateDialog';
import StoreActivator         from '../../../components/common/store/StoreActivator';
import AccessCheck            from '../../../components/Route/AccessCheck';
import Table                  from '../../../components/common/store/coupon/table/Table';
import NewCodeDialog          from '../../../components/common/store/coupon/NewCodeDialog';
import DeleteDialog           from '../../../components/common/store/coupon/DeleteDialog';

const List = ({catalog, selectedRealm}) => {
	const {t} = useTranslation();
	const [showCreate, setShowCreate] = useState(false);
	const [newCode, setNewCode] = useState();
	const [selected, setSelected] = useState(null);
	const [showDelete, setShowDelete] = useState(false);

	if (!selectedRealm || !catalog) return <SelectRealm/>;

	const onSelect = (coupon) => {
		setSelected(coupon);
		setShowDelete(true);
	};

	return (
		<Grid container spacing={3}>
			<AccessCheck capabilities={{store: true}} rights={{owner: true}} hideLoading
			             deniedComponent={<Redirect target={getPXLDashboardRoute()}/>}>
				<AppBarTitle title={t('coupon_title')}/>
				<StoreActivator/>
				<Grid item xs={12}>
					<Toolbar title={t('coupon_title')}>
						<HasRight manager>
							<Button endIcon={<AddIcon/>} color="primary" variant="contained"
							        onClick={() => setShowCreate(true)}>
								{t('create_btn')}
							</Button>
						</HasRight>
					</Toolbar>
				</Grid>

				<Grid item xs={12}>
					<Table onSelect={onSelect}/>
				</Grid>
			</AccessCheck>
			<CreateDialog open={showCreate} setOpen={setShowCreate} catalog={catalog} setNewCode={setNewCode}/>
			<NewCodeDialog newCode={newCode} setNewCode={setNewCode}/>
			<DeleteDialog coupon={selected} open={showDelete} setOpen={setShowDelete}/>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		catalog: state.store.catalog,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
