import React                   from 'react';
import {Trans, useTranslation} from 'react-i18next';
import Card                    from '@material-ui/core/Card';
import CardContent             from '@material-ui/core/CardContent';
import Typography              from '@material-ui/core/Typography';
import {Title}                 from '../../../typography/Title';

const Likes = ({count}) => {
	const {t} = useTranslation();

	return (
		<Card variant="outlined">
			<CardContent>
				<Title>{t('likes_title')}</Title>
				<Typography variant="body2" color="textSecondary" component="p">
					<Trans i18nKey="likes_stats" count={count}>
						You have <Typography color="secondary" component="span">{{count}}</Typography> users(s) that
						liked this content
					</Trans>
				</Typography>
			</CardContent>
		</Card>
	);
};


export default Likes;
