import React, { Fragment, useEffect } from 'react';
import TableHead                      from '@material-ui/core/TableHead';
import MaterialTable                  from '@material-ui/core/Table';
import TableRow                       from '@material-ui/core/TableRow';
import TableCell                      from '@material-ui/core/TableCell';
import TableBody                      from '@material-ui/core/TableBody';
import TableContainer                 from '@material-ui/core/TableContainer';
import Row                            from './Row';
import { connect }                    from 'react-redux';
import TableFooter                    from '@material-ui/core/TableFooter';
import { useTranslation }             from 'react-i18next';
import Empty                          from './Empty';
import {
	fetchMailHistory,
	setMailHistoryFilters,
	setMailHistoryPaging
}                                     from '../../../../../store/actions/mail/MailAction';
import Pagination                     from '../../../data/table/Pagination';


const Table = ({ realm, onSelected, data, fetch, setMailHistoryFilters, setMailHistoryPaging, loading, filters, paging, count, disabled = false }) => {
	const loadingData = [...Array(paging.results_per_page).keys()];

	const displayLoading = () => loadingData.map((k) => <Row key={k} loading={true} mail={null}/>);
	const displayHistory = () => data.map((o) => <Row onSelected={onSelected} key={o.Mail_History__} loading={false}
	                                                  mail={o}/>);
	const { t } = useTranslation();

	useEffect(() => {
		if (!disabled) fetch(realm.Realm__);
	}, [disabled, fetch, filters, paging, realm]);

	return (
		<Fragment>
			{(loading || data.length > 0) &&
			<TableContainer>
				<MaterialTable aria-label='simple table'>
					<TableHead>
						<TableRow>
							<Pagination
								prefix='m'
								setPaging={setMailHistoryPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
						<TableRow>
							<TableCell>{t('tablecell_mail_subject')}</TableCell>
							<TableCell>{t('tablecell_mail_date')}</TableCell>
							<TableCell align='right'/>
						</TableRow>
					</TableHead>
					<TableBody>
						{(loading || disabled) && displayLoading()}
						{(!loading && !disabled) && displayHistory()}
					</TableBody>
					<TableFooter>
						<TableRow>
							<Pagination
								prefix='m'
								setPaging={setMailHistoryPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
					</TableFooter>

				</MaterialTable>
			</TableContainer>
			}
			{(!loading && data.length < 1) && <Empty/>}
		</Fragment>
	);
};


const mapStateToProps = (state) => {
	return {
		filters: state.mail.historyFilters,
		paging: state.mail.historyPaging,
		count: state.mail.historyPagingCount,
		loading: state.mail.historyLoading,
		data: state.mail.history
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: (realmId) => dispatch(fetchMailHistory(realmId)),
		setMailHistoryFilters: (filters) => dispatch(setMailHistoryFilters(filters)),
		setMailHistoryPaging: (paging) => dispatch(setMailHistoryPaging(paging))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);

