import React              from 'react';
import { useTranslation } from 'react-i18next';
import EmptyState         from '../../../feeback/State/Empty';
import Graphic            from '../../../../../resources/images/categories.svg';

const EmptyReleaseTags = () => {
	const {t} = useTranslation();
	return (
		<EmptyState
			title={t('release_empty_tags_title')}
			textI18Key='release_empty_tags_text'
			graphic={Graphic}
		/>
	);
};

export default EmptyReleaseTags;
