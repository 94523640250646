import React, {useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {connect} from 'react-redux'
import {Trans, useTranslation} from 'react-i18next';
import {fetchShippings, setSelectedShippings, shippingGroup} from "../../../../store/actions/store/ShippingAction";
import Button from "../../inputs/Button";
import RichAlert from "../../feeback/RichAlert";
import {getSiteOrderEditRoute} from "../../../../router/routes/site/factory/order";
import Link from "../../typography/Link";

const GroupDialog = ({open, setOpen, grouping, shippingGroup, selected, refresh, refreshing, resetSelected}) => {
    const {t} = useTranslation();
    const [shipping, setShipping] = useState(null);
    const [toGroup, setToGroup] = useState(null);
    const [groupCount, setGroupCount] = useState(0);
    const [order, setOrder] = useState('');

    useEffect(() => {
        const flat = Object.values(selected)
        if (flat.length < 1) return
        const into = flat[0];
        setShipping(into);
        flat.shift()
        setToGroup(flat)
        setGroupCount(flat.length)
        setOrder(into.Order.Invoice_Number)

    }, [selected, setShipping, setToGroup, setGroupCount, setOrder])

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = (e) => {
        e.stopPropagation();
        e.preventDefault();

        const toIds = toGroup.map(s => s.Catalog_Shipping__)

        shippingGroup(shipping.Catalog_Shipping__, toIds)
            .then(refresh)
            .then(resetSelected)
            .then(handleClose);
    };

    return (
        <Dialog
            open={open}
            aria-labelledby={t('shipping_group_title')}
            aria-describedby={t('shipping_group_title')}
        >
            <form onSubmit={handleSubmit}>
                <DialogTitle
                    id="group-title">{t('shipping_group_title')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="group-description">
                        <RichAlert severity='info'>
                            <Trans i18nKey={'shipping_group_desc'}>
                                sample<br/>
                                <strong>sample</strong>
                                <Link target='_blank'
                                      to={getSiteOrderEditRoute(shipping ? shipping.Order__ : '')}>{{order}}</Link>
                                {{groupCount}}
                            </Trans>
                        </RichAlert>
                    </DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Button color="default" onClick={handleClose} disabled={refreshing || grouping}>
                        {t('close_btn')}
                    </Button>
                    <Button color="primary" variant='contained' loading={refreshing || grouping}
                            disabled={refreshing || grouping} type='submit'>
                        {t('shipment_group')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {
        refreshing: state.shipping.shippingsLoading,
        grouping: state.shipping.grouping,
        selected: state.shipping.selectedShippings
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        refresh: () => dispatch(fetchShippings()),
        shippingGroup: (id, ids) => dispatch(shippingGroup(id, ids)),
        resetSelected: () => dispatch(setSelectedShippings({}))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupDialog);