import React, {useEffect, useState}        from 'react';
import {useTranslation}                    from 'react-i18next';
import Grid                                from '@material-ui/core/Grid';
import Stepper                             from '@material-ui/core/Stepper';
import Step                                from '@material-ui/core/Step';
import StepLabel                           from '@material-ui/core/StepLabel';
import Basic         from './Basic';
import CategoriesNew from './Categories';
import TaxShipping   from './TaxShipping';
import Selling                             from './Selling';
import {getCurrency}                       from '@karpeleslab/klbfw';
import Gallery                             from './Gallery';
import {connect}                           from 'react-redux';
import {createProductWizard, fetchProduct} from '../../../store/actions/store/ProductAction';
import {useHistory}                        from 'react-router-dom';
import {getStoreProductEditRoute}          from '../../../router/routes/store/factory/store';
import {getAttribute, isAttributeUpdated}  from '../../utils/product';
import Loading                             from '../../common/feeback/loading/Loading';
import {useParams}                         from 'react-router';
import {deepCopy}                          from '../../utils/misc';

// this simulate the same architecture as the fetchAttributes of a product with default value for input that are not multilingual
const defaultValues = () => ({
	values: {
		Basic: {
			Name: [],
			Keywords: [],
			Priority: [],
			Date_Release: [],
			TaxProfile: [
				{
					Language__: null,
					Realm__: null,
					Value: '',
				}
			],
		},
		Description: {
			CatchPhrase: [],
			Short: [],
			Author: [],
			Pieces: [],
			AuthorCode: [
				{
					Language__: null,
					Realm__: null,
					Value: '',
				}
			],
		},
		Shipping: {
			Description: [],
			Type: [
				{
					Language__: null,
					Realm__: null,
					Value: null,
				}
			],
			Handling: [
				{
					Language__: null,
					Realm__: null,
					Value: null,
				}
			],
			Volumetric_Weight: [
				{
					Language__: null,
					Realm__: null,
					Value: null,
				}
			],
		},
		Native: {
			Internal_Name: [
				{
					Language__: null,
					Realm__: null,
					Value: '',
				}
			],
			Visible: [
				{
					Language__: null,
					Realm__: null,
					Value: 'N',
				}
			]
		},
	}
});

const Wizard = ({creating, createProductWizard, catalog, realm, fetchProduct}) => {
	const [activeStep, setActiveStep] = useState(0);
	const {t} = useTranslation();

	const [loading, setLoading] = useState(true);
	const {productId} = useParams();

	const [attributes, setAttributes] = useState(defaultValues());
	const [originalAttr, setOriginalAttr] = useState(defaultValues());

	const [categories, setCategories] = useState([]);
	const [deliverables, setDeliverables] = useState([]);

	const [price, setPrice] = useState();
	const [originalPrice, setOriginalPrice] = useState();
	const [currency, setCurrency] = useState(getCurrency());
	const [isDiscount, setIsDiscount] = useState(false);
	const [isVatIncluded, setIsVatIncluded] = useState(false);
	const [onSale, setOnSale] = useState(false);

	const [files, setFiles] = useState([]);

	const history = useHistory();

	useEffect(() => {
		if (!productId) setLoading(false);
		else {
			fetchProduct(productId)
				.then(d => {
					const v = deepCopy(d);
					const def = defaultValues();
					Object.keys(def.values).forEach((cat) => {
						Object.keys(def.values[cat]).forEach((attr) => {
							if (!(cat in v.values)) {
								v.values[cat] = def.values[cat];
							} else if (!(attr in v.values[cat])) {
								v.values[cat][attr] = def.values[cat][attr];
							}

						});

					});

					setAttributes(deepCopy(v));
					setOriginalAttr(deepCopy(v));
					setLoading(false);
				});
		}
	}, [productId, setLoading, setAttributes, setOriginalAttr, fetchProduct]);

	const handleNext = () => {
		setActiveStep(prevActiveStep => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep(prevActiveStep => prevActiveStep - 1);
	};

	const handleCreate = () => {
		const data = [];

		const buildData = key => {
			if (!isAttributeUpdated(originalAttr, key, getAttribute(attributes, key)))
				return;
			const value = getAttribute(attributes, key);
			value.forEach(v => {
				data.push({
					Variable: key,
					Language__: v.Language__,
					Realm__: null,
					Value: v.Value,
				});
			});
		};

		const buildSpecialData = (key, force = false) => {
			let tmp = getAttribute(originalAttr, key)[0].Value;
			let v = getAttribute(attributes, key)[0].Value;

			if (force || (!!v ? v.trim() : null) !== (!!tmp ? tmp.trim() : null)) {
				data.push({
					Variable: key,
					Language__: null,
					Realm__: null,
					Value: !!v ? v.trim() : null,
				});
			}
		};

		buildData('Basic.Name');
		buildData('Basic.Keywords');
		buildData('Basic.Priority');
		buildData('Basic.Date_Release');
		buildData('Description.CatchPhrase');
		buildData('Description.Short');
		buildData('Description.Author');
		buildData('Description.Pieces');
		buildData('Shipping.Description');

		buildSpecialData('Native.Internal_Name');
		buildSpecialData('Description.AuthorCode');
		buildSpecialData('Shipping.Type');
		buildSpecialData('Shipping.Handling');
		buildSpecialData('Shipping.Volumetric_Weight');
		buildSpecialData('Basic.TaxProfile');
		buildSpecialData('Native.Visible', true);

		let priceData = null;

		if (price) {
			priceData = {};
			priceData['price'] = price;
			priceData['publicPrice'] = isDiscount ? originalPrice : price;
			priceData['currency'] = currency;
			priceData['discount'] = isDiscount;
			priceData['flags'] = isVatIncluded ? {'vat_included': true} : {};
		}

		createProductWizard(
			catalog.Catalog__,
			getAttribute(attributes, 'Native.Internal_Name')[0].Value,
			realm.Realm__,
			data,
			onSale,
			deliverables,
			(categories ?? []).map(t => t.Classify_Tag__),
			priceData,
			files,
			productId ?? null
		).then(product => {
			history.push(getStoreProductEditRoute(product.Catalog_Product__));
		});
	};

	const getStepContent = () => {
		if (loading) return <Loading/>;

		switch (activeStep) {
			case 0:
				return <Basic
					handleNext={handleNext}
					attributes={attributes}
					setAttributes={setAttributes}
				/>;
			case 1:
				return <CategoriesNew
					handleNext={handleNext}
					handleBack={handleBack}
					categories={categories}
					setCategories={setCategories}

				/>;
			case 2:
				return <TaxShipping
					handleNext={handleNext}
					handleBack={handleBack}
					attributes={attributes}
					setAttributes={setAttributes}
				/>;
			case 3:
				return <Gallery
					handleBack={handleBack}
					handleNext={handleNext}
					files={files}
					setFiles={setFiles}
				/>;
			case 4:
				return <Selling
					attributes={attributes}
					setAttributes={setAttributes}
					handleCreate={handleCreate}
					handleBack={handleBack}
					deliverables={deliverables}
					setDeliverables={setDeliverables}
					originalPrice={originalPrice}
					setOriginalPrice={setOriginalPrice}
					price={price}
					setPrice={setPrice}
					currency={currency}
					setCurrency={setCurrency}
					isDiscount={isDiscount}
					setIsDiscount={setIsDiscount}
					isVatIncluded={isVatIncluded}
					setIsVatIncluded={setIsVatIncluded}
					onSale={onSale}
					setOnSale={setOnSale}
				/>;
			default:
				return <div/>;
		}
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Stepper activeStep={activeStep} alternativeLabel>
					<Step disabled={creating}><StepLabel>{t('wizard_product_basic')}</StepLabel></Step>
					<Step disabled={creating}><StepLabel>{t('wizard_product_categories')}</StepLabel></Step>
					<Step disabled={creating}><StepLabel>{t('wizard_product_tax_shipping')}</StepLabel></Step>
					<Step disabled={creating}><StepLabel>{t('wizard_product_gallery')}</StepLabel></Step>
					<Step disabled={creating}><StepLabel>{t('wizard_product_selling')}</StepLabel></Step>
				</Stepper>
			</Grid>
			<Grid item xs={12}>
				{getStepContent()}
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		creating: state.product.creating,
		catalog: state.store.catalog,
		realm: state.realm.selected,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchProduct: (productId) => dispatch(fetchProduct(productId)),
		createProductWizard: (catalogId, internalName, realmId, attributes, onSale, deliverables = [], tags = [], price = null, files = [], parentProductId = null) => dispatch(createProductWizard(catalogId, internalName, realmId, attributes, onSale, deliverables, tags, price, files, parentProductId))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Wizard);
