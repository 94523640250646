import React from 'react';
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {useTranslation} from "react-i18next";

// This is not very optimize but for a small list of flags it works great
const Flags = ({value, onChange, flags = [], disabled = false, exclusive = [], prefix = ''}) => {
    const {t} = useTranslation();

    const createOnChangeHandler = flag => e => {
        const v = e.target.checked;
        if (v) {
            if (value.includes(flag)) return;
            if(exclusive.includes(flag)) {
               const toExclude = new Set(exclusive.filter(f => f !== flag));
               value = value.filter( x => !toExclude.has(x) );
            }
            onChange([...value, flag])
        } else {
            if (!value.includes(flag)) return;
            onChange(value.filter(f => f !== flag));
        }
    };

    return (
        <Grid container spacing={3}>
            {
                flags.map(flag =>
                    <Grid item key={'flag-selector-'+flag}>
                        <FormControlLabel
                            control={<Checkbox
                                checked={value.includes(flag)}
                                color="primary"
                                name={flag}
                                disabled={disabled}
                                onChange={createOnChangeHandler(flag)}
                            />}
                            label={t((prefix ? (prefix + '_' ) : '') + 'flag_' + flag + '_label')}
                        />
                    </Grid>
                )
            }

        </Grid>
    );
};

export default Flags;