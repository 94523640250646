import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux'
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import Button               from "../../inputs/Button";
import ChangeStatusDialog   from "./ChangeStatusDialog";
import SendShippingDialog   from "./SendShippingDialog";
import PrintBtn             from "./PrintBtn";
import PrintIcon            from '@material-ui/icons/Print';
import PublishIcon          from '@material-ui/icons/Publish';
import GetAppIcon           from '@material-ui/icons/GetApp';
import ExportShippingDialog from "./ExportShippingDialog";
import ImportShippingDialog from "./ImportShippingDialog";
import CreateNewFolderIcon  from '@material-ui/icons/CreateNewFolder';
import GroupDialog          from "./GroupDialog";
import {printElementById}   from '../../../utils/print';
import {SHIPPING_TABLE_ID}  from './table/Table';

const BulkActions = ({selected, filters}) => {
    const {t} = useTranslation();
    const [count, setCount] = useState(Object.keys(selected).length);
    const [step, setStep] = useState('Status' in filters ? filters.Status : 'pending');

    const [sendDialogOpened, setSendDialogOpened] = useState(false);
    const [changeStatusDialogOpened, setChangeStatusDialogOpened] = useState(false);
    const [changeStatusForce, setChangeStatusForce] = useState('');

    const [showExportDialog, setShowExportDialog] = useState(false);
    const [showImportDialog, setShowImportDialog] = useState(false);
    const [showGroupDialog, setShowGroupDialog] = useState(false);

    useEffect(() => {
        setCount(Object.keys(selected).length)
    }, [selected, setCount]);

    useEffect(() => {
        setStep('Status' in filters ? filters.Status : 'pending')
    }, [filters]);

    const createChangeStatusHandler = status => e => {
        setChangeStatusForce(status);
        setChangeStatusDialogOpened(true);
    };

    const printTable = () => {
        printElementById(SHIPPING_TABLE_ID, ".print-main-row {background-color:#cfcfcf; border: 1px solid black;} .print-table {border-collapse: collapse;}")
    }

    return (
        <Grid container spacing={3} justify='flex-end'>
            {(step === 'pending' || step === 'processing') &&
            <Grid item>
                <Button disabled={count < 1} color='primary' variant='contained'
                        onClick={e => setSendDialogOpened(true)}>
                    {t('shipment_send')}
                </Button>
            </Grid>
            }
            {step === 'pending' &&
            <Grid item>
                <Button disabled={count < 1} color='primary' variant='outlined'
                        onClick={createChangeStatusHandler('processing')}>
                    {t('shipment_set_status_processing')}
                </Button>
            </Grid>
            }

            {step === 'processing' &&
            <>
                <Grid item>
                    <Button disabled={count < 1} color='primary' variant='outlined'
                            onClick={createChangeStatusHandler('pending')}>
                        {t('shipment_set_status_pending')}
                    </Button>
                </Grid>
            </>
            }

            <Grid item>
                <PrintBtn disabled={count < 1} color='primary' variant='outlined' startIcon={<PrintIcon/>}>
                    {t('print_address_btn')}
                </PrintBtn>
            </Grid>

            <Grid item>
                <Button color='primary' variant='outlined' startIcon={<PrintIcon/>} onClick={printTable}>
                    {t('print')}
                </Button>
            </Grid>

            {step !== 'sent' &&
            <Grid item>
                <Button
                    startIcon={<CreateNewFolderIcon/>}
                    disabled={count < 2}
                    color='primary'
                    variant='outlined'
                    onClick={() => setShowGroupDialog(true)}>
                    {t('shipment_group')}
                </Button>
            </Grid>
            }

            {step === 'processing' &&
            <>
                <Grid item>
                    <Button
                        startIcon={<GetAppIcon/>}
                        disabled={count < 1}
                        color='primary'
                        variant='outlined'
                        onClick={() => setShowExportDialog(true)}>
                        {t('shipment_export')}
                    </Button>
                </Grid>

                <Grid item>
                    <Button
                        startIcon={<PublishIcon/>}
                        color='primary'
                        variant='outlined'
                        onClick={() => setShowImportDialog(true)}>
                        {t('shipment_import')}
                    </Button>
                </Grid>
            </>
            }

            <ChangeStatusDialog open={changeStatusDialogOpened} setOpen={setChangeStatusDialogOpened}
                                force={changeStatusForce}/>

            <SendShippingDialog open={sendDialogOpened} setOpen={setSendDialogOpened}/>

            <ExportShippingDialog open={showExportDialog} setOpen={setShowExportDialog}/>

            <ImportShippingDialog open={showImportDialog} setOpen={setShowImportDialog}/>

            <GroupDialog open={showGroupDialog} setOpen={setShowGroupDialog}/>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        selected: state.shipping.selectedShippings,
        filters: state.shipping.shippingsFilters,
    }
};

export default connect(mapStateToProps)(BulkActions);