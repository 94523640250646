import React, { useEffect, useState }                                       from 'react';
import { useTranslation }                                                   from 'react-i18next';
import { Select, TextField }                                                from '@material-ui/core';
import Grid                                                                 from '@material-ui/core/Grid';
import FormControl                                                          from '@material-ui/core/FormControl';
import InputLabel                                                           from '@material-ui/core/InputLabel';
import SnsLinks                                                             from '../data/SnsLink/SnsLinks';
import Input                                                                from '@material-ui/core/Input';
import uuid                                                                 from '../../utils/uuid';
import defaultPicture
                                                                            from '../../../resources/images/landscape-image.svg';
import Loading                                                              from '../feeback/loading/Loading';
import { connect }                                                          from 'react-redux';
import { getText }                                                          from '../../../store/actions/TextAction';
import { getLocale }                                                        from '@karpeleslab/klbfw';
import moment                                                               from 'moment';
import DeleteDialog                                                         from './DeleteDialog';
import { createMember, updateMember, updateMemberImage }                    from '../../../store/actions/ArtistAction';
import { useHistory }                                                       from 'react-router-dom';
import { hasChanged as nameHasChanged, requiredCheck as nameRequiredCheck } from '../../utils/i18nInput';
import InputI18N                                                            from '../inputs/InputI18n';
import { getMemberEditRoute }                                               from '../../../router/routes/artist/factory/members';
import Date                                                                 from '../inputs/Date';
import Button                                                               from "../inputs/Button";
import FormRequiredField                                                    from '../feeback/FormRequiredField';


const Form = ({ artist, member, creating, updating, deleting, uploadingMemberImage, getText, createMember, updateMember, updateMemberImage, isManager, isAdmin }) => {
	const { t } = useTranslation();
	const controlDisabled = creating || updating || deleting || uploadingMemberImage || !isManager;
	const [saveDisabled, setSaveDisabled] = useState(true);

	const [showDeleteDialog, setShowDeleteDialog] = useState(false);

	const [bloodLabelWidth, setBloodLabelWidth] = useState();
	const bloodLabel = React.useRef(null);

	const [name, setName] = useState({});
	const [needDetailedName, setNeedDetailedName] = useState(false);

	const [bloodType, setBloodType] = useState('');
	const [birthdate, setBirthdate] = useState(null);

	const [order, setOrder] = useState();

	const [biography, setBiography] = useState('');
	const [biographyLanguage, setBiographyLanguage] = useState(getLocale());


	const [links, setLinks] = useState([]);
	const [profilePicture, setProfilePicture] = useState(undefined);
	const [profileSrc, setProfileSrc] = useState(defaultPicture);
	// Use to trick react to rerender the input without a value
	const [profilePictUuid, setProfilePictUuid] = useState(uuid);
	const [pictureEdited, setPictureEdited] = useState(false);


	const [loaded, setLoaded] = useState(!member);
	// Text data
	const [biographyText, setBiographyText] = useState();
	const [nameText, setNameText] = useState();

	const history = useHistory();

	// Initialise data, Get text and set internal State for the edit mode
	useEffect(() => {
		if (!member) return;
		const promises = [];
		promises.push(getText(member.Name_Text__)
			.then(text => {
					setNameText(text);
					setName(text.Values);
				}
			));

		promises.push(getText(member.Bio_Text__)
			.then(text => {
				const k = Object.keys(text.Values);
				if (k.length > 0) {
					setBiographyText(text);
					setBiographyLanguage(k[0]);
					setBiography(text.Values[k[0]]);
				}
			}));


		setBloodType(member.Blood_Type ?? undefined);
		setBirthdate(member.Birthdate ? moment(member.Birthdate) : null);
		setOrder(member.Order);

		// Dirty, we need to copy the link list so we can compare it later, to enable/disable the save btn
		const copy = [];
		member.Links.forEach(link => copy.push({ ...link }));
		setLinks(copy);


		if (member.Image_Drive_Item__) {
			setProfileSrc(member.Image_Drive_Item.Icon);
		}
		//
		Promise.all(promises).then(r => setLoaded(true));

	}, [member, setLoaded, getText]);

	// Used to enable/disable the save btn in edit mode
	useEffect(() => {
		if (!loaded) {
			setSaveDisabled(true);
			return;
		}

		if (!member) return;
		// Check minimum required field
		if (!nameRequiredCheck(name, needDetailedName)) {
			setSaveDisabled(true);
			return;
		}

		//Enable btn only if there is some changes
		if (bloodType !== (member.Blood_Type ?? undefined)) {
			setSaveDisabled(false);
			return;
		}

		if (order !== member.Order) {
			setSaveDisabled(false);
			return;
		}

		if (!birthdate && member.Birthdate) {
			setSaveDisabled(false);
			return;
		}

		if (birthdate && birthdate.format('YYYY-MM-DD') !== member.Birthdate) {
			setSaveDisabled(false);
			return;
		}

		if (pictureEdited) {
			setSaveDisabled(false);
			return;
		}
		if (!(biographyLanguage in biographyText.Values)) {
			// We previously didn't have any biography, but now we have
			if (biography.trim()) {
				setSaveDisabled(false);
				return;
			}
		} else {
			if (biographyText.Values[biographyLanguage] !== biography.trim()) {
				setSaveDisabled(false);
				return;
			}
		}

		if (nameHasChanged(nameText.Values, name)) {
			setSaveDisabled(false);
			return;
		}

		if (member.Links.length !== links.length) {
			setSaveDisabled(false);
			return;
		}

		//Dirty as fuck
		for (let i = 0; i < member.Links.length; i++) {
			for (let j = 0; j < links.length; j++) {
				if (links[j].Type === member.Links[i].Type &&
					member.Links[i].Link !== links[j].Link) {
					setSaveDisabled(false);
					return;
				}
			}
		}

		setSaveDisabled(true);

	}, [
		loaded,
		biographyLanguage,
		biographyText,
		nameText,
		order,
		member, name, bloodType, birthdate, needDetailedName, links, biography, pictureEdited

	]);

	// Used to enable/disable the save btn in create mode
	useEffect(() => {
		if (member) return;
		if (!nameRequiredCheck(name, needDetailedName)) {
			setSaveDisabled(true);
			return;
		}

		setSaveDisabled(false);
	}, [name, needDetailedName, setSaveDisabled, member]);


	// Update img src tag
	useEffect(() => {
		if (profilePicture === undefined) {
			if (member && member.Image_Drive_Item__)
				setProfileSrc(member.Image_Drive_Item.Icon);
			else
				setProfileSrc(defaultPicture);
			return;
		}

		const fr = new FileReader();
		fr.onload = function () {
			setProfileSrc(fr.result);
		};
		fr.readAsDataURL(profilePicture);

	}, [profilePicture, member]);


	useEffect(() => {
		if (!bloodLabel.current) return;
		setBloodLabelWidth(bloodLabel.current.offsetWidth);
	}, [bloodLabel, loaded, setBloodLabelWidth]);

	const onPictureChange = (e) => {
		if (e.target.files.length < 1) {
			setProfilePicture(undefined);
		} else {
			setProfilePicture(e.target.files[0]);
		}

		setPictureEdited(true);
	};

	const onRevertPicture = () => {
		setProfilePicture(undefined);
		setPictureEdited(false);
		setProfilePictUuid(uuid());
	};

	const handleEdit = () => {
		const bioTextValues = {
			[biographyLanguage]: biography
		};

		const params = {
			base: {
				Links: links,
				Birthdate: birthdate ? birthdate.format('YYYY-MM-DD') : null,
				Blood_Type: bloodType,
				Order: order,
			},
			texts: [
				{
					Text__: nameText.Text__,
					Values: name
				},
				{
					Text__: biographyText.Text__,
					Values: bioTextValues
				}
			]
		};


		if (pictureEdited) {
			updateMemberImage(member.Music_Label_Artist_Member__, profilePicture, 'main')
				.then(a => updateMember(member.Music_Label_Artist_Member__, params))
				.then(b => setPictureEdited(false))
				.then(b => setProfilePictUuid(uuid()));

		} else {
			updateMember(member.Music_Label_Artist_Member__, params);
		}
	};

	const handleCreate = () => {
		let formatedMember =
			{
				name: name,
				Links: links,
				Order: order,
				biography: { [biographyLanguage]: biography },
				bloodType: bloodType ? bloodType : null,
				birthdate: birthdate ? birthdate.format('YYYY-MM-DD') : null

			};

		createMember(artist.Music_Label_Artist__, formatedMember, profilePicture)
			.then(d => {
				history.push(getMemberEditRoute(d.Music_Label_Artist_Member__));
			});
	};

	const handleSave = () => {
		member ? handleEdit() : handleCreate();
	};

	if (!loaded) {
		return <Loading/>;
	}

	return (
		<Grid container spacing={3}>
			<FormRequiredField/>
			<Grid item xs={12}>
				<InputI18N
					value={name}
					onChange={setName}
					onDetailedNameChange={setNeedDetailedName}
					labelKey='artist_member_name'
					disabled={controlDisabled || !isManager}
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<FormControl variant='outlined' fullWidth
				             disabled={controlDisabled}
				>
					<InputLabel ref={bloodLabel} htmlFor='blood-selection'>{t('member_blood_label')}</InputLabel>
					<Select
						value={bloodType}
						onChange={e => setBloodType(e.target.value ? e.target.value : undefined)}
						native
						labelWidth={bloodLabelWidth}
						inputProps={{
							id: 'blood-selection'
						}}
					>
						<option value={undefined}/>
						<option value='O'>O</option>
						<option value='A'>A</option>
						<option value='B'>B</option>
						<option value='AB'>AB</option>
					</Select>
				</FormControl>
			</Grid>

			<Grid item xs={12} md={6}>
				<Date
					disabled={controlDisabled}
					disableFuture
					openTo='year'
					format='LL'
					views={['year', 'month', 'date']}
					label={t('profile_birthdate')}
					inputVariant='outlined'
					fullWidth
					value={birthdate ?? null}
					onChange={setBirthdate}
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<TextField
					disabled={controlDisabled}
					required
					label={t('member_order')}
					helperText={t('member_order_helperText')}
					fullWidth
					variant='outlined'
					value={order}
					inputProps={{ min: 0 }}
					type='number'
					onChange={e => setOrder(e.target.value)}/>
			</Grid>

			<Grid item xs={12}>
				<SnsLinks values={links} onChange={setLinks} disabled={controlDisabled}/>
			</Grid>

			<Grid item xs={12} md={6}>
				<Grid container spacing={3} alignItems='center'>
					<Grid item>
						<img height={150} src={profileSrc} alt={t('member_avatar')}/>
					</Grid>
					<Grid item>
						<FormControl fullWidth disabled={controlDisabled}>
							<InputLabel shrink={true} htmlFor='member-avatar'>{t('member_avatar')}</InputLabel>
							<Input
								key={profilePictUuid}
								onChange={onPictureChange} inputProps={
								{
									accept: 'image/png, image/jpeg, image/jpg, image/svg'
								}
							}
								id='member-avatar' type='file'/>
						</FormControl>
						{pictureEdited &&
						<Button color='secondary' variant='text' onClick={onRevertPicture} disabled={controlDisabled}>
							{t('cancel_edit_picture')}
						</Button>}
					</Grid>
				</Grid>
			</Grid>

			<Grid item xs={12}>
				<TextField
					variant='outlined'
					multiline rows={5}
					rowsMax={Infinity}
					placeholder={t('member_bio_placeholder')}
					fullWidth
					value={biography}
					onChange={e => setBiography(e.target.value)}
					label={t('member_bio_label')}
					disabled={controlDisabled}
				/>
			</Grid>

			<Grid item xs={12}>
				<Grid container justify={(member && isAdmin) ? 'space-between' : 'flex-end'}>
					{(member && isAdmin) && <Button variant='text' color='secondary' disabled={controlDisabled}
					                   onClick={e => setShowDeleteDialog(true)}>
						{t('delete_btn')}
					</Button>}
					<Button variant='contained' color='primary' disabled={controlDisabled || saveDisabled}
					        onClick={handleSave}>
						{member ? t('save_btn') : t('add_btn')}
					</Button>
				</Grid>
			</Grid>
			{member && <DeleteDialog open={showDeleteDialog} setOpen={setShowDeleteDialog} member={member}/>}
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		artist: state.artist.artist,
		deleting: state.artist.memberDeleting,
		updating: state.artist.memberUpdating,
		uploadingMemberImage: state.artist.uploadingMemberImage,
		isManager: state.access.manager,
		isAdmin: state.access.admin
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getText: (id) => dispatch(getText(id)),
		updateMember: (id, data) => dispatch(updateMember(id, data)),
		createMember: (artistId, data, picture) => dispatch(createMember(artistId, data, picture)),
		updateMemberImage: (id, file, purpose) => dispatch(updateMemberImage(id, file, purpose))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
