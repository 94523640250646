import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import React from 'react';

const theme = createTheme({
    palette: {
		background:{
			paper: '#2c2c2c',
		},
        primary: {
            gradient: 'linear-gradient(to right, #e40043 ,#f58e28)',
            gradientInverted: 'linear-gradient(90deg, #FFB017 -2.9%, #F48729 12.04%, #EE5832 64.32%, #EB3434 79.26%, #E40037 92.7%)',
            main: '#ff9100',
        },
	    secondary: {
		    main: '#e50137',
	    },
        type: 'dark',
    },
	overrides: {
		MuiFormLabel:{
			asterisk: {
				color: '#e50137',
				'&$error': {
					color: '#e50137'
				},
			}

		}
	},


});

const Theme = ({children}) => {
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    );
};

export default Theme;
