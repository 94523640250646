import {rest} from "@karpeleslab/klbfw";
import {useState} 	from 'react'

export const useCatalogProducts = (catalogId) => {
    const [products, setProducts] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetch = (search, paging = {}) => {
        let params = {
            'Catalog__': catalogId,
            ...paging
        };

        if (search) {
            if (typeof search === 'string' && search.trim().length > 0)
                params.Name = {'$prefix': search};
            else
                params = Object.assign(params, search);
        }

        setLoading(true);
        return rest(`Catalog/${catalogId}/Product`, 'GET', params)
            .then(setProducts)
            .catch(e => setProducts({error: e}))
            .finally(() => setLoading(false));
    };

    return [products, fetch, loading];
};

