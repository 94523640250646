import React       from 'react';
import Card        from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {Radio}     from '@material-ui/core';
import Grid        from '@material-ui/core/Grid';
import makeStyles  from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
	location: {
		height: '100%',
		'&:hover': {
			cursor: 'pointer',
		}
	},
}));

const Location = ({location, selected, setSelected, disabled}) => {
	const classes = useStyles();
	const handleChange = () => {
		if (disabled) return;
		setSelected(location.User_Location__);
	};

	return (
		<Card
			variant={selected ? 'elevation' : 'outlined'}
			className={classes.location}
			elevation={3}
			onClick={handleChange}
		>
			<CardContent>
				<Grid container
				      direction="column"
				      spacing={3}
				>
					<Grid item style={{textAlign: 'right'}}>
						<Radio
							disabled={disabled}
							checked={selected}
							onChange={handleChange}
						/>
					</Grid>
					<Grid item>
						{location.Display.map((d, idx) => <>{d}<br/></>)}
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

export default Location;