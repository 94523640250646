import React                  from 'react';
import {connect}              from 'react-redux';
import Grid                   from '@material-ui/core/Grid';
import AppBarTitle            from '../../../components/common/typography/AppBarTitle';
import {useTranslation}       from 'react-i18next';
import Toolbar                from '../../../components/common/typography/Toolbar';
import AccessCheck            from '../../../components/Route/AccessCheck';
import Redirect               from '../../../components/Route/Redirect';
import {getPXLDashboardRoute} from '../../../router/routes/pxl/factory/general';
import SelectRealm            from '../../SelectRealm';
import Wizard                 from '../../../components/wizard/product/Wizard';
import {useParams}            from 'react-router';

const Create = ({selectedRealm}) => {
	const {t} = useTranslation();
	const {productId} = useParams();
	if (!selectedRealm) return <SelectRealm/>;

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={productId ? t('product_variation_creation_title') : t('product_creation_title')}/>
			<Grid item xs={12}>
				<Toolbar title={productId ? t('product_variation_creation_title') : t('product_creation_title')}/>
			</Grid>

			<AccessCheck
				capabilities={{store: true}}
				rights={{manager: true}}
				deniedComponent={<Redirect target={getPXLDashboardRoute()}/>}
			>
				<Grid item xs={12}>
					<Wizard/>
				</Grid>
			</AccessCheck>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
	};
};


export default connect(mapStateToProps)(Create);
