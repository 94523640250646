import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {connect} from 'react-redux'
import {useHistory} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {deleteEntryData} from "../../../../store/actions/CmsAction";
import { useTranslation } from 'react-i18next';
import {getFanclubArticlesRoute} from "../../../../router/routes/fanclub/factory/content";
import {getSiteNewsRoute} from "../../../../router/routes/site/factory/content";
import {getPXLDashboardRoute} from "../../../../router/routes/pxl/factory/general";
import Button from "../../inputs/Button";

const DeleteDialog = ({open, setOpen, deleting, entryData, deleteEntryData, fanclubCms, newsCms}) => {
    let history = useHistory();
    const { t } = useTranslation();
    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = (e) => {
        e.stopPropagation();
        e.preventDefault();

        deleteEntryData(entryData.Content_Cms_Entry_Data__)
            .then(d => {
                handleClose();
                if (fanclubCms && fanclubCms.Content_Cms__ === entryData.Content_Cms__)
                    history.push(getFanclubArticlesRoute());
                else if (newsCms && newsCms.Content_Cms__ === entryData.Content_Cms__)
                    history.push(getSiteNewsRoute());
                else
                    history.push(getPXLDashboardRoute());
            })
    };

    return (

        <Dialog
            open={open}
            aria-labelledby="Delete article"
            aria-describedby="Delete article"
        >
            <DialogTitle id="delete-article-title">{t('confirm_delete_article')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="delete-article-description">
                    {t('once_confirmed_not_able_recover_content')}
                    <br/>
                    <Typography variant="body1" color="textPrimary" component="span">{t('are_you_sure')}</Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' color="primary" autoFocus onClick={handleClose} disabled={deleting}>
                    {t('back_btn')}
                </Button>
                <Button color="secondary" disabled={deleting} loading={deleting} endIcon={<DeleteForeverIcon/>} onClick={handleDelete}>
                    {t('delete_forever_btn')}
                </Button>
            </DialogActions>

        </Dialog>

    );
};

const mapStateToProps = (state) => {
    return {
        deleting: state.cms.deletingEntryData,
        fanclubCms: state.cms.fanclub_cms,
        newsCms: state.cms.news_cms
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        deleteEntryData: (entryDataId) => dispatch(deleteEntryData(entryDataId)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDialog);
