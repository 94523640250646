import React                     from 'react';
import Dialog                    from '@material-ui/core/Dialog';
import DialogTitle               from '@material-ui/core/DialogTitle';
import DialogContent             from '@material-ui/core/DialogContent';
import DialogContentText         from '@material-ui/core/DialogContentText';
import DialogActions             from '@material-ui/core/DialogActions';
import {connect}                 from 'react-redux';
import {useHistory}              from 'react-router-dom';
import Typography                from '@material-ui/core/Typography';
import {duplicateEntry}          from '../../../../store/actions/CmsAction';
import {Trans, useTranslation}   from 'react-i18next';
import {getSiteContentEditRoute} from '../../../../router/routes/site/factory/content';
import Button                    from '../../inputs/Button';
import FileCopyIcon              from '@material-ui/icons/FileCopy';

const DuplicateDialog = ({open, setOpen, duplicating, entryData, duplicateEntry, setTabIndex}) => {
	let history = useHistory();
	const {t} = useTranslation();
	const handleClose = () => {
		setOpen(false);
	};

	const handleDuplicate = (e) => {
		e.stopPropagation();
		e.preventDefault();

		duplicateEntry(entryData.Content_Cms_Entry__, t('copy_suffix'))
			.then(entry => {
				handleClose();
				history.push(getSiteContentEditRoute(entry.Data[entryData.Language__].Content_Cms_Entry_Data__));
				setTabIndex(0)
			});
	};

	return (
		<Dialog
			open={open}
			aria-labelledby="Duplicate article"
			aria-describedby="Duplicate article"
		>
			<DialogTitle id="duplicate-article-title">{t('article_duplicate_title')}</DialogTitle>
			<DialogContent>
				<DialogContentText id="duplicate-article-description">
					<Trans i18nKey='article_duplicate_text'>
						sample<br/>
						<strong>sample</strong>
					</Trans>
					<br/>
					<Typography variant="body1" color="textPrimary" component="span">{t('are_you_sure')}</Typography>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button color="primary" autoFocus onClick={handleClose} disabled={duplicating}>
					{t('back_btn')}
				</Button>
				<Button color="primary" disabled={duplicating} variant='contained' loading={duplicating} endIcon={<FileCopyIcon/>}
				        onClick={handleDuplicate}>
					{t('duplicate_btn')}
				</Button>
			</DialogActions>

		</Dialog>

	);
};

const mapStateToProps = (state) => {
	return {
		duplicating: state.cms.duplicating,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		duplicateEntry: (entryId, suffix) => dispatch(duplicateEntry(entryId, suffix)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateDialog);
