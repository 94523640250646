import React, {useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next';
import {fetchShippings, sendShipping, setSelectedShippings} from "../../../../store/actions/store/ShippingAction";
import Button from "../../inputs/Button";
import TextField from "@material-ui/core/TextField";

const SendShippingDialog = ({open, setOpen, sending, selected, refresh, sendShipping, refreshing, resetSelected}) => {
    const {t} = useTranslation();
    const [tracking, setTracking] = useState();

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = (e) => {
        e.stopPropagation();
        e.preventDefault();

        sendShipping(Object.keys(selected), tracking)
            .then(refresh)
            .then(resetSelected)
            .then(handleClose);
    };

    return (
        <Dialog
            open={open}
            aria-labelledby={t('shipping_send_title')}
            aria-describedby={t('shipping_send_title')}
        >
            <form onSubmit={handleSubmit}>
                <DialogTitle
                    id="shipping-send-title">{t('shipping_send_title', {count: Object.keys(selected).length})}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="shipping-send-description">
                        {t('shipping_send_description')}
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField label={t('shipping_tracking')} fullWidth variant="outlined" value={tracking}
                                       disabled={refreshing || sending}
                                       onChange={e => setTracking(e.target.value)}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="default" onClick={handleClose} disabled={refreshing || sending}>
                        {t('close_btn')}
                    </Button>
                    <Button color="primary" variant='contained' loading={refreshing || sending}
                            disabled={refreshing || sending} type='submit'>
                        {t('send_btn')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>

    );
};

const mapStateToProps = (state) => {
    return {
        refreshing: state.shipping.shippingsLoading,
        sending: state.shipping.sendingShipping,
        selected: state.shipping.selectedShippings
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        refresh: () => dispatch(fetchShippings()),
        sendShipping: (ids, tracking) => dispatch(sendShipping(ids, tracking)),
        resetSelected: () => dispatch(setSelectedShippings({}))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SendShippingDialog);