import React, {useEffect, useState} from 'react';
import {connect}                    from 'react-redux';
import {resetEntriesPaging}         from '../../store/actions/CmsAction';
import AppBarTitle                  from '../../components/common/typography/AppBarTitle';
import Grid                         from '@material-ui/core/Grid';
import EntriesTable                 from '../../components/common/cms/table/Table';
import AddIcon                      from '@material-ui/icons/Add';
import CreateDialog                 from '../../components/common/cms/entry/CreateDialog';
import {useTranslation}             from 'react-i18next';
import SelectRealm                  from '../SelectRealm';
import Empty                        from '../../components/common/news/table/Empty';
import HasRight                     from '../../components/Route/HasRight';
import Toolbar                      from '../../components/common/typography/Toolbar';
import Button                       from '../../components/common/inputs/Button';
import Filters                      from '../../components/common/cms/table/Filters';
import NewsActivator                from '../../components/common/cms/NewsActivator';

const News = ({selectedRealm, newsCms, resetEntriesPaging}) => {
	const [showTable, setShowTable] = useState(false);
	const [showCreate, setShowCreate] = useState(false);
	const [cms, setCms] = useState(null);
	const {t} = useTranslation();

	useEffect(() => {
		resetEntriesPaging();
	}, [resetEntriesPaging]);

	useEffect(() => {
		if (!newsCms) return;

		setCms(newsCms);
		setShowTable(true);

	}, [setShowTable, newsCms, setCms]);

	if (!selectedRealm) return <SelectRealm/>;

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('news_article_title')}/>

			<Grid item xs={12}>
				<Toolbar title={t('news_article_title')}>
					<HasRight manager hideLoading>
						<Button endIcon={<AddIcon/>} variant="contained" color="primary"
						        onClick={e => setShowCreate(true)}>
							{t('create_btn')}
						</Button>
					</HasRight>
				</Toolbar>
			</Grid>

			<NewsActivator/>

			<Grid item xs={12}>
				<Filters disabled={!showTable} cms={cms}/>
			</Grid>
			<Grid item xs={12}>
				<EntriesTable disabled={!showTable} noEntriesComponent={<Empty/>} cmsId={cms && cms.Content_Cms__}/>
			</Grid>

			{newsCms && <CreateDialog setOpen={setShowCreate} open={showCreate} cmsId={newsCms.Content_Cms__}/>}
		</Grid>

	);
};

const mapStateToProps = (state) => {
	return {
		newsCms: state.cms.news_cms,
		selectedRealm: state.realm.selected,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		resetEntriesPaging: () => dispatch(resetEntriesPaging())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(News);
