import {
    USER_LOCATION_CREATE,
    USER_LOCATION_CREATE_DONE,
    USER_LOCATION_FETCH,
    USER_LOCATION_FETCH_DONE,
    USER_LOCATION_UPDATE,
    USER_LOCATION_UPDATE_DONE
} from "../../reducers/system/UserLocationReducer";
import {handleError} from "../../../components/utils/apiErrorHandler";
import {rest} from '@karpeleslab/klbfw'

export const createUserLocation = (data) => {
    return (dispatch, getState) => {
        dispatch({type: USER_LOCATION_CREATE})
        return rest('User/Location', 'POST', data)
            .then(d => {
                dispatch({type: USER_LOCATION_CREATE_DONE})
                return d.data;
            }).catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
}


export const updateUserLocation = (id, data) => {
    return (dispatch, getState) => {
        dispatch({type: USER_LOCATION_UPDATE})
        return rest('User/Location/' + id, 'PATCH', data)
            .then(d => {
                dispatch({type: USER_LOCATION_UPDATE_DONE, location: d.data})
                return d.data;
            }).catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
}

export const fetchUserLocation = (id) => {
    return (dispatch, getState) => {
        dispatch({type: USER_LOCATION_FETCH})
        return rest('User/Location/' + id)
            .then(d => {
                dispatch({type: USER_LOCATION_FETCH_DONE, location: d.data})
                return d.data;
            }).catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
}
