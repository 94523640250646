import React, {useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import {connect} from 'react-redux'
import {Trans, useTranslation} from 'react-i18next';
import {shippingExport} from "../../../../store/actions/store/ShippingAction";
import Button from "../../inputs/Button";
import RichAlert from "../../feeback/RichAlert";
import {getPXLContactRoute} from "../../../../router/routes/pxl/factory/general";
import ShippingProviderSelector from "../../inputs/ShippingProviderSelector";
import ImportExportErrorsTable from "./ImportExportErrorsTable";
import {downloadUrl} from "../../../utils/dowloadUrl";
import Link from "../../typography/Link";

const ExportShippingDialog = ({open, setOpen, exporting, selected, shippingExport}) => {
    const {t} = useTranslation();
    const [provider, setProvider] = useState();
    const [file, setFile] = useState(null)
    const [errors, setErrors] = useState([])
    const [exportCount, setExportCount] = useState(0)

    const handleClose = () => {
        setOpen(false);
    };

    const handleExport = (e) => {
        e.stopPropagation();
        e.preventDefault();

        shippingExport(Object.keys(selected), provider)
            .then(result => {
                setErrors(result.errors)
                setFile(result.file)
                setExportCount(result.count)
            })

    };

    return (
        <Dialog
            onEnter={() => {
                setProvider(undefined);
                setFile(null);
                setErrors([]);
            }}
            open={open}
            aria-labelledby={t('shipping_export_title')}
            aria-describedby={t('shipping_export_title')}
        >
            <DialogTitle
                id="shipping-export-title">{t('shipping_export_title', {count: Object.keys(selected).length})}</DialogTitle>
            <DialogContent>
                <DialogContentText id="shipping-export-description">
                    {(!file && errors.length < 1) && <RichAlert severity='info'>
                        <Trans i18nKey='shipping_export_desc'>
                            sample<br/>
                            <Link to={getPXLContactRoute()}>sample</Link>
                        </Trans>
                    </RichAlert>}

                    {file &&
                    <RichAlert severity='info'>
                        <Trans i18nKey='shipping_export_complete'>
                            sample<br/>
                            <strong>{{exportCount}}</strong>
                        </Trans>
                    </RichAlert>
                    }
                </DialogContentText>
                <Grid container spacing={2}>
                    {(!file && errors.length < 1) &&
                    <Grid item xs={12} md={12}>
                        <ShippingProviderSelector
                            value={provider}
                            setValue={setProvider}
                            disabled={exporting}
                            required
                        />
                    </Grid>
                    }

                    {errors.length > 0 && <ImportExportErrorsTable errors={errors}/>}
                </Grid>


            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={handleClose} disabled={exporting}>
                    {t('close_btn')}
                </Button>

                <Button
                    color="primary"
                    variant='contained'
                    loading={exporting}
                    disabled={exporting || !provider}
                    type='submit'
                    onClick={e => file ? downloadUrl(file.Url_Download, t('export.csv')) : handleExport(e)}
                >
                    {t(file ? 'download_btn' : 'export_btn')}
                </Button>
            </DialogActions>
        </Dialog>

    );
};

const mapStateToProps = (state) => {
    return {
        exporting: state.shipping.exportingShipping,
        selected: state.shipping.selectedShippings
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        shippingExport: (ids, format) => dispatch(shippingExport(ids, format))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportShippingDialog);