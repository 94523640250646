import React, {useEffect, useState} from 'react';
import {useTranslation}        from 'react-i18next';
import Dialog                  from '@material-ui/core/Dialog';
import DialogTitle             from '@material-ui/core/DialogTitle';
import DialogContent           from '@material-ui/core/DialogContent';
import Grid                    from '@material-ui/core/Grid';
import TextField               from '@material-ui/core/TextField';
import DialogActions           from '@material-ui/core/DialogActions';
import Button                  from '../../../../inputs/Button';
import {connect}               from 'react-redux';
import {Box}                   from '@material-ui/core';
import {createShippingFeeZone} from '../../../../../../store/actions/store/ShippingFeeAction';
import FormRequiredField       from '../../../../feeback/FormRequiredField';

const CreateDialog = ({feeId, open, setOpen, creating, create, onCreated}) => {
	const {t} = useTranslation();
	const [name, setName] = useState('');

	const [saveDisabled, setSaveDisabled] = useState(true);

	useEffect(() => {
		setSaveDisabled((name.trim() === ''));
	}, [name, setSaveDisabled]);

	const handleClose = () => {
		setOpen(false);
	};

	const handleCreate = (e) => {
		e.preventDefault();
		create(feeId, {Name: name})
			.then((s) => onCreated(s))
			.then(handleClose);
	};

	return (
		<Dialog
			disableEnforceFocus
			open={open}
			aria-labelledby={t('create_shipping_fee_zone')}
			aria-describedby={t('create_shipping_fee_zone')}
			onEnter={() => {
				setName('');
			}}
		>
			<form onSubmit={handleCreate}>
				<DialogTitle>{t('create_shipping_fee_zone')}</DialogTitle>
				<DialogContent>
					<Box py={2}>
						<Grid container spacing={3}>
							<FormRequiredField/>
							<Grid item xs={12}>
								<TextField
									label={t('shipping_fee_zone_name')}
									fullWidth variant="outlined" value={name}
									required
									disabled={creating}
									onChange={e => setName(e.target.value)}
								/>
							</Grid>
						</Grid>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button color="default" onClick={handleClose} disabled={creating}>
						{t('close_btn')}
					</Button>

					<Button color="primary" type="submit" variant="contained"
					        disabled={creating || saveDisabled}
					        loading={creating}>
						{t('create_btn')}
					</Button>
				</DialogActions>
			</form>
		</Dialog>

	);
};
const mapStateToProps = (state) => {
	return {
		creating: state.shippingFee.zoneCreating,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		create: (feeId, data) => dispatch(createShippingFeeZone(feeId, data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDialog);