import React, {useEffect, useState}    from 'react';
import {connect}                       from 'react-redux';
import {resetEntriesPaging, selectCms} from '../store/actions/CmsAction';
import AppBarTitle                     from '../components/common/typography/AppBarTitle';
import Grid                            from '@material-ui/core/Grid';
import EntriesTable                    from '../components/common/cms/table/Table';
import {useTranslation}                from 'react-i18next';
import SelectRealm                     from './SelectRealm';
import Toolbar                         from '../components/common/typography/Toolbar';

const Pages = ({selectedRealm, pagesCms, selectCms, resetEntriesPaging}) => {
	const [showTable, setShowTable] = useState(false);
	const {t} = useTranslation();

	useEffect(() => {
		resetEntriesPaging();
	}, [resetEntriesPaging]);

	useEffect(() => {
		if (!pagesCms) return;
		selectCms(pagesCms);
		setShowTable(true);

	}, [setShowTable, pagesCms, selectCms]);

	if (!selectedRealm) return <SelectRealm/>;

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('pages_title')}/>

			<Grid item xs={12}>
				<Toolbar title={t('pages_title')}/>
			</Grid>

			<Grid item xs={12}>
				<EntriesTable disabled={!showTable}/>
			</Grid>

		</Grid>

	);
};

const mapStateToProps = (state) => {
	return {
		pagesCms: state.cms.pages_cms,
		selectedRealm: state.realm.selected,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		selectCms: (cms) => dispatch(selectCms(cms)),
		resetEntriesPaging: () => dispatch(resetEntriesPaging())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Pages);