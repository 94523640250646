import React, {useLayoutEffect, useState}   from 'react';
import {Grid, Typography}                   from '@material-ui/core';
import makeStyles                           from '@material-ui/core/styles/makeStyles';
import {matchPath, useHistory, useLocation} from 'react-router-dom';
import {hideMenu, setMenu}                  from '../../../store/actions/CommonAction';
import {connect}                            from 'react-redux';

const useStyles = makeStyles(theme => ({
	container: {
		marginTop: props => props.disabledMargin ? 0 :theme.spacing(3),
		marginBottom:  props => props.disabledMargin ? 0 :theme.spacing(3),
		cursor: 'pointer',
		'&:not(.selected):hover': {
			textShadow: ' 0px 0px 10px ' + theme.palette.primary.main,
			'& .menu-icon': {
				filter: `drop-shadow(0px 0px 15px ${theme.palette.primary.main});`
			}
		}
	},
	icon: {
		'&.selected': {
			fill: 'url(#linearColors)'
		}
	},
	text: {
		textTransform: 'uppercase',
		'&.selected': {
			background: theme.palette.primary.gradientInverted,
			'-webkit-background-clip': 'text',
			'-webkit-text-fill-color': 'transparent',
			backgroundClip: 'text',
			textFillColor: 'transparent',
		}
	}
}));
const DrawerItem = ({Icon, text, selectedRoutes = [], to = undefined, menu = undefined, setMenu, hideMenu, disabledMargin = false}) => {
	const classes = useStyles({disabledMargin:disabledMargin});
	const location = useLocation();
	const history = useHistory();
	const [selected, setSelected] = useState(false);

	useLayoutEffect(() => {
		let matched = false;
		for (let i = 0; i < selectedRoutes.length; i++) {
			const match = matchPath(location.pathname, selectedRoutes[i]);
			if (match) {
				matched = true;
				break;
			}
		}
		setSelected(matched);
	}, [location, setSelected, selectedRoutes]);

	const handleClick = () => {
		if (to) {
			history.push(to);
			hideMenu();
		}

		if (menu) {
			setMenu(menu);
		}
	};

	return (
		<Grid onClick={handleClick} container spacing={1} justify="center" alignItems="center"
		      className={`${classes.container} ${selected && 'selected'}`}>
			<Grid item>
				{selected && <svg width={0} height={0}>
					<linearGradient id="linearColors" x1="0%" y1="0%" x2="100%" y2="0%">
						<stop offset="-2.9%" stopColor="#FFB017"/>
						<stop offset="12.04%" stopColor="#F48729"/>
						<stop offset="64.32%" stopColor="#EE5832"/>
						<stop offset="79.26%" stopColor="#EB3434"/>
						<stop offset="92.7%" stopColor="#E40037"/>
					</linearGradient>
				</svg>}
				<Icon
					className={`menu-icon  ${classes.icon} ${selected && 'selected'}`}
					fontSize="large"
				/>

			</Grid>
			<Grid xs={12}/>
			<Grid item>
				<Typography variant="subtitle1" className={`${classes.text} ${selected && 'selected'}`}>
					{text}
				</Typography>
			</Grid>

		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setMenu: (menu) => dispatch(setMenu(menu)),
		hideMenu: () => dispatch(hideMenu()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DrawerItem);