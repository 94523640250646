import React, {useEffect, useState} from 'react';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import useTheme from "@material-ui/core/styles/useTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const useStyles = makeStyles(theme => ({
    inputComponent: {
        '& > *': {
            marginRight: theme.spacing(1)
        },
    },
    select: {
        '& > .MuiSelect-select': {
	        padding: '16.5px 14px',
	        minHeight: '24px',
        }
    }
}));

const ChipMultipleSelect = ({label, id, data, value, setValue, disabled = false}) => {
    const classes = useStyles();
    const theme = useTheme();

    const labelRef = React.useRef(null);
    const [labelWidth, setLabelWidth] = useState(0);

    useEffect(() => {
        setLabelWidth(labelRef.current.offsetWidth);
    }, []);

    const handleChange = event => {
        setValue(event.target.value);
    };

    const getStyles = (k) => {
        return {
            fontWeight:
                value.indexOf(k) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    };

    return (
        <FormControl fullWidth={true} disabled={disabled} variant="outlined">
            <InputLabel ref={labelRef} id={id + "-label"}>{label}</InputLabel>
            <Select
                labelId={id + "-label"}
                id={id}
                labelWidth={labelWidth}
                variant="outlined"
                multiple
                // input={<Input variant="outlined" id={id + 'select'}/>}
                value={value}
                onChange={handleChange}
                className={classes.select}
                renderValue={selected => (
                    <div className={classes.inputComponent}>
                        {selected.map(value => data[value])}
                    </div>
                )}
                MenuProps={MenuProps}
            >
                {Object.keys(data).map(k => (
                    <MenuItem key={k} value={k} style={getStyles(k)}>
                        {data[k]}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default ChipMultipleSelect;
