import React, { Fragment, useEffect }                     from 'react';
import TableHead                                          from '@material-ui/core/TableHead';
import MaterialTable                                      from '@material-ui/core/Table';
import TableRow                                           from '@material-ui/core/TableRow';
import TableCell                                          from '@material-ui/core/TableCell';
import TableBody                                          from '@material-ui/core/TableBody';
import TableContainer                                     from '@material-ui/core/TableContainer';
import Row                                                from './Row';
import { connect }                                        from 'react-redux';
import TableFooter                                        from '@material-ui/core/TableFooter';
import { useTranslation }                                 from 'react-i18next';
import Empty                                              from './Empty';
import { fetchAlbums, setAlbumsFilters, setAlbumsPaging } from '../../../../../store/actions/discography/AlbumAction';
import Alert                                              from '@material-ui/lab/Alert';
import Pagination                                         from '../../../data/table/Pagination';

const Table = ({ albums, fetch, loading, filters, paging, count, setAlbumsPaging, setAlbumsFilters }) => {
	const loadingData = [...Array(paging.results_per_page).keys()];
	const { t } = useTranslation();
	const displayLoading = () => loadingData.map((k) => <Row key={k} loading={true} album={null}/>);
	const displayAlbums = () => albums.map((u) => <Row key={u.Music_Label_Album__} loading={false}
	                                                   album={u}/>);

	useEffect(() => {
		fetch();
	}, [fetch, filters, paging]);

	/*const {sort} = filters;

	const sortHandler = (property, dir) => {
		let p = {...filters};
		// only 1 sort
		p.sort = {};
		p.sort[property] = dir;
		setAlbumsFilters(p);
	};*/

	return (
		<Fragment>
			{(!loading && albums.length > 0) &&
			<Alert severity='info'>{t('album_released_edit_notice')}</Alert>
			}
			{(loading || albums.length > 0) &&
			<TableContainer>
				<MaterialTable aria-label='simple table'>
					<TableHead>
						<TableRow>
							<Pagination
								setPaging={setAlbumsPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
						<TableRow>
							<TableCell> {t('tablecell_album_picture')}</TableCell>
							<TableCell> {t('tablecell_album_name')}</TableCell>
							<TableCell> {t('tablecell_album_track_count')}</TableCell>
							<TableCell> {t('tablecell_album_duration')}</TableCell>
							<TableCell align='right'/>
						</TableRow>
					</TableHead>
					<TableBody>
						{loading && displayLoading()}
						{!loading && displayAlbums()}
					</TableBody>
					<TableFooter>
						<TableRow>
							<Pagination
								setPaging={setAlbumsPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
					</TableFooter>

				</MaterialTable>
			</TableContainer>
			}
			{(!loading && albums.length < 1) && <Empty/>}
		</Fragment>
	);

};


const mapStateToProps = (state) => {
	return {
		filters: state.album.albumsFilters,
		paging: state.album.albumsPaging,
		count: state.album.albumsPagingCount,
		loading: state.album.albumsLoading,
		albums: state.album.albums
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: () => dispatch(fetchAlbums()),
		setAlbumsPaging: (newPaging) => dispatch(setAlbumsPaging(newPaging)),
		setAlbumsFilters: (newFilters) => dispatch(setAlbumsFilters(newFilters))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);

