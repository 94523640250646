import React, {useEffect, useState} from 'react';
import {editReleaseTags} from '../../../../../store/actions/discography/ReleaseAction';
import {connect}                    from 'react-redux';
import {Trans, useTranslation}      from 'react-i18next';
import uuid                         from '../../../../utils/uuid';
import {Grid, Typography}           from '@material-ui/core';
import Toolbar                      from '../../../typography/Toolbar';
import Button                       from '../../../inputs/Button';
import {Add}                        from '@material-ui/icons';
import ClassifyTagAutocomplete      from '../../../inputs/ClassifyTagAutocomplete';
import CreateCategoryDialog         from '../../../cms/entry/CreateCategoryDialog';
import EmptyReleaseTags             from './EmptyReleaseTags';

const ReleaseTags = ({ label, release, isManager, isAdmin, managingTags, editTags }) => {
	const {t} = useTranslation();
	const [articleTags, setArticleTags] = useState(release.Tags ?? []);

	const [originalMapTags, setOriginalMapTags] = useState({});
	const [originalLength, setOriginalLength] = useState();

	const [autocompleteKey, setAutoCompleteKey] = useState(uuid());
	const [createDialogOpened, setCreateDialogOpened] = useState(false);

	const [saveEnabled, setSaveEnabled] = useState(false);


	const refreshAutocompleteList = () => {
		setAutoCompleteKey(uuid());
	};

	useEffect(() => {
		let tags =  release.Tags ?? [];

		let original = [...tags];
		original = original.reduce((obj, cur) => ({...obj, [cur.Classify_Tag__]: cur}), {});

		setArticleTags(tags);
		setOriginalLength(Object.keys(original).length);
		setOriginalMapTags(original);
	}, [release, setOriginalMapTags, setArticleTags, setOriginalLength]);

	useEffect(() => {
		if (originalLength !== articleTags.length) {
			setSaveEnabled(true);
			return;
		}

		for (let i = 0; i < articleTags.length; i++) {
			if (!(articleTags[i].Classify_Tag__ in originalMapTags)) {
				setSaveEnabled(true);
				return;
			}
		}

		setSaveEnabled(false);


	}, [articleTags, setSaveEnabled, originalMapTags, originalLength]);

	const handleSave = (e) => {
		e.preventDefault();
		let toRemove = {...originalMapTags};
		let toAdd = [];

		for (let i = 0; i < articleTags.length; i++) {
			if (articleTags[i].Classify_Tag__ in toRemove) {
				delete toRemove[articleTags[i].Classify_Tag__];
			} else {
				toAdd.push(articleTags[i].Classify_Tag__);
			}
		}

		toRemove = Object.keys(toRemove);

		e.preventDefault();

		editTags(release.Music_Label_Release__, toAdd, toRemove)
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Toolbar disableGutters title={t('release_categories')}>
					{isManager &&
					<Button variant="contained" color="primary" startIcon={<Add/>}
					        onClick={() => setCreateDialogOpened(true)}>
						{t('add_btn')}
					</Button>
					}
				</Toolbar>
			</Grid>

			{articleTags.length > 0 && <Grid item xs={12}>
				<Typography variant="body2" color="textSecondary" component="p">
					<Trans i18nKey="release_empty_tags_text">
						sample <br/>
						sample
					</Trans>
				</Typography>
			</Grid>}

			<Grid item xs={12}>
				<ClassifyTagAutocomplete
					isAdmin={isAdmin}
					isManager={isManager}
					disabled={!isManager || managingTags}
					key={autocompleteKey}
					classifyId={label ? label.Classify.Classify__ : ''}
					value={articleTags}
					setValue={setArticleTags}
				/>
			</Grid>

			{articleTags.length < 1 && <Grid item xs={12}><EmptyReleaseTags/></Grid>}

			{isManager &&
			<Grid item xs={12}>
				<Grid container justifyContent="flex-end">
					<Button color="primary" variant="contained" loading={managingTags} onClick={handleSave}
					        disabled={!saveEnabled || managingTags}>
						{t('save_btn')}
					</Button>
				</Grid>
			</Grid>
			}

			{isManager && <CreateCategoryDialog
				open={createDialogOpened}
				setOpen={setCreateDialogOpened}
				classifyId={label ? label.Classify.Classify__ : ''}
				onCreated={refreshAutocompleteList}
			/>}

		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		label: state.music.label,
		isAdmin: state.access.admin,
		isManager: state.access.manager,
		managingTags: state.release.updatingTags,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		editTags: (releaseId, toAdd, toDelete) => dispatch(editReleaseTags(releaseId, toAdd, toDelete)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReleaseTags);
