import React, {useEffect, useState} from 'react';
import Grid                         from '@material-ui/core/Grid';
import AppBarTitle                  from '../../../components/common/typography/AppBarTitle';
import {useTranslation}             from 'react-i18next';
import {connect}                    from 'react-redux';
import SelectRealm                  from '../../SelectRealm';
import Table                        from '../../../components/common/store/shop/table/Table';
import Toolbar                      from '../../../components/common/typography/Toolbar';
import Redirect                     from '../../../components/Route/Redirect';
import {getPXLDashboardRoute}       from '../../../router/routes/pxl/factory/general';
import HasCapability                from '../../../components/Route/HasCapability';
import {setProductsFilters}         from '../../../store/actions/store/ProductAction';
import Loading                      from '../../../components/common/feeback/loading/Loading';
import HasRight                     from '../../../components/Route/HasRight';
import Button                       from '../../../components/common/inputs/Button';
import AddIcon                      from '@material-ui/icons/Add';
import StoreActivator               from '../../../components/common/store/StoreActivator';
import {Link}                       from 'react-router-dom';
import {getStoreProductCreateRoute} from '../../../router/routes/store/factory/store';

const List = ({catalog, selectedRealm, productsFilters, setProductsFilters}) => {
	const {t} = useTranslation();
	const [showTable, setShowTable] = useState(false);

	useEffect(() => {
		setProductsFilters({...productsFilters, Parent_Catalog_Product__: {'$null': true}});
		setShowTable(true);
		// eslint-disable-next-line
	}, [setProductsFilters, setShowTable]);

	if (!selectedRealm || !catalog) return <SelectRealm/>;

	return (
		<Grid container spacing={3}>
			<HasCapability store hideLoading deniedComponent={<Redirect target={getPXLDashboardRoute()}/>}>
				<AppBarTitle title={t('shop_title')}/>
				<StoreActivator/>
				<Grid item xs={12}>
					<Toolbar title={t('shop_title')}>
						<HasRight manager>
							<Button
								component={Link}
								to={getStoreProductCreateRoute()}
								endIcon={<AddIcon/>}
								color="primary"
								variant="contained"
							>
								{t('create_btn')}
							</Button>
						</HasRight>
					</Toolbar>
				</Grid>

				<Grid item xs={12}>
					{showTable && <Table catalog={catalog}/>}
					{!showTable && <Loading/>}
				</Grid>
			</HasCapability>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		catalog: state.store.catalog,
		productsFilters: state.product.productsFilters,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setProductsFilters: (newFilters) => dispatch(setProductsFilters(newFilters)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
