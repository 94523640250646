import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import {copyToClipboard} from "../../../utils/misc";
import Button from "../../inputs/Button";
import LinkIcon from '@material-ui/icons/Link';
import CopyToClipboard from 'react-copy-to-clipboard';


const NewCodeDialog = ({newCode, setNewCode}) => {
    const {t} = useTranslation();

    const onCloseDialog = () => {
        setNewCode("")
    }

    return (
        <Dialog
            open={!!newCode}
            onClose={onCloseDialog}
            aria-labelledby="new-coupon-created-dialog-title"
            aria-describedby="new-coupon-created-dialog-description"
        >
            <DialogTitle id="new-coupon-created-dialog-title">
                {t('new_coupon_created')}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            disabled
                            type="text"
                            value={newCode}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CopyToClipboard
                            text={newCode}
                            onCopy={() => copyToClipboard()}
                        >
                            <Button
                                color="primary"
                                variant="contained"
                                startIcon={<LinkIcon/>}
                                fullWidth
                            >
                                {t('copy_to_clipboard')}
                            </Button>
                        </CopyToClipboard>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseDialog} color="primary" autoFocus>
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>

    );
};

export default NewCodeDialog;