import {rest} from "@karpeleslab/klbfw";
import {handleError} from "../../../components/utils/apiErrorHandler";
import {
    COUPON_CREATE,
    COUPON_CREATE_DONE,
    COUPON_DELETE,
    COUPON_DELETE_DONE,
    COUPON_LIST_FETCH,
    COUPON_LIST_FETCH_DONE,
    COUPON_SET_FILTERS,
    COUPON_SET_PAGING,
} from "../../reducers/store/CouponReducer";
import {success} from "../system/ToastAction";

export const setCouponsPaging = (newPaging) => {
    return (dispatch, getState) => {
        dispatch({type: COUPON_SET_PAGING, paging: {...getState().coupon.couponsPaging, ...newPaging}});
    }
};

export const setCouponsFilters = (newFilters) => {
    return (dispatch, getState) => {
        dispatch({type: COUPON_SET_FILTERS, filters: newFilters});
    }
};

export const fetchCoupons = () => {
    return (dispatch, getState) => {
        dispatch({type: COUPON_LIST_FETCH});

        const params = {
            ...getState().coupon.couponsFilters,
            ...getState().coupon.couponsPaging,
        };

        const id = getState().store.catalog.Catalog__;

        return rest('Catalog/' + id + '/Coupon', 'GET', params)
            .then(data => {
                dispatch({type: COUPON_LIST_FETCH_DONE, coupons: data.data, paging: data.paging});
                return data.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const createCoupon = (params) => {
    return (dispatch, getState) => {
        const id = getState().store.catalog.Catalog__;

        dispatch({type: COUPON_CREATE});
        return rest('Catalog/' + id + '/Coupon', 'POST', params)
            .then(data => {
                success('coupon_create_success');
                dispatch({type: COUPON_CREATE_DONE});
                return data.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
}

export const deleteCoupon = (id) => {
    return (dispatch, getState) => {

        dispatch({type: COUPON_DELETE});
        return rest('Catalog/Coupon/' + id, 'DELETE')
            .then(data => {
                success('coupon_delete_success');
                dispatch({type: COUPON_DELETE_DONE});
                return data.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
}