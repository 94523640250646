import React, {useEffect} from 'react';
import {useTranslation}   from 'react-i18next';
import Grid               from '@material-ui/core/Grid';
import {Typography}      from '@material-ui/core';
import {useOrderProcess} from '../../../../../../hooks/api/useOrder';
import Button            from '../../../../inputs/Button';
import {success}          from '../../../../../../store/actions/system/ToastAction';

const Free = ({ order, settings, setPaying, refreshOrder, setDialogActions = null }) => {
	const { t } = useTranslation();

	const [processOrder, processingOrder] = useOrderProcess(order.Order__);

	useEffect(() => {
		setPaying(processingOrder);
	}, [processingOrder, setPaying]);

	const handleProcess = () => {
		processOrder(settings.session, settings.method, {})
			.then(d => refreshOrder(d))
			.then(() => success('order_paid'))
	};

	useEffect(() => {
		if (!setDialogActions) return;
		setDialogActions((
			<Button
				variant='contained'
				color='primary'
				onClick={handleProcess}
				disabled={processingOrder}
				loading={processingOrder}
			>
				{t('free_order_btn')}
			</Button>
		));
	// eslint-disable-next-line
	}, [setDialogActions, processingOrder]);

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Typography>{t('free_order_text')}</Typography>
			</Grid>
			{!setDialogActions && <Grid xs={12}>
				<Grid container spacing={3} justify='center'>
					<Grid item>
						<Button
							variant='contained'
							color='primary'
							onClick={handleProcess}
							disabled={processingOrder}
							loading={processingOrder}
						>
							{t('free_order_btn')}
						</Button>
					</Grid>
				</Grid>
			</Grid>
			}
		</Grid>
	);
};

export default Free;