import {COMMON_RESET} from './CommonReducer';
import {USER_LOGOUT}  from './system/UserReducer';

export const REALM_USERS_IMAGE_VARIATION = "strip&scale_crop=150x150&format=jpeg";
export const REALM_USER_IMAGE_VARIATION = "strip&scale_crop=400x400&format=jpeg";

const initState = () => {
    return {
        loading: true,
        realms: [],
        selected: null,
        loadingAliases: false,
        aliases: [],

        usersLoading: false,
        usersFilters: {
            image_variation: REALM_USERS_IMAGE_VARIATION,
        },
        users: [],
        usersPagingCount: 0,
        usersPaging: {
            page_no: 1,
            results_per_page: 10
        },

        selectedUser: null,
        loadingUser: true,

        paymentController: null,
        paymentControllerLoading: false,
        paymentControllerSet: false,
    }
};

export const REALM_USERS_FETCH = 'REALM_USERS_FETCH';
export const REALM_USERS_FETCH_DONE = 'REALM_USERS_FETCH_DONE';
export const REALM_USERS_SET_PAGING = 'REALM_USERS_SET_PAGING';
export const REALM_USERS_SET_FILTERS = 'REALM_USERS_SET_FILTERS';

export const REALM_FETCH_USER = 'REALM_FETCH_USER';
export const REALM_FETCH_USER_DONE = 'REALM_FETCH_USER_DONE';

export const REALM_FETCH = 'REALM_FETCH';
export const REALM_FETCH_DONE = 'REALM_FETCH_DONE';

export const REALM_SELECTING = 'REALM_SELECTING';
export const REALM_SELECT = 'REALM_SELECT';
export const REALM_SITE_UPDATED = 'REALM_SITE_UPDATED';
export const REALM_ALIASES = 'REALM_ALIASES';
export const REALM_ALIASES_DONE = 'REALM_ALIASES_DONE';

export const REALM_PAYMENT_CONTROLLER_FETCH = 'REALM_PAYMENT_CONTROLLER_FETCH';
export const REALM_PAYMENT_CONTROLLER_FETCH_DONE = 'REALM_PAYMENT_CONTROLLER_DONE';
export const REALM_PAYMENT_CONTROLLER_SET = 'REALM_PAYMENT_CONTROLLER_SET';
export const REALM_PAYMENT_CONTROLLER_SET_DONE = 'REALM_PAYMENT_CONTROLLER_SET_DONE';

const realmReducer = (state = initState(), action) => {
    switch (action.type) {
        case USER_LOGOUT:
            return initState();
        case COMMON_RESET:
            const initial = initState();

            return {
                ...state,
                loadingAliases: initial.loadingAliases,
                loading: initial.loading,
                usersLoading: initial.usersLoading,
                usersFilters: initial.usersFilters,
                users: initial.users,
                usersPagingCount: initial.usersPagingCount,
                usersPaging: initial.usersPaging,
                selectedUser: initial.selectedUser,
                loadingUser: initial.loadingUser,
                selected: action.force ? initial.selected : state.selected,
            };
        case REALM_PAYMENT_CONTROLLER_FETCH:
            return {...state, paymentControllerLoading: true};
        case REALM_PAYMENT_CONTROLLER_FETCH_DONE:
            return {...state, paymentControllerLoading: false, paymentController: action.paymentController};
        case REALM_PAYMENT_CONTROLLER_SET:
            return {...state, paymentControllerSet: true};
        case REALM_PAYMENT_CONTROLLER_SET_DONE:
            return {...state, paymentControllerSet: false, paymentController: action.paymentController};
        case REALM_FETCH_USER:
            return {...state, loadingUser: true, selectedUser: null};
        case REALM_FETCH_USER_DONE:
            return {...state, loadingUser: false, selectedUser: action.user};
        case REALM_ALIASES:
            return {...state, loadingAliases: true};
        case REALM_ALIASES_DONE:
            return {...state, aliases: action.aliases, loadingAliases: false};
        case REALM_SELECT:
            return {...state, selected: action.realm};
        case REALM_SITE_UPDATED:
            state.selected.Site__ = action.site;
            return {...state, selected: state.selected};
        case REALM_FETCH:
            return {...state, loading: true};
        case REALM_FETCH_DONE:
            return {...state, loading: false, realms: action.realms};

        case REALM_USERS_FETCH:
            return {...state, usersLoading: true};
        case REALM_USERS_FETCH_DONE:
            return {
                ...state,
                usersLoading: false,
                users: action.users,
                usersPagingCount: action.paging.count
            };
        case REALM_USERS_SET_FILTERS :
            return {...state, usersFilters: action.filters};
        case REALM_USERS_SET_PAGING :
            return {...state, usersPaging: action.paging};
        default:
            return state
    }
};

export default realmReducer