import React              from 'react';
import Select             from './Select';
import { useTranslation } from 'react-i18next';

const ProductVisibilitySelector = ({value, setValue, disabled, required}) => {
    const {t} = useTranslation();

    return <Select
        label={t('product_visibility_label')}
        value={value}
        onChange={e => setValue(e.target.value)}
        optionKey='Key'
        optionValue='Value'
        allowEmpty={false}
        helperText={t('product_visibility_helperText')}
        options={[
            {Key: 'Y', Value: t('product_visibility_y')},
            {Key: 'N', Value: t('product_visibility_n')},
        ]}
        inputProps={{
            disabled: disabled,
            variant: 'outlined',
            fullWidth: true,
            required: required
        }}
    />;
};

export default ProductVisibilitySelector;
