import React, {useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import {connect} from 'react-redux'
import {fetchMembers} from "../../../store/actions/ArtistAction";
import Member from "./Member";
import EmptyList from "./EmptyList";

const List = ({artist, list, loading, fetchMembers, isManager}) => {
    useEffect(() => {
        if (!artist) return;
        fetchMembers(artist.Music_Label_Artist__);
    }, [artist, fetchMembers]);

    const loadingData = [...Array(3).keys()];

    return (
        <Grid container spacing={3}>
            {loading && loadingData.map(idx =>
                <Grid item key={'member-loading-' + idx} md={4} xs={12}>
                    <Member editable={isManager}/>
                </Grid>
            )}

            {!loading && list.map(member =>
                <Grid item key={'member-' + member.Music_Label_Artist_Member__} md={4} xs={12}>
                    <Member member={member} editable={isManager}/>
                </Grid>
            )}

            {!loading && list.length < 1 && <EmptyList/>}
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        list: state.artist.members,
        loading: state.artist.loadingMembers,
	    isManager: state.access.manager
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchMembers: (artistId) => dispatch(fetchMembers(artistId))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
