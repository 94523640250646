import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import Button from "../../../inputs/Button";
import {useTranslation} from "react-i18next";
import {isValidHttpUrl} from "../../../../utils/url";
import {getStoreFromUrl} from "../../../../utils/store";

const Create = ({artist, disabled, onAdd}) => {
    const {t} = useTranslation();
    const [value, setValue] = React.useState('');
    const [saveEnabled, setSaveEnabled] = useState(false);

    const [validUrl, setValidUrl] = useState(true);

    useEffect(() => {
        const v = value.trim();
        const valid = isValidHttpUrl(v);
        setValidUrl(valid);
        setSaveEnabled(!!v && valid);
    }, [value, setSaveEnabled]);

    const handleAddStore = () => {
        const {Key} = getStoreFromUrl(value);

        onAdd({
            Link: value,
            Store: Key
        });

        setValue('');
        setValidUrl(true);
    };

    return (
        <Grid container spacing={3} justify="flex-start">
            <Grid item xs={12} md={6}>
                <TextField
	                disabled={disabled}
                    variant='outlined'
                    placeholder={t('album_link_add_placeholder')}
                    fullWidth
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    label={t('album_link_add_label')}
                    error={(!!value && !validUrl)}
                    helperText={(!value || validUrl) ? '' : t('album_link_add_error_helper')}

                />
            </Grid>
            <Grid item>
                <Grid container>
                    <Grid item>
                        <Button variant='contained' color='primary' onClick={handleAddStore}
                                disabled={!saveEnabled || disabled}
                                endIcon={<AddIcon/>}>
                            {t('add_btn')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Create
