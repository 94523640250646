import React, {useEffect, useState} from 'react';
import {useTranslation}             from 'react-i18next';
import Dialog                       from '@material-ui/core/Dialog';
import DialogTitle                  from '@material-ui/core/DialogTitle';
import DialogContent                from '@material-ui/core/DialogContent';
import Grid                         from '@material-ui/core/Grid';
import TextField                    from '@material-ui/core/TextField';
import DialogActions                from '@material-ui/core/DialogActions';
import Button                       from '../../../../../inputs/Button';
import {connect}                    from 'react-redux';
import {Box}                        from '@material-ui/core';
import {
	fetchShippingFeeZoneValues,
	updateShippingFeeZoneValue
}                                   from '../../../../../../../store/actions/store/ShippingFeeAction';
import CountrySelector              from '../../../../../inputs/CountrySelector';

const EditDialog = ({value, open, setOpen, updating, update, refresh}) => {
	const {t} = useTranslation();
	const [country, setCountry] = useState(value.Country__);
	const [province, setProvince] = useState(value.Province);

	const [saveDisabled, setSaveDisabled] = useState(true);

	useEffect(() => {
		setCountry(value.Country__);
		setProvince(value.Province);
	}, [value, setCountry, setProvince]);

	useEffect(() => {
		if (
			country !== value.Country__ ||
			province !== value.Province) {
			setSaveDisabled(false);
			return;
		}

		setSaveDisabled(true);
	}, [country, province, value, setSaveDisabled]);

	const handleClose = () => {
		setOpen(false);
	};

	const handleUpdate = (e) => {
		e.preventDefault();
		update(value.Catalog_Deliverable_Fee_Zone_Value__, {
			Country__: country ?? null,
			Province: province.trim() ?? null
		})
			.then(() => refresh(value.Catalog_Deliverable_Fee_Zone__))
			.then(handleClose);
	};

	return (
		<Dialog
			disableEnforceFocus
			open={open}
			aria-labelledby={t('update_shipping_fee_value_zone')}
			aria-describedby={t('update_shipping_fee_value_zone')}
			onEnter={() => {
				setCountry(value.Country__);
				setProvince(value.Province);
			}}
		>
			<form onSubmit={handleUpdate}>
				<DialogTitle>{t('update_shipping_fee_zone_value')}</DialogTitle>
				<DialogContent>
					<Box py={2}>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<CountrySelector
									label={t('shipping_zone_country')}
									helperText={t('shipping_zone_country_helperText')}
									disabled={updating}
									fullWidth
									value={country}
									setValue={setCountry}
								/>
							</Grid>
							{country && <Grid item xs={12}>
								<TextField
									label={t('shipping_fee_zone_province')}
									helperText={t('shipping_fee_zone_province_helperText')}
									fullWidth variant="outlined" value={province}
									disabled={updating}
									onChange={e => setProvince(e.target.value)}
								/>
							</Grid>}
						</Grid>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button color="default" onClick={handleClose} disabled={updating}>
						{t('close_btn')}
					</Button>

					<Button color="primary" type="submit" variant="contained"
					        disabled={updating || saveDisabled}
					        loading={updating}>
						{t('save_btn')}
					</Button>
				</DialogActions>
			</form>
		</Dialog>

	);
};
const mapStateToProps = (state) => {
	return {
		updating: state.shippingFee.zoneValueUpdating,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		update: (valueId, data) => dispatch(updateShippingFeeZoneValue(valueId, data)),
		refresh: (zoneId) => dispatch(fetchShippingFeeZoneValues(zoneId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDialog);