import Settings                                                                   from '../../../pages/site/Settings';
import { getSiteAddressRoute, getSiteFinancialReportRoute, getSiteSettingsRoute } from './factory/setting';
import Report                                                                     from '../../../pages/site/Report';
import Address                                                                    from '../../../pages/site/Address';

export default [
	{
		path: getSiteSettingsRoute(),
		component: Settings,
		exact: true,
		type: 'route',
		loginRequired: true,
	},
	{
		path:          getSiteFinancialReportRoute(),
		component:     Report,
		exact:         true,
		type:          'route',
		loginRequired: true,
	},
	{
		path: getSiteAddressRoute(),
		component: Address,
		exact: true,
		type: 'route',
		loginRequired: true,
	},
];
