import {
    SCHEDULE_CREATE,
    SCHEDULE_CREATE_DONE,
    SCHEDULE_DELETE,
    SCHEDULE_DELETE_DONE, SCHEDULE_DUPLICATING, SCHEDULE_DUPLICATING_DONE,
    SCHEDULE_FETCH,
    SCHEDULE_FETCH_DONE,
    SCHEDULE_LIST_FETCH,
    SCHEDULE_LIST_FETCH_DONE,
    SCHEDULE_MANAGE_TAGS,
    SCHEDULE_MANAGE_TAGS_DONE,
    SCHEDULE_PLANNER_FETCH,
    SCHEDULE_PLANNER_FETCH_DONE,
    SCHEDULE_SET_FILTERS,
    SCHEDULE_SET_PAGING,
    SCHEDULE_TAGS_FETCH,
    SCHEDULE_TAGS_FETCH_DONE,
    SCHEDULE_UPDATE,
    SCHEDULE_UPDATE_DONE
} from '../../reducers/planner/ScheduleReducer';
import {handleError}                                   from "../../../components/utils/apiErrorHandler";
import {rest}                                          from '@karpeleslab/klbfw'
import {success}                                       from "../system/ToastAction";
import moment                                          from 'moment';

export const fetchPlanner = (id) => {
    return (dispatch, getState) => {
        dispatch({type: SCHEDULE_PLANNER_FETCH});
        return rest('Planner/' + id)
            .then((d) => {
                dispatch({type: SCHEDULE_PLANNER_FETCH_DONE, planner: d.data});
                return d.data;
            }).catch((err) => {
                dispatch({type: SCHEDULE_PLANNER_FETCH_DONE});
                handleError(getState, dispatch, err)
            });
    }
};

export const setSchedulesPaging = (newPaging) => {
    return (dispatch, getState) => {
        dispatch({type: SCHEDULE_SET_PAGING, paging: {...getState().schedule.schedulesPaging, ...newPaging}});
    }
};

export const setSchedulesFilters = (newFilters) => {
    return (dispatch, getState) => {
        dispatch({type: SCHEDULE_SET_FILTERS, filters: newFilters});
    }
};

export const fetchSchedules = () => {
    return (dispatch, getState) => {
        dispatch({type: SCHEDULE_LIST_FETCH});

        const params = {
            ...getState().schedule.schedulesFilters,
            ...getState().schedule.schedulesPaging,
            Planner_Event__: {'$null': true}
        };

        const plannerId = getState().schedule.planner.Planner__;

        return rest('Planner/' + plannerId + '/Schedule', 'GET', params)
            .then(data => {
                dispatch({type: SCHEDULE_LIST_FETCH_DONE, schedules: data.data, paging: data.paging});
                return data.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const fetchSchedule = (id) => {
    return (dispatch, getState) => {
        dispatch({type: SCHEDULE_FETCH});
        return rest('Planner/Schedule/' + id)
            .then(data => {
                dispatch({type: SCHEDULE_FETCH_DONE, schedule: data.data});
                return data.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const deleteSchedule = (id) => {
    return (dispatch, getState) => {
        dispatch({type: SCHEDULE_DELETE});

        return rest('Planner/Schedule/' + id, 'DELETE')
            .then(d => {
                dispatch({type: SCHEDULE_DELETE_DONE});
                success('schedule_delete_success');
                return d;
            }).catch((err) => {
                dispatch({type: SCHEDULE_DELETE_DONE});
                handleError(getState, dispatch, err)
            });
    }
};

export const createSchedule = (cmsId, title, language, date, shortContents = '', contents = '') => {
    return (dispatch, getState) => {
        dispatch({type: SCHEDULE_CREATE});
        const plannerId = getState().schedule.planner.Planner__;

        return new Promise((resolve, reject) => {
            const entryParams = {
                Language__: language,
                Title: title,
                Short_Contents: shortContents,
                Contents: contents
            };

            const scheduleParams = {
                Date: date,
                Visible: 'N',
	            Published:  '@' + moment().unix(),
                Label: title,
                Description: contents
            };

            rest('Content/Cms/' + cmsId + '/Entry', 'POST', entryParams)
                .then(d => {
                    scheduleParams.Content_Cms_Entry__ = d.data.Content_Cms_Entry__;
                    return rest('Planner/' + plannerId + '/Schedule', 'POST', scheduleParams)
                })
                .then(d => {
                    success('schedule_create_success');
                    dispatch({type: SCHEDULE_CREATE_DONE});
                    resolve(d.data);
                })
                .catch(reject)

        }).catch((err) => {
            dispatch({type: SCHEDULE_CREATE_DONE});
            handleError(getState, dispatch, err)
        });

    }
};

export const updateSchedule = (id, data) => {
    return (dispatch, getState) => {
        dispatch({type: SCHEDULE_UPDATE});
        return rest('Planner/Schedule/' + id, 'PATCH', data)
            .then((d) => {
                success('schedule_update_success');
                dispatch({type: SCHEDULE_UPDATE_DONE, schedule: d.data});
                return d.data;
            }).catch((err) => {
                dispatch({type: SCHEDULE_UPDATE_DONE});
                handleError(getState, dispatch, err)
            });
    }
};

export const duplicateSchedule = (scheduleId, dateUnix, suffix, isEvent = false) => {
    return (dispatch, getState) => {
        dispatch({ type: SCHEDULE_DUPLICATING });
        return rest('Planner/Schedule/' + scheduleId + ':duplicate', 'POST', {date:dateUnix, suffix:suffix})
            .then(d => {
                if(isEvent)
                    success('event_duplicate_success');
                else
                    success('schedule_duplicate_success');
                dispatch({ type: SCHEDULE_DUPLICATING_DONE });
                return d.data;
            }).catch((err) => {
                handleError(getState, dispatch, err);
            });
    };
};

export const editScheduleTags = (scheduleId, toAdd = [], toDel = [], isEvent) => {
    return (dispatch, getState) => {
        dispatch({type: SCHEDULE_MANAGE_TAGS});
        return new Promise((resolve, reject) => {
            let p = [];
            if (toAdd.length > 0)
                p.push(rest('Planner/Schedule/' + scheduleId + ':addTag', 'POST', {tag: toAdd}));
            if (toDel.length > 0)
                p.push(rest('Planner/Schedule/' + scheduleId + ':delTag', 'POST', {tag: toDel}));

            Promise.all(p)
                .then((promisesResults) => {
                    //promisesResults will contain an array of the result of all promise, we will take last one to have the more update entry data
                    resolve(promisesResults[promisesResults.length - 1]);
                })
                .catch(reject)
        }).then((d) => {
            if (isEvent)
                success('event_tags_updated_success');
            else
                success('schedule_tags_updated_success');
            dispatch({type: SCHEDULE_MANAGE_TAGS_DONE, schedule: d.data});
            return d.data;
        })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const fetchTags = (classifyId) => {
    return (dispatch, getState) => {
        dispatch({type: SCHEDULE_TAGS_FETCH});
        return rest('Classify/' + classifyId + ':describeAllTags')
            .then((d) => {
                dispatch({type: SCHEDULE_TAGS_FETCH_DONE, tags: d.data});
                return d.data;
            })
    }
};

