import React, { useEffect, useState } from 'react';
import { connect }                    from 'react-redux';
import { updateDeliverable }          from '../../../../store/actions/store/DeliverableAction';
import Grid                           from '@material-ui/core/Grid';
import { useTranslation }             from 'react-i18next';
import TextField                      from '@material-ui/core/TextField';
import DeliverableStyleSelector       from '../../inputs/DeliverableStyleSelector';
import Button                         from '../../inputs/Button';
import DeleteDialog                   from './DeleteDialog';
import ShippingFeeSelector            from '../../inputs/ShippingFeeSelector';
import FormRequiredField              from '../../feeback/FormRequiredField';

const EditForm = ({ deliverable, updateDeliverable, updating, isAdmin }) => {
	const { t } = useTranslation();
	const [name, setName] = useState('');
	const [style, setStyle] = useState('');
	const [saveEnabled, setSaveEnabled] = useState(false);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [stock, setStock] = useState(0);
	const [fee, setFee] = useState(null);

	useEffect(() => {
		if (!deliverable) return;
		setName(deliverable.Name);
		setStyle(deliverable.Style);
		setStock(deliverable.Stock);
		setFee(deliverable.Catalog_Deliverable_Fee ?? null)
	}, [deliverable, setName, setStyle, setStock, setFee]);

	useEffect(() => {
		if (!deliverable || !name.trim()) {
			setSaveEnabled(false);
			return;
		}
		if((!!fee && !deliverable.Catalog_Deliverable_Fee__) ||
		(!fee && !!deliverable.Catalog_Deliverable_Fee__) ||
			((fee && fee.Catalog_Deliverable_Fee__) !== deliverable.Catalog_Deliverable_Fee__)
		) {
			setSaveEnabled(true);
			return;
		}

		setSaveEnabled(name.trim() !== deliverable.Name || stock !== deliverable.Stock);
	}, [deliverable, name, stock, setSaveEnabled, fee]);

	const handleSave = () => {
		updateDeliverable(deliverable.Catalog_Deliverable__, { Name: name.trim(), Stock: stock, Catalog_Deliverable_Fee__ : fee ? fee.Catalog_Deliverable_Fee__ : null });
	};

	return (
		<Grid container spacing={3}>
			<FormRequiredField/>
			<Grid item xs={6}>
				<TextField label={t('deliverable_name')} fullWidth variant='outlined' value={name}
				           required
				           helperText={t('deliverable_name_helperText')}
				           disabled={updating}
				           onChange={e => setName(e.target.value)}/>
			</Grid>
			<Grid item xs={6}>
				<DeliverableStyleSelector
					value={style}
					disabled={true}
					required
				/>
			</Grid>
			<Grid item xs={6}>
				<ShippingFeeSelector
					value={fee}
					onChange={setFee}
					disabled={updating}
				/>
			</Grid>
			{style === 'shipping' &&
			<Grid item xs={12}>
				<TextField label={t('deliverable_stock')}
				           fullWidth
				           variant='outlined'
				           value={stock}
				           required
				           type='number'
				           inputProps={{ min: 0 }}
				           onChange={e => setStock(e.target.value)}/>
			</Grid>
			}
			<Grid item xs={12}>
				<Grid container justify={isAdmin ? 'space-between' : 'flex-end'}>
					{isAdmin && <Button variant='text' color='secondary' disabled={updating}
					                    onClick={e => setShowDeleteDialog(true)}>
						{t('delete_btn')}
					</Button>}
					<Button variant='contained' color='primary' loading={updating} disabled={updating || !saveEnabled}
					        onClick={handleSave}>
						{t('save_btn')}
					</Button>
				</Grid>
			</Grid>
			{isAdmin && <DeleteDialog open={showDeleteDialog} setOpen={setShowDeleteDialog} deliverable={deliverable}/>}
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		updating: state.deliverable.updating,
		isAdmin: state.access.admin,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateDeliverable: (id, data) => dispatch(updateDeliverable(id, data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditForm);
