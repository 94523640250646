import React, {useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next';
import ShippingStatusSelector from "../../inputs/ShippingStatusSelector";
import {
    fetchShippings,
    setSelectedShippings,
    setShippingStatusBulk
} from "../../../../store/actions/store/ShippingAction";
import Button from "../../inputs/Button";

const ChangeStatusDialog = ({open, setOpen, changing, changeStatus, force, selected, refresh, setShippingStatus, refreshing, resetSelected}) => {
    const {t} = useTranslation();
    const [status, setStatus] = useState(force ?? '');

    const [saveEnabled, setSaveEnabled] = useState(false);

    useEffect(() => {
        setStatus(force)
    }, [force, setStatus]);

    useEffect(() => {
        if (!status.trim()) {
            setSaveEnabled(false);
            return;
        }

        setSaveEnabled(true)
    }, [status, setSaveEnabled]);


    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = (e) => {
        e.stopPropagation();
        e.preventDefault();

        setShippingStatus(Object.keys(selected), status)
            .then(refresh)
            .then(resetSelected)
            .then(handleClose);
    };

    return (
        <Dialog
            open={open}
            aria-labelledby={t('shipping_change_status_title')}
            aria-describedby={t('shipping_change_status_title')}
        >
            <form onSubmit={handleSubmit}>
                <DialogTitle
                    id="change-status-title">{t('shipping_change_status_title', {count: Object.keys(selected).length})}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="change-status-description">
                        {t('shipping_change_status_description')}
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <ShippingStatusSelector value={status} setValue={setStatus}
                                                    disabled={force ? true : (refreshing || changing)}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="default" onClick={handleClose} disabled={refreshing || changing}>
                        {t('close_btn')}
                    </Button>
                    <Button color="primary" variant='contained' loading={refreshing || changing}
                            disabled={refreshing || changing || !saveEnabled} type='submit'>
                        {t('change_btn')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>

    );
};

const mapStateToProps = (state) => {
    return {
        refreshing: state.shipping.shippingsLoading,
        changing: state.shipping.changingStatus,
        selected: state.shipping.selectedShippings
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        refresh: () => dispatch(fetchShippings()),
        setShippingStatus: (ids, status) => dispatch(setShippingStatusBulk(ids, status)),
        resetSelected: () => dispatch(setSelectedShippings({}))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeStatusDialog);