import React, {useEffect, useState} from 'react';
import {Grid}                       from '@material-ui/core';
import Button                       from '../../../inputs/Button';
import {useTranslation}             from 'react-i18next';
import {updateShippingFee}          from '../../../../../store/actions/store/ShippingFeeAction';
import {connect}                    from 'react-redux';
import TextField                    from '@material-ui/core/TextField';
import FormRequiredField            from '../../../feeback/FormRequiredField';

const EditForm = ({fee, updating, update}) => {
	const {t} = useTranslation();
	const [saveEnabled, setSaveEnabled] = useState(false);

	const [name, setName] = useState(fee.Name ?? '');
	const [type, setType] = useState(fee.Type);

	useEffect(() => {
		setName(fee.Name ?? '');
		setType(fee.Type);
	}, [fee, setName, setType]);

	useEffect(() => {
		if (!name.trim()) {
			setSaveEnabled(false);
			return;
		}

		setSaveEnabled(
			name.trim() !== fee.Name ||
			type !== fee.Type
		);
	}, [name, type, setSaveEnabled, fee]);

	const handleSubmit = e => {
		e.preventDefault();
		e.stopPropagation();

		update(fee.Catalog_Deliverable_Fee__, {Name: name.trim(), Type: type});
	};

	return (
		<form onSubmit={handleSubmit}>
			<Grid container spacing={3}>
				<FormRequiredField/>
				<Grid item xs={6}>
					<TextField
						label={t('shipping_fee_name')}
						fullWidth variant="outlined"
						value={name}
						required
						disabled={updating}
						onChange={e => setName(e.target.value)}
					/>
				</Grid>
				{/*<Grid item xs={6}>
					<ShippingFeeTypeSelector
						setValue={setType}
						value={type}
						required
						disabled={creating}
					/>
			</Grid>}*/}

				<Grid item xs={12}>
					<Grid container justify="flex-end">
						<Button
							color="primary"
							variant="contained"
							type="submit"
							loading={updating}
							disabled={!saveEnabled || updating}
						>
							{t('save_btn')}
						</Button>
					</Grid>
				</Grid>

			</Grid>
		</form>
	);
};

const mapStateToProps = (state) => {
	return {
		updating: state.shippingFee.updating,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		update: (feeId, params) => dispatch(updateShippingFee(feeId, params))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditForm);