import React                        from 'react';
import TableRow                     from '@material-ui/core/TableRow';
import Skeleton                     from '@material-ui/lab/Skeleton';
import TableCell                    from '@material-ui/core/TableCell';
import Flag                         from '../../data/flag/Flag';
import Grid                         from '@material-ui/core/Grid';
import { Chip, Tooltip }            from '@material-ui/core';
import { getSiteContentEditRoute }  from '../../../../router/routes/site/factory/content';
import Button                       from '../../inputs/Button';
import Link                         from '../../typography/Link';

const Row = ({entry}) => {
    const display = entry !== null;

    return (
        <TableRow>
            <TableCell>
                {!display && <Skeleton/>}
	            {display && <span>
		            <Link to={getSiteContentEditRoute(entry.Data[entry.Language__].Content_Cms_Entry_Data__)}>
			            {entry.Title}
					</Link>
				</span>}
            </TableCell>
	        <TableCell>
		        {!display && <Skeleton/>}
		        {display && <span>{(entry.Tags ?? []).map((tag) =>
			        <Chip
				        label={tag.Name}
				        variant='outlined'
				        style={{marginLeft:'5px'}}/>)}
		        </span>}
	        </TableCell>
            <TableCell>
	            <Grid
				  container
				  direction='row'
				  justify='flex-end'
				  alignItems='center'
				>

                    {!display && <Skeleton/>}

                    {
                        display && Object.keys(entry.Data).map((key, index) => {
                                const lang = key.split('-')[1].toLowerCase();
                                return <Grid item key={entry.Data[key].Content_Cms_Entry_Data__}>
                                    <Tooltip
                                        title={lang + ' ' + entry.Data[key].Status}
                                        placement='bottom'
                                        arrow
                                    >
                                        <Button component={Link}
                                                to={getSiteContentEditRoute(entry.Data[key].Content_Cms_Entry_Data__)}>
                                            <Flag
                                                ratio='4x3'
                                                size='medium'
                                                code={lang}
                                                disabled={entry.Data[key].Status !== 'published'
                                                }/>
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            }
                        )

                    }
                </Grid>
            </TableCell>
        </TableRow>
    );
};


export default Row;
