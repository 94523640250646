import React                                      from 'react';
import {connect}                                  from 'react-redux';
import SelectRealm                                from '../../SelectRealm';
import {Grid}                                     from '@material-ui/core';
import Redirect                                   from '../../../components/Route/Redirect';
import {getPXLContactRoute, getPXLDashboardRoute} from '../../../router/routes/pxl/factory/general';
import HasRight                                   from '../../../components/Route/HasRight';
import AppBarTitle                                from '../../../components/common/typography/AppBarTitle';
import {Trans, useTranslation}                    from 'react-i18next';
import RichAlert                                  from '../../../components/common/feeback/RichAlert';
import Toolbar                                    from '../../../components/common/typography/Toolbar';
import TypoLink                                   from '../../../components/common/typography/Link';
import CreateForm                                 from '../../../components/common/massMail/segment/CreateForm';


const NotifyCreateList = ({selectedRealm}) => {
	const {t} = useTranslation();

	if (!selectedRealm) return <SelectRealm/>;

	return (
		<Grid container spacing={3}>
			<HasRight manager deniedComponent={<Redirect target={getPXLDashboardRoute()}/>}>
				<AppBarTitle title={t('notify_create_list_title')}/>
				<Grid item xs={12}>
					<Toolbar title={t('notify_create_list_title')}/>
				</Grid>

				<Grid item xs={12}>
					<RichAlert severity="info">
						<Trans i18nKey="notify_create_list_info">
							sample<br/>
							<strong>sample</strong>
							<TypoLink to={getPXLContactRoute()}>sample</TypoLink>
						</Trans>
					</RichAlert>
				</Grid>

				<Grid item xs={12}>
					<CreateForm realmId={selectedRealm.Realm__}/>
				</Grid>

			</HasRight>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
	};
};

export default connect(mapStateToProps, null)(NotifyCreateList);
