import withStyles          from '@material-ui/core/styles/withStyles';
import MuiAccordion        from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

export const Accordion = withStyles(theme => {
	return {
		root: {},
	};
})(MuiAccordion);


export const AccordionSummary = withStyles(theme => {
	return {
		root: {
			padding: '0px'
		},
	};
})(MuiAccordionSummary);

export const AccordionDetails = withStyles(theme => {
	return {
		root: {
			padding: '0px'
		},
	};
})(MuiAccordionDetails);
