import React, { useEffect, useState } from 'react';
import { useTranslation }             from 'react-i18next';
import TableContainer                 from '@material-ui/core/TableContainer';
import Table                          from '@material-ui/core/Table';
import TableHead                      from '@material-ui/core/TableHead';
import TableCell                      from '@material-ui/core/TableCell';
import TableRow                       from '@material-ui/core/TableRow';
import TableBody                      from '@material-ui/core/TableBody';
import Skeleton                       from '@material-ui/lab/Skeleton';
import Paper                          from '@material-ui/core/Paper';

const OrderTable = ({ order, elevation = 0 }) => {
	const [rowSpan, setRowSpan] = useState(3);
	const { t } = useTranslation();
	useEffect(() => {
		if (!order) return;
		let c = 3;
		if (parseInt(order.Subtotals.shipping.value_int)) c += 1;
		if (parseInt(order.Subtotals.discount.value_int)) c += 1;

		setRowSpan(c);

	}, [order]);

	return (
		<Paper elevation={elevation}>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell align="center" colSpan={3}>
								{t('details')}
							</TableCell>
							<TableCell align="right">{t('order_detail_price')}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{t('order_detail_description')}</TableCell>
							<TableCell align="right">{t('order_detail_quantity')}</TableCell>
							<TableCell align="right">{t('order_detail_unit')}</TableCell>
							<TableCell align="right">{t('order_detail_sum')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{order && order.Items.map(row => (
							<TableRow key={row.Order_Item__}>
								<TableCell>{row.Catalog_Product['Basic.Name']}</TableCell>
								<TableCell align="right">{row.Quantity}</TableCell>
								<TableCell align="right">{row.Price.display}</TableCell>
								<TableCell align="right">{row.Full_Price.display}</TableCell>
							</TableRow>
						))}

						{
							!order &&
							<TableRow>
								<TableCell><Skeleton/></TableCell>
								<TableCell align="right"><Skeleton/></TableCell>
								<TableCell align="right"><Skeleton/></TableCell>
								<TableCell align="right"><Skeleton/></TableCell>
							</TableRow>
						}

						<TableRow>
							<TableCell rowSpan={rowSpan}/>
							<TableCell colSpan={2}>{t('order_detail_subtotal')}</TableCell>
							<TableCell align="right">
								{order && order.Total.display}
								{!order && <Skeleton/>}
							</TableCell>
						</TableRow>

						{
							(order && parseInt(order.Subtotals.shipping.value_int) !== 0) &&

							<TableRow>
								<TableCell colSpan={2}>{t('order_detail_shipping')}</TableCell>
								<TableCell align="right">{order.Subtotals.shipping.display}</TableCell>
							</TableRow>
						}

						<TableRow>
							<TableCell>{t('order_detail_tax')}</TableCell>
							<TableCell align="right">
								{order && order.Vat_Rate + '%'}
								{!order && <Skeleton/>}
							</TableCell>
							<TableCell align="right">
								{order && order.Vat_Amount.display}
								{!order && <Skeleton/>}
							</TableCell>
						</TableRow>

						{
							(order && parseInt(order.Subtotals.discount.value_int) !== 0) &&

							<TableRow>
								<TableCell colSpan={2}>{t('order_detail_discount')}</TableCell>
								<TableCell align="right">{order.Subtotals.discount.display}</TableCell>
							</TableRow>
						}

						<TableRow>
							<TableCell colSpan={2}>{t('order_detail_total')}</TableCell>
							<TableCell align="right">
								{order && order.Total_Vat.display}
								{!order && <Skeleton/>}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	);
};

export default OrderTable;
