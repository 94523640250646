import React from 'react';
import Select from "./Select";
import {useTranslation} from "react-i18next";

const DeliverableStyleSelector = ({value, setValue, disabled, required}) => {
    const {t} = useTranslation();

    return <Select
        label={t('deliverable_style')}
        helperText={t('deliverable_style_helperText')}
        value={value}
        onChange={e => setValue(e.target.value)}
        optionKey='Key'
        optionValue='Value'
        options={[
            {Key: 'ondemand', Value: t('deliverable_style_ondemand')},
            {Key: 'files', Value: t('deliverable_style_files')},
            {Key: 'shipping', Value: t('deliverable_style_shipping')},

        ]}
        inputProps={{
            disabled: disabled,
            variant: 'outlined',
            fullWidth: true,
            required: required
        }}
    />;
};

export default DeliverableStyleSelector;
