import React from 'react';
import Grid from "@material-ui/core/Grid";
import {CircularProgress} from "@material-ui/core";

const Loading = (props) => {
    return (
        <Grid
            container
            direction={props.direction ?? "row"}
            justify={props.justify ?? "center"}
            alignItems={props.alignItems ?? "center"}
            {...props.containerProps ?? {}}
        >
            <Grid item>
                <CircularProgress {...props}/>
            </Grid>
            {props.children}
        </Grid>
    );
};

export default Loading;
