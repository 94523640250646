const initState = () => {
    return {
        user: null,
        passwordReseting: false,
        passwordResetDone: false,
        passwordResetError: null,
        forgetPasswordSent: false,
        forgetPasswordEmail: null,
        // Password Management
        changingPassword: false,
        changingPasswordError: null,
        changingInfo: false,
        changingInfoError: null,
        changingAvatar: false,
        changingAvatarSuccess: false,

        //Profile
        updatingProfile: false,
        updateProfileError: null,
        updatingProfileSuccess: false,
    };
};

export const USER_CHANGE = 'USER_CHANGE';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_FORGET_PWD = 'USER_FORGET_PWD';
export const USER_FORGET_PWD_DONE = 'USER_FORGET_PWD_DONE';
export const USER_UPDATE = 'USER_UPDATE';

export const USER_PWD_CHANGING = 'USER_PWD_CHANGING';
export const USER_PWD_CHANGED = 'USER_PWD_CHANGED';
export const USER_PWD_CHANGE_ERROR = 'USER_PWD_CHANGE_ERROR';

export const USER_INFO_CHANGING = 'USER_INFO_CHANGING';
export const USER_INFO_CHANGED = 'USER_INFO_CHANGED';
export const USER_INFO_SET_NAME = 'USER_INFO_SET_NAME';
export const USER_INFO_SET_EMAIL = 'USER_INFO_SET_EMAIL';
export const USER_INFO_CHANGE_ERROR = 'USER_INFO_CHANGE_ERROR';

export const USER_AVATAR_CHANGING = 'USER_AVATAR_CHANGING';
export const USER_AVATAR_CHANGED = 'USER_AVATAR_CHANGED';
export const USER_AVATAR_RESET = 'USER_AVATAR_RESET';


export const USER_PASSWORD_RESETING = 'USER_PASSWORD_RESETING';
export const USER_PASSWORD_RESET_DONE = 'USER_PASSWORD_RESET_DONE';
export const USER_PASSWORD_RESET_ERROR = 'USER_PASSWORD_RESET_ERROR';

export const USER_PROFILE_UPDATING = 'USER_PROFILE_UPDATING';
export const USER_PROFILE_UPDATE_DONE = 'USER_PROFILE_UPDATE_DONE';
export const USER_PROFILE_UPDATE_ERROR = 'USER_PROFILE_UPDATE_ERROR';

const userReducer = (state = initState(), action) => {
    switch (action.type) {
        case USER_UPDATE:
            return {...state, user: action.data};
        case USER_LOGOUT:
            return {...state, user: null};
        case USER_CHANGE:
            return {...state, user: action.user};
        case USER_FORGET_PWD:
            return {...state, forgetPasswordSent: true};
        case USER_FORGET_PWD_DONE:
            return {...state, forgetPasswordSent: false};
        case USER_PWD_CHANGING:
            return {...state, changingPasswordError: null, changingPassword: true};
        case USER_PWD_CHANGED:
            return {
                ...state,
                changingPasswordError: null,
                changingPassword: false,
                user: action.user
            };
        case USER_PWD_CHANGE_ERROR:
            return {
                ...state,
                changingPasswordError: action.error,
                changingPassword: false
            };
        case USER_INFO_CHANGING:
            return {...state, changingInfo: true, changingInfoError: null};
        case USER_INFO_CHANGED:
            return {...state, changingInfo: false, changingInfoError: null, user: action.user ?? state.user};
        case USER_INFO_CHANGE_ERROR:
            return {...state, changingInfoError: action.error, changingInfo: false};
        case USER_AVATAR_CHANGING:
            return {...state, changingAvatar: true, changingAvatarSuccess: false};
        case USER_AVATAR_CHANGED:
            return {...state, changingAvatar: false, changingAvatarSuccess: true};
        case USER_AVATAR_RESET:
            return {...state, changingAvatar: false, changingAvatarSuccess: false};
        case USER_PASSWORD_RESETING:
            return {...state, passwordReseting: true, passwordResetDone: false, passwordResetError: null};
        case USER_PASSWORD_RESET_DONE:
            return {...state, passwordReseting: false, passwordResetDone: true, passwordResetError: null};
        case USER_PASSWORD_RESET_ERROR:
            return {...state, passwordReseting: false, passwordResetDone: false, passwordResetError: action.error};
        case  USER_PROFILE_UPDATING:
            return {...state, updatingProfile: true, updateProfileError: null};
        case  USER_PROFILE_UPDATE_ERROR:
            return {...state, updatingProfile: false, updateProfileError: action.error};
        case  USER_PROFILE_UPDATE_DONE:
            let user = {...state.user};
            user.Profile = action.profile;
            return {...state, updatingProfile: false, user: user};
        default:
            return state
    }
};


export default userReducer