import React, {useEffect, useState} from 'react';
import {connect}                    from 'react-redux';
import {useTranslation}             from 'react-i18next';
import Grid                         from '@material-ui/core/Grid';
import Toolbar                      from '../typography/Toolbar';
import moment                       from 'moment';
import Year                         from '../inputs/Year';
import Month                        from '../inputs/Month';
import {storeStats}                 from '../../../store/actions/store/StoreAction';
import ProductReport                from '../store/stats/ProductReport';
import Sales                        from '../store/stats/Sales';
//import { Title }                      from '../typography/Title';
//import Table                          from '../store/stats/table/Table';
import Currencies                   from '../inputs/Currencies';

const SalesReport = ({selectedRealm, fetch, catalog, loading, productReport, itemReport, salesReport}) => {
	const {t} = useTranslation();
	const [year, setYear] = useState(moment().format('YYYY'));
	const [month, setMonth] = useState(moment().format('MM'));
	const [currency, setCurrency] = useState('all');

	useEffect(() => {
		if (!selectedRealm) return;
		fetch(year + '-' + month, currency, selectedRealm.Realm__);
	}, [selectedRealm, fetch, year, month, currency]);

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Toolbar title={t('store_report_title')}>
					<Grid container justify="flex-end" spacing={3}>
						<Grid item xs={12} md={5}>
							<Currencies onChange={(e) => setCurrency(e.target.value)} value={currency}
							            allowEmpty
							            inputProps={{
								            fullWidth: true,
								            variant: 'outlined',
								            disabled: loading
							            }}/>
						</Grid>
						<Grid item xs={6} md={4}>
							<Year onChange={(e) => setYear(e.target.value)} value={year} inputProps={{
								fullWidth: true,
								variant: 'outlined',
								disabled: loading
							}}/>
						</Grid>

						<Grid item xs={6} md={3}>
							<Month onChange={(e) => setMonth(e.target.value)} value={month} inputProps={{
								fullWidth: true,
								variant: 'outlined',
								disabled: loading
							}}/>
						</Grid>

					</Grid>
				</Toolbar>
			</Grid>

			<Grid item xs={12}>
				<ProductReport report={productReport} loading={loading} currency={currency}/>
			</Grid>
			<Grid item xs={12}>
				<Sales currency={currency} stats={salesReport} month={month} year={year} loading={loading}/>
			</Grid>
			{/*<Grid item xs={12}>
				<Title>
					{t('store_report_product_history')}
				</Title>
			</Grid>
			<Grid item xs={12}>
				<Table
					currency={currency}
					realmId={selectedRealm.Realm__}
					loading={loading}
					products={itemReport}
					period={year + '-' + month}
				/>
			</Grid>*/}
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		catalog: state.store.catalog,
		loading: state.store.reportLoading,
		productReport: state.store.productReport,
		itemReport: state.store.itemReport,
		salesReport: state.store.salesReport,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: (period, currency, realmId) => dispatch(storeStats(period, currency, realmId, 'completed', true))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesReport);
