import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import {connect} from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import {useTranslation} from 'react-i18next';
import {deleteCoupon, fetchCoupons} from "../../../../store/actions/store/CouponAction";
import Button from "../../inputs/Button";

const DeleteDialog = ({open, setOpen, deleting, coupon, deleteCoupon, refreshCoupons}) => {
    const {t} = useTranslation();

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        deleteCoupon(coupon.Catalog_Coupon__)
            .then(() => refreshCoupons())
            .then(handleClose);
    };

    return (
        <Dialog
            open={open}
            aria-labelledby={t('confirm_delete_coupon')}
            aria-describedby={t('confirm_delete_coupon')}
            fullWidth
        >
            <DialogTitle id='gallery-delete-title'>{t('confirm_delete_coupon')}</DialogTitle>
            <DialogContent>
                <DialogContentText id='coupon-delete-description'>
                    {t('confirm_delete_coupon_desc')}
                    <br/>
                    <Typography variant='body1' color='textPrimary' component='span'>{t('are_you_sure')}</Typography>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button variant='contained' color='primary' autoFocus onClick={handleClose} disabled={deleting}>
                    {t('back_btn')}
                </Button>
                <Button color='secondary' loading={deleting} disabled={deleting} endIcon={<DeleteIcon/>} onClick={handleDelete}>
                    {t('delete_btn')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {
        deleting: state.coupon.deleting
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        refreshCoupons: () => dispatch(fetchCoupons()),
        deleteCoupon: (id) => dispatch(deleteCoupon(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDialog);
