import React                        from 'react';
import {Grid}                       from '@material-ui/core';
import {
	CalendarToday,
	Dashboard,
	HeadsetMic,
	LibraryMusic,
	People,
	Storefront
}                                   from '@material-ui/icons';
import {getPXLDashboardRoute}       from '../../../../router/routes/pxl/factory/general';
import WebIcon                      from '@material-ui/icons/Web';
import {
	MENU_ARTIST,
	MENU_FANCLUB,
	MENU_MUSIC,
	MENU_PLANNER,
	MENU_STORE,
	MENU_SUPPORT,
	MENU_WEBSITE
}                                   from '../../../../store/reducers/CommonReducer';
import {
	getSiteAddressRoute,
	getSiteFinancialReportRoute,
	getSiteSettingsRoute
}                                   from '../../../../router/routes/site/factory/setting';
import {getSiteUsersRoute}          from '../../../../router/routes/site/factory/user';
import {
	getSiteCommentsRoute,
	getSiteNewsRoute,
	getSiteNotificationListRoute,
	getSiteNotificationRoute
}                                   from '../../../../router/routes/site/factory/content';
import {getSiteOrdersRoute}         from '../../../../router/routes/site/factory/order';
import RecentActorsIcon             from '@material-ui/icons/RecentActors';
import {getArtistRoute}             from '../../../../router/routes/artist/factory/artist';
import {getMembersRoute}            from '../../../../router/routes/artist/factory/members';
import {getArtistWizardRoute}       from '../../../../router/routes/artist/factory/wizard';
import {getMusicDiscographyRoute}   from '../../../../router/routes/music/factory/discography';
import {getMusicAlbumsRoute}        from '../../../../router/routes/music/factory/album';
import {getMusicRecordsRoute}       from '../../../../router/routes/music/factory/records';
import {
	getSiteEventsRoute,
	getSiteSchedulesRoute
}                                   from '../../../../router/routes/site/factory/planner';
import HasCapability                from '../../../Route/HasCapability';
import {getFanclubArticlesRoute}    from '../../../../router/routes/fanclub/factory/content';
import {getFanclubWizardRoute}      from '../../../../router/routes/fanclub/factory/wizard';
import {getFanclubPlansRoute}       from '../../../../router/routes/fanclub/factory/plan';
import {getFanclubSubscribersRoute} from '../../../../router/routes/fanclub/factory/subscriber';
import {
	getStoreCouponsRoute,
	getStoreShipmentsRoute,
	getStoreShippingFeesRoute,
	getStoreShopRoute,
	getStoreWarehouseRoute,
	getStoreWizardRoute
}                                   from '../../../../router/routes/store/factory/store';
import {
	getSupportClosedRoute,
	getSupportInboxRoute,
	getSupportNewTicketRoute,
	getSupportOutboxRoute
}                                   from '../../../../router/routes/support/factory/boxes';
import {getSupportCannedsRoute}     from '../../../../router/routes/support/factory/canned';
import {useTranslation}             from 'react-i18next';
import MobileMenuItem               from './MobileMenuItem';

const RootMenu = () => {
	const {t} = useTranslation();

	return (
		<Grid container spacing={2}>
			<Grid item xs={4}>
				<MobileMenuItem
					Icon={Dashboard}
					text={t('menu_dashboard')}
					to={getPXLDashboardRoute()}
					selectedRoutes={[
						{path: getPXLDashboardRoute(), exact: true},
					]}
				/>
			</Grid>
			<Grid item xs={4}>
				<MobileMenuItem
					Icon={WebIcon}
					text={t('menu_website')}
					menu={MENU_WEBSITE}
					selectedRoutes={[
						{path: getSiteSettingsRoute(), exact: true},
						{path: getSiteUsersRoute(), exact: false},
						{path: getSiteNewsRoute(), exact: true},
						{path: getSiteOrdersRoute(), exact: true},
						{path: getSiteCommentsRoute(), exact: false},
						{path: getSiteNotificationRoute(), exact: false},
						{path: getSiteNotificationListRoute(), exact: false},
						{path: getSiteFinancialReportRoute(), exact: false},
						{path: getSiteAddressRoute(), exact: false},
					]}
				/>
			</Grid>
			<Grid item xs={4}>
				<MobileMenuItem
					Icon={RecentActorsIcon}
					text={t('menu_artist')}
					menu={MENU_ARTIST}
					selectedRoutes={[
						{path: getArtistRoute(), exact: false},
						{path: getMembersRoute(), exact: false},
						{path: getArtistWizardRoute(), exact: false},
					]}
				/>
			</Grid>
			<Grid item xs={4}>
				<MobileMenuItem
					Icon={LibraryMusic}
					text={t('menu_music')}
					menu={MENU_MUSIC}
					selectedRoutes={[
						{path: getMusicDiscographyRoute(), exact: false},
						{path: getMusicAlbumsRoute(), exact: false},
						{path: getMusicRecordsRoute(), exact: false},
					]}
				/>
			</Grid>
			<Grid item xs={4}>
				<MobileMenuItem
					Icon={CalendarToday}
					text={t('menu_planner')}
					menu={MENU_PLANNER}
					selectedRoutes={[
						{path: getSiteSchedulesRoute(), exact: false},
						{path: getSiteEventsRoute(), exact: false},
					]}
				/>
			</Grid>
			<HasCapability fanclub hideLoading>
				<Grid item xs={4}>
					<MobileMenuItem
						Icon={People}
						text={t('menu_fanclub')}
						menu={MENU_FANCLUB}
						selectedRoutes={[
							{path: getFanclubArticlesRoute(), exact: false},
							{path: getFanclubWizardRoute(), exact: false},
							{path: getFanclubPlansRoute(), exact: false},
							{path: getFanclubSubscribersRoute(), exact: false},
						]}
					/>
				</Grid>
			</HasCapability>

			<HasCapability store hideLoading>
				<Grid item xs={4}>
					<MobileMenuItem
						Icon={Storefront}
						text={t('menu_store')}
						menu={MENU_STORE}
						selectedRoutes={[
							{path: getStoreWizardRoute(), exact: false},
							{path: getStoreShopRoute(), exact: false},
							{path: getStoreCouponsRoute(), exact: false},
							{path: getStoreWarehouseRoute(), exact: false},
							{path: getStoreShippingFeesRoute(), exact: false},
							{path: getStoreShipmentsRoute(), exact: false},
						]}
					/>
				</Grid>
			</HasCapability>
			<Grid item xs={4}>
				<MobileMenuItem
					Icon={HeadsetMic}
					text={t('menu_support')}
					menu={MENU_SUPPORT}
					selectedRoutes={[
						{path: getSupportInboxRoute(), exact: false},
						{path: getSupportOutboxRoute(), exact: false},
						{path: getSupportClosedRoute(), exact: false},
						{path: getSupportCannedsRoute(), exact: false},
						{path: getSupportNewTicketRoute(), exact: false},

					]}
				/>
			</Grid>
		</Grid>
	);
};

export default RootMenu;