import React from 'react';
import {useTranslation} from "react-i18next";
import Graphic from "../../../../resources/images/deliverableProducts.svg";
import EmptyState from "../../feeback/State/Empty";

const EmptyGoods = () => {
    const {t} = useTranslation();

    return (
        <EmptyState
            title={t('store_product_good_empty_title')}
            textI18Key={'store_product_good_empty_text'}
            graphic={Graphic}
        />
    );

};


export default EmptyGoods;