import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Grid, Typography} from "@material-ui/core";
import {Title} from "../../typography/Title";
import FormControl from "@material-ui/core/FormControl";
import InputLabel        from "@material-ui/core/InputLabel";
import Select            from "@material-ui/core/Select";
import FormHelperText    from "@material-ui/core/FormHelperText";
import Button            from "../../inputs/Button";
import {updateSchedule}  from "../../../../store/actions/planner/ScheduleAction";
import {connect}         from 'react-redux'
import moment            from "moment";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DeleteDialog      from "./DeleteDialog";
import DateTime          from "../../inputs/DateTime";
import ScheduleTags      from './ScheduleTags';
import FormRequiredField from '../../feeback/FormRequiredField';

const Publication = ({schedule, updating, deleting, updateSchedule, isManager, isAdmin}) => {
    const visibleLabel = React.useRef(null);
    const [visibleLabelWidth, setVisibleLabelWidth] = useState(0);
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const [visible, setVisible] = useState();
    const [published, setPublished] = useState(null);
    const [date, setDate] = useState(null);
    const {t} = useTranslation();

    useEffect(() => {
        setVisibleLabelWidth(visibleLabel.current.offsetWidth);
    }, []);

    useEffect(() => {
        if (!schedule) return;
        setVisible(schedule.Visible);
        setPublished(moment(parseInt(schedule.Published.unixms)));
        setDate(moment(parseInt(schedule.Date.unixms)));

    }, [schedule, setVisible, setDate, setPublished]);

    useEffect(() => {
        if (visible !== schedule.Visible ||
	        date.unix() !== schedule.Date.unix ||
	        published.unix() !== schedule.Published.unix
        ) {
            setSaveEnabled(true);
            return;
        }

        setSaveEnabled(false);

    }, [schedule, visible, date, setSaveEnabled, published]);

    const handleSave = () => {
        const data = {
	        Published: '@' + published.unix(),
            Date: '@' + date.unix(),
            Visible: visible
        };

        updateSchedule(schedule.Planner_Schedule__, data)
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Title>{t('publication_title')}</Title>
            </Grid>

            <FormRequiredField/>

            <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary" component="p">
                    {t('publication_desc')}
                </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
                <FormControl variant='outlined' fullWidth disabled={updating || !isManager}>
                    <InputLabel ref={visibleLabel} htmlFor='visible-select'>{t('schedule_visible')}</InputLabel>
                    <Select
                        native
                        value={visible}
                        onChange={(e) => setVisible(e.target.value)}
                        labelWidth={visibleLabelWidth}
                        inputProps={{
                            id: 'visible-select',
                        }}
                    >
                        <option value='Y'>{t('visible_y')}</option>
                        <option value='N'>{t('visible_n')}</option>
                    </Select>
                    <FormHelperText>{t('schedule_visible_helperText')}</FormHelperText>
                </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
                <Grid item xs={12}>
                    <DateTime
                        label={t('schedule_date_lbl')}
                        inputVariant="outlined"
                        value={date}
                        disabled={updating || !isManager}
                        onChange={setDate}
                        helperText={t('schedule_date_helperText')}
                        fullWidth
                    />
                </Grid>
            </Grid>

	        <Grid item xs={12} md={6}>
		        <DateTime
			        label={t('schedule_published_lbl')}
			        inputVariant="outlined"
			        value={published}
			        disabled={updating || !isManager}
			        onChange={setPublished}
			        required
			        helperText={t('schedule_published_helperText')}
			        fullWidth
		        />
	        </Grid>

            {isManager &&
            <Grid item xs={12}>
                <Grid container justify='flex-end'>

                    <Button color='primary' variant='contained' loading={updating} disabled={!saveEnabled || updating}
                            onClick={handleSave}>
                        {t('save_btn')}
                    </Button>

                </Grid>
            </Grid>
            }

            <Grid item xs={12}>
                <ScheduleTags schedule={schedule}/>
            </Grid>

            <Grid item xs={12}>
                <Grid container justify='flex-start'>
                    {isAdmin &&
                    <Grid item>
                        <Button size='small' color='secondary'
                                startIcon={<DeleteForeverIcon/>}
                                disabled={deleting}
                                loading={deleting}
                                onClick={e => setShowDeleteDialog(true)}
                        >
                            {t('delete_btn')}
                        </Button>
                    </Grid>
                    }
                </Grid>
            </Grid>
            <DeleteDialog schedule={schedule} open={showDeleteDialog} setOpen={setShowDeleteDialog}/>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        schedule: state.schedule.schedule,
        updating: state.schedule.updating,
        deleting: state.schedule.deleting,
        isManager: state.access.manager,
        isAdmin: state.access.admin,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateSchedule: (id, data) => dispatch(updateSchedule(id, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Publication);
