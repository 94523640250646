import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux'
import {createTicket} from "../../../store/actions/SupportAction";
import {Grid} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "../inputs/Button";
import {useTranslation} from "react-i18next";

const NewTicket = ({support, user, creating, createTicket, onSent}) => {
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [sendEnabled, setSendEnabled] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        setSendEnabled(!!support && !!user && !!subject.trim() && !!message.trim())
    }, [setSendEnabled, support, user, subject, message])

    const handleSend = () => {
        createTicket(
            support.Support__,
            typeof user === 'string' ? user : user.User__,
            subject,
            message
        ).then(ticket => {
            setSubject('')
            setMessage('')
            if (onSent) onSent(ticket)
        })
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TextField
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    variant="outlined"
                    fullWidth
                    label={t('ticket_subject_lbl')}
                    required
                    disabled={creating}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    variant='outlined'
                    multiline rows={5}
                    rowsMax={Infinity}
                    required
                    fullWidth
                    disabled={creating}
                    label={t('contact_content_lbl')}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container justify='flex-end'>
                    <Button loading={creating} color='primary' variant='contained' onClick={handleSend}
                            disabled={!sendEnabled || creating}>
                        {t('send_btn')}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};


const mapStateToProps = (state) => {
    return {
        creating: state.support.creating,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createTicket: (supportId, user, subject, message) => dispatch(createTicket(supportId, user, subject, message))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewTicket);