import React                            from 'react';
import Dialog                           from '@material-ui/core/Dialog';
import DialogTitle                      from '@material-ui/core/DialogTitle';
import DialogContent                    from '@material-ui/core/DialogContent';
import DialogContentText                from '@material-ui/core/DialogContentText';
import DialogActions                    from '@material-ui/core/DialogActions';
import Grid                             from '@material-ui/core/Grid';
import makeStyles                       from '@material-ui/core/styles/makeStyles';
import { connect }                      from 'react-redux';
import DeleteIcon                       from '@material-ui/icons/Delete';
import Typography                       from '@material-ui/core/Typography';
import { useTranslation }               from 'react-i18next';
import { deleteGalleryImage }           from '../../../store/actions/ArtistAction';
import { ARTIST_GALLERY_IMG_VARIATION } from '../../../store/reducers/ArtistReducer';
import Button from "../inputs/Button";

const useStyles = makeStyles(theme => ({
	imgToInsert: {
		textAlign: 'center'
	},
}));

const DeleteDialog = ({ img, open, setOpen, deleting, artistId, deleteGalleryImage }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const handleClose = () => {
		setOpen(false);
	};

	const handleDelete = () => {
		deleteGalleryImage(artistId, img.Drive_Item__)
			.then(handleClose);
	};

	return (
		<Dialog
			open={open}
			aria-labelledby='Delete gallery picture'
			aria-describedby='Delete gallery picture'
			fullWidth
		>
			<DialogTitle id='gallery-delete-title'>{t('confirm_delete_picture_title')}</DialogTitle>
			<DialogContent>
				<DialogContentText id='gallery-delete-description'>
					{t('confirm_delete_picture_desc')}
					<br/>
					<Typography variant='body1' color='textPrimary' component='span'>{t('are_you_sure')}</Typography>
				</DialogContentText>
				<Grid container justify='center' alignItems='center' spacing={2}>
					<Grid item xs={12} className={classes.imgToInsert}>
						<img src={img.Media_Image[ARTIST_GALLERY_IMG_VARIATION]} alt={img.Filename}/>
					</Grid>
					<Grid item xs={12}>

					</Grid>

				</Grid>
			</DialogContent>

			<DialogActions>
				<Button variant='contained' color='primary' autoFocus onClick={handleClose} disabled={deleting}>
					{t('back_btn')}
				</Button>
				<Button color='secondary' loading={deleting} disabled={deleting} endIcon={<DeleteIcon/>} onClick={handleDelete}>
					{t('delete_btn')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const mapStateToProps = (state) => {
	return {
		deleting: state.artist.deletingImage
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		deleteGalleryImage: (artistId, imgId) => dispatch(deleteGalleryImage(artistId, imgId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDialog);
