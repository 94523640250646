import React, {Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import {Title} from "../../common/typography/Title";
import Typography from "@material-ui/core/Typography";
import Member from "./Member";
import Artist from "./Artist";
import Empty from './EmptyMember'
import Button from "../../common/inputs/Button";

const StepValidate = ({handleBack, handleNext, members, artist}) => {
    const {t} = useTranslation();


    return (
        <Fragment>
            <Grid container spacing={3} justify='center'>
                <Grid item>
                    <Title>{t('artist_wizard_step_5_title')}</Title>
                </Grid>
            </Grid>


            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="body1" color="textSecondary" component="p">
                        {t('artist_wizard_step_5_desc')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5" color="textPrimary" component="h5">
                        {t('artist_wizard_step_5_artist_title')}
                    </Typography>
                </Grid>


                <Grid item xs={12}>
                    <Artist artist={artist}/>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5" color="textPrimary" component="h5">
                        {t('artist_wizard_step_3_title_members')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" color="textSecondary" component="p">
                        {t('artist_wizard_step_3_desc_members')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={3} alignItems='stretch'>

                        {members.length < 1 &&
                        <Grid item xs={12}>
                            <Empty showCreate={false}/>
                        </Grid>}

                        {members.map((m, i) => {
                                return (
                                    <Grid item xs={6} md={4} key={'list-member-' + i}>
                                        <Member member={m} viewOnly={true}/>
                                    </Grid>)
                            }
                        )}

                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container justify='center' spacing={3}>

                        <Grid item>
                            <Button size='large' onClick={handleNext} variant='contained' color="primary">
                                {t('artist_wizard_confirm_btn')}
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>


                <Grid item xs={12}>
                    <Grid container justify='space-between' spacing={3}>

                        <Grid item xs={1}>
                            <Button onClick={handleBack} variant={'outlined'}
                                    color="primary">
                                {t('back_btn')}
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </Fragment>

    );
};

export default StepValidate;