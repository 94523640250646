import React from 'react';
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme}                       from '@material-ui/core/styles';
import Gallery, { GALLERY_DISPLAY_ALL } from "./Gallery";
import { useTranslation }               from 'react-i18next';
import Button from "../../inputs/Button";

const GalleryDialog = ({
                           open,
                           setOpen,
                           entryId,
                           galleryMode = 'insert',
                           selectCallback = () => {
                           },
                           insertCallback = () => {
                           },
	                       display = GALLERY_DISPLAY_ALL,
                       }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();
    const handleClose = () => {
        setOpen(false);
    };

    const onInsert = (html) => {
        insertCallback(html);
        handleClose();
    };

    const onSelect = (img) => {
        selectCallback(img);
        handleClose();
    };

    return (
        <Dialog
            open={open}
            aria-labelledby={t('article_gallery')}
            aria-describedby={t('article_gallery')}
            fullWidth
            fullScreen={fullScreen}
            maxWidth='lg'
        >
            <DialogContent>
                <Gallery
	                entryId={entryId}
	                onInsert={onInsert}
	                galleryMode={galleryMode}
	                onSelect={onSelect}
	                display={display}
                />
            </DialogContent>

            <DialogActions>
                <Button color="secondary" autoFocus onClick={handleClose}>
                    {t('close_btn')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};


export default GalleryDialog;
