import CreateArtist from "../../../pages/artist/Create";
import {getArtistWizardRoute} from "./factory/wizard";

export default [
    {
        path: getArtistWizardRoute(),
        component: CreateArtist,
        exact: true,
        type: 'route',
        loginRequired: true,
    },
]