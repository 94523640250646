import React, { useEffect, useState } from 'react';
import {connect}                      from 'react-redux'
import Grid                           from "@material-ui/core/Grid";
import {useTranslation}                    from 'react-i18next';
import { setUsersFilters, setUsersPaging } from "../../../../store/actions/RealmAction";
import InputAdornment                      from "@material-ui/core/InputAdornment";
import IconButton                     from "@material-ui/core/IconButton";
import SearchIcon                     from '@material-ui/icons/Search';
import TextField                      from "@material-ui/core/TextField";
import ClearIcon                      from '@material-ui/icons/Clear';
import { useHistory, useLocation }    from 'react-router-dom';
import queryString                    from 'query-string';

const Filters = ({setUsersFilters, setUsersPaging, filters}) => {
	const { t } = useTranslation();

	const location = useLocation();
	let params = queryString.parse(location.search);
	const [search, setSearch] = useState(filters?.value || params.s);


	const history = useHistory();

	const handleSearchChange = e => {
		if (e.key !== 'Enter')
			return;

		filterHandler();
	};

	const filterHandler = (clear = false) => {
		let params = queryString.parse(location.search);
		delete params.s;
		if (!clear && search && search.trim()) params.s = search;
		params.p = 1;
		history.push(location.pathname + '?' + queryString.stringify(params));
	};

	useEffect(() => {
		let params = queryString.parse(location.search);
		if ((!!params.s && filters?.value !== params.s)) {
			setUsersFilters({ ...filters, value: params.s  });
			setUsersPaging({ page_no: 1 });
			return;
		}

		if (!params.s) {
			let tmp = { ...filters };
			delete tmp.value;
			setUsersFilters(tmp);
			setUsersPaging({ page_no: 1 });
		}
		// eslint-disable-next-line
	}, [location]);

	const clearFiltersHandler = () => {
		setSearch('');
		filterHandler(true);
	};

	useEffect(() => {
		return () => {
			setUsersFilters({});
		};
		// eslint-disable-next-line
	}, []);

    return (

        <Grid container item xs={12} spacing={3}>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    variant="outlined"
                    value={search}
                    onKeyPress={handleSearchChange}
                    onChange={e => setSearch(e.target.value)}
                    placeholder={t('placeholder_search_users')}
                    InputProps={
                        {
                            min: 0,
                            endAdornment:
                                (<InputAdornment position="end">
                                    <IconButton
                                        aria-label={t('search_user_lbl')}
                                        onClick={() => filterHandler()}
                                    >
                                        <SearchIcon/>

                                    </IconButton>
                                    {search && <IconButton
                                        aria-label={t('clear_filter_label')}
                                        onClick={clearFiltersHandler}
                                    >
                                        <ClearIcon/>

                                    </IconButton>}
                                </InputAdornment>)
                        }
                    }
                />
            </Grid>


        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        filters: state.realm.usersFilters
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUsersFilters: (params) => dispatch(setUsersFilters(params)),
	    setUsersPaging: (params) => dispatch(setUsersPaging(params))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
