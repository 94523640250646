import Inbox from "../../../pages/support/Inbox";
import Outbox from "../../../pages/support/Outbox";
import {
    getSupportClosedRoute,
    getSupportInboxRoute,
    getSupportNewTicketRoute,
    getSupportOutboxRoute
} from "./factory/boxes";
import ClosedBox from "../../../pages/support/ClosedBox";
import Contact from "../../../pages/support/Contact";

export default [
    {
        path: getSupportInboxRoute(),
        component: Inbox,
        exact: false,
        type: 'route',
        loginRequired: true,
    },
    {
        path: getSupportOutboxRoute(),
        component: Outbox,
        exact: false,
        type: 'route',
        loginRequired: true,
    },
    {
        path: getSupportClosedRoute(),
        component: ClosedBox,
        exact: false,
        type: 'route',
        loginRequired: true,
    },
    {
        path: getSupportNewTicketRoute(),
        component: Contact,
        exact: false,
        type: 'route',
        loginRequired: true,
    },
]