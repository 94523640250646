import React       from 'react';
import {Grid}      from '@material-ui/core';
import Publication from './Publication';
import FanclubTags from './FanclubTags';
import {connect}   from 'react-redux';
import ArticleTags from './ArticleTags';

const Settings = ({entryData, isSelectedFanClub, disabled = false}) => {
	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Publication entryData={entryData} disabled={disabled}/>
			</Grid>

			{isSelectedFanClub && <Grid item xs={12}>
				<FanclubTags entryData={entryData} disabled={disabled}/>
			</Grid>}

			<Grid item xs={12}>
				<ArticleTags entryData={entryData} disabled={disabled}/>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		isSelectedFanClub: state.cms.isSelectedFanClub,
	};
};

export default connect(mapStateToProps)(Settings);
