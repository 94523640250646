import React, {useEffect, useState} from 'react';
import DialogTitle               from '@material-ui/core/DialogTitle';
import DialogContent             from '@material-ui/core/DialogContent';
import Grid                      from '@material-ui/core/Grid';
import Box                       from '@material-ui/core/Box';
import DialogActions             from '@material-ui/core/DialogActions';
import Button                    from '../../../inputs/Button';
import Dialog                    from '@material-ui/core/Dialog';
import {useTranslation}          from 'react-i18next';
import Filters                   from '../../record/table/Filters';
import Table                     from '../../record/table/Table';
import {useHistory, useLocation} from 'react-router-dom';
import queryString               from 'query-string';

const SelectorDialog = ({open, setOpen, existing= [], onAdd}) => {
	const {t} = useTranslation();
	const [selected, setSelected] = useState({})
	const [existingMap, setExistingMap] = useState({});
	const [count, setCount] = useState(0);

	const history = useHistory();
	const location = useLocation();


	const handleClose = () => {
		// remove paging and filters
		let params = queryString.parse(location.search);

		if ('p' in params) delete params.p;
		if ('pp' in params) delete params.pp;
		if ('s' in params) delete params.s;

		const query = queryString.stringify(params);

		history.push(location.pathname + (query ? `?${query}` : ''));

		setOpen(false);
	};

	useEffect(() => {
		setCount(Object.keys(selected).length)
	}, [selected, setCount])

	useEffect(() => {
		const map = {}
		existing.forEach(e => {
			map[e.Music_Label_Record__] = e;
		})
		setExistingMap(map);
	}, [existing])

	const handleAdd = () => {
		onAdd(Object.values(selected));
		handleClose()
	}

	return (
		<Dialog
			onEnter={() => {
				setSelected({});
			}}
			open={open}
			aria-labelledby={t('album_musics_title')}
			aria-describedby={t('album_musics_title')}
			maxWidth="xl"
			fullWidth
		>

			<DialogTitle id="album_musics_title">{t('album_musics_title')}</DialogTitle>
			<DialogContent>
				<Box py={2}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Filters/>
						</Grid>
						<Grid item xs={12}>
							<Table selectable={true} setSelected={setSelected} selected={selected} disabledValues={existingMap}/>
						</Grid>
					</Grid>
				</Box>
			</DialogContent>

			<DialogActions>
				<Button color="secondary" autoFocus onClick={handleClose}>
					{t('cancel_btn')}
				</Button>
				<Button color="primary" variant="contained" autoFocus disabled={count < 1} onClick={handleAdd}>
					{t('add_x_record_btn', {count:count})}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default SelectorDialog;