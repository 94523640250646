import React, {useEffect, useState}                        from 'react';
import Autocomplete                                        from '@material-ui/lab/Autocomplete';
import TextField                                           from '@material-ui/core/TextField';
import CircularProgress                                    from '@material-ui/core/CircularProgress';
import {connect}                                           from 'react-redux';
import {fetchAuthors, setAuthorsFilters, setAuthorsPaging} from '../../../../store/actions/AuthorAction';
import {useTranslation}                                    from 'react-i18next';
import {IconButton}                                        from '@material-ui/core';
import {Add, Edit}                                         from '@material-ui/icons';
import EditDialog                                          from './EditDialog';
import CreateDialog                                        from './CreateDialog';

const CmsAuthorAutocomplete = ({
	                               cmsId,
	                               value,
	                               setValue,
	                               list,
	                               filters,
	                               loading,
	                               setFilters,
	                               setPaging,
	                               fetch,
	                               disabled = false,
	                               required = false,
	                               isManager
                               }) => {
	const {t} = useTranslation();
	const [showEditDialog, setShowEditDialog] = useState(false);
	const [showCreateDialog, setShowCreateDialog] = useState(false);

	const onChangeHandler = (e) => {
		setPaging({results_per_page: 100});
		setFilters({Name: {'$prefix': e.target.value}});
	};

	useEffect(() => {
		setPaging({results_per_page: 100});
		fetch(cmsId);
		// eslint-disable-next-line
	}, [fetch, fetch, filters, cmsId]);

	const onUpdated = (a) => {
		setValue(a);
	}

	const onCreated = (a) => {
		setValue(a);
	}

	return (
		<>
			<Autocomplete
				id="author-autocomplete-select"
				onBlur={() => {
					setPaging({results_per_page: 100});
					fetch(cmsId);
				}}
				disabled={disabled}
				value={value}
				onChange={(e, value) => {
					if (value) setValue(value);
				}}
				getOptionSelected={(option, value) => option.Content_Cms_Author__ === value.Content_Cms_Author__}
				getOptionLabel={(option) => option === '' ? '' : option.Name}
				options={list}
				loading={loading}
				renderInput={(params) => (
					<TextField
						{...params}
						required={required}
						label={t('author_lbl')}
						variant="outlined"
						onChange={onChangeHandler}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loading ? <CircularProgress color="inherit" size={20}/> : null}
									{isManager && <IconButton
										onClick={() => setShowEditDialog(true)}
										size="small"
									>
										<Edit/>
									</IconButton>}
									{isManager && <IconButton
										onClick={() => setShowCreateDialog(true)}
										size="small"
									>
										<Add/>
									</IconButton>}
									{params.InputProps.endAdornment}
								</React.Fragment>
							),
						}}
					/>
				)}
			/>
			{(isManager && value) && <EditDialog
				open={showEditDialog}
				setOpen={setShowEditDialog}
				author={value}
				onUpdated={onUpdated}
			/>}
			{(isManager) && <CreateDialog
				open={showCreateDialog}
				setOpen={setShowCreateDialog}
				cmsId={cmsId}
				onCreated={onCreated}
			/>}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.author.authorsLoading,
		list: state.author.authors,
		filters: state.author.authorsFilters,
		isManager: state.access.manager,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: (cmsId) => dispatch(fetchAuthors(cmsId)),
		setFilters: (filters) => dispatch(setAuthorsFilters(filters)),
		setPaging: (paging) => dispatch(setAuthorsPaging(paging))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CmsAuthorAutocomplete);