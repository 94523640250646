import React, { useEffect, useState } from 'react';
import GridListTile                   from '@material-ui/core/GridListTile';
import GridListTileBar                from '@material-ui/core/GridListTileBar';
import IconButton                     from '@material-ui/core/IconButton';
import DeleteIcon                     from '@material-ui/icons/Delete';
import { Skeleton }                   from '@material-ui/lab';
import makeStyles                     from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
	icon: {
		color: 'rgba(255, 255, 255, 0.54)',
		'&:hover': {
			color: 'rgba(255, 255, 255, 0.8)',
		}
	},
	img: {
		maxHeight: '180px',
		maxWidth: '240px',
		overflow: 'hidden'
	}
}));

const GalleryImg = ({ idx, file, onRemove, ...rest }) => {
	const [src, setSrc] = useState(null);
	const classes = useStyles();

	useEffect(() => {
		if (FileReader && file) {
			const fr = new FileReader();
			fr.onload = function () {
				setSrc(fr.result);
			};
			fr.readAsDataURL(file);
		}
	}, [setSrc, file]);

	return (
		<GridListTile className={classes.img} {...rest}>
			{!src && <Skeleton width={200} height={180}/>}
			{src && <img src={src} alt={file.name}/>}

			<GridListTileBar
				title={file.name}
				actionIcon={
					<IconButton className={classes.icon}
					            onClick={() => {
						            onRemove(idx);
					            }}>
						<DeleteIcon/>
					</IconButton>
				}
			/>
			}
		</GridListTile>
	);
};

export default GalleryImg;
