import React from 'react';
import {Provider} from "react-redux";
import {renderToString} from 'react-dom/server';
import {matchPath, StaticRouter} from 'react-router-dom';
import createStore, {setAsCurrentStore} from "../store/store";
import Layout from "../components/layout/Layout";
import routeConfig, {prerequisites} from './routeConfig'
import {getPrefix, getPath, getUuid} from "@karpeleslab/klbfw";
import {Helmet} from "react-helmet";
import {i18nPromise} from '../i18n';

function loadData(store) {
    const promises = [i18nPromise];

    routeConfig.some(route => {
        const match = matchPath(getPath(), route);
        if (match && route.loadData !== undefined) {
            route.loadData.forEach((f) => {
                const promise = f(match, store);

                if (Promise.resolve(promise) !== promise) {
                    throw new Error('loadData functions must return a promise')
                }

                promises.push(promise);
            })
        }
        return match;
    });

    return Promise.all(promises);
}

function loadPrerequisite(store) {
    return Promise.all(prerequisites(store));
}

function render(store, result) {
    let context = {};

    result.app = renderToString(
        <Provider store={store}>
            <StaticRouter context={context} basename={getPrefix()} location={getPrefix() + getPath()}>
                <Layout/>
            </StaticRouter>
        </Provider>
    );

    if (context.statusCode) {
        result.statusCode = context.statusCode;
    }

    if (context.url) {
        result.redirect = context.url;
        return result;
    }

    result.initial = store.getState();

    Helmet.canUseDOM = false;

    const helmet = Helmet.renderStatic();
    result.title = helmet.title ? helmet.title.toString() : null;
    result.meta = helmet.meta ? helmet.meta.toString() : null;
    result.script = helmet.script ? helmet.script.toString() : null;
    result.link = helmet.link ? helmet.link.toString() : null;
    result.bodyAttributes = helmet.bodyAttributes ? helmet.bodyAttributes.toString() : null;
    result.htmlAttributes = helmet.htmlAttributes ? helmet.htmlAttributes.toString() : null;

    return result;
}

export default (cbk) => {
    const store = createStore();
    setAsCurrentStore(store);

    const result = {
        uuid: getUuid(),
        app: null,
        title: null,
        meta: null,
        script: null,
        link: null,
        initial: null,
        error: null,
        redirect: null,
        bodyAttributes: null,
        htmlAttributes: null,
        statusCode: null,
    };

    try {
        loadPrerequisite(store)
            .then(() => loadData(store))
            .then(() => render(store, result))
            .then((r) => {
                cbk(r)
            })


    } catch (e) {
        result.error = e.toString();
        return cbk(result);
    }
}
