import React, { Fragment, useEffect } from 'react';
import TableHead                      from '@material-ui/core/TableHead';
import MaterialTable                  from '@material-ui/core/Table';
import TableRow                       from '@material-ui/core/TableRow';
import TableCell                      from '@material-ui/core/TableCell';
import TableBody                      from '@material-ui/core/TableBody';
import TableContainer                 from '@material-ui/core/TableContainer';
import Row                            from './Row';
import { connect }                    from 'react-redux';
import TableFooter                    from '@material-ui/core/TableFooter';
import { useTranslation }             from 'react-i18next';
import Empty                          from './Empty';
import {
	fetchDeliverables,
	setDeliverablesFilters,
	setDeliverablesPaging,
}                                     from '../../../../../store/actions/store/DeliverableAction';
import HasRight                       from '../../../../Route/HasRight';
import SortableCell                   from '../../../data/table/SortableCell';
import Pagination                     from '../../../data/table/Pagination';

const Table = ({ catalog, deliverables, fetch, loading, filters, paging, count, setDeliverablesPaging, setDeliverablesFilters }) => {
	const loadingData = [...Array(paging.results_per_page).keys()];
	const { t } = useTranslation();
	const displayLoading = () => loadingData.map((k) => <Row
		key={k}
		loading={true}
		deliverable={null}
	/>);

	const displayDeliverables = () => deliverables.map((u, idx) => <Row key={u.Catalog_Deliverable__}
	                                                                    loading={false}
	                                                                    deliverable={u}/>);

	useEffect(() => {
		fetch(catalog.Catalog__);
	}, [fetch, filters, paging, catalog]);

	const { sort } = filters;

	const sortHandler = (property, dir) => {
		let p = { ...filters };
		// only 1 sort
		p.sort = {};
		p.sort[property] = dir;
		setDeliverablesFilters(p);
	};

	return (
		<Fragment>
			{(loading || deliverables.length > 0) &&
			<TableContainer>
				<MaterialTable aria-label='simple table'>
					<TableHead>
						<TableRow>
							<Pagination
								setPaging={setDeliverablesPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
						<TableRow>
							<TableCell> {t('tablecell_deliverable_name')}</TableCell>
							<TableCell>
								<SortableCell sort={sort} onClick={sortHandler} field='Style'
								              label={t('tablecell_deliverable_style')}/>
							</TableCell>
							<HasRight manager hideLoading>
								<TableCell align='right'/>
							</HasRight>
						</TableRow>
					</TableHead>
					<TableBody>
						{loading && displayLoading()}
						{!loading && displayDeliverables()}
					</TableBody>
					<TableFooter>
						<TableRow>
							<Pagination
								setPaging={setDeliverablesPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
					</TableFooter>

				</MaterialTable>
			</TableContainer>
			}
			{(!loading && deliverables.length < 1) && <Empty/>}
		</Fragment>
	);

};


const mapStateToProps = (state) => {
	return {
		filters: state.deliverable.deliverablesFilters,
		paging: state.deliverable.deliverablesPaging,
		count: state.deliverable.deliverablesPagingCount,
		loading: state.deliverable.deliverablesLoading,
		deliverables: state.deliverable.deliverables,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: (catalog) => dispatch(fetchDeliverables(catalog)),
		setDeliverablesPaging: (newPaging) => dispatch(setDeliverablesPaging(newPaging)),
		setDeliverablesFilters: (newFilters) => dispatch(setDeliverablesFilters(newFilters)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);

