import React            from 'react';
import Grid             from '@material-ui/core/Grid';
import AppBarTitle      from '../../components/common/typography/AppBarTitle';
import {useTranslation} from 'react-i18next';
import {connect}        from 'react-redux';
import EditArtist       from '../../components/common/artist/Edit';
import Toolbar          from '../../components/common/typography/Toolbar';
import SelectRealm      from '../SelectRealm';

const Edit = ({artist, selectedRealm}) => {
	const {t} = useTranslation();

	if (!selectedRealm) return <SelectRealm/>;

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('artist_title')}/>

			<Grid item xs={12}>
				<Toolbar title={t('artist_title')}/>
			</Grid>

			<Grid item xs={12}>
				<EditArtist artist={artist}/>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		artist: state.artist.artist,
		selectedRealm: state.realm.selected,
	};
};

export default connect(mapStateToProps)(Edit);
