import React from 'react';
import Grid from "@material-ui/core/Grid";
import {Title} from "../../../typography/Title";
import Typography from "@material-ui/core/Typography";
import {Trans, useTranslation} from 'react-i18next';
import {getFanclubSubscribersRoute} from "../../../../../router/routes/fanclub/factory/subscriber";
import ProductPrices from "../../../store/shop/Prices";
import Link from "../../../typography/Link";


const Prices = ({productId}) => {
    const {t} = useTranslation();

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Title>{t('prices_title')}</Title>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary" component="p">
                    {t('prices_title_desc')}
                    <br/>
                    <Trans i18nKey='prices_title_info'>
                        If you want to reduce the price a given user pay every month, please refers to the <Link
                        to={getFanclubSubscribersRoute()}>Members section</Link>.
                    </Trans>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <ProductPrices productId={productId}/>
            </Grid>
        </Grid>
    );
};


export default Prices;