import React, {Fragment, useEffect, useState} from 'react';
import Dialog                                 from '@material-ui/core/Dialog';
import DialogContentText                      from '@material-ui/core/DialogContentText';
import DialogContent                          from '@material-ui/core/DialogContent';
import Grid                                   from '@material-ui/core/Grid';
import TextField                              from '@material-ui/core/TextField';
import {useTranslation}                       from 'react-i18next';
import DialogTitle                            from '@material-ui/core/DialogTitle';
import DialogActions  from '@material-ui/core/DialogActions';
import {connect}      from 'react-redux';
import {createLabel}  from '../../../../../store/actions/TagAction';
import {getLocale}    from '@karpeleslab/klbfw';
import Button         from '../../../inputs/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import {Select}       from '@material-ui/core';
import Flag           from '../../../data/flag/Flag';
import MenuItem       from '@material-ui/core/MenuItem';
import Typography     from '@material-ui/core/Typography';
import Flags          from '../../../inputs/Flags';
import {fetch}        from '../../../../../store/actions/system/LanguageAction';
import {isObject}     from '../../../../utils/misc';

const buildItem = (v, t) => {
	if (!v) return; // The default value
	if(isObject(v)) v = v.Language__;
	return <MenuItem value={v} key={'multiText-' + v}>
		<Grid container spacing={3} alignItems="center">
			<Grid item>
				<Flag code={v.split('-')[1].toLowerCase()}/>
			</Grid>
			<Grid item>
				<Typography>{t('language_' + v.split('-')[0].toLowerCase())}</Typography>
			</Grid>
		</Grid>
	</MenuItem>;
};

const EditCategoryDialog = ({tag, open, setOpen, updating, update, onUpdated, settingLanguage, langs, fetchLang}) => {
	const {t} = useTranslation();
	const [name, setName] = useState('');
	const [saveDisabled, setSaveDisabled] = useState(true);
	const [locale, setLocale] = useState(getLocale());
	const [showLang, setShowLang] = useState(false);
	const [flags, setFlags] = useState(Object.keys(tag.Flags ?? {}));

	useEffect(() => {
		if(settingLanguage)return;
		fetchLang();
	}, [settingLanguage, fetchLang])

	useEffect(() => {
		const originalFlags = Object.keys(tag.Flags ?? {});
		// Really poorly optimized !!!
		if (flags.length !== originalFlags.length) {
			setSaveDisabled(false);
			return;
		}

		for (let i = 0; i < originalFlags.length; i++) {
			if (flags.includes(originalFlags[i])) continue;
			setSaveDisabled(false);
			return;
		}

		setSaveDisabled(name.trim() === '' || name === (locale in tag.Names ? tag.Names[locale] : ''));
	}, [locale, name, setSaveDisabled, tag, flags]);

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		setName(locale in tag.Names ? tag.Names[locale] : '');
	}, [locale, tag]);

	const handleEdit = () => {
		update(tag, name, locale, {Flags: flags})
			.then(d => {
				setLocale(getLocale());
				onUpdated(d);
				handleClose();
			});
	};

	const init = () => {
		const loc = getLocale();
		setLocale(loc);
		setName(loc in tag.Names ? tag.Names[locale] : '');
		setFlags(Object.keys(tag.Flags ?? {}));
	};

	return (
		<Dialog
			onEnter={init}
			open={open}
			aria-labelledby={t('update_category')}
			aria-describedby={t('update_category')}
		>
			<DialogTitle id="update-category-title">{t('update_category')}</DialogTitle>
			<DialogContent>
				<DialogContentText id="update-category-description">
				</DialogContentText>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							label={t('category_name_lbl')}
							fullWidth
							variant="outlined"
							value={name}
							required
							disabled={updating}
							onChange={e => setName(e.target.value)}
							helperText={t('category_name_helperText')}
							InputProps={
								{
									endAdornment:
										(<InputAdornment position="end" onClick={e => e.stopPropagation()}>
											<Fragment>
												<Select
													disableUnderline={true}
													open={showLang}
													onChange={(e) => setLocale(e.target.value)}
													onClose={() => setShowLang(false)}
													onOpen={() => setShowLang(true)}
													disabled={updating}
													value={locale}
													native={false}
													renderValue={(v) => {
														if (v)
															return <Flag
																size="small"
																ratio="4x3"
																code={v.split('-')[1].toLowerCase()}
																alt={v}
															/>;


													}
													}
												>
													{(settingLanguage ?? (langs ?? [])).map((v) => buildItem(v, t))}
												</Select>
											</Fragment>
										</InputAdornment>)
								}
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<Flags
							value={flags}
							onChange={setFlags}
							disabled={updating}
							prefix='tag'
							flags={[
								'hidden',
							]}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button color="default" onClick={handleClose} disabled={updating}>
					{t('close_btn')}
				</Button>
				<Button color="primary" onClick={handleEdit} variant="contained" disabled={updating || saveDisabled}
				        loading={updating}>
					{t('save_btn')}
				</Button>
			</DialogActions>
		</Dialog>

	);
};

const mapStateToProps = (state) => {
	return {
		updating: state.tag.creatingLabel,
		settingLanguage: state.template.templateSettings?.Data?.Languages ?? null,
		langs: state.language.languages,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		update: (tag, name, locale, data) => dispatch(createLabel(tag, name, locale, data)),
		fetchLang: () => dispatch(fetch())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCategoryDialog);
