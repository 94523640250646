import React, {useEffect}     from 'react';
import {fetchCommentsStats}   from '../../../../store/actions/SocialAction';
import {connect}              from 'react-redux';
import {useTranslation}       from 'react-i18next';
import {List}                                   from '@material-ui/core';
import HasRight                                 from '../../../Route/HasRight';
import ListItemNavLink                                     from '../ListItemNavLink';
import {
	getSiteAddressRoute,
	getSiteFinancialReportRoute,
	getSiteSettingsRoute
} from '../../../../router/routes/site/factory/setting';
import SettingsIcon                                        from '@material-ui/icons/Settings';
import {getSiteUsersRoute}                      from '../../../../router/routes/site/factory/user';
import {FaUsers}               from 'react-icons/fa';
import {
	getSiteCommentsRoute,
	getSiteNewsRoute, getSiteNotificationListRoute,
	getSiteNotificationRoute
}                              from '../../../../router/routes/site/factory/content';
import {TiNews}                from 'react-icons/ti';
import {getSiteOrdersRoute}    from '../../../../router/routes/site/factory/order';
import CreditCardIcon          from '@material-ui/icons/CreditCard';
import Badge                   from '@material-ui/core/Badge';
import CommentIcon             from '@material-ui/icons/Comment';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import SpeakerNotesIcon        from '@material-ui/icons/SpeakerNotes';
import AccountBalanceIcon      from '@material-ui/icons/AccountBalance';
import BusinessIcon            from '@material-ui/icons/Business';

const MenuWebsite = ({
	                     registryUrl,
	                     selectedRealm,
	                     newsCms,
	                     pagesCms,
	                     open,
	                     ready,
	                     refreshComments,
	                     creatingArtist,
	                     commentsCount,
	                     settings
                     }) => {
	const {t} = useTranslation();

	let showComments = true;
	if (settings && settings.Data && settings.Data.social && !settings.Data.social.includes('comment'))
		showComments = false;

	useEffect(() => {
		// For the init, to not wait wait the interval to finish
		if (!selectedRealm) return;
		refreshComments(selectedRealm.Realm__).catch(() => {
		});
	}, [selectedRealm, refreshComments]);

	useEffect(() => {
		const refreshInterval = setInterval(() => {
			if (!selectedRealm || creatingArtist) return;
			refreshComments(selectedRealm.Realm__).catch(() => {
			});
		}, 30000);

		return () => {
			clearInterval(refreshInterval);
		};
		// eslint-disable-next-line
	}, [creatingArtist, selectedRealm]);


	return (
		<List>
			<div>
				<HasRight manager hideLoading>
					<ListItemNavLink
						tooltip={t('menu_site_settings')}
						disabled={registryUrl === null || !ready}
						path={getSiteSettingsRoute()}
						icon={<SettingsIcon size={24}/>}
						primary={t('menu_site_settings')}
					/>
				</HasRight>

				<ListItemNavLink
					tooltip={t('menu_users')}
					disabled={selectedRealm === null || !ready}
					path={getSiteUsersRoute()}
					icon={<FaUsers size={24}/>}
					primary={t('menu_users')}
				/>

				<ListItemNavLink
					tooltip={t('menu_news')}
					disabled={selectedRealm === null || !newsCms || !ready}
					path={getSiteNewsRoute()}
					icon={<TiNews size={24}/>}
					primary={t('menu_news')}
				/>

				{/*
                    Hidden fo now, this cms was used for all legal document, now wa have a common non editable cms for this.
                    Pages will be used in the future as static content page. e.g a a link in the menu, custom page for an event etc ...

                    <ListItemNavLink
                    tooltip={t('menu_pages')}
                    disabled={selectedRealm === null || !pagesCms || !ready}
                    path={getSitePagesRoute()}
                    icon={<FaFileAlt size={24}/>}
                    primary={t('menu_pages')}
                />*/}
				<HasRight support hideLoading>
					<ListItemNavLink
						tooltip={t('menu_orders')}
						disabled={selectedRealm === null || !ready}
						path={getSiteOrdersRoute()}
						icon={<CreditCardIcon size={24}/>}
						primary={t('menu_orders')}
					/>
				</HasRight>

				{showComments && <ListItemNavLink
					tooltip={t('menu_comments')}
					disabled={selectedRealm === null || !ready}
					path={getSiteCommentsRoute()}
					icon={<Badge color="primary"
					             badgeContent={parseInt(commentsCount) ?? 0}><CommentIcon
						size={24}/></Badge>}
					primary={t('menu_comments')}
				/>}

				<HasRight manager hideLoading>
					<ListItemNavLink
						exact
						tooltip={t('menu_site_notify')}
						disabled={!ready}
						path={getSiteNotificationRoute()}
						icon={<NotificationsActiveIcon size={24}/>}
						primary={t('menu_site_notify')}
					/>
					<ListItemNavLink
						tooltip={t('menu_site_notify_list')}
						disabled={!ready}
						path={getSiteNotificationListRoute()}
						icon={<SpeakerNotesIcon size={24}/>}
						primary={t('menu_site_notify_list')}
					/>
				</HasRight>

				<HasRight admin hideLoading>
					<ListItemNavLink
						tooltip={t('menu_site_financial_report')}
						disabled={registryUrl === null || !ready}
						path={getSiteFinancialReportRoute()}
						icon={<AccountBalanceIcon size={24}/>}
						primary={t('menu_site_financial_report')}
					/>
				</HasRight>

				<HasRight owner hideLoading>
					<ListItemNavLink
						tooltip={t('menu_site_address')}
						disabled={registryUrl === null || !ready}
						path={getSiteAddressRoute()}
						icon={<BusinessIcon/>}
						primary={t('menu_site_address')}
					/>

				</HasRight>
			</div>
		</List>
	);
};


const mapStateToProps = (state) => {
	return {
		commentsCount: state.social.commentsStats,
		open: state.common.drawerOpened,
		selectedRealm: state.realm.selected,
		registryUrl: state.registry.url,
		newsCms: state.cms.news_cms,
		pagesCms: state.cms.pages_cms,
		ready: state.common.ready,
		creatingArtist: state.music.creating, // Use to not to fetch comments info when creating a new artist (cause it can lead to access denied error)
		settings: state.template.templateSettings,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		refreshComments: (id) => dispatch(fetchCommentsStats(id))
	};
};


export default connect(mapStateToProps, mapDispatchToProps)(MenuWebsite);
