import React from 'react';
import {Trans, useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import graphic from '../../../resources/images/store-wizard/intro.svg'
import Button from "../../common/inputs/Button";

const Step1 = ({handleBack, handleNext}) => {
    const {t} = useTranslation();

    return (
        <Grid container spacing={3} direction="row"
              justify="center">


            <Grid item xs={4} md={4}>
                <img src={graphic} alt='store' width="100%"/>
            </Grid>

            <Grid item xs={12} md={8}>
                <Grid container spacing={3} direction="column" justify="center">

                    <Grid item xs={12}>
                        <Typography variant="h5" color="textPrimary" component="h5">
                            {t('store_wizard_intro_title')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Trans i18nKey="store_wizard_intro_desc">
                            sample
                            <br/>
                            <strong>sample</strong>
                        </Trans>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify='center'>

                            <Button onClick={handleNext} variant="contained" color="primary">
                                {t('store_wizard_create_btn')}
                            </Button>
                        </Grid>
                    </Grid>


                </Grid>
            </Grid>

        </Grid>
    );
};

export default Step1;