import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import SelectRealm from '../../SelectRealm';
import {useParams} from 'react-router-dom';
import Loading from '../../../components/common/feeback/loading/Loading';
import {Tab, Tabs} from '@material-ui/core';
import AppBarTitle from '../../../components/common/typography/AppBarTitle';
import Grid from '@material-ui/core/Grid';
import Panel from '../../../components/common/data/tab/Panel';
import Content from '../../../components/common/cms/entry/Content';
import Gallery from '../../../components/common/cms/gallery/Gallery';
import Templates from '../../../components/common/cms/template/Templates';
import {fetchAndSelectCms, fetchEntryData} from '../../../store/actions/CmsAction';
import Publication from '../../../components/common/planner/event/Publication';
import {fetchEvent} from '../../../store/actions/planner/EventAction';
import Tickets from '../../../components/common/planner/event/Tickets';
import Details from '../../../components/common/planner/event/Details';
import Survey from '../../../components/common/planner/event/Survey';
import Reserve from '../../../components/common/planner/event/Reserve';
import Toolbar from '../../../components/common/typography/Toolbar';
import GoLiveDialog from '../../../components/common/planner/event/live/GoLiveDialog';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Stream from '../../../components/common/planner/event/Stream';
import HasRight from '../../../components/Route/HasRight';
import Button from '../../../components/common/inputs/Button';
import Social from '../../../components/common/cms/social/Social';
import Management from '../../../components/common/planner/schedule/Management';
import ScheduleActivator from '../../../components/common/planner/ScheduleActivator';
import EntryDataSelector from '../../../components/common/planner/EntryDataSelector';

const TAB_CONTENT = 'TAB_CONTENT';
const TAB_VISIBILITY = 'TAB_VISIBILITY';
const TAB_TICKET = 'TAB_TICKET';
const TAB_SURVEY = 'TAB_SURVEY';
const TAB_RESERVE = 'TAB_RESERVE';
const TAB_GALLERY = 'TAB_GALLERY';
const TAB_TEMPLATE = 'TAB_TEMPLATE';
const TAB_SOCIAL = 'TAB_SOCIAL';
const TAB_STREAM = 'TAB_STREAM';
const TAB_MANAGEMENT = 'TAB_MANAGEMENT';


const Edit = ({
	              event,
	              planner,
	              selectedRealm,
	              fetchEvent,
	              fetchAndSelectCms,
	              streamSupported,
	              isManager,
	              fetchEntryData,
	              entryData,
	              templateSettings
              }) => {
	const {t} = useTranslation();
	const [loading, setLoading] = useState(true);
	const {eventId} = useParams();
	const [tabIndex, setTabIndex] = useState(TAB_CONTENT);
	const [showGoLive, setShowGoLive] = useState(false);

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {noSsr: true});

	let showSocial = true;
	if (templateSettings && templateSettings.Data && templateSettings.Data.social === 'none')
		showSocial = false;

	useEffect(() => {
		if (!planner) return;
		setLoading(true);
		fetchEvent(eventId)
			.then(e => fetchEntryData(e.Schedule.Content_Cms_Entry_Data.Content_Cms_Entry_Data__))
			.then(e => fetchAndSelectCms(planner.Content_Cms__))
			.then(
				e => {
					setLoading(false);
				}
			);
	}, [planner, eventId, fetchEvent, fetchAndSelectCms, fetchEntryData]);

	if (!selectedRealm || !planner) return <SelectRealm/>;

	const handleTabChange = (event, newValue) => {
		setTabIndex(newValue);
	};

	if (loading) return <Loading/>;

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('event_edit_title')}/>
			<Grid item xs={12}>
				<Toolbar title={t('event_edit_title')}>
					<HasRight support hideLoading>
						<Button variant="outlined" color="primary" onClick={() => setShowGoLive(true)}>
							{t('go_live_btn')}
						</Button>
					</HasRight>
				</Toolbar>
				<HasRight support hideLoading>
					<GoLiveDialog open={showGoLive} setOpen={setShowGoLive} event={event}/>
				</HasRight>
			</Grid>

			<ScheduleActivator/>

			<Grid item xs={12}>
				<Tabs
					variant="scrollable"
					scrollButtons={isMobile ? 'on' : 'auto'}
					value={tabIndex}
					indicatorColor="primary"
					textColor="primary"
					onChange={handleTabChange}
					aria-label=""
				>
					<Tab label={t('article_tab_content')} value={TAB_CONTENT}/>
					<Tab label={t('article_tab_visibility')} value={TAB_VISIBILITY}/>
					<Tab label={t('event_tab_ticket')} value={TAB_TICKET}/>
					<Tab label={t('event_tab_survey')} value={TAB_SURVEY}/>
					<Tab label={t('event_tab_reserve')} value={TAB_RESERVE}/>
					<Tab label={t('article_tab_gallery')} value={TAB_GALLERY}/>
					<Tab label={t('article_tab_templates')} value={TAB_TEMPLATE}/>
					{showSocial && <Tab label={t('article_tab_social')} value={TAB_SOCIAL}/>}
					{(streamSupported && isManager) && <Tab label={t('stream_title')} value={TAB_STREAM}/>}
					<Tab label={t('article_tab_management')} value={TAB_MANAGEMENT}/>
				</Tabs>

				<Panel value={tabIndex} index={TAB_CONTENT}>
					<Details event={event}/>
					<EntryDataSelector/>
					<Content key={entryData.Content_Cms_Entry_Data__} entryData={entryData} schedule={event.Schedule}/>
				</Panel>

				<Panel value={tabIndex} index={TAB_VISIBILITY}>
					<Publication/>
				</Panel>

				<Panel value={tabIndex} index={TAB_TICKET}>
					<Tickets event={event}/>
				</Panel>

				<Panel value={tabIndex} index={TAB_SURVEY}>
					<Survey event={event}/>
				</Panel>

				<Panel value={tabIndex} index={TAB_RESERVE}>
					<Reserve event={event}/>
				</Panel>

				<Panel value={tabIndex} index={TAB_GALLERY}>
					<Gallery entryId={event.Schedule.Content_Cms_Entry__} galleryMode="manage"/>
				</Panel>

				<Panel value={tabIndex} index={TAB_TEMPLATE}>
					<Templates cmsId={planner.Content_Cms__}/>
				</Panel>

				{showSocial && <Panel value={tabIndex} index={TAB_SOCIAL}>
					<Social entryData={entryData}/>
				</Panel>}

				{(streamSupported && isManager) && <Panel value={tabIndex} index={TAB_STREAM}>
					<Stream event={event}/>
				</Panel>}

				<Panel value={tabIndex} index={TAB_MANAGEMENT}>
					<Management schedule={event.Schedule} isEvent={true} setTabIndex={() => {
						setTabIndex(TAB_CONTENT);
					}}/>
				</Panel>
			</Grid>

		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		planner: state.schedule.planner,
		entryData: state.cms.entryData,
		event: state.event.event,
		streamSupported: state.template.isStreamSupported,
		isManager: state.access.manager,

		templateSettings: state.template.templateSettings,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchEvent: (id) => dispatch(fetchEvent(id)),
		fetchEntryData: (id) => dispatch(fetchEntryData(id)),
		fetchAndSelectCms: (id) => dispatch(fetchAndSelectCms(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
