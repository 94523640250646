import React                   from 'react';
import {connect}               from 'react-redux';
import Grid                    from '@material-ui/core/Grid';
import AppBarTitle             from '../../components/common/typography/AppBarTitle';
import {Trans, useTranslation} from 'react-i18next';
import Toolbar                 from '../../components/common/typography/Toolbar';
import Wizard                  from '../../components/wizard/store/Wizard';
import AccessCheck             from '../../components/Route/AccessCheck';
import Redirect                from '../../components/Route/Redirect';
import {getPXLDashboardRoute}  from '../../router/routes/pxl/factory/general';
import graphic                 from '../../resources/images/store-wizard/intro.svg';
import Typography              from '@material-ui/core/Typography';
import StoreActivator          from '../../components/common/store/StoreActivator';
import SelectRealm             from '../SelectRealm';

const Create = ({selectedRealm, catalog}) => {
	const {t} = useTranslation();

	if (!selectedRealm) return <SelectRealm/>;

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('store_creation_title')}/>
			<Grid item xs={12}>
				<Toolbar title={t('store_creation_title')}/>
			</Grid>

			<AccessCheck capabilities={{store: true}} rights={{owner: true}}
			             deniedComponent={<Redirect target={getPXLDashboardRoute()}/>}>
				{!catalog &&
				<Grid item xs={12}>
					<Wizard/>
				</Grid>
				}
				{catalog &&
				<Grid item xs={12}>
					<Grid container spacing={3} direction="row"
					      justify="center">
						<Grid item xs={4} md={4}>
							<img src={graphic} alt="store" width="100%"/>
						</Grid>

						<Grid item xs={12} md={8}>
							<Grid container spacing={3} direction="column" justify="center">

								<Grid item xs={12}>
									<Typography variant="h5" color="textPrimary" component="h5">
										{t('store_wizard_created_title')}
									</Typography>
								</Grid>

								<StoreActivator/>

								<Grid item xs={12}>
									<Trans i18nKey="store_wizard_created_desc">
										sample
										<br/>
										<strong>sample</strong>
									</Trans>
								</Grid>

							</Grid>
						</Grid>
					</Grid>
				</Grid>
				}
			</AccessCheck>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		catalog: state.store.catalog,
	};
};


export default connect(mapStateToProps)(Create);
