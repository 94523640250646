import React from 'react';
import {useTranslation} from 'react-i18next';
import Select from "./Select";

const BloodTypeSelector = ({value, setValue, disabled, required}) => {
    const {t} = useTranslation();

    return <Select
        label={t('member_blood_label')}
        value={value}
        onChange={e => setValue(e.target.value)}
        optionKey='Key'
        optionValue='Value'
        options={[
            {Key: 'O', Value: 'O'},
            {Key: 'A', Value: 'A'},
            {Key: 'B', Value: 'B'},
            {Key: 'AB', Value: 'AB'},
        ]}
        inputProps={{
            native:true,
            disabled: disabled,
            variant: 'outlined',
            fullWidth: true,
            required: required
        }}
    />;
};

export default BloodTypeSelector;