import React, { Fragment }    from 'react';
import Card                   from '@material-ui/core/Card';
import CardContent            from '@material-ui/core/CardContent';
import Typography             from '@material-ui/core/Typography';
import Grid                   from '@material-ui/core/Grid';
import moment                 from 'moment';
import makeStyles             from '@material-ui/core/styles/makeStyles';
import Skeleton               from '@material-ui/lab/Skeleton';
import CardActions            from '@material-ui/core/CardActions';
import HighlightOffIcon       from '@material-ui/icons/HighlightOff';
import CancelMembershipDialog from './CancelMembershipDialog';
import PriceEditDialog        from './PriceEditDialog';
import { useTranslation }     from 'react-i18next';
import HasRight               from '../../../../Route/HasRight';
import Button from "../../../inputs/Button";
import Link from "../../../typography/Link";

const useStyles = makeStyles(theme => ({
	fillHeight: {
		height: '100%'
	}
}));

const Detail = ({ membershipUser, enableActions = true }) => {
	const [cancelModalOpened, setCancelModalOpened] = React.useState(false);
	const [priceModalOpened, setPriceModalOpened] = React.useState(false);
	const classes = useStyles();

	const handleEditPrice = e => {
		e.preventDefault();
		setPriceModalOpened(true);
	};
	const { t } = useTranslation();


	return (
		<Fragment>
			<Card className={classes.fillHeight} variant='outlined'>
				<CardContent>
					<Typography
						gutterBottom
						variant='h5'
						component='h2'
					>
						{t('details')}
					</Typography>

					<Grid
						container
						direction='row'
						justify='center'
						alignItems='center'
					>
						{/* Membership Price */}
						<Grid item xs={12}>
							<Typography variant='body1' color='textPrimary' component='p'>
								{t('price')}:
							</Typography>
						</Grid>

						<Grid item xs={9}>
							{
								membershipUser &&
								<Typography variant='body2' color='textSecondary' component='p'>
									{membershipUser.Price.tax.display}
								</Typography>
							}
							{!membershipUser && <Skeleton/>}
						</Grid>


						<Grid item xs={3}>
							{
								(membershipUser && enableActions) &&
								<HasRight admin hideLoading>
									<Link href='#' color='primary' onClick={handleEditPrice}>
										{t('edit_lnk')}
									</Link>
								</HasRight>
							}
						</Grid>


						{/* Membership Expires */}

						<Grid item xs={12}>
							<Typography variant='body1' color='textPrimary' component='p'>
								{t('expires')}:</Typography>
						</Grid>


						<Grid item xs={12}>
							{
								membershipUser &&
								<Typography variant='body2' color='textSecondary' component='p'>
									{moment(parseInt(membershipUser.Expires.unixms)).format('LLL')}
								</Typography>
							}
							{!membershipUser && <Skeleton/>}
						</Grid>

					</Grid>
				</CardContent>
				{enableActions && <CardActions>
					<Grid container
					      direction='row'
					      justify='center'
					      alignItems='center'
					>
						<HasRight admin hideLoading>
							{(membershipUser && membershipUser.Status === 'paid') &&
							<Button
								size='small'
								variant='contained'
								color='secondary'
								onClick={() => {
									setCancelModalOpened(true);
								}}
								startIcon={<HighlightOffIcon/>}>
								{t('cancel_membership')}
							</Button>
							}
						</HasRight>
					</Grid>
				</CardActions>}

			</Card>
			{enableActions &&
			<CancelMembershipDialog
				membershipUser={membershipUser}
				open={cancelModalOpened}
				setOpen={setCancelModalOpened}
			/>
			}
			{enableActions &&
			<PriceEditDialog
				membershipUser={membershipUser}
				open={priceModalOpened}
				setOpen={setPriceModalOpened}/>
			}
		</Fragment>
	);
};

export default Detail;
