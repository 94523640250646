import React, {useEffect, useState}                             from 'react';
import {connect}                                                from 'react-redux';
import {fetchAlbum}                                             from '../../../store/actions/discography/AlbumAction';
import {Trans, useTranslation}                                  from 'react-i18next';
import {Link, useHistory, useParams}                            from 'react-router-dom';
import SelectRealm                                              from '../../SelectRealm';
import Loading
                                                                from '../../../components/common/feeback/loading/Loading';
import Grid                                                     from '@material-ui/core/Grid';
import AppBarTitle
                                                                from '../../../components/common/typography/AppBarTitle';
import Toolbar                                                  from '../../../components/common/typography/Toolbar';
import Form                                                     from '../../../components/common/music/album/Form';
import {createRelease}                                          from '../../../store/actions/discography/DiscographyAction';
import {getMusicDiscographyEditRoute, getMusicDiscographyRoute} from '../../../router/routes/music/factory/discography';
import RichAlert                                                from '../../../components/common/feeback/RichAlert';
import FormControlLabel                                         from '@material-ui/core/FormControlLabel';
import Checkbox                                                 from '@material-ui/core/Checkbox';
import Button                                                   from '../../../components/common/inputs/Button';
import FormRequiredField
                                                                from '../../../components/common/feeback/FormRequiredField';

const Review = ({selectedRealm, artist, album, fetchAlbum, create, creating}) => {
	const {t} = useTranslation();
	const {albumId} = useParams();
	const [loading, setLoading] = useState(true);
	const [formLoaded, setFormLoaded] = useState(false);
	const [confirmCheck, setConfirmCheck] = useState(false);

	const history = useHistory();

	useEffect(() => {
		fetchAlbum(albumId)
			.then(s => setLoading(false));
	}, [fetchAlbum, albumId]);

	if (!selectedRealm) return <SelectRealm/>;
	if (!artist || loading) return <Loading/>;

	const handleCreate = () => {
		create(albumId)
			.then((r) => {
				history.push(getMusicDiscographyEditRoute(r.Music_Label_Release__));
			});
	};

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('album_review_title')}/>

			<Grid item xs={12}>
				<Toolbar title={t('album_review_title')}/>
			</Grid>

			<Grid item xs={12}>
				<Form artist={artist} album={album} isReview setFormLoaded={setFormLoaded}/>
			</Grid>

			{formLoaded &&
			<>
				<Grid item xs={12}>
					<RichAlert severity="warning">
						<Trans i18nKey="release_review_info">
							sample<br/>
							<strong>sample</strong>
							sample
						</Trans>
					</RichAlert>
				</Grid>

				<FormRequiredField/>

				<Grid item xs={12}>
					<FormControlLabel
						control={<Checkbox
							required
							checked={confirmCheck}
							color="primary"
							name="discount"
							disabled={creating}
							onChange={(e) => {
								setConfirmCheck(e.target.checked);
							}}
						/>}
						label={t('release_create_confirm_lbl')}
					/>
				</Grid>

				<Grid item xs={12}>
					<Grid container justify={'space-between'} spacing={3}>
						<Grid item>
							<Button
								component={Link}
								to={getMusicDiscographyRoute()}
								variant="outlined"
								disabled={creating}
							>
								{t('back_btn')}
							</Button>
						</Grid>
						<Grid item>
							<Button
								color="primary"
								variant="contained"
								loading={creating}
								disabled={!confirmCheck}
								onClick={handleCreate}
							>
								{t('create_btn')}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</>
			}
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		creating: state.discography.creating,
		selectedRealm: state.realm.selected,
		artist: state.artist.artist,
		album: state.album.album,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchAlbum: (id) => dispatch(fetchAlbum(id)),
		create: (albumId) => dispatch(createRelease(albumId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Review);
