import React, {useEffect, useState} from 'react';
import { Grid }                     from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import Deliverables              from './Deliverables';
import Toolbar                   from '../../common/typography/Toolbar';
import PriceStep                 from './PriceStep';
import Button                    from '../../common/inputs/Button';
import RichAlert                 from '../../common/feeback/RichAlert';
import ProductVisibilitySelector from '../../common/inputs/ProductVisibilitySelector';
import PutOnSale                 from './PutOnSale';
import { connect }               from 'react-redux';
import { isObject }              from '../../utils/misc';
import { getAttribute }          from '../../utils/product';
import FormRequiredField         from '../../common/feeback/FormRequiredField';

const Selling = ({ creating, attributes, setAttributes, handleBack, handleCreate, deliverables, setDeliverables, price, setPrice, originalPrice, setOriginalPrice, currency, setCurrency, isDiscount, setIsDiscount, setIsVatIncluded, isVatIncluded, onSale, setOnSale }) => {
	const { t } = useTranslation();

	const set = key => e => {
		const keys = key.split('.');
		const family = attributes.values[keys[0]];
		if (Array.isArray(e)) {
			family[keys[1]] = e;
		} else {
			if (family[keys[1]].length < 1) family[keys[1]].push({
				Language__: null,
				Realm__: null,
				Value: '',
			});
			family[keys[1]][0].Value = isObject(e) ? e.target.value : e;
		}

		setAttributes({ ...attributes });
	};

	const [isLowRate, setIsLowRate] = useState(true);

	useEffect(() => {
		for (let i = 0; i < deliverables.length; i++) {
			if(['files'].includes(deliverables[i].Style)) {
				setIsLowRate(false)
				return;
			}
		}
		setIsLowRate(true)
	}, [attributes, deliverables])


	return (
		<Grid container spacing={3}>

			<Grid item xs={12}>
				<Deliverables
					disabled={creating}
					deliverables={deliverables}
					setDeliverables={setDeliverables}
				/>
			</Grid>

			<Grid item xs={12}>
				<Toolbar title={t('product_price')}/>
			</Grid>

			<Grid item xs={12}>
				<PriceStep
					disabled={creating}
					currency={currency}
					setCurrency={setCurrency}
					originalPrice={originalPrice}
					setOriginalPrice={setOriginalPrice}
					price={price}
					setPrice={setPrice}
					isDiscount={isDiscount}
					setIsDiscount={setIsDiscount}
					isVatIncluded={isVatIncluded}
					setIsVatIncluded={setIsVatIncluded}
					isLowRate={isLowRate}
				/>
			</Grid>

			<Grid item xs={12}>
				<Toolbar title={t('product_visibility')}/>
			</Grid>
			<Grid item xs={12}>
				<RichAlert severity='info'>
					<Trans i18nKey='product_visibility_info'>
						sample<br/>
						<strong>sample</strong>
					</Trans>
				</RichAlert>
			</Grid>

			<FormRequiredField/>

			<Grid item xs={12}>
				<ProductVisibilitySelector
					disabled={creating}
					required
					setValue={e => set('Native.Visible')(e)}
					value={getAttribute(attributes, 'Native.Visible')[0].Value}
				/>
			</Grid>

			<Grid item xs={12}>
				<PutOnSale
					disabled={creating}
					price={price}
					deliverables={deliverables}
					attributes={attributes}
					onSale={onSale}
					setOnSale={setOnSale}
				/>
			</Grid>

			<Grid item xs={12}>
				<Grid container justify='space-between' spacing={3}>

					<Grid item>
						<Button
							disabled={creating}
							onClick={handleBack}
							variant={'outlined'}
							color='primary'
						>
							{t('back_btn')}
						</Button>
					</Grid>

					<Grid item>
						<Button
							onClick={handleCreate}
							variant='contained'
							color='primary'
							disabled={creating}
							loading={creating}
						>
							{t('create_btn')}
						</Button>
					</Grid>
				</Grid>
			</Grid>

		</Grid>
	);
};
const mapStateToProps = (state) => {
	return {
		creating: state.product.creating,
	};
};

export default connect(mapStateToProps)(Selling);
