import {Trans, useTranslation} from 'react-i18next';
import Dialog                  from '@material-ui/core/Dialog';
import DialogTitle             from '@material-ui/core/DialogTitle';
import DialogContent           from '@material-ui/core/DialogContent';
import DialogContentText       from '@material-ui/core/DialogContentText';
import Grid                    from '@material-ui/core/Grid';
import DialogActions           from '@material-ui/core/DialogActions';
import Button                  from '../inputs/Button';
import {connect}               from 'react-redux';
import React, {useState}       from 'react';
import RichAlert               from '../feeback/RichAlert';
import TextField               from '@material-ui/core/TextField';
import {DeleteForever}         from '@material-ui/icons';
import {deleteSite}            from '../../../store/actions/MusicAction';

const DeleteDialog = ({open, setOpen, deleting, deleteSite, artist, selectedRealm}) => {
	const {t} = useTranslation();
	const [name, setName] = useState();

	const handleClose = () => {
		setOpen(false);
	};

	const handleDelete = () => {
		deleteSite(selectedRealm.Realm__)
			.then(() => {
				handleClose()
				window.scrollTo({ top: 0, behavior: 'smooth' });
			});
	};

	return (
		<Dialog
			open={open}
			aria-labelledby={t('confirm_delete_website_title')}
			aria-describedby={t('confirm_delete_website_title')}
			fullWidth
		>
			<DialogTitle id="website-delete-title">{t('confirm_delete_website_title')}</DialogTitle>
			<DialogContent>
				<DialogContentText id="gallery-delete-description">
					<RichAlert severity="warning">
						<Trans i18nKey="confirm_delete_website_description">
							sample<br/>
							<strong>sample</strong>
						</Trans>
					</RichAlert>
				</DialogContentText>
				<Grid container justify="center" alignItems="center" spacing={2}>
					<Grid item xs={12}>
						<TextField
							label={t('artist_delete_confirm')}
							helperText={t('artist_delete_helperText')}
							fullWidth
							value={name}
							onChange={(e) => setName(e.target.value)}
							variant="outlined"
							disabled={deleting}
						/>
					</Grid>
				</Grid>
			</DialogContent>

			<DialogActions>
				<Button variant="contained" color="primary" autoFocus onClick={handleClose} disabled={deleting}>
					{t('back_btn')}
				</Button>
				<Button
					color="secondary"
					loading={deleting}
					disabled={deleting || name !== artist.Name}
					endIcon={<DeleteForever/>}
					onClick={handleDelete}>
					{t('delete_artist_confirm_btn')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const mapStateToProps = (state) => {
	return {
		artist: state.artist.artist,
		deleting: state.music.deleting,
		selectedRealm: state.realm.selected,
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		deleteSite: (realmId) => dispatch(deleteSite(realmId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDialog);
