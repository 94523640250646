import React from 'react';
import {Cell, Pie as RechartsPie, PieChart, ResponsiveContainer, Sector, Text} from "recharts";
import useTheme from "@material-ui/core/styles/useTheme";
import {useTranslation} from "react-i18next";
import uuid from "../../../../utils/uuid";

const RADIAN = Math.PI / 180;

const Pie = ({stats, getName = undefined, getCount = undefined, active = false, activeIndex, setActiveIndex}) => {
    const theme = useTheme();
    const {t} = useTranslation();

    const renderCustomizedLabel = (props) => {
        const {
            cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
            fill, name, Count, percent, payload
        } = props;


        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        const formattedName = getName !== undefined ?( getName(payload) ?? 'N/A') : (name ?? 'N/A')

        return (
            <g>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                {!active && <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>}
                {!active && <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>}
                {!active && <Text scaleToFit x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor}
                                  fill={theme.palette.text.primary}>{formattedName}</Text>}
                {!active && <Text scaleToFit x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor}
                                  fill={theme.palette.text.primary}>{getCount !== undefined ? getCount(Count) : t('stats_fanclub_users', {count: Count})}</Text>}
                {!active && <Text scaleToFit x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={32} textAnchor={textAnchor}
                                  fill={theme.palette.text.secondary}>
                    {`(${(percent * 100).toFixed(2)}%)`}
                </Text>}

                {active && <Text scaleToFit x={cx} y={cy - outerRadius - 10 - 15} dy={8} textAnchor="middle"
                                 fill={theme.palette.text.primary}>{formattedName}</Text>}

                {active && <Text scaleToFit x={cx} y={cy} dy={8} textAnchor="middle" fill={theme.palette.text.primary}>
                    {getCount !== undefined ? getCount(Count) : t('stats_fanclub_users', {count: Count})}
                </Text>}
                {active &&
                <Text scaleToFit x={cx} y={cy + 20} dy={8} textAnchor="middle" fill={theme.palette.text.secondary}>
                    {`(${(percent * 100).toFixed(2)}%)`}
                </Text>}

            </g>
        )
    };

    const onPieEnter = (data, index) => {
        setActiveIndex(index)
    };

    return (
        <ResponsiveContainer width="100%" height={300}>
            <PieChart>
                <RechartsPie
                    isAnimationActive={false}
                    data={stats}
                    activeIndex={active ? activeIndex : undefined}
                    activeShape={active ? renderCustomizedLabel : undefined}
                    label={active ? undefined : renderCustomizedLabel}
                    dataKey="Count"
                    innerRadius={60}
                    outerRadius={80}
                    paddingAngle={5}
                    fill={theme.palette.primary.main}
                    onMouseEnter={active ? onPieEnter : undefined}
                >
                    {
                        stats.map((s) => <Cell key={uuid()} fill={'fill' in s ? s.fill : theme.palette.primary.main}/>)
                    }
                </RechartsPie>
            </PieChart>
        </ResponsiveContainer>
    );
};

export default Pie;
