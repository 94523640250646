import CreateFanclub from "../../../pages/fanclub/Create";
import {getFanclubWizardRoute} from "./factory/wizard";

export default [
    {
        path: getFanclubWizardRoute(),
        component: CreateFanclub,
        exact: true,
        type: 'route',
        loginRequired: true,
    },
]