import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import {useTranslation} from "react-i18next";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import uuid from "../../utils/uuid";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import DescriptionIcon from '@material-ui/icons/Description';
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import {downloadUrl} from "../../utils/dowloadUrl";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";

const AttachmentDialog = ({open, setOpen, fileAttachments, partAttachments}) => {
    const {t} = useTranslation();
    const handleClose = () => {
        setOpen(false);
    };

    const Attachment = ({attachment}) => {
        const isImage = attachment.Mime.startsWith("image");

        return (
            <ListItem button onClick={() => {
                downloadUrl(attachment.Url_Download ? attachment.Url_Download : attachment.Url)
            }}>
                <ListItemAvatar>
                    {isImage && <Avatar src={attachment.Url}/>}
                    {!isImage && <Avatar>
                        <DescriptionIcon/>
                    </Avatar>}
                </ListItemAvatar>
                <ListItemText primary={attachment.Filename ? attachment.Filename : attachment.Url}/>
            </ListItem>
        )
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="attachment-dialog-title" open={open}>
            <DialogTitle id="attachment-dialog-title">{t('attachments_title')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="attachment-dialog-desc">
                    {t('attachments_desc')}
                </DialogContentText>
            </DialogContent>
            <List>
                {fileAttachments.map(f => <Attachment key={uuid()} attachment={f}/>)}
                {partAttachments.map(f => <Attachment key={uuid()} attachment={f}/>)}
            </List>
        </Dialog>
    );
};

export default AttachmentDialog;