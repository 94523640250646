import {
    QUESTION_CREATE,
    QUESTION_CREATE_DONE,
    QUESTION_DELETE,
    QUESTION_DELETE_DONE,
    QUESTION_LIST_FETCH,
    QUESTION_LIST_FETCH_DONE,
    QUESTION_SET_FILTERS,
    QUESTION_SET_PAGING,
    QUESTION_STATS_FETCH,
    QUESTION_STATS_FETCH_DONE,
    QUESTION_UPDATE,
    QUESTION_UPDATE_DONE
} from "../../reducers/planner/QuestionReducer";
import {rest} from "@karpeleslab/klbfw";
import {handleError} from "../../../components/utils/apiErrorHandler";
import {success} from "../system/ToastAction";

export const setQuestionsPaging = (newPaging) => {
    return (dispatch, getState) => {
        dispatch({type: QUESTION_SET_PAGING, paging: {...getState().question.questionsPaging, ...newPaging}});
    }
};

export const setQuestionsFilters = (newFilters) => {
    return (dispatch, getState) => {
        dispatch({type: QUESTION_SET_FILTERS, filters: newFilters});
    }
};

export const fetchQuestions = (eventId) => {
    return (dispatch, getState) => {
        dispatch({type: QUESTION_LIST_FETCH});

        const params = {
            ...getState().question.questionsFilters,
            ...getState().question.questionsPaging,
        };

        return rest('Planner/Event/' + eventId + '/Question', 'GET', params)
            .then(data => {
                dispatch({type: QUESTION_LIST_FETCH_DONE, questions: data.data, paging: data.paging});
                return data.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const createQuestion = (eventId, label, type, visible, index, options = null) => {
    return (dispatch, getState) => {
        dispatch({type: QUESTION_CREATE});

        const params = {
            'Index': index,
            'Question': label,
            'Type': type,
            'Visible': visible,
            'Options': options,
        };

        return rest('Planner/Event/' + eventId + '/Question', 'POST', params)
            .then(data => {
                success('question_create_success');
                dispatch({type: QUESTION_CREATE_DONE, question: data.data});
                return data.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const deleteQuestion = (id) => {
    return (dispatch, getState) => {
        dispatch({type: QUESTION_DELETE});

        return rest('Planner/Event/Question/' + id, 'DELETE')
            .then(d => {
                dispatch({type: QUESTION_DELETE_DONE});
                success('question_delete_success');
                return d;
            }).catch((err) => {
                dispatch({type: QUESTION_DELETE_DONE});
                handleError(getState, dispatch, err)
            });
    }
};

export const updateQuestion = (id, data) => {
    return (dispatch, getState) => {
        dispatch({type: QUESTION_UPDATE});
        return rest('Planner/Event/Question/' + id, 'PATCH', data)
            .then((d) => {
                success('question_update_success');
                dispatch({type: QUESTION_UPDATE_DONE, schedule: d.data});
                return d.data;
            }).catch((err) => {
                dispatch({type: QUESTION_UPDATE_DONE});
                handleError(getState, dispatch, err)
            });
    }
};

export const fetchQuestionStats = (eventId) => {
    return (dispatch, getState) => {
        dispatch({type: QUESTION_STATS_FETCH})
        return rest('Planner/Event/' + eventId + '/Question:stats')
            .then(d => {
                dispatch({type: QUESTION_STATS_FETCH_DONE, stats: d.data})
                return d.data;
            }).catch((err) => {
                handleError(getState, dispatch, err)
            });
    }
}