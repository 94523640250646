import {USER_LOGOUT}  from '../system/UserReducer';
import {COMMON_RESET} from '../CommonReducer';

const initState = () => {
	return {
		shippingsPendingCount: 0,
		shippingsProcessingCount: 0,
		selectedShippings: {},

		shippingsLoading: false,
		shippings: [],
		shippingsFilters: {
			Status: 'pending'
		},
		shippingsPagingCount: 0,
		shippingsPaging: {
			page_no: 1,
			results_per_page: 10
		},

		changingStatus: false,
		sendingShipping: false,

		locationToImages: false,
		exportingShipping: false,
		importingShipping: false,

		loadingProviders: false,
		providers: [],

		grouping: false,
		ungrouping: false,
	};
};

export const SHIPPING_EXPORT = 'SHIPPING_EXPORT';
export const SHIPPING_EXPORT_DONE = 'SHIPPING_EXPORT_DONE';

export const SHIPPING_IMPORT = 'SHIPPING_IMPORT';
export const SHIPPING_IMPORT_DONE = 'SHIPPING_IMPORT_DONE';

export const SHIPPING_REFRESH_PENDING_DONE = 'SHIPPING_REFRESH_PENDING_DONE';
export const SHIPPING_REFRESH_PROCESSING_DONE = 'SHIPPING_REFRESH_PROCESSING_DONE';
export const SHIPPING_LOCATION_TO_IMAGE = 'SHIPPING_LOCATION_TO_IMAGE';
export const SHIPPING_LOCATION_TO_IMAGE_DONE = 'SHIPPING_LOCATION_TO_IMAGE_DONE';

export const SHIPPING_LIST_FETCH = 'SHIPPING_LIST_FETCH';
export const SHIPPING_LIST_FETCH_DONE = 'SHIPPING_LIST_FETCH_DONE';

export const SHIPPING_SET_PAGING = 'SHIPPING_SET_PAGING';
export const SHIPPING_SET_FILTERS = 'SHIPPING_SET_FILTERS';

export const SHIPPING_SET_SELECTED_SHIPPINGS = 'SHIPPING_SET_SELECTED_SHIPPINGS';

export const SHIPPING_SET_STATUS = 'SHIPPING_SET_STATUS';
export const SHIPPING_SET_STATUS_DONE = 'SHIPPING_SET_STATUS_DONE';

export const SHIPPING_SEND = 'SHIPPING_SEND';
export const SHIPPING_SEND_DONE = 'SHIPPING_SEND_DONE';

export const SHIPPING_PROVIDERS_FETCH = 'SHIPPING_PROVIDERS_FETCH';
export const SHIPPING_PROVIDERS_FETCH_DONE = 'SHIPPING_PROVIDERS_FETCH_DONE';

export const SHIPPING_GROUP = 'SHIPPING_GROUP';
export const SHIPPING_GROUP_DONE = 'SHIPPING_GROUP_DONE';

export const SHIPPING_UNGROUP = 'SHIPPING_UNGROUP';
export const SHIPPING_UNGROUP_DONE = 'SHIPPING_UNGROUP_DONE';

const shippingReducer = (state = initState(), action) => {
	switch (action.type) {
		case USER_LOGOUT:
		case COMMON_RESET:
			return initState();
		case SHIPPING_GROUP:
			return {...state, grouping: true};
		case SHIPPING_GROUP_DONE:
			return {...state, grouping: false};
		case SHIPPING_UNGROUP:
			return {...state, ungrouping: true};
		case SHIPPING_UNGROUP_DONE:
			return {...state, ungrouping: false};
		case SHIPPING_PROVIDERS_FETCH:
			return {...state, loadingProviders: true};
		case SHIPPING_PROVIDERS_FETCH_DONE:
			return {...state, loadingProviders: false, providers: action.providers};
		case SHIPPING_IMPORT:
			return {...state, importingShipping: true};
		case SHIPPING_IMPORT_DONE:
			return {...state, importingShipping: false};
		case SHIPPING_EXPORT:
			return {...state, exportingShipping: true};
		case SHIPPING_EXPORT_DONE:
			return {...state, exportingShipping: false};
		case SHIPPING_LOCATION_TO_IMAGE:
			return {...state, locationToImages: true};
		case SHIPPING_LOCATION_TO_IMAGE_DONE:
			return {...state, locationToImages: false};
		case SHIPPING_SEND:
			return {...state, sendingShipping: true};
		case SHIPPING_SEND_DONE:
			return {...state, sendingShipping: false};
		case SHIPPING_SET_STATUS:
			return {...state, changingStatus: true};
		case SHIPPING_SET_STATUS_DONE:
			return {...state, changingStatus: false};
		case SHIPPING_SET_SELECTED_SHIPPINGS:
			return {...state, selectedShippings: action.selected};
		case SHIPPING_REFRESH_PENDING_DONE:
			return {...state, shippingsPendingCount: action.count};
		case SHIPPING_REFRESH_PROCESSING_DONE:
			return {...state, shippingsProcessingCount: action.count};
		case SHIPPING_LIST_FETCH:
			return {...state, shippingsLoading: true};
		case SHIPPING_LIST_FETCH_DONE:
			return {
				...state,
				shippingsLoading: false,
				shippings: action.shippings,
				shippingsPagingCount: action.paging.count
			};

		case SHIPPING_SET_FILTERS :
			return {...state, shippingsFilters: action.filters};
		case SHIPPING_SET_PAGING :
			return {...state, shippingsPaging: action.paging};
		default:
			return state;
	}

};

export default shippingReducer;