import React, {useEffect, useState}   from 'react';
import {useTranslation}               from 'react-i18next';
import Dialog                         from '@material-ui/core/Dialog';
import DialogTitle                    from '@material-ui/core/DialogTitle';
import DialogContent                  from '@material-ui/core/DialogContent';
import Grid                           from '@material-ui/core/Grid';
import TextField                      from '@material-ui/core/TextField';
import DialogActions                  from '@material-ui/core/DialogActions';
import Button                         from '../../../inputs/Button';
import {connect}                      from 'react-redux';
import {Box}                          from '@material-ui/core';
import {createShippingFee}            from '../../../../../store/actions/store/ShippingFeeAction';
import {useHistory}                   from 'react-router-dom';
import {getStoreShippingFeeEditRoute} from '../../../../../router/routes/store/factory/store';
import FormRequiredField              from '../../../feeback/FormRequiredField';

const CreateDialog = ({open, setOpen, creating, create}) => {
	const {t} = useTranslation();
	const [name, setName] = useState('');
	const [type, setType] = useState('weight');
	const history = useHistory();

	const [saveDisabled, setSaveDisabled] = useState(true);

	useEffect(() => {
		setSaveDisabled((name.trim() === '' || type.trim() === ''));
	}, [name, type, setSaveDisabled]);

	const handleClose = () => {
		setOpen(false);
	};

	const handleCreate = (e) => {
		e.preventDefault();
		create({Name: name.trim(), Type: type})
			.then((s) => history.push(getStoreShippingFeeEditRoute(s.Catalog_Deliverable_Fee__)))
			.then(handleClose);
	};

	return (
		<Dialog
			disableEnforceFocus
			open={open}
			aria-labelledby={t('create_shipping_fee')}
			aria-describedby={t('create_shipping_fee')}
			onEnter={() => {
				setName('');
				setType('fix');
			}}
		>
			<form onSubmit={handleCreate}>
				<DialogTitle>{t('create_shipping_fee')}</DialogTitle>
				<DialogContent>
					<Box py={2}>
						<Grid container spacing={3}>
							<FormRequiredField/>
							<Grid item xs={12}>
								<TextField
									label={t('shipping_fee_name')}
									fullWidth variant="outlined" value={name}
									required
									disabled={creating}
									onChange={e => setName(e.target.value)}
								/>
							</Grid>
							{/*<Grid item xs={12}>
								<ShippingFeeTypeSelector
									setValue={setType}
									value={type}
									required
									disabled={creating}
								/>
							</Grid>}*/}
						</Grid>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button color="default" onClick={handleClose} disabled={creating}>
						{t('close_btn')}
					</Button>

					<Button color="primary" type="submit" variant="contained"
					        disabled={creating || saveDisabled}
					        loading={creating}>
						{t('create_btn')}
					</Button>
				</DialogActions>
			</form>
		</Dialog>

	);
};
const mapStateToProps = (state) => {
	return {
		creating: state.shippingFee.creating,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		create: (data) => dispatch(createShippingFee(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDialog);