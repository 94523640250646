import React, {useState} from 'react';
import Grid              from '@material-ui/core/Grid';
import AppBarTitle       from '../../../components/common/typography/AppBarTitle';
import {useTranslation}  from 'react-i18next';
import {connect}         from 'react-redux';
import SelectRealm       from '../../SelectRealm';
import AddIcon           from '@material-ui/icons/Add';
import Table             from '../../../components/common/planner/schedule/table/Table';
import CreateDialog      from '../../../components/common/planner/schedule/CreateDialog';
import HasRight          from '../../../components/Route/HasRight';
import Toolbar           from '../../../components/common/typography/Toolbar';
import Button            from '../../../components/common/inputs/Button';
import ScheduleActivator from '../../../components/common/planner/ScheduleActivator';

const List = ({planner, selectedRealm}) => {
	const {t} = useTranslation();
	const [showCreate, setShowCreate] = useState(false);

	if (!selectedRealm || !planner) return <SelectRealm/>;

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('schedules_title')}/>
			<Grid item xs={12}>
				<Toolbar title={t('schedules_title')}>
					<HasRight manager hideLoading>
						<Button endIcon={<AddIcon/>} onClick={e => setShowCreate(true)} variant="contained"
						        color="primary">
							{t('create_btn')}
						</Button>
					</HasRight>
				</Toolbar>
			</Grid>
			<ScheduleActivator/>
			<Grid item xs={12}>
				<Table/>
			</Grid>
			{planner && <CreateDialog setOpen={setShowCreate} open={showCreate} cmsId={planner.Content_Cms__}/>}
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		planner: state.schedule.planner,
	};
};
export default connect(mapStateToProps)(List);