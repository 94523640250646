import React from 'react';
import TableRow from "@material-ui/core/TableRow";
import Skeleton from "@material-ui/lab/Skeleton";
import TableCell from "@material-ui/core/TableCell";
import EditIcon from "@material-ui/icons/Edit";
import {useTranslation} from "react-i18next";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Price from "../../../../data/Price/Price";
import Button from "../../../../inputs/Button";

const Row = ({seat, loading, onSelected, vat}) => {
    const {t} = useTranslation();
    const displaySeat = (!loading && seat);

    return (
        <TableRow>
            <TableCell>
                {!displaySeat && <Skeleton variant="circle" width={40} height={40}/>}
                {(displaySeat && seat.Visible === 'Y') && <VisibilityIcon/>}
                {(displaySeat && seat.Visible === 'N') && <VisibilityOffIcon/>}
            </TableCell>
            <TableCell>
                {!displaySeat && <Skeleton/>}
                {(displaySeat) && <span>{seat.Seat_Name}</span>}
            </TableCell>
            <TableCell>
                {!displaySeat && <Skeleton/>}
                {(displaySeat) && <span>{seat.Description}</span>}
            </TableCell>
            <TableCell>
                {!displaySeat && <Skeleton/>}
                {(displaySeat) &&
                <span>{t('seat_capacity_summary', {count: seat.Count, capacity: seat.Capacity})}</span>}
            </TableCell>
            <TableCell>
                {!displaySeat && <Skeleton/>}
                {(displaySeat) && <span>{
                    <Price
	                    value={parseFloat(seat.Price.value)}
	                    currency={seat.Currency__}
	                    priceIncludeVat={!!seat.Price_Flags.vat_included}
	                    inclVat={true}
	                    vat={vat}
                    />
                }</span>}
            </TableCell>

            <TableCell>
                {!displaySeat && <Skeleton/>}
                {(displaySeat) && <span>{seat.Currency__}</span>}
            </TableCell>
            <TableCell align="right">
                {!displaySeat && <Skeleton/>}
                {(displaySeat) && <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onSelected(seat)}
                    startIcon={<EditIcon/>}
                >
                    {t('edit_btn')}
                </Button>}

            </TableCell>
        </TableRow>
    );
};

export default Row;
