import React                                  from 'react';
import ReactMenu                              from '@material-ui/core/Menu';
import {reset}                                from '../../../store/actions/CommonAction';
import {fetchAndSelect}                       from '../../../store/actions/RealmAction';
import {connect}                              from 'react-redux';
import {ListItemIcon, ListItemText, MenuItem} from '@material-ui/core';
import {PersonAdd, Star, StarOutline}         from '@material-ui/icons';
import {getPXLDashboardRoute}                 from '../../../router/routes/pxl/factory/general';
import {useHistory}                           from 'react-router-dom';
import {useTranslation}                       from 'react-i18next';
import {getArtistWizardRoute}                 from '../../../router/routes/artist/factory/wizard';

const RealmMenu = ({anchorEl, setAnchorEl, loading, selected, list, select, reset}) => {
	const open = Boolean(anchorEl);
	const history = useHistory();
	const {t} = useTranslation();

	const handleClose = () => {
		setAnchorEl(null);
	};

	const realmChangeHandler = (v) => {
		if (!v) return;
		const filtered = list.filter((f) => f.Realm__ === v);
		reset();

		if (filtered.length > 0) {
			select(filtered[0]);
			handleClose();
			history.push(getPXLDashboardRoute());
		} else {
			console.log('Trying to select unknown realm value : ' + v + ' ');
		}
	};

	return (
		<ReactMenu
			id="menu-appbar"
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			keepMounted
			transformOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			PaperProps={{
				style: {
					maxHeight: '500px',
				},
			}}
			open={open}
			onClose={handleClose}
		>

			{list.map(realm => {
				return (
					<MenuItem key={realm.Realm__} selected={selected?.Realm__ === realm.Realm__}
					          onClick={() => realmChangeHandler(realm.Realm__)}>
						<ListItemIcon>
							{selected?.Realm__ !== realm.Realm__ && <StarOutline fontSize="small"/>}
							{selected?.Realm__ === realm.Realm__ && <Star color="primary" fontSize="small"/>}
						</ListItemIcon>
						<ListItemText primary={realm.Name}/>
					</MenuItem>
				);
			})}
			<MenuItem onClick={() => {
				history.push(getArtistWizardRoute());
				handleClose();
			}}>
				<ListItemIcon>
					<PersonAdd/>
				</ListItemIcon>
				<ListItemText primary={t('menu_artist_wizard')}/>
			</MenuItem>
		</ReactMenu>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.realm.loading,
		list: state.realm.realms,
		selected: state.realm.selected,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		reset: () => dispatch(reset()),
		select: (realm) => dispatch(fetchAndSelect(realm.Realm__))
	};
};


export default connect(mapStateToProps, mapDispatchToProps)(RealmMenu);