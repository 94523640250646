import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import {useTranslation} from "react-i18next";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import {useHistory} from "react-router-dom";
import {getSiteEventLiveRoute} from "../../../../../router/routes/site/factory/planner";
import Button from "../../../inputs/Button";

const GoLiveDialog = ({event, open, setOpen}) => {
    const {t} = useTranslation();
    const history = useHistory();

    const handleClose = () => {
        setOpen(false);
    };

    const handleGoLive = (e) => {
        e.preventDefault();
        history.push(getSiteEventLiveRoute(event.Planner_Event__))

    };

    return (
        <Dialog
            open={open}
            aria-labelledby={t('go_live')}
            aria-describedby={t('go_live')}
        >

            <DialogTitle id="access-revoke-title">{t('go_live')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="access-revoke-desc">
                    {t('go_live_desc')}
                    <br/>
                    <Typography variant="body1" color="textPrimary" component="span">{t('are_you_sure')}</Typography>
                </DialogContentText>


            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={handleClose}>
                    {t('close_btn')}
                </Button>
                <Button color="primary" type='submit' variant='contained' onClick={handleGoLive}>
                    {t('go_live_btn')}
                </Button>
            </DialogActions>
        </Dialog>

    );
};

export default GoLiveDialog;