import React              from 'react';
import { Grid }           from '@material-ui/core';
import PriceFeeNotice     from '../../common/feeback/PriceFeeNotice';
import { useTranslation } from 'react-i18next';
import Price              from '../../common/inputs/Price';
import Currencies         from '../../common/inputs/Currencies';
import FormControlLabel   from '@material-ui/core/FormControlLabel';
import Checkbox           from '@material-ui/core/Checkbox';

const PriceStep = ({ disabled, originalPrice, setOriginalPrice, price, setPrice, currency, setCurrency, isDiscount, setIsDiscount, setIsVatIncluded, isVatIncluded, isLowRate }) => {
	const { t } = useTranslation();

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<PriceFeeNotice low={isLowRate}/>
			</Grid>
			<Grid item lg={6} md={12}>
				<Price
					disabled={disabled}
					preferredCurrency={currency}
					vatIncluded={isVatIncluded}
					setVatIncluded={setIsVatIncluded}
					variant='outlined'
					fullWidth
					value={price}
					onValueChange={setPrice}
					label={t('price')}
				/>
			</Grid>

			<Grid item lg={6} md={12}>
				<Currencies
					disabled={disabled}
					onChange={(e) => setCurrency(e.target.value)}
					value={currency}
					inputProps={{
						variant: 'outlined',
						fullWidth: true,
						required: true,
						disabled: disabled
					}}
				/>
			</Grid>

			{isDiscount && <Grid item xs={12}>
				<Price
					disabled={disabled}
					preferredCurrency={currency}
					variant='outlined'
					fullWidth
					value={originalPrice}
					onValueChange={setOriginalPrice}
					label={t('original_price')}
					helperText={t('original_price_helper')}
					helperEnabled={!isVatIncluded}
				/>
			</Grid>}

			<Grid item xs={12}>
				<FormControlLabel
					disabled={disabled}
					control={<Checkbox
						checked={isDiscount}
						color='primary'
						name='discount'
						disabled={disabled}
						onChange={(e) => {
							setIsDiscount(e.target.checked);
						}}
					/>}
					label={t('price_discount_label')}
				/>
			</Grid>
		</Grid>
	);
};

export default PriceStep;
