import React from 'react';
import Graphic from '../../../resources/images/people.svg'
import {useTranslation} from "react-i18next";
import Empty from "../../common/feeback/State/Empty";
import AddIcon from "@material-ui/icons/Add";


const EmptyMember = ({showCreate, onClick}) => {
    const {t} = useTranslation();

    const action = {
        title: t('create_btn'),
        endIcon: <AddIcon/>,
        onClick: onClick,
    };

    return (
        <Empty
            title={t('artist_wizard_members_empty_title')}
            textI18Key={'artist_wizard_members_empty_text'}
            action={showCreate ? action : null}
            graphic={Graphic}
        />
    );
};

export default EmptyMember;