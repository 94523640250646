import React, { useEffect, useState }            from 'react';
import { connect }                               from 'react-redux';
import Grid                                      from '@material-ui/core/Grid';
import { useTranslation }                        from 'react-i18next';
import IconButton                                from '@material-ui/core/IconButton';
import SearchIcon                                from '@material-ui/icons/Search';
import TextField                                 from '@material-ui/core/TextField';
import ClearIcon                                 from '@material-ui/icons/Clear';
import { useHistory, useLocation }               from 'react-router-dom';
import queryString                               from 'query-string';
import { setReleasesFilters, setReleasesPaging } from '../../../../../store/actions/discography/DiscographyAction';
import ReleasesTagsAutocomplete                  from '../../../inputs/ReleasesTagsAutocomplete';
import { arrayEquals }                           from '../../../../utils/misc';

const Filters = ({ setReleasesFilters, setReleasesPaging, filters }) => {
	const { t } = useTranslation();

	const location = useLocation();
	let params = queryString.parse(location.search);
	const [search, setSearch] = useState(filters?.Name?.['$anywhere'] || params.s);
	const [tags, setTags] = useState(filters?.Tag || (Array.isArray(params.t) ? params.t : (!!params.t ? [params.t] : [])));


	const history = useHistory();

	const handleSearchChange = e => {
		if (e.key !== 'Enter')
			return;

		filterHandler();
	};

	const filterHandler = (clear = false) => {
		let params = queryString.parse(location.search);
		delete params.s;
		delete params.t;
		if (!clear && search && search.trim()) params.s = search;
		if (!clear && tags?.length) {
			params.t = tags
		}
		params.p = 1;
		history.push(location.pathname + '?' + queryString.stringify(params));
	};

	useEffect(() => {
		let params = queryString.parse(location.search);
		let newFilters = null;
		if ((!!params.s && filters?.Name?.['$anywhere'] !== params.s)) {
			newFilters = { Name: { '$anywhere': params.s } };
		}

		if (!!params.t && !arrayEquals((filters?.t ?? []), params.t)) {
			newFilters = { ...(newFilters ?? {}), Tag: params.t };
		}

		if (newFilters !== null) {
			setReleasesFilters({ ...filters, ...newFilters });
			setReleasesPaging({ page_no: 1 });
			return;
		}

		if (!params.s || !params.t) {
			let tmp = { ...filters };
			if(!params.s) delete tmp.Name;
			if(!params.t) delete tmp.Tag;
			setReleasesFilters(tmp);
			setReleasesPaging({ page_no: 1 });
		}
		// eslint-disable-next-line
	}, [location]);

	const clearFiltersHandler = () => {
		setSearch('');
		setTags([]);
		filterHandler(true);
	};

	useEffect(() => {
		return () => {
			setReleasesFilters({});
		};
		// eslint-disable-next-line
	}, []);

	return (

		<Grid container item xs={12} spacing={3}>
			<Grid item xs={12} md={5}>
				<TextField
					fullWidth
					variant='outlined'
					value={search}
					onKeyPress={handleSearchChange}
					onChange={e => setSearch(e.target.value)}
					placeholder={t('placeholder_search_releases')}
				/>
			</Grid>
			<Grid item xs={12} md={5}>
				<ReleasesTagsAutocomplete
					value={tags}
					setValue={setTags}
				/>
			</Grid>

			<Grid item xs={12} md={2}>
				<IconButton
					aria-label={t('placeholder_search_releases')}
					onClick={() => filterHandler()}
					disabled={!(search || tags?.length > 0)}
				>
					<SearchIcon/>

				</IconButton>
				<IconButton
					aria-label={t('clear_filter_label')}
					disabled={!(search || tags?.length > 0)}
					onClick={clearFiltersHandler}
				>
					<ClearIcon/>

				</IconButton>
			</Grid>


		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		filters: state.record.recordsFilters,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setReleasesFilters: (params) => dispatch(setReleasesFilters(params)),
		setReleasesPaging: (params) => dispatch(setReleasesPaging(params)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
