import React, {Fragment, useState} from 'react';
import CardContent from "@material-ui/core/CardContent";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {CardActions} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import Grid from "@material-ui/core/Grid";
import ChangePlanDialog from "./ChangePlanDialog";
import {useTranslation} from 'react-i18next';
import HasRight from "../../../../Route/HasRight";
import Button from "../../../inputs/Button";

const useStyles = makeStyles(theme => ({
    productCard: {
        whiteSpace: 'pre-wrap',
        height: '100%'
    },
}));


const Plan = ({membershipUser, enableActions = true}) => {
    const classes = useStyles();
    const [changePlanModalOpen, setChangePlanModalOpen] = useState(false);
    const {t} = useTranslation();

    return (
        <Fragment>
            <Card className={classes.productCard} variant='outlined'>
                <CardContent>
                    {!membershipUser && <Skeleton/>}
                    {!membershipUser && <Skeleton height={190}/>}


                    {membershipUser && <Typography gutterBottom variant="h5"
                                                   component="h2">{membershipUser.Membership_Plan.Catalog_Product['Basic.Name']}</Typography>}
                    {membershipUser && <Typography variant="body2" color="textSecondary"
                                                   component="p">{membershipUser.Membership_Plan.Catalog_Product['Description.Short']}</Typography>}
                </CardContent>
                {enableActions && <CardActions>
                    <Grid container
                          direction="row"
                          justify="center"
                          alignItems="center">

                        <HasRight admin>
                            <Button
                                size='small'
                                variant='contained'
                                color="primary"
                                onClick={() => {
                                    setChangePlanModalOpen(true)
                                }}
                                startIcon={<EditIcon/>}>
                                {t('change_plan_btn')}
                            </Button>
                        </HasRight>

                    </Grid>
                </CardActions>
                }
            </Card>
            {enableActions && <ChangePlanDialog membershipUser={membershipUser} setOpen={setChangePlanModalOpen}
                                                open={changePlanModalOpen}/>}
        </Fragment>
    );
};

export default Plan;
