import {useAction}                           from '../../useBaseHooks';
import {useCallback} from 'react';
import {
	getContentCmsEntryImageHtmlEndpoint,
	getContentCmsEntryOrderTranslateEndpoint, getContentCmsEntryQuoteTranslateEndpoint
} from '../../endpoints/content/cms/entryEndpoints';

export const useContentCmsEntryImageHtml = (entryId) => {
	const [_doAction, loading] = useAction(getContentCmsEntryImageHtmlEndpoint(entryId), 'POST');

	const doAction = useCallback((image, variation = null, settingsOverride = {}) => {
		const params = {
			image: image,
		};
		if (variation) {
			params['variation'] = variation;
		}

		return _doAction(params, settingsOverride);
	}, []); //eslint-disable-line

	return [doAction, loading];
};

export const useContentCmsEntryOrderTranslate = (entryId) => {
	const [_doAction, loading] = useAction(getContentCmsEntryOrderTranslateEndpoint(entryId), 'POST');

	const doAction = useCallback((source_language, target_language, tier = 'standard', settingsOverride = {}) => {
		const params = {
			source_language: source_language,
			target_language: target_language,
			tier: tier,
		};

		return _doAction(params, settingsOverride);
	}, []); //eslint-disable-line

	return [doAction, loading];
};

export const useContentCmsEntryQuoteTranslate = (entryId) => {
	const [_doAction, loading] = useAction(getContentCmsEntryQuoteTranslateEndpoint(entryId), 'POST');

	const doAction = useCallback((source_language, target_language, tier = 'standard', settingsOverride = {}) => {
		const params = {
			source_language: source_language,
			target_language: target_language,
			tier: tier,
		};

		return _doAction(params, settingsOverride);
	}, []); //eslint-disable-line

	return [doAction, loading];
};