import React, {Fragment, useEffect} from 'react';
import {Title} from "../../common/typography/Title";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Plan from "./Plan";
import {fetchVAT} from "../../../store/actions/OrderAction";
import {connect} from "react-redux";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import {useTranslation} from "react-i18next";
import Button from "../../common/inputs/Button";

const Validate = ({handleBack, handleNext, plans, stripeAccount, vat, fetchVat, address}) => {
    const {t} = useTranslation();
    useEffect(() => {
        fetchVat()
    }, [fetchVat]);

    return (
        <Fragment>
            <Grid container spacing={3} justify='center'>
                <Grid item>
                    <Title>{t('fanclub_wizard_step_validate_title')}</Title>
                </Grid>
            </Grid>


            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="body1" color="textSecondary" component="p">
                        {t('fanclub_wizard_step_validate_desc')}
                    </Typography>
                </Grid>

                {stripeAccount &&
                <Grid item xs={12}>
                    <Typography variant="h5" color="textPrimary" component="h5">
                        {t('fanclub_wizard_step_validate_stripe_title')}
                    </Typography>
                </Grid>
                }

                {stripeAccount &&
                <Grid item xs={12}>
                    <Grid container direction='column' spacing={3}>
                        <Grid item xs={12} md={4}>
                            <Card variant='outlined'>
                                <CardHeader
                                    title={stripeAccount.Name}
                                    subheader={t('fanclub_wizard_step_validate_stripe_desc')}
                                />
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                }

                <Grid item xs={12}>
                    <Typography variant="h5" color="textPrimary" component="h5">
                        {t('fanclub_wizard_step_validate_plans_title')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={3}>

                        {plans.map((m, i) => {
                                return (
                                    <Grid item xs={6} md={4} key={'list-plans-' + i}>
                                        <Plan plan={m} viewOnly={true} vat={vat ? vat.Rate : null}/>
                                    </Grid>)
                            }
                        )}

                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container justify='center' spacing={3}>

                        <Grid item>
                            <Button size='large' onClick={handleNext} variant='contained' color="primary">
                                {t('fanclub_wizard_confirm_btn')}
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>


                <Grid item xs={12}>
                    <Grid container justify='space-between' spacing={3}>

                        <Grid item xs={1}>
                            <Button onClick={handleBack} variant={'outlined'}
                                    color="primary">
                                {t('back_btn')}
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        vat: state.order.vat
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchVat: () => dispatch(fetchVAT())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Validate);
