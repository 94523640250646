import {Link as RouterLink} from "react-router-dom";
import MUILink from '@material-ui/core/Link';
import React from 'react';

const Link = React.forwardRef((props, ref) => {
    const {children, ...rest} = props;
    return (
        <MUILink component={RouterLink} ref={ref} {...rest}>
            {children}
        </MUILink>
    );
});

export default Link;