import {COMMON_RESET} from "./CommonReducer";
import {USER_LOGOUT}  from './system/UserReducer';

const initState = () => {
    return {
        billingsLoading: true,
        billingsFilters: {
            sort: {'Created': 'DESC'}
        },
        billings: [],
        billingsPagingCount: 0,
        billingsPaging: {
            page_no: 1,
            results_per_page: 10
        },
        
        billing: null,
        billingLoading: true,
    }
};

export const BILLING_FETCH_ONE = 'BILLING_FETCH_ONE';
export const BILLING_FETCH_ONE_DONE = 'BILLING_FETCH_ONE_DONE';

export const BILLING_BILLINGS_FETCH = 'BILLING_BILLINGS_FETCH';
export const BILLING_BILLINGS_FETCH_DONE = 'BILLING_BILLINGS_FETCH_DONE';
export const BILLING_BILLINGS_SET_FILTERS = 'BILLING_BILLINGS_SET_FILTERS';
export const BILLING_BILLINGS_SET_PAGING = 'BILLING_BILLINGS_SET_PAGING';

const billingReducer = (state = initState(), action) => {
    switch (action.type) {
        case USER_LOGOUT:
            return {...initState()};
        case COMMON_RESET:
            return {...initState()};
        case BILLING_BILLINGS_SET_PAGING:
            return {...state, billingsPaging: action.paging};
        case BILLING_BILLINGS_SET_FILTERS:
            return {...state, billingsFilters: action.filters};
        case BILLING_BILLINGS_FETCH:
            return {...state, billingsLoading: true};
        case BILLING_BILLINGS_FETCH_DONE:
            return {
                ...state,
                billingsLoading: false,
                billings: action.billings,
                billingsPagingCount: action.paging.count
            };
        case BILLING_FETCH_ONE:
            return {...state, billing: null, billingLoading: true};
        case BILLING_FETCH_ONE_DONE:
            if(!action.billing)  return {...state, billingLoading: false, billing: null};
            return {...state, billingLoading: false, billing: action.billing};
        default:
            return state
    }
};

export default billingReducer