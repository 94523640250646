import React, {useEffect, useState} from 'react';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {Select} from "@material-ui/core";
import {useTranslation} from 'react-i18next';

const Ivk = ({onChange, inputProps = {}, value = undefined}) => {
    const selectLabel = React.useRef(null);
    const [selectLabelWidth, setLabelWidth] = useState(0);
    const {t} = useTranslation();

    useEffect(() => {
        setLabelWidth(selectLabel.current.offsetWidth);
    }, []);

    return (
        <FormControl {...inputProps}>
            <InputLabel ref={selectLabel} htmlFor='ivk-select'>{t('ivk_label')}</InputLabel>
            <Select
                native
                value={value}
                onChange={onChange}
                labelWidth={selectLabelWidth}
                inputProps={{
                    id: 'ivk-select',
                }}
            >
                <option/>
                <option value='instrumental'>{t('ivk_instrumental')}</option>
                <option value='vocal'>{t('ivk_vocal')}</option>
                <option value='karaoke'>{t('ivk_karaoke')}</option>
            </Select>

        </FormControl>
    );
};

export default Ivk;