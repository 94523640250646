import React, { Fragment, useState } from 'react';
import Grid                          from '@material-ui/core/Grid';
import Toolbar                       from '../../typography/Toolbar';
import { useTranslation }            from 'react-i18next';
import HasRight                      from '../../../Route/HasRight';
import AddIcon                       from '@material-ui/icons/Add';
import Button                        from '../../inputs/Button';
import { deliverableUploadFile }     from '../../../../store/actions/store/DeliverableAction';
import { connect }                   from 'react-redux';
import TableContainer                from '@material-ui/core/TableContainer';
import Table                         from '@material-ui/core/Table';
import TableHead                     from '@material-ui/core/TableHead';
import TableRow                      from '@material-ui/core/TableRow';
import TableCell                     from '@material-ui/core/TableCell';
import TableBody                     from '@material-ui/core/TableBody';
import defaultPicture                from '../../../../resources/images/landscape-image.svg';
import Avatar                        from '@material-ui/core/Avatar';
import { downloadUrl }               from '../../../utils/dowloadUrl';
import CloudDownloadIcon             from '@material-ui/icons/CloudDownload';
import IconButton                    from '@material-ui/core/IconButton';
import DeleteForeverIcon             from '@material-ui/icons/DeleteForever';
import DeleteFileDialog              from './DeleteFileDialog';
import EmptyFiles                    from './EmptyFile';
import LinearProgress                from '@material-ui/core/LinearProgress';

const Files = ({ deliverable, uploading, upload, progress }) => {
	const { t } = useTranslation();
	const inputFileRef = React.useRef(null);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);


	const handleFileInputChange = (e) => {
		if (e.target.files.length < 1) {
			return;
		}

		upload(deliverable.Catalog_Deliverable__, e.target.files[0]);
	};


	if (deliverable.Style !== 'files') return <Fragment/>;

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Toolbar title={t('deliverable_files')}>
					<HasRight manager>
						<input multiple={false} type='file'
						       style={{ display: 'none' }}
						       ref={inputFileRef}
						       onChange={handleFileInputChange}
						/>
						<Button
							variant='contained'
							color='primary'
							disabled={uploading}
							loading={uploading}
							endIcon={<AddIcon/>}
							onClick={() => {
								inputFileRef.current.click();
							}}
						>
							{t('add_btn')}
						</Button>
					</HasRight>
				</Toolbar>
			</Grid>
			{uploading && <Grid item xs={12}><LinearProgress variant='determinate' value={progress * 100}/></Grid>}
			<Grid item xs={12}>
				{deliverable.Files.length > 0 &&
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell style={{ width: '75px' }}/>
								<TableCell> {t('tablecell_deliverable_file_name')}</TableCell>
								<TableCell align='right'/>
							</TableRow>
						</TableHead>
						<TableBody>
							{deliverable.Files.map(f =>
								<TableRow>
									<TableCell style={{ width: '75px' }}>
										<Avatar variant='square' src={f.Drive_Item.Icon ?? defaultPicture}/>
									</TableCell>
									<TableCell> {f.Name}</TableCell>
									<TableCell align='right'>
										<IconButton onClick={() => downloadUrl(f.Drive_Item.Download_Url, f.Name)}>
											<CloudDownloadIcon/>
										</IconButton>
										<HasRight admin>
											<IconButton
												color='secondary'
												onClick={() => {
													setSelectedFile(f);
													setShowDeleteDialog(true);
												}}>
												<DeleteForeverIcon/>
											</IconButton>
										</HasRight>
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				}
				{deliverable.Files.length < 1 && <EmptyFiles/>}
			</Grid>
			<HasRight admin>
				<DeleteFileDialog
					open={showDeleteDialog}
					setOpen={setShowDeleteDialog}
					deliverable={deliverable}
					file={selectedFile}
				/>
			</HasRight>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		uploading: state.deliverable.uploading,
		progress: state.deliverable.uploadImgRunningPercent,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		upload: (id, file) => dispatch(deliverableUploadFile(id, file))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Files);
