import React, {Fragment, useEffect}                         from 'react';
import TableHead                                            from '@material-ui/core/TableHead';
import MaterialTable                                        from '@material-ui/core/Table';
import TableRow                                             from '@material-ui/core/TableRow';
import TableCell                                            from '@material-ui/core/TableCell';
import TableBody                                            from '@material-ui/core/TableBody';
import TableContainer                                       from '@material-ui/core/TableContainer';
import Row                                                  from './Row';
import {connect}                                            from 'react-redux';
import TableFooter                                          from '@material-ui/core/TableFooter';
import {useTranslation}                                     from 'react-i18next';
import Empty                                                from './Empty';
import Pagination                                           from '../../data/table/Pagination';
import {fetchBillings, setBillingFilters, setBillingPaging} from '../../../../store/actions/BillingAction';


const Table = ({
	               billings,
	               fetch,
	               setBillingFilters,
	               setBillingPaging,
	               loading,
	               filters,
	               paging,
	               count,
	               disabled = false
               }) => {
	const loadingData = [...Array(paging.results_per_page).keys()];

	const displayLoading = () => loadingData.map((k) => <Row key={k} loading={true} billing={null}/>);
	const displayBillings = () => {
		return billings.map((o) => {
			return <Row key={o.User_Billing__} loading={false} billing={o}/>;
		});
	};

	const {t} = useTranslation();
	//const {sort} = filters;

	useEffect(() => {
		if (!disabled) fetch();
	}, [disabled, fetch, filters, paging]);

	/*const sortHandler = (property, dir) => {
		let p = {...filters};
		// only 1 sort
		p.sort = {};
		p.sort[property] = dir;
		setBillingFilters(p);
	};*/

	return (
		<Fragment>
			{(loading || billings.length > 0) &&
			<TableContainer>
				<MaterialTable aria-label="simple table">
					<TableHead>
						<TableRow>
							<Pagination
								setPaging={setBillingPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
						<TableRow>
							<TableCell>{t('tablecell_billing_method')}</TableCell>
							<TableCell>{t('tablecell_billing_expiration')}</TableCell>
							<TableCell>{t('tablecell_billing_valid')}</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(loading || disabled) && displayLoading()}
						{(!loading && !disabled) && displayBillings()}
					</TableBody>
					<TableFooter>
						<TableRow>
							<Pagination
								setPaging={setBillingPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
					</TableFooter>

				</MaterialTable>
			</TableContainer>
			}
			{(!loading && billings.length < 1) && <Empty/>}
		</Fragment>
	);
};


const mapStateToProps = (state) => {
	return {
		filters: state.billing.billingsFilters,
		paging: state.billing.billingsPaging,
		count: state.billing.billingsPagingCount,
		loading: state.billing.billingsLoading,
		billings: state.billing.billings
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: () => dispatch(fetchBillings()),
		setBillingFilters: (filters) => dispatch(setBillingFilters(filters)),
		setBillingPaging: (paging) => dispatch(setBillingPaging(paging))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);

