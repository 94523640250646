import {handleError} from "../../components/utils/apiErrorHandler";
import {rest} from '@karpeleslab/klbfw'
import {REGISTRY_URL_FETCH, REGISTRY_URL_FETCH_DONE} from "../reducers/RegistryReducer";

export const fetchUrl = (id) => {
    return (dispatch, getState) => {
        dispatch({type: REGISTRY_URL_FETCH});
        return rest('Registry/Url/' + id)
            .then(d => {
                dispatch({type: REGISTRY_URL_FETCH_DONE, url: d.data});
                return d.data
            }).catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};