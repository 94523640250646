import React, { Fragment, useEffect }                        from 'react';
import TableHead                                             from '@material-ui/core/TableHead';
import MaterialTable                                         from '@material-ui/core/Table';
import TableRow                                              from '@material-ui/core/TableRow';
import TableCell                                             from '@material-ui/core/TableCell';
import TableContainer                                        from '@material-ui/core/TableContainer';
import Row                                                   from './Row';
import { useTranslation }                                    from 'react-i18next';
import Empty                                                 from './Empty';
import TableBody                                             from '@material-ui/core/TableBody';
import TableFooter                                           from '@material-ui/core/TableFooter';
import { fetchCoupons, setCouponsFilters, setCouponsPaging } from '../../../../../store/actions/store/CouponAction';
import { connect }                                           from 'react-redux';
import Pagination                                            from '../../../data/table/Pagination';

const Table = ({ coupons, fetch, loading, filters, paging, count, setCouponsPaging, setCouponsFilters, onSelect }) => {
	const loadingData = [...Array(paging.results_per_page).keys()];
	const { t } = useTranslation();
	const displayLoading = () => loadingData.map((k) => <Row
		key={k}
		onSelect={onSelect}
		loading={true}
		coupon={null}
	/>);

	const displayCoupons = () => coupons.map((u, idx) => <Row key={u.Catalog_Coupon__}
	                                                          onSelect={onSelect}
	                                                          loading={false}
	                                                          coupon={u}/>);

	useEffect(() => {
		// Reset filters the first time
		setCouponsFilters({ ...filters });
		// eslint-disable-next-line
	}, [setCouponsFilters]);


	useEffect(() => {
		fetch();
	}, [fetch, filters, paging]);

	/*const {sort} = filters;

	const sortHandler = (property, dir) => {
		let p = {...filters};
		// only 1 sort
		p.sort = {};
		p.sort[property] = dir;
		setRecordsFilters(p);
	};*/

	return (
		<Fragment>
			{(loading || coupons.length > 0) &&
			<TableContainer>
				<MaterialTable aria-label='simple table'>
					<TableHead>
						<TableRow>
							<Pagination
								setPaging={setCouponsPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
						<TableRow>
							<TableCell> {t('tablecell_coupon_code')}</TableCell>
							<TableCell> {t('tablecell_coupon_type')}</TableCell>
							<TableCell> {t('tablecell_coupon_expires')}</TableCell>
							{/* <TableCell> {t('tablecell_coupon_status')}</TableCell>*/}
							<TableCell> {t('tablecell_coupon_amount')}</TableCell>
							<TableCell align='right'/>
						</TableRow>
					</TableHead>
					<TableBody>
						{loading && displayLoading()}
						{!loading && displayCoupons()}
					</TableBody>
					<TableFooter>
						<TableRow>
							<Pagination
								setPaging={setCouponsPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
					</TableFooter>

				</MaterialTable>
			</TableContainer>
			}
			{(!loading && coupons.length < 1) && <Empty isVariation={'Parent_Catalog_Coupon__' in filters}/>}
		</Fragment>
	);

};


const mapStateToProps = (state) => {
	return {
		filters: state.coupon.couponsFilters,
		paging: state.coupon.couponsPaging,
		count: state.coupon.couponsPagingCount,
		loading: state.coupon.couponsLoading,
		coupons: state.coupon.coupons,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: () => dispatch(fetchCoupons()),
		setCouponsPaging: (newPaging) => dispatch(setCouponsPaging(newPaging)),
		setCouponsFilters: (newFilters) => dispatch(setCouponsFilters(newFilters)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);

