import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {connect} from 'react-redux'
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from 'react-i18next';
import {deleteMember} from "../../../store/actions/ArtistAction";
import {useHistory} from "react-router-dom";
import {getMembersRoute} from "../../../router/routes/artist/factory/members";
import Button from "../inputs/Button";

const DeleteDialog = ({open, setOpen, deleting, member, deleteMember}) => {
    const {t} = useTranslation();
    const history = useHistory();

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        deleteMember(member.Music_Label_Artist_Member__)
            .then(() => {
                handleClose();
                history.push(getMembersRoute())
            })
    };

    return (
        <Dialog
            open={open}
            aria-labelledby="Delete member"
            aria-describedby="Delete member"
            fullWidth
        >
            <DialogTitle id="member-delete-title">{t('confirm_delete_member_title')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="member-delete-description">
                    {t('confirm_delete_member_desc')}
                    <br/>
                    <Typography variant="body1" color="textPrimary" component="span">{t('are_you_sure')}</Typography>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button variant='contained' color="primary" autoFocus onClick={handleClose} disabled={deleting}>
                    {t('back_btn')}
                </Button>
                <Button color="secondary" loading={deleting} disabled={deleting} endIcon={<DeleteForeverIcon/>} onClick={handleDelete}>
                    {t('delete_btn')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {
        deleting: state.artist.memberDeleting
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        deleteMember: (id) => dispatch(deleteMember(id))
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(DeleteDialog);
