import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import {useTranslation} from "react-i18next";
import EmptyGoods from "./EmptyGoods";
import MaterialLink from "@material-ui/core/Link";
import {getStoreWarehouseEditRoute} from "../../../../router/routes/store/factory/store";
import Table from "@material-ui/core/Table";
import {Link} from "react-router-dom";
import HasRight from "../../../Route/HasRight";
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Toolbar from "../../typography/Toolbar";
import Button from "../../inputs/Button";
import LinkIcon from '@material-ui/icons/Link';
import DeleteGoodDialog from "./DeleteGoodDialog";
import LinkGoodDialog from "./LinkGoodDialog";

const Goods = ({productId, attributes}) => {
    const {t} = useTranslation();

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showLinkDialog, setShowLinkDialog] = useState(false);
    const [selectedGood, setSelectedGood] = useState(null);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Toolbar title={t('product_goods')}>
                    <HasRight manager>
                        <Button endIcon={<LinkIcon/>} onClick={e => setShowLinkDialog(true)} variant='contained'
                                color='primary'>
                            {t('link_btn')}
                        </Button>
                    </HasRight>
                </Toolbar>
            </Grid>

            <Grid item xs={12}>
                {attributes.deliverables.length > 0 &&
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell> {t('tablecell_deliverable_name')}</TableCell>
                                <TableCell> {t('tablecell_deliverable_style')}</TableCell>
                                <TableCell align='right'/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {attributes.deliverables.map(g =>
                                <TableRow>
                                    <TableCell>
                                        <MaterialLink component={Link}
                                                      to={getStoreWarehouseEditRoute(g.Catalog_Deliverable__)}>{g.Name}</MaterialLink>
                                    </TableCell>
                                    <TableCell> {t('deliverable_style_' + g.Style)}</TableCell>
                                    <TableCell align='right'>
                                        <HasRight admin>
                                            <IconButton
                                                color='secondary'
                                                onClick={() => {
                                                    setSelectedGood(g);
                                                    setShowDeleteDialog(true)
                                                }}>
                                                <DeleteForeverIcon/>
                                            </IconButton>
                                        </HasRight>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                }
                {attributes.deliverables.length < 1 && <EmptyGoods/>}
                <HasRight admin>
                    <DeleteGoodDialog productId={productId} good={selectedGood} open={showDeleteDialog}
                                      setOpen={setShowDeleteDialog}/>
                </HasRight>
                <HasRight manager>
                    <LinkGoodDialog existing={attributes.deliverables} productId={productId} open={showLinkDialog} setOpen={setShowLinkDialog}/>
                </HasRight>
            </Grid>

        </Grid>
    );
};

export default Goods;