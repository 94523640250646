import React, {useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import {getLocale}                from "@karpeleslab/klbfw";
import TextField                  from "@material-ui/core/TextField";
import {connect}                  from 'react-redux'
import {createEntryData}          from "../../../../store/actions/CmsAction";
import {useHistory}               from "react-router-dom";
import {useTranslation}           from 'react-i18next';
import Button                     from "../../inputs/Button";
import Alert                      from "@material-ui/lab/Alert";
import {createSchedule}           from "../../../../store/actions/planner/ScheduleAction";
import {getSiteScheduleEditRoute} from "../../../../router/routes/site/factory/planner";
import DateTime                   from "../../inputs/DateTime";
import FormRequiredField          from '../../feeback/FormRequiredField';

const CreateDialog = ({open, setOpen, creating, cmsId, schedule = null, createEntryData, createSchedule}) => {
    const {t} = useTranslation();
    const [lang] = useState(getLocale());
    const [title, setTitle] = useState('');
    const [date, setDate] = useState(null);
    const [saveEnabled, setSaveEnabled] = useState(false);


    let history = useHistory();

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (!date || !lang) {
            setSaveEnabled(false);
            return;
        }

        if (!schedule && title.trim() === '') {
            setSaveEnabled(false);
            return;
        }

        setSaveEnabled(true)

    }, [schedule, lang, title, date]);

    const handleCreateEntry = () => {
        createSchedule(cmsId, title, lang, date)
            .then((schedule) => {
                history.push(getSiteScheduleEditRoute(schedule.Planner_Schedule__))
            }).then(handleClose)
    };

    const handleCreateEntryData = () => {
        createEntryData(schedule.Content_Cms_Entry__, lang)
            .then((e) => {
                history.push(getSiteScheduleEditRoute(schedule.Planner_Schedule__))
            }).then(handleClose)
    };

    const handleSubmit = (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (!schedule)
            handleCreateEntry();
        else
            handleCreateEntryData();
    };

    return (
        <Dialog
            open={open}
            aria-labelledby={t('create_schedule')}
            aria-describedby={t('create_schedule')}
        >
            <form onSubmit={handleSubmit}>
                <DialogTitle id="create-schedule-title">{t('create_schedule')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="create-schedule-description">
                    </DialogContentText>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Alert severity='info'>{t('create_schedule_alert')}</Alert>
                        </Grid>

                        <FormRequiredField/>

                        {!schedule && <Grid item xs={12} md={6}>
                            <TextField label={t('schedule_title')} fullWidth variant="outlined" value={title}
                                       required
                                       disabled={creating}
                                       onChange={e => setTitle(e.target.value)}
                                       helperText={t('title_schedule_selected_lang')}/>
                        </Grid>
                        }

                        {!schedule && <Grid item xs={12} md={6}>
                            <Grid item xs={12}>
                                <DateTime
                                    label={t('schedule_date_lbl')}
                                    inputVariant="outlined"
                                    value={date}
                                    disabled={creating}
                                    onChange={setDate}
                                    required
                                    helperText={t('schedule_date_helperText')}
                                    fullWidth
                                />

                            </Grid>

                        </Grid>}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="default" onClick={handleClose} disabled={creating}>
                        {t('close_btn')}
                    </Button>
                    <Button color="primary" variant='contained' loading={creating} disabled={creating || !saveEnabled}
                            type='submit'>
                        {t('create_btn')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>

    );
};

const mapStateToProps = (state) => {
    return {
        creating: state.schedule.creating || state.cms.creatingEntryData
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        createSchedule: (cmsId, title, language, date) => dispatch(createSchedule(cmsId, title, language, date)),
        createEntryData: (entryId, language) => dispatch(createEntryData(entryId, language)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDialog);
