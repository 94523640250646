import React, {useEffect, useState} from 'react';
import {connect}                    from 'react-redux';
import {useTranslation}             from 'react-i18next';
import {useParams}                  from 'react-router-dom';
import SelectRealm                  from '../../SelectRealm';
import Loading                      from '../../../components/common/feeback/loading/Loading';
import {fetchEvent}                 from '../../../store/actions/planner/EventAction';
import Grid                         from '@material-ui/core/Grid';
import AppBarTitle                  from '../../../components/common/typography/AppBarTitle';
import Validate                     from '../../../components/common/planner/event/reserve/Validate';
import Toolbar                      from '../../../components/common/typography/Toolbar';
import Button                       from '../../../components/common/inputs/Button';
import StopIcon                     from '@material-ui/icons/Stop';
import TerminateLiveDialog          from '../../../components/common/planner/event/live/TerminateLiveDialog';
import Redirect                     from '../../../components/Route/Redirect';
import {getPXLDashboardRoute}       from '../../../router/routes/pxl/factory/general';
import HasRight                     from '../../../components/Route/HasRight';

const Live = ({event, planner, selectedRealm, fetchEvent}) => {
	const {t} = useTranslation();
	const {eventId} = useParams();
	const [loading, setLoading] = useState(true);
	const [showTerminate, setShowTerminate] = useState(false);

	useEffect(() => {
		if (!planner) return;
		fetchEvent(eventId).then(() => setLoading(false));
	}, [planner, eventId, fetchEvent]);

	if (!selectedRealm || !planner) return <SelectRealm/>;
	if (loading || !event) return <Loading/>;

	return (
		<Grid container spacing={3}>
			<HasRight support deniedComponent={<Redirect target={getPXLDashboardRoute()}/>}>
				<AppBarTitle title={t('event_live_title', {name: event.Schedule.Content_Cms_Entry_Data.Title})}/>
				<Grid item xs={12}>
					<Toolbar title={t('event_live_title', {name: event.Schedule.Content_Cms_Entry_Data.Title})}>
						<Button endIcon={<StopIcon/>} onClick={() => setShowTerminate(true)} variant="contained"
						        color="secondary">
							{t('live_terminate_btn')}
						</Button>
					</Toolbar>
				</Grid>
				<Grid item xs={12}>
					<Validate event={event}/>
				</Grid>
				{event && <TerminateLiveDialog open={showTerminate} setOpen={setShowTerminate} event={event}/>}
			</HasRight>
		</Grid>
	);
};


const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		event: state.event.event,
		planner: state.schedule.planner,
		loading: state.event.eventLoading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchEvent: (id) => dispatch(fetchEvent(id))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Live);
