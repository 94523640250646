import React, {useState}       from 'react';
import Dialog                  from '@material-ui/core/Dialog';
import DialogTitle             from '@material-ui/core/DialogTitle';
import DialogContent           from '@material-ui/core/DialogContent';
import {useTranslation}        from 'react-i18next';
import DialogActions           from '@material-ui/core/DialogActions';
import Button                  from '../../../inputs/Button';
import {useUserLocationCreate} from '../../../../../hooks/api/user/useUserLocation';
import {Grid}                  from '@material-ui/core';
import FormData                from '../FormData';
import FormRequiredField       from '../../../feeback/FormRequiredField';

const CreateLocationDialog = ({open, setOpen, refresh}) => {
	const {t} = useTranslation();
	const [isValid, setIsValid] = useState(false);
	const [create, creating] = useUserLocationCreate('@');

	const [data, setData] = useState({});
	const handleClose = () => {
		setOpen(false);
	};

	const handleCreate = () => {
		const tmp = {...data};
		if (tmp.Country) tmp.Country__ = tmp.Country.Country__;
		create(tmp)
			.then(() => refresh())
			.then(handleClose);
	};

	return (
		<Dialog
			onEnter={() => {
				setData({});
			}}
			fullWidth
			maxWidth='md'
			open={open}
			aria-labelledby={t('create_location_title')}
			aria-describedby={t('create_location_title')}
		>
			<DialogTitle id="create_location_title">{t('create_location_title')}</DialogTitle>
			<DialogContent>
				<Grid container spacing={3}>
					<FormRequiredField/>
					<Grid item xs={12}>
						<FormData disabled={creating} address={data} setAddress={setData} setIsValid={setIsValid}/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button color="default" onClick={handleClose} disabled={creating}>
					{t('close_btn')}
				</Button>

				<Button
					color="primary"
					variant="contained"
					loading={creating}
					disabled={creating || !isValid}
					type="submit"
					onClick={handleCreate}
				>
					{t('create_btn')}
				</Button>
			</DialogActions>
		</Dialog>

	);
};

export default CreateLocationDialog;