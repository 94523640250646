import React                       from 'react';
import { connect }                 from 'react-redux';
import RichAlert                   from '../feeback/RichAlert';
import { Trans, useTranslation }   from 'react-i18next';
import {Grid, TableCell, TableRow} from '@material-ui/core';
import Button                      from '../inputs/Button';
import { Link }                    from 'react-router-dom';
import { getStoreWarehouseRoute }  from '../../../router/routes/store/factory/store';
import {Launch}                    from '@material-ui/icons';

const Warehouse = ({ outOfStock, taskCenter = false }) => {
	const { t } = useTranslation();
	if (!outOfStock) return <></>;

	if (taskCenter) {
		return <TableRow>
			<TableCell>
				<Trans i18nKey='deliverable_out_of_stock_warning' values={{ outOfStock: outOfStock }}>
					sample<br/>
					<strong>sample</strong>
				</Trans>
			</TableCell>
			<TableCell align='right'>
				<Button color='primary' size='small' variant='contained' endIcon={<Launch/>} component={Link}
				        to={`${getStoreWarehouseRoute()}?oos=1`}>
					{t('go_btn')}
				</Button>
			</TableCell>
		</TableRow>
	}

	return (
		<Grid item xs={12}>
			<RichAlert severity='warning' action={
				<Button color='primary' size='small' variant='contained' endIcon={<Launch/>} component={Link}
				        to={`${getStoreWarehouseRoute()}?oos=1`}>
					{t('view_btn')}
				</Button>
			}>
				<Trans i18nKey='deliverable_out_of_stock_warning' values={{ outOfStock: outOfStock }}>
					sample<br/>
					<strong>sample</strong>
				</Trans>
			</RichAlert>
		</Grid>
	);
};


const mapStateToProps = (state) => {
	return {
		outOfStock: state.deliverable.outOfStock,
	};
};

export default connect(mapStateToProps)(Warehouse);
