import React, {useEffect, useState}       from 'react';
//import {useTranslation}                   from 'react-i18next';
import {Grid}                             from '@material-ui/core';
import {useContentCmsEntryOrderTranslate} from '../../../hooks/api/content/cms/useContentCmsEntry';
import {useHistory}                       from 'react-router-dom';
import Loading                            from '../feeback/loading/Loading';
import {getSiteContentEditRoute}          from '../../../router/routes/site/factory/content';
import OrderWithPayment                   from '../myorder/order/OrderWithPayment';
import Button                             from '../inputs/Button';
import {useTranslation}                   from 'react-i18next';

const OrderTranslation = ({entryId, from, to, handleBack, tier = 'standard'}) => {
	const {t} = useTranslation();
	const [orderTranslate, ordering] = useContentCmsEntryOrderTranslate(entryId);
	const [order, setOrder] = useState(null);
	const [paymentInitiated, setPaymentInitiated] = useState(false);
	const history = useHistory();

	const onComplete = (newOrder) => {
		if (newOrder.Items.length < 0) return;
		const entryData = newOrder.Items[0].Catalog_Product['Meta.content_cms_entry_data__'];
		if (!entryData) return;
		history.push(getSiteContentEditRoute(entryData));
	};

	useEffect(() => {
		orderTranslate(from, to, tier).then(order => {
			if ('Content_Cms_Entry_Data__' in order) {
				history.push(getSiteContentEditRoute(order.Content_Cms_Entry_Data__));
			} else {
				setOrder(order);
			}
		});
		// eslint-disable-next-line
	}, []);

	if (!order || ordering) {
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}><Loading/></Grid>
			</Grid>
		);
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<OrderWithPayment
					orderId={order.Order__}
					onComplete={onComplete}
					onPaying={() => setPaymentInitiated(true)}
				/>
			</Grid>
			{!paymentInitiated && <Grid item xs={12}>
				<Button variant='outlined' onClick={handleBack}>
					{t('translate_change_option_btn')}
				</Button>
			</Grid>
			}

		</Grid>
	);
};

export default OrderTranslation;