import {rest, upload} from "@karpeleslab/klbfw";
import {handleError} from "../../../components/utils/apiErrorHandler";
import {
    RECORD_CREATE,
    RECORD_CREATE_DONE,
    RECORD_DELETE,
    RECORD_DELETE_DONE,
    RECORD_FETCH,
    RECORD_FETCH_DONE,
    RECORD_LIST_FETCH,
    RECORD_LIST_FETCH_DONE,
    RECORD_SET_FILTERS,
    RECORD_SET_PAGING,
    RECORD_UPDATE,
    RECORD_UPDATE_DONE
} from "../../reducers/discography/RecordReducer";
import {success} from "../system/ToastAction";

export const setRecordsPaging = (newPaging) => {
    return (dispatch, getState) => {
        dispatch({type: RECORD_SET_PAGING, paging: {...getState().record.recordsPaging, ...newPaging}});
    }
};

export const setRecordsFilters = (newFilters) => {
    return (dispatch, getState) => {
        dispatch({type: RECORD_SET_FILTERS, filters: newFilters});
    }
};

export const fetchRecords = () => {
    return (dispatch, getState) => {
        dispatch({type: RECORD_LIST_FETCH});

        const params = {
            ...getState().record.recordsFilters,
            ...getState().record.recordsPaging,
            Role:'artist',
            Music_Label_Artist__: getState().artist.artist.Music_Label_Artist__
        };

        const labelId = getState().artist.artist.Music_Label__;

        return rest('Music/Label/' + labelId + '/Record', 'GET', params)
            .then(data => {
                dispatch({type: RECORD_LIST_FETCH_DONE, records: data.data, paging: data.paging});
                return data.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const fetchRecord = (id) => {
    return (dispatch, getState) => {
        dispatch({type: RECORD_FETCH});
        return rest('Music/Label/Record/' + id)
            .then(data => {
                dispatch({type: RECORD_FETCH_DONE, record: data.data});
                return data.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const createRecord = (file, data) => {
    return (dispatch, getState) => {
        dispatch({type: RECORD_CREATE});

        const labelId = getState().artist.artist.Music_Label__;

        return new Promise((resolve, reject) => {
            upload.onprogress = (item) => {
                item.failed.forEach(i => {
                    upload.deleteItem(i.up_id)
                    reject(i.failure);
                })
            };

            upload.append('Music/Label/' + labelId + '/Record', file, data)
                .then((data) => {
                    resolve(data.final);
                }).catch(reject);

            upload.run();

        }).then(d => {
            dispatch({type: RECORD_CREATE_DONE});
            success('record_create_success');
            return d;
        }).catch((err) => {
            dispatch({type: RECORD_CREATE_DONE});
            handleError(getState, dispatch, err)
        });
    }
};

export const updateRecord = (id, data) => {
    return (dispatch, getState) => {
        dispatch({type: RECORD_UPDATE});

        return rest('Music/Label/Record/' + id, 'PATCH', data)
            .then(d => {
                dispatch({type: RECORD_UPDATE_DONE, record: d.data});
                success('record_update_success');
                return d;
            }).catch((err) => {
                dispatch({type: RECORD_UPDATE_DONE});
                handleError(getState, dispatch, err)
            });
    }
};

export const deleteRecord = (id) => {
    return (dispatch, getState) => {
        dispatch({type: RECORD_DELETE});

        return rest('Music/Label/Record/' + id, 'DELETE')
            .then(d => {
                dispatch({type: RECORD_DELETE_DONE});
                success('record_delete_success');
                return d;
            }).catch((err) => {
                dispatch({type: RECORD_DELETE_DONE});
                handleError(getState, dispatch, err)
            });
    }
};