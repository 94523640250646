import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux'
import {fetchPlans} from "../../../../store/actions/fanclub/PlanAction";
import Tags                                 from "./tags/Tags";
import {CircularProgress, Grid, Typography} from "@material-ui/core";
import {Title}                              from "../../typography/Title";
import {editEntryTags} from "../../../../store/actions/CmsAction";
import {useTranslation} from 'react-i18next';
import Button from "../../inputs/Button";

const FanclubTags = ({selectedFanclub, entryData, fetchPlans, plans, plansLoading, editEntryTags, entryManagingTags, isManager, disabled = false}) => {
    const [planTags, setPlanTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [saveEnabled, setSaveEnabled] = useState(false);
    const {t} = useTranslation();

    // This use to be sure the initial state is loaded only 1 time, this means if the entryData is changed by another component (update of its content)
    // this component will not change it's internal state. This means for example if we change the article title without safe but we safe some new tags, the articles title changes will no be lost
    const [loaded, setLoaded] = useState(false);


    const [toRemove, setToRemove] = useState([]);
    const [toAdd, setToAdd] = useState([]);

    useEffect(() => {
        if (!selectedFanclub) return;
        fetchPlans();
    }, [selectedFanclub, fetchPlans]);

    useEffect(() => {
        if (plans.length < 1) return;
        let tagsList = [];
        plans.forEach((p) => tagsList.push(p.Classify_Tag));
        setPlanTags(tagsList)
    }, [plans]);

    useEffect(() => {
        if (!entryData || loaded) return;
        if (!entryData.Content_Cms_Entry.Tags) return;

        let tagsList = [];
        entryData.Content_Cms_Entry.Tags.forEach((t) => {
            tagsList.push(t.Classify_Tag__)
        });

        setSelectedTags(tagsList);
        setToAdd([]);
        setToRemove([]);
        setLoaded(true)
    }, [entryData, loaded]);

    useEffect(() => {
        if (toAdd.length > 0 || toRemove.length > 0) {
            setSaveEnabled(true);
            return;
        }

        setSaveEnabled(false);
    }, [toAdd, toRemove]);

    const onAdd = (id) => {
        if (toRemove.includes(id)) {
            setToRemove(toRemove.filter(t => t !== id));
            return;
        }

        setToAdd([...toAdd, id]);
    };

    const onRemove = (id) => {
        if (toAdd.includes(id)) {
            setToAdd(toAdd.filter(t => t !== id));
            return;
        }

        setToRemove([...toRemove, id]);
    };

    const handleSave = (e) => {
        e.preventDefault();

        editEntryTags(entryData.Content_Cms_Entry__, toAdd, toRemove)
            .then(() => {
                setToAdd([]);
                setToRemove([]);
            })
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Title>{t('fanclub_visibility_title')}</Title>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary" component="p">
                    {t('fanclub_visibility_desc')}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                {plansLoading && <CircularProgress/>}
                <Tags list={planTags}
                      values={selectedTags}
                      onChange={(e) => setSelectedTags(e)}
                      onRemove={onRemove}
                      onAdd={onAdd}
                      disabled={!isManager || disabled}
                />

            </Grid>

            {isManager && <Grid item xs={12}>
                <Grid container justify='flex-end'>

                    <Button color='primary' variant='contained' onClick={handleSave}
                            loading={entryManagingTags}
                            disabled={!saveEnabled || entryManagingTags || disabled}>
                        {t('save_btn')}
                    </Button>

                </Grid>
            </Grid>
            }
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        selectedFanclub: state.membership.selectedFanclub,
        plans: state.plan.plans,
        plansLoading: state.plan.plansLoading,
        entryManagingTags: state.cms.entryManagingTags,
        isManager: state.access.manager,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchPlans: () => dispatch(fetchPlans()),
        editEntryTags: (entryId, toAdd, toDelete) => dispatch(editEntryTags(entryId, toAdd, toDelete)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FanclubTags);
