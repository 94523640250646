import React, { useEffect, useState } from 'react';
import { updateRelease }              from '../../../../../store/actions/discography/ReleaseAction';
import { connect }                    from 'react-redux';
import { useTranslation }             from 'react-i18next';
import { Grid }                       from '@material-ui/core';
import FormControlLabel               from '@material-ui/core/FormControlLabel';
import Checkbox                       from '@material-ui/core/Checkbox';
import TextField                      from '@material-ui/core/TextField';
import { Title }                      from '../../../typography/Title';
import Button                         from '../../../inputs/Button';
import FormRequiredField              from '../../../feeback/FormRequiredField';

const Visibility = ({ release, updating, update, isManager }) => {

	const { t } = useTranslation();
	const [saveEnabled, setSaveEnabled] = useState(false);

	const [weight, setWeight] = useState(parseInt(release?.Weight) ?? 0);


	useEffect(() => {
		setSaveEnabled(weight !== parseInt(release.Weight));
	}, [weight, release, setSaveEnabled]);

	const handleSave = () => {
		update(release.Music_Label_Release__, { Weight: weight });
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Title>{t('release_visibility_title')}</Title>
			</Grid>

			<FormRequiredField/>

			<Grid item xs={12} md={6}>
				<FormControlLabel
					disabled={updating || !isManager}
					control={
						<Checkbox
							disabled={updating || !isManager}
							checked={weight !== 0}
							onChange={e => setWeight(e.target.checked ? 1 : 0)}
							color='primary'
						/>
					}
					label={t('release_pin')}
				/>
			</Grid>

			{weight !== 0 && <Grid item xs={12} md={6}>
				<TextField
					disabled={updating || !isManager}
					label={t('release_pin_priority')}
					fullWidth
					variant='outlined'
					value={weight}
					required
					type='number'
					inputProps={{ min: 0 }}
					onChange={e => setWeight(parseInt(e.target.value) || 1)}
					helperText={t('release_priority_helperText')}
				/>
			</Grid>}

			{isManager &&
			<Grid item xs={12}>
				<Grid container justify='flex-end'>

					<Button color='primary' variant='contained' loading={updating}
					        disabled={!saveEnabled || updating}
					        onClick={handleSave}>
						{t('save_btn')}
					</Button>

				</Grid>
			</Grid>
			}

		</Grid>
	);
};


const mapStateToProps = (state) => {
	return {
		updating: state.release.updatingRelease,
		isManager: state.access.manager
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		update: (releaseId, data) => dispatch(updateRelease(releaseId, data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Visibility);
