import Canned                                                from '../../../pages/support/Canned';
import { getSupportCannedEditRoute, getSupportCannedsRoute } from './factory/canned';
import EditCanned                                            from '../../../pages/support/EditCanned';

export default [
	{
		path: getSupportCannedsRoute(),
		component: Canned,
		exact: true,
		type: 'route',
		loginRequired: true,
	},
	{
		path: getSupportCannedEditRoute(),
		component: EditCanned,
		exact: false,
		type: 'route',
		loginRequired: true,
	},
];
