import React            from 'react';
import Grid             from '@material-ui/core/Grid';
import AppBarTitle      from '../components/common/typography/AppBarTitle';
import NotFoundState    from '../components/common/feeback/State/NotFound';
import {useTranslation} from 'react-i18next';

const NotFound = () => {
	const {t} = useTranslation();
	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('page_notfound_title')}/>

			<Grid item xs={12}>
				<NotFoundState/>
			</Grid>
		</Grid>
	);
};

export default NotFound;