import React, {useState} from 'react';
import TableRow          from '@material-ui/core/TableRow';
import Skeleton          from '@material-ui/lab/Skeleton';
import TableCell         from '@material-ui/core/TableCell';
import {useTranslation}  from 'react-i18next';
import Button            from '../../../../../inputs/Button';
import EditIcon          from '@material-ui/icons/Edit';
import Price             from '../../../../../data/Price/Price';
import Grid              from '@material-ui/core/Grid';
import {DeleteForever}   from '@material-ui/icons';
import DeleteDialog      from '../DeleteDialog';
import EditDialog        from '../EditDialog';


const Row = ({scale, vat, loading}) => {
	const displayFee = (!loading && scale);
	const [showDelete, setShowDelete] = useState(false);
	const [showEdit, setShowEdit] = useState(false);
	const {t} = useTranslation();
	return (
		<TableRow>
			<TableCell>
				{!displayFee && <Skeleton/>}
				{displayFee && <span>{scale.Code}</span>}
			</TableCell>

			<TableCell>
				{!displayFee && <Skeleton/>}
				{displayFee && <span><Price
					value={parseFloat(scale.Fee)}
					currency={scale.Currency__}
					vat={vat}
					priceIncludeVat={scale.Vat_Included === 'Y'}/> </span>}
			</TableCell>

			<TableCell>
				{!displayFee && <Skeleton/>}
				{displayFee && <span>{scale.Max_Weight}g</span>}
			</TableCell>

			<TableCell>
				{!displayFee && <Skeleton/>}
				{displayFee && <span>{t('shipping_fee_scale_tax_profile_' + scale.Tax_Profile)}</span>}
			</TableCell>


			<TableCell align="right">
				{!displayFee && <Skeleton/>}
				{(displayFee) && <Grid container spacing={3} justify="space-between">
					<Grid item>
						<Button
							variant="outlined"
							color="secondary"
							onClick={() => setShowDelete(true)}
							startIcon={<DeleteForever/>}
						>
							{t('delete_btn')}
						</Button>
					</Grid>

					<Grid item>
						<Button
							variant="contained"
							color="primary"
							onClick={() => setShowEdit(true)}
							startIcon={<EditIcon/>}
						>
							{t('edit_btn')}
						</Button>
					</Grid>
				</Grid>
				}
			</TableCell>
			{showDelete && <DeleteDialog scale={scale} setOpen={setShowDelete} open={showDelete}/>}
			{showEdit && <EditDialog scale={scale} setOpen={setShowEdit} open={showEdit}/>}
		</TableRow>
	);
};


export default Row;