import React, {useEffect, useState} from 'react';
import Grid                        from "@material-ui/core/Grid";
import {Title}                     from "../../../typography/Title";
import TextField                   from "@material-ui/core/TextField";
import Skeleton                    from "@material-ui/lab/Skeleton";
import {connect}                   from 'react-redux'
import {updateProperties}          from "../../../../../store/actions/store/ProductAction";
import {updateSelectedPlanProduct} from "../../../../../store/actions/fanclub/PlanAction";
import { useTranslation }          from 'react-i18next';
import Button                      from "../../../inputs/Button";
import PlanPeriodSelector          from '../../../inputs/PlanPeriodSelector';
import PlanFreeSelector            from "../../../inputs/PlanFreeSelector";
import FormRequiredField           from '../../../feeback/FormRequiredField';


const Details = ({plan, updating, updateSelectedPlanProduct, update}) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [saveEnabled, setSaveEnabled] = useState(false);
    const { t } = useTranslation();


    useEffect(() => {
        if (!plan) return;
        setName(plan.Catalog_Product['Basic.Name']);
        setDescription(plan.Catalog_Product['Description.Short'])
    }, [plan]);

    useEffect(() => {
        if (!plan) return;
        if (name !== plan.Catalog_Product['Basic.Name']) {
            setSaveEnabled(true);
            return;
        }

        if (description !== plan.Catalog_Product['Description.Short']) {
            setSaveEnabled(true);
            return;
        }

        setSaveEnabled(false);

    }, [name, description, plan]);

    const handleSubmit = (e) => {
        e.preventDefault();
        update(plan.Catalog_Product, [
            {'var': 'Basic.Name', value: name},
            {'var': 'Description.Short', value: description}
        ]).then((d) => {
            updateSelectedPlanProduct(d);
        })
    };

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={3} direction='row'>

                <Grid item xs={12}>
                    <Title>{t('product_detail_title')}</Title>
                </Grid>

                <FormRequiredField/>

                <Grid item xs={6}>
                    {plan &&
                    <TextField helperText={t('plan_name_helperText')} label={t('plan_name_lbl')} variant="outlined" fullWidth value={name} disabled={updating}
                               onChange={e => setName(e.target.value)}/>}
                    {!plan && <Skeleton width={100}/>}
                </Grid>

                {(!plan || plan.Free === 'N') && <Grid item xs={6}>
		            {plan &&
		            <PlanPeriodSelector
			            required
			            value={plan.Period}
			            disabled
		            />}
		            {!plan && <Skeleton width={100}/>}
	            </Grid>}

                <Grid item xs={6}>
                    {plan &&
                    <PlanFreeSelector
                        required
                        value={plan.Free}
                        disabled
                    />}
                    {!plan && <Skeleton width={100}/>}
                </Grid>

                <Grid item xs={12}>
                    {plan &&
                    <TextField label={t('plan_description_lbl')} placeholder={t('plan_description_placeholder')} fullWidth variant="outlined" multiline rows="4" value={description}
                               disabled={updating}
                               onChange={e => setDescription(e.target.value)}/>}
                    {!plan && <Skeleton height={100} width={100}/>}
                </Grid>

                <Grid item xs={12}>
                    <Grid justify='flex-end' container>
                        <Button variant="contained" loading={updating} disabled={!saveEnabled || updating} color='primary' type='submit'>
                            {t('save_btn')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

const mapStateToProps = (state) => {
    return {
        updating: state.product.updatingProperties
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateSelectedPlanProduct: (product) => dispatch(updateSelectedPlanProduct(product)),
        update: (product, properties) => dispatch(updateProperties(product, properties))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);
