import React                          from 'react';
import TableRow                       from '@material-ui/core/TableRow';
import Skeleton                       from '@material-ui/lab/Skeleton';
import TableCell                      from '@material-ui/core/TableCell';
import { Link }                       from 'react-router-dom';
import { useTranslation }             from 'react-i18next';
import EditIcon                       from '@material-ui/icons/Edit';
import Button                         from '../../../inputs/Button';
import { getStoreWarehouseEditRoute } from '../../../../../router/routes/store/factory/store';
import HasRight                       from '../../../../Route/HasRight';
import DeliverableStyle               from './DeliverableStyle';

const Row = ({ deliverable, loading }) => {
	const displayDeliverable = (!loading && deliverable);
	const { t } = useTranslation();
	return (
		<TableRow>
			<TableCell>
				{!displayDeliverable && <Skeleton/>}
				{displayDeliverable && <span>{deliverable.Name}</span>}
			</TableCell>
			<TableCell>
				{!displayDeliverable && <Skeleton/>}
				{displayDeliverable && <DeliverableStyle deliverable={deliverable}/>}
			</TableCell>
			<HasRight manager hideLoading>
				<TableCell align='right'>
					{!displayDeliverable && <Skeleton/>}
					{displayDeliverable &&
					<Button
						variant='contained'
						color='primary'
						startIcon={<EditIcon/>}
						component={Link} to={getStoreWarehouseEditRoute(deliverable.Catalog_Deliverable__)}
					>
						{t('edit_btn')}
					</Button>
					}
				</TableCell>
			</HasRight>
		</TableRow>
	);
};

export default Row;
