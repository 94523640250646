import React                from 'react';
import TableRow             from '@material-ui/core/TableRow';
import Skeleton             from '@material-ui/lab/Skeleton';
import TableCell            from '@material-ui/core/TableCell';
import {useTranslation}     from 'react-i18next';
import Button               from '../../inputs/Button';
import Link                 from '../../typography/Link';
import {getPXLBillingRoute} from '../../../../router/routes/pxl/factory/order';
import {Chip}               from '@material-ui/core';

const Row = ({billing, loading}) => {
	const displayOrder = (!loading && billing);
	const {t} = useTranslation();

	return (
		<TableRow>
			<TableCell>
				{!displayOrder && <Skeleton width={17}/>}
				{displayOrder && <span>{billing.Methods[0].Name}</span>}
			</TableCell>
			<TableCell>
				{!displayOrder && <Skeleton width={17}/>}
				{displayOrder && <span>{billing.Methods[0].Expiration}</span>}
			</TableCell>
			<TableCell>
				{!displayOrder && <Skeleton width={17}/>}
				{displayOrder && <span>
					<Chip
						label={billing.Methods[0].Valid ? t('billing_method_valid') : t('billing_method_invalid')}
						variant="outlined"
						color={billing.Methods[0].Valid ? 'primary' : 'secondary'}
					/>
				</span>}
			</TableCell>
			<TableCell>
				{!displayOrder && <Skeleton/>}
				{
					displayOrder &&
					<Button
						fullWidth
						component={Link}
						to={getPXLBillingRoute(billing.User_Billing__)}
						variant="contained"
						size="small"
						color="primary"
					>
						{t('view_btn')}
					</Button>
				}
			</TableCell>
		</TableRow>
	);
};

export default Row;