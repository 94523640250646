import React, {useEffect, useState} from 'react';
import Grid                         from '@material-ui/core/Grid';
import AppBarTitle                  from '../../components/common/typography/AppBarTitle';
import {useTranslation}             from 'react-i18next';
import {connect}                    from 'react-redux';
import {setTicketsFilters}          from '../../store/actions/SupportAction';
import MailBox                      from '../../components/common/support/MailBox';
import SelectRealm                  from '../SelectRealm';

const Inbox = ({selectedRealm, support, setTicketsFilters}) => {
	const {t} = useTranslation();
	const [tableDisabled, setTableDisabled] = useState(true);

	useEffect(() => {
		if (!support) return;
		setTicketsFilters({Status: ['new', 'assigned'], sort: {Last_Update: 'ASC'}});
		setTableDisabled(false);

	}, [support, setTicketsFilters]);

	if (!selectedRealm) return <SelectRealm/>;

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('support_inbox_title')}/>

			<Grid item xs={12}>
				<MailBox disabled={tableDisabled} box="inbox"/>
			</Grid>

		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		support: state.support.support,
		selectedRealm: state.realm.selected,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setTicketsFilters: (filters) => dispatch(setTicketsFilters(filters))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Inbox);
