import React        from 'react';
import {withRouter} from 'react-router';
import ListItem     from '@material-ui/core/ListItem';
import {matchPath}  from 'react-router-dom';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Tooltip}    from '@material-ui/core';
import makeStyles   from '@material-ui/core/styles/makeStyles';
import Link         from '../../common/typography/Link';
import {hideMenu}   from '../../../store/actions/CommonAction';
import {connect}    from 'react-redux';

const useStyles = makeStyles(theme => ({
	nested: {
		paddingLeft: theme.spacing(4),
	}
}));

const ListItemNavLink = (props) => {
	const {icon, primary, path, tooltip, location, disabled, hideMenu} = props;
	const classes = useStyles();
	//https://material-ui.com/guides/composition/#caveat-with-inlining
	const renderLink = React.useMemo(
		() =>
			React.forwardRef((linkProps, ref) => (
				<Link ref={ref} to={path} {...linkProps} />
			)),
		[path],
	);

	const match = matchPath(location.pathname, {...props});
	const selected = !!match;

	const className = props.nested ? classes.nested : '';

	return (
		<Tooltip title={tooltip} placement="right" arrow>
			<ListItem className={className} button component={renderLink} selected={selected} disabled={disabled}
			          onClick={() => hideMenu()}>
				{icon && <ListItemIcon>{icon}</ListItemIcon>}
				<ListItemText primary={primary}/>
			</ListItem>
		</Tooltip>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		hideMenu: () => dispatch(hideMenu()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListItemNavLink));