import React, { useEffect, useState } from 'react';
import Grid                           from '@material-ui/core/Grid';
import { Trans, useTranslation }      from 'react-i18next';
import creationDoneImg                from '../../../resources/images/fanclub-wizard/creationDone.svg';
import { Title }                      from '../../common/typography/Title';
import Loading                        from '../../common/feeback/loading/Loading';
import Typography                     from '@material-ui/core/Typography';
import { connect }                    from 'react-redux';
import { createFanclub }              from '../../../store/actions/MusicAction';
import { fetchAliases }               from '../../../store/actions/RealmAction';
import FanclubActivator               from '../../common/fanclub/FanclubActivator';

const StepCreation = ({ handleBack, handleNext, plans, stripeAccount, createFanclub, realm, fetchAliases, address, hasLocation }) => {
	const { t } = useTranslation();
	const [creating, setCreating] = useState(true);

	useEffect(() => {

		const addressData = !hasLocation ? { ...address } : null;
		if (!hasLocation) addressData['Country__'] = address.Country.Country__;

		let formatedData = {
			Realm__: realm.Realm__,
			Plan: []
		};

		if (stripeAccount) {
			formatedData['Stripe_Token'] = stripeAccount.OAuth2_Consumer_Token__;
		}

		plans.forEach(plan => {
			formatedData.Plan.push({
				'Basic.Name': plan.name,
				'Description.Short': plan.description,
				Visible: plan.visible,
				Tag: plan.tag,
				Price: plan.free === 'N' ? parseFloat(plan.price) : null,
				Priority: plan.priority,
				Currency__: plan.currency,
				Color: plan.color ?? null,
				vatIncluded:plan.vatIncluded ?? false,
				Period:plan.period ?? '1m',
				Free:plan.free ?? 'N',
			});
		});

		createFanclub(formatedData, addressData)
			.then(fetchAliases) // refresh aliases
			.then(d => {
				setCreating(false);
			})
			.catch(d => {
				handleBack();
			});

		// eslint-disable-next-line
	}, [createFanclub]);

	return (
		<Grid container spacing={3} direction='row'>

			<Grid item xs={4} md={4}>
				<img src={creationDoneImg} alt='artist' width='100%'/>
			</Grid>

			<Grid item xs={12} md={8}>
				<Grid container spacing={3} justify='center'>

					<Grid item>
						{!creating && <Title>{t('fanclub_wizard_step_creation_done_title')}</Title>}
						{creating && <Title>{t('fanclub_wizard_step_creation_title')}</Title>}
					</Grid>

					{creating &&
					<Grid item xs={12}>
						<Loading size={100} thickness={1}/>
					</Grid>
					}

					{!creating && <FanclubActivator/>}

					{!creating &&
					<Grid item xs={12}>
						<Typography variant='subtitle1' color='textPrimary' align='left'>
							<Trans i18nKey='fanclub_wizard_step_creation_done_desc'>
								sample <br/>
								<strong>sample</strong>
							</Trans>
						</Typography>
					</Grid>
					}

				</Grid>
			</Grid>
		</Grid>);
};

const mapStateToProps = (state) => {
	return {
		realm: state.realm.selected
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		createFanclub: (data, addressData) => dispatch(createFanclub(data, addressData)),
		fetchAliases: (data) => dispatch(fetchAliases())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(StepCreation);
