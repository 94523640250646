import React, {useEffect, useState} from 'react';
import FormControl                  from "@material-ui/core/FormControl";
import InputLabel                   from "@material-ui/core/InputLabel";
import {Select}                     from "@material-ui/core";
import {useTranslation}             from 'react-i18next';
import {connect}                    from 'react-redux'
import {fetchTemplates}             from "../../../store/actions/TemplateAction";
import Loading                      from "../feeback/loading/Loading";
import {TEMPLATE_IMAGE_VARIATION}   from '../../../store/reducers/TemplateReducer';

const TemplateSelector = ({onChange, inputProps = {}, value = undefined, selected, templates, loading, fetch, allowEmpty = true, matchCapabilities = false}) => {
    const selectLabel = React.useRef(null);
    const [selectLabelWidth, setLabelWidth] = useState(0);
    const {t} = useTranslation();

    useEffect(() => {
        let filters = {};
        if (matchCapabilities && selected) // List only the templates that have the same features as the one we have
            filters = {Capabilities: Object.keys(selected.Capabilities)};

        fetch({...filters, Type:'artist', image_variation:TEMPLATE_IMAGE_VARIATION});
        setLabelWidth(selectLabel.current.offsetWidth);
    }, [fetch, matchCapabilities, selected]);

    const handleChange = (e) => {
        if (!templates) return;
        onChange(templates.filter(t => t.Site_Template__ === e.target.value)[0])
    };

    return (
        <FormControl {...inputProps}>
            <InputLabel ref={selectLabel} htmlFor='template-select'>{t('site_template_label')}</InputLabel>
            {loading && <Loading/>}
            {!loading && <Select
                native
                value={value ? value.Site_Template__ : undefined}
                onChange={handleChange}
                labelWidth={selectLabelWidth}
                inputProps={{
                    id: 'template-select',
                }}
            >
                {allowEmpty && <option/>}
                {templates.map(t => <option key={t.Site_Template__} value={t.Site_Template__}>{t.Name}</option>)}
	            {!loading && <option key='more' value='more' disabled>{t('template_selector_more')}</option>}
            </Select>
            }

        </FormControl>
    );
};


const mapStateToProps = (state) => {
    return {
        templates: state.template.templates,
        selected: state.template.selectedTemplate,
        loading: state.template.loading
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetch: (filters) => dispatch(fetchTemplates(filters))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateSelector);
