import React, {useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions               from "@material-ui/core/DialogActions";
import Grid                        from "@material-ui/core/Grid";
import Language                    from "../../inputs/Language";
import {getLocale}                 from "@karpeleslab/klbfw";
import TextField                   from "@material-ui/core/TextField";
import {connect}                   from 'react-redux'
import {useHistory}                from "react-router-dom";
import {useTranslation}            from 'react-i18next';
import {createCanned}              from "../../../../store/actions/SupportAction";
import {getSupportCannedEditRoute} from "../../../../router/routes/support/factory/canned";
import Button                      from "../../inputs/Button";
import FormRequiredField           from '../../feeback/FormRequiredField';

const CreateDialog = ({open, setOpen, creating, createCanned}) => {
    const {t} = useTranslation();
    const [lang, setLang] = useState(getLocale());
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [saveEnabled, setSaveEnabled] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (!lang.trim() || !title.trim() || !message.trim()) {
            setSaveEnabled(false);
            return;
        }

        setSaveEnabled(true)
    }, [lang, title, message, setSaveEnabled]);


    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = (e) => {
        e.stopPropagation();
        e.preventDefault();

        createCanned(title, lang, message)
            .then((d) => {
                handleClose();
                history.push(getSupportCannedEditRoute(d.Support_Canned__))
            })
    };

    return (
        <Dialog
            open={open}
            aria-labelledby={t('create_canned')}
            aria-describedby={t('create_canned')}
        >
            <form onSubmit={handleSubmit}>
                <DialogTitle id="create-canned-title">{t('create_canned_title')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="create-canned-description">
                        {t('create_canned_desc')}
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <FormRequiredField/>
                        <Grid item xs={12} md={6}>
                            <TextField label={t('title_canned')} fullWidth variant="outlined" value={title}
                                       required
                                       disabled={creating}
                                       onChange={e => setTitle(e.target.value)}
                                       helperText={t('title_canned_selected_lang')}/>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Language value={lang} onChange={e => setLang(e.target.value)}
                                      inputProps={{
                                          variant: 'outlined',
                                          required: true,
                                      }}
                                      helperText={t('lang_article_written_in')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={message}
                                onChange={e => setMessage(e.target.value)}
                                variant='outlined'
                                multiline rows={5}
                                rowsMax={Infinity}
                                placeholder={t('support_canned_response_placeholder')}
                                required
                                fullWidth
                                disabled={creating}
                                label={t('support_canned_response_label')}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="default" onClick={handleClose} disabled={creating}>
                        {t('close_btn')}
                    </Button>
                    <Button color="primary" variant='contained' loading={creating} disabled={creating || !saveEnabled} type='submit'>
                        {t('create_btn')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>

    );
};

const mapStateToProps = (state) => {
    return {
        creating: state.support.cannedCreating
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        createCanned: (title, lang, message) => dispatch(createCanned(title, lang, message)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDialog);
