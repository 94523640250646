import {
    CURRENCY_DECIMAL_MAP,
    CURRENCY_FETCH,
    CURRENCY_FETCH_DONE, CURRENCY_FORMAT_MAP,
    CURRENCY_NAME_MAP
} from "../../reducers/system/CurrencyReducer";
import {rest} from "@karpeleslab/klbfw";
import {handleError} from "../../../components/utils/apiErrorHandler";

export const fetch = (fetch) => {
    return (dispatch, getState) => {
        if (getState().currency.currencies.length > 0) return; // currencies won't changes do not reload them
        dispatch({type: CURRENCY_FETCH});
        return rest('Currency')
            .then(data => {
                dispatch({type: CURRENCY_FETCH_DONE, currencies: data.data});
                return data.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })

    }
};


export const getDecimals = (currency) => {
    return (dispatch, getState) => {
        if (currency in getState().currency.decimalMap) {
            return getState().currency.decimalMap[currency];
        }

        const r = getState().currency.currencies.filter(c => c.Currency__ === currency);
        if (r.length < 1) {
            return 5;
        } // default value
        const value = parseInt(r[0].Display_Decimals);
        dispatch({type: CURRENCY_DECIMAL_MAP, currency: currency, decimal: value});
        return value;
    }
};


export const getName = (currency) => {
    return (dispatch, getState) => {
        if (currency in getState().currency.nameMap) {
            return getState().currency.nameMap[currency];
        }

        const r = getState().currency.currencies.filter(c => c.Currency__ === currency);
        if (r.length < 1) {
            return currency;
        } // default value
        const value = r[0].Name;

        dispatch({type: CURRENCY_NAME_MAP, currency: currency, name: value});
        return value;
    }
};


function formatPriceCurrency(price, format) {
    if (format.Symbol_Position === "before")
        return format.Symbol + price;
    return price + format.Symbol
}

export const formatPrice = (price, currency) => {
    return (dispatch, getState) => {
        if (currency in getState().currency.formatMap) {
            const format = getState().currency.formatMap[currency];
            return formatPriceCurrency(price, format)

        }

        const r = getState().currency.currencies.filter(c => c.Currency__ === currency);
        if (r.length < 1) {
            return price + currency;
        } // default value

        dispatch({type: CURRENCY_FORMAT_MAP, currency: currency, format: r[0]});
        return formatPriceCurrency(price, r[0])
    }
};
