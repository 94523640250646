import Plans from "../../../pages/fanclub/plan/Plans";
import CreateFanclubPlan from "../../../pages/fanclub/plan/Create";
import PlanEdit from "../../../pages/fanclub/plan/PlanEdit";
import {getFanclubPlanCreateRoute, getFanclubPlanEditRoute, getFanclubPlansRoute} from "./factory/plan";

export default [
    {
        path: getFanclubPlansRoute(),
        component: Plans,
        exact: true,
        type: 'route',
        loginRequired: true,
    },
    {
        path: getFanclubPlanCreateRoute(),
        component: CreateFanclubPlan,
        exact: true,
        type: 'route',
        loginRequired: true,
    },
    {
        path: getFanclubPlanEditRoute(),
        component: PlanEdit,
        exact: false,
        type: 'route',
        loginRequired: true,
    },
]