import React                     from 'react';
import Grid                      from '@material-ui/core/Grid';
import { Title }                 from '../../../typography/Title';
import { Trans, useTranslation } from 'react-i18next';
import Typography                from '@material-ui/core/Typography';
import Table                     from './Table';
import Create                    from './Create';
import uuid                      from '../../../../utils/uuid';

const Collaborators = ({ value, onChange, disabled, type = 'Record', isReview = false }) => {
	const { t } = useTranslation();

	const onAdd = (artist, role) => {
		const existing = value.filter(v => v.Music_Label_Artist__ === artist.Music_Label_Artist__);
		for (let i = 0; i < existing.length; i++) {
			// Cannot have the artist with the same role twice
			if (existing[i].Role === role)
				return;
		}

		onChange([
			...value,
			{
				[`Music_Label_${type}_Artist__`]: uuid(),
				Music_Label_Artist__: artist.Music_Label_Artist__,
				Music_Label_Artist: artist,
				Role: role
			}
		]);
	};

	const onDelete = (collaborator) => {
		onChange([
			...value.filter(c => c[`Music_Label_${type}_Artist__`] !== collaborator[`Music_Label_${type}_Artist__`])
		]);
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Title>{t('record_collaborators_title')}</Title>
			</Grid>
			{!isReview && <Grid item xs={12}>
				<Grid container spacing={3} justify='space-between'>
					<Grid item>
						<Typography variant='body2' color='textSecondary' component='p'>
							<Trans i18nKey='record_collaborators_text'>
								sample <br/>
								<strong>sample</strong>
							</Trans>
						</Typography>

					</Grid>
				</Grid>

			</Grid>
			}
			{!isReview && <Grid item xs={12}>
				<Create disabled={disabled} onAdd={onAdd} existing={value}/>
			</Grid>
			}
			<Grid item xs={12}>
				<Table collaborators={value} disabled={disabled} onDelete={onDelete} isReview={isReview}/>
			</Grid>
		</Grid>
	);
};

export default Collaborators;
