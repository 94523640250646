import React, {useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../../inputs/Button";

const PrintSizeDialog = ({open, setOpen, height, width, setHeight, setWidth, onPrint}) => {
    const {t} = useTranslation();
    const [saveDisabled, setSaveDisabled] = useState(true);

    useEffect(() => {
        setSaveDisabled(!height || !width);
    }, [height, width, setSaveDisabled]);

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <Dialog
            open={open}
            aria-labelledby={t('address_print_size')}
            aria-describedby={t('address_print_size')}
        >
            <DialogTitle id="address-print-size">{t('address_print_size')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="address-print-size-desc">
                    {t('address_print_size_desc')}
                </DialogContentText>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField label={t('address_print_width')}
                                   fullWidth
                                   variant="outlined"
                                   value={width}
                                   required
                                   type='number'
                                   inputProps={{min: 0}}
                                   onChange={e => setWidth(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label={t('address_print_height')}
                                   fullWidth
                                   variant="outlined"
                                   value={height}
                                   required
                                   type='number'
                                   onChange={e => setHeight(e.target.value)}
                                   inputProps={{min: 0}}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={handleClose}>
                    {t('close_btn')}
                </Button>
                <Button color="primary" type='submit' variant='contained' disabled={saveDisabled}
                        onClick={() => {
                            handleClose();
                            onPrint()
                        }}>
                    {t('print_address_btn')}
                </Button>
            </DialogActions>
        </Dialog>

    );
};


export default PrintSizeDialog;