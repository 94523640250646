import React         from 'react';
import Grid          from '@material-ui/core/Grid';
import CartSubTotals from './Prices/CartSubTotals';
import CartTotals    from './Prices/CartTotals';

const CartPrices = ({ cart, detailed = false }) => {

	return (
		<Grid container spacing={3} justify={detailed ? 'space-between' : 'flex-end'}>
			{detailed && <Grid item xs={12} md={5}>
				<CartSubTotals subtotals={cart.subtotals}/>
			</Grid>}

			<Grid item xs={12} md={5}>
				<CartTotals cart={cart} detailed={detailed}/>
			</Grid>
		</Grid>
	);
};

export default CartPrices;