export const getStoreShipmentsRoute = () => '/shipping';
export const getStoreCouponsRoute = () => '/coupons';
export const getStoreShopRoute = () => '/shop';
export const getStoreShopReportRoute = () => '/shop/report';
export const getStoreProductCreateRoute = () => '/product/create';
export const getStoreProductCreateVariationRoute = (productId = null) => '/product/create/' + (productId ?? ':productId');
export const getStoreProductEditRoute = (productId = null) => '/product/' + (productId ?? ':productId');
export const getStoreWarehouseRoute = () => '/warehouse';
export const getStoreWarehouseEditRoute = (deliverableId = null) => '/warehouse/' + (deliverableId ?? ':deliverableId');
export const getStoreWizardRoute = () => '/store-wizard';
export const getStoreBankRoute = () => '/store-bank';
export const getStoreShippingFeesRoute = () => '/shipping-fees';
export const getStoreShippingFeeCreateRoute = () => '/shipping-fees/create';
export const getStoreShippingFeeEditRoute = (feeId = null) => '/shipping-fees/' + (feeId ?? ':feeId');
