import React from 'react';
import {useTranslation} from 'react-i18next';
import Select from "./Select";

const CmsAudienceSelector = ({value, setValue, disabled, required}) => {
    const {t} = useTranslation();

    return <Select
        label={t('cms_audience_selector')}
        helperText={t('cms_audience_selector_helperText')}
        value={value}
        onChange={e => setValue(e.target.value)}
        optionKey='Key'
        optionValue='Value'
        allowEmpty={false}
        options={[
            {Key: 'all', Value: t('cms_audience_selector_all')},
            {Key: 'web', Value: t('cms_audience_selector_web')},
            {Key: 'app', Value: t('cms_audience_selector_app')},
        ]}
        inputProps={{
            disabled: disabled,
            variant: 'outlined',
            fullWidth: true,
            required: required
        }}
    />;
};

export default CmsAudienceSelector;