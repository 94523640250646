import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {connect} from 'react-redux'
import Button from "../inputs/Button";
import TextField from "@material-ui/core/TextField";
import {sendTicket} from "../../../store/actions/ContactAction";
import graphic from "../../../resources/images/contactUs.svg"
import {useTranslation} from "react-i18next";
import Hidden from "@material-ui/core/Hidden";

const Form = ({supportId, user, sending, send}) => {
    const {t} = useTranslation();
    const [subject, setSubject] = useState('');
    const [content, setContent] = useState('');
    const [sendEnabled, setSendEnabled] = useState(false);

    const handleSend = () => {
        send(
            user.Email,
            user.Profile.Display_Name,
            supportId,
            subject,
            content
        )
            .then(d => {
                setSubject('');
                setContent('')
            })
    };

    useEffect(() => {
        setSendEnabled(subject.trim() !== '' && content.trim() !== '')
    }, [subject, content]);

    return (
        <Grid container spacing={3}>
            <Hidden smDown>
                <Grid item xs={12} md={6}>
                    <img src={graphic} alt='contact us' width="100%"/>
                </Grid>
            </Hidden>
            <Grid item xs={12} md={6}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('contact_subject_lbl')}
                            required
                            disabled={sending}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            value={content}
                            onChange={e => setContent(e.target.value)}
                            variant='outlined'
                            multiline rows={5}
                            rowsMax={Infinity}
                            placeholder={t('contact_content_lbl')}
                            required
                            fullWidth
                            disabled={sending}
                            label={t('contact_content_lbl')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify='flex-end'>
                            <Button loading={sending} color='primary' variant='contained' onClick={handleSend}
                                    disabled={!sendEnabled || sending}
                            >
                                {t('send_btn')}
                            </Button>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        sending: state.contact.sending,
        user: state.user.user,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        send: (email, name, to, subject, message) => dispatch(sendTicket(email, name, to, subject, message))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
