import React from 'react';
import TableRow from "@material-ui/core/TableRow";
import Skeleton from "@material-ui/lab/Skeleton";
import TableCell from "@material-ui/core/TableCell";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {useTranslation} from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import SearchIcon from "@material-ui/icons/Search";
import Button from "../../../inputs/Button";

const useStyles = makeStyles(theme => ({
    selected: {
        backgroundColor: '#00000052',
    },
    selectable: {
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#00000052',
        }
    },
}));

const Row = ({template, value, setValue, mode}) => {
    const {t} = useTranslation();
    const display = template !== null;
    const classes = useStyles();
    const isSelected = template ? (value ? value.Content_Cms_Template__ === template.Content_Cms_Template__ : false) : false;


    return (
        <TableRow className={(isSelected ? classes.selected : '') + ' ' + classes.selectable}>
            <TableCell>
                {!display && <Skeleton/>}
                {display && <span>{template.Name}</span>}
            </TableCell>
            <TableCell align='right'>
                {!display && <Skeleton/>}
                {display && <Button
                    variant={mode === 'manage' ? 'contained' : 'text'}
                    color="primary"
                    endIcon={mode === 'manage' ? <SearchIcon/> : <ArrowForwardIcon/>}
                    onClick={() => setValue(template)}
                >
                    {mode === 'manage' ? t('view_btn') : t('select_btn')}
                </Button>}
            </TableCell>
        </TableRow>
    );
};


export default Row;