import React, {useEffect, useState} from 'react';
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Flag from "../../common/data/flag/Flag";
import Grid from "@material-ui/core/Grid";
import SnsLinks from "../../common/data/SnsLink/SnsLinks";

const useStyles = makeStyles({
    cover: {
        height: '100%',
        backgroundSize: 'contain'
    },

});

const Artist = ({artist}) => {
    const classes = useStyles();
    const [imgLink, setImgLink] = useState('');

    useEffect(() => {
        const fr = new FileReader();
        fr.onload = function () {
            setImgLink(fr.result);
        };
        fr.readAsDataURL(artist.picture);
    }, [artist]);

    return (
        <Card variant='outlined'>
            <Grid container>
                <Grid item xs={6}>
                    <CardContent>
                        <Grid container direction='column'>
                            <Grid item>
                                <Grid container spacing={3}>
                                    <Grid item>
                                        <Flag className={classes.nameKana}
                                              code={artist.country.Country__.toLowerCase()}/>
                                    </Grid>
                                    <Grid item>

                                        <Typography component="span" variant="h5">
                                            {artist.name['en-US']}
                                        </Typography>
                                    </Grid>

                                    {('ja-JP' in artist.name) && <Grid item>

                                        <Typography component="span" variant="h5"
                                                    color="textSecondary">
                                            {artist.name['ja-JP']}
                                        </Typography>
                                    </Grid>}

                                    {('kana' in artist.name) && <Grid item>

                                        <Typography component="span" variant="h5"
                                                    color="textSecondary">
                                            {artist.name['kana']}
                                        </Typography>
                                    </Grid>
                                    }

                                </Grid>

                                <Typography variant="subtitle1" color="textSecondary">
                                    {artist.genre && artist.genre.Genre}
                                </Typography>

                                <Typography variant="body1" color="textSecondary">
                                    {artist.catchphrase}
                                </Typography>

                                <Typography variant="body1" color="textSecondary">
                                    {artist.biography}
                                </Typography>

                            </Grid>
                            <Grid item>
                                <SnsLinks values={artist.links} viewMode={true}/>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Grid>
                {imgLink &&

                <Grid item xs={6}>
                    <CardMedia
                        className={classes.cover}
                        image={imgLink}
                        title="Artist cover"
                    />
                </Grid>

                }
            </Grid>
        </Card>
    );
};

export default Artist;
