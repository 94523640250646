import React, { useEffect, useState } from 'react';
import { Grid, Typography }           from '@material-ui/core';
import { Title }                      from '../../typography/Title';
import FormControl                    from '@material-ui/core/FormControl';
import InputLabel                     from '@material-ui/core/InputLabel';
import Select                         from '@material-ui/core/Select';
import FormHelperText                 from '@material-ui/core/FormHelperText';
import moment                         from 'moment';
import { connect }                    from 'react-redux';
import {updateEntry, updateEntryData} from '../../../../store/actions/CmsAction';
import { useTranslation }             from 'react-i18next';
import DateTime                       from '../../inputs/DateTime';
import Button                         from '../../inputs/Button';
import FormControlLabel               from '@material-ui/core/FormControlLabel';
import Checkbox                       from '@material-ui/core/Checkbox';
import TextField                      from '@material-ui/core/TextField';
import CmsAudienceSelector            from '../../inputs/CmsAudienceSelector';
import FormRequiredField              from '../../feeback/FormRequiredField';

const Publication = ({ entryData, updateEntryData, updatingEntryData, isManager, updateEntry, disabled = false }) => {
	const statusLabel = React.useRef(null);
	const [statusLabelWidth, setStatusLabelWidth] = useState(0);
	const [saveEnabled, setSaveEnabled] = useState(false);

	// This use to be sure the initial state is loaded only 1 time, this means if the entryData is changed by another component (update of its content)
	// this component will not change it's internal state. This means for example if we change the article title without safe but we safe some new tags, the articles title changes will no be lost
	const [loaded, setLoaded] = useState(false);

	const [status, setStatus] = useState();
	const [audience, setAudience] = useState();
	const [publishedDate, setPublishedDate] = useState(null);
	const [weight, setWeight] = useState(0);
	const { t } = useTranslation();

	useEffect(() => {
		setStatusLabelWidth(statusLabel.current.offsetWidth);
	}, []);

	useEffect(() => {
		if (!entryData || loaded) return;
		setStatus(entryData.Status);
		setAudience(entryData.Content_Cms_Entry.Audience);
		setWeight(parseInt(entryData.Weight) || 0);
		setPublishedDate(entryData.Published ? moment(parseInt(entryData.Published.unixms)) : null);
		setLoaded(true);

	}, [entryData, loaded]);

	useEffect(() => {
		if (!entryData) return;
		if (weight !== (parseInt(entryData.Weight) || 0)) {
			setSaveEnabled(true);
			return;
		}

		if (audience !== entryData.Content_Cms_Entry.Audience) {
			setSaveEnabled(true);
			return;
		}

		if (status !== entryData.Status) {
			setSaveEnabled(true);
			return;
		}

		if (!entryData.Published && publishedDate) {
			setSaveEnabled(true);
			return;
		}

		if (entryData.Published && !publishedDate) {
			setSaveEnabled(true);
			return;
		}

		if (entryData.Published && publishedDate) {
			if (entryData.Published.unix !== publishedDate.unix()) {
				setSaveEnabled(true);
				return;
			}
		}

		setSaveEnabled(false);

	}, [entryData, status, weight, publishedDate, audience]);

	const handleSave = (e) => {
		e.preventDefault();
		let data = {
			Status: status,
			Weight: weight,
		};

		if (publishedDate) {
			data['Published'] = '@' + publishedDate.unix();
		}

		const refreshData = d => {
			// because we prevent the data from updating thank to the var 'loaded' (thanks to this we can edit section independently without loosing non saved changes)
			// the published might not be refreshed when change the status to published on an article that has never been published
			// So we do it here.
			if (!publishedDate && d.Published) {
				setPublishedDate(moment(parseInt(d.Published.unixms)));
			}
			setStatus(d.Status);
			setAudience(d.Content_Cms_Entry.Audience);
			setWeight(parseInt(d.Weight) || 0)
		}

		// Entry update required
		if (audience !== entryData.Content_Cms_Entry.Audience) {
			updateEntry(entryData.Content_Cms_Entry__, {Audience:audience})
				.then(() => updateEntryData(entryData.Content_Cms_Entry_Data__, data))
				.then(refreshData);
		}else {
			updateEntryData(entryData.Content_Cms_Entry_Data__, data).then(refreshData);
		}
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Title>{t('publication_title')}</Title>
			</Grid>

			<FormRequiredField/>

			<Grid item xs={12}>
				<Typography variant='body2' color='textSecondary' component='p'>
					{t('publication_desc')}
				</Typography>
			</Grid>

			<Grid item xs={12} md={6}>
				<FormControl variant='outlined' fullWidth disabled={updatingEntryData || !isManager || disabled}>
					<InputLabel ref={statusLabel} htmlFor='published-select'>{t('status')}</InputLabel>
					<Select
						native
						value={status}
						onChange={(e) => setStatus(e.target.value)}
						labelWidth={statusLabelWidth}
						inputProps={{
							id: 'published-select',
						}}
					>
						<option value='draft'>{t('draft_opt')}</option>
						<option value='published'>{t('published_opt')}</option>
					</Select>
					<FormHelperText>{t('changing_status_desc')}</FormHelperText>
				</FormControl>
			</Grid>

			<Grid item xs={12} md={6}>
				<Grid item xs={12}>
					<DateTime
						label={t('publish_at_lbl')}
						inputVariant='outlined'
						value={publishedDate}
						disabled={updatingEntryData || !isManager || disabled}
						onChange={setPublishedDate}
						helperText={t('publish_at_desc')}
						fullWidth
					/>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<CmsAudienceSelector
					value={audience}
					setValue={setAudience}
					disabled={updatingEntryData || !isManager || disabled}
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<FormControlLabel
					disabled={updatingEntryData || !isManager}
					control={
						<Checkbox
							disabled={updatingEntryData || !isManager || disabled}
							checked={weight !== 0}
							onChange={e => setWeight(e.target.checked ? 1 : 0)}
							color='primary'
						/>
					}
					label={t('cms_entry_pin')}
				/>
			</Grid>

			{weight !== 0 && <Grid item xs={12} md={6}>
				<TextField
					disabled={updatingEntryData || !isManager || disabled}
					label={t('cms_entry_pin_priority')}
					fullWidth
					variant='outlined'
					value={weight}
					required
					type='number'
					inputProps={{ min: 0 }}
					onChange={e => setWeight(parseInt(e.target.value) || 1)}
					helperText={t('cms_entry_pin_priority_helperText')}
				/>
			</Grid>}


			{isManager &&
			<Grid item xs={12}>
				<Grid container justify='flex-end'>

					<Button color='primary' variant='contained' loading={updatingEntryData}
					        disabled={!saveEnabled || updatingEntryData || disabled}
					        onClick={handleSave}>
						{t('save_btn')}
					</Button>

				</Grid>
			</Grid>
			}


		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		updatingEntryData: state.cms.updatingEntryData,
		isManager: state.access.manager
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateEntry: (entryId, data) => dispatch(updateEntry(entryId, data)),
		updateEntryData: (entryDataId, data) => dispatch(updateEntryData(entryDataId, data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Publication);
