import React, {useEffect}              from 'react';
import {connect}                       from 'react-redux';
import {useTranslation}                from 'react-i18next';
import Card                            from '@material-ui/core/Card';
import CardContent                     from '@material-ui/core/CardContent';
import Typography                      from '@material-ui/core/Typography';
import {Link}                          from 'react-router-dom';
import {getStoreShipmentsRoute}        from '../../../router/routes/store/factory/store';
import Button                          from '../inputs/Button';
import {BlackPaper}                    from '../surface/Paper';
import {
	refreshShippingsPendingCount,
	refreshShippingsProcessingCount
}                                      from '../../../store/actions/store/ShippingAction';
import {Box, CardActions, Grid, Paper} from '@material-ui/core';
import {Launch}                        from '@material-ui/icons';
import makeStyles                      from '@material-ui/core/styles/makeStyles';
import NoStore                         from './NoStore';

const useStyles = makeStyles({
	uppercase: {
		textTransform: 'uppercase',
	},
});

const Shippings = ({store, count, countProcessing, refreshShippingsPendingCount, refreshShippingsProcessingCount}) => {
	const {t} = useTranslation();
	const classes = useStyles();

	// For the first time
	useEffect(() => {
		if (!store) return;
		refreshShippingsProcessingCount().catch(() => {
		});
		refreshShippingsPendingCount().catch(() => {
		});
		// eslint-disable-next-line
	}, [store, refreshShippingsPendingCount, refreshShippingsProcessingCount]);

	useEffect(() => {
		if (!store) return;
		const refreshInterval = setInterval(() => {
			if (!store) return;
			refreshShippingsProcessingCount().catch(() => {
			});
			refreshShippingsPendingCount().catch(() => {
			});
		}, 30000);

		return () => {
			clearInterval(refreshInterval);
		};
	}, [store, refreshShippingsPendingCount, refreshShippingsProcessingCount]);


	if (!store) {
		return (
			<Card component={BlackPaper} style={{height:'100%'}}>
				<CardContent>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Typography align="center" variant={'h6'} className={classes.uppercase}>
								{t('store')}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<NoStore/>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		);
	}

	return (
		<Card elevation={0} component={BlackPaper} style={{height: '100%', overflow: 'unset', minHeight: '330px'}}>
			<CardContent style={{height: '80%'}}>
				<Grid container spacing={3} alignItems="center" justify="space-between" direction="column"
				      style={{height: '80%'}}>
					<Grid item>
						<Typography variant={'h6'} align={'center'} className={classes.uppercase}>
							{t('order_and_shipping')}
						</Typography>
					</Grid>

					<Grid item>
						<Grid container spacing={3}>
							<Grid item xs={6}>
								<Paper elevation={0}>
									<Grid container spacing={3} alignItems="center" justify="center">
										<Grid item={12}>
											<Typography variant={'h6'} align={'center'} className={classes.uppercase}>
												{count}
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<Typography variant={'h6'} align={'center'} className={classes.uppercase}>
												{t('shipment_to_process')}
											</Typography>
										</Grid>
									</Grid>
								</Paper>
							</Grid>

							<Grid item xs={6} style={{height: '100%'}}>
								<Paper elevation={0}>
									<Grid container spacing={3} alignItems="center" justify="center">
										<Grid item={12}>
											<Typography variant={'h6'} align={'center'} className={classes.uppercase}>
												{countProcessing}
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<Typography variant={'h6'} align={'center'} className={classes.uppercase}>
												{t('shipment_to_sent')}
											</Typography>
										</Grid>
									</Grid>
								</Paper>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</CardContent>
			<CardActions>
				<Box style={{textAlign: 'center', width: '100%'}}>
					<Button variant="text" color="primary" endIcon={<Launch/>} component={Link}
					        to={getStoreShipmentsRoute()}>
						{t('see_shipments_btn')}
					</Button>
				</Box>
			</CardActions>
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		store: state.store.catalog,
		count: state.shipping.shippingsPendingCount,
		countProcessing: state.shipping.shippingsProcessingCount,
	};
};


const mapDispatchToProps = (dispatch) => {
	return {
		refreshShippingsPendingCount: () => dispatch(refreshShippingsPendingCount(true)),
		refreshShippingsProcessingCount: () => dispatch(refreshShippingsProcessingCount(true)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Shippings);
