import React                   from 'react';
import {useTranslation}        from 'react-i18next';
import Empty                   from '../feeback/State/Empty';
import {connect}               from 'react-redux';
import {getFanclubWizardRoute} from '../../../router/routes/fanclub/factory/wizard';

const NoFanclub = ({isOwner}) => {
	const {t} = useTranslation();

	return (
		<Empty
			title={t('dashboard_no_fanclub_stats')}
			textI18Key="artist_wizard_step_creation_done_fanclub_desc"
			graphic={null}
			action={isOwner ? {
				title: t('create_btn'),
				to: getFanclubWizardRoute(),
			} : null}
		/>
	);
};
const mapStateToProps = (state) => {
	return {
		isOwner: state.access.owner
	};
};

export default connect(mapStateToProps)(NoFanclub);