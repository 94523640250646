import { rest }         from '@karpeleslab/klbfw';
import { handleError }  from '../../../components/utils/apiErrorHandler';
import {
	ACCESS_ACCEPT_LINK,
	ACCESS_ACCEPT_LINK_DONE,
	ACCESS_DECODE_LINK,
	ACCESS_DECODE_LINK_DONE,
	ACCESS_GRANT,
	ACCESS_GRANT_DONE,
	ACCESS_GROUP_FETCH,
	ACCESS_GROUP_FETCH_DONE,
	ACCESS_LEAVE,
	ACCESS_LEAVE_DONE,
	ACCESS_REVOKE,
	ACCESS_REVOKE_DONE,
	GET_GROUP_USER,
	GET_GROUP_USER_DONE,
}                       from '../../reducers/system/AccessReducer';
import { success }      from './ToastAction';
import { ready, reset } from '../CommonAction';
import { fetch }        from '../RealmAction';

export const fetchGroup = () => {
	return (dispatch, getState) => {
		dispatch({ type: ACCESS_GROUP_FETCH });

		console.log(getState())

		return rest('User/Group/' + getState().access.access.user_group)
			.then(d => {
				dispatch({ type: ACCESS_GROUP_FETCH_DONE, group: d.data });
				return d.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const revokeAccess = (userId) => {
	return (dispatch, getState) => {
		dispatch({ type: ACCESS_REVOKE });
		return rest('User/Group/' + getState().access.access.user_group + ':delMember', 'POST', { user: userId })
			.then(d => {
				dispatch({ type: ACCESS_REVOKE_DONE });
				success('access_revoke_success');
				return d.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const leaveGroup = () => {
	return (dispatch, getState) => {
		dispatch({ type: ACCESS_LEAVE });
		return rest('User/Group/' + getState().access.access.user_group + ':leave', 'POST')
			.then(() => {
				return dispatch(fetch());
			})
			.then(d => {
				dispatch({ type: ACCESS_LEAVE_DONE });
				// Reset the app as we no longer have access
				dispatch(reset(true));
				dispatch(ready());
				success('group_left_success');
				return d.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const grantAccess = (userId, access) => {
	return (dispatch, getState) => {
		dispatch({ type: ACCESS_GRANT });
		return rest('User/Group/' + getState().access.access.user_group + ':addMember', 'POST', {
			user: userId,
			access: access
		})
			.then(d => {
				dispatch({ type: ACCESS_GRANT_DONE });
				success('access_grand_success');
				return d.data;
			})
			.catch((err) => {
				dispatch({ type: ACCESS_GRANT_DONE });
				handleError(getState, dispatch, err);
			});
	};
};

export const getGroupUser = (user, gId = null) => {
	return (dispatch, getState) => {
		dispatch({ type: GET_GROUP_USER });


		console.log(gId)

		const groupId = gId !== null ? gId : getState().access.access.user_group;

		return rest('User/Group/' + groupId + ':getMember', 'GET', {
			user: user,
		})
			.then(d => {
				dispatch({ type: GET_GROUP_USER_DONE });
				return d;
			})
			.catch((err) => {
				dispatch({ type: GET_GROUP_USER_DONE });
				return err;
			});
	};
};


export const getInviteLink = (objectId, access) => {
	return (dispatch, getState) => {
		dispatch({ type: ACCESS_GRANT });
		return rest('MetaObject/' + objectId + ':linkAccess', 'POST', {
			expires: '+24 hour',
			type: 'permuser',
			access: access
		})
			.then(d => {
				dispatch({ type: ACCESS_GRANT_DONE });
				success('access_link_success');
				return d.data;
			})
			.catch((err) => {
				dispatch({ type: ACCESS_GRANT_DONE });
				handleError(getState, dispatch, err);
			});
	};
};

export const decodeInviteLink = (link) => {
	return (dispatch, getState) => {
		dispatch({ type: ACCESS_DECODE_LINK });
		return rest('MetaObject/Link:decode', 'GET', { id: link, })
			.then(d => {
				dispatch({ type: ACCESS_DECODE_LINK_DONE });
				return d.data;
			})
			.catch((err) => {
				dispatch({ type: ACCESS_DECODE_LINK_DONE });
				handleError(getState, dispatch, err);
			});
	};
};

export const acceptInviteLink = (link) => {
	return (dispatch, getState) => {
		dispatch({ type: ACCESS_ACCEPT_LINK });
		return rest('MetaObject:linkJoin', 'POST', { id: link, })
			.then(d => {
				dispatch({ type: ACCESS_ACCEPT_LINK_DONE });
				return d.data;
			})
			.catch((err) => {
				dispatch({ type: ACCESS_ACCEPT_LINK_DONE });
				handleError(getState, dispatch, err);
			});
	};
};

