import {initFlow} from "../../store/actions/system/FlowActions";
import {USER_LOGOUT} from "../../store/reducers/system/UserReducer";
import {error as errorToast} from "../../store/actions/system/ToastAction";

export const handleError = (getState, dispatch, error, silent = false) => {
    let reThrow = true;
    switch (error.token) {
        case 'error_login_required':
            dispatch(initFlow());
            dispatch({type: USER_LOGOUT});
            break;
        case 'invalid_request_token':
            reThrow = false;
            if (typeof window !== 'undefined')
                window.location.reload();
            break;
        default:
        	if(!silent) errorToast(error.message ?? error.error)
            break;
    }

    if (reThrow) throw error;
};
