import React, {useEffect, useState} from 'react';
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import {useTranslation} from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import defaultPicture from "../../../resources/images/landscape-image.svg";
import SnsLinks from "../../common/data/SnsLink/SnsLinks";
import Button from "../../common/inputs/Button";

const useStyles = makeStyles({
    media: {
        height: 140,
    },
    fillHeight: {
        height: '100%'
    }
});

const Member = ({member, onDelete, onEdit, viewOnly = false}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const [imgLink, setImgLink] = useState('');

    useEffect(() => {
        if (!member.picture) {
            setImgLink(defaultPicture);
            return;
        }
        const fr = new FileReader();
        fr.onload = function () {
            setImgLink(fr.result);
        };
        fr.readAsDataURL(member.picture);
    }, [member]);

    return (
        <Card className={classes.fillHeight} variant='outlined'>
            {imgLink && <CardMedia
                className={classes.media}
                image={imgLink}
                title={t('member_avatar')}
            />}
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item>
                        <Typography gutterBottom variant="h5" component="h2">
                            {member.name['en-US']}
                        </Typography>
                    </Grid>
                    {('ja-JP' in member.name) &&
                    <Grid item>
                        <Typography component="span" variant="h5" color="textSecondary">
                            {member.name['ja-JP']}
                        </Typography>
                    </Grid>}

                    {('kana' in member.name) &&
                    <Grid item>
                        <Typography component="span" variant="h5" color="textSecondary">
                            {member.name['kana']}
                        </Typography>
                    </Grid>}
                </Grid>

                {member.birthdate &&
                <Typography variant="body2" color="textSecondary" component="p">
                    {t('member_birthdate', {date: member.birthdate.format('YYYY/MM/DD')})}
                </Typography>
                }
                {member.bloodType &&
                <Typography variant="body2" color="textSecondary" component="p">
                    {t('member_bloodtype', {type: member.bloodType})}
                </Typography>
                }

                <SnsLinks values={member.links} viewMode={true}/>

            </CardContent>
            {!viewOnly && <CardActions>
                <Grid container justify='space-between' spacing={3}>
                    <Grid item>
                        <Button size="small" color="secondary" onClick={onDelete}>
                            {t('delete_btn')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button size="small" color="primary" variant='contained' onClick={onEdit}>
                            {t('save_btn')}
                        </Button>
                    </Grid>
                </Grid>
            </CardActions>}
        </Card>
    );
};

export default Member;
