import React from 'react';
import {matchPath, useLocation} from "react-router-dom";
import {Tooltip} from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Link from "../../common/typography/Link";

const MenuNavLink = (props) => {
    const {icon, primary, path, tooltip, disabled, onClick} = props;
    const location = useLocation()

    //https://material-ui.com/guides/composition/#caveat-with-inlining
    const renderLink = React.useMemo(
        () =>
            React.forwardRef((linkProps, ref) => (
                <Link ref={ref} to={path} {...linkProps} />
            )),
        [path],
    );

    const match = matchPath(location.pathname, {...props});
    const selected = !!match;

    return (
        <Tooltip title={tooltip} placement="right" arrow>
            <MenuItem button component={renderLink} selected={selected} disabled={disabled} onClick={onClick}>
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                <ListItemText primary={primary}/>
            </MenuItem>
        </Tooltip>
    );
};

export default MenuNavLink;