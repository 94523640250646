import {COMMON_RESET} from "../CommonReducer";
import {USER_LOGOUT} from "../system/UserReducer";

const initState = () => {
    return {
        historyLoading: false,
        history: [],
        historyFilters: {},
        historyPagingCount: 0,
        historyPaging: {
            page_no: 1,
            results_per_page: 10
        },
    }
};

export const MAIL_HISTORY_LIST_FETCH = 'MAIL_HISTORY_LIST_FETCH';
export const MAIL_HISTORY_LIST_FETCH_DONE = 'MAIL_HISTORY_LIST_FETCH_DONE';
export const MAIL_HISTORY_SET_PAGING = 'MAIL_HISTORY_SET_PAGING';
export const MAIL_HISTORY_SET_FILTERS = 'MAIL_HISTORY_SET_FILTERS';

const mailReducer = (state = initState(), action) => {
    switch (action.type) {
        case COMMON_RESET:
        case USER_LOGOUT:
            return initState();
        case MAIL_HISTORY_LIST_FETCH:
            return {...state, historyLoading: true};
        case MAIL_HISTORY_LIST_FETCH_DONE:
            return {
                ...state,
                historyLoading: false,
                history: action.history,
                historyPagingCount: action.paging.count
            };
        case MAIL_HISTORY_SET_FILTERS :
            return {...state, historyFilters: action.filters};
        case MAIL_HISTORY_SET_PAGING :
            return {...state, historyPaging: action.paging};
        default:
            return state;
    }

};

export default mailReducer