import React             from 'react';
import Grid              from '@material-ui/core/Grid';
import AppBarTitle       from '../../../components/common/typography/AppBarTitle';
import {useTranslation}  from 'react-i18next';
import {connect}         from 'react-redux';
import SelectRealm       from '../../SelectRealm';
import Form              from '../../../components/common/member/Form';
import HasRight          from '../../../components/Route/HasRight';
import Redirect          from '../../../components/Route/Redirect';
import Toolbar           from '../../../components/common/typography/Toolbar';
import {getMembersRoute} from '../../../router/routes/artist/factory/members';

const Create = ({selectedRealm}) => {
	const {t} = useTranslation();

	if (!selectedRealm) return <SelectRealm/>;

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('member_create_title')}/>

			<Grid item xs={12}>
				<Toolbar title={t('member_create_title')}/>
			</Grid>
			<HasRight admin deniedComponent={<Redirect target={getMembersRoute()}/>}>
				<Grid item xs={12}>
					<Form/>
				</Grid>
			</HasRight>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);