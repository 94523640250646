import React, {useEffect, useState} from 'react';
import Dialog                       from '@material-ui/core/Dialog';
import DialogTitle                  from '@material-ui/core/DialogTitle';
import DialogContent                from '@material-ui/core/DialogContent';
import Grid                         from '@material-ui/core/Grid';
import TextField                    from '@material-ui/core/TextField';
import DialogActions                from '@material-ui/core/DialogActions';
import Button                       from '../Button';
import {useTranslation}             from 'react-i18next';
import {Box}                        from '@material-ui/core';
import {connect}                    from 'react-redux';
import {createAuthor}               from '../../../../store/actions/AuthorAction';

const CreateDialog = ({open, setOpen, cmsId, creating, create, onCreated}) => {
	const {t} = useTranslation();
	const [name, setName] = useState('');
	const [saveEnabled, setSaveEnable] = useState(false);

	useEffect(() => {
		if (name.trim()) {
			setSaveEnable(true);
			return;
		}

		setSaveEnable(false);
	}, [name, setSaveEnable]);

	const handleClose = () => {
		setOpen(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		e.stopPropagation();
		create(cmsId, name).then((d) => {
			onCreated(d);
			handleClose();
		});
	};

	return (
		<Dialog
			open={open}
			aria-labelledby={t('create_author')}
			aria-describedby={t('create_author')}
			fullWidth
			maxWidth="sm"
			onEnter={() => {
				setName('');
			}}
		>
			<form onSubmit={handleSubmit}>
				<DialogTitle id="create-author-title">{t('create_author')}</DialogTitle>
				<DialogContent>
					<Box py={2}>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<TextField
									disabled={creating}
									variant="outlined"
									fullWidth
									label={t('name_lbl')}
									required
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</Grid>
						</Grid>
					</Box>

				</DialogContent>
				<DialogActions>
					<Button color="default" onClick={handleClose} disabled={creating}>
						{t('close_btn')}
					</Button>
					<Button color="primary" variant="contained" loading={creating} disabled={creating || !saveEnabled}
					        type="submit">
						{t('create_btn')}
					</Button>
				</DialogActions>
			</form>
		</Dialog>

	);
};

const mapStateToProps = (state) => {
	return {
		creating: state.author.creating,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		create: (cmsId, name) => dispatch(createAuthor(cmsId, name)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDialog);
