import React, {useState} from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import {connect}                 from 'react-redux'
import { Trans, useTranslation } from 'react-i18next';
import makeStyles                from "@material-ui/core/styles/makeStyles";
import {updateMembership} from "../../../../../store/actions/fanclub/MembershipAction";
import Button from "../../../inputs/Button";

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

const PriceEditDialog = ({membershipUser, open, setOpen, changePrice, updating}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const handleClose = () => {
        setOpen(false);
    };

    const [submitDisable, setSubmitDisable] = useState(true);
    const [price, setPrice] = useState(membershipUser ? membershipUser.Price.tax.value_disp : '');


    const handleSubmit = (e) => {
        e.preventDefault();
        changePrice(price).then(handleClose)
    };

    const handleChange = e => {
        setPrice(e.target.value);
        setSubmitDisable(membershipUser.Price.tax.value_disp === e.target.value)
    };

    return (
        <Dialog
            open={open}
            aria-labelledby={t('edit_price')}
            aria-describedby={t('edit_price')}
            onEnter={() => {
                setPrice(membershipUser ? membershipUser.Price.tax.value_disp : '')
            }}
        >
            <form onSubmit={handleSubmit}>
                <DialogTitle id="cancel-membership-title">{t('membership_price_editing')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="cancel-membership-description">
	                    <Trans i18nKey='membership_price_editing_desc'>
		                    sample<br/>
		                    <strong>br</strong>
	                    </Trans>
                    </DialogContentText>

                    <TextField
                        label={t('membership_price')}
                        value={price}
                        onChange={handleChange}
                        variant="outlined"
                        type='number'
                        required
                        inputProps={{max: membershipUser ? membershipUser.Price.tax.value_disp : '', min: 0}}
                        className={classes.formControl}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="primary" autoFocus onClick={handleClose} disabled={updating}>
                        {t('back_btn')}
                    </Button>
                    <Button variant='contained' type='submit' color="primary" loading={updating} disabled={submitDisable || updating}>
                        {t('save_btn')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {
        updating: state.membership.membershipUpdating,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        changePrice: (price) => dispatch(updateMembership({Price: price})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceEditDialog);
