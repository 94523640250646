import React, { useEffect, useState }         from 'react';
import LayoutLess                             from '../../components/layout/LayoutLess';
import { Trans, useTranslation }              from 'react-i18next';
import Grid                                   from '@material-ui/core/Grid';
import { Title }                              from '../../components/common/typography/Title';
import { useParams }                          from 'react-router';
import { connect }                            from 'react-redux';
import { acceptInviteLink, decodeInviteLink, getGroupUser } from '../../store/actions/system/AccessAction';
import Loading                                from '../../components/common/feeback/loading/Loading';
import { Avatar }                             from '@material-ui/core';
import Button                                 from '../../components/common/inputs/Button';
import { Link, useHistory }                   from 'react-router-dom';
import { getPXLDashboardRoute }               from '../../router/routes/pxl/factory/general';
import { success }                            from '../../store/actions/system/ToastAction';
import Alert                                  from '@material-ui/lab/Alert';
import { ready, reset }                       from '../../store/actions/CommonAction';
import { fetchArtist }                        from '../../store/actions/ArtistAction';

const Join = ({ decode, decoding, accepting, accept, reset, ready,fetchArtist, user, getGroupUser }) => {
	const { t } = useTranslation();
	const history = useHistory();

	const { groupLink, artistLink } = useParams();

	const [decodedGroup, setDecodedGroup] = useState();
	const [decodedArtist, setDecodedArtist] = useState();

	const [invalidLink, setInvalidLink] = useState(false);
	const [emailIndexOf, setEmailIndexOf] = useState(null);

	useEffect(() => {
		if (!groupLink || !artistLink) return;
		decode(groupLink).then(d => {
			setDecodedGroup(d);
		})
			.then(() => fetchArtist(artistLink))
			.then(d => {
				setDecodedArtist(d);
			})
			.catch(() => {
				setInvalidLink(true);
			});
		
	}, [groupLink, artistLink, setDecodedArtist, setDecodedGroup, decode, fetchArtist]);

	const handleAccept = () => {
		accept(groupLink)
			.then(() => {
				// This will force to reload the realm list etc
				reset()
				ready()
				success('workgroup_link_accept_success');
				history.push(getPXLDashboardRoute());
			});
	};


	useEffect(() => {

		if(decodedGroup === void 0) return;

getGroupUser(user.Email, decodedGroup.Object.User_Group__)
			.then((data) => {
					setEmailIndexOf(data);
			}).catch((err) => {
					setEmailIndexOf(err);
			});


	}, [decodedGroup, getGroupUser, user.Email])

	return (
		<LayoutLess title={t('join_page_title')}>
			<Grid container
			      direction='column'
			      justify='center'
			      alignItems='center'
			      spacing={5}
			>
				<Grid item>
					<Title>{t('join_page_title')}</Title>
				</Grid>

				{invalidLink && <>
					<Grid item>
						<Alert severity='warning'>
							{t('invalid_invite_link')}
						</Alert>
					</Grid>
					<Grid item>
						<Button
							variant='outlined'
							component={Link}
							to={getPXLDashboardRoute()}
						>
							{t('back_btn')}
						</Button>
					</Grid>
				</>}
				{((!decodedArtist || !decodedGroup || decoding) && !invalidLink) &&
				<Grid item xs={12}><Loading/></Grid>}
				{(!decoding && decodedGroup && decodedArtist) && <>

					{emailIndexOf !== null && emailIndexOf.result === "success"&&<Grid item xs={12} style={{ color:"red", fontWeight: "bold" }}>
						<span style={{ display:'inline-block', textAlign: "center", margin: "0 20px"}}>{t("group_user_already_registered_your")}</span>
					</Grid>}
					<Grid item xs={12}>
						<Trans i18nKey='join_page_desc' values={{ access: t(`access_right_${decodedGroup.Access}`) }}>
							sample<br/>
							<strong>sample</strong>
						</Trans>
					</Grid>
					<Grid item>
						<Avatar
							style={{
								height: '150px',
								width: '150px',
							}}
							alt={decodedArtist.Name}
							src={decodedArtist.Image_Drive_Item.Icon}
						/>
						<Title align='center'>{decodedArtist.Name}</Title>
					</Grid>
					<Grid item xs={12} alignItems='stretch'>
						<Grid container justify='space-around' spacing={3}>
							<Grid item>
								<Button
									disabled={accepting}
									variant='outlined'
									component={Link}
									to={getPXLDashboardRoute()}
								>
									{t('back_btn')}
								</Button>
							</Grid>
							<Grid item>
								<Button
									onClick={handleAccept}
									disabled={accepting}
									variant='contained'
									color='primary'
									loading={accepting}
								>
									{t('accept_invite_btn')}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</>}

			</Grid>
		</LayoutLess>
	);
};

const mapStateToProps = (state) => {
	return {
		decoding: state.access.decodingLink,
		accepting: state.access.acceptingLink,
		user: state.user.user,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		decode: (data) => dispatch(decodeInviteLink(data)),
		fetchArtist: (data) => dispatch(fetchArtist(data)),
		accept: (data) => dispatch(acceptInviteLink(data)),
		getGroupUser: (user, gId) => dispatch(getGroupUser(user, gId)),
		reset: () => dispatch(reset(true)),
		ready: () => dispatch(ready()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Join);
