import React from 'react';
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import Skeleton from "@material-ui/lab/Skeleton";
import Card from "@material-ui/core/Card";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslation} from 'react-i18next';


const useStyles = makeStyles(theme => ({
    card: {
        whiteSpace: 'pre-wrap',
        height: '100%'
    }
}));

const Details = ({order}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Card className={classes.card} variant='outlined'>
            <CardContent>
                <Typography gutterBottom variant="h5"
                            component="h2">{t('details')}</Typography>


                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item xs={12}>
                        <Typography variant="body1" color="textPrimary" component="p">
                        {t('status_lbl')}:</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        {
                            order &&
                            <Typography variant="body2" color="textSecondary" component="p">
                                {t('order_status_' + order.Status)}
                            </Typography>
                        }
                        {!order && <Skeleton/>}
                    </Grid>


                    <Grid item xs={12}>
                        <Typography variant="body1" color="textPrimary" component="p">
                        {t('order_date')}:</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        {
                            order &&
                            <Typography variant="body2" color="textSecondary" component="p">
                                {moment(parseInt(order.Created.unixms)).format('LLL')}
                            </Typography>
                        }
                        {!order && <Skeleton/>}
                    </Grid>


                    <Grid item xs={12}>
                        <Typography variant="body1" color="textPrimary" component="p">
                            {t('paid_date')}:</Typography>
                    </Grid>


                    <Grid item xs={12}>
                        {
                            order &&
                            <Typography variant="body2" color="textSecondary" component="p">
                                {
                                    order.Paid ?
                                        moment(parseInt(order.Paid.unixms)).format('LLL')
                                        : t('not_paid_status')
                                }
                            </Typography>
                        }
                        {!order && <Skeleton/>}
                    </Grid>

                </Grid>

            </CardContent>
        </Card>
    );
};

export default Details;