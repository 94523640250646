import React from 'react';
import TableRow from "@material-ui/core/TableRow";
import Skeleton from "@material-ui/lab/Skeleton";
import TableCell from "@material-ui/core/TableCell";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import defaultPicture from "../../../../../resources/images/landscape-image.svg";
import {PRODUCT_LIST_MAIN_IMAGE_VARIATION} from "../../../../../store/reducers/store/ProductReducer";
import Button from "../../../inputs/Button";
import {getStoreProductEditRoute} from "../../../../../router/routes/store/factory/store";
import EditIcon from '@material-ui/icons/Edit';


const Row = ({product, loading}) => {
    const displayProduct = (!loading && product);
    const {t} = useTranslation();
    return (
        <TableRow>
            {/*<TableCell style={{width:'40px'}}>
                {!displayProduct && <Skeleton variant="circle" width={40} height={40}/>}
                {(displayProduct && product.Visible === 'Y' && product._sold) && <AttachMoneyIcon/>}
                {(displayProduct && (product.Visible === 'N' || !product._sold)) && <MoneyOffIcon/>}
            </TableCell>*/}
            <TableCell style={{width: '75px'}}>
                {!displayProduct && <Skeleton/>}
                {(displayProduct && product.Image.main) &&
                <img width={75} src={product.Image.main[0].Variation[PRODUCT_LIST_MAIN_IMAGE_VARIATION]}
                     alt={t('tablecell_product_picture')}/>}
	            {(displayProduct && !product.Image.main &&  product.Image.list &&  product.Image.list.length > 0) &&
		            <img width={75} src={product.Image.list[0].Variation[PRODUCT_LIST_MAIN_IMAGE_VARIATION]}
		            alt={t('tablecell_product_picture')}/>}
                {(displayProduct && !product.Image.main &&  (!product.Image.list || product.Image.list.length < 1)) &&
                <img width={75} src={defaultPicture} alt={t('tablecell_product_picture')}/>}
            </TableCell>
            <TableCell>
                {!displayProduct && <Skeleton/>}
                {displayProduct && <span>{product.Internal_Name}</span>}
            </TableCell>

            <TableCell>
                {!displayProduct && <Skeleton/>}
                {displayProduct && <span>{product['Basic.Name']}</span>}
            </TableCell>

            <TableCell align="right">
                {!displayProduct && <Skeleton/>}
                {(displayProduct) && <Button
                    variant="contained"
                    color="primary"
                    startIcon={<EditIcon/>}
                    component={Link} to={getStoreProductEditRoute(product.Catalog_Product__)}
                >
                    {t('edit_btn')}
                </Button>}

            </TableCell>

        </TableRow>
    );
};

export default Row;
