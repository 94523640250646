import React, {useState} from 'react';
import {connect}         from 'react-redux';
import {useTranslation}  from 'react-i18next';
import SelectRealm       from '../../SelectRealm';
import Grid              from '@material-ui/core/Grid';
import Button            from '../../../components/common/inputs/Button';
import HasRight          from '../../../components/Route/HasRight';
import AppBarTitle       from '../../../components/common/typography/AppBarTitle';
import AddIcon           from '@material-ui/icons/Add';
import StripeRequired    from '../../../components/common/stripe/StripeRequired';
import Toolbar           from '../../../components/common/typography/Toolbar';
import Table             from '../../../components/common/planner/event/table/Table';
import CreateDialog      from '../../../components/common/planner/event/CreateDialog';
import graphic           from '../../../resources/images/event.svg';
import ScheduleActivator from '../../../components/common/planner/ScheduleActivator';


const List = ({selectedRealm, planner}) => {
	const {t} = useTranslation();
	const [showCreate, setShowCreate] = useState(false);

	if (!selectedRealm || !planner) return <SelectRealm/>;

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('events_title')}/>
			<StripeRequired
				graphic={graphic}
				titleKey="event_wizard_title"
				descriptionKey="event_wizard_desc"
			>
				<Grid item xs={12}>
					<Toolbar title={t('events_title')}>
						<HasRight manager hideLoading>
							<Button endIcon={<AddIcon/>} variant="contained" onClick={e => setShowCreate(true)}
							        color="primary">
								{t('create_btn')}
							</Button>
						</HasRight>
					</Toolbar>
				</Grid>
				<ScheduleActivator/>
				<Grid item xs={12}>
					<Table/>
				</Grid>
			</StripeRequired>
			{planner && <CreateDialog setOpen={setShowCreate} open={showCreate} cmsId={planner.Content_Cms__}/>}
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		planner: state.schedule.planner,
	};
};

export default connect(mapStateToProps)(List);
