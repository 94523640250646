import PropTypes from 'prop-types';
import React     from 'react';
import { rest }  from '@karpeleslab/klbfw';
import ErrorPage from './ErrorPage';

class ErrorBoundary extends React.Component {

	constructor(props) {
		super(props);
		this.state = { hasError: false, errorUUID: null, errorMessage: null };
	}

	static getDerivedStateFromError(/*error*/) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// Can log error here
		console.log(error.message, errorInfo, error.stack, error.messageData);
		rest('Debug:log_js_error', 'POST', {
			errorMsg: error.message,
			url: typeof window !== 'undefined' ? window.location.href : '',
			lineNumber: 0,
			stack: error.stack
		}).then(d => {
			this.setState({ ...this.state, errorUUID: d.data.id });
		}).catch(console.log);
	}

	componentWillUnmount() {
		this.setState( { hasError: false, errorUUID: null, errorMessage: null });
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return <ErrorPage errorID={this.state.errorUUID}/>;
		}

		return this.props.children;
	}
}

export default ErrorBoundary;

ErrorBoundary.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]),
};
