const initState = () => {
    return {
        sending: false,
    }
};

export const CONTACT_SEND = 'CONTACT_SEND';
export const CONTACT_SEND_DONE = 'CONTACT_SEND_DONE';

const contactReducer = (state = initState(), action) => {
    switch (action.type) {
        case CONTACT_SEND:
            return {...state, sending: true};
        case CONTACT_SEND_DONE:
            return {...state, sending: false};
        default:
            return state;
    }

};

export default contactReducer