import React, {useState} from 'react';
import Dialog            from '@material-ui/core/Dialog';
import DialogTitle               from '@material-ui/core/DialogTitle';
import DialogContent             from '@material-ui/core/DialogContent';
import DialogContentText         from '@material-ui/core/DialogContentText';
import DialogActions             from '@material-ui/core/DialogActions';
import {connect}                 from 'react-redux';
import {useHistory}               from 'react-router-dom';
import Typography                 from '@material-ui/core/Typography';
import {Trans, useTranslation}    from 'react-i18next';
import Button                     from '../../inputs/Button';
import FileCopyIcon               from '@material-ui/icons/FileCopy';
import {duplicateSchedule}        from '../../../../store/actions/planner/ScheduleAction';
import moment                     from 'moment';
import {Box, Grid}                from '@material-ui/core';
import DateTime                                          from '../../inputs/DateTime';
import {getSiteEventEditRoute, getSiteScheduleEditRoute} from '../../../../router/routes/site/factory/planner';

const DuplicateDialog = ({open, setOpen, duplicating, schedule, duplicateSchedule, setTabIndex, isEvent = false}) => {
	let history = useHistory();
	const {t} = useTranslation();
	const [date, setDate] = useState(moment(parseInt(schedule.Date.unixms)));
	const handleClose = () => {
		setOpen(false);
	};

	const handleDuplicate = (e) => {
		e.stopPropagation();
		e.preventDefault();

		duplicateSchedule(schedule.Planner_Schedule__, '@' + date.unix(), t('copy_suffix'), isEvent)
			.then(schedule => {
				handleClose();
				if(schedule.Planner_Event__)
					history.push(getSiteEventEditRoute(schedule.Planner_Event__));
				else
					history.push(getSiteScheduleEditRoute(schedule.Planner_Schedule__));
				setTabIndex(0)
			});
	};

	return (
		<Dialog
			open={open}
			aria-labelledby="Duplicate schedule"
			aria-describedby="Duplicate schedule"
			onEnter={() => {
				setDate(moment(parseInt(schedule.Date.unixms)));
			}}
		>
			<DialogTitle id="duplicate-schedule-title">{
				isEvent ?
					t('event_duplicate_title') :
				t('schedule_duplicate_title')
			}</DialogTitle>
			<DialogContent>
				<Box py={2}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<DialogContentText id="duplicate-schedule-description">
								<Trans i18nKey={isEvent ? 'event_duplicate_text' : 'schedule_duplicate_text'}>
									sample<br/>
									<strong>sample</strong>
								</Trans>
								<br/>
								<Typography variant="body1" color="textPrimary" component="span">{t('are_you_sure')}</Typography>
							</DialogContentText>
						</Grid>
						<Grid item xs={12}>
							<DateTime
								label={t('schedule_date_lbl')}
								inputVariant="outlined"
								value={date}
								disabled={duplicating}
								onChange={setDate}
								helperText={t('schedule_date_helperText')}
								fullWidth
							/>
						</Grid>
					</Grid>
				</Box>

			</DialogContent>
			<DialogActions>
				<Button color="primary" autoFocus onClick={handleClose} disabled={duplicating}>
					{t('back_btn')}
				</Button>
				<Button color="primary" disabled={duplicating} variant='contained' loading={duplicating} endIcon={<FileCopyIcon/>}
				        onClick={handleDuplicate}>
					{t('duplicate_btn')}
				</Button>
			</DialogActions>

		</Dialog>

	);
};

const mapStateToProps = (state) => {
	return {
		duplicating: state.schedule.duplicating,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		duplicateSchedule: (scheduleDate, date, suffix, isEvent) => dispatch(duplicateSchedule(scheduleDate, date, suffix, isEvent)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateDialog);
