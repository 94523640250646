import {COMMON_RESET}    from "../CommonReducer";
import {USER_LOGOUT}     from "../system/UserReducer";
import {TAG_DELETE_DONE} from '../TagReducer';

const initState = () => {
    return {
        planner: null,
        loadingPlanner: false,

        schedulesLoading: false,
        schedules: [],
        schedulesFilters: {},
        schedulesPagingCount: 0,
        schedulesPaging: {
            page_no: 1,
            results_per_page: 10
        },

        duplicating: false,
        creating: false,
        updating: false,
        deleting: false,
        schedule: null,
        scheduleLoading: false,

        scheduleManagingTags: false,
        selectedTagsLoading: false,
        selectedTags: []
    }
};

export const SCHEDULE_MANAGE_TAGS = 'SCHEDULE_MANAGE_TAGS';
export const SCHEDULE_MANAGE_TAGS_DONE = 'SCHEDULE_MANAGE_TAGS_DONE';

export const SCHEDULE_TAGS_FETCH = 'SCHEDULE_TAGS_FETCH';
export const SCHEDULE_TAGS_FETCH_DONE = 'RELEASE_TAGS_FETCH';

export const SCHEDULE_PLANNER_FETCH = 'SCHEDULE_PLANNER_FETCH';
export const SCHEDULE_PLANNER_FETCH_DONE = 'SCHEDULE_PLANNER_FETCH_DONE';


export const SCHEDULE_LIST_FETCH = 'SCHEDULE_LIST_FETCH';
export const SCHEDULE_LIST_FETCH_DONE = 'SCHEDULE_LIST_FETCH_DONE';
export const SCHEDULE_FETCH = 'SCHEDULE_FETCH';
export const SCHEDULE_FETCH_DONE = 'SCHEDULE_FETCH_DONE';
export const SCHEDULE_SET_PAGING = 'SCHEDULE_SET_PAGING';
export const SCHEDULE_SET_FILTERS = 'SCHEDULE_SET_FILTERS';
export const SCHEDULE_CREATE = 'SCHEDULE_CREATE';
export const SCHEDULE_CREATE_DONE = 'SCHEDULE_CREATE_DONE';
export const SCHEDULE_UPDATE = 'SCHEDULE_UPDATE';
export const SCHEDULE_UPDATE_DONE = 'SCHEDULE_UPDATE_DONE';
export const SCHEDULE_DELETE = 'SCHEDULE_DELETE';
export const SCHEDULE_DELETE_DONE = 'SCHEDULE_DELETE_DONE';

export const SCHEDULE_DUPLICATING = 'SCHEDULE_DUPLICATING';
export const SCHEDULE_DUPLICATING_DONE = 'SCHEDULE_DUPLICATING_DONE';

const scheduleReducer = (state = initState(), action) => {
    switch (action.type) {
        case COMMON_RESET:
        case  USER_LOGOUT:
            return initState();
        case SCHEDULE_TAGS_FETCH:
            return {...state, selectedTagsLoading: true};
        case SCHEDULE_TAGS_FETCH_DONE:
            return {...state, selectedTagsLoading: false, selectedTags: action.tags};
        case SCHEDULE_MANAGE_TAGS:
            return {...state, scheduleManagingTags: true};
        case SCHEDULE_MANAGE_TAGS_DONE:
            return {...state, scheduleManagingTags: false, schedule: action.schedule};
        case TAG_DELETE_DONE:
            if(!state.schedule) return {...state};
            const tmp = {...state.schedule}
            tmp.Tags =  tmp.Tags.filter(t => t.Classify_Tag__ !== action.tagId)
            return {...state, schedule: tmp};
        case SCHEDULE_PLANNER_FETCH:
            return {...state, loadingPlanner: true};
        case SCHEDULE_PLANNER_FETCH_DONE:
            return {...state, loadingPlanner: false, planner: action.planner};
        case SCHEDULE_DELETE:
            return {...state, deleting: true};
        case SCHEDULE_DELETE_DONE:
            return {...state, deleting: false};
        case SCHEDULE_DUPLICATING:
            return {...state, duplicating: true};
        case SCHEDULE_DUPLICATING_DONE:
            return {...state, duplicating: false};
        case SCHEDULE_UPDATE:
            return {...state, updating: true};

        case SCHEDULE_UPDATE_DONE:
            if (!action.schedule) return {...state, updating: false};
            return {...state, updating: false, schedule: action.schedule};
        case SCHEDULE_CREATE:
            return {...state, creating: true};
        case SCHEDULE_CREATE_DONE:
            return {...state, creating: false};
        case SCHEDULE_FETCH:
            return {...state, scheduleLoading: true};
        case SCHEDULE_FETCH_DONE:
            return {...state, scheduleLoading: false, schedule: action.schedule};

        case SCHEDULE_LIST_FETCH:
            return {...state, schedulesLoading: true};
        case SCHEDULE_LIST_FETCH_DONE:
            return {
                ...state,
                schedulesLoading: false,
                schedules: action.schedules,
                schedulesPagingCount: action.paging.count
            };
        case SCHEDULE_SET_FILTERS :
            return {...state, schedulesFilters: action.filters};
        case SCHEDULE_SET_PAGING :
            return {...state, schedulesPaging: action.paging};
        default:
            return state;
    }

};

export default scheduleReducer
