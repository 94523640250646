import {COMMON_RESET} from "./CommonReducer";
import {USER_LOGOUT} from "./system/UserReducer";

const initState = () => {
    return {
        label: null,
        loadingLabel: false,
        loadingGenres: false,
        loadingMoods: false,
	    creating:false,
        genres: [],
        moods:[],

        deleting: false,
    }
};

export const MUSIC_GENRE_FETCH = 'MUSIC_GENRE_FETCH';
export const MUSIC_GENRE_FETCH_DONE = 'MUSIC_GENRE_FETCH_DONE';

export const MUSIC_MOOD_FETCH = 'MUSIC_MOOD_FETCH';
export const MUSIC_MOOD_FETCH_DONE = 'MUSIC_MOOD_FETCH_DONE';

export const MUSIC_LABEL_FETCH = 'MUSIC_LABEL_FETCH';
export const MUSIC_LABEL_FETCH_DONE = 'MUSIC_LABEL_FETCH_DONE';

export const MUSIC_CREATE_ARTIST = 'MUSIC_CREATE_ARTIST';
export const MUSIC_CREATE_ARTIST_DONE = 'MUSIC_CREATE_ARTIST_DONE';

export const MUSIC_DELETE_ARTIST = 'MUSIC_DELETE_ARTIST';
export const MUSIC_DELETE_ARTIST_DONE = 'MUSIC_DELETE_ARTIST_DONE';

const musicReducer = (state = initState(), action) => {
    switch (action.type) {
        case COMMON_RESET:
        case USER_LOGOUT:
            return initState();
        case MUSIC_DELETE_ARTIST:
            return {...state, deleting: true};
        case MUSIC_DELETE_ARTIST_DONE:
            return {...state, deleting: false};
	    case MUSIC_CREATE_ARTIST:
		    return {...state, creating: true};
	    case MUSIC_CREATE_ARTIST_DONE:
		    return {...state, creating: false};
        case MUSIC_LABEL_FETCH:
            return {...state, loadingLabel: true};
        case MUSIC_LABEL_FETCH_DONE:
            return {...state, loadingLabel: false, label: action.label};
        case MUSIC_GENRE_FETCH:
            return {...state, loadingGenres: true};
        case MUSIC_GENRE_FETCH_DONE:
            return {...state, loadingGenres: false, genres: action.genres};
        case MUSIC_MOOD_FETCH:
            return {...state, loadingMoods: true};
        case MUSIC_MOOD_FETCH_DONE:
            return {...state, loadingMoods: false, moods: action.moods};
        default:
            return state;
    }

};

export default musicReducer
