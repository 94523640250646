import React, {useEffect} from 'react';
import {connect} from 'react-redux'
import {fetchQuestionStats} from "../../../../../store/actions/planner/QuestionAction";
import Loading from "../../../feeback/loading/Loading";
import Grid from "@material-ui/core/Grid";
import {Title} from "../../../typography/Title";
import {useTranslation} from "react-i18next";
import ActivePieChart from "../../../data/charts/pie/ActivePieChart";
import {COLOR_LIST} from "../../../../utils/colors";
import PieChartNoData from "../../../data/charts/pie/PieChartNoData";
import {Table, TableHead, TableRow} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import {TableContainer} from "../../../data/charts/pie/style";
import EmptyReport from "./EmptyReport";

const Report = ({event, loading, stats, fetchQuestionStats}) => {
    const {t} = useTranslation();
    useEffect(() => {
        fetchQuestionStats(event.Planner_Event__);
    }, [event, fetchQuestionStats])

    if (loading) return <Loading/>

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Title>
                    {t('event_survey_stats_title')}
                </Title>
            </Grid>

            {stats.length < 1 &&
            <Grid item xs={12}>
                <EmptyReport/>
            </Grid>}

            {stats.map((s, idx) => {
                switch (s.Question.Type) {
                    case 'string':
                        return (
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Title>{s.Question.Question}</Title>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {s.Answers.length > 0 &&
                                        <TableContainer>
                                            <Table size='small' stickyHeader>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            {t('table_cell_event_survey_stat_answer')}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {s.Answers.map((a, aidx) =>
                                                    <TableRow key={s.Question.Planner_Event_Question__ + '-' + aidx}>
                                                        <TableCell>{a}</TableCell>
                                                    </TableRow>
                                                )}
                                            </Table>

                                        </TableContainer>
                                        }
                                        {s.Answers.length < 1 &&
                                        <PieChartNoData text={t('event_survey_question_no_stats')}/>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    case 'select':
                        return (
                            <Grid item xs={12}>
                                <ActivePieChart
                                    title={s.Question.Question}
                                    noDataText={t('event_survey_question_no_stats')}
                                    loading={loading}
                                    stats={s.Answers.map((a, aIdx) => {
                                        return {
                                            ...a,
                                            fill: COLOR_LIST[aIdx % (COLOR_LIST.length - 1)]
                                        }
                                    })}
                                    getName={p => p.Label}
                                    tableHeaders={[
                                        t('table_cell_event_survey_stat_answer'),
                                        t('table_cell_event_survey_stat_answer_count'),
                                    ]}
                                    getCellContent={
                                        (rowIdx, cellIdx, data) => {
                                            switch (cellIdx) {
                                                case 0:
                                                    return stats[idx].Answers[rowIdx].Label;
                                                case 1:
                                                    return data.Count;
                                                default:
                                                    return <></>
                                            }
                                        }
                                    }
                                />
                            </Grid>
                        )
                    default:
                        return <></>
                }
            })}
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        loading: state.question.statsLoading,
        stats: state.question.stats,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchQuestionStats: (eventId) => dispatch(fetchQuestionStats(eventId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Report);