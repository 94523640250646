import React, {useEffect, useState}  from 'react';
import {withRouter}                  from 'react-router';
import {Grid, Tab, Tabs, Typography} from '@material-ui/core';
import Panel                         from '../../components/common/data/tab/Panel';
import Content                       from '../../components/common/cms/entry/Content';
import Settings                      from '../../components/common/cms/entry/Settings';
import {connect}                     from 'react-redux';
import {fetchEntryData}              from '../../store/actions/CmsAction';
import Skeleton                      from '@material-ui/lab/Skeleton';
import AddIcon                       from '@material-ui/icons/Add';
import CreateDialog                  from '../../components/common/cms/entry/CreateDialog';
import Flag                          from '../../components/common/data/flag/Flag';
import useTheme                      from '@material-ui/core/styles/useTheme';
import Tooltip                       from '@material-ui/core/Tooltip';
import Gallery                       from '../../components/common/cms/gallery/Gallery';
import {Trans, useTranslation}       from 'react-i18next';
import Templates                     from '../../components/common/cms/template/Templates';
import LoadingAppBarTitle            from '../../components/common/typography/LoadingAppBarTitle';
import HasRight                      from '../../components/Route/HasRight';
import Toolbar                       from '../../components/common/typography/Toolbar';
import Management                    from '../../components/common/cms/entry/Management';
import useMediaQuery                 from '@material-ui/core/useMediaQuery';
import Button                        from '../../components/common/inputs/Button';
import {useHistory, useParams}       from 'react-router-dom';
import TextField                     from '@material-ui/core/TextField';
import MenuItem                      from '@material-ui/core/MenuItem';
import {getSiteContentEditRoute}     from '../../router/routes/site/factory/content';
import Social                        from '../../components/common/cms/social/Social';
import RichAlert                     from '../../components/common/feeback/RichAlert';

const TAB_CONTENT = 'TAB_CONTENT';
const TAB_VISIBILITY = 'TAB_VISIBILITY';
const TAB_GALLERY = 'TAB_GALLERY';
const TAB_TEMPLATE = 'TAB_TEMPLATE';
const TAB_SOCIAL = 'TAB_SOCIAL';
const TAB_MANAGEMENT = 'TAB_MANAGEMENT';

const ArticleEdit = ({entry, fetchEntryData, loadingEntryData, match, templateSettings}) => {
	const [tabIndex, setTabIndex] = useState(TAB_CONTENT);
	const [showCreate, setShowCreate] = useState(false);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {noSsr: true});
	const {t} = useTranslation();
	const {entryData} = useParams();
	let history = useHistory();
	const disabled = loadingEntryData || (entry && entry.Status === 'translating');

	useEffect(() => {
		fetchEntryData(entryData);
	}, [entryData, fetchEntryData]);

	const handleTabChange = (event, newValue) => {
		setTabIndex(newValue);
	};

	const handleLangChange = (e) => {
		const id = e.target.value;
		history.push(getSiteContentEditRoute(id));
	};

	let showSocial = true;
	if (templateSettings && templateSettings.Data && templateSettings.Data.social === 'none')
		showSocial = false;

	return (
		<Grid container spacing={3}>
			<LoadingAppBarTitle
				value={entry}
				fallback={t('article_edit_title')}
				title={(v) => t('article_edit_title_title', {article: v})}
			/>
			<Grid item xs={12}>


				<Toolbar title={t('article_edit_title')} startAdornment={
					<div style={{marginRight: theme.spacing(2)}}>
						{
							entry &&
							<TextField
								select
								value={entryData}
								onChange={handleLangChange}
							>
								{Object.keys(entry.Content_Cms_Entry.Data).map((l) => (
									<MenuItem
										key={entry.Content_Cms_Entry.Data[l].Content_Cms_Entry_Data__ || entry.Content_Cms_Entry.Data[l]._key}
										value={entry.Content_Cms_Entry.Data[l].Content_Cms_Entry_Data__ || entry.Content_Cms_Entry.Data[l]._key}
									>
										<Tooltip title={l.split('-')[1].toLowerCase()}
										         placement="bottom"
										         arrow><Flag code={l.split('-')[1].toLowerCase()}/>
										</Tooltip>
									</MenuItem>
								))}
							</TextField>


						}
						{!entry && <Skeleton height={40} width={40}/>}
					</div>

				}>
					<HasRight manager hideLoading>
						<Button endIcon={<AddIcon/>} variant="contained" color="primary"
						        onClick={e => setShowCreate(true)}>
							{t('add_language_btn')}
						</Button>
					</HasRight>
				</Toolbar>
			</Grid>


			{(entry && entry.Status === 'translating') && <Grid item xs={12}>
				<RichAlert severity="warning">
					<Trans i18nKey="article_translating_warning">
						sample<br/>
						<strong>sample</strong>
					</Trans>
				</RichAlert>
			</Grid>}

			<Grid item xs={12}>
				<Typography variant="body2" color="textSecondary" component="p">
					{t('edit_article_manage_tags_visibility')}
				</Typography>
			</Grid>


			<Grid item xs={12}>
				<Tabs
					variant="scrollable"
					scrollButtons={isMobile ? 'on' : 'auto'}
					value={tabIndex}
					indicatorColor="primary"
					textColor="primary"
					onChange={handleTabChange}
					aria-label=""
				>
					<Tab label={t('article_tab_content')} disabled={disabled} value={TAB_CONTENT}/>
					<Tab label={t('article_tab_visibility')} disabled={disabled} value={TAB_VISIBILITY}/>
					<Tab label={t('article_tab_gallery')} disabled={disabled} value={TAB_GALLERY}/>
					<Tab label={t('article_tab_templates')} disabled={disabled} value={TAB_TEMPLATE}/>
					{showSocial && <Tab label={t('article_tab_social')} disabled={disabled} value={TAB_SOCIAL}/>}
					<Tab label={t('article_tab_management')} disabled={disabled} value={TAB_MANAGEMENT}/>

				</Tabs>

				<Panel value={tabIndex} index={TAB_CONTENT}>
					{loadingEntryData && <Skeleton height={500} width={500}/>}
					{!loadingEntryData && <Content entryData={entry} disabled={entry.Status === 'translating'}/>}
				</Panel>

				<Panel value={tabIndex} index={TAB_VISIBILITY}>
					{loadingEntryData && <Skeleton height={500} width={500}/>}
					{!loadingEntryData && <Settings entryData={entry} disabled={entry.Status === 'translating'}/>}
				</Panel>
				<Panel value={tabIndex} index={TAB_GALLERY}>
					{loadingEntryData && <Skeleton height={500} width={500}/>}
					{!loadingEntryData && <Gallery entryId={entry.Content_Cms_Entry__} galleryMode="manage"
					                               disabled={entry.Status === 'translating'}/>}
				</Panel>

				<Panel value={tabIndex} index={TAB_TEMPLATE}>
					{loadingEntryData && <Skeleton height={500} width={500}/>}
					{!loadingEntryData &&
					<Templates cmsId={entry.Content_Cms__} disabled={entry.Status === 'translating'}/>}
				</Panel>

				{showSocial && <Panel value={tabIndex} index={TAB_SOCIAL}>
					{loadingEntryData && <Skeleton height={500} width={500}/>}
					{!loadingEntryData && <Social entryData={entry} disabled={entry.Status === 'translating'}/>}
				</Panel>}

				<Panel value={tabIndex} index={TAB_MANAGEMENT}>
					{loadingEntryData && <Skeleton height={500} width={500}/>}
					{!loadingEntryData &&
					<Management entryData={entry} disabled={entry.Status === 'translating'} setTabIndex={setTabIndex}/>}
				</Panel>
			</Grid>

			{entry && <CreateDialog
				setOpen={setShowCreate}
				open={showCreate}
				cmsId={entry.Content_Cms__}
				entryId={entry.Content_Cms_Entry__}
				entryDataId={entry.Status !== 'translating' && entry.Content_Cms_Entry_Data__}
			/>
			}
		</Grid>


	);
};

const mapStateToProps = (state) => {
	return {
		entry: state.cms.entryData,
		loadingEntryData: state.cms.loadingEntryData,

		templateSettings: state.template.templateSettings,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchEntryData: (id) => dispatch(fetchEntryData(id))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ArticleEdit));
