import React             from 'react';
import Dialog            from '@material-ui/core/Dialog';
import DialogTitle       from '@material-ui/core/DialogTitle';
import DialogContent     from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions     from '@material-ui/core/DialogActions';
import {connect}         from 'react-redux';
import DeleteIcon        from '@material-ui/icons/Delete';
import Typography        from '@material-ui/core/Typography';
import {useTranslation}               from 'react-i18next';
import Button                         from '../../../../inputs/Button';
import {deleteComment, fetchComments} from '../../../../../../store/actions/SocialAction';


const DeleteDialog = ({comment, open, setOpen, deleting, deleteComment, refresh}) => {
	const {t} = useTranslation();

	const handleClose = () => {
		setOpen(false);
	};

	const handleDelete = () => {
		deleteComment(comment.Social_Comment__)
			.then(refresh)
			.then(handleClose);
	};

	return (
		<Dialog
			open={open}
			aria-labelledby="Delete comment"
			aria-describedby="Delete comment"
			fullWidth
		>
			<DialogTitle id="comment-delete-title">{t('confirm_delete_comment_title')}</DialogTitle>
			<DialogContent>
				<DialogContentText id="comment-delete-description">
					{t('confirm_delete_comment_desc')}
					<br/>
					<Typography variant="body1" color="textPrimary" component="span">{t('are_you_sure')}</Typography>
				</DialogContentText>
			</DialogContent>

			<DialogActions>
				<Button variant="contained" color="primary" autoFocus onClick={handleClose} disabled={deleting}>
					{t('back_btn')}
				</Button>
				<Button color="secondary" loading={deleting} disabled={deleting} endIcon={<DeleteIcon/>}
				        onClick={handleDelete}>
					{t('delete_btn')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const mapStateToProps = (state) => {
	return {
		deleting: state.social.commentDeleting
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		deleteComment: (commentId) => dispatch(deleteComment(commentId)),
		refresh: () => dispatch(fetchComments()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDialog);
