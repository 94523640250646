import React                      from 'react';
import {useTranslation}           from 'react-i18next';
import Graphic                    from '../../../../resources/images/orders.svg';
import EmptyState                 from '../../feeback/State/Empty';
import {getPXLBillingCreateRoute} from '../../../../router/routes/pxl/factory/order';
import {useHistory}               from 'react-router-dom';

const Empty = () => {
	const history = useHistory();
	const {t} = useTranslation();
	return (
		<EmptyState
			title={t('billings_empty_title')}
			textI18Key="billings_empty_text"
			action={{
				title: t('create_btn'),
				color: 'primary',
				variant: 'contained',
				component: 'button',
				onClick: () => history.push(getPXLBillingCreateRoute())
			}}
			graphic={Graphic}
		/>
	);

};

export default Empty;