import {rest, upload} from "@karpeleslab/klbfw";
import {handleError} from "../../../components/utils/apiErrorHandler";
import {
    ALBUM_CREATE,
    ALBUM_CREATE_DONE,
    ALBUM_DELETE,
    ALBUM_DELETE_DONE,
    ALBUM_FETCH,
    ALBUM_FETCH_DONE,
    ALBUM_LIST_FETCH,
    ALBUM_LIST_FETCH_DONE,
    ALBUM_SET_FILTERS,
    ALBUM_SET_PAGING,
    ALBUM_UPDATE,
    ALBUM_UPDATE_DONE,
    ALBUM_UPLOAD_IMAGE,
    ALBUM_UPLOAD_IMAGE_DONE
} from "../../reducers/discography/AlbumReducer";
import {success} from "../system/ToastAction";

export const setAlbumsPaging = (newPaging) => {
    return (dispatch, getState) => {
        dispatch({type: ALBUM_SET_PAGING, paging: {...getState().album.albumsPaging, ...newPaging}});
    }
};

export const setAlbumsFilters = (newFilters) => {
    return (dispatch, getState) => {
        dispatch({type: ALBUM_SET_FILTERS, filters: newFilters});
    }
};

export const fetchAlbums = () => {
    return (dispatch, getState) => {
        dispatch({type: ALBUM_LIST_FETCH});

        const params = {
            ...getState().album.albumsFilters,
            ...getState().album.albumsPaging,
            Role: 'artist',
            Music_Label_Artist__: getState().artist.artist.Music_Label_Artist__
        };

        const labelId = getState().artist.artist.Music_Label__;

        return rest('Music/Label/' + labelId + '/Album', 'GET', params)
            .then(data => {
                dispatch({type: ALBUM_LIST_FETCH_DONE, albums: data.data, paging: data.paging});
                return data.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const fetchAlbum = (id) => {
    return (dispatch, getState) => {
        dispatch({type: ALBUM_FETCH});
        return rest('Music/Label/Album/' + id)
            .then(data => {
                dispatch({type: ALBUM_FETCH_DONE, album: data.data});
                return data.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};


export const deleteAlbum = (id) => {
    return (dispatch, getState) => {
        dispatch({type: ALBUM_DELETE});

        return rest('Music/Label/Album/' + id, 'DELETE')
            .then(d => {
                dispatch({type: ALBUM_DELETE_DONE});
                success('album_delete_success');
                return d;
            }).catch((err) => {
                dispatch({type: ALBUM_DELETE_DONE});
                handleError(getState, dispatch, err)
            });
    }
};

export const updateAlbum = (id, data) => {
    return (dispatch, getState) => {
        dispatch({type: ALBUM_UPDATE});
        return rest('Music/Label/Album/' + id, 'PATCH', data)
            .then(d => {
                dispatch({type: ALBUM_UPDATE_DONE, album: d.data});
                success('album_update_success');
                return d.data;
            }).catch((err) => {
                dispatch({type: ALBUM_UPDATE_DONE});
                handleError(getState, dispatch, err)
            });
    }
};

export const updateAlbumImage = (id, file) => {
    return (dispatch, getState) => {
        dispatch({type: ALBUM_UPLOAD_IMAGE});

        return new Promise((resolve, reject) => {
            upload.onprogress = (item) => {
                item.failed.forEach(i => {
                    upload.deleteItem(i.up_id)
                    reject(i.failure);
                })
            };

            upload.append('Music/Label/Album/' + id + ':uploadImage', file)
                .then(resolve).catch(reject)


            upload.run();

        })
            .then(d => {
                dispatch({type: ALBUM_UPLOAD_IMAGE_DONE})
            })
            .catch((err) => {
                dispatch({type: ALBUM_UPLOAD_IMAGE_DONE})
                handleError(getState, dispatch, err)
            })
    }
};


export const createAlbum = (data, file) => {
    return (dispatch, getState) => {
        dispatch({type: ALBUM_CREATE});
        const labelId = getState().artist.artist.Music_Label__;
        return new Promise((resolve, reject) => {
            rest('Music/Label/' + labelId + '/Album', 'POST', data)
                .then(d => {
                    if (!file) {
                        resolve(d.data);
                        return;
                    }

                    upload.onprogress = (item) => {
                        item.failed.forEach(i => {
                            // Special case, we want the redirection on the edit page
                            // But with the fail message
                            upload.deleteItem(i.up_id)
                            resolve(d.data);
                            handleError(getState, dispatch, i.failure);

                        })
                    };

                    upload.append('Music/Label/Album/' + d.data.Music_Label_Album__ + ':uploadImage', file)
                        .then((data) => {
                            resolve(data.final);
                        }).catch((err) => {
                        // Special case, we want the redirection on the edit page
                        // But with the fail message
                        resolve(d.data);
                        handleError(getState, dispatch, err);
                    });

                    upload.run();
                })
                .catch(reject);

        }).then(d => {
            dispatch({type: ALBUM_CREATE_DONE});
            success('album_create_success');
            return d;
        }).catch((err) => {
            dispatch({type: ALBUM_CREATE_DONE});
            handleError(getState, dispatch, err)
        });
    }
};