import React, {useEffect}                         from 'react';
import Redirect                                   from '../../components/Route/Redirect';
import {getPXLContactRoute, getPXLDashboardRoute} from '../../router/routes/pxl/factory/general';
import AppBarTitle                                from '../../components/common/typography/AppBarTitle';
import Grid                                       from '@material-ui/core/Grid';
import Toolbar                                    from '../../components/common/typography/Toolbar';
import {Trans, useTranslation}                    from 'react-i18next';
import {connect}                                  from 'react-redux';
import SelectRealm                                from '../SelectRealm';
import AccessCheck                                from '../../components/Route/AccessCheck';
import RichAlert                                  from '../../components/common/feeback/RichAlert';
import {fetchTokens}                              from '../../store/actions/system/OAuth2Action';
import Loading                                    from '../../components/common/feeback/loading/Loading';
import BankOAuth                                  from '../../components/common/store/BankOAuth';
import StoreActivator                             from '../../components/common/store/StoreActivator';
import Link                                       from '../../components/common/typography/Link';
import MaterialLink                               from '@material-ui/core/Link';

const Bank = ({selectedRealm, catalog, loading, fetchTokens, tokens, bankConsumers}) => {
	const {t} = useTranslation();

	useEffect(() => {
		if (!catalog) return;
		fetchTokens();
	}, [fetchTokens, catalog]);


	if (!selectedRealm || !catalog) return <SelectRealm/>;

	const getOAuth = oauthId => {
		const oauth = tokens.filter(t => t.OAuth2_Consumer__ === oauthId);
		if (oauth.length > 0) return oauth[0];
		return null;
	};

	return (
		<Grid container spacing={3}>
			<AccessCheck capabilities={{store: true}} rights={{owner: true}}
			             deniedComponent={<Redirect target={getPXLDashboardRoute()}/>}>
				<AppBarTitle title={t('store_bank_title')}/>

				<StoreActivator/>

				<Grid item xs={12}>
					<Toolbar title={t('store_bank_title')}/>
				</Grid>


				<Grid item xs={12}>
					<RichAlert severity="info">
						<Trans i18nKey="store_bank_info">
							sample <br/>
							<strong>sample</strong>
							<MaterialLink href="https://stripe.com" target="_blank" rel="noopener noreferrer">Stripe
								link</MaterialLink>
							<Link to={getPXLContactRoute()}>sample</Link>
						</Trans>
					</RichAlert>
				</Grid>

				{loading && <Grid item xs={12}><Loading/></Grid>}
				{!loading &&
				bankConsumers.map(c =>
					<Grid item xs={4} key={c.Name}>
						<BankOAuth consumerInfo={c} oauth={getOAuth(c.OAuth2_Consumer__)}/>
					</Grid>
				)
				}
			</AccessCheck>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		catalog: state.store.catalog,
		loading: state.oauth2.tokensLoading,
		tokens: state.oauth2.tokens,
		bankConsumers: state.oauth2.storeBankTransferConsumers,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchTokens: () => dispatch(fetchTokens())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Bank);
