import {
    USER_AVATAR_CHANGED,
    USER_AVATAR_CHANGING,
    USER_AVATAR_RESET,
    USER_CHANGE,
    USER_FORGET_PWD,
    USER_FORGET_PWD_DONE,
    USER_INFO_CHANGE_ERROR,
    USER_INFO_CHANGED,
    USER_INFO_CHANGING,
    USER_LOGOUT,
    USER_PASSWORD_RESET_DONE,
    USER_PASSWORD_RESET_ERROR,
    USER_PASSWORD_RESETING,
    USER_PROFILE_UPDATE_DONE,
    USER_PROFILE_UPDATE_ERROR,
    USER_PROFILE_UPDATING,
    USER_PWD_CHANGE_ERROR,
    USER_PWD_CHANGED,
    USER_PWD_CHANGING,
    USER_UPDATE,
} from "../../reducers/system/UserReducer";

import {initFlow} from './FlowActions.js'
import {rest, setContext, upload} from '@karpeleslab/klbfw'
import {success} from "./ToastAction";
import {handleError} from "../../../components/utils/apiErrorHandler";
import Cookies from "js-cookie";
import {COMMON_RESET} from "../../reducers/CommonReducer";

export const changeUser = (user) => {
    return (dispatch, getState) => {
        dispatch({type: USER_CHANGE, user: user})
    }
};

export const logout = () => {
    return (dispatch, getState) => {
        return rest("User/@:logout", "POST")
            .then(() => {
                    Cookies.remove('Realm__');
                    setContext('g','all');
                    dispatch({type:COMMON_RESET})
                    dispatch(initFlow());
                    dispatch({type: USER_LOGOUT})
                }
            )
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const forgetPassword = (email) => {
    return (dispatch, getState) => {
        dispatch({type: USER_FORGET_PWD});
        return rest("User:forgot_password", "POST", {login: email})
            .then(() => {
                success('password_forgot_success');
                dispatch({type: USER_FORGET_PWD_DONE});
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};


export const resetPassword = (p1, p2, login, key) => {
    return (dispatch, getState) => {
        dispatch({type: USER_PASSWORD_RESETING});

        const p = {
            login: login,
            key: key,
            p1: p1,
            p2: p2
        };

        return rest("User:recover_password", "POST", p)
            .then(() => {
                dispatch({type: USER_PASSWORD_RESET_DONE})
            })
            .catch((e) => {
                dispatch({type: USER_PASSWORD_RESET_ERROR, error: e.message});
                handleError(getState, dispatch, e)
            })
    }
};


export const
    changePassword = (old, newPwd) => {
        return (dispatch, getState) => {
            dispatch({type: USER_PWD_CHANGING});
            return rest("User/@:setPassword", "POST", {old_password: old, password: newPwd})
                .then(
                    (data) => {
                        dispatch({type: USER_PWD_CHANGED, user: data.data});
                        success('user_password_update_success');
                    })
                .catch((err) => {
                    dispatch({type: USER_PWD_CHANGE_ERROR, error: err.message});
                    handleError(getState, dispatch, err)
                })
        }
    };

export const updateUser = () => {
    return (dispatch, getState) => {
        return rest("User:get", "GET")
            .then(
                (data) => {
                    dispatch({type: USER_UPDATE, data: data.data});
                    return data.data;
                }
            )
            .catch((err) => {
                dispatch({type: USER_INFO_CHANGE_ERROR, error: err.message});
                handleError(getState, dispatch, err)
            })
    }
};


export const changeInfo = (name, email, password) => {

    return (dispatch, getState) => {
        const user_name = getState().user.user.Profile.Display_Name;
        const user_email = getState().user.user.Email;

        dispatch({type: USER_INFO_CHANGING});

        if (name !== user_name) {
            return rest('User/@/Profile', 'PATCH', {Display_Name: name})
                .then(
                    () => {
                        dispatch({type: USER_INFO_CHANGED, user: null});
                        dispatch(updateUser());
                        success('user_info_update_success');
                    })
                .catch((err) => {
                    dispatch({type: USER_INFO_CHANGE_ERROR, error: err.message});
                    handleError(getState, dispatch, err)
                })
        }

        if (email !== user_email) {
            let data = {
                'email': email
            };
            if (password) {
                data.current_password = password
            }
            return rest('User/@:setEmail', 'POST', data)
                .then(
                    (data) => {
                        dispatch({type: USER_INFO_CHANGED, user: data.data});
                        success('user_info_update_success');
                    }
                )
                .catch((err) => {
                    dispatch({type: USER_INFO_CHANGE_ERROR, error: err.message});
                    handleError(getState, dispatch, err)
                })
        }

    }
};

export const setEmail = (email, password) => {
    return (dispatch, getState) => {
        let data = {
            'email': email
        };
        if (password) {
            data.current_password = password
        }
        dispatch({type: USER_INFO_CHANGING});
        return rest('User/@:setEmail', 'POST', data)
            .then(
                (data) => {
                    dispatch({type: USER_INFO_CHANGED, user: data.data});
                    success('user_info_update_success');
                }
            )
            .catch((err) => {
                dispatch({type: USER_INFO_CHANGE_ERROR});
                handleError(getState, dispatch, err)
            })
    }
};

export const updateAvatar = (files) => {
    return (dispatch, getState) => {
        dispatch({type: USER_AVATAR_CHANGING});

        upload.onprogress = (d) => {
            if (d.queue.length !== 0) return;
            let allComplete = true;


            d.running.forEach((value) => {
                if (value.status !== 'complete')
                    allComplete = false;
                if (allComplete) {
                    dispatch({type: USER_INFO_CHANGED, user: null});
                    dispatch({type: USER_AVATAR_CHANGED});
                    dispatch(updateUser());
                    success('user_info_update_success');
                    setTimeout(() => {
                        dispatch({type: USER_AVATAR_RESET});
                    }, 2000);
                }
            });
        };

        for (const vf in files) {
            if (files[vf].file) {
                upload.append(files[vf].target, files[vf].file, files[vf].param);
                upload.run();
            }
        }

    }
};

export const updateProfile = (profile) => {
    return (dispatch, getState) => {
        dispatch({type: USER_PROFILE_UPDATING});
        return rest('User/@/Profile', 'PATCH', profile)
            .then(
                (data) => {
                    dispatch({type: USER_PROFILE_UPDATE_DONE, profile: data.data});
                    success('user_profile_update_success');
                }
            )
            .catch((err) => {
                dispatch({type: USER_PROFILE_UPDATE_ERROR, error: err.message});
                handleError(getState, dispatch, err)
            });
    }
};