import {COMMON_RESET} from './CommonReducer';

const initState = () => {
	return {
		membershipStatsLoading: false,
		membershipStatsPlans: [],
		membershipStatsActive: 0,
		membershipStatsTotal: 0,
		membershipStatsFilters: {
			Active_Only: false,
		},

		orderStatsLoading: false,
		orderStats: [],
		ordersTotal: {},

		catalogStatsLoading: false,
		catalogStats: [],
		catalogTotals: {event:0,fanclub:0,store:0},

		financialReportLoading: false,

	};
};


export const STATS_MEMBERSHIP_FETCH = 'STATS_MEMBERSHIP_FETCH';
export const STATS_MEMBERSHIP_FETCH_DONE = 'STATS_MEMBERSHIP_FETCH_DONE';
export const STATS_MEMBERSHIP_SET_FILTERS = 'STATS_MEMBERSHIP_SET_FILTERS';


export const STATS_ORDER_FETCH = 'STATS_ORDER_FETCH';
export const STATS_ORDER_FETCH_DONE = 'STATS_ORDER_FETCH_DONE';


export const CATALOGS_STATS_FETCH = 'CATALOGS_STATS_FETCH';
export const CATALOGS_STATS_FETCH_DONE = 'CATALOGS_STATS_FETCH_DONE';

export const STATS_FINANCIAL_REPORT = 'STATS_FINANCIAL_REPORT';
export const STATS_FINANCIAL_REPORT_DONE = 'STATS_FINANCIAL_REPORT_DONE';

const statsReducer = (state = initState(), action) => {
	switch (action.type) {
		case COMMON_RESET:
			return initState();
		case STATS_FINANCIAL_REPORT:
			return {...state, financialReportLoading: true};
		case STATS_FINANCIAL_REPORT_DONE:
			return {...state, financialReportLoading: false};
		case CATALOGS_STATS_FETCH:
			return {...state, catalogStatsLoading: true};
		case CATALOGS_STATS_FETCH_DONE:
			let totals = {
				event:0,
				fanclub:0,
				store:0,
			}

			totals = action.stats.reduce((accumulator, item) => {
				accumulator.event+=item.event ?? 0;
				accumulator.fanclub+=item.fanclub ?? 0;
				accumulator.store+=item.store ?? 0;

				return accumulator
			}, totals);

			return {...state, catalogStatsLoading: false, catalogStats: action.stats, catalogTotals:totals};
		case  STATS_ORDER_FETCH:
			return {...state, orderStatsLoading: true};
		case  STATS_ORDER_FETCH_DONE:
			return {
				...state,
				orderStatsLoading: false,
				orderStats: action.stats,
				ordersTotal: action.total
			};
		case STATS_MEMBERSHIP_FETCH:
			return {...state, membershipStatsLoading: true};
		case STATS_MEMBERSHIP_SET_FILTERS:
			return {...state, membershipStatsFilters: action.filters};
		case STATS_MEMBERSHIP_FETCH_DONE: {
			let formattedPlans = [];

			for (let i = 0; i < action.stats.plans.length; i++) {
				formattedPlans.push({
					name: action.stats.plans[i].Membership_Plan.Catalog_Product['Basic.Name'],
					...action.stats.plans[i]
				});
			}

			return {
				...state,
				membershipStatsLoading: false,
				membershipStatsPlans: formattedPlans,
				membershipStatsActive: parseInt(action.stats.active),
				membershipStatsTotal: parseInt(action.stats.total)
			};
		}
		default:
			return state;
	}

};

export default statsReducer;