export const phoneValidator = (phone) => {
	if (!phone) return false;
	phone = phone.trim();
	const data = phone.replace(/\s|-/g, '');
	if (!data) return false;
	if (data.length > 20) return false;
	if (data.indexOf('--') !== -1) return false;
	if (data.match(/^\\+[0-9]+\\.[0-9]{3,}$/g)) return true;
	if (data.match(/^[0-9]{3,}$/g)) return true;
	return false;
};

export const emailValidator = (email) => {
	if (!email || !email.trim()) return false;
	if (email.indexOf('@') === -1) return false;
	return !!email.match(/^.*@[a-zA-Z0-9_.-]+.[a-zA-Z]+$/g);
};
