import React, {useEffect, useState} from 'react';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {connect} from 'react-redux'
import {Select} from "@material-ui/core";
import {fetchAndSelect} from "../../../store/actions/RealmAction";
import {reset} from "../../../store/actions/CommonAction";
import {withRouter} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import {getPXLDashboardRoute} from "../../../router/routes/pxl/factory/general";

let lastId = 0;


const RealmSelector = ({loading, reset, selected, select, list, history, className = '', fullwidth = false}) => {
    const id = "realm-selector-" + (lastId++);
    const {t} = useTranslation();
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = useState(0);
    useEffect(() => {
        if (!inputLabel || !inputLabel.current) return;

        setLabelWidth(inputLabel.current.offsetWidth);
    }, [inputLabel]);


    const realmChangeHandler = (e) => {
        const v = e.target.value;
        if(!v) return;
        const filtered = list.filter((f) => f.Realm__ === v);
        reset();

        if (filtered.length > 0) {
            select(filtered[0]);
            history.push(getPXLDashboardRoute());
        }
        else {
            console.log('Trying to select unknown realm value : ' + v + ' ')
        }
    };

    return (
        <FormControl className={className} variant='outlined' fullWidth={fullwidth}>
            <InputLabel ref={inputLabel} htmlFor={id}>{t('site_lbl')}</InputLabel>
            <Select
                native
                value={selected !== null ? selected.Realm__ : ""}
                onChange={realmChangeHandler}
                labelWidth={labelWidth}
                inputProps={{
                    id: id,
                }}
            >
                <option/>
                {
                    list.map(
                        (realm) =>
                            <option key={id + '-' + realm.Realm__}
                                    value={realm.Realm__}
                            >
                                {realm.Name}
                            </option>
                    )

                }
            </Select>
        </FormControl>
    );
};

const mapStateToProps = (state) => {
    return {
        loading: state.realm.loading,
        list: state.realm.realms,
        selected: state.realm.selected,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        reset: () => dispatch(reset()),
        select: (realm) => dispatch(fetchAndSelect(realm.Realm__))
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RealmSelector));
