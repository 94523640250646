import React, { useEffect, useState } from 'react';
import {CircularProgress, Grid}       from '@material-ui/core';
import Table                          from './reserve/table/Table';
import { useTranslation }             from 'react-i18next';
import Toolbar                        from '../../typography/Toolbar';
import ManageDialog                   from './seat/ManageDialog';
import Tooltip                        from '@material-ui/core/Tooltip';
import IconButton                     from '@material-ui/core/IconButton';
import FilterListIcon                 from '@material-ui/icons/FilterList';
import ExpandLessIcon                 from '@material-ui/icons/ExpandLess';
import Collapse                       from '@material-ui/core/Collapse';
import Filters                        from './reserve/Filters';
import { exportReserves, markAsUsed } from '../../../../store/actions/planner/ReserveAction';
import { connect }                    from 'react-redux';
import { Overlay }                    from '../../feeback/Overlay';
import Loading                        from '../../feeback/loading/Loading';
import Typography                     from '@material-ui/core/Typography';
import Alert                          from '@material-ui/lab/Alert';
import Button                         from '../../inputs/Button';
import HasRight                       from '../../../Route/HasRight';
import {EventSeat, GetApp}            from '@material-ui/icons';
import {downloadUrl}                  from '../../../utils/dowloadUrl';
import ImportAllocatedSeatDialog      from './reserve/ImportAllocatedSeatDialog';

const Reserve = ({ event, exportReserves, exporting, importing, markAsUsed, marking }) => {
	const { t } = useTranslation();
	const [selectedSeat, setSelectedSeat] = useState(null);
	const [showManage, setShowManage] = useState(false);

	const [showImport, setShowImport] = useState(false);

	const [checked, setChecked] = useState({});
	useEffect(() => {
		if (!event) return;
		setChecked(JSON.parse(localStorage.getItem('live-' + event.Planner_Event__) ?? '{}'));
	}, [event, setChecked]);


	const [filtersShown, setFiltersShown] = useState(false);

	const createFilterToggle = v => e => {
		setFiltersShown(v);
	};

	const handleMarkAsUsed = () => {
		markAsUsed(event.Planner_Event__, Object.keys(checked))
			.then(d => {
				localStorage.removeItem('live-' + event.Planner_Event__);
				setChecked({});
			});
	};

	 const exportClickHandler = () => {
		 exportReserves(event.Planner_Event__).then(data => {
			 downloadUrl(data.url, 'export.csv')
		 })
	 };

	const importClickHandler = () => {
		setShowImport(true);
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Toolbar title={t('event_reserve_title')}>
					<Tooltip title={t('filters')}>
						<IconButton aria-label={t('filters')} onClick={createFilterToggle(!filtersShown)}>
							{!filtersShown && <FilterListIcon/>}
							{filtersShown && <ExpandLessIcon/>}
						</IconButton>
					</Tooltip>

					<HasRight manager hideLoading>
						<Tooltip title={t('export_reserve_btn')}>
	                        <IconButton aria-label={t('export_reserve_btn')} onClick={exportClickHandler} disabled={exporting}>
		                        {!exporting && <GetApp/>}
		                        {exporting && <CircularProgress thickness={2} size={16}/>}
	                        </IconButton>
	                    </Tooltip>
						<Tooltip title={t('import_allocated_seat_btn')}>
							<IconButton aria-label={t('import_allocated_seat_btn')} onClick={importClickHandler} disabled={importing}>
								{!importing && <EventSeat/>}
								{importing && <CircularProgress thickness={2} size={16}/>}
							</IconButton>
						</Tooltip>
					</HasRight>
				</Toolbar>
				<Collapse in={filtersShown}>
					<Filters/>
				</Collapse>
			</Grid>

			<HasRight support hideLoading>
				{Object.keys(checked).length > 0 &&
				<Grid item xs={12}>
					<Alert
						severity='info'
						action={
							<Button onClick={handleMarkAsUsed} color='primary' loading={marking} disabled={marking}
							        variant='contained' size='small'>
								{t('reserve_sync_btn')}
							</Button>
						}
					>
						{t('reserve_validate_pending', { count: Object.keys(checked).length })}
					</Alert>
				</Grid>}
			</HasRight>

			<Grid item xs={12}>
				<Table event={event} onSelected={q => {
					setSelectedSeat(q);
					setShowManage(true);
				}}/>
			</Grid>

			<Overlay open={exporting}>
				<Loading color='inherit' size={60} direction='column'>
					<Grid item>
						<Typography variant='h2' component='h2' color='inherit'>
							{t('generating_file_please_wait')}
						</Typography>
					</Grid>
				</Loading>
			</Overlay>

			<ManageDialog event={event} seat={selectedSeat} open={showManage} setOpen={setShowManage}/>
			<HasRight manager hideLoading>
				<ImportAllocatedSeatDialog
					setOpen={setShowImport}
					open={showImport}
					eventId={event.Planner_Event__}
				/>
			</HasRight>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		exporting: state.reserve.exporting,
		importing: state.reserve.importing,
		marking: state.reserve.markingAsUsed,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		exportReserves: (eventId) => dispatch(exportReserves(eventId)),
		markAsUsed: (eventId, reserveIds) => dispatch(markAsUsed(eventId, reserveIds))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Reserve);
