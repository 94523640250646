import React, {useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import {connect} from 'react-redux'
import {Trans, useTranslation}                                  from 'react-i18next';
import { fetchShippings, setSelectedShippings, shippingImport } from "../../../../store/actions/store/ShippingAction";
import Button                                                   from "../../inputs/Button";
import RichAlert from "../../feeback/RichAlert";
import ShippingProviderSelector from "../../inputs/ShippingProviderSelector";
import ImportExportErrorsTable from "./ImportExportErrorsTable";
import uuid from "../../../utils/uuid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import {FormHelperText} from "@material-ui/core";

const ImportShippingDialog = ({open, setOpen, importing, shippingImport, refresh, refreshing, setSelectedShippings, selectedShippings}) => {
    const {t} = useTranslation();
    const [provider, setProvider] = useState();
    const [file, setFile] = useState(null)
    const [fileKey, setFileKey] = useState(uuid())
    const [errors, setErrors] = useState([])
    const [importCount, setImportCount] = useState(null)

    const handleClose = () => {
        setOpen(false);
    };

    const doneHandler = () => {
        refresh().then(handleClose)
    }

    const handleImport = (e) => {
        e.stopPropagation();
        e.preventDefault();

        shippingImport(provider, file)
            .then(result => {
            	// Update selected Shipping, remove imported one
            	const cpy = {...selectedShippings}
	            result.imported.forEach(i => {
	            	if(i in cpy) delete cpy[i];
	            })

	            setSelectedShippings(cpy)

                setErrors(result.errors)
                setImportCount(result.count)
            })

    };

    const fileChangeHandler = (e) => {
        if (e.target.files.length < 1) {
            setFile(null)
        } else {
            setFile(e.target.files[0])
        }
    }

    return (
        <Dialog
            onEnter={() => {
                setProvider(undefined);
                setErrors([]);
                setImportCount(null);
                setFile(null);
                setFileKey(uuid());
            }}
            open={open}
            aria-labelledby={t('shipping_import_title')}
            aria-describedby={t('shipping_import_title')}
        >
            <DialogTitle
                id="shipping-import-title">{t('shipping_import_title')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="shipping-import-description">
                    {(importCount === null && errors.length < 1) && <RichAlert severity='info'>
                        <Trans i18nKey='shipping_import_desc'>
                            sample<br/>
                            <strong>sample</strong>
                        </Trans>
                    </RichAlert>}


                </DialogContentText>
                <Grid container spacing={2}>

                    {importCount !== null &&
                    <Grid item xs={12}><RichAlert severity='info'>
                        <Trans i18nKey='shipping_import_complete'>
                            sample<br/>
                            <strong>{{importCount}}</strong>
                        </Trans>
                    </RichAlert>
                    </Grid>
                    }

                    {(importCount === null && errors.length < 1) &&
                    <>
                        <Grid item xs={12} md={12}>
                            <ShippingProviderSelector
                                value={provider}
                                setValue={setProvider}
                                disabled={importing}
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel shrink={true}
                                            htmlFor='shipping-import-file'>{t('shipping_import_file')}</InputLabel>
                                <Input
                                    key={fileKey}
                                    onChange={fileChangeHandler} inputProps={
                                    {
                                        accept: '.csv'
                                    }
                                }
                                    id='shipping-import-file' type='file'/>
                                <FormHelperText>{t('shipping_import_file_helperText')}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </>
                    }

                    {errors.length > 0 && <ImportExportErrorsTable errors={errors} isExport={false}/>}
                </Grid>


            </DialogContent>
            <DialogActions>
                {importCount === null && <Button color="default" onClick={handleClose} disabled={importing}>
                    {t('close_btn')}
                </Button>}


                <Button
                    color="primary"
                    variant='contained'
                    loading={importing || refreshing}
                    disabled={importing || !provider || !file || refreshing}
                    type='submit'
                    onClick={e => importCount === null ? handleImport(e) : doneHandler(e)}
                >
                    {t(importCount === null ? 'import_btn' : 'done_btn')}
                </Button>

            </DialogActions>
        </Dialog>

    );
};

const mapStateToProps = (state) => {
    return {
	    selectedShippings: state.shipping.selectedShippings,
        importing: state.shipping.importingShipping,
        refreshing: state.shipping.shippingsLoading,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
	    setSelectedShippings: (selected) => dispatch(setSelectedShippings(selected)),
        shippingImport: (format, file) => dispatch(shippingImport(format, file)),
        refresh: () => dispatch(fetchShippings()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportShippingDialog);
