import {Facebook, Google, Twitter} from "../common/data/SnsLink/styles";
import StripeIcon from "../../resources/images/fanclub-wizard/stripeLogo.svg";
import React from "react";

export const tokenTypeToIcon = (type) => {
    switch (type) {
        case 'stripe':
            return <img src={StripeIcon} alt='Stripe'/>;
        case 'twitter':
            return <Twitter/>;
        case 'facebook':
            return <Facebook/>;
        case 'google':
            return <Google className='google'/>;
        default:
            return null;
    }
};