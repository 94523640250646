import React, {useEffect, useState} from 'react';
import Grid                         from '@material-ui/core/Grid';
import AppBarTitle                  from '../../../../components/common/typography/AppBarTitle';
import {connect}                    from 'react-redux';
import Loading                      from '../../../../components/common/feeback/loading/Loading';
import SelectRealm                  from '../../../SelectRealm';
import {useTranslation}             from 'react-i18next';
import {useParams}                  from 'react-router-dom';
import AccessCheck                  from '../../../../components/Route/AccessCheck';
import Redirect                     from '../../../../components/Route/Redirect';
import Toolbar                      from '../../../../components/common/typography/Toolbar';
import StoreActivator               from '../../../../components/common/store/StoreActivator';
import {getPXLDashboardRoute}       from '../../../../router/routes/pxl/factory/general';
import {fetchShippingFee}           from '../../../../store/actions/store/ShippingFeeAction';
import EditForm                     from '../../../../components/common/store/shipping/fee/EditForm';
import ShippingFeeConfiguration     from '../../../../components/common/store/shipping/fee/ShippingFeeConfiguration';


const Edit = ({selectedRealm, fetchShippingFee, fee}) => {
	const {t} = useTranslation();
	const {feeId} = useParams();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (!feeId) return;
		fetchShippingFee(feeId).then(() => setLoading(false));
	}, [feeId, fetchShippingFee]);

	if (!selectedRealm) return <SelectRealm/>;
	if (loading) return <Loading/>;

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('shipping_fee_edit_title')}/>
			<StoreActivator/>
			<AccessCheck capabilities={{store: true}} rights={{admin: true}}
			             deniedComponent={<Redirect target={getPXLDashboardRoute()}/>}>
				<Grid item xs={12}>
					<Toolbar title={t('shipping_fee_edit_title')}/>
				</Grid>

				<Grid item xs={12}>
					<EditForm fee={fee}/>
				</Grid>

				<Grid item xs={12}>
					<ShippingFeeConfiguration fee={fee}/>
				</Grid>

			</AccessCheck>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		fee: state.shippingFee.fee,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchShippingFee: (feeId) => dispatch(fetchShippingFee(feeId))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
