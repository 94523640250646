import React              from 'react';
import TableRow           from '@material-ui/core/TableRow';
import Skeleton           from '@material-ui/lab/Skeleton';
import TableCell          from '@material-ui/core/TableCell';
import moment             from 'moment';
import {useTranslation}   from 'react-i18next';
import Button             from '../../inputs/Button';
import Link               from '../../typography/Link';
import {Grid}             from '@material-ui/core';
import GetAppIcon         from '@material-ui/icons/GetApp';
import {getPXLOrderRoute} from '../../../../router/routes/pxl/factory/order';

const Row = ({order, loading}) => {
	const displayOrder = (!loading && order);
	const {t} = useTranslation();

	return (
		<TableRow>
			<TableCell>
				{!displayOrder && <Skeleton width={17}/>}
				{displayOrder && <span>{order.Invoice_Number}</span>}
			</TableCell>
			<TableCell>
				{!displayOrder && <Skeleton/>}
				{displayOrder &&
				<span>{order.Invoice_Date ? moment(parseInt(order.Invoice_Date.unixms)).format('LLL') : '-'}</span>}
			</TableCell>
			<TableCell>
				{!displayOrder && <Skeleton/>}
				{displayOrder &&
				<span>{order.Paid ? moment(parseInt(order.Paid.unixms)).format('LLL') : '-'}</span>}
			</TableCell>
			<TableCell>
				{!displayOrder && <Skeleton/>}
				{displayOrder && <span>{t('order_status_' + order.Status)}</span>}
			</TableCell>
			<TableCell>
				{!displayOrder && <Skeleton/>}
				{displayOrder && <span>{order.Total_Vat.display}</span>}
			</TableCell>
			<TableCell>
				{!displayOrder && <Skeleton/>}
				{
					displayOrder &&
					<Grid container spacing={3} justify="flex-end">
						{order.Invoice_Date && <Grid item xs={12} lg={6}>
							<Button
								fullWidth
								componant="a"
								href={order.Invoice_Url}
								target="blank"
								variant="outlined"
								size="small"
								color="default"
								startIcon={<GetAppIcon/>}
							>
								{t('history_dl_pdf')}
							</Button>
						</Grid>}
						<Grid item xs={12} lg={6}>
							<Button
								fullWidth
								component={Link}
								to={getPXLOrderRoute(order.Order__)}
								variant="contained"
								size="small"
								color="primary"
							>
								{t('view_btn')}
							</Button>
						</Grid>
					</Grid>
				}
			</TableCell>
		</TableRow>
	);
};

export default Row;