import React, {useEffect, useState}                     from 'react';
import {useTranslation}                                 from 'react-i18next';
import Dialog                                           from '@material-ui/core/Dialog';
import DialogTitle                                      from '@material-ui/core/DialogTitle';
import DialogContent                                    from '@material-ui/core/DialogContent';
import Grid                                             from '@material-ui/core/Grid';
import TextField                                        from '@material-ui/core/TextField';
import DialogActions                                    from '@material-ui/core/DialogActions';
import Button                                           from '../../../../inputs/Button';
import {connect}                                        from 'react-redux';
import {Box}                                            from '@material-ui/core';
import {fetchShippingFeeScales, updateShippingFeeScale} from '../../../../../../store/actions/store/ShippingFeeAction';
import FeeScaleTaxProfileSelector                       from '../../../../inputs/FeeScaleTaxProfileSelector';
import InputAdornment                                   from '@material-ui/core/InputAdornment';
import Price                                            from '../../../../inputs/Price';

const EditDialog = ({scale, open, setOpen, updating, update, refresh}) => {
	const {t} = useTranslation();
	const [code, setCode] = useState(scale.Code);
	const [currency, setCurrency] = useState(scale.Currency__);
	const [price, setPrice] = useState(scale.Fee);
	const [vatIncluded, setVatIncluded] = useState(scale.Vat_Included === 'Y');
	const [weight, setWeight] = useState(scale.Max_Weight);
	const [taxProfile, setTaxProfile] = useState(scale.Tax_Profile);

	const [saveDisabled, setSaveDisabled] = useState(true);

	useEffect(() => {
		setCode(scale.Code);
		setCurrency(scale.Currency__);
		setPrice(scale.Fee);
		setWeight(scale.Max_Weight);
		setVatIncluded(scale.Vat_Included === 'Y');
		setTaxProfile(scale.Tax_Profile);
	}, [scale, setCode, setCurrency, setPrice, setWeight, setVatIncluded, setTaxProfile]);

	useEffect(() => {
		if (!currency || !code.trim() || !taxProfile || price === null) {
			setSaveDisabled(true);
			return;
		}

		if (currency !== scale.Currency__ ||
			code.trim() !== scale.Code ||
			price !== scale.Fee ||
			vatIncluded !== (scale.Vat_Included === 'Y') ||
			weight !== scale.Max_Weight ||
			taxProfile !== scale.Tax_Profile
		) {
			setSaveDisabled(false);
			return;
		}

		setSaveDisabled(true);

		// detect changes
	}, [scale, currency, code, taxProfile, setSaveDisabled, price, vatIncluded, weight]);

	const handleClose = () => {
		setOpen(false);
	};

	const handleCreate = (e) => {
		e.preventDefault();
		update(scale.Catalog_Deliverable_Fee_Scale__, {
			Code: code.trim(),
			Currency__: currency,
			Max_Weight: weight,
			Tax_Profile: taxProfile,
			Fee: price,
			Vat_Included: vatIncluded ? 'Y' : 'N',
		})
			.then((s) => refresh(scale.Catalog_Deliverable_Fee__))
			.then(handleClose);
	};

	return (
		<Dialog
			disableEnforceFocus
			open={open}
			aria-labelledby={t('edit_shipping_fee_scale')}
			aria-describedby={t('edit_shipping_fee_scale')}
			fullWidth
			maxWidth="md"
			onEnter={() => {
				setCode(scale.Code);
				setCurrency(scale.Currency__);
				setPrice(scale.Fee);
				setWeight(scale.Max_Weight);
				setVatIncluded(scale.Vat_Included === 'Y');
				setTaxProfile(scale.Tax_Profile);
			}}
		>
			<form onSubmit={handleCreate}>
				<DialogTitle>{t('create_shipping_fee')}</DialogTitle>
				<DialogContent>
					<Box py={2}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={6}>
								<TextField
									label={t('shipping_fee_scale_code')}
									fullWidth variant="outlined" value={code}
									required
									disabled={updating}
									onChange={e => setCode(e.target.value)}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<FeeScaleTaxProfileSelector
									value={taxProfile}
									setValue={setTaxProfile}
									disabled={updating}
									required
								/>
							</Grid>

							<Grid item xs={12}>
								<TextField
									label={t('shipping_fee_scale_max_weight')}
									helperText={t('shipping_fee_scale_max_weight_helperText')}
									fullWidth variant="outlined" value={weight}
									type="number"
									required
									inputProps={{min: 0}}
									disabled={updating}
									onChange={e => setWeight(e.target.value)}
									InputProps={{
										endAdornment: <InputAdornment position="end">g</InputAdornment>,
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Price
									variant="outlined"
									fullWidth
									required
									setVatIncluded={setVatIncluded}
									vatIncluded={vatIncluded}
									value={price}
									onValueChange={setPrice}
									label={vatIncluded ? t('plan_price_vat_lbl') : t('plan_price_lbl')}
									helperText={vatIncluded ? t('plan_price_vat_helperText') : t('plan_price_helperText')}
									disabled={updating}
								/>
							</Grid>

						</Grid>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button color="default" onClick={handleClose} disabled={updating}>
						{t('close_btn')}
					</Button>

					<Button color="primary" type="submit" variant="contained"
					        disabled={updating || saveDisabled}
					        loading={updating}>
						{t('save_btn')}
					</Button>
				</DialogActions>
			</form>
		</Dialog>

	);
};
const mapStateToProps = (state) => {
	return {
		updating: state.shippingFee.scaleUpdating,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		update: (scaleId, data) => dispatch(updateShippingFeeScale(scaleId, data)),
		refresh: (feeId) => dispatch(fetchShippingFeeScales(feeId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDialog);