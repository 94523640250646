import React from 'react';
import TableRow from "@material-ui/core/TableRow";
import Skeleton from "@material-ui/lab/Skeleton";
import TableCell from "@material-ui/core/TableCell";
import moment from "moment";
import SearchIcon from '@material-ui/icons/Search';
import {useTranslation} from 'react-i18next';
import Button from "../../../inputs/Button";

const Row = ({
                 mail, loading, onSelected = () => {
    }
             }) => {
    const displayHistory = (!loading && mail);
    const {t} = useTranslation();

    return (
        <TableRow>
            <TableCell>
                {!displayHistory && <Skeleton width={17}/>}
                {displayHistory && <span>{mail.Subject}</span>}
            </TableCell>
            <TableCell>
                {!displayHistory && <Skeleton/>}
                {displayHistory && <span>{moment(parseInt(mail.Date.unixms)).format('LLL')}</span>}
            </TableCell>
            <TableCell align='right'>
                {!displayHistory && <Skeleton/>}
                {
                    displayHistory &&
                    <Button variant='contained'
                            color='primary'
                            startIcon={<SearchIcon/>}
                            onClick={() => onSelected(mail)}
                    >
                        {t('view_btn')}
                    </Button>
                }
            </TableCell>
        </TableRow>
    );
};


export default Row;