import React, {useState} from 'react';
import {useTranslation}  from 'react-i18next';
import Grid              from '@material-ui/core/Grid';
import AppBarTitle       from '../components/common/typography/AppBarTitle';
import Group             from '../components/common/group/Group';
import Button            from '../components/common/inputs/Button';
import AddIcon           from '@material-ui/icons/Add';
import {connect}         from 'react-redux';
import CreateDialog      from '../components/common/group/CreateDialog';
import HasRight          from '../components/Route/HasRight';
import Skeleton          from '@material-ui/lab/Skeleton';
import Toolbar           from '../components/common/typography/Toolbar';
import SelectRealm       from './SelectRealm';

const WorkGroup = ({selectedRealm, access}) => {
	const {t} = useTranslation();
	const [showGrant, setShowGrant] = useState(false);

	if (!selectedRealm) return <SelectRealm/>;

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('work_group_title')}/>

			<Grid item xs={12}>
				<Toolbar title={t('work_group_title')}>
					<HasRight admin loadingComponent={<Skeleton width={100} height={50}/>}>
						<Button endIcon={<AddIcon/>} onClick={e => setShowGrant(true)} variant="contained"
						        color="primary">
							{t('grant_btn')}
						</Button>
					</HasRight>
				</Toolbar>
			</Grid>

			<Grid item xs={12}>
				<Group/>
			</Grid>
			<HasRight rights={['O', 'A']} hideLoading={true}>
				<CreateDialog open={showGrant} setOpen={setShowGrant} currentRight={access.available}/>
			</HasRight>
		</Grid>
	);
};


const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		access: state.access.access,
	};
};

export default connect(mapStateToProps)(WorkGroup);
