import React from 'react';
import {Grid} from "@material-ui/core";
import {Title} from "../typography/Title";
import {useTranslation} from "react-i18next";
import Picture from "./Picture";
import ChangeEmail from "./ChangeEmail";

const Account = () => {
    const {t} = useTranslation();

    return (
        <Grid container spacing={3} alignItems="center">
            <Grid item xs={12}>
                <Title>{t('profile_account')}</Title>
            </Grid>
            <Grid item xs={12} md={4}>
                <Picture/>
            </Grid>
            <Grid item xs={12} md={2}>
            </Grid>
            <Grid item xs={12} md={4}>
                <ChangeEmail/>
            </Grid>
        </Grid>
    );
};

export default Account;