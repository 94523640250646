import ShippingList    from '../../../pages/store/shipping/List';
import ShippingFeeList from '../../../pages/store/shipping/fee/List';
import ShippingFeeEdit from '../../../pages/store/shipping/fee/Edit';
import WarehouseList   from '../../../pages/store/warehouse/List';
import WarehouseEdit   from '../../../pages/store/warehouse/Edit';
import ShopList        from '../../../pages/store/shop/List';
import CouponList      from '../../../pages/store/coupon/List';
import ProductEdit     from '../../../pages/store/shop/Edit';
import ProductCreate   from '../../../pages/store/shop/Create';
import CreateStore     from '../../../pages/store/Create';
import {
	getStoreBankRoute,
	getStoreCouponsRoute,
	getStoreProductCreateRoute,
	getStoreProductCreateVariationRoute,
	getStoreProductEditRoute,
	getStoreShipmentsRoute,
	getStoreShippingFeeEditRoute,
	getStoreShippingFeesRoute,
	getStoreShopRoute,
	getStoreWarehouseEditRoute,
	getStoreWarehouseRoute,
	getStoreWizardRoute
}                      from './factory/store';
import Bank            from '../../../pages/store/Bank';

export default [
	{
		path: getStoreCouponsRoute(),
		component: CouponList,
		exact: true,
		type: 'route',
		loginRequired: true,
	},
	{
		path: getStoreShipmentsRoute(),
		component: ShippingList,
		exact: true,
		type: 'route',
		loginRequired: true,
	},
	{
		path: getStoreShopRoute(),
		component: ShopList,
		exact: true,
		type: 'route',
		loginRequired: true,
	},
	{
		path: getStoreProductCreateRoute(),
		component: ProductCreate,
		exact: true,
		type: 'route',
		loginRequired: true,
	},
	{
		path: getStoreProductCreateVariationRoute(),
		component: ProductCreate,
		exact: true,
		type: 'route',
		loginRequired: true,
	},
	{
		path: getStoreProductEditRoute(),
		component: ProductEdit,
		exact: true,
		type: 'route',
		loginRequired: true,
	},
	{
		path: getStoreWarehouseRoute(),
		component: WarehouseList,
		exact: true,
		type: 'route',
		loginRequired: true,
	},
	{
		path: getStoreWarehouseEditRoute(),
		component: WarehouseEdit,
		exact: true,
		type: 'route',
		loginRequired: true,
	},
	{
		path: getStoreWizardRoute(),
		component: CreateStore,
		exact: true,
		type: 'route',
		loginRequired: true,
	},
	{
		path: getStoreBankRoute(),
		component: Bank,
		exact: true,
		type: 'route',
		loginRequired: true,
	},
	{
		path: getStoreShippingFeesRoute(),
		component: ShippingFeeList,
		exact: true,
		type: 'route',
		loginRequired: true,
	},
	{
		path: getStoreShippingFeeEditRoute(),
		component: ShippingFeeEdit,
		exact: true,
		type: 'route',
		loginRequired: true,
	},
];
