import React, { Fragment, useEffect }                           from 'react';
import TableHead                                                from '@material-ui/core/TableHead';
import MaterialTable                                            from '@material-ui/core/Table';
import TableRow                                                 from '@material-ui/core/TableRow';
import TableCell                                                from '@material-ui/core/TableCell';
import TableContainer                                           from '@material-ui/core/TableContainer';
import Row                                                      from './Row';
import { useTranslation }                                       from 'react-i18next';
import Empty                                                    from './Empty';
import TableBody                                                from '@material-ui/core/TableBody';
import TableFooter                                              from '@material-ui/core/TableFooter';
import { fetchProducts, setProductsFilters, setProductsPaging } from '../../../../../store/actions/store/ProductAction';
import { PRODUCT_LIST_MAIN_IMAGE_VARIATION }                    from '../../../../../store/reducers/store/ProductReducer';
import { connect }                                              from 'react-redux';
import Pagination                                               from '../../../data/table/Pagination';

const Table = ({ catalog, products, fetch, loading, filters, paging, count, setProductsPaging, setProductsFilters, isVariation = false }) => {
	const loadingData = [...Array(paging.results_per_page).keys()];
	const { t } = useTranslation();
	const displayLoading = () => loadingData.map((k) => <Row
		key={k}
		loading={true}
		product={null}
	/>);

	const displayProducts = () => products.map((u, idx) => <Row key={u.Catalog_Product__}
	                                                            loading={false}
	                                                            product={u}/>);
	useEffect(() => {
		// Reset filters the first time
		setProductsFilters({ ...filters, image_variation: PRODUCT_LIST_MAIN_IMAGE_VARIATION });
		// eslint-disable-next-line
	}, [setProductsFilters]);

	useEffect(() => {
		fetch(catalog.Catalog__);
	}, [fetch, filters, paging, catalog]);

	/*const {sort} = filters;

	const sortHandler = (property, dir) => {
		let p = {...filters};
		// only 1 sort
		p.sort = {};
		p.sort[property] = dir;
		setRecordsFilters(p);
	};*/

	return (
		<Fragment>
			{(loading || products.length > 0) &&
			<TableContainer>
				<MaterialTable aria-label='simple table'>
					<TableHead>
						<TableRow>
							<Pagination
								setPaging={setProductsPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
						<TableRow>
							{/*<TableCell tyle={{width:'40px'}}> {t('tablecell_product_sold')}</TableCell>*/}
							<TableCell style={{ width: '75px' }}> {t('tablecell_product_picture')}</TableCell>
							<TableCell> {t('tablecell_product_internal_name')}</TableCell>
							<TableCell> {t('tablecell_product_name')}</TableCell>
							<TableCell align='right'/>
						</TableRow>
					</TableHead>
					<TableBody>
						{loading && displayLoading()}
						{!loading && displayProducts()}
					</TableBody>
					<TableFooter>
						<TableRow>
							<Pagination
								setPaging={setProductsPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
					</TableFooter>

				</MaterialTable>
			</TableContainer>
			}
			{(!loading && products.length < 1) && <Empty isVariation={isVariation}/>}
		</Fragment>
	);

};


const mapStateToProps = (state) => {
	return {
		filters: state.product.productsFilters,
		paging: state.product.productsPaging,
		count: state.product.productsPagingCount,
		loading: state.product.productsLoading,
		products: state.product.products,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: (catalogId) => dispatch(fetchProducts(catalogId)),
		setProductsPaging: (newPaging) => dispatch(setProductsPaging(newPaging)),
		setProductsFilters: (newFilters) => dispatch(setProductsFilters(newFilters)),

	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);

