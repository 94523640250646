import React from 'react';
import HasCapability from "./HasCapability";
import HasRight from "./HasRight";

/**
 * Allow to check both for HasCapability first then HasRight
 */
const AccessCheck = ({
                         capabilities = {}, rights = {},
                         children,
                         hideLoading = false,
                         deniedComponent = null,
                         loadingComponent = null,
                     }) => {
    return (
        <HasCapability {...capabilities} hideLoading={hideLoading} deniedComponent={deniedComponent}
                       loadingComponent={loadingComponent}>
            <HasRight {...rights} hideLoading={hideLoading} deniedComponent={deniedComponent}
                      loadingComponent={loadingComponent}>
                {children}
            </HasRight>
        </HasCapability>
    );
};

export default AccessCheck;