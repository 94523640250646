import React, { Fragment, useEffect } from 'react';
import TableHead                      from '@material-ui/core/TableHead';
import MaterialTable                  from '@material-ui/core/Table';
import TableRow                       from '@material-ui/core/TableRow';
import TableCell                      from '@material-ui/core/TableCell';
import TableBody                      from '@material-ui/core/TableBody';
import TableContainer                 from '@material-ui/core/TableContainer';
import Row                            from './Row';
import { connect }                    from 'react-redux';
import TableFooter                    from '@material-ui/core/TableFooter';
import { useTranslation }             from 'react-i18next';
import Empty                          from './Empty';
import {
	fetchQuestions,
	setQuestionsFilters,
	setQuestionsPaging
}                                     from '../../../../../../store/actions/planner/QuestionAction';
import HasRight                       from '../../../../../Route/HasRight';
import Pagination                     from '../../../../data/table/Pagination';

const Table = ({ event, onSelected, questions, fetch, loading, filters, paging, count, setQuestionsPaging, setQuestionsFilters }) => {
	const loadingData = [...Array(paging.results_per_page).keys()];
	const { t } = useTranslation();
	const displayLoading = () => loadingData.map((k) => <Row key={k} loading={true} question={null}/>);
	const displayQuestions = () => questions.map((u, idx) => <Row onSelected={onSelected}
	                                                              key={u.Planner_Event_Question__} loading={false}
	                                                              question={u}/>);
	useEffect(() => {
		// Reset filters the first time
		setQuestionsFilters({ sort: { Index: 'ASC' } });
	}, [setQuestionsFilters]);

	useEffect(() => {
		if (!event) return;
		fetch(event.Planner_Event__);
	}, [fetch, filters, paging, event]);

	/*const {sort} = filters;

	const sortHandler = (property, dir) => {
		let p = {...filters};
		// only 1 sort
		p.sort = {};
		p.sort[property] = dir;
		setSchedulesFilters(p);
	};*/

	return (
		<Fragment>
			{(loading || questions.length > 0) &&
			<TableContainer>
				<MaterialTable aria-label='simple table'>
					<TableRow>
						<Pagination
							setPaging={setQuestionsPaging}
							paging={paging}
							loading={loading}
							count={count}
						/>
					</TableRow>
					<TableHead>
						<TableRow>
							<TableCell>{t('tablecell_visible')}</TableCell>
							<TableCell>{t('tablecell_question_code')}</TableCell>
							<TableCell>{t('tablecell_question_index')}</TableCell>
							<TableCell> {t('tablecell_question_label')}</TableCell>
							<TableCell> {t('tablecell_question_type')}</TableCell>
							<TableCell> {t('tablecell_question_options')}</TableCell>
							<HasRight manager>
								<TableCell align='right'/>
							</HasRight>
						</TableRow>
					</TableHead>
					<TableBody>
						{loading && displayLoading()}
						{!loading && displayQuestions()}
					</TableBody>
					<TableFooter>
						<TableRow>
							<Pagination
								setPaging={setQuestionsPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
					</TableFooter>

				</MaterialTable>
			</TableContainer>
			}
			{(!loading && questions.length < 1) && <Empty/>}
		</Fragment>
	);

};


const mapStateToProps = (state) => {
	return {
		filters: state.question.questionsFilters,
		paging: state.question.questionsPaging,
		count: state.question.questionsPagingCount,
		loading: state.question.questionsLoading,
		questions: state.question.questions
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: (eventId) => dispatch(fetchQuestions(eventId)),
		setQuestionsPaging: (newPaging) => dispatch(setQuestionsPaging(newPaging)),
		setQuestionsFilters: (newFilters) => dispatch(setQuestionsFilters(newFilters))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);

