import React, {Fragment, useState} from 'react';
import {Editor}                    from '@tinymce/tinymce-react';
import GalleryDialog               from '../cms/gallery/GalleryDialog';
import TemplateDialog              from '../cms/template/TemplateDialog';

const RichText = ({value, onChange, entryId = null, cmsId = null, disabled = false}) => {
	const [editor, setEditor] = useState(null);
	const [cmsGalleryShown, setCmsGalleryShown] = useState(false);
	const [cmsTemplateShown, setCmsTemplateShown] = useState(false);

	const onGalleryInsert = (html) => {
		if (!editor) return;
		editor.execCommand('mceInsertContent', false, html);
	};

	const onTemplateInsert = (content) => {
		if (!editor) return;
		editor.execCommand('mceInsertContent', false, content);
	};

	return (
		<Fragment>
			<Editor
				disabled={disabled}
				value={value}
				apiKey="f47lp3yghrhxxco29z8y35ia2g2puzvm1nrdsdl4wf5hhiqq" // might need change this
				init={{
					height: 500,
					menubar: false,
					plugins: [
						'advlist autolink lists link image charmap print preview anchor',
						'searchreplace visualblocks code fullscreen',
						'insertdatetime media table paste code wordcount'
					],
					content_css: [
						'//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
					],
					toolbar1:
						'undo redo | formatselect | ' +
						' bold italic forecolor backcolor | alignleft aligncenter ' +
						' alignright alignjustify | bullist numlist outdent indent |' +
						' removeformat | table',
					toolbar2: 'entryImg media link main cover template code',
					setup: (editor) => {
						setEditor(editor);
						if (entryId) {

							editor.ui.registry.addButton('entryImg', {
								icon: 'gallery',
								onAction: () => {
									setCmsGalleryShown(true);
								}
							});
						}

						if (cmsId) {
							editor.ui.registry.addButton('template', {
								icon: 'template',
								onAction: () => {
									setCmsTemplateShown(true);
								}
							});
						}
					}
				}}
				onEditorChange={onChange}
			/>
			{entryId && <GalleryDialog entryId={entryId} open={cmsGalleryShown} setOpen={setCmsGalleryShown}
			                           insertCallback={onGalleryInsert}/>}
			{cmsId && <TemplateDialog cmsId={cmsId} open={cmsTemplateShown} setOpen={setCmsTemplateShown}
			                          insertCallback={onTemplateInsert}/>}
		</Fragment>
	);
};

export default RichText;
