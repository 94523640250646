import Order                                       from "../../../pages/site/Order";
import Orders                                      from "../../../pages/site/Orders";
import {getSiteOrderEditRoute, getSiteOrdersRoute} from "./factory/order";

export default [
    {
        path: getSiteOrdersRoute(),
        component: Orders,
        exact: true,
        type: 'route',
        loginRequired: true,
    },
    {
        path: getSiteOrderEditRoute(),
        component: Order,
        exact: false,
        type: 'route',
        loginRequired: true,
    },
]
