import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux'
import ChipMultipleSelect from "../../../inputs/ChipMultipleSelect";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import {InactiveChip, PaidChip} from "../../../data/chip/style";
import OfflinePinOutlinedIcon from "@material-ui/icons/OfflinePinOutlined";
import {fetchPlans}                            from "../../../../../store/actions/fanclub/PlanAction";
import { setMembersFilters, setMembersPaging } from "../../../../../store/actions/fanclub/MembershipAction";
import CardMembershipIcon                      from "@material-ui/icons/CardMembership";
import Chip             from "@material-ui/core/Chip";
import Grid             from "@material-ui/core/Grid";
import DeleteIcon       from '@material-ui/icons/Delete';
import SearchIcon       from '@material-ui/icons/Search';
import {useTranslation} from 'react-i18next';
import TextField                   from "@material-ui/core/TextField";
import moment                      from 'moment';
import FormControl                 from '@material-ui/core/FormControl';
import FormLabel                   from '@material-ui/core/FormLabel';
import RadioGroup                  from '@material-ui/core/RadioGroup';
import FormControlLabel            from '@material-ui/core/FormControlLabel';
import Radio                       from '@material-ui/core/Radio';
import Date                        from '../../../inputs/Date';
import { useHistory, useLocation } from 'react-router-dom';
import queryString                 from 'query-string';
import { arrayEquals }             from '../../../../utils/misc';
import Button                      from '../../../inputs/Button';

const Filters = ({plans, fetchPlans, setMembersFilters, selectedFanclub, plansLoading, filters, setShowFilters}) => {
    useEffect(() => {
        if (selectedFanclub) fetchPlans();
    }, [selectedFanclub, fetchPlans]);
    const {t} = useTranslation();

	const history = useHistory();
	const location = useLocation();
	let params = queryString.parse(location.search);

    const [planValues, setPlanValues] =  useState(params.pl ? (Array.isArray(params.pl) ? params.pl : [params.pl] ) : []);
    const [statusValues, setStatusValues] = useState(params.st ? (Array.isArray(params.st) ? params.st : [params.st] ) : []);
    const [search, setSearch] = useState(params.s);
    const [startFilter, setStartFilter] = useState(params.start ? moment(parseInt(params.start.replace('@', '')) * 1000) : null)
    const [startFilterMode, setStartFilterMode] = useState(params.sm ?? '$lt')


    const statusFilters = {
        inactive: <InactiveChip size="small" variant="outlined" icon={<HighlightOffOutlinedIcon/>} label={t('status_cancelled')}/>,
        paid: <PaidChip size="small" variant="outlined" icon={<OfflinePinOutlinedIcon/>} label={t('status_paid')}/>,
    };

    const membershipIcon = <CardMembershipIcon/>;
    let plansFilters = {};
    plans.forEach(p =>
        plansFilters[p.Membership_Plan__] =
            <Chip variant="outlined" size="small" color="primary" icon={membershipIcon} label={p.Catalog_Product['Basic.Name']}/>
    );

	const handleSearchChange = e => {
		if (e.key !== 'Enter')
			return;

		filterHandler()
	};

	useEffect(() => {
		let params = queryString.parse(location.search);
		let f = {...filters};
		let hasChange = false;

		if ((!!params.s && filters?.value !== params.s)) {
			f.value = params.s
			hasChange = true;
		}else if(!params.s) {
			delete f.value
			hasChange = true;
		}

		if (!!params.pl) {
			const current = filters?.Membership_Plan__?.['$in'] ?? [];
			if(!arrayEquals(current, params.pl)) {
				f.Membership_Plan__ = { '$in': params.pl };
				hasChange = true;
			}
		}else{
			delete f.Membership_Plan__
			hasChange = true;
		}

		if (!!params.st) {
			const current = filters?.Status?.['$in'] ?? [];
			if(!arrayEquals(current, params.st)) {
				f.Status = { '$in': params.st };
				hasChange = true;
			}
		}else {
			delete f.Status
			hasChange = true;
		}

		const requestedMode = params.sm
		const start = params.start ?? null
		const currentStart = filters?.Start?.['$lt'] ?? (filters?.Start?.['$gt'] ?? null)
		const currentMode = !filters.Start ? '$lt' : (('$lt' in filters.Start) ? '$lt' : '$gt')

		if(requestedMode && start) {
			if (!start && filters.Start) {
				delete f.Start;
				hasChange = true;
			} else if (!!start && start !== currentStart) {
				f.Start = { [requestedMode]: start }
				hasChange = true;
			} else if (start === currentStart) {
				// Detect mode change
				if (requestedMode !== currentMode) {
					f.Start = { [requestedMode]: start };
					hasChange = true;
				}
			}
		}else {
			delete f.Start;
			hasChange = true;
		}

		if(hasChange) {
			setShowFilters(true)
			setMembersFilters(f);
			setMembersPaging({ page_no: 1 });
		}


		// eslint-disable-next-line
	}, [location]);

	const filterHandler = (clear = false) => {
		let params = queryString.parse(location.search);
		delete params.s;
		delete params.pl;
		delete params.st;
		delete params.start;
		delete params.sm;
		if(!clear) {
			params.s = search;
			params.pl = planValues;
			params.st = statusValues;
			if(startFilter) {
				params.start = '@' + (startFilter.unix());
				params.sm = startFilterMode;
			}
		}

		params.p = 1;
		history.push(location.pathname + '?' + queryString.stringify(params));

    };

    const clearFiltersHandler = () => {
	    let params = {
		    ...filters
	    };

	    delete params.Membership_Plan__;
	    delete params.Status;
	    delete params.value;
	    delete params.Start;

	    setPlanValues([]);
	    setStatusValues([]);
	    setSearch("");
	    setStartFilterMode('$lt');
	    setStartFilter(null);

	    filterHandler(true);
    }

	useEffect(() => {
		return () => {
			setMembersFilters({});
		};
		// eslint-disable-next-line
	}, []);

    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={3}
        >
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={search}
                        onKeyPress={handleSearchChange}
                        onChange={e => setSearch(e.target.value)}
                        placeholder={t('placeholder_search_users')}/>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <ChipMultipleSelect disabled={plansLoading} label={t('status_lbl')} setValue={setStatusValues}
                                        value={statusValues} data={statusFilters} id='status-filter'/>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <ChipMultipleSelect disabled={plansLoading} label={t('subscription_filter_plan')} setValue={setPlanValues} value={planValues}
                                        data={plansFilters} id='plan-filter'/>
                </Grid>

	            <Grid item xs={12}  md={6} lg={6}>
		            <FormControl component="fieldset">
			            <FormLabel component="legend">{t('subscription_filter_start')}</FormLabel>
			            <RadioGroup value={startFilterMode} onChange={e => setStartFilterMode(e.target.value)} row>
				            <FormControlLabel disabled={plansLoading} value="$lt" control={<Radio />} label={t('subscription_filter_start_before')} />
				            <FormControlLabel disabled={plansLoading} value="$gt" control={<Radio />} label={t('subscription_filter_start_after')} />
			            </RadioGroup>
		            </FormControl>
	            </Grid>
	            <Grid item xs={12}  md={6} lg={6}>
		            <Date
			            clearable
			            disabled={plansLoading}
			            label={t('subscription_filter_start_date')}
			            inputVariant="outlined"
			            fullWidth
			            value={startFilter ?? null}
			            onChange={setStartFilter}
		            />
	            </Grid>
            </Grid>

            <Grid container item justify="flex-end" spacing={1}>
                <Grid item>
                    <Button disabled={plansLoading} variant="contained" color='secondary' startIcon={<DeleteIcon/>}
                            onClick={clearFiltersHandler}>{t('clear_filters_btn')}</Button>
                </Grid>
                <Grid item>
                    <Button disabled={plansLoading} variant="contained" color='primary' endIcon={<SearchIcon/>}
                            onClick={() => filterHandler()}>{t('filter_btn')}</Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        plans: state.plan.plans,
        plansLoading: state.plan.plansLoading,
        selectedFanclub: state.membership.selectedFanclub,
        filters: state.membership.membersFilters
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchPlans: () => dispatch(fetchPlans()),
        setMembersFilters: (params) => dispatch(setMembersFilters(params))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
