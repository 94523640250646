import React from 'react';
import {useTranslation} from "react-i18next";
import EmptyState from "../../../feeback/State/Empty";
import Graphic from "../../../../../resources/images/gallery.svg";

const Empty = () => {
    const {t} = useTranslation();
    return (
        <EmptyState
            title={t('gallery_empty_title')}
            textI18Key='product_gallery_empty_text'
            graphic={Graphic}
        />
    );
};

export default Empty;