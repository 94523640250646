import PropTypes          from 'prop-types';
import React              from 'react';
import Tab                from '@material-ui/core/Tab';
import { useTranslation } from 'react-i18next';

const PaymentMethodTab = ({ method, ...rest }) => {
	const { t } = useTranslation();

	switch (method) {
		case 'Free':
		case 'OnFile':
		case 'Stripe':
			return <Tab label={t(`payment_method_${method.toLowerCase()}`)} {...rest}/>;
		default:
			return null;
	}
};

export default PaymentMethodTab;

PaymentMethodTab.propTypes = {
	disabled: PropTypes.bool.isRequired,
	method: PropTypes.string.isRequired
};
