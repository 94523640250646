import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Alert from "@material-ui/lab/Alert";
// dirty
const useStyles = makeStyles(() => {
    return {
        fixAlert: {
            '& > .MuiAlert-message': {
                display: 'block',
            }
        }
    }
});

const RichAlert = ({children, ...rest}) => {
    const classes = useStyles()
    return (
        <Alert {...rest} classes={classes.fixAlert}>
            {children}
        </Alert>
    );
};

export default RichAlert;