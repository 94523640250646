import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import {fetchDeliverables, setDeliverablesFilters} from "../../../store/actions/store/DeliverableAction";

const DeliverableSelector = ({existing, catalogId, loading, list, fetch, filters, value, onChange, required = false, disabled = false, setFilters}) => {
    const {t} = useTranslation();

    const buildNotMap = useCallback(() => {
        const notMap = {};
        existing.forEach(e => notMap[e.Catalog_Deliverable__] = e);
        return notMap;
    }, [existing]);

    const [not, setNot] = useState(buildNotMap());


    useEffect(() => {
        setFilters({});
        setNot(buildNotMap())
    }, [setNot, existing, buildNotMap, setFilters]);

    const onChangeHandler = (e) => {
        setFilters({Name: {'$prefix': e.target.value},})
    };

    useEffect(() => {
        fetch(catalogId);
    }, [fetch, filters, existing, catalogId]);

    return (
        <Autocomplete
            id="catalog-deliverable-select"
            disabled={disabled}
            value={value}
            onBlur={() => { setFilters({});fetch(catalogId)}}
            onChange={(e, value) => onChange(value)}
            getOptionSelected={(option, value) => option.Catalog_Deliverable__ === value.Catalog_Deliverable__}
            getOptionLabel={(option) => option === '' ? '' : option.Name}
            getOptionDisabled={(option) => ([option.Catalog_Deliverable__] in not)}
            options={list}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    required={required}
                    label={t('product_deliverable_selector_name')}
                    helperText={t('product_deliverable_selector_name_helperText')}
                    variant="outlined"
                    onChange={onChangeHandler}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );

};

const mapStateToProps = (state) => {
    return {
        loading: state.deliverable.deliverablesLoading,
        list: state.deliverable.deliverables,
        filters: state.deliverable.deliverablesFilters,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetch: (catalogId) => dispatch(fetchDeliverables(catalogId)),
        setFilters: (filters) => dispatch(setDeliverablesFilters(filters))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverableSelector);
