import TableSortLabel from "@material-ui/core/TableSortLabel";
import React from "react";

export default ({sort, field, label, onClick}) => {
    let direction = 'asc';
    let sortHandlerDir = 'asc';

    if (sort && field in sort) {
        direction = sort[field].toLowerCase();
        if (direction === 'asc') sortHandlerDir = 'DESC';
        else if (direction === 'desc') sortHandlerDir = 'ASC';
    }

    return (
        <TableSortLabel
            onClick={()=>{onClick(field, sortHandlerDir)}}
            active={sort && field in sort}
            direction={direction}>{label}</TableSortLabel>
    )
};