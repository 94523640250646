import React                  from 'react';
import Grid                   from '@material-ui/core/Grid';
import AppBarTitle            from '../../../components/common/typography/AppBarTitle';
import CreateForm             from '../../../components/common/membership/plans/create/Create';
import {useTranslation}       from 'react-i18next';
import Redirect               from '../../../components/Route/Redirect';
import Toolbar                from '../../../components/common/typography/Toolbar';
import {getFanclubPlansRoute} from '../../../router/routes/fanclub/factory/plan';
import AccessCheck            from '../../../components/Route/AccessCheck';

const Create = () => {
	const {t} = useTranslation();

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('fanclub_plans_create_title')}/>

			<AccessCheck rights={{admin: true}} capabilities={{fanclub: true}}
			             deniedComponent={<Redirect target={getFanclubPlansRoute()}/>}>
				<Grid item xs={12}>
					<Toolbar title={t('fanclub_plans_create_title')}/>
				</Grid>

				<Grid item xs={12}>
					<CreateForm/>
				</Grid>
			</AccessCheck>
		</Grid>
	);
};

export default Create;
