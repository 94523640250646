import React, {useEffect, useState}       from 'react';
import {Grid}                             from '@material-ui/core';
import Button                             from '../inputs/Button';
import {Trans, useTranslation}            from 'react-i18next';
import GengoTierSelector                  from '../inputs/GengoTierSelector';
import {useContentCmsEntryQuoteTranslate} from '../../../hooks/api/content/cms/useContentCmsEntry';
import {getSiteContentEditRoute}          from '../../../router/routes/site/factory/content';
import RichAlert                          from '../feeback/RichAlert';
import {useHistory}                       from 'react-router-dom';
import Loading                            from '../feeback/loading/Loading';
import TableContainer                     from '@material-ui/core/TableContainer';
import Table                              from '@material-ui/core/Table';
import CartHeader                         from '../cart/Cart/CartHeader';
import TableBody                          from '@material-ui/core/TableBody';
import CartItems                          from '../cart/Cart/CartItems';
import CartPrices                         from '../cart/Cart/CartPrices';
import FormRequiredField                  from '../feeback/FormRequiredField';

const TranslationOptions = ({options, setOptions, next, sourceLang, targetLang, entryId, sourceEntryData}) => {
	const {t} = useTranslation();
	const [isValid, setIsValid] = useState(false);
	const [orderQuote, ordering] = useContentCmsEntryQuoteTranslate(entryId);
	const [cart, setCart] = useState(null);
	const [existingData, setExistingData] = useState(null);
	const history = useHistory();

	useEffect(() => {
		if (!cart || !('tier' in options)) {
			setIsValid(false);
			return;
		}

		setIsValid(true);
	}, [options, setIsValid, cart]);

	useEffect(() => {
		if (!('tier' in options)) return;
		orderQuote(sourceLang, targetLang, options.tier)
			.then(d => {
				if ('Content_Cms_Entry_Data__' in d) {
					setExistingData(d.Content_Cms_Entry_Data__);
				} else {
					setCart(d);
				}
			});

	}, [options, orderQuote, setCart, setExistingData, sourceLang, targetLang]);

	const createChangeHandler = (key, isEvent = true) => e => {
		setOptions({...options, [key]: isEvent ? e.target.value : e});
	};

	const getValue = (key, def = '') => {
		if (!(key in (options ?? {}))) return def;
		return options[key];
	};

	if (sourceLang === targetLang) {
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<RichAlert
					severity="info"
					action={
					<Button
						onClick={() => history.push(getSiteContentEditRoute(sourceEntryData.Content_Cms_Entry_Data__))}
						color="primary"
						variant="contained"
						size="small"
					>
						{t('view_btn')}
					</Button>
				}

					>
					<Trans i18nKey="translate_already_exist_info">
						sample<br/>
						<strong>sample</strong>
					</Trans>
				</RichAlert>
				</Grid>
			</Grid>
		)
	}

	return (
		<Grid container spacing={3}>
			<FormRequiredField/>
			<Grid item xs={12}>
				<GengoTierSelector
					value={getValue('tier')}
					setValue={createChangeHandler('tier', false)}
					required
					fullWidth
					sourceLang={sourceLang}
					targetLang={targetLang}
				/>
			</Grid>

			{ordering && <Grid item xs={12}>
				<Loading/>
			</Grid>}

			{existingData && <Grid item xs={12}>
				<RichAlert
					severity="info"
					action={
						<Button
							onClick={() => history.push(getSiteContentEditRoute(existingData))}
							color="primary"
							variant="contained"
							size="small"
						>
							{t('view_btn')}
						</Button>
					}

				>
					<Trans i18nKey="translate_already_exist_info">
						sample<br/>
						<strong>sample</strong>
					</Trans>
				</RichAlert>
			</Grid>}

			{(cart && !ordering) && <>
				<Grid item xs={12}>
					{t('cart_title')}
				</Grid>

				<Grid item xs={12}>
					<TableContainer>
						<Table>
							<CartHeader hasAction={false}/>
							<TableBody><CartItems items={cart.products}/></TableBody>
						</Table>
					</TableContainer>
				</Grid>

				<Grid item xs={12}>
					<CartPrices cart={cart}/>
				</Grid>
			</>}

			<Grid item xs={12}>
				<Grid justify="flex-end" container>
					<Button
						variant="contained"
						color="primary"
						onClick={() => next()}
						disabled={!isValid || ordering}
					>
						{t('order_translation_btn')}
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default TranslationOptions;