import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {connect} from 'react-redux'
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from 'react-i18next';
import {deleteDeliverableFile} from "../../../../store/actions/store/DeliverableAction";
import Alert from "@material-ui/lab/Alert";
import Button from "../../inputs/Button";


const DeleteFileDialog = ({deliverable, file, open, setOpen, deleting, deleteDeliverableFile}) => {
    const {t} = useTranslation();

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        deleteDeliverableFile(deliverable.Catalog_Deliverable__, file.Catalog_Deliverable_File__)
            .then(handleClose)
    };

    return (
        <Dialog
            open={open}
            aria-labelledby={t('confirm_delete_deliverable_file_title')}
            aria-describedby={t('confirm_delete_deliverable_file_title')}
            fullWidth
        >
            <DialogTitle id="deliverable-deletefile-title">{t('confirm_delete_deliverable_file_title')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="deliverable-deletefile-description">
                    <Alert severity='warning'>
                        {t('confirm_delete_deliverable_file_desc')}
                    </Alert>
                    <br/>
                    <Typography variant="body1" color="textPrimary" component="span">{t('are_you_sure')}</Typography>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button variant='contained' color="primary" autoFocus onClick={handleClose} disabled={deleting}>
                    {t('back_btn')}
                </Button>
                <Button color="secondary" loading={deleting} disabled={deleting} endIcon={<DeleteIcon/>}
                        onClick={handleDelete}>
                    {t('delete_btn')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {
        deleting: state.deliverable.deletingFile
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        deleteDeliverableFile: (id, fid) => dispatch(deleteDeliverableFile(id, fid)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteFileDialog);