import React, { Component }                          from 'react';
import { flow, initFlow, oauthTrigger, uploadFiles } from '../../store/actions/system/FlowActions';
import { connect }                                   from 'react-redux';
import Redirect                                                from '../Route/Redirect';
import { withRouter }                                          from 'react-router-dom';
import { changeUser }                                          from '../../store/actions/system/UserActions';
import { Get }                                                 from '@karpeleslab/klbfw';
import CircularProgress                                        from '@material-ui/core/CircularProgress';
import TextField                                               from '@material-ui/core/TextField';
import { LoginForm, OAuth2Wrapper, OauthBtn, UserInfoWrapper } from './styles';
import FormControlLabel                                        from '@material-ui/core/FormControlLabel';
import Checkbox                                                from '@material-ui/core/Checkbox';
import AvatarEditor                                            from '../avatar-editor/AvatarEditor';
//import Button from "@material-ui/core/Button";
import Grid                                                    from '@material-ui/core/Grid';
import Loading                                                 from '../common/feeback/loading/Loading';
import { withTranslation }                                     from 'react-i18next';
import Box                                                     from '@material-ui/core/Box';
import Button                                                  from '../common/inputs/Button';
import MaterialLink                                            from '@material-ui/core/Link';

class Login extends Component {

	data = {
		form: {},
		files: []
	};

	submitHandle = (e) => {
		e.preventDefault();

		const cpy = { ...this.data.form };
		this.data.form = {};
		this.props.flow(cpy).catch((err) => {
		});

	};

	isRequired = (field) => {
		return this.props.req.includes(field);
	};

	onInputChange = (e) => {
		if (e.target.getAttribute('type') === 'checkbox')
			this.data.form[e.target.getAttribute('name')] = e.target.checked;
		else
			this.data.form[e.target.getAttribute('name')] = e.target.value;
	};

	onImageChange = (e) => {
		if (e.target.files.length === 0) {
			return;
		}
		this.data.files[e.target.getAttribute('name')].file = e.target.files[0];
	};

	setAvatarImage = (name, image) => {
		this.data.files[name].file = image;
	};

	oauthBtnClickHandle = (id) => {
		let oauth = this.props.fields.find((field) => field.id === id);
		if(oauth && oauth?.info?.Button_Extra?.trigger) {
			let ctx = this;
			// eslint-disable-next-line
			this.props.oauthTrigger(oauth.info.Button_Extra.trigger)
				.then(d => {
					return ctx.props.flow(d)
				})
				.catch((err) => {console.log(err)})
		}
		else {
			this.props.flow({oauth2: id}).catch((err) => {});
		}
	};

	buildOauthBtns = () => {
		let fields = this.props.fields.filter((field) => field.type === 'oauth2');
		// eslint-disable-next-line
		fields = fields.filter(field => {
			if (!(field?.info?.Button_Extra?.condition)) return true;
			// eslint-disable-next-line
			return eval(field.info.Button_Extra.condition);
		});
		return fields.map((field, idx) => {
				return (
					<OauthBtn

						key={field.id}
						onClick={() => {
							this.oauthBtnClickHandle(field.id);
						}}
						alt={field.info.Name}
						className={field.info.Token_Name}
						style={{
							backgroundColor: field.button['background-color'],
							backgroundImage: `url(${field.button['logo']})`
						}}
					/>
				);

			});
	};

	buildForm = () => {
		return this.props.fields
			.filter((field) => field.type !== 'oauth2')
			.map((field, idx) => {
				const fieldId = (field.name ?? 0) + idx;
				switch (field.type) {
					case 'text':
					case 'password':
					case 'email' :
						return <TextField
							variant='outlined'
							margin='normal'
							required={this.isRequired(field.name)}
							fullWidth
							name={field.name}
							label={field.label}
							type={field.type}
							id={fieldId}
							key={fieldId}
							onChange={this.onInputChange}
						/>;
					case 'label':
						if (field.link)
							return <MaterialLink href={field.link} key={fieldId}>{field.label}</MaterialLink>;

						return <p key={fieldId} className={`${field.style === 'error' ? 'red' : ''}`}>{field.label}</p>;
					case 'checkbox':

						const checkbox = <Checkbox
							value='remember'
							color='primary'
							name={field.name}
							required={this.isRequired(field.name)}
							id={fieldId}
							onChange={this.onInputChange}
						/>;

						let label = field.label;
						if (field.link)
							label = (
								<MaterialLink
									target='_blank'
									rel='noopener noreferrer'
									href={field.link}>
									{label}
								</MaterialLink>
							);

						return (
							<FormControlLabel
								key={fieldId}
								control={checkbox}
								label={label}
							/>
						);
					case 'image':
						this.data.files[field.name] =
							{
								target: field.target,
								param: field.param,
								file: null,
							};

						return (
							<AvatarEditor key={fieldId} required={this.isRequired(field.name)} field={field}
							              setAvatarImage={this.setAvatarImage} id={fieldId}/>
						);
					default:
						return <div/>;
				}
			});
	};

	buildUserSection = () => {
		if (this.props.user) {
			const image = this.props.user.Profile.Media_Image;

			return (
				<UserInfoWrapper>
					{
						image && <img src={image.Url} alt='Profile'/>
					}
					<h2>{this.props.user.Profile.Display_Name}</h2>
				</UserInfoWrapper>
			);
		} else if (this.props.email) {
			return (
				<UserInfoWrapper>
					<h2>{this.props.email}</h2>
				</UserInfoWrapper>
			);
		}

		return '';
	};

	handleComplete = () => {

		// The upload as already been launched
		if (this.props.uploading) return <CircularProgress/>;

		// The upload has been completed and we have a redirect => do it
		if (this.props.uploadingDone && this.props.Redirect) {
			this.props.logUser(this.props.user);
			return <Redirect target={this.props.Redirect}/>;
		}

		// we didn't launched any upload (if we did the first if of this function would be true)
		// We have some files so upload them.
		if (!this.props.uploadingDone && Object.keys(this.data.files).length > 0) {
			this.props.uploadFiles(this.data.files);
		} else if (this.props.Redirect) {// We don't have any files so just check if we need to redirect
			this.props.logUser(this.props.user);
			return <Redirect target={this.props.Redirect}/>;
		} else if (this.props.url) { // We need to redirect to page outside the website
			this.props.logUser(this.props.user);
			return <Redirect target={this.props.url}/>;
		}


		// At this point, the login process is complete, we don't have any file and any redirect instruction, well we are kind of stuck ....
		// This case should not happen, don't really know what to do except to display an error

		return <div>Something went wrong</div>;
	};

	// transform the query string to an object e.g ?foo=bar&var=value become {foo:"bar",var:"value"}
	parseQuery = (queryString) => {
		let query = {};
		if (queryString === '') return query;
		let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
		for (let i = 0; i < pairs.length; i++) {
			let pair = pairs[i].split('=');
			query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
		}
		return query;
	};

	handleBack = () => {
		this.props.initFlow();
		this.props.flow(this.parseQuery(this.props.location.search.trim())).catch((err) => {
		});
	};


	render() {
		if (this.props.loading)
			return <LoginForm><Loading size={60}/></LoginForm>;

		const errors = this.props.error ? <div>Error {this.props.error}</div> : '';

		if (this.props.complete) {
			return this.handleComplete();
		}

		if (this.props.url || this.props.Redirect) {
			return <LoginForm>
				<Grid
					container
					direction='row'
					justify='center'
					alignItems='center'
				>
					<Grid item>
						<Redirect target={this.props.Redirect ?? this.props.url}/>
					</Grid>
				</Grid>
			</LoginForm>;
		}

		const userSection = this.buildUserSection();

		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<form onSubmit={this.submitHandle}>
						{userSection}
						{errors}
						{this.buildForm()}
						<OAuth2Wrapper>
							{this.buildOauthBtns()}
						</OAuth2Wrapper>
						<Box m={3}/>
						{this.buildButtons()}


					</form>
				</Grid>
			</Grid>
		);
	}

	buildButtons = () => {
		const { t } = this.props;
		const Next = <Button
			type='submit'
			fullWidth={this.props.initial}
			variant='contained'
			color='primary'
		>
			{t('next_btn')}
		</Button>;

		if (!this.props.initial) {
			return (
				<Grid
					container
					direction='row-reverse'
					justify='flex-start'
					alignItems='center'
					spacing={2}>
					<Grid item>
						{Next}
					</Grid>
					<Grid item>
						<Button
							variant='contained'
							onClick={() => this.handleBack()}
							color='default'>
							{t('back_btn')}
						</Button>
					</Grid>
				</Grid>
			);
		} else {
			return (Next);
		}
	};

	componentDidMount() {
		this.props.flow(this.parseQuery(this.props.location.search.trim())).catch((err) => {
		});

	}
}

const mapStateToProps = (state) => {
	return {
		complete: state.flow.complete,
		email: state.flow.complete,
		fields: state.flow.fields,
		message: state.flow.message,
		req: state.flow.req,
		loading: state.flow.loading,
		error: state.flow.error,
		user: state.flow.user,
		initial: state.flow.initial,
		Redirect: state.flow.Redirect,
		url: state.flow.url,
		uploading: state.flow.uploading,
		uploadingDone: state.flow.uploadingDone,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		flow: (data) => dispatch(flow(data)),
		oauthTrigger: (trigger) => dispatch(oauthTrigger(trigger)),
		uploadFiles: (files) => dispatch(uploadFiles(files)),
		logUser: (user) => dispatch(changeUser(user)),
		initFlow: () => dispatch(initFlow()),
	};
};

Login.serverFetch = (match, store) => store.dispatch(flow({ ...Get() }));

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(Login)));


