import React                       from 'react';
import TableRow                    from '@material-ui/core/TableRow';
import Skeleton                    from '@material-ui/lab/Skeleton';
import TableCell                   from '@material-ui/core/TableCell';
import EditIcon                    from '@material-ui/icons/Edit';
import { Link }                    from 'react-router-dom';
import Chip                        from '@material-ui/core/Chip';
import { useTranslation }          from 'react-i18next';
import CheckIcon                   from '@material-ui/icons/Check';
import * as moment                 from 'moment';
import Audio                       from '../../../data/player/Audio';
import { formatDuration }          from '../../../../utils/time';
import { getMusicRecordEditRoute } from '../../../../../router/routes/music/factory/records';
import Video                       from '../../../data/player/Video';
import Button                      from "../../../inputs/Button";
import {Checkbox}                  from '@material-ui/core';

const Row = ({ record, loading, selectable = false, checked = false, setChecked = undefined, disabled = false}) => {
	const { t } = useTranslation();
	const displayRecord = (!loading && record);
	return (
		<TableRow>
			{selectable &&
			<TableCell>
				{!displayRecord && <Skeleton/>}
				{displayRecord && <Checkbox disabled={disabled} color='primary' checked={checked} onChange={e => setChecked(record, e.target.checked)}/>}
			</TableCell>
			}
			<TableCell>
				{!displayRecord && <Skeleton/>}
				{displayRecord && <span>{record.Name}</span>}
			</TableCell>
			<TableCell>
				{!displayRecord && <Skeleton/>}
				{displayRecord && <span>{record.Music_Genre ? record.Music_Genre.Genre : '-'}</span>}
			</TableCell>
			<TableCell>
				{!displayRecord && <Skeleton/>}
				{displayRecord && (record.IVK ? <span>{t('ivk_' + record.IVK)}</span> : '-')}
			</TableCell>
			<TableCell>
				{!displayRecord && <Skeleton/>}
				{(displayRecord && record.Type === 'audio') && <Audio mp3={record.Preview.mp3}/>}
				{(displayRecord && record.Type === 'video') && <Video width={300} source={record.Preview.mp4}/>}
			</TableCell>
			<TableCell>
				{!displayRecord && <Skeleton/>}
				{displayRecord && formatDuration(moment.duration(parseInt(record.Duration), 'seconds'))}
			</TableCell>
			{!selectable && <TableCell align='right'>
				{!displayRecord && <Skeleton/>}
				{(displayRecord && record.Status !== 'released') &&
				<Button
					variant='contained'
					color='primary'
					startIcon={<EditIcon/>}
					component={Link} to={getMusicRecordEditRoute(record.Music_Label_Record__)}
				>
					{t('edit_btn')}
				</Button>
				}

				{(displayRecord && record.Status === 'released') && <Chip
					variant='outlined'
					size='small'
					color='primary'
					component={Link}
					to={getMusicRecordEditRoute(record.Music_Label_Record__)}
					clickable
					label={t('record_status_released')}
					icon={<CheckIcon/>}
				/>}
			</TableCell>
			}
		</TableRow>
	);
};

export default Row;
