import React from 'react';
import {useTranslation} from "react-i18next";
import EmptyState from "../../feeback/State/Empty";
import Graphic from "../../../../resources/images/categories.svg";

const EmptyTags = () => {
    const {t} = useTranslation();
    return (
        <EmptyState
            title={t('product_empty_tags_title')}
            textI18Key='product_empty_tags_text'
            graphic={Graphic}
        />
    );
};

export default EmptyTags;