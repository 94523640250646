import React from 'react';
import MaterialTable from "@material-ui/core/Table";
import {useTranslation} from "react-i18next";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Row from './Row'
import {connect} from 'react-redux'
import Loading from "../../../feeback/loading/Loading";
import Empty from "./Empty";

const Table = ({links, onDelete, changeOrder,onVisibilityChange, artist, disabled}) => {
    const {t} = useTranslation();
    if (!artist) return <Loading/>;
    return (
        <TableContainer>
            <MaterialTable aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{width: '40px'}}/>
                        <TableCell> {t('tablecell_album_link_store_name')}</TableCell>
                        <TableCell> {t('tablecell_album_link_store_link')}</TableCell>
                        <TableCell> {t('tablecell_album_link_store_visible')}</TableCell>
                        {!disabled && <TableCell align="right" style={{ whiteSpace: 'nowrap'}}/>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        links.map((link, idx) => {
                            return (<Row disabled={disabled} changeOrder={changeOrder} count={links.length} first={idx === 0}
                                         last={idx === (links.length - 1)} key={link.Music_Label_Album_Link__}
                                         link={link} onDelete={onDelete}
                                         onVisibilityChange={onVisibilityChange}
                            />)
                        })
                    }
                    {links.length < 1 &&
                    <TableRow>
                        <TableCell colSpan={4}>
                            <Empty/>
                        </TableCell>
                    </TableRow>}
                </TableBody>

            </MaterialTable>
        </TableContainer>
    );
};


const mapStateToProps = (state) => {
    return {
        artist: state.artist.artist
    }
};

export default connect(mapStateToProps)(Table);
