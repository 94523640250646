import { COMMON_RESET }     from '../CommonReducer';
import { USER_LOGOUT }      from '../system/UserReducer';
import { EVENT_FETCH_DONE } from './EventReducer';

const initState = () => {
	return {
		loading: true,
		rtmp: null,
		srtmp: null,
		stream_key: null,
		playbackUrl: null,
		reconnect: null,
		streamStatus: null,

		starting: false,

		loadingArchive: true,
		archive: null
	};
};

export const STREAM_BROADCAST_FETCH = 'STREAM_BROADCAST_FETCH';
export const STREAM_BROADCAST_FETCH_DONE = 'STREAM_BROADCAST_FETCH_DONE';

export const STREAM_START = 'STREAM_START';
export const STREAM_START_DONE = 'STREAM_START_DONE';

export const STREAM_ARCHIVE_FETCH = 'STREAM_ARCHIVE_FETCH';
export const STREAM_ARCHIVE_FETCH_DONE = 'STREAM_ARCHIVE_FETCH_DONE';

const streamReducer = (state = initState(), action) => {
	switch (action.type) {
		case COMMON_RESET:
		case USER_LOGOUT:
			return initState();
		case STREAM_ARCHIVE_FETCH:
			return { ...state, loadingArchive: true };
		case STREAM_ARCHIVE_FETCH_DONE:
			return { ...state, loadingArchive: false, archive: action.archive ?? null };
		case  STREAM_BROADCAST_FETCH:
			return {
				...state,
				loading: true,
				stream_key: null,
				rtmp: null,
				srtmp: null,
				playbackUrl: null,
				reconnect: null,
				streamStatus: null
			};
		case  STREAM_BROADCAST_FETCH_DONE:
			if (!action.info) return { ...state, loading: false };

			return {
				...state,
				loading: false,
				stream_key: action.info.stream_key ?? null,
				rtmp: action.info.rtmp ?? null,
				srtmp: action.info.srtmp ?? null,
				playbackUrl: action.info.playback ?? null,
				reconnect: action.info.reconnect ?? null,
				status: action.status
			};
		case STREAM_START:
			return { ...state, starting: true };
		case STREAM_START_DONE:
			if (!action.event) return { ...state, starting: false };
			return { ...state, starting: false, status: action.event.Stream_Status };
		case EVENT_FETCH_DONE:
			if (!action.event) return state;
			return { ...state, status: action.event.Stream_Status };
		default:
			return state;
	}

};

export default streamReducer;
