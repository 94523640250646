import React, { Fragment, useEffect } from 'react';
import TableHead                      from '@material-ui/core/TableHead';
import MaterialTable                  from '@material-ui/core/Table';
import TableRow                       from '@material-ui/core/TableRow';
import TableCell                      from '@material-ui/core/TableCell';
import TableBody                      from '@material-ui/core/TableBody';
import TableContainer                 from '@material-ui/core/TableContainer';
import Row                            from './Row';
import { connect }                    from 'react-redux';
import TableFooter                    from '@material-ui/core/TableFooter';
import { useTranslation }             from 'react-i18next';
import Empty                          from './Empty';
import {
	fetchRecords,
	setRecordsFilters,
	setRecordsPaging
}                                     from '../../../../../store/actions/discography/RecordAction';
import Alert                          from '@material-ui/lab/Alert';
import Pagination                     from '../../../data/table/Pagination';

const Table = ({ records, fetch, loading, filters, paging, count, setRecordsPaging, setRecordsFilters, selectable = false, selected = {}, setSelected, disabledValues = {} }) => {
	const loadingData = [...Array(paging.results_per_page).keys()];
	const { t } = useTranslation();
	const displayLoading = () => loadingData.map((k) => <Row selectable={selectable} key={k} loading={true} record={null}/>);
	const displayRecords = () => records.map((u, idx) => <Row
		selectable={selectable}
		key={u.Music_Label_Record__}
		loading={false}
		record={u}
		checked={u.Music_Label_Record__ in selected || u.Music_Label_Record__ in disabledValues}
		disabled={u.Music_Label_Record__ in disabledValues}
		setChecked={(record, s) => {
			if(s)
			setSelected({...selected, [record.Music_Label_Record__]: record})
			else{
				const tmp = {...selected}
				delete tmp[record.Music_Label_Record__]
				setSelected(tmp);
			}

		}}
	/>);
	useEffect(() => {
		// Reset filters the first time
		setRecordsFilters({});
	}, [setRecordsFilters]);

	useEffect(() => {
		fetch();
	}, [fetch, filters, paging]);

	/*const {sort} = filters;

	const sortHandler = (property, dir) => {
		let p = {...filters};
		// only 1 sort
		p.sort = {};
		p.sort[property] = dir;
		setRecordsFilters(p);
	};*/

	return (
		<Fragment>
			{(!loading && records.length > 0) &&
			<Alert severity='info'>{t('record_released_edit_notice')}</Alert>
			}
			{(loading || records.length > 0) &&
			<TableContainer>
				<MaterialTable aria-label='simple table'>
					<TableRow>
						<Pagination
							setPaging={setRecordsPaging}
							paging={paging}
							loading={loading}
							count={count}
						/>
					</TableRow>
					<TableHead>
						<TableRow>
							{selectable && <TableCell padding="checkbox"/>}
							<TableCell> {t('tablecell_record_name')}</TableCell>
							<TableCell> {t('tablecell_record_genre')}</TableCell>
							<TableCell> {t('tablecell_record_type')}</TableCell>
							<TableCell> {t('tablecell_record_preview')}</TableCell>
							<TableCell> {t('tablecell_record_duration')}</TableCell>
							{!selectable &&<TableCell align='right'/>}
						</TableRow>
					</TableHead>
					<TableBody>
						{loading && displayLoading()}
						{!loading && displayRecords()}
					</TableBody>
					<TableFooter>
						<TableRow>
							<Pagination
								setPaging={setRecordsPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
					</TableFooter>

				</MaterialTable>
			</TableContainer>
			}
			{(!loading && records.length < 1) && <Empty/>}
		</Fragment>
	);

};


const mapStateToProps = (state) => {
	return {
		filters: state.record.recordsFilters,
		paging: state.record.recordsPaging,
		count: state.record.recordsPagingCount,
		loading: state.record.recordsLoading,
		records: state.record.records
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: () => dispatch(fetchRecords()),
		setRecordsPaging: (newPaging) => dispatch(setRecordsPaging(newPaging)),
		setRecordsFilters: (newFilters) => dispatch(setRecordsFilters(newFilters))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);

