import React, { Fragment, useEffect }                     from 'react';
import TableHead                                          from '@material-ui/core/TableHead';
import MaterialTable                                      from '@material-ui/core/Table';
import TableRow                                           from '@material-ui/core/TableRow';
import TableCell                                          from '@material-ui/core/TableCell';
import TableBody                                          from '@material-ui/core/TableBody';
import TableContainer                                     from '@material-ui/core/TableContainer';
import Row                                                from './Row';
import { connect }                                        from 'react-redux';
import TableFooter                                        from '@material-ui/core/TableFooter';
import { fetchOrders, setOrdersFilters, setOrdersPaging } from '../../../../store/actions/OrderAction';
import SortableCell                                       from '../../data/table/SortableCell';
import { useTranslation }                                 from 'react-i18next';
import Empty                                              from './Empty';
import Pagination                                         from '../../data/table/Pagination';


const Table = ({ orders, fetch, setOrdersFilters, setOrdersPaging, loading, filters, paging, count, disabled = false }) => {
	const loadingData = [...Array(paging.results_per_page).keys()];

	const displayLoading = () => loadingData.map((k) => <Row key={k} loading={true} order={null}/>);
	const displayOrders = () => orders.map((o, idx) => <Row key={o.Order__} loading={false} idx={idx}/>);
	const { t } = useTranslation();
	const { sort } = filters;

	useEffect(() => {
		if (!disabled) fetch();
	}, [disabled, fetch, filters, paging]);

	const sortHandler = (property, dir) => {
		let p = { ...filters };
		// only 1 sort
		p.sort = {};
		p.sort[property] = dir;
		setOrdersFilters(p);
	};


	return (
		<Fragment>
			{(loading || orders.length > 0) &&
			<TableContainer>
				<MaterialTable aria-label='simple table'>
					<TableHead>
						<TableRow>
							<Pagination
								setPaging={setOrdersPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
						<TableRow>
							<TableCell style={{ width: '40px' }}>
								<SortableCell
									sort={sort}
									onClick={sortHandler}
									field='Invoice_Number'
								              label={t('tablecell_n')}
								/>
							</TableCell>
							<TableCell>{t('tablecell_username')}</TableCell>

							<TableCell>
								<SortableCell sort={sort} onClick={sortHandler} field='Invoice_Date'
								              label={t('tablecell_invoice_date')}/>
							</TableCell>
							<TableCell>{t('tablecell_payment_status')}</TableCell>
							<TableCell>{t('tablecell_payment_method')}</TableCell>
							<TableCell>{t('tablecell_status')}</TableCell>
							<TableCell>{t('tablecell_total')}</TableCell>
							<TableCell>{t('tablecell_vat')}</TableCell>
							<TableCell/>
						</TableRow>
					</TableHead>
					<TableBody>
						{(loading || disabled) && displayLoading()}
						{(!loading && !disabled) && displayOrders()}
					</TableBody>
					<TableFooter>
						<TableRow>
							<Pagination
								setPaging={setOrdersPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
					</TableFooter>

				</MaterialTable>
			</TableContainer>
			}
			{(!loading && orders.length < 1) && <Empty/>}
		</Fragment>
	);
};


const mapStateToProps = (state) => {
	return {
		filters: state.order.ordersFilters,
		paging: state.order.ordersPaging,
		count: state.order.ordersPagingCount,
		loading: state.order.ordersLoading,
		orders: state.order.orders
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: () => dispatch(fetchOrders()),
		setOrdersFilters: (filters) => dispatch(setOrdersFilters(filters)),
		setOrdersPaging: (paging) => dispatch(setOrdersPaging(paging))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);

