import React, {useEffect, useState} from 'react';
import Grid                         from '@material-ui/core/Grid';
import AppBarTitle                  from '../../components/common/typography/AppBarTitle';
import OrdersTable                  from '../../components/common/order/table/Table';
import {useTranslation}             from 'react-i18next';
import {Title}                      from '../../components/common/typography/Title';
import {connect}                    from 'react-redux';
import {setOrdersFilters}           from '../../store/actions/OrderAction';
import SelectRealm                  from '../SelectRealm';
import Filters                      from '../../components/common/order/table/Filters';
import HasRight                     from '../../components/Route/HasRight';
import Redirect                     from '../../components/Route/Redirect';
import {getPXLDashboardRoute}       from '../../router/routes/pxl/factory/general';
import {useLocation}                from 'react-router-dom';
import queryString                  from 'query-string';

const Orders = ({setOrdersFilters, selectedRealm}) => {
	const {t} = useTranslation();
	const [enableOrdersDisplay, setEnableOrdersDisplay] = useState(false);

	// Dirty shit
	const location = useLocation();

	useEffect(() => {
		if (!selectedRealm) return;
		const f = {Realm__: selectedRealm.Realm__};
		let params = queryString.parse(location.search);
		if (params.s) f.value = params.s;
		setOrdersFilters(f);
		setEnableOrdersDisplay(true);
		// eslint-disable-next-line
	}, [setOrdersFilters, selectedRealm]);

	if (!selectedRealm) return <SelectRealm/>;

	return (
		<Grid container spacing={3}>

			<AppBarTitle title={t('orders_title')}/>

			<HasRight support deniedComponent={<Redirect target={getPXLDashboardRoute()}/>}>
				<Grid item xs={12}>
					<Title>{t('orders_title')}</Title>
				</Grid>
				<Grid item xs={12}>
					<Filters/>
				</Grid>
				<Grid item xs={12}>
					<OrdersTable disabled={!enableOrdersDisplay}/>
				</Grid>
			</HasRight>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setOrdersFilters: (filters) => dispatch(setOrdersFilters(filters)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
