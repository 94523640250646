import React, {useEffect, useState}        from 'react';
import {useTranslation}                    from 'react-i18next';
import EditCategoryDialog                  from '../../common/cms/entry/tags/EditCategoryDialog';
import DeleteDialog                        from '../../common/cms/entry/tags/DeleteDialog';
import {Autocomplete}                      from '@material-ui/lab';
import {Chip, CircularProgress, TextField} from '@material-ui/core';
import IconButton                          from '@material-ui/core/IconButton';
import {DeleteForever, Edit}               from '@material-ui/icons';
import {useClassifyDescribeAllTags}        from '../../../hooks/api/classify/useClassify';

const ClassifyTagAutocomplete = ({
	                                 classifyId,
	                                 value,
	                                 setValue,
	                                 except,
	                                 required = false,
	                                 disabled = false,
	                                 isManager = false,
	                                 isAdmin = false
                                 }) => {
	const {t} = useTranslation();
	const [fetch, loading] = useClassifyDescribeAllTags(classifyId);
	const [list, setList] = useState([]);

	const [autoCompleteData, setAutocompleteData] = useState([]);

	const [editSelected, setEditSelected] = useState(null);
	const [editDialogOpened, setEditDialogOpened] = useState(false);
	const [deleteDialogOpened, setDeleteDialogOpened] = useState(false);

	useEffect(() => {
		fetch('').then(data => {
			const exceptLength = except ? Object.keys(except) : 0;
			if (exceptLength < 1) setList(data);
			else {
				setList(data.filter(t => !(t.Classify_Tag__ in except)));
			}
		});
	}, []); // eslint-disable-line

	useEffect(() => {
		if(!except) setAutocompleteData(list);
		else setAutocompleteData(list.filter(t => !(t.Classify_Tag__ in except)));
	}, [except, list]);// eslint-disable-line

	const onUpdated = (tag) => {
		for (let i = 0; i < autoCompleteData.length; i++) {
			if (autoCompleteData[i].Classify_Tag__ === tag.Classify_Tag__) {
				autoCompleteData[i] = tag;
				break;
			}
		}
		setAutocompleteData(autoCompleteData);

		for (let i = 0; i < value.length; i++) {
			if (value[i].Classify_Tag__ === tag.Classify_Tag__) {
				value[i] = tag;
				break;
			}
		}
		setValue(value);
	};

	const onDeleted = (tag) => {
		const newList = autoCompleteData.filter((t) => t.Classify_Tag__ !== tag.Classify_Tag__);
		setAutocompleteData(newList);

		const newValue = value.filter((t) => t.Classify_Tag__ !== tag.Classify_Tag__);
		setValue(newValue);
	};

	const handleEdit = (tag) => {
		setEditSelected(tag);
		setEditDialogOpened(true);
	};

	const handleDelete = (tag) => {
		setEditSelected(tag);
		setDeleteDialogOpened(true);
	};

	return (
		<>
			<Autocomplete
				multiple
				disabled={Boolean(disabled)}
				value={value}
				onChange={(e, value) => {
					setValue(value);
				}}
				getOptionSelected={(option, value) => option.Classify_Tag__ === value.Classify_Tag__}
				getOptionLabel={option => !option || !option.Name ? '' : option.Name}
				filterSelectedOptions
				options={autoCompleteData}
				loading={loading}
				renderTags={(tags) => tags.map(tag => {
					return <Chip
						style={{marginRight: '4px'}}
						label={tag.Name}
						onDelete={() => setValue(value.filter((t) => t.Classify_Tag__ !== tag.Classify_Tag__))}
						avatar={isManager ? (
							<>
								<IconButton size="small" onClick={() => handleEdit(tag)}><Edit/></IconButton>
								{isAdmin && <IconButton size="small" onClick={() => handleDelete(tag)}><DeleteForever/></IconButton>}
							</>) : undefined}
					/>;
				})}
				renderInput={(params) => (
					<TextField
						{...params}
						required={required}
						label={t('categories')}
						variant="outlined"
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loading ? <CircularProgress color="inherit" size={20}/> : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							)
						}}
					/>
				)}
			/>
			{(editSelected && isManager) &&
			<EditCategoryDialog onUpdated={onUpdated} tag={editSelected} setOpen={setEditDialogOpened}
			                    open={editDialogOpened}/>}

			{(editSelected && isAdmin) &&
			<DeleteDialog onDeleted={onDeleted} tag={editSelected} setOpen={setDeleteDialogOpened}
			              open={deleteDialogOpened}/>}

		</>
	);
};


export default ClassifyTagAutocomplete;
