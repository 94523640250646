import React, {useState}      from 'react';
import Grid                   from '@material-ui/core/Grid';
import AppBarTitle            from '../../../../components/common/typography/AppBarTitle';
import {useTranslation}       from 'react-i18next';
import {connect}              from 'react-redux';
import SelectRealm            from '../../../SelectRealm';
import Table                  from '../../../../components/common/store/shipping/fee/table/Table';
import Toolbar                from '../../../../components/common/typography/Toolbar';
import HasCapability          from '../../../../components/Route/HasCapability';
import {getPXLDashboardRoute} from '../../../../router/routes/pxl/factory/general';
import Redirect               from '../../../../components/Route/Redirect';
import HasRight               from '../../../../components/Route/HasRight';
import StoreActivator         from '../../../../components/common/store/StoreActivator';
import AddIcon                from '@material-ui/icons/Add';
import Button                 from '../../../../components/common/inputs/Button';
import CreateDialog           from '../../../../components/common/store/shipping/fee/CreateDialog';


const List = ({catalog, selectedRealm}) => {
	const {t} = useTranslation();
	const [showCreate, setShowCreate] = useState(false);

	if (!selectedRealm || !catalog) return <SelectRealm/>;

	return (
		<Grid container spacing={3}>
			<HasRight admin deniedComponent={<Redirect target={getPXLDashboardRoute()}/>}>
				<HasCapability store deniedComponent={<Redirect target={getPXLDashboardRoute()}/>}>
					<AppBarTitle title={t('shipping_fees_title')}/>
					<StoreActivator/>
					<Grid item xs={12}>
						<Toolbar title={t('shipping_fees_title')}>
							<Button
								endIcon={<AddIcon/>}
								onClick={e => setShowCreate(true)}
								variant="contained"
								color="primary"
							>
								{t('create_btn')}
							</Button>
						</Toolbar>
					</Grid>

					<Grid item xs={12}>
						<Table/>
					</Grid>
					<CreateDialog open={showCreate} setOpen={setShowCreate}/>
				</HasCapability>
			</HasRight>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		catalog: state.store.catalog,
	};
};
export default connect(mapStateToProps)(List);
