import React, {useEffect, useState} from 'react';
import {connect}                                 from 'react-redux'
import {setShippingsFilters, setShippingsPaging} from "../../../../store/actions/store/ShippingAction";
import Grid                                      from "@material-ui/core/Grid";
import {Tab, Tabs}                               from "@material-ui/core";
import {useTranslation}                          from "react-i18next";
import HourglassEmptyIcon                        from '@material-ui/icons/HourglassEmpty';
import SendIcon                                  from '@material-ui/icons/Send';
//import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import FlightTakeoffIcon                         from '@material-ui/icons/FlightTakeoff';
import queryString                               from "query-string";
import {useHistory, useLocation}                 from "react-router-dom";
import useTheme                                  from '@material-ui/core/styles/useTheme';
import useMediaQuery                             from '@material-ui/core/useMediaQuery';

const tabIndexToFilterMap = [
    // Map tab index to status
    {Status: 'pending', sort: { 'Date' : 'ASC' }},
    {Status: 'processing',  sort: { 'Date' : 'ASC' }},
    {Status: 'sent',  sort: { 'Date' : 'DESC' }},
    {Status: 'return',  sort: { 'Date' : 'ASC' }},
];

const statusToTabIndexMap = {
    'pending': 0,
    'processing': 1,
    'sent': 2,
    'return': 3,
};

const Filters = ({setFilters, filters, loading}) => {
    const {t} = useTranslation();
    const [tabIndex, setTabIndex] = useState('Status' in filters ? statusToTabIndexMap[filters.Status] : 0);
    const location = useLocation();
    const history = useHistory();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

    useEffect(() => {
        setTabIndex('Status' in filters ? statusToTabIndexMap[filters.Status] : 0)
    }, [filters, setTabIndex]);

    const handleTabChange = (e, newValue) => {
        let params = queryString.parse(location.search);
        params.page_no = 1;
        history.push(location.pathname + '?' + queryString.stringify(params));

        setFilters({...filters, ...tabIndexToFilterMap[parseInt(newValue)]})
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Tabs
                    variant='scrollable'
                    scrollButtons={isMobile ? 'on' : 'auto'}
                    value={tabIndex}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleTabChange}
                    aria-label=""
                >
                    <Tab disabled={loading} label={t('shipping_filter_tab_pending')} icon={<HourglassEmptyIcon/>}/>
                    <Tab disabled={loading} label={t('shipping_filter_tab_processing')} icon={<FlightTakeoffIcon/>}/>
                    <Tab disabled={loading} label={t('shipping_filter_tab_sent')} icon={<SendIcon/>}/>
                    {/*<Tab label={t('shipping_filter_tab_return')} icon={<CancelScheduleSendIcon />}/>*/}
                </Tabs>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        filters: state.shipping.shippingsFilters,
        loading: state.shipping.shippingsLoading
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setFilters: (filters) => dispatch(setShippingsFilters(filters)),
        setPaging: (paging) => dispatch(setShippingsPaging(paging))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
