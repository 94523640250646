import React, {useEffect, useState}       from 'react';
import {Grid}                             from "@material-ui/core";
import {Title}                            from "../../typography/Title";
import {useTranslation}                   from "react-i18next";
import ProductInput                       from "../../inputs/ProductInput";
import {getAttribute, isAttributeUpdated} from "../../../utils/product";
import {connect}                          from 'react-redux'
import Button                             from "../../inputs/Button";
import {updateProduct}                    from "../../../../store/actions/store/ProductAction";
import {deepCopy}                         from "../../../utils/misc";
import ShippingTypeSelector               from "../../inputs/ShippingTypeSelector";
import ShippingHandlingSelector           from '../../inputs/ShippingHandlingSelector';
import TextField                          from '@material-ui/core/TextField';

const Shipping = ({productId, attributes, updating, isManager, updateProduct}) => {
    const {t} = useTranslation();

    const shippingTypeTmp = getAttribute(attributes, 'Shipping.Type') ?? [];
    const [shippingType, setShippingType] = useState(shippingTypeTmp.length > 0 ? shippingTypeTmp[0].Value : '');

    let shippingHandlingTmp = getAttribute(attributes, 'Shipping.Handling') ?? [];
    shippingHandlingTmp = shippingHandlingTmp.length > 0 ? shippingHandlingTmp[0].Value : '';
    if (shippingHandlingTmp !== '') shippingHandlingTmp = shippingHandlingTmp.split(',')
    else shippingHandlingTmp = [];

    const [shippingHandling, setShippingHandling] = useState(shippingHandlingTmp);


    const shippingVolumeTmp = getAttribute(attributes, 'Shipping.Volumetric_Weight') ?? [];
    const [shippingVolume, setShippingVolume] = useState(shippingVolumeTmp.length > 0 ? shippingVolumeTmp[0].Value : '');



    const [shippingDescription, setShippingDescription] = useState(deepCopy(getAttribute(attributes, 'Shipping.Description') ?? []));

    const [saveEnabled, setSaveEnabled] = useState(false);

    useEffect(() => {
        setShippingDescription(deepCopy(getAttribute(attributes, 'Shipping.Description') ?? []));

        const shippingTypeTmp = getAttribute(attributes, 'Shipping.Type') ?? [];
        setShippingType(shippingTypeTmp.length > 0 ? shippingTypeTmp[0].Value : '');

        const shippingVolumeTmp = getAttribute(attributes, 'Shipping.Volumetric_Weight') ?? [];
        setShippingVolume(shippingVolumeTmp.length > 0 ? shippingVolumeTmp[0].Value : '');

        let shippingHandlingTmp = getAttribute(attributes, 'Shipping.Handling') ?? [];
        shippingHandlingTmp = shippingHandlingTmp.length > 0 ? shippingHandlingTmp[0].Value : '';
        if (shippingHandlingTmp !== '') shippingHandlingTmp = shippingHandlingTmp.split(',')
        else shippingHandlingTmp = [];

        setShippingHandling(shippingHandlingTmp);


    }, [attributes, setShippingType, setShippingHandling, setShippingVolume, setShippingDescription]);

    useEffect(() => {
        const shippingTypeTmp = getAttribute(attributes, 'Shipping.Type') ?? [];
        if (shippingType.trim() !== (shippingTypeTmp.length > 0 ? shippingTypeTmp[0].Value : '')) {
            setSaveEnabled(true);
            return;
        }

        const shippingVolumeTmp = getAttribute(attributes, 'Shipping.Volumetric_Weight') ?? [];

        if (shippingVolume.trim() !== (shippingVolumeTmp.length > 0 ? shippingVolumeTmp[0].Value : '')) {
            setSaveEnabled(true);
            return;
        }


        let shippingHandlingTmp = getAttribute(attributes, 'Shipping.Handling') ?? [];
        shippingHandlingTmp = shippingHandlingTmp.length > 0 ? shippingHandlingTmp[0].Value : '';
        if(shippingHandlingTmp === '') shippingHandlingTmp = [];
        else shippingHandlingTmp = shippingHandlingTmp.split(',').sort();

        if(shippingHandlingTmp.length !== shippingHandling.length) {
            setSaveEnabled(true);
            return;
        }else {
            for(let i = 0; i<shippingHandling.length;i++) {
                if(shippingHandling[i] !== shippingHandlingTmp[i]) {
                    setSaveEnabled(true);
                    return;
                }
            }
        }

        if (isAttributeUpdated(attributes, 'Shipping.Description', shippingDescription)) {

            setSaveEnabled(true);
            return;
        }

        setSaveEnabled(false);
    }, [attributes, shippingDescription, shippingType, shippingVolume, shippingHandling, setSaveEnabled]);


    const saveHandler = () => {
        const data = [];

        const buildData = (value, key) => {
            value.forEach(v => {
                data.push({
                    Variable: key,
                    Language__: v.Language__,
                    Realm__: null,
                    Value: v.Value,
                })
            })
        };
        // Because updating a property is quite heavy (updating children etc), we need to send only the props that have changed
        const shippingTypeTmp = getAttribute(attributes, 'Shipping.Type') ?? [];
        if (shippingType.trim() !== (shippingTypeTmp.length > 0 ? shippingTypeTmp[0].Value : '')) {
            data.push({
                Variable: 'Shipping.Type',
                Language__: null,
                Realm__: null,
                Value: shippingType.trim(),
            })
        }

        const shippingVolumeTmp = getAttribute(attributes, 'Shipping.Volumetric_Weight') ?? [];
        if (shippingVolume.trim() !== (shippingVolumeTmp.length > 0 ? shippingVolumeTmp[0].Value : '')) {
            data.push({
                Variable: 'Shipping.Volumetric_Weight',
                Language__: null,
                Realm__: null,
                Value: shippingVolume.trim(),
            })
        }

        data.push({
            Variable: 'Shipping.Handling',
            Language__: null,
            Realm__: null,
            Value: shippingHandling.length > 0 ? shippingHandling.join(',') : '',
        })

        if (isAttributeUpdated(attributes, 'Shipping.Description', shippingDescription))
            buildData(shippingDescription, 'Shipping.Description');


        updateProduct(productId, data);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Title>{t('product_shipping_title')}</Title>
            </Grid>

            <Grid item xs={12} md={6}>
                <ShippingTypeSelector
                    value={shippingType}
                    setValue={setShippingType}
                    disabled={!isManager || updating}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <ShippingHandlingSelector
                    value={shippingHandling}
                    setValue={setShippingHandling}
                    disabled={!isManager || updating}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    disabled={!isManager || updating}
                    label={t('shipping_volumetric_weight_label')}
                    placeholder={t('shipping_volumetric_weight_label')}
                    helperText={t('shipping_volumetric_weight_HelperText')}
                    variant="outlined"
                    fullWidth
                    value={shippingVolume}
                    onChange={e => setShippingVolume(e.target.value)}
                    type='number'
                    InputProps={
                        {
                            step: 'any',
                            min: 0,
                            endAdornment: 'g'
                        }
                    }
                />
            </Grid>

            <Grid item xs={12} md={12}>
                <ProductInput
                    allowDelete={false}
                    productId={productId}
                    fullWidth
                    value={shippingDescription}
                    setValue={setShippingDescription}
                    multiline rows={5}
                    rowsMax={Infinity}
                    disabled={!isManager || updating}
                    label={t('product_shipping_description')}
                    helperText={t('product_shipping_description_helperText')}
                    variant='outlined'/>
            </Grid>


            {isManager &&
            <Grid item xs={12}>
                <Grid container justify='flex-end'>

                    <Button onClick={saveHandler} loading={updating} color='primary' variant='contained'
                            disabled={!saveEnabled || updating}>
                        {t('save_btn')}
                    </Button>

                </Grid>
            </Grid>
            }
        </Grid>
    )
};

const mapStateToProps = (state) => {
    return {
        isManager: state.access.manager,
        updating: false,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateProduct: (id, data) => dispatch(updateProduct(id, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Shipping);