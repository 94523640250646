import React from 'react';
import Grid from "@material-ui/core/Grid";
import Toolbar from "../../typography/Toolbar";
import {useTranslation} from "react-i18next";
import Table from "./product/table/Table";

const Product = ({deliverable}) => {
    const {t} = useTranslation();
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Toolbar title={t('deliverable_products_title')}/>
            </Grid>
            <Grid item xs={12}>
                <Table deliverable={deliverable}/>
            </Grid>
        </Grid>
    );
};

export default Product;