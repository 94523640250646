import News                                                                                 from "../../../pages/site/News";
import Pages       from "../../../pages/Pages";
import ArticleEdit from "../../../pages/article/ArticleEdit";
import {
    getSiteCommentsRoute,
    getSiteContentEditRoute,
    getSiteContentTranslateRoute,
    getSiteNewsRoute,
    getSiteNotificationCreateListRoute,
    getSiteNotificationCreateRoute, getSiteNotificationEditRoute,
    getSiteNotificationListEditRoute, getSiteNotificationListRoute,
    getSiteNotificationRoute,
    getSitePagesRoute
}                        from './factory/content';
import Comments
                         from '../../../pages/site/Comment/Comments';
import ArticleTranslate
                         from '../../../pages/article/translate/ArticleTranslate';
import NotifyCreateList  from '../../../pages/site/Notify/NotifyCreateList';
import NotifyEditList    from '../../../pages/site/Notify/NotifyEditList';
import NotifyList        from '../../../pages/site/Notify/NotifyList';
import NotifyCreate      from '../../../pages/site/Notify/NotifyCreate';
import NotifyEdit        from '../../../pages/site/Notify/NotifyEdit';
import NotifyChannelList from '../../../pages/site/Notify/NotifyChannelList';

export default [
    {
        path: getSiteNewsRoute(),
        component: News,
        exact: false,
        type: 'route',
        loginRequired: true,
    },
    {
        path: getSitePagesRoute(),
        component: Pages,
        exact: false,
        type: 'route',
        loginRequired: true,
    },
    {
        path: getSiteContentTranslateRoute(),
        component: ArticleTranslate,
        exact: false,
        type: 'route',
        loginRequired: true,
    },
    {
        path: getSiteContentEditRoute(),
        component: ArticleEdit,
        exact: false,
        type: 'route',
        loginRequired: true,
    },
    {
        path: getSiteCommentsRoute(),
        component: Comments,
        exact: false,
        type: 'route',
        loginRequired: true,
    },
    {
        path: getSiteNotificationCreateListRoute(),
        component: NotifyCreateList,
        exact: false,
        type: 'route',
        loginRequired: true,
    },
    {
        path: getSiteNotificationListRoute(),
        component: NotifyChannelList,
        exact: true,
        type: 'route',
        loginRequired: true,
    },
    {
        path: getSiteNotificationListEditRoute(),
        component: NotifyEditList,
        exact: false,
        type: 'route',
        loginRequired: true,
    },
    {
        path: getSiteNotificationCreateRoute(),
        component: NotifyCreate,
        exact: true,
        type: 'route',
        loginRequired: true,
    },
    {
        path: getSiteNotificationEditRoute(),
        component: NotifyEdit,
        exact: false,
        type: 'route',
        loginRequired: true,
    },
    {
        path: getSiteNotificationRoute(),
        component: NotifyList,
        exact: false,
        type: 'route',
        loginRequired: true,
    },
]
