import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Grid, Typography} from "@material-ui/core";
import {Title} from "../../typography/Title";
import FormControl    from "@material-ui/core/FormControl";
import InputLabel        from "@material-ui/core/InputLabel";
import Select            from "@material-ui/core/Select";
import FormHelperText    from "@material-ui/core/FormHelperText";
import Button            from "../../inputs/Button";
import {connect}         from 'react-redux'
import moment            from "moment";
import {updateEvent}     from "../../../../store/actions/planner/EventAction";
import EventDates        from "./EventDates";
import Alert             from "@material-ui/lab/Alert";
import DateTime          from '../../inputs/DateTime';
import ScheduleTags      from '../schedule/ScheduleTags';
import FormRequiredField from '../../feeback/FormRequiredField';

const Publication = ({event, updating, deleting, updateEvent, isManager, isAdmin}) => {
    const visibleLabel = React.useRef(null);
    const [visibleLabelWidth, setVisibleLabelWidth] = useState(0);
    const [saveEnabled, setSaveEnabled] = useState(false);

    const [visible, setVisible] = useState();
    const [date, setDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [freeCancelDate, setFreeCancelDate] = useState(null);
    const [published, setPublished] = useState(null);

    const {t} = useTranslation();

    useEffect(() => {
        setVisibleLabelWidth(visibleLabel.current.offsetWidth);
    }, []);

    useEffect(() => {
        if (!event) return;
        setVisible(event.Visible);
        setPublished(moment(parseInt(event.Schedule.Published.unixms)));
        setDate(moment(parseInt(event.Event_Date.unixms)));
        setStartDate(moment(parseInt(event.Reservation_Start.unixms)));
        setEndDate(moment(parseInt(event.Reservation_End.unixms)));
        setFreeCancelDate(event.Free_Cancel_End ? moment(parseInt(event.Free_Cancel_End.unixms)) : null);

    }, [event, setVisible, setDate, setStartDate, setEndDate, setFreeCancelDate, setPublished]);

    useEffect(() => {
        if (visible !== event.Visible ||
            date.unix() !== event.Event_Date.unix ||
            startDate.unix() !== event.Reservation_Start.unix ||
            endDate.unix() !== event.Reservation_End.unix ||
            published.unix() !== event.Schedule.Published.unix
        ) {
            setSaveEnabled(true);
            return;
        }

        if ((!event.Free_Cancel_End && freeCancelDate) ||
            (event.Free_Cancel_End && !freeCancelDate) ||
            (freeCancelDate && (freeCancelDate.unix() !== event.Free_Cancel_End.unix))
        ) {
            setSaveEnabled(true);
            return;
        }

        setSaveEnabled(false);

    }, [event, visible, date, startDate, endDate, published, freeCancelDate, setSaveEnabled]);

    const handleSave = () => {
        const data = {
            Published: '@' + published.unix(),
            Event_Date: '@' + date.unix(),
            Reservation_Start: '@' + startDate.unix(),
            Reservation_End: '@' + endDate.unix(),
            Free_Cancel_End: !freeCancelDate ? null : '@' + freeCancelDate.unix(),
            Visible: visible
        };

        updateEvent(event.Planner_Event__, data)
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Title>{t('publication_title')}</Title>
            </Grid>

            <FormRequiredField/>

            <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary" component="p">
                    {t('publication_event_desc')}
                </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
                <FormControl variant='outlined' fullWidth disabled={updating || !isManager || event.Seats.length < 1}>
                    <InputLabel ref={visibleLabel} htmlFor='visible-select'>{t('event_visible')}</InputLabel>
                    <Select
                        native
                        value={visible}
                        onChange={(e) => setVisible(e.target.value)}
                        labelWidth={visibleLabelWidth}
                        inputProps={{
                            id: 'visible-select',
                        }}
                    >
                        <option value='Y'>{t('visible_y')}</option>
                        <option value='N'>{t('visible_n')}</option>
                    </Select>
                    <FormHelperText>{t('event_visible_helperText')}</FormHelperText>
                </FormControl>
            </Grid>

            {event.Seats.length < 1 &&
            <Grid item xs={12} md={6}>
                <Alert severity='info'>{t('event_visible_no_seat')}</Alert>
            </Grid>
            }

	        <Grid item xs={12} md={6}>
		        <DateTime
			        label={t('event_published_lbl')}
			        inputVariant="outlined"
			        value={published}
			        disabled={updating || !isManager}
			        onChange={setPublished}
			        required
			        helperText={t('event_published_helperText')}
			        fullWidth
		        />
	        </Grid>

            <Grid item xs={12}>
                <EventDates disabled={updating || !isManager}
                            date={date}
                            setDate={setDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            freeCancelDate={freeCancelDate}
                            setFreeCancelDate={setFreeCancelDate}
                />
            </Grid>

            {isManager &&
            <Grid item xs={12}>
                <Grid container justify='flex-end'>

                    <Button color='primary' variant='contained' loading={updating} disabled={!saveEnabled || updating}
                            onClick={handleSave}>
                        {t('save_btn')}
                    </Button>

                </Grid>
            </Grid>
            }

            <Grid item xs={12}>
                <ScheduleTags isEvent={true} schedule={event.Schedule}/>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        event: state.event.event,
        updating: state.event.updating,
        deleting: state.event.deleting,
        isManager: state.access.manager,
        isAdmin: state.access.admin,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateEvent: (id, data) => dispatch(updateEvent(id, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Publication);
