import React, { useEffect, useState }   from 'react';
import Dialog                           from '@material-ui/core/Dialog';
import DialogTitle                      from '@material-ui/core/DialogTitle';
import DialogContent                    from '@material-ui/core/DialogContent';
import DialogContentText                from '@material-ui/core/DialogContentText';
import DialogActions                                           from '@material-ui/core/DialogActions';
import Grid                                                    from '@material-ui/core/Grid';
import Language                                                from '../../inputs/Language';
import { getLocale }                                           from '@karpeleslab/klbfw';
import TextField                                               from '@material-ui/core/TextField';
import { connect }                                             from 'react-redux';
import { createEntry, createEntryData }                        from '../../../../store/actions/CmsAction';
import { useHistory }                                          from 'react-router-dom';
import { useTranslation }                                      from 'react-i18next';
import {getSiteContentEditRoute, getSiteContentTranslateRoute} from '../../../../router/routes/site/factory/content';
import Button                                                  from '../../inputs/Button';
import FormRequiredField                                       from '../../feeback/FormRequiredField';

const CreateDialog = ({ open, setOpen, creating, cmsId, entryId = null, entryDataId = null, onCreated = undefined, createEntryData, createEntry, isManager}) => {
	const { t } = useTranslation();
	const [lang, setLang] = useState(getLocale());
	const [title, setTitle] = useState('');
	const [saveEnable, setSaveEnabled] = useState(false);

	let history = useHistory();

	useEffect(() => {
		if (entryId)
			setSaveEnabled(!!lang);
		else
			setSaveEnabled(!!title.trim() && !!lang);
	}, [setSaveEnabled, title, lang, entryId]);

	const handleClose = () => {
		setOpen(false);
	};

	const handleCreateEntry = () => {
		createEntry(cmsId, title, lang)
			.then((entry) => {
				const entryData = entry.Data[Object.keys(entry.Data)[0]];
				if(onCreated) onCreated(entry)
				else history.push(getSiteContentEditRoute(entryData.Content_Cms_Entry_Data__));
			}).then(handleClose);
	};

	const handleCreateEntryData = () => {
		createEntryData(entryId, lang)
			.then((entryData) => {
				if(onCreated) onCreated(entryData)
				else history.push(getSiteContentEditRoute(entryData.Content_Cms_Entry_Data__));
			}).then(handleClose);
	};

	const handleSubmit = (e) => {
		e.stopPropagation();
		e.preventDefault();

		if (!entryId)
			handleCreateEntry();
		else
			handleCreateEntryData();
	};

	const handleOrder = () => {
		history.push(getSiteContentTranslateRoute(entryDataId, lang));
	}

	return (
		<Dialog
			open={open}
			aria-labelledby={t('create_article')}
			aria-describedby={t('create_article')}
		>
			<form onSubmit={handleSubmit}>
				<DialogTitle id='create-article-title'>{t('article_creation')}</DialogTitle>
				<DialogContent>
					<DialogContentText id='create-article-description'>
					</DialogContentText>
					<Grid container spacing={2}>
						<FormRequiredField/>
						{!entryId && <Grid item xs={12} md={6}>
							<TextField label={t('cms_entry_title')} fullWidth variant='outlined' value={title}
							           required
							           disabled={creating}
							           onChange={e => setTitle(e.target.value)}
							           helperText={t('title_article_selected_lang')}/>
						</Grid>
						}
						<Grid item xs={12} md={entryId ? 12 : 6}>
							<Language
								allowEmpty
								value={lang}
								onChange={e => setLang(e.target.value)}
								inputProps={{
									variant: 'outlined',
									required: true,
									fullWidth:true,
								}}
								helperText={t('lang_article_written_in')}
								fromSettings
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button color='default' onClick={handleClose} disabled={creating}>
						{t('close_btn')}
					</Button>
					{(entryDataId && isManager) &&
						<Button
							color='primary'
							variant='outlined'
							loading={creating}
							disabled={creating || !saveEnable}
							onClick={() => {handleOrder()}}
						>
							{t('order_translation_btn')}
						</Button>
					}
					<Button color='primary' variant='contained' loading={creating} disabled={creating || !saveEnable} type='submit'>
						{t('create_btn')}
					</Button>
				</DialogActions>
			</form>
		</Dialog>

	);
};

const mapStateToProps = (state) => {
	return {
		creating: state.cms.creatingEntryData,
		isManager: state.access.manager
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		createEntry: (cmsId, title, language) => dispatch(createEntry(cmsId, title, language)),
		createEntryData: (entryId, language) => dispatch(createEntryData(entryId, language)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDialog);
