import React                                      from 'react';
import {connect}                                  from 'react-redux';
import {Trans, useTranslation}                    from 'react-i18next';
import {Divider, TextField, Toolbar}              from '@material-ui/core';
import {Title}                                    from '../../components/common/typography/Title';
import SelectRealm                                from '../SelectRealm';
import Grid                                       from '@material-ui/core/Grid';
import AppBarTitle                                from '../../components/common/typography/AppBarTitle';
import Alert                                      from '@material-ui/lab/Alert';
import makeStyles                                 from '@material-ui/core/styles/makeStyles';
import HasRight                                   from '../../components/Route/HasRight';
import Redirect                                   from '../../components/Route/Redirect';
import {getPXLContactRoute, getPXLDashboardRoute} from '../../router/routes/pxl/factory/general';
import Link                                       from '../../components/common/typography/Link';
import TemplateSetup                              from '../../components/common/artist/TemplateSetup';
import SupportSettings                            from '../../components/common/support/Settings';

// dirty
const useStyles = makeStyles(() => {
	return {
		fixAlert: {
			'& > .MuiAlert-message': {
				display: 'block',
			}
		}
	};
});

const Settings = ({selectedRealm, url, support}) => {
	const {t} = useTranslation();
	const classes = useStyles();

	if (!selectedRealm || !url) return <SelectRealm/>;

	return (
		<Grid container spacing={3}>
			<HasRight manager deniedComponent={<Redirect target={getPXLDashboardRoute()}/>}>
				<AppBarTitle title={t('settings_title')}/>
				<Grid item xs={12}>
					<Toolbar>
						<Title>{t('settings_title')}</Title>
					</Toolbar>

				</Grid>
				<Grid item xs={12}>
					<Alert severity="info" className={classes.fixAlert}>
						<Trans i18nKey="settings_alert">
							sample<br/>
							<strong>sample</strong>
							<Link to={getPXLContactRoute()}>sample</Link>
						</Trans>
					</Alert>
				</Grid>
				<Grid item xs={12} md={6}>
					<TextField
						value={url.Domain}
						variant="outlined"
						fullWidth
						label={t('settings_site_lbl')}
						helperText={t('settings_site_helperText')}
						readOnly
					/>
				</Grid>
				<Grid item xs={12}>
					<Divider/>
				</Grid>
				{support && <HasRight manager hideLoading>
					<Grid item xs={12}>
						<SupportSettings support={support}/>
					</Grid>
					<Grid item xs={12}>
						<Divider/>
					</Grid>
				</HasRight>}

				<HasRight admin hideLoading>
					<TemplateSetup/>
				</HasRight>
			</HasRight>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		url: state.registry.url,
		support: state.support.support,
	};
};

export default connect(mapStateToProps, null)(Settings);
