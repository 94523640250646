import {USER_LOGOUT} from "./system/UserReducer";

const initState = () => {
    return {
        drawerOpened: true,
        title: '',
        ready: false, // Used to know if all alias has been loaded
        menu: null,
    }
};

export const COMMON_RESET = 'COMMON_RESET';
export const COMMON_SET_TITLE = 'COMMON_SET_TITLE';

export const COMMON_READY = 'COMMON_READY';

export const COMMON_SET_MENU = 'COMMON_SET_MENU'

export const MENU_WEBSITE = 'MENU_WEBSITE';
export const MENU_ARTIST = 'MENU_ARTIST';
export const MENU_MUSIC = 'MENU_MUSIC';
export const MENU_PLANNER = 'MENU_PLANNER';
export const MENU_FANCLUB = 'MENU_FANCLUB';
export const MENU_STORE = 'MENU_STORE';
export const MENU_SUPPORT = 'MENU_SUPPORT';

export const MENU_MOBILE = 'MENU_MOBILE';

const commonReducer = (state = initState(), action) => {
    switch (action.type) {
        case USER_LOGOUT:
            return initState();
        case COMMON_SET_TITLE:
            return {...state, title: action.title};
        case COMMON_RESET:
            return {...state, ready: false};
        case COMMON_SET_MENU:
            return {...state, menu:action.menu}
        case COMMON_READY:
            console.log('READY')
            return {...state, ready: true};
        default:
            return state
    }
};

export default commonReducer