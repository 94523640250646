import React                       from 'react';
import {connect}                   from 'react-redux';
import PlanTable                   from '../../../components/common/membership/plans/table/Table';
import SelectRealm                 from '../../SelectRealm';
import AppBarTitle                 from '../../../components/common/typography/AppBarTitle';
import {useTranslation}            from 'react-i18next';
import Grid                        from '@material-ui/core/Grid';
import AddIcon                     from '@material-ui/icons/Add';
import {Link}                      from 'react-router-dom';
import HasRight                    from '../../../components/Route/HasRight';
import Toolbar                     from '../../../components/common/typography/Toolbar';
import {getFanclubPlanCreateRoute} from '../../../router/routes/fanclub/factory/plan';
import {getPXLDashboardRoute}      from '../../../router/routes/pxl/factory/general';
import HasCapability               from '../../../components/Route/HasCapability';
import Redirect                    from '../../../components/Route/Redirect';
import FanclubActivator            from '../../../components/common/fanclub/FanclubActivator';
import Loading                     from '../../../components/common/feeback/loading/Loading';
import Button                      from '../../../components/common/inputs/Button';

const Plans = ({selectedMembership, selectedRealm}) => {
	const {t} = useTranslation();

	if (!selectedRealm)
		return <SelectRealm/>;
	if (!selectedMembership)
		return <Loading/>;

	return (
		<Grid container spacing={3}>
			<HasCapability fanclub deniedComponent={<Redirect target={getPXLDashboardRoute()}/>}>
				<AppBarTitle title={t('fanclub_plans_title')}/>

				<FanclubActivator/>

				<Grid item xs={12}>
					<Toolbar title={t('fanclub_plans_title')}>
						<HasRight admin hideLoading>
							<Button endIcon={<AddIcon/>} variant="contained" color="primary" component={Link}
							        to={getFanclubPlanCreateRoute()}>
								{t('create_btn')}
							</Button>
						</HasRight>
					</Toolbar>
				</Grid>

				<Grid item xs={12}>
					<PlanTable selectedMembership={selectedMembership}/>
				</Grid>
			</HasCapability>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedMembership: state.membership.selectedFanclub,
		selectedRealm: state.realm.selected,
	};
};


export default connect(mapStateToProps, null)(Plans);
