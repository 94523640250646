import ReactBackdrop from "@material-ui/core/Backdrop";
import withStyles from "@material-ui/core/styles/withStyles";

export const Overlay = withStyles(theme => {
    return {
        root: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }
})(ReactBackdrop);