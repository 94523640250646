import React, { useEffect, useState } from 'react';
import { connect }                    from 'react-redux';
import { Trans, useTranslation }      from 'react-i18next';
import { Toolbar }                    from '@material-ui/core';
import { Title }                      from '../typography/Title';
import Grid                           from '@material-ui/core/Grid';
import Alert                          from '@material-ui/lab/Alert';
import makeStyles                     from '@material-ui/core/styles/makeStyles';
import Button                         from '../inputs/Button';
import { getPXLContactRoute }         from '../../../router/routes/pxl/factory/general';
import Link                           from '../typography/Link';
import DateTime                       from '../inputs/DateTime';
import { financialReportFetch }       from '../../../store/actions/StatsAction';
import { downloadUrl }                from '../../utils/dowloadUrl';
import HasCapability                  from '../../Route/HasCapability';

// dirty
const useStyles = makeStyles(() => {
	return {
		fixAlert: {
			'& > .MuiAlert-message': {
				display: 'block',
			}
		}
	};
});

const FinancialReport = ({
	                         selectedRealm,
	                         financialReportFetch,
	                         generating,
	                         planner,
	                         store,
	                         fanclub,
	                         ready
                         }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const [start, setStart] = useState(null);
	const [end, setEnd] = useState(null);
	const [saveEnabled, setSaveEnabled] = useState();

	useEffect(() => {
		setSaveEnabled(!!start && !!end && end > start);
	}, [start, end, setSaveEnabled]);

	const createExportHandler = catalogId => () => {
		financialReportFetch(catalogId, '@' + start.unix(), '@' + end.unix())
			.then(d => {
				downloadUrl(d.Url_Download, d.Name);
			});
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Toolbar>
					<Title>{t('menu_site_financial_report')}</Title>
				</Toolbar>

			</Grid>
			<Grid item xs={12}>
				<Alert severity='info' className={classes.fixAlert}>
					<Trans i18nKey='financial_report_info'>
						sample<br/>
						<strong>sample</strong>
						<Link to={getPXLContactRoute()}>sample</Link>
					</Trans>
				</Alert>
			</Grid>
			<Grid item xs={6}>
				<DateTime
					label={t('financial_report_start')}
					inputVariant='outlined'
					value={start}
					disabled={generating}
					onChange={setStart}
					required
					fullWidth
					//disablePast
				/>
			</Grid>
			<Grid item xs={6}>
				<DateTime
					label={t('financial_report_end')}
					inputVariant='outlined'
					value={end}
					disabled={generating}
					onChange={setEnd}
					required
					fullWidth
					//disablePast
				/>
			</Grid>
			<HasCapability store hideLoading>
				{store && <Grid item>
					<Button
						variant='contained'
						color='primary'
						loading={generating}
						disabled={!saveEnabled || generating}
						onClick={createExportHandler(store?.Catalog__)}
					>
						{t('financial_report_store')}
					</Button>
				</Grid>}
			</HasCapability>
			<HasCapability fanclub hideLoading>
				{fanclub && <Grid item>
					<Button
						variant='contained'
						color='primary'
						loading={generating}
						disabled={!saveEnabled || generating}
						onClick={createExportHandler(fanclub?.Catalog__)}
					>
						{t('financial_report_fanclub')}
					</Button>
				</Grid>
				}
			</HasCapability>
			<HasCapability event hideLoading>
				{planner?.Catalog__ && <Grid item>
					<Button
						variant='contained'
						color='primary'
						loading={generating}
						disabled={!saveEnabled || generating}
						onClick={createExportHandler(planner?.Catalog__)}
					>
						{t('financial_report_event')}
					</Button>
				</Grid>
				}
			</HasCapability>
			<Grid item>
				<Button
					variant='contained'
					color='primary'
					loading={generating}
					disabled={!saveEnabled || generating}
					onClick={createExportHandler(selectedRealm.Realm__)}
				>
					{t('financial_report_all')}
				</Button>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		generating: state.stats.financialReportLoading,
		ready: state.common.ready,

		planner: state.schedule.planner,
		store: state.store.catalog,
		fanclub: state.membership.selectedFanclub,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		financialReportFetch: (catalogId, start, end) => dispatch(financialReportFetch(catalogId, start, end))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FinancialReport);
