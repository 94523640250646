import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {connect} from 'react-redux'
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from 'react-i18next';
import {useHistory} from "react-router-dom";
import {deleteRecord} from "../../../../store/actions/discography/RecordAction";
import Button from "../../inputs/Button";
import Alert from "@material-ui/lab/Alert";
import {getMusicRecordsRoute} from "../../../../router/routes/music/factory/records";

const DeleteDialog = ({open, setOpen, deleting, record, deleteRecord}) => {
    const {t} = useTranslation();
    const history = useHistory();

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        deleteRecord(record.Music_Label_Record__)
            .then(() => {
                handleClose();
                history.push(getMusicRecordsRoute())
            })
    };

    return (
        <Dialog
            open={open}
            aria-labelledby={t('delete_record')}
            aria-describedby={t('delete_record')}
            fullWidth
        >
            <DialogTitle id="record-delete-title">{t('confirm_delete_record_title')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="record-delete-description">
                    {t('confirm_delete_record_desc')}
                    <br/>
                    <Typography variant="body1" color="textPrimary" component="span">{t('are_you_sure')}</Typography>
                </DialogContentText>

                <Alert severity="info">{t('record_delete_alert')}</Alert>
            </DialogContent>

            <DialogActions>
                <Button variant='contained' color="primary" autoFocus onClick={handleClose} disabled={deleting}>
                    {t('back_btn')}
                </Button>
                <Button color="secondary" disabled={deleting} loading={deleting} endIcon={<DeleteForeverIcon/>}
                        onClick={handleDelete}>
                    {t('delete_btn')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {
        deleting: state.record.deleting
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        deleteRecord: (recordId) => dispatch(deleteRecord(recordId))
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(DeleteDialog);