import React                                       from 'react';
import {connect}                                   from 'react-redux';
import {useTranslation}                            from 'react-i18next';
import {List}                                      from '@material-ui/core';
import ListItemNavLink                             from '../ListItemNavLink';
import {getSiteEventsRoute, getSiteSchedulesRoute} from '../../../../router/routes/site/factory/planner';
import EventIcon                                   from '@material-ui/icons/Event';
import HasCapability                               from '../../../Route/HasCapability';
import EventSeatIcon                               from '@material-ui/icons/EventSeat';

const MenuPlanner = ({
	                     planner,
	                     ready,

                     }) => {
	const {t} = useTranslation();


	return (
		<List>
			<div>
				<ListItemNavLink
					tooltip={t('menu_schedule')}
					disabled={planner === null || !ready}
					path={getSiteSchedulesRoute()}
					icon={<EventIcon size={24}/>}
					primary={t('menu_schedule')}
				/>

				<HasCapability event hideLoading>
					<ListItemNavLink
						tooltip={t('menu_event')}
						disabled={planner === null || !ready}
						path={getSiteEventsRoute()}
						icon={<EventSeatIcon size={24}/>}
						primary={t('menu_event')}
					/>
				</HasCapability>


			</div>
		</List>
	);
};

const mapStateToProps = (state) => {
	return {
		planner: state.schedule.planner,
		ready: state.common.ready,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
	};
};


export default connect(mapStateToProps, mapDispatchToProps)(MenuPlanner);

