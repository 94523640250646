import React            from 'react';
import {useParams}      from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import AppBarTitle      from '../../components/common/typography/AppBarTitle';
import Grid             from '@material-ui/core/Grid';
import Toolbar          from '../../components/common/typography/Toolbar';
import OrderWithPayment from '../../components/common/myorder/order/OrderWithPayment';

const Order = () => {
	const {t} = useTranslation();
	const {orderId} = useParams();

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('order')}/>

			<Grid item xs={12}>
				<Toolbar title={t('order')}/>
			</Grid>

			<Grid item xs={12}>
				<OrderWithPayment orderId={orderId}/>
			</Grid>

		</Grid>
	);
};

export default Order;
