import React, {useEffect, useState} from 'react';
import Redirect                     from '../../components/Route/Redirect';
import {getPXLDashboardRoute}       from '../../router/routes/pxl/factory/general';
import AppBarTitle                  from '../../components/common/typography/AppBarTitle';
import Grid                         from '@material-ui/core/Grid';
import Toolbar                      from '../../components/common/typography/Toolbar';
import {Trans, useTranslation}      from 'react-i18next';
import {connect}                    from 'react-redux';
import SelectRealm                  from '../SelectRealm';
import AccessCheck                  from '../../components/Route/AccessCheck';
import RichAlert                    from '../../components/common/feeback/RichAlert';
import {fetchUserLocation}          from '../../store/actions/system/UserLocationAction';
import Loading                      from '../../components/common/feeback/loading/Loading';
import AddressForm                  from '../../components/common/store/AddressForm';

const Address = ({selectedRealm, settings, address, loading, fetchUserLocation}) => {
	const {t} = useTranslation();
	const [loadedOnce, setLoadedOnce] = useState(false);

	useEffect(() => {
		if (settings && settings.Data && settings.Data.location)
			fetchUserLocation(settings.Data.location.User_Location__).then(() => setLoadedOnce(true));
		else setLoadedOnce(true);
	}, [settings, fetchUserLocation, setLoadedOnce]);

	if (!selectedRealm || !settings || !('Data' in settings)) return <SelectRealm/>;

	return (
		<Grid container spacing={3}>
			<AccessCheck capabilities={{store: true}} rights={{owner: true}}
			             deniedComponent={<Redirect target={getPXLDashboardRoute()}/>}>
				<AppBarTitle title={t('menu_site_address')}/>

				<Grid item xs={12}>
					<Toolbar title={t('menu_site_address')}/>
				</Grid>

			</AccessCheck>

			<Grid item xs={12}>
				<RichAlert severity="info">
					<Trans i18nKey="your_address_desc">
						sample <br/>
						<strong>sample</strong>
					</Trans>
				</RichAlert>
			</Grid>

			{(loading && !loadedOnce) && <Loading/>}
			{(!loading || loadedOnce) &&
			<Grid item xs={12}><AddressForm settingId={settings.Setting__} address={address}/></Grid>}
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		settings: state.template.templateSettings,
		address: state.userLocation.location,
		loading: state.userLocation.loadingLocation,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchUserLocation: (id) => dispatch(fetchUserLocation(id))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Address);
