import {
	DELIVERABLE_CREATE,
	DELIVERABLE_CREATE_DONE,
	DELIVERABLE_DELETE,
	DELIVERABLE_DELETE_DONE,
	DELIVERABLE_FETCH,
	DELIVERABLE_FETCH_DONE,
	DELIVERABLE_FILE_DELETE,
	DELIVERABLE_FILE_DELETE_DONE,
	DELIVERABLE_LIST_FETCH,
	DELIVERABLE_LIST_FETCH_DONE,
	DELIVERABLE_PRODUCTS_LIST_FETCH,
	DELIVERABLE_PRODUCTS_LIST_FETCH_DONE,
	DELIVERABLE_PRODUCTS_SET_FILTERS,
	DELIVERABLE_PRODUCTS_SET_PAGING,
	DELIVERABLE_SET_FILTERS,
	DELIVERABLE_SET_PAGING, DELIVERABLE_STATS_DONE,
	DELIVERABLE_UPDATE,
	DELIVERABLE_UPDATE_DONE,
	DELIVERABLE_UPLOAD,
	DELIVERABLE_UPLOAD_DONE,
	DELIVERABLE_UPLOAD_RUNNING
} from '../../reducers/store/DeliverableReducer';
import { rest, upload }                      from '@karpeleslab/klbfw';
import { handleError }                       from '../../../components/utils/apiErrorHandler';
import { error, success }                    from '../system/ToastAction';
import { PRODUCT_LIST_MAIN_IMAGE_VARIATION } from '../../reducers/store/ProductReducer';

export const setDeliverablesPaging = (newPaging) => {
	return (dispatch, getState) => {
		dispatch({
			type: DELIVERABLE_SET_PAGING,
			paging: { ...getState().deliverable.deliverablesPaging, ...newPaging }
		});
	};
};

export const setDeliverablesFilters = (newFilters) => {
	return (dispatch, getState) => {
		dispatch({ type: DELIVERABLE_SET_FILTERS, filters: newFilters });
	};
};

export const fetchDeliverables = (catalogId) => {
	return (dispatch, getState) => {
		dispatch({ type: DELIVERABLE_LIST_FETCH });

		const params = {
			...getState().deliverable.deliverablesFilters,
			...getState().deliverable.deliverablesPaging,
		};

		return rest('Catalog/' + catalogId + '/Deliverable', 'GET', params)
			.then(data => {

				dispatch({ type: DELIVERABLE_LIST_FETCH_DONE, deliverables: data.data, paging: data.paging });
				return data.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const createDeliverable = (catalogId, name, style, file = null, stock = null, feeId = null) => {
	return (dispatch, getState) => {
		dispatch({ type: DELIVERABLE_CREATE });
		if (style !== 'files') {
			return rest('Catalog/' + catalogId + '/Deliverable', 'POST', { Name: name, Style: style, Stock:stock, Catalog_Deliverable_Fee__ : feeId })
				.then(d => {
					success('deliverable_create_success');
					dispatch({ type: DELIVERABLE_CREATE_DONE });
					return d.data;
				})
				.catch((err) => {
					handleError(getState, dispatch, err);
				});
		}

		return new Promise((resolve, reject) => {
			upload.onprogress = (item) => {
				item.failed.forEach(i => {
					upload.deleteItem(i.up_id);
					reject(i.failure);
				});
			};

			upload.append('Catalog/' + catalogId + '/Deliverable:uploadCreate', file, { Name: name, Catalog_Deliverable_Fee__ : feeId })
				.then((data) => {
					resolve(data.final);
				}).catch((err) => {
				resolve(err);
			});

			upload.run();
		})
			.then(d => {
				success('deliverable_create_success');
				dispatch({ type: DELIVERABLE_CREATE_DONE });
				return d;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};


export const fetchDeliverable = (deliverableId) => {
	return (dispatch, getState) => {
		dispatch({ type: DELIVERABLE_FETCH });
		return rest('Catalog/Deliverable/' + deliverableId)
			.then(d => {
				dispatch({ type: DELIVERABLE_FETCH_DONE, deliverable: d.data });
				return d.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};


export const deleteDeliverable = (deliverableId) => {
	return (dispatch, getState) => {
		dispatch({ type: DELIVERABLE_DELETE });
		return rest('Catalog/Deliverable/' + deliverableId, 'DELETE')
			.then(d => {
				success('deliverable_delete_success');
				dispatch({ type: DELIVERABLE_DELETE_DONE });
				return d.data;
			})
			.catch((err) => {
				if (err.token === 'error_object_has_references') {
					error('deliverable_delete_error_reference', true, 4000); // it's a long message, increase duration
				} else handleError(getState, dispatch, err);
				dispatch({ type: DELIVERABLE_DELETE_DONE });
			});
	};
};


export const updateDeliverable = (deliverableId, data) => {
	return (dispatch, getState) => {
		dispatch({ type: DELIVERABLE_UPDATE });
		return rest('Catalog/Deliverable/' + deliverableId, 'PATCH', data)
			.then(d => {
				success('deliverable_update_success');
				dispatch({ type: DELIVERABLE_UPDATE_DONE, deliverable: d.data });
				return d.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};


export const deliverableUploadFile = (deliverableId, file) => {
	return (dispatch, getState) => {
		dispatch({ type: DELIVERABLE_UPLOAD });

		return new Promise((resolve, reject) => {
			upload.onprogress = (d) => {
				let blockTotal = 0;
				let progressTotal = 0;
				d.running.forEach((running) => {
					if (running.status !== 'pending' && running.status !== 'complete') {
						progressTotal += running.done;
						blockTotal += running.blocks;
					}
				});

				d.failed.forEach(i => {
					upload.deleteItem(i.up_id);
					reject(i.failure);
				});

				const ratio = blockTotal > 0 ? progressTotal / blockTotal : 0;

				dispatch({ type: DELIVERABLE_UPLOAD_RUNNING, ratio });
			};

			upload.append('Catalog/Deliverable/' + deliverableId + ':upload', file)
				.then((data) => {
					resolve(data.final);
				}).catch((err) => {
				resolve(err);
			});

			upload.run();
		})
			.then(d => {
				success('deliverable_upload_success');
				dispatch({ type: DELIVERABLE_UPLOAD_DONE, deliverable: d });
				return d;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const deleteDeliverableFile = (deliverableId, deliverableFileId) => {
	return (dispatch, getState) => {
		dispatch({ type: DELIVERABLE_FILE_DELETE });
		return rest('Catalog/Deliverable/' + deliverableId + ':deleteFile', 'POST', { file_id: deliverableFileId })
			.then(d => {
				success('deliverable_file_delete_success');
				dispatch({ type: DELIVERABLE_FILE_DELETE_DONE, deliverable: d.data });
				return d.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};


export const setDeliverableProductsPaging = (newPaging) => {
	return (dispatch, getState) => {
		dispatch({
			type: DELIVERABLE_PRODUCTS_SET_PAGING,
			paging: { ...getState().deliverable.deliverableProductsPaging, ...newPaging }
		});
	};
};

export const setDeliverableProductsFilters = (newFilters) => {
	return (dispatch, getState) => {
		dispatch({ type: DELIVERABLE_PRODUCTS_SET_FILTERS, filters: newFilters });
	};
};

export const fetchDeliverableProducts = (deliverableId) => {
	return (dispatch, getState) => {
		dispatch({ type: DELIVERABLE_PRODUCTS_LIST_FETCH });

		const params = {
			...getState().deliverable.deliverableProductsFilters,
			...getState().deliverable.deliverableProductsPaging,
			image_variation: PRODUCT_LIST_MAIN_IMAGE_VARIATION
		};

		return rest('Catalog/Deliverable/' + deliverableId + '/Product', 'GET', params)
			.then(data => {

				dispatch({ type: DELIVERABLE_PRODUCTS_LIST_FETCH_DONE, products: data.data, paging: data.paging });
				return data.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const deliverableStats = (catalogId, silent = false) => {
	return (dispatch, getState) => {
		return rest('Catalog/' + catalogId + '/Deliverable:stats')
			.then(d => {
				dispatch({ type: DELIVERABLE_STATS_DONE, outOfStock: d.data.OutOfStock });
				return d.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err, silent);
			});
	};
};
