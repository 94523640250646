import React, {useEffect, useState} from 'react';
import TableRow                     from '@material-ui/core/TableRow';
import {fetchResponses}             from '../../../../../../store/actions/SocialAction';
import {connect}                    from 'react-redux';
import {TableCell}                  from '@material-ui/core';
import {Skeleton}                   from '@material-ui/lab';
import Row                          from './Row';

const ResponseComment = ({comment, fetchResponses}) => {
	const [loading, setLoading] = useState(true);
	const [responses, setResponses] = useState([]);

	useEffect(() => {
		fetchResponses(comment.Comments.Key)
			.then((d) => {
				setResponses(d);
				setLoading(false);
			});
	}, [setResponses, fetchResponses, comment]);

	if (loading) {
		return (
			<TableRow>
				<TableCell colSpan={4}>
					<Skeleton/>
				</TableCell>
			</TableRow>
		);
	}

	return (
		<>
			{responses.map((comment) =>
				<Row comment={comment} showResponse loading={false} key={comment.Social_Comment__} isResponse={true}/>
			)}
		</>
	);
};


const mapDispatchToProps = (dispatch) => {
	return {
		fetchResponses: (commentId) => dispatch(fetchResponses(commentId)),
	};
};

export default connect(null, mapDispatchToProps)(ResponseComment);