import React, {useState}                         from 'react';
import {Box, CircularProgress, Grid, IconButton} from '@material-ui/core';
import makeStyles                                from '@material-ui/core/styles/makeStyles';
import Typography                                from '@material-ui/core/Typography';
import {connect}                                 from 'react-redux';
import SendIcon                                  from '@material-ui/icons/Send';
import {useTranslation}                          from 'react-i18next';
import {Cached}                                  from '@material-ui/icons';
import ShareDialog                               from './ShareDialog';
import RealmMenu                                 from './RealmMenu';

const useStyles = makeStyles(theme => ({
	container: {
		padding:'3px',
		background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), #121212;',
	},
	lightColor : {
		cursor: 'pointer',
		color: 'rgba(255, 255, 255, 0.56)',
	},
	loader: {
		marginRight: theme.spacing(2)
	}
}));

const RealmSection = ({registryUrl, ready}) => {
	const classes = useStyles();
	const {t} = useTranslation();
	const [showShare, setShowShare] = useState(false)
	const [realmMenuAnchor, setRealmMenuAnchor] = useState(false);
	return (
		<Box className={classes.container}>
			<Grid container alignItems="center" justify="center">
				<Grid item>
					{(ready) && <Typography
						component={'span'}
						variant="subtitle1"
						color="inherit"
						target="_blank"
						rel="noopener noopener"
						noWrap
					>
						{registryUrl && registryUrl.Domain}
					</Typography>}
					{(!ready) && <CircularProgress size={21} className={classes.loader}/>}
				</Grid>
				{ready && <Grid item xs={12}>
					<Grid container justify='space-around'>
						{registryUrl && <Grid item>
							<IconButton size='small' className={classes.lightColor} onClick={() => setShowShare(true)}>
								<SendIcon/>
							</IconButton>
							<span className={classes.lightColor}
							      onClick={() => setShowShare(true)}>{t('share_site')}</span>
						</Grid>}
						<Grid item>
							<IconButton size='small' className={classes.lightColor} onClick={(e) => setRealmMenuAnchor(e.currentTarget)}>
								<Cached/>
							</IconButton>
							<span className={classes.lightColor} onClick={(e) => setRealmMenuAnchor(e.currentTarget)}>{t('change_realm')}</span>
						</Grid>
					</Grid>
				</Grid>
				}
			</Grid>
			<ShareDialog open={showShare} setOpen={setShowShare}/>
			<RealmMenu anchorEl={realmMenuAnchor} setAnchorEl={setRealmMenuAnchor}/>
		</Box>
	);
};

const mapStateToProps = (state) => {
	return {
		registryUrl: state.registry.url,
		ready: state.common.ready,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RealmSection);

