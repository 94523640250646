import React, { useEffect, useState } from 'react';
import { updateProduct }              from '../../../../store/actions/store/ProductAction';
import { connect }                    from 'react-redux';
import { Grid }                       from '@material-ui/core';
import { useTranslation }             from 'react-i18next';
import { getAttribute }               from '../../../utils/product';
import Button                         from '../../inputs/Button';
import ProductTaxProfile              from '../../inputs/ProductTaxProfileSelector';
import FormRequiredField              from '../../feeback/FormRequiredField';

const SellingAttributes = ({ attributes, productId, updating, isManager, updateProduct }) => {
	const { t } = useTranslation();

	const tmp = getAttribute(attributes, 'Basic.TaxProfile') ?? [];
	const [basicTaxProfile, setBasicTaxProfile] = useState(tmp.length > 0 ? tmp[0].Value : '');

	const [saveEnabled, setSaveEnabled] = useState(false);


	useEffect(() => {
		const tmp = getAttribute(attributes, 'Basic.TaxProfile') ?? [];
		setBasicTaxProfile(tmp.length > 0 ? tmp[0].Value : '');
	}, [attributes, setBasicTaxProfile]);

	useEffect(() => {
		const tmp = getAttribute(attributes, 'Basic.TaxProfile') ?? [];
		setSaveEnabled(basicTaxProfile.trim() && basicTaxProfile.trim() !== (tmp.length > 0 ? tmp[0].Value : ''));
	}, [setSaveEnabled, attributes, basicTaxProfile]);


	const saveHandler = () => {
		const data = [];

		// Because updating a property is quite heavy (updating children etc), we need to send only the props that have changed
		const tmp = getAttribute(attributes, 'Basic.TaxProfile') ?? [];
		if (basicTaxProfile.trim() !== (tmp.length > 0 ? tmp[0].Value : '')) {
			data.push({
				Variable: 'Basic.TaxProfile',
				Language__: null,
				Realm__: null,
				Value: basicTaxProfile.trim(),
			});
		}


		updateProduct(productId, data);
	};


	return (
		<Grid container spacing={3}>
			<FormRequiredField/>
			<Grid item xs={12}>
				<ProductTaxProfile
					value={basicTaxProfile}
					setValue={setBasicTaxProfile}
					required
					enabledError={isManager}
					disabled={updating || !isManager}
				/>
			</Grid>

			{isManager &&
			<Grid item xs={12}>
				<Grid container justify='flex-end'>

					<Button onClick={saveHandler} loading={updating} color='primary' variant='contained'
					        disabled={!saveEnabled || updating}>
						{t('save_btn')}
					</Button>

				</Grid>
			</Grid>
			}
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		isManager: state.access.manager,
		updating: false,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateProduct: (id, data) => dispatch(updateProduct(id, data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SellingAttributes);
