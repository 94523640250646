import {
    MEMBERSHIP_PLAN_CREATE,
    MEMBERSHIP_PLAN_CREATE_DONE,
    MEMBERSHIP_PLAN_FETCH,
    MEMBERSHIP_PLAN_FETCH_DONE,
    MEMBERSHIP_PLAN_FORCE_UPDATE_TAG_NAME, MEMBERSHIP_PLAN_FREE_FETCH, MEMBERSHIP_PLAN_FREE_FETCH_DONE,
    MEMBERSHIP_PLAN_UPDATE_PRODUCT,
    MEMBERSHIP_PLANS_FETCH,
    MEMBERSHIP_PLANS_FETCH_DONE,
    MEMBERSHIP_UPDATE_PLAN_DONE,
    MEMBERSHIP_UPDATING_PLAN
} from "../../reducers/fanclub/PlanReducer";
import {rest} from "@karpeleslab/klbfw";
import {handleError} from "../../../components/utils/apiErrorHandler";
import {error, success} from "../system/ToastAction";

export const fetchPlans = (includeFree = true) => {
    return (dispatch, getState) => {
        dispatch({type: MEMBERSHIP_PLANS_FETCH});

        const params = {
            sort: {
                Priority: 'DESC'
            }
        };

        if (!includeFree) {
            params['Free'] = {'$not':'Y'};
        }

        let tmp = null;
        return rest('Membership/' + getState().membership.selectedFanclub.Membership__ + '/Plan', 'GET', params)
            .then((data) => {
                tmp = data.data;
                return dispatch(fetchHasFreePlan())
            })
            .then(() => {
                dispatch({type: MEMBERSHIP_PLANS_FETCH_DONE, plans: tmp});
                return tmp;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const fetchHasFreePlan = () => {
    return (dispatch, getState) => {
        dispatch({type: MEMBERSHIP_PLAN_FREE_FETCH});
        return rest('Membership/' + getState().membership.selectedFanclub.Membership__ + '/Plan', 'GET', {'Free':'Y'})
            .then((data) => {
                dispatch({type: MEMBERSHIP_PLAN_FREE_FETCH_DONE, hasFreePlan: data.data.length > 0});
                return data.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const fetchPlan = (id) => {
    return (dispatch, getState) => {
        dispatch({type: MEMBERSHIP_PLAN_FETCH});
        return rest('Membership/Plan/' + id)
            .then((data) => {
                dispatch({type: MEMBERSHIP_PLAN_FETCH_DONE, plan: data.data});
                return data.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const updatePlan = (plan, data) => {
    return (dispatch, getState) => {
        dispatch({type: MEMBERSHIP_UPDATING_PLAN, plan: plan});

        return rest('Membership/Plan/' + plan.Membership_Plan__, 'PATCH', data)
            .then((data) => {
                dispatch({type: MEMBERSHIP_UPDATE_PLAN_DONE, plan: data.data});
                success('membership_plan_update_success');
                return data.data;
            }).catch((err) => {
                error(err.message, false);
                handleError(getState, dispatch, err)
            })
    }
};

export const updateSelectedPlanProduct = (product) => {
    return (dispatch, getState) => {
        dispatch({type: MEMBERSHIP_PLAN_UPDATE_PRODUCT, product: product})
    }
};

export const forceUpdateSelectedPlanTagName = (name) => {
    return (dispatch, getState) => {
        dispatch({type: MEMBERSHIP_PLAN_FORCE_UPDATE_TAG_NAME, name: name})
    }
};

export const createPlan = (data) => {
    return (dispatch, getState) => {
        dispatch({type: MEMBERSHIP_PLAN_CREATE});
        let tmp = null;
        return rest('Membership/' + getState().membership.selectedFanclub.Membership__ + '/Plan:create', 'POST', data)
            .then((data) => {
                tmp = data.data;
                return dispatch(fetchHasFreePlan())
            })
            .then(d => {
                dispatch({type: MEMBERSHIP_PLAN_CREATE_DONE});
                success('membership_create_plan_success');
                return tmp;
            }).catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};