import React, {useEffect, useState}                     from 'react';
import {useTranslation}                                 from 'react-i18next';
import Dialog                                           from '@material-ui/core/Dialog';
import DialogTitle                                      from '@material-ui/core/DialogTitle';
import DialogContent                                    from '@material-ui/core/DialogContent';
import Grid                                             from '@material-ui/core/Grid';
import TextField                                        from '@material-ui/core/TextField';
import DialogActions                                    from '@material-ui/core/DialogActions';
import Button                                           from '../../../../inputs/Button';
import {connect}                                        from 'react-redux';
import {Box}                                            from '@material-ui/core';
import {createShippingFeeScale, fetchShippingFeeScales} from '../../../../../../store/actions/store/ShippingFeeAction';
import {getCurrency}                                    from '@karpeleslab/klbfw';
import FeeScaleTaxProfileSelector                       from '../../../../inputs/FeeScaleTaxProfileSelector';
import InputAdornment                                   from '@material-ui/core/InputAdornment';
import Price                                            from '../../../../inputs/Price';
import FormRequiredField                                from '../../../../feeback/FormRequiredField';

const CreateDialog = ({feeId, zoneId, open, setOpen, creating, create, refresh}) => {
	const {t} = useTranslation();
	const [code, setCode] = useState('');
	const [currency, setCurrency] = useState(getCurrency());
	const [price, setPrice] = useState(null);
	const [vatIncluded, setVatIncluded] = useState(false);
	const [weight, setWeight] = useState();
	const [taxProfile, setTaxProfile] = useState('exempt');

	const [saveDisabled, setSaveDisabled] = useState(true);

	useEffect(() => {
		if (!currency || !code.trim() || !taxProfile || price === null) {
			setSaveDisabled(true);
		} else {
			setSaveDisabled(false);
		}
	}, [currency, code, taxProfile, setSaveDisabled, price]);

	const handleClose = () => {
		setOpen(false);
	};

	const handleCreate = (e) => {
		e.preventDefault();
		create(feeId, zoneId, {
			Code: code.trim(),
			Currency__: currency,
			Max_Weight: weight,
			Tax_Profile: taxProfile,
			Fee: price,
			Vat_Included: vatIncluded ? 'Y' : 'N',
		})
			.then((s) => refresh(feeId))
			.then(handleClose);
	};

	return (
		<Dialog
			disableEnforceFocus
			open={open}
			aria-labelledby={t('create_shipping_fee_scale')}
			aria-describedby={t('create_shipping_fee_scale')}
			fullWidth
			maxWidth='md'
			onEnter={() => {
				setCode('');
				setCurrency(getCurrency());
				setPrice(null);
				setWeight(null);
				setVatIncluded(false);
				setTaxProfile('exempt');
			}}
		>
			<form onSubmit={handleCreate}>
				<DialogTitle>{t('create_shipping_fee')}</DialogTitle>
				<DialogContent>
					<Box py={2}>
						<Grid container spacing={3}>
							<FormRequiredField/>
							<Grid item xs={12} md={6}>
								<TextField
									label={t('shipping_fee_scale_code')}
									fullWidth variant="outlined" value={code}
									required
									disabled={creating}
									onChange={e => setCode(e.target.value)}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<FeeScaleTaxProfileSelector
									value={taxProfile}
									setValue={setTaxProfile}
									disabled={creating}
									required
								/>
							</Grid>

							<Grid item xs={12}>
								<TextField
									label={t('shipping_fee_scale_max_weight')}
									helperText={t('shipping_fee_scale_max_weight_helperText')}
									fullWidth variant="outlined" value={weight}
									type="number"
									required
									inputProps={{min: 0}}
									disabled={creating}
									onChange={e => setWeight(e.target.value)}
									InputProps={{
										endAdornment: <InputAdornment position="end">g</InputAdornment>,
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Price
									variant="outlined"
									fullWidth
									required
									setVatIncluded={setVatIncluded}
									vatIncluded={vatIncluded}
									value={price}
									onValueChange={setPrice}
									label={vatIncluded ? t('plan_price_vat_lbl') : t('plan_price_lbl')}
									helperText={vatIncluded ? t('plan_price_vat_helperText') : t('plan_price_helperText')}
									disabled={creating}
								/>
							</Grid>

						</Grid>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button color="default" onClick={handleClose} disabled={creating}>
						{t('close_btn')}
					</Button>

					<Button color="primary" type="submit" variant="contained"
					        disabled={creating || saveDisabled}
					        loading={creating}>
						{t('create_btn')}
					</Button>
				</DialogActions>
			</form>
		</Dialog>

	);
};
const mapStateToProps = (state) => {
	return {
		creating: state.shippingFee.scaleCreating,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		create: (feeId, zoneId, data) => dispatch(createShippingFeeScale(feeId, zoneId, data)),
		refresh: (feeId) => dispatch(fetchShippingFeeScales(feeId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDialog);