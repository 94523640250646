import React, { useEffect, useState } from 'react';
import { connect }                    from 'react-redux';
import { updateCanned }               from '../../../../store/actions/SupportAction';
import { Grid }                       from '@material-ui/core';
import { useTranslation }             from 'react-i18next';
import TextField                      from '@material-ui/core/TextField';
import HasRight                       from '../../../Route/HasRight';
import Button                         from "../../inputs/Button";
import FormRequiredField              from '../../feeback/FormRequiredField';

const Edit = ({ canned, updating, update, isManager }) => {
	const { t } = useTranslation();
	const [title, setTitle] = useState('');
	const [message, setMessage] = useState('');
	const [saveEnabled, setSaveEnabled] = useState(false);

	useEffect(() => {
		if (!canned) return;
		if (!title.trim() || !message.trim()) {
			setSaveEnabled(false);
			return;
		}

		if (title !== canned.Title) {
			setSaveEnabled(true);
			return;
		}

		if (message !== canned.Message) {
			setSaveEnabled(true);
			return;
		}

		setSaveEnabled(false);
	}, [title, message, setSaveEnabled, canned]);

	useEffect(() => {
		if (!canned) return;
		setMessage(canned.Message);
		setTitle(canned.Title);
	}, [canned, setTitle, setMessage]);

	const handleUpdate = () => {
		if (!canned) return;
		update(canned.Support_Canned__, title, message);
	};

	return (
		<Grid container spacing={3}>
			<FormRequiredField/>
			<Grid item xs={12}>
				<TextField label={t('title_canned')} fullWidth variant='outlined' value={title}
				           required
				           disabled={updating || !isManager}
				           onChange={e => setTitle(e.target.value)}
				           helperText={t('title_canned_selected_lang')}/>
			</Grid>

			<Grid item xs={12}>
				<TextField
					value={message}
					onChange={e => setMessage(e.target.value)}
					variant='outlined'
					multiline rows={5}
					rowsMax={Infinity}
					placeholder={t('support_canned_response_placeholder')}
					required
					fullWidth
					disabled={updating || !isManager}
					label={t('support_canned_response_label')}
				/>
			</Grid>
			<HasRight manager hideLoading>
				<Grid item xs={12}>
					<Grid justify='flex-end' container>
						<Button variant='contained' loading={updating} disabled={!saveEnabled || updating} color='primary'
						        onClick={handleUpdate}>
							{t('save_btn')}
						</Button>
					</Grid>
				</Grid>
			</HasRight>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		updating: state.support.updatingCanned,
		isManager: state.access.manager,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		update: (cannedId, title, message) => dispatch(updateCanned(cannedId, title, message)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
