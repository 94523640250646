const initState = {
    loading : false,
    countries: []
};

export const COUNTRY_FETCH = 'COUNTRY_FETCH';
export const COUNTRY_FETCH_DONE = 'COUNTRY_FETCH_DONE';

const countryReducer = (state = initState, action) => {
    switch (action.type) {
        case COUNTRY_FETCH:
            return  {...state, loading: true};
        case COUNTRY_FETCH_DONE:
            return  {...state,loading: false, countries: action.countries}
        default:
            return state;
    }

}

export default countryReducer