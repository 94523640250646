import React, {useEffect, useState}    from 'react';
import Grid                            from '@material-ui/core/Grid';
import AppBarTitle                     from '../../../components/common/typography/AppBarTitle';
import {connect}                       from 'react-redux';
import Loading                         from '../../../components/common/feeback/loading/Loading';
import SelectRealm                     from '../../SelectRealm';
import {Link as RouterLink, useParams} from 'react-router-dom';
import {Trans, useTranslation}         from 'react-i18next';
import HasCapability                   from '../../../components/Route/HasCapability';
import {fetchProduct}                  from '../../../store/actions/store/ProductAction';
import Toolbar                         from '../../../components/common/typography/Toolbar';
import Details                         from '../../../components/common/store/shop/Details';
import {Tab, Tabs}                     from '@material-ui/core';
import Panel                           from '../../../components/common/data/tab/Panel';
import Variations                      from '../../../components/common/store/shop/Variations';
import Gallery                         from '../../../components/common/store/shop/gallery/Gallery';
import {getAttribute}                  from '../../../components/utils/product';
import Breadcrumbs                     from '@material-ui/core/Breadcrumbs';
import Link                            from '@material-ui/core/Link';
import {getStoreProductEditRoute}      from '../../../router/routes/store/factory/store';
import Typography                      from '@material-ui/core/Typography';
import Selling                         from '../../../components/common/store/shop/Selling';
import Shipping                        from '../../../components/common/store/shop/Shipping';
import RichAlert                       from '../../../components/common/feeback/RichAlert';
import useTheme                        from '@material-ui/core/styles/useTheme';
import useMediaQuery                   from '@material-ui/core/useMediaQuery';
import StoreActivator                  from '../../../components/common/store/StoreActivator';
import ProductTags                     from '../../../components/common/store/shop/ProductTags';

const Edit = ({selectedRealm, store, fetchProduct, attributes}) => {
	const [loading, setLoading] = useState(true);
	const {t} = useTranslation();
	const {productId} = useParams();
	const [tabIndex, setTabIndex] = useState(0);

	const nativeName = getAttribute(attributes, 'Native.Internal_Name') ?? [];
	const [nativeInternalName, setNativeInternalName] = useState(nativeName.length > 0 ? nativeName[0].Value : '');

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {noSsr: true});

	useEffect(() => {
		setTabIndex(0);
		fetchProduct(productId)
			.then(s => setLoading(false));
	}, [fetchProduct, productId]);

	useEffect(() => {
		const nativeName = getAttribute(attributes, 'Native.Internal_Name') ?? [];
		setNativeInternalName(nativeName.length > 0 ? nativeName[0].Value : '');
	}, [attributes, setNativeInternalName]);


	if (!selectedRealm) return <SelectRealm/>;
	if (!store || loading) return <Loading/>;

	const handleTabChange = (event, newValue) => {
		setTabIndex(newValue);
	};

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('product_edit_title', {name: nativeInternalName})}/>

			<HasCapability store>
				<StoreActivator/>
				<Grid item xs={12}>
					<Toolbar title={
						<span>
                            {t('product_edit_title', {name: nativeInternalName})}

							{attributes.breadcrumbs.length > 1 &&
							<Breadcrumbs component="span">
								{attributes.breadcrumbs.slice(0).reverse().map((b) =>
									b.Catalog_Product__ !== productId ?
										<Link component={RouterLink} color="inherit"
										      to={getStoreProductEditRoute(b.Catalog_Product__)}>
											{b.Internal_Name}
										</Link> : null
								)}

								<Typography
									color="textPrimary">{attributes.breadcrumbs[0].Internal_Name}</Typography>
							</Breadcrumbs>
							}
                            </span>
					}/>


				</Grid>

				<Grid item xs={12}>
					<RichAlert severity="info">
						<Trans i18nKey="product_info_cache">
							sample<br/>
							<strong>sample</strong>
						</Trans>
					</RichAlert>
				</Grid>

				<Grid item xs={12}>
					<Tabs
						variant="scrollable"
						scrollButtons={isMobile ? 'on' : 'auto'}
						value={tabIndex}
						indicatorColor="primary"
						textColor="primary"
						onChange={handleTabChange}
						aria-label=""
					>
						<Tab label={t('product_details')}/>
						<Tab label={t('product_categories')}/>
						<Tab label={t('product_sale')}/>
						<Tab label={t('product_gallery')}/>
						<Tab label={t('product_variations')}/>
						<Tab label={t('product_shipping')}/>

					</Tabs>

					<Panel value={tabIndex} index={0}>
						<Details attributes={attributes} productId={productId}/>
					</Panel>

					<Panel value={tabIndex} index={1}>
						<ProductTags realm={selectedRealm} attributes={attributes} productId={productId}/>
					</Panel>

					<Panel value={tabIndex} index={2}>
						<Selling attributes={attributes} productId={productId}/>
					</Panel>

					<Panel value={tabIndex} index={3}>
						<Gallery productId={productId}/>
					</Panel>

					<Panel value={tabIndex} index={4}>
						<Variations productId={productId}/>
					</Panel>

					<Panel value={tabIndex} index={5}>
						<Shipping attributes={attributes} productId={productId}/>
					</Panel>

				</Grid>

			</HasCapability>

		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		store: state.store.catalog,
		attributes: state.product.productProperties,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchProduct: (productId) => dispatch(fetchProduct(productId))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
