import React, { useEffect, useState } from 'react';
import { useTranslation }             from 'react-i18next';
import uuid                           from '../../../utils/uuid';

const Video = React.forwardRef((props, ref) => {
	const { source, preset = '360p', disabled = false, ...rest } = props;
	const { t } = useTranslation();
	const [key, setKey] = useState(uuid());
	const [presets, setPresets] = useState(null);

	useEffect(() => {
		// Force rerender of the component if the source changes
		setKey(uuid());

		if (source) {
			const n = {};
			source.forEach(s => n[s.preset] = s.url);
			setPresets(n);
		} else setPresets(null);

	}, [source, setPresets]);

	return (
		<video controls={!disabled} playsInline={true} key={key} preload='metadata' ref={ref} {...rest}>
			<source src={presets ? presets[preset] : null} type='video/mp4'/>
			{t('video_element_not_supported')}
		</video>
	);
});

export default Video;
