import {COMMON_RESET} from "../CommonReducer";
import {USER_LOGOUT} from "../system/UserReducer";

const initState = () => {
    return {
        reservesLoading: false,
        reserves: [],
        reservesFilters: {},
        reservesPagingCount: 0,
        reservesPaging: {
            page_no: 1,
            results_per_page: 10
        },

        exporting: false,
        importing: false,

        allReserve: [],
        allUsedReserve: [],
        loadingAll: true,

        markingAsUsed: false,
    }
};

export const RESERVE_LIST_FETCH = 'RESERVE_LIST_FETCH';
export const RESERVE_LIST_FETCH_DONE = 'RESERVE_LIST_FETCH_DONE';
export const RESERVE_SET_PAGING = 'RESERVE_SET_PAGING';
export const RESERVE_SET_FILTERS = 'RESERVE_SET_FILTERS';
export const RESERVE_EXPORT = 'RESERVE_EXPORT';
export const RESERVE_EXPORT_DONE = 'RESERVE_EXPORT_DONE';

export const RESERVE_IMPORT_ALLOCATED_SEAT = 'RESERVE_IMPORT_ALLOCATED_SEAT';
export const RESERVE_IMPORT_ALLOCATED_SEAT_DONE = 'RESERVE_IMPORT_ALLOCATED_SEAT_DONE';

export const RESERVE_FETCH_ALL = 'RESERVE_FETCH_ALL';
export const RESERVE_FETCH_ALL_DONE = 'RESERVE_FETCH_ALL_DONE';

export const RESERVE_MARK_AS_USED = 'RESERVE_MARK_AS_USED';
export const RESERVE_MARK_AS_USED_DONE = 'RESERVE_MARK_AS_USED_DONE';


const reserveReducer = (state = initState(), action) => {
    switch (action.type) {
        case COMMON_RESET:
        case USER_LOGOUT:
            return initState();
        case RESERVE_FETCH_ALL :
            return {...state, allReserve: [], allUsedReserve: [], loadingAll: true};
        case RESERVE_FETCH_ALL_DONE :
            return {...state, allReserve: action.reserves, allUsedReserve: action.used, loadingAll: false};
        case RESERVE_EXPORT :
            return {...state, exporting: true};
        case RESERVE_EXPORT_DONE :
            return {...state, exporting: false};
        case RESERVE_IMPORT_ALLOCATED_SEAT :
            return {...state, importing: true};
        case RESERVE_IMPORT_ALLOCATED_SEAT_DONE :
            return {...state, importing: false};
        case RESERVE_MARK_AS_USED :
            return {...state, markingAsUsed: true};
        case RESERVE_MARK_AS_USED_DONE :
            if (!action.reserves) {
                return {...state, markingAsUsed: false};
            }
            const n = [...state.allUsedReserve];
            action.reserves.forEach(r => n.push(r));
            return {...state, markingAsUsed: false, allUsedReserve: n};
        case RESERVE_LIST_FETCH:
            return {...state, reservesLoading: true};
        case RESERVE_LIST_FETCH_DONE:
            return {
                ...state,
                reservesLoading: false,
                reserves: action.reserves,
                reservesPagingCount: action.paging.count
            };
        case RESERVE_SET_FILTERS :
            return {...state, reservesFilters: action.filters};
        case RESERVE_SET_PAGING :
            return {...state, reservesPaging: action.paging};
        default:
            return state;
    }

};

export default reserveReducer