import {
	BILLING_BILLINGS_FETCH,
	BILLING_BILLINGS_FETCH_DONE,
	BILLING_BILLINGS_SET_FILTERS,
	BILLING_BILLINGS_SET_PAGING,
	BILLING_FETCH_ONE,
	BILLING_FETCH_ONE_DONE
}                    from '../reducers/BillingReducer';
import {rest}        from '@karpeleslab/klbfw';
import {handleError} from '../../components/utils/apiErrorHandler';

export const fetchBillings = () => {
	return (dispatch, getState) => {
		dispatch({type: BILLING_BILLINGS_FETCH});

		const params = {
			...getState().billing.billingsFilters,
			...getState().billing.billingsPaging
		};

		return rest('User/@/Billing', 'GET', params)
			.then(data => {
				dispatch({type: BILLING_BILLINGS_FETCH_DONE, billings: data.data, paging: data.paging});
				return data.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const fetchFirstBilling = () => {
	return (dispatch, getState) => {
		dispatch({type: BILLING_FETCH_ONE});
		return dispatch(fetchBillings())
			.then(billings => {
				dispatch({type: BILLING_FETCH_ONE_DONE, billing: billings.length > 0 ? billings[0] : null});
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const setBillingFilters = (filters) => {
	return (dispatch, getState) => {
		dispatch({type: BILLING_BILLINGS_SET_FILTERS, filters: filters});
	};
};

export const setBillingPaging = (paging) => {
	return (dispatch, getState) => {
		dispatch({type: BILLING_BILLINGS_SET_PAGING, paging: {...getState().billing.billingsPaging, ...paging}});
	};
};

export const fetchOne = (id, _admin_as = null, handleError = true) => {
    return (dispatch, getState) => {
        dispatch({type: BILLING_FETCH_ONE});
        const params = {};
        if(_admin_as)
            params['_admin_as'] = _admin_as;

        return rest('User/Billing/' + id, 'GET', params)
            .then(data => {
                dispatch({type: BILLING_FETCH_ONE_DONE, billing: data.data});

                return data.data;
            })
            .catch((err) => {
				dispatch({type: BILLING_FETCH_ONE_DONE});
				if(handleError)
                handleError(getState, dispatch, err)
            })
    }
};