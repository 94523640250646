import {useAction, useResourceList}         from '../../useBaseHooks';
import {getNetworkMassMailSegmentsEndpoint} from '../../endpoints/network/massmail/segmentEndpoints';
import {useCallback}                        from 'react';

export const useNetworkMassMailSegment = (restSettings = null) => useResourceList(getNetworkMassMailSegmentsEndpoint(), restSettings);

export const useNetworkMassMailSegmentCreate = () => {
	const [_doAction, loading] = useAction(getNetworkMassMailSegmentsEndpoint(), 'POST', {snackMessageToken: 'massmail_segment_created'});

	const doAction = useCallback((realmId, name, email, subjectPrefix = null, visible = 'Y', settingsOverride = {}) => _doAction({
		Name: name,
		Realm__: realmId,
		Email: email,
		Subject_Prefix: subjectPrefix,
		Visible:visible
	}, settingsOverride), []); //eslint-disable-line

	return [doAction, loading];
};
