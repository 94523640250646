import React, { useEffect, useState } from 'react';
import InputAdornment                 from '@material-ui/core/InputAdornment';
import TextField                      from '@material-ui/core/TextField';
import FileCopyIcon                   from '@material-ui/icons/FileCopy';
import IconButton                     from '@material-ui/core/IconButton';
import CheckIcon                      from '@material-ui/icons/Check';
import { useTranslation }             from 'react-i18next';
import Tooltip                        from '@material-ui/core/Tooltip';
import { Typography }                 from '@material-ui/core';
import { copyToClipboard }            from '../../utils/misc';

const ClipboardCopyTextField = ({ value, ...rest }) => {
	const [copied, setCopied] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		let timer = null;
		if (copied) {
			timer = setTimeout(() => {
				setCopied(false);
			}, 1000);
		}
		return () => {
			if (timer) clearTimeout(timer);
		};
	}, [copied]);

	const handleCopy = () => {
		if (copied) return;
		copyToClipboard(value);
		setCopied(true);
	};

	return (
		<TextField
			value={value}
			InputProps={
				{
					endAdornment:
						(<InputAdornment position='end'>

							{!copied && <Tooltip title={t('clipboard_copy_tooltip')} placement='down' arrow={false}>
								<IconButton
									disabled={rest.disabled}
									onClick={handleCopy}
								>
									<FileCopyIcon/>
								</IconButton>
							</Tooltip>}

							{copied && <>
								<CheckIcon color='primary'/>
								<Typography color='primary' variant='caption'>
									{t('clipboard_copy_confirm')}
								</Typography>

							</>}
						</InputAdornment>)
				}

			}

			{...rest}
		/>
	);
};

export default ClipboardCopyTextField;
