import { rest }                              from '@karpeleslab/klbfw';
import { handleError }                       from '../../../components/utils/apiErrorHandler';
import {
	CATALOG_FETCH,
	CATALOG_FETCH_DONE,
	STORE_CREATE,
	STORE_CREATE_DONE,
	STORE_FETCH_REPORT,
	STORE_FETCH_REPORT_DONE,
	STORE_FETCH_REPORT_SET_PAGING
}                                            from '../../reducers/store/StoreReducer';
import { success }                           from '../system/ToastAction';
import { PRODUCT_LIST_MAIN_IMAGE_VARIATION } from '../../reducers/store/ProductReducer';
import { createUserLocation }                from '../system/UserLocationAction';
import { updateSetting }                     from '../TemplateAction';

export const fetchCatalog = (id) => {
	return (dispatch, getState) => {
		dispatch({ type: CATALOG_FETCH });
		return rest('Catalog/' + id)
			.then(d => {
				dispatch({ type: CATALOG_FETCH_DONE, catalog: d.data });
				return d.data;
			})
			.catch((err) => handleError(getState, dispatch, err));
	};
};

const createMusicStore = (realmId, stripeToken = null) => {
	return (dispatch, getState) => {
		const params = {
			Realm__: realmId,
			Stripe_Token: stripeToken
		};

		return rest('Music:createStore', 'POST', params)
			.then(d => {
				return d;
			})
			.catch((err) => handleError(getState, dispatch, err));
	};
};

export const createStore = (realmId, address, stripeToken = null) => {
	return (dispatch, getState) => {
		dispatch({ type: STORE_CREATE });

		const settingId = getState().template.templateSettings.Setting__;
		if (!address) {
			dispatch(createMusicStore(realmId, stripeToken))
				.then(d => {
					success('store_create_success');
					dispatch({ type: STORE_CREATE_DONE, catalog: d.data });
					return d.data;
				})
				.catch((err) => handleError(getState, dispatch, err));
		} else {
			dispatch(createUserLocation(address))
				.then((location) => dispatch(updateSetting(settingId, 'location', location.User_Location__)))
				.then(() => dispatch(createMusicStore(realmId, stripeToken)))
				.then(d => {
					success('store_create_success');
					dispatch({ type: STORE_CREATE_DONE, catalog: d.data });
					return d.data;
				})
				.catch((err) => handleError(getState, dispatch, err));
		}
	};
};

export const setStoreReportPaging = (newPaging) => {
	return (dispatch, getState) => {
		dispatch({ type: STORE_FETCH_REPORT_SET_PAGING, paging: { ...getState().store.reportPaging, ...newPaging } });
	};
};

export const storeStats = (period, currency, catalogId, status = 'completed', isRealm = false) => {
	return (dispatch, getState) => {
		dispatch({ type: STORE_FETCH_REPORT });
		const params = {
			period: period,
			...getState().store.reportPaging,
			Order_Status: status,
			image_variation: PRODUCT_LIST_MAIN_IMAGE_VARIATION
		};

		if(params) params.Realm__ = catalogId;
		else params.Catalog__= catalogId;

		if (currency && currency !== 'all') {
			params.Order_Currency__ = currency;
		}

		return rest('Order:stats2', 'POST', params)
			.then(d => {

				dispatch({
					type: STORE_FETCH_REPORT_DONE,
					sales: d.data.aggregations.Order_Items.buckets,
					product: d.data.aggregations.Catalog_Product.buckets,
					items: d.data.data,
					paging: d.paging

				});

				return d.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const rebuildCache = () => {
	return (dispatch, getState) => {
		const id = getState().store.catalog.Catalog__;
		return rest(`Catalog/${id}:regenerateCache`, 'POST');
	};
};
