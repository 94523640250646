import React, {useEffect, useState} from 'react';
import {connect}                    from 'react-redux';
import SelectRealm                  from '../../SelectRealm';
import {useTranslation}             from 'react-i18next';
import {Link, useParams}            from 'react-router-dom';
import Loading                      from '../../../components/common/feeback/loading/Loading';
import {fetchRelease}               from '../../../store/actions/discography/ReleaseAction';
import Grid                         from '@material-ui/core/Grid';
import AppBarTitle                  from '../../../components/common/typography/AppBarTitle';
import Toolbar                      from '../../../components/common/typography/Toolbar';
import Button                       from '../../../components/common/inputs/Button';
import SearchIcon                   from '@material-ui/icons/Search';
import {getMusicAlbumEditRoute}     from '../../../router/routes/music/factory/album';
import Visibility                   from '../../../components/common/music/discography/release/Visibility';
import ReleaseTags                  from '../../../components/common/music/discography/release/ReleaseTags';

const Edit = ({selectedRealm, artist, release, fetchRelease}) => {
	const {t} = useTranslation();
	const {releaseId} = useParams();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		fetchRelease(releaseId)
			.then(s => setLoading(false));
	}, [fetchRelease, releaseId]);

	if (!selectedRealm) return <SelectRealm/>;
	if (!artist || loading || !release) return <Loading/>;

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('release_edit_title', {name: release.Music_Label_Album.Name})}/>

			<Grid item xs={12}>
				<Toolbar title={t('release_edit_title', {name: release.Music_Label_Album.Name})}>
					<Button
						variant="contained"
						color="primary"
						startIcon={<SearchIcon/>}
						component={Link}
						to={getMusicAlbumEditRoute(release.Music_Label_Album.Music_Label_Album__)}>
						{t('view_album_btn')}
					</Button>
				</Toolbar>
			</Grid>

			<Grid item xs={12}>
				<Visibility release={release}/>
			</Grid>

			<Grid item xs={12}>
				<ReleaseTags release={release}/>
			</Grid>

		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		artist: state.artist.artist,
		release: state.release.release,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchRelease: (id) => dispatch(fetchRelease(id))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
