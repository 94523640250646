import React from 'react';
import Select from "./Select";
import {useTranslation} from "react-i18next";

const ReserveTypeSelector = ({value, setValue, disabled, required}) => {
    const {t} = useTranslation();

    return <Select
        label={t('event_reserve_type')}
        value={value}
        onChange={e => setValue(e.target.value)}
        optionKey='Key'
        optionValue='Value'
        helperText={t('event_reserve_type_helperText')}
        options={[
            {Key: 'free', Value: t('event_reserve_type_free')},
            {Key: 'seated', Value: t('event_reserve_type_seated')},
        ]}
        inputProps={{
            disabled: disabled,
            variant: 'outlined',
            fullWidth: true,
            required: required
        }}
    />;
};

export default ReserveTypeSelector;
