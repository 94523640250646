import React, {useEffect, useState} from 'react';
import {useUserLocations}           from '../../../../../hooks/api/user/useUserLocation';
import {Card, CardContent, Grid}    from '@material-ui/core';
import Loading                      from '../../../feeback/loading/Loading';
import Location                     from './Location';
import AddIcon                      from '@material-ui/icons/Add';
import Button                       from '../../../inputs/Button';
import {useTranslation}             from 'react-i18next';
import CreateLocationDialog         from './CreateLocationDialog';

const UserLocationSelector = ({value, setValue, disabled}) => {
	const {t} = useTranslation();
	const [createDialogOpened, setCreateDialogOpened] = useState(false);
	const [list, fetch, loading] = useUserLocations();

	useEffect(() => {
		fetch({}, {results_per_page: 100});
		// eslint-disable-next-line
	}, []);

	if (loading || !list) {
		return <Grid container spacing={3} justify="center">
			<Grid item>
				<Loading/>
			</Grid>
		</Grid>;
	}

	return (
		<Grid container spacing={3}>
			{list.data.map(location => <Grid key={location.User_Location__} item xs={12} md={3}>
				<Location location={location} selected={value === location.User_Location__} setSelected={setValue} disabled={disabled}/>
			</Grid>)}
			<Grid item xs={12} md={3}>
				<Card
					variant={'outlined'}
					style={{height: '100%'}}
				>
					<CardContent>
						<Grid container
						      direction="row"
						      justify="center"
						      alignItems="center"
						      spacing={3}
						>
							<Grid item>
								<Button
									disabled={disabled}
									variant="contained"
									color="primary"
									startIcon={<AddIcon/>}
									onClick={e => setCreateDialogOpened(true)}>
									{t('add_btn')}
								</Button>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
			<CreateLocationDialog refresh={fetch} setOpen={setCreateDialogOpened} open={createDialogOpened}/>
		</Grid>
	);
};

export default UserLocationSelector;