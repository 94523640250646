import {handleError} from '../../components/utils/apiErrorHandler';
import {rest}        from '@karpeleslab/klbfw';
import {
	CATALOGS_STATS_FETCH,
	CATALOGS_STATS_FETCH_DONE,
	STATS_FINANCIAL_REPORT,
	STATS_FINANCIAL_REPORT_DONE,
	STATS_MEMBERSHIP_FETCH,
	STATS_MEMBERSHIP_FETCH_DONE,
	STATS_MEMBERSHIP_SET_FILTERS,
	STATS_ORDER_FETCH,
	STATS_ORDER_FETCH_DONE
}             from '../reducers/StatsReducer';
import moment from 'moment';
import uuid   from '../../components/utils/uuid';
import {pad}  from '../../components/utils/misc';

export const membershipStats = (membershipId) => {
	return (dispatch, getState) => {
		dispatch({type: STATS_MEMBERSHIP_FETCH});
		return rest('Membership/' + membershipId + ':stats', 'POST', getState().stats.membershipStatsFilters)
			.then(d => {
				dispatch({type: STATS_MEMBERSHIP_FETCH_DONE, stats: d.data});
				return d.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const setMembershipStatsFilters = (filters) => {
	return (dispatch, getState) => {
		dispatch({type: STATS_MEMBERSHIP_SET_FILTERS, filters: filters});
	};
};

export const fetchCatalogStats = (catalogId, period, currencyId, status = 'completed') => {
	return rest('Order:stats2', 'POST', {Catalog__: catalogId, period: period, Order_Currency__: currencyId, Order_Status: status});
};

const completeStats = (period, stats) => {
	const dayInMonth = moment(period, 'YYYY-MM').daysInMonth();
	let newStats = [];

	const generateEmpty = (day) => {
		return {
			Total: {value: 0, value_float: 0},
			Total_Vat: {value: 0, value_float: 0},
			Vat_Amount: {value: 0, value_float: 0},
			doc_count: 0,
			key: uuid(),
			key_as_string: period + '-' + pad(day, 2)
		};
	};

	let statsIdx = 0;

	for (let i = 0; i < dayInMonth; i++) {
		if (statsIdx > (stats.length - 1)) {
			newStats.push(generateEmpty(i + 1));
			continue;
		}

		if (stats[statsIdx].key_as_string.split('-')[2] === pad(i + 1, 2)) {
			newStats.push(stats[statsIdx]);
			statsIdx++;
			continue;
		}

		newStats.push(generateEmpty(i + 1));
	}

	return newStats;
}

export const fetchCatalogsStats = (period, currencyId) => {
	return (dispatch, getState) => {
		dispatch({type: CATALOGS_STATS_FETCH});
		let promisesName = [];

		let promises = [];

		if (getState().membership.selectedFanclub) {
			promisesName.push('fanclub');
			promises.push(fetchCatalogStats(getState().membership.selectedFanclub.Catalog__, period, currencyId));
		}

		if (getState().schedule.planner && getState().schedule.planner.Catalog__) {
			// edge case, on old planner catalog was not set on creation but when an event is created
			// So we could have a planner (schedules) without a catalog set (no event created)
			promisesName.push('event');
			promises.push(fetchCatalogStats(getState().schedule.planner.Catalog__, period, currencyId));
		}

		if (getState().store.catalog) {
			promisesName.push('store');
			promises.push(fetchCatalogStats(getState().store.catalog.Catalog__, period, currencyId));
		}


		return Promise.all(promises).then((datas) => {
			let stats = {};
			datas.forEach((data, idx) => {
				stats[promisesName[idx]] = completeStats(period, data.data.aggregations.Order_Items.buckets);
			});

			// Now we format it for recharts
			let finalStats = [];
			let statsCount = promisesName.length > 0 ? stats[promisesName[0]].length : -1; // All stats are same length after the complete stats

			if(statsCount < 0) {
				dispatch({type: CATALOGS_STATS_FETCH_DONE, stats: finalStats});
			}

			for(let statIndex = 0; statIndex < statsCount; statIndex++) {
				let s = {};
				Object.keys(stats).forEach((statsName) => {
					s[statsName] = stats[statsName][statIndex].Total_Vat.value_float
					s['name'] = stats[statsName][statIndex].key_as_string;
				})
				finalStats.push(s);
			}

			dispatch({type: CATALOGS_STATS_FETCH_DONE, stats: finalStats});
		})
			.catch((err) => {
				dispatch({type: CATALOGS_STATS_FETCH_DONE, stats: []});
				handleError(getState, dispatch, err);
			});
	};
};

export const orderStats = (realmId, period, currencyId) => {
	return (dispatch, getState) => {
		dispatch({type: STATS_ORDER_FETCH});
		return rest('Order:stats', 'POST', {
			Realm__: realmId,
			period: period,
			Currency__: currencyId
		})
			.then(d => {

				dispatch({
					type: STATS_ORDER_FETCH_DONE,
					stats: d.data.aggregations.Orders.buckets,
					total: d.data.hits.total
				});

				return d.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const financialReportFetch = (catalogOrRealmId, start, end) => {
	return (dispatch, getState) => {
		dispatch({type: STATS_FINANCIAL_REPORT});
		const params = {
			start: start,
			end: end
		};

		if (catalogOrRealmId.startsWith('usrr-')) {
			params['Realm__'] = catalogOrRealmId;
		} else {
			params['Catalog__'] = catalogOrRealmId;
		}

		return rest('Order/Item/Report:fetch', 'GET', params)
			.then(d => {
				dispatch({type: STATS_FINANCIAL_REPORT_DONE});
				return d.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};