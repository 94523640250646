import React, { useEffect, useState } from 'react';
import { connect }                    from 'react-redux';
import { updateEvent }                from '../../../../store/actions/planner/EventAction';
import { Grid }                       from '@material-ui/core';
import { Title }                      from '../../typography/Title';
import { useTranslation }             from 'react-i18next';
import TextField                      from '@material-ui/core/TextField';
import Button                         from '../../inputs/Button';
import FormRequiredField              from '../../feeback/FormRequiredField';

const Details = ({ event, updating, updateEvent, isManager }) => {
	const { t } = useTranslation();
	const [location, setLocation] = useState(event ? (event.Location ?? '') : '');
	const [locationUrl, setLocationUrl] = useState(event ? (event.Location_Url ?? '') : '');
	const [locationPostal, setLocationPostal] = useState(event ? (event.Location_Postal ?? '') : '');

	const [saveEnabled, setSaveEnabled] = useState(false);

	useEffect(() => {
		setLocation(event ? (event.Location ?? '') : '');
		setLocationUrl(event ? (event.Location_Url ?? '') : null);
		setLocationPostal(event ? (event.Location_Postal ?? '') : null);
	}, [event, setLocation, setLocationUrl, setLocationPostal]);

	useEffect(() => {
		if (!event) {
			setSaveEnabled(false);
			return;
		}

		if (location.trim() === '') {
			setSaveEnabled(false);
			return;
		}

		setSaveEnabled(
			(event.Location ?? '') !== location ? location.trim() : null ||
			(event.Location_Url ?? '') !== locationUrl ? locationUrl.trim() : null ||
			(event.Location_Postal ?? '') !== locationPostal ? locationPostal.trim() : null
		);

	}, [event, setSaveEnabled, location, locationUrl, locationPostal]);

	const handleSave = () => {
		updateEvent(event.Planner_Event__, {
			Location: location,
			Location_Url: locationUrl,
			Location_Postal: locationPostal
		}).then(event => {
			setSaveEnabled(
				(event.Location ?? '') !== location ? location.trim() : null ||
				(event.Location_Url ?? '') !== locationUrl ? locationUrl.trim() : null ||
				(event.Location_Postal ?? '') !== locationPostal ? locationPostal.trim() : null
			);
		});
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Title>{t('event_details_title')}</Title>
			</Grid>

			<FormRequiredField/>

			<Grid item xs={12} md={6}>
				<TextField label={t('event_location')} fullWidth variant='outlined' value={location}
				           required
				           disabled={updating || !isManager}
				           onChange={e => setLocation(e.target.value)}
				           helperText={t('event_location_helperText')}
				           inputProps={{
					           maxLength: 128
				           }}/>
			</Grid>

			<Grid item xs={12} md={6}>
				<TextField label={t('event_location_url')} fullWidth variant='outlined' value={locationUrl}
				           disabled={updating || !isManager}
				           onChange={e => setLocationUrl(e.target.value)}
				           helperText={t('event_location_url_helperText')}
				           inputProps={{
					           maxLength: 128
				           }}/>
			</Grid>

			<Grid item xs={12} md={6}>
				<TextField label={t('event_location_postal')} fullWidth variant='outlined' value={locationPostal}
				           disabled={updating || !isManager}
				           onChange={e => setLocationPostal(e.target.value)}
				           helperText={t('event_location_postal_helperText')}
				           inputProps={{
					           maxLength: 128
				           }}/>
			</Grid>

			{isManager &&
			<Grid item xs={12}>
				<Grid container justify='flex-end'>
					<Button color='primary' variant='contained'
					        disabled={!saveEnabled || updating} loading={updating} onClick={handleSave}>
						{t('save_btn')}
					</Button>
				</Grid>
			</Grid>
			}
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		updating: state.event.updating,
		isManager: state.access.manager,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateEvent: (id, data) => dispatch(updateEvent(id, data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);
