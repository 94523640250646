import React, { useEffect, useState } from 'react';
import { connect }                    from 'react-redux';
import { fetchStreamBroadcastInfo }   from '../../../../../store/actions/planner/StreamAction';
import Loading                        from '../../../feeback/loading/Loading';
import { Grid }                       from '@material-ui/core';
import RichAlert                      from '../../../feeback/RichAlert';
import { Trans, useTranslation }      from 'react-i18next';
import ListItem                       from '@material-ui/core/ListItem';
import ListItemIcon                   from '@material-ui/core/ListItemIcon';
import LanguageIcon                   from '@material-ui/icons/Language';
import ListItemText                   from '@material-ui/core/ListItemText';
import VpnKeyIcon                     from '@material-ui/icons/VpnKey';
import List                           from '@material-ui/core/List';
import OpenStreamDialog               from './OpenStreamDialog';
import ReactPlayer                    from 'react-player';
import StreamArchive                  from './StreamArchive';
import uuid                           from '../../../../utils/uuid';
import StreamStatus                   from './StreamStatus';
import Button from "../../../inputs/Button";

const StreamInfo = ({ event, fetchStreamBroadcastInfo, loading, rtmp, stream_key, playbackUrl, status }) => {
	const { t } = useTranslation();
	const [showStart, setShowStart] = useState(false);
	const [playerKey, setPlayerKey] = useState(uuid());
	const [error, setError] = useState(false);
	const [ready, setReady] = useState(false);

	useEffect(() => {
		fetchStreamBroadcastInfo(event.Planner_Event__);
	}, [event.Planner_Event__, fetchStreamBroadcastInfo]);


	const onPlayerError = (e, details) => {
		// Refresh player in 10 seconds
		if (details.type !== 'networkError' || details.details !== 'manifestLoadError') return;
		setError(true);
		setReady(false);
		setTimeout(() => {
			setPlayerKey(uuid());
		}, 10000);
	};

	const onReady = () => {
		setError(false);
		setReady(true);
	};

	if (loading) return <Loading containerProps={{ style: { height: '100%' } }}/>;
	if (status === 'finished') return <StreamArchive event={event}/>;

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<RichAlert severity='info'>
					<Trans i18nKey='stream_info'>
						sample<br/>
						<strong>sample</strong>
					</Trans>
				</RichAlert>
			</Grid>

			<Grid item xs={12}>
				<List>
					{rtmp && <ListItem>
						<ListItemIcon>
							<LanguageIcon/>
						</ListItemIcon>
						<ListItemText primary={rtmp}/>
					</ListItem>}
					{stream_key && <ListItem>
						<ListItemIcon>
							<VpnKeyIcon/>
						</ListItemIcon>
						<ListItemText primary={stream_key}/>
					</ListItem>}

				</List>
			</Grid>
			<Grid item xs={12}>
				<ReactPlayer
					onError={onPlayerError}
					onReady={onReady}
					key={playerKey}
					url={playbackUrl}
					controls={true}
					width='100%'
				/>
			</Grid>
			<Grid item xs={12}>
				<StreamStatus error={error} ready={ready}/>
			</Grid>
			<Grid item xs={12}>
				<RichAlert severity='info'>
					<Trans i18nKey='stream_prepare_info'>
						sample<br/>
						<strong>sample</strong>
					</Trans>
				</RichAlert>
			</Grid>
			<Grid item xs={12}>
				<Grid justify='flex-end' container>
					<Button
						onClick={() => setShowStart(true)}
						variant={event.Stream_Status === 'started' ? 'outlined' : 'contained'}
						color='primary'
					>
						{event.Stream_Status === 'started' ? t('reopen_stream_btn') : t('open_stream_btn')}
					</Button>
				</Grid>
			</Grid>
			<OpenStreamDialog event={event} open={showStart} setOpen={setShowStart}/>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.stream.loading,
		rtmp: state.stream.rtmp,
		srtmp: state.stream.srtmp,
		stream_key: state.stream.stream_key,
		playbackUrl: state.stream.playbackUrl,
		reconnect: state.stream.reconnect,
		status: state.stream.status,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchStreamBroadcastInfo: (eventId) => dispatch(fetchStreamBroadcastInfo(eventId))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(StreamInfo);
