import React from 'react';
import {useTranslation} from 'react-i18next';
import Select from "./Select";

const RecordLiveSelector = ({value, setValue, disabled, required}) => {
    const {t} = useTranslation();

    return <Select
        label={t('record_live_selector')}
        helperText={t('record_live_selector_helperText')}
        value={value}
        onChange={e => setValue(e.target.value)}
        optionKey='Key'
        optionValue='Value'
        allowEmpty={false}
        options={[
            {Key: 'N', Value: t('record_live_N')},
            {Key: 'Y', Value: t('record_live_Y')},
        ]}
        inputProps={{
            disabled: disabled,
            variant: 'outlined',
            fullWidth: true,
            required: required
        }}
    />;
};

export default RecordLiveSelector;