import React              from 'react';
import Dialog             from '@material-ui/core/Dialog';
import DialogTitle        from '@material-ui/core/DialogTitle';
import DialogContent      from '@material-ui/core/DialogContent';
import DialogContentText  from '@material-ui/core/DialogContentText';
import DialogActions      from '@material-ui/core/DialogActions';
import { connect }        from 'react-redux';
import Typography         from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { leaveGroup }     from '../../../store/actions/system/AccessAction';
import Button             from '../inputs/Button';
import ExitToAppIcon      from '@material-ui/icons/ExitToApp';

const LeaveDialog = ({open, setOpen, leaving, loading, leaveGroup}) => {
    const {t} = useTranslation();

    const handleClose = () => {
        setOpen(false);
    };

    const handleLeave = () => {
	    leaveGroup()
            .then(() => {
	            window.scrollTo({ top: 0, behavior: 'smooth' });
	            handleClose();
            })
    };

    return (
        <Dialog
            open={open}
            aria-labelledby={t('leave_access')}
            aria-describedby={t('leave_access')}
            fullWidth
        >
            <DialogTitle id="access-revoke-title">{t('confirm_leave_access_title')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="access-revoke-desc">
                    {t('confirm_leave_access_desc')}
                    <br/>
                    <Typography variant="body1" color="textPrimary" component="span">{t('are_you_sure')}</Typography>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button variant='contained' color="primary" autoFocus onClick={handleClose}
                        disabled={leaving || loading}>
                    {t('back_btn')}
                </Button>
                <Button color="secondary" disabled={leaving || loading} loading={leaving || loading}
                        endIcon={<ExitToAppIcon/>} onClick={handleLeave}>
                    {t('leave_btn')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {
        leaving: state.access.leavingGroup,
        loading: state.access.groupLoading
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
	    leaveGroup: () => dispatch(leaveGroup()),
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(LeaveDialog);
