import React, { useEffect, useState } from 'react';
import Grid                           from '@material-ui/core/Grid';
import Stepper                        from '@material-ui/core/Stepper';
import Step                           from '@material-ui/core/Step';
import StepLabel                      from '@material-ui/core/StepLabel';
import { useTranslation }             from 'react-i18next';
import Step1                          from './Step1';
import StepStripe                     from './StepStripe';
import StepCreation                   from './StepCreation';
import { useLocation }                from 'react-router-dom';
import { connect }                    from 'react-redux';
import StepAddress                    from './StepAddress';

const Wizard = ({ hasStripe, hasLocation }) => {
	const location = useLocation();
	const [activeStep, setActiveStep] = useState(0);
	const { t } = useTranslation();
	const [stripeAccount, setStripeAccount] = useState(null);
	const [address, setAddress] = useState({});

	useEffect(() => {
		if (location.hash === '#stripe')
			setActiveStep(1);

		// eslint-disable-next-line
	}, []);

	const handleNext = () => {
		setActiveStep(prevActiveStep => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep(prevActiveStep => prevActiveStep - 1);
	};

	const getStepContent = () => {
		if (hasStripe) {
			switch (activeStep) {
				case 0:
					return <Step1 handleBack={handleBack} handleNext={handleNext}/>;
				case 1:
					if (!hasLocation)
						return <StepAddress handleBack={handleBack} handleNext={handleNext} address={address}
						                    setAddress={setAddress}/>;
				// eslint-disable-next-line
				case 2:
					return <StepCreation handleBack={handleBack} address={address} handleNext={handleNext}
					                     stripeAccount={stripeAccount} hasLocation={hasLocation}/>;
				default:
					return <div/>;
			}
		}

		switch (activeStep) {
			case 0:
				return <Step1 handleBack={handleBack} handleNext={handleNext}/>;
			case 1:
				return <StepStripe handleBack={handleBack} handleNext={handleNext} stripeAccount={stripeAccount}
				                   setStripeAccount={setStripeAccount}/>;
			case 2:
				if (!hasLocation)
					return <StepAddress handleBack={handleBack} handleNext={handleNext} address={address}
				                    setAddress={setAddress}/>;
			// eslint-disable-next-line
			case 3:
				return <StepCreation handleBack={handleBack} handleNext={handleNext}
				                     stripeAccount={stripeAccount} address={address} hasLocation={hasLocation}/>;
			default:

				return <div/>;
		}
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Stepper activeStep={activeStep} alternativeLabel>
					<Step>
						<StepLabel>{t('create_store_step_1_title')}</StepLabel>
					</Step>
					{!hasStripe &&
					<Step>
						<StepLabel>{t('create_store_step_stripe_title')}</StepLabel>
					</Step>
					}
					{!hasLocation && <Step>
						<StepLabel>{t('create_store_step_address')}</StepLabel>
					</Step>}
					<Step>
						<StepLabel>{t('create_store_step_creating_title')}</StepLabel>
					</Step>
				</Stepper>
			</Grid>
			<Grid item xs={12}>
				{getStepContent()}
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		hasStripe: state.realm.paymentController,
		hasLocation: state.template.hasLocation
	};
};

export default connect(mapStateToProps)(Wizard);
