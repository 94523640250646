import {USER_LOGOUT} from "./UserReducer";
import {COMMON_RESET} from "../CommonReducer";

const initState = () => {
    return {
        loadingLocation: false,
        location: null,

        creating: false,
        updating: false,
    }
}

export const USER_LOCATION_FETCH = 'USER_LOCATION_FETCH'
export const USER_LOCATION_FETCH_DONE = 'USER_LOCATION_FETCH_DONE'

export const USER_LOCATION_CREATE = 'USER_LOCATION_CREATE'
export const USER_LOCATION_CREATE_DONE = 'USER_LOCATION_CREATE_DONE'

export const USER_LOCATION_UPDATE = 'USER_LOCATION_UPDATE'
export const USER_LOCATION_UPDATE_DONE = 'USER_LOCATION_UPDATE_DONE'

const userLocationReducer = (state = initState(), action) => {
    switch (action.type) {
        case USER_LOGOUT:
        case COMMON_RESET:
            return initState();
        case USER_LOCATION_FETCH:
            return {...state, loadingLocation: true}
        case USER_LOCATION_FETCH_DONE:
            return {...state, loadingLocation: false, location: action.location}
        case USER_LOCATION_CREATE:
            return {...state, creating: true}
        case USER_LOCATION_CREATE_DONE:
            return {...state, creating: false}
        case USER_LOCATION_UPDATE:
            return {...state, updating: true}
        case USER_LOCATION_UPDATE_DONE:
            return {...state, updating: false, location: action.location}
        default:
            return state;
    }
}

export default userLocationReducer