import React, {useEffect}                           from 'react';
import {connect}                                    from 'react-redux';
import {membershipStats, setMembershipStatsFilters} from '../../../store/actions/StatsAction';
import Grid                                         from '@material-ui/core/Grid';
import {useTranslation}                             from 'react-i18next';
import NoFanclub                                    from './NoFanclub';
import MembershipPlansRepartition                   from './MembershipPlansRepartition';
import {Card, CardContent, Typography}              from '@material-ui/core';
import {BlackPaper}                                 from '../surface/Paper';
import makeStyles                                   from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
	uppercase: {
		textTransform: 'uppercase',
	},
});

const Membership = ({fanclub, fetch}) => {
	const {t} = useTranslation();
	const classes = useStyles();

	useEffect(() => {
		if (!fanclub) return;
		fetch(fanclub.Membership__);

	}, [fetch, fanclub]);


	if (!fanclub) {
		return (
			<Card component={BlackPaper} style={{height:'100%'}}>
				<CardContent>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Typography align="center" variant={'h6'} className={classes.uppercase}>
								{t('dashboard_membership_title')}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<NoFanclub/>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		);
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<MembershipPlansRepartition/>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		fanclub: state.membership.selectedFanclub,
		filters: state.stats.membershipStatsFilters,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: (membershipId) => dispatch(membershipStats(membershipId)),
		setFilters: (filters) => dispatch(setMembershipStatsFilters(filters))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Membership);