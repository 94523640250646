import React              from 'react';
import DialogContent      from '@material-ui/core/DialogContent';
import DialogActions      from '@material-ui/core/DialogActions';
import Dialog             from '@material-ui/core/Dialog';
import useMediaQuery      from '@material-ui/core/useMediaQuery';
import { useTheme }       from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Templates          from './Templates';
import Button from "../../inputs/Button";

const TemplateDialog = ({
	                        open,
	                        setOpen,
	                        cmsId,
	                        insertCallback = () => {
	                        }
                        }) => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const { t } = useTranslation();
	const handleClose = () => {
		setOpen(false);
	};

	const onInsert = (id, variation, src) => {
		insertCallback(id, variation, src);
		handleClose();
	};

	return (
		<Dialog
			open={open}
			aria-labelledby={t('template_gallery')}
			aria-describedby={t('template_gallery')}
			fullWidth
			fullScreen={fullScreen}
			maxWidth='lg'
		>
			<DialogContent>
				<Templates mode='insert' onInsert={onInsert} cmsId={cmsId}/>
			</DialogContent>

			<DialogActions>
				<Button color='secondary' autoFocus onClick={handleClose}>
					{t('close_btn')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};


export default TemplateDialog;
