import {rest} from "@karpeleslab/klbfw";
import {
    ORDER_FETCH_VAT,
    ORDER_FETCH_VAT_DONE, ORDER_MY_ORDER_FETCH, ORDER_MY_ORDER_FETCH_DONE,
    ORDER_MY_ORDERS_FETCH,
    ORDER_MY_ORDERS_FETCH_DONE,
    ORDER_MY_ORDERS_SET_FILTERS,
    ORDER_MY_ORDERS_SET_PAGING,
    ORDER_ORDER_FETCH,
    ORDER_ORDER_FETCH_DONE,
    ORDER_ORDER_REFUND,
    ORDER_ORDER_REFUND_DONE,
    ORDER_ORDERS_FETCH,
    ORDER_ORDERS_FETCH_DONE,
    ORDER_ORDERS_SET_FILTERS,
    ORDER_ORDERS_SET_PAGING
} from '../reducers/OrderReducer';
import {error, success} from "./system/ToastAction";
import {handleError} from "../../components/utils/apiErrorHandler";

export const fetchMyOrders = () => {
    return (dispatch, getState) => {
        dispatch({type: ORDER_MY_ORDERS_FETCH});

        const params = {
            ...getState().order.myOrdersFilters,
            ...getState().order.myOrdersPaging
        };

        return rest('Order', 'GET', params)
            .then(data => {
                dispatch({type: ORDER_MY_ORDERS_FETCH_DONE, orders: data.data, paging: data.paging});
                return data.data;
            }).catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const setMyOrdersFilters = (filters) => {
    return (dispatch, getState) => {
        dispatch({type: ORDER_MY_ORDERS_SET_FILTERS, filters: filters});
    }
};

export const setMyOrdersPaging = (paging) => {
    return (dispatch, getState) => {
        dispatch({type: ORDER_MY_ORDERS_SET_PAGING, paging: {...getState().order.myOrdersPaging, ...paging}});
    }
};

export const fetchOrders = () => {
    return (dispatch, getState) => {
        dispatch({type: ORDER_ORDERS_FETCH});

        const params = {
            ...getState().order.ordersFilters,
            ...getState().order.ordersPaging
        };

        return rest('Order:adminSearch', 'POST', params)
            .then(data => {
                dispatch({type: ORDER_ORDERS_FETCH_DONE, orders: data.data, paging: data.paging});
                return data.data;
            }).catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const setOrdersFilters = (filters) => {
    return (dispatch, getState) => {
        dispatch({type: ORDER_ORDERS_SET_FILTERS, filters: filters});
    }
};

export const setOrdersPaging = (paging) => {
    return (dispatch, getState) => {
        dispatch({type: ORDER_ORDERS_SET_PAGING, paging: {...getState().order.ordersPaging, ...paging}});
    }
};


export const fetchOrder = (orderId) => {
    return (dispatch, getState) => {
        dispatch({type: ORDER_ORDER_FETCH});
        return rest('Order/' + orderId)
            .then((data) => {
                dispatch({type: ORDER_ORDER_FETCH_DONE, order: data.data});
                return data.data;
            }).catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const fetchMyOrder = (orderId, silent = false) => {
    return (dispatch, getState) => {
        if(!silent) dispatch({type: ORDER_MY_ORDER_FETCH});
        return rest('Order/' + orderId)
            .then((data) => {
                dispatch({type: ORDER_MY_ORDER_FETCH_DONE, order: data.data});
                return data.data;
            }).catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const refreshMyOrder = (orderData) => {
    return (dispatch, getState) => {
        if(orderData) return dispatch({type: ORDER_MY_ORDER_FETCH_DONE, order: orderData});
        else return dispatch(fetchMyOrder(getState().order.myOrder.Order__, true));
    }
};

export const refundOrder = () => {
    return (dispatch, getState) => {
        dispatch({type: ORDER_ORDER_REFUND});
        return rest('Order/' + getState().order.order.Order__ + ':refund')
            .then((data) => {
                dispatch({type: ORDER_ORDER_REFUND_DONE, order: data.data});
                success('order_refund_success');
                return data.data;
            }).catch((err) => {
                error(err.message, false);
                handleError(getState, dispatch, err)
            })
    }
};

export const fetchVAT = () => {
    return (dispatch, getState) => {
        dispatch({type: ORDER_FETCH_VAT});
        return rest('Order/Vat')
            .then((data) => {
                dispatch({type: ORDER_FETCH_VAT_DONE, vat: data.data});
                return data.data;
            }).catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};