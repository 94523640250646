import React from 'react';
import {ExpiredChip, InactiveChip, PaidChip} from "./style";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import moment from "moment";
import OfflineBoltOutlinedIcon from "@material-ui/icons/OfflineBoltOutlined";
import OfflinePinOutlinedIcon from "@material-ui/icons/OfflinePinOutlined";
import {Tooltip} from "@material-ui/core";
import {useTranslation} from "react-i18next";

const MembershipStatus = ({membership}) => {
    const {t} = useTranslation();

    let chip = null;
    if (membership.Status === 'inactive')
        chip = <InactiveChip variant="outlined" icon={<HighlightOffOutlinedIcon/>} label={t('status_cancelled')}/>;
    else if (membership.Status === 'expired')
        chip = <ExpiredChip variant="outlined" icon={<OfflineBoltOutlinedIcon/>} label={t('status_expired')}/>;
    else
        chip = <PaidChip variant="outlined" icon={<OfflinePinOutlinedIcon/>} label={t('status_paid')}/>;

    return <Tooltip title={moment(parseInt(membership.Expires.unixms)).format('LLL')} placement="bottom"
                    arrow>{chip}</Tooltip>
};

export default MembershipStatus;
