import React, {useEffect, useState} from 'react';
import {Grid}                       from '@material-ui/core';
import {useTranslation}             from 'react-i18next';
import Tabs                         from '@material-ui/core/Tabs';
import useMediaQuery                from '@material-ui/core/useMediaQuery';
import PaymentMethodTab             from './PaymentMethodTab';
import PaymentMethodContent         from './PaymentMethodContent';
import Loading                      from '../../../feeback/loading/Loading';
import {Title}                      from '../../../typography/Title';
import {Alert}                      from '@material-ui/lab';

const OrderPayments = ({processResult, processingOrder, refresh, setDialogActions = null, onPaying = undefined}) => {
	const {t} = useTranslation();

	const [paying, setPaying] = useState(false);

	const [paymentMethod, setPaymentMethod] = useState();

	const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

	const handleTabChange = (event, newValue) => {
		setPaymentMethod(newValue);
	};

	useEffect(() => {
		if(paying && onPaying) onPaying()
	}, [paying, onPaying])

	useEffect(() => {
		if (paymentMethod || !processResult) return;

		// Select default payment method
		if (processResult.methods_order.includes('OnFile')) {
			setPaymentMethod('OnFile');
		} else {
			setPaymentMethod(processResult.methods_order[0]);
		}
		// eslint-disable-next-line
	}, [processResult, setPaymentMethod]);

	if (processingOrder || !processResult) {
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}> <Loading/></Grid>
			</Grid>
		);
	}

	if (processResult.methods_order.length < 1) {
		return (
			<Grid container spacing={3} justify="center" alignItems="center">
				<Grid item>
					<Alert severity="info">
						{t('order_no_payment_methods')}
					</Alert>
				</Grid>
			</Grid>
		);
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Title>
					{t('order_payment_methods')}
				</Title>
			</Grid>

			<Grid item xs={12}>
				<Tabs
					variant="scrollable"
					scrollButtons={isMobile ? 'on' : 'auto'}
					value={paymentMethod}
					indicatorColor="primary"
					textColor="primary"
					onChange={handleTabChange}
				>
					{processResult.methods_order.map(method => <PaymentMethodTab
						method={method}
						key={method}
						value={method}
						disabled={processingOrder || paying}
					/>)}
				</Tabs>
			</Grid>

			<Grid item xs={12}>
				{processResult.methods_order.map(method => <PaymentMethodContent
					method={method}
					methodData={processResult.methods[method]}
					key={`${method}_content_${processResult.order.Status}`}
					selectedPaymentMethod={paymentMethod}
					setPaying={setPaying}
					order={processResult.order}
					refreshOrder={refresh}
					setDialogActions={setDialogActions}
				/>)}
			</Grid>
		</Grid>
	);
};

export default OrderPayments;