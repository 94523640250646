import {rest}                              from '@karpeleslab/klbfw';
import {handleError}                       from '../../../components/utils/apiErrorHandler';
import {COUNTRY_FETCH, COUNTRY_FETCH_DONE} from '../../reducers/system/CountryReducer';

export const fetch = () => {
	return (dispatch, getState) => {
		if (getState().country.countries.length > 0) return; // countries won't changes do not reload them
		dispatch({type: COUNTRY_FETCH});
		return rest('Country')
			.then(data => {
				dispatch({type: COUNTRY_FETCH_DONE, countries: data.data});
				return data.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});

	};
};

export const zipLookup = (country, zip) => {
	return (dispatch, getState) => {
		return rest('Country/' + country + ':zip', 'POST', {zip: zip})
			.then(data => {
				return data.data;
			})
			.catch((err) => {

			});
	}
};

export const provincesLookup = (country) => {
	return (dispatch, getState) => {
		return rest('Country/' + country + ':getProvinces', 'POST')
			.then(data => {
				return data.data;
			})
			.catch((err) => {
				return [];
			});
	}
};