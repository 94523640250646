import withStyles from "@material-ui/core/styles/withStyles";
import MuiTableContainer from "@material-ui/core/TableContainer/TableContainer";
import styledComponents from "styled-components";
import {styled} from "@material-ui/core";

export const TableContainer = withStyles(theme => {
    return {
        root: {
            overflow: 'auto',
            maxHeight: 300,
        },
    }
})(MuiTableContainer);

const Div = styledComponents.span``;

export const ColoredIcon = styled(Div)(style => {
    return {
        display: 'inline-block',
        width: '10px',
        height: '20px',
        marginRight: '5px',
        backgroundColor: style.color,
    }
});