import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux'
import {useTranslation} from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle                                      from "@material-ui/core/DialogTitle";
import DialogContent                                    from "@material-ui/core/DialogContent";
import Grid                                             from "@material-ui/core/Grid";
import DialogActions                                    from "@material-ui/core/DialogActions";
import Button                                           from "../../../inputs/Button";
import HasRight                                         from "../../../../Route/HasRight";
import TextField                                        from "@material-ui/core/TextField";
import {createQuestion, fetchQuestions, updateQuestion} from "../../../../../store/actions/planner/QuestionAction";
import QuestionVisibleSelector                          from "../../../inputs/QuestionVisibleSelector";
import QuestionTypeSelector                             from "../../../inputs/QuestionTypeSelector";
import QuestionOptions                                  from "../../../inputs/QuestionOptions";
import FormRequiredField                                from '../../../feeback/FormRequiredField';

const ManageDialog = ({open, setOpen, event, question = null, creating, updating, updateQuestion, createQuestion, refreshingQuestions, refreshQuestions}) => {
    const {t} = useTranslation();
    const [saveEnabled, setSaveEnabled] = useState(false);

    const [label, setLabel] = useState(question ? question.Question : '');
    const [visible, setVisible] = useState(question ? question.Visible : '');
    const [index, setIndex] = useState(question ? question.Index : '');
    const [type, setType] = useState(question ? question.Type : '');
    const [options, setOptions] = useState(question ? question.Options : []);

    const resetFields = () => {
        setLabel('');
        setVisible('');
        setIndex('');
        setType('');
        setOptions([]);
    }

    useEffect(() => {
        setLabel(question ? question.Question : '');
        setVisible(question ? question.Visible : '');
        setIndex(question ? question.Index : '');
        setType(question ? question.Type : '');
        setOptions(question ? question.Options : []);

    }, [question, setLabel, setVisible, setIndex, setType, setOptions]);

    // create case
    useEffect(() => {
        if (question) return;
        if (!label.trim() || !visible || index === '' || index === null || !type) {
            setSaveEnabled(false);
            return;
        }

        if (type === 'select' && options.length < 1) {
            setSaveEnabled(false);
            return;
        }

        setSaveEnabled(true);

    }, [setSaveEnabled, question, label, visible, index, type, options]);

    // update case
    useEffect(() => {
        if (!question) return;
        if (!label.trim() || !visible || index === '' || index === null || !type) {
            setSaveEnabled(false);
            return;
        }

        if (type === 'select' && options.length < 1) {
            setSaveEnabled(false);
            return;
        }

        if (label.trim() !== question.Question ||
            visible !== question.Visible ||
            index !== question.Index ||
            type !== question.Type) {
            setSaveEnabled(true);
            return;
        }

        if (options.length !== question.Options.length) {
            setSaveEnabled(true);
            return;
        }

        for (let i = 0; i < options.length; i++) {
            if (options[i] !== question.Options[i]) {
                setSaveEnabled(true);
                return;
            }
        }

        setSaveEnabled(false)
    }, [setSaveEnabled, question, label, visible, index, type, options]);

    const handleClose = () => {
        setOpen(false);
        if(!question) resetFields();
    };

    const handleCreate = () => {
        createQuestion(event.Planner_Event__, label, type, visible, index, type === 'string' ? null : options)
            .then(e => refreshQuestions(event.Planner_Event__))
            .then(resetFields)
            .then(handleClose)
    };

    const handleUpdate = () => {
        const data = {
            Question: label,
            Type: type,
            Visible: visible,
            Index: index,
            Options: type === 'string' ? null : options
        };

        updateQuestion(question.Planner_Event_Question__, data)
            .then(e => refreshQuestions(event.Planner_Event__))
            .then(handleClose)
    };

    const handleSave = (e) => {
        e.preventDefault();
        if (question) handleUpdate();
        else handleCreate();
    };


    return (
        <Dialog
            open={open}
            aria-labelledby={question ? t('update_question') : t('create_question')}
            aria-describedby={question ? t('update_question') : t('create_question')}
            fullWidth
        >
            <form onSubmit={handleSave}>
                <DialogTitle
                    id="manage-question-title">{question ? t('update_question') : t('create_question')}</DialogTitle>
                <DialogContent>

                    <Grid container spacing={2}>
                        <FormRequiredField/>
                        <Grid item xs={12}>
                            <TextField label={t('question_label')} fullWidth variant="outlined" value={label}
                                       required
                                       disabled={creating || updating || refreshingQuestions}
                                       onChange={e => setLabel(e.target.value)}
                                       helperText={t('question_label_helperText')}/>
                        </Grid>

                        <Grid item xs={6}>
                            <QuestionVisibleSelector required value={visible} setValue={setVisible}
                                                     disabled={creating || updating || refreshingQuestions}/>
                        </Grid>

                        <Grid item xs={6}>
                            <QuestionTypeSelector required value={type} setValue={setType}
                                                  disabled={creating || updating || refreshingQuestions}/>
                        </Grid>


                        <Grid item xs={12} md={6}>
                            <TextField label={t('question_index')} fullWidth variant="outlined" value={index}
                                       required
                                       disabled={creating || updating || refreshingQuestions}
                                       onChange={e => setIndex(e.target.value)}
                                       helperText={t('question_index_helperText')}
                                       type='number'
                                       InputProps={
                                           {
                                               min: 0
                                           }
                                       }/>
                        </Grid>

                        {type === 'select' &&
                        <Grid item xs={12}>
                            <QuestionOptions
                                setValue={setOptions}
                                value={options}
                                disabled={creating || updating || refreshingQuestions}
                                required
                            />
                        </Grid>
                        }


                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="default" onClick={handleClose}
                            disabled={creating || updating || refreshingQuestions}>
                        {t('close_btn')}
                    </Button>
                    <HasRight manager>
                        <Button color="primary" variant='contained'
                                loading={creating || updating || refreshingQuestions}
                                disabled={creating || updating || refreshingQuestions || !saveEnabled}
                                type='submit'>
                            {question ? t('save_btn') : t('create_btn')}
                        </Button>
                    </HasRight>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {
        creating: state.question.creating,
        updating: state.question.updating,
        refreshingQuestions: state.question.questionsLoading,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        refreshQuestions: (eventId) => dispatch(fetchQuestions(eventId)),
        createQuestion: (eventId, label, type, visible, index, options) => dispatch(createQuestion(eventId, label, type, visible, index, options)),
        updateQuestion: (questionId, data) => dispatch(updateQuestion(questionId, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageDialog);