import React, { Fragment, useEffect } from 'react';
import TableHead                      from '@material-ui/core/TableHead';
import MaterialTable                  from '@material-ui/core/Table';
import TableRow                       from '@material-ui/core/TableRow';
import TableCell                      from '@material-ui/core/TableCell';
import TableBody                      from '@material-ui/core/TableBody';
import TableContainer                 from '@material-ui/core/TableContainer';
import Row                            from './Row';
import { connect }                    from 'react-redux';
import TableFooter                    from '@material-ui/core/TableFooter';
import { useTranslation }             from 'react-i18next';
import Empty                          from './Empty';
import {
	fetchDeliverableProducts,
	setDeliverableProductsFilters,
	setDeliverableProductsPaging
}                                     from '../../../../../../store/actions/store/DeliverableAction';
import Pagination                     from '../../../../data/table/Pagination';

const Table = ({ deliverable, deliverableProducts, fetch, loading, filters, paging, count, setDeliverableProductsPaging, setDeliverableProductsFilters }) => {
	const loadingData = [...Array(paging.results_per_page).keys()];
	const { t } = useTranslation();
	const displayLoading = () => loadingData.map((k) => <Row
		key={k}
		loading={true}
		product={null}
	/>);

	const displayDeliverableProducts = () => deliverableProducts.map((u, idx) => <Row
		key={u.Catalog_Deliverable_Product__}
		loading={false}
		product={u.Catalog_Product}/>);

	useEffect(() => {
		fetch(deliverable.Catalog_Deliverable__);
	}, [fetch, filters, paging, deliverable]);

	/*const {sort} = filters;

	const sortHandler = (property, dir) => {
		let p = {...filters};
		// only 1 sort
		p.sort = {};
		p.sort[property] = dir;
		setRecordsFilters(p);
	};*/

	return (
		<Fragment>
			{(loading || deliverableProducts.length > 0) &&
			<TableContainer>
				<MaterialTable aria-label='simple table'>
					<TableHead>
						<TableRow>
							<Pagination
								setPaging={setDeliverableProductsPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
						<TableRow>
							<TableCell style={{ width: '75px' }}> {t('tablecell_product_picture')}</TableCell>
							<TableCell> {t('tablecell_product_internal_name')}</TableCell>
							<TableCell> {t('tablecell_product_name')}</TableCell>
							<TableCell align='right'/>
						</TableRow>
					</TableHead>
					<TableBody>
						{loading && displayLoading()}
						{!loading && displayDeliverableProducts()}
					</TableBody>
					<TableFooter>
						<TableRow>
							<Pagination
								setPaging={setDeliverableProductsPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
					</TableFooter>

				</MaterialTable>
			</TableContainer>
			}
			{(!loading && deliverableProducts.length < 1) && <Empty/>}
		</Fragment>
	);

};


const mapStateToProps = (state) => {
	return {
		filters: state.deliverable.deliverableProductsFilters,
		paging: state.deliverable.deliverableProductsPaging,
		count: state.deliverable.deliverableProductsPagingCount,
		loading: state.deliverable.deliverableProductsLoading,
		deliverableProducts: state.deliverable.deliverableProducts,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: (id) => dispatch(fetchDeliverableProducts(id)),
		setDeliverableProductsPaging: (newPaging) => dispatch(setDeliverableProductsPaging(newPaging)),
		setDeliverableProductsFilters: (newFilters) => dispatch(setDeliverableProductsFilters(newFilters)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);

