import React, {useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {connect} from 'react-redux'
import {Trans, useTranslation} from 'react-i18next';
import {fetchShippings, setSelectedShippings, shippingUngroup} from "../../../../store/actions/store/ShippingAction";
import Button from "../../inputs/Button";
import RichAlert from "../../feeback/RichAlert";
import {getSiteOrderEditRoute} from "../../../../router/routes/site/factory/order";
import GoodsTable from "./table/GoodsTable";
import Link from "../../typography/Link";

const UngroupDialog = ({open, setOpen, ungrouping, shippingUngroup, shipping, refresh, refreshing, resetSelected}) => {
    const {t} = useTranslation();

    const [ungroupCount, setUngroupCount] = useState(0);
    const [order, setOrder] = useState('');

    const [selected, setSelected] = useState({})

    const onSelectionChange = (checked, id, item) => {
        if (checked) {
            setSelected({...selected, [id]: item});
            return
        }

        const copy = {...selected};
        delete copy[id];
        setSelected(copy);
    }


    useEffect(() => {
        setUngroupCount(Object.keys(selected).length)
        setOrder(shipping ? shipping.Order.Invoice_Number : '')

    }, [selected, setUngroupCount, setOrder, shipping])

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = (e) => {
        e.stopPropagation();
        e.preventDefault();

        const toIds = Object.keys(selected);

        shippingUngroup(shipping.Catalog_Shipping__, toIds)
            .then(refresh)
            .then(resetSelected)
            .then(handleClose);
    };

    return (
        <Dialog
            open={open}
            maxWidth='lg'
            fullWidth
            aria-labelledby={t('shipping_ungroup_title')}
            aria-describedby={t('shipping_ungroup_title')}
        >
            <form onSubmit={handleSubmit}>
                <DialogTitle
                    id="group-title">{t('shipping_ungroup_title')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="ungroup-description">
                        <RichAlert severity='info'>
                            <Trans i18nKey={'shipping_ungroup_desc'}>
                                sample<br/>
                                <strong>sample</strong>
                                <Link target='_blank'
                                      to={getSiteOrderEditRoute(shipping ? shipping.Order__ : '')}>{{order}}</Link>
                                {{ungroupCount}}
                            </Trans>
                        </RichAlert>
                    </DialogContentText>

                    <GoodsTable
                        showUngroupBtn={false}
                        shipping={shipping}
                        selectable={true}
                        selected={selected}
                        onSelectionChange={onSelectionChange}
                    />

                </DialogContent>
                <DialogActions>
                    <Button color="default" onClick={handleClose} disabled={refreshing || ungrouping}>
                        {t('close_btn')}
                    </Button>
                    <Button color="primary" variant='contained' loading={refreshing || ungrouping}
                            disabled={
                                refreshing ||
                                ungrouping ||
                                Object.keys(selected).length < 1 ||
                                Object.keys(selected).length === shipping.Items.length
                            }
                            type='submit'
                    >
                        {t('shipment_ungroup')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {
        refreshing: state.shipping.shippingsLoading,
        ungrouping: state.shipping.ungrouping,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        refresh: () => dispatch(fetchShippings()),
        shippingUngroup: (id, ids) => dispatch(shippingUngroup(id, ids)),
        resetSelected: () => dispatch(setSelectedShippings({}))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UngroupDialog);