import React, {useState} from 'react';
import {TextField}       from '@material-ui/core';
import PickerDialog      from './PickerDialog';
import InputAdornment    from '@material-ui/core/InputAdornment';
import Box               from '@material-ui/core/Box';

const ColorPicker = ({
	                     // ColorPicker
	                     value,
	                     setValue,

	                     ...rest
                     }) => {
	const [showPicker, setShowPicker] = useState(false);

	return (
		<>
			<TextField
				onClick={() => setShowPicker(true)}
				value={value}
				InputProps={
					{
						endAdornment:
							(<InputAdornment position="end">
									{value && <Box bgcolor={value} p={2}/>}
							</InputAdornment>)
					}
				}
				{...rest}
			/>
			{showPicker && (
				<PickerDialog
					value={value}
					onClick={() => {
						setShowPicker(false);
					}}
					onChange={c => {
						setValue(c.hex);
					}}
				/>
			)}
		</>
	);
};

export default ColorPicker;