import React              from 'react';
import MaterialTable      from '@material-ui/core/Table';
import { useTranslation } from 'react-i18next';
import TableContainer     from '@material-ui/core/TableContainer';
import TableHead          from '@material-ui/core/TableHead';
import TableRow           from '@material-ui/core/TableRow';
import TableCell          from '@material-ui/core/TableCell';
import TableBody          from '@material-ui/core/TableBody';
import Row                from './Row';
import { connect }        from 'react-redux';
import Loading            from '../../../feeback/loading/Loading';

const Table = ({ collaborators, onDelete, artist, type = 'Record', disabled = false, isReview= false}) => {
	const { t } = useTranslation();
	if (!artist) return <Loading/>;
	return (
		<TableContainer>
			<MaterialTable>
				<TableHead>
					<TableRow>
						<TableCell> {t('tablecell_collaborator_name')}</TableCell>
						<TableCell> {t('tablecell_collaborator_role')}</TableCell>
						{!isReview && <TableCell align='right'/>}
					</TableRow>
				</TableHead>
				<TableBody>
					{
						collaborators.map((collaborator) => {
							const deletable = !(collaborator.Music_Label_Artist__ === artist.Music_Label_Artist__ && collaborator.Role === 'artist');
							return (<Row
								isReview={isReview}
								deletable={deletable && !disabled}
								key={collaborator[`Music_Label_${type}_Artist__`]}
								collaborator={collaborator} onDelete={onDelete}
							/>);
						})
					}
				</TableBody>

			</MaterialTable>
		</TableContainer>
	);
};


const mapStateToProps = (state) => {
	return {
		artist: state.artist.artist
	};
};

export default connect(mapStateToProps)(Table);
