import React from 'react';
import TableRow from "@material-ui/core/TableRow";
import Skeleton from "@material-ui/lab/Skeleton";
import TableCell from "@material-ui/core/TableCell";
import EditIcon from "@material-ui/icons/Edit";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import Chip from "@material-ui/core/Chip";
import {getSiteScheduleEditRoute} from "../../../../../router/routes/site/factory/planner";
import Button from "../../../inputs/Button";

const Row = ({schedule, loading}) => {
    const {t} = useTranslation();
    const displaySchedule = (!loading && schedule);
    return (
        <TableRow>
            <TableCell>
                {!displaySchedule && <Skeleton variant="circle" width={40} height={40}/>}
                {(displaySchedule && schedule.Visible === 'Y') && <VisibilityIcon/>}
                {(displaySchedule && schedule.Visible === 'N') && <VisibilityOffIcon/>}
            </TableCell>
            <TableCell>
                {!displaySchedule && <Skeleton/>}
                {displaySchedule && <Grid container direction='column'>
                    <Typography variant='h6'>
                        {moment(parseInt(schedule.Date.unixms)).format('LL')}
                    </Typography>
                    <Typography variant='subtitle1'>
                        {moment(parseInt(schedule.Date.unixms)).format('LT')}
                    </Typography>
                </Grid>}
            </TableCell>
            <TableCell>
                {!displaySchedule && <Skeleton/>}
                {(displaySchedule) && <span>{schedule.Content_Cms_Entry_Data.Title}</span>}
            </TableCell>
            <TableCell>
                {!displaySchedule && <Skeleton/>}
                {(displaySchedule && schedule.Tags) &&
                <Grid container spacing={3}>
                    {schedule.Tags.map(t => <Grid item><Chip variant='outlined' color='primary' label={t.Name}/></Grid>)}
                </Grid>
                }
            </TableCell>
            <TableCell align="right">
                {!displaySchedule && <Skeleton/>}
                {(displaySchedule) && <Button
                    variant="contained"
                    color="primary"
                    startIcon={<EditIcon/>}
                    component={Link} to={getSiteScheduleEditRoute(schedule.Planner_Schedule__)}
                >
                    {t('edit_btn')}
                </Button>}

            </TableCell>
        </TableRow>
    );
};

export default Row;