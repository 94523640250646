import { USER_LOGOUT }  from '../system/UserReducer';
import { COMMON_RESET } from '../CommonReducer';

const initState = () => {
	return {
		lyricsLoading: false,
		lyrics: [],
		updating: false,
	};
};

export const LYRICS_FETCH = 'LYRICS_FETCH';
export const LYRICS_FETCH_DONE = 'LYRICS_FETCH_DONE';

export const LYRICS_UPDATE = 'LYRICS_UPDATE';
export const LYRICS_UPDATE_DONE = 'LYRICS_UPDATE_DONE';

const lyricsReducer = (state = initState(), action) => {
	switch (action.type) {
		case USER_LOGOUT:
		case COMMON_RESET:
			return initState();
		case LYRICS_FETCH:
			return { ...state, lyricsLoading: true };
		case LYRICS_FETCH_DONE:
			return { ...state, lyricsLoading: false, lyrics: action.lyrics };
		case LYRICS_UPDATE:
			return { ...state, updating: true };
		case LYRICS_UPDATE_DONE:
			if (!action.lyrics) return { ...state, updating: false };
			return { ...state, updating: false, lyrics: action.lyrics };
		default:
			return state;
	}

};

export default lyricsReducer;
