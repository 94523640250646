import React, { useEffect, useState } from 'react';
import { connect }                    from 'react-redux';
import { updateSupport }              from '../../../store/actions/SupportAction';
import { useTranslation }             from 'react-i18next';
import Alert                          from '@material-ui/lab/Alert';
import Button                         from '../inputs/Button';
import { Grid, Toolbar }              from '@material-ui/core';
import { Title }                      from '../typography/Title';
import Switch                         from '@material-ui/core/Switch';
import FormControlLabel               from '@material-ui/core/FormControlLabel';
import TextField                      from '@material-ui/core/TextField';

const Settings = ({ support, updateSupport, updating }) => {
	const { t } = useTranslation();
	const [enabled, setEnabled] = useState(support.Ticket_Notify ?? 'N');
	const [name, setName] = useState(support.Name);
	const [saveEnabled, setSaveEnabled] = useState(false);

	useEffect(() => {
		setEnabled(support.Ticket_Notify);
	}, [support, setEnabled]);

	useEffect(() => {
		setSaveEnabled(support.Ticket_Notify !== enabled || name !== support.Name);
	}, [support, enabled,name, setSaveEnabled]);

	const handleSave = () => {
		updateSupport({
			Ticket_Notify: enabled,
			Name: name,
		});
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Toolbar>
					<Title>{t('support_settings_title')}</Title>
				</Toolbar>
			</Grid>
			<Grid item xs={12}>
				<Alert severity='info'>
					{t('support_notif_helperText')}
				</Alert>
			</Grid>
			<Grid item xs={12}>
				<FormControlLabel
					control={
						<Switch
							disabled={updating}
							color='primary'
							checked={enabled === 'Y'}
							onChange={e => setEnabled(e.target.checked ? 'Y' : 'N')}
						/>
					}
					label={enabled === 'Y' ? t('support_notif_label_enabled') : t('support_notif_label_disabled' )}
					labelPlacement='start'
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					fullWidth
					variant='outlined'
					value={name}
					onChange={e => setName(e.target.value)}
					label={t('support_from_name')}
					helperText={t('support_from_name_helperText')}
				/>
			</Grid>
			<Grid item xs={12}>
				<Grid container justify='flex-end'>
					<Button
						loading={updating}
						color='primary'
						variant='contained'
						onClick={handleSave}
						disabled={!saveEnabled || updating}>
						{t('save_btn')}
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		updating: state.support.updating
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateSupport: (data) => dispatch(updateSupport(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
