import React                  from 'react';
import TableRow               from "@material-ui/core/TableRow";
import Skeleton               from "@material-ui/lab/Skeleton";
import TableCell              from "@material-ui/core/TableCell";
import {useTranslation}       from "react-i18next";
import Typography             from "@material-ui/core/Typography";
import {getSiteUserEditRoute} from "../../../../../../router/routes/site/factory/user";
import Button                 from "../../../../inputs/Button";
import Link                   from "../../../../typography/Link";
import {Chip}                 from '@material-ui/core';

const Row = ({reserve, onSelected, loading}) => {
    const {t} = useTranslation();
    const displayReserve = (!loading && reserve);

    return (
        <TableRow>
            <TableCell>
                {!displayReserve && <Skeleton/>}
                {(displayReserve) && <span>{reserve.Serial}</span>}
            </TableCell>
            <TableCell>
                {!displayReserve && <Skeleton/>}
                {(displayReserve) && <span>
                    <Link to={getSiteUserEditRoute(reserve.User__)} target='_blank'>
                     <Typography variant="subtitle2">
                         {reserve.Name}
                     </Typography>
                    </Link>

                    {reserve.Name_Kana &&
                    <Typography variant="body2" color="textSecondary">
                        {reserve.Name_Kana}
                    </Typography>
                    }

                </span>}
            </TableCell>
            <TableCell>
                {!displayReserve && <Skeleton/>}
                {(displayReserve) && <span>{reserve.Tel}</span>}
            </TableCell>
            <TableCell>
                {!displayReserve && <Skeleton/>}
                {(displayReserve) &&
                <Button variant='outlined' onClick={e => {e.preventDefault(); onSelected(reserve.Planner_Event_Seat)}}>
                    {reserve.Planner_Event_Seat.Seat_Name}
                </Button>}
            </TableCell>
            <TableCell>
                {!displayReserve && <Skeleton/>}
                {(displayReserve) && <span>{t('reserve_status_' + reserve.Status)}</span>}
            </TableCell>
            <TableCell>
                {!displayReserve && <Skeleton/>}
                {(displayReserve) && <span>
                    {reserve.Used === 'Y' ?
                        <Chip variant='outlined' color='primary' label={t('ticket_used')}/> :
                        <Chip variant='outlined' color='secondary' label={t('ticket_unused')}/>
                    }
                </span>}
            </TableCell>
            <TableCell>
                {!displayReserve && <Skeleton/>}
                {(displayReserve) && <span>{reserve.Allocated_Seat ?? t('allocated_seat_not_set')}</span>}
            </TableCell>
        </TableRow>
    );
};

export default Row;