import React              from 'react';
import { useTranslation } from 'react-i18next';
import Select             from './Select';

const TicketRequiresPaymentSelector = ({ value, setValue, disabled, required }) => {
	const { t } = useTranslation();

	return <Select
		label={t('ticket_requires_payment')}
		helperText={t('ticket_requires_payment_helperText')}
		value={value}
		onChange={e => setValue(e.target.value)}
		optionKey='Key'
		optionValue='Value'
		allowEmpty={false}
		options={[
			{ Key: 'Y', Value: t('ticket_requires_payment_y') },
			{ Key: 'N', Value: t('ticket_requires_payment_n') },
		]}
		inputProps={{
			native: true,
			disabled: disabled,
			variant: 'outlined',
			fullWidth: true,
			required: required
		}}
	/>;
};

export default TicketRequiresPaymentSelector;
