import React, { useCallback, useEffect, useState }                          from 'react';
import { Link, useHistory }                                                 from 'react-router-dom';
import { getText }                                                          from '../../../../store/actions/TextAction';
import { connect }                                                          from 'react-redux';
import Grid                                                                 from '@material-ui/core/Grid';
import { hasChanged as nameHasChanged, requiredCheck as nameRequiredCheck } from '../../../utils/i18nInput';
import Loading                                                              from '../../feeback/loading/Loading';
import InputI18N                                                            from '../../inputs/InputI18n';
import { useTranslation }                                                   from 'react-i18next';
import Language                                                             from '../../inputs/Language';
import { TextField }                                                        from '@material-ui/core';
import Ivk                                                                  from '../../inputs/Ivk';
import Flags                                                                from '../../inputs/Flags';
import { Title }                                                            from '../../typography/Title';
import Alert                                                                from '@material-ui/lab/Alert';
import FormControl                                                          from '@material-ui/core/FormControl';
import InputLabel                                                           from '@material-ui/core/InputLabel';
import Input                                                                from '@material-ui/core/Input';
import FormHelperText                                                       from '@material-ui/core/FormHelperText';
import Collaborators                                                        from './collaborator/Collaborators';
import {
	createRecord,
	updateRecord
}                                                                           from '../../../../store/actions/discography/RecordAction';
import uuid             from '../../../utils/uuid';
import Button             from '../../inputs/Button';
import DeleteDialog           from './DeleteDialog';
import MusicGenre             from '../../inputs/MusicGenre';
import Audio                  from '../../data/player/Audio';
import {
	getMusicRecordEditRoute,
	getMusicRecordLyricsRoute
}                             from '../../../../router/routes/music/factory/records';
import Video                  from '../../data/player/Video';
import HasRight               from '../../../Route/HasRight';
import RecordAlbumTable       from './albums/RecordAlbumTable';
import RecordLiveSelector     from '../../inputs/RecordLiveSelector';
import RecordExplicitSelector from '../../inputs/RecordExplicitSelector';
import MusicMood              from '../../inputs/MusicMood';
import FormRequiredField      from '../../feeback/FormRequiredField';

const Form = ({ artist, record, getText, creating, updating, createRecord, updateRecord, deleting, isAdmin, isManager }) => {
	const { t }                                   = useTranslation();
	const [name, setName]                         = useState({});
	const [needDetailedName, setNeedDetailedName] = useState(false);
	const [nameText, setNameText]                 = useState();
	const [lang, setLang]                         = useState('');
	const [isrc, setIsrc]                         = useState('');
	const [ivk, setIvk]                           = useState('');
	const [flags, setFlags]                       = useState([]);
	const [genre, setGenre]                       = useState();
	const [mood, setMood]                         = useState();
	const [previewStart, _setPreviewStart]        = useState(0);

	const [deleteDialogOpened, setDeleteDialogOpened] = useState(false);

	const setPreviewStart = useCallback((v) => {
		if (v < 0) _setPreviewStart(0);
		else _setPreviewStart(v);
	}, [_setPreviewStart]);

	const [collaborators, setCollaborators] = useState([
		                                                   { // Force me in the artists
			                                                   Music_Label_Record_Artist__: uuid(),
			                                                   Music_Label_Artist__:        artist.Music_Label_Artist__,
			                                                   Music_Label_Artist:          artist,
			                                                   Role:                        'artist'
		                                                   }
	                                                   ]);

	const [originalCollaboratorMap, setOriginalCollaboratorMap] = useState({});
	const [collaboratorEdited, setCollaboratorEdited]           = useState(false);

	const [isReleased, setIsReleased] = useState(false);
	const [file, setFile]             = useState(undefined);

	const controlDisabled = creating || updating || deleting || !isManager;

	const [saveDisabled, setSaveDisabled] = useState(true);

	const [loaded, setLoaded] = useState(!record);

	const history = useHistory();

	// Initialise data, Get text and set internal State for the edit mode
	useEffect(() => {
		if (!record) return;

		const promises = [];
		promises.push(getText(record.Name_Text__)
			              .then(text => {
				                    setNameText(text);
				                    setName(text.Values);
			                    }
			              ));

		setLang(record.Language__ ? record.Language__ : '');
		setIvk(record.IVK ? record.IVK : '');
		setIsrc(record.ISRC ? record.ISRC : '');
		setIsReleased(record.Status === 'released');
		setCollaborators(record.Artists);
		setGenre(record.Music_Genre);
		setMood(record.Music_Mood ?? null);

		const map = {};
		record.Artists.forEach(c => {
			if (!map[c.Music_Label_Artist__]) map[c.Music_Label_Artist__] = {};
			map[c.Music_Label_Artist__][c.Role] = true;
		});

		setOriginalCollaboratorMap(map);

		setPreviewStart(parseInt(record.Preview_Start));
		setFlags(Object.keys(record.Flags));

		Promise.all(promises).then(r => setLoaded(true));
	}, [record, getText, setLang, setIvk, setCollaborators, setGenre, setPreviewStart, setFlags, setOriginalCollaboratorMap, setMood]);

	// Used to enable/disable the save btn in edit mode
	useEffect(() => {
		if (!loaded) {
			setSaveDisabled(true);
			return;
		}
		if (!record) return;
		// Check minimum required field
		if (!nameRequiredCheck(name, needDetailedName)) {
			setSaveDisabled(true);
			return;
		}

		if (!genre) {
			setSaveDisabled(true);
			return;
		}

		if (record.Music_Genre__ && genre.Music_Genre__ !== record.Music_Genre__) {
			setSaveDisabled(false);
			return;
		}

		if (record.Music_Mood__ !== (mood ? mood.Music_Mood__ : null)) {
			setSaveDisabled(false);
			return;
		}

		if (!record.Music_Genre__) {
			setSaveDisabled(false);
			return;
		}

		if (nameHasChanged(nameText.Values, name)) {
			setSaveDisabled(false);
			return;
		}

		if (ivk.trim() !== record.IVK ||
			isrc.trim() !== (record.ISRC ? record.ISRC : '') ||
			lang !== (record.Language__ ? record.Language__ : '')
		) {
			setSaveDisabled(false);
			return;
		}

		if (previewStart !== parseInt(record.Preview_Start)) {
			setSaveDisabled(false);
			return;
		}

		if (collaboratorEdited) {
			setSaveDisabled(false);
			return;
		}

		const originalFlags = Object.keys(record.Flags);

		// Really poorly optimized !!!
		if (flags.length !== originalFlags.length) {
			setSaveDisabled(false);
			return;
		}

		for (let i = 0; i < originalFlags.length; i++) {
			if (flags.includes(originalFlags[i])) continue;
			setSaveDisabled(false);
			return;
		}

		setSaveDisabled(true);

	}, [
		          collaboratorEdited,
		          collaborators,
		          previewStart,
		          genre,
		          loaded,
		          record,
		          nameText, name, needDetailedName,
		          lang, isrc, flags, ivk, mood,
		          file

	          ]);

	useEffect(() => {
		if (!record) return;
		// Really poorly optimized !!!
		if (collaborators.length !== record.Artists.length) {
			setCollaboratorEdited(true);
			return;
		}

		for (let i = 0; i < collaborators.length; i++) {
			if (!(collaborators[i].Music_Label_Artist__ in originalCollaboratorMap)) {
				setCollaboratorEdited(true);

				return;
			}

			if (!(collaborators[i].Role in originalCollaboratorMap[collaborators[i].Music_Label_Artist__])) {
				setCollaboratorEdited(true);
				return;
			}
		}

		setCollaboratorEdited(false);
	}, [record, collaborators, originalCollaboratorMap, setCollaboratorEdited]);

	// Used to enable/disable the save btn in create mode
	useEffect(() => {
		if (record) return;
		if (!nameRequiredCheck(name, needDetailedName) || file === undefined) {
			setSaveDisabled(true);
			return;
		}

		if (!genre) {
			setSaveDisabled(true);
			return;
		}

		setSaveDisabled(false);
	}, [name, needDetailedName, genre, originalCollaboratorMap, setSaveDisabled, file, record]);

	const handleEdit = () => {
		const data = {
			Name:          name,
			Flags:         flags,
			ISRC:          isrc.trim() ? isrc.trim() : null,
			IVK:           ivk ? ivk : null,
			Music_Genre__: genre ? genre.Music_Genre__ : null,
			Music_Mood__: mood ? mood.Music_Mood__ : null,
			Language__:    lang ? lang : null,
			Preview_Start: previewStart > 0 ? previewStart : 0,
		};

		if (collaborators.length > 0) {
			data.Artists = [];
			collaborators.forEach(c => {
				data.Artists.push({
					                  Role:                 c.Role,
					                  Music_Label_Artist__: c.Music_Label_Artist__
				                  });
			});
		}

		updateRecord(record.Music_Label_Record__, data);

	};

	const handleCreate = () => {
		const data = {
			Name:  name,
			Flags: flags
		};

		// Do not sen unnecessary parameters
		if (lang) data.Language__ = lang;
		if (isrc.trim()) data.ISRC = isrc.trim();
		if (ivk) data.IVK = ivk;
		data.Preview_Start = previewStart > 0 ? previewStart : 0;
		if (genre) data.Music_Genre__ = genre.Music_Genre__;
		if (mood) data.Music_Mood__ = mood.Music_Mood__;
		else data.Music_Mood__ = null;

		if (collaborators.length > 0) {
			data.Artists = [];
			collaborators.forEach(c => {
				data.Artists.push({
					                  Role:                 c.Role,
					                  Music_Label_Artist__: c.Music_Label_Artist__
				                  });
			});
		}

		createRecord(file, data)
			.then(r => {
				history.push(getMusicRecordEditRoute(r.Music_Label_Record__));
			});

	};

	const handleSave = () => {
		record ? handleEdit() : handleCreate();
	};

	const fileChangeHandler = (e) => {
		if (e.target.files.length < 1) {
			setFile(undefined);
		} else {
			setFile(e.target.files[0]);
		}
	};

	const createFlagChangeHandler = flag => value => {
		if(value === 'Y') {
			if(flags.includes(flag)) return;
			const tmp = [...flags]
			tmp.push(flag)
			setFlags(tmp);
		}else {
			setFlags(flags.filter(f => f !== flag));
		}
	}

	if (!loaded || !artist) {
		return <Loading/>;
	}

	return (
		<Grid container spacing={3}>
			{isReleased && <Grid item xs={12}>
				<Alert severity='info'>{t('record_released_edit_alert')}</Alert>
			</Grid>}

			<FormRequiredField/>

			<Grid item xs={12}>
				<InputI18N
					value={name}
					onChange={setName}
					onDetailedNameChange={setNeedDetailedName}
					labelKey='record_name'
					disabled={controlDisabled || isReleased}
				/>
			</Grid>
			{!record && <Grid item xs={12}>
				<FormControl fullWidth required={!record}>
					<InputLabel shrink={true}
					            htmlFor='record-file'>{t('record_file')}</InputLabel>
					<Input disabled={controlDisabled}
					       onChange={fileChangeHandler} inputProps={
						{
							accept: 'audio/*, video/*'
						}
					}
					       id='record-file' type='file'/>
					<FormHelperText>{t('record_file_helpertext')}</FormHelperText>
				</FormControl>
			</Grid>
			}
			{record && <Grid item xs={12}>
				<Grid container spacing={3} justify='space-between' alignItems='center'>
					<Grid item xs={12} md={6}>
						<Alert severity='info'>{t('record_file_edit_alert')}</Alert>
					</Grid>
					<Grid item>
						{record.Type === 'video' && <Video width={300} source={record.Preview.mp4}/>}
						{record.Type === 'audio' && <Audio mp3={record.Preview.mp3}/>}
					</Grid>
					<HasRight manager hideLoading>
						<Grid item>
							<Button
								variant='contained'
								color='primary'
								component={Link}
								to={getMusicRecordLyricsRoute(record.Music_Label_Record__)}
							>
								{t('record_manage_lyrics')}
							</Button>
						</Grid>
					</HasRight>
				</Grid>
			</Grid>
			}

			<Grid item xs={12} md={6}>
				<MusicGenre disabled={controlDisabled || isReleased} required={true} value={genre}
				            onChange={e => setGenre(e)} inputProps={{
					variant:   'outlined',
					required:  true,
					fullWidth: true,

				}}/>
			</Grid>


			<Grid item xs={12} md={6}>
				<MusicMood disabled={controlDisabled || isReleased} value={mood}
				            onChange={e => setMood(e)} inputProps={{
					variant:   'outlined',
					required:  true,
					fullWidth: true,

				}}/>
			</Grid>

			<Grid item xs={12} md={6}>
				<Language value={lang} onChange={e => setLang(e.target.value)}
				          inputProps={{
					          variant:   'outlined',
					          fullWidth: true,
					          disabled:  controlDisabled || isReleased
				          }}
				          useLocalName={false}
				          allowEmpty={true}
				          helperText={t('lang_record_lyrics_helpertext')}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextField disabled={controlDisabled || isReleased} variant='outlined' fullWidth
				           label={t('isrc_lbl')} placeholder={t('isrc_placeholder')}
				           helperText={t('isrc_helpertext')}
				           value={isrc}
				           onChange={(e) => setIsrc(e.target.value)}/>
			</Grid>
			<Grid item xs={12} md={6}>
				<Ivk value={ivk} onChange={e => setIvk(e.target.value)}
				     inputProps={{
					     variant:   'outlined',
					     fullWidth: true,
					     disabled:  controlDisabled || isReleased
				     }}
				/>
			</Grid>

			<Grid item xs={12}>
				<RecordLiveSelector
					value={ flags.includes('live')? 'Y' : 'N'}
					setValue={createFlagChangeHandler('live')}
					disabled={controlDisabled || isReleased}
				/>
			</Grid>
			<Grid item xs={12}>
				<RecordExplicitSelector
					value={flags.includes('explicit')? 'Y' : 'N'}
					setValue={createFlagChangeHandler('explicit')}
					disabled={controlDisabled || isReleased}
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<TextField
					disabled={controlDisabled || isReleased || flags.includes('complete_preview')}
					label={t('preview_start_label')}
					helperText={t('preview_start_helperText')}
					fullWidth
					variant='outlined'
					value={previewStart}
					type='number'
					inputProps={{ min: '0' }}
					onChange={e => setPreviewStart(parseInt(e.target.value))}/>
			</Grid>
			<Grid item xs={12} md={6}>
				<Flags value={flags} onChange={setFlags} disabled={controlDisabled || isReleased} flags={[
					'no_preview',
					'complete_preview',
				]}
				exclusive={[
					'no_preview',
					'complete_preview',
				]}
				/>
			</Grid>

			<Grid item xs={12}>
				<Collaborators value={collaborators} onChange={setCollaborators}
				               disabled={controlDisabled || isReleased}/>
			</Grid>

			{record && <Grid item xs={12}>
				<Title>{t('record_albums_title')}</Title>
			</Grid>}

			{record && <Grid item xs={12}>
					<RecordAlbumTable recordId={record.Music_Label_Record__}/>
			</Grid>}

			{(!isReleased) && <Grid item xs={12}>
				<Grid container justify={(record && isAdmin) ? 'space-between' : 'flex-end'} spacing={3}>
					{(record && isAdmin) && <Grid item>
						<Button loading={deleting} variant='text' color='secondary'
						        disabled={controlDisabled}
						        onClick={e => setDeleteDialogOpened(true)}>
							{t('delete_btn')}
						</Button>
					</Grid>}

					{(record && collaboratorEdited) && <Grid item>
						<Button variant='outlined' color='primary'
						        disabled={controlDisabled}
						        onClick={e => setCollaborators(record.Artists)}>
							{t('reset_collaborators_btn')}
						</Button>
					</Grid>}
					{isManager && <Grid item>
						<Button loading={creating || updating} variant='contained' color='primary'
						        disabled={controlDisabled || saveDisabled}
						        onClick={handleSave}>
							{record ? t('save_btn') : t('create_btn')}
						</Button>
					</Grid>
					}
				</Grid>
			</Grid>}
			{(record && isAdmin) &&
			<DeleteDialog record={record} open={deleteDialogOpened} setOpen={setDeleteDialogOpened}/>}
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		creating:  state.record.creating,
		updating:  state.record.updating,
		deleting:  state.record.deleting,
		isAdmin:   state.access.admin,
		isManager: state.access.manager,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getText:      (id) => dispatch(getText(id)),
		createRecord: (file, data) => dispatch(createRecord(file, data)),
		updateRecord: (id, data) => dispatch(updateRecord(id, data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
