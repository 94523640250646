import React, {useEffect, useState} from 'react';
import {useTranslation}             from 'react-i18next';
import Dialog                       from '@material-ui/core/Dialog';
import DialogTitle                  from '@material-ui/core/DialogTitle';
import DialogContent                from '@material-ui/core/DialogContent';
import Grid                         from '@material-ui/core/Grid';
import TextField                    from '@material-ui/core/TextField';
import DialogActions                from '@material-ui/core/DialogActions';
import Button                       from '../../../../inputs/Button';
import {connect}                    from 'react-redux';
import {Box}                        from '@material-ui/core';
import {updateShippingFeeZone}      from '../../../../../../store/actions/store/ShippingFeeAction';

const EditDialog = ({zone, open, setOpen, updating, update, onEdited}) => {
	const {t} = useTranslation();
	const [name, setName] = useState(zone.Name);

	const [saveDisabled, setSaveDisabled] = useState(true);

	useEffect(() => {
		setName(zone.Name);
	}, [zone, setName]);

	useEffect(() => {
		if (name.trim() === '') {
			setSaveDisabled(true);
			return;
		}
		if (name.trim() !== zone.Name) {
			setSaveDisabled(false);
			return;
		}

		setSaveDisabled(true);
	}, [name, zone, setSaveDisabled]);

	const handleClose = () => {
		setOpen(false);
	};

	const handleUpdate = (e) => {
		e.preventDefault();
		update(zone.Catalog_Deliverable_Fee_Zone__, {
			Name: name.trim()
		})
			.then(onEdited)
			.then(handleClose);
	};

	return (
		<Dialog
			disableEnforceFocus
			open={open}
			aria-labelledby={t('update_shipping_fee_zone')}
			aria-describedby={t('update_shipping_fee_zone')}
			onEnter={() => {
				setName(zone.Name);
			}}
		>
			<form onSubmit={handleUpdate}>
				<DialogTitle>{t('update_shipping_fee_zone')}</DialogTitle>
				<DialogContent>
					<Box py={2}>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<TextField
									label={t('shipping_fee_zone_name')}
									fullWidth variant="outlined" value={name}
									required
									disabled={updating}
									onChange={e => setName(e.target.value)}
								/>
							</Grid>
						</Grid>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button color="default" onClick={handleClose} disabled={updating}>
						{t('close_btn')}
					</Button>

					<Button color="primary" type="submit" variant="contained"
					        disabled={updating || saveDisabled}
					        loading={updating}>
						{t('save_btn')}
					</Button>
				</DialogActions>
			</form>
		</Dialog>

	);
};
const mapStateToProps = (state) => {
	return {
		updating: state.shippingFee.zoneUpdating,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		update: (zoneId, data) => dispatch(updateShippingFeeZone(zoneId, data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDialog);