import React, {useEffect}            from 'react';
import {connect}                     from 'react-redux';
import SelectRealm                   from '../../SelectRealm';
import {Grid}                        from '@material-ui/core';
import Redirect                      from '../../../components/Route/Redirect';
import {getPXLDashboardRoute}        from '../../../router/routes/pxl/factory/general';
import HasRight                      from '../../../components/Route/HasRight';
import AppBarTitle                   from '../../../components/common/typography/AppBarTitle';
import {Trans, useTranslation}       from 'react-i18next';
import RichAlert                     from '../../../components/common/feeback/RichAlert';
import MassMail                      from '../../../components/common/massMail/MassMail';
import Toolbar                       from '../../../components/common/typography/Toolbar';
import {useParams}                   from 'react-router-dom';
import {fetchMassMail, massMailTest} from '../../../store/actions/MassMailAction';
import Loading                       from '../../../components/common/feeback/loading/Loading';
import Button                        from '../../../components/common/inputs/Button';


const NotifyEdit = ({selectedRealm, fetchMassMail, massMail, loading, test, testing}) => {
	const {t} = useTranslation();

	const {massMailId} = useParams();

	useEffect(() => {
		fetchMassMail(massMailId);
	}, [fetchMassMail, massMailId]);

	if (!selectedRealm) return <SelectRealm/>;

	if (!massMail || loading) {
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Loading/>
				</Grid>
			</Grid>
		);
	}

	const handleTest = () => {
		test(massMail.Network_MassMail__);
	};

	return (
		<Grid container spacing={3}>
			<HasRight manager deniedComponent={<Redirect target={getPXLDashboardRoute()}/>}>
				<AppBarTitle title={t('notify_edit_title')}/>
				<Grid item xs={12}>
					<Toolbar title={t('notify_edit_title')}>
						<Button loading={testing} variant="outlined" color="primary" onClick={handleTest}>
							{t('massmail_test_btn')}
						</Button>
					</Toolbar>
				</Grid>

				{massMail.Status !== 'new' && <Grid item xs={12}>
					<RichAlert severity="warning">
						<Trans i18nKey="notify_edit_info">
							sample<br/>
						</Trans>
					</RichAlert>
				</Grid>}

				<Grid item xs={12}>
					<MassMail realmId={selectedRealm.Realm__} massMail={massMail} disabled={massMail.Status !== 'new'}/>
				</Grid>
			</HasRight>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		massMail: state.massMail.massMail,
		loading: state.massMail.massMailLoading,
		testing: state.massMail.testing,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchMassMail: (id) => dispatch(fetchMassMail(id)),
		test: (massMailId) => dispatch(massMailTest(massMailId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(NotifyEdit);
