import {
    OAUTH2_CONSUMER_LINK_FETCH,
    OAUTH2_CONSUMER_LINK_FETCH_DONE,
    OAUTH2_CONSUMERS_FETCH,
    OAUTH2_CONSUMERS_FETCH_DONE,
    OAUTH2_STRIPE_CONSUMER_FETCH,
    OAUTH2_STRIPE_CONSUMER_FETCH_DONE,
    OAUTH2_STRIPE_TOKENS_FETCH,
    OAUTH2_STRIPE_TOKENS_FETCH_DONE,
    OAUTH2_TOKEN_UNLINK,
    OAUTH2_TOKEN_UNLINK_DONE,
    OAUTH2_TOKENS_FETCH,
    OAUTH2_TOKENS_FETCH_DONE,
    OAUTH2_TOKENS_SET_PAGING,
    STRIPE_CONSUMER_ID
} from "../../reducers/system/OAuth2Reducer";
import {handleError} from "../../../components/utils/apiErrorHandler";
import {rest} from '@karpeleslab/klbfw'
import {success} from "./ToastAction";

export const fetchStripeTokens = () => {
    return (dispatch, getState) => {
        dispatch({type: OAUTH2_STRIPE_TOKENS_FETCH});
        return rest('OAuth2/Consumer/Token', 'GET', {OAuth2_Consumer__: STRIPE_CONSUMER_ID})
            .then(d => {
                dispatch({type: OAUTH2_STRIPE_TOKENS_FETCH_DONE, stripeTokens: d.data});
                return d.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const setTokensPaging = (newPaging) => {
    return (dispatch, getState) => {
        dispatch({
            type: OAUTH2_TOKENS_SET_PAGING,
            paging: {...getState().oauth2.tokensPaging, ...newPaging}
        });
    }
};

export const fetchConsumers = () => {
    return (dispatch, getState) => {

        dispatch({type: OAUTH2_CONSUMERS_FETCH});
        return rest('OAuth2/Consumer')
            .then(d => {
                dispatch({type: OAUTH2_CONSUMERS_FETCH_DONE, consumers: d.data});
                return d.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const fetchTokens = () => {
    return (dispatch, getState) => {

        const params = {
            ...getState().oauth2.tokensPaging,
        };

        dispatch({type: OAUTH2_TOKENS_FETCH});
        return rest('OAuth2/Consumer/Token', 'GET', params)
            .then(data => {
                dispatch({type: OAUTH2_TOKENS_FETCH_DONE, tokens: data.data, paging: data.paging});
                return data.data;
            }).catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const fetchStripeConsumer = () => {
    return (dispatch, getState) => {
        dispatch({type: OAUTH2_STRIPE_CONSUMER_FETCH});
        return rest('OAuth2/Consumer/' + STRIPE_CONSUMER_ID)
            .then(d => {
                dispatch({type: OAUTH2_STRIPE_CONSUMER_FETCH_DONE, stripeConsumer: d.data});
                return d.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const fetchConsumerLink = (consumerID, returnTo) => {
    return (dispatch, getState) => {
        dispatch({type: OAUTH2_CONSUMER_LINK_FETCH});
        return rest('OAuth2/Consumer/' + consumerID + ':link', 'POST', {return_to: returnTo})
            .then(d => {
                dispatch({type: OAUTH2_CONSUMER_LINK_FETCH_DONE});
                return d
            }).catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const fetchStripeLink = (returnTo) => {
    return fetchConsumerLink(STRIPE_CONSUMER_ID, returnTo);
};

export const unlinkToken = (tokenId) => {
    return (dispatch, getState) => {
        dispatch({type: OAUTH2_TOKEN_UNLINK});
        return rest('OAuth2/Consumer/Token/' + tokenId, 'DELETE')
            .then(d => {
                dispatch({type: OAUTH2_TOKEN_UNLINK_DONE});
                success('oauth2_token_delete_success');
                return d.data
            }).catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};