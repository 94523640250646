import React, {Fragment, useEffect, useState} from 'react';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Flag             from "../data/flag/Flag";
import {Select}         from "@material-ui/core";
import ListSubheader    from "@material-ui/core/ListSubheader";
import {useTranslation} from "react-i18next";
import MenuItem         from "@material-ui/core/MenuItem";
import Grid             from "@material-ui/core/Grid";
import Typography       from "@material-ui/core/Typography";
import {connect}        from 'react-redux'
import CircularProgress from "@material-ui/core/CircularProgress";
import {fetch}          from '../../../store/actions/system/LanguageAction'
import Date                   from '../inputs/Date'
import {  isObject } from '../../utils/misc';
import { getLocale }          from '@karpeleslab/klbfw';


const buildItem = (v, t) => {
    if (!v) return; // The default value
    return <MenuItem value={v} key={'multiText-' + v}>
        <Grid container spacing={3} alignItems='center'>
            <Grid item>
                <Flag code={v.split('-')[1].toLowerCase()}/>
            </Grid>
            <Grid item>
                <Typography>{t('language_' + v.split('-')[0].toLowerCase())}</Typography>
            </Grid>
        </Grid>
    </MenuItem>
};

const MultiLangTextField = ({
                                value, setValue, loading, type, allowDelete = true, disabled = false, langs, settingLanguage, fetch, endAdornment = null, ...rest
                            }) => {

    const [open, setOpen] = useState(false);
    // The default value format is [{Language__:lang1, Value:value}, ....]
	// The object mode allow to send a value like so {en-US:'value', ....} This is especially used with the Text__ format, in that case we don't have default value
	const [objectMode] = useState(isObject(value))
	const [lang, setLang] = useState(objectMode ? (Object.keys(value)?.[0] ?? getLocale()) : 'any');
    const [valueMap, setValueMap] = useState(objectMode ? value : {});

    const {t} = useTranslation();

    useEffect(() => {
        fetch()
    }, [fetch]);

    useEffect(() => {
    	// This is to default to the right language
	    // If not object mode we default to any (the defautl value)
	    // If object mode:
	    //  - If we have a value we take the first entry of the value as the defautl selected lan
	    //  - If not
	    //     -- Check if we use the langs from the template settings, if yes use the first one
	    //     -- If no use the current user locale (it will be necessarly preseng in the complete list of language
	    setLang(objectMode ? (Object.keys(value)?.[0] ?? (settingLanguage ? settingLanguage[0] : getLocale())) : 'any')
	    // eslint-disable-next-line
    }, [settingLanguage, setLang, objectMode])

    useEffect(() => {
    	if(objectMode) {
    	    return;
    	}
        const m = {};
        value.forEach(v => m[v.Language__ ?? 'any'] = v);
        setValueMap(m)

    }, [value, setValueMap, objectMode]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = e => {
        e.preventDefault();
        e.stopPropagation();
        setOpen(true);
    };

    const onChangeObjectMode = (l, v) => {
	    let newValue = {...value};
	    if (type === 'date' && !v && allowDelete) delete newValue[l];
	    else if (type !== 'date' && !v.trim() && allowDelete) delete newValue[l];
	    else newValue[l] = v;

	    setValue(newValue);
    }

    const onChange = (l, v) => {
	    if(objectMode) {
		    onChangeObjectMode(l, v)
		    return;
	    }

        l = l === 'any' ? null : l;
        let newValue = [...value];
        if (type === 'date' && !v && allowDelete) setValue(newValue.filter(nv => nv.Language__ !== l));
        else if (type !== 'date' && !v.trim() && allowDelete) setValue(newValue.filter(nv => nv.Language__ !== l));
        else {
            for (let i = 0; i < newValue.length; i++) {
                if (newValue[i].Language__ === l) {
                    newValue[i].Value = type === 'date' ? v : v;
                    setValue(newValue);
                    return;
                }
            }

            newValue.push({Language__: l, Value: v});
            setValue(newValue);
        }

    };

    const Field = type === 'date' ? Date : TextField;
    const defaultV = type === 'date' ? null : '';

    return (
        <Field
            {...rest}
            type={type === 'date' ? 'text' : type}
            disabled={loading || disabled}
            value={
                objectMode ?
                    ((lang in value) ? value[lang] : defaultV ) :
                    ((lang in valueMap) ?  valueMap[lang].Value : defaultV)
            }
            onChange={e => onChange(lang, type === 'date' ? e : e.target.value)}
            InputProps={
                {
                    endAdornment:
                        (<InputAdornment position="end" onClick={e => e.stopPropagation()}>
                            <Fragment>
                                <Select
                                    disableUnderline={true}
                                    open={open}
                                    onChange={(e) => setLang(e.target.value)}
                                    onClose={handleClose}
                                    onOpen={handleOpen}
                                    disabled={loading}
                                    value={lang}
                                    native={false}
                                    renderValue={(v) => {
                                        if (loading) return <CircularProgress thickness={1} size={20}/>;
                                        if (v === 'any') return t('i18n_input_default');
                                    
                                        if (v)
                                            return <Flag
                                                size='small'
                                                ratio="4x3"
                                                code={v.split('-')[1].toLowerCase()}
                                                alt={v}
                                            />;


                                    }
                                    }
                                >
	                                {!objectMode && <MenuItem value='any'>{t('i18n_input_default')}</MenuItem>}
                                    <ListSubheader>{t('i18n_input_filled')}</ListSubheader>
                                    {(objectMode ? Object.keys(value) : value).map((v) => buildItem(objectMode ? v : v.Language__, t))}
                                    <ListSubheader>{t('i18n_input_available')}</ListSubheader>
                                    {langs.map((v) => {
                                    	if(settingLanguage == null || settingLanguage.includes(v.Language__))
                                            return (!([v.Language__] in valueMap)) ? buildItem(v.Language__, t) : null
	                                    return null;
                                    })}
                                </Select>
                                {endAdornment && endAdornment}
                            </Fragment>
                        </InputAdornment>)
                }
            }
        />
    );
};

const mapStateToProps = (state) => {
    return {
	    settingLanguage: state.template.templateSettings?.Data?.Languages ?? null,
        langs: state.language.languages,
        loading: state.language.loading,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetch: () => dispatch(fetch())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiLangTextField);
