import React, {useState} from 'react';
import {Grid}            from '@material-ui/core';
import Empty             from '../../feeback/State/Empty';
import GraphicDuplicate  from '../../../../resources/images/duplicate.svg';
import {connect}         from 'react-redux';
import {useTranslation}  from 'react-i18next';
import FileCopyIcon      from '@material-ui/icons/FileCopy';
import DuplicateDialog   from './DuplicateDialog';

const Management = ({schedule, duplicating, isManager, setTabIndex, disabled = false, isEvent = false}) => {
	const {t} = useTranslation();
	const [showDuplicateDialog, setShowDuplicateDialog] = useState(false);

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Empty
					title={isEvent ? t('event_duplicate_title') : t('schedule_duplicate_title')}
					textI18Key={isEvent ? 'event_duplicate_text' : 'schedule_duplicate_text'}
					action={{
						title: t('duplicate_btn'),
						disabled: !isManager || duplicating || disabled,
						loading: duplicating,
						startIcon: <FileCopyIcon/>,
						color: 'primary',
						variant: 'contained',
						component: 'button',
						onClick: () => setShowDuplicateDialog(true)
					}}
					graphic={GraphicDuplicate}
				/>
			</Grid>
			{(schedule && isManager) &&
			<DuplicateDialog schedule={schedule} open={showDuplicateDialog} setOpen={setShowDuplicateDialog}
			                 setTabIndex={setTabIndex} isEvent={isEvent}/>
			}
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		duplicating: state.schedule.duplicating,
		isManager: state.access.manager,
	};
};

export default connect(mapStateToProps)(Management);
