import React, {useEffect, useState}                                      from 'react';
import Grid                                                              from '@material-ui/core/Grid';
import {Area, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {useTranslation}                                                  from 'react-i18next';
import AreaChart                                                         from 'recharts/lib/chart/AreaChart';
import {connect}                                                         from 'react-redux';
import {fetchCatalogsStats, orderStats}                                  from '../../../store/actions/StatsAction';
import Skeleton                                                          from '@material-ui/lab/Skeleton';
import moment                                                            from 'moment';
import {getCurrency}                                                     from '@karpeleslab/klbfw';
import Currencies                                                        from '../inputs/Currencies';
import Year                                                              from '../inputs/Year';
import Month                                                             from '../inputs/Month';
import {
	formatPrice,
	getName
}                                                                        from '../../../store/actions/system/CurrencyAction';
import Card                                                              from '@material-ui/core/Card';
import CardContent                                                       from '@material-ui/core/CardContent';
import Typography                                                        from '@material-ui/core/Typography';
import {BlackPaper}                                                      from '../surface/Paper';
import makeStyles                                                        from '@material-ui/core/styles/makeStyles';


const useStyles = makeStyles({
	uppercase: {
		textTransform: 'uppercase',
	},
});

const Sales = ({
	               realm,
	               formatPrice,
	               fetchCatalogsStats,
	               catalogStats,
	               catalogStatsLoading,
	               hasFanclub,
	               hasPlanner,
	               hasStore,
	               catalogTotals,
	               currencyLoading
               }) => {
	const {t} = useTranslation();
	const [currency, setCurrency] = useState(getCurrency());
	const [year, setYear] = useState(moment().format('YYYY'));
	const [month, setMonth] = useState(moment().format('MM'));
	const classes = useStyles();

	useEffect(() => {
		if (!realm) return;
		fetchCatalogsStats(year + '-' + month, currency);

	}, [fetchCatalogsStats, currency, year, month, realm]);

	const CatalogStatsTooltip = ({payload, label, active, value}) => {
		if (active && payload) {
			const total = payload.reduce(
				(previousValue, item) => previousValue + item.value,
				0
			);
			return (
				<Card style={
					{
						background: 'rgba(44, 44, 44, 0.8)',
						backdropFilter: 'blur(12px)',
						borderRadius: '4px',
						padding: '14px'
					}
				}>
					<CardContent>
						<Typography variant="body1" color="textPrimary" align="center">
							{label}
						</Typography>
						<Typography variant="subtitle1" color="textPrimary" align="center">
							{formatPrice(total, currency)}
						</Typography>
						{payload.map(item => <div>
							<div style={{display: 'flex', alignItems: 'center'}}>
								<div style={{
									width: 20,
									height: 20,
									border: '2px solid #FFFFFF',
									background: item.fill,
									borderRadius: '10px',
									marginRight: '14px',
								}}/>
								<div>
									<Typography variant="h6" style={{color: item.fill}}>
										{t(`dashboard_catalog_stats_${item.name}_tooltip`)}
									</Typography>
									<Typography variant="h6" style={{color: item.fill}}>
										{formatPrice(item.value, currency)}
									</Typography>
								</div>
							</div>
						</div>)}
					</CardContent>
				</Card>
			);
		}

		return null;
	};

	return (
		<Card elevation={0} component={BlackPaper} style={{height: '100%', overflow: 'unset'}}>
			<CardContent>

				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Typography variant="h5" align="center" className={classes.uppercase}>
							{t('dashboard_sales_title')}
						</Typography>
					</Grid>

					<Grid item xs={12}>
						<Grid container spacing={3}>

							<Grid item xs={12}>
								<Grid container alignItems="center">
									<Grid item xs={12}>
										<Grid container justify="center" spacing={3}>


											<Grid item>
												<Currencies onChange={(e) => setCurrency(e.target.value)}
												            value={currency}
												            inputProps={{
													            variant: 'outlined',
													            disabled: catalogStatsLoading,
													            fullWidth: true
												            }}/>
											</Grid>
											<Grid item>
												<Year onChange={(e) => setYear(e.target.value)} value={year}
												      inputProps={{
													      variant: 'outlined',
													      disabled: catalogStatsLoading,
													      fullWidth: true
												      }}/>
											</Grid>

											<Grid item>
												<Month onChange={(e) => setMonth(e.target.value)} value={month}
												       inputProps={{
													       variant: 'outlined',
													       disabled: catalogStatsLoading,
													       fullWidth: true
												       }}/>
											</Grid>

										</Grid>

									</Grid>

								</Grid>
							</Grid>

							{(catalogStatsLoading) &&
							<Skeleton height={200} width="100%" style={{transform: 'scale(1,1)'}}/>}

							{!catalogStatsLoading && <>
								<Grid item xs={12}>
									<ResponsiveContainer width="100%" height={200}>
										<AreaChart
											data={catalogStats}
										>
											<XAxis dataKey="name"/>
											<YAxis/>
											<Tooltip content={<CatalogStatsTooltip/>} wrapperStyle={{zIndex: 1}}/>
											<ReferenceLine x={moment().format('YYYY-MM-DD')} stroke="red"/>
											{hasPlanner &&
											<Area type="monotone" dataKey="event" stackId="1" stroke="#f2702e"
											      fillOpacity={0.6}
											      fill="#f2702e"/>}
											{hasFanclub &&
											<Area type="monotone" dataKey="fanclub" stackId="1" stroke="#eb3839"
											      fillOpacity={0.6}
											      fill="#eb3839"/>}
											{hasStore &&
											<Area type="monotone" dataKey="store" stackId="1" stroke="#e50137"
											      fillOpacity={0.6}
											      fill="#e50137"/>}
										</AreaChart>
									</ResponsiveContainer>
								</Grid>
								<Grid item xs={12}>
									<Grid container spacing={3} justify='center' alignItems='center'>
										{(hasPlanner && !currencyLoading) &&
										<Grid item>
											<div style={{display: 'flex', alignItems: 'center'}}>
												<div style={{
													width: 20,
													height: 20,
													border: '2px solid #FFFFFF',
													background: '#f2702e',
													borderRadius: '10px',
													marginRight: '14px',
												}}/>
												<div>
													<Typography variant="h6" style={{color: '#f2702e'}}>
														{t('dashboard_catalog_stats_event_total', {total: formatPrice(catalogTotals.event, currency)})}
													</Typography>
												</div>
											</div>
										</Grid>
										}
										{(hasFanclub && !currencyLoading) &&
										<Grid item>
											<div style={{display: 'flex', alignItems: 'center'}}>
												<div style={{
													width: 20,
													height: 20,
													border: '2px solid #FFFFFF',
													background: '#eb3839',
													borderRadius: '10px',
													marginRight: '14px',
												}}/>
												<div>
													<Typography variant="h6" style={{color: '#eb3839'}}>
														{t('dashboard_catalog_stats_fanclub_total', {total: formatPrice(catalogTotals.fanclub, currency)})}
													</Typography>
												</div>
											</div>
										</Grid>
										}
										{(hasStore && !currencyLoading) &&
										<Grid item>
											<Grid item>
												<div style={{display: 'flex', alignItems: 'center'}}>
													<div style={{
														width: 20,
														height: 20,
														border: '2px solid #FFFFFF',
														background: '#e50137',
														borderRadius: '10px',
														marginRight: '14px',
													}}/>
													<div>
														<Typography variant="h6" style={{color: '#e50137'}}>
															{t('dashboard_catalog_stats_store_total', {total: formatPrice(catalogTotals.store, currency)})}
														</Typography>
													</div>
												</div>
											</Grid>
										</Grid>
										}
										{currencyLoading && <Grid item><Skeleton width={300}/></Grid>}
									</Grid>
								</Grid>

							</>}
						</Grid>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		catalogStats: state.stats.catalogStats,
		catalogTotals: state.stats.catalogTotals,
		total: state.stats.ordersTotal,
		catalogStatsLoading: state.stats.catalogStatsLoading,
		realm: state.realm.selected,

		hasFanclub: !!state.membership.selectedFanclub,
		hasPlanner: !!state.schedule.planner,
		hasStore: !!state.store.catalog,
		currencyLoading: state.currency.loading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchStats: (realmId, period, currencyId) => dispatch(orderStats(realmId, period, currencyId)),
		fetchCatalogsStats: (period, currencyId) => dispatch(fetchCatalogsStats(period, currencyId)),
		getCurrencyName: (currency) => dispatch(getName(currency)),
		formatPrice: (price, currency) => dispatch(formatPrice(price, currency))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Sales);