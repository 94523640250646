import React, {useCallback, useEffect, useState} from 'react';
import {Trans, useTranslation}                   from 'react-i18next';
import {Grid}                                    from '@material-ui/core';
import {Title}                                   from '../../typography/Title';
import RichAlert                                 from '../../feeback/RichAlert';
import OrderTable                                from './OrderTable';
import OrderProcessingAlert                      from './OrderProcessingAlert';
import OrderPayments                             from './Payments/OrderPayments';
import {FINISHED_STATUS}                         from '../../../../store/reducers/OrderReducer';
import {useOrderProcess}                         from '../../../../hooks/api/useOrder';
import Loading                                   from '../../feeback/loading/Loading';
import {useLocation}                             from 'react-router-dom';

const OrderWithPayment = ({orderId, onComplete, onPaying = undefined, setDialogActions = null}) => {
	const {t} = useTranslation();
	const [processOrder, processingOrder] = useOrderProcess(orderId);
	const [initialLoading, setInitialLoading] = useState(true);
	const [processResult, setProcessResult] = useState(null);
	const location        = useLocation();

	const refreshProcessing = useCallback(() => {
		const params = {};
		const search = new URLSearchParams(location.search);
		search.forEach(function(value, key) {
			params[key] = value;
		});

		const {session = null, method = null, ...data} = params;

		return processOrder(session ?? null,method ?? null, data)
			.then(res => {
				setInitialLoading(false);
				setProcessResult(res);
				return res;
			})
		// eslint-disable-next-line
	}, [setProcessResult,setInitialLoading, processOrder]);

	const detectComplete = useCallback(newProcessResult => {
		if (processResult.order.Status !== newProcessResult.order.Status &&
			newProcessResult.order.Status === 'completed' &&
			onComplete) {
			onComplete(newProcessResult.order);
		}

		setProcessResult(newProcessResult);
		// eslint-disable-next-line
	}, [processResult, setProcessResult, onComplete]);

	useEffect(() => {
		// This will remove the pay action button once the order is paid
		if (!processResult || !processResult.order_payable || processResult.order.Paid || !setDialogActions) return;
		setDialogActions(null);
	}, [processResult, setDialogActions]);

	useEffect(() => {
		// Initial loading
		refreshProcessing()
		// eslint-disable-next-line
	}, [])


	if(initialLoading || !processResult) {
		return (<Grid container spacing={3}>
			<Grid item xs={12}>
				<Loading/>
			</Grid>
		</Grid>)
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Title>
					{t('order_details')}
				</Title>
			</Grid>

			{(processResult.order.Paid && !FINISHED_STATUS.includes(processResult.order.Status)) &&
			<Grid item xs={12}>
				<OrderProcessingAlert refresh={refreshProcessing} detectComplete={detectComplete}/>
			</Grid>
			}

			{FINISHED_STATUS.includes(processResult.order.Status) &&
			<Grid item xs={12}>
				<RichAlert>
					<Trans i18nKey="order_completed">
						sample<br/>
						<strong>sample</strong>
					</Trans>
				</RichAlert>
			</Grid>
			}

			<Grid item xs={12}>
				<OrderTable order={processResult.order} elevation={0}/>
			</Grid>

			{processResult.order_payable &&
			<Grid item xs={12}>
				<OrderPayments
					onPaying={onPaying}
					processResult={processResult}
					processingOrder={processingOrder}
					refresh={detectComplete}
					setDialogActions={setDialogActions}
				/>
			</Grid>
			}
		</Grid>
	);
};

export default OrderWithPayment;