import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {Title} from "../../../typography/Title";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import {connect} from 'react-redux'
import {createLabel} from "../../../../../store/actions/TagAction";
import {getLocale} from "@karpeleslab/klbfw";
import {forceUpdateSelectedPlanTagName} from "../../../../../store/actions/fanclub/PlanAction";
import { useTranslation } from 'react-i18next';
import Button from "../../../inputs/Button";


const Tag = ({plan, updating, update,forceUpdateSelectedPlanTagName}) => {
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [tag, setTagValue] = useState('');
    const { t } = useTranslation();
    useEffect(() => {
        if (!plan) return;
        setTagValue(plan.Classify_Tag.Name)
    }, [plan]);

    useEffect(() => {
        if (!plan) return;

        if (tag === plan.Classify_Tag.Name) {
            setSaveEnabled(false);
            return;
        }

        setSaveEnabled(true)
    }, [tag, setSaveEnabled, plan]);
    
    const handleSubmit = e => {
        e.preventDefault();
        update(plan.Classify_Tag, tag)
            .then(d => {
                forceUpdateSelectedPlanTagName(d.Name)
            })
    };

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Title>{t('article_tag_title')}</Title>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {t('article_tag_desc')}
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <TextField label={t('name_lbl')} fullWidth variant="outlined" value={tag} disabled={updating}
                               onChange={e => setTagValue(e.target.value)}/>
                </Grid>

                <Grid item xs={12}>
                    <Grid justify='flex-end' container>
                        <Button variant="contained" loading={updating} disabled={!saveEnabled || updating} color='primary' type='submit'>
                            {t('save_btn')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

const mapStateToProps = (state) => {
    return {
        updating: state.tag.creatingLabel,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        update: (tag, name) => dispatch(createLabel(tag, name, getLocale())),
        forceUpdateSelectedPlanTagName: (name) => dispatch(forceUpdateSelectedPlanTagName(name))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Tag);
