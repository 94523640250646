import React, {useEffect, useState} from 'react';
import Grid                         from '@material-ui/core/Grid';
import {useTranslation}             from 'react-i18next';
import {deepCopy}                   from '../../../utils/misc';
import Form                         from './Form';
import Button                       from '../../inputs/Button';
import {updateMassMailSegment}      from '../../../../store/actions/MassMailAction';
import {connect}                    from 'react-redux';
import FormRequiredField            from '../../feeback/FormRequiredField';

const EditForm = ({segment, update, updating}) => {
	const {t} = useTranslation();
	const [data, setData] = useState(deepCopy(segment));
	const [isValid, setIsValid] = useState(false);
	const [saveEnabled, setSaveEnabled] = useState(false);

	useEffect(() => {
		if (!isValid) {
			setSaveEnabled(false);
			return;
		}

		if (data['Name'] !== segment.Name ||
			data['Email'] !== segment.Email ||
			data['Visible'] !== segment.Visible ||
			(data['Subject_Prefix'] ?? '') !== (segment.Subject_Prefix ?? '')
		) {
			setSaveEnabled(true);
			return;
		}

		setSaveEnabled(false);
	}, [data, segment, isValid, setSaveEnabled]);

	const handleEdit = () => {
		update(segment.Network_MassMail_Segment__, data)
			.then((d) => {
				setData(deepCopy(d));
			});
	};

	return (
		<Grid container spacing={3}>
			<FormRequiredField/>
			<Grid item xs={12}>
				<Form
					setIsValid={setIsValid}
					disabled={updating}
					data={data}
					setData={setData}
				/>
			</Grid>
			<Grid item xs={12}>
				<Grid container justify="flex-end">
					<Grid item>
						<Button color="primary" variant="contained"
						        disabled={updating || !saveEnabled} loading={updating} onClick={handleEdit}>
							{t('save_btn')}
						</Button>
					</Grid>
				</Grid>
			</Grid>

		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		updating: state.massMail.massMailSegmentUpdating,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		update: (id, data) => dispatch(updateMassMailSegment(id, data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditForm);
