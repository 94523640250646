import {COMMON_RESET} from "../CommonReducer";
import AozoraLogo from '../../../resources/images/banks/aozora.svg'

const initState = () => {
    return {
        tokens: [],
        tokensLoading: false,
        tokensPagingCount: 0,
        tokensPaging: {
            page_no: 1,
            results_per_page: 10
        },

        consumers: [],
        loadingConsumers: false,

        stripeTokensLoading: true,
        stripeTokens: [],

        stripeConsumerLoading: true,
        stripeConsumer: null,

        loadingConsumerLink: false,
        unlink: false,

        storeBankTransferConsumers: [
            {
                Name: 'aozoranet',
                OAuth2_Consumer__: 'oatc-fw526l-jejz-fd3l-owv5-mgvpxfwe',
                Logo: AozoraLogo,
                LogoDisplay : {
                    width:150
                }
            }
        ]
    }
};

export const OAUTH2_STRIPE_TOKENS_FETCH = 'OAUTH2_STRIPE_TOKENS_FETCH';
export const OAUTH2_STRIPE_TOKENS_FETCH_DONE = 'OAUTH2_STRIPE_TOKENS_FETCH_DONE';

export const OAUTH2_STRIPE_CONSUMER_FETCH = 'OAUTH2_STRIPE_CONSUMER_FETCH';
export const OAUTH2_STRIPE_CONSUMER_FETCH_DONE = 'OAUTH2_STRIPE_CONSUMER_FETCH_DONE';

export const OAUTH2_CONSUMER_LINK_FETCH = 'OAUTH2_CONSUMER_LINK_FETCH';
export const OAUTH2_CONSUMER_LINK_FETCH_DONE = 'OAUTH2_CONSUMER_LINK_FETCH_DONE';

export const OAUTH2_TOKENS_FETCH = 'OAUTH2_TOKENS_FETCH';
export const OAUTH2_TOKENS_FETCH_DONE = 'OAUTH2_TOKENS_FETCH_DONE';
export const OAUTH2_TOKENS_SET_PAGING = 'OAUTH2_TOKENS_SET_PAGING';

export const OAUTH2_CONSUMERS_FETCH = 'OAUTH2_CONSUMERS_FETCH';
export const OAUTH2_CONSUMERS_FETCH_DONE = 'OAUTH2_CONSUMERS_FETCH_DONE';

export const OAUTH2_TOKEN_UNLINK = 'OAUTH2_TOKEN_UNLINK';
export const OAUTH2_TOKEN_UNLINK_DONE = 'OAUTH2_TOKEN_UNLINK_DONE';


export const STRIPE_CONSUMER_ID = 'oatc-xpo7z6-cu5v-ezjd-iuk6-65gocica';

const oAuth2Reducer = (state = initState(), action) => {
    switch (action.type) {
        case COMMON_RESET:
            return initState();
        case OAUTH2_TOKEN_UNLINK:
            return {...state, unlink: true};
        case OAUTH2_TOKEN_UNLINK_DONE:
            return {...state, unlink: false};
        case OAUTH2_CONSUMERS_FETCH:
            return {...state, loadingConsumers: true};
        case OAUTH2_CONSUMERS_FETCH_DONE:
            return {...state, loadingConsumers: false, consumers: action.consumers};
        case OAUTH2_TOKENS_FETCH:
            return {...state, tokensLoading: true};
        case OAUTH2_TOKENS_FETCH_DONE:
            return {
                ...state,
                tokensLoading: false,
                tokens: action.tokens,
                tokensPagingCount: action.paging.count
            };
        case OAUTH2_TOKENS_SET_PAGING :
            return {...state, tokensPaging: action.paging};

        case OAUTH2_CONSUMER_LINK_FETCH:
            return {...state, loadingConsumerLink: true};
        case OAUTH2_CONSUMER_LINK_FETCH_DONE:
            return {...state, loadingConsumerLink: false};
        case OAUTH2_STRIPE_CONSUMER_FETCH:
            return {...state, stripeConsumerLoading: true};
        case OAUTH2_STRIPE_CONSUMER_FETCH_DONE:
            return {...state, stripeConsumerLoading: false, stripeConsumer: action.stripeConsumer};
        case OAUTH2_STRIPE_TOKENS_FETCH:
            return {...state, stripeTokensLoading: true};
        case OAUTH2_STRIPE_TOKENS_FETCH_DONE:
            return {...state, stripeTokensLoading: false, stripeTokens: action.stripeTokens};
        default:
            return state;
    }

};

export default oAuth2Reducer