export const isFunction = (obj) => {
	return !!(obj && obj.constructor && obj.call && obj.apply);
};
export const isObject = (obj) => {
	return typeof obj === 'object' && obj !== null && !Array.isArray(obj);
};

export const pad = (num, size) => {
	let s = num + '';
	while (s.length < size) s = '0' + s;
	return s;
};

export const trimLength = (str, length) => {
	return str.substring(0, length);
};

export const trimLeft = (str, ch) => {
	return trim(str, ch, -1);
};

export const trimRight = (str, ch) => {
	return trim(str, ch, 1);
};

export const trim = (str, ch, mode = 0) => {
	let start = 0,
		end = str.length;

	if (mode === 0 || mode === -1) {
		while (start < end && str[start] === ch)
			++start;
	}

	if (mode === 0 || mode === 1) {
		while (end > start && str[end - 1] === ch)
			--end;
	}

	return (start > 0 || end < str.length) ? str.substring(start, end) : str;
};

export const deepCopy = (object) => {
	return JSON.parse(JSON.stringify(object));
};

export const copyToClipboard = (str) => {
	if (typeof document === 'undefined') return;
	const el = document.createElement('textarea');
	el.value = str;
	document.body.appendChild(el);
	el.select();
	el.focus();
	document.execCommand('copy');
	document.body.removeChild(el);
};

export const europeanVatNumberValidate = (vat) => {
	vat = (vat ?? '').trim();
	if (vat.length < 6) return false; // too short
	const prefix = ['AT', 'BE', 'BG', 'CY', 'CZ', 'DE', 'DK', 'EE', 'EL', 'ES', 'FI', 'FR', 'HR', 'HU', 'IE', 'IT', 'LT', 'LU', 'LV', 'MT', 'NL', 'PL', 'PT', 'RO', 'SE', 'SI', 'SK', 'XI'];
	vat = vat.toUpperCase();
	if (!prefix.includes(vat.substr(0, 2))) return false;

	return /^\d+$/.test(vat.substr(2));
};

export const arrayEquals = (a1, a2) => {
	if (!!a1 !== !!a2) return false;
	if (a1.length !== a2.length) return false;
	for (let i = 0; i < a1.length; i++) {
		if (a1[i] !== a2[i]) return false;
	}
	return true;
};

export const mimeToType = (mime) => {
	const splited =mime.split('/');
	if(splited.length < 1) return null;
	return splited[0];
}
