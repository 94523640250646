import React, { Fragment, useEffect, useState }     from 'react';
import { useParams }                                from 'react-router';
import { connect }                                  from 'react-redux';
import { fetchMessage, fetchTicket, replyToTicket } from '../../../store/actions/SupportAction';
import Grid                                         from '@material-ui/core/Grid';
import Loading                                      from '../feeback/loading/Loading';
import { useTranslation }                           from 'react-i18next';
import Messages                                     from './Messages';
import { TextField }                                from '@material-ui/core';
import Toolbar                                      from './Toolbar';
import SelectDialog                                 from './canned/SelectDialog';
import ReopenDialog                                 from './ReopenDialog';
import Button                                       from "../inputs/Button";
import FormRequiredField                            from '../feeback/FormRequiredField';

const Ticket = ({ ticket, loading, fetchTicket, messages, messagesLoading, fetchMessage, replying, replyToTicket, onReplied, onClosed, hasRights }) => {
	const { ticketId } = useParams();
	const { t } = useTranslation();

	const [message, setMessage] = useState('');
	const [sendBtnEnabled, setSendBtnEnabled] = useState(false);
	const [showCannedDialog, setShowCannedDialog] = useState(false);
	const [showReopenDialog, setShowReopenDialog] = useState(false);

	useEffect(() => {
		if (message.trim() !== '') {
			setSendBtnEnabled(true);
		} else {
			setSendBtnEnabled(false);

		}
	}, [message, setSendBtnEnabled]);


	useEffect(() => {
		fetchTicket(ticketId);
	}, [ticketId, fetchTicket]);

	useEffect(() => {
		if (!ticket) return;
		fetchMessage(ticket.Support_Ticket__);
	}, [ticket, fetchMessage]);

	const onCannedSelected = canned => {
		setMessage(canned.Message);
	};

	if (loading || messagesLoading || !ticket) return <Loading/>;


	const handleSend = () => {
		if (message.trim() === '') return;

		replyToTicket(ticketId, message.trim()).then(onReplied);
	};

	return (
		<Grid container spacing={3}>

			<Grid item xs={12}>

				<Toolbar ticket={ticket} onClosed={onClosed}/>
			</Grid>

			<Grid item xs={12}>
				<Grid container direction='column' alignItems='stretch' spacing={3}>
					<Messages messages={messages}/>
				</Grid>
			</Grid>

			{hasRights &&
			<Fragment>
				<FormRequiredField/>
				<Grid item xs={12}>

					<TextField
						value={message}
						onChange={e => setMessage(e.target.value)}
						variant='outlined'
						multiline rows={5}
						rowsMax={Infinity}
						placeholder={t('support_response_placeholder')}
						required
						fullWidth
						disabled={replying}
						label={t('support_response_label')}
					/>

				</Grid>

				<Grid item xs={12}>
					<Grid justify='space-between' container>
						<Grid item>
							<Button variant='contained' disabled={replying} color='primary'
							        onClick={() => setShowCannedDialog(true)}>
								{t('canned_btn')}
							</Button>
						</Grid>
						<Grid item>
							<Button variant='contained' loading={replying} disabled={!sendBtnEnabled || replying} color='primary'
							        onClick={() => ticket.Status !== 'closed' ? handleSend() : setShowReopenDialog(true)}>
								{t('send_btn')}
							</Button>
						</Grid>
					</Grid>
				</Grid>

				<SelectDialog setOpen={setShowCannedDialog} open={showCannedDialog} onSelected={onCannedSelected}/>
				<ReopenDialog open={showReopenDialog}
				              setOpen={setShowReopenDialog}
				              ticket={ticket}
				              message={message.trim()}
				              onTicketSend={d => {
					              onReplied(d);
				              }
				              }
				/>
			</Fragment>
			}
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		ticket: state.support.ticket,
		loading: state.support.ticketLoading,
		messages: state.support.ticketMessages,
		messagesLoading: state.support.ticketMessagesLoading,
		replying: state.support.supportReplying,

		hasRights: state.support.support && state.access.support
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchTicket: (ticketId) => dispatch(fetchTicket(ticketId)),
		fetchMessage: (ticketId) => dispatch(fetchMessage(ticketId)),
		replyToTicket: (ticketId, message) => dispatch(replyToTicket(ticketId, message)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Ticket);
