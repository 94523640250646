import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import {useTranslation} from "react-i18next";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../inputs/Button";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import MaterialLink from "@material-ui/core/Link";

const MailDialog = ({open, setOpen, mail}) => {
    const {t} = useTranslation();

    const handleClose = () => {
        setOpen(false);
    };


    if (!mail) return <></>;

    return (
        <Dialog
            open={open}
            aria-labelledby=''
            aria-describedby=''
            fullWidth
        >
            <DialogTitle id="mail-dialog-title">{mail.Subject}</DialogTitle>
            <DialogContent>
                <DialogContentText id="mail-dialog-description">
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('tablecell_mail_part_number')}</TableCell>
                                    <TableCell>{t('tablecell_mail_link')}</TableCell>
                                </TableRow>
                            </TableHead>
                            {Object.keys(mail.Part).map(key =>
                                <TableRow>
                                    <TableCell>{key}</TableCell>
                                    <TableCell>
                                        <MaterialLink href={mail.Part[key].Url} target='_blank' rel="noopener noreferrer">
                                            {mail.Part[key].Filename === 'part' ? mail.Part[key].Mime : mail.Part[key].Filename}
                                        </MaterialLink>
                                    </TableCell>
                                </TableRow>)}
                        </Table>
                    </TableContainer>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={handleClose}>
                    {t('close_btn')}
                </Button>
            </DialogActions>

        </Dialog>

    );
};

export default MailDialog;