import React      from 'react';
import {Paper}    from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DrawerItem from '../DrawerItem';

const useStyles = makeStyles(theme => ({
	container: {
		background: 'rgba(255, 255, 255, 0.03)',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
}));

const MobileMenuItem = ({Icon, text, to = undefined, menu = undefined, selectedRoutes = []}) => {
	const classes = useStyles();

	return (
		<Paper className={classes.container}>
			<DrawerItem Icon={Icon} text={text} selectedRoutes={selectedRoutes} menu={menu} to={to} disabledMargin/>
		</Paper>
	);
};

export default MobileMenuItem;