export const getSupportInboxRoute = () => '/inbox';
export const getSupportOutboxRoute = () => '/outbox';
export const getSupportClosedRoute = () => '/closed';
export const getSupportNewTicketRoute = () => '/new-ticket';

export const getSupportInboxTicketRoute = (ticketId = null) => '/inbox/' + (ticketId ?? ':ticketId');
export const getSupportOutboxTicketRoute = (ticketId = null) => '/outbox/' + (ticketId ?? ':ticketId');
export const getSupportClosedTicketRoute = (ticketId = null) => '/closed/' + (ticketId ?? ':ticketId');

export const getSupportBoxTicketRoute = (box, ticketId = null) => {
    switch (box) {
        case 'inbox':
            return getSupportInboxTicketRoute(ticketId);
        case 'outbox':
            return getSupportOutboxTicketRoute(ticketId);
        case 'closed':
            return getSupportClosedTicketRoute(ticketId);
        default :
            return '';
    }
};

export const getSupportBoxRoute = (box) => {
    switch (box) {
        case 'inbox':
            return getSupportInboxRoute();
        case 'outbox':
            return getSupportOutboxRoute();
        case 'closed':
            return getSupportClosedRoute();
        default :
            return '';
    }
};
