import {COMMON_RESET} from "./CommonReducer";

const initState = () => {
    return {
        support: null,
        loading: false,
        ticketsLoading: true,
        ticketsFilters: {},
        tickets: [],
        ticketsPagingCount: 0,
        ticketsPaging: {
            page_no: 1,
            results_per_page: 10
        },

        ticket: null,
        ticketLoading: null,

        ticketMessages: [],
        ticketMessagesLoading: true,

        supportClassifyTags: [],
        supportClassifyTagsLoading: false,

        supportReplying: false,
        ticketClosing: false,

        cannedLoading: true,
        cannedFilters: {},
        canned: [],
        cannedPagingCount: 0,
        cannedPaging: {
            page_no: 1,
            results_per_page: 10
        },

        cannedCreating: false,

        selectedCanned: null,
        selectedCannedLoading: false,

        updatingCanned: false,
        deletingCanned: false,

        creating: false,

	    updating: false,
    }
};

export const SUPPORT_UPDATE = 'SUPPORT_UPDATE';
export const SUPPORT_UPDATE_DONE = 'SUPPORT_UPDATE_DONE';

export const SUPPORT_TICKET_CREATE = 'SUPPORT_TICKET_CREATE';
export const SUPPORT_TICKET_CREATE_DONE = 'SUPPORT_TICKET_CREATE_DONE';

export const SUPPORT_TICKET_CLOSE = 'SUPPORT_TICKET_CLOSE';
export const SUPPORT_TICKET_CLOSE_DONE = 'SUPPORT_TICKET_CLOSE_DONE';

export const SUPPORT_TICKET_REPLY = 'SUPPORT_TICKET_REPLY';
export const SUPPORT_TICKET_REPLY_DONE = 'SUPPORT_TICKET_REPLY_DONE';

export const SUPPORT_FETCH = 'SUPPORT_FETCH';
export const SUPPORT_FETCH_DONE = 'SUPPORT_FETCH_DONE';

export const SUPPORT_FETCH_TAGS = 'SUPPORT_FETCH_TAGS';
export const SUPPORT_FETCH_TAGS_DONE = 'SUPPORT_FETCH_TAGS_DONE';

export const SUPPORT_TICKETS_FETCH = 'SUPPORT_TICKETS_FETCH';
export const SUPPORT_TICKETS_FETCH_DONE = 'SUPPORT_TICKETS_FETCH_DONE';
export const SUPPORT_TICKETS_SET_PAGING = 'SUPPORT_TICKETS_SET_PAGING';
export const SUPPORT_TICKETS_SET_FILTERS = 'SUPPORT_TICKETS_SET_FILTERS';

export const SUPPORT_TICKET_FETCH = 'SUPPORT_TICKET_FETCH';
export const SUPPORT_TICKET_FETCH_DONE = 'SUPPORT_TICKET_FETCH_DONE';

export const SUPPORT_TICKET_SELECT = 'SUPPORT_TICKET_SELECT';

export const SUPPORT_TICKET_MESSAGES_FETCH = 'SUPPORT_TICKET_MESSAGES_FETCH';
export const SUPPORT_TICKET_MESSAGES_FETCH_DONE = 'SUPPORT_TICKET_MESSAGES_FETCH_DONE';


export const SUPPORT_CANNED_FETCH = 'SUPPORT_CANNED_FETCH';
export const SUPPORT_CANNED_FETCH_DONE = 'SUPPORT_CANNED_FETCH_DONE';
export const SUPPORT_CANNED_SET_PAGING = 'SUPPORT_CANNED_SET_PAGING';
export const SUPPORT_CANNED_SET_FILTERS = 'SUPPORT_CANNED_SET_FILTERS';

export const SUPPORT_CANNED_CREATE = 'SUPPORT_CANNED_CREATE';
export const SUPPORT_CANNED_CREATE_DONE = 'SUPPORT_CANNED_CREATE_DONE';

export const SUPPORT_SELECTED_CANNED_FETCH = 'SUPPORT_SELECTED_CANNED_FETCH';
export const SUPPORT_SELECTED_CANNED_FETCH_DONE = 'SUPPORT_SELECTED_CANNED_FETCH_DONE';

export const SUPPORT_SELECTED_CANNED_UPDATE = 'SUPPORT_SELECTED_CANNED_UPDATE';
export const SUPPORT_SELECTED_CANNED_UPDATE_DONE = 'SUPPORT_SELECTED_CANNED_UPDATE_DONE';

export const SUPPORT_CANNED_DELETE = 'SUPPORT_CANNED_DELETE';
export const SUPPORT_CANNED_DELETE_DONE = 'SUPPORT_CANNED_DELETE_DONE';

const supportReducer = (state = initState(), action) => {
    switch (action.type) {
        case COMMON_RESET:
            return initState();
	    case SUPPORT_UPDATE:
		    return {...state, updating: true}
	    case SUPPORT_UPDATE_DONE:
	    	if (action.support)
		        return {...state, updating: false, support: action.support}
		    return {...state, updating: false}
        case SUPPORT_TICKET_CREATE:
            return {...state, creating: true}
        case SUPPORT_TICKET_CREATE_DONE:
            return {...state, creating: false}
        case SUPPORT_CANNED_DELETE:
            return {...state, deletingCanned: true};
        case SUPPORT_CANNED_DELETE_DONE:
            return {...state, deletingCanned: false};
        case SUPPORT_SELECTED_CANNED_UPDATE:
            return {...state, updatingCanned: true};
        case SUPPORT_SELECTED_CANNED_UPDATE_DONE:
            return {...state, updatingCanned: false, selectedCanned: action.canned};
        case SUPPORT_SELECTED_CANNED_FETCH:
            return {...state, selectedCannedLoading: true, selectedCanned: null};
        case SUPPORT_SELECTED_CANNED_FETCH_DONE:
            return {...state, selectedCannedLoading: false, selectedCanned: action.canned};
        case SUPPORT_CANNED_CREATE:
            return {...state, cannedCreating: true};
        case SUPPORT_CANNED_CREATE_DONE:
            return {...state, cannedCreating: false};
        case SUPPORT_TICKET_CLOSE:
            return {...state, ticketClosing: true};
        case SUPPORT_TICKET_CLOSE_DONE:
            return {...state, ticketClosing: false};
        case SUPPORT_TICKET_REPLY:
            return {...state, supportReplying: true};
        case SUPPORT_TICKET_REPLY_DONE:
            return {...state, supportReplying: false};
        case SUPPORT_FETCH_TAGS:
            return {...state, supportClassifyTagsLoading: true, supportClassifyTags: []};
        case SUPPORT_FETCH_TAGS_DONE:
            return {...state, supportClassifyTagsLoading: false, supportClassifyTags: action.tags};
        case SUPPORT_TICKET_MESSAGES_FETCH:
            return {...state, ticketMessagesLoading: true, ticketMessages: []};
        case SUPPORT_TICKET_MESSAGES_FETCH_DONE:
            return {...state, ticketMessagesLoading: false, ticketMessages: action.messages};
        case SUPPORT_TICKET_SELECT:
            return {...state, ticket: action.ticket};
        case SUPPORT_TICKET_FETCH:
            return {...state, ticketLoading: true, ticket: null};
        case SUPPORT_TICKET_FETCH_DONE:
            return {...state, ticketLoading: false, ticket: action.ticket};
        case SUPPORT_FETCH:
            // if silent we won't change the loading status,this allow to make background refresh every x seconds to retrieve the new message count without impacting the ui
            return {
                ...state,
                loading: true,
                support: null
            };
        case  SUPPORT_FETCH_DONE:
        	if(!action.support) return {...state, loading: false}
            return {...state, loading: false, support: action.support};
        case SUPPORT_TICKETS_FETCH:
            // if silent we won't change the loading status, this allow to make background refresh every x seconds without displaying the loader
            return {...state, ticketsLoading: action.silent ? state.ticketsLoading : true};
        case SUPPORT_TICKETS_FETCH_DONE:
            return {
                ...state,
                ticketsLoading: false,
                tickets: action.tickets,
                ticketsPagingCount: action.paging.count
            };
        case SUPPORT_TICKETS_SET_FILTERS :
            return {...state, ticketsFilters: action.filters};
        case SUPPORT_TICKETS_SET_PAGING :
            return {...state, ticketsPaging: action.paging};

        case SUPPORT_CANNED_FETCH:
            return {...state, cannedLoading: true};
        case SUPPORT_CANNED_FETCH_DONE:
            return {
                ...state,
                cannedLoading: false,
                canned: action.canned,
                cannedPagingCount: action.paging.count
            };
        case SUPPORT_CANNED_SET_FILTERS :
            return {...state, cannedFilters: action.filters};
        case SUPPORT_CANNED_SET_PAGING :
            return {...state, cannedPaging: action.paging};
        default:
            return state;
    }
};

export default supportReducer
