import React            from 'react';
import {useTranslation} from 'react-i18next';
import Grid             from '@material-ui/core/Grid';
import AppBarTitle      from '../../components/common/typography/AppBarTitle';
import Table            from '../../components/common/myorder/table/Table';
import Toolbar          from '../../components/common/typography/Toolbar';

const Orders = () => {
	const {t} = useTranslation();

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('order_history')}/>

			<Grid item xs={12}>
				<Toolbar title={t('order_history')}/>
			</Grid>

			<Grid item xs={12}>
				<Table/>
			</Grid>

		</Grid>
	);
};

export default Orders;