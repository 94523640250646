import React, {Fragment, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {Title} from "../../../typography/Title";
import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import Pie from "./Pie";
import PieChartNoData from "./PieChartNoData";
import {ColoredIcon, TableContainer} from "./style";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import uuid from "../../../../utils/uuid";

const ActivePieChart = ({title, noDataText, loading, stats, getCount = undefined, getName = undefined, getCellContent, tableHeaders = []}) => {
    const {t} = useTranslation();
    const [activeIndex, setActiveIndex] = useState(0);
    const key = uuid();

    const loadingData = [...Array(5).keys()];
    const displayLoading = () => loadingData.map((k) =>
        <TableRow key={'active-pie-row-loading-' + k + key}>
            {tableHeaders.map((header, cellIdx) =>
                <TableCell key={'active-pie-cell-loading-' + k + cellIdx + key}>
                    <Skeleton animation={loading ? 'wave' : false}/>
                </TableCell>)
            }
        </TableRow>);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Title>{title}</Title>
                <Typography variant="body2" color="textSecondary" component="p">
                    {t('store_report_product_info')}
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                {loading && <Skeleton height={300} style={{transform: 'scale(1,1)'}}/>}
                {(!loading && stats.length > 0) &&
                <Fragment>
                    <Pie active={true}
                         activeIndex={activeIndex}
                         setActiveIndex={setActiveIndex}
                         stats={stats}
                         getCount={getCount}
                         getName={getName}
                    />
                </Fragment>

                }

                {(!loading && stats.length < 1) && <PieChartNoData text={noDataText}/>}
            </Grid>
            <Grid item xs={12} md={6}>
                {tableHeaders.length > 0 &&
                <TableContainer>
                    <Table size='small' stickyHeader>
                        <TableHead>
                            <TableRow>
                                {tableHeaders.map((header, idx) =>
                                    <TableCell key={'active-pie-header-' + idx + key}>
                                        {header}
                                    </TableCell>)
                                }
                            </TableRow>
                        </TableHead>

                        {!loading && stats.map((stat, rowIdx) => {
                            return (
                                <TableRow hover onMouseEnter={() => setActiveIndex(rowIdx
                                )} selected={activeIndex === rowIdx} key={'active-pie-row-' + rowIdx + key}>
                                    {tableHeaders.map((header, cellIdx) =>

                                        <TableCell key={'active-pie-cell-' + rowIdx + cellIdx + key}>
                                            {cellIdx === 0 && <ColoredIcon color={stat.fill}/>}
                                            {getCellContent(rowIdx, cellIdx, stat)}
                                        </TableCell>
                                    )
                                    }
                                </TableRow>
                            )
                        })}

                        {loading && displayLoading()}
                        {(!loading && stats.length < 1) && displayLoading()}

                    </Table>
                </TableContainer>
                }
            </Grid>
        </Grid>
    );
};

export default ActivePieChart;