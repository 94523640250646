import React, {Fragment, useCallback, useEffect, useRef} from 'react';
import {connect} from 'react-redux'
import {
    fetch as fetchRealms,
    fetchAliases,
    fetchAliasForMetaObject,
    fetchAndSelect,
    fetchPaymentController,
} from "../../../store/actions/RealmAction";
import {fetchAndSelectFanclub, selectFanclub} from "../../../store/actions/fanclub/MembershipAction";
import {fetchAndSelectCms, loadFanClubCms, loadNewsCms, loadPagesCms} from "../../../store/actions/CmsAction";
import {useHistory}                                           from "react-router-dom";
import {fetchSupport, fetchSupportTags, supportSelect}        from "../../../store/actions/SupportAction";
import {ready}                                                from "../../../store/actions/CommonAction";
import Cookies                                                from "js-cookie";
import {fetchArtist}                                          from "../../../store/actions/ArtistAction";
import {fetchUrl}                                             from "../../../store/actions/RegistryAction";
import {fetchPlanner}                                         from "../../../store/actions/planner/ScheduleAction";
import {fetchLabel}                                           from "../../../store/actions/MusicAction";
import {fetchSelectedTemplate, fetchSelectedTemplateSettings} from "../../../store/actions/TemplateAction";
import {fetchCatalog}                                         from "../../../store/actions/store/StoreAction";
import {getPXLDashboardRoute}                                 from "../../../router/routes/pxl/factory/general";
import {setContext}                                           from '@karpeleslab/klbfw';

const Loader = ({selectedEvent, selectedSchedule, selectedRecord, selectedDeliverable,shippingFee, selectedProduct, selectedLabel, fetchLabel, selectedAlbum, selectedPlanner, fetchPlanner, fetchArtist, fetchUrl, ready, selectedPlan, selectedFanclub, resetSupport, selectedCanned, selectedTicket, support, entryData, selectedCms, realm, aliases, fetchAndSelectCms, fetchAliases, fetchFanClub, resetFanclub, user, loadingAliases, selectRealm, membershipUser, order,release, loadFanClubCms, loadNewsCms, loadPagesCms, fetchAliasForMetaObject, fetchSupport, fetchSupportTags, realmLoading, realmList, fetchRealms, fetchTemplate, fetchTemplateSettings, fetchCatalog, fetchPaymentController, massMailSegment, massMail}) => {
    const history = useHistory();

    useEffect(() => {
        fetchRealms()
            .then(list => {
                // Check for the last visited realm
                const lastVisitedRealm = Cookies.get('Realm__');
				// Get the realm info based on the previously visited
	            const filtered = list.filter(r => r.Realm__ === lastVisitedRealm);

	            if(filtered.length < 1 || !lastVisitedRealm) {
	            	// We don't have a previously visited realm
		            if(list.length > 0) {
		            	// But we have some realm, let select the first one
			            selectRealm(list[0].Realm__);
			            return;
		            }
		            // We have nothing to select, mark as ready
		            ready();
 	            }else { // We have a cookie and the filter result returned something
		            selectRealm(filtered[0].Realm__);
	            }

            })

        // eslint-disable-next-line
    }, [fetchRealms]);

    useEffect(() => {
        if (!realm) return;

        fetchAliases();

        // eslint-disable-next-line
    }, [realm]);

    const isFirstRun = useRef(true);
    useEffect(() => {
        //resetFanclub();
        //resetSupport();

        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        let dataToLoad = [];
        let totalToLoad = dataToLoad.length;

        const pushToLoad = (data) => {
            totalToLoad += 1;
            if ('then' in data) totalToLoad += 1;
            dataToLoad.push(data)
        };

        pushToLoad({method: fetchPaymentController, data: realm.Realm__});

        aliases.forEach((a) => {
            if (a.Object_Type === "Music_Label_Artist" && a.Alias === 'artist') {
                pushToLoad({method: fetchArtist, data: a.MetaObject__});
                return;
            }

            if (a.Object_Type === "Registry_Url" && a.Alias === 'url') {
                pushToLoad({
                    method: fetchUrl,
                    data: a.MetaObject__,
                    then: u => fetchTemplate(u.Site__)
                });
                return;
            }

            // Fanclub
            if (a.Object_Type === "Membership" && a.Alias === 'fanclub') {
                if (selectedFanclub && selectedFanclub.Membership__ === a.MetaObject__) return;
                pushToLoad({method: fetchFanClub, data: a.MetaObject__});
                return;
            }

            if (a.Object_Type === "Content_Cms" && a.Alias === 'fanclub') {
                pushToLoad({method: loadFanClubCms, data: a.MetaObject__});
                return;
            }

            if (a.Object_Type === "Planner" && a.Alias === 'planner') {
                if (selectedPlanner && selectedPlanner.Planner__ === a.MetaObject__) return;
                pushToLoad({method: fetchPlanner, data: a.MetaObject__});
                return;
            }

            if (a.Object_Type === "Music_Label" && a.Alias === 'label') {
                if (selectedLabel && selectedLabel.Music_Label__ === a.MetaObject__) return;
                pushToLoad({method: fetchLabel, data: a.MetaObject__});
                return;
            }

            if (a.Object_Type === "Content_Cms" && a.Alias === 'news') {
                pushToLoad({method: loadNewsCms, data: a.MetaObject__});
                return;
            }


            if (a.Object_Type === "Content_Cms" && a.Alias === 'pages') {
                pushToLoad({method: loadPagesCms, data: a.MetaObject__});
                return;
            }

            if (a.Object_Type === "Setting" && a.Alias === 'settings') {
                pushToLoad({method: fetchTemplateSettings, data: a.MetaObject__});
                return;
            }

            if (a.Object_Type === "Catalog" && a.Alias === 'store_catalog') {
                pushToLoad({method: fetchCatalog, data: a.MetaObject__});
                return;
            }

            if (a.Object_Type === "Support" && a.Alias === 'support') {
                // this will prevent a reload of the ui if we are loading a page that require a support
                // eg. /canned/ID will the loader will load the support to retrieve the realm
                if (support && support.Support__ === a.MetaObject__) return;
                pushToLoad({
                    method: fetchSupport, data: a.MetaObject__,
                    then: (s) => {
                        fetchSupportTags(s.Classify.Classify__)
                    }
                });

            }

        });


        const checkReady = () => {
            if (totalToLoad < 1) {
                ready()
            }
        };
        const resourceLoaded = () => {
            totalToLoad = totalToLoad - 1;
            checkReady();
        };

        dataToLoad.forEach((toLoad) => {
            let promise = toLoad.method(toLoad.data);
            if ('then' in toLoad) {
                promise.then(toLoad.then).then(resourceLoaded);
            }
            promise.then(resourceLoaded);
        });
        checkReady();

        // eslint-disable-next-line
    }, [aliases]);


    const fetchAndSelectRealm = useCallback((RealmId) => {
        selectRealm(RealmId)
            .catch(() => {
                history.push(getPXLDashboardRoute());
                // something is wrong, maybe we don"t have right to this realm ?
                //Just redirect to the homepage to be safe.
            })
    }, [selectRealm, history]);


    // If we are seeing a page that a linked to an user (user page / user order / membership etc...)
    // we can force the selected realm base on this user (this will update the RealmSelector selectbox and allow enable/disable the different menu link)
    const syncRealmFromUser = useCallback((usr) => {
        if (realm && usr.Realm__ === realm.Realm__) return;
        //select right realm
        fetchAndSelectRealm(usr.Realm__)
    }, [realm, fetchAndSelectRealm]);

    // This effect will ensure us the right realm is selected based on the user
    useEffect(() => {
        if (!user) return;
        syncRealmFromUser(user)
    }, [user, syncRealmFromUser]);

    // This effect will ensure us the right realm is selected based on the mass mail segment
    useEffect(() => {
        if (!massMailSegment) return;
        syncRealmFromUser(massMailSegment)
    }, [massMailSegment, syncRealmFromUser]);

    // This effect will ensure us the right realm is selected based on the mass mail
    useEffect(() => {
        if (!massMail) return;
        syncRealmFromUser(massMail)
    }, [massMail, syncRealmFromUser]);

    // This effect will ensure us the right realm is selected based on the membershipUser
    useEffect(() => {
        if (!membershipUser) return;
        syncRealmFromUser(membershipUser.User)

    }, [membershipUser, syncRealmFromUser]);

    // This effect will ensure us the right realm is selected based on the order
    useEffect(() => {
        if (!order) return;
        syncRealmFromUser(order.User)
    }, [order, syncRealmFromUser]);


    // This will ensure we have the right cms selected base on the entryData loaded
    useEffect(() => {
        if (!entryData) return;
        if (selectedCms && selectedCms.Content_Cms__ === entryData.Content_Cms__) return;
        fetchAndSelectCms(entryData.Content_Cms__);


    }, [entryData, selectedCms, fetchAndSelectCms]);


    // Ensure the right realm is selected based on the given MetaObjectId
    // This can only work with the following prerequisite :
    // A given MetaObjectId can only appears once in the Realm/Alias table.
    // This is not necessary true but it's the prerequisite we fixed only for Pxl services
    function reverseLookupRealm(MetaObjectId) {
        const selectedRealmId = realm ? realm.Realm__ : null;
        // Check if the MetaObject is present in the aliases
        const alias = aliases.filter((a) => a.MetaObject__ === MetaObjectId);
        // We found the MetaObject in the alias
        if (alias.length > 0) {
            //but the selected Realm is not the right one/ So switch to it.
            if (alias[0].Realm__ !== selectedRealmId) {
                fetchAndSelectRealm(alias[0].Realm__);
                return;
            }

            // The Realm of the MetaObject is the same as the selected realm, nothing to do everything is fine.
            return;
        }

        // We didn't find the MetaObject in the aliases, so we will need to try to find this MetaObject in the alias in order to retrieve the realm.
        fetchAliasForMetaObject(MetaObjectId)
            .then(a => {
                if (a == null) {
                    // something is horribly wrong.
                    history.push(getPXLDashboardRoute());
                    return;
                }

                // !here we go, we manage to find the right Realm we can now lookup it up and select it ! perfect !
                fetchAndSelectRealm(a.Realm__);
            })
    }


    // Ensure the right realm is selected based on the selected cms
    useEffect((data) => {
        if (!selectedCms) return;
        if (selectedPlanner && selectedPlanner.Content_Cms__ === selectedCms.Content_Cms__) {
            reverseLookupRealm(selectedPlanner.Planner__);
        } else
            reverseLookupRealm(selectedCms.Content_Cms__);
        // eslint-disable-next-line
    }, [selectedCms]);


    // Ensure the right realm is selected based on the selected support
    useEffect(() => {
        if (!support) return;
        reverseLookupRealm(support.Support__);

        // eslint-disable-next-line
    }, [support]);


    // Select the right support based on the selected ticket
    useEffect(() => {
        if (!selectedTicket) return;
        if (!support || support.Support__ !== selectedTicket.Support__) {
            setContext('g','all'); // We might comme from a default selected realm with no access
            fetchSupport(selectedTicket.Support__);
        }

        // eslint-disable-next-line
    }, [selectedTicket]);

    // Select the right support based on the selected canned response
    useEffect(() => {
        if (!selectedCanned) return;
        if (!support || support.Support__ !== selectedCanned.Support__) {
            setContext('g','all'); // We might comme from a default selected realm with no access
            fetchSupport(selectedCanned.Support__);
        }

        // eslint-disable-next-line
    }, [selectedCanned]);

    // Select the right membership based on the selected plan
    useEffect(() => {
        if (!selectedPlan) return;
        if (!selectedFanclub || selectedFanclub.Membership__ !== selectedPlan.Membership__) {
            setContext('g','all'); // We might comme from a default selected realm with no access
            fetchFanClub(selectedPlan.Membership__);
        }

        // eslint-disable-next-line
    }, [selectedPlan]);


    // Ensure the right realm is selected based on the selected fanclub
    useEffect((data) => {
        if (!selectedFanclub) return;
        reverseLookupRealm(selectedFanclub.Membership__);

        // eslint-disable-next-line
    }, [selectedFanclub]);

    // Ensure the right realm is selected based on the selected Schedule
    useEffect((data) => {
        if (!selectedSchedule) return;
        reverseLookupRealm(selectedSchedule.Planner__);

        // eslint-disable-next-line
    }, [selectedSchedule]);

    // Ensure the right realm is selected based on the selected Event
    useEffect((data) => {
        if (!selectedEvent) return;
        reverseLookupRealm(selectedEvent.Planner__);

        // eslint-disable-next-line
    }, [selectedEvent]);

    // Ensure the right realm is selected based on the selected deliverable
    useEffect((data) => {
        if (!selectedDeliverable) return;
        reverseLookupRealm(selectedDeliverable.Catalog__);

        // eslint-disable-next-line
    }, [selectedDeliverable]);

    // Ensure the right realm is selected based on the selected shippingFee
    useEffect((data) => {
        if (!shippingFee) return;
        reverseLookupRealm(shippingFee.Catalog__);

        // eslint-disable-next-line
    }, [shippingFee]);

    // Ensure the right realm is selected based on the selected release
    useEffect((data) => {
        if (!release) return;
        reverseLookupRealm(release.Music_Label__);

        // eslint-disable-next-line
    }, [release]);

    // Ensure the right realm is selected based on the selected product
    useEffect((data) => {
        if (!selectedProduct || !('data' in selectedProduct) || !selectedProduct.data.Catalog__) return;
        reverseLookupRealm(selectedProduct.data.Catalog__);

        // eslint-disable-next-line
    }, [selectedProduct]);

    // Ensure the right realm is selected based on the selected album
    useEffect((data) => {
        if (!selectedAlbum) return;
        let artistId = null;
        for (let i = 0; i < selectedAlbum.Artists.length; i++) {
            if (selectedAlbum.Artists[i].Role === 'artist') {
                artistId = selectedAlbum.Artists[i].Music_Label_Artist__;
                break;
            }
        }

        reverseLookupRealm(artistId);

        // eslint-disable-next-line
    }, [selectedAlbum]);

    // Ensure the right realm is selected based on the selected record
    useEffect((data) => {
        if (!selectedRecord) return;
        let artistId = null;
        for (let i = 0; i < selectedRecord.Artists.length; i++) {
            if (selectedRecord.Artists[i].Role === 'artist') {
                artistId = selectedRecord.Artists[i].Music_Label_Artist__;
                break;
            }
        }

        reverseLookupRealm(artistId);

        // eslint-disable-next-line
    }, [selectedRecord]);

    return <Fragment/>
};

const mapStateToProps = (state) => {
    return {
        membershipUser: state.membership.membershipUser,
        user: state.realm.selectedUser,
        loadingAliases: state.realm.loadingAliases,
        order: state.order.order,
        realm: state.realm.selected,
        aliases: state.realm.aliases,
        entryData: state.cms.entryData,
        selectedCms: state.cms.selected,
        support: state.support.support,
        selectedTicket: state.support.ticket,
        selectedCanned: state.support.selectedCanned,
        selectedFanclub: state.membership.selectedFanclub,
        selectedPlan: state.plan.selectedPlan,
        realmLoading: state.realm.loading,
        realmList: state.realm.realms,
        selectedPlanner: state.schedule.planner,
        selectedAlbum: state.album.album,
        selectedLabel: state.music.label,
        selectedRecord: state.record.record,
        selectedSchedule: state.schedule.schedule,
        selectedEvent: state.event.event,
        selectedDeliverable: state.deliverable.deliverable,
        shippingFee: state.shippingFee.fee,
        selectedProduct: state.product.productProperties,
	    release: state.release.release,
        massMailSegment: state.massMail.massMailSegment,
        massMail: state.massMail.massMail,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        ready: () => dispatch(ready()),
        fetchRealms: () => dispatch(fetchRealms()),
        fetchPlanner: (id) => dispatch(fetchPlanner(id)),
        selectRealm: (realmId) => dispatch(fetchAndSelect(realmId)),
        fetchSupport: (id) => dispatch(fetchSupport(id)),
        fetchSupportTags: (classifyId) => dispatch(fetchSupportTags(classifyId)),
        fetchAliases: (data) => dispatch(fetchAliases()),
        fetchFanClub: (id) => dispatch(fetchAndSelectFanclub(id)),
        resetFanclub: (id) => dispatch(selectFanclub(null)),
        resetSupport: (id) => dispatch(supportSelect(null)),
        loadFanClubCms: (id) => dispatch(loadFanClubCms(id)),
        loadNewsCms: (id) => dispatch(loadNewsCms(id)),
        loadPagesCms: (id) => dispatch(loadPagesCms(id)),
        fetchAndSelectCms: (id) => dispatch(fetchAndSelectCms(id)),
        fetchAliasForMetaObject: (id) => dispatch(fetchAliasForMetaObject(id)),
        fetchArtist: (id) => dispatch(fetchArtist(id)),
        fetchUrl: (id) => dispatch(fetchUrl(id)),
        fetchLabel: (id) => dispatch(fetchLabel(id)),
        fetchTemplate: (site) => dispatch(fetchSelectedTemplate(site)),
        fetchTemplateSettings: (settingId) => dispatch(fetchSelectedTemplateSettings(settingId)),
        fetchCatalog: (id) => dispatch(fetchCatalog(id)),
        fetchPaymentController: (realmId) => dispatch(fetchPaymentController(realmId)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Loader);
