import React, {useCallback, useEffect, useState} from 'react';
import Grid               from '@material-ui/core/Grid';
import TextField          from '@material-ui/core/TextField';
import FormControl        from '@material-ui/core/FormControl';
import InputLabel         from '@material-ui/core/InputLabel';
import Select             from '@material-ui/core/Select';
import FormHelperText     from '@material-ui/core/FormHelperText';
import {useTranslation}   from 'react-i18next';
import Price              from '../../../inputs/Price';
import PriceFeeNotice     from '../../../feeback/PriceFeeNotice';
import PlanPeriodSelector from '../../../inputs/PlanPeriodSelector';
import PlanFreeSelector   from "../../../inputs/PlanFreeSelector";
import ColorPicker        from '../../../inputs/ColorPicker/ColorPicker';
import FormRequiredField  from '../../../feeback/FormRequiredField';

const Form = ({plan, setPlan, setIsValid, disabled = false, allowFree = true}) => {
    const {t} = useTranslation();

    const visibleLabel = React.useRef(null);
    const [visibleLabelWidth, setVisibleLabelWidth] = useState(0);


    const createSetter = name => e => {
        let v = e;
        if (e.target) v = e.target.value;
        setPlan({...(plan ?? {}), [name]: v});
    };

    const getValue = useCallback((name, defaultValue) => {
        return (plan ?? {})[name] ?? defaultValue;
    }, [plan]);

    useEffect(() => {
        setVisibleLabelWidth(visibleLabel.current.offsetWidth);
    }, []);

    useEffect(() => {
        if (getValue('free', 'N') === 'N') {
            if(!getValue('period', '').trim() ||
                getValue('price', '') === '') {
                setIsValid(false);
                return;
            }
        }

        if (!getValue('name', '').trim() ||
            !getValue('description', '') ||
            !getValue('visible', '').trim() ||
            !getValue('free', '').trim() ||
            !getValue('tag', '').trim()) {
            setIsValid(false);

            return;
        }

        setIsValid(true);
    }, [setIsValid, plan, getValue]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <PriceFeeNotice/>
            </Grid>

            <FormRequiredField/>

            <Grid item lg={6} md={12}>
                <TextField required helperText={t('plan_name_helperText')} label={t('plan_name_lbl')} variant='outlined'
                           fullWidth value={getValue('name', '')}
                           onChange={createSetter('name')} disabled={disabled}/>
            </Grid>

            <Grid item md={12} lg={6}>
                <PlanFreeSelector
                    disabled={disabled || !allowFree}
                    required
                    value={getValue('free', 'N')}
                    setValue={createSetter('free')}
                />
            </Grid>

            <Grid item lg={6} xs={12}>
                <FormControl variant='outlined' fullWidth disabled={disabled} required>
                    <InputLabel ref={visibleLabel} htmlFor='visibility-select'>{t('visibility_title')}</InputLabel>
                    <Select
                        native
                        required
                        disabled={disabled}
                        value={getValue('visible', '')}
                        onChange={createSetter('visible')}
                        labelWidth={visibleLabelWidth}
                        inputProps={{
                            id: 'visibility-select',
                        }}
                    >
                        <option value=''/>
                        <option value='Y'>{t('yes_opt')}</option>
                        <option value='N'>{t('no_opt')}</option>
                    </Select>
                    <FormHelperText>{t('plan_visibility_helper')}</FormHelperText>
                </FormControl>

            </Grid>
            <Grid item lg={6} xs={12}>
                <TextField label={t('priority_lbl')}
                           fullWidth
                           variant='outlined'
                           type='number'
                           value={getValue('priority', 0)}
                           required
                           onChange={createSetter('priority')}
                           helperText={t('priority_helper')}
                           disabled={disabled}/>

            </Grid>

            <Grid item xs={6}>
                <TextField label={t('plan_tag_lbl')}
                           helperText={t('article_tag_desc')}
                           fullWidth
                           variant='outlined'
                           value={getValue('tag', '')}
                           onChange={createSetter('tag')}
                           required disabled={disabled}
                />
            </Grid>

            <Grid item lg={6} xs={12}>
                <ColorPicker
                    label={t('plan_color_lbl')}
                    helperText={t('plan_color_helperText')}
                    value={getValue('color', '')}
                    setValue={createSetter('color')}
                    fullWidth
                    variant='outlined'
                    disabled={disabled}
                />
            </Grid>

            {getValue('free', 'N') !== 'Y' &&  <Grid item lg={6} md={12}>
                <PlanPeriodSelector
                    required
                    value={getValue('period', '1m')}
                    setValue={createSetter('period')}
                />
            </Grid>}

            {getValue('free', 'N') !== 'Y' && <Grid item md={12}>
                <Price
                    variant='outlined'
                    fullWidth
                    required
                    setVatIncluded={createSetter('vatIncluded')}
                    vatIncluded={getValue('vatIncluded', false)}
                    value={getValue('price', '')}
                    onValueChange={createSetter('price')}
                    label={getValue('vatIncluded', false) ? t('plan_price_vat_lbl') : t('plan_price_lbl')}
                    helperText={getValue('vatIncluded', false) ? t('plan_price_vat_helperText') : t('plan_price_helperText')}
                    disabled={disabled}
                />
            </Grid>
            }

            <Grid item xs={12}>
                <TextField label={t('plan_description_lbl')}
                           helperText={t('plan_description_placeholder')}
                           placeholder={t('plan_description_placeholder')}
                           fullWidth
                           variant='outlined'
                           multiline
                           rows={5} rowsMax={Infinity}
                           value={getValue('description', '')}
                           onChange={createSetter('description')}
                           required
                           disabled={disabled}/>
            </Grid>


        </Grid>
    );
};

export default Form;
