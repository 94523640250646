import React from 'react';
import {Trans, useTranslation} from "react-i18next";
import uuid from "../../../../utils/uuid";
import {Title} from "../../../typography/Title";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Table from "./Table";
import Create from "./Create";

const Links = ({value, setValue, disabled = false, auto = false, isReview = false}) => {
    const {t} = useTranslation();

    const onAdd = (v) => {
        setValue([
            ...value,
            {
                Music_Label_Album_Link__: uuid(),
                Store: v.Store,
                Link: v.Link,
                Index: value.length,
                Visible: 'Y'
            }
        ])
    };

    const onDelete = (link) => {
        const newValue = [];
        value.forEach(v => {
            const vCopy = {...v};
            if (vCopy.Music_Label_Album_Link__ === link.Music_Label_Album_Link__) return;
            if (vCopy.Index > link.Index) vCopy.Index--;
            newValue.push(vCopy);
        });

        setValue(newValue);
    };

    const onVisibilityChange = (link, newVisibility) => {
        const newValue = [];
        value.forEach(v => {
            const vCopy = {...v};
            if (vCopy.Music_Label_Album_Link__ === link.Music_Label_Album_Link__) {
                vCopy.Visible = newVisibility;
            }
            newValue.push(vCopy);
        });

        setValue(newValue);
    };

    const changeOrder = (link, offset) => {
        if (value.length < 2) return;
        const vCopy = [...value];

        for (let i = 0; i < vCopy.length; i++) {
            if (vCopy[i].Music_Label_Album_Link__ !== link.Music_Label_Album_Link__) continue;

            const targetIndex = offset > 0 ? i + 1 : i - 1;
            const targetOffset = offset > 0 ? -1 : +1;
            if (targetIndex < 0 || targetIndex >= vCopy.length) {
                break;
            } // something wrong

            const tmp = vCopy[i];
            tmp.Index += offset;

            vCopy[i] = vCopy[targetIndex];
            vCopy[targetIndex] = tmp;
            vCopy[i].Index += targetOffset;

            break;
        }

        setValue(vCopy);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Title>{t('album_links_title')}</Title>
            </Grid>
            {!isReview && <Grid item xs={12}>
                <Grid container spacing={3} justify="space-between">
                    <Grid item>
                        <Typography variant="body2" color="textSecondary" component='p'>
                            <Trans i18nKey={auto ? 'album_links_text_auto' : 'album_links_text'}>
                                sample <br/>
                                <strong>sample</strong>
                            </Trans>
                        </Typography>

                    </Grid>
                </Grid>
            </Grid>}
            {!isReview && <Grid item xs={12} md={8}>
                <Create disabled={disabled} onAdd={onAdd}/>
            </Grid>}
            <Grid item xs={12}>
                <Table links={value} onDelete={onDelete} onVisibilityChange={onVisibilityChange}
                       changeOrder={changeOrder} disabled={disabled || isReview}/>
            </Grid>
        </Grid>
    );
};

export default Links;