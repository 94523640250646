const router = require('./router');

// export renderToString function for server side rendering
global.renderToString = router.renderToString;


// start app if it in the browser
if(typeof window !== 'undefined') {
    // Start main application here
    router.run();
}
