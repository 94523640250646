import React, {useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../../inputs/Button";
import {connect} from 'react-redux'
import {createCategory} from "../../../../store/actions/TagAction";

const CreateCategoryDialog = ({classifyId, open, setOpen, creating, createCategory, onCreated}) => {
    const {t} = useTranslation();
    const [name, setName] = useState('');
    const [saveDisabled, setSaveDisabled] = useState(true);

    useEffect(() => {
        setSaveDisabled(name.trim() === '')
    }, [name, setSaveDisabled]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleCreate = () => {
        createCategory(classifyId, name)
            .then(d => {
                onCreated();
                setName('');
                setOpen(false)
            })
    };


    return (
        <Dialog
            open={open}
            aria-labelledby={t('create_category')}
            aria-describedby={t('create_category')}
        >
            <DialogTitle id="create-category-title">{t('create_category')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="create-category-description">
                </DialogContentText>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField label={t('category_name_lbl')} fullWidth variant="outlined" value={name}
                                   required
                                   disabled={creating}
                                   onChange={e => setName(e.target.value)}
                                   helperText={t('category_name_helperText')}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={handleClose} disabled={creating}>
                    {t('close_btn')}
                </Button>
                <Button color="primary" onClick={handleCreate} variant='contained' disabled={creating || saveDisabled}
                        loading={creating}>
                    {t('create_btn')}
                </Button>
            </DialogActions>
        </Dialog>

    );
};

const mapStateToProps = (state) => {
    return {
        creating: state.tag.creatingCategory,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        createCategory: (classifyId, name) => dispatch(createCategory(classifyId, name)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCategoryDialog);