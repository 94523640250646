import React                            from 'react';
import {connect}                        from 'react-redux';
import SelectRealm                      from '../../SelectRealm';
import {Grid}                           from '@material-ui/core';
import Redirect                         from '../../../components/Route/Redirect';
import {getPXLDashboardRoute}           from '../../../router/routes/pxl/factory/general';
import HasRight                         from '../../../components/Route/HasRight';
import AppBarTitle                      from '../../../components/common/typography/AppBarTitle';
import {useTranslation}                 from 'react-i18next';
import Toolbar                          from '../../../components/common/typography/Toolbar';
import Button                           from '../../../components/common/inputs/Button';
import {Link}                           from 'react-router-dom';
import {getSiteNotificationCreateRoute} from '../../../router/routes/site/factory/content';
import Table                            from '../../../components/common/massMail/table/Table';

const NotifyList = ({selectedRealm}) => {
	const {t} = useTranslation();

	if (!selectedRealm) return <SelectRealm/>;

	return (
		<Grid container spacing={3}>
			<HasRight manager deniedComponent={<Redirect target={getPXLDashboardRoute()}/>}>
				<AppBarTitle title={t('notify_list_title')}/>
				<Grid item xs={12}>
					<Toolbar title={t('notify_list_title')}>
						<Button color="primary" variant="contained" component={Link}
						        to={getSiteNotificationCreateRoute()}>
							{t('create_notification')}
						</Button>
					</Toolbar>
				</Grid>

				<Grid item xs={12}>
					<Table realmId={selectedRealm.Realm__}/>
				</Grid>
			</HasRight>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
	};
};

export default connect(mapStateToProps, null)(NotifyList);
