import React, {useState} from 'react';
import TableRow from "@material-ui/core/TableRow";
import Skeleton from "@material-ui/lab/Skeleton";
import TableCell from "@material-ui/core/TableCell";
import {useTranslation} from "react-i18next";
import Button from "../../../inputs/Button";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {tokenTypeToIcon} from "../../../../utils/oauth";
import DeleteDialog from "../DeleteDialog";

const Row = ({token, loading}) => {
    const {t} = useTranslation();
    const displayToken = (!loading && token);
    const [deleteDialogOpened, setDeleteDialogOpened] = useState(false);
    return (
        <TableRow>
            <TableCell padding='checkbox'>
                {!displayToken && <Skeleton width={40} height={40}/>}
                {(displayToken) && tokenTypeToIcon(token.OAuth2_Consumer.Token_Name)}
            </TableCell>

            <TableCell>
                {!displayToken && <Skeleton/>}
                {displayToken && <span>{token.Service}</span>}
            </TableCell>

            <TableCell>
                {!displayToken && <Skeleton/>}
                {displayToken && <span>{token.Name}</span>}
            </TableCell>

            <TableCell align="right">
                {!displayToken && <Skeleton/>}
                {(displayToken && token.Token_Type !== 'stripe') &&
                <Button size='small' color='secondary'
                        onClick={e => setDeleteDialogOpened(true)}
                        startIcon={<DeleteForeverIcon/>}
                >
                    {t('delete_btn')}
                </Button>
                }
            </TableCell>
            {(displayToken && token.Token_Type !== 'stripe') &&
            <DeleteDialog open={deleteDialogOpened} setOpen={setDeleteDialogOpened} token={token}/>
            }
        </TableRow>
    );
};

export default Row;