import {COMMON_RESET} from './CommonReducer';
import {USER_LOGOUT}  from './system/UserReducer';
export const FINISHED_STATUS = ['completed', 'refunded'];
const initState = () => {
	return {
		myOrdersLoading: true,
		myOrdersFilters: {
			Status: {'$in': [...FINISHED_STATUS, 'paid']},
			sort: {'Created': 'DESC'}
		},
		myOrders: [],
		myOrdersPagingCount: 0,
		myOrdersPaging: {
			page_no: 1,
			results_per_page: 10
		},

		myOrder: null,
		myOrderLoading: true,


		ordersLoading: true,
		ordersFilters: {
			sort: {'Created': 'DESC'}
		},
		orders: [],
		ordersPagingCount: 0,
		ordersPaging: {
			page_no: 1,
			results_per_page: 10
		},

		orderLoading: true,
		order: null,

		orderRefunding: false,

		vatLoading: true,
		vat: null,

		processingOrder: false,
	};
};

export const ORDER_MY_ORDERS_FETCH = 'ORDER_MY_ORDERS_FETCH';
export const ORDER_MY_ORDERS_FETCH_DONE = 'ORDER_MY_ORDERS_FETCH_DONE';
export const ORDER_MY_ORDERS_SET_FILTERS = 'ORDER_MY_ORDERS_SET_FILTERS';
export const ORDER_MY_ORDERS_SET_PAGING = 'ORDER_MY_ORDERS_SET_PAGING';

export const ORDER_MY_ORDER_FETCH = 'ORDER_MY_ORDER_FETCH';
export const ORDER_MY_ORDER_FETCH_DONE = 'ORDER_MY_ORDER_FETCH_DONE';

export const ORDER_ORDERS_FETCH = 'ORDER_ORDERS_FETCH';
export const ORDER_ORDERS_FETCH_DONE = 'ORDER_ORDERS_FETCH_DONE';
export const ORDER_ORDERS_SET_FILTERS = 'ORDER_ORDERS_SET_FILTERS';
export const ORDER_ORDERS_SET_PAGING = 'ORDER_ORDERS_SET_PAGING';


export const ORDER_ORDER_FETCH = 'ORDER_ORDER_FETCH';
export const ORDER_ORDER_FETCH_DONE = 'ORDER_ORDER_FETCH_DONE';

export const ORDER_ORDER_REFUND = 'ORDER_ORDER_REFUND';
export const ORDER_ORDER_REFUND_DONE = 'ORDER_ORDER_REFUND_DONE';


export const ORDER_FETCH_VAT = 'ORDER_FETCH_VAT';
export const ORDER_FETCH_VAT_DONE = 'ORDER_FETCH_VAT_DONE';

export const ORDER_PROCESS = 'ORDER_PROCESS';
export const ORDER_PROCESS_DONE = 'ORDER_PROCESS_DONE';

const orderReducer = (state = initState(), action) => {
	switch (action.type) {
		case USER_LOGOUT:
			return {...initState()};
		case COMMON_RESET:
			return {
				...initState(),
				myOrder: state.myOrder,
				myOrderLoading: state.myOrderLoading,
				myOrdersLoading: state.myOrdersLoading,
				myOrdersFilters: state.myOrdersFilters,
				myOrders: state.myOrders,
				myOrdersPagingCount: state.myOrdersPagingCount,
				myOrdersPaging: state.myOrdersPaging,
			};
		case ORDER_FETCH_VAT:
			return {...state, vatLoading: true, vat: null};
		case ORDER_FETCH_VAT_DONE:
			return {...state, vatLoading: false, vat: action.vat};
		case ORDER_ORDER_REFUND:
			return {...state, orderRefunding: true};
		case ORDER_ORDER_REFUND_DONE:
			return {...state, orderRefunding: false, order: action.order};
		case ORDER_ORDER_FETCH:
			return {...state, orderLoading: true, order: null};
		case ORDER_ORDER_FETCH_DONE:
			return {...state, orderLoading: false, order: action.order};
		case ORDER_ORDERS_SET_PAGING:
			return {...state, ordersPaging: action.paging};
		case ORDER_ORDERS_SET_FILTERS:
			return {...state, ordersFilters: action.filters};
		case ORDER_ORDERS_FETCH:
			return {...state, ordersLoading: true};
		case ORDER_ORDERS_FETCH_DONE:
			return {
				...state,
				ordersLoading: false,
				orders: action.orders,
				ordersPagingCount: action.paging.count
			};
        case ORDER_MY_ORDERS_SET_PAGING:
            return {...state, myOrdersPaging: action.paging};
        case ORDER_MY_ORDERS_SET_FILTERS:
            return {...state, myOrdersFilters: action.filters};
        case ORDER_MY_ORDERS_FETCH:
            return {...state, myOrdersLoading: true};
        case ORDER_MY_ORDERS_FETCH_DONE:
            return {
                ...state,
                myOrdersLoading: false,
                myOrders: action.orders,
                myOrdersPagingCount: action.paging.count
            };
		case ORDER_MY_ORDER_FETCH:
			return {...state, myOrderLoading: true, myOrder: null};
		case ORDER_MY_ORDER_FETCH_DONE:
			return {...state, myOrderLoading: false, myOrder: action.order};
		case ORDER_PROCESS:
			return {...state, processingOrder: true};
		case ORDER_PROCESS_DONE:
			return {...state, processingOrder: false};
		default:
			return state;
	}
};

export default orderReducer;