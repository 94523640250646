import React, { useState } from 'react';
import { Grid }            from '@material-ui/core';
import Table               from './survey/table/Table';
import { useTranslation }  from 'react-i18next';
import Toolbar             from '../../typography/Toolbar';
import HasRight            from '../../../Route/HasRight';
import Button              from '../../inputs/Button';
import AddIcon             from '@material-ui/icons/Add';
import ManageDialog        from './survey/ManageDialog';
import Report              from './survey/Report';

const Survey = ({ event }) => {
	const { t } = useTranslation();
	const [selectedQuestion, setSelectedQuestion] = useState(null);
	const [showManage, setShowManage] = useState(false);

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Toolbar title={t('event_survey_title')}>
					<HasRight manager hideLoaging>
						<Button endIcon={<AddIcon/>} variant='contained' color='primary'
						        onClick={e => {
							        setSelectedQuestion(null);
							        setShowManage(true);
						        }}>
							{t('create_btn')}
						</Button>
					</HasRight>
				</Toolbar>
			</Grid>
			<Grid item xs={12}>
				<Table event={event} onSelected={q => {
					setSelectedQuestion(q);
					setShowManage(true);
				}}/>
			</Grid>
			<HasRight manager hideLoaging>
				<ManageDialog event={event} question={selectedQuestion} open={showManage} setOpen={setShowManage}/>

				<Grid item xs={12}>
					<Report event={event}/>
				</Grid>
			</HasRight>
		</Grid>
	);
};

export default Survey;
