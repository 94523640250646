import React            from 'react';
import AppBarTitle      from '../components/common/typography/AppBarTitle';
import Grid             from '@material-ui/core/Grid';
import Form             from '../components/common/contact/Form';
import {useTranslation} from 'react-i18next';
import Toolbar          from '../components/common/typography/Toolbar';

const Contact = () => {
	const {t} = useTranslation();

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('contact_us_title')}/>

			<Grid item xs={12}>
				<Toolbar title={t('contact_us_title')}/>
			</Grid>

			<Grid item xs={12}>
				<Form supportId="@support"/>
			</Grid>
		</Grid>
	);
};

export default Contact;
