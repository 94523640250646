import React, {useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next';
import InputI18N from "../../../inputs/InputI18n";
import {requiredCheck as nameRequiredCheck} from "../../../../utils/i18nInput";
import Button from "../../../inputs/Button";

const CreateArtistDialog = ({open, setOpen, creating, nameValue, handleCreate}) => {
    const {t} = useTranslation();
    const [name, setName] = useState(nameValue.hasKanji() ? {'ja-JP': nameValue} : {'en-US': nameValue});
    const [needDetailedName, setNeedDetailedName] = useState(nameValue.hasKanji());
    const [saveEnabled, setSaveEnabled] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (!nameRequiredCheck(name, needDetailedName)) {
            setSaveEnabled(false);
            return;
        }
        setSaveEnabled(true)
    }, [name, needDetailedName, setSaveEnabled]);

    useEffect(() => {
        const hasKanji = nameValue.hasKanji();
        setName(hasKanji ? {'ja-JP': nameValue} : {'en-US': nameValue});
        setNeedDetailedName(hasKanji)
    }, [nameValue]);

    return (

        <Dialog
            open={open}
            aria-labelledby={t('create_artist')}
            aria-describedby={t('create_artist')}
            fullWidth={true}
        >
            <DialogTitle id="create-artist-title">{t('create_artist')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="create-artist-description">
                </DialogContentText>

                <InputI18N spacing={2} value={name}
                           disabled={creating}
                           onChange={setName}
                           onDetailedNameChange={setNeedDetailedName}
                           labelKey='artist_name'
                />


            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={handleClose} disabled={creating}>
                    {t('close_btn')}
                </Button>
                <Button color="primary" variant='contained' loading={creating} disabled={!saveEnabled || creating}
                        onClick={() => handleCreate(name)}>
                    {t('create_btn')}
                </Button>
            </DialogActions>
        </Dialog>

    );
};

const mapStateToProps = (state) => {
    return {
        creating: state.artist.artistMinimalCreating,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateArtistDialog);
