import React, {useState}                 from 'react';
import {useTranslation}                  from 'react-i18next';
import {Grid}                            from '@material-ui/core';
import Form                              from './Form';
import Button                            from '../../inputs/Button';
import {useNetworkMassMailSegmentCreate} from '../../../../hooks/api/network/massmail/useMassMailSegment';
import {useHistory}                      from 'react-router-dom';
import {getSiteNotificationRoute}        from '../../../../router/routes/site/factory/content';
import {connect}                         from 'react-redux';
import FormRequiredField                 from '../../feeback/FormRequiredField';

const CreateForm = ({realmId, support}) => {
	const {t} = useTranslation();
	const [data, setData] = useState({Email: support.Email, Visible: 'Y'});
	const [isValid, setIsValid] = useState(false);
	const [create, creating] = useNetworkMassMailSegmentCreate();

	const history = useHistory();

	const handleSave = () => {
		create(realmId, data['Name'].trim(), data['Email'].trim(), data['Subject_Prefix']?.trim() ?? null, data['Visible'])
			.then(() => {
				history.push(getSiteNotificationRoute());
			});
	};

	return (
		<Grid container spacing={3}>
			<FormRequiredField/>
			<Grid item xs={12}>
				<Form
					setIsValid={setIsValid}
					disabled={creating}
					data={data}
					setData={setData}
				/>
			</Grid>
			<Grid item xs={12}>
				<Grid container justify="flex-end">
					<Grid item>
						<Button color="primary" variant="contained"
						        disabled={!isValid || creating} loading={creating} onClick={handleSave}>
							{t('save_btn')}
						</Button>
					</Grid>
				</Grid>
			</Grid>

		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		support: state.support.support
	};
};

export default connect(mapStateToProps)(CreateForm);