import React, { useRef }  from 'react';
import Dialog             from '@material-ui/core/Dialog';
import DialogTitle        from '@material-ui/core/DialogTitle';
import DialogContent      from '@material-ui/core/DialogContent';
import DialogContentText  from '@material-ui/core/DialogContentText';
import { IMG_VARIATION }             from './Gallery';
import DialogActions                 from '@material-ui/core/DialogActions';
import Grid                          from '@material-ui/core/Grid';
import makeStyles                    from '@material-ui/core/styles/makeStyles';
import { connect }                   from 'react-redux';
import { fetchEntry }                from '../../../../store/actions/CmsAction';
import { useTranslation }            from 'react-i18next';
import Cropper                       from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import Button                        from '../../inputs/Button';
import { mimeToType }                from '../../../utils/misc';
import {useContentCmsEntryImageHtml} from '../../../../hooks/api/content/cms/useContentCmsEntry';

const useStyles = makeStyles(theme => ({
	imgToInsert: {
		textAlign: 'center'
	},
	cropperImg: {
		'& img': {
			maxWidth: '100%',
		}
	}
}));

const InsertDialog = ({ loading, img, open, setOpen, fetchEntry, onInsert, entry }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const cropperRef = useRef(null);
	const [getImageHtml] = useContentCmsEntryImageHtml(entry.Content_Cms_Entry__);

	const handleClose = () => {
		setOpen(false);
	};

	const getCropData = () => {
		const imageElement = cropperRef?.current;
		const cropper = imageElement?.cropper;
		if (!cropper) return null;
		const data = cropper.getData(true);
		const imageData = cropper.getImageData()


		let x = data.x;
		let y = data.y;
		let width = data.width;
		let height = data.height;

		if(data.x < 0) x = 0
		if(data.x > imageData.naturalWidth) x = imageData.naturalWidth // Should not happen but in case

		if(data.y < 0) y = 0
		if(data.y > imageData.naturalHeight) y = imageData.naturalHeight // Should not happen but in case

		if((data.x + width) > imageData.naturalWidth) width =  imageData.naturalWidth-data.x
		if((data.y + height) > imageData.naturalHeight) height =  imageData.naturalHeight-data.y

		return `crop=${x}x${y}:${width}x${height}`;
	};

	const handleSubmit = (e) => {
		e.stopPropagation();
		e.preventDefault();

		let variation = 'format=png&strip';
		const cropData = getCropData();
		if (cropData) variation += '&' + cropData;

		getImageHtml(img.Drive_Item__, variation).then(r => {
			onInsert(r.HTML);
			setOpen(false);
		})
	};

	return (
		<Dialog
			open={open}
			aria-labelledby={t('insert_into_article')}
			aria-describedby={t('insert_into_article')}
			fullWidth
		>
			<form onSubmit={handleSubmit}>
				<DialogTitle id='gallery-insert-title'>{t('insert_img_article_title')}</DialogTitle>
				<DialogContent>
					<DialogContentText id='gallery-insert-description'>
						{t('insert_img_article_desc')}
					</DialogContentText>
					{ 'image' === mimeToType(img.Mime) && <Grid container justify='center' alignItems='center' spacing={2}>
						<Grid item xs={12} className={classes.imgToInsert}>
							<Cropper
								className={classes.cropperImg}
								viewMode={2}
								ref={cropperRef}
								src={img.Media_Image.Url}
								checkCrossOrigin={false}
								autoCropArea={1}
							/>
						</Grid>
					</Grid>}
				</DialogContent>

				<DialogActions>
					<Button color='secondary' autoFocus onClick={handleClose} disabled={loading}>
						{t('cancel_btn')}
					</Button>
					<Button color='primary' loading={loading} variant='contained' autoFocus type='submit'
					        disabled={loading}>
						{t('insert_btn')}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.cms.loadingEntry
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		fetchEntry: (variation) => dispatch(fetchEntry(props.entry.Content_Cms_Entry__, { image_variation: [IMG_VARIATION, variation] }))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(InsertDialog);
