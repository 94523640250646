import React                                                                  from 'react';
import {Grid}                                                                 from '@material-ui/core';
import {useTranslation}                                                       from 'react-i18next';
import {Bar, BarChart, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import Skeleton                                                               from '@material-ui/lab/Skeleton';
import Card                                                                   from '@material-ui/core/Card';
import CardContent                                                            from '@material-ui/core/CardContent';
import Typography                                                             from '@material-ui/core/Typography';
import {BlackPaper}                                                           from '../surface/Paper';
import {connect}                                                              from 'react-redux';
import makeStyles
                                                                              from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
	uppercase: {
		textTransform: 'uppercase',
	},
});

const MembershipPlansRepartition = ({stats, loading}) => {
	const {t} = useTranslation();
	const classes = useStyles();

	if (loading) {
		return <Grid spacing={3} container>
			<Grid item xs={12}>
				<Skeleton height={300} style={{transform: 'scale(1,1)'}}/>
			</Grid>
		</Grid>;
	}

	const TooltipCustom = ({payload, label, active, value}) => {
		if (active && payload) {
			return (
				<Card style={
					{
						background: 'rgba(44, 44, 44, 0.8)',
						backdropFilter: 'blur(12px)',
						borderRadius: '4px',
						padding: '14px'
					}
				}>
					<CardContent>
						<Typography variant="body1" color="textPrimary" align="center">
							{label}
						</Typography>
						<Typography variant="subtitle1" color="textPrimary" align="center">

						</Typography>
						{payload.map(item => <div>
							<div style={{display: 'flex', alignItems: 'center'}}>
								<div style={{
									width: 20,
									height: 20,
									border: '2px solid #FFFFFF',
									background: item.fill,
									marginRight: '14px',
									opacity: item.fillOpacity ?? 1,
								}}/>
								<div>
									<Typography variant="h6" style={{color: item.fill}}>
										{t(`dashboard_fanclub_stats_${item.name.toLowerCase()}_tooltip`)}
									</Typography>
									<Typography variant="h6" style={{color: item.fill}}>
										{item.value}
									</Typography>
								</div>
							</div>
						</div>)}
					</CardContent>
				</Card>
			);
		}

		return null;
	};

	const CustomCursor = props => {
		const {x, y, width, height} = props;
		return <Rectangle fill="#2a2a2a" fillOpacity={0.75} x={x} y={y} width={width} height={height}/>;
	};

	return (
		<Card elevation={0} component={BlackPaper} style={{height: '100%', overflow: 'unset'}}>
			<CardContent>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Typography variant="h5" align="center" className={classes.uppercase}>
							{t('plans_repartition')}
						</Typography>
					</Grid>

					<Grid item xs={12}>
						<ResponsiveContainer width="99%" height={300}>
							<BarChart data={stats}>
								<XAxis dataKey="name"/>
								<YAxis/>
								<Tooltip content={<TooltipCustom/>} cursor={<CustomCursor/>}
								         wrapperStyle={{zIndex: 1}}/>
								<Bar dataKey="Active" stackId="a" fill="#F2702E"/>
								<Bar dataKey="Inactive" stackId="a" fill="#F2702E" fillOpacity={0.5}/>
							</BarChart>
						</ResponsiveContainer>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};


const mapStateToProps = (state) => {
		return {
			stats: state.stats.membershipStatsPlans,
			loading: state.stats.membershipStatsLoading,
		};
	}
;

const mapDispatchToProps = (dispatch) => {
		return {};
	}
;

export default connect(mapStateToProps, mapDispatchToProps)(MembershipPlansRepartition);
