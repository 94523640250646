import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Flag from "../data/flag/Flag";
import {connect} from 'react-redux'
import {fetch} from "../../../store/actions/system/CountryAction";
import AutocompleteListboxComponent from "./AutocompleteListboxComponent";

const Country = ({
                     loading,
                     list,
                     fetch,
                     label = null,
                     onChange,
                     error = false,
                     defaultValue = '',
                     helperText = '',
                     inputProps = {},
                     value = undefined,
                     required = false,
                     disabled = false
                 }) => {
    const {t} = useTranslation();
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        fetch()
    }, [fetch]);


    return (
        <Autocomplete
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            onOpen={() => {
                setOpen(true);
            }}
            value={value}
            onChange={(e, value) => {
                onChange(value)
            }}

            getOptionSelected={(option, value) => option.Country__ === value.Country__}
            getOptionLabel={(option) => option === '' ? '' : option.Name}
            options={list}
            loading={loading}
            disabled={disabled}
            ListboxComponent={AutocompleteListboxComponent}
            disableListWrap
            renderOption={(option) =>
                <Grid container spacing={3} alignItems='center'>
                    <Grid item>
                        <Flag code={option.Country__.toLowerCase()}/>
                    </Grid>
                    <Grid item>
                        <Typography>{option.Name}</Typography>
                    </Grid>
                </Grid>
            }

            renderInput={(params) => {
	            params.inputProps.autoComplete ='new-password';
                return (
                    <TextField
                        {...params}
                        error={error}
                        required={required}
                        label={label ?? t('country_lbl')}
                        helperText={helperText}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
	                        autoComplete: 'new-password',
                            startAdornment: value ?
                                <Flag
                                    size='small'
                                    ratio="4x3"
                                    code={value.Country__.toLowerCase()}
                                />
                                : null,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />)
            }}
        />
    );
};


const mapStateToProps = (state) => {
    return {
        loading: state.country.loading,
        list: state.country.countries,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetch: () => dispatch(fetch())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Country);
