import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = makeStyles(theme => ({
    title: {
        flex: '1 1 100%',
    },
}));
export const Title = (props) => {
    const classes = styles();
    const {color = null, ...rest} = props;
    return (
        <Typography component="h2" variant="h6" className={classes.title} color={color ? color : "default"} gutterBottom {...rest}>
            {props.children}
        </Typography>
    );
};
