import React from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import {connect} from 'react-redux'
import {unsubscribe} from "../../../../../store/actions/fanclub/MembershipAction";
import { useTranslation } from 'react-i18next';
import Button from "../../../inputs/Button";


const CancelMembershipDialog = ({membershipUser, open, setOpen, membershipUnsubscribe, unsubscribe}) => {
    const handleClose = () => {
        setOpen(false);
    };

    const handleUnsubscribe = () => {
        unsubscribe()
            .then(handleClose)
    };
    const { t } = useTranslation();
    return (
        <Dialog
            open={open}
            aria-labelledby={t('cancel_membership')}
            aria-describedby={t('cancel_membership')}
        >
            <DialogTitle id="edit-membership-price-title">{t('confirm_cancel_user_membership_title')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="edit-membership-price-description">
                    {membershipUser ?
                        t('canceling_membership_prevent_access',{membershipUser : membershipUser.User.Profile.Display_Name} )
                    : t('canceling_membership_prevent_access_user')}
                    <br/>
                    {t('cancel_membership_refund_desc')}
                    <br/>
                    <Typography variant="body1" color="textPrimary" component="span">{t('are_you_sure')}</Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' color="primary" autoFocus onClick={handleClose} disabled={membershipUnsubscribe}>
                    {t('back_btn')}
                </Button>
                <Button color="secondary" loading={membershipUnsubscribe} disabled={membershipUnsubscribe} onClick={handleUnsubscribe}>
                    {t('cancel_this_membership_btn')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {
        membershipUnsubscribe: state.membership.membershipUnsubscribe
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        unsubscribe: () => dispatch(unsubscribe())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CancelMembershipDialog);
