import React, { useState } from 'react';
import { Grid }            from '@material-ui/core';
import Empty               from '../../feeback/State/Empty';
import Graphic             from '../../../../resources/images/trash.svg';
import GraphicDuplicate             from '../../../../resources/images/duplicate.svg';
import DeleteForeverIcon   from '@material-ui/icons/DeleteForever';
import DeleteDialog        from './DeleteDialog';
import { connect }         from 'react-redux';
import { useTranslation }  from 'react-i18next';
import FileCopyIcon        from '@material-ui/icons/FileCopy';
import DuplicateDialog     from './DuplicateDialog';

const Management = ({ entryData, deleting,duplicating, isAdmin, isManager, pagesCms, setTabIndex, disabled = false}) => {
	const { t } = useTranslation();
	const isPageCms = pagesCms ? pagesCms.Content_Cms__ === entryData.Content_Cms__ : true;
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [showDuplicateDialog, setShowDuplicateDialog] = useState(false);

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Empty
					title={t('article_duplicate_title')}
					textI18Key='article_duplicate_text'
					action={{
						title: t('duplicate_btn'),
						disabled: !isManager || duplicating || isPageCms || disabled,
						loading: duplicating,
						startIcon: <FileCopyIcon/>,
						color: 'primary',
						variant: 'contained',
						component: 'button',
						onClick: () => setShowDuplicateDialog(true)
					}}
					graphic={GraphicDuplicate}
				/>
			</Grid>
			<Grid item xs={12}>
				<Empty
					title={t('article_delete_title')}
					textI18Key='article_delete_text'
					action={{
						title: t('delete_btn'),
						disabled: !isAdmin || deleting || isPageCms || disabled,
						loading: deleting,
						startIcon: <DeleteForeverIcon/>,
						color: 'secondary',
						variant: 'contained',
						component: 'button',
						onClick: () => setShowDeleteDialog(true)
					}}
					graphic={Graphic}
				/>
			</Grid>
			{(entryData && !isPageCms && isAdmin) &&
			<DeleteDialog entryData={entryData} open={showDeleteDialog} setOpen={setShowDeleteDialog}/>
			}
			{(entryData && !isPageCms && isManager) &&
			<DuplicateDialog entryData={entryData} open={showDuplicateDialog} setOpen={setShowDuplicateDialog} setTabIndex={setTabIndex}/>
			}
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		deleting: state.cms.deleting,
		duplicating: state.cms.duplicating,
		pagesCms: state.cms.pages_cms,
		isAdmin: state.access.admin,
		isManager: state.access.manager,
	};
};

export default connect(mapStateToProps)(Management);
