import {COMMON_READY, COMMON_RESET, COMMON_SET_MENU, COMMON_SET_TITLE} from '../reducers/CommonReducer';

export const reset = (force = false) => {
	return (dispatch, getState) => {
		dispatch({type: COMMON_RESET, force: force});
	};
};

export const ready = () => {
	return (dispatch, getState) => {
		dispatch({type: COMMON_READY});
	};
};

export const setTitle = (title) => {
	return (dispatch, getState) => {
		dispatch({type: COMMON_SET_TITLE, title: title});
	};
};

export const setMenu = (menu) => {
	return (dispatch, getState) => {
		dispatch({type: COMMON_SET_MENU, menu: menu});
	};
};

export const hideMenu = () => {
	return (dispatch, getState) => {
		dispatch(setMenu(null));
	};
};