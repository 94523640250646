const keyToData = {
    itunes: {
        Key: 'itunes',
        Icon: require('../../resources/images/stores/itunes.png')
    },
    apple_music: {
        Key: 'apple_music',
        Icon: require('../../resources/images/stores/apple_music.png')
    },
    spotify: {
        Key: 'spotify',
        Icon: require('../../resources/images/stores/spotify.png')
    },
    line: {
        Key: 'line',
        Icon: require('../../resources/images/stores/line.png')
    },
    google_play_music: {
        Key: 'google_play_music',
        Icon: require('../../resources/images/stores/google_play_music.png')
    },
    google_play: {
        Key: 'google_play',
        Icon: require('../../resources/images/stores/google_play.png')
    },
    youtube_music_key: {
        Key: 'youtube_music_key',
        Icon: require('../../resources/images/stores/youtube_music_key.png')
    },
    amazon_music: {
        Key: 'amazon_music',
        Icon: require('../../resources/images/stores/amazon_music.png')
    },
    amazon_prime_music: {
        Key: 'amazon_prime_music',
        Icon: require('../../resources/images/stores/amazon_prime_music.png')
    },
    amazon_music_unlimited: {
        Key: 'amazon_music_unlimited',
        Icon: require('../../resources/images/stores/amazon_music_unlimited.png')
    },
    awa: {
        Key: 'awa',
        Icon: require('../../resources/images/stores/awa.png')
    },
    kkbox: {
        Key: 'kkbox',
        Icon: require('../../resources/images/stores/kkbox.png')
    },
    deezer: {
        Key: 'deezer',
        Icon: require('../../resources/images/stores/deezer.png')
    },
    moratouch: {
        Key: 'moratouch',
        Icon: require('../../resources/images/stores/moratouch.png')
    },
    recochoku_recochoku: {
        Key: 'recochoku_recochoku',
        Icon: require('../../resources/images/stores/recochoku_recochoku.png')
    },
    rec_music: {
        Key: 'rec_music',
        Icon: require('../../resources/images/stores/rec_music.png')
    },
    recochoku_dhits: {
        Key: 'recochoku_dhits',
        Icon: require('../../resources/images/stores/recochoku_dhits.png')
    },
    recochoku_dmusic_getugaku: {
        Key: 'recochoku_dmusic_getugaku',
        Icon: require('../../resources/images/stores/recochoku_dmusic_getugaku.png')
    },
    recochoku_dmusic: {
        Key: 'recochoku_dmusic',
        Icon: require('../../resources/images/stores/recochoku_dmusic.png')
    },
    recochoku_hikari_tv_music_a_la_carte: {
        Key: 'recochoku_hikari_tv_music_a_la_carte',
        Icon: require('../../resources/images/stores/recochoku_hikari_tv_music_a_la_carte.png')
    },
    recochoku_lismo_store: {
        Key: 'recochoku_lismo_store',
        Icon: require('../../resources/images/stores/recochoku_lismo_store.png')
    },
    musicjp_store: {
        Key: 'musicjp_store',
        Icon: require('../../resources/images/stores/musicjp_store.png')
    },
    dwango: {
        Key: 'dwango',
        Icon: require('../../resources/images/stores/dwango.png')
    },
    animelo_mix: {
        Key: 'animelo_mix',
        Icon: require('../../resources/images/stores/animelo_mix.png')
    },
    kpop_life: {
        Key: 'kpop_life',
        Icon: require('../../resources/images/stores/kpop_life.png')
    },
    billboard: {
        Key: 'billboard',
        Icon: require('../../resources/images/stores/billboard.png')
    },
    ototoy: {
        Key: 'ototoy',
        Icon: require('../../resources/images/stores/ototoy.png')
    },
    mysound_mysound: {
        Key: 'mysound_mysound',
        Icon: require('../../resources/images/stores/mysound_mysound.png')
    },
    oricon: {
        Key: 'oricon',
        Icon: require('../../resources/images/stores/oricon.png')
    },
    konami: {
        Key: 'konami',
        Icon: require('../../resources/images/stores/konami.png')
    },
    utapass: {
        Key: 'utapass',
        Icon: require('../../resources/images/stores/utapass.png')
    },
    jcom_music: {
        Key: 'jcom_music',
        Icon: require('../../resources/images/stores/jcom_music.png')
    },
    rakuten_music: {
        Key: 'rakuten_music',
        Icon: require('../../resources/images/stores/rakuten_music.png')
    },
    usen: {
        Key: 'usen',
        Icon: require('../../resources/images/stores/usen.png')
    },
    recochoku_otoraku: {
        Key: 'recochoku_otoraku',
        Icon: require('../../resources/images/stores/recochoku_otoraku.png')
    },
    kkbox_hmv: {
        Key: 'kkbox_hmv',
        Icon: require('../../resources/images/stores/kkbox_hmv.png')
    },
    other: {
        Key: 'other',
        Icon: require('../../resources/images/stores/other.png')
    }
};

const domainToKey = {
    'itunes.apple.com': 'itunes',
    'music.apple.com': 'apple_music',
    'open.spotify.com': 'spotify',
    'music.line.me': 'line',
    'play.google.com': 'google_play_music',
    'music.youtube.com': 'youtube_music_key',
    'www.amazon.co.jp': 'amazon_music',
    'www.amazon.com': 'amazon_music',
    's.awa.fm': 'awa',
    'www.kkbox.com': 'kkbox',
    'www.deezer.com': 'deezer',
    'music.orIcon.co.jp': 'orIcon',
    'mora.jp': 'moratouch',
    'recochoku.jp': 'recochoku_recochoku',
    'recmusic.jp': 'rec_music',
    'selection.music.dmkt-sp.jp': 'recochoku_dhits',
    'monthly.music.dmkt-sp.jp': 'recochoku_dmusic_getugaku',
    'music.dmkt-sp.jp': 'recochoku_dmusic',
    'music.hikaritv.net': 'recochoku_hikari_tv_music_a_la_carte',
    'musicstore.auone.jp': 'recochoku_lismo_store',
    'music-book.jp': 'musicjp_store',
    'music.oricon.co.jp': 'oricon',
    'pc.dwango.jp': 'dwango',
    'pc.animelo.jp': 'animelo_mix',
    'ototoy.jp': 'ototoy',
    'uta.573.jp': 'konami',
    'mysound.jp': 'mysound_mysound',
    'au.utapass.auone.jp': 'utapass',
    'www.jcom.co.jp': 'jcom_music',
    'music.rakuten.co.jp': 'rakuten_music',
    'smart.usen.com': 'usen',
    'otoraku.jp': 'recochoku_otoraku',
    'www.hmv.co.jp': 'kkbox_hmv',
    'other': 'other',
};

export const getStoreFromUrl = (url) => {
    let domain = 'other';
    try {
        url = new URL(url);
        domain = url.hostname;
    } catch (_) {
        domain = 'other';
    }

    let key = 'other';

    if (!(domain in domainToKey)) key = 'other';
    else key = domainToKey[domain];

    if (!(key in keyToData)) return keyToData['other'];
    return keyToData[key];
};