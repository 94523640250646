import React, { Fragment, useEffect } from 'react';
import TableHead                      from '@material-ui/core/TableHead';
import MaterialTable                  from '@material-ui/core/Table';
import TableRow                       from '@material-ui/core/TableRow';
import TableCell                      from '@material-ui/core/TableCell';
import TableBody                      from '@material-ui/core/TableBody';
import TableContainer                 from '@material-ui/core/TableContainer';
import Row                            from './Row';
import { connect }                    from 'react-redux';
import TableFooter                    from '@material-ui/core/TableFooter';
import { useTranslation }             from 'react-i18next';
import Empty                          from './Empty';
import {
	fetchShippings,
	setSelectedShippings,
	setShippingsFilters,
	setShippingsPaging
}                                     from '../../../../../store/actions/store/ShippingAction';
import Checkbox                       from '@material-ui/core/Checkbox';
import Pagination                     from '../../../data/table/Pagination';
export const SHIPPING_TABLE_ID = "SHIPPING_TABLE_ID";
const Table = ({ shippings, fetch, loading, filters, paging, count, flatShippings, setShippingsPaging, setShippingsFilters, selected, setSelected }) => {
	const loadingData = [...Array(paging.results_per_page).keys()];
	const { t } = useTranslation();
	const displayLoading = () => loadingData.map((k) => <Row
		key={k}
		loading={true}
		showTracking={'Status' in filters && filters.Status === 'sent'}
		selected={false}
		shipping={null}
	/>);

	const firstSelected = flatShippings.length > 0 ? flatShippings[0] : null;

	const displayShippings = () => shippings.map((u, idx) => <Row key={u.Catalog_Shipping__}
	                                                              selected={u.Catalog_Shipping__ in selected}
	                                                              highlight={firstSelected === u.Catalog_Shipping__}
	                                                              loading={false}
	                                                              showTracking={'Status' in filters && filters.Status === 'sent'}
	                                                              onSelectionChange={onRowSelectionChange}
	                                                              shipping={u}/>);

	useEffect(() => {
		// Reset filters the first time
		setShippingsFilters({ Status: 'pending', sort: { 'Date': 'ASC' } });
	}, [setShippingsFilters]);

	useEffect(() => {
		fetch();
	}, [fetch, filters, paging]);

	/*const {sort} = filters;

	const sortHandler = (property, dir) => {
		let p = {...filters};
		// only 1 sort
		p.sort = {};
		p.sort[property] = dir;
		setRecordsFilters(p);
	};*/

	const numSelected = Object.keys(selected).length;

	const onSelectAllClick = (all) => {
		if (!all) {
			setSelected({});
			return;
		}

		const selected = {};
		shippings.forEach(s => selected[s.Catalog_Shipping__] = s);
		setSelected(selected);
	};

	const onRowSelectionChange = (checked, shipping) => {
		if (checked) {
			setSelected({ ...selected, [shipping.Catalog_Shipping__]: shipping });
			return;
		}

		const copy = { ...selected };
		delete copy[shipping.Catalog_Shipping__];
		setSelected(copy);
	};

	return (
		<Fragment>
			{(loading || shippings.length > 0) &&
			<TableContainer id={SHIPPING_TABLE_ID}>
				<MaterialTable aria-label='simple table' size='small' className='print-table'>
					<TableHead>
						<TableRow className='no-print'>
							<Pagination
								setPaging={setShippingsPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
						<TableRow>
							<TableCell padding='checkbox' className='no-print'>
								<Checkbox
									color='primary'
									indeterminate={numSelected > 0 && numSelected < shippings.length}
									checked={shippings.length > 0 && numSelected === shippings.length}
									onChange={e => onSelectAllClick(numSelected !== shippings.length)}
									inputProps={{ 'aria-label': t('tablecell_shipping_select_all') }}
								/>
							</TableCell>
							<TableCell> {t('tablecell_shipping_order_number')}</TableCell>
							<TableCell> {t('tablecell_shipping_quantity')}</TableCell>
							<TableCell> {t('tablecell_shipping_address')}</TableCell>
							{('Status' in filters && filters.Status === 'sent') &&
							<TableCell> {t('tablecell_shipping_tracking')}</TableCell>
							}

							<TableCell className='no-print'> {t('tablecell_shipping_date')}</TableCell>
							<TableCell className='no-print'> {t('tablecell_shipping_user')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{loading && displayLoading()}
						{!loading && displayShippings()}
					</TableBody>
					<TableFooter>
						<TableRow className='no-print'>
							<Pagination
								setPaging={setShippingsPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
					</TableFooter>

				</MaterialTable>
			</TableContainer>
			}
			{(!loading && shippings.length < 1) && <Empty/>}
		</Fragment>
	);

};


const mapStateToProps = (state) => {
	return {
		filters: state.shipping.shippingsFilters,
		paging: state.shipping.shippingsPaging,
		count: state.shipping.shippingsPagingCount,
		loading: state.shipping.shippingsLoading,
		shippings: state.shipping.shippings,
		flatShippings: Object.keys(state.shipping.selectedShippings),
		selected: state.shipping.selectedShippings
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: () => dispatch(fetchShippings()),
		setShippingsPaging: (newPaging) => dispatch(setShippingsPaging(newPaging)),
		setShippingsFilters: (newFilters) => dispatch(setShippingsFilters(newFilters)),
		setSelected: (selected) => dispatch(setSelectedShippings(selected))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);

