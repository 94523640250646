import React                   from 'react';
import Dialog                  from '@material-ui/core/Dialog';
import DialogTitle             from '@material-ui/core/DialogTitle';
import DialogContent           from '@material-ui/core/DialogContent';
import DialogContentText       from '@material-ui/core/DialogContentText';
import DialogActions           from '@material-ui/core/DialogActions';
import {connect}               from 'react-redux';
import DeleteIcon              from '@material-ui/icons/Delete';
import Typography              from '@material-ui/core/Typography';
import {useTranslation}        from 'react-i18next';
import Button                  from '../../../../inputs/Button';
import {deleteShippingFeeZone} from '../../../../../../store/actions/store/ShippingFeeAction';


const DeleteDialog = ({zone, open, setOpen, deleting, deleteShippingFeeZone, onDeleted}) => {
	const {t} = useTranslation();

	const handleClose = () => {
		setOpen(false);
	};

	const handleDelete = () => {
		deleteShippingFeeZone(zone.Catalog_Deliverable_Fee_Zone__)
			.then(() => onDeleted(zone))
			.then(handleClose);
	};

	return (
		<Dialog
			open={open}
			aria-labelledby={t('confirm_delete_shipping_zone')}
			aria-describedby={t('confirm_delete_shipping_zone')}
			fullWidth
		>
			<DialogTitle>{t('confirm_delete_shipping_zone')}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{t('confirm_delete_shipping_zone_desc')}
					<br/>
					<Typography variant="body1" color="textPrimary" component="span">{t('are_you_sure')}</Typography>
				</DialogContentText>
			</DialogContent>

			<DialogActions>
				<Button variant="contained" color="primary" autoFocus onClick={handleClose} disabled={deleting}>
					{t('back_btn')}
				</Button>
				<Button color="secondary" loading={deleting} disabled={deleting} endIcon={<DeleteIcon/>}
				        onClick={handleDelete}>
					{t('delete_btn')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const mapStateToProps = (state) => {
	return {
		deleting: state.shippingFee.zoneDeleting
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		deleteShippingFeeZone: (zoneId) => dispatch(deleteShippingFeeZone(zoneId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDialog);
