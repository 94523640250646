import React, { Fragment, useEffect, useState } from 'react';
import TableHead                                from '@material-ui/core/TableHead';
import MaterialTable                            from '@material-ui/core/Table';
import TableRow                                 from '@material-ui/core/TableRow';
import TableCell                                from '@material-ui/core/TableCell';
import TableBody                                from '@material-ui/core/TableBody';
import TableContainer                           from '@material-ui/core/TableContainer';
import Row                                      from './Row';
import { connect }                              from 'react-redux';
import TableFooter                              from '@material-ui/core/TableFooter';
import { useTranslation }                       from 'react-i18next';
import Empty                                    from './Empty';

import { fetchSeats, setSeatsFilters, setSeatsPaging } from '../../../../../../store/actions/planner/SeatAction';
import TextField                                       from '@material-ui/core/TextField';
import Grid                                            from '@material-ui/core/Grid';
import { fetchVAT }                                    from '../../../../../../store/actions/OrderAction';
import InputAdornment                                  from '@material-ui/core/InputAdornment';
import Pagination                                      from '../../../../data/table/Pagination';

const Table = ({ event, onSelected, seats, fetch, fetchVat, loading, filters, paging, count, setSeatsPaging, setSeatsFilters }) => {
	const loadingData = [...Array(paging.results_per_page).keys()];
	const { t } = useTranslation();
	const displayLoading = () => loadingData.map((k) => <Row key={k} loading={true} seat={null}/>);
	const displaySeats = () => seats.map((u, idx) => <Row onSelected={onSelected} vat={vat} key={u.Planner_Event_Seat__}
	                                                      loading={false} seat={u}/>);
	const [vat, setVat] = useState('');

	useEffect(() => {
		fetchVat().then(d => setVat(d.Rate));
	}, [fetchVat]);

	useEffect(() => {
		// Reset filters the first time
		setSeatsFilters({});
	}, [setSeatsFilters]);

	useEffect(() => {
		fetch(event.Planner_Event__);
	}, [fetch, filters, paging, event]);

	/*const {sort} = filters;

	const sortHandler = (property, dir) => {
		let p = {...filters};
		// only 1 sort
		p.sort = {};
		p.sort[property] = dir;
		setSchedulesFilters(p);
	};*/

	return (
		<Fragment>
			{(loading || seats.length > 0) &&
			<TableContainer>
				<MaterialTable aria-label='simple table'>
					<TableHead>
						<TableRow>
							<Pagination
								setPaging={setSeatsPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
						<TableRow>
							<TableCell>{t('tablecell_visible')}</TableCell>
							<TableCell> {t('tablecell_seat_name')}</TableCell>
							<TableCell> {t('tablecell_seat_description')}</TableCell>
							<TableCell> {t('tablecell_seat_capacity')}</TableCell>
							<TableCell> {t('tablecell_seat_price')}</TableCell>
							<TableCell> {t('tablecell_seat_currency')}</TableCell>
							<TableCell align='right'/>
						</TableRow>
					</TableHead>
					<TableBody>
						{loading && displayLoading()}
						{!loading && displaySeats()}
					</TableBody>
					<TableFooter>
						<TableRow>
							<Pagination
								setPaging={setSeatsPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
					</TableFooter>

				</MaterialTable>
				<Grid container alignItems='flex-end' direction='column'>
					<Grid item>
						<TextField
							variant='standard'

							value={vat}
							onChange={e => setVat(e.target.value)}
							type='number'
							InputProps={
								{
									min: 0,
									endAdornment: <InputAdornment position='end'>%</InputAdornment>,
									startAdornment: <InputAdornment position='start'>{t('vat')}:</InputAdornment>,
								}
							}
						/>
					</Grid>
				</Grid>
			</TableContainer>
			}
			{(!loading && seats.length < 1) && <Empty/>}
		</Fragment>
	);

};


const mapStateToProps = (state) => {
	return {
		filters: state.seat.seatsFilters,
		paging: state.seat.seatsPaging,
		count: state.seat.seatsPagingCount,
		loading: state.seat.seatsLoading,
		seats: state.seat.seats
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchVat: () => dispatch(fetchVAT()),
		fetch: (eventId) => dispatch(fetchSeats(eventId)),
		setSeatsPaging: (newPaging) => dispatch(setSeatsPaging(newPaging)),
		setSeatsFilters: (newFilters) => dispatch(setSeatsFilters(newFilters))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);

