import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import PendingGraphic from "../../../../../resources/images/shippingsPending.svg";
import ProcessingGraphic from "../../../../../resources/images/shippingsProcessing.svg";
import SentGraphic from "../../../../../resources/images/shippingsSent.svg";
import ReturnGraphic from "../../../../../resources/images/shippingReturn.svg";
import EmptyState from "../../../feeback/State/Empty";
import {connect} from 'react-redux'

const statusToIcon = {
    'pending': PendingGraphic,
    'processing': ProcessingGraphic,
    'sent': SentGraphic,
    'return': ReturnGraphic,
};

const Empty = ({filters}) => {
    const {t} = useTranslation();
    const [txt, setTxt] = useState('Status' in filters ? filters.Status : 'pending');
    const [icon, setIcon] = useState('Status' in filters ? statusToIcon[filters.Status] : PendingGraphic);

    useEffect(() => {
        setTxt('Status' in filters ? filters.Status : 'pending');
        setIcon('Status' in filters ? statusToIcon[filters.Status] : PendingGraphic)
    }, [filters, setTxt, setIcon]);

    return (
        <EmptyState
            title={t('store_shipping_' + txt + '_empty_title')}
            textI18Key={'store_shipping_' + txt + '_empty_text'}
            graphic={icon}
        />
    );

};

const mapStateToProps = (state) => {
    return {
        filters: state.shipping.shippingsFilters
    }
};

export default connect(mapStateToProps)(Empty);