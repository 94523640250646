import React, {useEffect, useState} from 'react';
import {connect}                    from 'react-redux';
import SelectRealm                  from '../../SelectRealm';
import Grid                         from '@material-ui/core/Grid';
import AppBarTitle                  from '../../../components/common/typography/AppBarTitle';
import Toolbar                      from '../../../components/common/typography/Toolbar';
import CommentsTable                from '../../../components/common/cms/social/comment/table/Table';
import {useTranslation}             from 'react-i18next';
import {setCommentsFilters}         from '../../../store/actions/SocialAction';
import {FormControlLabel, Switch}   from '@material-ui/core';

const Comments = ({selectedRealm, setCommentsFilters, filters}) => {
	const {t} = useTranslation();
	const [enableTableDisplay, setEnableTableDisplay] = useState(false);


	useEffect(() => {
		if (selectedRealm) {
			const f = {
				Realm__: selectedRealm.Realm__,
				Visible: 'Y',
				Patrol: 'pending',
				sort: {Reports: 'DESC'}
			};
			setCommentsFilters(f);
			setEnableTableDisplay(true);
		} else {
			setEnableTableDisplay(false);
		}
		// eslint-disable-next-line
	}, [selectedRealm, setCommentsFilters]);

	if (!selectedRealm) return <SelectRealm/>;

	const handlePatrolChange = () => {
		let newFilters = {...filters};
		if ('Patrol' in newFilters) delete newFilters['Patrol'];
		else newFilters.Patrol = 'pending';
		setCommentsFilters(newFilters);
	};

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('comments_title')}/>

			<Grid item xs={12}>
				<Toolbar title={t('comments_title')}>
					<FormControlLabel
						control={
							<Switch
								checked={!('Patrol' in filters)}
								onChange={handlePatrolChange}
								name={t('show_all_comments')}
								color="primary"
							/>
						}
						label={t('show_all_comments')}
					/>
				</Toolbar>
			</Grid>
			<Grid item xs={12}>
				<CommentsTable disabled={!enableTableDisplay} realm={selectedRealm}/>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		filters: state.social.commentsFilters,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setCommentsFilters: (newPaging) => dispatch(setCommentsFilters(newPaging))
	};
};


export default connect(mapStateToProps, mapDispatchToProps)(Comments);
