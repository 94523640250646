import React, {useEffect, useRef, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {connect} from 'react-redux'
import {fetchAndSelectFanclub, updateMembership} from "../../../../../store/actions/fanclub/MembershipAction";
import {fetchPlans} from "../../../../../store/actions/fanclub/PlanAction";
import { useTranslation } from 'react-i18next';
import Button from "../../../inputs/Button";

const ChangePlanDialog = ({membershipUser, selectedMembership, open, setOpen, loading, plans, fetch, changePlan, updating, fetchAndSelectFanclub}) => {
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (selectedMembership) fetch()
    }, [fetch, selectedMembership]);

    useEffect(() => {
        if (membershipUser && !selectedMembership)
            fetchAndSelectFanclub(membershipUser.Membership__);
        else if (membershipUser)
            setSelectedPlan(membershipUser.Membership_Plan__)
    }, [membershipUser, selectedMembership, fetchAndSelectFanclub]);

    const inputLabel = useRef(null);
    const [labelWidth, setLabelWidth] = useState(0);
    const { t } = useTranslation();
    const [submitDisable, setSubmitDisable] = useState(loading);
    const [selectedPlan, setSelectedPlan] = useState(membershipUser ? membershipUser.Membership_Plan__ : '');

    const handleSelectChange = (e) => {
        setSubmitDisable(e.target.value === membershipUser.Membership_Plan__);
        setSelectedPlan(e.target.value);
    };

    const submitHandle = (e) => {
        e.preventDefault();
        changePlan(selectedPlan).then(handleClose);
    };

    return (
        <Dialog
            onEnter={() => {
                setSelectedPlan(membershipUser ? membershipUser.Membership_Plan__ : '')
            }}
            open={open}
            aria-labelledby={t('change_membership_plan_lbl')}
            aria-describedby={t('change_membership_plan_lbl')}
            onRendered={() => {
                setLabelWidth(inputLabel.current.offsetWidth);
            }}
        >
            <form onSubmit={submitHandle}>
                <DialogTitle id="edit-membership-plan-title">{t('change_user_membership_plan_lbl')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="edit-membership-plan-description">

                    {membershipUser ? 
                        t('change_membership_plan',{membershipUser : membershipUser.User.Profile.Display_Name} )

                        :  t('change_membership_plan_user')
                    }
                        
                        <br/>
                        {t('change_membership_plan_user_access_change')}
                        <br/>
                        {t('change_membership_plan_price_reflexion')}
                        

                    </DialogContentText>

                    <FormControl variant="outlined" fullWidth>
                        <InputLabel ref={inputLabel} htmlFor="select-plan">
                            {t('membership_plan_title')}
                        </InputLabel>
                        <Select
                            onChange={handleSelectChange}
                            value={selectedPlan}
                            native
                            labelWidth={labelWidth}
                            inputProps={{
                                name: 'plan',
                                id: 'select-plan',
                            }}
                        >
                            {
                                plans.map(p => <option key={'select-plan-' + p.Membership_Plan__}
                                                       value={p.Membership_Plan__}>{p.Catalog_Product['Basic.Name']}</option>)
                            }
                        </Select>
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button color="primary" autoFocus onClick={handleClose} disabled={updating}>
                        Back
                    </Button>
                    <Button type='submit' variant='contained' color="primary" loading={updating} disabled={submitDisable || updating}>
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {
        loading: state.plan.plansLoading,
        plans: state.plan.plans,
        selectedMembership: state.membership.selectedFanclub,
        updating: state.membership.membershipUpdating,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetch: () => dispatch(fetchPlans(false)),
        fetchAndSelectFanclub: (id) => dispatch(fetchAndSelectFanclub(id)),
        changePlan: (plan) => dispatch(updateMembership({Membership_Plan__: plan})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePlanDialog);
