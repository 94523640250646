import React                    from 'react';
import TableCell                from '@material-ui/core/TableCell';
import Skeleton                 from '@material-ui/lab/Skeleton';
import Link                     from '../../../typography/Link';
import {getMusicAlbumEditRoute} from '../../../../../router/routes/music/factory/album';
import TableRow                 from '@material-ui/core/TableRow';

const Row = ({album}) => {

	return (
		<TableRow>
			<TableCell>
				{!album && <Skeleton/>}
				{album && <span>
                    <Link to={getMusicAlbumEditRoute(album.Music_Label_Album__)}>
                       {album.Music_Label_Album.Name}
                    </Link>
                </span>}
			</TableCell>
			<TableCell>
				{!album && <Skeleton/>}
				{album && <span>{album.Track}</span>}
			</TableCell>

		</TableRow>
	);
};

export default Row;