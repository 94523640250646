export const gengoFormatLanguage = (lang) => {
	switch(lang) {
		case 'es-MX':
			return 'es-mx'; // Spanish (Mexico)
		case 'es-PE': // Peru
			return 'es-la'; // Spanish (Latin America)
		case 'fr-CA':
			return 'fr-ca'; // French (Canada)
		case 'pt-BR':
			return 'pt-br'; // Portuguese (Brazil)
		case 'zh-HK':
			return 'zh-hk'; // Chinese (Hong Kong)
		case 'zh-TW':
			return 'zh-tw'; // Chinese (Traditional)
		case 'en-GB':
			return 'en-gb'; // English (British)
		default:
			return lang.substr(0, 2);
	}
}