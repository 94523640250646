import React                                     from 'react';
import { matchPath, Route, Switch, useLocation } from 'react-router-dom';
import { getMode }                               from '@karpeleslab/klbfw';

import routes, { layoutLessRoute } from '../../router/routeConfig';

import ScrollToTop                                        from '../Route/ScrollToTop';
import LoginRequired                                      from '../Route/LoginRequired';
import CssBaseline                                        from '@material-ui/core/CssBaseline';
import AppBar                                             from './AppBar';
import { AppBarSpacer, MainContainer, MainContent, Root } from './style';
import Toasts                                             from '../common/feeback/Toasts';
import RealmLoader                                        from '../common/realm/Loader';
import { connect }                                        from 'react-redux';
import Theme                                              from './Theme';
import ErrorBoundary                                      from './ErrorBoundary';
import Drawer                                             from './drawer/Drawer';
import Menu                                               from './drawer/Menu/Menu';

const Layout = ({ user }) => {
	const mode = getMode();
	const location = useLocation();

	const buildRoute = (idx, route) => {
		if ('mode' in route) {
			if (Array.isArray(route.mode)) {
				if (!route.mode.includes(mode)) return null;
			} else if (mode !== route.mode)
				return null;
		}

		const C = 'components' in route ? route.components : route.component;
		const Component = 'components' in route ? C : <C/>;

		delete route.component;

		if ('loginRequired' in route && route.loginRequired === true) {
			return (
				<Route key={idx} {...route} >
					<LoginRequired>
						{Component}
					</LoginRequired>
				</Route>
			);
		}

		return (
			<Route key={idx} {...route} >
				{Component}
			</Route>
		);
	};

	const buildRoutes = () => {
		return (
			<Switch>
				{
					routes.map((route, idx) => {
						switch (route.type) {
							case 'route':
							default:
								return buildRoute(idx, { ...route }); // Copy it to not alter the original
						}
					})}
			</Switch>
		);
	};

	let hasLayoutLessRoute = false;

	for (let i = 0; i<layoutLessRoute.length; i++) {
		const match = matchPath(location.pathname, layoutLessRoute[i]);
		if(match) {
			hasLayoutLessRoute = true;
			break;
		}
	}

	const addLayout = mode !== 'ssr-open' &&
		mode !== 'client-open' &&
		mode !== 'ssr-mail' && !hasLayoutLessRoute;

	if (!addLayout) {
		return (
			<div>
				<Theme>
					<CssBaseline/>
					<ErrorBoundary>
						{buildRoutes()}
					</ErrorBoundary>
				</Theme>
			</div>
		);
	}

	return (
		<Root>
			<Theme>
				<CssBaseline/>
				<AppBar/>
				<Drawer/>
				<Menu/>
				<ScrollToTop/>
				{user && <RealmLoader/>}
				<MainContent>
					<AppBarSpacer/>
					<MainContainer maxWidth='xl'>
						<ErrorBoundary>
							{buildRoutes()}
						</ErrorBoundary>
					</MainContainer>
				</MainContent>
				<Toasts/>
			</Theme>
		</Root>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.user.user,
	};
};

export default connect(mapStateToProps)(Layout);
