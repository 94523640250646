import React, { useEffect, useState } from 'react';
import Dialog                         from '@material-ui/core/Dialog';
import DialogContent                  from '@material-ui/core/DialogContent';
import Grid                           from '@material-ui/core/Grid';
import { useTranslation }             from 'react-i18next';
import DialogTitle                    from '@material-ui/core/DialogTitle';
import DialogActions                  from '@material-ui/core/DialogActions';
import { connect }                    from 'react-redux';
import { linkGood }                   from '../../../../store/actions/store/ProductAction';
import Button                         from '../../inputs/Button';
import DeliverableSelector            from '../../inputs/DeliverableSelector';
import HasRight                       from '../../../Route/HasRight';
import AddIcon                        from '@material-ui/icons/Add';
import CreateDialog                   from '../warehouse/CreateDialog';
import uuid                           from '../../../utils/uuid';

const LinkGoodDialog = ({ existing, productId, catalog, open, setOpen, creating, linkGood, linkCallback = undefined }) => {
	const { t } = useTranslation();
	const [good, setGood] = useState(null);
	const [showCreate, setShowCreate] = useState(false);

	const [saveDisabled, setSaveDisabled] = useState(true);
	const [selectorKey, setSelectorKey] = useState(uuid());

	useEffect(() => {
		setSaveDisabled(!good);
	}, [good, setSaveDisabled]);

	const onCreated = () => {
		setSelectorKey(uuid());
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleCreate = (e) => {
		e.preventDefault();
		if (linkCallback) {
			linkCallback(good);
			handleClose();
		} else
			linkGood(productId, good.Catalog_Deliverable__)
				.then(handleClose);

	};

	return (
		<Dialog
			onEnter={() => setGood(null)}
			open={open}
			aria-labelledby={t('product_add_good')}
			aria-describedby={t('product_add_good')}
			fullWidth
			maxWidth='md'
		>
			<form onSubmit={handleCreate}>
				<DialogTitle
					id='create-product-title'>
					<Grid container justify='space-between'>
						<Grid item>
							{t('product_add_good')}
						</Grid>
						<Grid item>
							<HasRight manager hideLoading>
								<Button
									variant='contained'
									color='primary'
									startIcon={<AddIcon/>
									}
									onClick={e => setShowCreate(true)}>
									{t('create_new_btn')}
								</Button>
							</HasRight>
						</Grid>
					</Grid>
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<DeliverableSelector
								key={selectorKey}
								existing={existing}
								catalogId={catalog.Catalog__}
								value={good}
								onChange={e => setGood(e)}
								inputProps={{
									variant: 'outlined',
									fullWidth: true
								}}/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button color='default' onClick={handleClose} disabled={creating}>
						{t('close_btn')}
					</Button>
					<Button color='primary' type='submit' variant='contained'
					        disabled={creating || saveDisabled}
					        loading={creating}>
						{t('link_btn')}
					</Button>
				</DialogActions>
			</form>
			<CreateDialog open={showCreate} setOpen={setShowCreate} onCreated={onCreated} redirect={false}/>
		</Dialog>

	);
};

const mapStateToProps = (state) => {
	return {
		creating: state.product.addingGood,
		catalog: state.store.catalog,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		linkGood: (pid, did) => dispatch(linkGood(pid, did)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkGoodDialog);
