import React, { useState } from 'react';
import { Grid }    		   from '@material-ui/core';
import TextField           from '@material-ui/core/TextField';
import { useTranslation }  from 'react-i18next';
import Button from "../../../../inputs/Button";

const Interval = ({ handleSecondsAdjust, disabled = false }) => {
	const { t } = useTranslation();

	const [interval, setInterval] = useState(5);
	const handleIntervalChange = (e) => {
		let val = e.target.value;
		if (!(/^\d+$/.test(val))) return;
		if (parseInt(val) > 60 || parseInt(val) < 0) return;
		setInterval(val);
	};

	return (
		<Grid container spacing={1} justify='center' alignItems='center'>
			<Grid item>
				<Button
					disabled={disabled}
					variant='outlined'
					color='primary'
					onClick={() => handleSecondsAdjust(interval)}
				>
					{t('record_lyrics_timing_plus_sec', { value: interval })}
				</Button>
			</Grid>
			<Grid item>
				<Button
					disabled={disabled}
					variant='outlined'
					color='primary'
					onClick={() => handleSecondsAdjust(-interval)}
				>
					{t('record_lyrics_timing_minus_sec', { value: interval })}
				</Button>
			</Grid>
			<Grid item>
				<TextField
					disabled={disabled}
					variant='outlined'
					size='small'
					label={t('record_lyrics_interval')}
					type='number'
					onChange={handleIntervalChange}
					value={interval}
					InputProps={
						{
							step: 1,
							min: 60,
						}
					}
				/>
			</Grid>
		</Grid>
	);
};

export default Interval;
