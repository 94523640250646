import React from 'react';
import TableRow from "@material-ui/core/TableRow";
import Skeleton from "@material-ui/lab/Skeleton";
import TableCell from "@material-ui/core/TableCell";
import {connect} from 'react-redux'
import Avatar from "@material-ui/core/Avatar";
import {Link} from "react-router-dom";
import {REALM_USERS_IMAGE_VARIATION} from "../../../../store/reducers/RealmReducer";
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';
import {getSiteUserEditRoute} from "../../../../router/routes/site/factory/user";
import Button from "../../inputs/Button";

const Row = ({user, loading}) => {
    const displayUser = (!loading && user);
    const { t } = useTranslation();
    return (
        <TableRow>
            <TableCell>
                {!displayUser && <Skeleton variant="circle" width={40} height={40}/>}
                {(displayUser && user.Profile.Media_Image) &&
                <Avatar src={user.Profile.Media_Image[REALM_USERS_IMAGE_VARIATION]}/>}
                {(displayUser && !user.Profile.Media_Image) && <Avatar/>}
            </TableCell>
            <TableCell>
                {!displayUser && <Skeleton/>}
                {displayUser && <span>{user.Profile.Display_Name}</span>}
            </TableCell>
            <TableCell>
                {!displayUser && <Skeleton/>}
                {displayUser && <span>{user.Email ?? '-'}</span>}
            </TableCell>
            <TableCell align="right">
                {!displayUser && <Skeleton/>}
                {displayUser && <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SearchIcon/>}
                    component={Link} to={getSiteUserEditRoute(user.User__)}
                >
                    {t('view_btn')}
                </Button>}
            </TableCell>
        </TableRow>
    );
};

const mapStateToProps = (state, {loading, idx}) => {
    if (loading) return {};
    return {
        user: state.realm.users[idx],
    }
};

export default connect(mapStateToProps, null)(Row);