import WorkGroup from "../../../pages/WorkGroup";
import {getPXLWorkGroupRoute} from "./factory/workGroup";

export default [
    {
        path: getPXLWorkGroupRoute(),
        component: WorkGroup,
        exact: true,
        type: 'route',
        loginRequired: true,
    },
]