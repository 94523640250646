import React, {useEffect, useState}   from 'react';
import {
	Grid,
	TextField
}                                     from '@material-ui/core';
import MassMailSegmentSelector        from '../inputs/MassMailSegmentSelector';
import {Trans, useTranslation}        from 'react-i18next';
import Button                         from '../inputs/Button';
import MultiLangTextField             from '../inputs/MultiLangTextField';
import {
	hasChanged,
	isI18NFilled
}                                     from '../../utils/i18nInput';
import moment                         from 'moment';
import DateTime                       from '../inputs/DateTime';
import {connect}                      from 'react-redux';
import {
	createMassMail,
	fetchMassMailSegment,
	massMailBuildQueue,
	massMailCancel,
	updatedMassMail
}                                     from '../../../store/actions/MassMailAction';
import {useHistory}                   from 'react-router-dom';
import {getSiteNotificationEditRoute} from '../../../router/routes/site/factory/content';
import Loading                        from '../feeback/loading/Loading';
import {getText}                      from '../../../store/actions/TextAction';
import {deepCopy}                     from '../../utils/misc';
import MassMailReadyDialog            from './MassMailReadyDialog';
import RichAlert                      from '../feeback/RichAlert';
import MassMailCancelDialog           from './MassMailCancelDialog';
import FormRequiredField              from '../feeback/FormRequiredField';

const MassMail = ({
	                  realmId,
	                  massMail,
	                  creating,
	                  create,
	                  update,
	                  updating,
	                  getText,
	                  disabled = false,
	                  settingReady,
	                  fetchSegment,
	                  buildQueue,
	                  buildingQueue,
	                  cancelling,
	                  cancel
                  }) => {
	const {t} = useTranslation();
	const history = useHistory();

	const [segment, setSegment] = useState();
	const [subject, setSubject] = useState({});
	const [message, setMessage] = useState({});
	const [schedule, setSchedule] = useState(moment());
	const [label, setLabel] = useState();

	const [subjectText, setSubjectText] = useState();
	const [messageText, setMessageText] = useState();

	const [loaded, setLoaded] = useState(!massMail);

	const [sendEnabled, setSendEnabled] = useState(false);

	const [showReady, setShowReady] = useState(false);
	const [showCancel, setShowCancel] = useState(false);

	const isNew = massMail && massMail.Status === 'new';

	useEffect(() => {
		if (!massMail) return;
		const promises = [];
		promises.push(getText(massMail.Subject_Text__)
			.then(text => {
					setSubjectText(deepCopy(text));
					setSubject(text.Values ?? {});
				}
			));

		promises.push(getText(massMail.Body_Text__)
			.then(text => {
				setMessage(text.Values ?? {});
				setMessageText(deepCopy(text));
			}));

		promises.push(fetchSegment(massMail.Network_MassMail_Segment__)
			.then(segment => {
				setSegment(segment);
			}));


		setSchedule(moment(parseInt(massMail.Scheduled.unixms)));
		setLabel(massMail.Label);

		Promise.all(promises).then(r => setLoaded(true));

	}, [fetchSegment, massMail, setSubject, setSubjectText, setMessageText, setMessage, setSegment, setSchedule, setLabel, getText]);

	useEffect(() => {
		// Create only
		if (massMail) return;
		if (schedule && segment && ((label ?? '').trim()) && isI18NFilled(subject) && isI18NFilled(message)) {
			setSendEnabled(true);
			return;
		}

		setSendEnabled(false);
	}, [massMail, segment, subject, message, schedule, label, setSendEnabled]);

	useEffect(() => {
		// Edit only
		if (!massMail || !loaded) return;
		if (!schedule || !((label ?? '').trim()) || !isI18NFilled(subject) || !isI18NFilled(message)) {
			setSendEnabled(false);
			return;
		}

		if (
			label.trim() !== massMail.Label ||
			schedule.unix() !== massMail.Scheduled.unix ||
			hasChanged(subjectText.Values, subject) ||
			hasChanged(messageText.Values, message)

		) {
			setSendEnabled(true);
			return;
		}

		setSendEnabled(false);
	}, [massMail, segment, subject, message, schedule, label, setSendEnabled, subjectText, messageText, loaded]);

	const handleSend = () => {
		if (massMail) {
			// Edit
			update(massMail.Network_MassMail__, label, '@' + schedule.unix(), subject, message);
			return;
		}

		create(realmId, segment.Network_MassMail_Segment__, label, '@' + schedule.unix(), subject, message)
			.then((m) => {
				history.push(getSiteNotificationEditRoute(m.Network_MassMail__));
			});
	};

	const handleReady = () => {
		setShowReady(true);
	};

	const handleBuildQueue = () => {
		buildQueue(massMail.Network_MassMail__);
	};

	const handleCancel = () => {
		setShowCancel(true);
	};

	if (!loaded) {
		return <Grid container spacing={3}><Grid item xs={12}><Loading/></Grid></Grid>;
	}
	
	return (
		<Grid container spacing={3}>
			<FormRequiredField/>
			<Grid item xs={12}>
				<MassMailSegmentSelector
					required
					realmId={realmId}
					value={segment}
					setValue={setSegment}
					disabled={creating || disabled || updating || settingReady || !!massMail || cancelling}
				/>
			</Grid>

			<Grid item xs={12}>
				<TextField
					variant="outlined"
					required
					helperText={t('massmail_label_helperText')}
					fullWidth
					value={label}
					onChange={e => setLabel(e.target.value)}
					label={t('massmail_label_label')}
					disabled={creating || disabled || updating || settingReady || buildingQueue || cancelling}
				/>
			</Grid>

			<Grid item xs={12}>
				<DateTime
					label={t('massmail_schedule_lbl')}
					inputVariant="outlined"
					value={schedule}
					disabled={creating || disabled || updating || settingReady || buildingQueue || cancelling}
					onChange={setSchedule}
					required
					helperText={t('massmail_schedule_helperText')}
					fullWidth
					disablePast
				/>
			</Grid>

			<Grid item xs={12}>
				<MultiLangTextField
					required
					value={subject}
					setValue={setSubject}
					variant="outlined"
					placeholder={t('massmail_subject_lbl')}
					fullWidth
					label={t('massmail_subject_lbl')}
					disabled={creating || disabled || updating || settingReady || buildingQueue || cancelling}
				/>
			</Grid>
			<Grid item xs={12}>
				<MultiLangTextField
					required
					value={message}
					setValue={setMessage}
					multiline rows={5}
					rowsMax={Infinity}
					variant="outlined"
					placeholder={t('massmail_message_lbl')}
					fullWidth
					label={t('massmail_message_lbl')}
					disabled={creating || disabled || updating || settingReady || buildingQueue || cancelling}
				/>
			</Grid>

			{(massMail && massMail.Queue_Built) && <Grid item xs={12}>
				<RichAlert severity="info">
					<Trans
						i18nKey="massmail_recipient_infos"
						values={
							{
								date: moment(parseInt(massMail.Queue_Built.unixms)).format('LLL'),
								count: massMail.Target_Count
							}
						}
					>
						sample<br/>
						<strong>sample</strong>
					</Trans>
				</RichAlert>
			</Grid>}

			<Grid item xs={12}>
				<Grid container justify={massMail ? 'space-between' : 'flex-end'}>
					{(massMail && massMail.Status !== 'cancelled') && <Grid item>
						<Button loading={cancelling} color="secondary" variant="contained" onClick={handleCancel}
						        disabled={creating || updating || settingReady || buildingQueue || cancelling}
						>
							{t('cancel_btn')}
						</Button>
					</Grid>
					}
					{isNew &&
					<Grid item>
						<Button loading={settingReady} color="primary" variant="contained" onClick={handleReady}
						        disabled={creating || disabled || updating || settingReady || buildingQueue || cancelling}
						>
							{t('set_mass_mail_ready', {date: schedule.format('LLL')})}
						</Button>
					</Grid>
					}
					{isNew &&
					<Grid item>
						<Button loading={buildingQueue} color="primary" variant="outlined" onClick={handleBuildQueue}
						        disabled={creating || disabled || updating || settingReady || buildingQueue || cancelling}
						>
							{t('massmail_estimate_btn')}
						</Button>
					</Grid>
					}

					{(!massMail || isNew) && <Grid item>
						<Button loading={creating || updating} color="primary" variant="contained" onClick={handleSend}
						        disabled={!sendEnabled || creating || disabled || updating || settingReady || buildingQueue || cancelling}
						>
							{massMail ? t('edit_notification_btn') : t('schedule_notification_btn')}
						</Button>
					</Grid>}

				</Grid>
			</Grid>
			{massMail &&
			<MassMailReadyDialog massMailId={massMail.Network_MassMail__} setOpen={setShowReady} open={showReady}/>}
			{massMail &&
			<MassMailCancelDialog massMailId={massMail.Network_MassMail__} setOpen={setShowCancel} open={showCancel}/>}
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		creating: state.massMail.creating,
		updating: state.massMail.updating,
		settingReady: state.massMail.ready,
		buildingQueue: state.massMail.buildingQueue,
		cancelling: state.massMail.cancelling,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getText: (id) => dispatch(getText(id)),
		create: (realmId, segmentId, label, schedule, subject, body) => dispatch(createMassMail(realmId, segmentId, label, schedule, subject, body)),
		update: (massMailId, label, schedule, subject, body) => dispatch(updatedMassMail(massMailId, label, schedule, subject, body)),
		buildQueue: (massMailId) => dispatch(massMailBuildQueue(massMailId)),
		cancel: (massMailId) => dispatch(massMailCancel(massMailId)),
		fetchSegment: (id) => dispatch(fetchMassMailSegment(id)),
	};
};


export default connect(mapStateToProps, mapDispatchToProps)(MassMail);
