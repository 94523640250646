import React from 'react';
import {useTranslation} from "react-i18next";
import EmptyState from "../../../feeback/State/Empty";
import Graphic from "../../../../../resources/images/products.svg";

const Empty = ({isVariation = false}) => {
    const {t} = useTranslation();

    return (
        <EmptyState
            title={isVariation ? t('product_variations_empty_title') : t('store_shop_empty_title')}
            textI18Key={isVariation ? 'product_variations_empty_text' : 'store_shop_empty_text'}
            graphic={Graphic}
        />
    );

};

export default Empty;