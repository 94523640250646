import React, {useEffect, useState} from 'react';
import {useTranslation}             from 'react-i18next';
import {Grid}                       from '@material-ui/core';
import TextField                    from '@material-ui/core/TextField';
import {useOrderProcess}            from '../../../../../../hooks/api/useOrder';
import {success}                    from '../../../../../../store/actions/system/ToastAction';
import Button                       from '../../../../inputs/Button';
import FormRequiredField            from '../../../../feeback/FormRequiredField';

const OnFile = ({order, settings, setPaying, refreshOrder, setDialogActions = null}) => {
	const {t} = useTranslation();
	const [selected, setSelected] = useState();

	const [processOrder, processingOrder] = useOrderProcess(order.Order__);

	useEffect(() => {
		setPaying(processingOrder);
	}, [processingOrder, setPaying]);

	useEffect(() => {
		const billings = settings.fields.user_billing.values;
		const billingId = billings.length > 0 ? billings[0].User_Billing__ : null;
		setSelected(billingId);
	}, [settings, setSelected]);

	const handleProcess = () => {
		processOrder(settings.session, settings.method, {user_billing: selected})
			.then(d => refreshOrder(d))
			.then(() => success('order_paid'));
	};

	useEffect(() => {
		if (!setDialogActions) return;
		setDialogActions((
			<Button
				onClick={handleProcess}
				variant="contained"
				color="primary"
				disabled={processingOrder || !selected}
				loading={processingOrder}
			>
				{t('onfile_checkout_btn')}
			</Button>
		));
		// eslint-disable-next-line
	}, [setDialogActions, processingOrder, selected]);

	const formatExpiration = (exp) => {
		const components = exp.split('-');
		return `${components[1]}/${components[0]}`;
	}

	return (
		<Grid container spacing={3}>
			<FormRequiredField/>
			<Grid item xs={12}>
				<TextField
					label={t('user_billing_selector')}
					helperText={t('user_billing_selector_helperText')}
					select
					native
					variant="outlined"
					fullWidth
					required
					value={selected}
					onChange={e => setSelected(e.target.value)}
					disabled={processingOrder}
					SelectProps={{
						native: true
					}}
					inputProps={{
						id: 'billing-select'
					}}
				>
					<option disabled>{t('choose_option_select')}</option>
					{settings.fields.user_billing.values.map(billing => (
						<option key={billing.User_Billing__} value={billing.User_Billing__}>
							{billing.Methods[0].Expiration ? t('user_billing_selector_value', {
								name: billing.Methods[0].Name,
								exp: formatExpiration(billing.Methods[0].Expiration)
							}) :
								t('user_billing_selector_value_cc_only', {
									name: billing.Methods[0].Name})
							}
						</option>
					))}
				</TextField>
			</Grid>
			{!setDialogActions && <Grid item xs={12}>
				<Grid container spacing={3} justify="center">
					<Grid item>
						<Button
							onClick={handleProcess}
							variant="contained"
							color="primary"
							disabled={processingOrder || !selected}
							loading={processingOrder}
						>
							{t('onfile_checkout_btn')}
						</Button>
					</Grid>
				</Grid>
			</Grid>
			}
		</Grid>
	);
};

export default OnFile;