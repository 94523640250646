import React, {Fragment} from 'react';
import {StyledDrawer} from "./style";
import {connect} from 'react-redux'
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import DrawerContent from "./DrawerContent";

const Drawer = ({ user}) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    if (!user || mobile) return <Fragment/>;

    return (
        <StyledDrawer
            variant={'permanent'}
        >
            <DrawerContent/>
        </StyledDrawer>
    );
};

const mapStateToProps = (state) => {
    return {
        selectedRealm: state.realm.selected,
        open: state.common.drawerOpened,
        user: state.user.user,
        newsCms: state.cms.news_cms,
        pagesCms: state.cms.pages_cms
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Drawer);
