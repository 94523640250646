import React, {useEffect, useState}    from 'react';
import {connect}                       from 'react-redux';
import {useTranslation}                from 'react-i18next';
import Card                            from '@material-ui/core/Card';
import CardContent                     from '@material-ui/core/CardContent';
import Typography                      from '@material-ui/core/Typography';
import {Link}                          from 'react-router-dom';
import Button                          from '../inputs/Button';
import {BlackPaper}                    from '../surface/Paper';
import {Box, CardActions, Grid, Paper} from '@material-ui/core';
import {Launch}                        from '@material-ui/icons';
import makeStyles                      from '@material-ui/core/styles/makeStyles';
import {fetchSupport}                  from '../../../store/actions/SupportAction';
import {getSupportInboxRoute}          from '../../../router/routes/support/factory/boxes';

const useStyles = makeStyles({
	uppercase: {
		textTransform: 'uppercase',
	},
});

const Support = ({support, refreshSupport}) => {
	const {t} = useTranslation();
	const classes = useStyles();

	const [count, setCount] = useState(0);
	useEffect(() => {
		if (!support) return;
		setCount(support.New_Ticket_Count);
	}, [support]);

	useEffect(() => {
		const refreshInterval = setInterval(() => {
			if (!support) return;
			refreshSupport(support.Support__)
				.catch(() => {
				});
		}, 30000);

		return () => {
			clearInterval(refreshInterval);
		};
		// eslint-disable-next-line
	}, [support]);

	return (
		<Card elevation={0} component={BlackPaper}  style={{height: '100%', overflow:'unset', minHeight:'330px'}}>
			<CardContent style={{height: '80%'}}>
				<Grid container spacing={3} alignItems="center" justify="space-between" direction='column' style={{height: '80%'}}>
					<Grid item>
						<Typography variant={'h6'} align={'center'} className={classes.uppercase}>
							{t('support')}
						</Typography>
					</Grid>

					<Grid item>
						<Paper elevation={0}>
							<Grid container spacing={3} alignItems="center" justify="center">
								<Grid item={12}>
									<Typography variant={'h6'} align={'center'} className={classes.uppercase}>
										{count}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography variant={'h6'} align={'center'} className={classes.uppercase}>
										{t('unread_tickets')}
									</Typography>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			</CardContent>
			<CardActions>
				<Box style={{textAlign: 'center', width: '100%'}}>
					<Button variant="text" color="primary" endIcon={<Launch/>} component={Link}
					        to={getSupportInboxRoute()}>
						{t('see_support_btn')}
					</Button>
				</Box>
			</CardActions>
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		support: state.support.support,
		loading: state.support.loading
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		refreshSupport: (id) => dispatch(fetchSupport(id, true, true))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Support);
