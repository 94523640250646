import React, {useEffect}     from 'react';
import Grid                   from '@material-ui/core/Grid';
import {Title}                from '../components/common/typography/Title';
import RealmSelector          from '../components/common/realm/RealmSelector';
import AppBarTitle            from '../components/common/typography/AppBarTitle';
import {useTranslation}       from 'react-i18next';
import {connect}              from 'react-redux';
import Loading                from '../components/common/feeback/loading/Loading';
import {useHistory}           from 'react-router-dom';
import {getArtistWizardRoute} from '../router/routes/artist/factory/wizard';

const SelectRealm = ({selected, loading, list, ready}) => {
	const {t} = useTranslation();
	const history = useHistory();

	useEffect(() => {
		if (!loading && list.length < 1) {
			history.push(getArtistWizardRoute());
		}
		// eslint-disable-next-line
	}, [loading, list]);

	if (selected) {
		return <Loading/>;
	}

	if (ready && !selected) {
		return (
			<Grid container spacing={3}>
				<AppBarTitle title={t('website_selection')}/>
				<Grid item xs={12}>
					<Title>{t('please_select_website')}</Title>
				</Grid>
				<Grid item xs={12}>
					<RealmSelector fullwidth={true}/>
				</Grid>
			</Grid>
		);
	}

	return <Loading/>;
};

const mapStateToProps = (state) => {
	return {
		ready: state.common.ready,
		selected: state.realm.selected,
		loading: state.realm.loading,
		list: state.realm.realms,
	};
};

export default connect(mapStateToProps)(SelectRealm);
