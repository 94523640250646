import React, { useEffect, useState } from 'react';
import Grid                           from '@material-ui/core/Grid';
import Typography                  from '@material-ui/core/Typography';
import { connect }                 from 'react-redux';
import Tabs                        from '@material-ui/core/Tabs';
import Tab                         from '@material-ui/core/Tab';
import { getCurrency }             from '@karpeleslab/klbfw';
import moment                      from 'moment';
import FormControlLabel            from '@material-ui/core/FormControlLabel';
import Checkbox                    from '@material-ui/core/Checkbox';
import Box                         from '@material-ui/core/Box';
import { useTranslation }          from 'react-i18next';
import DateTime                    from '../../inputs/DateTime';
import Price                       from '../../inputs/Price';
import Currencies                  from '../../inputs/Currencies';
import Table                       from '../../membership/plans/edit/Price/Table';
import { createPrice, loadPrices } from '../../../../store/actions/store/ProductAction';
import PriceFeeNotice              from '../../feeback/PriceFeeNotice';
import useTheme                    from '@material-ui/core/styles/useTheme';
import useMediaQuery               from '@material-ui/core/useMediaQuery';
import Button from "../../inputs/Button";

const Prices = ({ productId, priceLoading, prices, loadPrices, createPrice, priceCreating, realm, disabled = false, showCreation = true, isLowRate = true}) => {
	const [tabIndex, setTabIndex] = React.useState(0);

	const [currency, setCurrency]           = React.useState(getCurrency());
	const [price, setPrice]                 = React.useState('');
	const [originalPrice, setOriginalPrice] = React.useState('');
	const [discount, setDiscount]           = React.useState(false);
	const [startDate, setStartDate]         = React.useState(moment());
	const { t }                             = useTranslation();

	const [vatIncluded, setVatIncluded] = useState(false);

	const theme    = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

	const [minStartDate, setMinStartDate] = React.useState(moment());

	const [simpleSubmitBtnEnabled, setSimpleSubmitBtnEnabled] = React.useState(false);

	useEffect(() => {
		if (priceCreating) {
			setSimpleSubmitBtnEnabled(false);
			return;
		}

		if (price === '') {
			setSimpleSubmitBtnEnabled(false);
			return;
		}

		if (currency === '') {
			setSimpleSubmitBtnEnabled(false);
			return;
		}

		if (discount && originalPrice === '') {
			setSimpleSubmitBtnEnabled(false);
			return;
		}

		setSimpleSubmitBtnEnabled(true);
	}, [price, currency, discount, originalPrice, priceCreating]);

	const getMinStartDate = (prices) => {
		let minStartDate = null;

		prices.forEach((p => {
			if (!p.End_Date) {
				minStartDate = parseInt(p.Start_Date.unixms);
			}
		}));

		if (minStartDate == null) return moment();

		return moment(minStartDate + 1000); // add one second
	};

	useEffect(() => {
		          let minStartDate = getMinStartDate(prices);
		          setMinStartDate(minStartDate);
		          setStartDate(minStartDate);
	          },
	          [prices, setMinStartDate, setStartDate]);

	const handleTabChange = (event, newValue) => {
		setPrice('');
		setOriginalPrice('');
		setDiscount(false);
		setTabIndex(newValue);

		let minStartDate = getMinStartDate(prices);
		setMinStartDate(minStartDate);
		setStartDate(minStartDate);
	};

	const createPriceAddHandler = (e) => {
		e.preventDefault();

		const flags = {}
		if(vatIncluded) flags['vat_included'] = true;

		setPrice('');
		setOriginalPrice('');
		setDiscount(false);
		setVatIncluded(false)

		createPrice(
			productId,
			price,
			discount ? originalPrice : price,
			currency,
			startDate.unix(),
			discount,
			realm,
			flags)
			.then(d => {
				// reload prices list
				loadPrices(productId);
			});
	};

	useEffect(() => {
		if (!productId) return;
		loadPrices(productId);
	}, [productId, loadPrices]);

	const DiscountField = () => <FormControlLabel
		control={<Checkbox
			checked={discount}
			color='primary'
			name='discount'
			disabled={priceCreating || disabled}
			onChange={(e) => {
				setDiscount(e.target.checked);
			}}
		/>}
		label={t('price_discount_label')}
	/>;

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<PriceFeeNotice low={isLowRate}/>
			</Grid>

			<Grid item  xs={12}>
				<Table prices={prices} loading={priceLoading}/>
			</Grid>

			{showCreation && <Grid item xs={12}>
				<Grid container>
					<Grid item xs={12}>
						<Tabs
							variant='scrollable'
							scrollButtons={isMobile ? 'on' : 'auto'}
							value={tabIndex}
							indicatorColor='primary'
							textColor='primary'
							onChange={handleTabChange}
							aria-label=''
						>
							<Tab label={t('change_price_tab')}/>
							<Tab label={t('schedule_price_tab')}/>
						</Tabs>

						<Box p={3}>
							<form onSubmit={createPriceAddHandler}>
								<Grid container spacing={3}>
									<Grid item xs={12}>
										{
											tabIndex === 0 &&
											<Typography variant='body2' color='textSecondary' component='p'>
												{vatIncluded ? t('change_price_vat_desc') : t('change_price_desc')}
											</Typography>
										}
										{
											tabIndex === 1 &&
											<Typography variant='body2' color='textSecondary' component='p'>
												{vatIncluded ? t('schedule_price_vat_desc') : t('schedule_price_desc')}
											</Typography>
										}
									</Grid>

									{
										tabIndex === 1 &&
										<Grid item xs={12}>
											<DateTime
												label={t('schedule_price_start_at')}
												inputVariant='outlined'
												value={startDate}
												onChange={setStartDate}
												minDate={minStartDate}
												disabled={priceCreating || disabled}
												required
												fullWidth
											/>
										</Grid>
									}

									<Grid item lg={6} md={12}>
										<Price
											preferredCurrency={currency}
											vatIncluded={vatIncluded}
											setVatIncluded={setVatIncluded}
											variant='outlined'
											fullWidth
											disabled={priceCreating || disabled}
											required
											value={price}
											onValueChange={setPrice}
											label={t('price')}
										/>
									</Grid>

									<Grid item lg={6} md={12}>
										<Currencies
											onChange={(e) => setCurrency(e.target.value)}
											value={currency}
											inputProps={{
												variant:   'outlined',
												fullWidth: true,
												required:  true,
												disabled:  priceCreating || disabled
											}}
										/>
									</Grid>

									{discount && <Grid item xs={12}>
										<Price
											preferredCurrency={currency}
											disabled={priceCreating || disabled}
											variant='outlined'
											fullWidth
											required
											value={originalPrice}
											onValueChange={setOriginalPrice}
											label={t('original_price')}
											helperText={t('original_price_helper')}
											helperEnabled={!vatIncluded}
										/>
									</Grid>}

									<Grid item xs={12}>
										<DiscountField/>
									</Grid>


									<Grid item xs={12}>
										<Button
											disabled={!simpleSubmitBtnEnabled || disabled}
											type='submit'
											variant='contained' color='primary'>
											{t('change_btn')}
										</Button>
									</Grid>

								</Grid>
							</form>
						</Box>

					</Grid>
				</Grid>
			</Grid>}

		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		priceLoading:  state.product.pricesLoading,
		prices:        state.product.prices,
		priceCreating: state.product.priceCreating,
		realm:         state.realm.selected,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		loadPrices:  (productId) => dispatch(loadPrices(productId)),
		createPrice: (productId, price, publicPrice, currency, startAtTimeStamp, discount, realm, flags) => dispatch(createPrice(productId, price, publicPrice, currency, startAtTimeStamp, discount, realm, flags)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Prices);
