import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {connect} from 'react-redux'
import SendIcon from '@material-ui/icons/Send';
import Typography from "@material-ui/core/Typography";
import {Trans, useTranslation} from 'react-i18next';
import {replyToTicket} from "../../../store/actions/SupportAction";
import Button from "../inputs/Button";

const ReopenDialog = ({open, setOpen, replying, ticket, message, replyToTicket, onTicketSend}) => {
    const {t} = useTranslation();

    const handleClose = () => {
        setOpen(false);
    };

    const handleSend = () => {
        replyToTicket(ticket.Support_Ticket__, message)
            .then(onTicketSend)
    };

    return (
        <Dialog
            open={open}
            aria-labelledby={t('support_reopen_ticket')}
            aria-describedby={t('support_reopen_ticket')}
            fullWidth
        >
            <DialogTitle id="ticket-reopen-title">{t('confirm_reopen_ticket_title')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="ticket-reopen-description">
                    <Trans i18nKey='confirm_reopen_ticket_desc'>
                        sample <br/>
                        sample {{message}}
                    </Trans>
                    <br/>
                    <br/>
                    <Typography variant="body1" color="textPrimary" component="span">{t('are_you_sure')}</Typography>
                    <br/>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button autoFocus onClick={handleClose} disabled={replying}>
                    {t('back_btn')}
                </Button>
                <Button color="primary" variant='contained' loading={replying} disabled={replying} endIcon={<SendIcon/>}
                        onClick={handleSend}>
                    {t('reopen_btn')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {
        replying: state.support.supportReplying
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        replyToTicket: (ticketId, message) => dispatch(replyToTicket(ticketId, message)),
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(ReopenDialog);