import React, {Fragment} from 'react';
import {connect} from 'react-redux'
import Loading from "../common/feeback/loading/Loading";

const HasRight = ({
                      children, rights = [], hideLoading = false, deniedComponent = null, loadingComponent = null, access, ready,
                      Aadmin,
                      Amanager,
                      Aowner,
                      Aviewer,
                      Asupport,
                      owner = false,
                      admin = false,
                      manager = false,
                      viewer = false,
                      support = false,
                  }) => {
    const l = hideLoading ? <Fragment/> : (loadingComponent ? loadingComponent : <Loading/>);

    if (!ready || !access) return l;

    if (owner) rights.push('O');
    if (admin) rights.push('A');
    if (manager) rights.push('W');
    if (support) rights.push('C');
    if (viewer) rights.push('R');

    for (let i = 0; i < rights.length; i++) {
        switch (rights[i]) {
            case 'O':
                if (Aowner) return children;
                break;
            case 'A':
                if (Aadmin) return children;
                break;
            case 'W':
                if (Amanager) return children;
                break;
            case 'C':
                if (Asupport) return children;
                break;
            case 'R':
                if (Aviewer) return children;
                break;
            default:
                break

        }
    }

    return deniedComponent ? deniedComponent : <Fragment/>
};

const mapStateToProps = (state) => {
    return {
        access: state.access.access,
        ready: state.common.ready,
        Aowner: state.access.owner,
        Aadmin: state.access.admin,
        Amanager: state.access.manager,
        Aviewer: state.access.viewer,
        Asupport: state.access.support,
    }
};

export default connect(mapStateToProps)(HasRight);