import React                    from 'react';
import { setPaymentController } from '../../../store/actions/RealmAction';
import { connect }              from 'react-redux';
import { useTranslation }       from 'react-i18next';
import { Grid }                 from '@material-ui/core';
import { Title }                from '../typography/Title';
import Typography               from '@material-ui/core/Typography';
import Card                     from '@material-ui/core/Card';
import CardHeader               from '@material-ui/core/CardHeader';
import Button                   from '../inputs/Button';

const StepConfirm = ({ handleBack, stripe, realm, address, setting, hasLocation, setPaymentController }) => {
	const { t } = useTranslation();

	const handleLink = () => {
		if (!stripe || !realm) return;
		const addressData = !hasLocation ? { ...address } : null;
		if (!hasLocation) addressData['Country__'] = address.Country.Country__;
		setPaymentController(realm.Realm__, stripe.OAuth2_Consumer_Token__, addressData);
	};

	return (
		<Grid container spacing={3}>
			<Grid item>
				<Title>{t('fanclub_wizard_step_validate_title')}</Title>
			</Grid>

			<Grid item xs={12}>
				<Typography variant='body1' color='textSecondary' component='p'>
					{t('fanclub_wizard_step_validate_desc')}
				</Typography>
			</Grid>

			{stripe &&
			<Grid item xs={12}>
				<Typography variant='h5' color='textPrimary' component='h5'>
					{t('fanclub_wizard_step_validate_stripe_title')}
				</Typography>
			</Grid>
			}

			{stripe &&
			<Grid item xs={12}>
				<Grid container direction='column' spacing={3}>
					<Grid item xs={12} md={4}>
						<Card variant='outlined'>
							<CardHeader
								title={stripe.Name}
								subheader={t('event_wizard_step_validate_stripe_desc')}
							/>
						</Card>
					</Grid>
				</Grid>
			</Grid>
			}

			<Grid item xs={12}>
				<Grid container justify='center' spacing={3}>

					<Grid item>
						<Button
							size='large'
							onClick={handleLink}
							disabled={setting}
							loading={setting}
							variant='contained'
							color='primary'
						>
							{t('event_wizard_confirm_btn')}
						</Button>
					</Grid>

				</Grid>
			</Grid>


			<Grid item xs={12}>
				<Grid container justify='space-between' spacing={3}>

					<Grid item xs={1}>
						<Button
							disabled={setting}
							onClick={handleBack}
							variant={'outlined'}
							color='primary'>
							{t('back_btn')}
						</Button>
					</Grid>

				</Grid>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		setting: state.realm.paymentControllerSet,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPaymentController: (realmId, stripeToken, address) => dispatch(setPaymentController(realmId, stripeToken, address, 'event_activate_success'))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(StepConfirm);
