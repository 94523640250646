import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {getText} from '../../../../store/actions/TextAction';
import {useTranslation} from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import InputI18N from '../../inputs/InputI18n';
import {useHistory} from 'react-router-dom';
import MusicGenre from '../../inputs/MusicGenre';
import Button from '../../inputs/Button';
import DeleteDialog from './DeleteDialog';
import {hasChanged, requiredCheck as nameRequiredCheck} from '../../../utils/i18nInput';
import PictureChooser from '../../inputs/PictureChooser';
import Musics                                       from './music/Musics';
import {createAlbum, updateAlbum, updateAlbumImage} from '../../../../store/actions/discography/AlbumAction';
import Loading                                      from '../../feeback/loading/Loading';
import uuid                                         from '../../../utils/uuid';
import Collaborators                                from '../record/collaborator/Collaborators';
import Links                                        from './links/Links';
import {hasFlag}                                    from '../../../utils/flags';
import {getMusicAlbumEditRoute}                     from '../../../../router/routes/music/factory/album';
import MultiLangTextField                           from '../../inputs/MultiLangTextField';
import {deepCopy}                                   from '../../../utils/misc';
import ReleaseLink                                  from './ReleaseLink';
import FormRequiredField                            from '../../feeback/FormRequiredField';

const Form = ({
	              artist,
	              album,
	              creating,
	              updating,
	              deleting,
	              getText,
	              createAlbum,
	              updateAlbum,
	              uploadingAlbumImage,
	              updateAlbumImage,
	              isManager,
	              isAdmin,
	              isReview = false,
				  setFormLoaded = undefined,
              }) => {
	const {t} = useTranslation();

	const [name, setName] = useState({});
	const [needDetailedName, setNeedDetailedName] = useState(false);
	const [nameText, setNameText] = useState();

	const [description, setDescription] = useState({});
	const [descriptionText, setDescriptionText] = useState();

	const [musics, setMusics] = useState([]);
	const [links, setLinks] = useState([]);

	const [collaborators, setCollaborators] = useState([
		{ // Force me in the artists
			Music_Label_Album_Artist__: uuid(),
			Music_Label_Artist__: artist.Music_Label_Artist__,
			Music_Label_Artist: artist,
			Role: 'artist'
		}
	]);

	const [originalCollaboratorMap, setOriginalCollaboratorMap] = useState({});
	const [collaboratorEdited, setCollaboratorEdited] = useState(false);

	const [genre, setGenre] = useState();

	const [isReleased, setIsReleased] = useState(false);

	const controlDisabled = creating || updating || deleting || uploadingAlbumImage || !isManager || isReview;
	const [saveDisabled, setSaveDisabled] = useState(true);

	const [deleteDialogOpened, setDeleteDialogOpened] = useState(false);

	const [musicsEdited, setMusicsEdited] = useState(false);
	const [linksEdited, setLinksEdited] = useState(false);

	const [picture, setPicture] = useState();
	const [pictureEdited, setPictureEdited] = useState(false);

	const [loaded, setLoaded] = useState(!album);
	const history = useHistory();

	useEffect(() => {
		if(!setFormLoaded) return;
		setFormLoaded(loaded);
	}, [loaded, setFormLoaded])

	// Initialise data, Get text and set internal State for the edit mode
	useEffect(() => {
		if (!album) return;
		const promises = [];
		promises.push(getText(album.Name_Text__)
			.then(text => {
					setNameText(text);
					setName(text.Values);
				}
			));

		promises.push(getText(album.Description_Text__)
			.then(text => {
				setDescription(text.Values ?? {});
				setDescriptionText(deepCopy(text));
			}));


		setGenre(album.Music_Genre);
		setIsReleased(album.Status === 'published');
		setMusics(album.Tracks);
		setLinks(album.Links);
		setCollaborators(album.Artists);

		const map = {};
		album.Artists.forEach(c => {
			if (!map[c.Music_Label_Artist__]) map[c.Music_Label_Artist__] = {};
			map[c.Music_Label_Artist__][c.Role] = true;
		});

		setOriginalCollaboratorMap(map);

		if (album.Drive_Item__) {
			setPicture(album.Drive_Item);
		}

		Promise.all(promises).then(r => setLoaded(true));
	}, [album, getText, setGenre, setMusics, setIsReleased, setCollaborators, setOriginalCollaboratorMap]);


	// Used to enable/disable the save btn in edit mode
	useEffect(() => {
		if (!loaded) {
			setSaveDisabled(true);
			return;
		}
		if (!album) return;
		// Check minimum required field
		if (!nameRequiredCheck(name, needDetailedName)) {
			setSaveDisabled(true);
			return;
		}

		if (!genre) {
			setSaveDisabled(true);
			return;
		}

		if (album.Music_Genre__ && genre.Music_Genre__ !== album.Music_Genre__) {
			setSaveDisabled(false);
			return;
		}

		if (!album.Music_Genre__) {
			setSaveDisabled(false);
			return;
		}

		if (pictureEdited) {
			setSaveDisabled(false);
			return;
		}

		if (collaboratorEdited) {
			setSaveDisabled(false);
			return;
		}

		if (hasChanged(nameText.Values, name) || hasChanged(descriptionText.Values ?? {}, description)) {
			setSaveDisabled(false);
			return;
		}

		if (musicsEdited) {
			setSaveDisabled(false);
			return;
		}

		if (linksEdited) {
			setSaveDisabled(false);
			return;
		}

		setSaveDisabled(true);

	}, [
		collaboratorEdited,
		pictureEdited,
		musicsEdited,
		linksEdited,
		genre,
		loaded,
		album,
		nameText, name, needDetailedName,
		description, descriptionText,

	]);

	useEffect(() => {
		if (!album) return;
		// Really poorly optimized !!!
		if (collaborators.length !== album.Artists.length) {
			setCollaboratorEdited(true);
			return;
		}

		for (let i = 0; i < collaborators.length; i++) {
			if (!(collaborators[i].Music_Label_Artist__ in originalCollaboratorMap)) {
				setCollaboratorEdited(true);

				return;
			}

			if (!(collaborators[i].Role in originalCollaboratorMap[collaborators[i].Music_Label_Artist__])) {
				setCollaboratorEdited(true);
				return;
			}
		}

		setCollaboratorEdited(false);
	}, [album, collaborators, originalCollaboratorMap, setCollaboratorEdited]);


	// Detect if the musics has been edited
	useEffect(() => {
		if (!album) return;
		// Really poorly optimized !!!
		if (musics.length !== album.Tracks.length) {
			setMusicsEdited(true);
			return;
		}

		for (let i = 0; i < musics.length; i++) {
			if (musics[i].Music_Label_Record__ !== album.Tracks[i].Music_Label_Record__ ||
				musics[i].Track !== album.Tracks[i].Track
			) {
				setMusicsEdited(true);
				return;
			}
		}

		setMusicsEdited(false);
	}, [album, musics, setMusicsEdited]);

	// Detect if the links has been edited
	useEffect(() => {
		if (!album) return;
		// Really poorly optimized !!!
		if (links.length !== album.Links.length) {
			setLinksEdited(true);
			return;
		}


		for (let i = 0; i < links.length; i++) {
			if (links[i].Music_Label_Album_Link__ !== album.Links[i].Music_Label_Album_Link__ ||
				links[i].Index !== album.Links[i].Index ||
				links[i].Visible !== album.Links[i].Visible
			) {
				setLinksEdited(true);
				return;
			}
		}

		setLinksEdited(false);
	}, [album, links, setLinksEdited]);


	// Used to enable/disable the save btn in create mode
	useEffect(() => {
		if (album) return;
		if (!nameRequiredCheck(name, needDetailedName)) {
			setSaveDisabled(true);
			return;
		}

		if (!genre) {
			setSaveDisabled(true);
			return;
		}


		setSaveDisabled(false);
	}, [name, needDetailedName, genre, setSaveDisabled, album]);


	const handleEdit = () => {
		const data = {
			Name: name,
			Music_Genre__: genre ? genre.Music_Genre__ : null,
			Description: description,
			Tracks: []
		};

		if (!hasFlag(album, 'links_auto') && linksEdited) {
			data['Links'] = links;
		}

		musics.forEach(m => data.Tracks.push(
			{
				Music_Label_Record__: m.Music_Label_Record__,
				Track: m.Track
			}
		));

		if (collaborators.length > 0) {
			data.Artists = [];
			collaborators.forEach(c => {
				data.Artists.push({
					Role: c.Role,
					Music_Label_Artist__: c.Music_Label_Artist__
				});
			});
		}

		if (pictureEdited) {
			updateAlbumImage(album.Music_Label_Album__, picture)
				.then(a => updateAlbum(album.Music_Label_Album__, data))
				.then(b => {
					setPicture(b.Drive_Item__ ? b.Drive_Item : undefined);
				});

		} else {
			updateAlbum(album.Music_Label_Album__, data);
		}

	};

	const handleCreate = () => {
		const data = {
			Name: name,
			Music_Genre__: genre ? genre.Music_Genre__ : null,
			Description: description,
			Tracks: [],
			Links: links
		};

		if (collaborators.length > 0) {
			data.Artists = [];
			collaborators.forEach(c => {
				data.Artists.push({
					Role: c.Role,
					Music_Label_Artist__: c.Music_Label_Artist__
				});
			});
		}

		musics.forEach(m => data.Tracks.push(
			{
				Music_Label_Record__: m.Music_Label_Record__,
				Track: m.Track
			}
		));

		createAlbum(data, picture)
			.then(d => {
				history.push(getMusicAlbumEditRoute(d.Music_Label_Album__));
			});

	};

	const handleSave = () => {
		album ? handleEdit() : handleCreate();
	};

	if (!loaded || !artist) {
		return <Loading/>;
	}

	return (
		<Grid container spacing={3}>
			{isReleased && <Grid item xs={12}>
				<Alert severity="info" action={<ReleaseLink album={album}/>}>
					{t('album_released_edit_alert')}
				</Alert>
			</Grid>}

			<FormRequiredField/>

			<Grid item xs={12}>
				<InputI18N
					value={name}
					onChange={setName}
					onDetailedNameChange={setNeedDetailedName}
					labelKey="album_name"
					disabled={controlDisabled || isReleased}
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<MusicGenre
					disabled={controlDisabled || isReleased}
					required={true}
					value={genre}
					onChange={e => setGenre(e)}
					inputProps={{
						variant: 'outlined',
						required: true,
						fullWidth: true,
						disabled: controlDisabled || isReleased

					}}/>
			</Grid>

			<Grid item xs={12}>
				<MultiLangTextField
					value={description}
					setValue={setDescription}
					variant="outlined"
					multiline rows={5}
					rowsMax={Infinity}
					placeholder={t('album_description_placeholder')}
					fullWidth
					label={t('album_description_label')}
					disabled={controlDisabled || isReleased}
				/>
			</Grid>

			<Grid item xs={12} md={12}>
				<PictureChooser
					label={t('album_picture')}
					disabled={controlDisabled || isReleased}
					value={picture}
					setValue={setPicture}
					defaultValue={(album && album.Drive_Item__) ? album.Drive_Item : undefined}
					pictureEditedChanged={setPictureEdited}
					minWidth={3000}
					minHeight={3000}
					helperText={t('album_picture_helperText')}
				/>
			</Grid>

			<Grid item xs={12}>
				<Collaborators
					value={collaborators}
					onChange={setCollaborators}
					disabled={controlDisabled || isReleased}
					isReview={isReview}
					type="Album"
				/>
			</Grid>

			<Grid item xs={12}>
				<Musics
					disabled={controlDisabled || isReleased}
					setValue={setMusics} value={musics}
					isReview={isReview}
				/>
			</Grid>

			<Grid item xs={12}>
				<Links
					disabled={controlDisabled || isReleased}
					setValue={setLinks}
					value={links}
					auto={hasFlag(album, 'links_auto')}
					isReview={isReview}
				/>
			</Grid>

			{(!isReleased && !isReview) && <Grid item xs={12}>
				<Grid container justify={(album && isAdmin) ? 'space-between' : 'flex-end'} spacing={3}>
					{(album && isAdmin) && <Grid item>
						<Button loading={deleting} variant="text" color="secondary"
						        disabled={controlDisabled}
						        onClick={e => setDeleteDialogOpened(true)}>
							{t('delete_btn')}
						</Button>
					</Grid>}

					{(album && musicsEdited) && <Grid item>
						<Button variant="outlined" color="primary"
						        disabled={controlDisabled}
						        onClick={e => setMusics(album.Tracks)}>
							{t('reset_musics_btn')}
						</Button>
					</Grid>}

					{(album && collaboratorEdited) && <Grid item>
						<Button variant="outlined" color="primary"
						        disabled={controlDisabled}
						        onClick={e => setCollaborators(album.Artists)}>
							{t('reset_collaborators_btn')}
						</Button>
					</Grid>}

					{isManager && <Grid item>
						<Button loading={creating || updating || uploadingAlbumImage} variant="contained"
						        color="primary"
						        disabled={controlDisabled || saveDisabled}
						        onClick={handleSave}>
							{album ? t('save_btn') : t('create_btn')}
						</Button>
					</Grid>}
				</Grid>
			</Grid>}
			{album && <DeleteDialog album={album} open={deleteDialogOpened} setOpen={setDeleteDialogOpened}/>}
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		creating: state.album.creating,
		updating: state.album.updating,
		deleting: state.album.deleting,
		uploadingAlbumImage: state.album.uploadingAlbumImage,
		isManager: state.access.manager,
		isAdmin: state.access.admin,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getText: (id) => dispatch(getText(id)),
		createAlbum: (data, file) => dispatch(createAlbum(data, file)),
		updateAlbum: (id, data) => dispatch(updateAlbum(id, data)),
		updateAlbumImage: (id, file) => dispatch(updateAlbumImage(id, file)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
