import React, { useEffect, useState } from 'react';
import { Grid }                 from '@material-ui/core';
import Button                   from '../../common/inputs/Button';
import { useTranslation }       from 'react-i18next';
import ProductTaxProfile        from '../../common/inputs/ProductTaxProfileSelector';
import { Title }                from '../../common/typography/Title';
import ShippingTypeSelector     from '../../common/inputs/ShippingTypeSelector';
import ProductInput             from '../../common/inputs/ProductInput';
import { isObject }             from '../../utils/misc';
import { getAttribute }         from '../../utils/product';
import ShippingHandlingSelector from '../../common/inputs/ShippingHandlingSelector';
import TextField                from '@material-ui/core/TextField';
import FormRequiredField        from '../../common/feeback/FormRequiredField';

const TaxShipping = ({ handleNext, handleBack, attributes, setAttributes }) => {
	const { t } = useTranslation();
	const [nextEnabled, setNextEnabled] = useState('Basic.TaxProfile' in attributes && attributes['Basic.TaxProfile'] !== null);

	const set = key => e => {
		const keys = key.split('.');
		const family = attributes.values[keys[0]];
		if (Array.isArray(e)) {
			family[keys[1]] = e;
		} else {
			if (family[keys[1]].length < 1) family[keys[1]].push({
				Language__: null,
				Realm__: null,
				Value: '',
			});
			family[keys[1]][0].Value = isObject(e) ? e.target.value : e;
		}

		setAttributes({ ...attributes });
	};

	useEffect(() => {
		const v = getAttribute(attributes, 'Basic.TaxProfile')[0].Value;
		let enabled;
		const split = v.split(',');

		enabled = split.length > 1 && !!(split[0].trim());

		setNextEnabled(enabled);
	}, [attributes, setNextEnabled]);

	let handling = (getAttribute(attributes,'Shipping.Handling')[0].Value ?? '')
	if(handling !== '') handling = handling.split(',')
	else handling = [];

	return (
		<Grid container spacing={3}>

			<Grid item xs={12}>
				<Title>{t('product_selling_attributes')}</Title>
			</Grid>

			<FormRequiredField/>

			<Grid item xs={12}>
				<ProductTaxProfile
					value={getAttribute(attributes, 'Basic.TaxProfile')[0].Value}
					setValue={e =>  set('Basic.TaxProfile')(e)}
					required
					error={!nextEnabled}
					enabledError
				/>
			</Grid>

			<Grid item xs={12}>
				<Title>{t('product_shipping_title')}</Title>
			</Grid>

			<Grid item xs={12} md={6}>
				<ShippingTypeSelector
					value={getAttribute(attributes,'Shipping.Type')[0].Value}
					setValue={e => set('Shipping.Type')(e)}
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<ShippingHandlingSelector
					value={handling}
					setValue={e => set('Shipping.Handling')(e.join(','))}
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<TextField
					label={t('shipping_volumetric_weight_label')}
					placeholder={t('shipping_volumetric_weight_label')}
					helperText={t('shipping_volumetric_weight_HelperText')}
					variant="outlined"
					fullWidth
					value={getAttribute(attributes,'Shipping.Volumetric_Weight')[0].Value}
					onChange={e => set('Shipping.Volumetric_Weight')(e)}
					type='number'
					InputProps={
						{
							step: 'any',
							min: 0,
							endAdornment: 'g'
						}
					}
				/>
			</Grid>

			<Grid item xs={12} md={12}>
				<ProductInput
					allowDelete={false}
					fullWidth
					value={getAttribute(attributes,'Shipping.Description')}
					setValue={set('Shipping.Description')}
					multiline rows={5}
					rowsMax={Infinity}
					label={t('product_shipping_description')}
					helperText={t('product_shipping_description_helperText')}
					variant='outlined'
				/>
			</Grid>


			<Grid item xs={12}>
				<Grid container justify='space-between' spacing={3}>

					<Grid item>
						<Button
							onClick={handleBack}
							variant={'outlined'}
							color='primary'
						>
							{t('back_btn')}
						</Button>
					</Grid>

					<Grid item>
						<Button onClick={handleNext} variant='contained' color='primary' disabled={!nextEnabled}>
							{t('next_btn')}
						</Button>
					</Grid>
				</Grid>
			</Grid>

		</Grid>
	);
};

export default TaxShipping;
