import React                from 'react';
import { Grid, Typography } from '@material-ui/core';
import Box                  from '@material-ui/core/Box';
import { useTranslation }   from 'react-i18next';

const Legend = () => {
	const { t } = useTranslation();
	return (
		<Grid container spacing={3}>
			<Grid item>
				<Typography>{t('record_lyrics_legend_title')}</Typography>
			</Grid>
			<Grid item>
				<Box bgcolor='primary.main' px={2} py='1px' color='white'>
					<Typography>{t('record_lyrics_legend_reading')}</Typography>
				</Box>
			</Grid>
			<Grid item>
				<Box bgcolor='text.disabled' color='white' px={2} py='1px'>
					<Typography>{t('record_lyrics_legend_to_be_set')}</Typography>
				</Box>
			</Grid>
		</Grid>
	);
};

export default Legend;
