import React, {useEffect, useState}     from 'react';
import {FormControlLabel, Grid, Switch} from '@material-ui/core';
import Likes                            from './like/Likes';
import {useTranslation}             from 'react-i18next';
import Toolbar                      from '../../typography/Toolbar';
import CommentsTable                from './comment/table/Table';
import {setCommentsFilters}         from '../../../../store/actions/SocialAction';
import {connect}                    from 'react-redux';

const Social = ({entryData, selectedRealm, setCommentsFilters, filters, disabled = false, templateSettings}) => {
	const {t} = useTranslation();

	const [enableTableDisplay, setEnableTableDisplay] = useState(false);
	let showLike = true;
	let showComment = true;
	if(templateSettings && templateSettings.Data && templateSettings.Data.social) {
		if(!templateSettings.Data.social.includes('like')) {
			showLike = false;
		}

		if(!templateSettings.Data.social.includes('comment')) {
			showComment = false;
		}
	}

	useEffect(() => {
		if (selectedRealm) {
			const f = {
				Realm__: selectedRealm.Realm__,
				MetaObject__: entryData.Content_Cms_Entry__,
				Visible: 'Y',
				Patrol: 'pending',
				sort: {Reports: 'DESC'}
			};
			setCommentsFilters(f);
			setEnableTableDisplay(true);
		} else {
			setEnableTableDisplay(false);
		}
		// eslint-disable-next-line
	}, [selectedRealm, setCommentsFilters, entryData]);

	const handlePatrolChange = ()  => {
		let newFilters = {...filters};
		if ('Patrol' in newFilters) delete newFilters['Patrol']
		else newFilters.Patrol = 'pending';
		setCommentsFilters(newFilters);
	}

	return (
		<Grid container spacing={3}>
			{showLike && <Grid item xs={12}>
				<Likes count={entryData.Likes.Like_Count}/>
			</Grid>}
			{showComment && <Grid item xs={12}>
				<Toolbar title={t('comments_title')}>
					<FormControlLabel
						disabled={disabled}
						control={
							<Switch
								checked={!('Patrol' in filters)}
								onChange={handlePatrolChange}
								name={t('show_all_comments')}
								color="primary"
							/>
						}
						label={t('show_all_comments')}
					/>
				</Toolbar>
			</Grid>}
			{showComment && <Grid item xs={12}>
				<CommentsTable disabled={!enableTableDisplay || disabled} realm={selectedRealm} showResponse/>
			</Grid>}
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		filters: state.social.commentsFilters,

		templateSettings: state.template.templateSettings,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setCommentsFilters: (newPaging) => dispatch(setCommentsFilters(newPaging))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Social);
