export const requiredCheck = (value, isDetailed) => {
    if(!('en-US' in value)) return false;

    if(value['en-US'].trim() === '' ) return false;
    if(!isDetailed) return true;

    if(!('ja-JP' in value) || value['ja-JP'].trim() === '' ) return false;
    if(!('kana' in value) || value['kana'].trim() === '' ) return false;

    return true;
}

export const hasChanged = (original, value) => {
    const originalKeys = Object.keys(original)
    const valueKeys = Object.keys(value)
    if(originalKeys.length !== valueKeys.length) return true;

    for(let i=0; i<originalKeys.length;i++) {
        if(!(originalKeys[i] in value)) return true;
        if(original[originalKeys[i]] !== value[originalKeys[i]]) return true;
    }

    return false;
}

export const isI18NFilled = (values) => {
    if(!values) return false;
    const keys = Object.keys(values);
    if (keys.length < 1) return false;
    for(let i=0; i<keys.length;i++) {
        if(values[keys[i]] && values[keys[i]].trim()) return true;
    }

    return false;
}