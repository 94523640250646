import { rest, setContext }                     from '@karpeleslab/klbfw';
import { handleError }                          from '../../components/utils/apiErrorHandler';
import {
	MUSIC_CREATE_ARTIST,
	MUSIC_CREATE_ARTIST_DONE, MUSIC_DELETE_ARTIST, MUSIC_DELETE_ARTIST_DONE,
	MUSIC_GENRE_FETCH,
	MUSIC_GENRE_FETCH_DONE,
	MUSIC_LABEL_FETCH,
	MUSIC_LABEL_FETCH_DONE, MUSIC_MOOD_FETCH, MUSIC_MOOD_FETCH_DONE
} from '../reducers/MusicReducer';
import { updateArtistImage, updateMemberImage } from './ArtistAction';
import { updateSetting, uploadSettingFiles }    from './TemplateAction';
import { createUserLocation }                   from './system/UserLocationAction';
import { success }    from './system/ToastAction';
import {ready, reset} from './CommonAction';
import {fetch}        from './RealmAction';

export const fetchGenres = (search) => {
	let params = { results_per_page: 100, sort: { Genre: 'ASC' } };
	if (search) {
		params.Genre = { '$like': search + '%' };
	}


	return (dispatch, getState) => {
		dispatch({ type: MUSIC_GENRE_FETCH });
		return rest('Music/Genre', 'GET', params)
			.then(data => {
				dispatch({ type: MUSIC_GENRE_FETCH_DONE, genres: data.data });
				return data.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});

	};
};

export const fetchMoods = (search) => {
	let params = { results_per_page: 100 };
	if (search) {
		params.Genre = { '$like': search + '%' };
	}

	return (dispatch, getState) => {
		dispatch({ type: MUSIC_MOOD_FETCH});
		return rest('Music/Mood', 'GET', params)
			.then(data => {
				dispatch({ type: MUSIC_MOOD_FETCH_DONE, moods: data.data });
				return data.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});

	};
};

export const createArtist = (data, settingFiles, pictures) => {
	return (dispatch, getState) => {
		return new Promise((resolve, reject) => {
			dispatch({ type: MUSIC_CREATE_ARTIST });
			return rest('Music:createHostedArtist', 'POST', data).then(data => {

				const createData = data.data;
				setContext('g', createData.User_Group.User_Group__);

				const uploads = [];
				const artistId = createData.Music_Label_Artist.Music_Label_Artist__;
				uploads.push(dispatch(updateArtistImage(artistId, pictures.artist, 'main')));

				uploads.push(uploadSettingFiles(settingFiles, createData.Setting.Setting__));

				createData.Members.forEach((v, idx) => {
					if (!pictures.members[idx]) return;
					uploads.push(dispatch(updateMemberImage(v.Music_Label_Artist_Member__, pictures.members[idx], 'main')));
				});

				Promise.all(uploads)
					.then(d => {
						dispatch({ type: MUSIC_CREATE_ARTIST_DONE });
						return resolve(createData);
					})
					.catch(reject);
			});
		}).catch((err) => {
			handleError(getState, dispatch, err);
		});


	};
};

const _createFanclub = (data) => {
	return (dispatch, getState) => {
		return rest('Music:createFanclub', 'POST', data).then(d => d.data)
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const createFanclub = (data, address) => {
	return (dispatch, getState) => {
		if (!address) {
			return dispatch(_createFanclub(data))
				.then(d => {
					success('fanclub_created')
					return d.data;
				})
				.catch((err) => {
					handleError(getState, dispatch, err);
				});
		} else {
			const settingId = getState().template.templateSettings.Setting__;
			return dispatch(createUserLocation(address))
				.then((location) => dispatch(updateSetting(settingId, 'location', location.User_Location__)))
				.then(() => dispatch(_createFanclub(data)))
				.then(d => {
					success('fanclub_created')
					return d.data;
				})
				.catch((err) => {
					handleError(getState, dispatch, err);
				});
		}
	};
};

export const fetchLabel = (id) => {
	return (dispatch, getState) => {
		dispatch({ type: MUSIC_LABEL_FETCH });
		return rest('Music/Label/' + id)
			.then(d => {
				dispatch({ type: MUSIC_LABEL_FETCH_DONE, label: d.data });
				return d.data;
			})
			.catch((err) => {
				dispatch({ type: MUSIC_LABEL_FETCH_DONE });
				handleError(getState, dispatch, err);
			});
	};
};


export const deleteSite = (id) => {
	return (dispatch, getState) => {
		dispatch({ type: MUSIC_DELETE_ARTIST });

		return rest('Music:deleteSite', 'POST', {Realm__:id})
			.then(()=> {
				return dispatch(fetch())
			}).then(d => {
				success('artist_delete_success')
				dispatch({ type: MUSIC_DELETE_ARTIST_DONE});
				dispatch(reset(true))
				dispatch(ready())
				return d.data;
			})
				.catch((err) => {
				dispatch({ type: MUSIC_DELETE_ARTIST_DONE });
				handleError(getState, dispatch, err);
			});
	};
};