import { COMMON_RESET } from '../CommonReducer';
import { USER_LOGOUT }  from '../system/UserReducer';
import { EVENT_FETCH }  from './EventReducer';

const initState = () => {
	return {
		announcing: false,
		chatLog: [],
		chatLoading: true,
		chatLimit: 0,
		chatStatus: true,
		chatLogApiEnable: true,
		poolingEnabled: true,
	};
};

export const CHAT_LIST_FETCH = 'CHAT_LIST_FETCH';
export const CHAT_LIST_FETCH_DONE = 'CHAT_LIST_FETCH_DONE';

export const ANNOUNCE_POST = 'ANNOUNCE_POST';
export const ANNOUNCE_POST_DONE = 'ANNOUNCE_POST_DONE';

export const CHAT_STATUS_CHANGE = 'CHAT_STATUS_CHANGE';

export const CHAT_LOG_DELETE = 'CHAT_LOG_DELETE';
export const CHAT_POOLING_ENABLED = 'CHAT_POOLING_ENABLED';

const eventReducer = (state = initState(), action) => {
	switch (action.type) {
		case COMMON_RESET:
		case USER_LOGOUT:
		case EVENT_FETCH:
			return initState();
		case CHAT_POOLING_ENABLED:
			return { ...state, poolingEnabled: action.enabled };
		case CHAT_LIST_FETCH:
			return { ...state, chatLoading: true, chatLogApiEnable: false, };
		case CHAT_LIST_FETCH_DONE:
			if (!action.chatLog) {
				return {
					...state,
					chatLoading: false,
					chatLogApiEnable: true
				};
			}
			return {
				...state,
				chatLoading: false,
				chatLogApiEnable: true,
				chatLog: [
					...state.chatLog,
					...action.chatLog
				]
			};
		case ANNOUNCE_POST:
			return { ...state, announcing: true, };
		case ANNOUNCE_POST_DONE:
			return { ...state, announcing: false, };
		case CHAT_STATUS_CHANGE:
			return {
				...state,
				chatStatus: action.status
			};
		case CHAT_LOG_DELETE:
			return {
				...state
			};
		default:
			return state;
	}

};

export default eventReducer;
