import {useCallback, useState} from 'react';
import {rest}        from '@karpeleslab/klbfw';
import {handleError} from '../../components/utils/apiErrorHandler';
import {getStore}    from '../../store/store';

export const useOrderProcess = orderId => {
	const [loading, setLoading] = useState(false);

	const doAction = useCallback((session = null, method = null, methodData = {}) => {
		let params = {};
		if (session && method) {
			params['session'] = session;
			params['method'] = method;
			params = {...params, ...methodData};
		}
		setLoading(true);
		return rest(`Order/${orderId}:process`, 'POST', params)
			.then(d => d.data)
			.catch(d => {
				const store = getStore();
				handleError(store.getState, store.dispatch, d);
			})
			.finally(() => {
				setLoading(false);
			});

	}, []); //eslint-disable-line

	return [doAction, loading];
};
