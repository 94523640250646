import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Select from "./Select";
import {getLocale} from "@karpeleslab/klbfw";
import Grid from "@material-ui/core/Grid";
import Country from "./Country";


const ProductTaxProfileSelector = ({value, setValue, disabled, required, enabledError = true, error = undefined}) => {
    const {t} = useTranslation();

    const tmp = value.split(',');

    const [internalValue, setInternalValue] = useState(tmp[0]);
    const [country, setCountry] = useState(
        tmp.length > 1 ?
            {
                Country__: tmp[1],
                Name: t('country_' + tmp[1].toLowerCase())
            } :
            {
                Country__: getLocale().split('-')[1].toUpperCase(),
                Name: t('country_' + getLocale().split('-')[1].toLowerCase())
            });

    const changeHandler = e => {
        const v = e.target.value;
        setInternalValue(v);
        setValue(v + ',' + country.Country__);
    };


    useEffect(() => {
        if (!country) return;
        setValue(internalValue + ',' + country.Country__);
        // eslint-disable-next-line
    }, [country]);


    return <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
            <Select
                label={t('product_tax_profile')}
                helperText={t('product_tax_profile_helperText')}
                value={internalValue}
                onChange={changeHandler}
                optionKey='Key'
                optionValue='Value'
                options={[
                    {
                        Key: 'physical',
                        Value: t('product_tax_profile_physical', {country: country ? country.Name : '...'})
                    },
                    {
                        Key: 'virtual_origin',
                        Value: t('product_tax_profile_virtual_origin', {country: country ? country.Name : '...'})
                    },
                    {
                        Key: 'virtual_recipient',
                        Value: t('product_tax_profile_virtual_recipient')
                    },
                ]}
                allowEmpt={false}
                inputProps={{
                    error: enabledError && (!value || error),
                    disabled: disabled,
                    variant: 'outlined',
                    fullWidth: true,
                    required: required,
                }
                }
            />
        </Grid>
        <Grid item xs={12} md={6}>
            <Country
                value={country}
                onChange={e => setCountry(e)}
                disabled={disabled}
                label={t('product_origin_country')}
                helperText={t('product_origin_country_helperText')}
                error={(!value || !country) && enabledError}
                inputProps={{
                    variant: 'outlined',
                    required: required,
                    fullWidth: true,
                }}/>
        </Grid>
    </Grid>;
};


export default ProductTaxProfileSelector;
