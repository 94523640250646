import React, {useEffect} from 'react';
import {setTitle} from "../../../store/actions/CommonAction";
import {connect} from 'react-redux'
import {Helmet} from "react-helmet";


const AppBarTitle = ({title, setTitle}) => {
    useEffect(() => {
        setTitle(title);
    }, [title, setTitle]);

    return (
        <Helmet>
            <title>{title}</title>
        </Helmet>
    );
};


const mapDispatchToProps = (dispatch) => {
    return {
        setTitle: (data) => dispatch(setTitle(data))
    }
};
export default connect(null, mapDispatchToProps)(AppBarTitle);
