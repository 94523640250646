import React              from 'react';
import InputAdornment     from '@material-ui/core/InputAdornment';
import IconButton         from '@material-ui/core/IconButton';
import KeyboardIcon       from '@material-ui/icons/Keyboard';
import TextField          from '@material-ui/core/TextField';
import VATHelperDialog    from '../membership/plans/edit/VATHelperDialog';
import { useTranslation } from 'react-i18next';
import { getCurrency }    from '@karpeleslab/klbfw';
import Grid               from '@material-ui/core/Grid';
import FormControlLabel   from '@material-ui/core/FormControlLabel';
import Checkbox           from '@material-ui/core/Checkbox';

const Price = (props) => {
	const { onValueChange, preferredCurrency, helperEnabled = true, vatIncluded, setVatIncluded, ...rest } = props;

	const [showVATHelper, setShowVATHelper] = React.useState(false);
	const handleOnChange = (e) => {
		onValueChange(e.target.value);
	};
	const { t } = useTranslation();
	const currency = preferredCurrency ?? getCurrency();
	return (
		<Grid container>
			<Grid item xs={12}>
				<TextField
					{...rest}
					onChange={handleOnChange}
					type='number'
					InputProps={
						{
							step: 'any',
							min: 0,
							startAdornment: <InputAdornment position='start'>{currency}</InputAdornment>,
							endAdornment: (helperEnabled && (!!setVatIncluded ? !vatIncluded : true)) ?
								(<InputAdornment position='end'>
									<IconButton
										disabled={rest.disabled}
										aria-label={t('vat_calculator_helper_lbl')}
										onClick={() => setShowVATHelper(true)}
									>
										<KeyboardIcon/>

									</IconButton>
								</InputAdornment>) : undefined
						}
					}
				/>
			</Grid>
			{!!setVatIncluded && <Grid item xs={12}>
				<FormControlLabel
					control={
						<Checkbox
							checked={vatIncluded}
							onChange={e => setVatIncluded(e.target.checked)}
							color='primary'
						/>
					}
					label={t('vat_included_check')}
				/>
			</Grid>}
			<VATHelperDialog open={showVATHelper} setOpen={setShowVATHelper} onClose={onValueChange}/>
		</Grid>
	);
};

export default Price;
