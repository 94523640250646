import React, {useEffect}         from 'react';
import Grid                       from '@material-ui/core/Grid';
import AppBarTitle                from '../../../components/common/typography/AppBarTitle';
import {useTranslation}           from 'react-i18next';
import {connect}                  from 'react-redux';
import SelectRealm                from '../../SelectRealm';
import AddIcon                    from '@material-ui/icons/Add';
import {Link}                     from 'react-router-dom';
import Table                      from '../../../components/common/music/album/table/Table';
import {setAlbumsFilters}         from '../../../store/actions/discography/AlbumAction';
import HasRight                   from '../../../components/Route/HasRight';
import Toolbar                    from '../../../components/common/typography/Toolbar';
import {getMusicAlbumCreateRoute} from '../../../router/routes/music/factory/album';
import Button                     from '../../../components/common/inputs/Button';
import Filters                    from '../../../components/common/music/album/table/Filters';

const List = ({artist, selectedRealm, setAlbumsFilters}) => {
	const {t} = useTranslation();

	useEffect(() => {
		setAlbumsFilters({});
	}, [setAlbumsFilters]);

	if (!selectedRealm || !artist) return <SelectRealm/>;

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('albums_title')}/>
			<Grid item xs={12}>
				<Toolbar title={t('albums_title')}>
					<HasRight manager>
						<Button endIcon={<AddIcon/>} component={Link} to={getMusicAlbumCreateRoute()}
						        variant="contained"
						        color="primary">
							{t('create_btn')}
						</Button>
					</HasRight>
				</Toolbar>

			</Grid>
			<Grid item xs={12}>
				<Filters/>
			</Grid>

			<Grid item xs={12}>
				<Table/>
			</Grid>

		</Grid>

	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		artist: state.artist.artist,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setAlbumsFilters: (filter) => dispatch(setAlbumsFilters(filter))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(List);