import React                                                  from 'react';
import {connect}                                              from 'react-redux';
import {useTranslation}                                       from 'react-i18next';
import {useHistory}                                           from 'react-router-dom';
import HasCapability                                          from '../../../Route/HasCapability';
import {CircularProgress, Grid, List, ListItem, ListItemIcon} from '@material-ui/core';
import ListItemNavLink                                        from '../ListItemNavLink';
import {getFanclubPlansRoute}                                 from '../../../../router/routes/fanclub/factory/plan';
import {MdCardMembership}                                     from 'react-icons/md';
import {getFanclubSubscribersRoute}                           from '../../../../router/routes/fanclub/factory/subscriber';
import {IoIosPeople}                                          from 'react-icons/io';
import {getFanclubArticlesRoute}                              from '../../../../router/routes/fanclub/factory/content';
import {FaClipboardList}                                      from 'react-icons/fa';
import {getFanclubWizardRoute}                                from '../../../../router/routes/fanclub/factory/wizard';
import Button                                                 from '../../../common/inputs/Button';
import {hideMenu}                                             from '../../../../store/actions/CommonAction';
import graphic
                                                              from '../../../../resources/images/fanclub-wizard/step1.svg';

const MenuFanclub = ({selectedFanclub, loading, fanclub_cms, ready, realm, isOwner, hideMenu}) => {

	const disabled = loading || selectedFanclub === null || fanclub_cms == null || !ready;
	const {t} = useTranslation();
	const history = useHistory();
	const displayCreate = ready && !selectedFanclub && realm;

	if (displayCreate && !isOwner) {
		return <p>{t('fanclub_ask_owner')}</p>;
	}

	return (
		<HasCapability fanclub hideLoading>
			<List>
				<div>
					{loading && <ListItemIcon>
						<CircularProgress size={15}/>
					</ListItemIcon>}


					{!displayCreate && <>
						<ListItemNavLink
							tooltip={t('menu_fanclub_plans')}
							disabled={disabled}
							path={getFanclubPlansRoute()}
							icon={<MdCardMembership size={24}/>}
							primary={t('menu_plans')}
						/>

						<ListItemNavLink
							tooltip={t('menu_fanclub_members')}
							disabled={disabled}
							path={getFanclubSubscribersRoute()}
							icon={<IoIosPeople size={24}/>}
							primary={t('menu_members')}
						/>

						<ListItemNavLink
							tooltip={t('menu_fanclub_articles')}
							disabled={disabled}
							path={getFanclubArticlesRoute()}
							icon={<FaClipboardList size={24}/>}
							primary={t('menu_articles')}
						/>
					</>
					}

					{displayCreate &&
					<ListItem onClick={() => {
						hideMenu();
						history.push(getFanclubWizardRoute());
					}}>
						<Grid container spacing={2} justify={'center'}>
							<Grid item style={{textAlign:'center'}}>
								<img src={graphic} alt="artist" width="100px"/>
							</Grid>
							<Grid item xs={12}/>
							<Grid item>
								<Button color="primary" variant="outlined">
									{t('menu_create_fanclub')}
								</Button>
							</Grid>
						</Grid>
					</ListItem>
					}
				</div>
			</List>
		</HasCapability>
	);
};

const mapStateToProps = (state) => {
	return {
		ready: state.common.ready,
		selectedFanclub: state.membership.selectedFanclub,
		loading: state.membership.loadingFanclub,

		fanclub_cms: state.cms.fanclub_cms,
		realm: state.realm.selected,

		isOwner: state.access.owner
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		hideMenu: () => dispatch(hideMenu()),
	};
};


export default connect(mapStateToProps, mapDispatchToProps)(MenuFanclub);
