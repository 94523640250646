import React                  from 'react';
import Grid                   from '@material-ui/core/Grid';
import Wizard                 from '../../components/wizard/fanclub/Wizard';
import AppBarTitle            from '../../components/common/typography/AppBarTitle';
import {useTranslation}       from 'react-i18next';
import {connect}              from 'react-redux';
import {useHistory}           from 'react-router-dom';
import Redirect               from '../../components/Route/Redirect';
import {getPXLDashboardRoute} from '../../router/routes/pxl/factory/general';
import AccessCheck            from '../../components/Route/AccessCheck';

const Create = ({fanclub}) => {
	const {t} = useTranslation();
	const history = useHistory();

	if (fanclub) {
		// Do not allow to create a fanclub if one is already existing
		history.push(getPXLDashboardRoute());
	}


	return (
		<AccessCheck rights={{owner: true}} capabilities={{fanclub: true}}
		             deniedComponent={<Redirect target={getPXLDashboardRoute()}/>}>
			<Grid container spacing={3}>
				<AppBarTitle title={t('create_fanclub_title')}/>

				<Grid item xs={12}>
					<Wizard/>
				</Grid>

			</Grid>
		</AccessCheck>
	);
};


const mapStateToProps = (state) => {
	return {
		fanclub: state.membership.selectedFanclub
	};
};

export default connect(mapStateToProps)(Create);
