import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {Trans, useTranslation} from "react-i18next";
import RichAlert from "../../common/feeback/RichAlert";
import FormData from "../../common/user/location/FormData";
import Button from "../../common/inputs/Button";

const StepAddress = ({handleNext, handleBack, address, setAddress}) => {
    const {t} = useTranslation();
    const [nextEnabled, setNextEnabled] = useState(false)


    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h5" color="textPrimary" component="h5">
                    {t('event_wizard_creating_address_title')}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <RichAlert severity='info'>
                    <Trans i18nKey='event_wizard_creating_address_desc'>
                        sample <br/>
                        <strong>sample</strong>
                    </Trans>
                </RichAlert>
            </Grid>

            <Grid item xs={12}>
                <FormData
                    address={address}
                    setAddress={setAddress}
                    setIsValid={setNextEnabled}
                />
            </Grid>

            <Grid item xs={12}>
                <Grid container justify='space-between' spacing={3}>

                    <Grid item>
                        <Button onClick={handleBack} variant={'outlined'}
                                color="primary">
                            {t('back_btn')}
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button onClick={handleNext} variant='contained' color="primary" disabled={!nextEnabled}>
                            {t('next_btn')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>


        </Grid>
    );
};

export default StepAddress;
