import React, {useEffect, useState}     from 'react';
import {connect}                        from 'react-redux';
import {Trans, useTranslation}          from 'react-i18next';
import Dialog                           from '@material-ui/core/Dialog';
import DialogTitle                      from '@material-ui/core/DialogTitle';
import DialogContent                    from '@material-ui/core/DialogContent';
import Grid                             from '@material-ui/core/Grid';
import DialogActions                    from '@material-ui/core/DialogActions';
import Button                           from '../../inputs/Button';
import Alert                            from '@material-ui/lab/Alert';
import makeStyles                       from '@material-ui/core/styles/makeStyles';
import Autocomplete                     from '@material-ui/lab/Autocomplete';
import TextField                        from '@material-ui/core/TextField';
import CircularProgress                 from '@material-ui/core/CircularProgress';
import {fetchAlbums, setAlbumsFilters}  from '../../../../store/actions/discography/AlbumAction';
import Checkbox                         from '@material-ui/core/Checkbox';
import FormControlLabel                 from '@material-ui/core/FormControlLabel';
import {Link}                           from 'react-router-dom';
import {getMusicDiscographyReviewRoute} from '../../../../router/routes/music/factory/discography';
import FormRequiredField                from '../../feeback/FormRequiredField';

// dirty
const useStyles = makeStyles(() => {
	return {
		fixAlert: {
			'& > .MuiAlert-message': {
				display: 'block',
			}
		}
	};
});

const COMMON_FILTERS = {
	Tracks: {'$gt': 0},
	// remove filter issue #104
	//Duration: {'$lte': (74 * 60) + 42}, // Max duration of an album 74 minutes 42 seconds
	Status: 'draft'
};

const CreateDialog = ({open, setOpen, albums, fetchAlbums, loading, setAlbumsFilters}) => {
	const {t} = useTranslation();
	const classes = useStyles();

	const [album, setAlbum] = useState(null);
	const [saveBtnEnabled, setSaveBtnEnabled] = useState(false);
	const [confirmCheck, setConfirmCheck] = useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		setSaveBtnEnabled(album !== null && confirmCheck);
	}, [album, setSaveBtnEnabled, confirmCheck]);

	useEffect(() => {
		setAlbumsFilters(COMMON_FILTERS);
		fetchAlbums();
	}, [fetchAlbums, setAlbumsFilters]);

	const onChangeHandler = (e) => {
		const value = e.target.value;
		setAlbumsFilters({Name: {'$prefix': value}, ...COMMON_FILTERS});
		fetchAlbums();
	};

	return (
		<Dialog
			open={open}
			aria-labelledby={t('create_release_title')}
			aria-describedby={t('create_release_title')}
		>
			<DialogTitle id="create-release-title">{t('create_release_title')}</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Alert severity="info" className={classes.fixAlert}>
							<Trans i18nKey="release_create_info">
								sample<br/>
								<strong>sample</strong>
								sample
							</Trans>
						</Alert>
					</Grid>
					<FormRequiredField/>
					<Grid item xs={12}>
						<Autocomplete
							id="album-select"
							onBlur={() => {
								setAlbumsFilters(COMMON_FILTERS);
								fetchAlbums();
							}}
							value={album}
							onChange={(e, value) => setAlbum(value)}
							getOptionSelected={(option, value) => option.Music_Label_Album__ === value.Music_Label_Album__}
							getOptionLabel={(option) => option === '' ? '' : option.Name}
							options={albums}
							loading={loading}
							renderInput={(params) => (
								<TextField
									{...params}
									required={true}
									label={t('album_lbl')}
									helperText={t('album_helperText')}
									variant="outlined"
									onChange={onChangeHandler}
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<React.Fragment>
												{loading ? <CircularProgress color="inherit" size={20}/> : null}
												{params.InputProps.endAdornment}
											</React.Fragment>
										),
									}}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControlLabel
							control={<Checkbox
								required
								checked={confirmCheck}
								color="primary"
								name="discount"
								onChange={(e) => {
									setConfirmCheck(e.target.checked);
								}}
							/>}
							label={t('release_create_confirm_lbl')}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button color="default" onClick={handleClose}>
					{t('close_btn')}
				</Button>
				<Button
					component={Link}
					to={getMusicDiscographyReviewRoute(album?.Music_Label_Album__)}
					color="primary"
					variant="contained"
					disabled={!saveBtnEnabled}
				>
					{t('review_btn')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const mapStateToProps = (state) => {
	return {
		albums: state.album.albums,
		loading: state.album.albumsLoading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchAlbums: () => dispatch(fetchAlbums()),
		setAlbumsFilters: (newFilters) => dispatch(setAlbumsFilters(newFilters)),

	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDialog);
