import React, {useEffect, useState} from 'react';
import GridListTile from "@material-ui/core/GridListTile";
import Skeleton from "@material-ui/lab/Skeleton";
import GridList from "@material-ui/core/GridList";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from '@material-ui/core/styles';
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Grid} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import StarIcon from '@material-ui/icons/Star';
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import {connect} from 'react-redux'
import {fetchGallery, productSetMainImage, uploadFiles} from "../../../../../store/actions/store/ProductAction";
import DeleteDialog from "./DeleteDialog";
import {useTranslation} from 'react-i18next';
import Empty from "./Empty";
import {PRODUCT_GALLERY_IMAGE_VARIATION} from "../../../../../store/reducers/store/ProductReducer";
import DeleteIcon from '@material-ui/icons/Delete';
import Toolbar from "../../../typography/Toolbar";
import Button from "../../../inputs/Button";


const useStyles = makeStyles(theme => ({
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
        '&:hover': {
            color: 'rgba(255, 255, 255, 0.8)',
        }
    },
    iconMain: {
        color: 'rgba(255, 255, 255, 1)',
        '&:hover': {
            color: 'rgba(255, 255, 255, 0.8)',
        }
    },
    titleBar: {
        background:
            'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
            'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
}));

const Gallery = ({
                     productId,
                     uploadFiles,
                     uploadImgCount,
                     uploadImgDoneCount,
                     uploadImgRunningCount,
                     uploadImgRunningPercent,
                     fetchGallery,
                     loading,
                     gallery,
                     isManager,
                     setMainImage,
                     settingMainImage
                 }) => {

    useEffect(() => {
        if (!productId) return;
        fetchGallery(productId);
    }, [productId, fetchGallery]);

    const inputFileRef = React.useRef(null);
    const {t} = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [deleteDialogShown, setDeleteDialogShown] = useState(false);
    const [selectedImg, setSelectedImg] = useState(null);

    const loadingData = [...Array(5).keys()];
    const displayLoading = () => loadingData.map((k) =>
        <GridListTile key={k}>
            <Skeleton width={200} height={180}/>
        </GridListTile>);

    const displayImages = () => gallery.map((g) => {
            const img = g.Drive_Item;
            return (
                <GridListTile key={img.Blob__}>
                    <img src={img.Media_Image[PRODUCT_GALLERY_IMAGE_VARIATION]} alt={img.Filename}/>
                    {isManager && <GridListTileBar
                        titlePosition="top"
                        actionIcon={
                            <IconButton disabled={settingMainImage}
                                        className={g.Role === 'main' ? classes.iconMain : classes.icon}
                                        onClick={() => setMainImage(g.Catalog_Product_Image__)}>
                                <StarIcon/>
                            </IconButton>
                        }
                        className={classes.titleBar}
                        actionPosition="left"
                    />}
                    {isManager && <GridListTileBar
                        title={img.Filename}
                        subtitle={moment(parseInt(img.Created.unixms)).format('LLL')}

                        actionIcon={
                            <IconButton disabled={settingMainImage} className={classes.icon} onClick={() => {
                                setSelectedImg(g);
                                setDeleteDialogShown(true)
                            }}>
                                <DeleteIcon/>
                            </IconButton>
                        }
                    />
                    }
                </GridListTile>
            )
        }
    );

    const displayUpload = uploadImgCount !== uploadImgDoneCount;

    let donePercent = 0;
    let bufferPercent = 0;

    if (displayUpload) {
        donePercent = (100 * uploadImgDoneCount) / uploadImgCount;
        bufferPercent = (100 * (uploadImgDoneCount + uploadImgRunningCount)) / uploadImgCount;
        // calculate the % of the currently uploading
        donePercent += ((bufferPercent - donePercent) * uploadImgRunningPercent)
    }

    const handleFileInputChange = (e) => {
        if (e.target.files.length < 1) {
            return;
        }

        uploadFiles(productId, e.target.files)
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Toolbar title={t('gallery_title')}>
                    {(!loading && isManager) &&
                    <input accept="image/png, image/jpeg, image/jpg, image/svg, image/gif" multiple={true} type='file'
                           style={{display: 'none'}} ref={inputFileRef}
                           onChange={handleFileInputChange}/>}
                    {(!loading && isManager) &&
                    <Button variant='contained' color='primary' disabled={displayUpload || settingMainImage}
                            endIcon={<AddIcon/>}
                            loading={settingMainImage}
                            className={classes.addBtn}
                            onClick={() => {
                                inputFileRef.current.click()
                            }}
                    >
                        {t('add_btn')}
                    </Button>}
                </Toolbar>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" color="textSecondary" component="p">
                    {t('product_gallery_desc')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {displayUpload && <LinearProgress variant="buffer" value={donePercent} valueBuffer={bufferPercent}/>}
            </Grid>
            <Grid item xs={12}>
                <GridList cellHeight={180} cols={fullScreen ? 3 : 5}>
                    {loading && displayLoading()}
                    {!loading && displayImages()}
                    {(selectedImg) &&
                    <DeleteDialog productId={productId} open={deleteDialogShown} setOpen={setDeleteDialogShown}
                                  img={selectedImg}/>}
                </GridList>

                {!loading && gallery.length < 1 && <Empty/>}
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        uploadImgCount: state.product.uploadImgCount,
        uploadImgDoneCount: state.product.uploadImgDoneCount,
        uploadImgRunningCount: state.product.uploadImgRunningCount,
        uploadImgRunningPercent: state.product.uploadImgRunningPercent,
        loading: state.product.galleryLoading,
        gallery: state.product.gallery,
        settingMainImage: state.product.setMainImage,
        isManager: state.access.manager
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        uploadFiles: (productId, files) => dispatch(uploadFiles(productId, files, {
            role: 'list',
            image_variation: PRODUCT_GALLERY_IMAGE_VARIATION
        })),
        fetchGallery: (productId) => dispatch(fetchGallery(productId)),
        setMainImage: (imgId) => dispatch(productSetMainImage(imgId)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
