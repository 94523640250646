import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import {connect} from 'react-redux'
import {fetchTemplate} from "../../../../store/actions/CmsAction";
import {useTranslation} from 'react-i18next';
import {Title} from "../../typography/Title";
import Box from "@material-ui/core/Box";
import Button from "../../inputs/Button";


const InsertDialog = ({template, open, setOpen, onInsert}) => {
    const {t} = useTranslation();

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = (e) => {
        e.stopPropagation();
        e.preventDefault();

        onInsert(template.Html)
    };

    return (
        <Dialog
            open={open}
            aria-labelledby={t('insert_into_article')}
            aria-describedby={t('insert_into_article')}
            fullWidth
        >
            <form onSubmit={handleSubmit}>
                <DialogTitle id="template-insert-title">{t('insert_template_article_title')}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Title>{template.Name}</Title>
                        </Grid>
                        <Grid item xs={12}>
                            <Box dangerouslySetInnerHTML={{__html: template.Html}}/>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button color="secondary" autoFocus onClick={handleClose}>
                        {t('cancel_btn')}
                    </Button>
                    <Button color="primary" variant='contained' autoFocus type='submit'>
                        {t('insert_btn')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {
        loading: state.cms.selectedTemplateLoading,
        template: state.cms.selectedTemplate
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchTemplate: (templateId) => dispatch(fetchTemplate(templateId))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(InsertDialog);