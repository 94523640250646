import {Trans}              from 'react-i18next';
import React, {useCallback} from 'react';
import {success}            from '../../../../store/actions/system/ToastAction';
import RichAlert            from '../../feeback/RichAlert';
import {useAutoRefresher}   from '../../../../hooks/useAutoRefresher';

const OrderProcessingAlert = ({refresh, detectComplete}) => {
	const refresher = useCallback(() => {
		refresh().then(o => {
			if (o.order.Status !== 'completed') return;
			detectComplete(o)
			success('order_completed_success');
		});
	}, [refresh, detectComplete]);

	useAutoRefresher(refresher, 60 * 1000);

	return (
		<RichAlert i18nKey="order_processing" severity="info">
			<Trans i18nKey="order_processing">
				sample<br/>
				<strong>sample</strong>
			</Trans>
		</RichAlert>
	);
};

export default OrderProcessingAlert;