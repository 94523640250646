const initState = {
    loading : false,
    languages: []
};

export const LANGUAGE_FETCH = 'LANGUAGE_FETCH';
export const LANGUAGE_FETCH_DONE = 'LANGUAGE_FETCH_DONE';

const languageReducer = (state = initState, action) => {
    switch (action.type) {
        case LANGUAGE_FETCH:
            return  {...state, loading: true};
        case LANGUAGE_FETCH_DONE:
            return  {...state,loading: false, languages: action.languages}
        default:
            return state;
    }

}

export default languageReducer