import {USER_LOGOUT} from "../system/UserReducer";
import {COMMON_RESET} from "../CommonReducer";

const initState = () => {
    return {
        albumsLoading: false,
        albums: [],
        albumsFilters: {},
        albumsPagingCount: 0,
        albumsPaging: {
            page_no: 1,
            results_per_page: 10
        },

        creating: false,
        updating: false,
        deleting: false,
        album: null,
        albumLoading: false,
        uploadingAlbumImage: false,
    }
};

export const ALBUM_LIST_FETCH = 'ALBUM_LIST_FETCH';
export const ALBUM_LIST_FETCH_DONE = 'ALBUM_LIST_FETCH_DONE';
export const ALBUM_FETCH = 'ALBUM_FETCH';
export const ALBUM_FETCH_DONE = 'ALBUM_FETCH_DONE';
export const ALBUM_SET_PAGING = 'ALBUM_SET_PAGING';
export const ALBUM_SET_FILTERS = 'ALBUM_SET_FILTERS';
export const ALBUM_CREATE = 'ALBUM_CREATE';
export const ALBUM_CREATE_DONE = 'ALBUM_CREATE_DONE';
export const ALBUM_UPDATE = 'ALBUM_UPDATE';
export const ALBUM_UPDATE_DONE = 'ALBUM_UPDATE_DONE';
export const ALBUM_DELETE = 'ALBUM_DELETE';
export const ALBUM_DELETE_DONE = 'ALBUM_DELETE_DONE';
export const ALBUM_UPLOAD_IMAGE = 'ALBUM_UPLOAD_IMAGE';
export const ALBUM_UPLOAD_IMAGE_DONE = 'ALBUM_UPLOAD_IMAGE_DONE';

const albumReducer = (state = initState(), action) => {
    switch (action.type) {
        case USER_LOGOUT:
        case COMMON_RESET:
            return initState();
        case ALBUM_UPLOAD_IMAGE:
            return {...state, uploadingAlbumImage: true};
        case ALBUM_UPLOAD_IMAGE_DONE:
            return {...state, uploadingAlbumImage: false};
        case ALBUM_DELETE:
            return {...state, deleting: true};
        case ALBUM_DELETE_DONE:
            return {...state, deleting: false};
        case ALBUM_UPDATE:
            return {...state, updating: true};
        case ALBUM_UPDATE_DONE:
            if (!action.album) return {...state, updating: false};
            return {...state, updating: false, album: action.album};
        case ALBUM_CREATE:
            return {...state, creating: true};
        case ALBUM_CREATE_DONE:
            return {...state, creating: false};
        case ALBUM_FETCH:
            return {...state, albumLoading: true};
        case ALBUM_FETCH_DONE:
            return {...state, albumLoading: false, album: action.album};
        case ALBUM_LIST_FETCH:
            return {...state, albumsLoading: true};
        case ALBUM_LIST_FETCH_DONE:
            return {
                ...state,
                albumsLoading: false,
                albums: action.albums,
                albumsPagingCount: action.paging.count
            };

        case ALBUM_SET_FILTERS :
            return {...state, albumsFilters: action.filters};
        case ALBUM_SET_PAGING :
            return {...state, albumsPaging: action.paging};
        default:
            return state;
    }

};

export default albumReducer
