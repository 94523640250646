import SchedulesList from "../../../pages/planner/schedule/List";
import SchedulesEdit from "../../../pages/planner/schedule/Edit";
import EventsList from "../../../pages/planner/event/List";
import EventsEdit from "../../../pages/planner/event/Edit";
import {
    getSiteEventChatOverlayRoute,
    getSiteEventEditRoute,
    getSiteEventLiveRoute,
    getSiteEventsRoute,
    getSiteScheduleEditRoute,
    getSiteSchedulesRoute
}                 from './factory/planner';
import Live       from "../../../pages/planner/event/Live";
import Chat       from '../../../pages/planner/event/overlay/Chat';

export default [
    {
        path: getSiteSchedulesRoute(),
        component: SchedulesList,
        exact: true,
        type: 'route',
        loginRequired: true,
    },
    {
        path: getSiteScheduleEditRoute(),
        component: SchedulesEdit,
        exact: false,
        type: 'route',
        loginRequired: true,
    },
    {
        path: getSiteEventsRoute(),
        component: EventsList,
        exact: true,
        type: 'route',
        loginRequired: true,
    },
    {
        path: getSiteEventLiveRoute(),
        component: Live,
        exact: false,
        type: 'route',
        loginRequired: true,
    },
    {
        path: getSiteEventChatOverlayRoute(),
        component: Chat,
        exact: false,
        type: 'route',
        loginRequired: false,
    },
    {
        path: getSiteEventEditRoute(),
        component: EventsEdit,
        exact: false,
        type: 'route',
        loginRequired: true,
    },
]