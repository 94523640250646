import React from 'react';
import {useTranslation} from "react-i18next";
import EmptyState from "../../../../../../feeback/State/Empty";
import Graphic from "../../../../../../../../resources/images/shippingFeeZoneValue.svg";

const Empty = () => {
    const {t} = useTranslation();

    return (
        <EmptyState
            title={ t('store_shipping_fee_zone_value_empty_title')}
            textI18Key={'store_shipping_fee_zone_value_empty_desc'}
            graphic={Graphic}
        />
    );

};

export default Empty;