import React, {useEffect, useState} from 'react';
import {useTranslation}             from 'react-i18next';
import {Grid, Toolbar}              from '@material-ui/core';
import AppBarTitle                  from '../../components/common/typography/AppBarTitle';
import {useParams}                  from 'react-router-dom';
import Loading                      from '../../components/common/feeback/loading/Loading';
import {fetchSelectedCanned}        from '../../store/actions/SupportAction';
import {connect}                    from 'react-redux';
import Flag                         from '../../components/common/data/flag/Flag';
import {Title}                      from '../../components/common/typography/Title';
import HasRight                     from '../../components/Route/HasRight';
import DeleteForeverIcon            from '@material-ui/icons/DeleteForever';
import DeleteDialog                 from '../../components/common/support/canned/DeleteDialog';
import useTheme                     from '@material-ui/core/styles/useTheme';
import Edit                         from '../../components/common/support/canned/Edit';
import Button                       from '../../components/common/inputs/Button';
import SelectRealm                  from '../SelectRealm';

const EditCanned = ({canned, loading, fetch, selectedRealm}) => {
	const {t} = useTranslation();
	const {cannedId} = useParams();
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const theme = useTheme();

	useEffect(() => {
		if (!cannedId) return;
		fetch(cannedId);
	}, [cannedId, fetch]);

	if (!selectedRealm) return <SelectRealm/>;
	if (!canned || loading) return <Loading/>;

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('canned_edit_title')}/>
			<Grid item xs={12}>
				<Toolbar>
					<div style={{marginRight: theme.spacing(2)}}>
						<Flag code={canned.Language__.split('-')[1].toLowerCase()}/>
					</div>
					<Title>{t('canned_edit_title')} </Title>
					<HasRight admin hideLoading>
						<Button size="small" color="secondary"
						        startIcon={<DeleteForeverIcon/>}
						        onClick={e => setShowDeleteDialog(true)}
						>
							{t('delete_btn')}
						</Button>
					</HasRight>
				</Toolbar>
			</Grid>

			<Grid item xs={12}>
				<Edit canned={canned}/>
			</Grid>

			<HasRight admin hideLoading>
				<DeleteDialog open={showDeleteDialog} setOpen={setShowDeleteDialog} canned={canned}/>
			</HasRight>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		canned: state.support.selectedCanned,
		loading: state.support.selectedCannedLoading,
		selectedRealm: state.realm.selected,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: (cannedId) => dispatch(fetchSelectedCanned(cannedId))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCanned);
