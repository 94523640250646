import React              from 'react';
import useTheme           from '@material-ui/core/styles/useTheme';
import makeStyles         from '@material-ui/core/styles/makeStyles';
import IconButton         from '@material-ui/core/IconButton';
import FirstPageIcon      from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft  from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon       from '@material-ui/icons/LastPage';
import { useTranslation } from 'react-i18next';
import TextField          from '@material-ui/core/TextField';
//import { TextField }      from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	root: {
		flexShrink: 0,
		marginLeft: theme.spacing(2.5),
	},
}));

const TablePaginationActions = (props) => {
	const classes = useStyles();
	const theme = useTheme();

	const { count, page, rowsPerPage, onPageChange } = props;
	const { t } = useTranslation();


	const handleFirstPageButtonClick = event => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = event => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = event => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = event => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	const onManuallyPageChange = (e) => {
		const max = Math.max(0, Math.ceil(count / rowsPerPage)) - 1;

		let requested = (parseInt(e.target.value) -1 ) || page;
		if (requested < 0) requested = 0;
		if (requested > max) requested = max;
		onPageChange(e, requested);
	};

	return (
		<div className={classes.root}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label={t('first_page')}
			>
				{theme.direction === 'rtl' ? <LastPageIcon/> : <FirstPageIcon/>}
			</IconButton>
			<IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label={t('previous_page')}>
				{theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
			</IconButton>
			<TextField
				size='small'
				value={page + 1}
				onChange={onManuallyPageChange}
				style={{ width: 'auto', padding:'12px' }}
				type='number'
				inputProps={
					{
						min: 0,
						max: Math.max(0, Math.ceil(count / rowsPerPage)),
						style:{textAlign:'center'}
					}
				}
			/>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label={t('next_page')}
			>
				{theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label={t('last_page')}
			>
				{theme.direction === 'rtl' ? <FirstPageIcon/> : <LastPageIcon/>}
			</IconButton>
		</div>
	);
};

export default TablePaginationActions;
