import React, {useEffect, useState} from 'react';
import { Grid} from "@material-ui/core";
import Card             from "@material-ui/core/Card";
import CardMedia                 from "@material-ui/core/CardMedia";
import CardContent               from "@material-ui/core/CardContent";
import Typography                from "@material-ui/core/Typography";
import CardActions               from "@material-ui/core/CardActions";
import makeStyles                from "@material-ui/core/styles/makeStyles";
import GalleryDialog             from "../gallery/GalleryDialog";
import {setImages}               from "../../../../store/actions/CmsAction";
import {connect}                 from 'react-redux'
import { Trans, useTranslation } from 'react-i18next';
import Button                    from "../../inputs/Button";
import RichAlert                 from '../../feeback/RichAlert';
import { GALLERY_DISPLAY_IMAGE } from '../gallery/Gallery';

const useStyles = makeStyles({
    media: {
        height: 140,
    },
    delete: {
        marginLeft: 'auto'
    }
});

const ArticlePictures = ({entryData, updatingEntryData, setImages, isManager, disabled = false}) => {
    const {t} = useTranslation();
    const classes = useStyles();

    const [saveEnabled, setSaveEnabled] = useState(false);


    const [mainImage, setMainImage] = useState(null);
    const [topImage, setTopImage] = useState(null);

    const [imageType, setImageType] = useState('main');
    const [showImgPicker, setShowImgPicker] = useState(false);

    // This use to be sure the initial state is loaded only 1 time, this means if the entryData is changed by another component (update of its content)
    // this component will not change it's internal state. This means for example if we change the article title without safe but we safe some new tags, the articles title changes will no be lost
    const [loaded, setLoaded] = useState(false);

    const [mainImageChanged, setMainImageChanged] = useState(false);
    const [topImageChanged, setTopImageChanged] = useState(false);


    const originalMainImage = entryData ? (entryData.Drive_Item__ ? entryData.Drive_Item : null) : null;
    const originalTopImage = entryData ? (entryData.Top_Drive_Item__ ? entryData.Top_Drive_Item : null) : null;

    useEffect(() => {
        if (!entryData || loaded) return;
        setMainImage(originalMainImage);
        setTopImage(originalTopImage);
        setLoaded(true)

    }, [entryData, loaded, originalMainImage, originalTopImage]);

    useEffect(() => {
        if (!entryData) return;

        let saveEnabled = false;
        let topImageChanged = false;
        let mainImageChanged = false;
        if (mainImage) {
            if (!entryData.Drive_Item__) {
                saveEnabled = true;
                mainImageChanged = true;
            }
            if (entryData.Drive_Item__ !== mainImage.Drive_Item__) {
                saveEnabled = true;
                mainImageChanged = true;
            }
        } else {
            if (entryData.Drive_Item__) {
                saveEnabled = true;
                mainImageChanged = true;
            }
        }

        if (topImage) {
            if (!entryData.Top_Drive_Item__) {
                saveEnabled = true;
                topImageChanged = true;
            }
            if (entryData.Top_Drive_Item__ !== topImage.Drive_Item__) {
                saveEnabled = true;
                topImageChanged = true;
            }
        } else {
            if (entryData.Top_Drive_Item__) {
                saveEnabled = true;
                topImageChanged = true;
            }
        }

        setSaveEnabled(saveEnabled);
        setMainImageChanged(mainImageChanged);
        setTopImageChanged(topImageChanged);
    }, [mainImage, topImage, entryData]);

    const onImgPicked = (img) => {
        if (imageType === 'main')
            setMainImage(img);
        else if (imageType === 'cover')
            setTopImage(img);
    };

    const handleSave = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (!entryData) return;
        setImages(entryData.Content_Cms_Entry_Data__, mainImage ? mainImage.Drive_Item__ : null, topImage ? topImage.Drive_Item__ : null)
    };

    return (
        <Grid container spacing={3}>
	        {(!!topImage && !mainImage) && <Grid item xs={12}>
		        <RichAlert severity='warning'>
			        <Trans i18nKey='article_top_picture_warn'>
				        sample<br/>
				        <strong>sample</strong>
			        </Trans>
		        </RichAlert>
	        </Grid>}

            <Grid item xs={12} md={6}>
                <Card variant='outlined'>
                    {mainImage && <CardMedia
                        className={classes.media}
                        image={mainImage.View_Url}
                        title={t('main_image')}
                    />}
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            {t('main_image')}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {t('image_displayed_listing_articles')}
                            {!mainImage &&
                            <span><br/> {t('no_image_set')}</span>}
                        </Typography>
                    </CardContent>

                    {isManager &&
                    <CardActions disableSpacing>
                        <Button size="small" color="primary" loading={updatingEntryData} disabled={updatingEntryData || disabled} onClick={() => {
                            setImageType('main');
                            setShowImgPicker(true)
                        }}>
                            {t('change_btn')}
                        </Button>

                        {mainImageChanged &&
                        <Button size="small" color="primary" loading={updatingEntryData} disabled={updatingEntryData || disabled} onClick={() => {
                            setMainImage(originalMainImage);
                        }}>
                            {t('revert_changes_btn')}
                        </Button>}

                        {mainImage && <Button className={classes.delete} size="small" loading={updatingEntryData} color="secondary" disabled={updatingEntryData || disabled}
                                onClick={() => {
                                    setMainImage(null);
                                }}>
                            {t('delete_btn')}
                        </Button>}
                    </CardActions>
                    }
                </Card>

            </Grid>


            <Grid item xs={12} md={6}>
                <Card variant='outlined'>
                    {topImage && <CardMedia
                        className={classes.media}
                        image={topImage.View_Url}
                        title={t('cover_img')}
                    />}
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            {t('cover_img')}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {t('image_displayed_user_view_article')}
                            {!topImage &&
                            <span><br/>{t('no_top_image_set')}</span>}
                        </Typography>
                    </CardContent>

                    {isManager &&
                    <CardActions disableSpacing>
                        <Button size="small" color="primary" loading={updatingEntryData} disabled={updatingEntryData || disabled} onClick={() => {
                            setImageType('cover');
                            setShowImgPicker(true)
                        }}>
                            {t('change_btn')}
                        </Button>
                        {topImageChanged &&
                        <Button size="small" color="primary" loading={updatingEntryData} disabled={updatingEntryData || disabled} onClick={() => {
                            setTopImage(originalTopImage);
                        }}>
                            {t('revert_changes_btn')}
                        </Button>}
                        {topImage && <Button className={classes.delete} size="small" color="secondary" loading={updatingEntryData} disabled={updatingEntryData || disabled}
                                onClick={() => {
                                    setTopImage(null);
                                }}>
                            {t('delete_btn')}
                        </Button> }
                    </CardActions>
                    }
                </Card>

            </Grid>

            {isManager &&
            <Grid item xs={12}>
                <Grid container justify='flex-end'>

                    <Button color='primary' variant='contained' loading={updatingEntryData}
                            disabled={!saveEnabled || updatingEntryData || disabled} onClick={handleSave}>
                        {t('save_btn')}
                    </Button>

                </Grid>
            </Grid>
            }

            {entryData &&
            <GalleryDialog
	            entryId={entryData.Content_Cms_Entry__}
	            selectCallback={onImgPicked}
	            galleryMode='select'
	            open={showImgPicker} setOpen={setShowImgPicker}
	            display={GALLERY_DISPLAY_IMAGE}
            />}
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        updatingEntryData: state.cms.updatingEntryData,
        isManager: state.access.manager
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setImages: (entryDataId, main, cover) => dispatch(setImages(entryDataId, main, cover)),
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(ArticlePictures);
