import React            from 'react';
import {useTranslation} from 'react-i18next';
import Select           from './Select';


const FeeScaleTaxProfileSelector = ({value, setValue, disabled, required}) => {
	const {t} = useTranslation();

	const changeHandler = e => {
		const v = e.target.value;
		setValue(v);
	};

	return (
		<Select
			label={t('shipping_scale_tax_profile')}
			helperText={t('shipping_scale_tax_profile_helperText')}
			value={value}
			onChange={changeHandler}
			optionKey="Key"
			optionValue="Value"
			options={[
				{
					Key: 'exempt',
					Value: t('shipping_fee_scale_tax_profile_exempt')
				},
				{
					Key: 'physical',
					Value: t('shipping_fee_scale_tax_profile_physical')
				},
				{
					Key: 'virtual_origin',
					Value: t('shipping_fee_scale_tax_profile_virtual_origin')
				},
				{
					Key: 'virtual_recipient',
					Value: t('shipping_fee_scale_tax_profile_virtual_recipient')
				},
			]}
			allowEmpty={false}
			inputProps={{
				disabled: disabled,
				variant: 'outlined',
				fullWidth: true,
				required: required,
			}
			}
		/>);

};

export default FeeScaleTaxProfileSelector;
