import React from 'react';
import Empty from "../feeback/State/Empty";
import {useTranslation} from "react-i18next";
import Graphic from '../../../resources/images/people.svg'
import AddIcon from "@material-ui/icons/Add";
import {connect} from 'react-redux'
import {getMemberCreateRoute} from "../../../router/routes/artist/factory/members";

const EmptyList = ({isAdmin}) => {
    const {t} = useTranslation();

    const action = {
        title: t('create_btn'),
        endIcon: <AddIcon/>,
        to : getMemberCreateRoute(),
    };

    return (
        <Empty
            title={t('artist_members_empty_title')}
            textI18Key={isAdmin ? 'artist_members_empty_text_admin' : 'artist_members_empty_text'}
            action={isAdmin ? action : null}
            graphic={Graphic}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        isAdmin: state.access.admin
    }
};

export default connect(mapStateToProps)(EmptyList);