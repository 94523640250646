import React            from 'react';
import Grid             from '@material-ui/core/Grid';
import Wizard           from '../../components/wizard/artist/Wizard';
import AppBarTitle      from '../../components/common/typography/AppBarTitle';
import {useTranslation} from 'react-i18next';

const Create = () => {
	const {t} = useTranslation();

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('create_artist_title')}/>

			<Grid item xs={12}>
				<Wizard/>
			</Grid>

		</Grid>
	);
};

export default Create;