import React from 'react';
import Grid from "@material-ui/core/Grid";
import {Area, CartesianGrid, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {useTranslation} from "react-i18next";
import AreaChart from "recharts/lib/chart/AreaChart";
import useTheme from "@material-ui/core/styles/useTheme";
import Skeleton from "@material-ui/lab/Skeleton";
import moment from "moment";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {Title} from "../../typography/Title";
import {pad} from "../../../utils/misc";
import uuid from "../../../utils/uuid";
import {formatPrice} from "../../../../store/actions/system/CurrencyAction";
import {connect} from 'react-redux'
import PieChartNoData from "../../data/charts/pie/PieChartNoData";

const completeStats = (period, stats) => {
    const dayInMonth = moment(period, 'YYYY-MM').daysInMonth();
    let newStats = [];

    const generateEmpty = (day) => {
        return {
            Quantity: {value: 0},
            Total_Vat: {value_float: 0},
            doc_count: 0,
            key: uuid(),
            key_as_string: period + '-' + pad(day, 2)
        }
    };

    let statsIdx = 0;

    for (let i = 0; i < dayInMonth; i++) {
        if (statsIdx > (stats.length - 1)) {
            newStats.push(generateEmpty(i + 1));
            continue;
        }

        if (stats[statsIdx].key_as_string.split('-')[2] === pad(i + 1, 2)) {
            newStats.push(stats[statsIdx]);
            statsIdx++;
            continue;
        }

        newStats.push(generateEmpty(i + 1));
    }

    return newStats;

};

const Sales = ({loading, currency, year, month, stats, formatPrice}) => {
    const {t} = useTranslation();
    const theme = useTheme();

    const total = (currency && currency !== 'all')  ?
        stats.reduce((a, b) => a + parseInt(b.Total_Vat.value_float), 0)
        :
        stats.reduce((a, b) => a + parseInt(b.Quantity.value), 0);

    const CustomTooltip = ({payload, label, active, value}) => {
        if (active && payload) {
            const totalText = (currency && currency !== 'all') ?
                t('dashboard_order_stats_tooltip_total', {total: formatPrice(payload[0].payload.Total_Vat.value_float, currency)}) :
                t('store_order_stats_tooltip_total', {total: payload[0].payload.Quantity.value});

            return (
                <Card>
                    <CardContent>
                        <Typography variant="body1" color="textPrimary"
                                    component="p">{t('store_order_stats_tooltip_period', {period: label})}</Typography>
                        <Typography variant="body1" color="textPrimary"
                                    component="p">{totalText}</Typography>

                    </CardContent>
                </Card>
            );
        }

        return null;
    };

    const gradientOffset = () => {
        const dataMax = Math.max(...stats.map((i) => (currency && currency !== 'all') ? i.Total_Vat.value_float : i.Total.value));
        const dataMin = Math.min(...stats.map((i) => (currency && currency !== 'all') ? i.Total_Vat.value_float : i.Total.value));

        if (dataMax <= 0) {
            return 0
        } else if (dataMin >= 0) {
            return 1
        } else {
            return dataMax / (dataMax - dataMin);
        }
    };

    const off = gradientOffset();

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Title>
                    {t('store_sales_title')}
                </Title>
            </Grid>

            <Grid item xs={12}>
                <Grid container alignItems='flex-end'>
                    <Grid item xs={12} md={4}>
                        {(currency && currency !== 'all') && t('store_income_axis_label', {count: formatPrice(total, currency)})}
                        {(!currency || currency === 'all') && t('store_orders_axis_label', {count: total})}
                    </Grid>

                </Grid>
            </Grid>

            {loading && <Skeleton height={200} width="100%" style={{transform: 'scale(1,1)'}}/>}

            {!loading && <Grid item xs={12}>

                {total > 0 &&
                <ResponsiveContainer width="100%" height={200}>
                    <AreaChart data={completeStats(year + '-' + month, stats)}>
                        <CartesianGrid stroke={theme.palette.text.disabled} strokeDasharray="5 5"/>
                        <XAxis dataKey="key_as_string"/>
                        <YAxis/>
                        <Tooltip content={<CustomTooltip/>}/>
                        <defs>
                            <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                                <stop offset={off} stopColor={theme.palette.primary.main} stopOpacity={1}/>
                                <stop offset={off} stopColor={theme.palette.secondary.main} stopOpacity={1}/>
                            </linearGradient>
                        </defs>
                        <ReferenceLine x={moment().format('YYYY-MM-DD')} stroke="red"/>
                        <Area type="monotone"
                              dataKey={s => (currency && currency !== 'all') ? s.Total_Vat.value_float : s.Quantity.value}
                              stroke={theme.palette.text.primary}
                              fill="url(#splitColor)" isAnimationActive={false}/>
                    </AreaChart>
                </ResponsiveContainer>
                }
                {total <= 0 &&
                <PieChartNoData height={200} text={t("store_orders_no_stats")}/>
                }
            </Grid>
            }
        </Grid>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        formatPrice: (price, currency) => dispatch(formatPrice(price, currency))
    }
};

export default connect(null, mapDispatchToProps)(Sales);