import React                               from 'react';
import {Card, Grid, Table, TableContainer} from '@material-ui/core';
import {BlackPaper}                        from '../surface/Paper';
import Typography                          from '@material-ui/core/Typography';
import {useTranslation}                    from 'react-i18next';
import HasCapability                       from '../../Route/HasCapability';
import Warehouse                           from './Warehouse';
import StoreActivator                      from '../store/StoreActivator';
import FanclubActivator                    from '../fanclub/FanclubActivator';
import ReleasesActivator                   from '../music/discography/ReleasesActivator';
import ScheduleActivator                   from '../planner/ScheduleActivator';
import NewsActivator                       from '../cms/NewsActivator';
import CardContent                         from '@material-ui/core/CardContent';
import makeStyles                          from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
	uppercase: {
		textTransform: 'uppercase',
	},
});

const TaskCenter = () => {
	const {t} = useTranslation();
	const classes = useStyles();
	return (
		<Card elevation={0} component={BlackPaper} style={{height: '100%'}}>
			<CardContent>
				<Grid container spacing={3}>

					<Grid item xs={12}>
						<Typography variant="h5" align="center" className={classes.uppercase}>
							{t('task_center')}
						</Typography>
					</Grid>

					<TableContainer>
						<Table>
							<HasCapability store hideLoading>
								<Warehouse taskCenter/>

								<StoreActivator taskCenter short/>

							</HasCapability>

							<HasCapability fanclub hideLoading>
								<FanclubActivator taskCenter short/>
							</HasCapability>

							<ReleasesActivator taskCenter short/>

							<ScheduleActivator taskCenter short/>

							<NewsActivator taskCenter short/>
						</Table>
					</TableContainer>



				</Grid>
			</CardContent>
		</Card>
	);
};

export default TaskCenter;