import React, {useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {connect} from 'react-redux'
import {fetchVAT} from "../../../../../store/actions/OrderAction";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from 'react-i18next';
import Button from "../../../inputs/Button";


const VATHelperDialog = ({open, setOpen, vatLoading, fetchVat, onClose}) => {
    const [price, setPrice] = useState(0.0);
    const [vat, setVat] = useState(0.0);
    const [result, setResult] = useState(0.0);
    const { t } = useTranslation();
    useEffect(() => {
        fetchVat().then(d => setVat(d.Rate));
    }, [fetchVat, setVat]);

    useEffect(() => {
        setResult(parseFloat((price / (1 + (vat / 100))).toFixed(5)))
    }, [vat, price, setResult]);

    const handleClose = () => {
        setOpen(false);
    };

    const submitHandle = (e) => {
        e.stopPropagation();
        e.preventDefault();
        onClose(result);
        handleClose();
    };

    return (
        <Dialog
            onEnter={() => {
                setPrice(0.0)
            }}
            open={open}
            aria-labelledby={t('vat_helper_title')}
            aria-describedby={t('vat_helper_title')}
            onRendered={() => {
            }}
        >
            <form onSubmit={submitHandle}>
                <DialogTitle id="vat-helper-title">{t('vat_helper_title')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="vat-helper-description">
                        {t('vat_helper_desc')}
                    </DialogContentText>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label={t('price_lbl')}
                                variant="outlined"
                                fullWidth
                                value={price}
                                onChange={e => setPrice(e.target.value)}
                                type='number'
                                InputProps={
                                    {
                                        step: 'any',
                                    }
                                }
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                label={t('vat_lbl')}
                                variant="outlined"
                                fullWidth
                                value={vat}
                                onChange={e => setVat(e.target.value)}
                                type='number'
                                InputProps={
                                    {
                                        step: 'any',
                                        min: 0,
                                        endAdornment: '%'
                                    }
                                }
                            />
                        </Grid>
                    </Grid>


                </DialogContent>
                <DialogActions>
                    <Button color="primary" autoFocus onClick={handleClose}>
                        {t('back_btn')}
                    </Button>
                    <Button type='submit' variant='contained' color="primary" disabled={vatLoading || price === ''}>
                        {t('set_price')} {result}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {
        vatLoading: state.order.vatLoading,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchVat: () => dispatch(fetchVAT())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(VATHelperDialog);
