import {updateUser}                                         from '../store/actions/system/UserActions';
import {fetch}                                              from '../store/actions/RealmAction';
import NotFound                                             from '../pages/NotFound';
import storeRoutes                                          from './routes/store/store';
import recordsRoutes                                        from './routes/music/records';
import scheduleRoutes                                       from './routes/site/planner';
import albumRoutes                                          from './routes/music/album';
import discographyRoutes                                    from './routes/music/discography';
import cannedResponseRoutes                                 from './routes/support/canned';
import boxesRoutes                                          from './routes/support/boxes';
import membersRoutes                                        from './routes/artist/members';
import artistRoutes                                         from './routes/artist/artist';
import fanclubWizardRoutes                                  from './routes/fanclub/wizard';
import fanclubContentRoutes                                 from './routes/fanclub/content';
import subscribersRoutes                                    from './routes/fanclub/subscriber';
import plansRoutes                                          from './routes/fanclub/plan';
import artistWizardRoutes                                   from './routes/artist/wizard';
import usersRoutes                                          from './routes/site/user';
import ordersRoutes                                         from './routes/site/order';
import contentRoutes                                        from './routes/site/content';
import settingsRoutes                                       from './routes/site/setting';
import profileRoutes                                        from './routes/pxl/profile';
import workGroupRoutes                                      from './routes/pxl/workGroup';
import generalRoutes                                        from './routes/pxl/general';
import orderRoutes                                          from './routes/pxl/order';
import {getPXLJoinRoute, getPXLLoginRoute, getPXLPwdForget} from './routes/pxl/factory/general';
import {getSiteEventChatOverlayRoute}                       from './routes/site/factory/planner';

export const layoutLessRoute = [
	getPXLLoginRoute(),
	getPXLPwdForget(),
	getPXLJoinRoute(),
	getSiteEventChatOverlayRoute()
];


// This set of promises will waited prior running all loadData promises.
export const prerequisites = (store) => {
	return [
		store.dispatch(updateUser())
			.then(u => u ? store.dispatch(fetch()) : '')
			.catch(() => {
			}), // ignore error
	];
};

export default [
	...generalRoutes,
	...orderRoutes,

	...profileRoutes,
	...workGroupRoutes,

	// Artist routes
	...artistRoutes,
	...membersRoutes,
	...artistWizardRoutes,

	// Site routes
	...usersRoutes,
	...contentRoutes,
	...ordersRoutes,
	...settingsRoutes,

	// Schedule route
	...scheduleRoutes,

	// Support route
	...boxesRoutes,
	...cannedResponseRoutes,

	// Music routes
	...discographyRoutes,
	...albumRoutes,
	...recordsRoutes,

	// Fanclub routes
	...fanclubWizardRoutes,
	...fanclubContentRoutes,
	...plansRoutes,
	...subscribersRoutes,


	// Store routes
	...storeRoutes,


	{
		path: '*',
		component: NotFound,
		type: 'route',
		loginRequired: false,
	},
];
