import React, {useEffect}           from 'react';
import {connect}                    from 'react-redux';
import {fetchMoods}                 from '../../../store/actions/MusicAction';
import {useTranslation}             from 'react-i18next';
import Autocomplete                 from '@material-ui/lab/Autocomplete';
import TextField                    from '@material-ui/core/TextField';
import CircularProgress             from '@material-ui/core/CircularProgress';
import AutocompleteListboxComponent from './AutocompleteListboxComponent';

const MusicMood = ({loading, list, fetch, value, onChange, required = false, disabled = false}) => {
	const {t} = useTranslation();
	const [open, setOpen] = React.useState(false);

	useEffect(() => {
		fetch();
	}, [fetch]);

	return (
		<Autocomplete
			id="music-mood-select"
			open={open}
			onClose={() => {
				setOpen(false);
			}}
			onOpen={() => {
				setOpen(true);
			}}
			value={value}
			onChange={(e, value) => onChange(value)}
			getOptionSelected={(option, value) => option.Music_Mood__ === value.Music_Mood__}
			getOptionLabel={(option) => option === '' ? '' : (option.Name)}
			options={list}
			loading={loading}
			disabled={disabled}
			ListboxComponent={AutocompleteListboxComponent}
			disableListWrap
			renderInput={(params) => (
				<TextField
					{...params}
					required={required}
					label={t('music_mood_lbl')}
					variant="outlined"
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<React.Fragment>
								{loading ? <CircularProgress color="inherit" size={20}/> : null}
								{params.InputProps.endAdornment}
							</React.Fragment>
						),
					}}
				/>
			)}
		/>);
};

const mapStateToProps = (state) => {
	return {
		loading: state.music.loadingMoods,
		list: state.music.moods,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: (search) => dispatch(fetchMoods(search))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MusicMood);
