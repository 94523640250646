import React             from 'react';
import ListItemNavLink   from '../ListItemNavLink';
import {getMembersRoute} from '../../../../router/routes/artist/factory/members';
import PeopleAltIcon     from '@material-ui/icons/PeopleAlt';
import FaceIcon          from '@material-ui/icons/Face';
import {getArtistRoute}  from '../../../../router/routes/artist/factory/artist';
import {List}            from '@material-ui/core';
import {useTranslation}  from 'react-i18next';
import {connect}         from 'react-redux';

const MenuArtist = ({selectedRealm, ready, artist}) => {
	const {t} = useTranslation();

	const disabled = !selectedRealm || !ready || !artist;

	return (
		<List>
			<div>
				<ListItemNavLink
					tooltip={t('menu_artist')}
					path={getArtistRoute()}
					exact={true}
					icon={<FaceIcon size={24}/>}
					primary={t('menu_artist')}
					disabled={disabled}
				/>
				<ListItemNavLink
					tooltip={t('menu_artist_members')}
					path={getMembersRoute()}
					exact={true}
					icon={<PeopleAltIcon size={24}/>}
					primary={t('menu_artist_members')}
					disabled={disabled}
				/>
			</div>
		</List>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		ready: state.common.ready,
		artist: state.artist.artist
	};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuArtist);
