import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {Trans, useTranslation} from "react-i18next";
import StripeAccountSelector from "../../common/stripe/StipeAccountSelector";
import Button from "../../common/inputs/Button";
import MaterialLink from "@material-ui/core/Link";

const StepStripe = ({handleBack, handleNext, stripeAccount, setStripeAccount}) => {
    const {t} = useTranslation();
    const [nextEnabled, setNextEnabled] = useState(false);

    useEffect(() => {
        setNextEnabled(stripeAccount !== null)
    }, [stripeAccount, setNextEnabled]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h5" color="textPrimary" component="h5">
                    {t('store_wizard_step_stripe_title')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" color="textSecondary" component="p">
                    <Trans i18nKey='store_wizard_step_stripe_desc'>
                        sample <br/>
                        <strong>sample</strong>
                        <MaterialLink href='https://stripe.com' target='_blank' rel="noopener noreferrer">Stripe link</MaterialLink>
                    </Trans>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <StripeAccountSelector value={stripeAccount} onChange={setStripeAccount}/>
            </Grid>


            <Grid item xs={12}>
                <Grid container justify='space-between' spacing={3}>

                    <Grid item>
                        <Button onClick={handleBack} variant={'outlined'}
                                color="primary">
                            {t('back_btn')}
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button onClick={handleNext} variant='contained' color="primary" disabled={!nextEnabled}>
                            {t('next_btn')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default StepStripe;