import React, {useEffect, } from "react";
import {
	PaymentElement,
	useStripe,
	useElements
} from "@stripe/react-stripe-js";


export default function StripeIntent({setStripe, setStripeElements, onChange}) {
	const stripe = useStripe();
	const stripeElements = useElements()

	useEffect(() => {
		setStripe(stripe);
		setStripeElements(stripeElements);
	}, [setStripe, setStripeElements, stripe, stripeElements]);

	const paymentElementOptions = {
		layout: "tabs"
	}

	return (
		<PaymentElement options={paymentElementOptions} onChange={onChange}/>
	);
}