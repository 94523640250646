import React, { useEffect, useState } from 'react';
import { useTranslation }             from 'react-i18next';
import uuid                           from '../../../utils/uuid';

const Audio = React.forwardRef((props, ref) => {
	const { t } = useTranslation();
	const [key, setKey] = useState(uuid());
	const { mp3, disabled = false,...rest } = props;

	useEffect(() => {
		// Force rerender of the component if the source changes
		setKey(uuid());
	}, [mp3]);

	return (
		<audio controls={!disabled} key={key} ref={ref} {...rest}>
			<source src={mp3} type='audio/mpeg'/>
			{t('audio_element_not_supported')}
		</audio>
	);
});

export default Audio;
