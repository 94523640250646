import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import SelectRealm from '../../SelectRealm';
import {fetchSchedule} from '../../../store/actions/planner/ScheduleAction';
import {useParams} from 'react-router-dom';
import Loading from '../../../components/common/feeback/loading/Loading';
import {Tab, Tabs, Toolbar} from '@material-ui/core';
import {Title} from '../../../components/common/typography/Title';
import AppBarTitle from '../../../components/common/typography/AppBarTitle';
import Grid from '@material-ui/core/Grid';
import Panel from '../../../components/common/data/tab/Panel';
import Content from '../../../components/common/cms/entry/Content';
import Gallery from '../../../components/common/cms/gallery/Gallery';
import Templates from '../../../components/common/cms/template/Templates';
import {fetchAndSelectCms, fetchEntryData} from '../../../store/actions/CmsAction';
import Publication from '../../../components/common/planner/schedule/Publication';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Social from '../../../components/common/cms/social/Social';
import Management from '../../../components/common/planner/schedule/Management';
import ScheduleActivator from '../../../components/common/planner/ScheduleActivator';
import EntryDataSelector from '../../../components/common/planner/EntryDataSelector';


const TAB_CONTENT = 'TAB_CONTENT';
const TAB_VISIBILITY = 'TAB_VISIBILITY';
const TAB_GALLERY = 'TAB_GALLERY';
const TAB_TEMPLATE = 'TAB_TEMPLATE';
const TAB_SOCIAL = 'TAB_SOCIAL';
const TAB_MANAGEMENT = 'TAB_MANAGEMENT';

const Edit = ({
	              schedule,
	              planner,
	              selectedRealm,
	              fetchSchedule,
	              fetchAndSelectCms,
	              entryData,
	              fetchEntryData,
	              templateSettings
              }) => {
	const {t} = useTranslation();
	const [loading, setLoading] = useState(true);
	const {scheduleId} = useParams();
	const [tabIndex, setTabIndex] = useState(TAB_CONTENT);

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {noSsr: true});

	let showSocial = true;
	if (templateSettings && templateSettings.Data && templateSettings.Data.social === 'none')
		showSocial = false;

	useEffect(() => {
		if (!planner) return;
		setLoading(true);
		fetchSchedule(scheduleId)
			.then(e => fetchEntryData(e.Content_Cms_Entry_Data.Content_Cms_Entry_Data__))
			.then(e => fetchAndSelectCms(planner.Content_Cms__))
			.then(
				e => {
					setLoading(false);
				}
			);
	}, [planner, scheduleId, fetchSchedule, fetchAndSelectCms, fetchEntryData]);

	if (!selectedRealm || !planner) return <SelectRealm/>;

	const handleTabChange = (event, newValue) => {
		setTabIndex(newValue);
	};

	if (loading) return <Loading/>;

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('schedules_edit_title')}/>
			<Grid item xs={12}>
				<Toolbar>
					<Title>{t('schedules_edit_title')}</Title>
				</Toolbar>
			</Grid>

			<ScheduleActivator/>

			<Grid item xs={12}>
				<Tabs
					variant="scrollable"
					scrollButtons={isMobile ? 'on' : 'auto'}
					value={tabIndex}
					indicatorColor="primary"
					textColor="primary"
					onChange={handleTabChange}
					aria-label=""
				>
					<Tab label={t('article_tab_content')} value={TAB_CONTENT}/>
					<Tab label={t('article_tab_visibility')} value={TAB_VISIBILITY}/>
					<Tab label={t('article_tab_gallery')} value={TAB_GALLERY}/>
					<Tab label={t('article_tab_templates')} value={TAB_TEMPLATE}/>
					{showSocial && <Tab label={t('article_tab_social')} value={TAB_SOCIAL}/>}
					<Tab label={t('article_tab_management')} value={TAB_MANAGEMENT}/>
				</Tabs>

				<Panel value={tabIndex} index={TAB_CONTENT}>
					<EntryDataSelector/>
					<Content entryData={entryData} key={entryData.Content_Cms_Entry_Data__}/>
				</Panel>

				<Panel value={tabIndex} index={TAB_VISIBILITY}>
					<Publication/>
				</Panel>
				<Panel value={tabIndex} index={TAB_GALLERY}>
					<Gallery entryId={schedule.Content_Cms_Entry__} galleryMode="manage"/>
				</Panel>

				<Panel value={tabIndex} index={TAB_TEMPLATE}>
					<Templates cmsId={planner.Content_Cms__}/>
				</Panel>

				{showSocial && <Panel value={tabIndex} index={TAB_SOCIAL}>
					<Social entryData={entryData}/>
				</Panel>}

				<Panel value={tabIndex} index={TAB_MANAGEMENT}>
					<Management schedule={schedule} isEvent={false} setTabIndex={setTabIndex}/>
				</Panel>
			</Grid>

		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		planner: state.schedule.planner,
		schedule: state.schedule.schedule,
		entryData: state.cms.entryData,

		templateSettings: state.template.templateSettings,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchSchedule: (id) => dispatch(fetchSchedule(id)),
		fetchEntryData: (id) => dispatch(fetchEntryData(id)),
		fetchAndSelectCms: (id) => dispatch(fetchAndSelectCms(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
