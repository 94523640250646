import React from 'react';
import {useTranslation} from "react-i18next";
import Graphic from "../../../../../resources/images/notify.svg";
import EmptyState from "../../../feeback/State/Empty";

const Empty = () => {
    const {t} = useTranslation();
    return (
        <EmptyState
            title={t('massmail_segment_empty_title')}
            textI18Key='massmail_segment_empty_text'
            graphic={Graphic}
        />
    );

};

export default Empty;