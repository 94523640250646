import React, {useEffect}                                            from 'react';
import {connect}                                                     from 'react-redux';
import {useTranslation}                                              from 'react-i18next';
import {fetchNextEventList}                                          from '../../../store/actions/planner/EventAction';
import {getSiteEventEditRoute, getSiteEventsRoute}                   from '../../../router/routes/site/factory/planner';
import Grid                                                          from '@material-ui/core/Grid';
import moment                                                        from 'moment';
import Typography                                                    from '@material-ui/core/Typography';
import Button                                                        from '../inputs/Button';
import {BlackPaper}                                                  from '../surface/Paper';
import {Box, Card, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import Link                                                          from '../typography/Link';
import {Launch}                                                      from '@material-ui/icons';
import CardContent                                                   from '@material-ui/core/CardContent';
import makeStyles                                                    from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
	uppercase: {
		textTransform: 'uppercase',
	},
});

const NextEvent = ({events, planner, fetchNextEventList}) => {
	const {t} = useTranslation();
	const classes = useStyles();

	useEffect(() => {
		if (!planner) return;
		fetchNextEventList(planner.Planner__);

	}, [planner, fetchNextEventList]);

	useEffect(() => {
		if (!planner) return;

		const refreshInterval = setInterval(() => {
			if (!planner) return;
			fetchNextEventList(planner.Planner__, true);
		}, 30000);

		return () => {
			clearInterval(refreshInterval);
		};
	}, [planner, fetchNextEventList]);

	return (
		<Card elevation={0} component={BlackPaper} style={{height: '100%'}}>
			<CardContent>
				<Grid container spacing={3} direction="column" justify="space-between">
					<Grid item xs={12}>
						<Typography variant="h5" align="center" className={classes.uppercase}>
							{t('next_event_title')}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Table>
							<TableHead>
								<TableCell>{t('event_date')}</TableCell>
								<TableCell>{t('event_title')}</TableCell>
								<TableCell align="right">{t('ticket_sold')}</TableCell>
							</TableHead>
							<TableBody>
								{events.map(e => <TableRow key={e.Planner_Event__}>

									<TableCell>{moment(parseInt(e.Event_Date.unixms)).format('LLL')}</TableCell>
									<TableCell><Link
										to={getSiteEventEditRoute(e.Planner_Event__)}>{e.Schedule.Content_Cms_Entry_Data.Title}</Link></TableCell>
									<TableCell align="right">{e.Total_Count}/{e.Total_Capacity}</TableCell>
								</TableRow>)}
							</TableBody>
							{(events.length < 1) && <TableRow>
								<TableCell colSpan={3}>
									<Typography align="center">
										{t('no_next_event')}
									</Typography>
								</TableCell>
							</TableRow>}
						</Table>
					</Grid>
					<Grid item xs={12}>
						<Box style={{textAlign: 'center', width: '100%'}}>
							<Button variant="text" color="primary" endIcon={<Launch/>} component={Link}
							        to={getSiteEventsRoute()}>
								{t('see_events_btn')}
							</Button>
						</Box>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		events: state.event.nextEventList,
		planner: state.schedule.planner,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchNextEventList: (plannerId, silent = false) => dispatch(fetchNextEventList(plannerId, silent))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(NextEvent);
