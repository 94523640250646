import {
	getPXLBillingCreateRoute,
	getPXLBillingRoute,
	getPXLBillingsRoute,
	getPXLOrderRoute,
	getPXLOrdersRoute
}              from './factory/order';
import Orders
               from '../../../pages/order/Orders';
import Order
               from '../../../pages/order/Order';
import Billings
               from '../../../pages/billing/Billings';
import Create
               from '../../../pages/billing/Create';
import Billing from '../../../pages/billing/Billing';

export default [
	{
		path: getPXLBillingCreateRoute(),
		component: Create,
		exact: true,
		type: 'route',
		loginRequired: true,
	},
	{
		path: getPXLBillingRoute(),
		component: Billing,
		exact: true,
		type: 'route',
		loginRequired: true,
	},
	{
		path: getPXLBillingsRoute(),
		component: Billings,
		exact: true,
		type: 'route',
		loginRequired: true,
	},
	{
		path: getPXLOrdersRoute(),
		component: Orders,
		exact: true,
		type: 'route',
		loginRequired: true,
	},
	{
		path: getPXLOrderRoute(),
		component: Order,
		exact: true,
		type: 'route',
		loginRequired: true,
	},
];