import {
    MEMBERSHIP_EXPORT,
    MEMBERSHIP_EXPORT_DONE,
    MEMBERSHIP_FAN_CLUB_FETCH,
    MEMBERSHIP_FAN_CLUB_FETCH_DONE,
    MEMBERSHIP_MEMBERS_FETCH,
    MEMBERSHIP_MEMBERS_FETCH_DONE,
    MEMBERSHIP_MEMBERS_SET_FILTERS,
    MEMBERSHIP_MEMBERS_SET_PAGING,
    MEMBERSHIP_UNSUBSCRIBE,
    MEMBERSHIP_UNSUBSCRIBE_DONE,
    MEMBERSHIP_UPDATE,
    MEMBERSHIP_UPDATE_DONE,
    MEMBERSHIP_USER_FETCH,
    MEMBERSHIP_USER_FETCH_DONE
} from "../../reducers/fanclub/MembershipReducer";
import {rest} from "@karpeleslab/klbfw";
import {error, success} from "../system/ToastAction";
import {handleError} from "../../../components/utils/apiErrorHandler";

export const fetchAndSelectFanclub = (fanclubId) => {
    return (dispatch, getState) => {
        dispatch({type: MEMBERSHIP_FAN_CLUB_FETCH});
        return rest('Membership/' + fanclubId)
            .then(data => {
                dispatch(selectFanclub(data.data));
                return data.data
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const selectFanclub = (fanclub) => {
    return (dispatch, getState) => {
        dispatch({type: MEMBERSHIP_FAN_CLUB_FETCH_DONE, fanclub: fanclub});
    }
};

export const setMembersPaging = (newPaging) => {
    return (dispatch, getState) => {
        dispatch({type: MEMBERSHIP_MEMBERS_SET_PAGING, paging: {...getState().membership.membersPaging, ...newPaging}});
    }
};

export const setMembersFilters = (newFilters) => {
    return (dispatch, getState) => {
        dispatch({type: MEMBERSHIP_MEMBERS_SET_FILTERS, filters: newFilters});
    }
};

export const fetchMembers = () => {
    return (dispatch, getState) => {
        dispatch({type: MEMBERSHIP_MEMBERS_FETCH});

        const params = {
            ...getState().membership.membersFilters,
            ...getState().membership.membersPaging
        };

        return rest('Membership/' + getState().membership.selectedFanclub.Membership__ + '/User:adminSearch', 'POST', params)
            .then(data => {
                dispatch({type: MEMBERSHIP_MEMBERS_FETCH_DONE, members: data.data, paging: data.paging});
                return data.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const fetchMembershipUser = (id, params = {}) => {
    return (dispatch, getState) => {
        dispatch({type: MEMBERSHIP_USER_FETCH});

        return rest('Membership/User/' + id, 'GET', params)
            .then(data => {
                dispatch({type: MEMBERSHIP_USER_FETCH_DONE, membershipUser: data.data});
                return data.data;
            }).catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const fetchMembershipUserByUser = (userId, params = {}) => {
    return (dispatch, getState) => {
        dispatch({type: MEMBERSHIP_USER_FETCH});
        params.User__ = userId;

        return rest('Membership/' + getState().membership.selectedFanclub.Membership__ + '/User', 'GET', params)
            .then(data => {
                const mu = data.data.length < 1 ? null : data.data[0];
                dispatch({type: MEMBERSHIP_USER_FETCH_DONE, membershipUser: mu});
                return mu;
            }).catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const exportMembers = () => {
    return (dispatch, getState) => {
        dispatch({type: MEMBERSHIP_EXPORT});
        return rest('Membership/' + getState().membership.selectedFanclub.Membership__ + '/User:export', 'POST', getState().membership.membersFilters)
            .then((data) => {
                dispatch({type: MEMBERSHIP_EXPORT_DONE, url: data.data});

                return data.data;
            })
            .catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const unsubscribe = () => {
    return (dispatch, getState) => {
        dispatch({type: MEMBERSHIP_UNSUBSCRIBE});
        return rest('Membership/User/' + getState().membership.membershipUser.Membership_User__ + ':unsubscribe', 'POST')
            .then((data) => {
                dispatch({type: MEMBERSHIP_UNSUBSCRIBE_DONE, membershipUser: data.data});
                success('membership_unsubscribe_success');
                return data.data;
            }).catch((err) => {
                error(err.message, false);
                handleError(getState, dispatch, err)
            })
    }
};

export const updateMembership = (data) => {
    return (dispatch, getState) => {
        dispatch({type: MEMBERSHIP_UPDATE});
        return rest('Membership/User/' + getState().membership.membershipUser.Membership_User__, 'PATCH', data)
            .then((data) => {
                dispatch({type: MEMBERSHIP_UPDATE_DONE, membershipUser: data.data});
                success('membership_update_success');
                return data.data;
            }).catch((err) => {
                error(err.message, false);
                handleError(getState, dispatch, err)
            })
    }
};