import React, {useEffect, useState} from 'react';
import {fetchStripeLink, fetchStripeTokens} from "../../../store/actions/system/OAuth2Action";
import {connect} from 'react-redux'
import Grid from "@material-ui/core/Grid";
import Loading from "../feeback/loading/Loading";
import StripeLogo from "../../../resources/images/fanclub-wizard/stripeLogo.svg";
import {useTranslation} from "react-i18next";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Radio from "@material-ui/core/Radio";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardHeader from "@material-ui/core/CardHeader";


const StripeAccountSelector = ({fetchStripeLink, fetchStripeTokens, stripeTokensLoading, stripeTokens, value, onChange, disabled = false}) => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchStripeTokens();
    }, [fetchStripeTokens]);

    const handleStripeLink = () => {
        setLoading(true);
        const redirectUrl = window.location.href.includes('#stripe') ? window.location.href : window.location.href + '#stripe';
        fetchStripeLink(redirectUrl)
            .then((d) => {
                window.location = d.redirect_url;
            })
    };

    const createChangeHandler = stripeToken => e => {
        onChange(stripeToken)
    };

    const isChecked = (token) => {
        if (!value) return false;
        return value.OAuth2_Consumer_Token__ === token.OAuth2_Consumer_Token__
    };

    if (stripeTokensLoading) {
        return (
            <Grid item xs={12}>
                <Grid container direction='column' alignItems='center'>
                    <Grid item xs={2}>
                        <Loading/>
                    </Grid>
                </Grid>
            </Grid>)
    }

    return (
        <Grid container spacing={3}>
            {stripeTokens.map(token =>

                <Grid item key={token.OAuth2_Consumer_Token__}>
                    <Card variant='outlined'>
                        <CardContent>
                            <CardActionArea onClick={createChangeHandler(token)}>
                                <CardHeader
                                    action={
                                        <Radio
                                            disabled={disabled || loading}
                                            color='primary'
                                            checked={isChecked(token)}
                                            onChange={createChangeHandler(token)}
                                            value={token.Name}
                                            name="radio-button-demo"
                                            inputProps={{'aria-label': token.Name}}
                                        />
                                    }
                                    title={token.Name}
                                    subheader={t('stripe_selector_select_lbl')}
                                />

                            </CardActionArea>
                        </CardContent>
                    </Card>
                </Grid>
            )}


            <Grid item>
                <Card variant='outlined'>
                    <CardContent>
                        <CardActionArea onClick={handleStripeLink}>
                            <CardHeader
                                action={
                                    !loading ? <img alt='Stripe logo' src={StripeLogo} height={32}/> :
                                        <Loading thickness={1}/>
                                }
                                title={t('link_stripe_btn')}
                                subheader={t('link_stripe_btn_desc')}
                            />

                        </CardActionArea>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};
const mapStateToProps = (state) => {
    return {
        stripeTokensLoading: state.oauth2.stripeTokensLoading,
        stripeTokens: state.oauth2.stripeTokens,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchStripeLink: (returnUrl) => dispatch(fetchStripeLink(returnUrl)),
        fetchStripeTokens: () => dispatch(fetchStripeTokens())
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(StripeAccountSelector);
