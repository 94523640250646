import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {connect} from 'react-redux'
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from 'react-i18next';
import {fetchTokens, unlinkToken} from "../../../../store/actions/system/OAuth2Action";
import Button from "../../inputs/Button";

const DeleteDialog = ({open, setOpen, unlink, token, unlinkToken, refresh, refreshing}) => {
    const {t} = useTranslation();

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        unlinkToken(token.OAuth2_Consumer_Token__)
            .then(() => {
                refresh().then(handleClose);
                handleClose();
            })
    };

    return (
        <Dialog
            open={open}
            aria-labelledby={t('confirm_delete_token_title')}
            aria-describedby={t('confirm_delete_token_title')}
            fullWidth
        >
            <DialogTitle id="canned-delete-title">{t('confirm_delete_token_title')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="canned-delete-description">
                    {t('confirm_delete_token_desc')}
                    <br/>
                    <Typography variant="body1" color="textPrimary" component="span">{t('are_you_sure')}</Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' color="primary" autoFocus onClick={handleClose}
                        disabled={unlink || refreshing}>
                    {t('back_btn')}
                </Button>
                <Button color="secondary"  disabled={unlink || refreshing} loading={unlink || refreshing}
                        endIcon={<DeleteForeverIcon/>}
                        onClick={handleDelete}>
                    {t('delete_btn')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {
        unlink: state.oauth2.unlink,
        refreshing: state.oauth2.tokensLoading,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        unlinkToken: (tokenId) => dispatch(unlinkToken(tokenId)),
        refresh: () => dispatch(fetchTokens())
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(DeleteDialog);
