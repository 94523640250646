import React                      from 'react';
import {connect}                  from 'react-redux';
import {useTranslation}           from 'react-i18next';
import {List}                     from '@material-ui/core';
import ListItemNavLink            from '../ListItemNavLink';
import {getMusicDiscographyRoute} from '../../../../router/routes/music/factory/discography';
import LibraryMusicIcon           from '@material-ui/icons/LibraryMusic';
import {getMusicAlbumsRoute}      from '../../../../router/routes/music/factory/album';
import AlbumIcon                  from '@material-ui/icons/Album';
import {getMusicRecordsRoute}     from '../../../../router/routes/music/factory/records';
import MicIcon                    from '@material-ui/icons/Mic';

const MenuMusic = ({selectedRealm, ready, artist}) => {
	const {t} = useTranslation();

	const disabled = !selectedRealm || !ready || !artist;

	return (
		<List>
			<div>
				<ListItemNavLink
					tooltip={t('menu_discography')}
					path={getMusicDiscographyRoute()}
					exact={true}
					icon={<LibraryMusicIcon size={24}/>}
					primary={t('menu_discography')}
					disabled={disabled}
				/>
				<ListItemNavLink
					tooltip={t('menu_album')}
					path={getMusicAlbumsRoute()}
					exact={true}
					icon={<AlbumIcon size={24}/>}
					primary={t('menu_album')}
					disabled={disabled}
				/>
				<ListItemNavLink
					tooltip={t('menu_record')}
					path={getMusicRecordsRoute()}
					exact={true}
					icon={<MicIcon size={24}/>}
					primary={t('menu_record')}
					disabled={disabled}
				/>
			</div>
		</List>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		open: state.common.drawerOpened,
		ready: state.common.ready,
		artist: state.artist.artist
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuMusic);
