const { useEffect } = require('react');
const { useRef } = require('react');

export const usePrevious = (value) => {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
		// eslint-disable-next-line
	},);
	return ref.current;
};
