import React, {useEffect, useState} from 'react';
import TableContainer from "@material-ui/core/TableContainer";
import MaterialTable from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TableBody from "@material-ui/core/TableBody";
import Skeleton from "@material-ui/lab/Skeleton";
import Row from "./Row";
import {connect} from 'react-redux'
import {fetchVAT} from "../../../../../../store/actions/OrderAction";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from 'react-i18next';


const Table = ({prices, loading, fetchVat}) => {
    const [showOldPrices, setShowOldPrices] = React.useState(false);
    const [vat, setVat] = useState('');
    const { t } = useTranslation();
    useEffect(() => {
        fetchVat().then(d => setVat(d.Rate))
    }, [fetchVat]);

    return (
        <TableContainer>
            <MaterialTable aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>{t('tablecell_price')}</TableCell>
                        <TableCell>{t('tablecell_currency')}</TableCell>
                        <TableCell>{t('tablecell_period')}</TableCell>
                        <TableCell align='right'>

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={showOldPrices}
                                        onChange={(e) => setShowOldPrices(e.target.checked)}
                                        value={t('show_old_prices')}
                                        color="primary"
                                    />
                                }
                                label={t('show_old_prices')}
                            />

                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading && <TableRow>
                        <TableCell>
                            <Skeleton/>
                        </TableCell>
                        <TableCell>
                            <Skeleton/>
                        </TableCell>
                        <TableCell colSpan={2}>
                            <Skeleton/>
                        </TableCell>
                    </TableRow>}

                    {
                        !loading &&
                        prices.map((p, idx) => <Row vat={vat} price={p} key={p.Catalog_Product_Price__}
                                                    showOldPrices={showOldPrices}/>)
                    }

                </TableBody>

            </MaterialTable>
            <Grid container alignItems='flex-end' direction='column'>
                <Grid item>
                    <TextField
                        variant='standard'

                        value={vat}
                        onChange={e => setVat(e.target.value)}
                        type='number'
                        InputProps={
                            {
                                min: 0,
                                endAdornment:  <InputAdornment position="end">%</InputAdornment>,
                                startAdornment: <InputAdornment position="start">{t('vat')}:</InputAdornment>,
                            }
                        }
                    />
                </Grid>
            </Grid>

        </TableContainer>

    );
};


const mapDispatchToProps = (dispatch) => {
    return {
        fetchVat: () => dispatch(fetchVAT())
    }
};

export default connect(null, mapDispatchToProps)(Table);