import React, { useEffect, useState } from 'react';
import { updateProduct }              from '../../../../store/actions/store/ProductAction';
import { Grid }                       from '@material-ui/core';
import Toolbar                        from '../../typography/Toolbar';
import { Trans, useTranslation }      from 'react-i18next';
import RichAlert                      from '../../feeback/RichAlert';
import { getAttribute }               from '../../../utils/product';
import Button                         from '../../inputs/Button';
import { connect }                    from 'react-redux';
import ProductVisibilitySelector      from '../../inputs/ProductVisibilitySelector';
import ClipboardCopyTextField         from '../../inputs/ClipboardCopyTextField';
import FormRequiredField              from '../../feeback/FormRequiredField';

const Visibility = ({ productId, attributes, isManager, updating, updateProduct, registryUrl }) => {
	const { t } = useTranslation();
	const [isOnSale, setIsOnSale] = useState(false);

	const tmp = getAttribute(attributes, 'Native.Visible') ?? [];
	const [isVisible, setIsVisible] = useState(tmp.length > 0 ? tmp[0].Value : '');

	const [saveEnabled, setSaveEnabled] = useState(false);

	useEffect(() => {
		const tmp = getAttribute(attributes, 'Native.Visible') ?? [];
		setIsVisible(tmp.length > 0 ? tmp[0].Value : '');
	}, [attributes, setIsVisible]);

	useEffect(() => {
		const tmp = getAttribute(attributes, 'Native.Visible') ?? [];
		setSaveEnabled(isVisible.trim() && isVisible.trim() !== (tmp.length > 0 ? tmp[0].Value : ''));
	}, [setSaveEnabled, attributes, isVisible]);


	const saveHandler = () => {
		const data = [];

		// Because updating a property is quite heavy (updating children etc), we need to send only the props that have changed
		const tmp = getAttribute(attributes, 'Native.Visible') ?? [];
		if (isVisible.trim() !== (tmp.length > 0 ? tmp[0].Value : '')) {
			data.push({
				Variable: 'Native.Visible',
				Language__: null,
				Realm__: null,
				Value: isVisible.trim(),
			});
		}

		updateProduct(productId, data);
	};

	useEffect(() => {
		const sale = attributes.sale.filter(s => s.Catalog_Product__ === productId);

		setIsOnSale(sale.length > 0 ? sale[0].Visible === 'Y' : false);
	}, [attributes, productId, setIsOnSale]);

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Toolbar title={t('product_visibility')}/>
			</Grid>
			<FormRequiredField/>
			<Grid item xs={12}>
				<RichAlert severity='info'>
					<Trans i18nKey='product_visibility_info'>
						sample<br/>
						<strong>sample</strong>
					</Trans>
				</RichAlert>
			</Grid>

			<Grid item xs={12} md={6}>
				<ProductVisibilitySelector
					disabled={!isManager || !isOnSale || updating}
					required
					setValue={setIsVisible}
					value={isVisible}
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<ClipboardCopyTextField
					fullWidth
					value={`https://${registryUrl.Domain}/cart?product=${productId}`}
					variant='outlined'
					editable={false}
					disabled={updating || !isOnSale}
					label={t('product_direct_link_label')}
					helperText={t('product_direct_link_helperText')}
				/>
			</Grid>

			{isManager &&
			<Grid item xs={12}>
				<Grid container justify='flex-end'>

					<Button
						onClick={saveHandler}
						loading={updating}
						color='primary'
						variant='contained'
						disabled={!saveEnabled || updating || !isOnSale}>
						{t('save_btn')}
					</Button>

				</Grid>
			</Grid>
			}
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		isManager: state.access.manager,
		updating: false,
		registryUrl: state.registry.url,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateProduct: (id, data) => dispatch(updateProduct(id, data))
	};
};


export default connect(mapStateToProps, mapDispatchToProps)(Visibility);
