import React, { Fragment, useEffect } from 'react';
import TableHead                      from '@material-ui/core/TableHead';
import MaterialTable                  from '@material-ui/core/Table';
import TableRow                       from '@material-ui/core/TableRow';
import TableCell                      from '@material-ui/core/TableCell';
import TableBody                      from '@material-ui/core/TableBody';
import TableContainer                 from '@material-ui/core/TableContainer';
import Row                            from './Row';
import { connect }                    from 'react-redux';
import TableFooter                    from '@material-ui/core/TableFooter';
import { useTranslation }             from 'react-i18next';
import Empty                          from './Empty';
import {
	fetchSchedules,
	setSchedulesFilters,
	setSchedulesPaging
}                                     from '../../../../../store/actions/planner/ScheduleAction';
import Pagination                     from '../../../data/table/Pagination';

const Table = ({ schedules, fetch, loading, filters, paging, count, setSchedulesPaging, setSchedulesFilters }) => {
	const loadingData = [...Array(paging.results_per_page).keys()];
	const { t } = useTranslation();
	const displayLoading = () => loadingData.map((k) => <Row key={k} loading={true} schedule={null}/>);
	const displaySchedules = () => schedules.map((u, idx) => <Row key={u.Planner_Schedule__} loading={false}
	                                                              schedule={u}/>);

	useEffect(() => {
		// Reset filters the first time
		setSchedulesFilters({ sort: { Date: 'DESC' } });
	}, [setSchedulesFilters]);

	useEffect(() => {
		fetch();
	}, [fetch, filters, paging]);

	/*const {sort} = filters;

	const sortHandler = (property, dir) => {
		let p = {...filters};
		// only 1 sort
		p.sort = {};
		p.sort[property] = dir;
		setSchedulesFilters(p);
	};*/

	return (
		<Fragment>
			{(loading || schedules.length > 0) &&
			<TableContainer>
				<MaterialTable aria-label='simple table'>
					<TableHead>
						<TableRow>
							<Pagination
								setPaging={setSchedulesPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
						<TableRow>
							<TableCell>{t('tablecell_visible')}</TableCell>
							<TableCell> {t('tablecell_schedule_date')}</TableCell>
							<TableCell> {t('tablecell_schedule_name')}</TableCell>
							<TableCell> {t('tablecell_schedule_categories')}</TableCell>
							<TableCell align='right'/>
						</TableRow>
					</TableHead>
					<TableBody>
						{loading && displayLoading()}
						{!loading && displaySchedules()}
					</TableBody>
					<TableFooter>
						<TableRow>
							<Pagination
								setPaging={setSchedulesPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
					</TableFooter>

				</MaterialTable>
			</TableContainer>
			}
			{(!loading && schedules.length < 1) && <Empty/>}
		</Fragment>
	);

};


const mapStateToProps = (state) => {
	return {
		filters: state.schedule.schedulesFilters,
		paging: state.schedule.schedulesPaging,
		count: state.schedule.schedulesPagingCount,
		loading: state.schedule.schedulesLoading,
		schedules: state.schedule.schedules
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: () => dispatch(fetchSchedules()),
		setSchedulesPaging: (newPaging) => dispatch(setSchedulesPaging(newPaging)),
		setSchedulesFilters: (newFilters) => dispatch(setSchedulesFilters(newFilters))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);

