import { COMMON_RESET }            from '../CommonReducer';
import { USER_LOGOUT }             from '../system/UserReducer';
import { STREAM_START_DONE }       from './StreamReducer';
import {TAG_DELETE_DONE}           from '../TagReducer';
import {SCHEDULE_MANAGE_TAGS_DONE} from './ScheduleReducer';

const initState = () => {
	return {
		event: null,
		eventLoading: false,

		eventsLoading: false,
		events: [],
		eventsFilters: {},
		eventsPagingCount: 0,
		eventsPaging: {
			page_no: 1,
			results_per_page: 10
		},

		creating: false,
		updating: false,
		deleting: false,

		nextEvent: null,
		nextEventLoading: false,

		nextEventList: [],
		nextEventLoadingList: false,
	};
};

export const EVENT_LIST_FETCH = 'EVENT_LIST_FETCH';
export const EVENT_LIST_FETCH_DONE = 'EVENT_LIST_FETCH_DONE';
export const EVENT_FETCH = 'EVENT_FETCH';
export const EVENT_FETCH_DONE = 'EVENT_FETCH_DONE';
export const EVENT_SET_PAGING = 'EVENT_SET_PAGING';
export const EVENT_SET_FILTERS = 'EVENT_SET_FILTERS';

export const EVENT_CREATE = 'EVENT_CREATE';
export const EVENT_CREATE_DONE = 'EVENT_CREATE_DONE';

export const EVENT_UPDATE = 'EVENT_UPDATE';
export const EVENT_UPDATE_DONE = 'EVENT_UPDATE_DONE';

export const EVENT_DELETE = 'EVENT_DELETE';
export const EVENT_DELETE_DONE = 'EVENT_DELETE_DONE';

export const EVENT_FETCH_NEXT = 'EVENT_FETCH_NEXT';
export const EVENT_FETCH_NEXT_DONE = 'EVENT_FETCH_NEXT_DONE';

export const EVENT_FETCH_NEXT_LIST = 'EVENT_FETCH_NEXT_LIST';
export const EVENT_FETCH_NEXT_LIST_DONE = 'EVENT_FETCH_NEXT_LIST_DONE';

const eventReducer = (state = initState(), action) => {
	switch (action.type) {
		case COMMON_RESET:
		case USER_LOGOUT:
			return initState();
		case EVENT_FETCH_NEXT:
			return { ...state, nextEventLoading: !action.silent };
		case EVENT_FETCH_NEXT_DONE:
			return { ...state, nextEventLoading: false, nextEvent: action.event };
		case EVENT_FETCH_NEXT_LIST:
			return { ...state, nextEventListLoading: !action.silent };
		case EVENT_FETCH_NEXT_LIST_DONE:
			return { ...state, nextEventListLoading: false, nextEventList: action.events };
		case EVENT_CREATE:
			return { ...state, creating: true };
		case EVENT_CREATE_DONE:
			return { ...state, creating: false };
		case EVENT_FETCH:
			return { ...state, eventLoading: true };
		case EVENT_FETCH_DONE:
			return { ...state, eventLoading: false, event: action.event };
		case EVENT_LIST_FETCH:
			return { ...state, eventsLoading: true };
		case EVENT_LIST_FETCH_DONE:
			return {
				...state,
				eventsLoading: false,
				events: action.events,
				eventsPagingCount: action.paging.count
			};
		case EVENT_SET_FILTERS :
			return { ...state, eventsFilters: action.filters };
		case EVENT_SET_PAGING :
			return { ...state, eventsPaging: action.paging };
		case EVENT_DELETE:
			return { ...state, deleting: true };
		case EVENT_DELETE_DONE:
			return { ...state, deleting: false };
		case EVENT_UPDATE:
			return { ...state, updating: true };
		case EVENT_UPDATE_DONE:
			if (!action.event) return { ...state, updating: false };
			return { ...state, updating: false, event: action.event };
		case STREAM_START_DONE:
			if (!action.event) return { ...state };
			return { ...state, event: action.event };
		case SCHEDULE_MANAGE_TAGS_DONE:
			if(!state.event || state.event.Planner_Event__ !== action.schedule.Planner_Event__) return {...state};
			const tmpEvent = {...state.event}
			tmpEvent.Schedule = action.schedule;
			return {...state, event: tmpEvent};
		case TAG_DELETE_DONE:
			if(!state.event) return {...state};
			const tmp = {...state.event}
			tmp.Schedule.Tags =  tmp.Schedule.Tags.filter(t => t.Classify_Tag__ !== action.tagId)
			return {...state, event: tmp};
		default:
			return state;
	}

};

export default eventReducer;
