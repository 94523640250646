import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {connect} from 'react-redux'
import {fetchConsumerLink, fetchConsumers} from "../../../../store/actions/system/OAuth2Action";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../../inputs/Button";
import Grid from "@material-ui/core/Grid";
import Loading from "../../feeback/loading/Loading";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import {tokenTypeToIcon} from "../../../utils/oauth";
import CircularProgress from "@material-ui/core/CircularProgress";

const AddDialog = ({open, setOpen, consumers, loading, fetchConsumerLink, fetchConsumers}) => {
    const {t} = useTranslation();
    const [selectedConsumer, setSelectedConsumer] = useState(null);

    useEffect(() => {
        fetchConsumers()
    }, [fetchConsumers]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleConsumerClick = (consumer) => {
        setSelectedConsumer(consumer);
        fetchConsumerLink(consumer.OAuth2_Consumer__, window.location.href)
            .then((d) => {
                window.location = d.redirect_url;
            })
    };

    return (
        <Dialog
            open={open}
            aria-labelledby={t('link_service')}
            aria-describedby={t('link_service')}
        >
            <DialogTitle id="link-service-title">{t('link_service')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="link-service-description">
                    {t('link_service_description')}
                </DialogContentText>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {loading && <Loading/>}
                        {!loading &&

                        <List>
                            {
                                consumers.map(consumer => {
                                        const selected = selectedConsumer && selectedConsumer.OAuth2_Consumer__ === consumer.OAuth2_Consumer__;
                                        return (
                                            <ListItem button disabled={!!selectedConsumer}
                                                      onClick={() => handleConsumerClick(consumer)}
                                                      key={consumer.OAuth2_Consumer__}
                                                      selected={selected}>
                                                <ListItemAvatar>
                                                    {tokenTypeToIcon(consumer.Token_Name)}
                                                </ListItemAvatar>
                                                <ListItemText primary={consumer.Name}/>
                                                {selected && <CircularProgress thickness={1}/>}
                                            </ListItem>
                                        )
                                    }
                                )
                            }
                        </List>
                        }
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={handleClose} disabled={!!selectedConsumer || loading}>
                    {t('close_btn')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {
        consumers: state.oauth2.consumers,
        loading: state.oauth2.loadingConsumers,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchConsumers: () => dispatch(fetchConsumers()),
        fetchConsumerLink: (consumerId, returnTo) => dispatch(fetchConsumerLink(consumerId, returnTo))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDialog);
