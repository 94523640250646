import React                       from 'react';
import Grid                        from '@material-ui/core/Grid';
import AppBarTitle                 from '../../../components/common/typography/AppBarTitle';
import {useTranslation}            from 'react-i18next';
import {connect}                   from 'react-redux';
import SelectRealm                 from '../../SelectRealm';
import AddIcon                     from '@material-ui/icons/Add';
import {Link}                      from 'react-router-dom';
import Table                       from '../../../components/common/music/record/table/Table';
import HasRight                    from '../../../components/Route/HasRight';
import Toolbar                     from '../../../components/common/typography/Toolbar';
import {getMusicRecordCreateRoute} from '../../../router/routes/music/factory/records';
import Button                      from '../../../components/common/inputs/Button';
import Filters                     from '../../../components/common/music/record/table/Filters';

const List = ({artist, selectedRealm}) => {
	const {t} = useTranslation();

	if (!selectedRealm || !artist) return <SelectRealm/>;

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('records_title')}/>
			<Grid item xs={12}>
				<Toolbar title={t('records_title')}>
					<HasRight manager>
						<Button endIcon={<AddIcon/>} component={Link} to={getMusicRecordCreateRoute()}
						        variant="contained"
						        color="primary">
							{t('create_btn')}
						</Button>
					</HasRight>
				</Toolbar>
			</Grid>
			<Grid item xs={12}>
				<Filters/>
			</Grid>
			<Grid item xs={12}>
				<Table/>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		artist: state.artist.artist,
	};
};
export default connect(mapStateToProps)(List);