import {USER_LOGOUT} from "../system/UserReducer";
import {COMMON_RESET} from "../CommonReducer";

const initState = () => {
    return {
        couponsLoading: false,
        coupons: [],
        couponsFilters: {Status: 'valid'},
        couponsPagingCount: 0,
        couponsPaging: {
            page_no: 1,
            results_per_page: 10
        },

        creating: false,
        deleting: false,
    }
};

export const COUPON_LIST_FETCH = 'COUPON_LIST_FETCH';
export const COUPON_LIST_FETCH_DONE = 'COUPON_LIST_FETCH_DONE';

export const COUPON_SET_PAGING = 'COUPON_SET_PAGING';
export const COUPON_SET_FILTERS = 'COUPON_SET_FILTERS';

export const COUPON_CREATE = 'COUPON_CREATE';
export const COUPON_CREATE_DONE = 'COUPON_CREATE_DONE';

export const COUPON_DELETE = 'COUPON_DELETE';
export const COUPON_DELETE_DONE = 'COUPON_DELETE_DONE';

const couponReducer = (state = initState(), action) => {
    switch (action.type) {
        case USER_LOGOUT:
        case COMMON_RESET:
            return initState();
        case COUPON_CREATE:
            return {...state, creating: true}
        case COUPON_CREATE_DONE:
            return {...state, creating: false}
        case COUPON_DELETE:
            return {...state, deleting: true}
        case COUPON_DELETE_DONE:
            return {...state, deleting: false}
        case COUPON_LIST_FETCH:
            return {...state, couponsLoading: true};
        case COUPON_LIST_FETCH_DONE:
            return {
                ...state,
                couponsLoading: false,
                coupons: action.coupons,
                couponsPagingCount: action.paging.count
            };

        case COUPON_SET_FILTERS :
            return {...state, couponsFilters: action.filters};
        case COUPON_SET_PAGING :
            return {...state, couponsPaging: action.paging};
        default:
            return state;
    }

};

export default couponReducer