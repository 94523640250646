import React              from 'react';
import { Grid }           from '@material-ui/core';
import Toolbar            from '../../typography/Toolbar';
import Empty              from './stream/Empty';
import { useTranslation } from 'react-i18next';
import StreamInfo         from './stream/StreamInfo';
import Chat               from './stream/chat/Chat';
import HasRight           from '../../../Route/HasRight';

const Stream = ({ event }) => {
	const { t } = useTranslation();

	const isOnlineEvent = !!event.Seats.find(({ Type }) => Type === 'stream');

	if (!isOnlineEvent) {
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Toolbar title={t('stream_title')}/>
				</Grid>

				<Grid item xs={12}>
					<Empty/>
				</Grid>
			</Grid>
		);
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Toolbar title={t('stream_title')}/>
			</Grid>

			<HasRight manager hideLoading>
				<Grid item xs={12} md={6}>
					<StreamInfo event={event}/>
				</Grid>
			</HasRight>
			<Grid item xs={12} md={6}>
				<Chat event={event}/>
			</Grid>

		</Grid>
	);
};

export default Stream;
