import React, { useState } from 'react';
import { connect }         from 'react-redux';
import { useTranslation }  from 'react-i18next';
import Grid                from '@material-ui/core/Grid';
import Loading             from '../feeback/loading/Loading';
import Stepper             from '@material-ui/core/Stepper';
import StepLabel           from '@material-ui/core/StepLabel';
import Step                from '@material-ui/core/Step';
import Introduction        from './Introduction';
import StepStripe          from './StepStripe';
import StepConfirm         from './StepConfirm';
import StepAddress         from './StepAddress';


const StripeRequired = ({ realm, hasStripe, children, ready, graphic, titleKey, descriptionKey, hasLocation }) => {
	const [stripeAccount, setStripeAccount] = useState(null);
	const [activeStep, setActiveStep] = useState(0);
	const [address, setAddress] = useState({});
	const { t } = useTranslation();

	const handleNext = () => {
		setActiveStep(prevActiveStep => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep(prevActiveStep => prevActiveStep - 1);
	};


	if (!ready) return <Loading/>;
	if (hasStripe) return children;

	const getContent = () => {
		if (hasLocation) {
			switch (activeStep) {
				case 0:
					return <Introduction
						handleNext={handleNext}
						graphic={graphic}
						titleKey={titleKey}
						descriptionKey={descriptionKey}
					/>;
				case 1:
					return <StepStripe
						handleNext={handleNext}
						realm={realm}
						setStripe={setStripeAccount}
						stripe={stripeAccount}
						handlePrev={handleBack}
					/>;
				case 2:
					return <StepConfirm
						handleNext={handleNext}
						realm={realm}
						address={address}
						stripe={stripeAccount}
						handlePrev={handleBack}
						hasLocation={hasLocation}
					/>;
				default:
					return <></>;
			}
		}

		switch (activeStep) {
			case 0:
				return <Introduction
					handleNext={handleNext}
					graphic={graphic}
					titleKey={titleKey}
					descriptionKey={descriptionKey}
				/>;
			case 1:
				return <StepStripe
					handleNext={handleNext}
					realm={realm}
					setStripe={setStripeAccount}
					stripe={stripeAccount}
					handleBack={handleBack}
				/>;
			case 2:
				return <StepAddress
					handleNext={handleNext}
					address={address}
					setAddress={setAddress}
					handleBack={handleBack}
				/>;
			case 3:
				return <StepConfirm
					handleNext={handleNext}
					realm={realm}
					address={address}
					stripe={stripeAccount}
					handleBack={handleBack}
					hasLocation={hasLocation}
				/>;
			default:
				return <></>;
		}
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Stepper activeStep={activeStep} alternativeLabel>
					<Step><StepLabel>{t('stripe_required_intro')}</StepLabel></Step>
					<Step><StepLabel>{t('stripe_required_title')}</StepLabel></Step>
					{!hasLocation && <Step><StepLabel>{t('stripe_required_address')}</StepLabel></Step>}
					<Step><StepLabel>{t('stripe_required_confirm')}</StepLabel></Step>
				</Stepper>
			</Grid>
			<Grid item xs={12}>
				{getContent()}
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		realm: state.realm.selected,
		ready: state.common.ready,
		setting: state.realm.paymentControllerSet,
		hasStripe: state.realm.paymentController,
		hasLocation: state.template.hasLocation
	};
};


export default connect(mapStateToProps)(StripeRequired);
