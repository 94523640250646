import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect }                                         from 'react-redux';
import { Grid }                                            from '@material-ui/core';
import { Trans, useTranslation }                           from 'react-i18next';

// action
import { chatFetch, disablePooling, enablePooling } from '../../../../../../store/actions/planner/ChatAction';

// component
import Toolbar                        from '../../../../typography/Toolbar';
import Table                          from './table/Table';
import ChatAnnounce                   from './ChatAnnounce';
import HasRight                       from '../../../../../Route/HasRight';
import ChatSwitch                     from '../../../../inputs/ChatSwitch/ChatSwitch';
import RichAlert                      from '../../../../feeback/RichAlert';
import Link                           from '../../../../typography/Link';
import {getSiteEventChatOverlayRoute} from '../../../../../../router/routes/site/factory/planner';

const Chat = ({ event, chatFetch, chatLog, chatLoading, enablePooling, disablePooling }) => {
	const { t } = useTranslation();
	const chatRef = useRef(null);
	const [fetchError, setFetchError] = useState(false);

	const scroll = useCallback((res) => {
		if (res.data.length !== 0 && chatRef.current !== null) {
			const scrollTimer = setTimeout(() => {
				chatRef.current.scrollTop = chatRef.current.scrollHeight;
			}, 1);
			return () => clearTimeout(scrollTimer);
		}
	}, [chatRef]);

	const onChatError = () => {
		setFetchError(true);
		setTimeout(() => {
			setFetchError(false);

			chatFetch(event).then(scroll).catch(onChatError);

		}, 10000);
	};

	useEffect(() => {
		scroll({ data: chatLog });
		// eslint-disable-next-line
	}, [(chatLog ?? []).length]);

	useEffect(() => {
		enablePooling();
		chatFetch(event).then(scroll).catch(onChatError);
		return () => {
			disablePooling();
		};
		// eslint-disable-next-line
	}, []);


	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Toolbar title={t('chat_title')}/>
			</Grid>

			<Grid item xs={12}>
				{fetchError && <RichAlert severity='warning'>
					<Trans i18nKey='stream_chat_error'>
						sample<br/>
						<strong>sample</strong>
					</Trans>
				</RichAlert>}
			</Grid>

			<Grid item xs={12}>
				<div
					ref={chatRef}
					style={{
						height: '500px',
						overflowY: 'auto',
					}}
				>
					<Table chatLog={chatLog} chatLoading={chatLoading}/>
				</div>
			</Grid>
			<Grid item xs={12}>
				<HasRight manager hideLoading>
					<div>
						{<ChatSwitch/>}
					</div>
				</HasRight>
			</Grid>
			<HasRight manager hideLoading>
				<Grid item xs={12}>
					<ChatAnnounce event={event}/>
				</Grid>
			</HasRight>
			<Grid item xs={6}>
				<Link target='_blank' to={getSiteEventChatOverlayRoute(event.Planner_Event__)}>
					{t('chat_overlay')}
				</Link>
			</Grid>
			<Grid item xs={6}>
				<Link target='_blank' to={getSiteEventChatOverlayRoute(event.Planner_Event__)+'?superchatonly=true'}>
					{t('superchat_overlay')}
				</Link>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		chatLimit: state.chat.chatLimit,
		chatLoading: state.chat.chatLoading,
		chatLog: state.chat.chatLog,
		chatStatus: state.chat.chatStatus,
		chatLogApiEnable: state.chat.chatLogApiEnable
	};
};


const mapDispatchToProps = (dispatch) => {
	return {
		chatFetch: (event) => dispatch(chatFetch(event)),
		enablePooling: () => dispatch(enablePooling()),
		disablePooling: () => dispatch(disablePooling()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
