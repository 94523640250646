import React, {useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import {useTranslation} from 'react-i18next';
import Table from "./Table/Table";
import {Title} from "../../typography/Title";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import Button from "../../inputs/Button";

const useStyles = makeStyles(theme => ({
    preWrap: {
        whiteSpace: 'pre-wrap',
    },
}));

const SelectDialog = ({open, setOpen, onSelected}) => {
    const {t} = useTranslation();
    const classes = useStyles();

    const [saveEnabled, setSaveEnabled] = useState(false);
    const [selected, setSelected] = useState(null);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (!selected) {
            setSaveEnabled(false);
            return;
        }

        setSaveEnabled(true)
    }, [selected, setSaveEnabled]);


    const handleClose = () => {
        setOpen(false);
    };

    const handleSelect = (e) => {
        onSelected(selected);
        handleClose()
    };

    return (
        <Dialog
            open={open}
            aria-labelledby={t('select_canned')}
            aria-describedby={t('select_canned')}
            fullScreen={fullScreen}
            fullWidth
            maxWidth='lg'
        >
            <DialogTitle id="select-canned-title">{t('create_canned_title')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="select-canned-description">
                </DialogContentText>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Table mode='select' setValue={setSelected} value={selected}/>
                    </Grid>
                    {selected &&
                    <Grid item xs={12} md={6}>
                        <Title>{selected.Title}</Title>
                        <Typography variant="body1" color="textPrimary" component="p" className={classes.preWrap}>
                            {selected.Message}
                        </Typography>
                    </Grid>
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={handleClose}>
                    {t('close_btn')}
                </Button>
                <Button color="primary" variant='contained' disabled={!saveEnabled} onClick={handleSelect}>
                    {t('select_btn')}
                </Button>
            </DialogActions>
        </Dialog>

    );
};


export default SelectDialog;