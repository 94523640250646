import {COMMON_RESET}    from "../CommonReducer";
import {USER_LOGOUT}     from "../system/UserReducer";
import {TAG_DELETE_DONE} from '../TagReducer';

const initState = () => {
    return {
        updatingProperties: false,

        productsLoading: false,
        products: [],
        productsFilters: {},
        productsPagingCount: 0,
        productsPaging: {
            page_no: 1,
            results_per_page: 10
        },

        creating: false,
        updating: false,

        loadingProductProperties: false,
        productProperties: {},

        productManagingTags: false,
        selectedTagsLoading: false,
        selectedTags: [],

        galleryLoading: false,
        gallery: [],
        uploadingImage: false,

        deletingImage: false,

        uploadImgCount: 0,
        uploadImgDoneCount: 0,
        uploadImgRunningCount: 0,
        uploadImgRunningPercent: 0,

        setMainImage: false,


        pricesLoading: true,
        prices: [],

        priceCreating: false,

        addingGood: false,
        deleteGood: false,

        changingSaleState: false,
    }
};

export const PRODUCT_CHANGE_SALE_STATE = 'PRODUCT_CHANGE_SALE_STATE';
export const PRODUCT_CHANGE_SALE_STATE_DONE = 'PRODUCT_CHANGE_SALE_STATE_DONE';

export const PRODUCT_MANAGE_TAGS = 'PRODUCT_MANAGE_TAGS';
export const PRODUCT_MANAGE_TAGS_DONE = 'PRODUCT_MANAGE_TAGS_DONE';

export const PRODUCT_TAGS_FETCH = 'PRODUCT_TAGS_FETCH';
export const PRODUCT_TAGS_FETCH_DONE = 'PRODUCT_TAGS_FETCH_DONE';

export const PRODUCT_UPDATING_PROPERTIES = 'PRODUCT_UPDATING_PROPERTIES';
export const PRODUCT_PROPERTIES_UPDATE_DONE = 'PRODUCT_PROPERTIES_UPDATE_DONE';

export const PRODUCT_LIST_FETCH = 'PRODUCT_LIST_FETCH';
export const PRODUCT_LIST_FETCH_DONE = 'PRODUCT_LIST_FETCH_DONE';
export const PRODUCT_SET_PAGING = 'PRODUCT_SET_PAGING';
export const PRODUCT_SET_FILTERS = 'PRODUCT_SET_FILTERS';

export const PRODUCT_CREATE = 'PRODUCT_CREATE';
export const PRODUCT_CREATE_DONE = 'PRODUCT_CREATE_DONE';

export const PRODUCT_FETCH_PROPERTIES = 'PRODUCT_FETCH_PROPERTIES';
export const PRODUCT_FETCH_PROPERTIES_DONE = 'PRODUCT_FETCH_PROPERTIES_DONE';

export const PRODUCT_UPDATE = 'PRODUCT_UPDATE';
export const PRODUCT_UPDATE_DONE = 'PRODUCT_UPDATE_DONE';

export const PRODUCT_FETCH_GALLERY = 'PRODUCT_FETCH_GALLERY';
export const PRODUCT_FETCH_GALLERY_DONE = 'PRODUCT_FETCH_GALLERY_DONE';

export const PRODUCT_UPLOAD_IMAGE = 'PRODUCT_UPLOAD_IMAGE';
export const PRODUCT_UPLOAD_IMAGE_DONE = 'PRODUCT_UPLOAD_IMAGE_DONE';
export const PRODUCT_UPLOAD_IMAGE_RUNNING = 'PRODUCT_UPLOAD_IMAGE_RUNNING';

export const PRODUCT_DELETE_IMAGE = 'PRODUCT_DELETE_IMAGE';
export const PRODUCT_DELETE_IMAGE_DONE = 'PRODUCT_DELETE_IMAGE_DONE';

export const PRODUCT_LIST_MAIN_IMAGE_VARIATION = 'strip&scale_crop=75x75&background_color=white&format=jpeg';
export const PRODUCT_GALLERY_IMAGE_VARIATION = "strip&scale_crop=240x180&background_color=white&format=jpeg";

export const PRODUCT_SET_MAIN_IMAGE = 'PRODUCT_SET_MAIN_IMAGE';
export const PRODUCT_SET_MAIN_IMAGE_DONE = 'PRODUCT_SET_MAIN_IMAGE_DONE';

export const PRODUCT_PRICE_CREATE = 'PRODUCT_PRICE_CREATE';
export const PRODUCT_PRICE_CREATE_DONE = 'PRODUCT_PRICE_CREATE_DONE';

export const PRODUCT_PRICES_FETCH = 'PRODUCT_PRICES_FETCH';
export const PRODUCT_PRICES_FETCH_DONE = 'PRODUCT_PRICES_FETCH_DONE';


export const PRODUCT_ADD_GOOD = 'PRODUCT_ADD_GOOD';
export const PRODUCT_ADD_GOOD_DONE = 'PRODUCT_ADD_GOOD_DONE';

export const PRODUCT_DELETE_GOOD = 'PRODUCT_DELETE_GOOD';
export const PRODUCT_DELETE_GOOD_DONE = 'PRODUCT_DELETE_GOOD_DONE';

const productReducer = (state = initState(), action) => {
    switch (action.type) {
        case COMMON_RESET:
        case USER_LOGOUT:
            return initState();
        case PRODUCT_CHANGE_SALE_STATE:
            return {...state, changingSaleState: true};
        case PRODUCT_CHANGE_SALE_STATE_DONE:
            return {...state, changingSaleState: false, productProperties: action.attributes};
        case PRODUCT_ADD_GOOD :
            return {...state, addingGood: true};
        case PRODUCT_ADD_GOOD_DONE :
            return {...state, addingGood: false, productProperties: action.attributes};
        case PRODUCT_DELETE_GOOD :
            return {...state, deleteGood: true};
        case PRODUCT_DELETE_GOOD_DONE :
            return {...state, deleteGood: false, productProperties: action.attributes};
        case PRODUCT_PRICE_CREATE :
            return {...state, priceCreating: true};
        case PRODUCT_PRICE_CREATE_DONE :
            return {...state, priceCreating: false};
        case PRODUCT_PRICES_FETCH:
            return {...state, pricesLoading: true, prices: []};
        case PRODUCT_PRICES_FETCH_DONE:
            return {...state, pricesLoading: false, prices: action.prices};
        case PRODUCT_SET_MAIN_IMAGE:
            return {...state, setMainImage: true};
        case PRODUCT_SET_MAIN_IMAGE_DONE:
            const ng = [...state.gallery];
            ng.forEach(g => g.Role = action.imgId === g.Catalog_Product_Image__ ? 'main' : 'list');
            return {...state, setMainImage: false, gallery: ng};
        case PRODUCT_DELETE_IMAGE:
            return {...state, deletingImage: true};
        case PRODUCT_DELETE_IMAGE_DONE:
            if (!action.imgId) return {...state, deletingImage: false};
            const nW = state.gallery.filter(g => g.Catalog_Product_Image__ !== action.imgId);
            return {...state, deletingImage: false, gallery: nW};
        case PRODUCT_FETCH_GALLERY:
            return {...state, galleryLoading: true};
        case PRODUCT_FETCH_GALLERY_DONE:
            return {...state, galleryLoading: false, gallery: action.gallery};
        case PRODUCT_UPLOAD_IMAGE:
            return {...state, uploadingImage: true, uploadImgCount: state.uploadImgCount + action.count};
        case PRODUCT_UPLOAD_IMAGE_RUNNING:
            return {...state, uploadImgRunningCount: action.count, uploadImgRunningPercent: action.ratio};
        case PRODUCT_UPLOAD_IMAGE_DONE:
            const newG = [...state.gallery];
            newG.push(action.img);

            return {...state, uploadingImage: false, uploadImgDoneCount: state.uploadImgDoneCount + 1, gallery: newG};
        case PRODUCT_TAGS_FETCH:
            return {...state, selectedTagsLoading: true};
        case PRODUCT_TAGS_FETCH_DONE:
            return {...state, selectedTagsLoading: false, selectedTags: action.tags};
        case PRODUCT_MANAGE_TAGS:
            return {...state, productManagingTags: true};
        case PRODUCT_MANAGE_TAGS_DONE:
            return {...state, productManagingTags: false, productProperties: action.attributes};
        case TAG_DELETE_DONE:
            if(!state.productProperties || !state.productProperties.tags || state.productProperties.tags.length < 1) return {...state};
            const tmp = {...state.productProperties}
            tmp.tags[0].Tags =  tmp.tags[0].Tags.filter(t => t.Classify_Tag__ !== action.tagId)
            return {...state, productProperties: tmp};
        case PRODUCT_UPDATE:
            return {...state, updating: true};
        case PRODUCT_UPDATE_DONE:
            return {...state, updating: false, productProperties: action.properties};
        case PRODUCT_FETCH_PROPERTIES:
            return {...state, loadingProductProperties: true};
        case PRODUCT_FETCH_PROPERTIES_DONE:
            return {...state, loadingProductProperties: false, productProperties: action.properties};
        case PRODUCT_CREATE:
            return {...state, creating: true};
        case PRODUCT_CREATE_DONE:
            return {...state, creating: false};
        case PRODUCT_UPDATING_PROPERTIES:
            return {...state, updatingProperties: true};
        case PRODUCT_PROPERTIES_UPDATE_DONE:
            return {...state, updatingProperties: false};
        case PRODUCT_LIST_FETCH:
            return {...state, productsLoading: true};
        case PRODUCT_LIST_FETCH_DONE:
            return {
                ...state,
                productsLoading: false,
                products: action.products,
                productsPagingCount: action.paging.count
            };
        case PRODUCT_SET_FILTERS :
            return {...state, productsFilters: action.filters};
        case PRODUCT_SET_PAGING :
            return {...state, productsPaging: action.paging};
        default:
            return state;
    }

};

export default productReducer