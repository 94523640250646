import React, {useEffect} from 'react';
import {connect} from 'react-redux'
import {fetchGroup} from "../../../store/actions/system/AccessAction";
import TableContainer from "@material-ui/core/TableContainer";
import MaterialTable from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import {useTranslation} from "react-i18next";
import Member from "./Member";


const Group = ({loading, group, fetchGroup, access, isOwner, isAdmin}) => {
    const {t} = useTranslation();

    const loadingData = [...Array(5).keys()];

    const displayLoading = () => loadingData.map((k) => <Member  group={group} key={k} loading={true}/>);
    const displayMembers = () => group.Members.map((u) => <Member group={group} key={u.User.User__} member={u} loading={false}/>);

    useEffect(() => {
        if (!access) return;
        fetchGroup()
    }, [fetchGroup, access]);

    return (
        <TableContainer>
            <MaterialTable aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>{t('tablecell_username')}</TableCell>
                        <TableCell>{t('tablecell_access')}</TableCell>
                        <TableCell>{t('tablecell_email')}</TableCell>
                        <TableCell align="right"/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading && displayLoading()}
                    {!loading && displayMembers()}
                </TableBody>
            </MaterialTable>
        </TableContainer>
    );
};

const mapStateToProps = (state) => {
    return {
        group: state.access.group,
        access: state.access.access,
        loading: state.access.groupLoading,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchGroup: (data) => dispatch(fetchGroup())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Group);