import {getLocale} from "@karpeleslab/klbfw";
import {COMMON_RESET} from "../CommonReducer";
import {USER_LOGOUT} from "../system/UserReducer";

const initState = () => {
    return {
        planLoading: true,
        selectedPlan: null,
        planUpdating: false,

        plansLoading: true,
        plans: [],

        creatingPlan: false,

        hasFreePlan: true, // default to true, fail safe
    }
};

export const MEMBERSHIP_PLAN_FETCH = 'MEMBERSHIP_PLAN_FETCH';
export const MEMBERSHIP_PLAN_FETCH_DONE = 'MEMBERSHIP_PLAN_FETCH_DONE';

export const MEMBERSHIP_PLAN_FREE_FETCH = 'MEMBERSHIP_PLAN_FREE_FETCH';
export const MEMBERSHIP_PLAN_FREE_FETCH_DONE = 'MEMBERSHIP_PLAN_FREE_FETCH_DONE';

export const MEMBERSHIP_PLANS_FETCH = 'MEMBERSHIP_PLANS_FETCH';
export const MEMBERSHIP_PLANS_FETCH_DONE = 'MEMBERSHIP_PLANS_FETCH_DONE';

export const MEMBERSHIP_UPDATING_PLAN = 'MEMBERSHIP_UPDATING_PLAN';
export const MEMBERSHIP_UPDATE_PLAN_DONE = 'MEMBERSHIP_UPDATE_PLAN_DONE';

export const MEMBERSHIP_PLAN_UPDATE_PRODUCT = 'MEMBERSHIP_PLAN_UPDATE_PRODUCT';
export const MEMBERSHIP_PLAN_FORCE_UPDATE_TAG_NAME = 'MEMBERSHIP_PLAN_FORCE_UPDATE_TAG_NAME';

export const MEMBERSHIP_PLAN_CREATE = 'MEMBERSHIP_PLAN_CREATE';
export const MEMBERSHIP_PLAN_CREATE_DONE = 'MEMBERSHIP_PLAN_CREATE_DONE';

const planReducer = (state = initState(), action) => {
    switch (action.type) {
        case USER_LOGOUT:
        case COMMON_RESET:
            return initState();
        case MEMBERSHIP_PLAN_FREE_FETCH:
            return {...state, hasFreePlan: true};
        case MEMBERSHIP_PLAN_FREE_FETCH_DONE:
            return {...state, hasFreePlan: action.hasFreePlan};
        case MEMBERSHIP_PLAN_CREATE :
            return {...state, creatingPlan: true};
        case MEMBERSHIP_PLAN_CREATE_DONE :
            return {...state, creatingPlan: false};
        case MEMBERSHIP_PLAN_FORCE_UPDATE_TAG_NAME :
            if (!state.selectedPlan) return {...state};
            let p = {...state.selectedPlan};
            p.Classify_Tag.Name = action.name;
            p.Classify_Tag.Full_Name = action.name;
            p.Classify_Tag.Full_Name_Compact = action.name;
            if (getLocale() in p.Classify_Tag.Names)
                p.Classify_Tag.Names[getLocale()] = action.name;

            return {...state, selectedPlan: p};
        case MEMBERSHIP_PLAN_UPDATE_PRODUCT :
            if (!state.selectedPlan) return {...state};
            let plan = {...state.selectedPlan};
            plan.Catalog_Product = action.product;
            plan.Catalog_Product__ = action.product.Catalog_Product__;

            return {...state, selectedPlan: plan};
        case MEMBERSHIP_PLANS_FETCH:
            return {...state, plansLoading: true};
        case MEMBERSHIP_PLAN_FETCH:
            return {...state, planLoading: true, selectedPlan: null};
        case MEMBERSHIP_PLAN_FETCH_DONE:
            return {...state, planLoading: false, selectedPlan: action.plan};
        case MEMBERSHIP_PLANS_FETCH_DONE:
            return {
                ...state,
                plansLoading: false,
                plans: action.plans,
            };
        case MEMBERSHIP_UPDATING_PLAN:
            return {...state, planUpdating: true};
        case MEMBERSHIP_UPDATE_PLAN_DONE:
            return {...state, selectedPlan: action.plan, planUpdating: false};
        default:
            return state;
    }

};

export default planReducer