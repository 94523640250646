import React, {Fragment}                        from 'react';
import AccountCircle                            from '@material-ui/icons/AccountCircle';
import MenuItem                                 from '@material-ui/core/MenuItem';
import ReactMenu                                from '@material-ui/core/Menu';
import ListItemIcon                             from '@material-ui/core/ListItemIcon';
import LogoutIcon                               from '@material-ui/icons/ExitToApp';
import ListItemText                             from '@material-ui/core/ListItemText';
import {logout}                                 from '../../../store/actions/system/UserActions';
import {connect}                                from 'react-redux';
import {useTranslation}                         from 'react-i18next';
import MenuNavLink                              from './MenuNavLink';
import Avatar                                   from '@material-ui/core/Avatar';
import ContactSupportIcon                       from '@material-ui/icons/ContactSupport';
import GroupWorkIcon                            from '@material-ui/icons/GroupWork';
import PaymentIcon                              from '@material-ui/icons/Payment';
import {getPXLProfileRoute}                     from '../../../router/routes/pxl/factory/profile';
import {getPXLWorkGroupRoute}                   from '../../../router/routes/pxl/factory/workGroup';
import {getPXLContactRoute}                     from '../../../router/routes/pxl/factory/general';
import {getPXLBillingsRoute, getPXLOrdersRoute} from '../../../router/routes/pxl/factory/order';
import AccountBalanceWalletIcon                 from '@material-ui/icons/AccountBalanceWallet';
import makeStyles                               from '@material-ui/core/styles/makeStyles';
import Typography                               from '@material-ui/core/Typography';
import {getArtistWizardRoute}                   from '../../../router/routes/artist/factory/wizard';
import {PersonAdd}                              from '@material-ui/icons';
import {useHistory}                             from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	profile: {
		cursor: 'pointer',
		marginRight: theme.spacing(3)
	},
}));

const ProfileMenu = ({logout, user, selectedRealm}) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const history = useHistory();
	const open = Boolean(anchorEl);
	const {t} = useTranslation();
	const classes = useStyles();

	const handleMenu = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogout = () => {
		setAnchorEl(null);
		logout();
	};

	return (
		<Fragment>
			<Avatar className={classes.profile} onClick={handleMenu}
			        src={user.Profile.Media_Image ? user.Profile.Media_Image.Url : ''}/>

			<ReactMenu
				id="menu-appbar"
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				open={open}
				onClose={handleClose}
			>
				<div style={{textAlign: 'center'}}>
					<Typography variant="subtitle1">
						{user.Profile.Display_Name}
					</Typography>

				</div>

				<div>
					<MenuNavLink
						onClick={handleClose}
						tooltip={t('menu_profile')}
						path={getPXLProfileRoute()}
						exact={true}
						icon={<AccountCircle/>}
						primary={t('menu_profile')}
					/>
				</div>
				<div>
					<MenuNavLink
						onClick={handleClose}
						tooltip={t('menu_my_orders')}
						path={getPXLOrdersRoute()}
						exact={true}
						icon={<PaymentIcon/>}
						primary={t('menu_my_orders')}
					/>
				</div>
				<div>
					<MenuNavLink
						onClick={handleClose}
						tooltip={t('menu_billings')}
						path={getPXLBillingsRoute()}
						exact={true}
						icon={<AccountBalanceWalletIcon/>}
						primary={t('menu_billings')}
					/>
				</div>
				<div>
					<MenuNavLink
						disabled={!selectedRealm}
						onClick={handleClose}
						tooltip={t('menu_work_group')}
						path={getPXLWorkGroupRoute()}
						exact={true}
						icon={<GroupWorkIcon/>}
						primary={t('menu_work_group')}
					/>
				</div>
				<div>
					<MenuItem onClick={() => {
						history.push(getArtistWizardRoute());
						handleClose();
					}}>
						<ListItemIcon>
							<PersonAdd/>
						</ListItemIcon>
						<ListItemText primary={t('menu_artist_wizard')}/>
					</MenuItem>
				</div>
				<div>
					<MenuNavLink
						onClick={handleClose}
						tooltip={t('menu_contact_us')}
						path={getPXLContactRoute()}
						exact={true}
						icon={<ContactSupportIcon/>}
						primary={t('menu_contact_us')}
					/>
				</div>
				<MenuItem onClick={handleLogout}>
					<ListItemIcon>
						<LogoutIcon fontSize="small"/>
					</ListItemIcon>
					<ListItemText primary={t('menu_logout')}/>
				</MenuItem>
			</ReactMenu>
		</Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.user.user,
		selectedRealm: state.realm.selected,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		logout: () => dispatch(logout())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenu);
