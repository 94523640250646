import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const PieChartNoData = ({text, height = 300}) => {
    return (
        <Grid
            style={{height:height+'px', backgroundColor: 'rgba(0, 0, 0, 0.04)'}}
            container
            direction="row"
            justify="center"
            alignItems="center">
            <Grid item>
                <Typography variant="body2" color="textSecondary" component="p">
                    {text}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default PieChartNoData;