import React, {useEffect, useState} from 'react';
import {Grid, Typography} from "@material-ui/core";
import {Title} from "../../typography/Title";
import ReserveTypeSelector from "../../inputs/ReserveTypeSelector";
import {useTranslation} from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import Toolbar from "../../typography/Toolbar";
import HasRight from "../../../Route/HasRight";
import AddIcon from "@material-ui/icons/Add";
import Table from "./seat/table/Table";
import ManageDialog from "./seat/ManageDialog";
import TextField from "@material-ui/core/TextField";
import {connect} from 'react-redux'
import {updateEvent} from "../../../../store/actions/planner/EventAction";
import Button from "../../inputs/Button";

const Tickets = ({event, updating, updateEvent, isManager}) => {
    const {t} = useTranslation();
    const [showSeatDialog, setShowSeatDialog] = useState(false);
    const [selectedSeat, setSelectedSeat] = useState(null);
    const [saveEnabled, setSaveEnabled] = useState(false);

    const [maxSeatPerUser, setMaxSeatPerUser] = useState(event.Max_Seat_Per_User);

    useEffect(() => {
        setMaxSeatPerUser(event.Max_Seat_Per_User);
    }, [event, setMaxSeatPerUser]);

    useEffect(() => {
        setSaveEnabled(maxSeatPerUser !== event.Max_Seat_Per_User);
    }, [event, setSaveEnabled, maxSeatPerUser]);

    const handleSave = () => {
        updateEvent(event.Planner_Event__, {
            Max_Seat_Per_User: (maxSeatPerUser === undefined || maxSeatPerUser === '') ? null : maxSeatPerUser
        })
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Title>{t('event_tickets_title')}</Title>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary" component="p">
                    {t('event_tickets_desc')}
                </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
                <ReserveTypeSelector disabled value={event.Reserve_Type}/>
            </Grid>

            {event.Seats.length < 1 &&
            <Grid item xs={12} md={6}>
                <Alert severity='warning'>{t('event_visible_no_seat')}</Alert>
            </Grid>
            }

            <Grid item xs={12} md={6}>
                <TextField label={t('seat_max_seat_user')} fullWidth variant="outlined" value={maxSeatPerUser}
                           disabled={updating || !isManager}
                           onChange={e => setMaxSeatPerUser(e.target.value)}
                           helperText={t('seat_max_seat_user_helperText')}
                           type='number'
                           InputProps={
                               {
                                   min: 0
                               }
                           }/>
            </Grid>

            {isManager &&
            <Grid item xs={12}>
                <Grid container justify='flex-end'>
                    <Button color='primary' variant='contained'
                            disabled={!saveEnabled || updating} loading={updating} onClick={handleSave}>
                        {t('save_btn')}
                    </Button>
                </Grid>
            </Grid>
            }

            <Grid item xs={12}>
                <Toolbar title={t('event_tickets_list_title')} disableGutters>
                    {((event.Reserve_Type === 'free' && event.Seats.length < 1) || event.Reserve_Type === 'seated') &&
                    <HasRight manager>
                        <Button variant='contained' color='primary' startIcon={<AddIcon/>}
                                onClick={() => {
                                    setSelectedSeat(null);
                                    setShowSeatDialog(true);
                                }}
                        >
                            {t('add_btn')}
                        </Button>
                    </HasRight>
                    }
                </Toolbar>
            </Grid>

            <Grid item xs={12}>
                <Table event={event} onSelected={seat => {
                    setSelectedSeat(seat);
                    setShowSeatDialog(true)
                }}/>
            </Grid>
            <ManageDialog open={showSeatDialog} setOpen={setShowSeatDialog} seat={selectedSeat} event={event}/>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        updating: state.event.updating,
        isManager: state.access.manager,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateEvent: (id,data) => dispatch(updateEvent(id,data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Tickets);
