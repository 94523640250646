import {
    DISCOGRAPHY_RELEASE_CREATE,
    DISCOGRAPHY_RELEASE_CREATE_DONE,
    DISCOGRAPHY_RELEASES_FETCH,
    DISCOGRAPHY_RELEASES_FETCH_DONE,
    DISCOGRAPHY_RELEASES_SET_FILTERS,
    DISCOGRAPHY_RELEASES_SET_PAGING
} from "../../reducers/discography/DiscographyReducer";
import {handleError} from "../../../components/utils/apiErrorHandler";
import {rest} from '@karpeleslab/klbfw'
import {success} from "../system/ToastAction";

export const setReleasesPaging = (newPaging) => {
    return (dispatch, getState) => {
        dispatch({
            type: DISCOGRAPHY_RELEASES_SET_PAGING,
            paging: {...getState().discography.releasesPaging, ...newPaging}
        });
    }
};

export const setReleasesFilters = (newFilters) => {
    return (dispatch, getState) => {
        dispatch({type: DISCOGRAPHY_RELEASES_SET_FILTERS, filters: newFilters});
    }
};

export const fetchReleases = () => {
    return (dispatch, getState) => {

        const params = {
            ...getState().discography.releasesFilters,
            ...getState().discography.releasesPaging,
            Role: 'artist',
            Music_Label_Artist__: getState().artist.artist.Music_Label_Artist__
        };

        const labelId = getState().artist.artist.Music_Label__;

        dispatch({type: DISCOGRAPHY_RELEASES_FETCH});
        return rest('Music/Label/' + labelId + '/Release', 'GET', params)
            .then(data => {
                dispatch({type: DISCOGRAPHY_RELEASES_FETCH_DONE, releases: data.data, paging: data.paging});
                return data.data;
            }).catch((err) => {
                handleError(getState, dispatch, err)
            })
    }
};

export const createRelease = (albumId) => {
    return (dispatch, getState) => {
        dispatch({type: DISCOGRAPHY_RELEASE_CREATE});

        const params = {
            Music_Label_Album__: albumId,
        };

        const labelId = getState().artist.artist.Music_Label__;

        return rest('Music/Label/' + labelId + '/Release', 'POST', params)
            .then(data => {
                success('discography_release_created');
                dispatch({type: DISCOGRAPHY_RELEASE_CREATE_DONE});

                return data.data;
            }).catch((err) => {
                dispatch({type: DISCOGRAPHY_RELEASE_CREATE_DONE});
                handleError(getState, dispatch, err)
            })
    }
};