import React from 'react';
import MaterialTable from "@material-ui/core/Table";
import {useTranslation} from "react-i18next";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Row from './Row'
import {connect} from 'react-redux'
import Loading from "../../../feeback/loading/Loading";
import Empty from "./Empty";

const Table = ({musics, onDelete, changeOrder, artist, disabled}) => {
    const {t} = useTranslation();
    if (!artist) return <Loading/>;
    return (
        <TableContainer>
            <MaterialTable aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell> {t('tablecell_track_name')}</TableCell>
                        <TableCell> {t('tablecell_track_preview')}</TableCell>
                        <TableCell> {t('tablecell_track_duration')}</TableCell>
                        {!disabled && <TableCell align="right"/>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        musics.map((music, idx) => {
                            return (<Row disabled={disabled} changeOrder={changeOrder} count={musics.length} first={idx === 0}
                                         last={idx === (musics.length - 1)} key={music.Music_Label_Album_Track__}
                                         music={music} onDelete={onDelete}/>)
                        })
                    }
                    {musics.length < 1 &&
                    <TableRow>
                        <TableCell colSpan={4}>
                            <Empty/>
                        </TableCell>
                    </TableRow>}
                </TableBody>

            </MaterialTable>
        </TableContainer>
    );
};


const mapStateToProps = (state) => {
    return {
        artist: state.artist.artist
    }
};

export default connect(mapStateToProps)(Table);