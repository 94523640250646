import React from 'react';
import {StyledNavChip} from "./style";
import Link from "../../typography/Link";


const NavChip = (props) => {
    const {to} = props;

    const renderLink = React.useMemo(
        () =>
            React.forwardRef((linkProps, ref) => (
                <Link ref={ref} to={to} {...linkProps} />
            )),
        [to],
    );

    return (
        <StyledNavChip {...props} component={renderLink}/>
    );
};

export default NavChip;