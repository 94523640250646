import React from 'react';
import {Card} from "@material-ui/core";
import {REALM_USER_IMAGE_VARIATION} from "../../../store/reducers/RealmReducer";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Skeleton from "@material-ui/lab/Skeleton";
import moment from "moment";
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles(theme => ({
    wordBreak: {
        wordBreak: "break-all"
    },
    profilePicture: {
        height: '100px',
        width: '100px',
    },
    gridJustify: {
        [theme.breakpoints.down("xs")]: {
            justifyContent: "center"
        }
    },
    profileDetail: {
        marginLeft: theme.spacing(1),
        display: 'inline-block'
    }
}));

const Profile = ({user}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    let birthDate = 'N/A';
    if(user && user.Profile.Birthdate && user.Profile.Birthdate !== '0000-00-00')
        birthDate = moment(user.Profile.Birthdate).format('LLL');

    return (
        <Card elevation={0}>

            <Grid container className={classes.gridJustify}>
                <Grid item lg={1}>
                    <Avatar
                        src={(user && user.Profile.Media_Image) ? user.Profile.Media_Image[REALM_USER_IMAGE_VARIATION] : ''}
                        title={t('profile_picture_title')}
                        className={classes.profilePicture}
                    />
                </Grid>

                <Grid item lg={5}>
                    <CardContent className={classes.wordBreak}>
                        {user && <Typography component="h5" variant="h5">
                            {user.Profile.Display_Name}
                        </Typography>}
                        {user && <Typography variant="subtitle1" color="textSecondary">
                            {user.Email}
                        </Typography>}

                        {!user && <Skeleton width={200}/>}
                        {!user && <Skeleton width={200}/>}
                    </CardContent>
                </Grid>

                <Grid item lg={4} >
                    <CardContent className={classes.wordBreak}>

                        <Grid container justify='flex-start' direction='column' spacing={2}>

                            <Grid item>
                                <Typography variant="subtitle1" color="textPrimary">
                                    {t('profile_birthdate')}:
                                    <Typography variant="subtitle1" color="textSecondary" className={classes.profileDetail} component='span'>
                                        {!user && <Skeleton component='span' width={100}/>}
                                        {user && birthDate}
                                    </Typography>
                                </Typography>

                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1" color="textPrimary">
                                    {t('profile_gender')}:
                                    <Typography variant="subtitle1" color="textSecondary" className={classes.profileDetail} component='span'>
                                        {!user && <Skeleton component='span' width={100}/>}
                                        {user && (user.Profile.Gender ?? 'N/A')}
                                    </Typography>
                                </Typography>

                            </Grid>

                        </Grid>

                        {!user && <Skeleton/>}
                        {!user && <Skeleton/>}
                    </CardContent>
                </Grid>
            </Grid>

        </Card>
    );
};

export default Profile;