import React from 'react';
import Select from "./Select";
import {useTranslation} from "react-i18next";

const CouponTypeSelector = ({value, setValue, disabled, required}) => {
    const {t} = useTranslation();

    return <Select
        label={t('coupon_type')}
        value={value}
        onChange={e => setValue(e.target.value)}
        optionKey='Key'
        optionValue='Value'
        allowEmpty={false}
        options={[
            {Key: 'rate', Value: t('coupon_type_rate')},
            {Key: 'fix', Value: t('coupon_type_fix')},
        ]}
        inputProps={{
            disabled: disabled,
            variant: 'outlined',
            fullWidth: true,
            required: required
        }}
    />;
};

export default CouponTypeSelector;