import React from 'react';
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import DateTime from "../../inputs/DateTime";

const EventDates = ({disabled, date, setDate, startDate, setStartDate, endDate, setEndDate, freeCancelDate, setFreeCancelDate}) => {
    const {t} = useTranslation();
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <DateTime
                    label={t('event_date_lbl')}
                    inputVariant="outlined"
                    value={date}
                    disabled={disabled}
                    onChange={setDate}
                    required
                    helperText={t('event_date_helperText')}
                    fullWidth
                    //disablePast
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <DateTime
                    label={t('event_reserve_start_date_lbl')}
                    inputVariant="outlined"
                    value={startDate}
                    disabled={disabled}
                    onChange={setStartDate}
                    required
                    helperText={t('event_reserve_start_date_helperText')}
                    fullWidth
                    //disablePast
                    //maxDate={endDate ?? (date ?? undefined)}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <DateTime
                    label={t('event_reserve_end_date_lbl')}
                    inputVariant="outlined"
                    value={endDate}
                    disabled={disabled}
                    onChange={setEndDate}
                    required
                    helperText={t('event_reserve_end_date_helperText')}
                    fullWidth
                    //disablePast
                    //minDate={startDate ?? undefined}
                    //maxDate={date ?? undefined}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <DateTime
                    label={t('event_free_cancel_date_lbl')}
                    inputVariant="outlined"
                    value={freeCancelDate}
                    disabled={disabled}
                    onChange={setFreeCancelDate}
                    helperText={t('event_free_cancel_date_helperText')}
                    fullWidth
                    clearable
                    //maxDate={date ?? undefined}
                />
            </Grid>
        </Grid>
    );
};

export default EventDates;
