import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import Table from "./table/Table";
import ManageDialog from "./ManageDialog";
import {useTranslation} from "react-i18next";
import {connect} from 'react-redux'
import {fetchTemplate} from "../../../../store/actions/CmsAction";
import InsertDialog from "./InsertDialog";
import Toolbar from "../../typography/Toolbar";
import Button from "../../inputs/Button";

const Templates = ({cmsId, fetchTemplate, selectedTemplateLoading, selectedTemplate, mode = 'manage', onInsert, isManager, disabled = false}) => {
    const {t} = useTranslation();
    const [showCreate, setShowCreate] = useState(false);
    const [showInsert, setShowInsert] = useState(false);
    const [enableTableDisplay, setEnableTableDisplay] = useState(false);
    const [template, setTemplate] = useState(null);

    useEffect(() => {
        if (!cmsId) return;
        setEnableTableDisplay(true);
    }, [cmsId, setEnableTableDisplay]);

    const onCreateHandler = () => {
        setTemplate(null);
        setShowCreate(true);
    };

    const onSelectHandler = (t) => {
        fetchTemplate(t.Content_Cms_Template__)
            .then(d => {
                setTemplate(d);
                if (mode === 'manage')
                    setShowCreate(true);
                else if (mode === 'insert')
                    setShowInsert(true)
            })

    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Toolbar title={t('cms_templates_title')}>
                    {isManager &&
                    <Button endIcon={<AddIcon/>} variant='contained' disabled={disabled} color='primary' onClick={onCreateHandler}>
                        {t('create_btn')}
                    </Button>
                    }
                </Toolbar>
            </Grid>
            <Grid item xs={12}>
                <Table
	                cmsId={cmsId}
	                mode={mode}
	                disabled={!enableTableDisplay || disabled}
	                value={selectedTemplate}
                    setValue={onSelectHandler}
                />
            </Grid>

            {cmsId && <ManageDialog cmsId={cmsId} open={showCreate} setOpen={setShowCreate}
                                    template={mode === 'manage' ? template : null}/>}
            {(cmsId && template !== null) && <InsertDialog open={showInsert} setOpen={setShowInsert}
                                                           onInsert={onInsert}
                                                           template={template}/>}
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        selectedTemplateLoading: state.cms.selectedTemplateLoading,
        selectedTemplate: state.cms.selectedTemplate,
        isManager: state.access.manager
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchTemplate: (templateId) => dispatch(fetchTemplate(templateId))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Templates);
