import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import Plan from "./Plan";
import {fetchVAT} from "../../../store/actions/OrderAction";
import {connect} from "react-redux";
import uuid from "../../utils/uuid";
import Empty from "../../common/membership/plans/table/Empty";
import CreatePlanDialog from "./CreatePlanDialog";
import Toolbar from "../../common/typography/Toolbar";
import AddIcon from "@material-ui/icons/Add";
import Button from "../../common/inputs/Button";

const StepPlan = ({handleBack, handleNext, plans, setPlans, vat}) => {
    const {t} = useTranslation();
    const [nextEnabled, setNextEnabled] = useState(false);
    const [showCreate, setShowCreate] = useState(false);
    const [allowFree, setAllowFree] = useState(true);
    const [editSelectedIdx, setEditSelectedIdx] = useState(null)

    const addPlan = (plan) => {
    	let newPlans = [];
    	if(editSelectedIdx === null) {
		    newPlans = [...plans, plan];
	    }else{
		    newPlans = [...plans];
		    newPlans[editSelectedIdx] = plan;
	    }
        newPlans.sort((p1, p2) => p2.priority - p1.priority);
        setPlans(newPlans)
	    setEditSelectedIdx(null)
    };

    const createDeleteHandler = idx => e => {
        setPlans(plans.filter((e, i) => i !== idx))
    };

	const createEditHandler = idx => e => {
		setEditSelectedIdx(idx);
		setShowCreate(true);
	};

	useEffect(() => {
		if(!showCreate){ // we closed the create window reset the selection
			setEditSelectedIdx(null)
		}

	}, [showCreate, setEditSelectedIdx])


    useEffect(() => {
        setNextEnabled(plans.length > 0);
        setAllowFree(plans.filter(p => p.free === 'Y').length < 1)
    }, [plans, setNextEnabled, setAllowFree]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Toolbar title={t('fanclub_wizard_plans_title')}>
                    {plans.length > 0 &&
                    <Button
                        onClick={() => setShowCreate(true)}
                        variant='contained'
                        color='primary'
                        endIcon={<AddIcon/>}
                    >
                        {t('create_btn')}
                    </Button>}
                </Toolbar>
            </Grid>

            <Grid item xs={12}>
                {plans.length < 1 && <Empty createHandler={() => setShowCreate(true)}/>}
                <Grid container spacing={3}>
                    {plans.map((plan, idx) => <Grid xs={12} md={4} item key={uuid()}>
                        <Plan
	                        plan={plan}
	                        vat={vat ? vat.Rate : null}
	                        onEdit={createEditHandler(idx)}
	                        onDelete={createDeleteHandler(idx)}
                        />
                    </Grid>)
                    }
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container justify='space-between' spacing={3}>

                    <Grid item xs={1}>
                        <Button onClick={handleBack} variant={'outlined'}
                                color="primary">
                            {t('back_btn')}
                        </Button>
                    </Grid>

                    <Grid item xs={1}>
                        <Button onClick={handleNext} variant='contained' color="primary" disabled={!nextEnabled}>
                            {t('next_btn')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <CreatePlanDialog
	            open={showCreate}
	            addPlan={addPlan}
	            setOpen={setShowCreate}
	            allowFree={allowFree}
	            planEdit={editSelectedIdx === null ? null : plans[editSelectedIdx]}
            />
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        vat: state.order.vat
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchVat: () => dispatch(fetchVAT())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(StepPlan);
