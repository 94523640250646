import React, {useEffect} from 'react';
import Grid               from '@material-ui/core/Grid';
import {useTranslation}   from 'react-i18next';
import {connect}          from 'react-redux';
import SelectRealm        from '../../SelectRealm';
import LoadingAppBarTitle from '../../../components/common/typography/LoadingAppBarTitle';
import Loading            from '../../../components/common/feeback/loading/Loading';
import {useParams}        from 'react-router-dom';
import {fetchMember}      from '../../../store/actions/ArtistAction';
import Form               from '../../../components/common/member/Form';
import Toolbar            from '../../../components/common/typography/Toolbar';

const Edit = ({member, loading, selectedRealm, fetchMember}) => {
	const {t} = useTranslation();
	const {memberId} = useParams();

	useEffect(() => {
		fetchMember(memberId);
	}, [memberId, fetchMember]);

	if (!selectedRealm) return <SelectRealm/>;
	if (!member || loading) return <Loading/>;

	return (
		<Grid container spacing={3}>
			<LoadingAppBarTitle value={member} fallback={t('member_edit_title')}
			                    title={(v) => t('member_edit_title', {name: member.Name})}/>

			<Grid item xs={12}>
				<Toolbar title={t('member_edit_title')}/>
			</Grid>
			<Grid item xs={12}>
				<Form member={member}/>
			</Grid>

		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		member: state.artist.member,
		loading: state.artist.memberLoading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchMember: (id) => dispatch(fetchMember(id))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);