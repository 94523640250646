import { rest, upload } from '@karpeleslab/klbfw';
import { handleError }  from '../../../components/utils/apiErrorHandler';
import { success }      from '../system/ToastAction';
import {
	PRODUCT_ADD_GOOD,
	PRODUCT_ADD_GOOD_DONE,
	PRODUCT_CHANGE_SALE_STATE,
	PRODUCT_CHANGE_SALE_STATE_DONE,
	PRODUCT_CREATE,
	PRODUCT_CREATE_DONE,
	PRODUCT_DELETE_GOOD,
	PRODUCT_DELETE_GOOD_DONE,
	PRODUCT_DELETE_IMAGE,
	PRODUCT_DELETE_IMAGE_DONE,
	PRODUCT_FETCH_GALLERY,
	PRODUCT_FETCH_GALLERY_DONE,
	PRODUCT_FETCH_PROPERTIES,
	PRODUCT_FETCH_PROPERTIES_DONE,
	PRODUCT_GALLERY_IMAGE_VARIATION,
	PRODUCT_LIST_FETCH,
	PRODUCT_LIST_FETCH_DONE,
	PRODUCT_MANAGE_TAGS,
	PRODUCT_MANAGE_TAGS_DONE,
	PRODUCT_PRICE_CREATE,
	PRODUCT_PRICE_CREATE_DONE,
	PRODUCT_PRICES_FETCH,
	PRODUCT_PRICES_FETCH_DONE,
	PRODUCT_PROPERTIES_UPDATE_DONE,
	PRODUCT_SET_FILTERS,
	PRODUCT_SET_MAIN_IMAGE,
	PRODUCT_SET_MAIN_IMAGE_DONE,
	PRODUCT_SET_PAGING,
	PRODUCT_TAGS_FETCH,
	PRODUCT_TAGS_FETCH_DONE,
	PRODUCT_UPDATE,
	PRODUCT_UPDATE_DONE,
	PRODUCT_UPDATING_PROPERTIES,
	PRODUCT_UPLOAD_IMAGE,
	PRODUCT_UPLOAD_IMAGE_DONE,
	PRODUCT_UPLOAD_IMAGE_RUNNING
}                       from '../../reducers/store/ProductReducer';
import { isObject }     from '../../../components/utils/misc';
import moment           from 'moment';

export const updateProperties = (product, properties) => {
	return (dispatch, getState) => {
		dispatch({ type: PRODUCT_UPDATING_PROPERTIES });
		let rootPromise;

		properties.forEach((property, idx) => {
			const tmpP = rest('Catalog/Product/' + product.Catalog_Product__ + ':updateProperty', 'POST',
				{
					'var': property.var,
					value: property.value,
				}
			);

			if (!rootPromise)
				rootPromise = tmpP;
			else
				rootPromise.then(tmpP);
		});


		return rootPromise
			.then((d) => {
				success('product_updated_success');
				dispatch({ type: PRODUCT_PROPERTIES_UPDATE_DONE, product: d.data.Catalog_Product });
				return d.data.Catalog_Product;
			})
			.catch((err) => handleError(getState, dispatch, err));

	};
};

export const setProductsPaging = (newPaging) => {
	return (dispatch, getState) => {
		dispatch({ type: PRODUCT_SET_PAGING, paging: { ...getState().product.productsPaging, ...newPaging } });
	};
};

export const setProductsFilters = (newFilters) => {
	return (dispatch, getState) => {
		dispatch({ type: PRODUCT_SET_FILTERS, filters: newFilters });
	};
};

export const fetchProducts = (catalogId) => {
	return (dispatch, getState) => {
		dispatch({ type: PRODUCT_LIST_FETCH });

		const params = {
			...getState().product.productsFilters,
			...getState().product.productsPaging,
		};

		return rest('Catalog/' + catalogId + '/Product', 'GET', params)
			.then(data => {
				dispatch({ type: PRODUCT_LIST_FETCH_DONE, products: data.data, paging: data.paging });
				return data.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};


export const createProductWizard = (catalogId, internalName, realmId, attributes, onSale, deliverables = [], tags = [], price = null, files = [], parentProductId = null) => {
	return (dispatch, getState) => {
		dispatch({ type: PRODUCT_CREATE });
		let product = null;
		return dispatch(createProduct(catalogId, internalName, realmId, parentProductId, true))
			.then(p => {
				product = p;
				return dispatch(updateProduct(product.Catalog_Product__, attributes, true));
			})
			.then((d) => {
				if(!tags || tags.length < 1)
					return d;
				return dispatch(editProductTags(realmId, product.Catalog_Product__, tags, [], true))
			})
			.then((d) => {
				if (!price) return d;
				return dispatch(createPrice(product.Catalog_Product__, price['price'], price['publicPrice'], price['currency'], moment().unix(), price['discount'], realmId, price['flags'], true));
			})
			.then(d => {
				if (deliverables.length < 1) return d;
				return dispatch(linkGoods(product.Catalog_Product__, deliverables));
			})
			.then(() => {
				return dispatch(productSetSale(product.Catalog_Product__, onSale, true));
			})
			.then(d => {
				if (files.length < 1) return d;
				return dispatch(uploadFiles(product.Catalog_Product__, files, { role: 'list'}));
			})
			.then(() => {
				success('product_create_success');
				dispatch({ type: PRODUCT_CREATE_DONE });
				return product;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});

	};
};

export const createProduct = (catalogId, internalName, realmId, parentProductId = null, fromWizard = false) => {
	return (dispatch, getState) => {
		dispatch({ type: PRODUCT_CREATE });
		const params = {
			Internal_Name: internalName,
			Realm__: null,
			Visible: 'Y'
		};
		if (parentProductId) params.Parent_Catalog_Product__ = parentProductId;

		let dirty = null;
		return rest('Catalog/' + catalogId + '/Product', 'POST', params)
			.then(d => {
				dirty = d;
				return rest('Catalog/Product/' + d.data.Catalog_Product__ + '/Sale', 'POST', {
					Visible: 'N',
					Realm__: realmId
				});
			})
			.then(() => {
				if (!fromWizard) {
					success('product_create_success');
					dispatch({ type: PRODUCT_CREATE_DONE });
				}
				return dirty.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const fetchProduct = (productId) => {
	return (dispatch, getState) => {
		dispatch({ type: PRODUCT_FETCH_PROPERTIES });
		return rest('Catalog/Product/' + productId + ':fetchInformation')
			.then(d => {
				dispatch({ type: PRODUCT_FETCH_PROPERTIES_DONE, properties: d.data });
				return d.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const updateProduct = (productId, data, silent = false) => {
	return (dispatch, getState) => {
		dispatch({ type: PRODUCT_UPDATE });
		return rest('Catalog/Product/' + productId + ':batchUpdate', 'POST', { update: data })
			.then(d => {
				if (!silent) success('product_update_success');
				dispatch({ type: PRODUCT_UPDATE_DONE, properties: d.data });
				return d.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};


export const fetchTags = (classifyId) => {
	return (dispatch, getState) => {
		dispatch({ type: PRODUCT_TAGS_FETCH });
		return rest('Classify/' + classifyId + ':describeAllTags')
			.then((d) => {
				dispatch({ type: PRODUCT_TAGS_FETCH_DONE, tags: d.data });
				return d.data;
			});
	};
};

export const editProductTags = (realmId, productId, toAdd = [], toDel = [], silent = false) => {
	return (dispatch, getState) => {
		dispatch({ type: PRODUCT_MANAGE_TAGS });
		return new Promise((resolve, reject) => {
			let p = [];
			if (toAdd.length > 0)
				p.push(rest('Catalog/Product/' + productId + ':addTag', 'POST', { tag: toAdd, Realm__: realmId }));
			if (toDel.length > 0)
				p.push(rest('Catalog/Product/' + productId + ':delTag', 'POST', { tag: toDel, Realm__: realmId }));

			Promise.all(p)
				.then((promisesResults) => {
					//promisesResults will contain an array of the result of all promise, we will take last one to have the more update entry data
					resolve(promisesResults[promisesResults.length - 1]);
				})
				.catch(reject);
		}).then((d) => {
			if (!silent) success('product_tags_updated_success');
			dispatch({ type: PRODUCT_MANAGE_TAGS_DONE, attributes: d.data });
			return d.data;
		})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};


export const fetchGallery = (productId) => {
	return (dispatch, getState) => {
		dispatch({ type: PRODUCT_FETCH_GALLERY });

		return rest('Catalog/Product/' + productId + '/Image', 'GET', { image_variation: PRODUCT_GALLERY_IMAGE_VARIATION })
			.then((d) => {
				dispatch({ type: PRODUCT_FETCH_GALLERY_DONE, gallery: d.data });
				return d.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};

};

export const uploadFiles = (productId, files = [], params = {}) => {
	return (dispatch, getState) => {
		dispatch({ type: PRODUCT_UPLOAD_IMAGE, count: files.length });
		const promises = [];

		upload.onprogress = d => {
			let runningCount = 0;
			let blockTotal = 0;
			let progressTotal = 0;
			d.running.forEach((running) => {
				if (running.status !== 'pending' && running.status !== 'complete') {
					runningCount++;
					progressTotal += running.done;
					blockTotal += running.blocks;
				}
			});

			const ratio = blockTotal > 0 ? progressTotal / blockTotal : 0;

			dispatch({ type: PRODUCT_UPLOAD_IMAGE_RUNNING, count: runningCount, ratio });
		};

		for (let i = 0; i < files.length; i++) {
			promises.push(upload.append('Catalog/Product/' + productId + ':addImage', files[i], params)
				.then((img) => {
					dispatch({ type: PRODUCT_UPLOAD_IMAGE_DONE, img: img.final });
				})
			);
		}

		upload.run();

		return Promise.all(promises);
	};
};


export const deleteImage = (productId, imgId, extraParams = {}) => {
	return (dispatch, getState) => {
		dispatch({ type: PRODUCT_DELETE_IMAGE });
		return rest('Catalog/Product/' + productId + ':deleteImage', 'POST', { imageId: imgId, ...extraParams })
			.then(d => {
				success('product_picture_delete_success');
				dispatch({ type: PRODUCT_DELETE_IMAGE_DONE, imgId: imgId });
				return d.data;
			}).catch((err) => {
				dispatch({ type: PRODUCT_DELETE_IMAGE_DONE });
				handleError(getState, dispatch, err);
			});
	};
};


export const productSetMainImage = (imgId) => {
	return (dispatch, getState) => {
		dispatch({ type: PRODUCT_SET_MAIN_IMAGE });
		return rest('Catalog/Product/Image/' + imgId + ':setRole', 'POST', { role: 'main' })
			.then(d => {
				success('product_picture_main_change_success');
				dispatch({ type: PRODUCT_SET_MAIN_IMAGE_DONE, imgId: imgId });
				return d.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};


export const loadPrices = (productId) => {
	return (dispatch, getState) => {
		dispatch({ type: PRODUCT_PRICES_FETCH });
		const p = {
			sort: {
				Start_Date: 'ASC'
			}
		};
		return rest('Catalog/Product/' + productId + '/Price', 'GET', p)
			.then((data) => {
				dispatch({ type: PRODUCT_PRICES_FETCH_DONE, prices: data.data });

				return data.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const createPrice = (productId, price, publicPrice, currency, startAtTimeStamp, discount, realm, flags, silent = false) => {
	return (dispatch, getState) => {
		dispatch({ type: PRODUCT_PRICE_CREATE });
		const p = {
			Start_Date: '@' + startAtTimeStamp,
			Price: price,
			Public_Price: publicPrice,
			Currency__: currency,
			Discount: discount ? 'Y' : 'N',
			Realm__: isObject(realm) ? realm.Realm__ : realm,
			Flags: flags,
		};

		return rest('Catalog/Product/' + productId + '/Price', 'POST', p)
			.then((d) => {
				if(!silent)
				success('product_price_created_success');
				dispatch({ type: PRODUCT_PRICE_CREATE_DONE, price: d.data });
				return d.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const linkGoods = (productId, deliverableList) => {
	return (dispatch, getState) => {
		const promises = [];
		deliverableList.forEach(d => {
			promises.push(dispatch(linkGood(productId, d.Catalog_Deliverable__, true)));
		});

		return Promise.all(promises);
	};
};

export const linkGood = (productId, deliverableId, silent = false) => {
	return (dispatch, getState) => {
		dispatch({ type: PRODUCT_ADD_GOOD });
		return rest('Catalog/Product/' + productId + ':deliverableAssign', 'POST', { Catalog_Deliverable__: deliverableId })
			.then(d => {
				if (!silent) success('product_add_good_success');
				dispatch({ type: PRODUCT_ADD_GOOD_DONE, attributes: d.data });
				return d.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});

	};
};

export const unlinkGood = (productId, deliverableId) => {
	return (dispatch, getState) => {
		dispatch({ type: PRODUCT_DELETE_GOOD });
		return rest('Catalog/Product/' + productId + ':deliverableRemove', 'POST', { Catalog_Deliverable__: deliverableId })
			.then(d => {
				success('product_remove_good_success');
				dispatch({ type: PRODUCT_DELETE_GOOD_DONE, attributes: d.data });
				return d.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});

	};
};

export const productSetSale = (productId, isOnSale, silent = false) => {
	return (dispatch, getState) => {
		const realm = getState().realm.selected;
		dispatch({ type: PRODUCT_CHANGE_SALE_STATE });
		return rest('Catalog/Product/' + productId + ':setSale', 'POST', { visible: isOnSale, Realm__: realm.Realm__ })
			.then(d => {
				if (!silent) {
					success(isOnSale ? 'product_on_sale_success' : 'product_remove_sale_success');
				}
				dispatch({ type: PRODUCT_CHANGE_SALE_STATE_DONE, attributes: d.data });
				return d.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};
