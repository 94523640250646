import React, {useCallback, useEffect, useState} from 'react';
import {TextField} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";

const InputI18N = ({
                       labelKey, value, onChange, disabled = false, onDetailedNameChange = (v) => {
    }, spacing = 3
                   }) => {
    const {t} = useTranslation();
    const [needDetailedName, _setNeedDetailedName] = useState('ja-JP' in value);

    const setNeedDetailedName = useCallback((v) => {
        _setNeedDetailedName(v);
        onDetailedNameChange(v)
    }, [_setNeedDetailedName, onDetailedNameChange]);

    useEffect(() => {
        setNeedDetailedName('ja-JP' in value)
    }, [value, setNeedDetailedName]);

    const handleNameChange = (e) => {
        const v = e.target.value;
        if (v.hasKanji()) {
            const newValue = {
                ...value,
                'ja-JP': v
            };
            // we switched from not detailed to detailed,
            if (!needDetailedName) {
                // Clean data
                newValue['en-US'] = '';
                newValue['kana'] = '';
                setNeedDetailedName(true);
            }

            onChange(newValue)
        } else {
            const newValue = {
                'en-US': v
            };
            // we switched from detailed to non detailed
            if (needDetailedName) {
                setNeedDetailedName(false);
            }

            onChange(newValue)
        }
    };

    const createDetailChangeHandler = lang => e => {
        const v = e.target.value;
        onChange({
            ...value,
            [lang]: v
        })
    };

    return (
        <Grid container spacing={spacing}>
            <Grid item xs={12} md={6}>
                <TextField
                    variant='outlined'
                    placeholder={t(labelKey + '_placeholder')}
                    required
                    fullWidth
                    label={t(labelKey + '_label')}
                    value={needDetailedName ? value['ja-JP'] : (value['en-US'] ?? '')}
                    onChange={handleNameChange}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} md={6}>
            </Grid>
            {needDetailedName && <Grid item xs={12} md={6}>
                <TextField
                    variant='outlined'
                    placeholder={t(labelKey + '_english_placeholder')}
                    required
                    fullWidth
                    helperText={t(labelKey + '_english_helperText')}
                    value={value['en-US'] ?? ''}
                    onChange={createDetailChangeHandler('en-US')}
                    label={t(labelKey + '_english_label')}
                    disabled={disabled}
                />
            </Grid>}
            {needDetailedName && <Grid item xs={12} md={6}>
                <TextField
                    variant='outlined'
                    placeholder={t(labelKey + '_kana_placeholder')}
                    required
                    fullWidth
                    helperText={t(labelKey + '_kana_helperText')}
                    value={value['kana'] ?? ''}
                    onChange={createDetailChangeHandler('kana')}
                    label={t(labelKey + '_kana_label')}
                    disabled={disabled}
                />
            </Grid>}
        </Grid>
    );
};

export default InputI18N;