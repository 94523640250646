import React, {useEffect, useState} from 'react';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {Select} from "@material-ui/core";
import {useTranslation} from 'react-i18next';

const Month = ({onChange, defaultValue = '', inputProps = {}, value = undefined}) => {
    const selectLabel = React.useRef(null);
    const [selectLabelWidth, setCurrencyLabelWidth] = useState(0);
    const {t} = useTranslation();

    useEffect(() => {
        setCurrencyLabelWidth(selectLabel.current.offsetWidth);
    }, []);

    let options = [];

    function pad(d) {
        return (d < 10) ? '0' + d.toString() : d.toString();
    }

    for (let i = 1; i <= 12; i++) {
        const v = pad(i);
        options.push(<option key={'month-' + v} value={v}>{v}</option>)
    }

    return (
        <FormControl {...inputProps}>
            <InputLabel ref={selectLabel} htmlFor='month-select'>{t('month_lbl')}</InputLabel>
            <Select
                native
                value={value}
                onChange={onChange}
                labelWidth={selectLabelWidth}
                inputProps={{
                    id: 'month-select',
                }}
            >
                {options}
            </Select>

        </FormControl>
    );
};

export default Month;