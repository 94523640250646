import {
	RESERVE_EXPORT,
	RESERVE_EXPORT_DONE,
	RESERVE_FETCH_ALL,
	RESERVE_FETCH_ALL_DONE, RESERVE_IMPORT_ALLOCATED_SEAT, RESERVE_IMPORT_ALLOCATED_SEAT_DONE,
	RESERVE_LIST_FETCH,
	RESERVE_LIST_FETCH_DONE,
	RESERVE_MARK_AS_USED,
	RESERVE_MARK_AS_USED_DONE,
	RESERVE_SET_FILTERS,
	RESERVE_SET_PAGING,
} from '../../reducers/planner/ReserveReducer';

import {rest, upload}  from '@karpeleslab/klbfw';
import {handleError}   from '../../../components/utils/apiErrorHandler';
import {success}                           from '../system/ToastAction';

export const setReservesPaging = (newPaging) => {
	return (dispatch, getState) => {
		dispatch({type: RESERVE_SET_PAGING, paging: {...getState().reserve.reservesPaging, ...newPaging}});
	};
};

export const setReservesFilters = (newFilters) => {
	return (dispatch, getState) => {
		dispatch({type: RESERVE_SET_FILTERS, filters: newFilters});
	};
};

export const fetchReserves = (eventId) => {
	return (dispatch, getState) => {
		dispatch({type: RESERVE_LIST_FETCH});

		const params = {
			...getState().reserve.reservesFilters,
			...getState().reserve.reservesPaging,
		};

		return rest('Planner/Event/' + eventId + '/Seat/Reserve', 'GET', params)
			.then(data => {
				dispatch({type: RESERVE_LIST_FETCH_DONE, reserves: data.data, paging: data.paging});
				return data.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const fetchAll = (eventId) => {
	return (dispatch, getState) => {
		dispatch({type: RESERVE_FETCH_ALL});
		return rest('Planner/Event/' + eventId + '/Seat/Reserve:status')
			.then(data => {
				const mapped = [];
				const usedMap = [];
				data.data.forEach(r => {
					if (r.Used === 'Y') {
						usedMap.push(r);
						mapped.push(r);
					} else mapped.push(r);
				});
				dispatch({type: RESERVE_FETCH_ALL_DONE, reserves: mapped, used: usedMap});
				return [mapped, usedMap];
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const markAsUsed = (eventId, reserveIds) => {
	return (dispatch, getState) => {
		dispatch({type: RESERVE_MARK_AS_USED});

		return rest('Planner/Event/' + eventId + '/Seat/Reserve:setUsedBulk', 'POST', {ids: reserveIds})
			.then(d => {
				success('reserve_mark_as_used_success');
				dispatch({type: RESERVE_MARK_AS_USED_DONE, reserves: reserveIds});
				return d.data;
			}).catch((err) => {
				dispatch({type: RESERVE_MARK_AS_USED_DONE});
				handleError(getState, dispatch, err);
			});
	};
};


export const exportReserves = (eventId) => {
	return (dispatch, getState) => {
		dispatch({type: RESERVE_EXPORT});

		return rest('Planner/Event/' + eventId + '/Seat/Reserve:export', 'POST')
			.then((data) => {
				dispatch({type: RESERVE_EXPORT_DONE});

				return data.data;
			})
			.catch((err) => {
				dispatch({type: RESERVE_EXPORT_DONE});
				handleError(getState, dispatch, err);
			});
	};
};

export const importAllocatedSeat = (eventId, file) => {
	return (dispatch, getState) => {
		dispatch({type: RESERVE_IMPORT_ALLOCATED_SEAT});

		return new Promise((resolve, reject) => {
			upload.onprogress = (item) => {
				item.failed.forEach(i => {
					upload.deleteItem(i.up_id)
					reject(i.failure);
				})
			};

			upload.append('Planner/Event/' + eventId + '/Seat/Reserve:import', file)
				.then((data) => {
					resolve(data.final);
				}).catch(reject);

			upload.run();

		}).then(d => {
			dispatch({type: RESERVE_IMPORT_ALLOCATED_SEAT_DONE});
			success('allocated_seat_imported');
			return d;
		}).catch((err) => {
			dispatch({type: RESERVE_IMPORT_ALLOCATED_SEAT_DONE});
			handleError(getState, dispatch, err)
		});
	}
};