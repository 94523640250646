import {COMMON_RESET} from "../CommonReducer";
import {USER_LOGOUT} from "../system/UserReducer";

const initState = () => {
    return {
        seats: [],
        seatsLoading: false,
        seatsFilters: {},
        seatsPagingCount: 0,
        seatsPaging: {
            page_no: 1,
            results_per_page: 10
        },

        creating: false,
        updating: false,
        deleting: false,
    }
};

export const SEAT_LIST_FETCH = 'SEAT_LIST_FETCH';
export const SEAT_LIST_FETCH_DONE = 'SEAT_LIST_FETCH_DONE';
export const SEAT_SET_PAGING = 'SEAT_SET_PAGING';
export const SEAT_SET_FILTERS = 'SEAT_SET_FILTERS';

export const SEAT_CREATE = 'SEAT_CREATE';
export const SEAT_CREATE_DONE = 'SEAT_CREATE_DONE';

export const SEAT_UPDATE = 'SEAT_UPDATE';
export const SEAT_UPDATE_DONE = 'SEAT_UPDATE_DONE';

export const SEAT_DELETE = 'SEAT_DELETE';
export const SEAT_DELETE_DONE = 'SEAT_DELETE_DONE';

const seatReducer = (state = initState(), action) => {
    switch (action.type) {
        case COMMON_RESET:
        case USER_LOGOUT:
            return initState();
        case SEAT_CREATE:
            return {...state, creating: true};
        case SEAT_CREATE_DONE:
            return {...state, creating: false};
        case SEAT_UPDATE:
            return {...state, updating: true};
        case SEAT_UPDATE_DONE:
            return {...state, updating: false};
        case SEAT_DELETE:
            return {...state, deleting: true};
        case SEAT_DELETE_DONE:
            return {...state, deleting: false};
        case SEAT_LIST_FETCH:
            return {...state, seatsLoading: true};
        case SEAT_LIST_FETCH_DONE:
            return {
                ...state,
                seatsLoading: false,
                seats: action.seats,
                seatsPagingCount: action.paging.count
            };
        case SEAT_SET_FILTERS :
            return {...state, seatsFilters: action.filters};
        case SEAT_SET_PAGING :
            return {...state, seatsPaging: action.paging};
        default:
            return state;
    }

};

export default seatReducer