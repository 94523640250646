import React                              from 'react';
import TableRow                           from '@material-ui/core/TableRow';
import Skeleton                           from '@material-ui/lab/Skeleton';
import TableCell                          from '@material-ui/core/TableCell';
import {connect}                          from 'react-redux';
import {useTranslation}                   from 'react-i18next';
import Button                             from '../../../inputs/Button';
import Link                               from '../../../typography/Link';
import {Edit}                             from '@material-ui/icons';
import VisibilityIcon                     from '@material-ui/icons/Visibility';
import VisibilityOffIcon                  from '@material-ui/icons/VisibilityOff';
import {getSiteNotificationListEditRoute} from '../../../../../router/routes/site/factory/content';

const Row = ({segment, loading}) => {
	const displaySegments = (!loading && segment);
	const {t} = useTranslation();

	return (
		<TableRow>
			<TableCell>
				{!displaySegments && <Skeleton variant="circle" width={40} height={40}/>}
				{(displaySegments && segment.Visible === 'Y') && <VisibilityIcon/>}
				{(displaySegments && segment.Visible === 'N') && <VisibilityOffIcon/>}
			</TableCell>
			<TableCell>
				{!displaySegments && <Skeleton/>}
				{displaySegments &&
				<span>{segment.Name}</span>}
			</TableCell>
			<TableCell>
				{!displaySegments && <Skeleton/>}
				{displaySegments && <span>{segment.Email}</span>}
			</TableCell>
			<TableCell align="right">
				{!displaySegments && <Skeleton/>}
				{
					displaySegments &&
					<Button variant="contained"
					        color="primary"
					        startIcon={<Edit/>}
					        component={Link}
					        to={getSiteNotificationListEditRoute(segment.Network_MassMail_Segment__)}
					>
						{t('view_btn')}
					</Button>
				}
			</TableCell>
		</TableRow>
	);
};

const mapStateToProps = (state, {loading, idx}) => {
	if (loading) return {};
	return {
		order: state.order.orders[idx],
	};
};

export default connect(mapStateToProps, null)(Row);