const initState = () => {
    return {
        creatingLabel: false,
        creatingCategory : false,
        deleting : false,
    }
};

export const TAG_DELETE = 'TAG_DELETE';
export const TAG_DELETE_DONE = 'TAG_DELETE_DONE';

export const TAG_CREATE_LABEL = 'TAG_CREATE_LABEL';
export const TAG_CREATE_LABEL_DONE = 'TAG_CREATE_LABEL_DONE';

export const TAG_CATEGORY_CREATE = 'TAG_CATEGORY_CREATE';
export const TAG_CATEGORY_CREATE_DONE = 'TAG_CATEGORY_CREATE_DONE';

const tagReducer = (state = initState(), action) => {
    switch (action.type) {
        case TAG_DELETE:
            return {...state, deleting: true};
        case TAG_DELETE_DONE:
            return {...state, deleting: false};
        case TAG_CATEGORY_CREATE:
            return {...state, creatingCategory: true};
        case TAG_CATEGORY_CREATE_DONE:
            return {...state, creatingCategory: false};
        case TAG_CREATE_LABEL:
            return {...state, creatingLabel: true};
        case TAG_CREATE_LABEL_DONE:
            return {...state, creatingLabel: false};
        default:
            return state;
    }

};

export default tagReducer