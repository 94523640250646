import React, {useEffect, useState}                   from 'react';
import {CircularProgress, TextField}                  from '@material-ui/core';
import {useTranslation}                               from 'react-i18next';
import InputAdornment                                 from '@material-ui/core/InputAdornment';
import {useRest}                                      from '@karpeleslab/react-klbfw-hooks';
import {getContentCmsEntryGetTranslationLanguagePair} from '../../../hooks/api/endpoints/content/cms/entryEndpoints';
import {gengoFormatLanguage}                          from '../../utils/gengo';

const GengoTierSelector = ({
	                           value,
	                           setValue,
	                           label = null,
	                           helperText = null,
	                           disabled = false,
	                           required = false,
	                           sourceLang,
	                           targetLang,
	                           ...rest
                           }) => {
	const {t} = useTranslation();
	const [langPair] = useRest(getContentCmsEntryGetTranslationLanguagePair(), {source_language: sourceLang});
	const [tiers, setTiers] = useState(null);

	useEffect(() => {
		if (!langPair) return;
		if(!(gengoFormatLanguage(sourceLang) in langPair.data) || !(gengoFormatLanguage(targetLang) in langPair.data[gengoFormatLanguage(sourceLang)])) {
			return;
		}
		const tiers = Object.keys(langPair.data[gengoFormatLanguage(sourceLang)][gengoFormatLanguage(targetLang)]);
		setTiers(tiers);
		if(!value) {
			setValue(tiers.includes('standard') ? 'standard' : tiers[0]);
		}

		// eslint-disable-next-line
	}, [langPair, sourceLang, targetLang, setTiers, setValue]);

	return (
		<TextField
			label={label ?? t('gengo_tier_title')}
			helperText={helperText ?? t('gengo_tier_helperText')}
			value={value}
			onChange={e => setValue(e.target.value)}
			select
			fullWidth
			InputLabelProps={{shrink: !!value}}
			disabled={disabled || !tiers}
			required={required}
			variant="outlined"
			InputProps={!tiers ?
				{
					endAdornment:
						(<InputAdornment position="end">
							<CircularProgress size={30} thickness={1}/>
						</InputAdornment>)
				} : undefined
			}
			SelectProps={{
				native: true
			}}
			{...rest}
		>
			<option disabled>{t(`gengo_tier_select_option`)}</option>
			{tiers && tiers.map(
				tier => <option key={tier} value={tier}>{t(`gengo_tier_${tier}`)}</option>
			)}
		</TextField>
	);
};

export default GengoTierSelector;
