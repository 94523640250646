import React, {useEffect}     from 'react';
import {connect}              from 'react-redux';
import SelectRealm            from '../../SelectRealm';
import {Grid}                 from '@material-ui/core';
import Redirect               from '../../../components/Route/Redirect';
import {getPXLDashboardRoute} from '../../../router/routes/pxl/factory/general';
import HasRight               from '../../../components/Route/HasRight';
import AppBarTitle            from '../../../components/common/typography/AppBarTitle';
import {useTranslation}       from 'react-i18next';
import Toolbar                from '../../../components/common/typography/Toolbar';
import {fetchMassMailSegment} from '../../../store/actions/MassMailAction';
import {useParams}            from 'react-router-dom';
import Loading                from '../../../components/common/feeback/loading/Loading';
import EditForm               from '../../../components/common/massMail/segment/EditForm';


const NotifyEditList = ({selectedRealm, segment, loading, fetch}) => {
	const {t} = useTranslation();
	const {segmentId} = useParams();

	useEffect(() => {
		fetch(segmentId);
	}, [fetch, segmentId]);

	if (!selectedRealm) return <SelectRealm/>;

	if (!segment || loading) {
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Loading/>
				</Grid>
			</Grid>
		);
	}

	return (
		<Grid container spacing={3}>
			<HasRight manager deniedComponent={<Redirect target={getPXLDashboardRoute()}/>}>
				<AppBarTitle title={t('notify_edit_list_title')}/>
				<Grid item xs={12}>
					<Toolbar title={t('notify_edit_list_title')}/>
				</Grid>

				<Grid item xs={12}>
					<EditForm segment={segment}/>
				</Grid>
			</HasRight>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		segment: state.massMail.massMailSegment,
		loading: state.massMail.massMailSegmentLoading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: (id) => dispatch(fetchMassMailSegment(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(NotifyEditList);
