import React                     from 'react';
import { connect }               from 'react-redux';
import { updateSetting }         from '../../../store/actions/TemplateAction';
import RichAlert                 from '../feeback/RichAlert';
import { Trans, useTranslation } from 'react-i18next';
import Button                      from '../inputs/Button';
import {Grid, TableCell, TableRow} from '@material-ui/core';
import useTheme                    from '@material-ui/core/styles/useTheme';
import useMediaQuery             from '@material-ui/core/useMediaQuery';

const ScheduleActivator = ({ setting, updating, enabledSchedule, isAdmin, short = false, taskCenter = false }) => {
	const { t } = useTranslation();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

	if (!setting.Setting__ || !isAdmin) return <></>; // Some custom website were integrated with PXL but does not have this settings system
	if (setting.Data.schedule_visible === 'Y') return <></>;

	const activate = () => {
		enabledSchedule(setting.Setting__);
	};

	if(taskCenter) {
		return (
			<TableRow>
				<TableCell>
					<Trans i18nKey={(short || isMobile) ? 'activate_schedule_warning_short' : 'activate_schedule_warning'}>
						sample<br/>
						<strong>sample</strong>
					</Trans>
				</TableCell>
				<TableCell align='right'>
					<Button
						onClick={activate}
						color='primary'
						loading={updating}
						disabled={updating}
						variant='contained'
						size='small'
					>
						{t('activate_schedule_btn')}
					</Button>
				</TableCell>
			</TableRow>
		)
	}

	return (
		<Grid item xs={12}>
			<RichAlert
				severity='warning'
				action={
					<Button
						onClick={activate}
						color='primary'
						loading={updating}
						disabled={updating}
						variant='contained'
						size='small'
					>
						{t('activate_schedule_btn')}
					</Button>
				}
			>
				<Trans i18nKey={(short || isMobile) ? 'activate_schedule_warning_short' : 'activate_schedule_warning'}>
					sample<br/>
					<strong>sample</strong>
				</Trans>
			</RichAlert>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		setting: state.template.templateSettings,
		updating: state.template.updatingTemplate,
		isAdmin: state.access.admin
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		enabledSchedule: (settingId) => dispatch(updateSetting(settingId, 'schedule_visible', 'Y', 'activate_schedule_success'))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleActivator);
