import React, { useEffect, useState }                    from 'react';
import { Grid }                                          from '@material-ui/core';
import Toolbar                                           from '../typography/Toolbar';
import AddIcon                                           from '@material-ui/icons/Add';
import Typography                                        from '@material-ui/core/Typography';
import LinearProgress                                    from '@material-ui/core/LinearProgress';
import GridList                                          from '@material-ui/core/GridList';
import Empty                                             from '../cms/gallery/Empty';
import { useTranslation }                                from 'react-i18next';
import { useTheme }                                      from '@material-ui/core/styles';
import useMediaQuery                                     from '@material-ui/core/useMediaQuery';
import GalleryImage                                      from './GalleryImage';
import { connect }                                       from 'react-redux';
import { IMG_VARIATION }                                 from '../cms/gallery/Gallery';
import { fetchArtistGallery, uploadFileToArtistGallery } from '../../../store/actions/ArtistAction';
import DeleteDialog                                      from './DeleteDialog';
import Button from "../inputs/Button";

const Gallery = ({
	                 artist,
	                 isManager,
	                 loading,
	                 images,
	                 uploadImgCount,
	                 uploadImgDoneCount,
	                 uploadImgRunningCount,
	                 uploadImgRunningPercent,

	                 fetchImages,
	                 upload
                 }) => {
	const { t } = useTranslation();
	const inputFileRef = React.useRef(null);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const [loadingData] = useState([...Array(5).keys()]);
	const [selected, setSelected] = useState(null);
	const [showDelete, setShowDelete] = useState(false);

	const handleDelete = img => {
		setSelected(img)
		setShowDelete(true)
	}

	const displayLoading = () => loadingData.map((k) => <GalleryImage key={k} loading/>);
	const displayImages = () => images.map((img) => <GalleryImage artist={artist} key={img.Blob__} img={img} onDelete={handleDelete}/>);

	useEffect(() => {
		fetchImages(artist.Music_Label_Artist__);
	}, [artist.Music_Label_Artist__, fetchImages]);

	const handleFileInputChange = (e) => {
		if (e.target.files.length < 1) {
			return;
		}

		upload(artist.Music_Label_Artist__, e.target.files);
	};

	const displayUpload = uploadImgCount !== uploadImgDoneCount;

	let donePercent = 0;
	let bufferPercent = 0;

	if (displayUpload) {
		donePercent = (100 * uploadImgDoneCount) / uploadImgCount;
		bufferPercent = (100 * (uploadImgDoneCount + uploadImgRunningCount)) / uploadImgCount;
		// calculate the % of the currently uploading
		donePercent += ((bufferPercent - donePercent) * uploadImgRunningPercent);
	}


	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Toolbar title={t('artist_gallery_title')} disableGutters>
					{(isManager) &&
					<input accept='image/png, image/jpeg, image/jpg, image/svg, image/gif' multiple={true} type='file'
					       style={{ display: 'none' }} ref={inputFileRef}
					       onChange={handleFileInputChange}/>}
					{(isManager) &&
					<Button variant='contained' color='primary' disabled={displayUpload} endIcon={<AddIcon/>}
					        onClick={() => {
						        inputFileRef.current.click();
					        }}
					>
						{t('add_btn')}
					</Button>}
				</Toolbar>
			</Grid>
			<Grid item xs={12}>
				<Typography variant='body1' color='textSecondary' component='p'>
					{t('artist_gallery_desc')}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				{displayUpload && <LinearProgress variant='buffer' value={donePercent} valueBuffer={bufferPercent}/>}
			</Grid>
			<Grid item xs={12}>
				<GridList cellHeight={180} cols={fullScreen ? 3 : 5}>
					{loading && displayLoading()}
					{!loading && displayImages()}
				</GridList>
				{!loading && images.length < 1 && <Empty/>}
			</Grid>
			{selected && <DeleteDialog
				img={selected}
				open={showDelete}
				setOpen={setShowDelete}
				artistId={artist.Music_Label_Artist__}
			/>
			}
		</Grid>
	);
};


const mapStateToProps = (state) => {
	return {
		uploadImgCount: state.artist.uploadImgCount,
		uploadImgDoneCount: state.artist.uploadImgDoneCount,
		uploadImgRunningCount: state.artist.uploadImgRunningCount,
		uploadImgRunningPercent: state.artist.uploadImgRunningPercent,
		loading: state.artist.loadingImages,
		images: state.artist.images,
		isManager: state.access.manager
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		upload: (artistId, files) => dispatch(uploadFileToArtistGallery(artistId, files, { image_variation: IMG_VARIATION })),
		fetchImages: (artistId) => dispatch(fetchArtistGallery(artistId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
