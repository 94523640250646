import { rest }        from '@karpeleslab/klbfw';
import { handleError } from '../../../components/utils/apiErrorHandler';
import {
	SEAT_CREATE,
	SEAT_CREATE_DONE, SEAT_DELETE,
	SEAT_DELETE_DONE,
	SEAT_LIST_FETCH,
	SEAT_LIST_FETCH_DONE,
	SEAT_SET_FILTERS,
	SEAT_SET_PAGING,
	SEAT_UPDATE,
	SEAT_UPDATE_DONE
} from '../../reducers/planner/SeatReducer';
import { success }     from '../system/ToastAction';

export const setSeatsPaging = (newPaging) => {
	return (dispatch, getState) => {
		dispatch({ type: SEAT_SET_PAGING, paging: { ...getState().seat.seatsPaging, ...newPaging } });
	};
};

export const setSeatsFilters = (newFilters) => {
	return (dispatch, getState) => {
		dispatch({ type: SEAT_SET_FILTERS, filters: newFilters });
	};
};

export const fetchSeats = (eventId) => {
	return (dispatch, getState) => {
		dispatch({ type: SEAT_LIST_FETCH });

		const params = {
			...getState().seat.seatsFilters,
			...getState().seat.seatsPaging,
		};

		return rest('Planner/Event/' + eventId + '/Seat', 'GET', params)
			.then(data => {
				dispatch({ type: SEAT_LIST_FETCH_DONE, seats: data.data, paging: data.paging });
				return data.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};


export const createSeat = (eventId, name, displayName, description, capacity, visible, price, priceFlags, currency, type = 'standing', membershipRequired = 'N', requiresPayment = 'Y') => {
	return (dispatch, getState) => {
		dispatch({ type: SEAT_CREATE });

		const params = {
			Currency__: currency,
			Price: price,
			Seat_Name: name,
			Seat_Name_Display: displayName ?? {},
			Description: description,
			Capacity: capacity,
			Visible: visible,
			Type: type,
			Price_Flags: priceFlags,
			Membership_Required: membershipRequired,
			Reservation_Requires_Payment: requiresPayment,
		};

		return rest('Planner/Event/' + eventId + '/Seat', 'POST', params)
			.then(data => {
				success('seat_create_success');
				dispatch({ type: SEAT_CREATE_DONE, seat: data.data });
				return data.data;
			})
			.catch((err) => {
				dispatch({ type: SEAT_CREATE_DONE });
				handleError(getState, dispatch, err);
			});
	};
};

export const updateSeat = (seatId, name, displayName, description, capacity, visible, price, priceFlags, currency, type = 'standing', membershipRequired = 'N', requiresPayment = 'Y') => {
	return (dispatch, getState) => {
		dispatch({ type: SEAT_UPDATE });

		const params = {
			Currency__: currency,
			Price: price,
			Seat_Name: name,
			Seat_Name_Display: displayName,
			Description: description,
			Capacity: capacity,
			Visible: visible,
			Type: type,
			Price_Flags: priceFlags,
			Membership_Required: membershipRequired,
			Reservation_Requires_Payment: requiresPayment,
		};

		return rest('Planner/Event/Seat/' + seatId, 'PATCH', params)
			.then(data => {
				success('seat_update_success');
				dispatch({ type: SEAT_UPDATE_DONE, seat: data.data });
				return data.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const deleteSeat = (seatId) => {
	return (dispatch, getState) => {
		dispatch({ type: SEAT_DELETE });

		return rest('Planner/Event/Seat/' + seatId, 'DELETE')
			.then(data => {
				success('seat_delete_success');
				dispatch({ type: SEAT_DELETE_DONE });
				return data.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};
