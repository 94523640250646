import {
	TEMPLATE_FETCH,
	TEMPLATE_FETCH_DONE, TEMPLATE_IMAGE_VARIATION,
	TEMPLATE_SELECTED_FETCH,
	TEMPLATE_SELECTED_FETCH_DONE,
	TEMPLATE_SETTING_UPDATE,
	TEMPLATE_SETTING_UPDATE_DONE,
	TEMPLATE_SETTINGS_FETCH,
	TEMPLATE_SETTINGS_FETCH_DONE,
	TEMPLATE_UPDATE,
	TEMPLATE_UPDATE_DONE
}                                                 from '../reducers/TemplateReducer';
import { handleError }                            from '../../components/utils/apiErrorHandler';
import { rest, upload }                           from '@karpeleslab/klbfw';
import { REGISTRY_UPDATE_DONE }                   from '../reducers/RegistryReducer';
import { success }                                from './system/ToastAction';
import { createUserLocation, updateUserLocation } from './system/UserLocationAction';
import { REALM_SITE_UPDATED}         from '../reducers/RealmReducer';

export const fetchTemplates = (filters = {}) => {
	return (dispatch, getState) => {
		dispatch({ type: TEMPLATE_FETCH });
		return rest('Site/Template', 'GET', filters)
			.then(d => {
				dispatch({ type: TEMPLATE_FETCH_DONE, templates: d.data });
				return d.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const fetchSelectedTemplate = (site) => {
	return (dispatch, getState) => {
		dispatch({ type: TEMPLATE_SELECTED_FETCH });
		return rest('Site/Template', 'GET', { Site__: site, image_variation:TEMPLATE_IMAGE_VARIATION })
			.then(d => {
				const template = d.data.length > 0 ? d.data[0] : null;
				dispatch({ type: TEMPLATE_SELECTED_FETCH_DONE, template: template });
				return template;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};


export const fetchSelectedTemplateSettings = (settingId) => {
	return (dispatch, getState) => {
		dispatch({ type: TEMPLATE_SETTINGS_FETCH });
		return rest('Setting/' + settingId)
			.then(d => {
				dispatch({ type: TEMPLATE_SETTINGS_FETCH_DONE, settings: d.data });
				return d.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const uploadSettingFiles = (originalFiles, settingId) => {
	const files = [];
	// Filter send files to be sure only uploadable files are sent
	Object.keys(originalFiles).forEach(key => {
		if (originalFiles[key] instanceof File) files.push({ file: originalFiles[key], key: key });
	});

	return new Promise((resolve, reject) => {
		if (files.length < 1) {
			resolve();
			return;
		}

		upload.onprogress = (d) => {
			if (d.queue.length !== 0) return;
			let allComplete = true;

			d.running.forEach((value) => {
				if (value.status !== 'complete')
					allComplete = false;
			});

			if (allComplete)
				resolve();
		};

		files.forEach(f => {
			upload.append('Setting/' + settingId + ':uploadFile', f.file, { key: f.key });
		});


		upload.run();
	});
};

export const updateSetting = (settingId, key, value, successMessageToken = null) => {
	return (dispatch, getState) => {
		dispatch({ type: TEMPLATE_SETTING_UPDATE });
		return rest(`Setting/${settingId}:setData`, 'POST', { key: key, data: value })
			.then(d => {
				if (successMessageToken) success(successMessageToken);
				dispatch({ type: TEMPLATE_SETTING_UPDATE_DONE, settings: d.data });
				return d.data;
			}).catch((err) => {
				dispatch({ type: TEMPLATE_SETTING_UPDATE_DONE });
				handleError(getState, dispatch, err);
			});
	};
};

export const updateTemplateAndSettings = (newTemplateId, newSettings, files, registryUrlId) => {
	return (dispatch, getState) => {
		dispatch({ type: TEMPLATE_UPDATE });
		let updateResult = null;
		return new Promise((resolve, reject) => {
			rest('Music:updateSiteTemplate', 'POST', {
				template: newTemplateId,
				settings: newSettings,
				registryUrl: registryUrlId,
				image_variation:TEMPLATE_IMAGE_VARIATION
			})
				.then(d => {
					updateResult = d.data;
					return uploadSettingFiles(files, updateResult.Setting.Setting__);
				})
				.then(d => dispatch(fetchSelectedTemplateSettings(updateResult.Setting.Setting__))) // refresh;
				.then(setting => {
					dispatch({ type: TEMPLATE_UPDATE_DONE, template: updateResult.Site_Template, settings: setting });
					dispatch({ type: REGISTRY_UPDATE_DONE, url: updateResult.Registry_Url });
					dispatch({ type: REALM_SITE_UPDATED, site: updateResult.Realm.Site__ });
					resolve(updateResult);
					success('template_settings_update_success');
					return setting;
				})
				.catch(reject);

		})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const setSiteLocation = (settingId, locationData, locationId = null) => {
	return (dispatch, getState) => {
		dispatch({ type: TEMPLATE_SETTING_UPDATE });

		const upsert = (locationData, locationId = null) => {
			if (locationId) return updateUserLocation(locationId, locationData);
			return createUserLocation(locationData);
		};

		dispatch(upsert(locationData, locationId))
			.then((location) => dispatch(updateSetting(settingId, 'location', location.User_Location__)))
			.then((d) => {
				success('catalog_location_update_success');
				dispatch({ type: TEMPLATE_SETTING_UPDATE_DONE });
				return d.data;
			})
			.catch((err) => handleError(getState, dispatch, err));
	};
};

