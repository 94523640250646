import React, { Fragment, useEffect }                  from 'react';
import TableHead                                       from '@material-ui/core/TableHead';
import MaterialTable                                   from '@material-ui/core/Table';
import TableRow                                        from '@material-ui/core/TableRow';
import TableCell                                       from '@material-ui/core/TableCell';
import TableBody                                       from '@material-ui/core/TableBody';
import TableContainer                                  from '@material-ui/core/TableContainer';
import Row                                             from './Row';
import { connect }                                     from 'react-redux';
import TableFooter                                     from '@material-ui/core/TableFooter';
import { fetchUsers, setUsersFilters, setUsersPaging } from '../../../../store/actions/RealmAction';
import { useTranslation }                              from 'react-i18next';
import Empty                                           from './Empty';
import Pagination                                      from '../../data/table/Pagination';


const Table = ({ realm, users, fetch, loading, filters, paging, count, setUsersPaging, setUsersFilters, disabled = false }) => {

	const loadingData = [...Array(paging.results_per_page).keys()];
	const { t } = useTranslation();
	const displayLoading = () => loadingData.map((k) => <Row key={k} loading={true} user={null}/>);
	const displayUsers = () => users.map((u, idx) => <Row key={u.User__} loading={false} idx={idx}/>);

	useEffect(() => {
		if (realm && !disabled) {
			fetch();
		}

	}, [disabled, realm, fetch, setUsersFilters, filters, paging]);


	/*
	const {sort} = filters;

	const sortHandler = (property, dir) => {
		var p = {...filters};
		// only 1 sort
		p.sort = {};
		p.sort[property] = dir;
		setUsersFilters(p);
	};*/

	return (
		<Fragment>
			{(loading || users.length > 0) && <TableContainer>
				<MaterialTable aria-label='simple table'>
					<TableHead>
						<TableRow>
							<Pagination
								setPaging={setUsersPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
						<TableRow>
							<TableCell style={{ width: '40px' }}> {t('tablecell_picture')}</TableCell>
							<TableCell>{t('tablecell_username')}</TableCell>
							<TableCell>{t('tablecell_email')}</TableCell>
							<TableCell align='right'/>
						</TableRow>
					</TableHead>
					<TableBody>
						{(loading || disabled) && displayLoading()}
						{(!loading && !disabled) && displayUsers()}
					</TableBody>
					<TableFooter>
						<TableRow>
							<Pagination
								setPaging={setUsersPaging}
								paging={paging}
								loading={loading}
								count={count}
							/>
						</TableRow>
					</TableFooter>

				</MaterialTable>
			</TableContainer>}
			{(!loading && users.length < 1) && <Empty/>}
		</Fragment>
	);

};


const mapStateToProps = (state) => {
	return {
		filters: state.realm.usersFilters,
		paging: state.realm.usersPaging,
		count: state.realm.usersPagingCount,
		loading: state.realm.usersLoading,
		users: state.realm.users
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: () => dispatch(fetchUsers()),
		setUsersPaging: (newPaging) => dispatch(setUsersPaging(newPaging)),
		setUsersFilters: (newPaging) => dispatch(setUsersFilters(newPaging))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);

