import {
	REALM_ALIASES,
	REALM_ALIASES_DONE,
	REALM_FETCH,
	REALM_FETCH_DONE,
	REALM_FETCH_USER,
	REALM_FETCH_USER_DONE,
	REALM_PAYMENT_CONTROLLER_FETCH,
	REALM_PAYMENT_CONTROLLER_FETCH_DONE,
	REALM_PAYMENT_CONTROLLER_SET,
	REALM_PAYMENT_CONTROLLER_SET_DONE,
	REALM_SELECT,
	REALM_SELECTING,
	REALM_USER_IMAGE_VARIATION,
	REALM_USERS_FETCH,
	REALM_USERS_FETCH_DONE,
	REALM_USERS_SET_FILTERS,
	REALM_USERS_SET_PAGING
}                             from '../reducers/RealmReducer';
import { rest }               from '@karpeleslab/klbfw';
import { handleError }        from '../../components/utils/apiErrorHandler';
import Cookies                from 'js-cookie';
import { createUserLocation } from './system/UserLocationAction';
import { updateSetting }      from './TemplateAction';
import { success }            from './system/ToastAction';

export const fetch = () => {
	return (dispatch, getState) => {
		dispatch({ type: REALM_FETCH });
		return rest('Realm', 'GET', { results_per_page: 100, all: 1 })
			.then(data => {
				dispatch({ type: REALM_FETCH_DONE, realms: data.data, accesses: data.access });
				return data.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const fetchAndSelect = (id) => {
	return (dispatch, getState) => {
		dispatch({ type: REALM_SELECTING, realmId: id });
		return dispatch(fetchById(id))
			.then(([realm, access]) => dispatch(select(realm, access)));
	};
};
export const fetchById = (id) => {
	return (dispatch, getState) => {
		return rest('Realm/' + id)
			.then(data => {
				return [data.data, data.access];
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const select = (realm, access) => {
	return (dispatch, getState) => {
		if (getState().realm.selected) {
			if (getState().realm.selected.Realm__ === realm.Realm__) return; // No realm changes, to not trigger all refresh.
		}
		Cookies.set('Realm__', realm.Realm__);
		return dispatch({ type: REALM_SELECT, realm: realm, access: access });
	};
};

export const fetchAliases = () => {
	return (dispatch, getState) => {
		dispatch({ type: REALM_ALIASES });
		return rest('Realm/' + getState().realm.selected.Realm__ + '/Alias')
			.then(data => {
				dispatch({ type: REALM_ALIASES_DONE, aliases: data.data });
				return data.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const fetchAliasForMetaObject = (metaObjectId) => {
	return (dispatch, getState) => {
		return rest('Realm/Alias', 'GET', { MetaObject__: metaObjectId })
			.then(data => {
				if (data.data.length > 0) return data.data[0];
				return null;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const setUsersPaging = (newPaging) => {
	return (dispatch, getState) => {
		dispatch({ type: REALM_USERS_SET_PAGING, paging: { ...getState().realm.usersPaging, ...newPaging } });
	};
};

export const setUsersFilters = (newFilters) => {
	return (dispatch, getState) => {
		dispatch({ type: REALM_USERS_SET_FILTERS, filters: newFilters });
	};
};

export const fetchUsers = () => {
	return (dispatch, getState) => {
		dispatch({ type: REALM_USERS_FETCH });

		const params = {
			...getState().realm.usersFilters,
			...getState().realm.usersPaging
		};

		return rest('User:adminSearch', 'POST', params)
			.then(data => {
				dispatch({ type: REALM_USERS_FETCH_DONE, users: data.data, paging: data.paging });
				return data.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const fetchUser = (id) => {
	return (dispatch, getState) => {
		dispatch({ type: REALM_FETCH_USER });
		return rest('User/' + id, 'GET', { image_variation: REALM_USER_IMAGE_VARIATION })
			.then(data => {
				dispatch({ type: REALM_FETCH_USER_DONE, user: data.data });
				return data.data;
			})
			.catch((err) => {
				handleError(getState, dispatch, err);
			});

	};
};

const _setPaymentController = (realmId, stripeToken) => {
	return (dispatch, getState) => {
		return rest('Music:setStripePaymentControllerRealm', 'POST', { Realm__: realmId, Stripe_Token: stripeToken })
			.then(d => {
				return d;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const setPaymentController = (realmId, stripeToken, address = null, successToken = null) => {
	return (dispatch, getState) => {
		dispatch({ type: REALM_PAYMENT_CONTROLLER_SET });
		if (!address) {
			return dispatch(_setPaymentController(realmId, stripeToken))
				.then(d => {
					if(successToken) success(successToken)
					dispatch({ type: REALM_PAYMENT_CONTROLLER_SET_DONE, paymentController: d.data });
					return d.data;
				}).catch((err) => {
					handleError(getState, dispatch, err);
				});
		}

		const settingId = getState().template.templateSettings.Setting__;
		return dispatch(createUserLocation(address))
			.then((location) => dispatch(updateSetting(settingId, 'location', location.User_Location__)))
			.then(() => dispatch(_setPaymentController(realmId, stripeToken)))
			.then(d => {
				if(successToken) success(successToken)
				dispatch({ type: REALM_PAYMENT_CONTROLLER_SET_DONE, paymentController: d.data });
				return d.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};

export const fetchPaymentController = (realmId) => {
	return (dispatch, getState) => {
		dispatch({ type: REALM_PAYMENT_CONTROLLER_FETCH });
		return rest('Music:getStripePaymentControllerRealm', 'POST', { Realm__: realmId })
			.then(d => {
				dispatch({ type: REALM_PAYMENT_CONTROLLER_FETCH_DONE, paymentController: d.data });
				return d.data;
			}).catch((err) => {
				handleError(getState, dispatch, err);
			});
	};
};
