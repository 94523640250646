import React, { useEffect, useState } from 'react';
import { connect }                    from 'react-redux';
import { fetch }                      from '../../../store/actions/system/LanguageAction';
import FormControl                    from '@material-ui/core/FormControl';
import InputLabel                     from '@material-ui/core/InputLabel';
import { Select }                     from '@material-ui/core';
import FormHelperText                 from '@material-ui/core/FormHelperText';
import { useTranslation }             from 'react-i18next';
import CircularProgress               from '@material-ui/core/CircularProgress';
import InputAdornment                 from '@material-ui/core/InputAdornment';

const Language = ({ loading, list, fetch, multiple = false, onChange, defaultValue = '', helperText = '', disabled = false, inputProps = {}, value = undefined, useLocalName = false, allowEmpty = false, fromSettings = false, settingLanguage = [] }) => {
	const languageLabel = React.useRef(null);
	const [languageLabelWidth, setLanguageLabelWidth] = useState(0);
	const { t } = useTranslation();

	useEffect(() => {
		setLanguageLabelWidth(languageLabel.current.offsetWidth);
	}, []);

	useEffect(() => {
		fetch();
	}, [fetch]);

	return (
		<FormControl disabled={disabled || loading} {...inputProps}>
			<InputLabel ref={languageLabel} htmlFor='language-select' shrink>{t('language_lbl')}</InputLabel>
			<Select
				multiple={multiple}
				native
				value={loading ? undefined : value}
				onChange={onChange}
				labelWidth={languageLabelWidth}
				startAdornment={loading ?
					<InputAdornment position='start'>
						<CircularProgress thickness={1} size={20}/>
					</InputAdornment> : undefined
				}
				inputProps={{
					id: 'language-select',
				}}
			>
				<option disabled>{t('choose_language')}</option>
				{allowEmpty && <option value=''>{t('no_language')}</option>}
				{list.map((v) => {
						if (!fromSettings || settingLanguage == null || settingLanguage.includes(v.Language__))
							return (
								<option
									key={v.Language__}
									value={v.Language__}>
									{useLocalName ? v.Local_Name : v.Name_Long}
								</option>
							);
						return null;
					}
				)}
			</Select>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	);
};

const mapStateToProps = (state) => {
	return {
		settingLanguage: state.template.templateSettings?.Data?.Languages ?? null,
		loading: state.language.loading,
		list: state.language.languages,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: (data) => dispatch(fetch())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Language);
