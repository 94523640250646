import React, {useState}                 from 'react';
import {fetchEntryData, selectEntryData} from '../../../store/actions/CmsAction';
import {connect}                from 'react-redux';
import {CircularProgress, Grid} from '@material-ui/core';
import TextField                from '@material-ui/core/TextField';
import MenuItem            from '@material-ui/core/MenuItem';
import Tooltip             from '@material-ui/core/Tooltip';
import Flag                from '../data/flag/Flag';
import Skeleton            from '@material-ui/lab/Skeleton';
import HasRight            from '../../Route/HasRight';
import Button              from '../inputs/Button';
import AddIcon             from '@material-ui/icons/Add';
import Toolbar             from '../typography/Toolbar';
import useTheme            from '@material-ui/core/styles/useTheme';
import {useTranslation}    from 'react-i18next';
import CreateDialog        from '../cms/entry/CreateDialog';

const EntryDataSelector = ({entryData, selectEntryData, fetchEntryData, loading}) => {
	const theme = useTheme();
	const {t} = useTranslation();
	const [showCreate, setShowCreate] = useState(false);

	if (!entryData) return <></>;

	const handleLangChange = (e) => {
		fetchEntryData(e.target.value);
	};

	const onCreated = (entryData) => {
		selectEntryData(entryData);
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Toolbar title={entryData.Title} disableGutters startAdornment={
					<div style={{marginRight: theme.spacing(2)}}>
						{
							entryData &&
							<TextField
								disabled={loading}
								select
								value={entryData.Content_Cms_Entry_Data__}
								onChange={handleLangChange}
							>
								{Object.keys(entryData.Content_Cms_Entry.Data).map((l) => (
									<MenuItem
										key={entryData.Content_Cms_Entry.Data[l].Content_Cms_Entry_Data__ || entryData.Content_Cms_Entry.Data[l]._key}
										value={entryData.Content_Cms_Entry.Data[l].Content_Cms_Entry_Data__ || entryData.Content_Cms_Entry.Data[l]._key}
									>
										<Tooltip title={l.split('-')[1].toLowerCase()}
										         placement="bottom"
										         arrow><Flag code={l.split('-')[1].toLowerCase()}/>
										</Tooltip>
									</MenuItem>
								))}
							</TextField>


						}
						{loading && <CircularProgress style={{marginLeft:'6px'}}/>}
						{!entryData && <Skeleton height={40} width={40}/>}
					</div>

				}>
					<HasRight manager hideLoading>
						<Button endIcon={<AddIcon/>} variant="contained" color="primary"
						        onClick={e => setShowCreate(true)}>
							{t('add_language_btn')}
						</Button>
					</HasRight>
				</Toolbar>
			</Grid>
			{entryData && <CreateDialog
				setOpen={setShowCreate}
				open={showCreate}
				cmsId={entryData.Content_Cms__}
				entryId={entryData.Content_Cms_Entry.Content_Cms_Entry__}
				onCreated={onCreated}
			/>
			}
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		entryData: state.cms.entryData,
		loading: state.cms.loadingEntryData,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchEntryData: (id) => dispatch(fetchEntryData(id, true)),
		selectEntryData: (data) => dispatch(selectEntryData(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EntryDataSelector);
