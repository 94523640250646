import React, {useEffect} from 'react';
import {connect}          from 'react-redux';
import {useTranslation}   from 'react-i18next';
import Autocomplete       from '@material-ui/lab/Autocomplete';
import TextField          from '@material-ui/core/TextField';
import CircularProgress   from '@material-ui/core/CircularProgress';
import {
	fetchShippingFees,
	setShippingFeeFilters,
	setShippingFeePaging
}                         from '../../../store/actions/store/ShippingFeeAction';

const ShippingFeeSelector = ({
	                             loading,
	                             list,
	                             fetch,
	                             filters,
	                             value,
	                             onChange,
	                             required = false,
	                             disabled = false,
	                             setFilters,
	                             setPaging
                             }) => {
	const {t} = useTranslation();

	useEffect(() => {
		setFilters({});
		setPaging({page_no: 1, results_per_page: 100});
	}, [setFilters, setPaging]);

	const onChangeHandler = (e) => {
		setFilters({Name: {'$prefix': e.target.value},});
	};

	useEffect(() => {
		fetch();
	}, [fetch, filters]);

	return (
		<Autocomplete
			id="shipping-fee-select"
			disabled={disabled}
			value={value}
			onBlur={() => {
				setFilters({});
				fetch();
			}}
			onChange={(e, value) => onChange(value)}
			getOptionSelected={(option, value) => option.Catalog_Deliverable_Fee__ === value.Catalog_Deliverable_Fee__}
			getOptionLabel={(option) => option === '' ? '' : option.Name}
			options={list}
			loading={loading}
			renderInput={(params) => (
				<TextField
					{...params}
					required={required}
					label={t('deliverable_fee_selector')}
					helperText={t('deliverable_fee_selector_helperText')}
					variant="outlined"
					onChange={onChangeHandler}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<React.Fragment>
								{loading ? <CircularProgress color="inherit" size={20}/> : null}
								{params.InputProps.endAdornment}
							</React.Fragment>
						),
					}}
				/>
			)}
		/>
	);

};

const mapStateToProps = (state) => {
	return {
		loading: state.shippingFee.feesLoading,
		list: state.shippingFee.fees,
		filters: state.shippingFee.feesFilters,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetch: () => dispatch(fetchShippingFees()),
		setFilters: (filters) => dispatch(setShippingFeeFilters(filters)),
		setPaging: (paging) => dispatch(setShippingFeePaging(paging))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ShippingFeeSelector);
