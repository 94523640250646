const initialState = {
    data: [],
    error: null,
};

export const FETCH_LANG_BEGIN = 'FETCH_LANG_BEGIN';
export const FETCH_LANG_SUCCESS = 'FETCH_LANG_SUCCESS';
export const FETCH_LANG_FAILURE = 'FETCH_LANG_FAILURE';

const lang = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_LANG_BEGIN: {
			return {
				...state,
                loading: true,
                error: null,
                data:[]
			};
		}
		case FETCH_LANG_SUCCESS: {
			return {
				...state,
                loading: false,
                data: action.lang
            };
        }
        case FETCH_LANG_FAILURE: {
			return {
				...state,
                loading: false,
                error: action.lang.error,
                data: []
			};
		}
		default: {
			return state;
		}
	}
};

export default lang