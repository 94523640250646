import React, {useEffect, useState}        from 'react';
import {connect}                           from 'react-redux';
import {fetchReleases, setReleasesFilters} from '../../../../store/actions/discography/DiscographyAction';
import Button                              from '../../inputs/Button';
import {useTranslation}                    from 'react-i18next';
import {Link}                              from 'react-router-dom';
import {getMusicDiscographyEditRoute}      from '../../../../router/routes/music/factory/discography';

const ReleaseLink = ({album, setReleasesFilters, fetchReleases}) => {
	const {t} = useTranslation();
	const [release, setRelease] = useState(null);

	useEffect(() => {
		if (!album) return;
		setReleasesFilters({Music_Label_Album__: album.Music_Label_Album__});
		fetchReleases().then(d => {
			if (d.length < 1) setRelease(false);
			else setRelease(d[0]);
		});
	}, [album, setRelease, fetchReleases, setReleasesFilters]);

	return (
		<Button loading={release === null} disabled={!release} component={Link}
		        to={getMusicDiscographyEditRoute(release?.Music_Label_Release__)}>
			{t('view_btn')}
		</Button>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		setReleasesFilters: (filters) => dispatch(setReleasesFilters(filters)),
		fetchReleases: () => dispatch(fetchReleases()),
	};
};

export default connect(null, mapDispatchToProps)(ReleaseLink);
