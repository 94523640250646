import React                  from 'react';
import Grid                   from '@material-ui/core/Grid';
import AppBarTitle            from '../../../components/common/typography/AppBarTitle';
import {useTranslation}       from 'react-i18next';
import MemberList             from '../../../components/common/member/List';
import {connect}              from 'react-redux';
import SelectRealm            from '../../SelectRealm';
import AddIcon                from '@material-ui/icons/Add';
import {Link}                 from 'react-router-dom';
import HasRight               from '../../../components/Route/HasRight';
import Toolbar                from '../../../components/common/typography/Toolbar';
import {getMemberCreateRoute} from '../../../router/routes/artist/factory/members';
import Button                 from '../../../components/common/inputs/Button';

const List = ({artist, selectedRealm}) => {
	const {t} = useTranslation();

	if (!selectedRealm || !artist) return <SelectRealm/>;

	return (
		<Grid container spacing={3}>
			<AppBarTitle title={t('members_title')}/>
			<Grid item xs={12}>
				<Toolbar title={t('members_title')}>
					<HasRight admin>
						<Button endIcon={<AddIcon/>} component={Link} to={getMemberCreateRoute()} variant="contained"
						        color="primary">
							{t('create_btn')}
						</Button>
					</HasRight>
				</Toolbar>

			</Grid>
			<Grid item xs={12}>
				<MemberList artist={artist}/>
			</Grid>

		</Grid>

	);
};

const mapStateToProps = (state) => {
	return {
		selectedRealm: state.realm.selected,
		artist: state.artist.artist,
	};
};
export default connect(mapStateToProps)(List);