import React, {useState} from 'react';

import {Trans, useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AddMemberDialog from "./AddMemberDialog";
import Member from "./Member";
import Empty from './EmptyMember'
import AddIcon from "@material-ui/icons/Add";
import Button from "../../common/inputs/Button";

const StepMembers = ({handleBack, handleNext, members, setMembers}) => {
    const {t} = useTranslation();

    const [open, setOpen] = useState(false);
    const [selectedMember, setSelectedMember] = useState(null);

    const createDeleteHandler = idx => e => {
        setMembers(members.filter((e, i) => i !== idx))
    };

    const addHandler = member => {
        setMembers([...members, member])
    };

    const editHandler = (idx, member) => {
        const list = [...members];
        list[idx] = member;
        setMembers(list);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <AddMemberDialog
                    onEdit={editHandler}
                    member={selectedMember ? selectedMember[0] : null}
                    memberIdx={selectedMember ? selectedMember[1] : null}
                    onAdd={addHandler}
                    open={open}
                    setOpen={setOpen}/>
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={3} justify='space-between'>
                    <Grid item>
                        <Typography variant="h5" color="textPrimary" component="h5">
                            {t('artist_wizard_step_3_title_members')}
                        </Typography>
                    </Grid>
                    {members.length > 0 &&
                    <Grid item>
                        <Button
                            variant='contained'
                            endIcon={<AddIcon/>}
                            color="primary"
                            onClick={() => {
                                setSelectedMember(null);
                                setOpen(true)
                            }}
                        >
                            {t('add_btn')}
                        </Button>
                    </Grid>
                    }
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid item xs={12}>
                    <Typography variant="body1" color="textSecondary" component="p">
                        <Trans i18nKey="artist_wizard_step_3_desc">
                            sample
                            <br/>
                            sample
                        </Trans>
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={3} alignItems="stretch">

                    {members.length < 1 &&
                    <Grid item xs={12}>
                        <Empty showCreate={true} onClick={() => {
                            setSelectedMember(null);
                            setOpen(true)
                        }}/>
                    </Grid>}

                    {members.map((m, i) => {
                            return (
                                <Grid item xs={6} md={4} key={'list-member-' + i}>
                                    <Member
                                        member={m}
                                        onEdit={() => {
                                            setSelectedMember([m, i]);
                                            setOpen(true)
                                        }}
                                        onDelete={createDeleteHandler(i)}
                                    />
                                </Grid>)
                        }
                    )}

                </Grid>
            </Grid>


            <Grid item xs={12}>
                <Grid container justify='space-between' spacing={3}>

                    <Grid item>
                        <Button onClick={handleBack} variant={'outlined'}
                                color="primary">
                            {t('back_btn')}
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button onClick={handleNext} variant={members.length < 1 ? 'outlined' : 'contained'}
                                color="primary">
                            {members.length < 1 && t('skip_btn')}
                            {members.length > 0 && t('next_btn')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default StepMembers;