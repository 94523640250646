import React                     from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Grid                      from '@material-ui/core/Grid';
import Typography                from '@material-ui/core/Typography';
import HasRight                  from '../../Route/HasRight';
import Alert                     from '@material-ui/lab/Alert';
import Button from "../inputs/Button";

const Introduction = ({ handleNext, titleKey, descriptionKey, graphic }) => {
	const { t } = useTranslation();


	return (
		<Grid container spacing={3} direction='row'
		      justify='center'>


			<Grid item xs={4} md={4}>
				<img src={graphic} alt='store' width='100%'/>
			</Grid>

			<Grid item xs={12} md={8}>
				<Grid container spacing={3} direction='column' justify='center'>

					<Grid item xs={12}>
						<Typography variant='h5' color='textPrimary' component='h5'>
							{t(titleKey)}
						</Typography>
					</Grid>

					<Grid item xs={12}>
						<Trans i18nKey={descriptionKey}>
							sample
							<br/>
							<strong>sample</strong>
						</Trans>
					</Grid>
					<HasRight owner deniedComponent={
						<Grid container justify='center'>
							<Grid item>
								<Alert severity='warning'>
									{t('stripe_required_owner_required')}
								</Alert>
							</Grid>
						</Grid>
					}>
						<Grid item xs={12}>
							<Grid container justify='center'>

								<Button onClick={handleNext} variant='contained' color='primary'>
									{t('stripe_required_start')}
								</Button>
							</Grid>
						</Grid>
					</HasRight>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Introduction;
