import AlbumsList from "../../../pages/music/album/List";
import AlbumsCreate from "../../../pages/music/album/Create";
import AlbumsEdit from "../../../pages/music/album/Edit";
import {getMusicAlbumCreateRoute, getMusicAlbumEditRoute, getMusicAlbumsRoute} from "./factory/album";

export default [
    {
        path: getMusicAlbumsRoute(),
        component: AlbumsList,
        exact: true,
        type: 'route',
        loginRequired: true,
    },
    {
        path: getMusicAlbumCreateRoute(),
        component: AlbumsCreate,
        exact: true,
        type: 'route',
        loginRequired: true,
    },
    {
        path: getMusicAlbumEditRoute(),
        component: AlbumsEdit,
        exact: false,
        type: 'route',
        loginRequired: true,
    },
]