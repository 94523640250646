import {CONTACT_SEND, CONTACT_SEND_DONE} from "../reducers/ContactReducer";
import {success} from "./system/ToastAction";
import {rest} from '@karpeleslab/klbfw'
import {handleError} from "../../components/utils/apiErrorHandler";

export const sendTicket = (email, name, to, subject, message, tags = [], attach = []) => {
    return (dispatch, getState) => {
        let params = {
            Email: email,
            Name: name,
            To: to,
            Subject: subject,
            Message: message,
        };

        if (attach.length > 0) params.Attach = attach;
        if (tags.length > 0) params.Tags = tags;

        dispatch({type: CONTACT_SEND});
        return rest('Support/Ticket', 'POST', params).then(
            (d) => {
                dispatch({type: CONTACT_SEND_DONE});
                success('contact_send_success');

                return d.data

            }
        ).catch((err) => {
            dispatch({type: CONTACT_SEND_DONE});
            handleError(getState, dispatch, err)
        });
    }
};
