import React, { useEffect, useState }                                       from 'react';
import { connect }                                                          from 'react-redux';
import { getText }                                                          from '../../../store/actions/TextAction';
import Grid                                                                 from '@material-ui/core/Grid';
import Loading                                                              from '../../../pages/SelectRealm';
import { getLocale }                                                        from '@karpeleslab/klbfw';
import {  TextField }                                        		        from '@material-ui/core';
import Country                                                              from '../inputs/Country';
import MusicGenre                                                           from '../inputs/MusicGenre';
import { useTranslation }                                                   from 'react-i18next';
import { updateArtist, updateArtistImage }                                  from '../../../store/actions/ArtistAction';
import SnsLinks                                                             from '../data/SnsLink/SnsLinks';
import { hasChanged as nameHasChanged, requiredCheck as nameRequiredCheck } from '../../utils/i18nInput';
import InputI18N                                                            from '../inputs/InputI18n';
import PictureChooser                                                       from '../inputs/PictureChooser';
import HasRight                                                             from '../../Route/HasRight';
import Gallery                                                              from './Gallery';
import Button                                                               from "../inputs/Button";
import FormRequiredField                                                    from '../feeback/FormRequiredField';

const Edit = ({ artist, getText, updating, uploadingArtistImage, updateArtist, updateArtistImage, isManager }) => {
	const updatingArtist = uploadingArtistImage || updating;
	const { t } = useTranslation();
	const [name, setName] = useState({});
	const [needDetailedName, setNeedDetailedName] = useState(false);

	const [country, setCountry] = useState();
	const [genre, setGenre] = useState();
	const [biography, setBiography] = useState();
	const [biographyLanguage, setBiographyLanguage] = useState(getLocale());

	const [catchphrase, setCatchphrase] = useState('');
	const [catchphraseLanguage, setCatchphraseLanguage] = useState(getLocale());


	const [links, setLinks] = useState([]);

	const [picture, setPicture] = useState();
	const [pictureEdited, setPictureEdited] = useState(false);

	const [saveEnabled, setSaveEnabled] = useState(false);

	// Text data
	const [biographyText, setBiographyText] = useState();
	const [nameText, setNameText] = useState();
	const [catchphraseText, setCatchphraseText] = useState();

	const [loaded, setLoaded] = useState(false);

	// Initialise data, Get text and set internal State
	useEffect(() => {
		if (!artist) return;
		const promises = [];
		promises.push(getText(artist.Name_Text__)
			.then(text => {
					setNameText(text);
					setName(text.Values);
				}
			));

		promises.push(getText(artist.Bio_Text__)
			.then(text => {
				const k = Object.keys(text.Values);
				if (k.length > 0) {
					setBiographyText(text);
					setBiographyLanguage(k[0]);
					setBiography(text.Values[k[0]]);
				}
			}));

		promises.push(getText(artist.Catchphrase_Text__)
			.then(text => {
					setCatchphraseText(text);
					if (!text.Values) return;
					const k = Object.keys(text.Values);
					if (k.length > 0) {
						setCatchphraseLanguage(k[0]);
						setCatchphrase(text.Values[k[0]]);
					}
				}
			));


		setCountry(artist.Country);
		setGenre(artist.Music_Genre);

		// Dirty, we need to copy the link list so we can compare it later, to enable/disable the save btn
		const copy = [];
		artist.Links.forEach(link => copy.push({ ...link }));
		setLinks(copy);

		if (artist.Image_Drive_Item__) {
			setPicture(artist.Image_Drive_Item);
		}
		//
		Promise.all(promises).then(r => setLoaded(true));

	}, [artist, setLoaded, getText]);

	// Enable/Disable save btn
	useEffect(() => {
		if (!loaded) {
			setSaveEnabled(false);
			return;
		}
		// Check minimum required field
		if (!nameRequiredCheck(name, needDetailedName)) {
			setSaveEnabled(false);
			return;
		}

		if (!country || !country.Country__) {
			setSaveEnabled(false);
			return;
		}

		//Enable btn only if there is some changes
		if (country.Country__ !== artist.Country__) {
			setSaveEnabled(true);
			return;
		}

		if (!artist.Music_Genre__ && genre) {
			setSaveEnabled(true);
			return;
		}

		if (artist.Music_Genre__ && !genre) {
			setSaveEnabled(true);
			return;
		}

		if (genre && artist.Music_Genre__ && genre.Music_Genre__ !== artist.Music_Genre__) {
			setSaveEnabled(true);
			return;
		}

		if (pictureEdited) {
			setSaveEnabled(true);
			return;
		}

		if (!(biographyLanguage in biographyText.Values)) {
			// We previously didn't have any biography, but now we have
			if (biography.trim()) {
				setSaveEnabled(true);
				return;
			}
		} else {
			if (biographyText.Values[biographyLanguage] !== biography.trim()) {
				setSaveEnabled(true);
				return;
			}
		}


		if (!(catchphraseLanguage in (catchphraseText.Values ?? {}))) {
			// We previously didn't have any catchphrase, but now we have
			if (catchphrase.trim()) {
				setSaveEnabled(true);
				return;
			}
		} else {
			if (catchphraseText.Values[catchphraseLanguage] !== catchphrase.trim()) {
				setSaveEnabled(true);
				return;
			}
		}

		if (nameHasChanged(nameText.Values, name)) {
			setSaveEnabled(true);
			return;
		}

		if (artist.Links.length !== links.length) {
			setSaveEnabled(true);
			return;
		}

		//Dirty as fuck
		for (let i = 0; i < artist.Links.length; i++) {
			for (let j = 0; j < links.length; j++) {
				if (links[j].Type === artist.Links[i].Type &&
					artist.Links[i].Link !== links[j].Link) {
					setSaveEnabled(true);
					return;
				}
			}
		}

		setSaveEnabled(false);

	}, [
		loaded,
		biographyLanguage,
		biographyText,
		catchphraseLanguage,
		catchphraseText,
		catchphrase,
		nameText,
		artist, name, country, genre, needDetailedName, links, biography, pictureEdited

	]);

	const handleSave = () => {

		const params = {
			Links: links,
			Country__: country.Country__,
			Music_Genre__: genre ? genre.Music_Genre__ : null,
			Bio: {
				[biographyLanguage]: biography
			},
			Catchphrase: {
				[catchphraseLanguage]: catchphrase
			},
			Name: name
		};

		if (pictureEdited) {
			updateArtistImage(artist.Music_Label_Artist__, picture, 'main')
				.then(a => updateArtist(artist.Music_Label_Artist__, params))
				.then(b => {
					setPicture(b.Image_Drive_Item__ ? b.Image_Drive_Item : undefined);
				});
			//.then(b => setPictureEdited(false))
			//.then(b => setPictureKey(uuid()))

		} else {
			updateArtist(artist.Music_Label_Artist__, params);
		}

	};


	if (!loaded) {
		return <Loading/>;
	}

	return (
		<Grid container item xs={12} spacing={3}>

			<FormRequiredField/>

			<Grid item xs={12}>
				<InputI18N value={name}
				           onChange={setName}
				           onDetailedNameChange={setNeedDetailedName}
				           labelKey='artist_name'
				           disabled={updatingArtist || !isManager}
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<Country value={country} required={true} onChange={e => setCountry(e)}
				         disabled={updatingArtist || !isManager} inputProps={{
					variant: 'outlined',
					required: true,
					fullWidth: true,


				}}/>
			</Grid>
			<Grid item xs={12} md={6}>
				<MusicGenre disabled={updatingArtist || !isManager} value={genre} onChange={e => setGenre(e)}
				            inputProps={{
					            variant: 'outlined',
					            fullWidth: true,
				            }}/>
			</Grid>
			<Grid item xs={12}>
				<PictureChooser
					label={t('artist_avatar')}
					value={picture}
					setValue={setPicture}
					pictureEditedChanged={setPictureEdited}
					defaultValue={(artist && artist.Image_Drive_Item__) ? artist.Image_Drive_Item : undefined}
					disabled={updatingArtist || !isManager}
					required={true}
				/>
			</Grid>

			<Grid item xs={12}>
				<TextField
					variant='outlined'
					multiline rows={2}
					rowsMax={Infinity}
					helperText={t('artist_catchphrase_helperText')}
					fullWidth
					value={catchphrase}
					onChange={e => setCatchphrase(e.target.value)}
					label={t('artist_catchphrase_label')}
					disabled={updatingArtist || !isManager}
				/>
			</Grid>

			<Grid item xs={12}>
				<TextField
					variant='outlined'
					multiline rows={5}
					rowsMax={Infinity}
					placeholder={t('artist_bio_placeholder')}
					fullWidth
					value={biography}
					onChange={e => setBiography(e.target.value)}
					label={t('artist_bio_label')}
					disabled={updatingArtist || !isManager}
				/>
			</Grid>

			<Grid item xs={12}>
				<SnsLinks values={links} onChange={setLinks} disabled={updatingArtist || !isManager} available={
					[
						'youtube',
						'facebook',
						'twitter',
						'instagram',
						'soundcloud',
						'twitch',
						'tiktok',
						'reddit',
					]

				}/>
			</Grid>

			<HasRight manager>
				<Grid item xs={12}>
					<Grid container justify='flex-end'>
						<Button loading={updatingArtist} color='primary' variant='contained' onClick={handleSave}
						        disabled={!saveEnabled || updatingArtist}>
							{t('save_btn')}
						</Button>
					</Grid>
				</Grid>
			</HasRight>

			<Grid item xs={12}>
				<Gallery artist={artist}/>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		updating: state.artist.updatingArtist,
		uploadingArtistImage: state.artist.uploadingArtistImage,
		isManager: state.access.manager
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getText: (id) => dispatch(getText(id)),
		updateArtist: (id, data) => dispatch(updateArtist(id, data)),
		updateArtistImage: (id, file, purpose = null) => dispatch(updateArtistImage(id, file, purpose)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
