import { USER_LOGOUT } from './system/UserReducer';
import { COMMON_RESET } from './CommonReducer';
export const TEMPLATE_IMAGE_VARIATION = 'strip&scale_crop=200x180&format=jpeg'
const initState = () => {
	return {
		loading: true,
		templates: [],

		loadingTemplate: true,
		selectedTemplate: null,

		loadingSettings: true,
		templateSettings: {},

		hasLocation: false,

		updatingTemplate: false,

		// Capabilities of the selected template

		isCustomTemplate: false,
		isFanclubSupported: false,
		isStoreSupported: false,
		isEventSupported: false,
		isStreamSupported: false,
	};
};

export const TEMPLATE_FETCH = 'TEMPLATE_FETCH';
export const TEMPLATE_FETCH_DONE = 'TEMPLATE_FETCH_DONE';

export const TEMPLATE_SELECTED_FETCH = 'TEMPLATE_SELECTED_FETCH';
export const TEMPLATE_SELECTED_FETCH_DONE = 'TEMPLATE_SELECTED_FETCH_DONE';

export const TEMPLATE_SETTINGS_FETCH = 'TEMPLATE_SETTINGS_FETCH';
export const TEMPLATE_SETTINGS_FETCH_DONE = 'TEMPLATE_SETTINGS_FETCH_DONE';

export const TEMPLATE_UPDATE = 'TEMPLATE_UPDATE';
export const TEMPLATE_UPDATE_DONE = 'TEMPLATE_UPDATE_DONE';

export const TEMPLATE_SETTING_UPDATE = 'TEMPLATE_SETTING_UPDATE';
export const TEMPLATE_SETTING_UPDATE_DONE = 'TEMPLATE_SETTING_UPDATE_DONE';

const buildCapabilities = (template) => {
	return {
		isCustomTemplate: !template || template.IsCustom === 'Y',
		isFanclubSupported: template ? 'pxl_fanclub' in template.Capabilities : true,
		isStoreSupported: template ? 'pxl_store' in template.Capabilities : true,
		isEventSupported: template ? 'pxl_event' in template.Capabilities : true,
		isStreamSupported: template ? 'pxl_stream' in template.Capabilities : true,
	};
};

const templateReducer = (state = initState(), action) => {
	switch (action.type) {
		case USER_LOGOUT:
		case COMMON_RESET:
			return initState();
		case TEMPLATE_SETTING_UPDATE :
			return { ...state, updatingTemplate: true };
		case TEMPLATE_SETTING_UPDATE_DONE :
			if (!action.settings) return { ...state, updatingTemplate: false };
			return {
				...state,
				updatingTemplate: false,
				templateSettings: action.settings,
				hasLocation: !!action.settings.Data.location
			};
		case TEMPLATE_UPDATE :
			return { ...state, updatingTemplate: true };
		case TEMPLATE_UPDATE_DONE :
			return {
				...state,
				updatingTemplate: false,
				selectedTemplate: action.template,
				templateSettings: action.settings,
				hasLocation: !!action.settings.Data.location,
				...buildCapabilities(action.template)
			};
		case TEMPLATE_SETTINGS_FETCH :
			return { ...state, loadingSettings: true };
		case TEMPLATE_SETTINGS_FETCH_DONE :
			return {
				...state,
				loadingSettings: false,
				templateSettings: action.settings,
				hasLocation: !!action.settings.Data.location,
			};
		case TEMPLATE_SELECTED_FETCH :
			return { ...state, loadingTemplate: true };
		case TEMPLATE_SELECTED_FETCH_DONE :
			return {
				...state,
				loadingTemplate: false,
				selectedTemplate: action.template, ...buildCapabilities(action.template)
			};
		case TEMPLATE_FETCH :
			return { ...state, loading: true };
		case TEMPLATE_FETCH_DONE :
			return { ...state, loading: false, templates: action.templates };
		default:
			return state;
	}

};

export default templateReducer;
