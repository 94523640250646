import {Toast, TOAST_MSG, TOAST_RESET} from "../../reducers/system/ToastReducer";
import {getStore} from "../../store";


export const toast = (msg, i18n = true, duration = 2000, type ='info') => {
    const store = getStore();
    if (!store) return;
    const t = new Toast(msg, i18n, duration, type)
    store.dispatch({type: TOAST_MSG, toast : t});
};

export const warning = (msg, i18n = true, duration = 2000) => {
    toast(msg, i18n, duration , 'warning')
};

export const error = (msg, i18n = true, duration = 2000) => {
    toast(msg, i18n, duration , 'error')
};

export const success = (msg, i18n = true, duration = 2000) => {
    toast(msg, i18n, duration , 'success')
};

export const consumed = (toast) => {
    return (dispatch, getState) => {
        dispatch({type : TOAST_RESET, toast:toast})
    }
}