import React, { useEffect, useState } from 'react';
import { Grid }                       from '@material-ui/core';
import TextField          from '@material-ui/core/TextField';
import Button             from '../../../../inputs/Button';
import { connect }        from 'react-redux';
import { announcePost }   from '../../../../../../store/actions/planner/ChatAction';
import { useTranslation } from 'react-i18next';
import makeStyles                     from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
	infoMessage: {
		fontSize: '14px',
		marginRight: '15px',
	},
});

const ChatAnnounce = ({ event, announcePost, announcing }) => {
	const { t } = useTranslation();
	const [hasReservation, setHasReservation] = useState(event.Total_Count !== 0);
	const [announce, setAnnounce] = useState('');
	const [chatLimit, setChatLimit] = useState(0);

	const classes = useStyles();

	useEffect(() => {
		setHasReservation(event.Total_Count !== 0);
	}, [event.Total_Count, setHasReservation]);

	const handleAnnounce = (e) => {
		e.preventDefault();
		if (chatLimit !== 0) return;

		const query = { message: announce.trim() };

		announcePost(event, query).then(() => setAnnounce(''));
	};

	useEffect(() => {
		if (chatLimit !== 0) {
			const limitTimer = setTimeout(() => {
				setChatLimit(chatLimit - 1);
			}, 1000);
			return () => clearTimeout(limitTimer);
		}

	}, [chatLimit]);

	return (
		<form onSubmit={handleAnnounce}>
			<Grid item xs={12}>
				<TextField
					label={chatLimit !== 0 ? t('chat_limit', { time: chatLimit }) : t('chat_announce')}
					fullWidth
					variant='outlined'
					helperText={t('chat_announce_helper')}
					onChange={(e) => {
						setAnnounce(e.target.value);
					}}
					value={announce}
					disabled={announcing || chatLimit !== 0 || !event.Stream_Flags.chat_enabled}
					type='text'
				/>
			</Grid>

			<Grid item xs={12}>
				<Grid container justify='flex-end' alignItems={'center'}>
					{!hasReservation ?
						<div className={classes.infoMessage}>{t('chat_error_no_reservation')}</div> : <></>}
					<Button
						color='primary'
						type='submit'
						variant='contained'
						loading={announcing}
						disabled={announcing || !announce.trim() || chatLimit !== 0 || !hasReservation || !event.Stream_Flags.chat_enabled}
					>
						{t('chat_send')}
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};

const mapStateToProps = (state) => {
	return {
		announcing: state.chat.announcing,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		announcePost: (event, query) => dispatch(announcePost(event, query)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatAnnounce);
