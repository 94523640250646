import React, {useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import {useTranslation} from "react-i18next";
import Button from "../inputs/Button";

const RequestPasswordDialog = ({open, setOpen, closeCallback}) => {
    const {t} = useTranslation();

    const [pwd, setPwd] = useState('');

    useEffect(() => {
        setPwd('');
    }, [open]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleUnlock = () => {
        setOpen(false);
        closeCallback(pwd);
    };

    return (
        <Dialog
            open={open}
            aria-labelledby="Security password"
            aria-describedby="Security password"
        >
            <DialogTitle id="security-password-title">{t('security_password_required_title')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="security-password-description">
                    {t('security_password_required_description')}
                </DialogContentText>

                <TextField
                    label={t('label_current_password')}
                    fullWidth
                    value={pwd}
                    onChange={(e) => setPwd(e.target.value)}
                    variant="outlined"
                    type='password'
                />
            </DialogContent>
            <DialogActions>
                <Button color="primary" autoFocus onClick={handleClose}>
                    {t('back_btn')}
                </Button>
                <Button variant='contained' type='submit' color="primary" onClick={handleUnlock}>
                    {t('unlock_btn')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RequestPasswordDialog;